import React, { useState } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Dashboard/hooks/useClientDashboard";

import GenAIChatWindow from "@src/multiCustodian/components/GenAI/ChatWindow";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import Spacer from "@src/sharedComponents/Forms/Spacer";

import ClientDrawer from "./ClientDrawer";

import styles from "./ClientProfile.module.css";

export default function Copilot() {
  const { enableAdvisorCoPilot } = useFlags();

  const { clientId } = useParams<{ clientId: ClientId }>();

  const [genAIPrompt, setGenAIPrompt] = useState<string | null>(null);

  return (
    <ClientDrawer curTab={"Copilot"}>
      {enableAdvisorCoPilot && (
        <>
          <Spacer />

          <div className={styles.copilotDiv}>
            <div>
              <div className={styles.copilotTitle}>Advisor CoPilot (Beta)</div>
              <div className={styles.copilotText}>
                The power of generative AI to help our clients
              </div>
            </div>

            <ButtonPrimary
              text="Generate a 30 day update email"
              onClick={() =>
                setGenAIPrompt("/advisors/chat/prepared/update/30-day")
              }
              variant="outlined"
              buttonType="primary"
            />
          </div>

          <Spacer />

          {genAIPrompt && (
            <GenAIChatWindow
              prompt={genAIPrompt}
              onClose={() => setGenAIPrompt(null)}
              clientId={clientId}
            />
          )}
        </>
      )}
    </ClientDrawer>
  );
}
