import React from "react";

import { Divider, Stack } from "@mui/material";

import {
  LargeBalanceOpportunity,
  LargeDepositOpportunity,
  OpportunityType,
} from "@fartherfinance/frontend/api/Opportunities/Types";

import OpportunityAccount from "../OpportunityAccount";
import OpportunityClient from "../OpportunityClient";
import OpportunityTimestamps from "../OpportunityTimestamps";
import OpportunityTransfer from "../OpportunityTransfer";

import styles from "./OpportunityCommonDetails.module.css";

interface OpportunityStandardCommonDetailsProps {
  opportunity: LargeBalanceOpportunity | LargeDepositOpportunity;
}

const OpportunityStandardCommonDetails: React.FC<
  OpportunityStandardCommonDetailsProps
> = ({ opportunity }) => {
  return (
    <>
      <Stack className={styles.content}>
        <OpportunityClient clientDetails={opportunity.clientDetails} />

        <OpportunityAccount account={opportunity.typeAttributes.value} />

        {opportunity.type === OpportunityType.enum.LARGE_DEPOSIT && (
          <OpportunityTransfer transfer={opportunity.typeAttributes.value} />
        )}

        <OpportunityTimestamps
          createdTs={opportunity.createdTs}
          updatedTs={opportunity.updatedTs}
        />
      </Stack>

      <Divider className={styles.divider} />
    </>
  );
};

export default OpportunityStandardCommonDetails;
