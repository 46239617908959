import React from "react";

import { Box, Fade } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";

import { LendingOfferingType } from "@fartherfinance/frontend/api/AltAssetsService/Types";

import { LendingMarketplacePath } from "@src/config/routing/RouterPaths";
import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import { lendingOfferingTypes } from "@src/multiCustodian/components/Marketplace/constants";
import OfferingTypeList from "@src/multiCustodian/components/Marketplace/OfferingTypeList/OfferingTypeList";
import { MarketplaceClientPathParams } from "@src/multiCustodian/components/Marketplace/types";
import PageHero from "@src/sharedComponents/PageHero/PageHero";
import TitleBlock from "@src/sharedComponents/PageHero/TitleBlock";

import offeringTypeListStyles from "@src/multiCustodian/components/Marketplace/OfferingTypeList/OfferingTypeList.module.css";

const LendingMarketplace: React.FC = () => {
  const { clientId } = useParams<MarketplaceClientPathParams>();
  const history = useHistory();

  const handleRedirectToOfferingsList = (
    offeringType: LendingOfferingType
  ): void => {
    history.push(
      `/Client/${clientId}/${LendingMarketplacePath}/${offeringType}`
    );
  };

  return (
    <BaseLayout>
      <PageHero start={<TitleBlock title="Lending" />} />

      <Fade in>
        <Box className={offeringTypeListStyles.wrapperClient}>
          <OfferingTypeList
            offeringTypes={lendingOfferingTypes}
            onOfferingTypeClick={handleRedirectToOfferingsList}
          />
        </Box>
      </Fade>
    </BaseLayout>
  );
};

export default LendingMarketplace;
