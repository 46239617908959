import React, { useState } from "react";

import SystemUpdateAltRoundedIcon from "@mui/icons-material/SystemUpdateAltRounded";
import { Modal as MUIModal } from "@mui/material";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import Modal from "@src/sharedComponents/Modal/Modal";
import SingleFileDropUpload from "@src/sharedComponents/SingleFileDropUpload/SingleFileDropUpload";

import styles from "./CustomPortfolioFileUploadModal.module.css";

const TEMPLATE_URL =
  "https://uat-multi-custodian-portfolio-models.s3.amazonaws.com/Templates/Farther+BlackRock+Upload+Template.csv";

interface Props {
  closeModal: () => void;
  onUpload: (file: File) => void;
}

const CustomPortfolioFileUploadModal = (props: Props): JSX.Element => {
  const [file, setFile] = useState<File | null>(null);
  return (
    <MUIModal open onClose={props.closeModal}>
      <Modal
        closeModal={props.closeModal}
        modalStyle={{ width: "520px", height: "265px" }}
      >
        <p className={styles.title}>Add File</p>

        <SingleFileDropUpload
          file={file}
          onChange={setFile}
          acceptedFileExtensions={[".csv"]}
        />

        <div className={styles.footer}>
          <div className={styles.downloadTemplateDiv}>
            <SystemUpdateAltRoundedIcon className={styles.downloadIcon} />
            <a
              href={TEMPLATE_URL}
              target="_blank"
              className={styles.downloadTemplateText}
              rel="noreferrer"
            >
              Download Template
            </a>
          </div>

          <div className={styles.modalButtonsDiv}>
            <Button
              variant={"outlined"}
              buttonType={"secondary"}
              text={"Cancel"}
              onClick={props.closeModal}
            />

            <Button
              disabled={!file}
              variant={"contained"}
              buttonType={"primary"}
              text={"Upload"}
              onClick={
                file
                  ? () => {
                      props.onUpload(file);
                      props.closeModal();
                    }
                  : () => {
                      return;
                    }
              }
              style={{ marginLeft: "15px" }}
            />
          </div>
        </div>
      </Modal>
    </MUIModal>
  );
};

export default CustomPortfolioFileUploadModal;
