import React from "react";

import CircleIcon from "@mui/icons-material/Circle";
import LockIcon from "@mui/icons-material/Lock";
import { Box, Typography } from "@mui/material";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import styles from "./DirectoryItem.module.css";

interface DirectoryItemProps {
  directoryName: string;
  directoryValue: string;
  isActive: boolean;
  isLocked: boolean;
  hasSignableDocuments: boolean;
  onClick: (name: string) => void;
}

export default function DirectoryItem({
  directoryName,
  directoryValue,
  isActive,
  isLocked,
  hasSignableDocuments,
  onClick,
}: DirectoryItemProps): JSX.Element {
  return (
    <Box
      className={toClassName(styles.container, {
        [styles.containerActive]: isActive,
      })}
      onClick={() => onClick(directoryValue)}
    >
      {isLocked && (
        <Tooltip
          tooltipText="You cannot upload files to this folder."
          placement="top"
        >
          <LockIcon className={styles.lockIcon} />
        </Tooltip>
      )}

      <Typography
        className={toClassName(styles.directoryName, {
          [styles.directoryNameActive]: isActive,
        })}
      >
        {directoryName}
      </Typography>

      {hasSignableDocuments && <CircleIcon className={styles.circleIcon} />}
    </Box>
  );
}
