import React from "react";

import { useParams } from "react-router-dom";

import { ClientId, TaxBudgetId } from "@fartherfinance/frontend/api/Types";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import LinkWithLocation from "@src/sharedComponents/LinkWithLocation/LinkWithLocation";

import styles from "./DetailTabs.module.css";

type Tab = "Usage" | "Budget Details";

interface Props {
  curTab: Tab;
  detailsPath: string;
  usagePath: string;
}

const TaxBudgetDetailTabs: React.FC<Props> = ({
  curTab,
  detailsPath,
  usagePath,
}) => {
  const { clientId, taxBudgetId } = useParams<{
    clientId: ClientId;
    taxBudgetId: TaxBudgetId;
  }>();

  return (
    <div className={styles.container}>
      <LinkWithLocation
        pathname={usagePath
          .replace(":clientId", clientId)
          .replace(":taxBudgetId", taxBudgetId)}
        style={{ textDecoration: "none" }}
      >
        <div
          className={toClassName(styles.tab, {
            [styles.selected]: curTab === "Usage",
          })}
        >
          Usage
        </div>
      </LinkWithLocation>

      <LinkWithLocation
        pathname={detailsPath
          .replace(":clientId", clientId)
          .replace(":taxBudgetId", taxBudgetId)}
        style={{ textDecoration: "none" }}
      >
        <div
          className={toClassName(styles.tab, {
            [styles.selected]: curTab === "Budget Details",
          })}
        >
          Budget Details
        </div>
      </LinkWithLocation>
    </div>
  );
};

export default TaxBudgetDetailTabs;
