import React from "react";

import { range } from "lodash";

import useGetScenariosV4 from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Projections/useGetScenariosV4";
import { PerformanceGroupId } from "@fartherfinance/frontend/api/Types";
import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import FlowerBullet from "@src/multiCustodian/components/PerformanceGroups/Projections/FlowerBullet";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import {
  isScenarioOptionLowerCase,
  scenarioDescriptions,
  ScenarioOptionLowerCase,
} from "@src/multiCustodian/pages/Dashboard/Performance_Groups/types";
import PerformanceIndicatorArrow from "@src/sharedComponents/PerformanceIndicatorArrow/PerformanceIndicatorArrow";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./ScenariosTable.module.css";

interface IncomingProps {
  groupId: PerformanceGroupId | null;
}

export default function ScenariosTableV4(props: IncomingProps) {
  const {
    color: {
      $iconDanger,
      $chartSegmentTwo,
      $chartSegmentFour,
      $chartSegmentFive,
      $chartSegmentThree,
      $chartSegmentOne,
      $chartSegmentSix,
    },
  } = useTheme();

  const auth = useRequestAuth();
  const groupScenarios = useGetScenariosV4(props.groupId, auth);

  const flowerBulletColors = [
    $iconDanger, // bloodOrange
    $chartSegmentTwo, // kleinBlue
    $chartSegmentFour, //steelBlue
    $chartSegmentFive, // powderBlue
    $chartSegmentThree, // sage
    $chartSegmentOne, // parakeetGreen
    $chartSegmentSix, //gold
  ] as const;

  if (groupScenarios.isLoading) {
    return (
      <div className={styles.scenariosTable}>
        {range(6).map((i) => {
          return <ScenarioRowLoading key={i} />;
        })}
      </div>
    );
  }

  if (groupScenarios.hasError) {
    return (
      <div className={styles.scenariosTable}>
        <div className={styles.noData}>Error</div>
      </div>
    );
  }

  const noData =
    Object.values(groupScenarios.data).filter((v) => typeof v === "number")
      .length === 0; //We only care about the properties that contain numeric values

  if (noData) {
    return (
      <div className={styles.scenariosTable}>
        <div className={styles.noData}>Scenarios data is not available</div>
      </div>
    );
  }

  return (
    <div className={styles.scenariosTable}>
      {Object.keys(groupScenarios.data)
        .filter(isScenarioOptionLowerCase)
        .filter((s) => groupScenarios.data[s] !== null)
        .filter((s) => scenarioDescriptions[s] !== undefined)
        .map((s, idx) => {
          const performance = groupScenarios.data[s];

          return (
            <ScenarioRow
              key={s}
              scenario={s}
              performance={performance}
              colorHex={`${[
                flowerBulletColors[idx] ? flowerBulletColors[idx] : "#ffffff",
              ].toString()}`}
            />
          );
        })}
    </div>
  );
}

interface ScenarioRowProps {
  scenario: ScenarioOptionLowerCase;
  performance: number | null;
  colorHex: string;
}

const ScenarioRow = (props: ScenarioRowProps): JSX.Element => {
  return (
    <div className={styles.scenariosRowContainer}>
      <div className={styles.scenariosRow}>
        <div className={styles.scenariosColumn1}>
          <FlowerBullet wH={30} colorHex={props.colorHex} />
        </div>

        <div className={styles.scenariosColumn2}>
          <div className={styles.scenarioHeader}>
            {scenarioDescriptions[props.scenario].title}
          </div>

          <p className={styles.scenarioDescription}>
            {scenarioDescriptions[props.scenario].desc}
          </p>
        </div>

        <div className={styles.scenariosColumn3}>
          <div className={styles.performance}>
            {
              <PerformanceIndicatorArrow
                style={{ marginRight: "5px" }}
                status={
                  props.performance === null
                    ? "neutral"
                    : props.performance >= 0
                    ? "positive"
                    : "negative"
                }
              />
            }
            <span className={styles.scenarioPerformanceText}>
              {props.performance}%
            </span>
          </div>
        </div>
      </div>

      <div className={styles.scenariosSeparator} />
    </div>
  );
};

const ScenarioRowLoading = (): JSX.Element => {
  return (
    <div className={styles.scenariosRowContainer}>
      <Skeleton width={"100%"} height={90} style={{ transform: "none" }} />

      <div className={styles.scenariosSeparator} />
    </div>
  );
};
