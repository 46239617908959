import { tabClasses } from "@mui/material/Tab";
import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

const MIN_HEIGHT = 33;

export const useStyles = makeStyles()((theme) => ({
  tabsRoot: {
    marginBottom: theme.spacing(pxToSpacing(32)),
    minHeight: MIN_HEIGHT,
  },
  tabsIndicator: {
    backgroundColor: theme.appearance.$backgroundSelected,
  },
  tabsContainer: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.appearance.$borderBold,
  },
  tab: {
    minWidth: 0,
    minHeight: MIN_HEIGHT,
    padding: 0,
    marginRight: theme.spacing(pxToSpacing(40)),

    color: theme.colors.primary.tints.navy50,
    fontFamily: theme.typography.fontSecondary,
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: "19px",
    textTransform: "none",

    [`&.${tabClasses.selected}`]: {
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}));
