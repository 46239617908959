import React from "react";

import { useSelector } from "react-redux";

import { ADVISOR_NOTE_FOR_REBALANCING } from "../../const";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import Modal from "@src/sharedComponents/Modal/Modal";
import { State } from "@src/store";

import styles from "./modal.module.css";

interface Props {
  closeModal: () => void;
  onConfirm: () => void;
}

const ApplyToAccountsModal = (props: Props): JSX.Element => {
  const isAdvisor = useSelector(
    (state: State) => state.main_Reducer.user.isAdvisor
  );

  return (
    <Modal closeModal={props.closeModal} modalStyle={{ width: "520px" }}>
      <p className={styles.modalHeader}>Apply to Accounts</p>

      <p className={styles.modalText}>
        {`Beginning with the next business day, Farther will begin automatically
        evaluating this account for rebalancing on a daily basis and executing
        trades as necessary. ${isAdvisor ? ADVISOR_NOTE_FOR_REBALANCING : ""}`}
      </p>

      <div className={styles.modalButtonsDiv}>
        <Button
          variant={"outlined"}
          buttonType={"secondary"}
          text={"Cancel"}
          onClick={props.closeModal}
        />

        <Button
          variant={"contained"}
          buttonType={"primary"}
          text={"Confirm"}
          onClick={props.onConfirm}
          style={{ marginLeft: "20px" }}
        />
      </div>
    </Modal>
  );
};

export default ApplyToAccountsModal;
