import React, { useEffect, useMemo, useState } from "react";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import { head, orderBy } from "lodash";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { Link, useParams } from "react-router-dom";

import { UNKNOWN_ACCOUNT } from "@fartherfinance/frontend/api/Accounts/utilities/accountUtil";
import useCreateTrigger from "@fartherfinance/frontend/api/CashCycle/hooks/useCreateTrigger";
import {
  TargetGoalWaterfallAccount,
  UserAmountType,
} from "@fartherfinance/frontend/api/CashCycle/requests/getCurrentTrigger";
import { IncomingTriggerBody } from "@fartherfinance/frontend/api/CashCycle/requests/postTrigger";
import useClientAccounts from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAccounts";
import {
  AccountDetails,
  AccountSubDetails,
} from "@fartherfinance/frontend/api/Dashboard/requests/getClientDashboard";
import { ClientId, FartherAccountId } from "@fartherfinance/frontend/api/Types";
import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import {
  cronDayStringToDayNumberString,
  CUSTOM_DAY_OF_THE_MONTH,
  dropdownOptions,
  FIXED_AMOUNT_TYPE,
  makeScheduleBody,
  ScheduleDateString,
  scheduleTypeToOptionString,
  TransferDay,
} from "../shared";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import formatAmount from "@src/multiCustodian/pages/Dashboard/Dashboard_Components/DashboardForms/formatters/formatAmount";
import isValidLocaleNumber from "@src/multiCustodian/pages/Dashboard/Dashboard_Components/DashboardForms/validators/isValidLocaleNumber";
import numberGTEMin from "@src/multiCustodian/pages/Dashboard/Dashboard_Components/DashboardForms/validators/numberGTEMin";
import numberLTEMax from "@src/multiCustodian/pages/Dashboard/Dashboard_Components/DashboardForms/validators/numberLTEMax";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import Switch from "@src/sharedComponents/Switch/Switch";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import { fundingDaysInMonth, isValidAccountTypeForCashMgmt } from "./shared";
import {
  MAX_AMOUNT,
  MAX_AMOUNT_USD,
  MIN_AMOUNT,
  MIN_AMOUNT_USD,
} from "./validators/validAmount";

import styles from "./SetupRecurringTransfersModal.module.css";

const hasTargetGoal = (accountDetails: AccountSubDetails): boolean => {
  return (
    accountDetails.targetGoal !== null && accountDetails.targetDate !== null
  );
};

type WaterfallMethod = "waterfall";
const WATERFALL_METHOD_TYPE: WaterfallMethod = "waterfall";

type NonEmptyArray<T> = readonly [T, ...T[]];

interface WaterfallAccount {
  accountId: FartherAccountId;
}

const AmountField = "Amount";
const FrequencyField = "Frequency";
const TransferDayField = "TransferDay";

type Form = {
  [AmountField]: string | undefined;
  [FrequencyField]: { label: ScheduleDateString } | undefined;
  [TransferDayField]: { label: TransferDay } | undefined;
};

const isNonEmptyArray = (
  arr: readonly WaterfallAccount[]
): arr is NonEmptyArray<WaterfallAccount> => {
  return arr.length > 0;
};

interface ExtendedWaterfallAccount {
  accountName: string;
  accountId: FartherAccountId;
  isOn: boolean;
}

interface Props {
  closeModal: () => void;
  setIsMutating: (isMutating: boolean) => void;
  isMutating: boolean;
  currentAmount: string;
  currentFrequency:
    | "month_first"
    | "month_last"
    | "month_split"
    | "custom"
    | null;
  currentCustomDay: string | null;
  currentWaterfallAccounts: TargetGoalWaterfallAccount[] | null;
  accounts: AccountDetails[];
}

const WaterfallFundingMethodSetup = (props: Props): JSX.Element => {
  const methods = useForm<Form>({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      Amount: props.currentAmount,
      Frequency:
        props.currentFrequency === null
          ? undefined
          : { label: scheduleTypeToOptionString(props.currentFrequency) },
      TransferDay:
        props.currentCustomDay === null
          ? undefined
          : cronDayStringToDayNumberString(props.currentCustomDay) ?? undefined,
    },
  });

  const {
    control,
    resetField,
    getValues,
    setError,
    clearErrors,
    formState: { isValid, isDirty, errors },
  } = methods;

  const frequency = useWatch({ control, name: FrequencyField });

  useEffect(() => {
    if (frequency === undefined) {
      return;
    }

    if (frequency.label !== CUSTOM_DAY_OF_THE_MONTH) {
      resetField(TransferDayField);
    }
  }, [frequency, resetField]);

  const [isMutating, setIsMutating] = useState<boolean>(false);

  const [orderedAccounts, setOrderedAccounts] = useState<
    ExtendedWaterfallAccount[]
  >([]);

  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const statusNotification = useStatusNotification();

  const auth = useRequestAuth();
  const accounts = useClientAccounts(clientId, auth);
  const createTrigger = useCreateTrigger(clientId, auth);

  useEffect(() => {
    if (accounts.hasError) {
      statusNotification("Error fetching accounts", "Error");
    }
  }, [accounts.hasError, statusNotification]);

  useEffect(() => {
    if (props.currentWaterfallAccounts === null) {
      const validFartherAccounts = props.accounts.filter(
        (acct) =>
          acct.accountDetails.operationalState === "Open" &&
          isValidAccountTypeForCashMgmt(acct) &&
          hasTargetGoal(acct.accountDetails)
      );

      const accountObjects = validFartherAccounts.map((acct) => ({
        accountId: acct.accountId,
        isOn: true,
        accountName: acct.accountDetails.displayName,
      }));

      setOrderedAccounts(accountObjects);
    } else {
      // copy current waterfall account settings
      const validFartherAccounts = props.accounts.filter(
        (acct) =>
          acct.accountDetails.operationalState === "Open" &&
          isValidAccountTypeForCashMgmt(acct) &&
          hasTargetGoal(acct.accountDetails)
      );

      const accountsFromCurrentSettings = props.currentWaterfallAccounts.map(
        (acct) => ({
          accountId: acct.accountId,
          isOn: true,
          accountName:
            props.accounts.find((a) => a.accountId === acct.accountId)
              ?.accountDetails.displayName ?? UNKNOWN_ACCOUNT,
        })
      );

      const validAccountsNotInCurrentSettings = validFartherAccounts
        .filter((vfa) => {
          return !accountsFromCurrentSettings.find(
            (acct) => vfa.accountId === acct.accountId
          );
        })
        .map((acct) => ({
          accountId: acct.accountId,
          isOn: false,
          accountName: acct.accountDetails.displayName,
        }));

      setOrderedAccounts([
        ...accountsFromCurrentSettings,
        ...validAccountsNotInCurrentSettings,
      ]);
    }
  }, [props, accounts, isMutating]);

  const validAccountsMissingTargetGoal = useMemo(() => {
    return props.accounts.filter(
      (acct) =>
        acct.accountDetails.operationalState === "Open" &&
        isValidAccountTypeForCashMgmt(acct) &&
        !hasTargetGoal(acct.accountDetails)
    );
  }, [props.accounts]);

  const toggleAccount = (accountId: FartherAccountId) => {
    const newToggles = orderedAccounts.map((acct) => {
      const newAccount = { ...acct };
      if (acct.accountId === accountId) {
        newAccount.isOn = !newAccount.isOn;
      }
      return newAccount;
    });

    const reorder = orderBy(newToggles, (el) => el.isOn, "desc");

    setOrderedAccounts(reorder);
  };

  useEffect(() => {
    const atLeastOneEnabled =
      orderedAccounts.filter((acct) => acct.isOn).length > 0;

    if (atLeastOneEnabled === false) {
      if (errors.root === undefined) {
        setError("root", {
          type: "custom",
          message: "At least one account must be enabled",
        });
      }
    } else if (errors.root !== undefined) {
      clearErrors("root");
    }
  }, [clearErrors, errors.root, orderedAccounts, setError]);

  const swapSpots = (index1: number, index2: number) => {
    const element1 = orderedAccounts[index1];
    const element2 = orderedAccounts[index2];

    if (element1 === undefined || element2 === undefined) {
      return;
    }

    const newToggles = orderedAccounts.map((acct, i) => {
      const tmp1 = { ...orderedAccounts[index1] };
      const tmp2 = { ...orderedAccounts[index2] };

      if (i === index1) {
        return tmp2;
      } else if (i === index2) {
        return tmp1;
      }

      return { ...acct };
    });

    const reorder = orderBy(newToggles, (el) => el.isOn, "desc");

    setOrderedAccounts(reorder);
  };

  const orderedAccountsSortedByOnOff = orderBy(
    orderedAccounts,
    (el) => el.isOn,
    "desc"
  );

  const atLeastOneEnabled =
    orderedAccounts.filter((acct) => acct.isOn).length > 0;

  const isDisabled = !isValid || !isDirty || !atLeastOneEnabled;

  const onUpdate = async () => {
    if (isDisabled) {
      return;
    }

    const values = getValues();

    if (
      !values.Frequency?.label ||
      values.Amount === undefined ||
      (values.Frequency.label === CUSTOM_DAY_OF_THE_MONTH &&
        !values.TransferDay?.label)
    ) {
      return;
    }

    try {
      setIsMutating(true);
      props.setIsMutating(true);

      const amountBody: UserAmountType = {
        type: FIXED_AMOUNT_TYPE,
        amount: parseFloat(values.Amount.replace(/,/g, "")),
      };

      const waterfallOrderedAccounts = orderedAccounts
        .filter((acct) => acct.isOn)
        .map((acct) => ({
          accountId: acct.accountId,
        }));

      if (!isNonEmptyArray(waterfallOrderedAccounts)) {
        return;
      }

      const methodBody: IncomingTriggerBody["method"] = {
        type: WATERFALL_METHOD_TYPE,
        accounts: waterfallOrderedAccounts,
      };

      const body: IncomingTriggerBody = {
        schedule: makeScheduleBody(
          values.Frequency.label,
          values.TransferDay?.label
        ),
        amount: amountBody,
        method: methodBody,
      };

      await createTrigger({
        clientId: clientId,
        body: body,
      });

      statusNotification("Funding settings updated", "Success");
    } catch (e) {
      if (!accountIsMissingTargetGoalError(e)) {
        statusNotification("Error updating settings", "Error");
      }
    } finally {
      setIsMutating(false);
      props.setIsMutating(false);
      props.closeModal();
    }
  };

  const accountIsMissingTargetGoalError = (e: any): boolean => {
    // Will look through response error to see if the target goal is the reason
    // Will return true and display error notification if can successfully parse, otherwise returns false
    if (e?.response?.data?.indexOf("not have a goal defined") >= 0) {
      const errorResStringParts = e.response.data.split(" ");
      if (errorResStringParts.length >= 3) {
        const accountIdWithoutTargetGoal = errorResStringParts[2];
        const accountWithoutTargetGoal = orderedAccounts.find(
          (acct) => acct.accountId === accountIdWithoutTargetGoal
        );
        if (accountWithoutTargetGoal) {
          statusNotification(
            `'${accountWithoutTargetGoal.accountName}' needs a target goal`,
            "Error"
          );
          return true;
        }
      }
    }

    return false;
  };

  if (props.isMutating) {
    return <></>;
  }

  return (
    <div className={styles.methodContainer}>
      <FormProvider {...methods}>
        <FormTextField
          name={AmountField}
          label="Amount"
          placeholder="1,500"
          required="Must not be empty"
          startAdornment={<AttachMoneyIcon className={styles.dollarSignIcon} />}
          helperText={`Needs to be greater than or equal to ${MIN_AMOUNT_USD}`}
          valueFormatterOnBlur={(e) => {
            //No decimals
            return formatAmount(head(e.split(".")));
          }}
          valueFormatterOnChange={(e) => {
            //No decimals
            return formatAmount(head(e.split(".")));
          }}
          rules={{
            validate: {
              isValidNumber: (e) => isValidLocaleNumber(e, true),
              atLeastMin: (e) => {
                return numberGTEMin(e, MIN_AMOUNT)
                  ? true
                  : `Amount must be at least ${MIN_AMOUNT_USD}`;
              },
              atMostMax: (e) => {
                return numberLTEMax(e, MAX_AMOUNT)
                  ? true
                  : `Amount must not exceed ${MAX_AMOUNT_USD}`;
              },
            },
          }}
        />

        <Spacer />

        <FormDropdownField
          name={FrequencyField}
          placeholder={"Select a frequency..."}
          label={"Frequency"}
          values={dropdownOptions.map((option) => ({
            label: option,
          }))}
          disableSearch={true}
          required={"Must not be empty"}
        />

        {frequency === undefined ||
          (frequency.label === CUSTOM_DAY_OF_THE_MONTH && (
            <>
              <Spacer />

              <FormDropdownField
                name={TransferDayField}
                placeholder={
                  frequency?.label === CUSTOM_DAY_OF_THE_MONTH
                    ? "1 - 28"
                    : "Not required"
                }
                label={"Transfer Day"}
                values={fundingDaysInMonth.map((day) => ({
                  label: day,
                }))}
                disableSearch={true}
                disabled={
                  frequency !== undefined &&
                  frequency.label !== CUSTOM_DAY_OF_THE_MONTH
                }
                required={
                  frequency?.label === CUSTOM_DAY_OF_THE_MONTH
                    ? "Must not be empty"
                    : false
                }
              />
            </>
          ))}

        <Spacer />

        <div className={styles.accountsLabel}>
          Accounts
          <span className={styles.accountsLabelSpan}>
            {orderedAccountsSortedByOnOff.length > 4
              ? "(scroll to view all)"
              : ""}
          </span>
        </div>

        <div className={styles.scrollDiv}>
          {orderedAccountsSortedByOnOff.map((acct, i) => {
            return (
              <WaterfallAccountRow
                key={acct.accountId}
                waterfallAccount={acct}
                orderedAccounts={orderedAccounts}
                index={i}
                swapSpots={swapSpots}
                toggleAccount={toggleAccount}
              />
            );
          })}

          {validAccountsMissingTargetGoal.map((acct) => {
            return (
              <WaterfallAccountRowNoTargetGoal
                key={acct.accountId}
                clientId={clientId}
                accountId={acct.accountId}
                accountName={acct.accountDetails.displayName}
              />
            );
          })}
        </div>
      </FormProvider>

      <div className={styles.textOfNote}>
        *Tax advantaged accounts are not supported at this time
      </div>

      <div className={styles.footer}>
        <div className={styles.modalButtonsDiv}>
          <Button
            variant={"outlined"}
            buttonType={"secondary"}
            text={"Cancel"}
            onClick={props.closeModal}
          />

          <Button
            disabled={isDisabled}
            variant={"contained"}
            buttonType={"primary"}
            text={"Setup"}
            onClick={() => onUpdate()}
            style={{ marginLeft: "15px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default WaterfallFundingMethodSetup;

interface WaterfallAccountRowProps {
  waterfallAccount: ExtendedWaterfallAccount;
  orderedAccounts: ExtendedWaterfallAccount[];
  index: number;
  swapSpots: (index1: number, index2: number) => void;
  toggleAccount: (accountId: FartherAccountId) => void;
}

const WaterfallAccountRow = ({
  waterfallAccount,
  orderedAccounts,
  index,
  swapSpots,
  toggleAccount,
}: WaterfallAccountRowProps): JSX.Element => {
  const {
    color: { $icon, $iconSubtle, $text, $textSubtle },
  } = useTheme();

  const keyboardArrowStyle =
    index === orderedAccounts.length - 1 ||
    orderedAccounts[index + 1].isOn === false
      ? { color: $iconSubtle, cursor: "default" }
      : { color: $icon, cursor: "pointer" };

  return (
    <div className={styles.waterfallRowContainer}>
      <div className={styles.waterfallRowLeft}>
        {waterfallAccount.isOn ? (
          <>
            <KeyboardArrowDownRoundedIcon
              className={styles.chevronDownIcon}
              style={keyboardArrowStyle}
              onClick={() => swapSpots(index, index + 1)}
            />

            <KeyboardArrowUpRoundedIcon
              className={styles.chevronUpIcon}
              style={{
                color: index === 0 ? $iconSubtle : $icon,
                cursor: index === 0 ? "default" : "pointer",
              }}
              onClick={() => swapSpots(index - 1, index)}
            />
          </>
        ) : (
          <div className={styles.takeUpChevronsSpace} />
        )}

        <p
          className={styles.accountName}
          style={{ color: waterfallAccount.isOn ? $text : $textSubtle }}
        >
          {waterfallAccount.accountName}
        </p>
      </div>

      <div className={styles.waterfallRowRight}>
        <p className={styles.onOffText}>
          {waterfallAccount.isOn ? "On" : "Off"}
        </p>

        <Switch
          on={waterfallAccount.isOn}
          onClick={() => toggleAccount(waterfallAccount.accountId)}
        />
      </div>
    </div>
  );
};

interface WaterfallAccountRowNoTargetGoalProps {
  clientId: ClientId;
  accountId: FartherAccountId;
  accountName: string;
}

const WaterfallAccountRowNoTargetGoal = ({
  clientId,
  accountId,
  accountName,
}: WaterfallAccountRowNoTargetGoalProps): JSX.Element => {
  return (
    <div className={styles.waterfallRowContainer}>
      <div className={styles.waterfallRowLeft}>
        <div className={styles.takeUpChevronsSpace} />

        <div className={styles.accountNameNoTargetGoal}>{accountName}</div>

        <Tooltip
          tooltipText={
            <p className={styles.tooltipText}>
              To include this account in a waterfall transfer, the account needs
              to have a target goal and goal date. You can set a target goal and
              date for this account
              <Link
                to={`/Client/${clientId}/Accounts/Managed/${accountId}/Settings/SetTargetGoal`}
              >
                <span className={styles.tooltipLink}>here</span>
              </Link>
              .
            </p>
          }
          placement="top"
        >
          <InfoOutlinedIcon className={styles.infoIconNoTargetGoal} />
        </Tooltip>
      </div>

      <div className={styles.waterfallRowRight}>
        <div className={styles.onOffTextNoTargetGoal}>Off</div>

        <Switch disabled={true} on={false} onClick={() => undefined} />
      </div>
    </div>
  );
};
