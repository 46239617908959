import React, { PropsWithChildren } from "react";

import BackButton from "@src/multiCustodian/components/Client/BackButton";
import ExitButton from "@src/multiCustodian/components/Client/ExitButton";

import styles from "./BackAndExitButtonWrapper.module.css";

interface Props {
  backButtonOnClick: () => void;
  exitButtonOnClick?: () => void;
}

const BackAndExitButtonWrapper: React.FC<PropsWithChildren<Props>> = (
  props
) => {
  return (
    <div className={styles.container}>
      <div className={styles.backBtnContainer}>
        <BackButton onClick={props.backButtonOnClick} />

        <ExitButton overrideBackRoute={props.exitButtonOnClick} />
      </div>

      {props.children}
    </div>
  );
};

export default BackAndExitButtonWrapper;
