import React from "react";

import useMatchPerformanceGroupV4 from "@fartherfinance/frontend/api/PerformanceGroups/hooks/useMatchPerformanceGroupV4";
import { FartherAccountId } from "@fartherfinance/frontend/api/Types";

import PerformanceGroupAccountActivityV4 from "@src/multiCustodian/components/PerformanceGroups/Activity/ActivityV4";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

import styles from "./Activity.module.css";

interface Props {
  accountId: FartherAccountId;
}

export default function ActivityV4({ accountId }: Props): JSX.Element {
  const auth = useRequestAuth();
  const accountGroup = useMatchPerformanceGroupV4(accountId, auth);

  return (
    <>
      <div className={styles.heading}>Account Activity</div>

      <PerformanceGroupAccountActivityV4
        accountId={accountId}
        performanceGroupId={accountGroup.data ?? null}
      />
    </>
  );
}
