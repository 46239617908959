import React from "react";

import Spacer from "@src/sharedComponents/Forms/Spacer";
import BorderedList from "@src/sharedComponents/List/BorderedList";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import Subheader from "./Subheader";

import styles from "./SMADetails.module.css";

const SMADetailsBodyLoading = (): JSX.Element => {
  return (
    <div>
      <Skeleton height={27} />

      <Skeleton height={80} />

      <Subheader />

      <Spacer verticalSpacing="24px" />

      <div className={styles.sectionTitle}>Investment Details</div>

      <BorderedList
        rows={[
          {
            left: "Investment Manager",
            right: <Skeleton height={18} width={100} />,
          },
          {
            left: "Minimum Investment",
            right: <Skeleton height={18} width={100} />,
          },
          {
            left: "Custodian",
            right: <Skeleton height={18} width={100} />,
          },
          {
            left: "Pricing",
            right: <Skeleton height={18} width={100} />,
          },
        ]}
      />

      <Spacer verticalSpacing="24px" />

      <div className={styles.sectionTitle}>Investment Manager Contact Info</div>

      <BorderedList
        rows={[
          { left: "Name", right: <Skeleton height={18} width={100} /> },
          {
            left: "Email",
            right: (
              <div className={styles.textSubtle}>
                <Skeleton height={18} width={100} />
              </div>
            ),
          },
          {
            left: "Phone",
            right: (
              <div className={styles.textSubtle}>
                <Skeleton height={18} width={100} />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default SMADetailsBodyLoading;
