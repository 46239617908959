import React from "react";

import { useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import LinkButton from "@src/sharedComponents/LinkButton/LinkButton";

import styles from "./FlowFooter.module.css";

interface Props {
  onClickBack: () => void;
  onClickNext: () => void;
  nextButtonText: string;
  nextButtonIsDisabled: boolean;
  showCancelButton: boolean;
  showBackButton: boolean;
  showNextButton: boolean;
}

const FlowFooter = ({
  onClickBack,
  onClickNext,
  nextButtonText,
  nextButtonIsDisabled,
  showCancelButton,
  showBackButton,
  showNextButton,
}: Props): JSX.Element => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.innerLeft}>
          {showCancelButton && (
            <LinkButton
              variant={"outlined"}
              buttonType={"secondary"}
              text={"Cancel"}
              to={`/Client/${clientId}/Sharing`}
            />
          )}
        </div>

        <div className={styles.innerRight}>
          {showBackButton && (
            <Button
              variant={"outlined"}
              buttonType={"secondary"}
              text={"Back"}
              onClick={onClickBack}
              style={{ marginLeft: "15px" }}
            />
          )}

          {showNextButton && (
            <Button
              variant={"contained"}
              buttonType={"primary"}
              text={nextButtonText}
              onClick={onClickNext}
              style={{ marginLeft: "15px" }}
              disabled={nextButtonIsDisabled}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FlowFooter;
