import React, { useCallback } from "react";

import AddIcon from "@mui/icons-material/Add";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { InputAdornment, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import AdvisorChips from "../../SharedComponents/AdvisorChip";
import searchIcon from "@src/assets/svg/search_icon.svg";
import useIsAdmin from "@src/multiCustodian/components/Advisor/utils/useIsAdmin";
import Select from "@src/sharedComponents/Select/Select";
import SelectItem from "@src/sharedComponents/Select/SelectItem";
import useResetTablePaginationPage from "@src/sharedComponents/Table/useResetTablePaginationPage";
import TextInput from "@src/sharedComponents/TextInput/TextInput";
import { State } from "@src/store";

import styles from "./Header.module.css";

const filterOptions = ["Active Models", "Archived Models"] as const;
export type FilterOption = typeof filterOptions[number];

const actionOptionsAdvisor = ["Create Custom Model"] as const;

const actionOptionsAdmin = [
  ...actionOptionsAdvisor,
  "Create New Partner",
  "Update FAM/Partner Models",
] as const;

type Props = {
  filter: FilterOption;
  setFilter: (o: FilterOption) => void;
  tableSearch: string;
  setTableSearch: (s: string) => void;
};

const Header = (props: Props): JSX.Element => {
  const history = useHistory();

  const {
    color: { $icon },
  } = useTheme();

  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));

  const isAdmin = useIsAdmin(advisorId);

  const resetTablePaginationPage = useResetTablePaginationPage();

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      resetTablePaginationPage();
      props.setTableSearch(e.target.value);
    },
    [props, resetTablePaginationPage]
  );

  return (
    <div className={styles.header}>
      <TextInput
        label={""}
        value={props.tableSearch}
        onChange={handleOnChange}
        placeholder={"Search models..."}
        style={{ width: "280px", marginRight: "20px" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position={"start"}>
              <img src={searchIcon} />
            </InputAdornment>
          ),
        }}
      />

      <AdvisorChips />

      <Stack
        direction={"row"}
        gap={"16px"}
        alignItems={"center"}
        flexGrow={1}
        justifyContent={"flex-end"}
      >
        <Select
          className={styles.select}
          options={filterOptions}
          value={props.filter}
          onChange={props.setFilter}
          renderValue={(v) => v}
        />

        <Select
          className={styles.select}
          options={
            isAdmin.data === undefined
              ? actionOptionsAdvisor
              : isAdmin.data
              ? actionOptionsAdmin
              : actionOptionsAdvisor
          }
          value={""}
          displayEmpty
          renderOption={(option) => {
            const url = (() => {
              switch (option) {
                case "Create Custom Model":
                  return "/Advisor/Investments/ModelMarketplace/Custom/Create";
                case "Create New Partner":
                  return "/Advisor/Investments/ModelMarketplace/NewPartner";
                case "Update FAM/Partner Models":
                  return "/Advisor/Investments/ModelMarketplace/Update";

                default:
                  const _x: never = option;
                  throw new Error(
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    `[Model Marketplace Header]: Missing url due to unknown option: ${_x}`
                  );
              }
            })();

            if (option === "Update FAM/Partner Models") {
              return (
                <SelectItem
                  onClick={() =>
                    history.push({
                      pathname: "/Advisor/Investments/ModelMarketplace/Update",
                      state: history.location.state,
                    })
                  }
                  key={option}
                  value={option}
                  label={option}
                  postAdornment={
                    <ChevronRightIcon
                      style={{ color: $icon }}
                      className={styles.icon}
                    />
                  }
                />
              );
            }

            return (
              <SelectItem
                onClick={() =>
                  history.push({
                    pathname: url,
                    state: history.location.state,
                  })
                }
                key={option}
                value={option}
                label={option}
                preAdornment={
                  <AddIcon style={{ color: $icon }} className={styles.icon} />
                }
              />
            );
          }}
          onChange={() => undefined}
          renderValue={(v) => {
            if (v === "") {
              return "Model Actions";
            }

            return v;
          }}
        />
      </Stack>
    </div>
  );
};

export default Header;
