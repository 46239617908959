import React from "react";

import { Box, Fade, Grow, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import { useFormContext } from "react-hook-form";

import { formatCurrency } from "@fartherfinance/frontend/formatting/numbers";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useClientName from "@src/multiCustodian/hooks/useClientName";
import AlertInline from "@src/sharedComponents/AlertInline/AlertInline";

import SummaryItem from "./SummaryItem/SummaryItem";

import styles from "./AdvisorLiquidation.module.css";

import type { FormData } from "../AdvisorLiquidation";

interface LiquidationDetailsProps {
  errorMessage: string | null;
  onClose: () => void;
  onContinue: () => void;
}

const LiquidationDetails = ({
  errorMessage,
  onClose,
  onContinue,
}: LiquidationDetailsProps): JSX.Element => {
  const { getValues } = useFormContext<FormData>();
  const clientName = useClientName();

  const formValues = getValues();

  return (
    <Fade in>
      <Stack>
        <Typography className={styles.title}>
          Verify details of the request to raise cash
        </Typography>
        <Typography className={styles.description}>
          Once a request to raise cash has been made, Farther will evaluate
          whether there is already sufficient cash in the account. If trading is
          required to raise cash, then a rebalancing proposal will be generated
          the next business day.
        </Typography>

        <Stack sx={{ my: "40px" }} gap="8px">
          <Typography className={styles.details}>
            Request to Raise Cash Details
          </Typography>

          <Box className={styles.detailsSummary}>
            <SummaryItem label="Client" value={clientName?.fullName} />
            <SummaryItem label="Account" value={formValues.account.label} />
            <SummaryItem
              label="Amount"
              value={formatCurrency(formValues.amount)}
            />
            <SummaryItem
              label="Request Date"
              value={format(new Date(), "M/dd/yy")}
            />
          </Box>
        </Stack>

        {errorMessage && (
          <Grow in>
            <Box sx={{ mb: "40px" }}>
              <AlertInline message={errorMessage} serverity="error" />
            </Box>
          </Grow>
        )}

        <Stack direction="row" gap="16px" alignSelf="flex-end">
          <Button
            variant="outlined"
            buttonType="primary"
            text="Cancel"
            onClick={onClose}
          />
          <Button
            variant="contained"
            buttonType="primary"
            text="Raise Cash"
            onClick={onContinue}
          />
        </Stack>
      </Stack>
    </Fade>
  );
};

export default LiquidationDetails;
