import React from "react";

import { useLocation } from "react-router-dom";

import { TaxBudget } from "@fartherfinance/frontend/api/InvestmentModel/Types";

import EmptyStateText from "../EmptyText/EmptyText";
import ListHeading from "../ListHeading/ListHeading";

import Row from "./Row";

interface Props {
  budget?: TaxBudget;
}

const CurrentBudget: React.FC<Props> = ({ budget }) => {
  const { pathname } = useLocation();

  return (
    <div>
      <ListHeading
        variant={pathname.startsWith("/Advisor") ? "secondary" : "primary"}
      >
        Current Budget
      </ListHeading>

      {budget && <Row budget={budget} />}

      {!budget && <EmptyStateText>No current tax budget set</EmptyStateText>}
    </div>
  );
};

export default CurrentBudget;
