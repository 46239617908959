import React from "react";

import { SentInvitation } from "@fartherfinance/frontend/api/Sharing/Types";

import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";
import BorderBoxBody from "@src/sharedComponents/BorderBox/BorderBoxBody";
import BorderBoxHeader from "@src/sharedComponents/BorderBox/BorderBoxHeader";

import Header from "./Header/Header";
import CollaboratorTableRow from "./Row/CollaboratorTableRow";

import styles from "./CollaboratorsTable.module.css";

interface Props {
  invitations: SentInvitation[];
}

const CollaboratorsTable: React.FC<Props> = ({ invitations }) => {
  return (
    <BorderBox>
      <BorderBoxHeader className={styles.borderBoxHeader} start={<Header />} />

      <BorderBoxBody className={styles.borderBoxBody}>
        {invitations.map((invitation) => (
          <CollaboratorTableRow
            key={invitation.invitationId}
            invitation={invitation}
          />
        ))}
      </BorderBoxBody>

      <BorderBoxHeader className={styles.borderBoxFooter} start={<></>} />
    </BorderBox>
  );
};

export default CollaboratorsTable;
