import React, { useCallback, useMemo } from "react";

import {
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox as MuiCheckbox,
  styled,
  SxProps,
} from "@mui/material";

import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

interface Props
  extends Omit<CheckboxProps, "defaultChecked" | "checked" | "onChange"> {
  label: React.ReactNode;
  checked: boolean;
  onChange: (checked: boolean) => void;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  labelSx?: SxProps;
  checkboxStyle?: React.CSSProperties;
  labelMargin?: number;
}

export default function Checkbox({
  checked,
  onChange,
  error,
  helperText,
  label,
  disabled,
  checkboxStyle,
  ...props
}: Props): JSX.Element {
  const {
    color: {
      $backgroundSelectedBold,
      $borderBold,
      $text,
      $textDanger,
      $textDisabled,
      $border,
    },
  } = useTheme();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.checked);
    },
    [onChange]
  );

  const labelSx = useMemo(() => {
    return {
      "& .MuiFormControlLabel-label": {
        color: $text,
        fontFamily: "var(--fakt)",
        fontSize: "14px",
        fontWeight: 400,
        marginLeft: props.labelMargin ? `${props.labelMargin}px` : "8px",
      },
    };
  }, [$text, props.labelMargin]);

  const labelDisabledSx = useMemo(() => {
    return {
      "& .MuiFormControlLabel-label.Mui-disabled": {
        color: $textDisabled,
        fontFamily: "var(--fakt)",
        fontSize: "14px",
        fontWeight: 400,
        marginLeft: props.labelMargin ? `${props.labelMargin}px` : "8px",
      },
    };
  }, [$textDisabled, props.labelMargin]);

  const StyledCheckbox = useMemo(
    () =>
      styled(MuiCheckbox)(() => ({
        color: $borderBold,
        [`&.Mui-checked`]: {
          color: $backgroundSelectedBold,
        },
        [`&.Mui-disabled`]: {
          color: $border,
        },
      })),
    [$borderBold, $backgroundSelectedBold, $border]
  );

  return (
    <FormControl>
      <FormGroup>
        <FormControlLabel
          control={
            <StyledCheckbox
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
              disabled={disabled ?? false}
              sx={{
                padding: "2px 2px 2px 2px",
                marginLeft: "8px",
                ...checkboxStyle,
              }}
            />
          }
          label={label}
          sx={{
            ...props.labelSx,
            ...(disabled ? labelDisabledSx : labelSx),
          }}
        />
      </FormGroup>

      {error === true ? (
        <div style={{ color: $textDanger, fontSize: 14 }}>{helperText}</div>
      ) : null}
    </FormControl>
  );
}
