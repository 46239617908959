import React from "react";

import { Divider, Stack, Typography } from "@mui/material";

import { AccountDetails } from "@fartherfinance/frontend/api/Dashboard/requests/getClientDashboard";
import { TradingGroup } from "@fartherfinance/frontend/api/TradingGroups/requests/getTradingGroup";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import { PLACEHOLDER_SIGN } from "@src/yellowstone/modules/shared";

import styles from "./TradingGroupSummary.module.css";

interface Props {
  tradingGroup: TradingGroup;
  accounts: AccountDetails[];
}

const TradingGroupSummary: React.FC<Props> = ({ accounts, tradingGroup }) => {
  return (
    <Stack className={styles.container}>
      <Typography
        className={toClassName(
          styles.text,
          styles.textPrimary,
          styles.groupNameRow
        )}
      >
        {tradingGroup.displayName}
      </Typography>

      {accounts.map((account, index) => {
        const accountName = account.accountDetails.displayName;

        return (
          <Stack
            key={index}
            className={styles.accountRow}
            direction="row"
            justifyContent="space-between"
          >
            <Typography
              className={toClassName(styles.text, styles.textPrimary)}
            >
              {accountName ?? PLACEHOLDER_SIGN}
            </Typography>
          </Stack>
        );
      })}

      <Divider className={styles.divider} />

      <Stack direction="row" justifyContent="space-between">
        <Typography className={toClassName(styles.text, styles.textPrimary)}>
          Tax-loss harvesting
        </Typography>
        <Typography className={toClassName(styles.text, styles.textSecondary)}>
          {tradingGroup.taxLossHarvesting ? "On" : "Off"}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography className={toClassName(styles.text, styles.textPrimary)}>
          Direct Indexing
        </Typography>
        <Typography className={toClassName(styles.text, styles.textSecondary)}>
          {tradingGroup.directIndexing ? "On" : "Off"}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default TradingGroupSummary;
