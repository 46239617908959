import React, { PropsWithChildren, useState } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";

import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import PageHero from "@src/sharedComponents/PageHero/PageHero";
import TitleBlock from "@src/sharedComponents/PageHero/TitleBlock";

import { logNavigation } from "./navigate";
import DepositContainer from "./screens/BankAccount/Deposit/DepositContainer";
import DepositOrWithdrawal from "./screens/BankAccount/DepositOrWithdrawal";
import Withdraw from "./screens/BankAccount/Withdrawal/Withdraw";
import WithdrawSuccess from "./screens/BankAccount/Withdrawal/WithdrawSuccess";
import WithdrawV4 from "./screens/BankAccount/Withdrawal/WithdrawV4";
import ERAProcedures from "./screens/EmployerRetirement/ERAProcedures";
import InstitutionSelection from "./screens/InstitutionSelection";
import AccountInformation from "./screens/InstitutionSelection/AccountInformation";
import MissingInstitution from "./screens/InstitutionSelection/MissingInstitution";
import OptionalInfo from "./screens/InstitutionSelection/OptionalInfo";
import TransfersDestination from "./screens/InstitutionSelection/TransferDestination";
import TransferInitiated from "./screens/InstitutionSelection/TransferInitiated";
import TransferVerification from "./screens/InstitutionSelection/TransferVerification";
import IRATypeSelection from "./screens/IRATypeSelection";
import RetirementTransfer from "./screens/RetirementTransfer";
import TransferTypeSelection from "./screens/TransferTypeSelection";
import { TransferScreen } from "./Types";

import styles from "./Transfers.module.css";

function Wrapper(props: PropsWithChildren<unknown>) {
  return (
    <BaseLayout>
      <PageHero start={<TitleBlock title="Transfers" />} />

      <div className={styles.container}>{props.children}</div>
    </BaseLayout>
  );
}

function assertUnreachable(_x: never): never {
  throw new Error("Didn't expect to get here");
}

export default function Transfers(): JSX.Element {
  const [curScreen, setScreen] = useState<TransferScreen>({
    screen: "transferType",
  });

  const { enableCustomPerformanceGroupsV2 } = useFlags();

  const setCurScreen = (screen: TransferScreen) => {
    logNavigation(screen);
    setScreen(screen);
  };

  switch (curScreen.screen) {
    case "transferType":
      return (
        <Wrapper>
          <TransferTypeSelection setCurScreen={setCurScreen} />
        </Wrapper>
      );

    case "bankAccount":
      return (
        <Wrapper>
          <DepositOrWithdrawal setCurScreen={setCurScreen} />
        </Wrapper>
      );

    case "IRA/institutionSelection":
    case "Investment/institutionSelection":
      return (
        <Wrapper>
          <InstitutionSelection setCurScreen={setCurScreen} {...curScreen} />
        </Wrapper>
      );

    case "IRA/missingInstitution":
    case "Investment/missingInstitution":
      return (
        <Wrapper>
          <MissingInstitution setCurScreen={setCurScreen} {...curScreen} />
        </Wrapper>
      );

    case "IRA/accountInformation":
    case "Investment/accountInformation":
      return (
        <Wrapper>
          <AccountInformation setCurScreen={setCurScreen} {...curScreen} />
        </Wrapper>
      );

    case "IRA/transferDestination":
    case "Investment/transferDestination":
      return (
        <Wrapper>
          <TransfersDestination setCurScreen={setCurScreen} {...curScreen} />
        </Wrapper>
      );

    case "IRA/optionalInfo":
    case "Investment/optionalInfo":
      return (
        <Wrapper>
          <OptionalInfo setCurScreen={setCurScreen} {...curScreen} />
        </Wrapper>
      );

    case "IRA/transferVerification":
    case "Investment/transferVerification":
      return (
        <Wrapper>
          <TransferVerification setCurScreen={setCurScreen} {...curScreen} />
        </Wrapper>
      );

    case "IRA/transferInitiated":
    case "Investment/transferInitiated":
      return (
        <Wrapper>
          <TransferInitiated setCurScreen={setCurScreen} {...curScreen} />
        </Wrapper>
      );

    case "IRA/TypeSelection":
      return (
        <Wrapper>
          <IRATypeSelection setCurScreen={setCurScreen} />
        </Wrapper>
      );

    case "ERA/Selection":
      return (
        <Wrapper>
          <RetirementTransfer setCurScreen={setCurScreen} />
        </Wrapper>
      );

    case "deposit":
      return (
        <Wrapper>
          <DepositContainer setCurScreen={setCurScreen} />
        </Wrapper>
      );

    case "withdrawal":
      return (
        <Wrapper>
          <>
            {enableCustomPerformanceGroupsV2 ? (
              <WithdrawV4 setCurScreen={setCurScreen} />
            ) : (
              <Withdraw setCurScreen={setCurScreen} />
            )}
          </>
        </Wrapper>
      );

    case "withdrawalSuccess":
      return (
        <Wrapper>
          <WithdrawSuccess
            setCurScreen={setCurScreen}
            withdrawFromAccountId={curScreen.withdrawFromAccountId}
            depositToExternalId={curScreen.depositToExternalId}
            transferAmount={curScreen.transferAmount}
          />
        </Wrapper>
      );

    case "Employer Retirement Account Instructions":
      return (
        <Wrapper>
          <ERAProcedures
            setCurScreen={setCurScreen}
            transferType={curScreen.transferType}
          />
        </Wrapper>
      );

    default:
      return assertUnreachable(curScreen);
  }
}
