import React, { useCallback, useEffect, useRef } from "react";

import { difference } from "lodash";

import useClientAccounts from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAccounts";
import useTradingGroups from "@fartherfinance/frontend/api/TradingGroups/hooks/useTradingGroups";
import { ClientId, FartherAccountId } from "@fartherfinance/frontend/api/Types";

import AccountList from "@src/multiCustodian/components/TaxBudgets/AccountList/AccountList";
import { actions } from "@src/multiCustodian/components/TaxBudgets/reducer/actions";
import { useCreateEditTaxBudgetContext } from "@src/multiCustodian/components/TaxBudgets/reducer/Context";
import useClientNameForId from "@src/multiCustodian/hooks/Advisor/useClientNameForId";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import SpouseAccountPopOver from "./SpouseAccountPopOver";

import styles from "./AccountListForClient.module.css";

interface Props {
  clientId: ClientId;
  isSpouse?: boolean;
  onDelete?: (accountIds: FartherAccountId[]) => void;
  onEdit?: () => void;
}

const AccountListForClient: React.FC<Props> = ({
  clientId,
  isSpouse = false,
  onDelete = () => undefined,
  onEdit = () => undefined,
}) => {
  const anchorRef = useRef<HTMLDivElement | null>(null);

  const [isOpen, setIsOpen] = React.useState(false);

  const {
    state: {
      data: { clientAccountIds },
    },
    dispatch,
  } = useCreateEditTaxBudgetContext();

  const auth = useAdvisorRequestAuth();

  const clientAuth = auth !== null ? { ...auth, clientId: clientId } : null;

  const { data: accountData } = useClientAccounts(clientId, clientAuth);

  const { data: tradingGroups = [] } = useTradingGroups(clientId, clientAuth);

  const accounts = accountData?.fartherAccounts || [];

  const accountIds = accounts.map((account) => account.accountId);

  const clientName = useClientNameForId(clientId, auth);

  const handleOnDelete = useCallback(() => {
    setIsOpen(false);
    onDelete(accountIds);
  }, [accountIds, onDelete]);

  const handleOnEdit = useCallback(() => {
    setIsOpen(false);
    onEdit();
  }, [onEdit]);

  useEffect(() => {
    const reducerHasAccounts =
      difference(accountIds, clientAccountIds).length === 0;

    if (!reducerHasAccounts) {
      dispatch({
        type: actions.SET_ACCOUNT_IDS,
        payload: [...clientAccountIds, ...accountIds],
      });
    }
  }, [accountIds, clientAccountIds, clientId, dispatch]);

  const toggleOpen = useCallback(() => setIsOpen((prev) => !prev), [setIsOpen]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {clientName ? (
          <div className={styles.name}>
            {`${clientName.name.first}'s Accounts`}
            {isSpouse && <span className={styles.spouse}>Spouse</span>}
          </div>
        ) : (
          <Skeleton width={200} />
        )}
        {isSpouse && (
          <SpouseAccountPopOver
            anchorRef={anchorRef}
            isOpen={isOpen}
            onDelete={handleOnDelete}
            onEdit={handleOnEdit}
            onOpen={toggleOpen}
            onClose={toggleOpen}
          />
        )}
      </div>
      {accounts.length > 0 && (
        <AccountList accounts={accounts} tradingGroups={tradingGroups} />
      )}
    </div>
  );
};

export default AccountListForClient;
