import React, { useMemo } from "react";

import { useParams } from "react-router-dom";

import useAllAccounts from "@fartherfinance/frontend/api/Accounts/hooks/useAllAccounts";
import useInvitations from "@fartherfinance/frontend/api/Sharing/hooks/useInvitations";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import { SharedResourceId } from "../Types";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";
import BorderBoxBody from "@src/sharedComponents/BorderBox/BorderBoxBody";
import BorderBoxHeader from "@src/sharedComponents/BorderBox/BorderBoxHeader";
import LinkButton from "@src/sharedComponents/LinkButton/LinkButton";

import styles from "./SharingResourceWhoHasOrGaveAccessTable.module.css";

interface Props {
  resourceId: SharedResourceId;
}

const SharingResourceWhoHasOrGaveAccessTable: React.FC<Props> = ({
  resourceId,
}) => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const auth = useRequestAuth();

  const invitations = useInvitations(auth);

  const allAccounts = useAllAccounts(clientId, auth);

  const allResources = useMemo(() => {
    if (allAccounts.isLoading || allAccounts.hasError) {
      return {
        fartherAccounts: [],
        externalAccounts: [],
        manuallyTrackedAccounts: [],
      };
    }

    return allAccounts.data;
  }, [allAccounts]);

  const { collaborators, sharedWithYou } = useMemo(() => {
    if (invitations.isLoading || invitations.hasError) {
      return {
        collaborators: [],
        sharedWithYou: [],
      };
    }

    return {
      collaborators: invitations.data.sent.filter((i) => i.status === "Active"),
      sharedWithYou: invitations.data.received.filter(
        (i) => i.status === "Active"
      ),
    };
  }, [invitations]);

  const hasGivenAccess: string[] = useMemo(() => {
    const hasGivenFullAccess = collaborators.filter(
      (c) => c.accessLevel === "All"
    );

    const hasGivenLimitedAccess = collaborators.filter(
      (c) =>
        c.accessLevel === "Limited" &&
        c.resources.some((r) => r.resourceId === resourceId)
    );

    return [...hasGivenFullAccess, ...hasGivenLimitedAccess].map(
      (a) => `${a.collaborator.firstName} ${a.collaborator.lastName}`
    );
  }, [collaborators, resourceId]);

  const hasReceivedAccess: string | undefined = useMemo(() => {
    let invitorId: ClientId | undefined = undefined;

    const managedAccount = allResources.fartherAccounts.find(
      (a) => a.virtualAccountId === resourceId
    );
    if (managedAccount) {
      invitorId =
        managedAccount.sharedBy[0] && managedAccount.sharedBy[0] !== clientId
          ? managedAccount.sharedBy[0]
          : undefined;
    }

    if (invitorId === undefined) {
      const externalAccount = allResources.externalAccounts.find(
        (a) => a.accountId === resourceId
      );
      if (externalAccount) {
        invitorId =
          externalAccount.clientId !== clientId
            ? externalAccount.clientId
            : undefined;
      }

      if (invitorId === undefined) {
        const manuallyTrackedAccount =
          allResources.manuallyTrackedAccounts.find((a) => a.id === resourceId);
        if (manuallyTrackedAccount) {
          invitorId =
            manuallyTrackedAccount.personId !== clientId
              ? manuallyTrackedAccount.personId
              : undefined;
        }
      }
    }

    const invitation = sharedWithYou.find(
      (i) => i.invitor.invitorId === invitorId
    );

    return invitation
      ? `${invitation.invitor.firstName} ${invitation.invitor.lastName}`
      : undefined;
  }, [allResources, sharedWithYou, resourceId, clientId]);

  if (invitations.hasError) {
    return <div>Error retrieving sharing data</div>;
  }

  if (allAccounts.hasError) {
    return <div>Error retrieving client's accounts</div>;
  }

  if (invitations.isLoading || allAccounts.isLoading) {
    return <></>;
  }

  // resource was not shared (by current client or another client)
  if (
    !invitations.isLoading &&
    hasGivenAccess.length === 0 &&
    hasReceivedAccess === undefined
  ) {
    return <></>;
  }

  // resource was shared
  return (
    <BorderBox className={styles.borderBox}>
      <BorderBoxHeader
        className={styles.borderBoxHeader}
        start={<div className={styles.headerStartText}>Sharing</div>}
        end={
          <LinkButton
            variant={"outlined"}
            buttonType={"secondary"}
            text={"See Collaborators"}
            sx={{ width: "148px", height: "32px" }}
            to={`/Client/${clientId}/Sharing`}
          />
        }
      />

      <BorderBoxBody className={styles.borderBoxBody}>
        {hasReceivedAccess ? (
          <div className={styles.text}>
            <span className={styles.invitorName}>{hasReceivedAccess}</span>{" "}
            shared access to this account with you.
          </div>
        ) : (
          hasGivenAccess.map((collaborator) => (
            <div key={collaborator} className={styles.text}>
              You are sharing access to this account with{" "}
              <span className={styles.collaboratorName}>{collaborator}</span>
            </div>
          ))
        )}
      </BorderBoxBody>
    </BorderBox>
  );
};

export default SharingResourceWhoHasOrGaveAccessTable;
