import { Action } from "redux";

import { Advisor } from "@fartherfinance/frontend/api/Dashboard/requests/getClientDashboard";
import { AdvisorId, ClientId } from "@fartherfinance/frontend/api/Types";

import { END_IMPERSONATION, EndImpersonation } from "./commonActions";

// INFO: storybook import
// eslint-disable-next-line import/no-unused-modules
export interface Images {
  logo_main: string;
  logo_gold: string;
  logo_blue: string;
  logo_red: string;
  logo_green: string;
  logo_white: string;
  logo_purple: string;
  logo_mini: string;
  logo_medium: string;

  // logo_symbol: string;
  // logo_wordmark: string;
  bullet_swirl_1: string;
  bullet_swirl_2: string;
  bullet_swirl_3: string;
  bullet_swirl_4: string;

  checklist_app_icon: string;
  accounts_app_icon: string;
  documents_app_icon: string;
  families_app_icon: string;
  portfolios_app_icon: string;
  funding_settings_app_icon: string;
  tax_budgets_app_icon: string;
  transfers_app_icon: string;
  alt_assets_app_icon: string;
  performance_groups_app_icon: string;
  lending_app_icon: string;
  insurance_app_icon: string;
  projections_app_icon: string;
  advisor_app_icon: string;
  messaging_app_icon: string;
  options_menu_app_icon: string;
  big_x_icon: string;
  small_x_icon: string;
  small_blue_check: string;
  chevron_up: string;
  chevron_down: string;
  chevron_right: string;
  add_circle: string;
  remove_circle: string;
  orange_plant: string;
  blue_plant: string;
  warning_icon: string;
  left_arrow: string;
  arrrow_right_collapse: string;
  arrow_right_icon: string;
  arrow_left_icon: string;
  radio_btn_on: string;
  radio_btn_off: string;
  mountainOutline: string;
  tentOutline: string;
  bootOutline: string;
  openBookOutline: string;
  fistBumpOutline: string;
  checkbox_checked: string;
  checkbox_unchecked: string;
}

// INFO: storybook import
// eslint-disable-next-line import/no-unused-modules
export interface Fonts {
  regular: string;
  bold: string;
  alternate_regular: string;
  header: string;
}

export interface Palette {
  "--farther_cream_color_1": string;
  "--farther_navy_color_1": string;

  "--button_primary_text_color_default_1": string;
  "--button_primary_text_color_hover_1": string;
  "--button_primary_text_color_press_1": string;
  "--button_primary_text_color_disabled_1": string;
  "--button_primary_color_default_1": string;
  "--button_primary_color_hover_1": string;
  "--button_primary_color_press_1": string;
  "--button_primary_color_disabled_1": string;
  "--button_primary_border_color_default_1": string;
  "--button_primary_border_color_hover_1": string;
  "--button_primary_border_color_press_1": string;
  "--button_primary_border_color_disabled_1": string;

  "--button_secondary_text_color_default_1": string;
  "--button_secondary_text_color_hover_1": string;
  "--button_secondary_text_color_press_1": string;
  "--button_secondary_text_color_disabled_1": string;
  "--button_secondary_border_color_default_1": string;
  "--button_secondary_border_color_hover_1": string;
  "--button_secondary_border_color_press_1": string;
  "--button_secondary_border_color_disabled_1": string;
  "--button_secondary_color_default_1": string;
  "--button_secondary_color_hover_1": string;
  "--button_secondary_color_press_1": string;
  "--button_secondary_color_disabled_1": string;

  "--toggle_background_color_1": string;
  "--toggle_active_color_1": string;

  "--primary_color_1": string;
  "--primary_text_color_1": string;
  "--primary_text_color_2": string;
  "--primary_text_color_3": string;
  "--primary_text_color_4": string;
  "--primary_text_color_5": string;
  "--alternate_text_color_1": string;
  "--alternate_text_color_2": string;
  "--primary_text_hover_color_1": string;
  "--selected_text_color_1": string;
  "--selected_text_color_2": string;

  "--primary_color_1_shade_0": string;
  "--primary_color_1_shade_1": string;
  "--primary_color_1_shade_2": string;
  "--primary_color_1_shade_3": string;
  "--primary_color_1_shade_4": string;
  "--primary_color_1_shade_5": string;
  "--primary_color_1_shade_6": string;
  "--primary_color_1_shade_7": string;
  "--primary_color_1_shade_8": string;
  "--primary_color_1_shade_9": string;
  "--primary_color_1_shade_10": string;

  "--primary_color_1_variation_1": string;
  "--primary_color_1_variation_2": string;
  "--primary_color_1_variation_3": string;
  "--primary_color_1_variation_4": string;
  "--primary_color_1_variation_7": string;
  "--primary_color_1_variation_8": string;
  "--primary_color_1_variation_9": string;
  "--primary_color_1_variation_10": string;
  "--primary_color_1_variation_11": string;
  "--primary_color_1_variation_12": string;
  "--primary_color_1_variation_15": string;
  "--primary_color_1_variation_16": string;

  "--secondary_color_1": string;
  "--secondary_color_1_shade_1": string;
  "--secondary_color_1_shade_2": string;
  "--secondary_color_1_shade_3": string;
  "--secondary_color_2": string;
  "--secondary_color_2_shade_1": string;
  "--secondary_color_2_shade_2": string;
  "--secondary_color_2_shade_3": string;
  "--secondary_color_2_shade_4": string;
  "--secondary_color_2_shade_5": string;
  "--secondary_color_2_shade_6": string;
  "--secondary_color_3": string;
  "--secondary_color_3_shade_1": string;
  "--secondary_color_4": string;

  "--random_color_1": string;
  "--random_color_2": string;
  "--random_color_3": string;
  "--random_color_4": string;
  "--random_color_5": string;
  "--random_color_6": string;
  "--random_color_7": string;
  "--random_color_9": string;
  "--random_color_10": string;
  "--random_color_11": string;
  "--random_color_12": string;
  "--random_color_13": string;
  "--random_color_14": string;
  "--random_color_15": string;
  "--random_color_17": string;
  "--random_color_18": string;
  "--random_color_19": string;
  "--random_color_20": string;
  "--random_color_21": string;
  "--random_color_22": string;
  "--random_color_23": string;
  "--random_color_24": string;
  "--random_color_25": string;
  "--random_color_26": string;
  "--random_color_27": string;
  "--random_color_28": string;
  "--random_color_29": string;
  "--random_color_30": string;
  "--box_shadow_color_1": string;

  "--dropdown_menu_color_1": string;
  "--dropdown_menu_hover_color_1": string;
  "--dropdown_menu_border_color_1": string;
  "--dropdown_menu_text_color_1": string;
  "--dropdown_menu_text_color_2": string;

  "--tooltip_background_color_1": string;
  "--tooltip_text_color_1": string;

  "--background_div_color_1": string;
  "--item_div_hover_color_1": string;
  "--border_color_1": string;
  "--icon_color_1": string;
  "--pill_text_color_1": string;
  "--background_div_color_2": string;
  "--background_div_color_3": string;
  "--border_color_2": string;
  "--background_div_color_4": string;
  "--background_div_color_5": string;
  "--background_div_color_6": string;
  "--border_color_3": string;

  "--item_focus_selected_color_1": string;
  "--item_focus_selected_color_2": string;
  "--item_focus_selected_color_3": string;
  "--item_focus_selected_color_4": string;
  "--item_no_focus_selected_color_1": string;
  "--item_no_focus_selected_color_2": string;

  "--modal_background_div_color_1": string;
  "--modal_background_div_color_2": string;
  "--modal_border_color_1": string;
  "--modal_text_color_1": string;
  "--modal_text_color_2": string;
  "--modal_text_color_3": string;
  "--modal_backdrop_color": string;

  "--pointer_tooltip_color_1": string;
  "--pointer_tooltip_border_color_1": string;
  "--pointer_tooltip_border_color_2": string;
  "--pointer_tooltip_text_color_1": string;
  "--pointer_tooltip_important_text_color_1": string;

  "--alert_msgbox_color_1": string;
  "--alert_msgbox_border_color_1": string;
  "--alert_msgbox_text_color_1": string;
  "--alert_msgbox_important_text_color_1": string;

  "--progress_bar_background_color_1": string;
  "--progress_bar_fill_color_1": string;

  "--positive_performance_up_color": string;
  "--negative_performance_down_color": string;

  "--notification_attention_color_1": string;

  "--table_row_hover_color_1": string;
  "--table_row_hover_color_2": string;

  "--neutral_input_underline_color_1": string;
  "--neutral_input_underline_color_2": string;
  "--good_input_underline_color_1": string;
  "--bad_input_underline_color_1": string;

  "--bad_negative_color": string;
  "--good_positive_color": string;
  "--disabled_color_1": string;

  "--switch_toggle_on_color_1": string;
  "--switch_toggle_off_color_1": string;

  "--text_of_note_color_1": string;
  "--text_of_note_color_2": string;

  "--sendbird_icon_color_1": string;

  "--checkbox_unchecked_background_color_1": string;
  "--checkbox_checked_background_color_1": string;

  "--portfolio_breakdown_chart_background_theme_color": string;
  "--portfolio_breakdown_chart_text_color": string;
  "--portfolio_breakdown_chart_color_1": string;
  "--portfolio_breakdown_chart_color_2": string;
  "--portfolio_breakdown_chart_color_3": string;
  "--portfolio_breakdown_chart_color_4": string;
  "--portfolio_breakdown_chart_color_5": string;

  "--portfolio_forecast_graph_color_1": string;
  "--portfolio_forecast_graph_color_2": string;
  "--portfolio_forecast_graph_color_3": string;
  "--portfolio_forecast_graph_crosshair_color": string;
  "--portfolio_forecast_graph_text_color": string;
  "--portfolio_forecast_graph_grid_line_color": string;
  "--portfolio_forecast_graph_tooltip_background_color": string;
  "--portfolio_forecast_graph_tooltip_text_color": string;
  "--portfolio_forecast_graph_tooltip_text_2_color": string;
  "--portfolio_forecast_graph_tooltip_boxShadow_color": string;
  "--portfolio_forecast_graph_tooltip_border_color": string;
}

export type UiTheme = "farther_dark" | "farther_light";

type UiThemes = { farther_dark: 1; farther_light: 2 };

export type Brand = {
  farther_dark: {
    images: Images;
    fonts: Fonts;
  };
  farther_light: {
    images: Images;
    fonts: Fonts;
  };
  current: {
    images: Images;
    fonts: Fonts;
  };
};

export type CSSColorPalettes = {
  farther_dark: Palette;
  farther_light: Palette;
  current: Palette;
};

interface State {
  cur_user: string | null;
  isLoggedIn: boolean;
  farther_jwt: string | null;
  dashboard_status: string | boolean | null;
  has_completed_account_selections: boolean | null;
  user: {
    id_user: ClientId | null;
    email: string | null;
    isAdvisor: boolean | null;
    password: string | null;
  };
  advisor: Advisor | null;
  ui_theme: UiTheme;
  ui_themes: UiThemes;
  brand: Brand;
  cockroach_advisor_id: AdvisorId | null;
  funding_strategy_type: null;
  funding_settings: null;
  dashboard_data: {
    main_graph_info: null;
  };
  login_conflict: boolean;
  is_in_debug: boolean;
  isAdmin: boolean;
  css_color_palettes: CSSColorPalettes;
}

const initialState: State = {
  cur_user: null,
  isLoggedIn: false,
  farther_jwt: null,
  dashboard_status: null,
  has_completed_account_selections: null,
  user: { id_user: null, email: null, isAdvisor: null, password: null },
  advisor: null,
  ui_theme: "farther_dark",
  ui_themes: { farther_dark: 1, farther_light: 2 },
  brand: {
    farther_dark: {
      images: {
        logo_main: "/src/assets/svg/monoGoldFartherLogo.svg",
        logo_gold: "/src/assets/svg/gold_farther_icon.svg",
        logo_blue: "/src/assets/svg/blue_farther_icon.svg",
        logo_red: "/src/assets/svg/red_farther_icon.svg",
        logo_green: "/src/assets/svg/green_farther_icon.svg",
        logo_white: "/src/assets/svg/white_farther_icon.svg",
        logo_purple: "/src/assets/svg/purple_farther_icon.svg",
        logo_mini: "/src/assets/svg/mini_logo_yel.svg",
        logo_medium: "/src/assets/svg/farther-mark.svg",

        // logo_symbol: "/src/assets/svg/logo_symbol_cream.svg",
        // logo_wordmark: "/src/assets/svg/logo_wordmark_cream.svg",
        bullet_swirl_1: "/src/assets/svg/bullet_swirl_green.svg",
        bullet_swirl_2: "/src/assets/svg/bullet_swirl_orange.svg",
        bullet_swirl_3: "/src/assets/svg/bullet_swirl_klein_blue.svg",
        bullet_swirl_4: "/src/assets/svg/bullet_swirl_steel_blue.svg",

        checklist_app_icon: "/src/assets/svg/checklist_app_cream.svg",
        accounts_app_icon: "/src/assets/svg/accounts_app_cream.svg",
        documents_app_icon: "/src/assets/svg/documents_app_cream.svg",
        families_app_icon: "/src/assets/svg/families_app_cream.svg",

        portfolios_app_icon: "/src/assets/svg/portfolios_app_cream.svg",
        funding_settings_app_icon:
          "/src/assets/svg/funding_settings_app_cream.svg",
        transfers_app_icon: "/src/assets/svg/transfers_app_cream.svg",
        alt_assets_app_icon: "/src/assets/svg/alt_assets_app_cream.svg",
        performance_groups_app_icon:
          "/src/assets/svg/performance_groups_app_cream.svg",
        lending_app_icon: "/src/assets/svg/lending_app_cream.svg",
        insurance_app_icon: "/src/assets/svg/insurance_app_cream.svg",
        projections_app_icon: "/src/assets/svg/projections_app_cream.svg",
        advisor_app_icon: "/src/assets/svg/advisor_app_cream.svg",
        messaging_app_icon: "/src/assets/svg/messaging_app_cream.svg",
        options_menu_app_icon: "/src/assets/svg/options_menu_app_cream.svg",
        tax_budgets_app_icon: "/src/assets/svg/tax_budgets_app_cream.svg",

        big_x_icon: "/src/assets/svg/close_cream.svg",
        small_x_icon: "/src/assets/svg/x_circle_white.svg",
        small_blue_check: "/src/assets/svg/small_blue_check.svg",
        chevron_up: "/src/assets/svg/chevron-up.svg",
        chevron_down: "/src/assets/svg/chevron-down.svg",
        chevron_right: "/src/assets/svg/chevron-right.svg",
        add_circle: "/src/assets/svg/add_circle.svg",
        remove_circle: "/src/assets/svg/remove_circle.svg",
        orange_plant: "/src/assets/svg/orange_plant.svg",
        blue_plant: "/src/assets/svg/blue_plant.svg",
        warning_icon: "/src/assets/svg/warning.svg",
        left_arrow: "/src/assets/svg/left_arrow.svg",
        arrrow_right_collapse: "/src/assets/svg/arrowRightCollapse.svg",
        arrow_right_icon: "/src/assets/svg/arrow_right_icon.svg",
        arrow_left_icon: "/src/assets/svg/arrow_left_icon.svg",
        radio_btn_on: "/src/assets/svg/radio_btn_2_on.svg",
        radio_btn_off: "/src/assets/svg/radio_btn_2_off.svg",
        mountainOutline: "/src/assets/svg/mountainOutline.svg",
        tentOutline: "/src/assets/png/tentOutline.png",
        bootOutline: "/src/assets/png/bootOutline.png",
        openBookOutline: "/src/assets/png/openBookOutline.png",
        fistBumpOutline: "/src/assets/png/fistBumpOutline.png",
        checkbox_checked: "/src/assets/svg/checkbox_light.svg",
        checkbox_unchecked: "/src/assets/svg/checkbox_unchecked_navy.svg",
      },
      fonts: {
        regular: "/src/assets/fonts/Regular/regular.otf",
        bold: "/src/assets/fonts/Bold/bold.otf",
        alternate_regular: "/src/assets/fonts/Alternate/Regular/regular.otf",
        header: "/src/assets/fonts/Header/header.otf",
      },
    },
    farther_light: {
      images: {
        logo_main: "/src/assets/svg/monoGoldFartherLogo.svg",
        logo_gold: "/src/assets/svg/gold_farther_icon.svg",
        logo_blue: "/src/assets/svg/blue_farther_icon.svg",
        logo_red: "/src/assets/svg/red_farther_icon.svg",
        logo_green: "/src/assets/svg/green_farther_icon.svg",
        logo_white: "/src/assets/svg/white_farther_icon.svg",
        logo_purple: "/src/assets/svg/purple_farther_icon.svg",
        logo_mini: "/src/assets/svg/mini_logo_yel.svg",
        logo_medium: "/src/assets/svg/farther-mark.svg",

        // logo_symbol: "/src/assets/svg/logo_symbol_navy.svg",
        // logo_wordmark: "/src/assets/svg/logo_wordmark_navy.svg",
        bullet_swirl_1: "/src/assets/svg/bullet_swirl_green.svg",
        bullet_swirl_2: "/src/assets/svg/bullet_swirl_orange.svg",
        bullet_swirl_3: "/src/assets/svg/bullet_swirl_klein_blue.svg",
        bullet_swirl_4: "/src/assets/svg/bullet_swirl_steel_blue.svg",

        checklist_app_icon: "/src/assets/svg/checklist_app_navy.svg",
        accounts_app_icon: "/src/assets/svg/accounts_app_navy.svg",
        documents_app_icon: "/src/assets/svg/documents_app_navy.svg",
        families_app_icon: "/src/assets/svg/families_app_navy.svg",
        portfolios_app_icon: "/src/assets/svg/portfolios_app_navy.svg",
        funding_settings_app_icon:
          "/src/assets/svg/funding_settings_app_navy.svg",
        transfers_app_icon: "/src/assets/svg/transfers_app_navy.svg",
        alt_assets_app_icon: "/src/assets/svg/alt_assets_app_navy.svg",
        performance_groups_app_icon:
          "/src/assets/svg/performance_groups_app_navy.svg",
        lending_app_icon: "/src/assets/svg/lending_app_navy.svg",
        insurance_app_icon: "/src/assets/svg/insurance_app_navy.svg",
        projections_app_icon: "/src/assets/svg/projections_app_navy.svg",
        advisor_app_icon: "/src/assets/svg/advisor_app_navy.svg",
        messaging_app_icon: "/src/assets/svg/messaging_app_navy.svg",
        options_menu_app_icon: "/src/assets/svg/options_menu_app_navy.svg",
        tax_budgets_app_icon: "/src/assets/svg/tax_budgets_app_navy.svg",
        big_x_icon: "/src/assets/svg/close_navy.svg",
        small_x_icon: "/src/assets/svg/x_circle_navy.svg",
        small_blue_check: "/src/assets/svg/small_blue_check.svg",
        chevron_up: "/src/assets/svg/chevron-up-navy.svg",
        chevron_down: "/src/assets/svg/chevron-down-navy.svg",
        chevron_right: "/src/assets/svg/chevron-right-navy.svg",
        add_circle: "/src/assets/svg/add_circle_navy.svg",
        remove_circle: "/src/assets/svg/remove_circle_navy.svg",
        orange_plant: "/src/assets/svg/orange_plant.svg",
        blue_plant: "/src/assets/svg/blue_plant.svg",
        warning_icon: "/src/assets/svg/warning_navy.svg",
        left_arrow: "/src/assets/svg/left_arrow.svg",
        arrrow_right_collapse: "/src/assets/svg/arrowRightCollapse_navy.svg",
        arrow_right_icon: "/src/assets/svg/arrow_right_icon.svg",
        arrow_left_icon: "/src/assets/svg/arrow_left_icon.svg",
        radio_btn_on: "/src/assets/svg/radio_btn_2_on_navy.svg",
        radio_btn_off: "/src/assets/svg/radio_btn_2_off_navy.svg",
        mountainOutline: "/src/assets/svg/mountainOutline_grey.svg",
        tentOutline: "/src/assets/svg/tentOutline_navy.svg",
        bootOutline: "/src/assets/svg/bootOutline_navy.svg",
        openBookOutline: "/src/assets/svg/openBookOutline.svg",
        fistBumpOutline: "/src/assets/png/fistBumpOutline_navy.png",
        checkbox_checked: "/src/assets/svg/checkbox_navy.svg",
        checkbox_unchecked: "/src/assets/svg/checkbox_unchecked.svg",
      },
      fonts: {
        regular: "/src/assets/fonts/Regular/regular.otf",
        bold: "/src/assets/fonts/Bold/bold.otf",
        alternate_regular: "/src/assets/fonts/Alternate/Regular/regular.otf",
        header: "/src/assets/fonts/Header/header.otf",
      },
    },
    current: {
      images: {
        logo_main: "/src/assets/svg/monoGoldFartherLogo.svg",
        logo_gold: "/src/assets/svg/gold_farther_icon.svg",
        logo_blue: "/src/assets/svg/blue_farther_icon.svg",
        logo_red: "/src/assets/svg/red_farther_icon.svg",
        logo_green: "/src/assets/svg/green_farther_icon.svg",
        logo_white: "/src/assets/svg/white_farther_icon.svg",
        logo_purple: "/src/assets/svg/purple_farther_icon.svg",
        logo_mini: "/src/assets/svg/mini_logo_yel.svg",
        logo_medium: "/src/assets/svg/farther-mark.svg",

        // logo_symbol: "/src/assets/svg/logo_symbol_cream.svg",
        // logo_wordmark: "/src/assets/svg/logo_wordmark_cream.svg",
        bullet_swirl_1: "/src/assets/svg/bullet_swirl_green.svg",
        bullet_swirl_2: "/src/assets/svg/bullet_swirl_orange.svg",
        bullet_swirl_3: "/src/assets/svg/bullet_swirl_klein_blue.svg",
        bullet_swirl_4: "/src/assets/svg/bullet_swirl_steel_blue.svg",

        checklist_app_icon: "/src/assets/svg/checklist_app_cream.svg",
        accounts_app_icon: "/src/assets/svg/accounts_app_cream.svg",
        documents_app_icon: "/src/assets/svg/documents_app_cream.svg",
        families_app_icon: "/src/assets/svg/families_app_cream.svg",
        portfolios_app_icon: "/src/assets/svg/portfolios_app_cream.svg",
        funding_settings_app_icon:
          "/src/assets/svg/funding_settings_app_cream.svg",
        transfers_app_icon: "/src/assets/svg/transfers_app_cream.svg",
        alt_assets_app_icon: "/src/assets/svg/alt_assets_app_cream.svg",
        performance_groups_app_icon:
          "/src/assets/svg/performance_groups_app_cream.svg",
        lending_app_icon: "/src/assets/svg/lending_app_cream.svg",
        insurance_app_icon: "/src/assets/svg/insurance_app_cream.svg",
        projections_app_icon: "/src/assets/svg/projections_app_cream.svg",
        advisor_app_icon: "/src/assets/svg/advisor_app_cream.svg",
        messaging_app_icon: "/src/assets/svg/messaging_app_cream.svg",
        options_menu_app_icon: "/src/assets/svg/options_menu_app_cream.svg",
        tax_budgets_app_icon: "/src/assets/svg/tax_budgets_app_cream.svg",
        big_x_icon: "/src/assets/svg/close_cream.svg",
        small_x_icon: "/src/assets/svg/x_circle_white.svg",
        small_blue_check: "/src/assets/svg/small_blue_check.svg",
        chevron_up: "/src/assets/svg/chevron-up.svg",
        chevron_down: "/src/assets/svg/chevron-down.svg",
        chevron_right: "/src/assets/svg/chevron-right.svg",
        add_circle: "/src/assets/svg/add_circle.svg",
        remove_circle: "/src/assets/svg/remove_circle.svg",
        orange_plant: "/src/assets/svg/orange_plant.svg",
        blue_plant: "/src/assets/svg/blue_plant.svg",
        warning_icon: "/src/assets/svg/warning.svg",
        left_arrow: "/src/assets/svg/left_arrow.svg",
        arrrow_right_collapse: "/src/assets/svg/arrowRightCollapse.svg",
        arrow_right_icon: "/src/assets/svg/arrow_right_icon.svg",
        arrow_left_icon: "/src/assets/svg/arrow_left_icon.svg",
        radio_btn_on: "/src/assets/svg/radio_btn_2_on.svg",
        radio_btn_off: "/src/assets/svg/radio_btn_2_off.svg",
        mountainOutline: "/src/assets/svg/mountainOutline.svg",
        tentOutline: "/src/assets/png/tentOutline.png",
        bootOutline: "/src/assets/png/bootOutline.png",
        openBookOutline: "/src/assets/png/openBookOutline.png",
        fistBumpOutline: "/src/assets/png/fistBumpOutline.png",
        checkbox_checked: "/src/assets/svg/checkbox_light.svg",
        checkbox_unchecked: "/src/assets/svg/checkbox_unchecked_navy.svg",
      },
      fonts: {
        regular: "/src/assets/fonts/Regular/regular.otf",
        bold: "/src/assets/fonts/Bold/bold.otf",
        alternate_regular: "/src/assets/fonts/Alternate/Regular/regular.otf",
        header: "/src/assets/fonts/Header/header.otf",
      },
    },
  },
  cockroach_advisor_id: null,
  funding_strategy_type: null,
  funding_settings: null,
  dashboard_data: { main_graph_info: null },
  login_conflict: false,
  is_in_debug: false,
  isAdmin: false,
  css_color_palettes: {
    farther_dark: {
      "--farther_cream_color_1": "#FFFEF4",
      "--farther_navy_color_1": "#02112A",

      "--button_primary_text_color_default_1": "#02112A",
      "--button_primary_text_color_hover_1": "#02112A",
      "--button_primary_text_color_press_1": "#02112A",
      "--button_primary_text_color_disabled_1": "#02112A",
      "--button_primary_color_default_1": "#FFFEF4",
      "--button_primary_color_hover_1": "#C0C3C1",
      "--button_primary_color_press_1": "#80878F",
      "--button_primary_color_disabled_1": "#4E5867",
      "--button_primary_border_color_default_1": "#FFFEF4",
      "--button_primary_border_color_hover_1": "#C0C3C1",
      "--button_primary_border_color_press_1": "#80878F",
      "--button_primary_border_color_disabled_1": "#4E5867",

      "--button_secondary_text_color_default_1": "#FFFEF4",
      "--button_secondary_text_color_hover_1": "#C0C3C1",
      "--button_secondary_text_color_press_1": "#FFFEF4",
      "--button_secondary_text_color_disabled_1": "#4E5867",
      "--button_secondary_border_color_default_1": "#FFFEF4",
      "--button_secondary_border_color_hover_1": "#C0C3C1",
      "--button_secondary_border_color_press_1": "#C0C3C1",
      "--button_secondary_border_color_disabled_1": "#4E5867",
      "--button_secondary_color_default_1": "#FFFEF4",
      "--button_secondary_color_hover_1": "#C0C3C1",
      "--button_secondary_color_press_1": "#FFFEF4",
      "--button_secondary_color_disabled_1": "#4E5867",

      "--toggle_background_color_1": "#1B293E",
      "--toggle_active_color_1": "#4E5867",

      "--primary_color_1": "#02112A",
      "--primary_text_color_1": "#FFFEF4",
      "--primary_text_color_2": "#80878F",
      "--primary_text_color_3": "#B3B7B8",
      "--primary_text_color_4": "#C3C1A8",
      "--primary_text_color_5": "#80878F",
      "--alternate_text_color_1": "#80878F",
      "--alternate_text_color_2": "#898c82",
      "--primary_text_hover_color_1": "#80878F",
      "--selected_text_color_1": "#C3C1A8",
      "--selected_text_color_2": "#39D435",

      "--primary_color_1_shade_0": "#02112A",
      "--primary_color_1_shade_1": "#02112A",
      "--primary_color_1_shade_2": "#3B4F56",
      "--primary_color_1_shade_3": "#3B4F56",
      "--primary_color_1_shade_4": "#3B4F56",
      "--primary_color_1_shade_5": "#3B4F56",
      "--primary_color_1_shade_6": "#80878F",
      "--primary_color_1_shade_7": "#80878F",
      "--primary_color_1_shade_8": "#80878F",
      "--primary_color_1_shade_9": "#80878F",
      "--primary_color_1_shade_10": "#80878F",

      "--primary_color_1_variation_1": "#02112A",
      "--primary_color_1_variation_2": "#383F52",
      "--primary_color_1_variation_3": "#0F1D34",
      "--primary_color_1_variation_4": "#4C5263",
      "--primary_color_1_variation_7": "#151D36",
      "--primary_color_1_variation_8": "#222A43",
      "--primary_color_1_variation_9": "#151D34",
      "--primary_color_1_variation_10": "#1D3452",
      "--primary_color_1_variation_11": "#161C32",
      "--primary_color_1_variation_12": "#2C3348",
      "--primary_color_1_variation_15": "#2B3347",
      "--primary_color_1_variation_16": "#02112A",

      "--secondary_color_1": "#FFFEF4",
      "--secondary_color_1_shade_1": "#C0C3C1",
      "--secondary_color_1_shade_2": "#FFFEF4",
      "--secondary_color_1_shade_3": "#FFFEF4",
      "--secondary_color_2": "#4471AA",
      "--secondary_color_2_shade_1": "#5684BF",
      "--secondary_color_2_shade_2": "#528DD6",
      "--secondary_color_2_shade_3": "#304C70",
      "--secondary_color_2_shade_4": "#1D3452",
      "--secondary_color_2_shade_5": "#3461AA",
      "--secondary_color_2_shade_6": "#427CBF",
      "--secondary_color_3": "#AF3F26",
      "--secondary_color_3_shade_1": "#CF4B2D",
      "--secondary_color_4": "#02112A",

      "--random_color_1": "#FF7000",
      "--random_color_2": "#828692",
      "--random_color_3": "#6D6D6F",
      "--random_color_4": "#FFFFFF",
      "--random_color_5": "#ADC9FF",
      "--random_color_6": "#0C3B66",
      "--random_color_7": "#4E70A6",
      "--random_color_9": "#20273D",
      "--random_color_10": "#F2B642",
      "--random_color_11": "#1D2338",
      "--random_color_12": "#212529",
      "--random_color_13": "#747474",
      "--random_color_14": "#9599A3",
      "--random_color_15": "#808592",
      "--random_color_17": "#6B6D73",
      "--random_color_18": "#383838",
      "--random_color_19": "rgba(0,0,0,0.2)",
      "--random_color_20": "rgba(170, 170, 170, 0.03)",
      "--random_color_21": "rgb(8, 16, 41)",
      "--random_color_22": "rgba(0, 0, 0, 0.3)",
      "--random_color_23": "rgba(0, 0, 0, 0.4)",
      "--random_color_24": "rgb(0,0,0)",
      "--random_color_25": "#158c1d",
      "--random_color_26": "#F78300",
      "--random_color_27": "#60a9ff",
      "--random_color_28": "#9465FF",
      "--random_color_29": "#96D1F2",
      "--random_color_30": "#FF63BD",
      "--box_shadow_color_1": "rgba(0,0,0,0.2)",

      "--dropdown_menu_color_1": "#0F1D34",
      "--dropdown_menu_hover_color_1": "#1B293E",
      "--dropdown_menu_border_color_1": "#3B4F56",
      "--dropdown_menu_text_color_1": "#FFFEF4",
      "--dropdown_menu_text_color_2": "#C0C3C1",

      "--tooltip_background_color_1": "#C3C1A8",
      "--tooltip_text_color_1": "#02112A",

      "--background_div_color_1": "#1B293E",
      "--item_div_hover_color_1": "#B3B7B8",
      "--border_color_1": "#3B4F56",
      "--icon_color_1": "#C3C1A8",
      "--pill_text_color_1": "#02112A",
      "--background_div_color_2": "#3B4F56",
      "--background_div_color_3": "#3b4f56",
      "--border_color_2": "#3B4F56",
      "--background_div_color_4": "#0F1D34",
      "--background_div_color_5": "#1B293E",
      "--background_div_color_6": "#1B293E",
      "--border_color_3": "#354052",

      "--item_focus_selected_color_1": "#6EA6CD",
      "--item_focus_selected_color_2": "#BAE8EB",
      "--item_focus_selected_color_3": "#80878F",
      "--item_focus_selected_color_4": "#C3C1A8",
      "--item_no_focus_selected_color_1": "#BAE8EB",
      "--item_no_focus_selected_color_2": "#BAE8EB",

      "--modal_background_div_color_1": "#02112A",
      "--modal_background_div_color_2": "#1B293E",
      "--modal_border_color_1": "#3B4F56",
      "--modal_text_color_1": "#FFFEF4",
      "--modal_text_color_2": "#80878F",
      "--modal_text_color_3": "#FFFEF4",
      "--modal_backdrop_color": "rgba(0, 0, 0, 0.4)",

      "--pointer_tooltip_color_1": "#6EA6CD",
      "--pointer_tooltip_border_color_1": "#3B4F56",
      "--pointer_tooltip_border_color_2": "#FFFEF4",
      "--pointer_tooltip_text_color_1": "#80878F",
      "--pointer_tooltip_important_text_color_1": "#142EDB",

      "--alert_msgbox_color_1": "#02112A",
      "--alert_msgbox_border_color_1": "#3B4F56",
      "--alert_msgbox_text_color_1": "#FFFEF4",
      "--alert_msgbox_important_text_color_1": "#39D435",

      "--progress_bar_background_color_1": "#3B4F56",
      "--progress_bar_fill_color_1": "#FFFEF4",

      "--positive_performance_up_color": "#39D435",
      "--negative_performance_down_color": "#FE665C",

      "--notification_attention_color_1": "#FE665C",

      "--table_row_hover_color_1": "#0F1D34",
      "--table_row_hover_color_2": "#0F1D34",

      "--neutral_input_underline_color_1": "#80878F",
      "--neutral_input_underline_color_2": "#80878F",
      "--good_input_underline_color_1": "#39D435",
      "--bad_input_underline_color_1": "#FE665C",

      "--bad_negative_color": "#FE665C",
      "--good_positive_color": "#39D435",
      "--disabled_color_1": "#4E5867",

      "--switch_toggle_on_color_1": "#FFFEF4",
      "--switch_toggle_off_color_1": "#80878F",

      "--text_of_note_color_1": "#39D435",
      "--text_of_note_color_2": "#BAE8EB",

      "--sendbird_icon_color_1": "#80878F",

      "--checkbox_unchecked_background_color_1": "#1b293e",
      "--checkbox_checked_background_color_1": "#FFFEF4",

      "--portfolio_breakdown_chart_background_theme_color": "#1B293E",
      "--portfolio_breakdown_chart_text_color": "#C0C3C1",
      "--portfolio_breakdown_chart_color_1": "#FE665C",
      "--portfolio_breakdown_chart_color_2": "#C3C1A8",
      "--portfolio_breakdown_chart_color_3": "#6EA6CD",
      "--portfolio_breakdown_chart_color_4": "#BAE8EB",
      "--portfolio_breakdown_chart_color_5": "#BFE1BE",

      "--portfolio_forecast_graph_color_1": "#FE665C",
      "--portfolio_forecast_graph_color_2": "#BAE8EB",
      "--portfolio_forecast_graph_color_3": "#BFE1BE",
      "--portfolio_forecast_graph_crosshair_color": "#414C5D",
      "--portfolio_forecast_graph_text_color": "#80878F",
      "--portfolio_forecast_graph_grid_line_color": "#3B4F56",
      "--portfolio_forecast_graph_tooltip_background_color": "#DBDAC5",
      "--portfolio_forecast_graph_tooltip_text_color": "#02112A",
      "--portfolio_forecast_graph_tooltip_text_2_color": "#80878F",
      "--portfolio_forecast_graph_tooltip_boxShadow_color": "black",
      "--portfolio_forecast_graph_tooltip_border_color": "#3B4F56",
    },
    farther_light: {
      "--farther_cream_color_1": "#FFFEF4",
      "--farther_navy_color_1": "#02112A",

      "--button_primary_text_color_default_1": "#FFFEF4",
      "--button_primary_text_color_hover_1": "#FFFEF4",
      "--button_primary_text_color_press_1": "#FFFEF4",
      "--button_primary_text_color_disabled_1": "#FFFEF4",
      "--button_primary_color_default_1": "#02112A",
      "--button_primary_color_hover_1": "#C0C3C1",
      "--button_primary_color_press_1": "#80878F",
      "--button_primary_color_disabled_1": "#4E5867",
      "--button_primary_border_color_default_1": "#02112A",
      "--button_primary_border_color_hover_1": "#C0C3C1",
      "--button_primary_border_color_press_1": "#80878F",
      "--button_primary_border_color_disabled_1": "#4E5867",

      "--button_secondary_text_color_default_1": "#02112A",
      "--button_secondary_text_color_hover_1": "#C0C3C1",
      "--button_secondary_text_color_press_1": "#FFFEF4",
      "--button_secondary_text_color_disabled_1": "#B3B7B8",
      "--button_secondary_border_color_default_1": "#02112A",
      "--button_secondary_border_color_hover_1": "#C0C3C1",
      "--button_secondary_border_color_press_1": "#C0C3C1",
      "--button_secondary_border_color_disabled_1": "#B3B7B8",
      "--button_secondary_color_default_1": "#02112A",
      "--button_secondary_color_hover_1": "#FFFEF4",
      "--button_secondary_color_press_1": "#C0C3C1",
      "--button_secondary_color_disabled_1": "#B3B7B8",

      "--toggle_background_color_1": "#F2F1E2",
      "--toggle_active_color_1": "#C3C1A8",

      "--primary_color_1": "#FFFEF4",
      "--primary_text_color_1": "#02112A",
      "--primary_text_color_2": "#80878F",
      "--primary_text_color_3": "#B3B7B8",
      "--primary_text_color_4": "#C3C1A8",
      "--primary_text_color_5": "#414C5D",
      "--alternate_text_color_1": "#80878F",
      "--alternate_text_color_2": "#898c82",
      "--primary_text_hover_color_1": "#C3C1A8",
      "--selected_text_color_1": "#C3C1A8",
      "--selected_text_color_2": "#39D435",

      "--primary_color_1_shade_0": "#FFFEF4",
      "--primary_color_1_shade_1": "#FFFEF4",
      "--primary_color_1_shade_2": "#C3C1A8",
      "--primary_color_1_shade_3": "#C3C1A8",
      "--primary_color_1_shade_4": "#C3C1A8",
      "--primary_color_1_shade_5": "#C3C1A8",
      "--primary_color_1_shade_6": "#80878F",
      "--primary_color_1_shade_7": "#80878F",
      "--primary_color_1_shade_8": "#80878F",
      "--primary_color_1_shade_9": "#80878F",
      "--primary_color_1_shade_10": "#80878F",

      "--primary_color_1_variation_1": "#02112A",
      "--primary_color_1_variation_2": "#383F52",
      "--primary_color_1_variation_3": "#0F1D34",
      "--primary_color_1_variation_4": "#4C5263",
      "--primary_color_1_variation_7": "#151D36",
      "--primary_color_1_variation_8": "#222A43",
      "--primary_color_1_variation_9": "#151D34",
      "--primary_color_1_variation_10": "#1D3452",
      "--primary_color_1_variation_11": "#161C32",
      "--primary_color_1_variation_12": "#2C3348",
      "--primary_color_1_variation_15": "#2B3347",
      "--primary_color_1_variation_16": "#02112A",

      "--secondary_color_1": "#FFFEF4",
      "--secondary_color_1_shade_1": "#C0C3C1",
      "--secondary_color_1_shade_2": "#FFFEF4",
      "--secondary_color_1_shade_3": "#FFFEF4",
      "--secondary_color_2": "#4471AA",
      "--secondary_color_2_shade_1": "#5684BF",
      "--secondary_color_2_shade_2": "#528DD6",
      "--secondary_color_2_shade_3": "#304C70",
      "--secondary_color_2_shade_4": "#1D3452",
      "--secondary_color_2_shade_5": "#3461AA",
      "--secondary_color_2_shade_6": "#427CBF",
      "--secondary_color_3": "#AF3F26",
      "--secondary_color_3_shade_1": "#CF4B2D",
      "--secondary_color_4": "#02112A",

      "--random_color_1": "#FF7000",
      "--random_color_2": "#828692",
      "--random_color_3": "#6D6D6F",
      "--random_color_4": "#FFFFFF",
      "--random_color_5": "#ADC9FF",
      "--random_color_6": "#0C3B66",
      "--random_color_7": "#4E70A6",
      "--random_color_9": "#20273D",
      "--random_color_10": "#F2B642",
      "--random_color_11": "#1D2338",
      "--random_color_12": "#212529",
      "--random_color_13": "#747474",
      "--random_color_14": "#9599A3",
      "--random_color_15": "#808592",
      "--random_color_17": "#6B6D73",
      "--random_color_18": "#383838",
      "--random_color_19": "rgba(0,0,0,0.2)",
      "--random_color_20": "rgba(170, 170, 170, 0.03)",
      "--random_color_21": "rgb(8, 16, 41)",
      "--random_color_22": "rgba(0, 0, 0, 0.3)",
      "--random_color_23": "rgba(0, 0, 0, 0.4)",
      "--random_color_24": "rgb(0,0,0)",
      "--random_color_25": "#158c1d",
      "--random_color_26": "#F78300",
      "--random_color_27": "#60a9ff",
      "--random_color_28": "#9465FF",
      "--random_color_29": "#96D1F2",
      "--random_color_30": "#FF63BD",
      "--box_shadow_color_1": "rgba(0,0,0,0.2)",

      "--dropdown_menu_color_1": "#F2F2EA",
      "--dropdown_menu_hover_color_1": "#C3C1A8",
      "--dropdown_menu_border_color_1": "#C3C1A8",
      "--dropdown_menu_text_color_1": "#02112A",
      "--dropdown_menu_text_color_2": "#C0C3C1",

      "--tooltip_background_color_1": "#C3C1A8",
      "--tooltip_text_color_1": "#02112A",

      "--background_div_color_1": "#F2F1E2",
      "--item_div_hover_color_1": "#C3C1A8",
      "--border_color_1": "#C3C1A8",
      "--icon_color_1": "#C3C1A8",
      "--pill_text_color_1": "#02112A",
      "--background_div_color_2": "#DBDAC5",
      "--background_div_color_3": "#E1DFCE",
      "--border_color_2": "#C3C1A8",
      "--background_div_color_4": "#F2F1E2",
      "--background_div_color_5": "#DBDAC5",
      "--background_div_color_6": "#E6E6E0",
      "--border_color_3": "#C3C1A8",

      "--item_focus_selected_color_1": "#6EA6CD",
      "--item_focus_selected_color_2": "#BAE8EB",
      "--item_focus_selected_color_3": "#80878F",
      "--item_focus_selected_color_4": "#C3C1A8",
      "--item_no_focus_selected_color_1": "#BAE8EB",
      "--item_no_focus_selected_color_2": "#BAE8EB",

      "--modal_background_div_color_1": "#02112A",
      "--modal_background_div_color_2": "#1B293E",
      "--modal_border_color_1": "#3B4F56",
      "--modal_text_color_1": "#FFFEF4",
      "--modal_text_color_2": "#80878F",
      "--modal_text_color_3": "#FFFEF4",
      "--modal_backdrop_color": "rgba(0, 0, 0, 0.4)",

      "--pointer_tooltip_color_1": "#6EA6CD",
      "--pointer_tooltip_border_color_1": "#C3C1A8",
      "--pointer_tooltip_border_color_2": "#02112A",
      "--pointer_tooltip_text_color_1": "#02112A",
      "--pointer_tooltip_important_text_color_1": "#142EDB",

      "--alert_msgbox_color_1": "#FFFEF4",
      "--alert_msgbox_border_color_1": "#3B4F56",
      "--alert_msgbox_text_color_1": "#02112A",
      "--alert_msgbox_important_text_color_1": "#142EDB",

      "--progress_bar_background_color_1": "#FFFEF4",
      "--progress_bar_fill_color_1": "#80878F",

      "--positive_performance_up_color": "#39D435",
      "--negative_performance_down_color": "#FE665C",

      "--notification_attention_color_1": "#FE665C",

      "--table_row_hover_color_1": "#F2F1E2",
      "--table_row_hover_color_2": "#DBDAC5",

      "--neutral_input_underline_color_1": "#02112A",
      "--neutral_input_underline_color_2": "#80878F",
      "--good_input_underline_color_1": "#39D435",
      "--bad_input_underline_color_1": "#FE665C",

      "--bad_negative_color": "#FE665C",
      "--good_positive_color": "#39D435",
      "--disabled_color_1": "#4E5867",

      "--switch_toggle_on_color_1": "#C3C1A8",
      "--switch_toggle_off_color_1": "#80878F",

      "--text_of_note_color_1": "#39D435",
      "--text_of_note_color_2": "#BAE8EB",

      "--sendbird_icon_color_1": "#80878F",

      "--checkbox_unchecked_background_color_1": "#F2F1E2",
      "--checkbox_checked_background_color_1": "#FFFEF4",

      "--portfolio_breakdown_chart_background_theme_color": "#F2F1E2",
      "--portfolio_breakdown_chart_text_color": "#80878F",
      "--portfolio_breakdown_chart_color_1": "#142EDB",
      "--portfolio_breakdown_chart_color_2": "#6EA6CD",
      "--portfolio_breakdown_chart_color_3": "#BAE8EB",
      "--portfolio_breakdown_chart_color_4": "#BFE1BE",
      "--portfolio_breakdown_chart_color_5": "#C3C1A8",

      "--portfolio_forecast_graph_color_1": "#FE665C",
      "--portfolio_forecast_graph_color_2": "#BAE8EB",
      "--portfolio_forecast_graph_color_3": "#BFE1BE",
      "--portfolio_forecast_graph_crosshair_color": "#414C5D",
      "--portfolio_forecast_graph_text_color": "#80878F",
      "--portfolio_forecast_graph_grid_line_color": "#C3C1A8",
      "--portfolio_forecast_graph_tooltip_background_color": "#DBDAC5",
      "--portfolio_forecast_graph_tooltip_text_color": "#02112A",
      "--portfolio_forecast_graph_tooltip_text_2_color": "#80878F",
      "--portfolio_forecast_graph_tooltip_boxShadow_color": "black",
      "--portfolio_forecast_graph_tooltip_border_color": "#C3C1A8",
    },
    current: {
      "--farther_cream_color_1": "#FFFEF4",
      "--farther_navy_color_1": "#02112A",

      "--button_primary_text_color_default_1": "#02112A",
      "--button_primary_text_color_hover_1": "#02112A",
      "--button_primary_text_color_press_1": "#02112A",
      "--button_primary_text_color_disabled_1": "#02112A",
      "--button_primary_color_default_1": "#FFFEF4",
      "--button_primary_color_hover_1": "#C0C3C1",
      "--button_primary_color_press_1": "#80878F",
      "--button_primary_color_disabled_1": "#4E5867",
      "--button_primary_border_color_default_1": "#FFFEF4",
      "--button_primary_border_color_hover_1": "#C0C3C1",
      "--button_primary_border_color_press_1": "#80878F",
      "--button_primary_border_color_disabled_1": "#4E5867",

      "--button_secondary_text_color_default_1": "#FFFEF4",
      "--button_secondary_text_color_hover_1": "#C0C3C1",
      "--button_secondary_text_color_press_1": "#FFFEF4",
      "--button_secondary_text_color_disabled_1": "#4E5867",
      "--button_secondary_border_color_default_1": "#FFFEF4",
      "--button_secondary_border_color_hover_1": "#C0C3C1",
      "--button_secondary_border_color_press_1": "#C0C3C1",
      "--button_secondary_border_color_disabled_1": "#4E5867",
      "--button_secondary_color_default_1": "#FFFEF4",
      "--button_secondary_color_hover_1": "#C0C3C1",
      "--button_secondary_color_press_1": "#FFFEF4",
      "--button_secondary_color_disabled_1": "#4E5867",

      "--toggle_background_color_1": "#1B293E",
      "--toggle_active_color_1": "#4E5867",

      "--primary_color_1": "#02112A",
      "--primary_text_color_1": "#FFFEF4",
      "--primary_text_color_2": "#80878F",
      "--primary_text_color_3": "#B3B7B8",
      "--primary_text_color_4": "#C3C1A8",
      "--primary_text_color_5": "#80878F",
      "--alternate_text_color_1": "#80878F",
      "--alternate_text_color_2": "#898c82",
      "--primary_text_hover_color_1": "#80878F",
      "--selected_text_color_1": "#C3C1A8",
      "--selected_text_color_2": "#39D435",

      "--primary_color_1_shade_0": "#02112A",
      "--primary_color_1_shade_1": "#02112A",
      "--primary_color_1_shade_2": "#3B4F56",
      "--primary_color_1_shade_3": "#3B4F56",
      "--primary_color_1_shade_4": "#3B4F56",
      "--primary_color_1_shade_5": "#3B4F56",
      "--primary_color_1_shade_6": "#80878F",
      "--primary_color_1_shade_7": "#80878F",
      "--primary_color_1_shade_8": "#80878F",
      "--primary_color_1_shade_9": "#80878F",
      "--primary_color_1_shade_10": "#80878F",

      "--primary_color_1_variation_1": "#02112A",
      "--primary_color_1_variation_2": "#383F52",
      "--primary_color_1_variation_3": "#0F1D34",
      "--primary_color_1_variation_4": "#4C5263",
      "--primary_color_1_variation_7": "#151D36",
      "--primary_color_1_variation_8": "#222A43",
      "--primary_color_1_variation_9": "#151D34",
      "--primary_color_1_variation_10": "#1D3452",
      "--primary_color_1_variation_11": "#161C32",
      "--primary_color_1_variation_12": "#2C3348",
      "--primary_color_1_variation_15": "#2B3347",
      "--primary_color_1_variation_16": "#02112A",

      "--secondary_color_1": "#FFFEF4",
      "--secondary_color_1_shade_1": "#C0C3C1",
      "--secondary_color_1_shade_2": "#FFFEF4",
      "--secondary_color_1_shade_3": "#FFFEF4",
      "--secondary_color_2": "#4471AA",
      "--secondary_color_2_shade_1": "#5684BF",
      "--secondary_color_2_shade_2": "#528DD6",
      "--secondary_color_2_shade_3": "#304C70",
      "--secondary_color_2_shade_4": "#1D3452",
      "--secondary_color_2_shade_5": "#3461AA",
      "--secondary_color_2_shade_6": "#427CBF",
      "--secondary_color_3": "#AF3F26",
      "--secondary_color_3_shade_1": "#CF4B2D",
      "--secondary_color_4": "#02112A",

      "--random_color_1": "#FF7000",
      "--random_color_2": "#828692",
      "--random_color_3": "#6D6D6F",
      "--random_color_4": "#FFFFFF",
      "--random_color_5": "#ADC9FF",
      "--random_color_6": "#0C3B66",
      "--random_color_7": "#4E70A6",
      "--random_color_9": "#20273D",
      "--random_color_10": "#F2B642",
      "--random_color_11": "#1D2338",
      "--random_color_12": "#212529",
      "--random_color_13": "#747474",
      "--random_color_14": "#9599A3",
      "--random_color_15": "#808592",
      "--random_color_17": "#6B6D73",
      "--random_color_18": "#383838",
      "--random_color_19": "rgba(0,0,0,0.2)",
      "--random_color_20": "rgba(170, 170, 170, 0.03)",
      "--random_color_21": "rgb(8, 16, 41)",
      "--random_color_22": "rgba(0, 0, 0, 0.3)",
      "--random_color_23": "rgba(0, 0, 0, 0.4)",
      "--random_color_24": "rgb(0,0,0)",
      "--random_color_25": "#158c1d",
      "--random_color_26": "#F78300",
      "--random_color_27": "#60a9ff",
      "--random_color_28": "#9465FF",
      "--random_color_29": "#96D1F2",
      "--random_color_30": "#FF63BD",
      "--box_shadow_color_1": "rgba(0,0,0,0.2)",

      "--dropdown_menu_color_1": "#0F1D34",
      "--dropdown_menu_hover_color_1": "#1B293E",
      "--dropdown_menu_border_color_1": "#3B4F56",
      "--dropdown_menu_text_color_1": "#FFFEF4",
      "--dropdown_menu_text_color_2": "#C0C3C1",

      "--tooltip_background_color_1": "#C3C1A8",
      "--tooltip_text_color_1": "#02112A",

      "--background_div_color_1": "#1B293E",
      "--item_div_hover_color_1": "#B3B7B8",
      "--border_color_1": "#3B4F56",
      "--icon_color_1": "#C3C1A8",
      "--pill_text_color_1": "#02112A",
      "--background_div_color_2": "#3B4F56",
      "--background_div_color_3": "#3b4f56",
      "--border_color_2": "#3B4F56",
      "--background_div_color_4": "#0F1D34",
      "--background_div_color_5": "#1B293E",
      "--background_div_color_6": "#1B293E",
      "--border_color_3": "#354052",

      "--item_focus_selected_color_1": "#6EA6CD",
      "--item_focus_selected_color_2": "#BAE8EB",
      "--item_focus_selected_color_3": "#80878F",
      "--item_focus_selected_color_4": "#C3C1A8",
      "--item_no_focus_selected_color_1": "#BAE8EB",
      "--item_no_focus_selected_color_2": "#BAE8EB",

      "--modal_background_div_color_1": "#02112A",
      "--modal_background_div_color_2": "#1B293E",
      "--modal_border_color_1": "#3B4F56",
      "--modal_text_color_1": "#FFFEF4",
      "--modal_text_color_2": "#80878F",
      "--modal_text_color_3": "#FFFEF4",
      "--modal_backdrop_color": "rgba(0, 0, 0, 0.4)",

      "--pointer_tooltip_color_1": "#6EA6CD",
      "--pointer_tooltip_border_color_1": "#3B4F56",
      "--pointer_tooltip_border_color_2": "#FFFEF4",
      "--pointer_tooltip_text_color_1": "#80878F",
      "--pointer_tooltip_important_text_color_1": "#142EDB",

      "--alert_msgbox_color_1": "#02112A",
      "--alert_msgbox_border_color_1": "#3B4F56",
      "--alert_msgbox_text_color_1": "#FFFEF4",
      "--alert_msgbox_important_text_color_1": "#39D435",

      "--progress_bar_background_color_1": "#3B4F56",
      "--progress_bar_fill_color_1": "#FFFEF4",

      "--positive_performance_up_color": "#39D435",
      "--negative_performance_down_color": "#FE665C",

      "--notification_attention_color_1": "#FE665C",

      "--table_row_hover_color_1": "#0F1D34",
      "--table_row_hover_color_2": "#0F1D34",

      "--neutral_input_underline_color_1": "#80878F",
      "--neutral_input_underline_color_2": "#80878F",
      "--good_input_underline_color_1": "#39D435",
      "--bad_input_underline_color_1": "#FE665C",

      "--bad_negative_color": "#FE665C",
      "--good_positive_color": "#39D435",
      "--disabled_color_1": "#4E5867",

      "--switch_toggle_on_color_1": "#FFFEF4",
      "--switch_toggle_off_color_1": "#80878F",

      "--text_of_note_color_1": "#39D435",
      "--text_of_note_color_2": "#BAE8EB",

      "--sendbird_icon_color_1": "#80878F",

      "--checkbox_unchecked_background_color_1": "#1b293e",
      "--checkbox_checked_background_color_1": "#FFFEF4",

      "--portfolio_breakdown_chart_background_theme_color": "#1B293E",
      "--portfolio_breakdown_chart_text_color": "#C0C3C1",
      "--portfolio_breakdown_chart_color_1": "#FE665C",
      "--portfolio_breakdown_chart_color_2": "#C3C1A8",
      "--portfolio_breakdown_chart_color_3": "#6EA6CD",
      "--portfolio_breakdown_chart_color_4": "#BAE8EB",
      "--portfolio_breakdown_chart_color_5": "#BFE1BE",

      "--portfolio_forecast_graph_color_1": "#FE665C",
      "--portfolio_forecast_graph_color_2": "#BAE8EB",
      "--portfolio_forecast_graph_color_3": "#BFE1BE",
      "--portfolio_forecast_graph_crosshair_color": "#414C5D",
      "--portfolio_forecast_graph_text_color": "#80878F",
      "--portfolio_forecast_graph_grid_line_color": "#3B4F56",
      "--portfolio_forecast_graph_tooltip_background_color": "#DBDAC5",
      "--portfolio_forecast_graph_tooltip_text_color": "#02112A",
      "--portfolio_forecast_graph_tooltip_text_2_color": "#80878F",
      "--portfolio_forecast_graph_tooltip_boxShadow_color": "black",
      "--portfolio_forecast_graph_tooltip_border_color": "#3B4F56",
    },
  },
};

interface LogIn extends Action {
  type: "LOG_IN";
  payload: {
    farther_jwt: string | null;
    id_user: ClientId;
    email: string;
    password: string | null;
    isAdvisor: boolean;
    conflict: boolean;
  };
}

interface ClearFartherJwt extends Action {
  type: "CLEAR_FARTHER_JWT";
  payload: unknown;
}

interface SetDebugFlag extends Action {
  type: "SET_DEBUG_FLAG";
  payload: {
    is_in_debug: boolean;
  };
}

interface ChangeCurUser extends Action {
  type: "CHANGE_CUR_USER";
  payload: {
    cur_user: string | null;
  };
}

interface SetIsLoggedInAsAdvisorState extends Action {
  type: "SET_IS_LOGGED_IN_AS_ADVISOR_STATE";
  payload: {
    isLoggedIn: boolean;
    farther_jwt: string | null;
    isAdvisor?: boolean | null;
    cur_user: string | null;
    cockroach_advisor_id: AdvisorId | null;
  };
}

interface SetAdvisorData extends Action {
  type: "SET_ADVISOR_DATA";
  payload: {
    has_completed_account_selections: boolean;
    an_account_is_open: string | boolean | null;
    advisor: Advisor;
  };
}

interface SetUITheme extends Action {
  type: "SET_UI_THEME";
  payload: {
    ui_theme: UiTheme;
    ui_themes: UiThemes | null;
    brand: Brand;
    css_color_palettes: CSSColorPalettes;
  };
}

interface SetIsAdmin extends Action {
  type: "SET_ISADMIN";
  payload: {
    isAdmin: boolean;
  };
}

export type MainReducerActions =
  | LogIn
  | ClearFartherJwt
  | SetDebugFlag
  | ChangeCurUser
  | SetIsLoggedInAsAdvisorState
  | SetAdvisorData
  | SetUITheme
  | SetIsAdmin
  | EndImpersonation;

const main_Reducer = (
  state: State = initialState,
  action: MainReducerActions
): State => {
  switch (action.type) {
    case END_IMPERSONATION: {
      const user = {
        id_user: null,
        email: null,
        password: null,
        isAdvisor: state.user.isAdvisor,
      };
      const {
        farther_jwt,
        cockroach_advisor_id,
        isAdmin,
        isLoggedIn,
        ui_theme,
        css_color_palettes,
        brand,
      } = state;

      return {
        ...initialState,
        user,
        farther_jwt,
        cockroach_advisor_id,
        isAdmin,
        isLoggedIn,
        ui_theme,
        css_color_palettes,
        brand,
      };
    }
    case "LOG_IN":
      return {
        ...state,
        isLoggedIn: true,
        farther_jwt: action.payload.farther_jwt,
        user: {
          id_user: action.payload.id_user,
          email: action.payload.email,
          password: state.user.password,
          isAdvisor: action.payload.isAdvisor,
        },
        login_conflict: action.payload.conflict,
        cur_user: action.payload.id_user,
      };

    case "CLEAR_FARTHER_JWT":
      return {
        ...state,
        farther_jwt: null,
        user: { id_user: null, email: null, password: null, isAdvisor: false },
        isLoggedIn: false,
      };

    case "SET_DEBUG_FLAG":
      return {
        ...state,
        is_in_debug: action.payload.is_in_debug,
      };

    case "CHANGE_CUR_USER":
      return {
        ...state,
        cur_user: action.payload.cur_user,
      };

    case "SET_IS_LOGGED_IN_AS_ADVISOR_STATE":
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn,
        farther_jwt: action.payload.farther_jwt,
        user: {
          id_user: null,
          email: null,
          password: null,
          isAdvisor: action.payload.isAdvisor
            ? action.payload.isAdvisor
            : state.user.isAdvisor,
        },
        cur_user: action.payload.cur_user,
        cockroach_advisor_id: action.payload.cockroach_advisor_id,
      };

    case "SET_ADVISOR_DATA":
      return {
        ...state,
        has_completed_account_selections:
          action.payload.has_completed_account_selections,
        dashboard_status: action.payload.an_account_is_open,
        advisor: action.payload.advisor,
      };

    case "SET_UI_THEME":
      return {
        ...state,
        ui_theme: action.payload.ui_theme
          ? action.payload.ui_theme
          : state.ui_theme,
        ui_themes: action.payload.ui_themes
          ? action.payload.ui_themes
          : state.ui_themes,
        brand: action.payload.brand,
        css_color_palettes: action.payload.css_color_palettes
          ? action.payload.css_color_palettes
          : state.css_color_palettes,
      };

    case "SET_ISADMIN":
      return {
        ...state,
        isAdmin: action.payload.isAdmin,
      };

    default:
      return state;
  }
};

export default main_Reducer;
