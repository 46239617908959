import React, { ComponentProps, useMemo } from "react";

import {
  Controller,
  FieldValues,
  Message,
  Path,
  PathValue,
  useFormContext,
} from "react-hook-form";

import useAdvisorClients from "@fartherfinance/frontend/api/Entity/hooks/useAdvisorClients";
import useClientBeneficiaries from "@fartherfinance/frontend/api/Entity/hooks/useClientBeneficiaries";
import { BeneficiaryId, ClientId } from "@fartherfinance/frontend/api/Types";

import { AutocompleteOption } from "../Dropdown/Dropdown";
import Dropdown from "../SAODropdown/Dropdown";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";

export interface FormBeneficiary extends AutocompleteOption {
  label: `${string} ${string}`;
  beneficiaryId: BeneficiaryId;
}

interface Props<F extends FieldValues> {
  name: Path<F>;
  label: string;
  clientId: ClientId;
  defaultValue?: FormBeneficiary;
  placeholder?: string;
  disabled?: boolean;
  required?: Message | false;
  rules?: ComponentProps<typeof Controller>["rules"];
}

interface PseudoClient {
  name: {
    first: string;
    last: string;
  };
}

export const labelMaker = (client: PseudoClient): `${string} ${string}` =>
  `${client.name.first} ${client.name.last}`;

export default function FormClientBeneficiaryDropdownField<
  F extends FieldValues
>({ ...props }: Props<F>): JSX.Element {
  const { control } = useFormContext<F>();

  const auth = useAdvisorRequestAuth();

  const advisorClients = useAdvisorClients(undefined, auth);

  const clientBeneficiaries = useClientBeneficiaries(props.clientId, auth);

  const client = advisorClients.data?.clients.find(
    (c) => c.clientId === props.clientId
  );

  const beneficiaryList: FormBeneficiary[] = useMemo(() => {
    if (clientBeneficiaries.data === undefined) {
      if (props.defaultValue !== undefined) {
        return [props.defaultValue];
      } else {
        return [];
      }
    }

    const list = clientBeneficiaries.data.map((el) => ({
      beneficiaryId: el.beneficiaryId,
      label: labelMaker(el),
    }));

    const clientLabel = client
      ? `${client.name.first} ${client.name.last}`
      : null;

    return list.length > 0
      ? list
      : [
          {
            beneficiaryId: "" as BeneficiaryId,
            label: clientLabel ? `None found for ${clientLabel}` : "None found",
          },
        ];
  }, [clientBeneficiaries.data, client, props.defaultValue]);

  const normalizedDefault: FormBeneficiary | undefined = useMemo(() => {
    if (props.defaultValue === undefined) {
      return undefined;
    }

    const incomingDefault = props.defaultValue;
    if (typeof incomingDefault !== "object" || incomingDefault === null) {
      return undefined;
    }

    try {
      const bId = incomingDefault?.["beneficiaryId"] as
        | undefined
        | BeneficiaryId;
      const label = incomingDefault?.["label"] as undefined | string;

      if (typeof bId === "string" && typeof label === "string") {
        return { beneficiaryId: bId, label: label as `${string} ${string}` };
      }
    } catch (_e) {
      return undefined;
    }

    return undefined;
  }, [props.defaultValue]);

  return (
    <Controller
      defaultValue={normalizedDefault as PathValue<F, Path<F>>}
      name={props.name}
      control={control}
      rules={{ required: props.required, ...props.rules }}
      render={({ field, fieldState }) =>
        // Otherwise we get Option Not Found errors
        clientBeneficiaries.isLoading ? (
          <Dropdown
            placeholder={props.placeholder}
            formLabel={props.label}
            value={null}
            onChange={() => undefined}
            values={[]}
            disabled={true}
            loading={true}
          />
        ) : (
          <Dropdown<FormBeneficiary>
            placeholder={props.placeholder}
            formLabel={props.label}
            value={field.value ?? null}
            onChange={(e) => {
              if (e.beneficiaryId === "") {
                return;
              }

              field.onChange(e);
            }}
            onBlur={field.onBlur}
            values={beneficiaryList}
            disabled={props.disabled}
            loading={clientBeneficiaries.isLoading}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
          />
        )
      }
    />
  );
}
