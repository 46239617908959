import React from "react";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import { Button } from "@src/yellowstone/components";

import { useStyles } from "./ProposalNavigation.styles";

interface ProposalNavigationProps {
  proposalCreatedOn: string | undefined;
  onReturnToTable: () => void;
  onNextProposal: () => void;
  onCsvDownload: () => void;
  isNextTradeButtonVisible: boolean;
  isNextTradeButtonDisabled: boolean;
  isProposalCreationDateHidden?: boolean;
  isCsvDownloadButtonVisible: boolean;
}

export const ProposalNavigation: React.FC<ProposalNavigationProps> = ({
  proposalCreatedOn,
  onReturnToTable,
  onNextProposal,
  onCsvDownload,
  isNextTradeButtonVisible,
  isNextTradeButtonDisabled,
  isProposalCreationDateHidden,
  isCsvDownloadButtonVisible,
}) => {
  const { classes, cx } = useStyles();

  return (
    <Stack
      className={classes.container}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      {/* prettier-ignore */}
      <Button
        variant="outlined"
        size="small"
        disableTextTransform
        onClick={onReturnToTable}
      >
        <ArrowForwardIcon className={cx(classes.icon, classes.returnIcon)}  />
        Return To Table
      </Button>

      {!isProposalCreationDateHidden && (
        <Typography className={classes.timestamp} noWrap>
          <span className={classes.timestampAccent}>Proposed:</span>
          {proposalCreatedOn ? (
            <>
              {new Date(proposalCreatedOn).toLocaleDateString("en-US")} -{" "}
              {new Date(proposalCreatedOn).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </>
          ) : (
            <Skeleton width={150} />
          )}
        </Typography>
      )}

      <Stack direction="row" alignItems="center" gap={1}>
        {isCsvDownloadButtonVisible && (
          <Button
            variant="text"
            size="small"
            disableTextTransform
            onClick={onCsvDownload}
          >
            Export to CSV
            <DownloadOutlinedIcon
              className={cx(classes.icon, classes.downloadIcon)}
            />
          </Button>
        )}
        {isNextTradeButtonVisible ? (
          <Button
            variant="contained"
            size="small"
            disabled={isNextTradeButtonDisabled}
            disableTextTransform
            onClick={onNextProposal}
          >
            Next Trade
            <ArrowForwardIcon className={cx(classes.icon, classes.nextIcon)} />
          </Button>
        ) : (
          <Box sx={{ width: 120 }} />
        )}
      </Stack>
    </Stack>
  );
};
