import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

export const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.appearance.$backgroundInverseSubtle,

    display: "flex",
    flexDirection: "column",

    color: theme.appearance.$text,
  },
  container: {
    paddingTop: "16px",
    paddingBottom: theme.spacing(pxToSpacing(52)),
  },
  pagination: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.drawer,
  },
}));
