import React from "react";

const BookAnalytics: React.FC = () => (
  <svg
    width="100%"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_828_9758"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="-1"
      y="-1"
      width="15"
      height="15"
    >
      <rect
        x="-0.11377"
        y="-0.00195312"
        width="14"
        height="14"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_828_9758)">
      <path
        d="M11.7863 9.14141L6.68216 5.07266L4.56758 7.97474L1.98633 6.03516V4.19766L4.08633 5.59766L6.88633 2.09766L9.68633 4.19766H11.7863V9.14141ZM1.98633 11.1977V7.34766L4.78633 9.44766L6.88633 6.57474L11.7863 10.4977V11.1977H1.98633Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default BookAnalytics;
