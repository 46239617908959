import React, { useEffect, useMemo, useState } from "react";

import { last } from "lodash";
import { useElementSize } from "usehooks-ts";

import usePortfolioPlanningV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/usePortfolioPlanningV2";
import { PortfolioId } from "@fartherfinance/frontend/api/Types";

import ProjectionsGraph, {
  DataItem,
} from "../../ProjectionsGraph/ProjectionsGraph";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import MultiToggle from "@src/sharedComponents/MultiToggle/MultiToggle";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import DateDisplay from "./DateDisplay";
import Legend from "./Legend";
import createDateByYear from "./utils/createDateByYear";
import formatToProjectionsGraphData from "./utils/formatToProjectionsGraphData";

import styles from "../ModelAnalysis.module.css";

const dateRanges = ["5Y", "10Y", "25Y"] as const;

type DateRange = typeof dateRanges[number];

const dateRangeToYears: Record<DateRange, number> = {
  //dateRange string to Number
  "5Y": 5,
  "10Y": 10,
  "25Y": 25,
};

interface Props {
  portfolioId: PortfolioId | null;
}

const Projections = (props: Props): JSX.Element => {
  const [dateRange, setDateRange] = useState<DateRange>("25Y");
  const [endDate, setEndDate] = useState<string | null>(null);

  const auth = useRequestAuth();

  const portfolioPlanning = usePortfolioPlanningV2(props.portfolioId, auth);

  const monteCarloData: DataItem[] = useMemo(() => {
    if (portfolioPlanning.data === undefined) {
      return [];
    }

    return formatToProjectionsGraphData(
      portfolioPlanning.data.monteCarloSimulationResults,
      dateRangeToYears[dateRange] + 1 // we add 1 because we are asking for years into the future
    );
  }, [dateRange, portfolioPlanning.data]);

  useEffect(() => {
    const lastItem = last(monteCarloData);

    if (lastItem === undefined) {
      return;
    }

    setEndDate(createDateByYear(lastItem.year));
  }, [dateRange, monteCarloData]);

  if (portfolioPlanning.isLoading) {
    return (
      <Container
        projectionsGraph={
          <Skeleton
            width={"100%"}
            height={"100%"}
            style={{ transform: "none" }}
          />
        }
      >
        <LegendDataLoading />
      </Container>
    );
  }

  if (portfolioPlanning.hasError) {
    return (
      <Container projectionsGraph={"Error"}>
        <LegendDataError />
      </Container>
    );
  }

  return (
    <Container
      projectionsGraph={
        <ProjectionsGraph
          monteCarloData={monteCarloData}
          setEndDate={setEndDate}
        />
      }
    >
      <LegendData
        endDate={endDate}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
    </Container>
  );
};

export default Projections;

interface ContainerProps {
  projectionsGraph: React.ReactNode;
}

const Container = (props: React.PropsWithChildren<ContainerProps>) => {
  const [graphRef, { width: graphWidth, height: graphHeight }] =
    useElementSize();

  return (
    <div className={styles.projectionsContainer}>
      <div style={{ flexGrow: 1, position: "relative" }} ref={graphRef}>
        <div
          className={styles.projectionsResizer}
          style={{
            width: graphWidth,
            height: graphHeight,
          }}
        >
          {props.projectionsGraph}
        </div>
      </div>

      {props.children}
    </div>
  );
};

const useFooterStyle = () => {
  const [footerRef, { width }] = useElementSize();

  const graphFooterStyle: React.CSSProperties = useMemo(() => {
    return {
      flexDirection: width < 400 ? "column" : "row",
    };
  }, [width]);

  return { footerRef, graphFooterStyle };
};

const LegendDataLoading = (): JSX.Element => {
  return <Skeleton width={"100%"} height={80} />;
};

const LegendDataError = (): JSX.Element => {
  const { footerRef, graphFooterStyle } = useFooterStyle();

  return (
    <div style={{ visibility: "hidden" }}>
      <DateDisplay toDate={null} />

      <div
        ref={footerRef}
        className={styles.graphFooter}
        style={graphFooterStyle}
      >
        <Legend />

        <MultiToggle
          items={[{ label: "placeholder" }]}
          currentItem={{ label: "placeholder" }}
          onChange={() => undefined}
        />
      </div>
    </div>
  );
};

interface LegendDataProps {
  endDate: string | null;
  dateRange: DateRange;
  setDateRange: (range: DateRange) => void;
}

const LegendData = (props: LegendDataProps): JSX.Element => {
  const { footerRef, graphFooterStyle } = useFooterStyle();

  return (
    <>
      <DateDisplay toDate={props.endDate} />

      <div
        ref={footerRef}
        className={styles.graphFooter}
        style={graphFooterStyle}
      >
        <Legend />

        <MultiToggle
          items={dateRanges.map((i) => ({ label: i }))}
          currentItem={{ label: props.dateRange }}
          onChange={(i) => props.setDateRange(i.label)}
        />
      </div>
    </>
  );
};
