import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import MuiDialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";

import { Button } from "@src/yellowstone/components/Button/Button.component";
import { isStringDefinied } from "@src/yellowstone/modules/shared";

import { useStyles } from "./Dialog.styles";

interface DialogProps {
  children: React.ReactNode;
  isDialogOpen: boolean;
  cancelLabel?: string;
  confirmLabel: string;
  onClose: () => void;
  onCancel?: () => void;
  onConfirm: () => void;
}

export const Dialog: React.FC<DialogProps> = ({
  children,
  isDialogOpen,
  cancelLabel,
  confirmLabel,
  onClose,
  onCancel,
  onConfirm,
}) => {
  const { classes } = useStyles();

  return (
    <MuiDialog
      open={isDialogOpen}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{ className: classes.paper, elevation: 0 }}
    >
      <Box className={classes.header}>
        <IconButton onClick={onClose} disableRipple>
          <CancelIcon className={classes.closeIcon} />
        </IconButton>
      </Box>
      <Box className={classes.content}>{children}</Box>
      <Box className={classes.footer}>
        {isStringDefinied(cancelLabel) && (
          <Button variant="text" size="large" onClick={onCancel}>
            {cancelLabel}
          </Button>
        )}
        <Button variant="contained" size="large" onClick={onConfirm}>
          {confirmLabel}
        </Button>
      </Box>
    </MuiDialog>
  );
};
