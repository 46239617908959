import React, { useEffect, useRef } from "react";

import Fade from "@mui/material/Fade";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./Modal.module.css";

export const standardWidth: React.CSSProperties = { width: "460px" };

interface Props {
  isOpen?: boolean;
  disableAutoFocus?: boolean;
  modalStyle?: React.CSSProperties;
  className?: string;
  closeModal: () => void;
}

// TODO: refactor using Material UI Modal/Dialog component

export default function Modal({
  children,
  isOpen = true,
  disableAutoFocus,
  modalStyle,
  className,
  closeModal,
}: React.PropsWithChildren<Props>): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleEscapeKeyPress: React.KeyboardEventHandler<HTMLDivElement> = (
    event
  ): void => {
    if (event.key === "Escape") {
      closeModal();
    }
  };

  useEffect(() => {
    if (containerRef.current === null) {
      return;
    }

    if (!disableAutoFocus) {
      containerRef.current.focus();
    }
  }, [disableAutoFocus]);

  return (
    <Fade in={isOpen}>
      <div
        ref={containerRef}
        className={toClassName(styles.modalContainer, {
          [styles.isOpen]: isOpen,
        })}
        tabIndex={0}
        onKeyDown={handleEscapeKeyPress}
      >
        <div className={styles.backgroundLayer} onClick={closeModal} />
        <div className={styles.modal} style={modalStyle}>
          <div className={toClassName(styles.content, className)}>
            {children}
          </div>
        </div>
      </div>
    </Fade>
  );
}
