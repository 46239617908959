import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles<{ columnsCount: number }>()(
  (_, { columnsCount }) => ({
    gridContainer: {
      display: "grid",
      gridTemplateColumns: `repeat(${columnsCount}, 1fr)`,

      width: "100%",
    },
  })
);
