import React from "react";

import { AccountDetails } from "@fartherfinance/frontend/api/Dashboard/requests/getClientDashboard";

import styles from "./Label.module.css";

interface Props {
  account: AccountDetails;
}

const Label: React.FC<Props> = ({ account }) => (
  <div>
    <div>{account.accountDetails.displayName}</div>
    <div className={styles.account}>
      {account.accountDetails.custodianAccountNumber}
    </div>
  </div>
);

export default Label;
