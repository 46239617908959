import type { GetOpportunitiesQueryParams } from "@fartherfinance/frontend/api/Opportunities/requests/getOpportunities";

import {
  extractSortParts,
  mapToTableSortOrder,
} from "@src/multiCustodian/utils/sorting";
import type { Column } from "@src/sharedComponents/Table/Types";

import type { OpportunitiesTableColumns } from "./OpportunitiesTable";

export const createTableColumns = (
  sort: GetOpportunitiesQueryParams["sort"]
): Column<OpportunitiesTableColumns>[] => {
  const { sortBy, sortOrder } = extractSortParts(sort);

  return [
    {
      label: "Client",
      accessor: "client",
      sortable: false,
    },
    {
      label: "Type",
      accessor: "type",
      sortable: true,
      active: sortBy === "type",
      sortOrder: mapToTableSortOrder(sortOrder),
    },
    {
      label: "Status",
      accessor: "status",
      sortable: true,
      active: sortBy === "status",
      sortOrder: mapToTableSortOrder(sortOrder),
    },
    {
      label: "Priority",
      accessor: "priority",
      sortable: true,
      active: sortBy === "priority",
      sortOrder: mapToTableSortOrder(sortOrder),
    },
    {
      label: "Created",
      accessor: "createdTs",
      sortable: true,
      active: sortBy === "createdTs",
      sortOrder: mapToTableSortOrder(sortOrder),
    },
    {
      label: "Updated",
      accessor: "updatedTs",
      sortable: true,
      active: sortBy === "updatedTs",
      sortOrder: mapToTableSortOrder(sortOrder),
    },
    {
      label: "Due",
      accessor: "dueDate",
      sortable: true,
      active: sortBy === "dueDate",
      sortOrder: mapToTableSortOrder(sortOrder),
    },
    // NOTE: action column
    { label: "", accessor: "action", sortable: false },
  ];
};
