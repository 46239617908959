/* eslint-disable react/prop-types */
import React, { Component } from "react";

import { truncate } from "lodash";
import { connect } from "react-redux";

import { State as RootState } from "../../../store";

import "./DropdownMenuSelectOne.css";

type ReduxProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export interface Item {
  name: string;
}

interface Props<I extends Item> {
  cur_item?: I;
  items: I[];
  changeMenuState: (t: I) => void;
  menu_width: string | number;
  hide_dropdown_img?: boolean;
}

interface State {
  showDropdown: boolean;
  cur_item: Item | null;
}

type ExtendedProps<I extends Item> = Props<I> & ReduxProps;

class DropdownMenuSelectOne_Component<I extends Item> extends Component<
  ExtendedProps<I>,
  State
> {
  constructor(props: ExtendedProps<I>) {
    super(props);
    this.state = {
      showDropdown: false,
      cur_item: null,
    };
  }

  componentDidMount() {
    if (this.props.cur_item) {
      this.setState({ cur_item: this.props.cur_item });
    } else if (this.props.items && this.props.items.length > 0) {
      this.setState({ cur_item: this.props.items[0] });
    }
  }

  componentDidUpdate(prevProps: Props<I>) {
    if (
      this.props.cur_item !== prevProps.cur_item &&
      this.props.cur_item !== this.state.cur_item
    ) {
      this.setState({ cur_item: this.props.cur_item ?? null });
    }
  }

  changeMenuState = (item: I) => {
    this.setState({ cur_item: item, showDropdown: false });
    this.props.changeMenuState(item);
  };

  calcMaxLengthBasedOnWidth = (width: string | number): number => {
    const width_num = typeof width === "number" ? width : parseFloat(width);
    return Math.round(width_num / 10) + 2;
  };

  render() {
    return (
      <div
        className="DropdownMenuSelectOne_Component__container"
        style={{ width: this.props.menu_width }}
      >
        <div
          className="DropdownMenuSelectOne_Component__menu_div"
          onMouseLeave={() => this.setState({ showDropdown: false })}
        >
          <div className="DropdownMenuSelectOne_Component__cur_item_div">
            <p className="DropdownMenuSelectOne_Component__item_text">
              {this.state.cur_item
                ? truncate(this.state.cur_item.name, {
                    length: this.calcMaxLengthBasedOnWidth(
                      this.props.menu_width
                    ),
                  })
                : ""}
            </p>
            {this.props.items &&
              this.props.items.length > 1 &&
              !this.props.hide_dropdown_img && (
                <img
                  className="DropdownMenuSelectOne_Component__dropdown_img"
                  src={
                    this.props.main_Reducer.brand.current.images.chevron_down
                  }
                  onMouseEnter={() => this.setState({ showDropdown: true })}
                />
              )}
          </div>

          {this.state.showDropdown && (
            <div className="DropdownMenuSelectOne_Component__invisible_div">
              <div
                className="DropdownMenuSelectOne_Component__option_div"
                onMouseLeave={() => this.setState({ showDropdown: false })}
              >
                {this.props.items.map((item, i) => {
                  return (
                    <p
                      key={i}
                      className="DropdownMenuSelectOne_Component__option"
                      onClick={() => this.changeMenuState(item)}
                    >
                      {item.name}
                    </p>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return { main_Reducer: state.main_Reducer };
};

const mapDispatchToProps = () => {
  return {};
};

const DropdownMenuSelectOne = connect(
  mapStateToProps,
  mapDispatchToProps
)(DropdownMenuSelectOne_Component) as <I extends Item>(
  p: Props<I>
) => JSX.Element;

export default DropdownMenuSelectOne;
