import React, { useCallback, useMemo, useState } from "react";

import { startCase } from "lodash";
import {
  DefaultValues,
  FormProvider,
  useForm,
  useFormState,
  useWatch,
} from "react-hook-form";

import usePlanDetailsV2 from "@fartherfinance/frontend/api/Accounts/hooks/usePlanDetailsV2";
import useSetPlanAccountInfoV2 from "@fartherfinance/frontend/api/Accounts/hooks/useSetPlanAccountInfoV2";
import {
  Account,
  AccountInformationFormToSend,
} from "@fartherfinance/frontend/api/Accounts/Types";
import useCollateralAccount from "@fartherfinance/frontend/api/Dashboard/hooks/useCollateralAccount";
import {
  CollateralAccount,
  GetCollateralAccountsRes,
} from "@fartherfinance/frontend/api/Dashboard/requests/getCollateralAccount";
import useAdvisorClients from "@fartherfinance/frontend/api/Entity/hooks/useAdvisorClients";
import {
  ClientId,
  CustodianAccountId,
  FartherAccountId,
  PlanId,
} from "@fartherfinance/frontend/api/Types";

import DrawerHeader from "../Components/DrawerHeader";
import Drawer from "@src/multiCustodian/components/Drawer/Drawer";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import FormH2 from "@src/sharedComponents/Forms/FormH2";
import FormNumberField from "@src/sharedComponents/Forms/FormNumberField";
import FormRadioField from "@src/sharedComponents/Forms/FormRadioField";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import { AutocompleteOption } from "@src/sharedComponents/SAODropdown/Dropdown";
import { extractAxiosErrorMessage, isAxiosErrorCode } from "@src/utils/axios";

import styles from "./Form.module.css";
import planStyles from "../plans.module.css";

const interestRateTypeOptions = [
  "FederalTargetLimit",
  "Prime",
  "Other",
  "InterestRateScheduleProvided",
] as const;
type InterestRateType = typeof interestRateTypeOptions[number];

const purposeOfCreditOptions = ["Liquidity/Expenses", "Other"] as const;
type PurposeOfCredit = typeof purposeOfCreditOptions[number];

const baseRateOptions = ["Plus", "Minus"] as const;
type BaseRate = typeof baseRateOptions[number];

const deliveryOptions = ["Yes", "No"] as const;
type Delivery = typeof deliveryOptions[number];

function isCollateralAccount(
  x: CollateralAccount | GetCollateralAccountsRes[number]
): x is CollateralAccount {
  return x.custodianAccountNumber !== null;
}

interface CollateralAccountAutoComplete extends AutocompleteOption {
  label: string;
  virtualAccountId: string;
}

interface InterestRateTypeAutoComplete extends AutocompleteOption {
  label: string;
  value: InterestRateType;
}

interface Form {
  AccountName: string | undefined;
  CustodianAccountNumber: CustodianAccountId | undefined;
  CollateralAccount: CollateralAccountAutoComplete | undefined;
  InterestRateType: InterestRateTypeAutoComplete | undefined;
  OtherInterestRateType: string | undefined;
  PurposeOfCredit: { label: PurposeOfCredit } | undefined;
  OtherPurposeOfCredit: string | undefined;
  BaseRate: BaseRate | undefined;
  BaseInterestRate: string | undefined;
  Spread: string | undefined;
  CreditAmount: string | undefined;
  Delivery: Delivery | undefined;
}

type StripUndefined<F extends object> = { [k in keyof F]: NonNullable<F[k]> };

interface Props {
  planId: PlanId;
  accountId: FartherAccountId;
  onClose: () => void;
  account: Account;
}

export default function AssetLending(props: Props): JSX.Element {
  const auth = useAdvisorRequestAuth();

  const advisorClients = useAdvisorClients(undefined, auth);

  const planDetails = usePlanDetailsV2(props.planId, auth);

  const defaultAccountHolder = advisorClients.data?.clients.find(
    (c) => c.clientId === planDetails.data?.plan.planHolder.clientId
  );

  const collateral = useCollateralAccount(
    defaultAccountHolder?.clientId ?? null,
    auth
  );

  const defaultValues: DefaultValues<Form> = useMemo(
    () => getDefaultValues(props.account),
    [props.account]
  );

  const filteredCollateralAccounts: CollateralAccount[] = useMemo(() => {
    return collateral.data !== undefined
      ? collateral.data.filter(isCollateralAccount)
      : [];
  }, [collateral.data]);

  const defaultCollateralAccountId: CollateralAccountAutoComplete | undefined =
    useMemo(() => {
      const matchingCollateral = filteredCollateralAccounts.find(
        (ca) =>
          ca.physicalAccountId ===
          props.account.accountInformation.assetLendingData
            ?.collateralPhysicalAccountId
      );

      if (matchingCollateral === undefined) {
        return undefined;
      }

      return {
        virtualAccountId: matchingCollateral.virtualAccountId,
        label: matchingCollateral.custodianAccountNumber,
      };
    }, [
      filteredCollateralAccounts,
      props.account.accountInformation.assetLendingData,
    ]);

  if (
    advisorClients.isLoading ||
    planDetails.isLoading ||
    collateral.isLoading
  ) {
    return (
      <Drawer
        transitionDuration={0}
        header={
          <DrawerHeader planId={props.planId} accountId={props.accountId} />
        }
        isDrawerOpen
        onClose={props.onClose}
      >
        <div className={planStyles.loadingContainer}>
          <LogoLoadingStill />
        </div>
      </Drawer>
    );
  }

  if (advisorClients.hasError || planDetails.hasError || collateral.hasError) {
    return (
      <Drawer
        transitionDuration={0}
        header={
          <DrawerHeader planId={props.planId} accountId={props.accountId} />
        }
        isDrawerOpen
        onClose={props.onClose}
      >
        <div>Error</div>
      </Drawer>
    );
  }

  // Data corruption
  if (defaultAccountHolder === undefined) {
    return (
      <Drawer
        transitionDuration={0}
        header={
          <DrawerHeader planId={props.planId} accountId={props.accountId} />
        }
        isDrawerOpen
        onClose={props.onClose}
      >
        <div>Error with data</div>
      </Drawer>
    );
  }

  return (
    <AssetLendingDrawer
      defaultValues={defaultValues}
      onClose={props.onClose}
      planId={props.planId}
      accountId={props.accountId}
      collateralAccounts={filteredCollateralAccounts}
      account={props.account}
      defaultCollateralAccount={defaultCollateralAccountId}
      accountHolder={defaultAccountHolder.clientId}
    />
  );
}

interface AssetLendingProps {
  defaultValues: DefaultValues<Form>;
  onClose: () => void;
  planId: PlanId;
  accountId: FartherAccountId;
  collateralAccounts: CollateralAccount[];
  account: Account;
  defaultCollateralAccount: CollateralAccountAutoComplete | undefined;
  accountHolder: ClientId;
}

function AssetLendingDrawer(props: AssetLendingProps): JSX.Element {
  const [isMutating, setIsMutating] = useState<boolean>(false);

  const statusNotification = useStatusNotification();

  const methods = useForm<Form>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: props.defaultValues,
  });

  const { control, getValues } = methods;

  const { isValid } = useFormState({
    control,
  });

  const interestRateType = useWatch<Form>({
    control,
    name: "InterestRateType",
  }) as Form["InterestRateType"] | undefined;

  const purposeOfCredit = useWatch<Form>({
    control,
    name: "PurposeOfCredit",
  }) as Form["PurposeOfCredit"] | undefined;

  const auth = useAdvisorRequestAuth();

  const callPutPlanAccountInfo = useSetPlanAccountInfoV2(auth);

  const submit = useCallback(async () => {
    if (!isValid) {
      return;
    }

    const values = getValues() as StripUndefined<Form>;

    const interestRateTypeDescription =
      values.InterestRateType.label === "Other"
        ? values.OtherInterestRateType
        : null;

    const purposeOfCredit =
      values.PurposeOfCredit.label === "Other"
        ? values.OtherPurposeOfCredit
        : values.PurposeOfCredit.label;

    const form: AccountInformationFormToSend = {
      accountType: "Asset Lending",
      accountTitle: values.AccountName,
      accountTarget: null,
      assetLendingData: {
        accountHolder: props.accountHolder,
        interestRateType: values.InterestRateType.value,
        interestRateTypeDescription,
        custodianAccountNumber: values.CustodianAccountNumber,
        collateralAccountId: values.CollateralAccount.virtualAccountId,
        purposeOfCredit,
        interestOperator: values.BaseRate === "Plus",
        delivery: values.Delivery === "Yes",
        baseRate: parseFloat(values.BaseInterestRate),
        spread: parseFloat(values.Spread),
        loanAmount: parseFloat(values.CreditAmount),
      },
    };

    try {
      setIsMutating(true);

      await callPutPlanAccountInfo({
        planId: props.planId,
        virtualAccountId: props.accountId,
        accountData: form,
      });

      statusNotification("Account updated", "Success");
      props.onClose();
    } catch (error) {
      const errorMessage = isAxiosErrorCode(400, error)
        ? extractAxiosErrorMessage(error)
        : "Error updating account";

      statusNotification(errorMessage, "Error");
      setIsMutating(false);
    }
  }, [callPutPlanAccountInfo, getValues, isValid, props, statusNotification]);

  const collateralAccounts = useMemo(
    () =>
      props.collateralAccounts.map(
        (ca): CollateralAccountAutoComplete => ({
          virtualAccountId: ca.virtualAccountId,
          label: ca.custodianAccountNumber,
        })
      ),
    [props.collateralAccounts]
  );

  const interestRates = useMemo(
    () =>
      interestRateTypeOptions.map(
        (ito): InterestRateTypeAutoComplete => ({
          value: ito,
          label: startCase(ito),
        })
      ),
    []
  );

  const pocOptions = useMemo(
    () => purposeOfCreditOptions.map((poc) => ({ label: poc })),
    []
  );

  return (
    <Drawer
      transitionDuration={0}
      header={
        <DrawerHeader planId={props.planId} accountId={props.accountId} />
      }
      footer={
        <Footer
          onSave={submit}
          saveDisabled={!isValid}
          closeDrawer={props.onClose}
          isMutating={isMutating}
        />
      }
      isDrawerOpen
      onClose={props.onClose}
    >
      <FormProvider {...methods}>
        <FormH1>Asset Lending</FormH1>

        <FormH2>Account Information</FormH2>

        <FlexWrapper>
          <FormTextField
            name="AccountName"
            label="Account Name"
            required="Must not be empty"
          />

          <FormTextField
            name="CustodianAccountNumber"
            label="Custodian Account Number"
            required="Must not be empty"
            placeholder="3ZT..."
          />

          <FormDropdownField
            name="CollateralAccount"
            label="Collateral Account"
            values={collateralAccounts}
            required="Must not be empty"
            defaultValue={props.defaultCollateralAccount}
          />
        </FlexWrapper>

        <FlexWrapper>
          <FormDropdownField
            name="InterestRateType"
            label="Interest Rate Type"
            values={interestRates}
            required="Must not be empty"
          />

          <FormTextField
            name="OtherInterestRateType"
            label="Other Interest Rate Type"
            required={
              interestRateType?.label === "Other" ? "Must not be empty" : false
            }
            style={
              interestRateType?.label === "Other"
                ? undefined
                : { display: "none" }
            }
          />
        </FlexWrapper>

        <FlexWrapper>
          <FormDropdownField
            name="PurposeOfCredit"
            label="Purpose of Credit"
            values={pocOptions}
            required="Must not be empty"
          />

          <FormTextField
            name="OtherPurposeOfCredit"
            label="Other Purpose of Credit"
            required={
              purposeOfCredit?.label === "Other" ? "Must not be empty" : false
            }
            style={
              purposeOfCredit?.label === "Other"
                ? undefined
                : { display: "none" }
            }
          />
        </FlexWrapper>

        <FlexWrapper>
          <FormNumberField
            name="BaseInterestRate"
            label="Base Interest Rate"
            endAdornment="%"
            textAlign="right"
            required="Must not be empty"
          />

          <FormRadioField
            name="BaseRate"
            label="'Base Rate' +/- 'Spread'"
            values={baseRateOptions}
            required="Please select an option"
          />

          <FormNumberField
            name="Spread"
            label="Spread"
            required="Must not be empty"
          />

          <FormNumberField
            name="CreditAmount"
            label="Credit Amount"
            required="Must not be empty"
            rules={{
              validate: {
                greaterThan0: () => {
                  return parseFloat(getValues("CreditAmount") ?? "0") > 0;
                },
              },
            }}
            startAdornment={"$"}
          />

          <FormRadioField
            name="Delivery"
            label="Delivery"
            values={deliveryOptions}
            required="Please select an option"
          />
        </FlexWrapper>
      </FormProvider>

      {isMutating && (
        <div className={styles.loading}>
          <LogoLoadingStill />
        </div>
      )}
    </Drawer>
  );
}

interface FooterProps {
  closeDrawer: () => void;
  saveDisabled: boolean;
  onSave: () => void;
  isMutating: boolean;
}

const Footer = (props: FooterProps): JSX.Element => {
  return (
    <div className={styles.footer}>
      <ButtonPrimary
        text={"Cancel"}
        onClick={props.closeDrawer}
        variant={"outlined"}
        buttonType={"primary"}
      />

      <ButtonPrimary
        text={"Save"}
        onClick={props.onSave}
        variant={"contained"}
        buttonType={"primary"}
        disabled={props.saveDisabled || props.isMutating}
      />
    </div>
  );
};

const getDefaultValues = (account: Account): DefaultValues<Form> => {
  {
    const accountInfo = account.accountInformation;

    const defaultAccountName = accountInfo.accountTitle;

    const defaultCustodianAccountNumber =
      accountInfo.assetLendingData?.custodianAccountNumber;

    const defaultInterestRateType =
      accountInfo.assetLendingData?.interestRateType;

    const defaultOtherInterestRateType =
      accountInfo.assetLendingData?.interestRateTypeDescription;

    const defaultPurposeOfCredit =
      accountInfo.assetLendingData?.purposeOfCredit !== "Liquidity/Expenses"
        ? "Other"
        : accountInfo.assetLendingData?.purposeOfCredit;

    const defaultOtherPurposeOfCredit =
      defaultPurposeOfCredit === "Other"
        ? accountInfo.assetLendingData?.purposeOfCredit
        : undefined;

    const defaultBaseRate = accountInfo.assetLendingData?.interestOperator
      ? "Plus"
      : "Minus";

    const defaultBaseInterestRate =
      accountInfo.assetLendingData?.baseRate ?? 0.0;

    const defaultSpread = accountInfo.assetLendingData?.spread ?? 0.0;

    const defaultCreditAmount = accountInfo.assetLendingData?.loanAmount ?? 0.0;

    const defaultDelivery = accountInfo.assetLendingData?.delivery
      ? "Yes"
      : "No";

    const interestRateType: InterestRateTypeAutoComplete | undefined =
      defaultInterestRateType
        ? {
            label: startCase(defaultInterestRateType),
            value: defaultInterestRateType,
          }
        : undefined;

    return {
      AccountName: defaultAccountName,
      CustodianAccountNumber: defaultCustodianAccountNumber ?? undefined,
      InterestRateType: interestRateType,
      OtherInterestRateType: defaultOtherInterestRateType ?? undefined,
      PurposeOfCredit: defaultPurposeOfCredit
        ? { label: defaultPurposeOfCredit }
        : undefined,
      OtherPurposeOfCredit: defaultOtherPurposeOfCredit ?? undefined,
      BaseRate: defaultBaseRate,
      BaseInterestRate: defaultBaseInterestRate.toString(),
      Spread: defaultSpread.toString(),
      CreditAmount: defaultCreditAmount.toString(),
      Delivery: defaultDelivery,
    };
  }
};
