import React from "react";

import Admin from "../Admin";
import CustomModelBillingPage from "@src/multiCustodian/components/Admin/CustomModelBilling/CustomModelBilling";

const CustomModelBilling = (): JSX.Element => {
  return (
    <Admin>
      <CustomModelBillingPage />
    </Admin>
  );
};

export default CustomModelBilling;
