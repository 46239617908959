import React, { useEffect, useMemo, useState } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { orderBy } from "lodash";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";

import usePortfolioAnalysisV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/usePortfolioAnalysisV2";
import useSleeves from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useSleeves";
import { AnyPortfolioV2 } from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";
import useTradingGroups from "@fartherfinance/frontend/api/TradingGroups/hooks/useTradingGroups";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import { captureException } from "@src/multiCustodian/services/tracking";
import BasicTableChip from "@src/sharedComponents/BasicTableChip/BasicTableChip";
import IconButton from "@src/sharedComponents/IconButton/IconButton";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";
import { State } from "@src/store";
import { pluralize } from "@src/utils/pluralize";

import Row from "./Row/Row";
import { RowProps } from "./Types";

import styles from "./PortfolioTable.module.css";

interface Props {
  showDirectIndexedFilter: boolean;
  portfolio: AnyPortfolioV2;
  onBack?: () => void;
  title: { description: string; secondaryDescription: string };
  rows: RowProps[];
  headerStyle?: React.CSSProperties;
}

const PortfolioTable = (props: Props): JSX.Element => {
  const [isShowingSleeves, setIsShowingSleeves] = useState<boolean>(false);
  const [isShowingAllSecurities, setIsShowingAllSecurities] =
    useState<boolean>(false);
  const [sortByWeight, setSortByWeight] = useState<boolean>(false);

  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));
  const history = useHistory();

  const clientAuth = useRequestAuth();
  const adisorAuth = useAdvisorRequestAuth();
  const tradingGroups = useTradingGroups(clientId, clientAuth);
  const sleeves = useSleeves(
    clientId ? clientId : advisorId,
    clientId ? clientAuth : adisorAuth
  );
  const portfolioAnalysis = usePortfolioAnalysisV2(
    props.portfolio.model.portfolioModelId,
    clientAuth
  );

  const noPortfolioAnalysisData = useMemo(() => {
    if (portfolioAnalysis.isLoading) {
      return false;
    }

    if (portfolioAnalysis.hasError) {
      return true;
    }

    if (
      portfolioAnalysis.data.portfolioCategoryBreakdown === null ||
      portfolioAnalysis.data.portfolioAssetClassBreakdown === null
    ) {
      return true;
    }

    return false;
  }, [portfolioAnalysis]);

  useEffect(() => {
    if (
      !isShowingAllSecurities &&
      !isShowingSleeves &&
      noPortfolioAnalysisData
    ) {
      setIsShowingAllSecurities(true);
    }
  }, [noPortfolioAnalysisData, isShowingAllSecurities, isShowingSleeves]);

  const resetSearchUrlParamAndReturnParamsString = useMemo(() => {
    const location = history.location;
    const params = new URLSearchParams(location.search);
    params.delete("search");
    params.delete("page");
    return params.toString();
  }, [history.location]);

  const anyRowsToClickInto = useMemo(
    () => props.rows.some((r) => r.onClick),
    [props.rows]
  );

  const tooltipSecondPartText: string = useMemo(() => {
    if (tradingGroups.data === undefined) {
      return "";
    }

    const tradingGroupsCurrentlyDirectIndexed = tradingGroups.data
      .filter((tg) => tg.portfolioId !== null)
      .filter(
        (tg) =>
          tg.portfolioId === props.portfolio.model.portfolioModelId ||
          tg.portfolioId === props.portfolio.model.directIndexingInverse
      )
      .filter((tg) => tg.directIndexing)
      .map((tg) => tg.displayName);

    if (tradingGroupsCurrentlyDirectIndexed.length === 0) {
      return "Direct-indexing is not turned on for any of your trading groups.";
    }

    return `Direct-indexing is turned on for the following trading groups: ${tradingGroupsCurrentlyDirectIndexed.join(
      ", "
    )}.`;
  }, [tradingGroups.data, props.portfolio]);

  const toggleDirectIndexed = () => {
    if (props.portfolio.model.directIndexingInverse !== null) {
      // change to the inverse model (either the non-Direct-Indexed or Direct-Indexed model) which is the directIndexingInverse when its not null
      history.push({
        pathname: `/Client/${clientId}/Investments/Portfolios/${props.portfolio.model.directIndexingInverse}/ModelDetails`,
        state: history.location.state,
      });
    }
  };

  const clearFilters = () => {
    if (props.portfolio.model.directIndexing === true) {
      toggleDirectIndexed();
    }

    if (isShowingSleeves) {
      setIsShowingSleeves(false);
    }

    if (isShowingAllSecurities) {
      setIsShowingAllSecurities(false);
    }
  };

  const showDirectIndexing =
    props.showDirectIndexedFilter &&
    props.portfolio.model.portfolioType !== "Custom" &&
    props.portfolio.model.directIndexingInverse !== null;

  const isDirectIndex = props.portfolio.model.directIndexing === true;

  const numSleeves = props.portfolio.positions.sleeves.length;
  const portfolioHasSleeves = numSleeves >= 1;

  const showHeadingWithChips = props.onBack === undefined;

  const allSecuritiesFlatList = useMemo(() => {
    return (portfolioAnalysis.data?.portfolioAssetClassBreakdown ?? []).flatMap(
      (acObj) => Object.values(acObj)[0].securities
    );
  }, [portfolioAnalysis.data]);

  const rowsContent = useMemo(() => {
    if (noPortfolioAnalysisData && isShowingAllSecurities) {
      const allSecuritiesFlatListBackup = (
        props.portfolio.positions.securities ?? []
      ).map((sec) => ({
        name: "",
        ticker: sec.ticker,
        value: sec.value,
      }));

      return orderBy(
        allSecuritiesFlatListBackup,
        sortByWeight
          ? [(el) => el.value, (el) => el.ticker]
          : [(el) => el.ticker],
        sortByWeight ? ["desc", "asc"] : ["asc"]
      ).map((sec) => {
        return (
          <Row
            key={sec.ticker}
            description={sec.ticker === "CASH$" ? "CASH" : sec.ticker}
            secondaryDescription={
              sec.ticker === "CASH$" ? "US Dollars" : sec.name
            }
            allocation={sec.value / 100}
          />
        );
      });
    }

    if (isShowingAllSecurities) {
      return orderBy(
        allSecuritiesFlatList,
        sortByWeight
          ? [(el) => parseFloat(el.percent), (el) => el.ticker]
          : [(el) => el.ticker],
        sortByWeight ? ["desc", "asc"] : ["asc"]
      ).map((sec) => {
        return (
          <Row
            key={sec.ticker}
            description={sec.ticker === "CASH$" ? "CASH" : sec.ticker}
            secondaryDescription={
              sec.ticker === "CASH$" ? "US Dollars" : sec.name
            }
            allocation={parseFloat(sec.percent)}
          />
        );
      });
    }

    if (isShowingSleeves) {
      const sleeveRows = props.portfolio.positions.sleeves.map((s) => {
        let sleeveName = "";
        if (sleeves.data !== undefined) {
          const matchedSleeve = sleeves.data.sleeves.find(
            (m) => m.sleeveId === s.sleeveId
          );

          if (matchedSleeve !== undefined) {
            sleeveName = matchedSleeve.displayName;
          } else {
            sleeveName = "--";

            const err = new Error(
              "Failed to look up selected sleeve from all sleeves using sleeveId"
            );
            captureException(err, {
              extra: {
                sleeveId: s.sleeveId,
                call: "sleeveLookup[s.sleeveId]",
                file: "PortfolioTable.tsx",
              },
            });
          }
        }

        return {
          ...s,
          name: sleeveName,
        };
      });

      const sleeveRowsOrdered = orderBy(
        sleeveRows,
        sortByWeight ? [(el) => el.weight, (el) => el.name] : [(el) => el.name],
        sortByWeight ? ["desc", "asc"] : ["asc"]
      ).map((s) => {
        return (
          <Link
            style={{ textDecoration: "none" }}
            key={s.sleeveId}
            to={
              clientId
                ? {
                    pathname: `/Client/${clientId}/Investments/Portfolios/${props.portfolio.model.portfolioModelId}/Sleeve/${s.sleeveId}/SleeveDetails`,
                    state: history.location.state,
                  }
                : {
                    pathname: `/Advisor/Investments/Sleeves/${s.sleeveId}/SleeveDetails`,
                    state: history.location.state,
                    search: resetSearchUrlParamAndReturnParamsString,
                  }
            }
          >
            <Row
              description={s.name ? s.name : <Skeleton width={100} />}
              secondaryDescription={"(Sleeve)"}
              allocation={s.weight / 100}
              onClick={() => undefined}
            />
          </Link>
        );
      });

      const looseSecurityRowsOrdered = orderBy(
        props.portfolio.positions.securities,
        sortByWeight
          ? [(el) => el.value, (el) => el.ticker]
          : [(el) => el.ticker],
        sortByWeight ? ["desc", "asc"] : ["asc"]
      ).map((sec) => {
        return (
          <Row
            key={sec.ticker}
            description={sec.ticker}
            secondaryDescription={""}
            allocation={sec.value / 100}
          />
        );
      });

      return [...sleeveRowsOrdered, ...looseSecurityRowsOrdered];
    }

    return orderBy(
      props.rows,
      sortByWeight
        ? [(el) => el.allocation, (el) => el.description]
        : [(el) => el.description],
      sortByWeight ? ["desc", "asc"] : ["asc"]
    ).map((r) => (
      <Row
        key={JSON.stringify(r)}
        description={r.description}
        secondaryDescription={r.secondaryDescription}
        allocation={r.allocation}
        onClick={r.onClick}
      />
    ));
  }, [
    sleeves.data,
    clientId,
    history,
    isShowingSleeves,
    isShowingAllSecurities,
    props.portfolio,
    props.rows,
    resetSearchUrlParamAndReturnParamsString,
    sortByWeight,
    allSecuritiesFlatList,
    noPortfolioAnalysisData,
  ]);

  const totalCalculatedWeight = useMemo(() => {
    if (noPortfolioAnalysisData && isShowingAllSecurities) {
      const total =
        props.portfolio.positions.securities.reduce(
          (acc, cur) => acc + cur.value,
          0
        ) / 10_000;

      return total.toLocaleString("en-US", {
        style: "percent",
        minimumFractionDigits: 2,
      });
    }

    if (isShowingSleeves) {
      const sleevesWeight =
        props.portfolio.positions.sleeves.reduce(
          (acc, cur) => acc + cur.weight,
          0
        ) / 10_000;

      const looseSecuritiesWeight =
        props.portfolio.positions.securities.reduce(
          (acc, cur) => acc + cur.value,
          0
        ) / 10_000;

      const totalWeight = sleevesWeight + looseSecuritiesWeight;

      return totalWeight.toLocaleString("en-US", {
        style: "percent",
        minimumFractionDigits: 2,
      });
    } else {
      const weight =
        props.rows.reduce((prev, cur) => prev + cur.allocation, 0) / 100;

      return weight.toLocaleString("en-US", {
        style: "percent",
        minimumFractionDigits: 2,
      });
    }
  }, [
    props.portfolio,
    props.rows,
    isShowingSleeves,
    isShowingAllSecurities,
    noPortfolioAnalysisData,
  ]);

  if (tradingGroups.hasError) {
    return <div>Error retrieving trading groups</div>;
  }

  const clickShowAllSecuritiesChip = () => {
    if (noPortfolioAnalysisData && isShowingAllSecurities) {
      // if noPortfolioAnalysisData === true we want to only show a flat list of all securities or sleeves
      // we DONT want to show the drill in portfolio analysis
      // so keep isShowingAllSecurities === true or isShowingSleeves === true
      // user can click on Show Sleeves chip
      return;
    }

    setIsShowingAllSecurities(!isShowingAllSecurities);
    setIsShowingSleeves(false);
  };

  const clickShowSleevesChip = () => {
    if (noPortfolioAnalysisData && isShowingSleeves) {
      // if noPortfolioAnalysisData === true we want to only show a flat list of all securities or sleeves
      // we DONT want to show the drill in portfolio analysis
      // so keep isShowingAllSecurities === true or isShowingSleeves === true
      // user can click on Show All Securities chip
      return;
    }

    setIsShowingSleeves(!isShowingSleeves);
    setIsShowingAllSecurities(false);
  };

  let firstColumnName = "Category";
  if (noPortfolioAnalysisData) {
    firstColumnName = "Asset";
  } else if (isShowingAllSecurities) {
    firstColumnName = "Security";
  } else if (isShowingSleeves) {
    firstColumnName = "Name";
  }

  const showClearChip =
    (isDirectIndex || isShowingSleeves || isShowingAllSecurities) &&
    !noPortfolioAnalysisData;

  let headerRightDescription = props.title.secondaryDescription;
  if (isShowingSleeves) {
    headerRightDescription = `${numSleeves} ${
      numSleeves === 1 ? "Sleeve" : "Sleeves"
    }`;
  } else if (noPortfolioAnalysisData) {
    headerRightDescription = pluralize(
      props.portfolio.positions.securities,
      "Security",
      true
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header} style={props.headerStyle}>
        <div className={styles.headerLeft}>
          {props.onBack !== undefined && (
            <IconButton
              size="small"
              iconClassName={styles.headerBackIcon}
              onClick={props.onBack}
              IconComponent={ArrowBackIcon}
            />
          )}
          <div className={styles.description}>{props.title.description}</div>
        </div>

        <div className={styles.headerRight}>{headerRightDescription}</div>
      </div>

      {showHeadingWithChips && (
        <div className={styles.filterContainer}>
          <div className={styles.filterContainerLeft}>
            {showDirectIndexing && (
              <BasicTableChip
                text={"Show Direct-Indexed"}
                isSelected={isDirectIndex}
                onClick={toggleDirectIndexed}
              >
                <Tooltip
                  tooltipText={
                    <>
                      <div className={styles.tooltipTextTop}>
                        Direct-indexing seeks to replicate the exposures of a
                        fund or ETF by investing in the underlying securities.
                      </div>

                      {tooltipSecondPartText && (
                        <div className={styles.tooltipTextBottom}>
                          {tooltipSecondPartText}
                        </div>
                      )}
                    </>
                  }
                  placement="top"
                >
                  <InfoOutlinedIcon
                    className={
                      isDirectIndex
                        ? styles.directIndexPillInfoIcon
                        : styles.notDirectIndexPillInfoIcon
                    }
                  />
                </Tooltip>
              </BasicTableChip>
            )}

            <BasicTableChip
              text={"Show All Securities"}
              isSelected={isShowingAllSecurities}
              onClick={clickShowAllSecuritiesChip}
            />

            {portfolioHasSleeves && (
              <BasicTableChip
                text={"Show Sleeves"}
                isSelected={isShowingSleeves}
                onClick={clickShowSleevesChip}
              />
            )}
          </div>

          <div className={styles.filterContainerRight}>
            {showClearChip && (
              <div className={styles.clearPill} onClick={clearFilters}>
                <div className={styles.clearPillText}>Clear</div>

                <ClearIcon className={styles.clearPillIcon} />
              </div>
            )}

            {noPortfolioAnalysisData && (
              <div className={styles.noPortfolioAnalysisDataWarning}>
                Category breakdown unavailable
              </div>
            )}
          </div>
        </div>
      )}

      <div className={styles.body}>
        <div
          className={styles.tableHeader}
          style={
            anyRowsToClickInto && !isShowingAllSecurities
              ? { padding: "0px 30px 12px 0px" }
              : {}
          }
        >
          <div
            className={styles.headerColumn}
            onClick={() => setSortByWeight(false)}
          >
            {firstColumnName}
          </div>

          <div
            className={styles.headerColumn}
            onClick={() => setSortByWeight(true)}
          >
            {noPortfolioAnalysisData ? "Allocation" : "Weight"}
          </div>
        </div>

        <div>{rowsContent}</div>
      </div>

      <div
        className={styles.footer}
        style={
          anyRowsToClickInto && !isShowingAllSecurities
            ? { padding: "0px 46px 16px 16px" }
            : {}
        }
      >
        <div>Total</div>
        <div>{totalCalculatedWeight}</div>
      </div>
    </div>
  );
};

export default PortfolioTable;
