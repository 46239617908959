import { ClientAffiliations } from "@fartherfinance/frontend/api/Entity/requests/getClientProfile";

import {
  BrokerDealerAffiliated,
  CustodianAffiliated,
  FamilyMemberBrokerAffiliated,
  FartherEmployeeRelated,
  Form,
  PublicCompanyAffiliated,
  StockExchangeAffiliated,
} from "../types";

export default function mapDefaultValues(
  clientAffiliations: ClientAffiliations
): Form {
  const {
    isUsOfficial,
    exchangeName,
    publicCompanyName,
    brokerDealerName,
    fartherEmployeeRelative,
    brokerDealerRelative,
    custodianName,
  } = clientAffiliations;

  const stockExchange: StockExchangeAffiliated = {
    ...(exchangeName === null
      ? { StockExchange: "No" }
      : {
          StockExchange: "Yes",
          StockExchangeName: { label: exchangeName },
          DocumentAlreadyUploaded: true,
        }),
  };

  const publicCompany: PublicCompanyAffiliated = {
    ...(publicCompanyName === null
      ? { PublicCompany: "No" }
      : { PublicCompany: "Yes", PublicCompanyName: publicCompanyName }),
  };

  const custodian: CustodianAffiliated = {
    ...(custodianName === null
      ? { CustodianAffiliated: "No" }
      : {
          CustodianAffiliated: "Yes",
          CustodianName: { label: custodianName },
        }),
  };

  const broker: BrokerDealerAffiliated = {
    ...(brokerDealerName === null
      ? { BrokerDealerAffiliated: "No" }
      : {
          BrokerDealerAffiliated: "Yes",
          BrokerDealerName: brokerDealerName,
        }),
  };

  const fartherEmployeeFirst =
    fartherEmployeeRelative === null ? "" : fartherEmployeeRelative.name.first;

  const fartherEmployeeLast =
    fartherEmployeeRelative === null ? "" : fartherEmployeeRelative.name.last;

  const fartherEmployeeFull =
    `${fartherEmployeeFirst} ${fartherEmployeeLast}`.trim();

  const fartherRelated: FartherEmployeeRelated = {
    ...(fartherEmployeeRelative === null
      ? { FartherEmployeeRelated: "No" }
      : {
          FartherEmployeeRelated: "Yes",
          FartherEmployeeFullName: fartherEmployeeFull,
          FartherEmployeeRelationship: {
            label: fartherEmployeeRelative.relationship,
          },
        }),
  };

  const familyMemberFirst =
    brokerDealerRelative === null ? "" : brokerDealerRelative.name.first;

  const familyMemberLast =
    brokerDealerRelative === null ? "" : brokerDealerRelative.name.last;

  const familyMemberFull = `${familyMemberFirst} ${familyMemberLast}`.trim();

  const familyBroker: FamilyMemberBrokerAffiliated = {
    ...(brokerDealerRelative === null
      ? { FamilyMemberBrokerAffiliated: "No" }
      : {
          FamilyMemberBrokerAffiliated: "Yes",
          FamilyBrokerDealerName: familyMemberFull,
          FamilyBrokerDealerCompany: brokerDealerRelative.company,
          FamilyBrokerDealerRelationship: {
            label: brokerDealerRelative.relationship,
          },
        }),
  };

  return {
    ...stockExchange,
    ...publicCompany,
    ...custodian,
    ...broker,
    ...fartherRelated,
    ...familyBroker,
    USOfficial: isUsOfficial === true ? "Yes" : "No",
  };
}
