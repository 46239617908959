import React from "react";

import { range } from "lodash";

import BorderBoxBody from "@src/sharedComponents/BorderBox/BorderBoxBody";

import EquivalentSecuritiesAdminTableLoadingRow from "./EquivalentSecuritiesAdminTableLoadingRow";

import styles from "./EquivalentSecuritiesAdminTable.module.css";

const EquivalentSecuritiesAdminTableLoading: React.FC = () => {
  return (
    <BorderBoxBody className={styles.borderBoxBody} showTopBorder={true}>
      {range(5).map((_, idx) => (
        <EquivalentSecuritiesAdminTableLoadingRow key={idx} />
      ))}
    </BorderBoxBody>
  );
};

export default EquivalentSecuritiesAdminTableLoading;
