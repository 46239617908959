import { Magic, MagicUserMetadata } from "magic-sdk";
import ms, { StringValue } from "ms";

import { captureException } from "./multiCustodian/services/tracking";

if (
  process.env.MAGIC_KEY === undefined ||
  process.env.MAGIC_ADVISORS_KEY === undefined ||
  process.env.MAGIC_TOKEN_LIFESPAN_IN_STRINGVALUE === undefined ||
  process.env.MAGIC_TOKEN_LIFESPAN_ADVISORS_IN_STRINGVALUE === undefined
) {
  throw new Error("Need to set magic keys");
}

const magic = new Magic(process.env.MAGIC_KEY);
const magicAdvisors = new Magic(process.env.MAGIC_ADVISORS_KEY);

const defaultMagicTokenLifespanInMs = ms("6hours");

const advisorTimeoutInMs =
  ms(process.env.MAGIC_TOKEN_LIFESPAN_ADVISORS_IN_STRINGVALUE as StringValue) ??
  // If `MAGIC_TOKEN_LIFESPAN_ADVISORS_IN_STRINGVALUE` is not a valid StringValue `ms()` returns undefined so we default it to make it a valid number
  defaultMagicTokenLifespanInMs;

const clientTimeoutInMs =
  ms(process.env.MAGIC_TOKEN_LIFESPAN_IN_STRINGVALUE as StringValue) ??
  // If `MAGIC_TOKEN_LIFESPAN_IN_STRINGVALUE` is not a valid StringValue `ms()` returns undefined so we default it to make it a valid number
  defaultMagicTokenLifespanInMs;

export type LoginType = "Client" | "Advisor";

export const magicCheckLogin = async (
  whichAuthRealm: LoginType
): Promise<boolean> => {
  const magicPromise =
    whichAuthRealm === "Advisor"
      ? magicAdvisors.user.isLoggedIn()
      : magic.user.isLoggedIn();

  /* Sometimes the `isLoggedIn` stalls out and does not return in a reasonable 
    time. Throw a timeout error then. */
  const timeoutPromise = new Promise<boolean>(
    (_res, rej) =>
      setTimeout(() => {
        const err = new Error(`isLoggedIn() Timeout for ${whichAuthRealm}`);

        rej(err);
      }, ms("10sec")) // 10sec is arbitrary
  );

  const race = Promise.race<boolean>([magicPromise, timeoutPromise]).catch(
    (err) => {
      // Catch it to log it to APM then re-throw it
      captureException(err, { function: "magicCheckLogin", whichAuthRealm });
      throw err;
    }
  );

  return race;
};

class MagicGetIdTokenReturnNull extends Error {
  constructor() {
    super(`Magic getIdToken returned null not string`);
  }
}

export const magicGetIdToken = async (
  whichAuthRealm: LoginType,
  lifetimeInSec?: number
): Promise<string> => {
  const defaultLifeTimeInSec =
    (whichAuthRealm === "Advisor" ? advisorTimeoutInMs : clientTimeoutInMs) /
    1000;

  const lifetimeWeAreAskingFor = Math.ceil(
    lifetimeInSec ?? defaultLifeTimeInSec
  );

  const didToken: string | null =
    whichAuthRealm === "Advisor"
      ? await magicAdvisors.user.getIdToken({
          lifespan: lifetimeWeAreAskingFor,
        })
      : await magic.user.getIdToken({
          lifespan: lifetimeWeAreAskingFor,
        });

  if (didToken === null) {
    console.log("magicGetIdToken got null response - getIdToken", didToken);
    throw new MagicGetIdTokenReturnNull();
  }

  try {
    const metadata = getMagicMetadata(didToken);
    if (metadata !== null) {
      const timeLeft = metadata.ext - Date.now() / 1000;
      console.log(
        `Get token expires in ${timeLeft}sec or ${ms(timeLeft * 1000)}`
      );
    }
  } catch (e) {
    if (
      e instanceof Error &&
      (e.name.includes("is not valid JSON") ||
        e.message.includes("is not valid JSON"))
    ) {
      // noop
      console.error("magicGetIdToken getMagicMetadata JSON error", e, didToken);
    } else {
      console.error("magicGetIdToken getMagicMetadata", e, didToken);
    }
  }

  return didToken;
};

export const magicGetUserMetadata = async (
  whichAuthRealm: LoginType
): Promise<MagicUserMetadata> => {
  const userMetadata: MagicUserMetadata =
    whichAuthRealm === "Advisor"
      ? await magicAdvisors.user.getMetadata()
      : await magic.user.getMetadata();

  return userMetadata;
};

// https://magic.link/docs/auth/introduction/decentralized-id#decentralized-id-token-specification
type MagicDiD = [string, string];
interface MagicMetaData {
  iat: number; // Issued at timestamp (UTC in seconds).
  ext: number; // Expiration timestamp (UTC in seconds).
  iss: string; // Issuer (the signer, the "user"). This field is represented as a Decentralized Identifier populated with the user's Ethereum public key.
  sub: string; // The "subject" of the request. This field is populated with the user's Magic entity ID. Note: this is separate from the user's Ethereum public key.
  aud: string; // Identifies the project space. This field is populated with the application's Magic entity ID.
  nbf: number; // Not valid before timestamp (UTC in seconds).
  tid: string; // Unique token identifier.
  add: string; // An encrypted signature of arbitrary, serialized data. The usage of this field is up to the developer and use-case dependent. It's handy for validating information passed between client and server. The raw data must already be known to the developer in order to recover the token!
}

export function getMagicMetadata(did: string): MagicMetaData | null {
  try {
    const decoded = window.atob(did);

    try {
      const [_proof, metadataAsJSON] = JSON.parse(decoded) as MagicDiD;

      try {
        const metadata = JSON.parse(metadataAsJSON) as MagicMetaData;
        return metadata;
      } catch (e) {
        console.error(
          "getMagicMetadata parse metadataAsJSON (did, decoded, metadata, e)",
          did,
          decoded,
          metadataAsJSON,
          e
        );
        return null;
      }
    } catch (e) {
      console.error(
        "getMagicMetadata parse decoded (did, decoded, e)",
        did,
        decoded,
        e
      );
      return null;
    }
  } catch (e) {
    console.error("getMagicMetadata atob threw (did, e)", did, e);
    return null;
  }
}
