import React, { useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import { Box, Collapse, Fade, Stack, Typography } from "@mui/material";

import { TASK_TITLE_MAX_LENGTH } from "@fartherfinance/frontend/api/Tasks/constants/common";
import useUpdateTask from "@fartherfinance/frontend/api/Tasks/hooks/useUpdateTask";
import { Task } from "@fartherfinance/frontend/api/Tasks/Types";

import { TASK_TITLE_MAX_LENGTH_ERROR_MSG } from "../../../common";
import { useTaskingPermissions } from "../../../hooks";
import { isTaskFieldEditingRestricted } from "../../../utils";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { trackEvent } from "@src/multiCustodian/services/tracking";
import IconButton from "@src/sharedComponents/IconButton/IconButton";
import TextInput from "@src/sharedComponents/TextInput/TextInput";
import { extractAxiosErrorMessage } from "@src/utils/axios";

import styles from "./TaskTitle.module.css";

interface TaskTitleProps {
  task: Task;
  openAlertDialog: () => void;
}

export default function TaskTitle({
  task,
  openAlertDialog,
}: TaskTitleProps): JSX.Element {
  const [isHovering, setIsHovering] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [titleValue, setTitleValue] = useState(task.title);

  const auth = useAdvisorRequestAuth();
  const callUpdateTask = useUpdateTask(auth);
  const statusNotification = useStatusNotification();
  const { taskPermissions } = useTaskingPermissions();

  const isInputErrored = titleValue.length > TASK_TITLE_MAX_LENGTH;

  const handleUpdateTask = async (): Promise<void> => {
    try {
      setIsEditingTitle(false);
      await callUpdateTask({
        taskId: task.id,
        request: { title: titleValue },
      });

      trackEvent({ name: "Advisor Tasks Update" });
    } catch (error) {
      // INFO: rollback to the initial value
      setTitleValue(task.title);
      statusNotification(
        extractAxiosErrorMessage(error, "Failed to update task."),
        "Error"
      );
    }
  };

  const handleMouseEnter = (): void => {
    setIsHovering(true);
  };

  const handleMouseLeave = (): void => {
    setIsHovering(false);
  };

  const handleSetEditingMode = (): void => {
    setIsEditingTitle(true);
  };

  const handleCancelEditingMode = (): void => {
    setTitleValue(task.title);
    setIsEditingTitle(false);
    setIsHovering(false);
  };

  const handleTitleValue = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    setTitleValue(event.target.value);
  };

  return (
    <Stack className={styles.container}>
      {isEditingTitle ? (
        <TextInput
          value={titleValue}
          onChange={handleTitleValue}
          helperText={isInputErrored && TASK_TITLE_MAX_LENGTH_ERROR_MSG}
          error={isInputErrored}
          inputProps={{
            className: styles.title,
          }}
        />
      ) : (
        <Stack
          direction="row"
          justifyContent="space-between"
          gap="10px"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Stack direction="row" alignItems="center" gap="5px">
            <Typography className={styles.title}>{titleValue}</Typography>

            {isTaskFieldEditingRestricted("title", task) === false && (
              <Fade in={isHovering}>
                <Box className={styles.editIconContainer}>
                  <IconButton
                    onClick={handleSetEditingMode}
                    IconComponent={EditIcon}
                  />
                </Box>
              </Fade>
            )}
          </Stack>
          {taskPermissions(task).isAllowedToDelete && (
            <Typography
              className={styles.deleteAction}
              onClick={openAlertDialog}
            >
              Delete Task
            </Typography>
          )}
        </Stack>
      )}
      <Collapse in={isEditingTitle}>
        <Stack
          className={styles.updateActions}
          direction="row"
          justifyContent="flex-end"
          spacing={1}
        >
          <Button
            variant="outlined"
            buttonType="primary"
            text="Cancel"
            onClick={handleCancelEditingMode}
          />
          <Button
            variant="contained"
            buttonType="primary"
            text="Save"
            disabled={isInputErrored}
            onClick={handleUpdateTask}
          />
        </Stack>
      </Collapse>
    </Stack>
  );
}
