import React from "react";

import { PlanDetails } from "@fartherfinance/frontend/api/Accounts/requests/getPlanDetailsV2";
import { ManualAccount } from "@fartherfinance/frontend/api/ExternalAccount/requests/getExternalAccounts";

import PlanTableComponent from "../table/PlanTable";
import { isManualAccount } from "@src/multiCustodian/pages/Dashboard/Funding/utils";

import styles from "../table/table.module.css";

const headers = ["Type", "Funding Account"];
const cellWidthPercentages = [40, 60];

interface Props {
  planDetails: PlanDetails | null;
  setChosenFundingAccount: (fa: ManualAccount) => void;
}

const TransferAuthorizationTable = (props: Props): JSX.Element => {
  return (
    <PlanTableComponent
      headers={headers}
      cellWidthPercentages={cellWidthPercentages}
      tableContent={(props.planDetails?.resources.fundingAccounts ?? [])
        .filter(isManualAccount)
        .map((fa) => (
          <TableRow
            key={fa.accountId}
            faccount={fa}
            setChosenFundingAccount={props.setChosenFundingAccount}
          />
        ))}
      placeholderText={
        props.planDetails === null
          ? "Loading..."
          : "Add transfer authorization to this plan."
      }
    />
  );
};

export default TransferAuthorizationTable;

interface TableRowProps {
  faccount: ManualAccount;
  setChosenFundingAccount: (fa: ManualAccount) => void;
}

const TableRow = ({
  faccount,
  setChosenFundingAccount,
}: TableRowProps): JSX.Element => {
  return (
    <tr
      className={styles.tableRow}
      onClick={() => setChosenFundingAccount({ ...faccount })}
    >
      <td
        className={styles.tableCol}
        style={{ width: cellWidthPercentages[0] + "%" }}
      >
        <div className={styles.tableCol_cell}>Standing Instructions</div>
      </td>

      <td
        className={styles.tableCol}
        style={{ width: cellWidthPercentages[1] + "%" }}
      >
        <div className={styles.tableCol_cell}>
          {`${faccount.details.bank.name} - ${faccount.details.account.accountTitle}`}
        </div>
      </td>
    </tr>
  );
};
