import React, { useMemo } from "react";

import opacity from "hex-color-opacity";
import { isNil, orderBy, uniqBy } from "lodash";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import { useElementSize } from "usehooks-ts";

import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import { ProjectionsGraphData } from "@src/multiCustodian/components/PerformanceGroups/Projections/Types";
import createResultsObject from "@src/multiCustodian/components/PerformanceGroups/Projections/utils/createResultsObject";
import { formatBalance } from "@src/multiCustodian/components/PerformanceGroups/Shared/helpers";

import styles from "./ProjectionsGraph.module.css";

interface IncomingProps {
  monteCarloData: ProjectionsGraphData[];
  color90pct: string;
  color50pct: string;
  color10pct: string;
  graphHeight: number;
}

/* GRAPH SETTINGS */
const YAxisWidth = 90;
const XAxisHeight = 20;
const strokeWidth = 3;
const yTickCount = 5;
/* dx: moves YTick labels left
    dy: moves XTick labels down */
const dx = -8;
const dy = 10;
const fontSize = "14px";

const customHoverLines = ({
  x,
  y,
  color,
  graphHeight,
}: {
  x: number;
  y: number;
  color: string;
  graphHeight: number;
}) => {
  const innerGraphHeight = graphHeight - XAxisHeight;
  return (
    <>
      {/* Horizontal dash line */}
      <line
        x1={YAxisWidth}
        y1={y}
        x2={x}
        y2={y}
        stroke={color}
        strokeDasharray="4 3"
      />
      {/* Vertical dash line */}
      <line
        x1={x}
        y1={innerGraphHeight}
        x2={x}
        y2={y}
        stroke="white"
        strokeDasharray="4 2"
      />
    </>
  );
};

const topHoverLine = ({
  x,
  y,
  color,
  graphHeight,
}: {
  x: number;
  y: number;
  color: string;
  graphHeight: number;
}) => {
  const innerGraphHeight = graphHeight - XAxisHeight;
  return (
    <>
      {/* Vertical dash line */}
      <line
        x1={x}
        y1={innerGraphHeight}
        x2={x}
        y2={y}
        stroke={color}
        strokeDasharray="4 2"
      />
    </>
  );
};

const CustomTooltip = (
  props: TooltipProps<number, string>,
  colors: { color90Pct: string; color50Pct: string; color10Pct: string }
) => {
  const { active, payload } = props;
  const validPayload = !isNil(payload) && payload[0] !== undefined;

  if (
    active &&
    validPayload !== false &&
    validPayload !== undefined &&
    props.payload
  ) {
    const pct90 = props.payload.find((p) => p.name === "results90Pct");
    const pct50 = props.payload.find((p) => p.name === "results50Pct");
    const pct10 = props.payload.find((p) => p.name === "results10Pct");

    const year = props.payload[0].payload.yearIndex + new Date().getFullYear();

    return (
      <div className={styles.graphTooltip}>
        <div className={styles.graphTooltipYear}>{year}</div>

        {pct90 !== undefined && pct90.value !== undefined && (
          <div
            className={styles.graphTooltipPct}
            style={{
              color: colors.color90Pct,
            }}
          >
            <div className={styles.graphTooltipTextLeft}>90%:</div>

            <div className={styles.graphTooltipTextRight}>
              {formatBalance(pct90.value)}
            </div>
          </div>
        )}

        {pct50 !== undefined && pct50.value !== undefined && (
          <div
            className={styles.graphTooltipPct}
            style={{
              color: colors.color50Pct,
            }}
          >
            <div className={styles.graphTooltipTextLeft}>50%:</div>

            <div className={styles.graphTooltipTextRight}>
              {formatBalance(pct50.value)}
            </div>
          </div>
        )}

        {pct10 !== undefined && pct10.value !== undefined && (
          <div
            className={styles.graphTooltipPct}
            style={{
              color: colors.color10Pct,
            }}
          >
            <div className={styles.graphTooltipTextLeft}>10%:</div>

            <div className={styles.graphTooltipTextRight}>
              {formatBalance(pct10.value)}
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return <></>;
  }
};

const customDot = (
  props: { cx: number; cy: number; payload: { to: string }; dataKey: string },
  cb: undefined | ((x: string) => void),
  colorHex: string,
  backgroundColor: string,
  hoverLineColor: string,
  graphHeight: number
) => {
  if (!props.cx || !props.cy) {
    return <></>;
  }

  const { to } = props.payload;
  if (cb) {
    if (to) {
      cb(to);
    }
  }

  /* '<circles >' derived from graph_dot_<color>.svg */
  /* Fill opacity added to the center circle for transparency */
  /* The svg cannot be directly served as a component */
  /* properties should be converted to camel Case */
  return (
    <>
      {props.dataKey === "results90Pct" ? (
        <>
          {topHoverLine({
            x: props.cx,
            y: props.cy,
            color: hoverLineColor,
            graphHeight: graphHeight,
          })}
          <circle
            cx={props.cx}
            cy={props.cy}
            r={8}
            fill={backgroundColor}
            fillOpacity={1}
            stroke={colorHex}
            strokeWidth={1.4}
          />
          <circle cx={props.cx} cy={props.cy} r={2.5} fill={colorHex} />
        </>
      ) : props.dataKey === "results50Pct" ? (
        <>
          {/* hover lines are only for the yellow line */}
          {customHoverLines({
            x: props.cx,
            y: props.cy,
            color: hoverLineColor,
            graphHeight: graphHeight,
          })}
          <circle
            cx={props.cx}
            cy={props.cy}
            r={8}
            fill={backgroundColor}
            fillOpacity={1}
            stroke={colorHex}
            strokeWidth={1.4}
          />
          <circle cx={props.cx} cy={props.cy} r={2.5} fill={colorHex} />
        </>
      ) : props.dataKey === "results10Pct" ? (
        <>
          <circle
            cx={props.cx}
            cy={props.cy}
            r={8}
            fill={backgroundColor}
            fillOpacity={1}
            stroke={colorHex}
            strokeWidth={1.4}
          />
          <circle cx={props.cx} cy={props.cy} r={2.5} fill={colorHex} />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default function ProjectionsGraph(props: IncomingProps) {
  const {
    color: { $borderSelected, $borderBold, $textSubtle },
  } = useTheme();

  const hoverLineColor = $borderSelected;
  const cartesianGridStroke = $borderBold;
  const xAxisStroke = $borderBold;
  const yAxisStroke = $textSubtle;

  const area90Stroke = props.color90pct;
  const area50Stroke = props.color50pct;
  const area10Stroke = props.color10pct;

  const area90Fill = opacity(props.color90pct, 0.2);
  const area50Fill = opacity(props.color50pct, 0.2);
  const area10Fill = opacity(props.color10pct, 0.2);

  const results10Pct = useMemo(() => {
    return createResultsObject(props.monteCarloData, "results10Pct");
  }, [props.monteCarloData]);

  const results50Pct = useMemo(() => {
    return createResultsObject(props.monteCarloData, "results50Pct");
  }, [props.monteCarloData]);

  const results90Pct = useMemo(() => {
    return createResultsObject(props.monteCarloData, "results90Pct");
  }, [props.monteCarloData]);

  const graphData = useMemo(() => {
    const data = uniqBy(props.monteCarloData, "yearIndex").map((d) => ({
      results10Pct: results10Pct[d.yearIndex].balance,
      results50Pct: results50Pct[d.yearIndex].balance,
      results90Pct: results90Pct[d.yearIndex].balance,
      yearIndex: d.yearIndex,
    }));

    return orderBy(data, (d) => d.yearIndex, "asc");
  }, [props.monteCarloData, results10Pct, results50Pct, results90Pct]);

  const [chartRef, { width }] = useElementSize();

  return (
    <div
      className={styles.graphContainer}
      ref={chartRef}
      style={{ height: `${props.graphHeight}px` }}
    >
      <div className={styles.graph}>
        <ComposedChart
          width={width}
          height={props.graphHeight}
          data={graphData}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid
            vertical={false}
            stroke={cartesianGridStroke}
            strokeDasharray="0"
            strokeWidth={1}
          />
          <XAxis
            style={{ fontSize }}
            height={XAxisHeight}
            dataKey={"yearIndex"}
            stroke={xAxisStroke}
            tickLine={false}
            dy={dy}
            tick={false}
          />
          <YAxis
            style={{ fontSize }}
            width={YAxisWidth}
            tickFormatter={(balance: number) => formatBalance(balance)}
            stroke={yAxisStroke}
            tickCount={yTickCount}
            tickLine={false}
            axisLine={false}
            dx={dx}
          />
          <Tooltip
            cursor={false}
            content={(p: TooltipProps<number, string>) =>
              CustomTooltip(p, {
                color10Pct: props.color10pct,
                color50Pct: props.color50pct,
                color90Pct: props.color90pct,
              })
            }
          />
          <Area
            type={"linear"}
            strokeWidth={strokeWidth}
            dataKey={"results90Pct"}
            stroke={area90Stroke}
            fill={area90Fill}
            fillOpacity={1}
            activeDot={(dotProps) =>
              customDot(
                dotProps,
                undefined,
                area90Stroke,
                area90Fill,
                hoverLineColor,
                props.graphHeight
              )
            }
            isAnimationActive={false}
            strokeLinejoin="round" //*Important for Safari* - prevents rendering of small bumps in the stroke line
          />
          <Area
            type={"linear"}
            strokeWidth={strokeWidth}
            dataKey={"results50Pct"}
            stroke={area50Stroke}
            fill={area50Fill}
            fillOpacity={1}
            activeDot={(dotProps) =>
              customDot(
                dotProps,
                undefined,
                area50Stroke,
                area50Fill,
                hoverLineColor,
                props.graphHeight
              )
            }
            isAnimationActive={false}
            strokeLinejoin="round" //*Important for Safari* - prevents rendering of small bumps in the stroke line
          />
          <Area
            type={"linear"}
            strokeWidth={strokeWidth}
            dataKey={"results10Pct"}
            stroke={area10Stroke}
            fill={area10Fill}
            fillOpacity={1}
            activeDot={(dotProps) =>
              customDot(
                dotProps,
                undefined,
                area10Stroke,
                area10Fill,
                hoverLineColor,
                props.graphHeight
              )
            }
            isAnimationActive={false}
            strokeLinejoin="round" //*Important for Safari* - prevents rendering of small bumps in the stroke line
          />
        </ComposedChart>
      </div>
    </div>
  );
}
