import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

export const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    padding: 0,

    [theme.breakpoints.down("xl")]: {
      padding: theme.spacing(0, pxToSpacing(30)),
    },
  },
}));
