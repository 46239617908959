import React from "react";

import { orderBy } from "lodash";

import { ReceivedInvitation } from "@fartherfinance/frontend/api/Sharing/Types";

import AcceptedInvitationRow from "./AcceptedInvitationRow";
import PendingInvitationRow from "./PendingInvitationRow";

import styles from "./SharedWithYouTable.module.css";

interface Props {
  invitations: ReceivedInvitation[];
}

const SharedWithYouTable: React.FC<Props> = ({ invitations }) => {
  const orderedInvitations = orderBy(
    invitations,
    [(inv) => inv.status === "Pending", (inv) => inv.createdAt],
    ["desc", "desc"]
  );
  return (
    <>
      <div className={styles.heading}>Shared With You</div>

      <div className={styles.table}>
        {orderedInvitations.map((invitation) =>
          invitation.status === "Pending" ? (
            <PendingInvitationRow
              key={invitation.invitationId}
              invitation={invitation}
            />
          ) : (
            <AcceptedInvitationRow
              key={invitation.invitationId}
              invitation={invitation}
            />
          )
        )}
      </div>
    </>
  );
};

export default SharedWithYouTable;
