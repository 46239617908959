import React, { useState } from "react";

import { FormProvider, useForm } from "react-hook-form";

import useSetAccountTarget from "@fartherfinance/frontend/api/Accounts/hooks/useSetAccountTarget";
import { ClientId, FartherAccountId } from "@fartherfinance/frontend/api/Types";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import formatAmount from "@src/multiCustodian/pages/Dashboard/Dashboard_Components/DashboardForms/formatters/formatAmount";
import isValidLocaleNumber from "@src/multiCustodian/pages/Dashboard/Dashboard_Components/DashboardForms/validators/isValidLocaleNumber";
import FormDateField from "@src/sharedComponents/Forms/FormDateField";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import Spacer from "@src/sharedComponents/Forms/Spacer";

import styles from "./SetGoalForm.module.css";

interface Form {
  TargetGoal: string | undefined;
  TargetDate: Date;
}

interface Props {
  closeModal: () => void;
  accountId: FartherAccountId;
  clientId: ClientId;
  currentTargetDate: Date | null;
  currentTargetGoal: number | null;
}

const SetGoalForm = (props: Props): JSX.Element => {
  const auth = useRequestAuth();

  const methods = useForm<Form>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      TargetGoal: props.currentTargetGoal
        ? formatAmount(props.currentTargetGoal)
        : undefined,
      TargetDate: props.currentTargetDate ?? undefined,
    },
  });

  const statusNotification = useStatusNotification();

  const [mutating, setMutating] = useState(false);

  const setAccountTarget = useSetAccountTarget(props.clientId, auth);

  const {
    getValues,
    formState: { isValid, isDirty },
  } = methods;

  const save = async () => {
    if (!isValid) {
      return;
    }

    const values = getValues();

    if (values.TargetGoal === undefined || values.TargetDate === undefined) {
      return;
    }

    try {
      setMutating(true);

      await setAccountTarget({
        virtualAccountId: props.accountId,
        date: values.TargetDate,
        goal: parseFloat(values.TargetGoal.replace(/,/g, "")),
      });

      statusNotification("Updated target goal", "Success");

      props.closeModal();
    } catch {
      statusNotification("Failed to update target goal", "Error");
    } finally {
      setMutating(false);
    }
  };

  const formTitle =
    props.currentTargetGoal !== null && props.currentTargetDate !== null
      ? "Edit Target Goal"
      : "Set Target Goal";

  return (
    <>
      <FormProvider {...methods}>
        <FormH1>{formTitle}</FormH1>

        <Spacer verticalSpacing="30px" />

        <FormTextField
          name="TargetGoal"
          label="Goal Amount"
          placeholder="Enter amount"
          required="Must not be empty"
          valueFormatterOnChange={formatAmount}
          valueFormatterOnBlur={formatAmount}
          startAdornment="$"
          rules={{
            validate: {
              isValidNumber: (e) => isValidLocaleNumber(e, true),
            },
          }}
        />

        <Spacer />

        <FormDateField
          name="TargetDate"
          label="Target Date"
          placeholder="Select a date"
          required="Must not be empty"
          dateCheck={"AfterToday"}
        />
      </FormProvider>

      <Spacer />

      <div className={styles.footer}>
        <Button
          text="Cancel"
          onClick={props.closeModal}
          variant="outlined"
          buttonType="primary"
        />

        <Button
          text="Save"
          variant="contained"
          buttonType="primary"
          onClick={save}
          disabled={!isValid || !isDirty || mutating}
        />
      </div>
    </>
  );
};

export default SetGoalForm;
