import { isBefore, parse } from "date-fns";
import { isNil } from "lodash";

export default function isOnOrAfter1950(
  startDate: Date | null | undefined
): string | true {
  return isNil(startDate) ||
    isBefore(startDate, parse("1950", "yyyy", new Date()))
    ? "Must be 1950 or later"
    : true;
}
