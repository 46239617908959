import React from "react";

import { Stack } from "@mui/material";

import OpportunityAdditionalDetails from "../OpportunityAdditionalDetails";
import { yesNoValues } from "@src/multiCustodian/pages/Advisor/Opportunities/common";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormDateField from "@src/sharedComponents/Forms/FormDateField";
import FormNumberField from "@src/sharedComponents/Forms/FormNumberField";
import FormRadioField from "@src/sharedComponents/Forms/FormRadioField";
import FormTextareaField from "@src/sharedComponents/Forms/FormTextareaField";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";

import { isDateValid } from "./OpportunityCompletedForm.utils";

import styles from "./OpportunityCompletedForm.module.css";

interface OpportunityOfferingCompletedFormProps {
  isAssetWon: boolean;
  isRevenueWon: boolean;
}

export default function OpportunityOfferingCompletedForm({
  isAssetWon,
  isRevenueWon,
}: OpportunityOfferingCompletedFormProps): JSX.Element {
  return (
    <Stack className={styles.container}>
      <FormTextField
        style={{ display: "none" }}
        name="completeFormType"
        defaultValue="OFFERING"
      />
      <FormRadioField
        name="assetWon"
        label="Did the client pursue this offering?"
        required="Please select an option"
        values={yesNoValues}
      />
      {isAssetWon && (
        <FormRadioField
          name="revenueWon"
          label="Did it lead to revenue?"
          required="Please select an option"
          values={yesNoValues}
        />
      )}
      {isAssetWon && isRevenueWon && (
        <FlexWrapper className={styles.assetWonContainer}>
          <FormNumberField
            name="amount"
            startAdornment="$"
            label="How much?"
            required="Please enter an amount"
          />
          <FormDateField
            name="expectedClearanceDate"
            label="When?"
            required="Please select a date"
            rules={{
              validate: { isDateValid },
            }}
          />
        </FlexWrapper>
      )}

      {!isAssetWon || !isRevenueWon ? (
        <FormTextareaField
          containerClassName={styles.otherReasonTextarea}
          name="completeExtraDetails"
          label="Please explain why"
          placeholder="Provide details..."
          required="Please provide an explanation."
          minRows={1}
        />
      ) : (
        <OpportunityAdditionalDetails fieldName="completeExtraDetails" />
      )}
    </Stack>
  );
}
