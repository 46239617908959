import React, { useCallback, useEffect, useRef, useState } from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Box, Stack, Typography } from "@mui/material";

import useCreateTaskComment from "@fartherfinance/frontend/api/Tasks/hooks/useCreateTaskComment";
import { TaskId } from "@fartherfinance/frontend/api/Types";

import { ATTACHMENTS_LIMIT_COUNT } from "../../common";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import ProfilePhotoAdvisor from "@src/multiCustodian/components/Navbar/ProfilePhotoAdvisor";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useFilesManagement from "@src/multiCustodian/hooks/useFilesManagement";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { trackEvent } from "@src/multiCustodian/services/tracking";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import Attachment from "@src/sharedComponents/Attachment/Attachment";
import IconButton from "@src/sharedComponents/IconButton/IconButton";
import Upload from "@src/sharedComponents/Upload/Upload";
import { extractAxiosErrorMessage } from "@src/utils/axios";

import InputHelperText from "./InputHelperText";

import styles from "./TaskDrawerFooter.module.css";

interface TaskDrawerFooterProps {
  taskId: TaskId;
}

export default function TaskDrawerFooter({
  taskId,
}: TaskDrawerFooterProps): JSX.Element {
  const [inputValue, setInputValue] = useState("");
  const [isCommentLoading, setIsCommentLoading] = useState(false);
  const commentInputRef = useRef<HTMLDivElement>();

  const auth = useAdvisorRequestAuth();
  const callCreateTaskComment = useCreateTaskComment(auth);
  const { files, setFiles, removeFile, removeAllFiles } = useFilesManagement({
    countLimit: ATTACHMENTS_LIMIT_COUNT,
  });
  const statusNotification = useStatusNotification();

  const commentInputElement = commentInputRef.current;
  const isSendButtonDisabled =
    (inputValue.length === 0 && files.length === 0) || isCommentLoading;
  const isHelperTextVisible = inputValue.length > 2;
  const isAttachmentsSectionVisible = files.length > 0;

  const handlePersistFiles = (files: FileList | null): void => {
    if (files && files.length) {
      setFiles(files);
    }
  };

  const handleSendComment = useCallback((): void => {
    setIsCommentLoading(true);
    setInputValue((currentInputValue) => {
      // NOTE: we need to call this mutation as such because we do not want to include "inputValue"
      // in the array of useCallback dependencies, otherwise useEffect with event handlers would attach
      // and detach listeners on each key stroke...
      callCreateTaskComment({
        taskId,
        request: {
          payload: {
            content: currentInputValue,
          },
          ...(files.length && { attachments: files }),
        },
      })
        .then(() => {
          trackEvent({ name: "Advisor Tasks Comment Create" });
        })
        .catch((error) =>
          statusNotification(
            extractAxiosErrorMessage(error, "Failed to create task comment."),
            "Error"
          )
        )
        .finally(() => {
          removeAllFiles();
          setIsCommentLoading(false);

          if (commentInputElement) {
            commentInputElement.textContent = "";
          }
        });

      return "";
    });
  }, [
    taskId,
    files,
    callCreateTaskComment,
    statusNotification,
    removeAllFiles,
    commentInputElement,
  ]);

  useEffect(() => {
    // NOTE: we need to intercept paste event to omit raw data like HTML elements
    const pasteEventHandler = (event: ClipboardEvent): void => {
      event.preventDefault();
      const text = event.clipboardData?.getData("text/plain");

      if (text) {
        document.execCommand("insertText", false, text);
      }
    };

    const keydownEventHandler = (event: KeyboardEvent): void => {
      const modifierKey = event.altKey || event.shiftKey;

      if (!isSendButtonDisabled) {
        if (event.key === "Enter" && !modifierKey) {
          event.preventDefault();
          handleSendComment();
        }
      }
    };

    commentInputElement?.addEventListener("paste", pasteEventHandler);
    commentInputElement?.addEventListener("keydown", keydownEventHandler);

    return () => {
      commentInputElement?.removeEventListener("paste", pasteEventHandler);
      commentInputElement?.removeEventListener("keydown", keydownEventHandler);
    };
  }, [commentInputElement, handleSendComment, isSendButtonDisabled]);

  return (
    <Stack className={styles.container}>
      <Stack
        className={styles.inputAreaContainer}
        direction="row"
        alignItems="center"
        spacing={2}
      >
        <ProfilePhotoAdvisor
          containerClassName={styles.avatarContainer}
          avatarClassName={styles.avatar}
        />

        <Box className={styles.inputArea}>
          <Box
            ref={commentInputRef}
            className={styles.commentInput}
            onInput={(event) =>
              setInputValue(event.currentTarget.textContent ?? "")
            }
            placeholder="Comment or upload a file..."
            spellCheck
            contentEditable={!isCommentLoading}
          />
          {isAttachmentsSectionVisible && (
            <Box className={styles.attachmentsContainer}>
              {files.map((file) => (
                <Attachment
                  key={file.name}
                  name={file.name}
                  url={URL.createObjectURL(file)}
                  isLoading={isCommentLoading}
                  onRemove={() => removeFile(file.name)}
                />
              ))}
            </Box>
          )}
          <Stack
            className={toClassName({
              [styles.toolbarContainer]: !isAttachmentsSectionVisible,
              [styles.toolbarContainerFullWidth]: isAttachmentsSectionVisible,
            })}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {isAttachmentsSectionVisible && (
              <Typography className={styles.attachmentsCounter}>
                {files.length}/{ATTACHMENTS_LIMIT_COUNT} Attachments
              </Typography>
            )}
            <Stack direction="row" alignItems="center">
              <Upload
                multiple
                onChange={handlePersistFiles}
                renderChild={(onClick) => (
                  <Box className={styles.attachmentIconContainer}>
                    <IconButton
                      iconClassName={styles.attachmentIcon}
                      disabled={isCommentLoading}
                      onClick={onClick}
                      IconComponent={AttachFileIcon}
                    />
                  </Box>
                )}
              />
              <Button
                variant="contained"
                buttonType="primary"
                text="Comment"
                disabled={isSendButtonDisabled}
                onClick={handleSendComment}
              />
            </Stack>
          </Stack>
        </Box>
      </Stack>
      <InputHelperText isVisible={isHelperTextVisible} />
    </Stack>
  );
}
