import React, { useEffect, useMemo } from "react";

import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import { useFlags } from "launchdarkly-react-client-sdk";
import { orderBy } from "lodash";
import { useSelector } from "react-redux";

import useGetFartherManagedAccounts from "@fartherfinance/frontend/api/Accounts/hooks/useGetFartherManagedAccounts";
import { FartherManagedAccount } from "@fartherfinance/frontend/api/Accounts/Types";
import useAllAccountBalances from "@fartherfinance/frontend/api/PerformanceGroups/hooks/useAllAccountBalances";
import useInvitations from "@fartherfinance/frontend/api/Sharing/hooks/useInvitations";
import { ReceivedInvitation } from "@fartherfinance/frontend/api/Sharing/Types";
import { ClientId } from "@fartherfinance/frontend/api/Types";
import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import { Accordions } from "../Types";
import { AccountsPath } from "@src/config/routing/RouterPaths";
import Accordion, {
  Row,
  specialtyIconWidth,
} from "@src/multiCustodian/components/Client/Accounts/AccountsAccordions/Accordion";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useThemeAssets from "@src/multiCustodian/theme/useThemeAssets";
import useThemeFragment from "@src/multiCustodian/theme/useThemeFragment";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";
import { State } from "@src/store";
import toUSD from "@src/utils/toUSD";

import AccountBalance from "./Components/AccountBalance";
import AccountBalanceV4 from "./Components/AccountBalanceV4";

import styles from "./Components/AccountBalance.module.css";

const makeSharedSubDescription = (
  account: FartherManagedAccount,
  isAdvisor: boolean,
  invitations?: ReceivedInvitation[]
): string => {
  const { custodian, accountType, custodianAccountNumber } =
    account.accountDetails;

  const accId =
    custodianAccountNumber === null
      ? ""
      : isAdvisor
      ? `(${custodianAccountNumber})`
      : `(${custodianAccountNumber.slice(-4)})`;

  const invitation = invitations?.find((invitation) =>
    account.sharedBy.includes(invitation.invitor.invitorId)
  );

  const name = invitation
    ? `${invitation.invitor.firstName} ${invitation.invitor.lastName}`
    : "Unknown";

  return `${custodian} - ${accountType} by ${name} ${accId}`.trim();
};

const makeSubDescription = (
  account: FartherManagedAccount,
  isAdvisor: boolean
): string => {
  const { custodian, accountType, custodianAccountNumber } =
    account.accountDetails;

  const accId =
    custodianAccountNumber === null
      ? ""
      : isAdvisor
      ? `(${custodianAccountNumber})`
      : `(...${custodianAccountNumber.slice(-4)})`;

  return `${custodian} - ${accountType} ${accId}`.trim();
};

interface Props {
  clientId: ClientId;
  expanded: boolean;
  setExpanded: (newState: boolean) => void;
  openAccordions: Accordions[];
  isLoading: boolean;
  setLoaded: () => void;
}

export default function FartherAccountAccordion(props: Props): JSX.Element {
  const { isAdvisor } = useSelector((state: State) => ({
    isAdvisor: state.main_Reducer.user.isAdvisor ?? false,
  }));

  const { enablePerformanceGroups, enableCustomPerformanceGroupsV2 } =
    useFlags();

  const {
    color: { $icon, $iconSubtle },
  } = useTheme();

  const auth = useRequestAuth();

  const managedAccounts = useGetFartherManagedAccounts(props.clientId, auth);

  const invitations = useInvitations(auth);

  const { setLoaded } = props;
  useEffect(() => {
    if (!managedAccounts.isLoading) {
      setLoaded();
    }
  }, [managedAccounts.isLoading, setLoaded]);

  const t = useThemeFragment("Farther");
  const assets = useThemeAssets("Farther");

  const balances = useAllAccountBalances(props.clientId, auth);

  const transformedAccounts = useMemo(() => {
    if (managedAccounts.isLoading || managedAccounts.hasError) {
      return managedAccounts;
    }

    const ordered = orderBy(
      managedAccounts.data ?? [],
      [
        (a) => a.accountDetails.displayName,
        (a) => a.accountDetails.custodianAccountNumber,
        (a) => a.virtualAccountId,
      ],
      ["asc", "asc", "asc"]
    );

    const transformedRows: Row[] = ordered.map((a): Row => {
      const shared = a.sharedBy.length > 0;

      const subDescription = shared
        ? makeSharedSubDescription(a, isAdvisor, invitations.data?.received)
        : makeSubDescription(a, isAdvisor);

      return {
        key: a.virtualAccountId,
        description: a.accountDetails.displayName,
        subDescription,
        status: a.accountDetails.operationalState,
        value: a.accountDetails.lastReportedBalance.balance,
        /* For now we are not use PG for balance data for shared accounts, this
         * is because we can't link a shared account's Id to a PG Id because
         * no PG group is created for shared accounts
         */
        valueDisplay: !enablePerformanceGroups ? (
          a.accountDetails.lastReportedBalance.balance ? (
            toUSD(a.accountDetails.lastReportedBalance.balance)
          ) : (
            "N/A"
          )
        ) : (
          <>
            {enableCustomPerformanceGroupsV2 ? (
              <AccountBalanceV4
                balanceCssClassName={styles.balance}
                clientId={props.clientId}
                accountId={a.virtualAccountId}
                loadingSkeleton={<Skeleton width={100} height={30} />}
              />
            ) : (
              <AccountBalance
                balanceCssClassName={styles.balance}
                clientId={props.clientId}
                accountId={a.virtualAccountId}
                loadingSkeleton={<Skeleton width={100} height={30} />}
              />
            )}
          </>
        ),
        linkTo: `/Client/${props.clientId}/${AccountsPath}/Managed/${a.virtualAccountId}`,
        icon: shared ? (
          <Tooltip tooltipText={"This is shared with you"}>
            <PeopleOutlineOutlinedIcon
              style={{
                width: specialtyIconWidth,
              }}
              sx={{
                color: $iconSubtle,
                "&:hover": {
                  color: $icon,
                },
              }}
            />
          </Tooltip>
        ) : undefined,
      };
    });

    return {
      ...managedAccounts,
      data: transformedRows,
    };
  }, [
    $icon,
    $iconSubtle,
    enablePerformanceGroups,
    isAdvisor,
    managedAccounts,
    // memberNames,
    props.clientId,
    invitations.data?.received,
    enableCustomPerformanceGroupsV2,
  ]);

  const linkState = useMemo(
    () => ({ openAccordions: props.openAccordions }),
    [props.openAccordions]
  );

  const totalBalance: number | undefined | null = useMemo(() => {
    if (balances.isLoading) {
      return undefined;
    }

    if (
      balances.hasError ||
      balances.data === undefined ||
      balances.data.length === 0
    ) {
      return null;
    }

    return balances.data
      .map((a) => a.balance)
      .reduce<number>((accum, curr) => accum + (curr ?? 0), 0);
  }, [balances]);

  if (enablePerformanceGroups) {
    if (transformedAccounts.hasError || balances.hasError) {
      return <div>Error</div>;
    }
  }

  if (transformedAccounts.isLoading || props.isLoading) {
    return <Skeleton width={"100%"} height={60} />;
  }

  return (
    <Accordion
      expanded={props.expanded}
      setExpanded={props.setExpanded}
      icon={
        <img
          style={{ width: `${specialtyIconWidth}px`, height: "auto" }}
          src={assets.data.logoSymbol}
        />
      }
      title={`${t("companyName")} Managed`}
      total={totalBalance}
      rows={transformedAccounts.data ?? []}
      linkState={linkState}
    />
  );
}
