import { isNil } from "lodash";

import removePhoneNumberFormatting from "../formatters/removePhoneNumberFormatting";

const error =
  "Please enter your phone number as (xxx) xxx xxxx. ex: (123) 456 7890";

export default function isValidPhoneNumber(
  input: string | null | undefined,
  isRequired: boolean
): string | true {
  const isEmpty = isNil(input) || input === "";

  const noFormatting = removePhoneNumberFormatting(input ?? "");

  const isValidPhoneNumber = noFormatting.length === 10;

  if (isRequired) {
    return !isEmpty && isValidPhoneNumber ? true : error;
  }
  return isEmpty || isValidPhoneNumber ? true : error;
}
