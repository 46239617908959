import React from "react";

import FormSubHeader from "@src/sharedComponents/Forms/FormSubHeader";

const Subheader = (): JSX.Element => {
  return (
    <FormSubHeader>
      How to apply this SMA to an account
      <br />
      1. Contact the portfolio manager to get a proposal.
      <br />
      2. Once you have a copy of the proposal, click "Apply To Account."
    </FormSubHeader>
  );
};

export default Subheader;
