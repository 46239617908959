import React, { useMemo } from "react";

import usePortfolioAnalysisV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/usePortfolioAnalysisV2";
import usePortfolioV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/usePortfolioV2";
import {
  AdvisorRequestConfig,
  PortfolioId,
  RequestConfig,
} from "@fartherfinance/frontend/api/Types";

import PortfolioTableLoading from "../Client/Portfolio/PortfolioTable/PortfolioTableLoading";
import PortfolioModelTableView from "../Client/Portfolio/TableViews/TableViews";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

import styles from "./PortfolioAnalysisAssetClassCategoryTable.module.css";
interface Props {
  showDirectIndexedFilter: boolean;
  portfolioId: PortfolioId | null;
  headerStyle?: React.CSSProperties;
  height100?: boolean;
}

const PortfolioAnalysisAssetClassCategoryTable = ({
  showDirectIndexedFilter,
  portfolioId,
  headerStyle,
  height100,
}: Props): JSX.Element => {
  const clientAuth = useRequestAuth();
  const advisorAuth = useAdvisorRequestAuth();

  const auth: AdvisorRequestConfig | RequestConfig | null = useMemo(
    () => clientAuth ?? advisorAuth,
    [advisorAuth, clientAuth]
  );

  const portfolio = usePortfolioV2(portfolioId, auth);
  const portfolioAnalysis = usePortfolioAnalysisV2(portfolioId, auth);

  if (portfolio.isLoading || portfolioAnalysis.isLoading) {
    return (
      <div
        className={styles.tableContainer}
        style={height100 ? { height: "100%" } : {}}
      >
        <PortfolioTableLoading headerStyle={headerStyle} />
      </div>
    );
  }

  if (
    portfolio.hasError ||
    portfolioAnalysis.hasError ||
    portfolio === undefined
  ) {
    return (
      <div className={styles.tableContainer}>
        <div>Error</div>
      </div>
    );
  }

  return (
    <PortfolioModelTableView
      showDirectIndexedFilter={showDirectIndexedFilter}
      portfolio={portfolio.data}
      portfolioAnalysis={portfolioAnalysis.data}
      headerStyle={headerStyle}
      height100={height100}
    />
  );
};

export default PortfolioAnalysisAssetClassCategoryTable;
