import React from "react";

import useMatchPerformanceGroup from "@fartherfinance/frontend/api/PerformanceGroups/hooks/useMatchPerformanceGroup";
import { ClientId, FartherAccountId } from "@fartherfinance/frontend/api/Types";

import PerformanceGroupAccountActivity from "@src/multiCustodian/components/PerformanceGroups/Activity/Activity";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

import styles from "./Activity.module.css";

interface Props {
  clientId: ClientId;
  accountId: FartherAccountId;
}

export default function Activity({ clientId, accountId }: Props): JSX.Element {
  const auth = useRequestAuth();
  const performanceGroupId = useMatchPerformanceGroup(
    clientId,
    accountId,
    auth
  );

  return (
    <>
      <div className={styles.heading}>Account Activity</div>

      <PerformanceGroupAccountActivity
        accountId={accountId}
        performanceGroupIdData={performanceGroupId}
      />
    </>
  );
}
