import React, { useCallback, useEffect, useState } from "react";

import { format } from "date-fns";
import { useSelector } from "react-redux";

import { dateFormat } from "@src/constants/dateFormat";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import { trackEvent } from "@src/multiCustodian/services/tracking";
import { State } from "@src/store";

import CustomDateRangeSelectorForm from "./CustomDateRangeSelectorForm";
import {
  CustomDateRange,
  usePerformanceCustomDateRangeContext,
} from "./PerformanceCustomDateRangeContextProvider";

import styles from "./CustomDateRangeSelector.module.css";

interface Props {
  onApply: () => void;
  resetToDefaultTimeFrame: () => void;
}

const CustomDateRangeSelector: React.FC<Props> = ({
  onApply,
  resetToDefaultTimeFrame,
}) => {
  const isAdvisor = useSelector(
    (state: State) => state.main_Reducer.user.isAdvisor
  );

  const { customDateRange, setCustomDateRange, setShowCustomDateRangeForm } =
    usePerformanceCustomDateRangeContext();

  const [localCustomDateRange, setLocalCustomDateRange] =
    useState<CustomDateRange>({ ...customDateRange });

  const [formIsValid, setFormIsValid] = useState<boolean>(false);

  useEffect(() => {
    if (
      customDateRange.from !== localCustomDateRange.from ||
      customDateRange.to !== localCustomDateRange.to
    ) {
      setLocalCustomDateRange({ ...customDateRange });
    }
    // eslint-disable-next-line
  }, [customDateRange]);

  const trackCustomTimeRange = useCallback(() => {
    if (
      localCustomDateRange.from === null ||
      localCustomDateRange.to === null
    ) {
      return;
    }

    const dayInMs = 1000 * 60 * 60 * 24;
    const timeDiffInMs =
      localCustomDateRange.to.getTime() - localCustomDateRange.from.getTime();

    const properties = {
      from: format(localCustomDateRange.from, dateFormat),
      to: format(localCustomDateRange.to, dateFormat),
      timeFrameInDays: Math.round(timeDiffInMs / dayInMs),
    };

    if (isAdvisor) {
      trackEvent({
        name: "Advisor Performance Custom Time Frame",
        properties,
      });
    } else {
      trackEvent({
        name: "Client Performance Custom Time Frame",
        properties,
      });
    }
  }, [isAdvisor, localCustomDateRange]);

  const isValid = (b: boolean) => setFormIsValid(b);

  const handleOnClickReset = useCallback(() => {
    setShowCustomDateRangeForm(false);
    resetToDefaultTimeFrame();
    setCustomDateRange({
      from: null,
      to: null,
    });
  }, [resetToDefaultTimeFrame, setCustomDateRange, setShowCustomDateRangeForm]);

  const handleOnClickApply = useCallback(() => {
    setCustomDateRange({ ...localCustomDateRange });
    onApply();
    trackCustomTimeRange();
  }, [localCustomDateRange, onApply, setCustomDateRange, trackCustomTimeRange]);

  return (
    <div className={styles.container}>
      <CustomDateRangeSelectorForm
        customDateRange={localCustomDateRange}
        setCustomDateRange={setLocalCustomDateRange}
        setFormIsValid={isValid}
      />

      <div className={styles.footer}>
        <ButtonPrimary
          text="Clear"
          onClick={handleOnClickReset}
          variant="outlined"
          buttonType="secondary"
          sx={{ width: "114px" }}
        />

        <ButtonPrimary
          text="Apply"
          onClick={handleOnClickApply}
          variant="contained"
          buttonType="primary"
          disabled={
            localCustomDateRange.from === null ||
            localCustomDateRange.to === null ||
            !formIsValid
          }
          sx={{ width: "114px" }}
        />
      </div>
    </div>
  );
};

export default CustomDateRangeSelector;
