import React, { useState } from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import isElement from "lodash/isElement";

import { useStyles } from "./DropdownMenu.styles";

export type DropdownMenuOption<T> = {
  label: string;
  value: T;
  className?: string;
};

interface DropdownMenuProps<T> {
  children: (props: {
    isMenuOpen: boolean;
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void;
  }) => React.ReactNode;
  options: DropdownMenuOption<T>[];
  onSelect: (option: DropdownMenuOption<T>) => void;
}

export const DropdownMenu = <T,>({
  children,
  options,
  onSelect,
}: DropdownMenuProps<T>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { classes, cx } = useStyles();

  const isMenuOpen = isElement(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      {children({ isMenuOpen, handleOpenMenu })}

      <Menu
        classes={{ paper: classes.menuPaper }}
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        transformOrigin={{ horizontal: "left", vertical: -10 }}
        autoFocus={false}
        PaperProps={{ elevation: 0 }}
        MenuListProps={{ className: classes.menuList }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            className={classes.menuItem}
            onClick={() => onSelect(option)}
          >
            <Typography className={cx(classes.menuItemText, option.className)}>
              {option.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
