import React, { useMemo } from "react";

import { useParams } from "react-router-dom";

import { EquivalentSecurityListId } from "@fartherfinance/frontend/api/Types";

import AdvisorContainer from "@src/multiCustodian/pages/Advisor/AdvisorContainer";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

import EquivalentSecuritiesForm from "./SecuritySearch/EquivalentSecuritiesForm";
import useEquivalentSecurityListMembersWithInfo from "./useEquivalentSecurityListMembersWithInfo";

const EditEquivalentSecurities: React.FC = () => {
  const { listId } = useParams<{
    listId: EquivalentSecurityListId;
  }>();

  const eslist = useEquivalentSecurityListMembersWithInfo(listId);

  const { name, desc, securities } = useMemo(() => {
    if (eslist.data === undefined) {
      return { name: "", desc: "", securities: [] };
    }

    return {
      name: eslist.data?.name ?? "",
      desc: eslist.data?.description ?? "",
      securities: (eslist.data?.members ?? []).map((sec) => ({
        ticker: sec.ticker,
        securityId: sec.securityId,
        description: sec.description,
      })),
    };
  }, [eslist.data]);

  if (eslist.hasError) {
    return <div>{eslist.error}</div>;
  }

  return (
    <AdvisorContainer hideNavbar={true}>
      {eslist.isLoading && <LogoLoadingStill onTop={true} />}

      <EquivalentSecuritiesForm
        isUpdate={true}
        name={name}
        description={desc}
        members={securities}
      />
    </AdvisorContainer>
  );
};

export default EditEquivalentSecurities;
