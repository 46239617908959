import React, { useState } from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Link } from "@mui/material";
import { Stack } from "@mui/system";
import { identity, orderBy } from "lodash";
import { FormProvider, useForm, useFormState, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";

import usePostUploadDocument from "@fartherfinance/frontend/api/CustodianDoc/hooks/usePostUploadDocument";
import useClientProfile from "@fartherfinance/frontend/api/Entity/hooks/useClientProfile";
import usePutAffiliations from "@fartherfinance/frontend/api/Entity/hooks/usePutAffiliations";
import { ClientAffiliations } from "@fartherfinance/frontend/api/Entity/requests/getClientProfile";
import { custodianOptions } from "@fartherfinance/frontend/api/Types";

import FormFooter from "../components/FormFooter/FormFooter";
import { exchangeNameOptions } from "@src/constants/exchangeName";
import { relationshipOptions } from "@src/constants/relationship";
import Portal from "@src/multiCustodian/components/Portal/Portal";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import FormRadioField from "@src/sharedComponents/Forms/FormRadioField";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import IconButton from "@src/sharedComponents/IconButton/IconButton";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import Upload from "@src/sharedComponents/Upload/Upload";
import { State } from "@src/store";

import { Form } from "./types";
import mapDefaultValues from "./utils/mapDefaultValues";
import mapValuesToSubmitForm from "./utils/mapValuesToSubmitForm";

import styles from "./AffiliationForm.module.css";
import sharedFormStyle from "../SharedFormStyle.module.css";

const EXCHANGE_FORM_PATH = "Employer%20Agreements/Letter%203210";

const verticalSpacing = "30px";

const yesNo = ["Yes", "No"] as const;

interface Props {
  drawerFooterPortalId: string;
  onClose: () => void;
}

export default function AffiliationFormFetcher(props: Props) {
  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));

  const auth = useRequestAuth();

  const clientProfile = useClientProfile(clientId, auth);

  if (clientProfile.isLoading) {
    return (
      <div className={sharedFormStyle.loading}>
        <LogoLoadingStill />
      </div>
    );
  }

  if (clientProfile.hasError) {
    return <div>Error</div>;
  }

  return (
    <AffiliationForm
      drawerFooterPortalId={props.drawerFooterPortalId}
      affiliations={clientProfile.data.affiliations}
      onClose={props.onClose}
    />
  );
}

interface AffiliationFormProps {
  drawerFooterPortalId: string;
  affiliations: ClientAffiliations;
  onClose: () => void;
}

function AffiliationForm(props: AffiliationFormProps): JSX.Element {
  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));

  const auth = useRequestAuth();

  const statusNotification = useStatusNotification();

  const callPutAffiliations = usePutAffiliations(clientId, auth);

  const callUploadDocument = usePostUploadDocument(auth);

  const methods = useForm<Form>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: mapDefaultValues(props.affiliations),
  });

  const { control, getValues } = methods;

  const { isValid } = useFormState({ control });

  const [isMutating, setIsMutating] = useState(false);

  const [file, setFile] = useState<File | null>(null);

  const required = "Must not be empty";

  const [
    StockExchange,
    DocumentAlreadyUploaded,
    PublicCompany,
    CustodianAffiliated,
    BrokerDealerAffiliated,
    FartherEmployeeRelated,
    FamilyMemberBrokerAffiliated,
  ] = useWatch({
    control,
    name: [
      "StockExchange",
      "DocumentAlreadyUploaded",
      "PublicCompany",
      "CustodianAffiliated",
      "BrokerDealerAffiliated",
      "FartherEmployeeRelated",
      "FamilyMemberBrokerAffiliated",
    ],
  });

  const isExchangeFieldValid =
    DocumentAlreadyUploaded === true || file !== null || StockExchange === "No";

  const isSubmitDisabled = !isValid || !isExchangeFieldValid || isMutating;

  const submit = async () => {
    if (isSubmitDisabled) {
      return;
    }

    const values = getValues();

    const form = mapValuesToSubmitForm(values);

    try {
      setIsMutating(true);

      await callPutAffiliations(form);

      if (values.StockExchange === "Yes" && file !== null) {
        await callUploadDocument({
          path: EXCHANGE_FORM_PATH,
          file,
        });
      }

      statusNotification("Updated affiliations.", "Success");

      props.onClose();
    } catch {
      setIsMutating(false);

      statusNotification("Failed to update affiliations.", "Error");
    }
  };

  return (
    <FormProvider {...methods}>
      <FormH1>Provide your affiliation information</FormH1>

      <FlexWrapper>
        <FormRadioField
          name="StockExchange"
          label="Are you affiliated with a stock exchange or FINRA?"
          values={yesNo}
          required={required}
        />

        {StockExchange === "Yes" && (
          <div>
            <FormDropdownField
              name="StockExchangeName"
              label="Entity Name"
              required={required}
              values={exchangeNameOptions.map((e) => ({ label: e }))}
            />

            <Spacer verticalSpacing={verticalSpacing} />

            <div className={styles.uploadFieldTitle}>
              Please upload any relevant documentation (e.g., Letter 3210)
            </div>

            <UploadField setFile={setFile} file={file} />
          </div>
        )}
      </FlexWrapper>

      <FlexWrapper>
        <FormRadioField
          name="PublicCompany"
          label="Are you affiliated with a Public Company"
          values={yesNo}
          required={required}
        />

        {PublicCompany === "Yes" && (
          <FormTextField
            name="PublicCompanyName"
            label="Public Company Name"
            required={required}
          />
        )}
      </FlexWrapper>

      <FlexWrapper>
        <FormRadioField
          name="USOfficial"
          label="Are you a US official/politically exposed person?"
          values={yesNo}
          required={required}
        />
      </FlexWrapper>

      <FlexWrapper>
        <FormRadioField
          name="FartherEmployeeRelated"
          label="Are you related to a Farther Employee?"
          values={yesNo}
          required={required}
        />

        {FartherEmployeeRelated === "Yes" && (
          <div>
            <FormTextField
              name="FartherEmployeeFullName"
              label="Employee Name"
              required={required}
            />

            <Spacer verticalSpacing={verticalSpacing} />

            <FormDropdownField
              name="FartherEmployeeRelationship"
              label="Relationship"
              values={relationshipOptions.map((e) => ({ label: e }))}
              required={required}
            />
          </div>
        )}
      </FlexWrapper>

      <FlexWrapper>
        <FormRadioField
          name="CustodianAffiliated"
          label="Are you an employee or affiliated with a custodian?"
          values={yesNo}
          required={required}
        />

        {CustodianAffiliated === "Yes" && (
          <FormDropdownField
            name="CustodianName"
            label="Custodian Name"
            values={orderBy(custodianOptions, identity, "asc").map((e) => ({
              label: e,
            }))}
            required={required}
            disableSearch
          />
        )}
      </FlexWrapper>

      <FlexWrapper>
        <FormRadioField
          name="BrokerDealerAffiliated"
          label="Are you an employee or affiliated with a broker-dealer?"
          values={yesNo}
          required={required}
        />

        {BrokerDealerAffiliated === "Yes" && (
          <FormTextField
            name="BrokerDealerName"
            label="Broker-Dealer Name"
            required={required}
          />
        )}
      </FlexWrapper>

      <FlexWrapper>
        <FormRadioField
          name="FamilyMemberBrokerAffiliated"
          label="Is a family member affiliated with a broker-dealer?"
          values={yesNo}
          required={required}
        />

        {FamilyMemberBrokerAffiliated === "Yes" && (
          <div>
            <FormTextField
              name="FamilyBrokerDealerName"
              label="Family Member Name"
              required={required}
            />

            <Spacer verticalSpacing={verticalSpacing} />

            <FormTextField
              name="FamilyBrokerDealerCompany"
              label="Affiliate Name"
              required={required}
            />

            <Spacer verticalSpacing={verticalSpacing} />

            <FormDropdownField
              name="FamilyBrokerDealerRelationship"
              label="Relationship"
              values={relationshipOptions.map((e) => ({ label: e }))}
              required={required}
            />
          </div>
        )}
      </FlexWrapper>

      <Portal targetNodeId={props.drawerFooterPortalId}>
        <FormFooter isSubmitDisabled={isSubmitDisabled} handleSubmit={submit} />
      </Portal>

      {isMutating && (
        <div className={sharedFormStyle.loading}>
          <LogoLoadingStill />
        </div>
      )}
    </FormProvider>
  );
}

interface UploadFieldProps {
  setFile: (file: File | null) => void;
  file: File | null;
}

const UploadField = ({ file, setFile }: UploadFieldProps): JSX.Element => {
  const { textColor } = useSelector((state: State) => ({
    textColor:
      state.main_Reducer.css_color_palettes.current["--primary_text_color_1"],
  }));

  const handlePersistFiles = (files: FileList | null): void => {
    if (files && files.length) {
      setFile(files[0]);
    }
  };

  return (
    <div>
      <Upload
        onChange={handlePersistFiles}
        renderChild={(onClick) => (
          <Stack direction="row" marginTop="8px" gap="6px">
            <div className={styles.chooseFile} onClick={onClick}>
              <AttachFileIcon />

              {file === null && (
                <div className={styles.chooseFileText}>Choose file</div>
              )}
            </div>

            {file !== null && (
              <Stack direction="row" alignItems="center" gap="8px">
                <Link
                  style={{ color: textColor }}
                  href={URL.createObjectURL(file)}
                  target="_blank"
                >
                  {file.name}
                </Link>

                <IconButton
                  size="small"
                  onClick={() => setFile(null)}
                  IconComponent={RemoveCircleOutlineIcon}
                />
              </Stack>
            )}
          </Stack>
        )}
      />
    </div>
  );
};
