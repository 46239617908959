import { FartherManagedAccount } from "@fartherfinance/frontend/api/Accounts/Types";

const createAccLabel = (
  account: FartherManagedAccount,
  // number means known balance, null means unknown balance, undefined is loading balance
  balance: number | null | undefined,
  isAdvisor: boolean,
  companyName: string
): string => {
  const custodian = `${account.accountDetails.custodian}`;

  const name =
    account.accountDetails.displayName === "Long-term Savings"
      ? `${companyName} - Long-term Growth`
      : `${companyName} - ${account.accountDetails.displayName}`;

  const balanceStr =
    balance === null
      ? "--"
      : balance === undefined
      ? "(Loading balance)"
      : balance.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });

  const custodianId = account.accountDetails.custodianAccountNumber ?? "";

  if (isAdvisor) {
    return `${name} - ${custodian} ${custodianId} - ${balanceStr}`;
  }

  return `${name} - ${balanceStr}`;
};

export default createAccLabel;
