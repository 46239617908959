import React, { PropsWithChildren } from "react";

import { MarketplaceAssetType } from "@fartherfinance/frontend/api/AltAssetsService/Types";

import { InsuranceMarketplacePath } from "@src/config/routing/RouterPaths";
import { insuranceOfferingTypes } from "@src/multiCustodian/components/Marketplace/constants";

import OfferingsListClient from "./OfferingsListClient";

const InsuranceOfferingsListClient: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <OfferingsListClient
      assetType={MarketplaceAssetType.enum.insurance}
      offeringTypes={insuranceOfferingTypes}
      marketplacePath={InsuranceMarketplacePath}
      pageTitleFallback="Insurance"
    >
      {children}
    </OfferingsListClient>
  );
};

export default InsuranceOfferingsListClient;
