import React from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import * as fieldStyles from "./FormFieldLabel.module.css";

interface Props {
  label?: React.ReactNode;
  labelTooltip?: string;
}
export default function FormFieldLabel({
  label,
  labelTooltip,
}: Props): JSX.Element {
  if (label === undefined && labelTooltip === undefined) {
    return <></>;
  }

  // If we have `labelTooltip` but no `label` we need to show something to take
  // up space
  const labelToShow = label ?? " ";

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          flexGrow: 1,
          fontFamily: "var(--fakt)",
          color: "var(--textSubtle)",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "20px",
          letterSpacing: "0.28",
        }}
      >
        {labelToShow}
      </div>

      {labelTooltip !== undefined ? (
        <div>
          <Tooltip tooltipText={labelTooltip} placement="top">
            <InfoOutlinedIcon className={fieldStyles.infoIcon} />
          </Tooltip>
        </div>
      ) : null}
    </div>
  );
}
