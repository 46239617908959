import React, { PropsWithChildren } from "react";

import { useHistory, useParams } from "react-router-dom";

import { MarketplaceAssetType } from "@fartherfinance/frontend/api/AltAssetsService/Types";
import { MarketplaceOfferingId } from "@fartherfinance/frontend/api/Types";

import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import OfferingsList from "@src/multiCustodian/components/Marketplace/OfferingsList/OfferingsList";
import useOfferingsListData from "@src/multiCustodian/components/Marketplace/OfferingsList/useOfferingsListData";
import {
  MarketplaceClientPathParams,
  MarketplaceOfferingTypeCard,
} from "@src/multiCustodian/components/Marketplace/types";
import { findOfferingTypeByValue } from "@src/multiCustodian/components/Marketplace/utils";
import PageHero from "@src/sharedComponents/PageHero/PageHero";
import TitleBlock from "@src/sharedComponents/PageHero/TitleBlock";

import styles from "./OfferingsListClient.module.css";

interface OfferingsListClientProps {
  assetType: MarketplaceAssetType;
  offeringTypes: MarketplaceOfferingTypeCard[];
  marketplacePath: string;
  pageTitleFallback: string;
}

const OfferingsListClient: React.FC<
  PropsWithChildren<OfferingsListClientProps>
> = ({
  children,
  assetType,
  offeringTypes,
  marketplacePath,
  pageTitleFallback,
}) => {
  const history = useHistory();
  const { clientId, offeringType } = useParams<MarketplaceClientPathParams>();
  const { offerings, pagination, isLoading, hasError, handleOnPageChange } =
    useOfferingsListData("client", assetType, offeringType);

  const marketplaceOfferingType = findOfferingTypeByValue(
    offeringTypes,
    offeringType
  );

  const handleNavigateToOfferingTypes = (): void => {
    history.push(`/Client/${clientId}/${marketplacePath}`);
  };

  const handleNavigateToOfferingDetails = (
    offeringId: MarketplaceOfferingId
  ): void => {
    history.push(
      `/Client/${clientId}/${marketplacePath}/${offeringType}/${offeringId}`
    );
  };

  return (
    <BaseLayout>
      <PageHero
        start={
          <TitleBlock
            title={marketplaceOfferingType?.name ?? pageTitleFallback}
            subTitle={marketplaceOfferingType?.description}
          />
        }
      />

      <div className={styles.container}>
        <OfferingsList
          offerings={offerings}
          offeringTypes={offeringTypes}
          pagination={pagination}
          isLoading={isLoading}
          isErrored={hasError}
          onCardClick={handleNavigateToOfferingDetails}
          onPageChange={handleOnPageChange}
          onNavigateBackClick={handleNavigateToOfferingTypes}
        />
      </div>

      {children}
    </BaseLayout>
  );
};

export default OfferingsListClient;
