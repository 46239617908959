import React from "react";

import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./EquivalentSecuritiesDrawer.module.css";

const EquivalentSecuritiesDrawerTableRowLoading: React.FC = () => {
  return (
    <div className={styles.row}>
      <Skeleton
        width={40}
        height={20}
        style={{ transform: "none", margin: "0px 32px 0px 24px" }}
      />

      <Skeleton width={220} height={20} style={{ transform: "none" }} />
    </div>
  );
};

export default EquivalentSecuritiesDrawerTableRowLoading;
