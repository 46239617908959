import React, { useState } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { accessLevelsForDisplay } from "../../../constants";
import { AccessLevelForDisplay } from "../../../Types";
import AccessLevelInfoNote from "../../shared/AccessLevelInfoNote/AccessLevelInfoNote";
import InfoModal from "../../shared/InfoModal/InfoModal";
import SelectResourcesToShare from "../../shared/SelectResources/SelectResourcesToShare";
import { actions } from "../reducer/actions";
import { useEditCollaboratorContext } from "../reducer/Context";
import { Relationship, relationshipOptions } from "@src/constants/relationship";
import {
  serviceProfessionalOptions,
  ServiceProfessionalTypeOption,
} from "@src/constants/serviceProffesional";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";
import BorderBoxBody from "@src/sharedComponents/BorderBox/BorderBoxBody";
import Checkbox from "@src/sharedComponents/Checkbox/Checkbox";
import HeaderWithSubHeader from "@src/sharedComponents/HeaderWithSubHeader/HeaderWithSubHeader";
import Dropdown, {
  AutocompleteOption,
} from "@src/sharedComponents/SAODropdown/Dropdown";
import TextInput from "@src/sharedComponents/TextInput/TextInput";

import EditCollaboratorSelectResourcesFormStateAndDispatcher from "./SelectResourcesStateAndDispatcher/FormStateAndDispatcher";

import styles from "./CollaboratorNameTypeAndAccessLevel.module.css";

const CollaboratorNameTypeAndAccessLevel: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const { state, dispatch } = useEditCollaboratorContext();

  const {
    data: {
      firstName,
      lastName,
      collaboratorType,
      accessLevel,
      notify,
      serviceProfessionalType,
    },
  } = state;

  const handleFirstName = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch({ type: actions.SET_FIRST_NAME, payload: e.target.value });

  const handleLastName = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch({ type: actions.SET_LAST_NAME, payload: e.target.value });

  const handleType = (e: AutocompleteOption) =>
    dispatch({
      type: actions.SET_COLLABORATOR_TYPE,
      payload: e.label as Relationship,
    });

  const handleServiceProType = (e: AutocompleteOption) =>
    dispatch({
      type: actions.SET_SERVICE_PRO_TYPE,
      payload: e.label as ServiceProfessionalTypeOption,
    });

  const handleAccess = (e: AutocompleteOption) => {
    const accessLevel = e.label as AccessLevelForDisplay;

    dispatch({
      type: actions.SET_ACCESS_LEVEL,
      payload: accessLevel,
    });

    if (accessLevel === "All-Access" || accessLevel === "Revoke") {
      dispatch({ type: actions.REMOVE_ALL_RESOURCES, payload: undefined });
    }
  };

  const handleOnCheck = (bool: boolean) =>
    dispatch({ type: actions.SET_NOTIFY, payload: bool });

  return (
    <div className={styles.container}>
      {showModal && <InfoModal hideModal={() => setShowModal(false)} />}

      <div className={styles.inner}>
        <HeaderWithSubHeader
          className={styles.headerWithSubHeader}
          header={"Update Collaborator"}
          subHeader={
            "Update your collaborator's personal information or access level."
          }
        />

        <BorderBox className={styles.borderBox}>
          <BorderBoxBody className={styles.borderBoxBody} showTopBorder={false}>
            <div className={styles.inputsRow}>
              <TextInput
                label={<div className={styles.label}>First Name</div>}
                value={firstName}
                onChange={handleFirstName}
                disabled={true}
              />

              <TextInput
                label={<div className={styles.label}>Last Name</div>}
                value={lastName}
                onChange={handleLastName}
                disabled={true}
              />
            </div>

            <div className={styles.inputsRow}>
              {collaboratorType && (
                <Dropdown
                  disableSearch={true}
                  formLabel="Collaborator Type"
                  value={collaboratorType ? { label: collaboratorType } : null}
                  onChange={handleType}
                  values={relationshipOptions.map((t) => ({ label: t }))}
                />
              )}

              {serviceProfessionalType && (
                <Dropdown
                  disableSearch={true}
                  formLabel="Service Pro"
                  value={
                    serviceProfessionalType
                      ? { label: serviceProfessionalType }
                      : null
                  }
                  onChange={handleServiceProType}
                  values={serviceProfessionalOptions.map((t) => ({ label: t }))}
                />
              )}

              <Dropdown
                disableSearch={true}
                formLabel={
                  <div>
                    Access
                    <InfoOutlinedIcon
                      className={styles.infoIcon}
                      onClick={() => setShowModal(true)}
                    />
                  </div>
                }
                value={
                  accessLevel
                    ? {
                        label: accessLevel,
                      }
                    : null
                }
                onChange={handleAccess}
                values={accessLevelsForDisplay.map((l) => ({
                  label: l,
                }))}
              />
            </div>

            <AccessLevelInfoNote accessLevel={accessLevel} />

            <div
              className={toClassName({
                [styles.checkboxRowLimited]: accessLevel === "Limited Access",
                [styles.checkboxRow]: accessLevel !== "Limited Access",
              })}
            >
              <Checkbox
                checked={notify}
                onChange={handleOnCheck}
                label={"Notify collaborator via email"}
                checkboxStyle={{ marginTop: "2px", marginBottom: "2px" }}
              />
            </div>
          </BorderBoxBody>
        </BorderBox>

        {accessLevel === "Limited Access" && (
          <SelectResourcesToShare>
            <EditCollaboratorSelectResourcesFormStateAndDispatcher />
          </SelectResourcesToShare>
        )}
      </div>
    </div>
  );
};

export default CollaboratorNameTypeAndAccessLevel;
