export enum actions {
  RESET_TO_DEFAULT_DATA = "RESET_TO_DEFAULT_DATA",
  SET_PAGE_INDEX = "SET_PAGE_INDEX",
  INCREMENT_PAGE_INDEX = "INCREMENT_PAGE_INDEX",
  DECREMENT_PAGE_INDEX = "DECREMENT_PAGE_INDEX",
  SET_FIRST_NAME = "SET_FIRST_NAME",
  SET_LAST_NAME = "SET_LAST_NAME",
  SET_EMAIL = "SET_EMAIL",
  SET_COLLABORATOR_TYPE = "SET_COLLABORATOR_TYPE",
  SET_SERVICE_PRO_TYPE = "SET_SERVICE_PRO_TYPE",
  SET_ACCESS_LEVEL = "SET_ACCESS_LEVEL",
  SET_NOTIFY = "SET_NOTIFY",
  ADD_RESOURCE = "ADD_RESOURCE",
  ADD_ALL_RESOURCES = "ADD_ALL_RESOURCES",
  REMOVE_RESOURCE = "REMOVE_RESOURCE",
  REMOVE_ALL_RESOURCES = "REMOVE_ALL_RESOURCES",
}
