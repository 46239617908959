import React from "react";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Chip, Typography } from "@mui/material";

import ExpandableDescription from "../ExpandableDescription/ExpandableDescription";
import IconButton from "@src/sharedComponents/IconButton/IconButton";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./OfferingsListCard.module.css";

type OfferingsListCardOwnProps = {
  offeringType: string;
  partnerName: string;
  partnerDescription: string;
  howItWorks: string;
  isFeatured: boolean;
  onClick: () => void;
};

type OfferingsListCardLoadingFalseProps = OfferingsListCardOwnProps & {
  isLoading: false;
};

type OfferingsListCardLoadingTrueProps = Never<OfferingsListCardOwnProps> & {
  isLoading: true;
};

type OfferingsListCardProps =
  | OfferingsListCardLoadingFalseProps
  | OfferingsListCardLoadingTrueProps;

const OfferingsListCard: React.FC<OfferingsListCardProps> = ({
  isLoading,
  offeringType,
  partnerName,
  partnerDescription,
  howItWorks,
  isFeatured,
  onClick,
}) => {
  return (
    <>
      {isLoading ? (
        <Skeleton height={250} sx={{ transform: "unset" }} />
      ) : (
        <div className={styles.container} onClick={onClick}>
          <div className={styles.highlight} />
          <div className={styles.header}>
            <Typography className={styles.type}>{offeringType}</Typography>

            <div className={styles.actions}>
              {isFeatured && (
                <Chip
                  className={styles.chip}
                  size="small"
                  label="Featured"
                  variant="filled"
                />
              )}
              <IconButton
                size="medium"
                onClick={onClick}
                IconComponent={ArrowForwardIcon}
              />
            </div>
          </div>

          <div className={styles.content}>
            <Typography className={styles.name}>{partnerName}</Typography>

            <div className={styles.descriptions}>
              <ExpandableDescription
                title="About"
                description={partnerDescription}
              />
              <ExpandableDescription
                title="How It Works"
                description={howItWorks}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OfferingsListCard;
