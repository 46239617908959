import React from "react";

import { useParams } from "react-router-dom";

import useClientAccounts from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAccounts";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import Button from "../../../MUI/Button/Button";
import { actions } from "../../../TaxBudgets/reducer/actions";
import { useCreateEditTaxBudgetContext } from "../../../TaxBudgets/reducer/Context";
import useClientName from "@src/multiCustodian/hooks/useClientName";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import ActionRow from "@src/sharedComponents/ActionRow/ActionRow";

const AccountsReviewRow: React.FC = () => {
  const { clientId } = useParams<{ clientId: ClientId }>();

  const auth = useRequestAuth();

  const { data: accountData } = useClientAccounts(clientId, auth);

  const clientName = useClientName();

  const {
    state: {
      data: { excludedVirtualAccountIds },
    },
    dispatch,
  } = useCreateEditTaxBudgetContext();

  const accounts = accountData?.fartherAccounts || [];

  const accountNames = accounts
    .filter((account) => !excludedVirtualAccountIds.includes(account.accountId))
    .map((account) => account.accountDetails.displayName);

  const handleOnClick = () =>
    dispatch({ type: actions.SET_PAGE_INDEX, payload: 1 });

  return (
    <ActionRow
      start={`${clientName?.name.first || ""}'s Accounts (${
        accountNames.length
      })`}
      end={accountNames.join(", ")}
      action={
        <Button
          text="Edit"
          variant="text"
          buttonType="primary"
          onClick={handleOnClick}
        />
      }
    />
  );
};

export default AccountsReviewRow;
