import React from "react";

const TaxBudgets: React.FC = () => (
  <svg
    width="100%"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8323 19.4486L4.92188 31.1776V40.0781H40.0781L40.0781 16.357H30.6959L25.4297 22.3808L15.8323 19.4486Z"
      fill="currentColor"
    />
    <path
      d="M32.8473 13.8961H40.0781L40.0781 5.625L32.8473 13.8961Z"
      fill="currentColor"
    />
    <path
      d="M29.4782 15.7422H4.92188V14.3359H30.9375L29.4782 15.7422Z"
      fill="currentColor"
    />
  </svg>
);

export default TaxBudgets;
