import { useMemo } from "react";

import { useSelector } from "react-redux";

import { RequestConfig } from "@fartherfinance/frontend/api/Types";

import { captureException } from "@src/multiCustodian/services/tracking";
import { State } from "@src/store";

export default function useRequestAuth(): RequestConfig | null {
  const { farther_jwt, cockroach_advisor_id, isAdvisor, clientId } =
    useSelector((state: State) => ({
      farther_jwt: state.main_Reducer.farther_jwt,
      cockroach_advisor_id: state.main_Reducer.cockroach_advisor_id,
      isAdvisor: state.main_Reducer.user.isAdvisor,
      clientId: state.main_Reducer.user.id_user,
    }));

  const auth: RequestConfig | null = useMemo(() => {
    if (farther_jwt === null || clientId === null) {
      return null;
    }

    if (isAdvisor === true && cockroach_advisor_id === null) {
      const errorMessage = "isAdvisor and cockroach_advisor_id DO NOT AGREE";
      console.error(errorMessage);
      const e = new Error(errorMessage);
      captureException(e);
    }

    return {
      jwt: farther_jwt,
      clientId: clientId,
      ...(cockroach_advisor_id && { advisorId: cockroach_advisor_id }),
    };
  }, [clientId, cockroach_advisor_id, farther_jwt, isAdvisor]);

  return auth;
}
