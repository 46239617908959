import React from "react";

import useRequestDocuments from "@fartherfinance/frontend/api/CustodianDoc/hooks/useRequestDocuments";

import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

export default function PreloadDocuments(): JSX.Element {
  const auth = useRequestAuth();
  // Request custodian doc center documents to grab newest documents from the custodian
  const docs = useRequestDocuments(auth);

  return (
    <div
      id="PreloadDocuments"
      data-loading={docs.isLoading ? "loading" : "done-loading"}
      data-data={docs.data === undefined ? "no-data" : docs.data.message}
      data-error={docs.hasError ? "error" : "no-error"}
      style={{ display: "none" }}
    />
  );
}
