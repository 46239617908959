import { OfferingsListToolbarFilters } from "@src/multiCustodian/components/Marketplace/OfferingsList/useOfferingsListDataV2";
import { assertUnreachable } from "@src/utils/assertUnreachable";

export const mapFilterKeyToLabel = (
  filterKey: keyof OfferingsListToolbarFilters
): string => {
  switch (filterKey) {
    case "filterByOfferingType":
      return "Offering Type";

    case "filterByObjectives":
      return "Objectives";

    case "filterByStrategy":
      return "Strategy";

    case "filterBySubStrategy":
      return "Substrategy";

    case "filterByMinimumInvestment":
      return "Minimum Investment";

    case "filterByInvestorQualification":
      return "Investor Qualification";

    case "filterByLiquidity":
      return "Liquidity";

    case "filterBySubscriptions":
      return "Subscriptions";

    case "filterByLockUp":
      return "Lock Up";

    default:
      return assertUnreachable(filterKey);
  }
};
