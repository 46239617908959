import React, { PropsWithChildren, useMemo } from "react";

import { useLocation } from "react-router-dom";

import AdvisorContainer from "./AdvisorContainer";
import AdvisorContentContainer from "./AdvisorContentContainer";
import PlansHeaderAndList from "./components/PlansHeaderAndList";

export default function Plans(props: PropsWithChildren<unknown>) {
  const { search } = useLocation();

  const qs = useMemo(() => new URLSearchParams(search), [search]);

  const page = qs.get("page");
  let parsedPage = page ? Number(page) : undefined;
  if (parsedPage !== undefined && isNaN(parsedPage)) {
    parsedPage = undefined;
  }

  return (
    <AdvisorContainer>
      <AdvisorContentContainer heroTitle={"Plans"}>
        <PlansHeaderAndList
          startPage={parsedPage}
          startQuery={qs.get("query") ?? undefined}
        />
        <>{props.children}</>
      </AdvisorContentContainer>
    </AdvisorContainer>
  );
}
