export const POST_TRADE_PORTFOLIO_HEADERS = [
  "Account",
  "Ticker",
  "Total Quantity",
  "Price",
  "Total Market Value",
  "Weight",
] as const;

export const TARGET_PORTFOLIO_HEADERS = ["Weight", "Difference"] as const;
