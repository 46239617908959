import React, { useCallback } from "react";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { IconButton } from "@mui/material";

import { SecurityId, Ticker } from "@fartherfinance/frontend/api/Types";

import styles from "./SecuritySearchTable.module.css";

interface Props {
  securityId: SecurityId;
  ticker: Ticker;
  description: string;
  removeSecurity: (ticker: SecurityId) => void;
}

const SecuritySearchTableRow: React.FC<Props> = ({
  securityId,
  ticker,
  description,
  removeSecurity,
}) => {
  const onRemove = useCallback(
    () => removeSecurity(securityId),
    [removeSecurity, securityId]
  );

  return (
    <div className={styles.row}>
      <div className={styles.tickerContainer}>
        <IconButton className={styles.deleteIconContainer} onClick={onRemove}>
          <RemoveCircleOutlineIcon className={styles.deleteIcon} />
        </IconButton>

        <div className={styles.ticker}>{ticker}</div>

        <div className={styles.description}>{description}</div>
      </div>
    </div>
  );
};

export default SecuritySearchTableRow;
