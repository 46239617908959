import React from "react";

import { useParams } from "react-router-dom";
import { Navigate } from "react-router-dom-v5-compat";

import { ProposalId } from "@fartherfinance/frontend/api/Types";

import { ProposalDetails } from "./ProposalDetails.component";

type ProposalDetailsPathParams = Record<"proposalId", ProposalId>;

export const ProposalDetailsGuard: React.FC = () => {
  const { proposalId } = useParams<ProposalDetailsPathParams>();

  if (!proposalId) {
    return (
      <Navigate
        to="/Advisor/Client-Ops/Dashboard/Daily-Trading/Daily-Proposals"
        replace={true}
      />
    );
  }

  return <ProposalDetails currentProposalId={proposalId} />;
};
