import React, { useState } from "react";

import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

import {
  FartherAccountId,
  PortfolioId,
  TradingGroupId,
} from "@fartherfinance/frontend/api/Types";

import { SHARED_ACCOUNT_TOOLTIP_TEXT } from "../const";
import ApplyToAccountsModal from "../TradingGroupsTable/modals/ApplyToAccountsModal";
import AccountListDetails from "../utils/AccountListDetails";
import { AccountWithPortfolio } from "../utils/useAvailableAccountFromTradingGroups";
import Checkbox from "@src/sharedComponents/Checkbox/Checkbox";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import styles from "./AccountsListSelection.module.css";

interface Props {
  portfolioId: PortfolioId;
  account: AccountWithPortfolio;
  applyAccount: (
    isChecked: boolean,
    accountId: FartherAccountId,
    tradingGroupId: TradingGroupId | null
  ) => void;
}

const ApplyAccountRow = ({
  portfolioId,
  account,
  applyAccount,
}: Props): JSX.Element => {
  const [showApplyToAccountsModal, setShowApplyToAccountsModal] =
    useState<boolean>(false);

  const onApply = () =>
    applyAccount(
      account.isChecked ?? false,
      account.accountId,
      account.tradingGroupId
    );

  const onChange = () => {
    if (account.isChecked) {
      onApply();
    } else {
      setShowApplyToAccountsModal(true);
    }
  };

  const isShared = account.sharedBy.length > 0;

  return (
    <>
      {showApplyToAccountsModal && (
        <ApplyToAccountsModal
          closeModal={() => setShowApplyToAccountsModal(false)}
          onConfirm={onApply}
        />
      )}

      <div className={styles.optionDiv}>
        <div className={styles.flex}>
          <Checkbox
            label={account.name}
            checked={account.isChecked ?? false}
            onChange={onChange}
            disabled={account.isDisabled || isShared}
          />

          {isShared && (
            <Tooltip
              tooltipText={SHARED_ACCOUNT_TOOLTIP_TEXT}
              placement={"top"}
            >
              <PeopleAltIcon className={styles.sharedIcon} />
            </Tooltip>
          )}
        </div>

        <AccountListDetails
          account={account}
          currentPortfolioId={portfolioId}
        />
      </div>
    </>
  );
};

export default ApplyAccountRow;
