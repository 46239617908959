/**
 * Similar to lodash's chunk but leftover elements are distributed evenly
 * @param arr Array of type T
 * @param chunkSize how many elements in each sub array
 */
export default function frontEvenChunk<T>(arr: T[], chunkSize: number): T[][] {
  // 22 length & 5 chunkSize => 4 chunks
  const chunks = Math.max(1, Math.floor(arr.length / chunkSize));
  const numberOfChunksWithExtraElement = arr.length % chunkSize;

  return Array.from({ length: chunks }, (_, idx) => idx).reduce<T[][]>(
    (chunks, idx) => {
      const elementsInThisChunk =
        idx < numberOfChunksWithExtraElement ? chunkSize + 1 : chunkSize;
      const start = chunks.flat().length;
      const newChunk = arr.slice(start, start + elementsInThisChunk);

      return [...chunks, newChunk];
    },
    []
  );
}
