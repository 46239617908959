import React from "react";

import TitleAndDescription from "../../../TaxBudgets/Hero/TitleAndDescription";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";
import BorderBoxHeader from "@src/sharedComponents/BorderBox/BorderBoxHeader";

import styles from "./AdvisorDisclaimer.module.css";

const AdvisorDisclaimer = (): JSX.Element => {
  return (
    <BorderBox className={styles.container}>
      <BorderBoxHeader
        className={styles.header}
        start={
          <TitleAndDescription
            title="Tax Budgets Administered by Advisor"
            titleStyle={styles.titleStyle}
            description="Your advisor has already set up a tax budget that involves additional parties (e.g. a partner or a spouse). Please work with your advisor to make changes to your tax budget."
          />
        }
      />
    </BorderBox>
  );
};

export default AdvisorDisclaimer;
