import React, { useState } from "react";

import { format } from "date-fns";
import { isNil } from "lodash";
import { FormProvider, useForm, useFormState, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";

import { investingObjectiveOptions } from "@fartherfinance/frontend/api/Dashboard/constants/investingObjective";
import { investorTypeOptions } from "@fartherfinance/frontend/api/Dashboard/constants/investorType";
import useClientProfile from "@fartherfinance/frontend/api/Entity/hooks/useClientProfile";
import usePutInvestorProfile from "@fartherfinance/frontend/api/Entity/hooks/usePutInvestorProfile";
import {
  Address,
  ClientEmploymentDetails,
  ClientInvestorProfile,
  Employer,
  TaxInformation,
} from "@fartherfinance/frontend/api/Entity/requests/getClientProfile";
import { USAStates } from "@fartherfinance/frontend/api/Types";

import FormFooter from "../components/FormFooter/FormFooter";
import formatAmount from "../formatters/formatAmount";
import formatSSN from "../formatters/formatSSN";
import has5Digits from "../validators/has5Digits";
import isInteger from "../validators/isInteger";
import isLessThanContributionLimit from "../validators/isLessThanContributionLimit";
import isOnOrAfter1950 from "../validators/isOnOrAfter1950";
//import isPositive from "../validators/isPositive"; // will add this back in the near future
import isValidLocaleNumber from "../validators/isValidLocaleNumber";
import isValidSSN from "../validators/isValidSSN";
import numberWithinLimit from "../validators/numberWithinLimit";
import { citizenshipOptions } from "@src/constants/citizenship";
import { employmentStatusOptions } from "@src/constants/employment";
import {
  incomeRangeOptions,
  incomeRangeToLabel,
} from "@src/constants/incomeRange";
import Portal from "@src/multiCustodian/components/Portal/Portal";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import useThemeFragment from "@src/multiCustodian/theme/useThemeFragment";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormDateField from "@src/sharedComponents/Forms/FormDateField";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import FormNumberField from "@src/sharedComponents/Forms/FormNumberField";
import FormRadioField from "@src/sharedComponents/Forms/FormRadioField";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import { State } from "@src/store";

import { EmployedForm, Form, NotEmployedForm } from "./types";
import isEmployedForm from "./utils/isEmployedForm";
import mapDefaultValues from "./utils/mapDefaultValues";

import styles from "../SharedFormStyle.module.css";

const TAX_LTG_MIN = 0;
const TAX_LTG_MAX = 25;
const TAX_ORD_MIN = 0;
const TAX_ORD_MAX = 100;

interface Props {
  drawerFooterPortalId: string;
  onClose: () => void;
}

export default function FinancialFormFetcher(props: Props): JSX.Element {
  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));

  const auth = useRequestAuth();

  const clientProfile = useClientProfile(clientId, auth);

  if (clientProfile.isLoading) {
    return (
      <div className={styles.loading}>
        <LogoLoadingStill />
      </div>
    );
  }

  if (clientProfile.hasError) {
    return <div>Error</div>;
  }

  return (
    <FinancialForm
      drawerFooterPortalId={props.drawerFooterPortalId}
      investorProfile={clientProfile.data.investorProfile}
      employmentDetails={clientProfile.data.employmentDetails}
      onClose={props.onClose}
    />
  );
}

interface FinancialFormProps {
  drawerFooterPortalId: string;
  investorProfile: ClientInvestorProfile;
  employmentDetails: ClientEmploymentDetails;
  onClose: () => void;
}

function FinancialForm(props: FinancialFormProps): JSX.Element {
  const { clientId, isAdvisor } = useSelector((state: State) => ({
    isAdvisor: state.main_Reducer.user.isAdvisor,
    clientId: state.main_Reducer.user.id_user,
  }));

  const t = useThemeFragment("Farther");

  const auth = useRequestAuth();

  const [isMutating, setIsMutating] = useState(false);

  const statusNotification = useStatusNotification();

  const callPutInvestorProfile = usePutInvestorProfile(clientId, auth);

  const validateWholeForm = !isAdvisor;

  const methods = useForm<Form>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: mapDefaultValues(
      props.investorProfile,
      props.employmentDetails
    ),
  });

  const { control, getValues, setValue } = methods;

  const { isValid } = useFormState({ control });

  const isSubmitDisabled = !isValid || isMutating;

  const isRequired = validateWholeForm ? "Must not be empty" : false;

  const [EmploymentStatus, ContributedToIRA] = useWatch({
    control,
    name: ["EmploymentStatus", "ContributedToIRA"],
  });

  const contributionAmountRequired = ContributedToIRA === "Yes";

  const submit = async () => {
    if (isSubmitDisabled) {
      return;
    }

    const values = getValues();

    if (
      (validateWholeForm && values.EmploymentStatus === null) ||
      (isEmployedForm(values) &&
        (values.StartYear === null || values.State === null))
    ) {
      return;
    }

    const taxInformation: TaxInformation | null = !isNil(values.TaxID)
      ? {
          taxIdType: values.TaxID.startsWith("9") ? "ITIN" : "SSN",
          taxId: values.TaxID,
        }
      : null;

    //Backend accepts netWorth in decimals, but returns it in millions, so we convert it before sending.
    const netWorth =
      !isNil(values.NetWorth) && values.NetWorth !== ""
        ? parseFloat(values.NetWorth.replace(/,/g, "")) / 1_000_000
        : null;

    const iraOutsideFarther =
      !isNil(values.ContributionAmount) && values.ContributionAmount !== ""
        ? parseFloat(values.ContributionAmount.replace(/,/g, ""))
        : null;

    const address: Address | null =
      isEmployedForm(values) && !isNil(values.State)
        ? {
            street: values.BusinessAddress1 ?? "",
            unit: values.BusinessAddress2 ?? null,
            city: values.City ?? "",
            state: values.State.label,
            postalCode: values.ZipCode ?? "",
            country: "US",
          }
        : null;

    const employer: Employer | null =
      isEmployedForm(values) && !isNil(values.StartYear) && address !== null
        ? {
            occupation: values.Position ?? "",
            startYear: Number(format(values.StartYear, "yyyy")),
            employerName: values.EmployerName ?? "",
            employerAddress: address,
          }
        : null;

    const employmentForm: ClientEmploymentDetails = {
      employmentStatus: !isNil(values.EmploymentStatus)
        ? values.EmploymentStatus.label
        : null,
      employer,
    };

    const investorProfileForm: ClientInvestorProfile = {
      ...props.investorProfile,
      personalDetails: {
        ...props.investorProfile.personalDetails,
        citizenship: values.CitizenshipStatus?.label ?? null,
        taxInformation,
        incomeRange: values.IncomeRange?.range ?? null,
        estimatedNetWorthMillions: netWorth,
        iraOutsideFarther,
        investingObjective: values.InvestingObjective?.label ?? null,
        investorType: values.InvestorType?.label ?? null,
      },
      taxBracketInfo: {
        taxBracketLTG: !isNil(values.TaxBracketLTG)
          ? parseInt(values.TaxBracketLTG, 10)
          : null,
        taxBracketOrdinary: !isNil(values.TaxBracketOrdinary)
          ? parseInt(values.TaxBracketOrdinary, 10)
          : null,
        capitalLossCarryover: !isNil(values.CapitalLossCarryover)
          ? parseFloat(values.CapitalLossCarryover)
          : null,
      },
      employmentDetails: employmentForm,
    };

    try {
      setIsMutating(true);

      await callPutInvestorProfile(investorProfileForm);

      statusNotification("Updated additional info", "Success");

      props.onClose();
    } catch {
      setIsMutating(false);

      statusNotification("Failed to update additional info.", "Error");
    }
  };

  const clearField = (
    field: keyof EmployedForm | keyof NotEmployedForm
  ): void => {
    setValue(field, null);
  };

  const showEmployedFields =
    !isNil(EmploymentStatus) && EmploymentStatus.label === "Employed";

  return (
    <FormProvider {...methods}>
      <FormH1>Provide additional information</FormH1>

      <FlexWrapper>
        <FormDropdownField
          name="CitizenshipStatus"
          label="Citizenship Status"
          disableSearch
          required={isRequired}
          onClear={() => clearField("CitizenshipStatus")}
          values={citizenshipOptions.map((e) => ({ label: e }))}
        />

        <FormTextField
          name="TaxID"
          label="SSN (or ITIN)"
          required={isRequired}
          rules={{
            validate: {
              isValidSSN: (str) => isValidSSN(str, validateWholeForm),
            },
          }}
          valueFormatterOnChange={(e) => {
            const prevTaxID = getValues("TaxID");

            return !isNil(prevTaxID) ? formatSSN(prevTaxID, e) : e;
          }}
          valueFormatterOnBlur={(e) => {
            const prevTaxID = getValues("TaxID");

            return !isNil(prevTaxID) ? formatSSN(prevTaxID, e) : e;
          }}
        />

        <FormDropdownField
          name="IncomeRange"
          label="Income Range"
          required={isRequired}
          disableSearch
          onClear={() => clearField("IncomeRange")}
          values={incomeRangeOptions.map((e) => ({
            label: incomeRangeToLabel[e],
            range: e,
          }))}
        />

        <FormTextField
          name="NetWorth"
          label="Net Worth"
          required={isRequired}
          valueFormatterOnChange={formatAmount}
          valueFormatterOnBlur={formatAmount}
          startAdornment="$"
          rules={{
            validate: {
              isValidNumber: (e) => isValidLocaleNumber(e, validateWholeForm),
            },
          }}
        />

        <FormDropdownField
          name="InvestorType"
          label="Investor Type"
          disableSearch
          required={isRequired}
          onClear={() => clearField("InvestorType")}
          values={investorTypeOptions.map((e) => ({ label: e }))}
        />

        <FormDropdownField
          name="InvestingObjective"
          label="Investing Objective"
          disableSearch
          required={isRequired}
          onClear={() => clearField("InvestingObjective")}
          values={investingObjectiveOptions.map((e) => ({ label: e }))}
        />

        <FormNumberField
          name="TaxBracketLTG"
          label="Tax Bracket (long-term capital gains)"
          labelTooltip="If you participate in tax-aware investment strategies, we typically assume the highest tax brackets by default. If you wish to customize your experience, specify your tax information here. It's ok to provide an estimate."
          required={isRequired}
          rules={{
            validate: {
              isInteger: (v) => isInteger(v, validateWholeForm),
              numberWithinLimit: (v) =>
                numberWithinLimit(
                  v,
                  TAX_LTG_MIN,
                  TAX_LTG_MAX,
                  validateWholeForm
                ),
            },
          }}
          endAdornment="%"
        />

        <FormNumberField
          name="TaxBracketOrdinary"
          label="Tax Bracket (ordinary income)"
          labelTooltip="If you participate in tax-aware investment strategies, we typically assume the highest tax brackets by default. If you wish to customize your experience, specify your tax information here. It's ok to provide an estimate."
          required={isRequired}
          rules={{
            validate: {
              isInteger: (v) => isInteger(v, validateWholeForm),
              numberWithinLimit: (v) =>
                numberWithinLimit(
                  v,
                  TAX_ORD_MIN,
                  TAX_ORD_MAX,
                  validateWholeForm
                ),
            },
          }}
          endAdornment="%"
        />

        {/* Removed for the time being - will be added back in the near future
          <FormTextField
            name="CapitalLossCarryover"
            label="Capital Loss Carryover"
            startAdornment="$"
            required={isRequired}
            valueFormatterOnChange={formatAmount}
            valueFormatterOnBlur={formatAmount}
            rules={{
              validate: {
                isValidNumber: (v) => isValidLocaleNumber(v, validateWholeForm),
                isPositive: (v) => isPositive(v, validateWholeForm),
              },
            }}
          />
          */}
      </FlexWrapper>

      <FlexWrapper>
        <FormRadioField
          name="ContributedToIRA"
          label={`Have you contributed to an IRA outside of ${t(
            "companyName"
          )} this year?`}
          values={["Yes", "No"]}
          required={isRequired}
        />

        {contributionAmountRequired && (
          <FormTextField
            name="ContributionAmount"
            label="How much did you contribute?"
            required={"Must not be empty"}
            valueFormatterOnChange={formatAmount}
            valueFormatterOnBlur={formatAmount}
            rules={{
              validate: {
                isValidNumber: (e) => isValidLocaleNumber(e, true),
                isLessThanContributionLimit,
              },
            }}
            startAdornment="$"
            inputMode="numeric"
          />
        )}
      </FlexWrapper>

      <FlexWrapper>
        <FormDropdownField
          name="EmploymentStatus"
          label="Employment Status"
          required={isRequired}
          disableSearch
          onClear={() => clearField("EmploymentStatus")}
          values={employmentStatusOptions.map((e) => ({ label: e }))}
        />

        {showEmployedFields && (
          <>
            <FormTextField
              name="EmployerName"
              label="Employer Name"
              required={isRequired}
              rules={{
                minLength: {
                  value: 2,
                  message: "Must have 2 or more characters",
                },
              }}
            />

            <FormTextField
              name="Position"
              label="Position"
              required={isRequired}
              rules={{
                minLength: {
                  value: 2,
                  message: "Must have 2 or more characters",
                },
              }}
            />

            <FormDateField
              name="StartYear"
              label="Start Year"
              required={"Must not be empty"}
              disableOpenPicker
              inputFormat={"yyyy"}
              rules={{
                validate: {
                  isOnOrAfter1950,
                },
              }}
              placeholder="YYYY"
            />

            <FormTextField
              name="BusinessAddress1"
              label="Business Address"
              required={isRequired}
            />

            <FormTextField
              name="BusinessAddress2"
              label="Business Address 2 (Optional)"
            />

            <FormTextField
              name="City"
              label="City"
              required={isRequired}
              rules={{
                minLength: {
                  value: 3,
                  message: "City must have 3 or more characters",
                },
              }}
            />

            <FormDropdownField
              name="State"
              label="State"
              required={"Must not be empty"}
              values={USAStates.map((e) => ({ label: e }))}
              onClear={() => clearField("State")}
            />

            <FormNumberField
              name="ZipCode"
              label="Zip Code"
              required={isRequired}
              rules={{
                validate: {
                  has5Digits: (e) => has5Digits(e, validateWholeForm),
                },
              }}
            />
          </>
        )}
      </FlexWrapper>

      <Portal targetNodeId={props.drawerFooterPortalId}>
        <FormFooter isSubmitDisabled={isSubmitDisabled} handleSubmit={submit} />
      </Portal>

      {isMutating && (
        <div className={styles.loading}>
          <LogoLoadingStill />
        </div>
      )}
    </FormProvider>
  );
}
