import React, { useCallback, useMemo } from "react";

import EastIcon from "@mui/icons-material/East";
import { orderBy } from "lodash";
import { useHistory } from "react-router-dom";

import { EquivalentSecurityList } from "@fartherfinance/frontend/api/EquivalentSecurities/requests/getEquivalentSecurityList";

import useEquivalentSecurityListMembersWithInfo from "../useEquivalentSecurityListMembersWithInfo";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./EquivalentSecuritiesAdminTable.module.css";

const MAX_TICKERS_SHOWN = 6; // show this many tickers before cutting off with "..." in row

interface Props {
  eslist: EquivalentSecurityList;
}

const EquivalentSecuritiesAdminRow: React.FC<Props> = ({ eslist }) => {
  const history = useHistory();

  const list = useEquivalentSecurityListMembersWithInfo(eslist.listId);

  const navToSecuritiesList = useCallback(
    () =>
      history.push({
        pathname: `/Advisor/Admin/EquivalentSecurities/${eslist.listId}`,
        state: history.location.state,
      }),
    [history, eslist]
  );

  const tickers = useMemo(() => {
    if (list.isLoading || list.hasError) {
      return "";
    }

    const tickers = list.data.members
      .slice(0, MAX_TICKERS_SHOWN)
      .map((sec) => sec.ticker);

    const orderedTickers = orderBy(tickers, [(t) => t], ["asc"]);

    return `${orderedTickers.join(", ")}${
      list.data.members.length > MAX_TICKERS_SHOWN ? "..." : ""
    }`;
  }, [list]);

  if (list.hasError) {
    return <div>{list.error}</div>;
  }

  return (
    <div className={styles.row}>
      <div className={toClassName(styles.paddingLeft, styles.text)}>
        {eslist.name}
      </div>

      <div className={styles.rowEnd}>
        <div className={styles.text}>{tickers}</div>

        <div className={styles.hoverContainer}>
          <div className={styles.rowRedirectIconContainer}>
            <EastIcon className={styles.icon} onClick={navToSecuritiesList} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EquivalentSecuritiesAdminRow;
