import React, { PropsWithChildren } from "react";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./Text.module.css";

interface Props {
  className?: string;
  variant?: "body" | "default" | "helperError" | "title1" | "title2" | "title3";
}

const Text: React.FC<PropsWithChildren<Props>> = ({
  children,
  className,
  variant,
}) => {
  const isTitleVariant =
    variant === "title1" || variant === "title2" || variant === "title3";

  return (
    <div
      className={toClassName(
        {
          [styles.title]: isTitleVariant,
          [styles.title1]: variant === "title1",
          [styles.title2]: variant === "title2",
          [styles.title3]: variant === "title3",
          [styles.body]: variant === "body",
          [styles.helperError]: variant === "helperError",
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export default Text;
