import React, { PropsWithChildren, useEffect, useMemo } from "react";

import { useHistory, useLocation, useParams } from "react-router-dom";

import useAllPortfolios from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useAllPortfolios";
import { ClientId, HookResult } from "@fartherfinance/frontend/api/Types";

import PortfoliosTable, {
  GeneralPortfolioData,
} from "@src/multiCustodian/components/Client/Portfolio/Portfolios/PortfoliosTable";
import PortfoliosTableLoading from "@src/multiCustodian/components/Client/Portfolio/Portfolios/PortfoliosTableLoading";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import { trackEvent } from "@src/multiCustodian/services/tracking";
import LinkButton from "@src/sharedComponents/LinkButton/LinkButton";

import styles from "./Portfolios/Create.module.css";
import styles2 from "@src/multiCustodian/components/Client/Portfolio/Portfolios/PortfoliosTable.module.css";

/**
 * We provide locationState when performing history.push to let this file know that we've arrived
 * here from the Create page, and should not enforce routing back to it.
 */
interface PortfolioLocationState {
  shouldRouteOnEmptyPortfolios?: boolean; // default is false
}

export const portfolioLocationState: PortfolioLocationState = {
  shouldRouteOnEmptyPortfolios: false,
};

interface Props {
  atRoot?: boolean;
}

const Portfolios = (props: PropsWithChildren<Props>): JSX.Element => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const history = useHistory();
  const location = useLocation<PortfolioLocationState | undefined>();
  const auth = useRequestAuth();

  const portfolioList = useAllPortfolios(clientId, auth);

  const portfolioListForTable = useMemo((): HookResult<
    GeneralPortfolioData[]
  > => {
    if (portfolioList.isLoading || portfolioList.hasError) {
      return portfolioList;
    }

    return {
      data: portfolioList.data
        .filter((p) => p.isActive)
        .map(
          (p): GeneralPortfolioData => ({
            portfolioModelId: p.portfolioModelId,
            displayName: p.displayName,
            createTime: p.createTime,
            taxType: p.taxType,
            assetAllocation: "assetAllocation" in p ? p.assetAllocation : null,
            directIndexing: "directIndexing" in p ? p.directIndexing : null,
            accountsApplied: p.accountsApplied,
            portfolioPartner: p.set,
          })
        ),
      isLoading: false,
      hasError: false,
    };
  }, [portfolioList]);

  useEffect(() => {
    trackEvent({ name: "Client Open Portfolios" });
  }, []);

  useEffect(() => {
    if (portfolioListForTable.data === undefined) {
      return;
    }

    if (
      portfolioListForTable.data.length === 0 &&
      location.state?.shouldRouteOnEmptyPortfolios !== false &&
      "atRoot" in props &&
      props.atRoot === true
      //This action should only be performed if we are in the root Portfolios.tsx route
    ) {
      history.push(`/Client/${clientId}/Investments/Portfolios/Create`);
    }
  }, [
    clientId,
    history,
    location.pathname,
    location.state?.shouldRouteOnEmptyPortfolios,
    portfolioListForTable.data,
    props,
  ]);

  if (portfolioListForTable.hasError) {
    return <PortfoliosError>{props.children}</PortfoliosError>;
  }

  if (portfolioListForTable.isLoading) {
    return <PortfoliosLoading>{props.children}</PortfoliosLoading>;
  }

  return (
    <div>
      <div className={styles2.container}>
        <div className={styles2.centeringContainer}>
          <div className={styles2.linkButton}>
            <LinkButton
              text="Select Model Portfolio"
              to={`/Client/${clientId}/Investments/Portfolios/Create`}
              buttonType="primary"
              variant="contained"
            />
          </div>

          <PortfoliosTable
            data={portfolioListForTable.data}
            clientId={clientId}
          />
        </div>
      </div>

      {props.children}
    </div>
  );
};

export default Portfolios;

const PortfoliosLoading = (props: PropsWithChildren<unknown>) => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  return (
    <div>
      <div className={styles2.container}>
        <div className={styles2.centeringContainer}>
          <div className={styles2.linkButton}>
            <LinkButton
              text="Select Model Portfolio"
              to={`/Client/${clientId}/Investments/Portfolios/Create`}
              buttonType="primary"
              variant="contained"
            />
          </div>

          <PortfoliosTableLoading />
        </div>
      </div>

      {props.children}
    </div>
  );
};

const PortfoliosError = (props: PropsWithChildren<unknown>) => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  return (
    <div>
      <div className={styles2.container}>
        <div className={styles2.centeringContainer}>
          <div className={styles2.linkButton}>
            <LinkButton
              text="Select Model Portfolio"
              to={`/Client/${clientId}/Investments/Portfolios/Create`}
              buttonType="primary"
              variant="contained"
            />
          </div>

          <div className={styles.loadingContainer}>
            <p>Error retrieving portfolios</p>
          </div>
        </div>
      </div>

      {props.children}
    </div>
  );
};
