import React from "react";

import { Stack, Typography } from "@mui/material";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./SummaryItem.module.css";

interface SummaryItemProps {
  label: string;
  value: React.ReactNode;
}

const SummaryItem = ({ label, value }: SummaryItemProps): JSX.Element => {
  return (
    <Stack
      className={styles.container}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography className={toClassName(styles.text, styles.label)}>
        {label}
      </Typography>
      <Typography className={toClassName(styles.text, styles.value)}>
        {value}
      </Typography>
    </Stack>
  );
};

export default SummaryItem;
