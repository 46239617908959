import React, { useMemo } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  FormControl,
  FormLabel,
  InputAdornment,
  Popper,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { createTheme, SxProps, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";

import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import SearchIcon from "../../assets/svg/search_icon.svg";
import { State } from "../../store";
import { fontSize } from "../constants";

const underlineStyle: Record<string, React.CSSProperties> = {
  neutral: {
    borderBottom: "1px solid var(--borderBold)",
    width: "100%",
  },
  good: {
    borderBottom: "1px solid var(--iconSuccess)",
    width: "100%",
  },
  bad: {
    borderBottom: "1px solid var(--borderDanger)",
    width: "100%",
  },
};

//MUI Autocomplete: https://mui.com/material-ui/react-autocomplete/
export interface AutocompleteOption {
  label: string;
}

export interface Props<T extends AutocompleteOption> {
  formLabel?: React.ReactNode;
  value: T | null;
  onChange: (newValue: T) => void;
  onClear?: () => void;
  values: T[] | readonly T[];
  placeholder?: string;
  sx?: SxProps;
  style?: React.CSSProperties;
  readOnly?: boolean;
  disabled?: boolean;
  isValid?: boolean;
  disableSearch?: boolean;
  loading?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  getOptionKey?: (option: T) => React.Key;
  getOptionDisabled?: (option: T) => boolean;
  inputValue?: string;
  onInputChange?: (
    e: React.SyntheticEvent<Element, Event>,
    newInputValue: string
  ) => void;
  disablePortal?: boolean;
  groupBy?: (option: T) => string;
}

const Dropdown = <T extends AutocompleteOption>(
  props: Props<T>
): JSX.Element => {
  const {
    color: {
      $text,
      $textSubtle,
      $textSubtlest,
      $textDisabled,
      $icon,
      $iconDisabled,
      $borderBold,
      $backgroundNeutralSubtle,
    },
  } = useTheme();

  const { fontRegular } = useSelector((state: State) => ({
    fontRegular: state.main_Reducer.brand.current.fonts.regular,
  }));

  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: fontRegular,
        },
        palette: {
          text: {
            primary: $text,
            secondary: $textSubtle, // Placeholder
            disabled: $textDisabled,
          },
          action: {
            active: $icon, // Chevron and X
            disabled: $iconDisabled,
          },
          background: {
            paper: $backgroundNeutralSubtle, // Dropdown Menu Background
          },
          secondary: {
            main: $textSubtle,
          },
        },
      }),
    [
      fontRegular,
      $text,
      $textSubtle,
      $textDisabled,
      $icon,
      $iconDisabled,
      $backgroundNeutralSubtle,
    ]
  );

  const textFieldSx: SxProps<typeof theme> = useMemo(
    () => ({
      ...(props.isValid !== undefined && {
        borderBottom: props.isValid ? underlineStyle.good : underlineStyle.bad,
      }),
      "& input": {
        padding: "0px",
      },
      "& .MuiInput-underline": {
        "&:after": {
          borderBottomWidth: 1,
          borderBottomColor: $borderBold,
        },
        "&:before": {
          borderBottomWidth: 1,
          borderBottomColor: $borderBold,
          borderBottomStyle: "solid",
        },
      },
      "& .MuiInputBase-root": {
        "&:hover:before": {
          borderBottomWidth: 1,
          borderBottomColor: $borderBold,
        },
      },
      "& .MuiSvgIcon-root": {
        "&:hover": {
          color: $text,
        },
      },
      "& .MuiFormHelperText-root": {
        color: $textSubtlest,
      },
      "& .MuiMenuItem-root.Mui-selected": {
        backgroundColor: "green",
      },
      "& .MuiMenuItem-root:hover": {
        backgroundColor: "pink",
      },
      "& .MuiMenuItem-root.Mui-selected:hover": {
        backgroundColor: "red",
      },
    }),
    [props.isValid, $text, $textSubtlest, $borderBold]
  );

  const boxSx = useMemo(
    () => ({
      margin: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontSize: "14px",
    }),
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <FormControl
        fullWidth={true}
        disabled={props.disabled}
        sx={{ ...props.style, ...props.sx }}
      >
        {props.formLabel && (
          <FormLabel
            sx={{
              fontFamily: "var(--fakt)",
              color: $textSubtle,
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "20px",
              letterSpacing: "0.28",
            }}
          >
            {props.formLabel}
          </FormLabel>
        )}

        <Autocomplete
          value={props.value}
          options={props.values}
          disableClearable={props.onClear === undefined}
          disablePortal={props.disablePortal ?? true}
          onBlur={props.onBlur}
          inputValue={props.inputValue}
          onInputChange={props.onInputChange}
          getOptionDisabled={props.getOptionDisabled}
          groupBy={props.groupBy}
          isOptionEqualToValue={(option: T, value: T) =>
            (option === null && value === null) || option.label === value.label
          }
          onChange={(_e, val, reason) => {
            if (reason === "clear" && props.onClear) {
              props.onClear();
            } else if (val !== null) {
              props.onChange(val);
            }
          }}
          popupIcon={<ExpandMoreIcon color="secondary" />}
          renderInput={(params) => (
            <TextField
              variant="standard"
              {...params}
              ref={params.InputProps.ref}
              inputProps={{
                ...params.inputProps,
                style: { ...params.inputProps.style, fontSize: 14 },
                readOnly: props.disableSearch,
              }}
              placeholder={props.placeholder}
              sx={textFieldSx}
              error={props.error}
              helperText={props.helperText}
              InputProps={{
                ...params.InputProps,
                ...(!props.disableSearch && {
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                        <img src={SearchIcon} />
                      </InputAdornment>

                      {params.InputProps.startAdornment}
                    </>
                  ),
                }),
                style: { fontSize: fontSize },
              }}
            />
          )}
          PopperComponent={(props) => (
            <Popper {...props} placement="bottom-start" />
          )}
          renderOption={(
            optionProps: React.HTMLAttributes<HTMLLIElement> & {
              key?: React.Key;
            },
            option
          ) => (
            <li
              {...optionProps}
              key={props.getOptionKey?.(option) ?? optionProps.key}
            >
              <Box component={"p"} sx={boxSx}>
                {option.label}
              </Box>
            </li>
          )}
          readOnly={props.readOnly}
          disabled={props.disabled}
          loading={props.loading}
        />
      </FormControl>
    </ThemeProvider>
  );
};

export default Dropdown;
