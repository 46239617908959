import React, { useEffect, useState } from "react";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";

import usePasswordStrengthCheck from "@fartherfinance/frontend/api/Identity/hooks/usePasswordStrengthCheck";

import Button from "../MUI/Button/Button";
import TextInput from "@src/sharedComponents/TextInput/TextInput";
import Text from "@src/sharedComponents/Typography/Text";

import getDefaultEmail from "./getDefaultEmail";
import Layout from "./Layout";

import * as styles from "./StytchLogin.module.css";

interface Props {
  isLoading: boolean;
  onBack?: () => void;
  onPasswordResetClick: (email: string, password: string) => Promise<void>;
}

type PasswordLuds = {
  has_digit: boolean;
  has_lower_case: boolean;
  has_symbol: boolean;
  has_upper_case: boolean;
  missing_characters: number;
};

const PasswordHelper: React.FC<{ luds: PasswordLuds | null }> = ({ luds }) => {
  return (
    <div>
      <Text variant="body">
        <div>
          <span>
            {luds?.has_upper_case ? "✅" : "❌"} At least 1 uppercase character
          </span>
        </div>
        <div>
          <span>
            {luds?.has_lower_case ? "✅" : "❌"} At least 1 lowercase character
          </span>
        </div>
        <div>
          <span>{luds?.has_digit ? "✅" : "❌"} At least 1 digit</span>
        </div>
        <div>
          <span>{luds?.has_symbol ? "✅" : "❌"} At least 1 symbol</span>
        </div>
        <div>
          <span>
            {luds?.missing_characters === 0 ? "✅" : "❌"} At least 10
            characters
          </span>
        </div>
      </Text>
    </div>
  );
};

const PasswordReset: React.FC<Props> = ({
  isLoading,
  onBack,
  onPasswordResetClick,
}) => {
  const [email] = useState<string>(getDefaultEmail() ?? "");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [passWordStrengthOk, setPasswordStrengthOk] = useState<boolean | null>(
    null
  );
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [luds, setLuds] = useState<PasswordLuds | null>(null);

  const strengthCheck = usePasswordStrengthCheck();

  useEffect(() => {
    const [clientEmail, devEmail] = email.split("__");

    strengthCheck(password, devEmail ?? clientEmail).then((res) => {
      setLuds(res.feedback.luds_requirements);
      setPasswordStrengthOk(res.valid_password);
    });
  }, [email, password, strengthCheck]);

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);
  const handleClickShowConfirm = () => setShowConfirm((prev) => !prev);

  const continueDisabled =
    !passWordStrengthOk ||
    password !== confirmPassword ||
    password === "" ||
    confirmPassword === "";

  return (
    <Layout
      onBack={onBack}
      subtitle="Password requirements:"
      title="Set Password"
      widget={<PasswordHelper luds={luds} />}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (continueDisabled) {
            return;
          }

          onPasswordResetClick(email, password);
        }}
      >
        <TextInput
          label="Enter password"
          type={showPassword ? "text" : "password"}
          autoComplete="new-password"
          placeholder="••••••••••"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  className={styles.showIcon}
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <div className={styles.helperSpacer} />

        <TextInput
          label="Confirm password"
          type={showConfirm ? "text" : "password"}
          autoComplete="new-password"
          placeholder="••••••••••"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  className={styles.showIcon}
                  onClick={handleClickShowConfirm}
                  edge="end"
                >
                  {showConfirm ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {password !== confirmPassword && confirmPassword !== "" ? (
          <Text className={styles.helper} variant="helperError">
            Passwords do not match.
          </Text>
        ) : (
          <div className={styles.helperSpacer} />
        )}

        <Button
          disabled={continueDisabled}
          text="Continue"
          onClick={() => {
            onPasswordResetClick(email, password);
          }}
          variant="contained"
          buttonType="primary"
          sx={{ width: "100%", marginTop: "var(--space-2)" }}
          useLoader
          isLoading={isLoading}
        />

        <input type="submit" style={{ display: "none" }} />
      </form>
    </Layout>
  );
};

export default PasswordReset;
