import React, { useMemo, useState } from "react";

import { TradingGroupId } from "@fartherfinance/frontend/api/Types";

import {
  ACCOUNTS_NO_TRADINGGROUP_TOOLTIP_TEXT,
  REALIZED_CAPITAL_GAINS_TOOLTIP_TEXT,
} from "../constants";
import CreateEditLayout from "../CreateEditLayout/CreateEditLayout";
import { useCreateEditTaxBudgetContext } from "../reducer/Context";
import { NO_TRADE_GROUP_ID } from "../SelectHypotheticalModels/constants";
import TooltipBasic from "@src/sharedComponents/Tooltip/TooltipBasic";

import CapitalGainsTaxesTable from "./CapitalGainsTaxesTable/CapitalGainsTaxesTable";
import HeaderWithSubtext from "./HeaderWithSubtext/HeaderWithSubtext";
import HypotheticalHoldingsWeightsTable from "./HypotheticalHoldingsWeightsTable/HypotheticalHoldingsWeightsTable";
import ScenariosSettings from "./ScenarioSettings/ScenarioSettings";

import styles from "./HypotheticalResultsAnalysis.module.css";

const HypotheticalResultsAnalysis: React.FC = () => {
  const { state } = useCreateEditTaxBudgetContext();

  const {
    data: { hypotheticalTradeGroups },
  } = state;

  const defaultTradingGroupId = hypotheticalTradeGroups[0].tradingGroupId;
  const [selectedGroup, setSelectedGroup] = useState<TradingGroupId>(
    defaultTradingGroupId
  );

  const selectedAccountIds = useMemo(() => {
    const tradeGroup = hypotheticalTradeGroups.find(
      (tg) => selectedGroup === tg.tradingGroupId
    );
    if (tradeGroup === undefined) {
      throw new Error(
        `selected model id: ${selectedGroup}, not in hypotheticalTradeGroups`
      );
    }

    return tradeGroup?.virtualAccountIds;
  }, [selectedGroup, hypotheticalTradeGroups]);

  return (
    <CreateEditLayout>
      <div className={styles.container}>
        <ScenariosSettings />

        <HeaderWithSubtext
          title={"Compare Capital Gains Taxes"}
          text={"Compare capital gains taxes with and without a tax budget"}
        >
          <TooltipBasic
            className={styles.infoIcon}
            text={REALIZED_CAPITAL_GAINS_TOOLTIP_TEXT}
          />
        </HeaderWithSubtext>

        <div className={styles.tableHeader}>
          Capital Gains Taxes: Breakdown of Realized Capital Gains
        </div>

        <CapitalGainsTaxesTable />

        <HeaderWithSubtext
          title={"Compare Weights with a Tax Budget"}
          text={
            "Setting a tax budget may result in deviations in the weights of your holdings from their target model weights"
          }
        />

        <div className={styles.modelSelectionBar}>
          {hypotheticalTradeGroups.map((group) => {
            return (
              <div
                key={group.tradingGroupId}
                className={
                  selectedGroup === group.tradingGroupId
                    ? styles.tabSelected
                    : styles.tab
                }
                onClick={() => setSelectedGroup(group.tradingGroupId)}
              >
                <div>{group.accountsInPortfolio}</div>

                {group.tradingGroupId === NO_TRADE_GROUP_ID && (
                  <TooltipBasic
                    className={styles.infoIcon}
                    type={"secondary"}
                    text={ACCOUNTS_NO_TRADINGGROUP_TOOLTIP_TEXT}
                  />
                )}
              </div>
            );
          })}
        </div>

        <HypotheticalHoldingsWeightsTable accountIds={selectedAccountIds} />
      </div>
    </CreateEditLayout>
  );
};

export default HypotheticalResultsAnalysis;
