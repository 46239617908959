import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import FilterListIcon from "@mui/icons-material/FilterList";
import { InputAdornment } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";

import searchIcon from "@src/assets/svg/search_icon.svg";
import ClientsTable, {
  ClientSearchFiltersCheckboxLabel,
} from "@src/multiCustodian/components/Advisor/Clients/ClientsTable";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import DeletableChip from "@src/sharedComponents/DeletableChip/DeletableChip";
import Popover from "@src/sharedComponents/Popover/Popover";
import ScrollableCheckboxList, {
  CheckboxListConfig,
} from "@src/sharedComponents/ScrollableCheckboxList/ScrollableCheckboxList";
import useResetTablePaginationPage from "@src/sharedComponents/Table/useResetTablePaginationPage";
import TextInput from "@src/sharedComponents/TextInput/TextInput";

import AdvisorContainer from "./AdvisorContainer";
import AdvisorContentContainer from "./AdvisorContentContainer";

import * as styles from "./Clients.module.css";

const popoverDropdownMenuWidth = 190;

const clientSearchFiltersDefault: CheckboxListConfig<ClientSearchFiltersCheckboxLabel> =
  {
    Name: {
      isParent: false,
      checked: true,
      hasTopBorder: false,
      children: null,
    },
    Email: {
      isParent: false,
      checked: false,
      hasTopBorder: false,
      children: null,
    },
    Mobile: {
      isParent: false,
      checked: false,
      hasTopBorder: false,
      children: null,
    },
    "Client ID": {
      isParent: false,
      checked: false,
      hasTopBorder: false,
      children: null,
    },
    Advisor: {
      isParent: false,
      checked: false,
      hasTopBorder: false,
      children: null,
    },
    Created: {
      isParent: false,
      checked: false,
      hasTopBorder: false,
      children: null,
    },
  };

const Clients: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const filterPopoverAnchorRef = useRef<HTMLDivElement | null>(null);

  const [showFilterPopover, setShowFilterPopover] = useState<boolean>(false);

  const [clientSearchFilters, setClientSearchFilters] = useState<
    CheckboxListConfig<ClientSearchFiltersCheckboxLabel>
  >(clientSearchFiltersDefault);

  const history = useHistory();

  const { search: searchParam } = useLocation();

  const qs = useMemo(() => new URLSearchParams(searchParam), [searchParam]);

  const [search, setSearch] = useState(qs.get("clientQuery") ?? "");

  useEffect(() => {
    const location = history.location;
    const params = new URLSearchParams(location.search);

    params.set("clientQuery", search);
    if (search === "") {
      params.delete("clientQuery");
    }

    history.replace({ ...location, search: params.toString() });
  }, [history, search]);

  const openCreateClient = useCallback(() => {
    const location = history.location;
    history.push({ ...location, pathname: "/Advisor/Clients/Create" });
  }, [history]);

  const resetTablePaginationPage = useResetTablePaginationPage();

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      resetTablePaginationPage();
      setSearch(e.target.value);
    },
    [resetTablePaginationPage]
  );

  const onChangeClientSearchFilters = (
    config: CheckboxListConfig<ClientSearchFiltersCheckboxLabel>
  ) => {
    if (Object.values(config).filter((val) => val.checked).length === 0) {
      // always have 1 filter checked
      return;
    }

    setClientSearchFilters(config);
  };

  const chipLabel: ClientSearchFiltersCheckboxLabel | string = useMemo(() => {
    const filtersChecked = Object.entries(clientSearchFilters).filter(
      ([_, val]) => val.checked
    );

    return filtersChecked.length > 1
      ? `${filtersChecked.length} filters`
      : filtersChecked[0][0];
  }, [clientSearchFilters]);

  const resetFilter = () => setClientSearchFilters(clientSearchFiltersDefault);

  return (
    <AdvisorContainer>
      <AdvisorContentContainer heroTitle={"Clients"}>
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            <TextInput
              label=""
              value={search}
              onChange={handleOnChange}
              placeholder="Search clients..."
              style={{ width: "240px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} />
                  </InputAdornment>
                ),
              }}
            />

            <Popover
              open={showFilterPopover}
              onClose={() => setShowFilterPopover(false)}
              anchor={filterPopoverAnchorRef.current}
              transformOriginVertical={-10}
              popoverElement={
                <div ref={filterPopoverAnchorRef}>
                  <ButtonPrimary
                    text="Filters"
                    onClick={() => setShowFilterPopover(!showFilterPopover)}
                    variant="outlined"
                    buttonType="primary"
                    startIcon={<FilterListIcon className={styles.filterIcon} />}
                    sx={{ height: "30px" }}
                  />
                </div>
              }
            >
              <ScrollableCheckboxList
                haveSelectAll={false}
                checkboxesConfig={clientSearchFilters}
                setCheckboxesConfig={onChangeClientSearchFilters}
                multiSelectOff={false}
                minWidth={popoverDropdownMenuWidth}
              />
            </Popover>

            <DeletableChip
              label={chipLabel}
              onPress={() => setShowFilterPopover(true)}
              onClear={resetFilter}
              hideCloseIcon={chipLabel === "Name"}
            />
          </div>

          <ButtonPrimary
            text="Create Client"
            onClick={openCreateClient}
            variant="outlined"
            buttonType="primary"
            sx={{ height: "32px" }}
          />
        </div>

        <ClientsTable search={search} searchFilters={clientSearchFilters} />

        <div>{children}</div>
      </AdvisorContentContainer>
    </AdvisorContainer>
  );
};

export default Clients;
