import React, { PropsWithChildren } from "react";

import { useParams } from "react-router-dom";

import usePortfolioV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/usePortfolioV2";
import { ClientId, PortfolioId } from "@fartherfinance/frontend/api/Types";

import useFamReRouter from "@src/multiCustodian/components/Client/Portfolio/useFamReRouter";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

import FAMPortfolioContent from "./FAMPortfolioContent";
import PortfolioAccountsWrapper from "./PortfolioAccountsWrapper";
import PortfolioContent from "./PortfolioContent";

import styles from "./PortfolioAccounts.module.css";

const PortfolioAccounts = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const { clientId, portfolioId } = useParams<{
    clientId: ClientId;
    portfolioId: PortfolioId;
  }>();

  const auth = useRequestAuth();
  const portfolio = usePortfolioV2(portfolioId, auth);

  useFamReRouter(clientId, portfolioId);

  if (portfolio.isLoading) {
    return (
      <PortfolioAccountsWrapper>
        <div className={styles.loading}>
          <LogoLoadingStill />
        </div>
      </PortfolioAccountsWrapper>
    );
  }

  if (portfolio.hasError) {
    return (
      <PortfolioAccountsWrapper>
        <div className={styles.loading}>
          <p>Error could not find portfolio data</p>
        </div>
      </PortfolioAccountsWrapper>
    );
  }

  switch (portfolio.data.model.portfolioType) {
    case "FAM":
      return (
        <PortfolioAccountsWrapper>
          <FAMPortfolioContent isDerived={false} />

          {children}
        </PortfolioAccountsWrapper>
      );

    default:
      return portfolio.data.model.isDerived ? (
        <PortfolioAccountsWrapper>
          <FAMPortfolioContent isDerived={true} />

          {children}
        </PortfolioAccountsWrapper>
      ) : (
        <PortfolioAccountsWrapper>
          <PortfolioContent
            isDerived={portfolio.data.model.isDerived}
            modelType={portfolio.data.model.portfolioType}
          />

          {children}
        </PortfolioAccountsWrapper>
      );
  }
};

export default PortfolioAccounts;
