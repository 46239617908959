import { keyBy, range } from "lodash";

import { MonteCarloResults } from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";

import { DataItem } from "../../../ProjectionsGraph/ProjectionsGraph";

const BASE_BALANCE = 10000;

type year = string;
type balance = number;

type DictionaryItem = Record<year, [year, balance]>;

/**
 * Generate an array of Projection data that respects the maxYears limit,
 * and contains the calculated projected amount for the desired keys (results10Pct, results50Pct, results90Pct)
 * for each year.\
 *
 * @example
 *
 * Example Projections Input: {
 *     results10Pct: {"0": 1, "1": 1.1},
 *     results50Pct: {"0": 1, "1": 1.2},
 *     results90Pct: {"0": 1, "1": 1.4}
 *   }
 *
 * Example Output: [
 *   {year: 0, results10Pct: 10000, results50Pct: 10000, results90Pct: 10000},
 *   {year: 1, results10Pct: 11000, results50Pct: 12000, results90Pct: 14000}
 * ]
 */
export default function formatToProjectionsGraphData(
  projections: MonteCarloResults,
  maxYears: number
): DataItem[] {
  if (maxYears === 0) {
    return [];
  }

  const { results10Pct, results50Pct, results90Pct } = projections;

  const pct10 =
    results10Pct === null
      ? null
      : toDictionary(filterYears(results10Pct, maxYears));

  const pct50 =
    results50Pct === null
      ? null
      : toDictionary(filterYears(results50Pct, maxYears));

  const pct90 =
    results90Pct === null
      ? null
      : toDictionary(filterYears(results90Pct, maxYears));

  return range(maxYears).map((y) => {
    return {
      year: y,
      results10Pct: nullOrAmount(pct10, y),
      results50Pct: nullOrAmount(pct50, y),
      results90Pct: nullOrAmount(pct90, y),
    };
  });
}

/* Returns data for years that are less than or equal to maxYears */
const filterYears = (
  data: Record<year, balance>,
  maxYears: number
): [year, balance][] => {
  return Object.entries(data).filter(([year]) => Number(year) <= maxYears);
};

const toDictionary = (data: [year, balance][]): DictionaryItem => {
  return keyBy(data, ([year]) => year);
};

const nullOrAmount = (
  item: DictionaryItem | null,
  year: number
): balance | null => {
  if (
    item === null ||
    item[year] === undefined ||
    item[year][1] === undefined
  ) {
    return null;
  }

  return item[year][1] * BASE_BALANCE;
};
