import React from "react";

import AdvisorContainer from "../AdvisorContainer";
import AdvisorContentContainer from "../AdvisorContentContainer";
import PerformanceCustomDateRangeContextProvider from "@src/multiCustodian/components/PerformanceGroups/Summary/components/PerformanceCustomDateRangeContextProvider";

import BookAnalytics from "./BookAnalytics";

import styles from "./BookAnalyticsWrapper.module.css";

const BookAnalyticsWrapper: React.FC = () => {
  return (
    <AdvisorContainer>
      <AdvisorContentContainer heroTitle={"Book Analytics"}>
        <PerformanceCustomDateRangeContextProvider>
          <div className={styles.portaledChipContainer}>
            <div id="performanceCustomTimeFrameChip" />

            <div className={styles.takeUpSpace} />
          </div>

          <BookAnalytics />
        </PerformanceCustomDateRangeContextProvider>
      </AdvisorContentContainer>
    </AdvisorContainer>
  );
};

export default BookAnalyticsWrapper;
