import React from "react";

import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./RecurringTransfers.module.css";

const RecurringTransfersLoading = (): JSX.Element => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerInnerDiv}>
          <Skeleton width={160} />

          <div className={styles.headerRight}>
            <Skeleton className={styles.marginRight} width={120} />

            <Skeleton width={100} />
          </div>
        </div>
      </div>

      <div className={styles.body}>
        <div className={styles.bodyInnerDiv}>
          <div className={styles.row}>
            <Skeleton width={110} />

            <Skeleton width={100} />
          </div>

          <div className={styles.row}>
            <Skeleton width={150} />

            <Skeleton width={170} />
          </div>

          <div className={styles.row}>
            <Skeleton width={110} />

            <Skeleton width={100} />
          </div>

          <div className={styles.footerRow}>
            <div className={styles.footerRowLeft}>
              <Skeleton width={110} />
            </div>

            <div className={styles.footerRowRight}>
              <Skeleton className={styles.footerRowRightItem} width={120} />

              <Skeleton className={styles.footerRowRightItem} width={180} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecurringTransfersLoading;
