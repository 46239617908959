import React from "react";

import "./status.css";

type Props = {
  clickBanner?: () => void;
  msg: string;
} & (
  | { img_src: string; icon?: undefined }
  | { img_src?: undefined; icon: JSX.Element }
);

const Caution = (props: Props) => {
  return (
    <div
      className="Dashboard_Caution_Update_component__container"
      onClick={() => (props.clickBanner ? props.clickBanner() : undefined)}
    >
      <div className="Dashboard_Caution_Update_component__inner_div">
        <div className="Dashboard_Caution_Update_component__inner_div_left">
          {props.img_src !== undefined ? (
            <img src={props.img_src} />
          ) : (
            props.icon
          )}

          <div className="Dashboard_Caution_Update_component__text_2">
            {props.msg}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Caution;
