import React from "react";

import graphPresets from "./graphPresets";

interface Props {
  x: number;
  y: number;
  color: string;
}

const VerticalHoverLine = ({ x, y, color }: Props) => {
  return (
    <>
      {/* Vertical dash line */}
      <line
        x1={x}
        y1={graphPresets.innerGraphHeight}
        x2={x}
        y2={y}
        stroke={color}
        strokeDasharray="4 2"
      />
    </>
  );
};

export default VerticalHoverLine;
