import React, { useMemo } from "react";

import {
  AnyPortfolioV2,
  BreakdownItem,
  type PortfolioAssetClasses,
  Security,
} from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";

import PortfolioTable from "../PortfolioTable/PortfolioTable";
import { getSecuritiesLabel } from "../utils/getSecuritiesLabel";

type MiniSecurity = Pick<Security, "ticker" | "name">;

function tickerDescription(sec: MiniSecurity): string {
  switch (sec.ticker) {
    case "CASH$":
      return "CASH";
    default:
      return sec.ticker;
  }
}

function tickerSecondaryDescription(sec: MiniSecurity): string {
  switch (sec.ticker) {
    case "CASH$":
      return "US Dollars";
    default:
      return sec.name;
  }
}

type PortfolioAssetClass = typeof PortfolioAssetClasses[number];

interface Props {
  showDirectIndexedFilter: boolean;
  portfolio: AnyPortfolioV2;
  assetClass: PortfolioAssetClass;
  asset: BreakdownItem;
  onBack: () => void;
  headerStyle?: React.CSSProperties;
}

const AssetView = ({
  showDirectIndexedFilter,
  portfolio,
  assetClass,
  asset,
  onBack,
  headerStyle,
}: Props): JSX.Element => {
  const title = useMemo(() => {
    const secondaryDescription = getSecuritiesLabel(asset.securities.length);

    return {
      name: asset.category,
      description: assetClass,
      secondaryDescription,
    };
  }, [asset, assetClass]);

  const rows = useMemo(
    () =>
      asset.securities.map((s) => {
        return {
          key: s.ticker,
          description: tickerDescription(s),
          secondaryDescription: tickerSecondaryDescription(s),
          allocation: parseFloat(s.percent),
        };
      }),
    [asset.securities]
  );

  return (
    <PortfolioTable
      showDirectIndexedFilter={showDirectIndexedFilter}
      portfolio={portfolio}
      title={title}
      rows={rows}
      onBack={onBack}
      headerStyle={headerStyle}
    />
  );
};

export default AssetView;
