import React, { useState } from "react";

import { useSelector } from "react-redux";

import { State } from "../../../../../../store";
import { SetScreenAndTransferType } from "../../Types";

import "../css.css";

interface Props {
  setCurScreen: SetScreenAndTransferType;
}

const DepositOrWithdrawal = (props: Props): JSX.Element => {
  const [hoveringOver, setHoveringOver] = useState<-1 | 1 | 0>(-1);

  const { hoverColor, noHoverColor } = useSelector((state: State) => ({
    hoverColor:
      state.main_Reducer.css_color_palettes.current["--item_div_hover_color_1"],
    noHoverColor:
      state.main_Reducer.css_color_palettes.current["--background_div_color_1"],
  }));

  const { chevron_down, left_arrow } = useSelector((state: State) => ({
    chevron_down: state.main_Reducer.brand.current.images.chevron_down,
    left_arrow: state.main_Reducer.brand.current.images.left_arrow,
  }));

  return (
    <div className="Transfers__container">
      <div className="Transfers__content_container">
        <div
          className="Transfers__navlink"
          onClick={() => props.setCurScreen({ screen: "transferType" })}
        >
          <div className="Transfers__back_button_container">
            <img src={left_arrow} />
            <div className="Transfers__back_button">Back</div>
          </div>
        </div>

        <p className="Transfers__title">Choose a transfer type</p>

        <p className="Transfers__text">
          Please select where you would like to transfer money from.
        </p>

        <div
          className="Transfers__navlink"
          onClick={() => props.setCurScreen({ screen: "deposit" })}
        >
          <div
            className="Transfers__choices_div"
            onMouseEnter={() => setHoveringOver(0)}
            onMouseLeave={() => setHoveringOver(-1)}
            style={{
              backgroundColor:
                hoveringOver === 0
                  ? "var(--item_div_hover_color_1)"
                  : "var(--background_div_color_1)",
            }}
          >
            <p className="Transfers__text_bold">One-time Deposit</p>
            <img src={chevron_down} className="Transfers__arrow_img" />
          </div>
        </div>

        <div
          className="Transfers__navlink"
          onClick={() => props.setCurScreen({ screen: "withdrawal" })}
        >
          <div
            className="Transfers__choices_div"
            onMouseEnter={() => setHoveringOver(1)}
            onMouseLeave={() => setHoveringOver(-1)}
            style={{
              backgroundColor: hoveringOver === 1 ? hoverColor : noHoverColor,
            }}
          >
            <p className="Transfers__text_bold">Withdrawal</p>
            <img src={chevron_down} className="Transfers__arrow_img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepositOrWithdrawal;
