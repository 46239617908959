import React, { useState } from "react";

import opacity from "hex-color-opacity";
import { useSelector } from "react-redux";

import { State as RootState } from "../../store";

import styles from "./TwoTextItemToggle.module.css";

interface Props {
  leftOption: string;
  rightOption: string;
  value: "Left" | "Right";
  onChange: (option: "Left" | "Right") => void;
}

function TwoTextItemToggle(props: Props): JSX.Element {
  const [curHoverOption, setCurHoverOption] = useState<
    "Left" | "Right" | "None"
  >("None");

  const { css_color_palette } = useSelector((state: RootState) => ({
    css_color_palette: state.main_Reducer.css_color_palettes.current,
  }));

  const clickOption = (option: "Left" | "Right") => {
    if (option === props.value) {
      return;
    }

    props.onChange(option);
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.leftOptionContainer}
        style={
          curHoverOption === "Left"
            ? {
                backgroundColor: opacity(
                  css_color_palette["--toggle_active_color_1"],
                  0.3
                ),
              }
            : {}
        }
        onClick={() => clickOption("Left")}
      >
        <p
          className={styles.optionTextLeft}
          onMouseEnter={() => setCurHoverOption("Left")}
          onMouseLeave={() => setCurHoverOption("None")}
        >
          {props.leftOption}
        </p>

        <div
          className={
            props.value === "Left"
              ? styles.leftOptionSelected
              : styles.leftOption
          }
          onMouseEnter={() => setCurHoverOption("Left")}
          onMouseLeave={() => setCurHoverOption("None")}
        />
      </div>

      <div
        className={styles.rightOptionContainer}
        style={
          curHoverOption === "Right"
            ? {
                backgroundColor: opacity(
                  css_color_palette["--toggle_active_color_1"],
                  0.3
                ),
              }
            : {}
        }
        onClick={() => clickOption("Right")}
      >
        <p
          className={styles.optionTextRight}
          onMouseEnter={() => setCurHoverOption("Right")}
          onMouseLeave={() => setCurHoverOption("None")}
        >
          {props.rightOption}
        </p>

        <div
          className={
            props.value === "Right"
              ? styles.rightOptionSelected
              : styles.rightOption
          }
          onMouseEnter={() => setCurHoverOption("Right")}
          onMouseLeave={() => setCurHoverOption("None")}
        />
      </div>
    </div>
  );
}

export default TwoTextItemToggle;
