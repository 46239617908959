import { alpha } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

export const useStyles = makeStyles()((theme) => ({
  container: {
    height: 60,
    backgroundColor: theme.appearance.$backgroundInverseSubtle,

    borderTopWidth: 1,
    borderTopStyle: "solid",
    borderTopColor: alpha(theme.palette.common.white, 0.2),
  },
  content: {
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    maxWidth: theme.breakpoints.values.xl,
    margin: "0 auto",
    padding: theme.spacing(pxToSpacing(10), pxToSpacing(52)),
  },
  range: {
    fontFamily: theme.typography.fontSecondary,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "19px",
    letterSpacing: "0.63px",
    color: alpha(theme.palette.common.white, 0.5),
  },
  spacer: {
    marginRight: theme.spacing(pxToSpacing(20)),
  },
}));
