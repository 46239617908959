import React from "react";

import { AccountDetails } from "@fartherfinance/frontend/api/Dashboard/requests/getClientDashboard";
import { TradingGroup } from "@fartherfinance/frontend/api/TradingGroups/requests/getTradingGroup";
import { FartherAccountId } from "@fartherfinance/frontend/api/Types";

import AccountRow from "../AccountRow/AccountRow";
import { actions } from "../reducer/actions";
import { useCreateEditTaxBudgetContext } from "../reducer/Context";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";

type TradingGroupDataResponse = {
  tradingGroup?: TradingGroup;
  accounts: AccountDetails[];
};

interface Props {
  accounts: AccountDetails[];
  tradingGroups: TradingGroup[];
}

const AccountList: React.FC<Props> = ({ accounts, tradingGroups }) => {
  const { state, dispatch } = useCreateEditTaxBudgetContext();

  const { data } = state;

  const { excludedVirtualAccountIds } = data;

  const handleOnChecked = (account: AccountDetails) => {
    dispatch({
      type: actions.TOGGLE_EXCLUDED_ACCOUNT_ID,
      payload: account.accountId,
    });
  };

  const getTradingGroupDataForAccount = (
    accountId: FartherAccountId
  ): TradingGroupDataResponse => {
    const tradingGroup = tradingGroups?.find((group) =>
      group.accounts.some((account) => account.virtualAccountId === accountId)
    );

    const tgAccounts: AccountDetails[] = tradingGroup?.accounts
      .map((account) =>
        accounts.find((fa) => fa.accountId === account.virtualAccountId)
      )
      .filter((el) => el !== undefined) as AccountDetails[];

    return {
      tradingGroup,
      accounts: tgAccounts || [],
    };
  };

  return (
    <BorderBox>
      {accounts.map((account) => {
        const { tradingGroup, accounts: tradingGroupAccounts } =
          getTradingGroupDataForAccount(account.accountId);

        return (
          <AccountRow
            key={account.accountId}
            account={account}
            checked={
              !excludedVirtualAccountIds.some(
                (id: FartherAccountId) => id === account.accountId
              )
            }
            onChecked={handleOnChecked}
            tradingGroup={tradingGroup}
            tradingGroupAccounts={tradingGroupAccounts}
          />
        );
      })}
    </BorderBox>
  );
};

export default AccountList;
