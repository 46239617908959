import { isNil } from "lodash";

const numberGTEMin = (
  //GTE = Greater Than or Equal to
  input: string | null | undefined,
  min: number
): boolean => {
  const isEmpty = isNil(input) || input === "";

  if (isEmpty) {
    return true;
  }

  const normalizedInput = !isEmpty ? input.replace(/,/g, "") : "";

  const toNumber = Number(normalizedInput);

  const isValidNum = !isNaN(toNumber);

  const isOrAboveMin = min <= toNumber;

  return isValidNum && isOrAboveMin;
};

export default numberGTEMin;
