import React from "react";

import { AccessLevelForDisplay } from "../../../Types";

import styles from "./AccessLevelInfoNote.module.css";

interface Props {
  accessLevel: AccessLevelForDisplay | undefined;
}

const AccessLevelInfoNote: React.FC<Props> = ({ accessLevel }) => {
  return (
    <>
      {accessLevel === "All-Access" && (
        <div className={styles.text}>
          By selecting "All-Access," you agree to allow this collaborator to
          view all of the information from your portal, including account
          information (e.g., balances, account numbers) and documents.
        </div>
      )}

      {accessLevel === "Revoke" && (
        <div className={styles.text}>
          By selecting "Revoke," you are agreeing to remove all access for this
          collaborator.
        </div>
      )}
    </>
  );
};

export default AccessLevelInfoNote;
