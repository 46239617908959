import { isNil } from "lodash";

export default function isValidLocaleNumber(
  input: string | null | undefined,
  required: boolean
): string | true {
  const isEmpty = isNil(input) || input === "";

  const noComma = (input ?? "").replace(/,/g, "");

  const isValidNumber = !isNaN(Number(noComma));

  if (required) {
    return isValidNumber ? true : "Must only contain numbers";
  }
  return isEmpty || isValidNumber ? true : "Must only contain numbers";
}
