import React from "react";

import { useFlags } from "launchdarkly-react-client-sdk";

import useAccountBalanceV4 from "@fartherfinance/frontend/api/PerformanceGroups/hooks/useAccountBalanceV4";
import { ClientId, FartherAccountId } from "@fartherfinance/frontend/api/Types";

import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";
import toUSD from "@src/utils/toUSD";

import styles from "./AccountBalance.module.css";

interface Props {
  balanceCssClassName: string;
  clientId: ClientId;
  accountId: FartherAccountId;
  loadingSkeleton: JSX.Element;
}

const AccountBalance = (props: Props): JSX.Element => {
  const auth = useRequestAuth();

  const { enablePerformanceGroups } = useFlags();

  const balance = useAccountBalanceV4(
    props.clientId,
    props.accountId,
    enablePerformanceGroups ? auth : null
  );

  if (!enablePerformanceGroups || balance.hasError) {
    return <div className={styles.noBalance}>Unable to retrieve balance</div>;
  }

  if (balance.isLoading) {
    return props.loadingSkeleton;
  }

  if (balance.data === null) {
    return (
      <Tooltip
        tooltipText={
          "This account appears to have a $0 balance. If you believe this is inaccurate, please contact your advisor."
        }
        placement="top"
      >
        <div className={props.balanceCssClassName}>{"$0*"}</div>
      </Tooltip>
    );
  }

  return <div className={props.balanceCssClassName}>{toUSD(balance.data)}</div>;
};

export default AccountBalance;
