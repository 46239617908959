import React from "react";

const Investments: React.FC = () => (
  <svg
    width="100%"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.6194 7.7348C21.4929 8.84048 17.7186 10.9851 14.6567 13.9641C11.5947 16.9431 9.34717 20.657 8.12856 24.7515C6.90995 28.8461 6.76089 33.1845 7.69561 37.353C8.63035 41.5215 10.6177 45.3809 13.468 48.5631C16.3183 51.7451 19.9364 54.1438 23.9772 55.5301C28.0181 56.9163 32.3468 57.244 36.5502 56.4818C40.7537 55.7196 44.6917 53.893 47.9886 51.1762C51.2854 48.4595 53.831 44.9432 55.3826 40.9629L32.0898 31.8829L25.6194 7.7348Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.0109 38.5002C56.0109 38.5002 36.3776 30.4819 33.9364 29.5754L27.9475 7.20711C31.6371 6.6164 35.4239 6.85865 39.0263 7.93002C43.453 9.24647 47.4285 11.7634 50.5119 15.2016C53.5953 18.6399 55.666 22.865 56.4944 27.4084C57.1685 31.1058 56.9985 34.8965 56.0109 38.5002Z"
      fill="currentColor"
    />
  </svg>
);

export default Investments;
