import isValidLocaleNumber from "@src/multiCustodian/pages/Dashboard/Dashboard_Components/DashboardForms/validators/isValidLocaleNumber";

export const MIN_AMOUNT = 20;

export const MAX_AMOUNT = 100000;

export const MIN_AMOUNT_USD = MIN_AMOUNT.toLocaleString("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export const MAX_AMOUNT_USD = MAX_AMOUNT.toLocaleString("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export type Amount = string;

const validAmount = (amount: string | undefined): amount is Amount => {
  return isNotUndefined(amount) && isValueInRange(amount);
};

const isNotUndefined = (amount: string | undefined): amount is string => {
  return amount !== undefined;
};

const isValueInRange = (amount: string): boolean => {
  const normalizedAmount = amount.replace(/,/g, "").trim();

  return (
    normalizedAmount !== "" &&
    isValidLocaleNumber(normalizedAmount, true) === true &&
    parseFloat(normalizedAmount.replace(/,/g, "")) >= MIN_AMOUNT &&
    parseFloat(normalizedAmount.replace(/,/g, "")) <= MAX_AMOUNT
  );
};

export default validAmount;
