import React, { useCallback, useState } from "react";

import { AddCircleOutline } from "@mui/icons-material";
import { useParams } from "react-router-dom";

import { ClientId, FartherAccountId } from "@fartherfinance/frontend/api/Types";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import CreateEditLayout from "@src/multiCustodian/components/TaxBudgets/CreateEditLayout/CreateEditLayout";
import { actions } from "@src/multiCustodian/components/TaxBudgets/reducer/actions";
import { useCreateEditTaxBudgetContext } from "@src/multiCustodian/components/TaxBudgets/reducer/Context";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";

import AccountListForClient from "./AccountListForClient";
import SelectClientModal from "./SelectClientModal";

import styles from "./SelectAccounts.module.css";

const AdvisorSelectAccounts: React.FC = () => {
  const { clientId } = useParams<{ clientId: ClientId }>();

  const { state, dispatch } = useCreateEditTaxBudgetContext();

  const {
    data: { clientIds, clientAccountIds, excludedVirtualAccountIds },
  } = state;

  const [spouseClientId, setSpouseClientId] = useState<ClientId | null>(
    clientIds.length > 1
      ? clientIds.find((id: ClientId) => id !== clientId) || null
      : null
  );

  const [isOpen, setIsOpen] = useState(false);

  const numSelected =
    clientAccountIds.length - excludedVirtualAccountIds.length;

  const handleOnClick = useCallback(() => setIsOpen(true), [setIsOpen]);

  const handleOnClose = useCallback(() => setIsOpen(false), [setIsOpen]);

  const handleOnDelete = useCallback(
    (accountIds: FartherAccountId[]) => {
      handleOnClose();
      setSpouseClientId(null);
      dispatch({
        type: actions.SET_CLIENT_IDS,
        payload: [clientId],
      });

      dispatch({
        type: actions.SET_ACCOUNT_IDS,
        payload: clientAccountIds.filter((id) => !accountIds.includes(id)),
      });
    },
    [clientAccountIds, clientId, dispatch, handleOnClose, setSpouseClientId]
  );

  const handleOnEdit = useCallback(() => setIsOpen(true), [setIsOpen]);

  const handleOnSelect = useCallback(
    (selectedSpouseClientId: ClientId) => {
      handleOnClose();
      setSpouseClientId(selectedSpouseClientId);
      dispatch({
        type: actions.SET_CLIENT_IDS,
        payload: [clientId, selectedSpouseClientId],
      });
    },
    [clientId, dispatch, handleOnClose]
  );

  return (
    <CreateEditLayout>
      <BorderBox className={styles.container}>
        <div className={styles.titleContainer}>
          <div
            className={styles.title}
          >{`Available Accounts (${clientAccountIds.length})`}</div>

          <div className={styles.selected}>{`${numSelected} Account${
            numSelected === 1 ? "" : "s"
          } selected`}</div>
        </div>

        <AccountListForClient clientId={clientId} />

        {spouseClientId && (
          <AccountListForClient
            clientId={spouseClientId}
            isSpouse
            onEdit={handleOnEdit}
            onDelete={handleOnDelete}
          />
        )}

        <Button
          startIcon={<AddCircleOutline />}
          disabled={Boolean(spouseClientId)}
          text="Add Spouse's accounts"
          onClick={handleOnClick}
          variant="text"
          buttonType="primary"
          style={{ justifyContent: "start" }}
        />
      </BorderBox>

      <SelectClientModal
        isOpen={isOpen}
        onClose={handleOnClose}
        onSelect={handleOnSelect}
      />
    </CreateEditLayout>
  );
};

export default AdvisorSelectAccounts;
