import { TransactionTypeEnum } from "@fartherfinance/frontend/api/Dashboard/constants/transactionType";
import { DateFilter } from "@fartherfinance/frontend/api/PerformanceGroups/Types";
import { mapObject } from "@fartherfinance/frontend/utils/mapObject";

import {
  CheckboxConfig,
  CheckboxListConfig,
} from "@src/sharedComponents/ScrollableCheckboxList/ScrollableCheckboxList";

type TransferType =
  | "Pending Deposit"
  | "Pending Withdrawal"
  | "Pending ACAT"
  | "Pending Liquidation";

export type TransactionAndTransferType = TransactionTypeEnum | TransferType;

type TransactionAndTransferTypeObj = Record<
  TransactionAndTransferType,
  boolean
>;

export interface EventsFilterConfig {
  dateFilter: DateFilter | null;
  types: TransactionAndTransferTypeObj;
}

export const defaultFilter: EventsFilterConfig = Object.freeze({
  dateFilter: null,
  types: {
    // transaction types - from events
    "Cash Deposit": true,
    "Cash Withdrawal": true,
    Dividend: true,
    Rebate: true,
    "Transfer In": true,
    "Transfer Out": true,
    Buy: true,
    Sale: true,
    "Management Fee": true,
    Journal: true,
    "Corporate Action": true,
    "Capital Gains": true,
    "Dividend Reinvestment": true,
    Expense: true,
    Interest: true,
    "Capital Gains Reinvestment": true,
    Income: true,
    "Interest Reinvestment": true,
    "Income Reinvestment": true,
    "Option Activity": true,
    "Short Sale": true,
    Valuation: true,
    "Return of Capital": true,
    // transfer types (made locally) - from Requests
    "Pending Deposit": true,
    "Pending Withdrawal": true,
    "Pending ACAT": true,
    "Pending Liquidation": true,
  },
});

export type ActivityTypeParentCheckboxLabel =
  | "Cash In"
  | "Cash Out"
  | "Buy"
  | "Capital Gains"
  | "Capital Gains Reinvestment"
  | "Corporate Action"
  | "Dividend"
  | "Dividend Reinvestment"
  | "Expense"
  | "Income"
  | "Income Reinvestment"
  | "Interest"
  | "Interest Reinvestment"
  | "Journal"
  | "Management Fee"
  | "Option Activity"
  | "Rebate"
  | "Return of Capital"
  | "Sale"
  | "Short Sale"
  | "Valuation";

export type DateRangeCheckboxLabel =
  | "All"
  | "Current Month"
  | "Previous Month"
  | "Year-to-date"
  | "Custom";

export const getDateRangeSelectedString = (
  dateRangeFilterConfig: CheckboxListConfig<DateRangeCheckboxLabel>
): DateRangeCheckboxLabel => {
  const entries: [DateRangeCheckboxLabel, CheckboxConfig][] = mapObject(
    dateRangeFilterConfig,
    (v, k) => [k, v]
  );

  for (const [key, val] of entries) {
    if (val.checked) {
      return key;
    }
  }

  throw new Error("No DateRangeCheckboxLabel was checked");
};
