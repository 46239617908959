import React from "react";

import styles from "./BottomBar.module.css";

const BottomBar = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<unknown>
>(function BottomBar(props: React.PropsWithChildren<unknown>, ref) {
  return (
    <div ref={ref} className={styles.container}>
      <div className={styles.body}>{props.children}</div>
    </div>
  );
});

export default BottomBar;
