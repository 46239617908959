import React from "react";

import AdvisorContainer from "../../../AdvisorContainer";
import UpdateUploadSleeve from "@src/multiCustodian/components/Advisor/Investments/Sleeves/UpdateSleeve/UpdateSleeve";

const UpdateSleeve = (): JSX.Element => {
  return (
    <AdvisorContainer hideNavbar={true}>
      <UpdateUploadSleeve />
    </AdvisorContainer>
  );
};

export default UpdateSleeve;
