import React from "react";

import useClientAccounts from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAccounts";
import { TaxBudget } from "@fartherfinance/frontend/api/InvestmentModel/Types";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import { MULTIPLE_ACCOUNTS_TOOLTIP_TEXT } from "@src/multiCustodian/components/TaxBudgets/constants";
import Row from "@src/multiCustodian/components/TaxBudgets/DetailTabs/Row";
import useClientNameForId from "@src/multiCustodian/hooks/Advisor/useClientNameForId";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import TooltipBasic from "@src/sharedComponents/Tooltip/TooltipBasic";

interface Props {
  clientId: ClientId;
  budget?: TaxBudget;
}

const AccountRow: React.FC<Props> = ({ clientId, budget }) => {
  const auth = useAdvisorRequestAuth();

  const clientAuth = auth !== null ? { ...auth, clientId: clientId } : null;

  const { data: accountData, isLoading } = useClientAccounts(
    clientId,
    clientAuth
  );

  const clientName = useClientNameForId(clientId, auth);

  const accountNames =
    accountData?.fartherAccounts
      .filter((a) =>
        (budget?.excludedVirtualAccountIds ?? []).every(
          (eaid) => eaid !== a.accountId
        )
      )
      .map((account) => account.accountDetails.displayName) || [];

  return (
    <Row
      start={
        <span>
          {`${clientName?.name.first || "Client"}'s Accounts (${
            accountNames.length
          })`}

          <TooltipBasic
            type={"secondary"}
            text={MULTIPLE_ACCOUNTS_TOOLTIP_TEXT}
          />
        </span>
      }
      end={isLoading ? <Skeleton /> : accountNames.join(", ")}
    />
  );
};

export default AccountRow;
