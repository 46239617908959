import React, { useMemo, useState } from "react";

import { range } from "lodash";

import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import FullDataTable from "@src/sharedComponents/Table/FullDataTable";
import { Cell, Row } from "@src/sharedComponents/Table/Types";

import Header, { FilterOption } from "./Header";
import { rowsPerPage, TableKey, tableKeys } from "./SleevesTable";

const TableLoading = (): JSX.Element => {
  const [filter, setFilter] = useState<FilterOption>("Active Sleeves");

  const LOADING_DATA: Row<TableKey, Cell>[] = useMemo(() => {
    return range(rowsPerPage).map((i) => {
      return {
        key: `${i}`,
        Name: { value: <Skeleton /> },
        Type: { value: <Skeleton /> },
        Securities: { value: <Skeleton /> },
        "Active Portfolios": { value: <Skeleton /> },
        Updated: { value: <Skeleton /> },
      };
    });
  }, []);

  return (
    <>
      <Header
        filter={filter}
        setFilter={setFilter}
        tableSearch={""}
        setTableSearch={() => undefined}
      />

      <FullDataTable
        isLoading={true}
        columns={tableKeys}
        rows={LOADING_DATA}
        defaultRowsPerPage={rowsPerPage}
        defaultSortColumn={undefined}
        disableColumnSorting={tableKeys}
        emptyCell={<Skeleton />}
      />
    </>
  );
};

export default TableLoading;
