import * as Sentry from "@sentry/react";
import {
  applyMiddleware,
  combineReducers,
  compose,
  legacy_createStore as createStore,
} from "redux";
import thunk from "redux-thunk";

import main_Reducer, {
  MainReducerActions,
} from "./multiCustodian/reducers/main_Reducer";
import misc_Reducer, {
  MiscReducerActions,
} from "./multiCustodian/reducers/misc_Reducer";

const middleware =
  process.env.WEBAPP_EXACT_ENV === "DEV"
    ? [thunk]
    : process.env.WEBAPP_EXACT_ENV === "UAT" ||
      process.env.WEBAPP_EXACT_ENV === "PROD"
    ? [thunk]
    : [thunk]; // STAGING

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const enhancer = composeEnhancers(
  applyMiddleware(...middleware),
  sentryReduxEnhancer
);

const reducers = {
  main_Reducer: main_Reducer,
  misc_Reducer: misc_Reducer,
};

const store = createStore(combineReducers(reducers), enhancer);

type Reducers = typeof reducers;
export type State = { [P in keyof Reducers]: ReturnType<Reducers[P]> };

export type MCReduxAction = MainReducerActions | MiscReducerActions;

export default store;
