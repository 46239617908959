import { Relationship, relationshipOptions } from "@src/constants/relationship";

export const getRelationTypeIdFromType = (
  relationType: Relationship
): number => {
  const relationTypeId =
    relationshipOptions.findIndex((r) => r === relationType) + 1;

  if (relationTypeId <= 0 || relationTypeId > 19) {
    throw new Error("relationType not in relationshipOptions"); // 19 is the limit as there are only 19 options
  }

  return relationTypeId;
};
