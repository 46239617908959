type MessageType = "success" | "failure" | "error" | "warning";

type RemoveFunc = () => void;

const notification_ts = (
  text: string,
  _messageType: MessageType = "success",
  close_image_source: string,
  image_source: string,
  pinToLeftInsteadOfRight: boolean = true,
  timeoutLengthMS: number = 8000
): RemoveFunc => {
  // .css is in App.css
  const el_container = document.getElementById(
    pinToLeftInsteadOfRight
      ? "Status_Notification__containerBottomLeft"
      : "Status_Notification__container"
  );

  const new_div = document.createElement("div");
  new_div.className = pinToLeftInsteadOfRight
    ? "Status_Notification__divBottomLeft"
    : "Status_Notification__div";

  new_div.style.animation = pinToLeftInsteadOfRight
    ? `statusNotificationAnimationBottomLeft ${timeoutLengthMS / 1000}s linear`
    : `statusNotificationAnimation ${timeoutLengthMS / 1000}s linear`;

  const new_img = document.createElement("img");

  new_img.className = "Status_Notification__img";
  new_img.src = image_source;

  const new_p = document.createElement("p");
  const close_img = document.createElement("img");

  close_img.className = "Status_Notification__close";
  close_img.src = close_image_source;
  close_img.style.cursor = "pointer";
  close_img.addEventListener("click", () => {
    new_div.remove();
  });

  new_p.className = "Status_Notification__text";
  new_p.innerHTML = text;
  new_div.appendChild(new_img);
  new_div.appendChild(new_p);
  new_div.appendChild(close_img);
  if (el_container !== null) {
    el_container.appendChild(new_div);
  }
  setTimeout(() => {
    new_div.remove();
  }, timeoutLengthMS);

  return () => new_div.remove();
};

export default notification_ts;
