import React from "react";

import useMatchPerformanceGroup from "@fartherfinance/frontend/api/PerformanceGroups/hooks/useMatchPerformanceGroup";
import { ClientId, FartherAccountId } from "@fartherfinance/frontend/api/Types";

import Holdings from "@src/multiCustodian/components/PerformanceGroups/Holdings/Holdings";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

import styles from "./Holdings.module.css";

interface Props {
  clientId: ClientId;
  accountId: FartherAccountId;
}

export default function AccountHoldings({
  clientId,
  accountId,
}: Props): JSX.Element {
  const auth = useRequestAuth();

  const performanceGroupId = useMatchPerformanceGroup(
    clientId,
    accountId,
    auth
  );

  return (
    <>
      <div className={styles.heading}>Account Holdings</div>

      <Holdings groupId={performanceGroupId.data ?? null} />
    </>
  );
}
