import React from "react";

import useMFAConfirm, {
  type UpdateMFAConfirm,
} from "@fartherfinance/frontend/api/Identity/hooks/useMFAConfirm";
import useSetMFASettings from "@fartherfinance/frontend/api/Identity/hooks/useSetMFASettings";
import { AdvisorId, ClientId } from "@fartherfinance/frontend/api/Types";

import TextInput from "../../../sharedComponents/TextInput/TextInput";
import Button from "../MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";

import { LocalStorageKeys } from "./constants";
import { formatPhoneNumber } from "./formatPhoneNumber";
import Layout from "./Layout";

import * as styles from "./StytchLogin.module.css";

interface Props {
  onBack?: () => void;
  onSetMFA: (
    methodId: string | null,
    confirm: UpdateMFAConfirm
  ) => Promise<void>;
  onSkip: () => void;
}

const UsPhoneNumber = /^\+1\d{10}$/;

const SetMFA: React.FC<Props> = ({ onBack, onSetMFA, onSkip }) => {
  const clientAuth = useRequestAuth();

  const advisorAuth = useAdvisorRequestAuth();

  const auth = clientAuth ?? advisorAuth;

  const setMFASettings = useSetMFASettings(auth);

  const [phone, setPhone] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const notification = useStatusNotification();

  const personId: AdvisorId | ClientId = localStorage.getItem(
    LocalStorageKeys.personId
  ) as AdvisorId | ClientId;

  const confirm = useMFAConfirm(personId, auth);

  const onSkipClick = async () => {
    try {
      await setMFASettings({ personId, body: { enable2FA: false } });

      onSkip();
    } catch (_err) {
      notification("There was a problem saving your settings.", "Error");
    }
  };

  const phoneForAPI = `+1${phone.replace(/[^A-Z0-9]/gi, "")}`;

  const onContinueClick = async () => {
    setIsLoading(true);

    try {
      const res = await setMFASettings({
        personId,
        body: { enable2FA: true, phoneNumber: phoneForAPI },
      });

      if (res?.enable2FA === true) {
        onSetMFA(res?.methodId, confirm);
      } else {
        throw new Error("Enable 2FA needs to be true to continue.");
      }
    } catch (_err) {
      notification("There was a problem saving your settings.", "Error");
    } finally {
      setIsLoading(false);
    }
  };

  const phoneValid = UsPhoneNumber.test(phoneForAPI);

  const continueDisabled = phoneValid === false;

  return (
    <Layout
      onBack={onBack}
      subtitle="Add extra security to your Farther account by setting up multiple layers of protection. You may skip this step and return to it at any time."
      title="Two-Factor Authentication"
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (continueDisabled) {
            return;
          }

          onContinueClick();
        }}
      >
        <TextInput
          autoFocus
          autoComplete="tel"
          type="tel"
          label="Phone Number"
          placeholder="(281) 937-4192"
          value={formatPhoneNumber(phone)}
          onChange={(e) => {
            e.preventDefault();
            setPhone(e.target.value);
          }}
        />
        <div className={styles.spacer} />
        <div className={styles.horizontal}>
          <Button
            text="Skip For Now"
            onClick={onSkipClick}
            variant="outlined"
            buttonType="primary"
            sx={{ width: "100%" }}
            style={{ width: "100%" }}
          />
          <Button
            disabled={continueDisabled}
            text="Continue"
            onClick={onContinueClick}
            variant="contained"
            buttonType="primary"
            sx={{ width: "100%" }}
            style={{ width: "100%" }}
            useLoader
            isLoading={isLoading}
          />
        </div>

        <input type="submit" style={{ display: "none" }} />
      </form>
    </Layout>
  );
};

export default SetMFA;
