import React, { useRef, useState } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import { format } from "date-fns";

import usePatchInvitation from "@fartherfinance/frontend/api/Sharing/hooks/usePatchInvitation";
import { ReceivedInvitation } from "@fartherfinance/frontend/api/Sharing/Types";

import { getAccessLevelForDisplay } from "../utils/getAccessLevelForDisplay";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import Popover from "@src/sharedComponents/Popover/Popover";
import SimplePopoverItem from "@src/sharedComponents/Popover/SimplePopoverItem";
import SimplePopoverMenu from "@src/sharedComponents/Popover/SimplePopoverMenu";
import ThreeDotsButton from "@src/sharedComponents/ThreeDotsButton/ThreeDotsButton";

import RemoveModal from "./RemoveModal/RemoveModal";

import styles from "./SharedWithYouTable.module.css";

interface Props {
  invitation: ReceivedInvitation;
}

const AcceptedInvitationRow: React.FC<Props> = ({ invitation }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);

  const statusNotification = useStatusNotification();

  const auth = useRequestAuth();
  const patchInvitation = usePatchInvitation(auth);

  const handleOnConfirmRemove = async () => {
    setShowModal(false);

    try {
      setIsLoading(true);

      await patchInvitation({
        invitationId: invitation.invitationId,
        body: { status: "Removed" },
      });

      statusNotification("Access removed", "Success");
    } catch {
      statusNotification("Error removing access", "Error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnClosePopover = () => setShowPopover(false);

  const handleOnClickPopover = () => setShowPopover(true);

  const handleOnClickRemove = () => {
    setShowPopover(false);
    setShowModal(true);
  };

  return (
    <>
      {showModal && (
        <RemoveModal
          invitation={invitation}
          onCancel={() => setShowModal(false)}
          onRemove={handleOnConfirmRemove}
        />
      )}
      <div className={styles.row}>
        <div className={styles.flexRow}>
          <div className={styles.rowTextFront}>
            {`${invitation.invitor.firstName} ${invitation.invitor.lastName}`}
          </div>

          <div className={styles.rowTextBack}>
            {`gave you ${getAccessLevelForDisplay(
              invitation.accessLevel
            )} on ${format(invitation.createdAt, "MMMM d, y")}`}
          </div>
        </div>

        <div className={styles.flexRow}>
          {!isLoading && (
            <Popover
              open={showPopover}
              onClose={handleOnClosePopover}
              anchor={anchorRef.current}
              popoverElement={
                <ThreeDotsButton
                  onClick={handleOnClickPopover}
                  anchorRef={anchorRef}
                />
              }
            >
              <SimplePopoverMenu>
                <SimplePopoverItem onClick={handleOnClickRemove}>
                  Remove
                </SimplePopoverItem>
              </SimplePopoverMenu>
            </Popover>
          )}
          {isLoading && (
            <div className={styles.loader}>
              <CircularProgress color={"inherit"} size={20} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AcceptedInvitationRow;
