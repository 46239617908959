import { useCallback, useEffect, useMemo, useRef } from "react";

import { differenceInSeconds } from "date-fns";
import { orderBy } from "lodash";
import { matchPath, useLocation } from "react-router-dom";

import { trackEvent } from "@src/multiCustodian/services/tracking";

import { RouteElement, SinglePath, SinglePathWithEvent } from "./Router.types";

function flattenRoutes(routes: RouteElement[]): SinglePath[] {
  return routes.flatMap((route) => {
    if ("routes" in route) {
      return flattenRoutes(route.routes);
    } else {
      return route;
    }
  });
}

function isSinglePathWithEvent(path: SinglePath): path is SinglePathWithEvent {
  return "trackingEvent" in path;
}

export function useTrackPageChanges(routes: RouteElement[]) {
  const matchedRoutesCache = useRef<[Date, SinglePath][]>([]);

  const matchIsNotInCacheOrHasBeen60Sec = useCallback(
    (matchedRoute: SinglePathWithEvent) => {
      // keep matches that are within 60 sec, throw away the rest/older ones
      const routesHitWithin60Sec = matchedRoutesCache.current.filter(
        ([date, _]) => differenceInSeconds(new Date(), date) <= 60
      );

      matchedRoutesCache.current = routesHitWithin60Sec; // update cache

      return routesHitWithin60Sec.every(
        ([_, route]) => matchedRoute.path !== route.path
      );
    },
    []
  );

  const { pathname } = useLocation();

  // We flatten all the routes so that we can easily filter
  const flatRoutes = useMemo(() => flattenRoutes(routes), [routes]);

  useEffect(() => {
    // from https://v5.reactrouter.com/web/api/matchPath
    const matchingRoutes: SinglePathWithEvent[] = flatRoutes
      .filter((route) =>
        matchPath(pathname, {
          path: route.path,
          exact: true,
        })
      )
      .filter(isSinglePathWithEvent);

    // We assume that the longer the path the more specific the route and the more important it is so track its event
    const orderedMatches = orderBy(
      matchingRoutes,
      [(route) => route.path.length],
      ["desc"]
    );

    const bestMatch: SinglePathWithEvent | undefined = orderedMatches[0];

    if (bestMatch !== undefined && matchIsNotInCacheOrHasBeen60Sec(bestMatch)) {
      trackEvent(bestMatch.trackingEvent);

      matchedRoutesCache.current = [
        ...matchedRoutesCache.current,
        [new Date(), bestMatch],
      ]; // add it to the cache
    }
  }, [flatRoutes, pathname, matchIsNotInCacheOrHasBeen60Sec]);
}
