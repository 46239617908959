import React from "react";

import styles from "./table.module.css";

interface Props {
  headers: string[];
  cellWidthPercentages: number[];
  tableContent: JSX.Element[];
  placeholderText?: string;
}

const PlanTable = (props: Props) => {
  return (
    <div className={styles.wrapper}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.tableHeaderRow}>
            {props.headers.map((th, i) => (
              <th
                key={th}
                className={styles.tableHeaderCol}
                style={{ width: props.cellWidthPercentages[i] + "%" }}
              >
                <p className={styles.tableHeaderCol_cell}>{th}</p>
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {props.tableContent.map((tableRowJSX: JSX.Element) => tableRowJSX)}

          {props.placeholderText &&
            (!props?.tableContent || props.tableContent.length === 0) && (
              <tr className={styles.tableRow}>
                <td className={styles.tableCol} colSpan={props.headers.length}>
                  <p className={styles.tableCol_cell_placeholder_text}>
                    {props.placeholderText}
                  </p>
                </td>
              </tr>
            )}

          {!props.placeholderText &&
            (!props?.tableContent || props.tableContent.length === 0) && (
              <tr className={styles.tableRow}>
                {props.cellWidthPercentages.map((pct, i) => {
                  return (
                    <td
                      key={`${i}`}
                      className={styles.tableCol}
                      style={{ width: pct + "%" }}
                    >
                      <div className={styles.tableCol_cell}>--</div>
                    </td>
                  );
                })}
              </tr>
            )}
        </tbody>
      </table>
    </div>
  );
};

export default PlanTable;
