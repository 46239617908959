import React from "react";

import useSMAAssetList from "@fartherfinance/frontend/api/AltAssetsService/hooks/useSMAAssetList";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";

import SMATable from "./Components/SMATable";
import SMATableLoading from "./Components/SMATableLoading";

const SMABody = (): JSX.Element => {
  const auth = useAdvisorRequestAuth();

  const smaOptions = useSMAAssetList(auth);

  if (smaOptions.isLoading) {
    return <SMATableLoading />;
  }

  if (smaOptions.hasError) {
    return <div>Error getting SMA options</div>;
  }

  return <SMATable smaOptions={smaOptions.data} />;
};

export default SMABody;
