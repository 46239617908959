import { actions } from "./actions";
import { initialState } from "./initialState";
import {
  ActionPayload,
  AddCollaboratorFlowData,
  FlowState,
  PageIndex,
  pageIndices,
} from "./types";
import { determineButtonText, isNextButtonDisabled } from "./utils";

const isPageIndexInRange = (index: number | PageIndex): index is PageIndex => {
  return index >= 0 && index <= pageIndices.length - 1;
};

function reducer(
  state: FlowState<AddCollaboratorFlowData>,
  action: ActionPayload
): FlowState<AddCollaboratorFlowData> {
  const { type, payload } = action;

  switch (type) {
    case actions.RESET_TO_DEFAULT_DATA: {
      return {
        ...initialState,
      };
    }

    case actions.SET_PAGE_INDEX: {
      const pageIndex = payload;

      const indices = pageIndices;
      const firstIndex = indices[0];
      const lastIndex = indices[indices.length - 1];

      if (pageIndex > lastIndex || pageIndex < firstIndex) {
        return state;
      }

      if (!isPageIndexInRange(pageIndex)) {
        throw new Error(`pageIndex: ${pageIndex ?? "N/A"}, is out of range`);
      }

      const updatedState: FlowState<AddCollaboratorFlowData> = {
        ...state,
        pageIndex,
        buttonText: determineButtonText(pageIndex),
      };

      return {
        ...updatedState,
        isNextButtonDisabled: isNextButtonDisabled(updatedState),
      };
    }

    case actions.INCREMENT_PAGE_INDEX: {
      const { pageIndex } = state;

      const indices = pageIndices;
      const newPageIndex = pageIndex + 1;
      const lastIndex = indices[indices.length - 1];

      if (newPageIndex > lastIndex) {
        return state;
      }

      if (!isPageIndexInRange(newPageIndex)) {
        throw new Error(`pageIndex: ${newPageIndex}, is out of range`);
      }

      const updatedState: FlowState<AddCollaboratorFlowData> = {
        ...state,
        pageIndex: newPageIndex,
        buttonText: determineButtonText(newPageIndex),
      };

      return {
        ...updatedState,
        isNextButtonDisabled: isNextButtonDisabled(updatedState),
      };
    }

    case actions.DECREMENT_PAGE_INDEX: {
      const { pageIndex } = state;

      const indices = pageIndices;
      const newPageIndex = pageIndex - 1;
      const firstIndex = indices[0];

      if (newPageIndex < firstIndex) {
        return state;
      }

      if (!isPageIndexInRange(newPageIndex)) {
        throw new Error(`pageIndex: ${newPageIndex}, is out of range`);
      }

      const updatedState: FlowState<AddCollaboratorFlowData> = {
        ...state,
        pageIndex: newPageIndex,
        buttonText: determineButtonText(newPageIndex),
      };

      return {
        ...updatedState,
        isNextButtonDisabled: isNextButtonDisabled(updatedState),
      };
    }

    case actions.SET_FIRST_NAME: {
      const data = { ...state.data, firstName: payload };
      return {
        ...state,
        isNextButtonDisabled: isNextButtonDisabled({
          ...state,
          data,
        }),
        data,
      };
    }

    case actions.SET_LAST_NAME: {
      const data = { ...state.data, lastName: payload };
      return {
        ...state,
        isNextButtonDisabled: isNextButtonDisabled({
          ...state,
          data,
        }),
        data,
      };
    }

    case actions.SET_EMAIL: {
      const data = { ...state.data, email: payload };
      return {
        ...state,
        isNextButtonDisabled: isNextButtonDisabled({
          ...state,
          data,
        }),
        data,
      };
    }

    case actions.SET_COLLABORATOR_TYPE: {
      const data = { ...state.data, collaboratorType: payload };
      return {
        ...state,
        isNextButtonDisabled: isNextButtonDisabled({
          ...state,
          data,
        }),
        data,
      };
    }

    case actions.SET_SERVICE_PRO_TYPE: {
      const data = { ...state.data, serviceProfessionalType: payload };
      return {
        ...state,
        isNextButtonDisabled: isNextButtonDisabled({
          ...state,
          data,
        }),
        data,
      };
    }

    case actions.SET_ACCESS_LEVEL: {
      const data = { ...state.data, accessLevel: payload };
      return {
        ...state,
        isNextButtonDisabled: isNextButtonDisabled({
          ...state,
          data,
        }),
        data,
      };
    }

    case actions.SET_NOTIFY: {
      return {
        ...state,
        data: { ...state.data, notify: payload },
      };
    }

    case actions.ADD_RESOURCE: {
      const data = {
        ...state.data,
        resources: [...state.data.resources, payload],
      };
      return {
        ...state,
        isNextButtonDisabled: isNextButtonDisabled({
          ...state,
          data,
        }),
        data,
      };
    }

    case actions.ADD_ALL_RESOURCES: {
      const data = { ...state.data, resources: payload };
      return {
        ...state,
        isNextButtonDisabled: isNextButtonDisabled({
          ...state,
          data,
        }),
        data,
      };
    }

    case actions.REMOVE_RESOURCE: {
      const data = {
        ...state.data,
        resources: state.data.resources.filter((r) => r.resourceId !== payload),
      };
      return {
        ...state,
        isNextButtonDisabled: isNextButtonDisabled({
          ...state,
          data,
        }),
        data,
      };
    }

    case actions.REMOVE_ALL_RESOURCES: {
      const data = { ...state.data, resources: [] };
      return {
        ...state,
        isNextButtonDisabled: isNextButtonDisabled({
          ...state,
          data,
        }),
        data,
      };
    }

    default:
      return state;
  }
}

export default reducer;
