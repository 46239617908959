import React, { useState } from "react";

import { format, isValid as isValidDateFns } from "date-fns";
import { isNil } from "lodash";
import { FormProvider, useForm, useFormState } from "react-hook-form";
import { useSelector } from "react-redux";

import useClientProfile from "@fartherfinance/frontend/api/Entity/hooks/useClientProfile";
import usePutInvestorProfile from "@fartherfinance/frontend/api/Entity/hooks/usePutInvestorProfile";
import { ClientInvestorProfile } from "@fartherfinance/frontend/api/Entity/requests/getClientProfile";

import FormFooter from "../components/FormFooter/FormFooter";
import fullNameUnder31Char from "../validators/fullNameUnder31Char";
import isOver18 from "../validators/isOver18";
import isUnder100 from "../validators/isUnder100";
import onlyContainsLetters from "../validators/onlyContainsLetters";
import onlyLettersAndNumbers from "../validators/onlyLettersAndNumbers";
import { dateFormat } from "@src/constants/dateFormat";
import { genderOptions } from "@src/constants/gender";
import { martialStatusOptions } from "@src/constants/martialStatus";
import { prefixOptions } from "@src/constants/prefix";
import { suffixOptions } from "@src/constants/suffix";
import Portal from "@src/multiCustodian/components/Portal/Portal";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { captureException } from "@src/multiCustodian/services/tracking";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormDateField from "@src/sharedComponents/Forms/FormDateField";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import { State } from "@src/store";

import { Form } from "./Types";
import { labelOrNull } from "./utils/labelOrNull";
import { mapDefaultValues } from "./utils/mapDefaultValues";

import styles from "../SharedFormStyle.module.css";

interface Props {
  drawerFooterPortalId: string;
  onClose: () => void;
}

export default function ProfileFormFetcher(props: Props): JSX.Element {
  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));

  const auth = useRequestAuth();

  const clientProfile = useClientProfile(clientId, auth);

  if (clientProfile.isLoading) {
    return (
      <div className={styles.loading}>
        <LogoLoadingStill />
      </div>
    );
  }

  if (clientProfile.hasError) {
    return <div>Error</div>;
  }

  return (
    <ProfileForm
      drawerFooterPortalId={props.drawerFooterPortalId}
      investorProfile={clientProfile.data.investorProfile}
      onClose={props.onClose}
    />
  );
}

interface ProfileFormProps {
  drawerFooterPortalId: string;
  investorProfile: ClientInvestorProfile;
  onClose: () => void;
}

const nameChecker =
  process.env.WEBAPP_ENV === "PROD"
    ? onlyContainsLetters
    : onlyLettersAndNumbers;

function ProfileForm(props: ProfileFormProps): JSX.Element {
  const { isAdvisor, clientId } = useSelector((state: State) => ({
    isAdvisor: state.main_Reducer.user.isAdvisor,
    clientId: state.main_Reducer.user.id_user,
  }));

  const auth = useRequestAuth();

  const [isMutating, setIsMutating] = useState(false);

  const statusNotification = useStatusNotification();

  const callPutInvestorProfile = usePutInvestorProfile(clientId, auth);

  const validateWholeForm = !isAdvisor;

  const methods = useForm<Form>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: mapDefaultValues(props.investorProfile),
  });

  const { control, getValues, setValue } = methods;

  const { isValid } = useFormState({
    control,
  });

  const isRequired = validateWholeForm ? "Must not be empty" : false;

  const isSubmitDisabled = !isValid || isMutating;

  const submit = async () => {
    if (isSubmitDisabled) {
      return;
    }

    const values = getValues();

    const dobString =
      !isNil(values.DateOfBirth) && isValidDateFns(values.DateOfBirth)
        ? format(values.DateOfBirth, dateFormat)
        : null;

    if (isNil(values.FirstName) || isNil(values.LastName)) {
      const e =
        "[ProfileForm.tsx]: react-hook-form failed to prevent null or undefined FirstName or LastName from passing validation.";

      const error = new Error(e);

      captureException(error, {
        extra: {
          FirstName: values.FirstName ?? "null",
          LastName: values.LastName ?? "null",
        },
      });

      throw error;
    }

    const form: ClientInvestorProfile = {
      ...props.investorProfile,
      personalDetails: {
        ...props.investorProfile.personalDetails,
        name: {
          prefix: labelOrNull(values.Title),
          first: values.FirstName,
          last: values.LastName,
          middle: values.MiddleName ?? null,
          suffix: labelOrNull(values.Suffix),
        },
        gender: labelOrNull(values.Gender),
        maritalStatus: labelOrNull(values.MaritalStatus),
        dateOfBirth: dobString,
      },
    };

    try {
      setIsMutating(true);

      await callPutInvestorProfile(form);

      statusNotification("Profile updated.", "Success");

      props.onClose();
    } catch {
      setIsMutating(false);

      statusNotification("Failed to update profile.", "Error");
    }
  };

  const clearField = (field: keyof Form): void => {
    setValue(field, null);
  };

  return (
    <FormProvider {...methods}>
      <FormH1>Provide your profile information</FormH1>

      <FlexWrapper>
        <FormDropdownField
          name="Title"
          label="Title (Optional)"
          values={prefixOptions.map((p) => ({ label: p }))}
          onClear={() => clearField("Title")}
          disableSearch
        />

        <FormTextField
          name="FirstName"
          label="First Name"
          required={"Must not be empty"}
          rules={{
            validate: {
              nameChecker: (e) => nameChecker(e, "first", validateWholeForm),
              fullNameUnder31Char: (first) =>
                fullNameUnder31Char(first, getValues("LastName")),
            },
          }}
        />

        <FormTextField
          name="LastName"
          label="Last Name"
          required={"Must not be empty"}
          rules={{
            validate: {
              nameChecker: (e) => nameChecker(e, "last", validateWholeForm),
              fullNameUnder31Char: (last) =>
                fullNameUnder31Char(getValues("FirstName"), last),
            },
          }}
        />

        <FormTextField
          name="MiddleName"
          label="Middle Name (Optional)"
          rules={{
            validate: {
              nameChecker: (e) => nameChecker(e, "middle", false),
              max20: (e) =>
                e !== null && e.length > 20
                  ? "Middle name needs to be under 21 characters"
                  : true,
            },
          }}
        />

        <FormDropdownField
          name="Suffix"
          label="Suffix (Optional)"
          values={suffixOptions.map((e) => ({ label: e }))}
          disableSearch
          onClear={() => clearField("Suffix")}
        />

        <FormDropdownField
          name="Gender"
          label="Gender"
          values={genderOptions.map((e) => ({ label: e }))}
          disableSearch
          required={isRequired}
        />

        <FormDropdownField
          name="MaritalStatus"
          label="Marital Status"
          values={martialStatusOptions.map((e) => ({ label: e }))}
          disableSearch
          required={isRequired}
        />

        <FormDateField
          name="DateOfBirth"
          label="Date of Birth"
          required={isRequired}
          placeholder="MM/DD/YYYY"
          disableOpenPicker
          rules={{
            validate: {
              isOver18,
              isUnder100,
            },
          }}
        />
      </FlexWrapper>

      <Portal targetNodeId={props.drawerFooterPortalId}>
        <FormFooter isSubmitDisabled={isSubmitDisabled} handleSubmit={submit} />
      </Portal>

      {isMutating && (
        <div className={styles.loading}>
          <LogoLoadingStill />
        </div>
      )}
    </FormProvider>
  );
}
