import { httpClientV1 } from "@src/yellowstone/services/network";

import type {
  PostResubmitAccountsRequest,
  PostResubmitAccountsResponse,
} from "./types";
import type { AxiosResponse } from "axios";

const ACCOUNTS_PATH = "/accounts";

const postResubmitAccounts = (
  postResubmitPayload: PostResubmitAccountsRequest
) =>
  httpClientV1.post<
    PostResubmitAccountsResponse,
    AxiosResponse<PostResubmitAccountsResponse>,
    PostResubmitAccountsRequest
  >(`${ACCOUNTS_PATH}/resubmit`, postResubmitPayload);

export const accountsRepository = {
  postResubmitAccounts: {
    mutationCall: postResubmitAccounts,
  },
};
