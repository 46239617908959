import React, { useMemo } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

import Drawer from "@src/multiCustodian/components/Drawer/Drawer";
import DropdownMenuSelectOne from "@src/multiCustodian/components/DropdownMenuSelectOne/DropdownMenuSelectOne";
import useApplicationMode from "@src/multiCustodian/hooks/useApplicationMode";
import { MiscReducerActions } from "@src/multiCustodian/reducers/misc_Reducer";
import { State } from "@src/store";

import AffiliationFormFetcher from "./AffiliationForm/AffiliationFormFetcher";
import BeneficiaryFormFetcher from "./BeneficiaryForm/BeneficiaryFormFetcher";
import Trusts from "./components/Trusts/trusts";
import ContactFormFetcher from "./ContactForm/ContactFormFetcher";
import FinancialFormFetcher from "./FinancialForm/FinancialFormFetcher";
import ProfileFormFetcher from "./ProfileForm/ProfileFormFetcher";
import SecuritySettingsFetcher from "./SecuritySettings/SecuritySettingsFetcher";
import {
  dashboardDrawerFormsMenuOptions,
  DashboardForm,
  DRAWER_FOOTER_PORTAL_ID,
} from "./Types";

const Footer: React.FC = () => <div id={DRAWER_FOOTER_PORTAL_ID} />;

const DashboardForms = (): JSX.Element => {
  const { isOpen, context } = useSelector(
    (state: State) => state.misc_Reducer.drawerPanel
  );

  const { isClientMode, isImpersonationMode } = useApplicationMode();

  const dispatch = useDispatch<Dispatch<MiscReducerActions>>();

  const { authenticationProvider } = useFlags();

  const handleSetDrawerPanelContext = (formToShow: DashboardForm): void => {
    dispatch({
      type: "SET_DRAWER_PANEL_CONTEXT",
      payload: {
        formToShow,
      },
    });
  };

  const handleUnsetDrawerPanelContext = (): void => {
    dispatch({ type: "UNSET_DRAWER_PANEL_CONTEXT" });
  };

  const currentSelectItem = context ? { name: context.formToShow } : undefined;

  const filteredDashboardDrawerFormsMenuOptions = useMemo(
    () =>
      dashboardDrawerFormsMenuOptions.filter((x) => {
        // Hide this if not using Stytch
        if (x.name === "Security & Access") {
          return process.env.WEBAPP_EXACT_ENV === "UAT"
            ? false
            : authenticationProvider === "stytch";
        }

        const shouldShowMenuItems = isClientMode || isImpersonationMode;

        return shouldShowMenuItems;
      }),
    [authenticationProvider, isClientMode, isImpersonationMode]
  );

  return (
    <Drawer
      isDrawerOpen={isOpen}
      onClose={handleUnsetDrawerPanelContext}
      header={
        <DropdownMenuSelectOne
          menu_width="210px"
          cur_item={currentSelectItem}
          items={filteredDashboardDrawerFormsMenuOptions}
          changeMenuState={({ name }) =>
            handleSetDrawerPanelContext(name as DashboardForm)
          }
        />
      }
      footer={
        context?.formToShow === "Security & Access" ? undefined : <Footer />
      }
      ModalProps={{ keepMounted: true }}
    >
      {context?.formToShow === "Investor Profile" && (
        <ProfileFormFetcher
          drawerFooterPortalId={DRAWER_FOOTER_PORTAL_ID}
          onClose={handleUnsetDrawerPanelContext}
        />
      )}

      {context?.formToShow === "Contact Information" && (
        <ContactFormFetcher
          drawerFooterPortalId={DRAWER_FOOTER_PORTAL_ID}
          onClose={handleUnsetDrawerPanelContext}
        />
      )}

      {context?.formToShow === "Additional Information" && (
        <FinancialFormFetcher
          drawerFooterPortalId={DRAWER_FOOTER_PORTAL_ID}
          onClose={handleUnsetDrawerPanelContext}
        />
      )}

      {context?.formToShow === "Beneficiaries" && (
        <BeneficiaryFormFetcher
          drawerFooterPortalId={DRAWER_FOOTER_PORTAL_ID}
          onClose={handleUnsetDrawerPanelContext}
        />
      )}

      {context?.formToShow === "Affiliations" && (
        <AffiliationFormFetcher
          drawerFooterPortalId={DRAWER_FOOTER_PORTAL_ID}
          onClose={handleUnsetDrawerPanelContext}
        />
      )}

      {context?.formToShow === "Trusts" && (
        <Trusts drawerFooterPortalId={DRAWER_FOOTER_PORTAL_ID} />
      )}

      {context?.formToShow === "Security & Access" && (
        <SecuritySettingsFetcher />
      )}
    </Drawer>
  );
};

export default DashboardForms;
