import React, { useMemo } from "react";

import {
  ACCOUNT_IS_TAX_EXEMPT,
  COUNT_TOWARDS_CURRENT_YEAR,
} from "../../constants";
import { useCreateEditTaxBudgetContext } from "../../reducer/Context";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import TooltipBasic from "@src/sharedComponents/Tooltip/TooltipBasic";
import toUSD from "@src/utils/toUSD";

import styles from "../basicTable.module.css";

const CapitalGainsTaxesTable: React.FC = () => {
  const { state } = useCreateEditTaxBudgetContext();

  const {
    data: { budgetAmount, hypotheticalProposal },
  } = state;

  const proposal = useMemo(() => {
    if (!hypotheticalProposal) {
      throw new Error("hypotheticalProposal should not be null");
    }

    const hypotheticalGainsRealized_with =
      hypotheticalProposal.withBudgetProposals.reduce(
        (sum, cur) =>
          sum +
          cur.capitalConsequences.estimatedShortTermGainsDollars +
          cur.capitalConsequences.estimatedLongTermGainsDollars,
        0
      );

    const hypotheticalGainsRealized_without =
      hypotheticalProposal.withoutBudgetProposals.reduce(
        (sum, cur) =>
          sum +
          cur.capitalConsequences.estimatedShortTermGainsDollars +
          cur.capitalConsequences.estimatedLongTermGainsDollars,
        0
      );

    const hypotheticalLossesHarvested_with =
      hypotheticalProposal.withBudgetProposals.reduce(
        (sum, cur) =>
          sum +
          cur.capitalConsequences.estimatedShortTermLossesDollars +
          cur.capitalConsequences.estimatedLongTermLossesDollars,
        0
      );

    const hypotheticalLossesHarvested_without =
      hypotheticalProposal.withoutBudgetProposals.reduce(
        (sum, cur) =>
          sum +
          cur.capitalConsequences.estimatedShortTermLossesDollars +
          cur.capitalConsequences.estimatedLongTermLossesDollars,
        0
      );

    return {
      currentMarketValue_with: toUSD(
        hypotheticalProposal.withBudgetProposals.reduce(
          (sum, cur) => sum + cur.currentHoldingsDetails.totalMarketValue,
          0
        )
      ),
      currentMarketValue_without: toUSD(
        hypotheticalProposal.withoutBudgetProposals.reduce(
          (sum, cur) => sum + cur.currentHoldingsDetails.totalMarketValue,
          0
        )
      ),

      ytdNetGainsRealized_with: toUSD(
        hypotheticalProposal.withBudgetProposals.reduce(
          (sum, cur) => sum + cur.ytdNetGainsRealized.totalDollars,
          0
        )
      ),

      ytdNetGainsRealized_without: toUSD(
        hypotheticalProposal.withoutBudgetProposals.reduce(
          (sum, cur) => sum + cur.ytdNetGainsRealized.totalDollars,
          0
        )
      ),

      hypotheticalGainsRealized_with: toUSD(hypotheticalGainsRealized_with),
      hypotheticalGainsRealized_without: toUSD(
        hypotheticalGainsRealized_without
      ),

      hypotheticalLossesHarvested_with: toUSD(hypotheticalLossesHarvested_with),
      hypotheticalLossesHarvested_without: toUSD(
        hypotheticalLossesHarvested_without
      ),

      taxesPaid_with: toUSD(
        hypotheticalProposal.withBudgetProposals.reduce(
          (sum, cur) =>
            sum +
            cur.capitalConsequences.estimatedShortTermTaxesDollars +
            cur.capitalConsequences.estimatedLongTermTaxesDollars,
          0
        )
      ),
      taxesPaid_without: toUSD(
        hypotheticalProposal.withoutBudgetProposals.reduce(
          (sum, cur) =>
            sum +
            cur.capitalConsequences.estimatedShortTermTaxesDollars +
            cur.capitalConsequences.estimatedLongTermTaxesDollars,
          0
        )
      ),
    };
  }, [hypotheticalProposal]);

  const anAccountIsTaxAdvantaged = useMemo(() => {
    if (!hypotheticalProposal) {
      throw new Error("hypotheticalProposal should not be null");
    }

    return hypotheticalProposal.withBudgetProposals.some(
      (p) => p.includesTaxAdvantagedAccount
    );
  }, [hypotheticalProposal]);

  return (
    <table className={styles.table}>
      <thead className={styles.thead}>
        <tr className={styles.headerRow}>
          <td className={styles.cell}></td>
          <td className={toClassName(styles.headerCell, styles.textSubtle)}>
            Without a Tax Budget
          </td>
          <td className={styles.headerCell}>With a Tax Budget</td>
        </tr>
      </thead>

      <tbody>
        <tr className={styles.row}>
          <td className={styles.cell}>Tax Budget Amount</td>
          <td className={styles.cell}>Unlimited</td>
          <td className={styles.cell}>{toUSD(budgetAmount)}</td>
        </tr>

        <tr className={styles.row}>
          <td className={styles.cell}>Current Market Value</td>
          <td className={styles.cell}>{proposal.currentMarketValue_without}</td>
          <td className={styles.cell}>{proposal.currentMarketValue_with}</td>
        </tr>

        <tr className={styles.row}>
          <td className={styles.cell}>YTD Net Gains Realized</td>
          <td className={styles.cell}>
            {proposal.ytdNetGainsRealized_without}
          </td>
          <td className={styles.cell}>{proposal.ytdNetGainsRealized_with}</td>
        </tr>

        <tr className={styles.row}>
          <td className={styles.cell}>Hypothetical Gains Realized</td>
          <td className={styles.cell}>
            {proposal.hypotheticalGainsRealized_without}
          </td>
          <td className={styles.cell}>
            {proposal.hypotheticalGainsRealized_with}
          </td>
        </tr>

        <tr className={styles.row}>
          <td className={styles.cell}>Hypothetical Losses Harvested</td>
          <td className={styles.cell}>
            {`(${proposal.hypotheticalLossesHarvested_without})`}
          </td>
          <td className={styles.cell}>
            {`(${proposal.hypotheticalLossesHarvested_with})`}
          </td>
        </tr>

        <tr className={styles.footerRow}>
          <td className={styles.cell}>
            <div className={styles.flexRow}>
              <div>Taxes Paid</div>
              <TooltipBasic
                className={styles.infoIcon}
                text={`${COUNT_TOWARDS_CURRENT_YEAR} ${
                  anAccountIsTaxAdvantaged ? ACCOUNT_IS_TAX_EXEMPT : ""
                }`}
              />
            </div>
          </td>
          <td className={styles.cell}>{proposal.taxesPaid_without}</td>
          <td className={styles.cell}>{proposal.taxesPaid_with}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default CapitalGainsTaxesTable;
