import React from "react";

import { format } from "date-fns";
import { range } from "lodash";
import { useElementSize } from "usehooks-ts";

import { UNKNOWN_BALANCE } from "@fartherfinance/frontend/api/Accounts/utilities/accountUtil";
import { TaxLot } from "@fartherfinance/frontend/api/PerformanceGroups/requests/getHoldings";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import { FartherTableBodyCell } from "@src/sharedComponents/Table/Components";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";
import toUSD from "@src/utils/toUSD";

import styles from "./ExpandableRow.module.css";

export const formatNumber = (amount: number, fractionDigits = 2): string =>
  amount.toLocaleString("en-US", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

interface Props {
  taxLots: TaxLot[];
}

const ExpandableRow = ({ taxLots }: Props): JSX.Element => {
  const [titleRef, { height: titleHeight }] = useElementSize();

  const spacerStyle: React.CSSProperties = {
    position: "relative",
    height: `${titleHeight}px`,
  }; //Used to create the vertical space that the overflowing Transaction history title takes up

  return (
    <>
      <FartherTableBodyCell colSpan={1}>
        <div className={styles.col1}>
          <div style={spacerStyle}>
            <div ref={titleRef} className={styles.title}>
              Transaction History (Tax Lots)
            </div>
          </div>

          <div className={styles.dataContainer}>
            {taxLots.map((t, idx) => (
              <div key={`date-${idx}`}>{format(t.buyDate, "MMM d, yyyy")}</div>
            ))}
          </div>
        </div>
      </FartherTableBodyCell>

      <FartherTableBodyCell colSpan={1}>
        <div style={spacerStyle} />

        <div className={styles.dataContainer}>
          {taxLots.map((t, idx) => (
            <div key={`shares-${idx}`}>{formatNumber(t.quantity)}</div>
          ))}
        </div>
      </FartherTableBodyCell>

      <FartherTableBodyCell colSpan={1}>
        <div style={spacerStyle} />

        <div className={styles.dataContainer}>
          {range(taxLots.length).map((idx) => (
            <div key={`unknown-1-${idx}`}>{UNKNOWN_BALANCE}</div>
          ))}
        </div>
      </FartherTableBodyCell>

      <FartherTableBodyCell colSpan={1}>
        <div style={spacerStyle} />

        <div className={styles.dataContainer}>
          {range(taxLots.length).map((idx) => (
            <div key={`unknown-2-${idx}`}>{UNKNOWN_BALANCE}</div>
          ))}
        </div>
      </FartherTableBodyCell>

      <FartherTableBodyCell colSpan={1}>
        <div style={spacerStyle} />

        <div className={toClassName(styles.alignRight, styles.dataContainer)}>
          {taxLots.map((t, idx) => {
            if (t.buyPrice === 0 && t.quantity > 0) {
              return (
                <div key={`price-${idx}`}>
                  <Tooltip
                    tooltipText={"Account data may be inaccurate"}
                    placement="top"
                  >
                    <div>{`${toUSD(t.buyPrice * t.quantity)}*`}</div>
                  </Tooltip>
                </div>
              );
            }

            return (
              <div key={`price-${idx}`}>{toUSD(t.buyPrice * t.quantity)}</div>
            );
          })}
        </div>
      </FartherTableBodyCell>

      <FartherTableBodyCell colSpan={1}>
        <div style={spacerStyle} />

        <div className={toClassName(styles.alignRight, styles.dataContainer)}>
          {taxLots.map((t, idx) => (
            <div key={`value-${idx}`}>{toUSD(t.marketValue)}</div>
          ))}
        </div>
      </FartherTableBodyCell>

      <FartherTableBodyCell colSpan={1} />
    </>
  );
};

export default ExpandableRow;
