import React from "react";

import { AccountType } from "@fartherfinance/frontend/api/Transfer/requests/Types";
import { FartherAccountId } from "@fartherfinance/frontend/api/Types";

import ButtonPrimary from "../../../../../components/MUI/Button/Button";
import {
  AcatsInstitutionInfo,
  IraType,
  SetScreenAndTransferType,
  TransferScreen,
} from "../../Types";

import "../css.css";

type Props = (
  | {
      transferType: "roll over";
      iraType: IraType;
    }
  | { transferType: "transfer" }
) & {
  screen: TransferScreen["screen"];
  acatsInstitution: AcatsInstitutionInfo;
  acatsAccountType: AccountType;
  accountHolderName: string;
  accountNumber: string;
  toAccountId: FartherAccountId;
  setCurScreen: SetScreenAndTransferType;
};

const OptionalInfo = (props: Props) => {
  const onClickOptionYes = () => {
    const { setCurScreen: _, screen: _s, ...screenInfo } = props;

    if ("iraType" in screenInfo) {
      props.setCurScreen({
        screen: "IRA/transferVerification",
        ...screenInfo,
      });
    } else {
      props.setCurScreen({
        screen: "Investment/transferVerification",
        ...screenInfo,
      });
    }
  };

  const onClickOptionNo = () => {
    const { setCurScreen: _, screen: _s, ...screenInfo } = props;

    if ("iraType" in screenInfo) {
      props.setCurScreen({
        screen: "IRA/transferInitiated",
        uploadFilename: null,
        statement: null,
        ...screenInfo,
      });
    } else {
      props.setCurScreen({
        screen: "Investment/transferInitiated",
        uploadFilename: null,
        statement: null,
        ...screenInfo,
      });
    }
  };

  return (
    <div className="Dashboard_Acats_pages__container">
      <div className="Dashboard_Acats_pages__content_container">
        <p className="Dashboard_Acats_pages__title">Upload account statement</p>

        <p className="Dashboard_Acats_pages__summary">
          Excellent! Your {props.transferType} is almost underway. Before we
          initiate it, would you like to provide optional documentation for
          verification in case your institution attempts to reject your{" "}
          {props.acatsAccountType}?
        </p>

        <p className="Dashboard_Acats_pages__gray_text">* We recommend it</p>

        <div className="Dashboard_Acats_pages__flex_row_div_2">
          <ButtonPrimary
            variant={"outlined"}
            buttonType={"secondary"}
            text={"Yes"}
            onClick={() => onClickOptionYes()}
          />

          <ButtonPrimary
            style={{ marginLeft: "30px" }}
            variant={"contained"}
            buttonType={"primary"}
            text={"No"}
            onClick={() => onClickOptionNo()}
          />
        </div>

        <div className="Dashboard_Acats_pages__spacing"></div>

        <div className="Dashboard_Acats_pages__button_container_2">
          <div
            className="Dashboard_Acats_pages__back_button_container"
            onClick={() => {
              const { setCurScreen: _, screen: _s, ...screenInfo } = props;

              if ("iraType" in screenInfo) {
                props.setCurScreen({
                  screen: "IRA/transferDestination",
                  ...screenInfo,
                });
              } else {
                props.setCurScreen({
                  screen: "Investment/transferDestination",
                  ...screenInfo,
                });
              }
            }}
          >
            <img src="/src/assets/svg/left_arrow.svg"></img>
            <div className="Dashboard_Acats_pages__back_button">Back</div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default OptionalInfo;
