import React, { Fragment, useState } from "react";

import InfoIcon from "@mui/icons-material/Info";
import { Box, Stack } from "@mui/material";
import { head, isNumber } from "lodash";

import {
  PostTradePortfolioAggregate,
  PostTradePortfolioTotal,
} from "@fartherfinance/frontend/api/Rebalance/Types";

import {
  toCurrency,
  toLocaleNumber,
  toPercent,
} from "../../ProposalDetails.utils";
import { TableRotatedLegend } from "../TableRotatedLegend/TableRotatedLegend.component";
import Message from "@src/sharedComponents/Message/Message";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";
import { Table } from "@src/yellowstone/components";
import { PLACEHOLDER_SIGN } from "@src/yellowstone/modules/shared";
import {
  isCashSecurity,
  isSignificantDiff,
} from "@src/yellowstone/modules/trades";

import {
  POST_TRADE_PORTFOLIO_HEADERS,
  TARGET_PORTFOLIO_HEADERS,
} from "./ProposalPostTradePortfolio.const";
import { useStyles } from "./ProposalPostTradePortfolio.styles";

const POST_TRADE_ROWS_SKELETON_COUNT = 5;

interface ProposalPostTradePortfolioProps {
  postTradePortfolioAggregates: PostTradePortfolioAggregate[];
  postTradePortfolioTotal: PostTradePortfolioTotal | undefined;
  isLoading: boolean;
  hasError: boolean;
}

export const ProposalPostTradePortfolio: React.FC<
  ProposalPostTradePortfolioProps
> = ({
  postTradePortfolioAggregates,
  postTradePortfolioTotal,
  isLoading,
  hasError,
}) => {
  const { classes, cx } = useStyles({
    rowsCount:
      postTradePortfolioAggregates.length || POST_TRADE_ROWS_SKELETON_COUNT,
  });
  const [rowIndexToHighlight, setRowIndexToHighlight] = useState<number>();

  const headers = [
    ...POST_TRADE_PORTFOLIO_HEADERS,
    ...TARGET_PORTFOLIO_HEADERS,
  ];
  const rotatedLegendsCount = 2;
  const columnsCount = headers.length + rotatedLegendsCount;

  if (
    hasError ||
    (!hasError && !isLoading && postTradePortfolioAggregates.length === 0)
  ) {
    return (
      <Stack className={classes.container}>
        <Message text="Sorry, we couldn't generate proposal post-trade details." />
      </Stack>
    );
  }

  return (
    <Stack className={classes.container}>
      <Table className={classes.table} columnsCount={columnsCount}>
        <TableRotatedLegend
          className={classes.legend}
          legendTitle="Post-Trade Portfolio"
        />
        <TableRotatedLegend
          className={cx(classes.legend, classes.targetPortfolioLegend)}
          legendTitle="Target Portfolio"
        />

        {headers.map((header, index) => (
          <Table.Header
            key={`${header}-${index}`}
            className={cx({
              [classes.firstColumnSpacer]: index === 0 || index === 6,
            })}
          >
            {header}
          </Table.Header>
        ))}

        {isLoading && (
          <>
            {Array.from({ length: POST_TRADE_ROWS_SKELETON_COUNT }).map(
              (_, index) => (
                <Fragment key={index}>
                  <Table.Row className={classes.postTradePortfolioRow}>
                    {Array.from({
                      length: POST_TRADE_PORTFOLIO_HEADERS.length,
                    }).map((_, index) => (
                      <Table.Cell key={index}>
                        <Skeleton />
                      </Table.Cell>
                    ))}
                  </Table.Row>

                  <Table.Row className={classes.targetPortfolioRow}>
                    {Array.from({
                      length: TARGET_PORTFOLIO_HEADERS.length,
                    }).map((_, index) => (
                      <Table.Cell key={index}>
                        <Skeleton />
                      </Table.Cell>
                    ))}
                  </Table.Row>
                </Fragment>
              )
            )}
          </>
        )}

        {!hasError && !isLoading && (
          <>
            {postTradePortfolioAggregates.map((postTradeAggregate, index) => {
              const isMultiple = postTradeAggregate.accountItems.length > 1;
              const isLastRow =
                index === postTradePortfolioAggregates.length - 1;

              const account = isMultiple
                ? "Subtotal"
                : head(postTradeAggregate.accountItems)?.account
                    .custodianAccountId ?? PLACEHOLDER_SIGN;

              return (
                <Fragment key={index}>
                  <Table.Row
                    className={cx(classes.postTradePortfolioRow, {
                      [classes.rowHighlight]: index === rowIndexToHighlight,
                    })}
                    onMouseEnter={() => setRowIndexToHighlight(index)}
                    onMouseLeave={() => setRowIndexToHighlight(undefined)}
                  >
                    <Table.Cell
                      className={cx(
                        classes.firstColumn,
                        classes.firstColumnSpacer
                      )}
                    >
                      {account}
                    </Table.Cell>
                    <Table.Cell
                      className={cx({
                        [classes.cashInfoContainer]: !isLoading,
                      })}
                    >
                      {postTradeAggregate.ticker ?? PLACEHOLDER_SIGN}
                      {isCashSecurity(postTradeAggregate.cusip) && (
                        <Tooltip
                          tooltipText="Post-trade cash only reflects yesterday's cash balance. It does not factor in outstanding deposits, outstanding withdrawals, or use of margin."
                          placement="top"
                        >
                          <InfoIcon className={classes.cashInfoIndicator} />
                        </Tooltip>
                      )}
                    </Table.Cell>
                    <Table.Cell className={classes.truncate}>
                      <Tooltip
                        tooltipText={postTradeAggregate.subtotal.totalQuantity}
                        placement="top"
                      >
                        <span>
                          {toLocaleNumber(
                            // prettier-ignore
                            Number(postTradeAggregate.subtotal.totalQuantity.toFixed(2))
                          )}
                        </span>
                      </Tooltip>
                    </Table.Cell>
                    <Table.Cell>
                      {isNumber(postTradeAggregate.subtotal.price)
                        ? toCurrency(postTradeAggregate.subtotal.price)
                        : PLACEHOLDER_SIGN}
                    </Table.Cell>
                    <Table.Cell>
                      {isNumber(postTradeAggregate.subtotal.totalMarketValue)
                        ? toCurrency(
                            postTradeAggregate.subtotal.totalMarketValue
                          )
                        : PLACEHOLDER_SIGN}
                    </Table.Cell>
                    <Table.Cell>
                      {isNumber(postTradeAggregate.subtotal.weight)
                        ? toPercent(postTradeAggregate.subtotal.weight)
                        : PLACEHOLDER_SIGN}
                    </Table.Cell>
                    {isMultiple &&
                      postTradeAggregate.accountItems.map((item, index) => (
                        <Fragment key={index}>
                          <Box
                            className={cx(
                              classes.subtotal,
                              classes.subtotalFirstColumn
                            )}
                          >
                            {item.account.custodianAccountId ??
                              PLACEHOLDER_SIGN}
                          </Box>
                          <Box className={classes.subtotal}>
                            {postTradeAggregate.ticker ?? PLACEHOLDER_SIGN}
                          </Box>
                          <Box
                            className={cx(classes.subtotal, classes.truncate)}
                          >
                            {toLocaleNumber(
                              Number(item.totalQuantity.toFixed(2))
                            )}
                          </Box>
                          <Box className={classes.subtotal}>
                            {isNumber(item.price)
                              ? toCurrency(item.price)
                              : PLACEHOLDER_SIGN}
                          </Box>
                          <Box className={classes.subtotal}>
                            {isNumber(item.totalMarketValue)
                              ? toCurrency(item.totalMarketValue)
                              : PLACEHOLDER_SIGN}
                          </Box>
                          <Box className={classes.subtotal}>
                            {isNumber(item.weight)
                              ? toPercent(item.weight)
                              : PLACEHOLDER_SIGN}
                          </Box>
                        </Fragment>
                      ))}
                  </Table.Row>

                  <Table.Row
                    className={cx(classes.targetPortfolioRow, {
                      [classes.rowHighlight]: index === rowIndexToHighlight,
                    })}
                    onMouseEnter={() => setRowIndexToHighlight(index)}
                    onMouseLeave={() => setRowIndexToHighlight(undefined)}
                  >
                    <Table.Cell
                      className={cx(
                        classes.firstColumn,
                        classes.firstColumnSpacer
                      )}
                    >
                      {toPercent(postTradeAggregate.subtotal.targetWeight)}
                    </Table.Cell>
                    <Table.Cell
                      className={cx({
                        [classes.significantDiff]: isSignificantDiff(
                          postTradeAggregate.subtotal.targetWeightDifference ??
                            0
                        ),
                      })}
                    >
                      {isNumber(
                        postTradeAggregate.subtotal.targetWeightDifference
                      )
                        ? toPercent(
                            postTradeAggregate.subtotal.targetWeightDifference,
                            { signDisplay: "exceptZero" }
                          )
                        : PLACEHOLDER_SIGN}
                    </Table.Cell>
                    {isMultiple &&
                      postTradeAggregate.accountItems.map((_, index) => (
                        <Fragment key={index}>
                          <Box
                            className={cx(
                              classes.subtotal,
                              classes.firstColumnSpacer
                            )}
                          >
                            {PLACEHOLDER_SIGN}
                          </Box>
                          <Box className={classes.subtotal}>
                            {PLACEHOLDER_SIGN}
                          </Box>
                        </Fragment>
                      ))}
                  </Table.Row>

                  {isLastRow && postTradePortfolioTotal && (
                    <>
                      <Table.Row
                        className={classes.postTradePortfolioRow}
                        noHover
                      >
                        <Table.Cell
                          className={cx(
                            classes.total,
                            classes.firstColumn,
                            classes.firstColumnSpacer
                          )}
                        >
                          Total
                        </Table.Cell>
                        <Table.Cell className={classes.total}>
                          {PLACEHOLDER_SIGN}
                        </Table.Cell>
                        <Table.Cell className={classes.total}>
                          {PLACEHOLDER_SIGN}
                        </Table.Cell>
                        <Table.Cell className={classes.total}>
                          {PLACEHOLDER_SIGN}
                        </Table.Cell>
                        <Table.Cell className={classes.total}>
                          {isNumber(postTradePortfolioTotal.totalMarketValue)
                            ? toCurrency(
                                postTradePortfolioTotal.totalMarketValue
                              )
                            : PLACEHOLDER_SIGN}
                        </Table.Cell>
                        <Table.Cell className={classes.total}>
                          {isNumber(postTradePortfolioTotal.weight)
                            ? toPercent(postTradePortfolioTotal.weight)
                            : PLACEHOLDER_SIGN}
                        </Table.Cell>
                      </Table.Row>

                      <Table.Row className={classes.targetPortfolioRow} noHover>
                        <Table.Cell
                          className={cx(
                            classes.total,
                            classes.firstColumn,
                            classes.firstColumnSpacer
                          )}
                        >
                          {toPercent(postTradePortfolioTotal.targetWeight)}
                        </Table.Cell>
                        <Table.Cell className={classes.total}>
                          {isNumber(
                            postTradePortfolioTotal.targetWeightDifference
                          )
                            ? toPercent(
                                postTradePortfolioTotal.targetWeightDifference
                              )
                            : PLACEHOLDER_SIGN}
                        </Table.Cell>
                      </Table.Row>
                    </>
                  )}
                </Fragment>
              );
            })}
          </>
        )}
      </Table>
    </Stack>
  );
};
