import React from "react";

import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Collapse from "@mui/material/Collapse";

import Skeleton from "../Skeleton/Skeleton";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./OptionWithExpandCollapseContent.module.css";

interface Props {
  headerLeftContent: JSX.Element;
  expand: boolean;
  setExpand: (bool: boolean) => void;
  isLoading?: boolean;
  headerRightContent?: JSX.Element;
  className?: string;
  headerClassName?: string;
}

const OptionWithExpandCollapseContent = (
  props: React.PropsWithChildren<Props>
): JSX.Element => {
  return (
    <div className={toClassName(styles.container, props.className)}>
      <div
        className={toClassName(
          styles.header,
          props.expand ? styles.headerExpanded : styles.headerCollapsed,
          props.headerClassName
        )}
        onClick={() => {
          if (!props.isLoading) {
            props.setExpand(!props.expand);
          }
        }}
      >
        {props.headerLeftContent}

        {props.isLoading ? (
          <Skeleton
            width={props.headerRightContent ? 120 : 40}
            height={20}
            style={{ transform: "none" }}
          />
        ) : (
          <div className={styles.headerRight}>
            {props.headerRightContent && props.headerRightContent}

            <ChevronRightRoundedIcon
              className={toClassName(
                styles.chevron,
                props.expand ? styles.chevronRotateDown : styles.chevronRotateUp
              )}
            />
          </div>
        )}
      </div>

      {!props.isLoading && (
        <Collapse orientation="vertical" in={props.expand} collapsedSize={0}>
          {props.children}
        </Collapse>
      )}
    </div>
  );
};

export default OptionWithExpandCollapseContent;
