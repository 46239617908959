import React from "react";

import Button from "../../../MUI/Button/Button";
import { actions } from "../../../TaxBudgets/reducer/actions";
import { useCreateEditTaxBudgetContext } from "../../../TaxBudgets/reducer/Context";
import ActionRow from "@src/sharedComponents/ActionRow/ActionRow";
import toUSD from "@src/utils/toUSD";

const AmountReviewRow: React.FC = () => {
  const {
    state: {
      data: { budgetAmount },
    },
    dispatch,
  } = useCreateEditTaxBudgetContext();

  const handleOnClick = () =>
    dispatch({ type: actions.SET_PAGE_INDEX, payload: 2 });

  return (
    <ActionRow
      start="Budget Amount"
      end={`${toUSD(budgetAmount)}`}
      action={
        <Button
          text="Edit"
          variant="text"
          buttonType="primary"
          onClick={handleOnClick}
        />
      }
    />
  );
};

export default AmountReviewRow;
