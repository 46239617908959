import React from "react";

import { Stack } from "@mui/material";

import { MarketplaceOfferingInterestOpportunity } from "@fartherfinance/frontend/api/Opportunities/Types";

import OpportunityClient from "../OpportunityClient";
import OpportunityOffering from "../OpportunityOffering";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./OpportunityCommonDetails.module.css";

interface OpportunityOfferingCommonDetailsProps {
  opportunity: MarketplaceOfferingInterestOpportunity;
}

const OpportunityOfferingCommonDetails: React.FC<
  OpportunityOfferingCommonDetailsProps
> = ({ opportunity }) => {
  return (
    <Stack className={toClassName(styles.content, styles.contentOffering)}>
      <OpportunityClient clientDetails={opportunity.clientDetails} />

      <OpportunityOffering opportunity={opportunity} />
    </Stack>
  );
};

export default OpportunityOfferingCommonDetails;
