import React, { useState } from "react";

import {
  AccountType,
  accountTypes,
} from "@fartherfinance/frontend/api/Transfer/requests/Types";

import { IraType, SetScreenAndTransferType, TransferScreen } from "../../Types";
import Input_Component from "@src/multiCustodian/components/Input/Input_Component_2";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import SAODropdown from "@src/sharedComponents/SAODropdown/Dropdown";

import "../css.css";

const neutral_underline_style = {
  borderBottom: "1px solid var(--neutral_input_underline_color_1)",
  width: "100%",
};

type AccountTypeLabel = "Individual" | "Joint" | "Trust";

type Props = (
  | {
      transferType: "roll over";
      iraType: IraType;
    }
  | { transferType: "transfer" }
) & {
  screen: TransferScreen["screen"];
  setCurScreen: SetScreenAndTransferType;
};

const MissingInstitution = (props: Props) => {
  const [institutionName, setInstitutionName] = useState<string>("");

  const [acatsAccountType, setAcatsAccountType] = useState<{
    type: AccountType;
    label: AccountTypeLabel;
  }>({ type: "INDIVIDUAL", label: "Individual" });

  const institutionOk = institutionName.length >= 3;

  const onContinue = () => {
    if (institutionName.length < 3) {
      return;
    }

    const { setCurScreen: _, screen: _s, ...screenInfo } = props;

    if ("iraType" in screenInfo) {
      props.setCurScreen({
        ...screenInfo,
        screen: "IRA/accountInformation",
        acatsInstitution: {
          type: "name",
          acatsInstitutionName: institutionName,
        },
        acatsAccountType: acatsAccountType.type,
      });
    } else {
      props.setCurScreen({
        ...screenInfo,
        screen: "Investment/accountInformation",
        acatsInstitution: {
          type: "name",
          acatsInstitutionName: institutionName,
        },
        acatsAccountType: acatsAccountType.type,
      });
    }
  };

  return (
    <div className="Dashboard_Acats_pages__container">
      <div className="Dashboard_Acats_pages__content_container">
        <p className="Dashboard_Acats_pages__title">Add your institution</p>

        <p className="Dashboard_Acats_pages__summary">
          Didn't see your institution? That's no problem. Simply type the name
          of the institution and select the account type and we'll handle the $
          {props.transferType} manually on our end.
        </p>

        {props.transferType === "transfer" && (
          <div className="Dashboard_Acats_pages__dropdownDiv">
            <div className="Dashboard_Acats_pages__dropdownHeader">
              Account Type
            </div>

            <SAODropdown
              disableSearch={true}
              value={{
                item: acatsAccountType.type,
                label: acatsAccountType.label,
              }}
              values={accountTypes.map((acctType) => {
                const val: AccountTypeLabel =
                  acctType === "INDIVIDUAL"
                    ? "Individual"
                    : acctType === "JOINT"
                    ? "Joint"
                    : "Trust";

                return {
                  item: acctType,
                  label: val,
                };
              })}
              onChange={(e) =>
                setAcatsAccountType({
                  type: e.item,
                  label: e.label,
                })
              }
              style={{ width: "100%" }}
            />
          </div>
        )}

        <Input_Component
          title={"Institution"}
          name={"institution_name"}
          type={"text"}
          placeholder={""}
          value={institutionName}
          validation_img={
            institutionOk
              ? "/src/assets/svg/check_green.svg"
              : "/src/assets/svg/!.svg"
          }
          show_checkmark={institutionOk}
          underline_style={neutral_underline_style}
          error_description={undefined}
          handleInputChange={(e) => setInstitutionName(e.target.value)}
        />

        <div className="Dashboard_Acats_pages__button_container_2">
          <div
            className="Dashboard_Acats_pages__back_button_container"
            onClick={() => {
              const { setCurScreen: _, screen: _s, ...screenInfo } = props;

              if ("iraType" in screenInfo) {
                props.setCurScreen({
                  screen: "IRA/institutionSelection",
                  ...screenInfo,
                });
              } else {
                props.setCurScreen({
                  screen: "Investment/institutionSelection",
                  ...screenInfo,
                });
              }
            }}
          >
            <img src="/src/assets/svg/left_arrow.svg"></img>
            <div className="Dashboard_Acats_pages__back_button">Back</div>
          </div>

          <ButtonPrimary
            disabled={!institutionOk}
            variant={"contained"}
            buttonType={"primary"}
            text={"Continue"}
            onClick={() => onContinue()}
          />
        </div>
      </div>
    </div>
  );
};

export default MissingInstitution;
