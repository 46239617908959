import React, { ComponentProps } from "react";

import { Controller, Message, useFormContext } from "react-hook-form";

import Textarea, { TextareaProps } from "../Textarea/Textarea";

interface Props
  extends Omit<TextareaProps, "required" | "value" | "onChange" | "label"> {
  name: string;
  label: React.ReactNode;
  required?: Message | false;
  rules?: ComponentProps<typeof Controller>["rules"];
}

export default function FormTextareaField({
  name,
  defaultValue,
  required,
  rules,
  ...restProps
}: Props): JSX.Element {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required, ...rules }}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => (
        <Textarea
          name={field.name}
          value={field.value}
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
          onChange={field.onChange}
          onBlur={field.onBlur}
          {...restProps}
        />
      )}
    />
  );
}
