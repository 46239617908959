import React, { PropsWithChildren } from "react";

import { MarketplaceAssetType } from "@fartherfinance/frontend/api/AltAssetsService/Types";

import OfferingsListClientV2 from "../../Marketplace/OfferingsListClient/OfferingsListClientV2";
import { AltAssetsMarketplacePath } from "@src/config/routing/RouterPaths";
import { altAssetOfferingTypes } from "@src/multiCustodian/components/Marketplace/constants";

const AltAssetsOfferingsListClient: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <OfferingsListClientV2
      assetType={MarketplaceAssetType.enum.alternative_asset}
      offeringTypes={altAssetOfferingTypes}
      marketplacePath={AltAssetsMarketplacePath}
      pageTitleFallback="Alternative Assets"
    >
      {children}
    </OfferingsListClientV2>
  );
};

export default AltAssetsOfferingsListClient;
