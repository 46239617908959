import React from "react";

import styles from "./FlexWrapperBeneficiaries.module.css";

interface Props {
  style?: React.CSSProperties;
}

export default function FlexWrapperBeneficiaries(
  props: React.PropsWithChildren<Props>
): JSX.Element {
  return (
    <div className={styles.wrapper} style={props.style}>
      {props.children}
    </div>
  );
}
