import { useMemo } from "react";

import orderBy from "lodash/orderBy";

import useAdvisorClients from "@fartherfinance/frontend/api/Entity/hooks/useAdvisorClients";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";

export const useClientsOptions = () => {
  const auth = useAdvisorRequestAuth();
  const advisorClients = useAdvisorClients(undefined, auth);

  return useMemo(
    () =>
      orderBy(
        advisorClients.data?.clients.map((client) => ({
          label: `${client.name.first} ${client.name.last}`,
          value: client.clientId,
        })) ?? [],
        (entity) => entity.label
      ),

    [advisorClients.data]
  );
};
