export const generateRange = (
  pageSize: number,
  pageNumber: number,
  totalCount: number,
  collectionName: string
): string => {
  const start = pageNumber === 0 ? 1 : pageSize * pageNumber;
  const end = pageNumber === 0 ? pageSize : pageSize * (pageNumber + 1);

  const limitedStart = start > totalCount ? totalCount : start;
  const limitedEnd = end > totalCount ? totalCount : end;

  return totalCount === 1
    ? `${limitedStart}-${limitedEnd} ${collectionName}`
    : `${limitedStart}-${limitedEnd} ${collectionName} (${totalCount} total)`;
};
