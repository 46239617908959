import React from "react";

const Home: React.FC = () => (
  <svg
    width="100%"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="home">
      <mask
        id="mask0_828_5215"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="14"
        height="14"
      >
        <rect id="Bounding box" width="14" height="14" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_828_5215)">
        <path
          id="home_2"
          d="M2.7998 11.8996V5.24961L6.9998 2.09961L11.1998 5.24961V11.8996H8.0498V8.04961H5.9498V11.8996H2.7998Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default Home;
