// markdown lists come as "1. xxx\n2. xxx" and we want to add double
// whitespace like "1. xxx\n\n2. xxx". We can also get unordered lists using
// "\n- xxx\n- xxx" format
export function markdownListSplit(str: string): string {
  return str
    .replace(/\n(\d)\. /g, "\n\n$1. ")
    .replace(/\n\- /g, "\n\n- ")
    .replace(/(.)\n\| /g, "$1\n\n| ")
    .replace(/\|\n\|/g, "|\n\n|"); // splits tables
}
