import isNull from "lodash/isNull";

import { ListProposal } from "@fartherfinance/frontend/api/Rebalance/Types";

import { formatters } from "@src/yellowstone/modules/shared";

import {
  locationStateSchema,
  ProposalDetailsLocationQueryParams,
  ProposalDetailsLocationState,
} from "./ProposalDetails.const";

export const parseLocationState = (
  locationState: unknown
): ProposalDetailsLocationState | null => {
  return locationStateSchema.parse(locationState);
};

export const generateNextPageQueryParams = (
  currentQueryParams: ProposalDetailsLocationQueryParams | null
): ProposalDetailsLocationQueryParams | null => {
  return isNull(currentQueryParams)
    ? currentQueryParams
    : {
        ...currentQueryParams,
        page: currentQueryParams.page + 1,
      };
};

type NextProposal = {
  nextProposalId: string | null;
  shouldFetchNextPage: boolean;
};

const FETCH_OFFSET = 2;

// TODO: remove proposals.length check
export const findNextProposalId = (
  currentPageProposals: ListProposal[],
  nextPageProposals: ListProposal[],
  currentProposalId: string
): NextProposal => {
  const proposalIndex = currentPageProposals.findIndex(
    ({ proposalId }) => proposalId === currentProposalId
  );
  const isLastElement = currentPageProposals.length - 1 === proposalIndex;

  const nextProposal = isLastElement
    ? nextPageProposals.at(0)
    : currentPageProposals.at(proposalIndex + 1);
  const shouldFetchNextPage = currentPageProposals.length
    ? currentPageProposals.length - (proposalIndex + 1) < FETCH_OFFSET
    : false;

  return {
    nextProposalId: nextProposal?.proposalId ?? null,
    shouldFetchNextPage,
  };
};

export const toLocaleNumber = (
  value: number,
  options?: Intl.NumberFormatOptions
): string => {
  return formatters
    .numberFormatter({ maximumFractionDigits: 20, ...options })
    .format(value);
};

export const toCurrency = (
  value: number,
  options?: Intl.NumberFormatOptions
): string => {
  return formatters
    .currencyFormatter({ maximumFractionDigits: 20, ...options })
    .format(value);
};

export const toPercent = (
  value: number,
  options?: Intl.NumberFormatOptions
): string => {
  return formatters
    .percentFormatter({ maximumFractionDigits: 2, ...options })
    .format(value / 100);
};
