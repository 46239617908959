import React, { useEffect, useMemo } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";

import useClientDashboard from "@fartherfinance/frontend/api/Dashboard/hooks/useClientDashboard";
import useClientChecklist from "@fartherfinance/frontend/api/Entity/hooks/useClientChecklist";
import useAllPortfolios from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useAllPortfolios";

import { MCReduxAction, State } from "../../../store";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

import Dashboard, { ExternalAccountLink } from "./dashboard";

export default function DashboardDataFetch(): JSX.Element {
  const { clientId, isAdvisor } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
    isAdvisor: state.main_Reducer.user.isAdvisor,
  }));

  const history = useHistory();

  const dispatch = useDispatch<Dispatch<MCReduxAction>>();

  const {
    enableCustomPerformanceGroupsV2,
    enabledAltsWaitlistBanner,
    enablePerformanceGroups,
    showStalePlaid,
    showAdvisorsStalePlaid,
  } = useFlags();

  const auth = useRequestAuth();
  const dashboard = useClientDashboard(clientId, auth);
  const checklist = useClientChecklist(clientId, auth);
  const allPortfolios = useAllPortfolios(clientId, auth);

  const hasFAMPortfolios = useMemo((): boolean => {
    if (allPortfolios.data === undefined) {
      return false;
    }

    return allPortfolios.data.filter((p) => p.set === "FAM").length >= 1;
  }, [allPortfolios.data]);

  const institutionsToRelink: ExternalAccountLink[] = (
    dashboard?.data?.externalAccountDetails.links ?? []
  ).filter((link) => {
    return link.status === "Error" || link.status === "Expired";
  });

  const anAccountIsOpen = useMemo(() => {
    if (dashboard.isLoading || dashboard.hasError) {
      return false;
    }

    return dashboard.data.accountDetails.some(
      (acct) => acct.accountDetails.operationalState === "Open"
    );
  }, [dashboard]);

  useEffect(() => {
    if (!dashboard.data || !clientId) {
      return;
    }

    const has_completed_account_selections =
      dashboard.data.accountDetails.length > 0;

    dispatch({
      type: "SET_ADVISOR_DATA",
      payload: {
        advisor: { ...dashboard.data.advisor },
        has_completed_account_selections: has_completed_account_selections,
        an_account_is_open: anAccountIsOpen,
      },
    });
  }, [dashboard, clientId, dispatch, anAccountIsOpen]);

  const showAltInvestmentBanner = enabledAltsWaitlistBanner && hasFAMPortfolios;

  const navToPlaidRelinkPage = () => {
    if (clientId) {
      history.push({
        ...history.location,
        pathname: `/Client/${clientId}/Relink`,
      });
    }
  };

  const navToDocumentsPage = () => {
    if (clientId) {
      history.push({
        ...history.location,
        pathname: `/Client/${clientId}/Documents`,
      });
    }
  };

  return (
    <Dashboard
      institutionsToRelink={institutionsToRelink}
      showStalePlaid={isAdvisor ? showAdvisorsStalePlaid : showStalePlaid}
      showAltInvestmentBanner={showAltInvestmentBanner}
      navToPlaidRelinkPage={navToPlaidRelinkPage}
      navToDocumentsPage={navToDocumentsPage}
      enablePerformanceGroups={enablePerformanceGroups}
      checklistIsLoading={checklist.isLoading}
      accountsAreLoading={dashboard.isLoading}
      enableCustomPerformanceGroupsV2={enableCustomPerformanceGroupsV2}
    />
  );
}
