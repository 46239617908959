import React, { useMemo } from "react";

import { useParams } from "react-router-dom";

import useClientAccounts from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAccounts";
import {
  ClientId,
  Cusip,
  CustodianAccountId,
  FartherAccountId,
  Ticker,
} from "@fartherfinance/frontend/api/Types";

import {
  ACCOUNT_UNDER_10_000,
  TARGET_WEIGHT_TOOLTIP_TEXT,
  WEIGHT_DIFFERENCE_TOOLTIP_TEXT,
} from "../../constants";
import { useCreateEditTaxBudgetContext } from "../../reducer/Context";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import TooltipBasic from "@src/sharedComponents/Tooltip/TooltipBasic";
import toPercent from "@src/utils/toPercent";

import SecurityRow from "./SecurityRow";

import styles2 from "./HypotheticalHoldingsWeightsTable.module.css";
import styles from "../basicTable.module.css";

type AccountIdToCustodianId = Record<FartherAccountId, CustodianAccountId>;

interface CurrentSecurityWeight {
  weight: number;
}
interface SecurityWeight extends CurrentSecurityWeight {
  cusip: Cusip;
  ticker: Ticker;
  price: number;
  targetWeight: number;
  targetWeightDifference: number;
  totalMarketValue: number;
  totalQuantity: number;
}

type CusipToCurrentSecurityWeight = Record<Cusip, CurrentSecurityWeight>;

type CusipToSecurityWeight = Record<Cusip, SecurityWeight>;

interface SecurityRowData {
  cusip: Cusip;
  ticker: Ticker;
  currentWeight: string;
  targetWeight: string;
  withTaxBudgetWeight: string;
  withoutTaxBudgetWeight: string;
  difference: string;
}

interface Props {
  accountIds: FartherAccountId[];
}

const HypotheticalHoldingsWeightsTable: React.FC<Props> = ({ accountIds }) => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const { state } = useCreateEditTaxBudgetContext();

  const {
    data: { hypotheticalProposal },
  } = state;

  const clientAuth = useRequestAuth();
  const advisorReqAuth = useAdvisorRequestAuth();
  const advisorAuth = advisorReqAuth
    ? { ...advisorReqAuth, clientId: clientId }
    : null;
  const auth = clientAuth ? clientAuth : advisorAuth;

  const accounts = useClientAccounts(clientId, auth);

  const securityWeights: SecurityRowData[] = useMemo(() => {
    if (accounts.isLoading || accounts.hasError) {
      return [];
    }

    if (!hypotheticalProposal) {
      throw new Error("hypotheticalProposal should not be null");
    }

    const accountIdToCustodianId: AccountIdToCustodianId =
      accounts.data.fartherAccounts.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.accountId]: cur.accountDetails.custodianAccountNumber,
        };
      }, {});

    const withBudgetProposal = hypotheticalProposal.withBudgetProposals.find(
      (p) =>
        p.proposal.hypotheticalTradingGroup.accounts.some((tga) =>
          accountIds.some((a) => {
            if (!accountIdToCustodianId[a]) {
              throw new Error(
                `withBudgetProposal: accountId: ${a}, is not in accountIdToCustodianId lookup`
              );
            }

            return accountIdToCustodianId[a] === tga.info.custodianAccountId;
          })
        )
    );

    if (withBudgetProposal === undefined) {
      throw new Error(
        "Can't find proposal in withBudgetProposal based on selected accountId(s)"
      );
    }

    const withoutBudgetProposal =
      hypotheticalProposal.withoutBudgetProposals.find((p) =>
        p.proposal.hypotheticalTradingGroup.accounts.some((tga) =>
          accountIds.some((a) => {
            if (!accountIdToCustodianId[a]) {
              throw new Error(
                `withoutBudgetProposal: accountId: ${a}, is not in accountIdToCustodianId lookup`
              );
            }

            return accountIdToCustodianId[a] === tga.info.custodianAccountId;
          })
        )
      );

    if (withoutBudgetProposal === undefined) {
      throw new Error(
        "Can't find proposal in withoutBudgetProposal based on selected accountId(s)"
      );
    }

    const totalMarketValue =
      withoutBudgetProposal.aggregatedPostTradeDetails.total.totalMarketValue;

    const currentSecurityWeightLookup =
      withoutBudgetProposal.currentHoldingsDetails.holdings.reduce(
        (acc, cur) => {
          const cusipKey = cur.cusip.toLowerCase() as Cusip;
          return {
            ...acc,
            [cusipKey]: {
              weight: cur.marketValue / totalMarketValue,
            },
          };
        },
        {} as CusipToCurrentSecurityWeight
      );

    const withBudgetSecurityWeightLookup =
      withBudgetProposal.aggregatedPostTradeDetails.aggregatedItems.reduce(
        (acc, cur) => {
          const cusipKey = cur.cusip.toLowerCase() as Cusip;
          return {
            ...acc,
            [cusipKey]: {
              cusip: cur.cusip,
              ticker: cur.ticker,
              ...cur.subtotal,
            },
          };
        },
        {} as CusipToSecurityWeight
      );

    return withoutBudgetProposal.aggregatedPostTradeDetails.aggregatedItems.map(
      (sec) => {
        const cusipKey = sec.cusip.toLowerCase() as Cusip;

        const currentWeight =
          currentSecurityWeightLookup[cusipKey]?.weight ?? 0;

        const withTaxBudgetWeight =
          withBudgetSecurityWeightLookup[cusipKey]?.weight ?? 0;

        return {
          cusip: sec.cusip,
          ticker: sec.ticker,
          currentWeight: toPercent(currentWeight),
          targetWeight: toPercent(sec.subtotal.targetWeight / 100),
          withTaxBudgetWeight: toPercent(withTaxBudgetWeight / 100),
          withoutTaxBudgetWeight: toPercent(sec.subtotal.weight / 100),
          difference: toPercent(
            (withTaxBudgetWeight - sec.subtotal.weight) / 100
          ),
        };
      }
    );
  }, [hypotheticalProposal, accountIds, accounts]);

  const isAnAccountUnder10_000 = useMemo(() => {
    if (accounts.isLoading || accounts.hasError) {
      return false;
    }

    if (!hypotheticalProposal) {
      throw new Error("hypotheticalProposal should not be null");
    }

    const accountIdToCustodianId: AccountIdToCustodianId =
      accounts.data.fartherAccounts.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.accountId]: cur.accountDetails.custodianAccountNumber,
        };
      }, {});

    const withBudgetProposal = hypotheticalProposal.withBudgetProposals.find(
      (p) =>
        p.proposal.hypotheticalTradingGroup.accounts.some((tga) =>
          accountIds.some((a) => {
            if (!accountIdToCustodianId[a]) {
              throw new Error(
                `withBudgetProposal: accountId: ${a}, is not in accountIdToCustodianId lookup`
              );
            }

            return accountIdToCustodianId[a] === tga.info.custodianAccountId;
          })
        )
    );

    if (withBudgetProposal === undefined) {
      throw new Error(
        "Can't find proposal in withBudgetProposal based on selected accountId(s)"
      );
    }

    return withBudgetProposal.lowBalanceDisclaimerWarning;
  }, [hypotheticalProposal, accountIds, accounts]);

  return (
    <>
      <div className={styles2.heading}>
        <div className={styles2.header}>
          Holdings Weights: Deviation from Target Model Due to Tax Budget
        </div>

        {isAnAccountUnder10_000 && (
          <TooltipBasic
            className={styles.infoIcon}
            text={ACCOUNT_UNDER_10_000}
          />
        )}
      </div>

      <table className={styles.table}>
        <thead className={styles.thead}>
          <tr className={styles.headerRow}>
            <td
              className={toClassName(styles.headerCell, styles2.textAlignLeft)}
            >
              Holdings
            </td>

            <td
              className={toClassName(styles.headerCell, styles2.textAlignLeft)}
            >
              Current
            </td>

            <td className={styles.headerCell}>
              <div
                className={toClassName(styles.flexRow, styles2.textAlignLeft)}
              >
                <div>Target</div>

                <TooltipBasic
                  className={styles.infoIcon}
                  type={"secondary"}
                  text={TARGET_WEIGHT_TOOLTIP_TEXT}
                />
              </div>
            </td>

            <td
              className={toClassName(styles.headerCell, styles2.textAlignLeft)}
            >
              Without Tax Budget
            </td>

            <td
              className={toClassName(styles.headerCell, styles2.textAlignLeft)}
            >
              With Tax Budget
            </td>

            <td className={styles.headerCell}>
              <div
                className={toClassName(styles.flexRow, styles2.textAlignLeft)}
              >
                <div>Difference</div>

                <TooltipBasic
                  className={styles.infoIcon}
                  type={"secondary"}
                  text={WEIGHT_DIFFERENCE_TOOLTIP_TEXT}
                />
              </div>
            </td>
          </tr>
        </thead>

        <tbody>
          {securityWeights.map((sec) => {
            return (
              <SecurityRow
                key={sec.cusip}
                ticker={sec.ticker}
                current={sec.currentWeight}
                target={sec.targetWeight}
                withTaxBudget={sec.withTaxBudgetWeight}
                withoutTaxBudget={sec.withoutTaxBudgetWeight}
                difference={sec.difference}
              />
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default HypotheticalHoldingsWeightsTable;
