import { isNil } from "lodash";

import {
  FirstMonth,
  LastMonth,
  SplitMonth,
  UserFacingSchedule,
} from "@fartherfinance/frontend/api/CashCycle/requests/getCurrentTrigger";

import { captureException } from "@src/multiCustodian/services/tracking";

import { fundingDaysInMonth } from "./SetupRecurringTransfersModal/shared";

type FixedAmount = "fixed";
export const FIXED_AMOUNT_TYPE: FixedAmount = "fixed";

export const FIXED_METHOD_TYPE = "fixed_amount";

/* eslint-disable-next-line import/no-unused-modules */
export const FIRST_DAY_OF_THE_MONTH = "First day of the month";
/* eslint-disable-next-line import/no-unused-modules */
export const LAST_DAY_OF_THE_MONTH = "Last day of the month";
/* eslint-disable-next-line import/no-unused-modules */
export const FIRST_AND_FIFTEENTH_DAY_OF_THE_MONTH =
  "The 1st and 15th of the month";
/* eslint-disable-next-line import/no-unused-modules */
export const CUSTOM_DAY_OF_THE_MONTH = "Custom day of the month (1 - 28)";

export const dropdownOptions = [
  FIRST_DAY_OF_THE_MONTH,
  LAST_DAY_OF_THE_MONTH,
  FIRST_AND_FIFTEENTH_DAY_OF_THE_MONTH,
  CUSTOM_DAY_OF_THE_MONTH,
] as const;

export type ScheduleDateString = typeof dropdownOptions[number];

export const makeScheduleBody = (
  option: ScheduleDateString,
  startDate: string | null | undefined
): UserFacingSchedule => {
  switch (option) {
    case FIRST_DAY_OF_THE_MONTH:
      return { type: "system", value: "month_first" };
    case LAST_DAY_OF_THE_MONTH:
      return { type: "system", value: "month_last" };
    case FIRST_AND_FIFTEENTH_DAY_OF_THE_MONTH:
      return { type: "system", value: "month_split" };
    case CUSTOM_DAY_OF_THE_MONTH:
      if (isNil(startDate)) {
        const emsg = new Error(
          "'startDate' should not be null for Fixed method, Custom funding schedule."
        );
        captureException(emsg, {
          extra: {
            call: "finalizeSchedule",
            file: "FixedFundingMethodSetup.tsx",
            option: option,
            startDate: startDate,
          },
        });
        throw emsg;
      }
      return {
        type: "custom",
        value: `0 0 0 ${startDate} * ?`,
      };
  }
};

type fundingScheduleType =
  | FirstMonth["value"]
  | LastMonth["value"]
  | SplitMonth["value"]
  | "custom";

export const scheduleTypeToOptionString = (
  type: fundingScheduleType
): ScheduleDateString => {
  switch (type) {
    case "month_first":
      return FIRST_DAY_OF_THE_MONTH;
    case "month_last":
      return LAST_DAY_OF_THE_MONTH;
    case "month_split":
      return FIRST_AND_FIFTEENTH_DAY_OF_THE_MONTH;
    case "custom":
      return CUSTOM_DAY_OF_THE_MONTH;
    default:
      const emsg = new Error("Not a valid fundingScheduleType");
      captureException(emsg, {
        extra: {
          call: "scheduleTypeToString",
          file: "shared.ts",
          type: type,
        },
      });
      throw emsg;
  }
};

export type TransferDay = typeof fundingDaysInMonth[number];

const isTransferDayType = (day: string): day is TransferDay => {
  return fundingDaysInMonth.some((d) => d === day);
};

export const cronDayStringToDayNumberString = (
  customCronScheduleValue: string
): { label: TransferDay } | null => {
  const cronStringParts = customCronScheduleValue.split(" ");

  const day = cronStringParts[3];

  if (isTransferDayType(day)) {
    return { label: day };
  }

  return null;
};
