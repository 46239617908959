import React, { useState } from "react";

import { ArrowForward } from "@mui/icons-material";

import Button from "../MUI/Button/Button";
import TextInput from "@src/sharedComponents/TextInput/TextInput";
import normalizeEmail from "@src/utils/normalizeEmail";

import getDefaultEmail from "./getDefaultEmail";
import Layout from "./Layout";

import * as styles from "./StytchLogin.module.css";

interface Props {
  onPasswordContinueClick: (email: string) => Promise<void>;
  onSendOTPClick: (email: string) => Promise<void>;
  onResetPasswordClick: (email: string) => Promise<void>;
  onBack?: () => void;
}

const StytchLoginStartComponent: React.FC<Props> = ({
  onPasswordContinueClick,
  onSendOTPClick,
  onResetPasswordClick,
  onBack,
}) => {
  const [email, setEmail] = useState<string>(getDefaultEmail() ?? "");

  return (
    <Layout
      onBack={onBack}
      subtitle="Enter your email to log in or sign up."
      title="Welcome To Farther"
    >
      <TextInput
        label="Email"
        type={"email"}
        autoComplete="email"
        value={email}
        onChange={(e) => {
          e.preventDefault();
          setEmail(normalizeEmail(e.target.value));
        }}
      />

      <div className={styles.spacer} />

      <Button
        disabled={email === ""}
        text="Continue with Password"
        onClick={() => onPasswordContinueClick(email)}
        variant="contained"
        buttonType="primary"
        sx={{ width: "100%" }}
      />

      <div className={styles.spacer_sm} />

      <Button
        disabled={email === ""}
        text="Send One-Time code"
        onClick={() => onSendOTPClick(email)}
        variant="contained"
        buttonType="primary"
        sx={{ width: "100%" }}
      />

      <div className={styles.spacer_sm} />

      <Button
        disabled={email === ""}
        text={
          <>
            Forgot Password? <ArrowForward className={styles.arrow} />
          </>
        }
        onClick={() => onResetPasswordClick(email)}
        variant="text"
        buttonType="primary"
        sx={{
          width: "100%",
          textDecoration: "underline",
          color: email === "" ? "var(--textDisabled) !important" : "inherit",
        }}
      />
    </Layout>
  );
};

export default StytchLoginStartComponent;
