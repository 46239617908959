const agent = window.navigator.userAgent.toLowerCase();
export const browser =
  agent.indexOf("edge") > -1
    ? "Edge"
    : agent.indexOf("edg") > -1
    ? "Edge" // "Chromium based edge"
    : agent.indexOf("chrome") > -1 && "chrome" in window
    ? "Chrome"
    : agent.indexOf("trident") > -1
    ? "IE"
    : agent.indexOf("firefox") > -1
    ? "FireFox"
    : agent.indexOf("safari") > -1
    ? "Safari"
    : agent.indexOf("opr") > -1 && "opr" in window
    ? "Opera"
    : "Other";
