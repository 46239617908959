import React, { useEffect, useMemo, useState } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { isAxiosError } from "axios";
import isEmpty from "lodash/isEmpty";

import useGetProposalsV4 from "@fartherfinance/frontend/api/Rebalance/hooks/useGetProposalsV4";
import usePatchProposal, {
  PatchProposalPayload,
} from "@fartherfinance/frontend/api/Rebalance/hooks/usePatchProposal";
import usePostProposalSubmission from "@fartherfinance/frontend/api/Rebalance/hooks/usePostProposalSubmission";
import { GetProposalsQueryParams } from "@fartherfinance/frontend/api/Rebalance/requests/getProposals";
import { PaginationHeaders } from "@fartherfinance/frontend/api/utils/parsePaginationHeaders";

import Portal from "@src/multiCustodian/components/Portal/Portal";
import { usePersistedQueryParams } from "@src/multiCustodian/hooks/Advisor/usePersistedQueryParams";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import { Button, Pagination } from "@src/yellowstone/components";
import {
  PAGINATION_DEFAULT_PAGE_SIZE,
  PROPOSALS_ACTIONS_OUTLET_NODE_ID,
} from "@src/yellowstone/modules/shared";

import {
  FailedProposalsDialog,
  ProposalsTable,
  ProposalsToolbar,
} from "./components";
import { useStyles } from "./DailyProposals.styles";

interface DailyProposalsProps {
  isDailyProposalsView: boolean;
  isSubmittedProposalsView: boolean;
  isSkippedProposalsView: boolean;
  defaultQueryParams: GetProposalsQueryParams;
}

export const DailyProposals: React.FC<DailyProposalsProps> = ({
  isDailyProposalsView,
  isSubmittedProposalsView,
  isSkippedProposalsView,
  defaultQueryParams,
}) => {
  const { classes } = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isMutating, setIsMutating] = useState(false);
  const [showPortal, setShowPortal] = useState(false);

  useEffect(() => {
    // the target element (node with id = PROPOSALS_ACTIONS_OUTLET_NODE_ID) does not get rendered before this component (in certain states) so need to wait
    setTimeout(() => setShowPortal(true), 200);
  }, []);

  const [paginationState, setPaginationState] = useState<PaginationHeaders>();
  const [queryParams, setQueryParams] =
    usePersistedQueryParams<GetProposalsQueryParams>(defaultQueryParams);

  const statusNotification = useStatusNotification();
  const auth = useAdvisorRequestAuth();
  // NOTE: we do not want this request to fire if query params are empty
  const authOrNull = isEmpty(queryParams) ? null : auth;
  const proposalsQuery = useGetProposalsV4(queryParams, authOrNull, false, {
    cacheTime: 0,
  });
  const proposalMutation = usePatchProposal(queryParams, auth);
  const proposalsSubmissionMutation = usePostProposalSubmission(auth);

  const failedProposals = useMemo(
    () => proposalsSubmissionMutation.data?.failedProposals ?? [],
    [proposalsSubmissionMutation]
  );

  const {
    values: proposals = [],
    confirmedProposals = [],
    pagination,
  } = proposalsQuery.data ?? {};

  const isSubmitButtonVisible = isDailyProposalsView;
  const isSubmitButtonDisabled =
    confirmedProposals.length === 0 || proposalsQuery.isLoading || isMutating;

  const handleChangePaginationPage = (pageNumber: number): void => {
    setQueryParams({ ...queryParams, page: pageNumber });
  };

  const handleChangeQueryParams = (
    newQueryParams: GetProposalsQueryParams
  ): void => {
    setQueryParams({ ...queryParams, ...newQueryParams });
  };

  const handleProposalUpdate = async (
    payload: PatchProposalPayload
  ): Promise<void> => {
    try {
      setIsMutating(true);
      await proposalMutation(payload);
    } catch {
      statusNotification(
        `Failed to update proposal ${payload.proposalId}.`,
        "Error"
      );
    } finally {
      setIsMutating(false);
    }
  };

  const handleProposalsSubmission = async (): Promise<void> => {
    try {
      await proposalsSubmissionMutation.mutateAsync({ confirmedProposals });
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 409) {
        statusNotification(
          "Failed to submit proposals for confirmation.",
          "Error"
        );
      } else {
        statusNotification(
          "Something went wrong, please try again later.",
          "Error"
        );
      }
    }
  };

  useEffect(() => {
    if (failedProposals.length > 0) {
      setIsDialogOpen(true);
    }
  }, [failedProposals]);

  useEffect(() => {
    // NOTE: we have to persist pagination values locally as they become undefined inbetween request
    if (pagination) {
      setPaginationState(pagination);
    }
  }, [pagination]);

  return (
    <Stack className={classes.container}>
      {proposalsSubmissionMutation.isLoading && <LogoLoadingStill onTop />}

      <FailedProposalsDialog
        isDialogOpen={isDialogOpen}
        failedProposals={failedProposals}
        onClose={() => setIsDialogOpen(false)}
      />

      {showPortal && (
        <Portal targetNodeId={PROPOSALS_ACTIONS_OUTLET_NODE_ID}>
          <ProposalsToolbar
            isDailyProposalsView={isDailyProposalsView}
            isSkippedProposalsView={isSkippedProposalsView}
            queryParams={queryParams}
            onQueryParamChange={handleChangeQueryParams}
          />
        </Portal>
      )}

      <ProposalsTable
        proposals={proposals}
        proposalsSkeletonCount={PAGINATION_DEFAULT_PAGE_SIZE}
        queryParams={queryParams}
        isLoading={proposalsQuery.isLoading}
        isErrored={proposalsQuery.hasError}
        isSubmittedProposalsView={isSubmittedProposalsView}
        isSkippedProposalsView={isSkippedProposalsView}
        onProposalPatch={handleProposalUpdate}
      />

      {isSubmitButtonVisible && (
        <Box className={classes.footer}>
          <Button
            color="success"
            variant="contained"
            size="large"
            disabled={isSubmitButtonDisabled}
            disableTextTransform
            onClick={handleProposalsSubmission}
          >
            Submit Confirmed
          </Button>
        </Box>
      )}

      {paginationState && (
        <Pagination
          collectionName="proposals"
          pageSize={paginationState.pageSize}
          pageNumber={paginationState.currentPage}
          totalCount={paginationState.totalCount}
          onPageChange={handleChangePaginationPage}
        />
      )}
    </Stack>
  );
};
