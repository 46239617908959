import React from "react";

import { actions } from "../reducer/actions";
import { useAddCollaboratorContext } from "../reducer/Context";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";
import BorderBoxBody from "@src/sharedComponents/BorderBox/BorderBoxBody";
import HeaderWithSubHeader from "@src/sharedComponents/HeaderWithSubHeader/HeaderWithSubHeader";
import TextInput from "@src/sharedComponents/TextInput/TextInput";

import styles from "./CollaboratorNameAndEmail.module.css";

const CollaboratorNameAndEmail: React.FC = () => {
  const { state, dispatch } = useAddCollaboratorContext();

  const {
    data: { firstName, lastName, email },
  } = state;

  const handleFirstName = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch({ type: actions.SET_FIRST_NAME, payload: e.target.value });

  const handleLastName = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch({ type: actions.SET_LAST_NAME, payload: e.target.value });

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch({ type: actions.SET_EMAIL, payload: e.target.value });

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <HeaderWithSubHeader
          className={styles.headerWithSubHeader}
          header={"Add Collaborator"}
          subHeader={"Tell us who you want to collaborate with."}
        />

        <BorderBox className={styles.borderBox}>
          <BorderBoxBody className={styles.borderBoxBody} showTopBorder={false}>
            <div className={styles.topRow}>
              <TextInput
                label={<div className={styles.label}>First Name</div>}
                value={firstName}
                onChange={handleFirstName}
              />

              <TextInput
                label={<div className={styles.label}>Last Name</div>}
                value={lastName}
                onChange={handleLastName}
              />
            </div>

            <div className={styles.bottomRow}>
              <TextInput
                label={<div className={styles.label}>Email</div>}
                value={email}
                onChange={handleEmail}
              />
            </div>
          </BorderBoxBody>
        </BorderBox>
      </div>
    </div>
  );
};

export default CollaboratorNameAndEmail;
