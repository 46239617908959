import { isThisYear } from "date-fns";

import {
  Person,
  TaskManipulator,
  TaskManipulatorType,
} from "@fartherfinance/frontend/api/Tasks/Types";
import { AdvisorId } from "@fartherfinance/frontend/api/Types";

import { UNKNOWN_USER } from "@src/multiCustodian/pages/Advisor/common";

export const getDateFormat = (date: Date): string => {
  // NOTE: add year if the creation date was in year prior
  // NOTE: format example -> Wednesday, March 1st at 1:32pm
  return isThisYear(date)
    ? "eeee',' MMMM do 'at' K:mmaaa"
    : "eeee',' MMMM do yyyy 'at' K:mmaaa";
};

export const getDateFormatYearInsensitive = (): string => {
  return "eeee',' MMMM do 'at' K:mmaaa";
};

export const getPersonName = (
  person: Person,
  advisorId?: AdvisorId
): string => {
  // NOTE: if we are the task actor just show 'You' rather than my full name
  return person.id === advisorId
    ? "You"
    : person.name
    ? `${person.name.first} ${person.name.last}`
    : UNKNOWN_USER;
};

export const getManipulatorName = (
  manipulator: TaskManipulator,
  advisorId?: AdvisorId
): string => {
  return manipulator.type === TaskManipulatorType.enum.SYSTEM
    ? "System"
    : getPersonName(manipulator, advisorId);
};
