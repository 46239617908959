import React from "react";

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

import DocumentsExplorerMessage from "./DocumentsExplorerMessage";

interface DocumentsExplorerEmptyStateProps {
  currentDirectory: string;
  isDirectoryNonActionable: boolean;
  onCallToActionClick: () => void;
}

const DocumentsExplorerEmptyState: React.FC<
  DocumentsExplorerEmptyStateProps
> = ({ currentDirectory, isDirectoryNonActionable, onCallToActionClick }) => {
  return isDirectoryNonActionable ? (
    <DocumentsExplorerMessage
      title="No files"
      subtitle={`There are no files in ${currentDirectory}`}
    />
  ) : (
    <DocumentsExplorerMessage
      title="No files"
      subtitle={`You haven’t uploaded any files to ${currentDirectory}`}
      action={{
        label: "Upload",
        icon: <FileUploadOutlinedIcon />,
        onClick: onCallToActionClick,
      }}
    />
  );
};

export default DocumentsExplorerEmptyState;
