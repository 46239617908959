import React, { useMemo, useState } from "react";

import { Fade, Stack, Typography } from "@mui/material";
import orderBy from "lodash/orderBy";
import { useFormContext } from "react-hook-form";

import { AccountDetails } from "@fartherfinance/frontend/api/Dashboard/requests/getClientDashboard";
import { TaxBudget } from "@fartherfinance/frontend/api/InvestmentModel/Types";
import { TradingGroup } from "@fartherfinance/frontend/api/TradingGroups/requests/getTradingGroup";
import { formatFartherAccount } from "@fartherfinance/frontend/formatting/account";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import formatAmount from "@src/multiCustodian/pages/Dashboard/Dashboard_Components/DashboardForms/formatters/formatAmount";
import isValidLocaleNumber from "@src/multiCustodian/pages/Dashboard/Dashboard_Components/DashboardForms/validators/isValidLocaleNumber";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import Spacer from "@src/sharedComponents/Forms/Spacer";

import TaxBudgetsModal from "./TaxBudgetsModal/TaxBudgetsModal";
import { reformatAmount } from "./utils";

import styles from "./AdvisorLiquidation.module.css";

import type { FormData } from "../AdvisorLiquidation";

interface LiquidationRequestProps {
  accounts: AccountDetails[];
  tradingGroups: TradingGroup[];
  taxBudgets: TaxBudget[];
  onClose: () => void;
  onContinue: () => void;
}

const LiquidationRequest = ({
  accounts,
  tradingGroups,
  taxBudgets,
  onClose,
  onContinue,
}: LiquidationRequestProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { formState } = useFormContext<FormData>();

  const isTaxBudgetSetForCurrentYear = taxBudgets.some(
    (taxBudget) => taxBudget.year === new Date().getFullYear()
  );

  const accountOptions = useMemo(
    () =>
      orderBy(
        accounts,
        [
          (a) => a.accountDetails.displayName,
          (a) => a.accountDetails.custodianAccountNumber,
          (a) => a.accountId,
        ],
        ["asc", "asc", "asc"]
      ).map((account) => ({
        label: formatFartherAccount(account, false),
        value: account.accountId,
      })),
    [accounts]
  );

  const activeTradingGroups = useMemo(
    () =>
      tradingGroups.filter(
        (group) => group.isActive && group.accounts.length > 0
      ),
    [tradingGroups]
  );

  const handleMatchTradingGroup = (
    accountId: string
  ): TradingGroup | undefined => {
    return activeTradingGroups.find((tradingGroup) =>
      tradingGroup.accounts.some(
        (account) => account.virtualAccountId === accountId
      )
    );
  };

  const handleOnContinueClick = (): void => {
    if (isTaxBudgetSetForCurrentYear) {
      setIsModalOpen(true);
    } else {
      onContinue();
    }
  };

  return (
    <>
      {isModalOpen && (
        <TaxBudgetsModal
          onClose={() => setIsModalOpen(false)}
          onContinue={onContinue}
        />
      )}

      <Fade in>
        <Stack>
          <Typography className={styles.title}>
            Make a request to raise cash
          </Typography>
          <Typography className={styles.description}>
            Prior to making a request to raise cash, please ensure that the
            account has a model portfolio selected and trading is turned on. If
            this is a retirement account, please request an amount which is
            sufficient to cover any tax obligations.
          </Typography>

          <Spacer verticalSpacing="10px" />

          <FlexWrapper>
            <FormDropdownField
              name="account"
              label="Account"
              values={accountOptions}
              required="Please select an account"
              rules={{
                deps: ["amount"],
                validate: {
                  isModelPortfolioSelected: (account) => {
                    const tradingGroup = handleMatchTradingGroup(account.value);

                    return tradingGroup?.portfolioId
                      ? true
                      : "This account does not have a model portfolio selected.";
                  },
                  isTradingTurnedOn: (account) => {
                    const tradingGroup = handleMatchTradingGroup(account.value);

                    return tradingGroup?.isActive
                      ? true
                      : "This account does not have trading turned on.";
                  },
                },
              }}
            />
            <FormTextField
              name="amount"
              label="Amount"
              labelTooltip="If the selected account's model portfolio contains an allocation to cash, Farther will look to raise cash in excess of the model's target cash allocation."
              required="Please specify an amount to raise cash"
              startAdornment="$"
              inputMode="numeric"
              valueFormatterOnChange={formatAmount}
              valueFormatterOnBlur={formatAmount}
              rules={{
                validate: {
                  isValidNumber: (amount) => isValidLocaleNumber(amount, true),
                  isPositive: (amount) =>
                    Number(reformatAmount(amount)) > 0
                      ? true
                      : "Amount to raise cash must be greater than 0.",
                  isExceedingAccountBalance: (amount, formValues) => {
                    const account = accounts.find(
                      (account) =>
                        account.accountId === formValues?.account.value
                    );
                    const amountValue = Number(reformatAmount(amount));
                    // NOTE: we can safely fallback to 0 so if we don't have current account balance,
                    // we will always fail the validation
                    const accountBalance =
                      account?.accountDetails.accountBalance ?? 0;

                    return amountValue > accountBalance
                      ? "This amount is greater than the value of the account balance."
                      : true;
                  },
                },
              }}
            />
          </FlexWrapper>

          <Spacer verticalSpacing="40px" />

          <Stack direction="row" gap="16px" alignSelf="flex-end">
            <Button
              variant="outlined"
              buttonType="primary"
              text="Cancel"
              onClick={onClose}
            />
            <Button
              variant="contained"
              buttonType="primary"
              text="Continue"
              disabled={!formState.isValid}
              onClick={handleOnContinueClick}
            />
          </Stack>
        </Stack>
      </Fade>
    </>
  );
};

export default LiquidationRequest;
