import React from "react";

import { useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import useClientNameForId from "@src/multiCustodian/hooks/Advisor/useClientNameForId";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";

import styles from "./SecondaryTitle.module.css";

interface Props {
  title: string;
}

const SecondaryTitle: React.FC<Props> = ({ title }) => {
  const { clientId } = useParams<{ clientId: ClientId }>();

  const auth = useAdvisorRequestAuth();

  const clientName = useClientNameForId(clientId, auth);

  return (
    <div className={styles.title}>
      {title}
      <span className={styles.name}>{`${clientName?.fullName || ""}`}</span>
    </div>
  );
};

export default SecondaryTitle;
