import { AccessLevel } from "@fartherfinance/frontend/api/Sharing/Types";

import { AccessLevelForDisplay } from "../Types";
import { assertUnreachable } from "@src/utils/assertUnreachable";

export const getAccessLevel = (
  accessLevel: AccessLevelForDisplay
): AccessLevel => {
  switch (accessLevel) {
    case "All-Access":
      return "All";
    case "Limited Access":
      return "Limited";
    case "Revoke":
      return "None";
    default:
      return assertUnreachable(accessLevel);
  }
};
