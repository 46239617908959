import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom-v5-compat";

import {
  Autocomplete,
  AutocompleteOption,
} from "@src/yellowstone/components/Autocomplete/Autocomplete.component";
import { isStringDefinied } from "@src/yellowstone/modules/shared";
import {
  parseUsersSearchQueryParams,
  UserRecord,
  usersRepository,
  useUsersSearchMutation,
} from "@src/yellowstone/modules/users";

import type {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from "@mui/material/Autocomplete";

const autocompleteOptionMapper = (
  userRecord: UserRecord
): AutocompleteOption<UserRecord> => {
  return {
    label: `${userRecord.name.first} ${userRecord.name.last}`,
    value: userRecord,
  };
};

export const UserSearchBar: React.FC = () => {
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data = [], mutate, isLoading } = useUsersSearchMutation();

  const usersSearchOptions = useMemo(
    () => data.map(autocompleteOptionMapper),
    [data]
  );

  const handleSetSearchText = (
    value: string,
    reason: AutocompleteInputChangeReason
  ): void => {
    if (reason === "input") {
      setSearchValue(value);
    }
  };

  const handleSelectUser = (
    option: AutocompleteOption<UserRecord> | null,
    reason: AutocompleteChangeReason
  ): void => {
    if (option && reason === "selectOption") {
      navigate(`/Advisor/Client-Ops/Dashboard/Accounts/${option.value.id}`);
    }

    queryClient.removeQueries({
      queryKey: [usersRepository.getUsers.queryKey],
      exact: false,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getSearchResults = useCallback(
    debounce<(value: string) => void>((value) => {
      mutate(parseUsersSearchQueryParams(value));
    }, 700),
    []
  );

  useEffect(() => {
    if (isStringDefinied(searchValue)) {
      getSearchResults(searchValue);
    }
  }, [searchValue, getSearchResults]);

  return (
    <Autocomplete
      options={usersSearchOptions}
      isOptionEqualToValue={(option, value) =>
        option.value.id === value.value.id
      }
      isLoading={isLoading}
      onChange={(_, value, reason) => handleSelectUser(value, reason)}
      onInputChange={(_, value, reason) => handleSetSearchText(value, reason)}
      getOptionKey={(option) => option.value.id}
      TextFieldProps={{
        placeholder: "Search User",
        variant: "outlined",
      }}
    />
  );
};
