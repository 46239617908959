import React from "react";

import { Typography } from "@mui/material";

import { UNKNOWN_BALANCE } from "@fartherfinance/frontend/api/Accounts/utilities/accountUtil";
import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

const UnknownBalance = (): JSX.Element => {
  const {
    color: { $textSubtle },
  } = useTheme();

  return <Typography sx={{ color: $textSubtle }}>{UNKNOWN_BALANCE}</Typography>;
};

export default UnknownBalance;
