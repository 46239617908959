import React, { ReactElement, useMemo, useState } from "react";

import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { random } from "lodash";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import usePortfolioV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/usePortfolioV2";
import useTradingGroup from "@fartherfinance/frontend/api/TradingGroups/hooks/useTradingGroup";
import useUpdateTradingGroupV1_5 from "@fartherfinance/frontend/api/TradingGroups/hooks/useUpdateTradingGroupV1_5";
import {
  PortfolioId,
  TradingGroupId,
  TradingGroupsBody,
} from "@fartherfinance/frontend/api/Types";

import { TRADING_TOGGLE_DISABLED_MSG } from "../const";
import ToggleTradingSwitchModal from "../TradingGroupsTable/modals/ToggleTradingSwitchModal";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import Switch from "@src/sharedComponents/Switch/Switch";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";
import { State } from "@src/store";
import toUSD from "@src/utils/toUSD";

import { AccountWithPortfolio } from "./useAvailableAccountFromTradingGroups";

import styles from "../AccountsListSelection/AccountsListSelection.module.css";

interface Props {
  account: AccountWithPortfolio;
  currentPortfolioId: PortfolioId;
}

export default function AccountListDetails(props: Props): JSX.Element {
  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));

  const history = useHistory();

  const auth = useRequestAuth();

  const portfolio = usePortfolioV2(props.account.portfolioId, auth);

  const notify = useStatusNotification();

  return (
    <div className={styles.rowContentRightContainer}>
      {props.account.assignedTo === "ThisPortfolio" ? (
        <div className={styles.description}>
          <GroupTradingSwitch
            currentPortfolioId={props.currentPortfolioId}
            tradingGroupId={props.account.tradingGroupId}
            account={props.account}
          />
        </div>
      ) : props.account.assignedTo === "AnotherPortfolio" ? (
        <p className={styles.description}>
          Applied to{" "}
          {props.account.portfolioId !== null ? (
            <span
              className={styles.alreadyAppliedDiv}
              onClick={() => {
                if (clientId === null || props.account.portfolioId === null) {
                  notify("Unknown IDs", "Error");
                  return;
                }

                history.push(
                  `/Client/${clientId}/Investments/Portfolios/${props.account.portfolioId}/Accounts`
                );
              }}
            >
              <span className={styles.alreadyAppliedText}>
                {portfolio.data?.model.displayName ?? (
                  <Skeleton width={random(40, 60, false)} />
                )}
              </span>

              <ChevronRightRoundedIcon className={styles.chevronRight} />
            </span>
          ) : (
            "Unknown portfolio"
          )}
        </p>
      ) : (
        <p className={styles.description}>
          Balance{" "}
          <span className={styles.balanceText}>
            {props.account.lastReportedBalance.balance === null
              ? "N/A"
              : toUSD(props.account.lastReportedBalance.balance)}
          </span>
        </p>
      )}
    </div>
  );
}

interface GroupTradingProps {
  tradingGroupId: TradingGroupId | null;
  currentPortfolioId: PortfolioId;
  account: AccountWithPortfolio;
}

const GroupTradingSwitch = (props: GroupTradingProps): ReactElement => {
  const [showToggleTradingSwitchModal, setShowToggleTradingSwitchModal] =
    useState<boolean>(false);
  const [isMutating, setIsMutating] = useState<boolean>(false);
  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));

  const auth = useRequestAuth();

  const statusNotification = useStatusNotification();

  const tradingGroup = useTradingGroup(props.tradingGroupId, auth);

  const { updateTradingGroup } = useUpdateTradingGroupV1_5(clientId, auth);

  const accountsInTradingGroup = useMemo(() => {
    return [
      {
        accountId: props.account.accountId,
        displayName: props.account.displayName,
        custodianAccountNumber: props.account.custodianAccountNumber,
      },
    ];
  }, [props.account]);

  if (tradingGroup.isLoading) {
    return (
      <div className={styles.groupTradingSwitch}>
        <div>Trading</div>
        <Skeleton height={40} width={20} />
      </div>
    );
  }

  if (tradingGroup.hasError) {
    return (
      <div className={styles.groupTradingSwitch}>
        <div>Trading</div>
        <div>Error</div>
      </div>
    );
  }

  const toggleTradingGroupActive = async () => {
    if (tradingGroup.data.changesAllowed === false) {
      statusNotification("Changes are not allowed", "Error");
      return;
    }

    setIsMutating(true);

    const {
      displayName,
      accounts,
      portfolioId,
      isActive,
      directIndexing,
      taxLossHarvesting,
      equivalentSecuritiesEnabled,
    } = tradingGroup.data;

    const body: TradingGroupsBody = {
      displayName,
      accounts: accounts.map((tgAcct) => tgAcct.virtualAccountId),
      portfolioId: portfolioId ?? props.currentPortfolioId,
      isActive: !isActive,
      directIndexing,
      taxLossHarvesting,
      equivalentSecuritiesEnabled,
    };

    try {
      await updateTradingGroup({
        tradingGroupId: tradingGroup.data.groupId,
        body,
      });

      if (isActive) {
        statusNotification("Trading turned off", "Success");
      } else {
        statusNotification("Trading turned on", "Success");
      }
    } catch {
      statusNotification("Failed to toggle trading", "Error");
    } finally {
      setIsMutating(false);
      setShowToggleTradingSwitchModal(false);
    }
  };

  const isShared = props.account.sharedBy.length > 0;

  const isSharedText = `Trading is ${
    tradingGroup.data.isActive ? "enabled" : "disabled"
  }. Only the owner can make changes to the model portfolio settings.`;

  const disabled = tradingGroup.data.changesAllowed === false;

  return (
    <>
      {showToggleTradingSwitchModal && (
        <ToggleTradingSwitchModal
          onConfirm={toggleTradingGroupActive}
          accountsInTradingGroup={accountsInTradingGroup}
          isMutating={isMutating}
          closeModal={() => setShowToggleTradingSwitchModal(false)}
          toggleOnToOff={tradingGroup.data.isActive}
        />
      )}

      <div className={styles.groupTradingSwitch}>
        <div className={styles.groupTradingText}>
          <div
            className={toClassName({ [styles.groupTradingDisabled]: disabled })}
          >
            Trading
          </div>

          {isShared ? (
            <Tooltip tooltipText={isSharedText} placement={"top"}>
              <InfoOutlinedIcon className={styles.infoIconDisabled} />
            </Tooltip>
          ) : disabled ? (
            <Tooltip tooltipText={TRADING_TOGGLE_DISABLED_MSG} placement="top">
              <InfoOutlinedIcon className={styles.infoIconDisabled} />
            </Tooltip>
          ) : (
            <></>
          )}
        </div>

        <Tooltip
          disableHoverListener={!disabled && !isShared}
          tooltipText={isShared ? isSharedText : TRADING_TOGGLE_DISABLED_MSG}
          placement="left"
        >
          <span>
            <Switch
              on={tradingGroup.data.isActive}
              onClick={() => setShowToggleTradingSwitchModal(true)}
              disabled={disabled || isShared}
            />
          </span>
        </Tooltip>
      </div>
    </>
  );
};
