import React from "react";

import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import styles from "./PerformanceIndicatorArrow.module.css";

interface Props {
  status: "positive" | "negative" | "neutral";
  style?: React.CSSProperties;
}

export default function PerformanceIndicatorArrow({ status, style }: Props) {
  const {
    color: { $iconSuccess, $iconDanger, $iconSubtle },
  } = useTheme();

  return (
    <div
      className={styles.triangle}
      style={
        status === "positive"
          ? {
              backgroundColor: $iconSuccess,
              clipPath: "polygon(50% 10%, 0% 100%, 100% 100%)",
              ...style,
            }
          : status === "negative"
          ? {
              backgroundColor: $iconDanger,
              clipPath: "polygon(50% 100%, 0% 10%, 100% 10%)",
              ...style,
            }
          : {
              backgroundColor: $iconSubtle,
              clipPath: "polygon(50% 10%, 0% 100%, 100% 100%)",
              ...style,
            }
      }
    />
  );
}
