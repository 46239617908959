import React from "react";

import { useParams } from "react-router-dom";

import { SleeveId } from "@fartherfinance/frontend/api/Types";

import Portfolios from "../../../Portfolios";
import SleeveDetailsTable from "@src/multiCustodian/components/Sleeves/SleeveDetailsTable/SleeveDetailsTable";
import SleeveDrawer from "@src/multiCustodian/components/Sleeves/SleeveDrawer/SleeveDrawer";
import SleeveHistoricPerformanceTable from "@src/multiCustodian/components/Sleeves/SleeveHistoricPerformanceTable/SleeveHistoricPerformanceTable";
import SleeveAnalysisAssetClassCategoryTable from "@src/multiCustodian/components/Sleeves/SleeveHoldingsTable/SleeveHoldingsTable";

export default function SleeveDetails(): JSX.Element {
  const { sleeveId } = useParams<{
    sleeveId: SleeveId;
  }>();

  return (
    <Portfolios>
      <SleeveDrawer
        curTab="Sleeve Details"
        sleeveId={sleeveId}
        isInClientPortal={true}
      >
        <SleeveDetailsTable isInClientPortal={true} sleeveId={sleeveId} />

        <SleeveHistoricPerformanceTable
          isInClientPortal={true}
          sleeveId={sleeveId}
        />

        <SleeveAnalysisAssetClassCategoryTable
          isInClientPortal={true}
          sleeveId={sleeveId}
        />
      </SleeveDrawer>
    </Portfolios>
  );
}
