import React, { useState } from "react";

import useGetProjectionsV4 from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Projections/useGetProjectionsV4";
import useMatchPerformanceGroupV4 from "@fartherfinance/frontend/api/PerformanceGroups/hooks/useMatchPerformanceGroupV4";
import { ClientId, FartherAccountId } from "@fartherfinance/frontend/api/Types";

import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import { Disclaimer } from "@src/multiCustodian/pages/Dashboard/Performance_Groups/PG_hardcoded_exports";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import TwoTextItemToggle from "@src/sharedComponents/Toggle/TwoTextItemToggle";

import ProjectionsLegendForSpecificAccount from "./Projections/ProjectionsLegendForSpecificAccount";
import ProjectionsTabV4 from "./Projections/ProjectionsTabV4";
import ScenariosTableV4 from "./Projections/ScenariosTableV4";

import styles from "./Projections.module.css";

export const noMonteCarloWarning =
  "Projections are unavailable due to the presence of unsupported securities.";

interface Props {
  clientId: ClientId;
  accountId: FartherAccountId;
}

const ProjectionsV4 = ({ clientId, accountId }: Props): JSX.Element => {
  const [curTab, setCurTab] = useState<"Simulations" | "Scenarios">(
    "Simulations"
  );

  const auth = useRequestAuth();
  const performanceGroupId = useMatchPerformanceGroupV4(accountId, auth);

  const groupProjections = useGetProjectionsV4(
    performanceGroupId.data ?? null,
    auth
  );

  if (performanceGroupId.data === null || performanceGroupId.hasError) {
    return (
      <div className={styles.container}>
        <div className={styles.heading}>Account Projections</div>

        <div className={styles.errorDiv}>Projections data unavailable</div>

        <Spacer verticalSpacing="32px" />

        <Disclaimer />
      </div>
    );
  }

  const securitiesRemoved =
    groupProjections.data !== undefined &&
    groupProjections.data.securitiesRemoved !== null &&
    groupProjections.data.securitiesRemoved.length > 0;

  if (securitiesRemoved) {
    return (
      <div className={styles.container}>
        <div className={styles.heading}>Account Projections</div>

        <div className={styles.errorDiv}>{noMonteCarloWarning}</div>

        <Spacer verticalSpacing="32px" />

        <Disclaimer />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.heading}>Account Projections</div>
      <div className={styles.header}>
        <div className={styles.toggleButtonDiv}>
          <TwoTextItemToggle
            onChange={(val: "Left" | "Right") =>
              setCurTab(val === "Left" ? "Simulations" : "Scenarios")
            }
            leftOption="Simulations"
            rightOption="Scenarios"
            value={curTab === "Simulations" ? "Left" : "Right"}
          />
        </div>

        {curTab === "Simulations" && <ProjectionsLegendForSpecificAccount />}
      </div>

      {curTab === "Simulations" && (
        <ProjectionsTabV4
          clientId={clientId}
          groupId={performanceGroupId.data ?? null}
          graphHeight={300}
          displayContentSideBySide={false}
        />
      )}

      {curTab === "Scenarios" && (
        <ScenariosTableV4 groupId={performanceGroupId.data ?? null} />
      )}

      <Spacer verticalSpacing="32px" />

      <Disclaimer />
    </div>
  );
};

export default ProjectionsV4;
