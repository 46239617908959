import React, { useState } from "react";

import { useSelector } from "react-redux";

import useClientAccounts from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAccounts";
import useCreateAcatsTransfer from "@fartherfinance/frontend/api/Transfer/hooks/useCreateAcatsTransfer";
import useGetAcatsInstitutions from "@fartherfinance/frontend/api/Transfer/hooks/useGetAcatsInstitutions";
import { CreateAcatsData } from "@fartherfinance/frontend/api/Transfer/requests/createAcatsTransfer";
import { AccountType } from "@fartherfinance/frontend/api/Transfer/requests/Types";
import { FartherAccountId } from "@fartherfinance/frontend/api/Types";

import LogoLoadingStill from "../../../../../../sharedComponents/LogoLoadingStill/LogoLoadingStill";
import { State } from "../../../../../../store";
import ButtonPrimary from "../../../../../components/MUI/Button/Button";
import {
  AcatsInstitutionInfo,
  IraType,
  SetScreenAndTransferType,
  TransferScreen,
} from "../../Types";
import { getDateTimeString } from "../BankAccount/utils/getDateTimeString";
import execute_statusNotification_ts from "@src/multiCustodian/components/GlobalStatusNotification/notification_ts";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import { captureException } from "@src/multiCustodian/services/tracking";
import useThemeFragment from "@src/multiCustodian/theme/useThemeFragment";

import "./TransferInitiated.css";

type Props = (
  | {
      transferType: "roll over";
      iraType: IraType;
    }
  | { transferType: "transfer" }
) & {
  screen: TransferScreen["screen"];
  acatsInstitution: AcatsInstitutionInfo;
  acatsAccountType: AccountType;
  accountHolderName: string;
  accountNumber: string;
  toAccountId: FartherAccountId;
  uploadFilename: string | null;
  statement: File | null;
  setCurScreen: SetScreenAndTransferType;
};

const TransferInitiated = (props: Props): JSX.Element => {
  const { small_x_icon } = useSelector((state: State) => ({
    small_x_icon: state.main_Reducer.brand.current.images.small_x_icon,
  }));

  const { clientId, jwt } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
    jwt: state.main_Reducer.farther_jwt,
  }));

  const t = useThemeFragment("Farther");

  const auth = useRequestAuth();

  const createAcatsTransfer = useCreateAcatsTransfer(auth);

  const callCreateAcatsTransfer = async (
    acatsTransferDataInfo: CreateAcatsData,
    statement: File | undefined
  ) => {
    return await createAcatsTransfer({
      acatsTransferDataInfo,
      statement,
    });
  };

  const accounts = useClientAccounts(clientId, auth);
  const acatsInstitutions = useGetAcatsInstitutions(auth);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [eChecked, setEChecked] = useState<boolean>(false);
  const [buttonStyleBad, setButtonStyleBad] = useState<boolean>(false);

  const toggleCheck = () => {
    setEChecked(!eChecked);
    setButtonStyleBad(eChecked);
  };

  const onConfirm = () => {
    if (eChecked) {
      executeTransfer();
    }
  };

  if (acatsInstitutions.isLoading || accounts.isLoading) {
    return (
      <div className="Dashboard_Acats_Initiated_page__finished_centering_div">
        <LogoLoadingStill />
      </div>
    );
  }

  if (acatsInstitutions.hasError) {
    return (
      <div className="Dashboard_Acats_Initiated_page__finished_centering_div">
        Error getting list of institutions
      </div>
    );
  }

  if (accounts.hasError) {
    return (
      <div className="Dashboard_Acats_Initiated_page__finished_centering_div">
        Error getting list of accounts
      </div>
    );
  }

  const acatsInstitution = acatsInstitutions.data.institutions.find(
    (e) =>
      e.institutionCode ===
      (props.acatsInstitution.type === "code"
        ? props.acatsInstitution.acatsInstitutionCode
        : "")
  );

  const account = accounts.data.fartherAccounts.find(
    (e) => e.accountId === props.toAccountId
  );

  const executeTransfer = async () => {
    if (acatsInstitution === undefined) {
      return;
    }

    setIsLoading(true);

    if (clientId === null || jwt === null) {
      const errorMessage =
        "[AcatsInitiated.tsx], executeTransfer: something is null";

      captureException(new Error(errorMessage), {
        extra: {
          clientId: clientId ?? "null",
          jwt: jwt ?? "null",
        },
      });

      console.error(errorMessage, clientId, jwt);

      throw new Error(errorMessage);
    }

    const acatsTransferDataInfo: CreateAcatsData = {
      type: props.acatsAccountType,
      institutionId: acatsInstitution.institutionId,
      accountNumber: props.accountNumber,
      holderName: props.accountHolderName,
      accountId: props.toAccountId,
    };

    try {
      await callCreateAcatsTransfer(
        acatsTransferDataInfo,
        props.statement ?? undefined
      );

      setIsLoading(false);

      execute_statusNotification_ts(
        "ACATS transfer initiated.",
        "success",
        small_x_icon,
        "/src/assets/svg/check_green.svg"
      );

      props.setCurScreen({ screen: "transferType" });
    } catch (_e) {
      setIsLoading(false);

      execute_statusNotification_ts(
        "Failed to initiate ACATS transfer.",
        "error",
        small_x_icon,
        "/src/assets/svg/!.svg"
      );
    }
  };

  if (isLoading) {
    return (
      <div className="Dashboard_Acats_Initiated_page__finished_centering_div">
        <LogoLoadingStill />
      </div>
    );
  }

  return (
    <div className="Dashboard_Acats_Initiated_page__container">
      <div className="Dashboard_Acats_Initiated_page__content_container">
        <p className="Dashboard_Acats_Initiated_page__title">
          {props.transferType === "transfer"
            ? "Confirm transfer"
            : "Confirm rollover"}
        </p>

        <p className="Dashboard_Acats_Initiated_page__heading_summary">
          {props.transferType === "transfer"
            ? "Lastly, once you confirm the transfer, here's what you should expect."
            : "Lastly, once you confirm the rollover, here's what you should expect."}
        </p>

        <div className="Dashboard_Acats_Initiated_page__checklist">
          <div className="Dashboard_Acats_Initiated_page__flex_row_div">
            <div className="Dashboard_Acats_Initiated_page__orange_number">
              1
            </div>
            <p className="Dashboard_Acats_Initiated_page__text_2">
              We'll instruct {acatsInstitution?.displayName ?? "Unknown bank"}{" "}
              to begin the {props.transferType} process.
            </p>
          </div>
          <div className="Dashboard_Acats_Initiated_page__flex_row_div">
            <div className="Dashboard_Acats_Initiated_page__orange_number">
              2
            </div>
            <p className="Dashboard_Acats_Initiated_page__text_2">
              {acatsInstitution?.displayName ?? "Unknown bank"} will
              {props.transferType} your account to {t("companyName")} in 3-6
              business days.
            </p>
          </div>
          <div className="Dashboard_Acats_Initiated_page__flex_row_div">
            <div className="Dashboard_Acats_Initiated_page__orange_number">
              3
            </div>
            <p className="Dashboard_Acats_Initiated_page__text_2">
              We'll invest your account's funds into your {t("companyName")}{" "}
              portfolio.
            </p>
          </div>
        </div>

        <div className="Dashboard_Acats_Initiated_page__negative_spacing"></div>

        <div className="Dashboard_Acats_Initiated_page__flex_row_div_2">
          <img
            className="Dashboard_Acats_Initiated_page__img_icon"
            src="/src/assets/svg/arrow_left_icon.svg"
          ></img>
          <p className="Dashboard_Acats_Initiated_page__text_3_gray">
            {props.transferType === "transfer"
              ? "Transfer from"
              : "Roll over from"}
          </p>
        </div>

        <p className="Dashboard_Acats_Initiated_page__heading">
          {props.acatsInstitution.type === "code"
            ? acatsInstitution?.displayName
            : props.acatsInstitution.acatsInstitutionName}
          {" - "}
          {props.transferType === "transfer"
            ? `Investment Account (${props.accountNumber})`
            : `${props.iraType} (${props.accountNumber})`}
        </p>

        <div className="Dashboard_Acats_Initiated_page__flex_row_div_2">
          <img
            className="Dashboard_Acats_Initiated_page__img_icon"
            src="/src/assets/svg/arrow_right_icon.svg"
          ></img>
          <p className="Dashboard_Acats_Initiated_page__text_3_gray">
            {props.transferType === "transfer" ? "Transfer to" : "Roll over to"}
          </p>
        </div>

        <p className="Dashboard_Acats_Initiated_page__heading">
          {`${t("companyName")} - ` +
            (account?.accountDetails.displayName === "Long-term Savings"
              ? "Long-term Growth"
              : account?.accountDetails.displayName ?? "Unknown account")}
        </p>

        {props.uploadFilename && (
          <React.Fragment>
            <div className="Dashboard_Acats_Initiated_page__flex_row_div_2">
              <img
                className="Dashboard_Acats_Initiated_page__img_icon"
                src="/src/assets/svg/doc_text_icon.svg"
              ></img>
              <p className="Dashboard_Acats_Initiated_page__text_3_gray">
                Document file
              </p>
            </div>

            <p className="Dashboard_Acats_Initiated_page__heading">
              {props.uploadFilename ? props.uploadFilename : "none"}
            </p>
          </React.Fragment>
        )}

        <div className="Dashboard_Acats_Initiated_page__flex_row_div_2">
          <img
            className="Dashboard_Acats_Initiated_page__img_icon"
            src="/src/assets/svg/cal_icon.svg"
          ></img>
          <p className="Dashboard_Acats_Initiated_page__text_3_gray">
            Transfer date
          </p>
        </div>

        <p className="Dashboard_Acats_Initiated_page__amount_heading">
          {getDateTimeString()}
        </p>

        <div className="Dashboard_Acats_Initiated_page__flex_row_div_3">
          {eChecked && (
            <div
              className="Dashboard_Acats_pages__checkbox_yellow"
              onClick={toggleCheck}
            >
              <img
                src="/src/assets/svg/mini_checkmark.svg"
                className="Dashboard_Acats_pages__img"
              ></img>
            </div>
          )}

          {!eChecked && (
            <div
              className="Dashboard_Acats_pages__checkbox_gray"
              onClick={toggleCheck}
            ></div>
          )}

          <p className="Dashboard_Acats_pages__text_2">
            {`Sign ${
              props.transferType === "transfer" ? "transfer" : "rollover"
            } request`}
          </p>
        </div>

        <p className="Dashboard_Acats_pages__text_4_gray">
          {`By checking off the box you are authorizing with an e-signature,
                                    the legal equivalent of a written signature, for ${t(
                                      "companyName"
                                    )} to
                                    execute the ${
                                      props.transferType === "transfer"
                                        ? "transfer"
                                        : "rollover"
                                    }
                                    request.`}
        </p>

        <div className="Dashboard_Acats_Initiated_page__button_container">
          <div
            className="Dashboard_Acats_Verification_page__back_button_container"
            onClick={() => {
              const { setCurScreen: _, screen: _s, ...screenInfo } = props;

              if ("iraType" in screenInfo) {
                props.setCurScreen({
                  screen: "IRA/optionalInfo",
                  ...screenInfo,
                });
              } else {
                props.setCurScreen({
                  screen: "Investment/optionalInfo",
                  ...screenInfo,
                });
              }
            }}
          >
            <img src="/src/assets/svg/left_arrow.svg"></img>
            <div className="Dashboard_Acats_Verification_page__back_button">
              Back
            </div>
          </div>

          <ButtonPrimary
            disabled={buttonStyleBad}
            variant={"contained"}
            buttonType={"primary"}
            text={"Confirm"}
            onClick={() => onConfirm()}
          />
        </div>
      </div>
    </div>
  );
};

export default TransferInitiated;
