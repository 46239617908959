import React from "react";

import { useLocation } from "react-router-dom";
import { useWindowSize } from "usehooks-ts";

import MobileBrowser from "@src/multiCustodian/pages/MobileBrowser/MobileBrowser";

import { MobileHelloSignBaseUrl } from "./RouterPaths";

const MobileRedirect: React.FC = () => {
  const { width: windowWidth } = useWindowSize();
  const { pathname } = useLocation();

  if (
    windowWidth < 640 &&
    windowWidth >= 1 &&
    !pathname.startsWith(MobileHelloSignBaseUrl)
  ) {
    return <MobileBrowser />;
  }

  return <></>;
};

export default MobileRedirect;
