export const lockedDirectories = [
  {
    label: "Farther Records",
    value: "Farther%20Records",
  },
  {
    label: "Manually-Tracked Assets",
    value: "Manually-Tracked%20Assets",
  },
];

export const regularDirectories = [
  {
    label: "Business Ownership",
    value: "Business%20Ownership",
  },
  {
    label: "Education",
    value: "Education",
  },
  {
    label: "Employer Agreements",
    value: "Employer%20Agreements",
  },
  {
    label: "Estate Planning",
    value: "Estate%20Planning",
  },
  {
    label: "Insurance Policies",
    value: "Insurance%20Policies",
  },
  {
    label: "Leases",
    value: "Leases",
  },
  {
    label: "Other",
    value: "Other",
  },
  {
    label: "Property Ownership",
    value: "Property%20Ownership",
  },
  {
    label: "Statements",
    value: "Statements",
  },
  {
    label: "Taxes",
    value: "Taxes",
  },
  {
    label: "Trusts",
    value: "Trusts",
  },
  {
    label: "Vehicles",
    value: "Vehicles",
  },
];

export const fileUploadAcceptedExtensions = [
  ".png",
  ".jpg",
  ".jpeg",
  ".pdf",
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ".csv",
  ".odt",
  ".ods",
  ".ppt",
  ".pptx",
  ".txt",
  ".heic",
  ".odf",
  ".log",
];
