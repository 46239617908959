import React, { useRef, useState } from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";

import { FartherManagedAccount } from "@fartherfinance/frontend/api/Accounts/Types";
import { ClientId } from "@fartherfinance/frontend/api/Dashboard/hooks/useClientDashboard";

import copyToClipboard from "@src/multiCustodian/utils/copyToClipboard";
import Popover from "@src/sharedComponents/Popover/Popover";
import { State } from "@src/store";

import styles from "./ClientAdmin.module.css";

interface Props {
  account: FartherManagedAccount;
}

const ClientAccountRow = ({ account }: Props): JSX.Element => {
  const { clientId } = useParams<{ clientId: ClientId }>();

  const { smallXIcon } = useSelector((state: State) => ({
    smallXIcon: state.main_Reducer.brand.current.images.small_x_icon,
  }));

  const [showPopover, setShowPopover] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);

  const history = useHistory();

  const custodianAccountNumber =
    account.accountDetails.custodianAccountNumber ?? "- -";

  return (
    <div key={account.virtualAccountId} className={styles.tableRow}>
      <div className={styles.col1Cell}>
        {account.accountDetails.displayName}
      </div>

      <div className={styles.col2Cell}>
        <div
          className={styles.col2CellText}
          onClick={() =>
            copyToClipboard(
              custodianAccountNumber,
              smallXIcon,
              "Custodian Account Number"
            )
          }
        >
          {custodianAccountNumber}
        </div>

        <ContentCopyIcon
          onClick={() =>
            copyToClipboard(
              custodianAccountNumber,
              smallXIcon,
              "Custodian Account Number"
            )
          }
          classes={{
            root: styles.copyIcon,
          }}
        />
      </div>

      <div className={styles.col3Cell}>
        {account.accountDetails.accountType}
      </div>

      <div className={styles.col4Cell}>{account.accountDetails.custodian}</div>

      <div className={styles.col5Cell}>
        <div className={styles.col5CellInner}>
          <Popover
            open={showPopover}
            onClose={() => setShowPopover(false)}
            anchorOriginVertical={"bottom"}
            anchorOriginHorizontal={"right"}
            transformOriginHorizontal={"right"}
            anchor={anchorRef.current}
            popoverElement={
              <div
                className={styles.dotMenu}
                onClick={() => setShowPopover(true)}
                ref={anchorRef}
              >
                <MoreHorizIcon
                  classes={{
                    root: styles.dotsIcon,
                  }}
                />
              </div>
            }
          >
            <div className={styles.popoverMenu}>
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname: `/Advisor/Clients/${clientId}/Admin/Account/${account.virtualAccountId}/Rename`,
                  state: history.location.state,
                }}
              >
                <div
                  className={styles.rename}
                  onClick={() => setShowPopover(false)}
                >
                  Rename
                </div>
              </Link>

              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname: `/Advisor/Clients/${clientId}/Admin/Account/${account.virtualAccountId}/Archive`,
                  state: history.location.state,
                }}
              >
                <div
                  className={styles.delete}
                  onClick={() => setShowPopover(false)}
                >
                  Archive
                </div>
              </Link>

              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname: `/Advisor/Clients/${clientId}/Admin/Account/${account.virtualAccountId}/Delete`,
                  state: history.location.state,
                }}
              >
                <div
                  className={styles.delete}
                  onClick={() => setShowPopover(false)}
                >
                  Delete
                </div>
              </Link>
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default ClientAccountRow;
