import React, { useEffect, useMemo } from "react";

import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";

import useGetManuallyTrackedAccounts from "@fartherfinance/frontend/api/Accounts/hooks/useGetManuallyTrackedAccounts";
import { ManuallyTrackedAccount } from "@fartherfinance/frontend/api/Accounts/Types";
import useInvitations from "@fartherfinance/frontend/api/Sharing/hooks/useInvitations";
import { ReceivedInvitation } from "@fartherfinance/frontend/api/Sharing/Types";
import { ClientId } from "@fartherfinance/frontend/api/Types";
import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import { Accordions } from "../Types";
import { AccountsPath } from "@src/config/routing/RouterPaths";
import Accordion, {
  Row,
  specialtyIconWidth,
} from "@src/multiCustodian/components/Client/Accounts/AccountsAccordions/Accordion";
import FlowerBullet from "@src/multiCustodian/components/PerformanceGroups/Projections/FlowerBullet";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

const makeSharedSubDescription = (
  manualAsset: ManuallyTrackedAccount,
  invitations?: ReceivedInvitation[]
): string => {
  const invitation = invitations?.find((invitation) =>
    invitation.resources.some((r) => r.resourceId === manualAsset.id)
  );

  const name = invitation
    ? `${invitation.invitor.firstName} ${invitation.invitor.lastName}`
    : "Unknown";

  return `${manualAsset.assetType} - shared by ${name}`;
};

interface Props {
  clientId: ClientId;
  expanded: boolean;
  setExpanded: (newState: boolean) => void;
  openAccordions: Accordions[];
  isLoading: boolean;
  setLoaded: () => void;
}

const ManualAssetAccordion = (props: Props): JSX.Element => {
  const auth = useRequestAuth();

  const manualAssets = useGetManuallyTrackedAccounts(props.clientId, auth);

  const invitations = useInvitations(auth);

  const {
    color: { $icon, $iconSubtle },
  } = useTheme();

  const linkState = useMemo(
    () => ({ openAccordions: props.openAccordions }),
    [props.openAccordions]
  );

  const { setLoaded } = props;
  useEffect(() => {
    if (!manualAssets.isLoading) {
      setLoaded();
    }
  }, [manualAssets.isLoading, setLoaded]);

  if (manualAssets.hasError) {
    return <div>Error</div>;
  }

  if (manualAssets.isLoading || props.isLoading) {
    return <Skeleton width={"100%"} height={60} />;
  }

  return (
    <Accordion
      expanded={props.expanded}
      setExpanded={props.setExpanded}
      icon={<FlowerBullet colorHex={"#C3C1A8"} wH={specialtyIconWidth} />}
      title={"External Manually-Tracked"}
      total={manualAssets.data.reduce(
        (acc, cur) => acc + (cur.assetStatus === "Active" ? cur.assetValue : 0),
        0
      )}
      rows={manualAssets.data.map(
        (ma): Row => ({
          key: ma.id,
          description: ma.assetName,
          subDescription:
            ma.personId !== props.clientId
              ? makeSharedSubDescription(ma, invitations.data?.received)
              : ma.assetType,
          status: ma.assetStatus === "Sold" ? `(Sold)` : undefined,
          value: ma.assetValue,
          valueDisplay: ma.assetValue.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          }),
          icon:
            ma.personId !== props.clientId ? (
              <Tooltip tooltipText={"This is shared with you"}>
                <PeopleOutlineOutlinedIcon
                  style={{
                    width: specialtyIconWidth,
                  }}
                  sx={{
                    color: $iconSubtle,
                    "&:hover": {
                      color: $icon,
                    },
                  }}
                />
              </Tooltip>
            ) : undefined,
          linkTo: `/Client/${props.clientId}/${AccountsPath}/Manual/${ma.id}`,
        })
      )}
      linkState={linkState}
    />
  );
};

export default ManualAssetAccordion;
