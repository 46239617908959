import React, { useMemo } from "react";

import { actions } from "../reducer/actions";
import { useAddCollaboratorContext } from "../reducer/Context";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import ActionRow from "@src/sharedComponents/ActionRow/ActionRow";

const TypeReviewRow: React.FC = () => {
  const {
    state: {
      data: { collaboratorType, serviceProfessionalType },
    },
    dispatch,
  } = useAddCollaboratorContext();

  const handleOnClick = () =>
    dispatch({ type: actions.SET_PAGE_INDEX, payload: 1 });

  const start = useMemo(() => {
    if (collaboratorType) {
      return "Collaborator Type";
    } else if (serviceProfessionalType) {
      return "Service Pro";
    }
  }, [collaboratorType, serviceProfessionalType]);

  return (
    <ActionRow
      start={start}
      end={collaboratorType ? collaboratorType : serviceProfessionalType}
      action={
        <Button
          text="Edit"
          variant="text"
          buttonType="primary"
          onClick={handleOnClick}
        />
      }
    />
  );
};

export default TypeReviewRow;
