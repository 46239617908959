import React from "react";

import { useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import { SharingPath } from "@src/config/routing/RouterPaths";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";
import BorderBoxBody from "@src/sharedComponents/BorderBox/BorderBoxBody";
import BorderBoxHeader from "@src/sharedComponents/BorderBox/BorderBoxHeader";
import LinkButton from "@src/sharedComponents/LinkButton/LinkButton";

import styles from "./EmptyCollaboratorsTable.module.css";

const EmptyCollaboratorsTable = (): JSX.Element => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  return (
    <BorderBox>
      <BorderBoxHeader
        className={styles.borderBoxHeader}
        start={<div className={styles.headerText}>Collaborators</div>}
      />

      <BorderBoxBody className={styles.floatRight}>
        <LinkButton
          text="Get Started"
          to={`/Client/${clientId}/${SharingPath}/AddCollaborator`}
          variant="contained"
          buttonType="primary"
        />
      </BorderBoxBody>
    </BorderBox>
  );
};

export default EmptyCollaboratorsTable;
