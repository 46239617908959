import React from "react";

import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./ChangeFundingAccountModal.module.css";

const ChangeFundingAccountModalLoading = (): JSX.Element => {
  return (
    <div className={styles.paddingContainer}>
      <Skeleton width={"60%"} className={styles.title} />

      <Skeleton width={"85%"} className={styles.subTitle} />

      <Skeleton width={150} className={styles.header1} />

      <Skeleton width={"100%"} height={50} />

      <Skeleton width={"50%"} className={styles.text1} />

      <div className={styles.footer}>
        <div className={styles.modalButtonsDiv}>
          <Skeleton width={100} height={30} className={styles.marginRight} />

          <Skeleton width={100} height={30} />
        </div>
      </div>
    </div>
  );
};

export default ChangeFundingAccountModalLoading;
