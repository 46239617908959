import React, { useCallback } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import Card from "@src/multiCustodian/components/TaxBudgets/Card/Card";
import TitleAndDescription from "@src/multiCustodian/components/TaxBudgets/Hero/TitleAndDescription";
import { trackEvent } from "@src/multiCustodian/services/tracking";
import LinkButton from "@src/sharedComponents/LinkButton/LinkButton";

import styles from "./Empty.module.css";

const Empty: React.FC = () => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const { enableTaxBudgetHypotheticals } = useFlags();

  const onTrackOpenCreate = useCallback(() => {
    trackEvent({ name: "Advisor Open Create Tax-Budget" });
  }, []);

  const onTrackOpenHypothetical = useCallback(() => {
    trackEvent({ name: "Advisor Open Hypothetical-Tax-Budget" });
  }, []);

  return (
    <div className={styles.container}>
      <Card
        start={
          <TitleAndDescription
            title="Set a Tax Budget"
            titleStyle={styles.taxBudgetCardTitleStyle}
            description="Limit the capital gains taxes each year for your client"
          />
        }
        end={
          <LinkButton
            variant="contained"
            buttonType="primary"
            text="New Tax Budget"
            sx={{ width: "220px" }}
            to={`/Advisor/Clients/${clientId}/TaxBudgets/Create`}
            onClick={onTrackOpenCreate}
          />
        }
      />

      {enableTaxBudgetHypotheticals && (
        <Card
          start={
            <TitleAndDescription
              title="Test Hypothetical Scenarios"
              titleStyle={styles.taxBudgetCardTitleStyle}
              description={
                "Test different tax budgets and target model portfolios"
              }
            />
          }
          end={
            <LinkButton
              variant="outlined"
              buttonType="secondary"
              text="New Hypothetical Scenario"
              sx={{ width: "220px" }}
              to={`/Advisor/Clients/${clientId}/TaxBudgets/CreateHypothetical`}
              onClick={onTrackOpenHypothetical}
            />
          }
        />
      )}
    </div>
  );
};

export default Empty;
