import * as Sentry from "@sentry/react";
import { History } from "history";

import store from "../../store";

export default function logUserOut(reason: string, history?: History) {
  console.log("Logging user out, reason:", reason);
  const isAdvisor = store.getState().main_Reducer.user.isAdvisor ?? false;

  Sentry.addBreadcrumb({
    message: "Logging user out",
    data: { isAdvisor: isAdvisor, reason: reason },
  });

  const replace = history
    ? history.replace
    : window.location.replace.bind(window.location);

  Promise.resolve()
    .then(() => Sentry.flush(1000))
    .then(() => {
      const isAdvisorStr = isAdvisor ? "true" : "false";

      const queryParams = `?reason=${encodeURI(
        reason
      )}&isAdvisor=${isAdvisorStr}`;

      if (isAdvisor) {
        return replace(`/Advisor/Logout${queryParams}`);
      } else {
        return replace(`/Logout${queryParams}`);
      }
    })
    .catch((e) => {
      console.error(e);
    });
}
