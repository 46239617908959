import React, { useState } from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, Stack, Typography } from "@mui/material";
import { formatRelative, isEqual, parseISO } from "date-fns";

import { TaskComment as TaskCommentType } from "@fartherfinance/frontend/api/Tasks/Types";
import {
  AdvisorId,
  AttachmentId,
  TaskCommentId,
} from "@fartherfinance/frontend/api/Types";

import { useTaskingPermissions } from "../../../hooks";
import Avatar from "@src/multiCustodian/components/Avatar/Avatar";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import Attachment from "@src/sharedComponents/Attachment/Attachment";
import IconButton from "@src/sharedComponents/IconButton/IconButton";
import Menu, { MenuOption } from "@src/sharedComponents/Menu/Menu";
import Textarea from "@src/sharedComponents/Textarea/Textarea";

import { getPersonName } from "./utils";

import styles from "./TaskComment.module.css";

interface TaskCommentProps {
  comment: TaskCommentType;
  onCommentDelete: (commentId: TaskCommentId) => Promise<void>;
  onCommentUpdate: (commentId: TaskCommentId, content: string) => Promise<void>;
  onAttachmentDelete: (attachmentId: AttachmentId) => Promise<void>;
  advisorId?: AdvisorId;
}

export default function TaskComment({
  comment,
  onCommentDelete,
  onCommentUpdate,
  onAttachmentDelete,
  advisorId,
}: TaskCommentProps): JSX.Element {
  const [isEditing, setIsEditing] = useState(false);
  const [attachmentIdsLoading, setAttachmentIdsLoading] = useState<
    AttachmentId[]
  >([]);
  const [commentValue, setCommentValue] = useState(comment.content);
  const { commentPermissions, attachmentPermissions } = useTaskingPermissions();

  const isCommentEdited = !isEqual(
    parseISO(comment.createdTs),
    parseISO(comment.updatedTs)
  );
  const authorAvatarSource = comment.author.headshotPath ?? undefined;
  const authorInitials = comment.author.name
    ? `${comment.author.name.first[0]}${comment.author.name.last[0]}`
    : undefined;

  const handleCommentUpdate = (): void => {
    onCommentUpdate(comment.id, commentValue);
    setIsEditing(false);
  };

  const handleCommentDelete = (): void => {
    onCommentDelete(comment.id);
    setIsEditing(false);
  };

  const handleCommentValue = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    setCommentValue(event.target.value);
  };

  const handleDeleteAttachment = async (
    attachmentId: AttachmentId
  ): Promise<void> => {
    try {
      setAttachmentIdsLoading((attachmentIds) =>
        attachmentIds.concat(attachmentId)
      );
      await onAttachmentDelete(attachmentId);
    } finally {
      setAttachmentIdsLoading((attachmentIds) =>
        attachmentIds.filter((id) => id !== attachmentId)
      );
    }
  };

  const editOption: MenuOption = {
    label: "Edit Comment",
    onClick: () => setIsEditing(true),
  };
  const deleteOption: MenuOption = {
    label: "Delete Comment",
    className: styles.textWarning,
    onClick: handleCommentDelete,
  };
  const menuOptions: MenuOption[] = [
    ...(commentPermissions(comment).isAllowedToUpdate ? [editOption] : []),
    ...(commentPermissions(comment).isAllowedToDelete ? [deleteOption] : []),
  ];

  return (
    <Stack className={styles.container} direction="row" spacing={2.5}>
      <Avatar
        className={styles.avatar}
        imageSource={authorAvatarSource}
        initials={authorInitials}
      />
      <Stack className={styles.commentContainer}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box className={styles.commentMetadata}>
            <Typography className={styles.commentAuthor}>
              {getPersonName(comment.author, advisorId)}
            </Typography>
            <Typography className={styles.commentTimestamp}>
              {formatRelative(parseISO(comment.createdTs), new Date())}
            </Typography>
          </Box>
          {menuOptions.length > 0 && (
            <Menu options={menuOptions}>
              {({ handleOpenMenu }) => (
                <IconButton
                  iconClassName={styles.commentMenuIcon}
                  onClick={handleOpenMenu}
                  IconComponent={MoreHorizIcon}
                />
              )}
            </Menu>
          )}
        </Stack>

        {isEditing ? (
          <>
            <Textarea
              textareaClassName={toClassName(
                styles.commentContent,
                styles.textarea
              )}
              value={commentValue}
              onChange={handleCommentValue}
              maxRows={10}
            />
            <Stack
              className={styles.updateActions}
              direction="row"
              justifyContent="flex-end"
              spacing={1}
            >
              <Button
                variant="outlined"
                buttonType="primary"
                text="Cancel"
                onClick={() => setIsEditing(false)}
              />
              <Button
                variant="contained"
                buttonType="primary"
                text="Save"
                onClick={handleCommentUpdate}
              />
            </Stack>
          </>
        ) : (
          <>
            <Typography className={styles.commentContent}>
              {comment.content}
              &nbsp;
              {isCommentEdited && (
                <span className={styles.commentEdited}>(edited)</span>
              )}
            </Typography>

            {comment.attachments.length > 0 && (
              <Stack className={styles.commentAttachments} spacing={1}>
                {comment.attachments.map((attachment) => (
                  <Attachment
                    key={attachment.id}
                    url={attachment.previewUrl}
                    name={attachment.filename}
                    isLoading={attachmentIdsLoading.includes(attachment.id)}
                    onRemove={
                      attachmentPermissions(comment).isAllowedToDelete
                        ? () => handleDeleteAttachment(attachment.id)
                        : undefined
                    }
                  />
                ))}
              </Stack>
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
}
