export const PROPOSED_TRADES_LIST_HEADERS = [
  " ", // NOTE: first column does not have a header
  "Account",
  "Ticker",
  "Side",
  "Quantity",
  "Price",
  "Market Value",
] as const;

export const PROPOSED_TRADES_SUMMARY_HEADERS = [
  " ", // NOTE: first column does not have a header
  "Diff Tickers:",
  "Side Count:",
  "Quantity",
  "Sum (Quantity x Price):",
] as const;
