import React from "react";

import { Stack } from "@mui/material";

import useSMAAsset, {
  SMAAssetId,
} from "@fartherfinance/frontend/api/AltAssetsService/hooks/useSMAAsset";

import Drawer from "@src/multiCustodian/components/Drawer/Drawer";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";

import ApplySMAForm from "./ApplySMAForm/ApplySMAForm";
import ApplySMAFormLoading from "./ApplySMAForm/ApplySMAFormLoading";

import styles from "./ApplySMADrawer.module.css";

const APPLY_SMA_DRAWER_FOOTER_PORTAL_ID = "apply-sma-drawer-footer-portal-id";

interface Props {
  SMAId: SMAAssetId;
  onClose: () => void;
  cancel: () => void;
}

const ApplySMADrawer = (props: Props): JSX.Element => {
  const auth = useAdvisorRequestAuth();

  const currentSMA = useSMAAsset(props.SMAId, auth);

  if (currentSMA.isLoading) {
    return (
      <Drawer
        isDrawerOpen
        onClose={props.onClose}
        footer={
          <Stack direction={"row"} gap={"16px"} alignItems={"center"}>
            <ButtonPrimary
              buttonType="primary"
              variant="outlined"
              text="Cancel"
              onClick={props.cancel}
            />

            <ButtonPrimary
              buttonType="primary"
              variant="contained"
              text="Apply SMA"
              disabled={true}
              onClick={() => undefined}
            />
          </Stack>
        }
      >
        <ApplySMAFormLoading />
      </Drawer>
    );
  }

  if (currentSMA.hasError) {
    return <div className={styles.text}>Error getting SMA details</div>;
  }

  return (
    <Drawer
      isDrawerOpen
      onClose={props.onClose}
      footer={<div id={APPLY_SMA_DRAWER_FOOTER_PORTAL_ID} />}
    >
      <ApplySMAForm
        portalId={APPLY_SMA_DRAWER_FOOTER_PORTAL_ID}
        sma={currentSMA.data}
        cancel={props.cancel}
      />
    </Drawer>
  );
};

export default ApplySMADrawer;
