import React, { useState } from "react";

import Stack from "@mui/material/Stack";

import usePostCreateFolder from "@fartherfinance/frontend/api/CustodianDoc/hooks/usePostCreateFolder";

import { isFolderNameSafe } from "../utils";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import Modal from "@src/sharedComponents/Modal/Modal";
import TextInput from "@src/sharedComponents/TextInput/TextInput";

interface NewFolderModalProps {
  directories: string[];
  onClose: () => void;
}

const NewFolderModal: React.FC<NewFolderModalProps> = ({
  directories,
  onClose,
}) => {
  const [folderName, setFolderName] = useState("");

  const auth = useRequestAuth();
  const callPostCreateFolder = usePostCreateFolder(auth);
  const statusNotification = useStatusNotification();

  const isFolderNameValid = isFolderNameSafe(folderName);
  const isInputErrored = folderName.length > 0 ? !isFolderNameValid : false;
  const helperText = isInputErrored
    ? "Only letters, numbers, hyphens, periods and underscores. Whitespaces are allowed."
    : " ";

  const handleCreateNewFolder = async (): Promise<void> => {
    const encodedDirectories = directories.map((directory, index) => {
      if (index === 0) {
        return encodeURIComponent(directory);
      }
      return directory;
    });
    const folderPathName =
      encodedDirectories.join("/") + `/${folderName.trim()}/`;

    try {
      await callPostCreateFolder(folderPathName);
      statusNotification("Folder created", "Success");
    } catch {
      statusNotification("Failed to create folder", "Error");
    } finally {
      onClose();
    }
  };

  return (
    <Modal
      disableAutoFocus
      closeModal={onClose}
      modalStyle={{ width: "460px" }}
    >
      <FormH1>New Folder</FormH1>

      <Stack marginTop="32px">
        <TextInput
          autoFocus
          label="Title"
          value={folderName}
          onChange={(e) => setFolderName(e.target.value)}
          error={isInputErrored}
          helperText={helperText}
        />
      </Stack>

      <Stack
        direction="row"
        gap="20px"
        marginTop="20px"
        justifyContent="flex-end"
      >
        <Button
          variant="outlined"
          buttonType="primary"
          text="Cancel"
          onClick={onClose}
        />
        <Button
          variant="contained"
          buttonType="primary"
          text="Create"
          disabled={!isFolderNameValid}
          onClick={handleCreateNewFolder}
        />
      </Stack>
    </Modal>
  );
};

export default NewFolderModal;
