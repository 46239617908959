import { httpClientV1 } from "@src/yellowstone/services/network";

import type {
  GetUsersQueryParams,
  GetUsersResponse,
  GetUsersSearchQueryParams,
  GetUsersSearchResponse,
} from "./types";

const USERS_PATH = "/user";

const normalizeQueryParams = (
  queryParams: GetUsersSearchQueryParams
): GetUsersSearchQueryParams => {
  return {
    limit: 10,
    ...queryParams,
  };
};

const getUsersUrl = USERS_PATH;
const getUsers = (queryParams?: GetUsersQueryParams) =>
  httpClientV1.get<GetUsersResponse>(getUsersUrl, {
    params: queryParams,
  });

const getUsersSearchUrl = `${USERS_PATH}/search`;
const getUsersSearch = (queryParams: GetUsersSearchQueryParams) =>
  httpClientV1.get<GetUsersSearchResponse>(getUsersSearchUrl, {
    params: normalizeQueryParams(queryParams),
  });

export const usersRepository = {
  getUsers: {
    queryKey: getUsersUrl,
    queryCall: getUsers,
  },
  getUsersSearch: {
    mutationCall: getUsersSearch,
  },
};
