import React from "react";

import { NavLink } from "react-router-dom";

import { trackEvent } from "@src/multiCustodian/services/tracking";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import IsHiddenIcon from "./IsHiddenIcon";
import NavIcon from "./NavIcon";
import { NavItemDetails } from "./Types";

import styles from "./NavItem.module.css";

type Props = Omit<NavItemDetails, "isDisabled">;

const NavItemEnabled: React.FC<Props> = ({
  iconName,
  text,
  path,
  isHidden,
  mixpanelEvent,
  notifications,
}) => {
  const onTrackEvent = () => {
    if (mixpanelEvent) {
      trackEvent(mixpanelEvent);
    }
  };

  return (
    <div className={styles.container}>
      <NavLink
        className={(isActive) =>
          toClassName(styles.navItem, {
            [styles.navItemHover]: !isActive,
            [styles.navItemActive]: isActive,
          })
        }
        to={path}
        onClick={onTrackEvent}
      >
        <div className={styles.flexDiv}>
          {iconName && (
            <div className={styles.icon}>
              <NavIcon iconName={iconName} />
            </div>
          )}

          <div className={styles.text}>{text}</div>
        </div>

        <div className={styles.flexDiv}>
          {notifications}

          <IsHiddenIcon isHidden={isHidden} />
        </div>
      </NavLink>
    </div>
  );
};

export default NavItemEnabled;
