import React from "react";

const NotificationDot: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <rect width="14" height="14" fill="transparent" />
    <circle cx="6.9987" cy="6.99967" r="4.66667" fill="currentColor" />
  </svg>
);

export default NotificationDot;
