import React, { useRef, useState } from "react";

import DropdownPopoverButton from "@src/sharedComponents/DropdownPopoverButton/DropdownPopoverButton";
import Popover from "@src/sharedComponents/Popover/Popover";
import ScrollableCheckboxList, {
  CheckboxListConfig,
} from "@src/sharedComponents/ScrollableCheckboxList/ScrollableCheckboxList";

import styles from "./FilterPopoverSection.module.css";

interface FilterPopoverSectionProps {
  sectionLabel: string;
  filtersConfig: CheckboxListConfig<string>;
  setFiltersConfig: (config: CheckboxListConfig<string>) => void;
  selectedFiltersLabel: string;
}

const FilterPopoverSection: React.FC<FilterPopoverSectionProps> = ({
  sectionLabel,
  filtersConfig,
  setFiltersConfig,
  selectedFiltersLabel,
}) => {
  const [showDropdownPopover, setShowDropdownPopover] = useState(false);
  const dropdownPopoverAnchorRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className={styles.container}>
      <div className={styles.label}>{sectionLabel}</div>

      <Popover
        open={showDropdownPopover}
        onClose={() => setShowDropdownPopover(false)}
        anchor={dropdownPopoverAnchorRef.current}
        transformOriginVertical={-10}
        popoverElement={
          <div ref={dropdownPopoverAnchorRef}>
            <DropdownPopoverButton
              text={selectedFiltersLabel}
              showPopover={() => setShowDropdownPopover(true)}
              minWidth={240}
            />
          </div>
        }
      >
        <ScrollableCheckboxList
          haveSelectAll={true}
          checkboxesConfig={filtersConfig}
          setCheckboxesConfig={setFiltersConfig}
          minWidth={240}
          maxHeight={432}
        />
      </Popover>
    </div>
  );
};

export default FilterPopoverSection;
