import React from "react";

import CreateEditLayout from "../CreateEditLayout/CreateEditLayout";
import Disclosure from "../Disclosure/Disclosure";
import { useCreateEditTaxBudgetContext } from "../reducer/Context";

import styles from "./ReviewDisclosures.module.css";

const ReviewDisclosures: React.FC = () => {
  const { state } = useCreateEditTaxBudgetContext();

  const { flowType } = state;

  return (
    <CreateEditLayout>
      <div className={styles.container}>
        {flowType === "Hypothetical" && (
          <Disclosure title="Target Model Portfolios Are Not Affected by Hypothetical Scenarios">
            The hypothetical model portfolios will not go into effect as a
            result of this analysis—it's purely hypothetical. To assign a new
            model portfolio go to the Model Portfolios section.
          </Disclosure>
        )}

        <Disclosure title="Tax Budgets Are a Goal, Not a Guarantee">
          Users with precise tax requirements are encouraged to build in a
          buffer when setting a budget. While Farther will attempt to limit
          taxes to the limit specified in the tax budget, staying within the
          budget is not guaranteed. Actual taxes can differ for a variety of
          reasons, including but not limited to market volatility during trade
          execution and custodial data limitations pertaining to tax lots
          purchased on the same calendar day.
        </Disclosure>

        <Disclosure title="Tax Budgets Are Limited to Certain Transactions">
          Farther will attempt to limit the taxes incurred from buying and
          selling securities during rebalancing. However, this budget does not
          cover other sources of taxation, including but not limited to:
          <div className={styles.listContainer}>
            <ul className={styles.list}>
              <li>Dividends</li>
              <li>Cash Buyouts</li>
            </ul>
            <ul className={styles.list}>
              <li>Off-platform trades</li>
              <li>Required minimum distributions</li>
            </ul>
          </div>
        </Disclosure>

        <Disclosure title="Consult Your Tax Professional">
          By setting a "tax budget", Farther is not providing tax advice. Client
          should consult with their tax professional, tax attorney or CPA.
        </Disclosure>
      </div>
    </CreateEditLayout>
  );
};

export default ReviewDisclosures;
