import React, { useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, Stack, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";

import {
  GetProposalsQueryParams,
  ProposalsFilterQueryParams,
} from "@fartherfinance/frontend/api/Rebalance/requests/getProposals";

import { ProposalsFilterForm } from "../ProposalsFilterForm/ProposalsFilterForm.component";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import TextInput from "@src/sharedComponents/TextInput/TextInput";
import { PAGINATION_DEFAULT_PAGE_NUMBER } from "@src/yellowstone/modules/shared";

import { useStyles } from "./ProposalsToolbar.styles";

interface ProposalsToolbarProps {
  isDailyProposalsView: boolean;
  isSkippedProposalsView: boolean;
  queryParams: GetProposalsQueryParams;
  onQueryParamChange: (newQueryParams: GetProposalsQueryParams) => void;
}

export const ProposalsToolbar: React.FC<ProposalsToolbarProps> = ({
  isDailyProposalsView,
  isSkippedProposalsView,
  queryParams,
  onQueryParamChange,
}) => {
  const { classes, cx } = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState(
    queryParams.searchByPhrase ?? ""
  );

  const handleFilterFormSubmission = (
    newFilters: ProposalsFilterQueryParams
  ): void => {
    onQueryParamChange({ ...newFilters, page: PAGINATION_DEFAULT_PAGE_NUMBER });
  };

  const handleProposalsSearch = (): void => {
    onQueryParamChange({
      searchByPhrase: isEmpty(inputValue) ? undefined : inputValue,
      page: PAGINATION_DEFAULT_PAGE_NUMBER,
    });
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={1.5}>
          <TextInput
            className={classes.searchInput}
            placeholder="Search..."
            fullWidth={false}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.searchAdornment}
                >
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <Button
            sx={{ height: "30px" }}
            variant="contained"
            buttonType="primary"
            text="Search"
            onClick={handleProposalsSearch}
          />
        </Stack>
        <Stack
          className={classes.filtersContainer}
          direction="row"
          alignItems="center"
          onClick={() => setIsModalOpen(true)}
        >
          <Typography className={classes.filtersLabel}>Filters</Typography>
          <KeyboardArrowDownIcon
            className={cx({
              [classes.filtersIconRotate]: isModalOpen,
            })}
          />
        </Stack>
      </Stack>

      {isModalOpen && (
        <ProposalsFilterForm
          isDailyProposalsView={isDailyProposalsView}
          isSkippedProposalsView={isSkippedProposalsView}
          currentFilters={omit(queryParams, [
            "page",
            "pageSize",
            "sort",
            "searchByPhrase",
          ])}
          onSubmit={handleFilterFormSubmission}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};
