import { parse } from "date-fns";

import { ClientInvestorProfile } from "@fartherfinance/frontend/api/Entity/requests/getClientProfile";

import { Form } from "../Types";
import { dateFormat } from "@src/constants/dateFormat";

export const mapDefaultValues = (profile: ClientInvestorProfile): Form => {
  const {
    name: { prefix, suffix, first, last, middle },
    gender,
    maritalStatus,
    dateOfBirth,
  } = profile.personalDetails;

  const dobDate =
    dateOfBirth !== null ? parse(dateOfBirth, dateFormat, new Date()) : null;

  return {
    Title: objectOrNull(prefix),
    FirstName: first,
    LastName: last,
    MiddleName: middle,
    Suffix: objectOrNull(suffix),
    Gender: objectOrNull(gender),
    MaritalStatus: objectOrNull(maritalStatus),
    DateOfBirth: dobDate,
  };
};

const objectOrNull = <T extends string>(
  input: T | null
): { label: T } | null => {
  return input !== null ? { label: input } : null;
};
