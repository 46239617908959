import React, { useEffect, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";

import useGetTasks from "@fartherfinance/frontend/api/Tasks/hooks/useGetTasks";
import { GetTasksQueryParams } from "@fartherfinance/frontend/api/Tasks/requests/getTasks";
import { PaginationHeaders } from "@fartherfinance/frontend/api/utils/parsePaginationHeaders";

import { usePersistedQueryParams } from "@src/multiCustodian/hooks/Advisor/usePersistedQueryParams";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import { trackEvent } from "@src/multiCustodian/services/tracking";
import Spacer from "@src/sharedComponents/Forms/Spacer";

import AdvisorContainer from "./AdvisorContainer";
import AdvisorContentContainer from "./AdvisorContentContainer";
import { TASKS_DEFAULT_QUERY_PARAMS } from "./Tasks/common";
import TasksTable from "./Tasks/Components/TasksTable/TasksTable";
import TasksToolbar from "./Tasks/Components/TasksToolbar/TasksToolbar";
import { usePrefetchDictionaryOptions } from "./Tasks/hooks";

const tasksURLRegex = /^.+\/\/[\w\.\-\:]+\/task\/.*\/tasks.*$/;

export default function Tasks({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element {
  usePrefetchDictionaryOptions();

  useEffect(() => {
    trackEvent({ name: "Advisor Open Tasks List" });
  }, []);

  const auth = useAdvisorRequestAuth();
  const [paginationState, setPaginationState] = useState<PaginationHeaders>();
  const [queryParams, setQueryParams] =
    usePersistedQueryParams<GetTasksQueryParams>(TASKS_DEFAULT_QUERY_PARAMS, {
      parseBooleans: true,
      decode: false,
      arrayFormat: "bracket-separator",
    });
  const queryClient = useQueryClient();

  // NOTE: we do not want this request to fire if query params are empty
  const authOrNull = isEmpty(queryParams) ? null : auth;
  const { data, isLoading, hasError } = useGetTasks(queryParams, authOrNull);

  const { values: tasks = [], pagination } = data ?? {};

  const handleChangeQueryParams = (
    newQueryParams: GetTasksQueryParams
  ): void => {
    setQueryParams({ ...queryParams, ...newQueryParams });
  };

  const handleResetQueryParams = (
    resetOverwriteQueryParams: GetTasksQueryParams
  ): void => {
    setQueryParams({
      ...TASKS_DEFAULT_QUERY_PARAMS,
      ...resetOverwriteQueryParams,
    });
  };

  const handleTasksListRefetch = (): void => {
    // NOTE: reset all cached tasks queries, this includes list, details and comments
    queryClient.resetQueries({
      predicate: (query) =>
        query.queryKey?.some(
          (key) => typeof key === "string" && tasksURLRegex.test(key)
        ),
    });
  };

  useEffect(() => {
    // NOTE: we have to persist pagination values locally as they become undefined inbetween request
    if (pagination) {
      setPaginationState(pagination);
    }
  }, [pagination]);

  return (
    <AdvisorContainer>
      <AdvisorContentContainer heroTitle={"Tasks"}>
        <TasksToolbar
          filters={omit(queryParams, ["page", "pageSize", "sort"])}
          onQueryParamChange={handleChangeQueryParams}
          onQueryParamReset={handleResetQueryParams}
          onListRefetch={handleTasksListRefetch}
        />
        <TasksTable
          tasks={tasks}
          isLoading={isLoading}
          isErrored={hasError}
          pagination={paginationState}
          sort={queryParams.sort}
          onQueryParamChange={handleChangeQueryParams}
        />

        <Spacer verticalSpacing="50px" />

        {children}
      </AdvisorContentContainer>
    </AdvisorContainer>
  );
}
