import React from "react";

const Complete: React.FC = () => (
  <svg
    width="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="check">
      <circle id="Ellipse 706" cx="8" cy="8" r="7.5" stroke="currentColor" />
      <path
        id="Vector"
        d="M4.3 8.2 L7 10.8 L11.6 5.4"
        stroke="currentColor"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default Complete;
