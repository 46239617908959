import React, { useEffect, useMemo, useState } from "react";

import { Box, Stack } from "@mui/material";
import { Link, useParams } from "react-router-dom";

import useAllClientCustodians from "@fartherfinance/frontend/api/Dashboard/hooks/useAllClientCustodians";
import useClientCustodian from "@fartherfinance/frontend/api/Dashboard/hooks/useClientCustodian";
import useClientDashboard from "@fartherfinance/frontend/api/Dashboard/hooks/useClientDashboard";
import useExternalAccountsExtended from "@fartherfinance/frontend/api/ExternalAccount/hooks/useExternalAccountsExtended";
import useSetFundingAccount from "@fartherfinance/frontend/api/ExternalAccount/hooks/useSetFundingAccount";
import { ExternalAccountProvider } from "@fartherfinance/frontend/api/ExternalAccount/requests/types";
import {
  ClientId,
  ExternalAccountId,
} from "@fartherfinance/frontend/api/Types";
import { formatExternalAccount } from "@fartherfinance/frontend/formatting/account";

import { CashManagementPath } from "@src/config/routing/RouterPaths";
import custodiansRequiringVerificationDocument from "@src/constants/custodiansRequiringVerificationDocument";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import {
  isManualAccount,
  isPlaidAccount,
} from "@src/multiCustodian/pages/Dashboard/Funding/utils";
import FileUploadButton from "@src/sharedComponents/FileUploadButton/FileUploadButton";
import FormFieldLabel from "@src/sharedComponents/Forms/Private/FormFieldLabel";
import Modal from "@src/sharedComponents/Modal/Modal";
import DropdownMenu from "@src/sharedComponents/SAODropdown/Dropdown";

import ChangeFundingAccountModalLoading from "./ChangeFundingAccountModalLoading";

import styles from "./ChangeFundingAccountModal.module.css";

const CHASE_INSTITUTION_ID_1 = "ins_3";
const CHASE_INSTITUTION_ID_2 = "ins_56";

interface SelectedAccount {
  label: string;
  accountId: ExternalAccountId;
  needsRelink: boolean;
  accountProvider: ExternalAccountProvider;
}

interface Props {
  closeModal: () => void;
}

const ChangeFundingAccountModal = (props: Props): JSX.Element => {
  const [isMutating, setIsMutating] = useState<boolean>(false);

  const [verificationFile, setVerificationFile] = useState<File | null>(null);

  const [selectedAccount, setSelectedAccount] =
    useState<SelectedAccount | null>(null);

  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const statusNotification = useStatusNotification();

  const auth = useRequestAuth();
  const fundingAccounts = useExternalAccountsExtended(undefined, auth);
  const setFundingAccount = useSetFundingAccount(auth);
  const clientCustodian = useClientCustodian(clientId, auth);

  const custodianToUse = useMemo(() => {
    if (clientCustodian.isLoading || clientCustodian.hasError) {
      return clientCustodian;
    }

    if (clientCustodian.data.type === "SingleFullCustodian") {
      return {
        ...clientCustodian,
        data: clientCustodian.data.custodian,
      };
    }

    return {
      ...clientCustodian,
      data: null, //Only single, FullCustodians, are allowed in Cash Management, (which contains this component)
    };
  }, [clientCustodian]);

  const allAccountCustodians = useAllClientCustodians(clientId, auth);

  const dashboard = useClientDashboard(clientId, auth);

  const defaultCustodian = useMemo(() => {
    if (dashboard.isLoading || dashboard.hasError) {
      return dashboard;
    }

    return {
      ...dashboard,
      data: dashboard.data.defaultCustodian,
    };
  }, [dashboard]);

  useEffect(() => {
    if (fundingAccounts.hasError) {
      statusNotification("Error loading funding accounts", "Error");
      setIsMutating(false);
      props.closeModal();
    }
  }, [fundingAccounts.hasError, statusNotification, setIsMutating, props]);

  const clientHasApexAccounts =
    allAccountCustodians.data?.includes("Apex") ?? false;

  const clientHasPershingAccounts =
    allAccountCustodians.data?.includes("Pershing");

  const dropdownMenuOptions = useMemo(() => {
    if (fundingAccounts.data) {
      const validFundingAccounts = fundingAccounts.data.filter((acct) => {
        if (acct.details === null) {
          return false;
        }

        if (isPlaidAccount(acct)) {
          if (clientHasPershingAccounts) {
            /*
              Plaid appears to be sending us the wrong account number for certain Chase accounts. 
              For Pershing, when a client selects a Plaid-linked Chase account as a funding account, 
              we populate the ACH Authorization form to create the standing instruction. 
              (We don’t use these account numbers for Apex ACH relationships.)
              So, for now, don't want clients with Pershing accounts to be able to select/use a Chase account
              */
            return (
              acct.isValidForFunding &&
              acct.details.institutionId !== CHASE_INSTITUTION_ID_1 && // hide Chase
              acct.details.institutionId !== CHASE_INSTITUTION_ID_2 // hide Chase
            );
          }

          return acct.isValidForFunding;
        }

        if (isManualAccount(acct)) {
          // acct is a manual account at this point. To be a valid funding account, the client cannot
          // have any Apex accounts. Manual funding accounts (Standing Instructions) cannot fund Apex accounts
          return clientHasApexAccounts;
        }

        return false; // not plaid or manual (or cant decipher from the acct that its one of those 2)...
      });

      return validFundingAccounts.map((acct) => {
        let linkIsExpiredOrError = false;
        if (isPlaidAccount(acct)) {
          linkIsExpiredOrError =
            acct.link.status === "error" || acct.link.status === "expired";
        }

        if (linkIsExpiredOrError && isPlaidAccount(acct)) {
          return {
            label: `${formatExternalAccount(acct)} *Need to relink`,
            accountId: acct.accountId,
            needsRelink: true,
            accountProvider: ExternalAccountProvider.Enum.plaid,
          };
        }

        return {
          label: formatExternalAccount(acct),
          accountId: acct.accountId,
          needsRelink: false,
          accountProvider: isPlaidAccount(acct)
            ? ExternalAccountProvider.Enum.plaid
            : ExternalAccountProvider.Enum.manual,
        };
      });
    }

    return [];
  }, [fundingAccounts.data, clientHasPershingAccounts, clientHasApexAccounts]);

  const verificationDocumentRequired =
    custodianToUse !== null &&
    selectedAccount?.accountProvider === "plaid" &&
    custodiansRequiringVerificationDocument.some(
      (c) => c === custodianToUse.data
    );

  const onUpload = async () => {
    if (defaultCustodian.data === undefined) {
      statusNotification(
        "Required custodian information is unavailable",
        "Error"
      );
      return;
    }

    if (verificationDocumentRequired && verificationFile === null) {
      statusNotification(
        "Please attach an account verification document.",
        "Error"
      );
      return;
    }

    if (selectedAccount) {
      setIsMutating(true);
      try {
        await setFundingAccount({
          accountId: selectedAccount.accountId,
        });
        statusNotification("Updated funding account", "Success");
      } catch {
        statusNotification("Failed to update funding account", "Error");
      } finally {
        setIsMutating(false);
        props.closeModal();
      }
    }
  };

  const allowAddingAccountManually = clientHasApexAccounts === false;

  return (
    <Modal closeModal={props.closeModal} modalStyle={{ width: "520px" }}>
      {isMutating ? (
        <ChangeFundingAccountModalLoading />
      ) : (
        <div className={styles.paddingContainer}>
          <p className={styles.title}>Select a Funding Account</p>

          <p className={styles.subTitle}>
            Please select a linked institution from the dropdown:
          </p>

          <p className={styles.header1}>Funding Account</p>

          <DropdownMenu
            formLabel={undefined}
            placeholder={"Select an account..."}
            value={
              selectedAccount && selectedAccount.needsRelink
                ? {
                    ...selectedAccount,
                    label: selectedAccount.label.replace("*Need to relink", ""),
                  }
                : selectedAccount
            }
            values={dropdownMenuOptions}
            onChange={(acct: SelectedAccount) => setSelectedAccount(acct)}
            disabled={
              isMutating ||
              fundingAccounts.isLoading ||
              defaultCustodian.data === undefined
            }
            loading={
              isMutating ||
              fundingAccounts.isLoading ||
              defaultCustodian.data === undefined
            }
          />

          {selectedAccount && selectedAccount.needsRelink ? (
            <div className={styles.textAlert}>
              To set this account as your funding account you need to relink
              your institution, to do so click
              <Link
                className={styles.relinkLink}
                to={`/Client/${clientId}/Relink`}
              >
                here
              </Link>
              .
            </div>
          ) : (
            <div className={styles.text1}>
              If you need to add a new account, go to the{" "}
              {
                <span>
                  <Link to={`/Client/${clientId}/Accounts`}>Accounts</Link>
                </span>
              }{" "}
              section and click Add Account.
            </div>
          )}

          {verificationDocumentRequired && (
            <Box marginTop={"32px"} marginBottom={"40px"}>
              <FormFieldLabel
                label={<div className={styles.label}>Account Verification</div>}
              />

              <FileUploadButton
                onChange={setVerificationFile}
                currentFileName={verificationFile?.name}
              />

              <div className={styles.helperText}>
                Upload a bank statement or voided check <br />
                associated with this funding account.
              </div>
            </Box>
          )}

          <Stack
            direction="row"
            alignItems="center"
            justifyContent={
              allowAddingAccountManually ? "space-between" : "flex-end"
            }
          >
            {allowAddingAccountManually && (
              <Link
                to={`/Client/${clientId}/${CashManagementPath}/ChangeFundingAccount/AddManualFundingAccount`}
              >
                Add Account Manually
              </Link>
            )}

            <div className={styles.modalButtonsDiv}>
              <Button
                variant={"outlined"}
                buttonType={"secondary"}
                text={"Cancel"}
                onClick={props.closeModal}
              />

              <Button
                disabled={
                  !selectedAccount ||
                  (selectedAccount && selectedAccount.needsRelink) ||
                  (verificationDocumentRequired && verificationFile === null)
                }
                variant={"contained"}
                buttonType={"primary"}
                text={"Save"}
                onClick={onUpload}
                style={{ marginLeft: "15px" }}
              />
            </div>
          </Stack>
        </div>
      )}
    </Modal>
  );
};

export default ChangeFundingAccountModal;
