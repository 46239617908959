import execute_statusNotification_ts from "../components/GlobalStatusNotification/notification_ts";

export default function copyToClipboard(
  textToCopy: string,
  smallXIcon: string,
  type: string
) {
  navigator.clipboard.writeText(textToCopy);
  execute_statusNotification_ts(
    `Copied ${type} to your clipboard`,
    "success",
    smallXIcon,
    "/src/assets/svg/check_green.svg"
  );
}
