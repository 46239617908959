import React from "react";

import Admin from "../Admin";
import CreateCXMemberPage from "@src/multiCustodian/components/Admin/CreateCXMember/CreateCXMemberPage";

const CreateCXMember = (): JSX.Element => {
  return (
    <Admin>
      <CreateCXMemberPage />
    </Admin>
  );
};

export default CreateCXMember;
