import React from "react";

import { useParams } from "react-router-dom";

import useManuallyTrackedAccountDetail from "@fartherfinance/frontend/api/Accounts/hooks/useManuallyTrackedAccountDetail";
import { AltAssetId } from "@fartherfinance/frontend/api/Types";

import TabTitle from "../Components/TabTitle";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

import ActivityTable from "./Components/ActivityTable";
import ActivityTableLoading from "./Components/ActivityTableLoading";

import sharedStyles from "../SharedStyles.module.css";

const ActivityTab = (): JSX.Element => {
  const { accountId } = useParams<{
    accountId: AltAssetId;
  }>();

  const auth = useRequestAuth();

  const manualAsset = useManuallyTrackedAccountDetail(accountId, auth);

  if (manualAsset.isLoading) {
    return (
      <>
        <TabTitle>Account Activity</TabTitle>

        <ActivityTableLoading />
      </>
    );
  }

  if (manualAsset.hasError) {
    return (
      <>
        <TabTitle>Account Activity</TabTitle>

        <div className={sharedStyles.error}>
          Error retrieving manual asset details
        </div>
      </>
    );
  }

  return (
    <>
      <TabTitle>Account Activity</TabTitle>

      <ActivityTable manualAsset={manualAsset.data} />
    </>
  );
};

export default ActivityTab;
