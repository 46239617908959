import React from "react";

import AdvisorContainer from "@src/multiCustodian/pages/Advisor/AdvisorContainer";

import EquivalentSecuritiesForm from "./SecuritySearch/EquivalentSecuritiesForm";

const CreateEquivalentSecurities: React.FC = () => {
  return (
    <AdvisorContainer hideNavbar={true}>
      <EquivalentSecuritiesForm
        isUpdate={false}
        name={""}
        description={""}
        members={[]}
      />
    </AdvisorContainer>
  );
};

export default CreateEquivalentSecurities;
