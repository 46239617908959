import React from "react";

import { red } from "@mui/material/colors";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import TextareaAutosize, {
  TextareaAutosizeProps,
} from "@mui/material/TextareaAutosize";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./Textarea.module.css";

export interface TextareaProps
  extends Omit<TextareaAutosizeProps, "className"> {
  label?: React.ReactNode;
  helperText?: string;
  containerClassName?: string;
  textareaClassName?: string;
  error?: boolean;
}

export default function Textarea({
  label,
  helperText,
  containerClassName,
  textareaClassName,
  disabled,
  error,
  ...restProps
}: TextareaProps): JSX.Element {
  return (
    <FormControl
      className={containerClassName}
      fullWidth
      error={error}
      disabled={disabled}
    >
      {label && (
        <FormLabel className={styles.label} error={false}>
          {label}
        </FormLabel>
      )}
      <TextareaAutosize
        style={{ borderColor: error ? red[700] : undefined }}
        className={toClassName(styles.textarea, textareaClassName)}
        disabled={disabled}
        {...restProps}
      />
      {helperText && (
        <FormHelperText className={styles.helperText}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
