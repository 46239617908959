import { isNil } from "lodash";

import removePhoneNumberFormatting from "./removePhoneNumberFormatting";

/**
 *
 * @param curPhoneNumber The user's current phone number input in varying formats.
 * @param prevPhoneNumber The user's previous phone number input, formatted as "(xxx) xxx-xxxx".
 * @returns a phone number string formatted as "(xxx) xxx-xxxx".
 */
export default function formatPhoneNumber(
  prevPhoneNumber: string,
  curPhoneNumber: string | null | undefined
): string {
  if (isNil(curPhoneNumber)) {
    return "";
  }

  if (prevPhoneNumber.length > curPhoneNumber.length) {
    return curPhoneNumber;
  }

  const noFormatting = removePhoneNumberFormatting(curPhoneNumber);

  if (
    noFormatting.length === 3 &&
    curPhoneNumber.length > prevPhoneNumber.length
  ) {
    return `(${noFormatting}) `;
  }

  if (
    noFormatting.length === 6 &&
    curPhoneNumber.length > prevPhoneNumber.length
  ) {
    const first3 = noFormatting.slice(0, 3);
    const second3 = noFormatting.slice(3, 6);

    return `(${first3}) ${second3}-`;
  }

  if (
    noFormatting.length >= 6 &&
    curPhoneNumber.length >= prevPhoneNumber.length
  ) {
    const first3 = noFormatting.slice(0, 3);
    const second3 = noFormatting.slice(3, 6);
    const lastAll = noFormatting.slice(6, noFormatting.length);

    return `(${first3}) ${second3}-${lastAll}`;
  }

  if (curPhoneNumber.length === prevPhoneNumber.length) {
    if (noFormatting.length >= 3) {
      return `(${noFormatting.slice(0, 3)}) `;
    }
  }

  return curPhoneNumber;
}
