import React from "react";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./TextBox.module.css";

export interface Props<T extends string = string> {
  hasError: boolean;
  onBlur?: (newValue: string) => void;
  value: T | null | undefined;
  onChange: (newValue: string) => void;
  placeholder?: string;
  startAdornment?: React.ReactNode;
}

const TextBox = (props: Props): JSX.Element => {
  return (
    <div
      className={toClassName(styles.container, {
        [styles.border]: !props.hasError,
        [styles.errorBorder]: props.hasError,
      })}
    >
      {props.startAdornment !== undefined && (
        <div className={toClassName(styles.adornment, styles.startAdornment)}>
          {props.startAdornment}
        </div>
      )}

      <input
        placeholder={props.placeholder}
        className={styles.input}
        value={props.value ?? ""}
        onChange={(e) => props.onChange(e.target.value)}
        onBlur={(e) =>
          props.onBlur ? props.onBlur(e.target.value) : undefined
        }
      />
    </div>
  );
};

export default TextBox;
