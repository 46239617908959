import React from "react";

import { Box, Fade, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import { useFormContext } from "react-hook-form";

import { formatCurrency } from "@fartherfinance/frontend/formatting/numbers";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useClientName from "@src/multiCustodian/hooks/useClientName";

import SummaryItem from "./SummaryItem/SummaryItem";

import styles from "./AdvisorLiquidation.module.css";

import type { FormData } from "../AdvisorLiquidation";

interface LiquidationConfirmationProps {
  onTaskIdClick: () => void;
  onContinue: () => void;
}

const LiquidationConfirmation = ({
  onTaskIdClick,
  onContinue,
}: LiquidationConfirmationProps): JSX.Element => {
  const { getValues } = useFormContext<FormData>();
  const clientName = useClientName();

  const formValues = getValues();

  return (
    <Fade in>
      <Stack>
        <Typography className={styles.title}>
          Your request to raise cash has been made
        </Typography>
        <Typography className={styles.description}>
          Funds will be available after trade settlement (T+1 for stocks).
        </Typography>

        <Stack sx={{ my: "40px" }} gap="8px">
          <Typography className={styles.details}>
            Request to Raise Cash Details
          </Typography>

          <Box className={styles.detailsSummary}>
            <SummaryItem label="Client" value={clientName?.fullName} />
            <SummaryItem label="Account" value={formValues.account.label} />
            <SummaryItem
              label="Amount"
              value={formatCurrency(formValues.amount)}
            />
            <SummaryItem
              label="Request Date"
              value={format(new Date(), "M/dd/yy")}
            />
            <SummaryItem
              label="Task Created"
              value={
                <Typography
                  component="span"
                  className={styles.detailsLink}
                  onClick={onTaskIdClick}
                >
                  Raise Cash
                </Typography>
              }
            />
          </Box>
        </Stack>

        <Stack direction="row" gap="16px" alignSelf="flex-end">
          <Button
            variant="contained"
            buttonType="primary"
            text="Done"
            onClick={onContinue}
          />
        </Stack>
      </Stack>
    </Fade>
  );
};

export default LiquidationConfirmation;
