import React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { format, parseISO } from "date-fns";

import useRemoveAttachment from "@fartherfinance/frontend/api/Tasks/hooks/useRemoveAttachment";
import useRemoveTaskComment from "@fartherfinance/frontend/api/Tasks/hooks/useRemoveTaskComment";
import useUpdateTaskComment from "@fartherfinance/frontend/api/Tasks/hooks/useUpdateTaskComment";
import { Task, TaskComment } from "@fartherfinance/frontend/api/Tasks/Types";
import {
  AdvisorId,
  AttachmentId,
  TaskCommentId,
} from "@fartherfinance/frontend/api/Types";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { trackEvent } from "@src/multiCustodian/services/tracking";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import { extractAxiosErrorMessage } from "@src/utils/axios";

import TaskCommentComponent from "./TaskComment";
import { getDateFormat, getManipulatorName } from "./utils";

import styles from "./TaskActivity.module.css";

const renderCreatedBy = (task: Task, advisorId?: AdvisorId): JSX.Element => {
  const { creator, createdTs } = task;
  const createdDate = parseISO(createdTs);
  const dateFormat = getDateFormat(createdDate);
  const creatorName = getManipulatorName(creator, advisorId);

  return (
    <>
      <strong>{creatorName} </strong>
      created this task on {format(createdDate, dateFormat)}.
    </>
  );
};

const renderModifiedBy = (task: Task, advisorId?: AdvisorId): JSX.Element => {
  const { modifiedBy, updatedTs } = task;

  if (modifiedBy === null) {
    return <></>;
  }

  const modifiedDate = parseISO(updatedTs);
  const dateFormat = getDateFormat(modifiedDate);
  const modificatorName = getManipulatorName(modifiedBy, advisorId);

  return (
    <>
      <strong>{modificatorName} </strong>
      last updated this task on {format(modifiedDate, dateFormat)}.
    </>
  );
};

interface TaskActivityProps {
  task: Task;
  taskComments: TaskComment[];
}

export default function TaskActivity({
  task,
  taskComments,
}: TaskActivityProps): JSX.Element {
  const auth = useAdvisorRequestAuth();
  const statusNotification = useStatusNotification();
  const callRemoveTaskComment = useRemoveTaskComment(auth);
  const callUpdateTaskComment = useUpdateTaskComment(auth);
  const callRemoveAttachment = useRemoveAttachment(auth);

  const advisorId = auth?.advisorId;

  const handleRemoveTaskComment = async (
    commentId: TaskCommentId
  ): Promise<void> => {
    try {
      await callRemoveTaskComment({ taskId: task.id, commentId });

      trackEvent({ name: "Advisor Tasks Comment Delete" });
    } catch (error) {
      statusNotification(
        extractAxiosErrorMessage(error, "Failed to delete comment."),
        "Error"
      );
    }
  };

  const handleUpdateTaskComment = async (
    commentId: TaskCommentId,
    content: string
  ): Promise<void> => {
    try {
      await callUpdateTaskComment({
        taskId: task.id,
        commentId,
        request: { content },
      });

      trackEvent({ name: "Advisor Tasks Comment Update" });
    } catch (error) {
      statusNotification(
        extractAxiosErrorMessage(error, "Failed to update comment."),
        "Error"
      );
    }
  };

  const handleDeleteAttachment = async (
    attachmentId: AttachmentId
  ): Promise<void> => {
    try {
      await callRemoveAttachment({ taskId: task.id, attachmentId });

      trackEvent({ name: "Advisor Tasks Attachment Delete" });

      statusNotification("Attachment removed successfully.", "Success");
    } catch (error) {
      statusNotification(
        extractAxiosErrorMessage(error, "Failed to delete attachment."),
        "Error"
      );
    }
  };

  return (
    <Stack className={styles.container}>
      <Typography className={styles.title}>Activity</Typography>
      <Box className={styles.activityArea}>
        <Typography className={styles.activityText}>
          {renderCreatedBy(task, advisorId)}
        </Typography>
        <Typography
          className={toClassName(styles.activityText, styles.topSpacer)}
        >
          {renderModifiedBy(task, advisorId)}
        </Typography>

        <Box className={styles.spacer} />

        <Stack spacing={3}>
          {taskComments.map((comment) => (
            <TaskCommentComponent
              key={comment.id}
              comment={comment}
              onCommentDelete={handleRemoveTaskComment}
              onCommentUpdate={handleUpdateTaskComment}
              onAttachmentDelete={handleDeleteAttachment}
              advisorId={advisorId}
            />
          ))}
        </Stack>
      </Box>
    </Stack>
  );
}
