import React from "react";

import AdvisorSelectAccounts from "../SelectAccounts/SelectAccounts";
import HypotheticalResultsAnalysis from "@src/multiCustodian/components/TaxBudgets/HypotheticalResultsAnalysis/HypotheticalResultsAnalysis";
import { useCreateEditTaxBudgetContext } from "@src/multiCustodian/components/TaxBudgets/reducer/Context";
import ReviewDisclosures from "@src/multiCustodian/components/TaxBudgets/ReviewDisclosures/ReviewDisclosures";
import SelectBudget from "@src/multiCustodian/components/TaxBudgets/SelectBudget/SelectBudget";
import SelectHypotheticalModels from "@src/multiCustodian/components/TaxBudgets/SelectHypotheticalModels/SelectHypotheticalModels";

const HypotheticalFlowBody: React.FC = () => {
  const { state } = useCreateEditTaxBudgetContext();

  const { pageIndex } = state;

  switch (pageIndex) {
    case 0:
      return <AdvisorSelectAccounts />;
    case 1:
      return <SelectBudget />;
    case 2:
      return <ReviewDisclosures />;
    case 3:
      return <SelectHypotheticalModels />;
    case 4:
      return <HypotheticalResultsAnalysis />;
    default:
      return null;
  }
};

export default HypotheticalFlowBody;
