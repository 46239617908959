import React from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Button from "@src/multiCustodian/components/MUI/Button/Button";

import styles from "./BackButton.module.css";

interface Props {
  onClick: () => void;
}

const BackButton: React.FC<Props> = ({ onClick }) => (
  <Button
    onClick={onClick}
    text="Back"
    startIcon={<ArrowBackIcon className={styles.icon} />}
    variant="text"
    buttonType="primary"
  />
);

export default BackButton;
