import React from "react";

import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";

import { useStyles } from "./Button.styles";

type ButtonProps = Omit<MuiButtonProps, "size"> & {
  size: "small" | "large";
  disableTextTransform?: boolean;
};

export const Button: React.FC<ButtonProps> = ({
  children,
  className,
  disableTextTransform,
  ...restProps
}) => {
  const { classes, cx } = useStyles();

  return (
    <MuiButton
      className={cx(classes.button, className, {
        [classes.normalText]: disableTextTransform,
      })}
      disableElevation
      disableRipple
      disableFocusRipple
      disableTouchRipple
      {...restProps}
    >
      {children}
    </MuiButton>
  );
};
