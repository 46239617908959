import React from "react";

import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import UploadIcon from "@mui/icons-material/Upload";
import { Stack } from "@mui/material";
import { useHistory } from "react-router-dom";

import { PortfolioId } from "@fartherfinance/frontend/api/Types";

import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";

import styles from "./CreateUpdateCustomModelForm.module.css";

interface Props {
  csvFile: File | null;
  goToCSVUpload: () => void;
  portfolioId: PortfolioId | undefined;
  callUpdateModel: () => void;
  cameFromUpload: boolean;
  mutating: boolean;
  isValid: boolean;
  isDirty: boolean;
  total: number;
}

const UpdateCustomModelFooter = (props: Props): JSX.Element => {
  const history = useHistory();
  const statusNotification = useStatusNotification();

  return (
    <>
      <Stack direction="row" gap="8px">
        {props.csvFile !== null && (
          <div className={styles.fileNameContainer}>
            <InsertDriveFileOutlinedIcon className={styles.fileIcon} />
            <span className={styles.fileName}>{props.csvFile.name}</span>
          </div>
        )}

        <ButtonPrimary
          text={
            <div className={styles.upload}>
              <span>Upload</span>
              <UploadIcon className={styles.uploadIcon} />
            </div>
          }
          variant="outlined"
          onClick={props.goToCSVUpload}
          buttonType="primary"
        />
      </Stack>

      <Stack direction="row" justifyContent={"flex-end"} gap="16px">
        <ButtonPrimary
          text="Cancel"
          variant="outlined"
          onClick={() => {
            if (!props.portfolioId) {
              statusNotification("No portfolio present", "Error");
              return;
            }

            history.push({
              pathname: `/Advisor/Investments/ModelMarketplace/${props.portfolioId}/ModelDetails`,
              state: history.location.state,
            });
          }}
          buttonType="primary"
        />

        <ButtonPrimary
          text="Update Model"
          variant="contained"
          onClick={props.callUpdateModel}
          buttonType="primary"
          disabled={
            props.mutating ||
            !props.isValid ||
            (!props.isDirty && !props.cameFromUpload) ||
            props.total !== 100
          }
        />
      </Stack>
    </>
  );
};

export default UpdateCustomModelFooter;
