import React from "react";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./TitleAndDescription.module.css";

interface Props {
  title: string;
  description: string;
  titleStyle?: string;
}

const HeroTitleAndDescription = (props: Props): JSX.Element => {
  return (
    <div>
      <div className={toClassName(styles.title, props.titleStyle)}>
        {props.title}
      </div>

      <div className={styles.description}>{props.description}</div>
    </div>
  );
};

export default HeroTitleAndDescription;
