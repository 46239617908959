import React, { useEffect, useReducer, useState } from "react";

import { isAxiosError } from "axios";
import { useHistory, useParams } from "react-router-dom";

import useCreateTaxBudget from "@fartherfinance/frontend/api/InvestmentModel/hooks/useCreateTaxBudget";
import useEditTaxBudget from "@fartherfinance/frontend/api/InvestmentModel/hooks/useEditTaxBudget";
import useGetTaxBudgetsForClient from "@fartherfinance/frontend/api/InvestmentModel/hooks/useGetTaxBudgetsForClient";
import { ClientId, TaxBudgetId } from "@fartherfinance/frontend/api/Types";

import SecondaryTitle from "../../../components/TaxBudgets/SecondaryTitle/SecondaryTitle";
import FlowBody from "@src/multiCustodian/components/Advisor/TaxBudgets/FlowBody/FlowBody";
import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import FlowInit from "@src/multiCustodian/components/TaxBudgets/FlowInit/FlowInit";
import { actions } from "@src/multiCustodian/components/TaxBudgets/reducer/actions";
import CreateEditTaxBudgetContext from "@src/multiCustodian/components/TaxBudgets/reducer/Context";
import { initialState } from "@src/multiCustodian/components/TaxBudgets/reducer/initialState";
import reducer from "@src/multiCustodian/components/TaxBudgets/reducer/reducer";
import { ErrorMessage } from "@src/multiCustodian/components/TaxBudgets/reducer/types";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { trackEvent } from "@src/multiCustodian/services/tracking";
import FlowHero from "@src/sharedComponents/FlowHero/FlowHero";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

const CreateEdit: React.FC = () => {
  const { clientId } = useParams<{
    clientId: ClientId;
    taxBudgetId: TaxBudgetId;
  }>();

  const [state, dispatch] = useReducer(
    reducer,
    initialState({ flowType: "Budget", isAdvisor: true })
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { buttonText, isContinueDisabled, pageIndex, pageTitle, data } = state;

  const statusNotification = useStatusNotification();

  const history = useHistory();

  const advisorAuth = useAdvisorRequestAuth();

  const auth = advisorAuth ? { ...advisorAuth, clientId: clientId } : null;

  const { data: budgets, isLoading: isLoadingBudgets } =
    useGetTaxBudgetsForClient({ clientId }, auth);

  useEffect(() => {
    setIsLoading(isLoadingBudgets);
  }, [isLoadingBudgets]);

  const callCreateTaxBudget = useCreateTaxBudget(auth);

  const callEditTaxBudget = useEditTaxBudget(auth);

  const cancel = () => {
    dispatch({ type: actions.RESET_TO_DEFAULT_DATA, payload: undefined });
    history.goBack();
  };

  const handleOnClickCancel = () => cancel();

  const createOrEditTaxBudget = async () => {
    try {
      setIsLoading(true);

      const response = data.budgetId
        ? await callEditTaxBudget({
            budgetId: data.budgetId,
            budgetAmount: data.budgetAmount,
            clientIds: data.clientIds,
            excludedVirtualAccountIds: data.excludedVirtualAccountIds,
            year: data.year || 0,
          })
        : await callCreateTaxBudget({
            budgetAmount: data.budgetAmount,
            clientIds: data.clientIds,
            excludedVirtualAccountIds: data.excludedVirtualAccountIds,
            year: data.year || 0,
          });

      trackEvent({
        name: data.budgetId
          ? "Advisor Complete Edit Tax-Budget"
          : "Advisor Complete Create Tax-Budget",
      });

      dispatch({
        type: actions.SET_TAX_BUDGET_ID,
        payload: response.budgetId,
      });

      dispatch({ type: actions.INCREMENT_PAGE_INDEX, payload: undefined });
    } catch (error) {
      if (isAxiosError<ErrorMessage>(error)) {
        const message =
          error.response?.data.message ??
          "Unexpected error from investment model service.";

        return statusNotification(message, "Error");
      }

      statusNotification("Something went wrong. Please try again.", "Error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnClickBack = () => {
    switch (pageIndex) {
      case 0:
        cancel();
        break;
      default:
        dispatch({ type: actions.DECREMENT_PAGE_INDEX, payload: undefined });
        break;
    }
  };

  const handleOnClickContinue = () => {
    switch (pageIndex) {
      case 4:
        createOrEditTaxBudget();
        break;
      default:
        dispatch({ type: actions.INCREMENT_PAGE_INDEX, payload: undefined });
        break;
    }
  };
  // Don't show the hero on the success page
  const displayHero = pageIndex !== 5;

  return (
    <CreateEditTaxBudgetContext.Provider value={{ state, dispatch }}>
      <BaseLayout showSideNav={false}>
        <FlowInit budgets={budgets || []}>
          {displayHero && (
            <FlowHero
              onClickBack={handleOnClickBack}
              onClickCancel={handleOnClickCancel}
              onClickContinue={handleOnClickContinue}
              primaryButtonText={buttonText}
              primaryButtonIsDisabled={isContinueDisabled}
              secondaryTitle={<SecondaryTitle title={"Set a Tax Budget"} />}
              title={pageTitle}
            />
          )}

          {!isLoading && <FlowBody />}

          {isLoading && <LogoLoadingStill onTop noBackgroundColor />}
        </FlowInit>
      </BaseLayout>
    </CreateEditTaxBudgetContext.Provider>
  );
};

export default CreateEdit;
