import React from "react";

import { useFlags } from "launchdarkly-react-client-sdk";

import { ClientId, FartherAccountId } from "@fartherfinance/frontend/api/Types";

import AccountBalance from "../AccountsAccordions/Components/AccountBalance";
import AccountBalanceV4 from "../AccountsAccordions/Components/AccountBalanceV4";
import PerformanceIndicatorArrow from "@src/sharedComponents/PerformanceIndicatorArrow/PerformanceIndicatorArrow";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import toUSD from "@src/utils/toUSD";

import * as styles from "./Header.module.css";

interface HeaderProps {
  clientId: ClientId;
  accountId: FartherAccountId;
  name: string | null;
  subHeader: string | null;
  marketChange: number | null | undefined;
  twr: number | null | undefined;
  timeFrame: string | null;
}

export default function Header(props: HeaderProps): JSX.Element {
  const { enableCustomPerformanceGroupsV2 } = useFlags();

  return (
    <div className={styles.leftRight}>
      <div className={styles.headers}>
        <div className={styles.header}>
          {props.name ? (
            props.name
          ) : (
            <Skeleton width={145} height={28} style={{ transform: "none" }} />
          )}
        </div>

        <div className={styles.subHeader}>
          {props.subHeader ? (
            props.subHeader
          ) : (
            <Skeleton width={160} height={19} style={{ transform: "none" }} />
          )}
        </div>
      </div>

      <div className={styles.rightSide}>
        <div className={styles.subHeader}>Balance</div>

        {enableCustomPerformanceGroupsV2 ? (
          <AccountBalanceV4
            balanceCssClassName={styles.balance}
            clientId={props.clientId}
            accountId={props.accountId}
            loadingSkeleton={
              <Skeleton width={220} height={54} style={{ transform: "none" }} />
            }
          />
        ) : (
          <AccountBalance
            balanceCssClassName={styles.balance}
            clientId={props.clientId}
            accountId={props.accountId}
            loadingSkeleton={
              <Skeleton width={220} height={54} style={{ transform: "none" }} />
            }
          />
        )}

        <div className={styles.performance}>
          {props.twr !== undefined && props.marketChange !== undefined && (
            <>
              {props.twr !== null && (
                <PerformanceIndicatorArrow
                  style={{ marginRight: "5px" }}
                  status={
                    props.marketChange === null
                      ? "neutral"
                      : props.marketChange >= 0
                      ? "positive"
                      : "negative"
                  }
                />
              )}
              <span>
                {props.marketChange ? (
                  toUSD(props.marketChange)
                ) : (
                  <Skeleton width={70} style={{ transform: "none" }} />
                )}
              </span>
              <span> </span> {/* Space is important do not delete */}
              <span className={styles.percent}>
                {props.twr ? (
                  `(${props.twr.toLocaleString("en-US", {
                    style: "percent",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })})`
                ) : (
                  <Skeleton width={50} style={{ transform: "none" }} />
                )}
              </span>
              <span> </span> {/* Space is important do not delete */}
            </>
          )}

          <span className={styles.textSubtle}>
            {props.timeFrame ? (
              props.timeFrame
            ) : (
              <Skeleton width={40} style={{ transform: "none" }} />
            )}
          </span>
        </div>
      </div>
    </div>
  );
}
