import React, { ComponentProps } from "react";

import { InputAdornment, InputBaseComponentProps } from "@mui/material";
import { Controller, Message, useFormContext } from "react-hook-form";

import TextInput from "../TextInput/TextInput";

import FormFieldLabel from "./Private/FormFieldLabel";

export interface Props {
  name: string;
  label?: React.ReactNode;
  labelTooltip?: string;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: string;
  required?: Message | false;
  rules?: ComponentProps<typeof Controller>["rules"];
  style?: React.CSSProperties;
  valueFormatterOnChange?: <T extends string>(input: T) => string;
  valueFormatterOnBlur?: <T extends string>(input: T) => string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  inputMode?: InputBaseComponentProps["inputMode"];
  helperText?: React.ReactNode;
}

export default function FormTextField({ ...props }: Props): JSX.Element {
  const { control, register } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      rules={{ required: props.required, ...props.rules }}
      defaultValue={props.defaultValue}
      render={({ field, fieldState }) => (
        <TextInput
          {...register(props.name)}
          label={
            <FormFieldLabel
              label={props.label}
              labelTooltip={props.labelTooltip}
            />
          }
          placeholder={props.placeholder}
          onChange={(e) =>
            field.onChange(
              props.valueFormatterOnChange
                ? props.valueFormatterOnChange(e.target.value)
                : e
            )
          }
          value={field.value}
          name={field.name}
          inputRef={field.ref}
          onBlur={(e) => {
            if (props.valueFormatterOnBlur) {
              field.onChange(props.valueFormatterOnBlur(e.target.value));
            }

            field.onBlur();
          }}
          disabled={props.disabled}
          error={fieldState.invalid}
          helperText={fieldState.error?.message ?? props.helperText}
          style={props.style}
          inputProps={{
            ...(props.inputMode
              ? {
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }
              : {}),
          }}
          InputProps={{
            ...(props.startAdornment && {
              startAdornment: (
                <InputAdornment position="start">
                  {props.startAdornment}
                </InputAdornment>
              ),
            }),
            ...(props.endAdornment && {
              endAdornment: (
                <InputAdornment position="end">
                  {props.endAdornment}
                </InputAdornment>
              ),
            }),
          }}
        />
      )}
    />
  );
}
