import React from "react";

import { Ticker } from "@fartherfinance/frontend/api/Types";

import FormNumberField from "@src/sharedComponents/Forms/FormNumberField";
import ScrollContainer from "@src/sharedComponents/ScrollContainer/ScrollContainer";
import { pluralize } from "@src/utils/pluralize";

import styles from "./SecuritiesAllocationTable.module.css";

export type Security = {
  displayName: string;
  ticker: Ticker;
};

interface Props {
  portfolioName: string;
  securities: Security[];
  total: number;
}

const SecuritiesAllocationTable = (props: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      <div className={styles.listHeaderDiv}>
        <div className={styles.flexDiv}>
          <p className={styles.headerText1}>{props.portfolioName}</p>

          <p className={styles.headerText2}>Model Allocation</p>
        </div>

        <p className={styles.headerText3}>
          {pluralize(props.securities, "Security", true)}
        </p>
      </div>

      <div className={styles.optionsDiv}>
        <div className={styles.firstOptionDiv}>
          <p className={styles.headerText2}>Securities</p>
          <p className={styles.headerText2}>Allocation</p>
        </div>

        <ScrollContainer maxHeight={330}>
          {props.securities.map((sec) => {
            return (
              <div key={sec.ticker} className={styles.optionDiv}>
                <div className={styles.flexDiv}>
                  <p className={styles.headerText1}>{sec.ticker}</p>
                  <p className={styles.headerText2}>{sec.displayName}</p>
                </div>

                <div className={styles.allocationInputDiv}>
                  <FormNumberField
                    name={sec.ticker}
                    endAdornment={"%"}
                    hideErrorMsg={true}
                    customRegex={{
                      regex: /^[\d\.]+$/g,
                      message: `${sec.displayName} must be only numerical digits`,
                    }}
                    rules={{
                      required: `${sec.displayName} must have an allocation`,
                      min: {
                        value: 0.01,
                        message: `${sec.displayName} must be larger than 0.01%`,
                      },
                      max: {
                        value: 100,
                        message: `${sec.displayName} is over 100%`,
                      },
                      validate: {
                        moreThan2Decimals: (value): string | true => {
                          if (typeof value !== "string") {
                            return `Invalid value for ${sec.displayName}`;
                          }

                          const decimalPortion = value.split(".")[1] as
                            | string
                            | undefined;
                          if (decimalPortion === undefined) {
                            return true;
                          }

                          // This will catch even 1.110
                          return decimalPortion.length <= 2
                            ? true
                            : `Only 2 decimals allowed for ${sec.displayName}`;
                        },
                      },
                    }}
                  />
                </div>
              </div>
            );
          })}
        </ScrollContainer>

        <div className={styles.allocationTotalDiv}>
          <p className={styles.headerText2}>Total</p>

          <p className={styles.allocationText}>
            {(props.total / 100).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
              style: "percent",
            })}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SecuritiesAllocationTable;
