import React, { PropsWithChildren } from "react";

import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";

import styles from "./Disclosure.module.css";

interface Props {
  title: string;
}

const Disclosure: React.FC<PropsWithChildren<Props>> = ({
  title,
  children,
}) => {
  return (
    <BorderBox className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.text}>{children}</div>
    </BorderBox>
  );
};

export default Disclosure;
