const graphHeight = 310;
const XAxisHeight = 20;

const graphPresets = {
  graphHeight,
  YAxisWidth: 80,
  XAxisHeight,
  innerGraphHeight: graphHeight - XAxisHeight,
  tickMargin: -2,
  dx: -8, // dx: moves YTick labels left
  dy: 10, // dy: moves XTick labels down
  axisFontsize: "14px",
  yTickCount: 5, //tickCount determines how many labels to place
  strokeWidth: 3,
};

export default graphPresets;
