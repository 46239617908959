/* eslint-disable react/prop-types */

import React, { Component } from "react";

import "./input.css";

export const underlineStyle = {
  neutral: {
    borderBottom: "1.5px solid var(--neutral_input_underline_color_1)",
    width: "100%",
  },
  good: {
    borderBottom: "1.5px solid var(--good_input_underline_color_1)",
    width: "100%",
  },
  bad: {
    borderBottom: "1.5px solid var(--bad_input_underline_color_1)",
    width: "100%",
  },
};

type Item<ID extends string = any> = ID | { itemId: ID; itemString: string };

interface IncomingProps<ID extends string> {
  changeMenuState: (item: ID) => void;
  title: string | JSX.Element;
  placeholder: string;
  type: React.HTMLInputTypeAttribute;
  cur_item?: Item<ID>;
  items: readonly Item<ID>[];

  //Optional
  name?: string;
  title_img?: string | null;
  error_description?: string;
  children?: any | null;
  show_validation_img?: boolean | null;
  validation_img?: string | null;
  disabled?: boolean | null;
  underline_style?: object | null;
  pre_text?: string | null;
  dropdownMenuStyle?: object | null;
  container_style?: object | null;
  shell_style?: React.CSSProperties;
  onBlur?: () => void;
}
interface InitialState {
  showDropdown: boolean;
  tabIndex: 0 | undefined;
}

class InputWithDropdownMenu<ID extends string = string> extends Component<
  IncomingProps<ID>,
  InitialState
> {
  dictionary: React.MutableRefObject<Record<string, string> | null>;
  [key: string]: any;

  constructor(props: IncomingProps<ID>) {
    super(props);
    this.state = {
      showDropdown: false,
      tabIndex: undefined,
    };
    /* Dictionary of all possible first-letter items and their Refs */
    this.dictionary = React.createRef<{
      [key: string]: string;
    } | null>() as React.MutableRefObject<Record<string, string> | null>;
    this.dictionary.current = {}; // initialize the dictionary

    /* Create the Refs that will be necessary later */
    for (let i = 0; i < this.props.items.length; i++) {
      this[`item${i}`] = React.createRef<HTMLDivElement>();
    }
  }

  componentDidUpdate(
    _prevProps: IncomingProps<ID>,
    prevState: InitialState
  ): void {
    if (prevState.showDropdown !== this.state.showDropdown) {
      if (this.state.showDropdown) {
        this.setState({ tabIndex: 0 });
      } else {
        this.setState({ tabIndex: undefined });
      }
    }
  }

  changeMenuState = (new_item: any) => {
    this.setState({ showDropdown: false });
    this.props.changeMenuState(new_item);
  };

  firstLetterSearch = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (this.dictionary.current === null) {
      this.dictionary.current = {};
    }

    /* Helps find an item by its first character if the character exists */
    if (this.dictionary.current[e.key.toLowerCase()]) {
      const ref = this[this.dictionary.current[e.key.toLowerCase()]] as
        | undefined
        | React.MutableRefObject<HTMLElement | null>;

      ref?.current?.scrollIntoView?.();
    }
  };

  updateDictionary = (letter: string, item: string) => {
    /* Creating the dictionary for available first characters */
    if (!this.dictionary.current) {
      this.dictionary.current = {};
    }
    if (!this.dictionary.current[`${letter.toLowerCase()}`]) {
      this.dictionary.current[`${letter.toLowerCase()}`] = `${item}`;
    }
  };

  render() {
    let shell_style = this.props.shell_style
      ? { ...this.props.shell_style }
      : {};
    shell_style = this.props.underline_style
      ? { ...shell_style, ...this.props.underline_style }
      : shell_style;

    return (
      <div
        tabIndex={this.state.tabIndex}
        onKeyDown={this.firstLetterSearch}
        className="input_component__dropdownMenu_container_2"
        style={
          this.props.container_style
            ? {
                ...this.props.container_style,
                opacity: this.props.disabled ? 0.3 : 1,
              }
            : { opacity: this.props.disabled ? 0.3 : 1 }
        }
        onMouseLeave={() => this.setState({ showDropdown: false })}
      >
        <div className="input_component__dropdownMenu_cred_container">
          <div className="input_component__cred_container_leftSide">
            {this.props.title_img && (
              <img
                src={this.props.title_img}
                className="input_component__cred_img"
              ></img>
            )}
            <div className="input_component__input_title">
              {this.props.title}
            </div>
            {this.props.children}
          </div>
          {this.props.show_validation_img && !this.props.disabled && (
            <img
              src={
                this.props.validation_img
                  ? this.props.validation_img
                  : undefined
              }
              className="input_component__dropdown_img_2"
              onMouseEnter={() => this.setState({ showDropdown: true })}
            ></img>
          )}
        </div>
        <div className="input_component__input_shell" style={shell_style}>
          {this.props.pre_text && (
            <p className="input_component__pre_text">{this.props.pre_text}</p>
          )}
          <input
            className="input_component__dropdownMenu_input_2"
            placeholder={this.props.placeholder}
            autoComplete="off"
            name={this.props.name}
            type={this.props.type}
            onChange={() => undefined}
            onBlur={this.props.onBlur}
            disabled={
              this.props.disabled !== null ? this.props.disabled : undefined
            }
            value={
              this.props.cur_item
                ? typeof this.props.cur_item === "string"
                  ? this.props.cur_item
                  : this.props.cur_item.itemString
                : ""
            }
          />
        </div>

        {this.props.error_description &&
          this.props.error_description.length > 0 && (
            <div className="input_component__error_text">
              {this.props.error_description}
            </div>
          )}

        {this.state.showDropdown && (
          <div className="input_component__dropdownMenu_invisible_div">
            <div className="input_component__dropdownMenu_div">
              <div
                className="input_component__dropdownMenu_2"
                style={
                  this.props.dropdownMenuStyle
                    ? this.props.dropdownMenuStyle
                    : {}
                }
              >
                {this.props.items.map((item, i) => {
                  const opt = `item${i}`;
                  const str =
                    typeof item === "string"
                      ? item === ""
                        ? "none"
                        : item
                      : item.itemString;

                  if (this && this.dictionary && str && str !== "") {
                    this.updateDictionary(str.charAt(0), opt);
                  }

                  const itemId: ID =
                    typeof item === "string" ? item : item.itemId;

                  return (
                    <div
                      ref={this[`${opt}`]}
                      key={i}
                      onClick={() => this.changeMenuState(itemId)}
                      className="input_component__dropdownMenuOption_2"
                    >
                      <p>{str}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default InputWithDropdownMenu;
