import React, { useState } from "react";

import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import UploadIcon from "@mui/icons-material/Upload";
import { Stack } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useElementSize } from "usehooks-ts";

import useCreateEssentialPortfolioV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useCreateEssentialPortfolioV2";
import useUpdateEssentialPortfolio from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useUpdateEssentialPortfolio";
import {
  CreateEssentialPortfolio,
  UpdateEssentialPortfolio,
} from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";

import { AddOrUpdate } from "../../SharedComponents/AddPartnerModelIncrementsCheckbox/AddPartnerModelIncrementsCheckbox";
import BottomBar from "../../SharedComponents/BottomBar/BottomBar";
import minBottomMargin from "../../SharedComponents/constants/minBottomMargin";
import ModelSecuritiesTable from "../../SharedComponents/ModelSecuritiesTable/ModelSecuritiesTable";
import PageTitle from "../../SharedComponents/PageTitle";
import { isCreateEssentialPortfolio } from "../AddNewPartner/NewPartnerBody";
import BackButton from "@src/multiCustodian/components/Client/BackButton";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

import styles from "./UpdateModelsBody.module.css";

type Portfolio = CreateEssentialPortfolio | UpdateEssentialPortfolio;

function isAdd(action: AddOrUpdate): action is "Add" {
  return action === "Add";
}

function isUpdateEssentialPortfolio(
  x: Portfolio
): x is UpdateEssentialPortfolio {
  return x.portfolioId !== null;
}
interface Props {
  models: Portfolio[];
  goToUploadCSV: () => void;
  csvFile: File | null;
  action: AddOrUpdate;
}

const UpdateModelsBody = (props: Props): JSX.Element => {
  const history = useHistory();
  const auth = useAdvisorRequestAuth();

  const [bottomBarRef, { height }] = useElementSize();

  const [isMutating, setIsMutating] = useState(false);

  const statusNotification = useStatusNotification();

  const updatePartnerModels = useUpdateEssentialPortfolio(auth);
  const addIncrementsToPartner = useCreateEssentialPortfolioV2(auth);

  const submit = () => {
    if (isAdd(props.action)) {
      addNewModelIncrements();
    } else {
      updateExistingModels();
    }
  };

  const updateExistingModels = async () => {
    try {
      if (props.models.length <= 0) {
        statusNotification("Attempted to upload empty model(s)", "Error");
        return;
      }

      setIsMutating(true);

      const partner = props.models[0].set;

      await updatePartnerModels({
        portfolios: props.models.filter(isUpdateEssentialPortfolio),
      });

      statusNotification(`${partner} model(s) updated`, "Success");

      history.push("/Advisor/Investments/ModelMarketplace");
    } catch (_err) {
      statusNotification("Failed to update model(s)", "Error");
    } finally {
      setIsMutating(false);
    }
  };

  const addNewModelIncrements = async () => {
    try {
      if (!isAdd(props.action)) {
        throw new Error(
          "props.action should be Add - can only Add increments here"
        );
      }

      if (props.models.length <= 0) {
        statusNotification("Attempted to add empty model(s)", "Error");
        return;
      }

      setIsMutating(true);

      const partner = props.models[0].set;

      await addIncrementsToPartner({
        body: { portfolios: props.models.filter(isCreateEssentialPortfolio) },
        action: props.action,
      });

      statusNotification(`Added ${partner} model(s)`, "Success");

      history.push("/Advisor/Investments/ModelMarketplace");
    } catch (_err) {
      statusNotification("Failed to add model(s)", "Error");
    } finally {
      setIsMutating(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.centeringContainer}>
        {isMutating && <LogoLoadingStill onTop={true} />}

        <BackButton
          onClick={() => history.push("/Advisor/Investments/ModelMarketplace")}
        />
        <Spacer verticalSpacing="8px" />
        <div className={styles.bodyContainer}>
          <PageTitle>Update Model Portfolios</PageTitle>

          <Spacer verticalSpacing="16px" />

          <div className={styles.subTitle}>
            <span>Securities & Weights</span>

            <span>{`${props.models.length} Models`}</span>
          </div>

          <Spacer verticalSpacing="16px" />

          <ModelSecuritiesTable partnerModels={props.models} />
        </div>

        {/* Ensure we can see the bottom of bodyContainer, since BottomBar has position: fixed */}
        <Spacer verticalSpacing={`${height + minBottomMargin}px`} />

        <BottomBar ref={bottomBarRef}>
          <Stack direction="row" gap="8px">
            {props.csvFile !== null && (
              <div className={styles.fileNameContainer}>
                <InsertDriveFileOutlinedIcon className={styles.fileIcon} />
                <span className={styles.fileName}>{props.csvFile.name}</span>
              </div>
            )}

            <ButtonPrimary
              text={
                <div className={styles.upload}>
                  <span>Upload</span>
                  <UploadIcon className={styles.uploadIcon} />
                </div>
              }
              variant="outlined"
              onClick={props.goToUploadCSV}
              buttonType="primary"
            />
          </Stack>

          <Stack direction="row" gap="16px">
            <ButtonPrimary
              text="Cancel"
              variant="outlined"
              onClick={() =>
                history.push("/Advisor/Investments/ModelMarketplace")
              }
              buttonType="primary"
            />
            <ButtonPrimary
              text="Update Models"
              variant="contained"
              onClick={submit}
              buttonType="primary"
              disabled={isMutating}
            />
          </Stack>
        </BottomBar>
      </div>
    </div>
  );
};

export default UpdateModelsBody;
