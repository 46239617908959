import React, { useMemo } from "react";

import { orderBy } from "lodash";

import {
  PortfolioAnalysis,
  type PortfolioAssetCategories,
  Sleeve,
} from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";

import { getSecuritiesLabel } from "../../Client/Portfolio/utils/getSecuritiesLabel";

import SleeveTable from "./Table/SleeveTable";

type PortfolioAssetCategory = typeof PortfolioAssetCategories[number];

interface Props {
  setToCategoryView: (category: PortfolioAssetCategory) => void;
  sleeve: Sleeve;
  sleeveAnalysis: PortfolioAnalysis;
}

const SleeveView = ({
  sleeve,
  sleeveAnalysis,
  setToCategoryView,
}: Props): JSX.Element => {
  const title = useMemo(() => {
    const allSecurities = (
      sleeveAnalysis.portfolioAssetClassBreakdown ?? []
    ).flatMap((acObj) => Object.values(acObj)[0].securities);

    const secondaryDescription = getSecuritiesLabel(allSecurities.length);

    return {
      name: sleeve.displayName,
      description: "Holdings Breakdown",
      secondaryDescription,
    };
  }, [sleeve, sleeveAnalysis]);

  const rows = useMemo(
    () =>
      //Object.entries does not return in any sorted way
      orderBy(
        Object.entries(sleeveAnalysis.portfolioCategoryBreakdown ?? {}),
        [([category]) => category, ([_, allocation]) => allocation],
        ["asc", "desc"]
      ).map(([category, allocation]) => ({
        key: category,
        description: category,
        allocation: allocation,
        onClick: () => setToCategoryView(category as PortfolioAssetCategory),
      })),
    [sleeveAnalysis.portfolioCategoryBreakdown, setToCategoryView]
  );

  return <SleeveTable title={title} rows={rows} sleeve={sleeve} />;
};

export default SleeveView;
