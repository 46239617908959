import React from "react";

import { Stack } from "@mui/system";

import Chip from "@src/sharedComponents/Chip/Chip";
import Spacer from "@src/sharedComponents/Forms/Spacer";

import styles from "./PartnerCard.module.css";

interface Props {
  partnerName: string;
  numberOfModels: number;
  directIndexing: boolean;
}

const PartnerCard = (props: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      <div className={styles.subtleHeader}>New Partner</div>

      <Spacer verticalSpacing="8px" />

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <div className={styles.partnerName}>{props.partnerName}</div>

        <Stack direction="row" alignItems="center" gap="8px">
          <Chip
            variant="outlined"
            label={`${props.numberOfModels} Model Portfolios`}
          />

          {props.directIndexing && (
            <Chip variant="outlined" label="Direct-Indexing" />
          )}
        </Stack>
      </Stack>
    </div>
  );
};

export default PartnerCard;
