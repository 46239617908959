import React, { useEffect, useState } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useHistory, useParams } from "react-router-dom";

import useCurrentTrigger from "@fartherfinance/frontend/api/CashCycle/hooks/useCurrentTrigger";
import useClientAccounts from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAccounts";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import { CashManagementPath } from "@src/config/routing/RouterPaths";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import Modal from "@src/sharedComponents/Modal/Modal";
import TwoTextItemToggle from "@src/sharedComponents/Toggle/TwoTextItemToggle";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import FixedFundingMethodSetupLoading from "./Components/FixedFundingMethodSetupLoading";
import WaterfallFundingMethodSetupLoading from "./Components/WaterfallFundingMethodSetupLoading";
import FixedFundingMethodSetup from "./FixedFundingMethodSetup";
import WaterfallFundingMethodSetup from "./WaterfallFundingMethodSetup";

import styles from "./SetupRecurringTransfersModal.module.css";

interface Props {
  closeModal: () => void;
}

const SetupRecurringTransfersModal = (props: Props): JSX.Element => {
  const [method, setMethod] = useState<"waterfall" | "fixed">("fixed");
  const [isMutating, setIsMutating] = useState<boolean>(false);

  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const history = useHistory();

  const auth = useRequestAuth();

  const currentTrigger = useCurrentTrigger(clientId, auth);

  const accounts = useClientAccounts(clientId, auth);

  useEffect(() => {
    if (
      currentTrigger.data &&
      currentTrigger.data.method.type === "waterfall"
    ) {
      setMethod("waterfall");
    }
  }, [currentTrigger.data]);

  const { enableRecurringTransfers } = useFlags();

  useEffect(() => {
    if (!enableRecurringTransfers) {
      history.replace(`/Client/${clientId}/${CashManagementPath}`);
    }
  }, [enableRecurringTransfers, clientId, history]);

  if (currentTrigger.hasError) {
    <div className={styles.errorContainer}>Error</div>;
  }

  return (
    <Modal closeModal={props.closeModal} modalStyle={{ width: "520px" }}>
      {(isMutating || currentTrigger.isLoading) && (
        <div className={styles.loadingContainer}>
          <LogoLoadingStill />
        </div>
      )}

      <div className={styles.paddingContainer}>
        <FormH1>Setup Recurring Transfers</FormH1>

        <Spacer />

        <div className={styles.tooltipDiv}>
          <p className={styles.tooltipLabel}>Method</p>

          <Tooltip
            tooltipText="With the waterfall method, you prioritize your accounts, and then Farther deposits funds into your top account until you reach your target or contribution limit. Then, Farther automatically starts depositing funds into the next account. With the fixed method, you allocate a set amount to deposit into each of your accounts every month."
            placement="right"
          >
            <InfoOutlinedIcon className={styles.infoIcon} />
          </Tooltip>
        </div>

        <div className={styles.textToggleDiv}>
          <TwoTextItemToggle
            onChange={(side) =>
              setMethod(side === "Left" ? "waterfall" : "fixed")
            }
            leftOption="Waterfall"
            rightOption="Fixed"
            value={method === "waterfall" ? "Left" : "Right"}
          />
        </div>

        <Spacer />

        {accounts.hasError ? (
          <div className={styles.errorContainer}>Error loading accounts</div>
        ) : method === "waterfall" ? (
          accounts.isLoading || isMutating ? (
            <WaterfallFundingMethodSetupLoading closeModal={props.closeModal} />
          ) : (
            <WaterfallFundingMethodSetup
              setIsMutating={setIsMutating}
              isMutating={isMutating}
              closeModal={props.closeModal}
              currentAmount={
                currentTrigger.data?.method.type === "waterfall"
                  ? `${currentTrigger.data.amount.amount}`
                  : ""
              }
              currentFrequency={
                currentTrigger.data?.method.type === "waterfall"
                  ? currentTrigger.data.schedule.type === "system"
                    ? currentTrigger.data.schedule.value
                    : "custom"
                  : null
              }
              currentCustomDay={
                currentTrigger.data?.method.type === "waterfall"
                  ? currentTrigger.data.schedule.type === "custom"
                    ? currentTrigger.data.schedule.value
                    : null
                  : null
              }
              currentWaterfallAccounts={
                currentTrigger.data?.method.type === "waterfall"
                  ? currentTrigger.data.method.accounts
                  : null
              }
              accounts={accounts.data.fartherAccounts}
            />
          )
        ) : accounts.isLoading || isMutating ? (
          <FixedFundingMethodSetupLoading closeModal={props.closeModal} />
        ) : (
          <FixedFundingMethodSetup
            setIsMutating={setIsMutating}
            isMutating={isMutating}
            closeModal={props.closeModal}
            currentFrequency={
              currentTrigger.data?.method.type === "fixed_amount"
                ? currentTrigger.data.schedule.type === "system"
                  ? currentTrigger.data.schedule.value
                  : "custom"
                : null
            }
            currentCustomDay={
              currentTrigger.data?.method.type === "fixed_amount"
                ? currentTrigger.data.schedule.type === "custom"
                  ? currentTrigger.data.schedule.value
                  : null
                : null
            }
            currentFixedAmountAccounts={
              currentTrigger.data?.method.type === "fixed_amount"
                ? currentTrigger.data.method.accounts
                : null
            }
            accounts={accounts.data.fartherAccounts}
          />
        )}
      </div>
    </Modal>
  );
};

export default SetupRecurringTransfersModal;
