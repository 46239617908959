import React, { PropsWithChildren } from "react";

import { Box, Stack } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";

import useCheckPermission from "@fartherfinance/frontend/api/Authz/hooks/useCheckPermission";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import LinkButton from "@src/sharedComponents/LinkButton/LinkButton";

import EquivalentSecurities from "./EquivalentSecurities/AdminTable/EquivalentSecurities";

import styles from "./AdminPage.module.css";

const AdminPage = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
  const auth = useAdvisorRequestAuth();

  const {
    enableAdminCreateCxAdvisor,
    enableAdminBulkAccountUpload,
    enableAdminFamBilling,
    enableAdminCustomModelBilling,
    enableAdminSleeveBilling,
    enableCreateAdvisorV2,
    enableEquivalentSecuritiesAdmin,
  } = useFlags();

  const canCreateCX = useCheckPermission(
    {
      type: "Organization",
      action: "create.ops",
      id: "Farther",
    },
    auth
  );

  const canBulkCreateClient = useCheckPermission(
    {
      type: "Organization",
      action: "create.client.bulk",
      id: "Farther",
    },
    auth
  );

  const canGenerateBilling = useCheckPermission(
    {
      type: "TradingGroup",
      action: "billing.generate",
      // We are looking for a generic permission for the TradingGroup resource
      // and not a specific instance
      id: null,
    },
    auth
  );

  return (
    <Stack width={640} margin={"auto"} marginTop={"40px"} gap={"24px"}>
      {enableAdminCreateCxAdvisor && canCreateCX && (
        <Box className={styles.section}>
          <Box className={styles.title}>Client Experience (CX) Team</Box>
          <Box className={styles.row}>
            <div>Create CX Team Member</div>

            <LinkButton
              variant={"outlined"}
              buttonType={"secondary"}
              text={"Create CX Member"}
              to={"/Advisor/Admin/CreateCXMember"}
              sx={{ height: "32px" }}
            />
          </Box>
        </Box>
      )}

      {enableAdminBulkAccountUpload && canBulkCreateClient && (
        <Box className={styles.section}>
          <Box className={styles.title}>Bulk Create Client Accounts</Box>
          <Box className={styles.row}>
            <div>
              Create multiple client accounts at once from a spreadsheet
            </div>

            <LinkButton
              variant={"outlined"}
              buttonType={"secondary"}
              text={"Upload Clients CSV"}
              to={"/Advisor/Admin/CreateBulkClients"}
              sx={{ height: "32px" }}
            />
          </Box>
        </Box>
      )}

      {enableCreateAdvisorV2 && (
        <Box className={styles.section}>
          <Box className={styles.title}>Advisor Team</Box>
          <Box className={styles.row}>
            <div>Create an Advisor Team Member</div>

            <LinkButton
              variant={"outlined"}
              buttonType={"secondary"}
              text={"Create New Advisor Member"}
              to={"/Advisor/Admin/CreateAdvisor"}
              sx={{ height: "32px" }}
            />
          </Box>
        </Box>
      )}

      {canGenerateBilling && (
        <Box className={styles.section}>
          <Box className={styles.title}>Billing</Box>
          {enableAdminFamBilling && (
            <Box className={styles.row}>
              <div>
                <div>FAM Billing</div>
                <div className={styles.greyText}>
                  Download a CSV to process FAM billing
                </div>
              </div>

              <LinkButton
                variant={"outlined"}
                buttonType={"secondary"}
                text={"FAM Billing"}
                to={"/Advisor/Admin/FAMBilling"}
                sx={{ height: "32px" }}
              />
            </Box>
          )}
          {enableAdminCustomModelBilling && (
            <Box className={styles.row}>
              <div>
                <div>Custom Model Billing</div>
                <div className={styles.greyText}>
                  Download a CSV to process Custom Model billing
                </div>
              </div>

              <LinkButton
                variant={"outlined"}
                buttonType={"secondary"}
                text={"Custom Model Billing"}
                to={"/Advisor/Admin/CustomModelBilling"}
                sx={{ height: "32px" }}
              />
            </Box>
          )}

          {enableAdminSleeveBilling && (
            <Box className={styles.row}>
              <div>
                <div>Sleeve Billing</div>
                <div className={styles.greyText}>
                  Download a CSV to process Sleeve billing
                </div>
              </div>

              <LinkButton
                variant={"outlined"}
                buttonType={"secondary"}
                text={"Sleeve Billing"}
                to={"/Advisor/Admin/SleeveBilling"}
                sx={{ height: "32px" }}
              />
            </Box>
          )}
        </Box>
      )}

      {enableEquivalentSecuritiesAdmin && <EquivalentSecurities />}

      {children}
    </Stack>
  );
};

export default AdminPage;
