import React, { ComponentProps } from "react";

import { SxProps } from "@mui/system";
import { Controller, Message, useFormContext } from "react-hook-form";

import Checkbox from "../Checkbox/Checkbox";

interface Props {
  name: string;
  label: string;
  defaultValue?: boolean;
  required?: Message | false;
  rules?: ComponentProps<typeof Controller>["rules"];
  labelSx?: SxProps;
}

export default function FormCheckboxField({ ...props }: Props): JSX.Element {
  const { control } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      rules={{ required: props.required, ...props.rules }}
      defaultValue={props.defaultValue}
      render={({ field, fieldState }) => (
        <Checkbox
          label={props.label}
          onChange={field.onChange}
          checked={field.value}
          name={field.name}
          onBlur={field.onBlur}
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
          labelSx={props.labelSx}
        />
      )}
    />
  );
}
