import React from "react";

import { useParams } from "react-router-dom";

import useGetConsumedBudget from "@fartherfinance/frontend/api/Rebalance/hooks/useGetConsumedBudget";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import { CAPITAL_GAINS_TAXES_TOOLTIP_TEXT } from "../constants";
import GainsAndLosses from "../DetailTabs/GainsAndLosses";
import { thisYear } from "../utils/taxYearOptions";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";
import BorderBoxBody from "@src/sharedComponents/BorderBox/BorderBoxBody";
import BorderBoxHeader from "@src/sharedComponents/BorderBox/BorderBoxHeader";
import TooltipBasic from "@src/sharedComponents/Tooltip/TooltipBasic";
import toUSD from "@src/utils/toUSD";

import styles from "./CapitalGainsTaxes.module.css";

const CapitalGainsTaxes: React.FC = () => {
  const { clientId } = useParams<{ clientId: ClientId }>();

  const advisorAuth = useAdvisorRequestAuth();

  const clientAuth = useRequestAuth();

  const auth = advisorAuth || clientAuth;

  const { data, isLoading } = useGetConsumedBudget(
    { clientId, ignoreTaxBudget: true },
    auth
  );

  const taxes = data
    ? (data.capitalConsequences.estimatedShortTermTaxesDollars ?? 0) +
      (data.capitalConsequences.estimatedLongTermTaxesDollars ?? 0)
    : 0;

  return (
    <BorderBox className={styles.borderBox}>
      <BorderBoxHeader
        className={styles.borderBoxHeader}
        start={
          <div className={styles.container}>
            <div className={styles.topContainer}>
              <div className={styles.flexRow}>
                <div
                  className={styles.text}
                >{`${thisYear} Capital Gains Taxes`}</div>

                <TooltipBasic text={CAPITAL_GAINS_TAXES_TOOLTIP_TEXT} />
              </div>

              <div className={styles.text2}>
                {isLoading ? "--" : `${toUSD(taxes)}`}
              </div>
            </div>

            <div className={styles.progressBar} />
          </div>
        }
      />
      <BorderBoxBody showTopBorder={true}>
        <GainsAndLosses ignoreTaxBudget={true} />
      </BorderBoxBody>
    </BorderBox>
  );
};

export default CapitalGainsTaxes;
