import React from "react";

import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import SvgLine from "@src/multiCustodian/components/PerformanceGroups/Shared/SvgLine";

import styles from "../ModelAnalysis.module.css";

const Legend = (): JSX.Element => {
  const {
    color: { $chartLineTertiary, $chartLinePrimary, $chartLineSecondary },
  } = useTheme();

  return (
    <div className={styles.legend}>
      <div className={styles.legendItem}>
        <SvgLine color={$chartLineTertiary} width={26} dashed={false} />
        <div
          style={{
            color: $chartLineTertiary,
          }}
        >
          90%
        </div>
      </div>

      <div className={styles.legendItem}>
        <SvgLine color={$chartLinePrimary} width={26} dashed={false} />
        <div
          style={{
            color: $chartLinePrimary,
          }}
        >
          50%
        </div>
      </div>

      <div className={styles.legendItem}>
        <SvgLine color={$chartLineSecondary} width={36} dashed={false} />
        <div
          style={{
            color: $chartLineSecondary,
          }}
        >
          10%
        </div>
      </div>
    </div>
  );
};

export default Legend;
