import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Stack from "@mui/material/Stack";
import { useParams } from "react-router-dom";

import { Pagination, usePagination } from "@src/yellowstone/components";
import {
  calculateSkeletonCount,
  matchReactQueryStatus,
} from "@src/yellowstone/modules/shared";
import {
  useUserDetailsQuery,
  useUsersQuery,
} from "@src/yellowstone/modules/users";

import { UserAccounts, UserAccountsSkeleton } from "./components";
import { useStyles } from "./UserList.styles";

type UserDetailsPathParams = Record<"userId", string>;

interface UserListProps {
  isSelectedUserMode: boolean;
}

export const UserList: React.FC<UserListProps> = ({ isSelectedUserMode }) => {
  const { classes } = useStyles();
  const [usersTotalCount, setUsersTotalCount] = useState(0);
  const { userId } = useParams<UserDetailsPathParams>();
  const { pageSize, pageNumber, setPageNumber, resetPaginationParams } =
    usePagination();

  const {
    data: usersQueryData = [],
    totalCount: usersQueryTotalCount = 0,
    status: usersQueryStatus,
  } = useUsersQuery(
    { pageSize, page: pageNumber },
    { enabled: !isSelectedUserMode }
  );
  const {
    data: userDetailsQueryData = [],
    totalCount: userDetailsQueryTotalCount = 0,
    status: userDetailsQueryStatus,
  } = useUserDetailsQuery(userId ?? "", { enabled: isSelectedUserMode });

  const data = isSelectedUserMode ? userDetailsQueryData : usersQueryData;
  const totalCount = isSelectedUserMode
    ? userDetailsQueryTotalCount
    : usersQueryTotalCount;
  const status = isSelectedUserMode ? userDetailsQueryStatus : usersQueryStatus;

  const usersSkeletonCount = calculateSkeletonCount(
    pageSize,
    pageNumber,
    usersTotalCount
  );

  useEffect(() => {
    if (userDetailsQueryStatus === "loading") {
      resetPaginationParams();
    }
  }, [userDetailsQueryStatus, resetPaginationParams]);

  useEffect(() => {
    if (totalCount) {
      setUsersTotalCount(totalCount);
    }
  }, [totalCount]);

  return (
    <Stack className={classes.container}>
      {matchReactQueryStatus(status)({
        onPending: () => (
          <Fade key="pending" in>
            <Box>
              {Array.from({ length: usersSkeletonCount }, (_, index) => (
                <Box key={index} className={classes.userAccountsContainer}>
                  <UserAccountsSkeleton />
                </Box>
              ))}
            </Box>
          </Fade>
        ),
        onSuccess: () => (
          <Fade key="success" in>
            <Box>
              {data.map((user) => (
                <Box key={user.id} className={classes.userAccountsContainer}>
                  <UserAccounts
                    user={user}
                    isSelectedUserMode={isSelectedUserMode}
                  />
                </Box>
              ))}
            </Box>
          </Fade>
        ),
        onFailure: () => (
          <Box className={classes.warning}>
            Something went wrong, please try again
          </Box>
        ),
      })}

      <Pagination
        collectionName="users"
        pageSize={pageSize}
        pageNumber={pageNumber}
        totalCount={usersTotalCount}
        onPageChange={setPageNumber}
      />
    </Stack>
  );
};
