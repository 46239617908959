import React from "react";

import { useSelector } from "react-redux";

import { ADVISOR_NOTE_FOR_REBALANCING } from "../../const";
import { Account } from "../Types";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import Modal from "@src/sharedComponents/Modal/Modal";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import { State } from "@src/store";

import styles from "./modal.module.css";

interface Props {
  closeModal: () => void;
  onConfirm: () => void;
  accountsInTradingGroup: Account[];
  isMutating: boolean;
  toggleOnToOff: boolean;
}

const ToggleTradingSwitchModal = (props: Props): JSX.Element => {
  const isAdvisor = useSelector(
    (state: State) => state.main_Reducer.user.isAdvisor
  );

  const accountsText =
    props.accountsInTradingGroup.length === 0
      ? "this trading group"
      : props.accountsInTradingGroup.length === 1
      ? props.accountsInTradingGroup[0].displayName
      : "these accounts";

  return (
    <Modal closeModal={props.closeModal} modalStyle={{ width: "520px" }}>
      <p className={styles.modalHeader}>
        {`Turn ${props.toggleOnToOff ? "Off" : "On"} Trading`}
      </p>

      <p className={styles.modalText}>
        {props.toggleOnToOff
          ? `Beginning with the next business day, Farther will no longer automatically evaluate ${accountsText} for rebalancing.`
          : `Beginning with the next business day, Farther will begin automatically evaluating ${accountsText} for rebalancing on a daily basis and executing trades as necessary. ${
              isAdvisor ? ADVISOR_NOTE_FOR_REBALANCING : ""
            }`}
      </p>

      {props.isMutating ? (
        <div className={styles.modalButtonsDiv}>
          <Skeleton width={70} height={35} style={{ transform: "none" }} />

          <Skeleton
            width={70}
            height={35}
            style={{ transform: "none", marginLeft: "20px" }}
          />
        </div>
      ) : (
        <div className={styles.modalButtonsDiv}>
          <Button
            variant={"outlined"}
            buttonType={"secondary"}
            text={"Cancel"}
            onClick={props.closeModal}
          />

          <Button
            variant={"contained"}
            buttonType={"primary"}
            text={"Confirm"}
            onClick={props.onConfirm}
            style={{ marginLeft: "20px" }}
          />
        </div>
      )}
    </Modal>
  );
};

export default ToggleTradingSwitchModal;
