export const serviceProfessionalOptions = [
  "Accountant",
  "Estate Lawyer",
  "Financial Advisor",
  "Property Manager",
  "Insurance Broker",
] as const;

export type ServiceProfessionalTypeOption =
  typeof serviceProfessionalOptions[number];

export const serviceProfessionalTypes = [
  "Accountant",
  "EstateLawyer",
  "FinancialAdvisor",
  "PropertyManager",
  "InsuranceBroker",
] as const;
