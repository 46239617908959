import React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import isNull from "lodash/isNull";

import { useStyles } from "../../UserAccounts.styles";
import { formatters } from "@src/yellowstone/modules/shared";
import type { UserAccount } from "@src/yellowstone/modules/users";

interface AccountEntryProps {
  className?: string;
  account: UserAccount;
}

export const AccountEntry: React.FC<AccountEntryProps> = ({
  className,
  account,
}) => {
  const { classes } = useStyles();

  return (
    <Stack
      className={className}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography className={classes.accountName}>
        <span className={classes.accountId}>{account.custodianAccountId}</span>{" "}
        - {account.displayName}
      </Typography>

      <Stack direction="row" alignItems="center">
        <Typography className={classes.balance}>
          {!isNull(account.balance)
            ? formatters.currencyFormatter().format(account.balance)
            : "N/A"}
        </Typography>
      </Stack>
    </Stack>
  );
};
