import React, { useMemo } from "react";

import { range } from "lodash";

import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import FullDataTable from "@src/sharedComponents/Table/FullDataTable";
import { Cell, Row } from "@src/sharedComponents/Table/Types";

import { rowsPerPage, TableKey, tableKeys } from "./ModelSecuritiesTable";

const ModelSecuritiesTableLoading = (): JSX.Element => {
  const LOADING_DATA: Row<TableKey, Cell>[] = useMemo(() => {
    return range(rowsPerPage).map((i) => {
      return {
        key: `${i}`,
        "Model Name": { value: <Skeleton /> },
        "Direct-Indexed": { value: <Skeleton /> },
        "Tax Status": { value: <Skeleton /> },
      };
    });
  }, []);

  return (
    <FullDataTable
      isLoading={true}
      columns={tableKeys}
      rows={LOADING_DATA}
      defaultRowsPerPage={rowsPerPage}
      defaultSortColumn={undefined}
      disableColumnSorting={tableKeys}
      emptyCell={<Skeleton />}
    />
  );
};

export default ModelSecuritiesTableLoading;
