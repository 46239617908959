import React from "react";

import InfoIcon from "@mui/icons-material/Info";

import BorderBox from "../BorderBox/BorderBox";
import BorderBoxHeader from "../BorderBox/BorderBoxHeader";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./InfoBox.module.css";

interface Props {
  text: string;
  className?: string;
}

const InfoBox: React.FC<Props> = ({ text, className }): JSX.Element => {
  return (
    <BorderBox className={toClassName(styles.borderBox, className)}>
      <BorderBoxHeader
        start={<InfoIcon className={styles.infoIcon} />}
        end={<div className={styles.text}>{text}</div>}
      />
    </BorderBox>
  );
};

export default InfoBox;
