import React from "react";

import styles from "./ProjectionsTab.module.css";

const ProjectionsLegendForDashboard = (): JSX.Element => {
  return (
    <div className={styles.legendContainer}>
      <div className={styles.legendHeader}>Monte Carlo Simulation</div>

      <div className={styles.rightContainer}>
        <div className={styles.legendDiv}>
          <div className={styles.bar90} />
          <div className={styles.legendText}>90%</div>
        </div>

        <div className={styles.legendDiv}>
          <div className={styles.bar50} />
          <div className={styles.legendText}>50%</div>
        </div>

        <div className={styles.legendDiv}>
          <div className={styles.bar10} />
          <div className={styles.legendText}>10%</div>
        </div>
      </div>
    </div>
  );
};

export default ProjectionsLegendForDashboard;
