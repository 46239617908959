import { useDebugValue, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";

import { FartherToken } from "@fartherfinance/frontend/api/Identity/requests/exchangeStytchToken";

import useLogIntoRedux from "../useLogIntoRedux";
import useNavigateOnLogin from "../useNavigateOnLogin";
import { magicCheckLogin } from "@src/magic";
import { LocalStorageKeys } from "@src/multiCustodian/components/Login/constants";
import { MCReduxAction, State } from "@src/store";

type LoggedInState = {
  isLoggedIn: boolean;
};

type Return =
  | { isLoading: true; hasError: false; data: undefined }
  | { isLoading: false; hasError: false; data: LoggedInState }
  | { isLoading: false; hasError: true; data: undefined };

export default function useCheckSavedAdvisorMagicSession(): Return {
  const { isAdvisor, isAdmin, advisorId, jwt } = useSelector(
    (state: State) => ({
      isAdvisor: state.main_Reducer.user.isAdvisor,
      advisorId: state.main_Reducer.cockroach_advisor_id,
      isAdmin: state.main_Reducer.isAdmin,
      jwt: state.main_Reducer.farther_jwt,
    })
  );

  const isFartherToken = jwt?.startsWith("ffp_");

  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);

  const isLoading = isLoggedIn === null;

  const history = useHistory();

  useDebugValue(
    `useCheckSavedAdvisorMagicSession – isLoggedIn: ${
      isLoggedIn ? "Yes" : "No"
    }, isAdvisor: ${
      isAdvisor === null ? "null" : isAdvisor ? "Yes" : "No"
    }, isAdmin: ${isAdmin ? "Yes" : "No"}`
  );

  const dispatch = useDispatch<Dispatch<MCReduxAction>>();
  const navigateOnLogin = useNavigateOnLogin();
  const logIntoRedux = useLogIntoRedux();

  useEffect(() => {
    if (isFartherToken === true) {
      console.log(
        "useCheckSavedAdvisorMagicSession - Setting as logged in using Farther token"
      );

      setIsLoggedIn(true);

      return;
    }

    try {
      const savedFartherToken = localStorage.getItem(LocalStorageKeys.token);
      const savedPersonId = localStorage.getItem(LocalStorageKeys.personId);
      const savedPersonType = localStorage.getItem(LocalStorageKeys.personType);

      if (
        savedFartherToken?.startsWith("ffp_") &&
        savedPersonId !== null &&
        savedPersonType !== null
      ) {
        console.log(
          "Logging in from useCheckSavedAdvisorMagicSession using Stytch saved"
        );

        logIntoRedux(
          savedPersonId,
          savedPersonType as "Advisor" | "Client",
          savedFartherToken as FartherToken
        );

        navigateOnLogin(savedPersonType as "Advisor" | "Client");

        // Ignore Magic
        return;
      }
    } catch (err) {
      console.error("useLogIntoSavedAdvisorSession", err);
    }

    magicCheckLogin("Advisor")
      .then(async (isLoggedIn) => {
        console.log("useCheckSavedAdvisorMagicSession isLoggedIn", isLoggedIn);

        setIsLoggedIn(isLoggedIn);
      })
      .catch((err) => {
        setIsLoggedIn(false);

        console.error("useCheckSavedAdvisorMagicSession", err);
      });
  }, [
    advisorId,
    dispatch,
    history,
    isAdvisor,
    isFartherToken,
    logIntoRedux,
    navigateOnLogin,
  ]);

  return useMemo((): Return => {
    if (isLoading) {
      return { isLoading, hasError: false, data: undefined };
    } else {
      return { isLoading: false, hasError: false, data: { isLoggedIn } };
    }
  }, [isLoading, isLoggedIn]);
}
