import React from "react";

import { truncate } from "lodash";

import useGetPlanAccountDetails from "@fartherfinance/frontend/api/Accounts/hooks/useGetPlanAccountDetails";
import usePlanDetailsV2 from "@fartherfinance/frontend/api/Accounts/hooks/usePlanDetailsV2";
import { FartherAccountId, PlanId } from "@fartherfinance/frontend/api/Types";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";

import styles from "./DrawerHeader.module.css";

interface Props {
  planId: PlanId;
  accountId: FartherAccountId;
}

export default function DrawerHeader(props: Props): JSX.Element {
  const auth = useAdvisorRequestAuth();
  const planDetails = usePlanDetailsV2(props.planId, auth);
  const accountDetails = useGetPlanAccountDetails(
    props.planId,
    props.accountId,
    auth
  );

  if (planDetails.data === undefined || accountDetails.data === undefined) {
    return <></>;
  }
  const planHolder = planDetails.data.plan.planHolder;

  const clientLabel = `${planHolder.name.first} ${
    planHolder.name.last
  } (...${planHolder.clientId.slice(-5)})`;

  const planName = truncate(planDetails.data.plan.planTitle, {
    length: 15,
  });

  const accountType = accountDetails.data.accountInformation.accountType;

  return (
    <div className={styles.header}>
      {clientLabel} | {planName} |{" "}
      <span>
        {accountType === "Rollover IRA" ? "Roll Over IRA" : accountType} Account
      </span>
    </div>
  );
}
