export const getSecuritiesLabel = (securitiesLength: number): string => {
  if (securitiesLength === 0) {
    return "No Securities";
  }

  if (securitiesLength === 1) {
    return "1 Security";
  }

  return `${securitiesLength} Securities`;
};
