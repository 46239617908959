import React, { useMemo, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import { orderBy } from "lodash";

import { TimeFrame } from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Types";
import useGetAccountGroupsV4 from "@fartherfinance/frontend/api/PerformanceGroups/hooks/useGetAccountGroupsV4";
import { PerformanceGroupId } from "@fartherfinance/frontend/api/Types";

import CreateAccountGroupModal from "../Modals/Create/CreateAccountGroupModal";
import ManageAccountGroupsModal from "../Modals/Manage/ManageAccountGroupsModal";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

import MenuActionItem from "./DropdownMenuActionItem";
import CustomPerformanceGroupsDropdownMenuItem from "./DropdownMenuItem";

import styles from "./DropdownMenu.module.css";

interface Props {
  width?: number;
  accoundGroupId: PerformanceGroupId;
  setAccountGroupId: (agId: PerformanceGroupId) => void;
  setAwaitingAccountGroupId: (agId: PerformanceGroupId) => void;
  closeDropdown: () => void;
  setTimeFrame: (range: TimeFrame) => void;
}

const CustomPerformanceGroupsDropdownMenu: React.FC<Props> = (props: Props) => {
  const [showCreateAccountGroupModal, setShowCreateAccountGroupModal] =
    useState<boolean>(false);
  const [showManageAccountGroupsModal, setShowManageAccountGroupsModal] =
    useState<boolean>(false);

  const auth = useRequestAuth();
  const accountGroups = useGetAccountGroupsV4(auth);

  const orderedNonSingleAccountGroups = useMemo(() => {
    if (accountGroups.data === undefined) {
      return [];
    }

    const notSingleGroups = accountGroups.data.filter(
      (ag) => ag.accountGroupType !== "SINGLE_CLIENT_ACCOUNT"
    );

    return orderBy(notSingleGroups, [(ag) => ag.displayName], "asc");
  }, [accountGroups.data]);

  const orderedSingleAccountGroups = useMemo(() => {
    if (accountGroups.data === undefined) {
      return [];
    }

    const singleAccountGroups = accountGroups.data.filter(
      (ag) => ag.accountGroupType === "SINGLE_CLIENT_ACCOUNT"
    );

    return orderBy(singleAccountGroups, [(ag) => ag.displayName], "asc");
  }, [accountGroups.data]);

  return (
    <>
      {showCreateAccountGroupModal && (
        <CreateAccountGroupModal
          closeModal={() => setShowCreateAccountGroupModal(false)}
          setAwaitingAccountGroupId={props.setAwaitingAccountGroupId}
          closeDropdown={props.closeDropdown}
          setTimeFrame={props.setTimeFrame}
        />
      )}

      {showManageAccountGroupsModal && (
        <ManageAccountGroupsModal
          closeModal={() => setShowManageAccountGroupsModal(false)}
          setAwaitingAccountGroupId={props.setAwaitingAccountGroupId}
          closeDropdown={props.closeDropdown}
          setTimeFrame={props.setTimeFrame}
        />
      )}

      <div
        className={styles.container}
        style={props.width ? { width: `${props.width}px` } : {}}
      >
        <div className={styles.listContainer}>
          <div className={styles.listHeader}>Groups</div>

          <div className={styles.scrollContainer}>
            {accountGroups.data ? (
              orderedNonSingleAccountGroups.map((ag) => (
                <CustomPerformanceGroupsDropdownMenuItem
                  key={ag.accountGroupId}
                  name={ag.displayName}
                  isFavorite={ag.isFavorite}
                  isChecked={ag.accountGroupId === props.accoundGroupId}
                  setAccountGroupId={() => {
                    props.setAccountGroupId(ag.accountGroupId);
                    props.setTimeFrame("1Y"); // reset to default
                  }}
                />
              ))
            ) : (
              <div className={styles.loadingText}>...loading</div>
            )}
          </div>
        </div>

        <div className={styles.listContainer}>
          <div className={styles.listHeader}>Accounts</div>

          <div className={styles.scrollContainer}>
            {accountGroups.data ? (
              orderedSingleAccountGroups.map((ag) => (
                <CustomPerformanceGroupsDropdownMenuItem
                  key={ag.accountGroupId}
                  name={ag.displayName}
                  isFavorite={ag.isFavorite}
                  isChecked={ag.accountGroupId === props.accoundGroupId}
                  setAccountGroupId={() => {
                    props.setAccountGroupId(ag.accountGroupId);
                    props.setTimeFrame("1Y"); // reset to default
                  }}
                />
              ))
            ) : (
              <div className={styles.loadingText}>...loading</div>
            )}
          </div>
        </div>

        <div className={styles.listContainer}>
          <MenuActionItem
            text={"New group"}
            icon={<AddIcon className={styles.icon} />}
            onClick={() => setShowCreateAccountGroupModal(true)}
          />

          <MenuActionItem
            text={"Manage groups"}
            icon={<SettingsIcon className={styles.icon} />}
            onClick={() => setShowManageAccountGroupsModal(true)}
          />
        </div>
      </div>
    </>
  );
};

export default CustomPerformanceGroupsDropdownMenu;
