import React from "react";

import CheckboxSectionItem from "../../../shared/SelectResources/CheckboxSection/CheckboxSectionItem";
import { CheckboxItem } from "../../../shared/SelectResources/CheckboxSection/Types";
import { actions } from "../../reducer/actions";
import { useEditCollaboratorContext } from "../../reducer/Context";

interface Props {
  item: CheckboxItem;
}

const EditCollaboratorSelectResourcesCheckboxStateAndDispatcher: React.FC<
  Props
> = ({ item: { resource, labelTop, labelBottom } }) => {
  const { state, dispatch } = useEditCollaboratorContext();

  const {
    data: { resources },
  } = state;

  const isChecked = resources.some((r) => r.resourceId === resource.resourceId);

  const handleToggleCheckbox = (bool: boolean) => {
    if (bool) {
      dispatch({ type: actions.ADD_RESOURCE, payload: { ...resource } });
    } else {
      dispatch({ type: actions.REMOVE_RESOURCE, payload: resource.resourceId });
    }
  };

  return (
    <CheckboxSectionItem
      isChecked={isChecked}
      handleToggleCheckbox={handleToggleCheckbox}
      labelTop={labelTop}
      labelBottom={labelBottom}
    />
  );
};

export default EditCollaboratorSelectResourcesCheckboxStateAndDispatcher;
