import React, { useRef } from "react";

interface UploadProps {
  onChange: (files: FileList | null) => void;
  renderChild: (onClick: () => void) => React.ReactNode;
  multiple?: HTMLInputElement["multiple"];
  accept?: HTMLInputElement["accept"];
}

export default function Upload({
  multiple,
  accept,
  onChange,
  renderChild,
}: UploadProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnClick = (): void => {
    inputRef.current?.click();
  };

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        hidden
        multiple={multiple}
        accept={accept}
        onChange={(event) => {
          onChange(event.target.files);
          // NOTE: as this input is permanently hidden we can clean up the value
          // right after we call out onChange function
          event.target.value = "";
        }}
      />
      {renderChild(handleOnClick)}
    </>
  );
}
