import React, { useState } from "react";

import { Divider, Fade, Stack, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import useGetOpportunity from "@fartherfinance/frontend/api/Opportunities/hooks/useGetOpportunity";
import usePatchOpportunity from "@fartherfinance/frontend/api/Opportunities/hooks/usePatchOpportunity";
import usePostOpportunityStatus from "@fartherfinance/frontend/api/Opportunities/hooks/usePostOpportunityStatus";
import { OpportunityId } from "@fartherfinance/frontend/api/Types";

import Opportunities from "../../../Opportunities";
import Drawer from "@src/multiCustodian/components/Drawer/Drawer";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import {
  mapValueToLabel,
  opportunityTypeMap,
} from "@src/multiCustodian/pages/Advisor/Opportunities/utils";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

import OpportunityCanceledForm from "./Components/OpportunityCanceledForm/OpportunityCanceledForm";
import OpportunityCommonDetails from "./Components/OpportunityCommonDetails/OpportunityCommonDetails";
import OpportunityCompletedForm from "./Components/OpportunityCompletedForm/OpportunityCompletedForm";
import OpportunityDrawerFooter from "./Components/OpportunityDrawerFooter/OpportunityDrawerFooter";
import OpportunityDrawerHeader from "./Components/OpportunityDrawerHeader/OpportunityDrawerHeader";
import OpportunitySnoozedForm from "./Components/OpportunitySnoozedForm/OpportunitySnoozedForm";
import {
  areStatusesEqual,
  FormData,
  getOpportunityOfferingDescription,
  isMarketplaceOfferingOpportunity,
  mapFormDataToPayload,
  mapOpportunityToFormData,
} from "./OpportunityDetails.utils";

import styles from "./OpportunityDetails.module.css";

interface OpportunityDetailsProps {
  opportunityId: OpportunityId;
}

export default function OpportunityDetails({
  opportunityId,
}: OpportunityDetailsProps): JSX.Element {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [isMutating, setIsMutating] = useState<boolean>(false);
  const history = useHistory();

  const auth = useAdvisorRequestAuth();
  const getOpportunityQuery = useGetOpportunity(opportunityId, auth);
  const callPatchOpportunity = usePatchOpportunity(auth);
  const callPostOpportunityStatus = usePostOpportunityStatus(auth);
  const statusNotification = useStatusNotification();

  const { data: opportunity, isLoading, hasError } = getOpportunityQuery;
  const shouldShowHeaderAndFooter = !isLoading && !hasError;

  const form = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      status: "NOT_STARTED",
      priority: "MEDIUM",
    },
    values: opportunity && mapOpportunityToFormData(opportunity),
  });

  const opportunityStatus = form.watch("status");
  const { dirtyFields } = form.formState;
  const isOpportunityStatusDirty = dirtyFields.status;
  const isSubmittable = Object.keys(dirtyFields).length > 0;

  const handleNavigateToOpportunitiesList = (): void => {
    history.push({
      pathname: "/Advisor/Opportunities",
      search: history.location.search,
    });
  };

  const handleFormSubmit = (): void => {
    form.handleSubmit(async (formData) => {
      if (!opportunity) {
        throw new Error("Opportunity details are not available");
      }

      try {
        setIsMutating(true);

        // NOTE: if status is unchanged we should call PATCH endpoint
        const updateOpportunity = areStatusesEqual(
          opportunity.status,
          formData.status
        )
          ? callPatchOpportunity
          : callPostOpportunityStatus;

        await updateOpportunity({
          opportunityId: opportunityId,
          request: mapFormDataToPayload(formData),
        });
        statusNotification("Opportunity updated successfully.", "Success");
      } catch {
        statusNotification(
          "Something went wrong, please try again later.",
          "Error"
        );
      } finally {
        setIsMutating(false);
      }
    })();
  };

  const handleFormReset = (): void => {
    form.reset();
  };

  return (
    <Opportunities>
      <FormProvider {...form}>
        <Drawer
          isDrawerOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          SlideProps={{ onExited: handleNavigateToOpportunitiesList }}
          {...(shouldShowHeaderAndFooter && {
            header: <OpportunityDrawerHeader />,
            footer: isSubmittable && (
              <OpportunityDrawerFooter
                isPending={isMutating}
                onSave={handleFormSubmit}
                onCancel={handleFormReset}
              />
            ),
          })}
        >
          {opportunity && (
            <Fade in>
              <Stack className={styles.container}>
                <div className={styles.header}>
                  <Typography className={styles.title}>
                    {mapValueToLabel(opportunityTypeMap, opportunity.type)}
                  </Typography>

                  {isMarketplaceOfferingOpportunity(opportunity) && (
                    <>
                      <Typography className={styles.description}>
                        {getOpportunityOfferingDescription(
                          opportunity.clientDetails,
                          opportunity.typeAttributes.value.assetType,
                          opportunity.typeAttributes.value.interestType
                        )}
                      </Typography>

                      <Divider className={styles.divider} />
                    </>
                  )}
                </div>

                {(!isOpportunityStatusDirty ||
                  opportunityStatus === "NOT_STARTED" ||
                  opportunityStatus === "IN_PROGRESS" ||
                  opportunityStatus === "AWAITING_RESPONSE") && (
                  <OpportunityCommonDetails opportunity={opportunity} />
                )}

                {opportunityStatus === "COMPLETED" && (
                  <OpportunityCompletedForm
                    opportunityType={opportunity.type}
                  />
                )}
                {opportunityStatus === "SNOOZED" && <OpportunitySnoozedForm />}
                {opportunityStatus === "CANCELED" && (
                  <OpportunityCanceledForm />
                )}
              </Stack>
            </Fade>
          )}

          {hasError && (
            <Stack
              className={styles.errorContainer}
              justifyContent="center"
              alignItems="center"
            >
              <Typography className={styles.errorMessage}>
                Something went wrong, please try again later.
              </Typography>
            </Stack>
          )}

          {(isLoading || isMutating) && (
            <div className={styles.loading}>
              <LogoLoadingStill />
            </div>
          )}
        </Drawer>
      </FormProvider>
    </Opportunities>
  );
}
