import React, { useCallback, useEffect, useState } from "react";

import { Stack } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import useSleeveBilling from "@fartherfinance/frontend/api/TradingGroups/hooks/useSleeveBilling";
import { PostGetSleeveBilling } from "@fartherfinance/frontend/api/TradingGroups/requests/postGetSleeveBilling";
import {
  FullCustodian,
  fullCustodianOptions,
} from "@fartherfinance/frontend/api/Types";

import Drawer from "../../Drawer/Drawer";
import ButtonPrimary from "../../MUI/Button/Button";
import { FullCustodianInclAll, fullCustodianOptionsInclAll } from "../types";
import { downloadToFile } from "../utils/downloadToFile";
import { useMonthList } from "../utils/useMonthList";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

import styles from "../FAMBilling/FAMBilling.module.css";

const MonthYear = "MonthYear";
const Custodian = "Custodian";

interface Form {
  [MonthYear]: { label: string; value: string } | undefined;
  [Custodian]: { label: FullCustodianInclAll } | undefined;
}

const SleeveBilling = (): JSX.Element => {
  const history = useHistory();

  const monthList = useMonthList();

  const [mutating, setMutating] = useState(false);

  const methods = useForm<Form>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      [MonthYear]: undefined,
      [Custodian]: undefined,
    },
  });

  const statusNotification = useStatusNotification();

  const { enableAdminSleeveBilling } = useFlags();

  const auth = useAdvisorRequestAuth();
  const getSleeveBilling = useSleeveBilling(auth);

  useEffect(() => {
    if (!enableAdminSleeveBilling) {
      history.push({ ...history.location, pathname: "/Advisor/Clients" });
    }
  }, [enableAdminSleeveBilling, history]);

  const {
    getValues,
    formState: { isValid, isDirty },
  } = methods;

  const onClose = useCallback(
    () => history.push({ ...history.location, pathname: "/Advisor/Admin" }),
    [history]
  );

  const submit = async () => {
    if (mutating) {
      statusNotification("Form is being submitted", "Error");
      return;
    }

    if (!isDirty) {
      statusNotification("Nothing to submit", "Error");
      return;
    }

    if (!isValid) {
      statusNotification("Some fields are incomplete", "Error");
      return;
    }

    const values = getValues();

    if (values[MonthYear] === undefined) {
      statusNotification("Need to select a billing period", "Error");
      return;
    }

    if (values[Custodian] === undefined) {
      statusNotification("Need to select a custodian", "Error");
      return;
    }

    const form: PostGetSleeveBilling = {
      date: values[MonthYear].value,
      custodians:
        values[Custodian].label === "All"
          ? (fullCustodianOptions as unknown as FullCustodian[])
          : [values[Custodian].label],
    };

    try {
      setMutating(true);

      const res = await getSleeveBilling(form);

      if (res.content?.length > 0) {
        downloadToFile(res.content, res.filename, "text/csv");

        statusNotification("Downloaded billing CSV", "Success");

        onClose();
      } else {
        statusNotification(
          "There is no billing data for that custodian and period",
          "Error"
        );
      }
    } catch {
      statusNotification("Failed to download billing CSV", "Error");
    } finally {
      setMutating(false);
    }
  };

  return (
    <Drawer
      isDrawerOpen
      onClose={onClose}
      footer={
        <Stack direction="row" gap="16px">
          <ButtonPrimary
            text="Cancel"
            variant="outlined"
            buttonType="primary"
            onClick={onClose}
          />

          <ButtonPrimary
            disabled={!isDirty || !isValid || mutating}
            text="Download CSV"
            variant="contained"
            buttonType="primary"
            onClick={submit}
          />
        </Stack>
      }
    >
      {mutating && <LogoLoadingStill onTop />}
      <FormProvider {...methods}>
        <div className={styles.title}>Sleeve Billing</div>

        <div className={styles.text}>
          Select the month and custodian for which we should compile billing
          records
        </div>

        <FlexWrapper>
          <FormDropdownField
            name={MonthYear}
            label={"Month"}
            values={monthList}
            disableSearch
            required={"Must not be empty"}
          />

          <FormDropdownField
            name={Custodian}
            label={"Custodian"}
            values={fullCustodianOptionsInclAll.map((c) => ({ label: c }))}
            disableSearch
            required={"Must not be empty"}
          />
        </FlexWrapper>
      </FormProvider>
    </Drawer>
  );
};

export default SleeveBilling;
