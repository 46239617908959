import React from "react";

import { Box } from "@mui/system";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import Modal from "@src/sharedComponents/Modal/Modal";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./modal.module.css";

interface Props {
  closeModal: () => void;
  isActivating: boolean;
  isMutating: boolean;
  onConfirm: () => void;
}

const ActivateBody: React.FC = () => (
  <>
    <p className={styles.modalHeader}>
      Activate Equivalent Securities Protection
    </p>

    <p className={styles.modalText}>
      After toggling this feature on, Farther will attempt to prevent trades
      that involve selling one security to buy another with substantially
      similar investment exposure. Determining which securities are sufficiently
      similar is at Farther's discretion. You accept the risk that these
      securities may differ in practice.
    </p>

    <p className={styles.modalText}>
      Once activated, you will see a modified version of your target model
      portfolio (appended with "ES") which includes the appropriate security
      swaps.
    </p>
  </>
);

const DeactivateBody: React.FC = () => (
  <>
    <p className={styles.modalHeader}>
      Deactivate Equivalent Securities Protection{" "}
    </p>

    <p className={styles.modalText}>
      After toggling this feature off, Farther can not attempt to prevent trades
      that involve selling one security to buy another with substantially
      similar investment exposure.
    </p>
  </>
);

const EquivalentSecuritiesModal: React.FC<Props> = ({
  closeModal,
  isActivating,
  isMutating,
  onConfirm,
}) => (
  <Modal closeModal={closeModal} modalStyle={{ width: "520px" }}>
    <>
      {isActivating ? <ActivateBody /> : <DeactivateBody />}

      {!isMutating && (
        <div className={styles.modalButtonsDiv}>
          <Button
            variant={"outlined"}
            buttonType={"secondary"}
            text={"Cancel"}
            onClick={closeModal}
          />

          <Button
            variant={"contained"}
            buttonType={"primary"}
            text={isActivating ? "Activate" : "Deactivate"}
            onClick={onConfirm}
            style={{ marginLeft: "20px" }}
          />
        </div>
      )}
    </>

    {isMutating && (
      <div>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LogoLoadingStill onTop />
        </Box>
        <div className={styles.modalButtonsDiv}>
          <Skeleton width={76} height={36} style={{ transform: "none" }} />

          <Skeleton
            width={84}
            height={36}
            style={{ transform: "none", marginLeft: "20px" }}
          />
        </div>
      </div>
    )}
  </Modal>
);

export default EquivalentSecuritiesModal;
