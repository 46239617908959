import type { GetUsersSearchQueryParams } from "./types";

export const parseUsersSearchQueryParams = (
  value: string
): GetUsersSearchQueryParams => {
  if (value.match(/^[0-9]+$/)) {
    return {
      searchById: value,
    };
  } else {
    return {
      searchByName: value,
    };
  }
};
