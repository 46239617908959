import { alpha } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

export const useStyles = makeStyles()((theme) => ({
  header: {
    fontFamily: theme.typography.fontSecondary,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "19px",
    letterSpacing: "0.63px",
    color: theme.appearance.$textSubtle,

    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: alpha(theme.appearance.$borderSelected, 0.1),

    padding: theme.spacing(0, pxToSpacing(10), pxToSpacing(8)),
  },
}));
