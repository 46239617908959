import React, { useState } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import { format } from "date-fns";

import usePatchInvitation from "@fartherfinance/frontend/api/Sharing/hooks/usePatchInvitation";
import { ReceivedInvitation } from "@fartherfinance/frontend/api/Sharing/Types";

import { getAccessLevelForDisplay } from "../utils/getAccessLevelForDisplay";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";

import styles from "./SharedWithYouTable.module.css";

interface Props {
  invitation: ReceivedInvitation;
}

const PendingInvitationRow: React.FC<Props> = ({ invitation }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const statusNotification = useStatusNotification();

  const auth = useRequestAuth();
  const patchInvitation = usePatchInvitation(auth);

  const onSetInvitationStatus = async (status: "Active" | "Rejected") => {
    try {
      setIsLoading(true);

      await patchInvitation({
        invitationId: invitation.invitationId,
        body: { status },
      });

      statusNotification(
        `Invitation ${status === "Active" ? "accepted" : "rejected"}`,
        "Success"
      );
    } catch (_e) {
      statusNotification(
        `Error ${status === "Active" ? "accepting" : "rejecting"} invitation`,
        "Error"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.row}>
      <div className={styles.flexRow}>
        {isLoading && (
          <div className={styles.loader}>
            <CircularProgress color={"inherit"} size={20} />
          </div>
        )}

        <div className={styles.rowTextFront}>
          {`${invitation.invitor.firstName} ${invitation.invitor.lastName}`}
        </div>

        <div className={styles.rowTextBack}>
          {`wants to give you ${getAccessLevelForDisplay(
            invitation.accessLevel
          )} (${format(invitation.createdAt, "MMMM d, y")})`}
        </div>
      </div>

      <div className={styles.flexRow}>
        <Button
          variant="outlined"
          buttonType="primary"
          size="small"
          text={"Decline"}
          onClick={() => onSetInvitationStatus("Rejected")}
          sx={{ marginRight: "var(--space-2)" }}
          disabled={isLoading}
        />
        <Button
          variant={"contained"}
          buttonType={"primary"}
          size="small"
          text={"Accept"}
          onClick={() => onSetInvitationStatus("Active")}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};

export default PendingInvitationRow;
