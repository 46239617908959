import React from "react";

import BorderBox from "../BorderBox/BorderBox";

import PopoverMenuRow, { NavLinkMenuOption } from "./PopoverMenuRow";

import styles from "./PopoverMenu.module.css";

interface Props {
  items: NavLinkMenuOption[];
  bottomItem?: NavLinkMenuOption;
}

const PopoverMenu: React.FC<Props> = ({ items, bottomItem }: Props) => {
  if (items.length === 1) {
    return (
      <BorderBox className={styles.borderBox}>
        <PopoverMenuRow className={styles.singleRow} item={items[0]} />

        {bottomItem && (
          <PopoverMenuRow className={styles.bottomRow} item={bottomItem} />
        )}
      </BorderBox>
    );
  }

  return (
    <BorderBox className={styles.borderBox}>
      <div className={styles.topItemsContainer}>
        {items.map((item) => {
          return (
            <PopoverMenuRow
              key={item.label}
              className={styles.row}
              item={item}
            />
          );
        })}
      </div>

      {bottomItem && (
        <PopoverMenuRow className={styles.bottomRow} item={bottomItem} />
      )}
    </BorderBox>
  );
};

export default PopoverMenu;
