import { ProposalInternalStatus } from "@fartherfinance/frontend/api/Rebalance/Types";

import { mapProposalStatusToActionLabel } from "@src/yellowstone/modules/trades";

import type { DropdownMenuOption } from "../DropdownMenu/DropdownMenu.component";

export const generateProposalStatusOptions = (
  currentProposalStatus: ProposalInternalStatus
): DropdownMenuOption<ProposalInternalStatus>[] => {
  return [
    ProposalInternalStatus.enum.NEEDS_REVIEW,
    ProposalInternalStatus.enum.SKIP_PROPOSAL,
    currentProposalStatus === ProposalInternalStatus.enum.CONFIRM
      ? ProposalInternalStatus.enum.PROPOSED
      : ProposalInternalStatus.enum.CONFIRM,
  ].map((proposalStatus) => ({
    label: mapProposalStatusToActionLabel(proposalStatus),
    value: proposalStatus,
  }));
};

export const enrichProposalStatusOption = (
  proposalStatusOption: DropdownMenuOption<ProposalInternalStatus>,
  className: string
) => {
  return {
    ...proposalStatusOption,
    className,
  };
};
