import React, { PropsWithChildren } from "react";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./FormSubHeader.module.css";

interface Props {
  className?: string;
}

export default function FormSubHeader(
  props: PropsWithChildren<Props>
): JSX.Element {
  return (
    <div className={toClassName(styles.container, props.className)}>
      {props.children}
    </div>
  );
}
