import React from "react";

import { useFlags } from "launchdarkly-react-client-sdk";

import { Data } from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Types";
import { FartherAccountId } from "@fartherfinance/frontend/api/Types";

import { unknownBalanceValue } from "@src/constants/unknownBalanceValue";
import { Disclaimer } from "@src/multiCustodian/pages/Dashboard/Performance_Groups/PG_hardcoded_exports";
import PerformanceIndicatorArrow from "@src/sharedComponents/PerformanceIndicatorArrow/PerformanceIndicatorArrow";
import toUSD from "@src/utils/toUSD";

import * as styles from "./BreakdownTable.module.css";

interface Props {
  accountId: FartherAccountId | null;
  noData: boolean;
  balSumFirstItem: Data | null;
  balSumLastItem: Data | null;
  currentBalance: string | null;
  marketGains: number | null;
  deposits: string | null;
  withdrawals: string | null;
  timeWeightedReturns?: string | null;
  netAdditions: string | null;
}

const BreakdownTable = (props: Props) => {
  const isInDashboard = props.accountId === null;

  const { enableBdPerformancePassthrough } = useFlags();

  return (
    <>
      <div className={styles.data_summary_table}>
        <div
          className={
            isInDashboard
              ? styles.data_summary_table_header_dashboard
              : styles.data_summary_table_header
          }
        >
          Balance Breakdown
        </div>

        <div className={styles.data_summary_table_body}>
          <div className={styles.data_summary_table_item}>
            <p className={styles.tableTextItem}>Starting Balance</p>

            <p className={styles.tableTextItem}>
              {props.noData === false && props.balSumFirstItem
                ? toUSD(props.balSumFirstItem.balanceOnStartDay)
                : unknownBalanceValue}
            </p>
          </div>

          {enableBdPerformancePassthrough ? (
            <div className={styles.data_summary_table_item}>
              <p className={styles.tableTextItem}>Net Additions</p>
              {props.noData === false && props.balSumLastItem !== null ? (
                <p className={styles.tableTextItem}>
                  {props.netAdditions ?? unknownBalanceValue}
                </p>
              ) : (
                <p className={styles.tableTextItem}>{unknownBalanceValue}</p>
              )}
            </div>
          ) : (
            <>
              <div className={styles.data_summary_table_item}>
                <p className={styles.tableTextItem}>Deposits</p>
                {props.noData === false && props.balSumLastItem !== null ? (
                  <p className={styles.tableTextItem}>
                    {props.deposits ?? unknownBalanceValue}
                  </p>
                ) : (
                  <p className={styles.tableTextItem}>{unknownBalanceValue}</p>
                )}
              </div>

              <div className={styles.data_summary_table_item}>
                <p className={styles.tableTextItem}>Withdrawals</p>
                {props.noData === false ? (
                  <p className={styles.tableTextItem}>{props.withdrawals}</p>
                ) : (
                  <p className={styles.tableTextItem}>{unknownBalanceValue}</p>
                )}
              </div>
            </>
          )}

          <div className={styles.data_summary_table_item}>
            <p className={styles.tableTextItem}>Market Gains</p>

            {props.noData === false ? (
              <p className={styles.tableTextItem}>
                {props.marketGains
                  ? toUSD(props.marketGains)
                  : unknownBalanceValue}
              </p>
            ) : (
              <p className={styles.tableTextItem}>{unknownBalanceValue}</p>
            )}
          </div>

          {props.timeWeightedReturns !== undefined && (
            <div className={styles.data_summary_table_item_justUnder}>
              {props.noData === false && (
                <div className={styles.twrPerformance}>
                  <PerformanceIndicatorArrow
                    style={{ marginRight: "5px" }}
                    status={
                      props.timeWeightedReturns === null
                        ? "neutral"
                        : parseFloat(props.timeWeightedReturns) >= 0
                        ? "positive"
                        : "negative"
                    }
                  />

                  <span>
                    {props.timeWeightedReturns ?? unknownBalanceValue}
                  </span>
                </div>
              )}
            </div>
          )}

          <div className={styles.hr_line} />

          <div className={styles.data_summary_table_item}>
            <p className={styles.p_total}>
              {isInDashboard ? "Farther Managed Balance" : "Current Balance"}
            </p>

            {props.noData === false && props.balSumLastItem !== null ? (
              <p
                className={
                  isInDashboard
                    ? styles.p_total_right_green
                    : styles.p_total_right
                }
              >
                {props.currentBalance ?? unknownBalanceValue}
              </p>
            ) : (
              <p
                className={
                  isInDashboard
                    ? styles.p_total_right_green
                    : styles.p_total_right
                }
              >
                {unknownBalanceValue}
              </p>
            )}
          </div>
        </div>
      </div>

      {isInDashboard && (
        <div className={styles.disclaimerContainer}>
          <Disclaimer />
        </div>
      )}
    </>
  );
};

export default BreakdownTable;
