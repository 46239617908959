import React, { useCallback, useMemo, useState } from "react";

import { Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import { identity, orderBy, sample } from "lodash";
import { FormProvider, useForm, useFormState, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import useUploadClientDocument from "@fartherfinance/frontend/api/CustodianDoc/hooks/useUploadClientDocument";
import { Custodian } from "@fartherfinance/frontend/api/Dashboard/requests/getClientDashboard";
import useCreateClient, {
  Request,
} from "@fartherfinance/frontend/api/Entity/hooks/useCreateClient";
import useGetFartherEmployees from "@fartherfinance/frontend/api/Entity/hooks/useFartherEmployees";
import { Advisor } from "@fartherfinance/frontend/api/Entity/requests/getFartherEmployees";
import { custodianOptions as allCustodianOptions } from "@fartherfinance/frontend/api/Types";

import Drawer from "@src/multiCustodian/components/Drawer/Drawer";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import Clients from "@src/multiCustodian/pages/Advisor/Clients";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import FormExplanatoryText from "@src/sharedComponents/Forms/FormExplanatoryText";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import FormH2 from "@src/sharedComponents/Forms/FormH2";
import FormRadioField from "@src/sharedComponents/Forms/FormRadioField";
import FormSubHeader from "@src/sharedComponents/Forms/FormSubHeader";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import SingleFileDropUpload from "@src/sharedComponents/SingleFileDropUpload/SingleFileDropUpload";
import { State } from "@src/store";

import { emailValidationRegex } from "./constants";
import { checkEmail } from "./utils/checkEmail";
import firstNames from "./utils/firstNames";
import lastNames from "./utils/lastNames";

import styles from "./Form.module.css";

type StripUndefined<F extends object> = { [k in keyof F]: NonNullable<F[k]> };

interface Form {
  FirstName: string | undefined;
  LastName: string | undefined;
  Email: string | undefined;
  Advisor: (Advisor & { label: string }) | undefined;
  Custodian: { label: Custodian } | undefined;
  HasSignedAgreements: "Yes" | "No";
}

const yesNo = ["Yes", "No"] as const;
const isUAT = process.env.WEBAPP_ENV === "UAT";

export default function CreateClient(): JSX.Element {
  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));

  const history = useHistory();

  const location = useLocation();

  const auth = useAdvisorRequestAuth();

  const advisors = useGetFartherEmployees("All", auth);

  const statusNotification = useStatusNotification();

  const advisorList = useMemo(() => {
    return orderBy(
      (advisors.data?.advisors ?? [])
        .filter((a) => a.details.isAvailable) // Filter to only "actual" advisors
        .map((advisor) => ({
          advisorId: advisor.advisorId,
          label: `${advisor.name.first} ${advisor.name.last}`,
        })),
      [(advisor) => advisor.label],
      "asc"
    );
  }, [advisors]);

  const defaultAdvisor =
    advisorId !== null
      ? advisorList.find((advisor) => advisor.advisorId === advisorId)
      : undefined;

  const callPostClient = useCreateClient(auth);
  const callUploadClientDocument = useUploadClientDocument(auth);

  const sampleFirstName = useMemo(() => sample(firstNames) ?? "Bob", []);
  const sampleLastName = useMemo(() => sample(lastNames) ?? "Smith", []);
  const sampleEmail = useMemo(
    () =>
      `${sampleFirstName}.${sampleLastName}+${format(
        new Date(),
        "DDD" // Day number (1-365)
      )}@example.com`.toLowerCase(),
    [sampleFirstName, sampleLastName]
  );

  const methods = useForm<Form>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      Advisor: defaultAdvisor,
      FirstName: isUAT ? sampleFirstName : undefined,
      LastName: isUAT ? sampleLastName : undefined,
      Email: isUAT ? sampleEmail : undefined,
      HasSignedAgreements: "No",
    },
  });

  const { control, getValues, setValue } = methods;

  const { isValid, errors } = useFormState({
    control,
  });

  const [isMutating, setIsMutating] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [file, setFile] = useState<File | null>(null);

  const navigateToClientsList = () => {
    history.push({ pathname: "/Advisor/Clients", search: location.search });
  };

  const closeDrawer = useCallback((): void => {
    setIsDrawerOpen(false);
  }, []);

  const createClient = useCallback(async () => {
    if (!isValid || !advisorId) {
      return;
    }

    const values = getValues() as StripUndefined<Form>;

    const currentEmail = values.Email;
    const newEmail = currentEmail.toLowerCase();

    if (newEmail !== currentEmail) {
      statusNotification(
        `Email was changed to "${newEmail}" from "${currentEmail}"`,
        "Error"
      );
    }

    const form: Request = {
      advisorId: values.Advisor.advisorId,
      firstName: values.FirstName,
      lastName: values.LastName,
      emailAddress: newEmail,
      defaultCustodian: values.Custodian.label,
      hasSignedAgreement: values.HasSignedAgreements === "Yes",
    };

    try {
      setIsMutating(true);

      const {
        client: { clientId },
      } = await callPostClient(form);

      if (form.hasSignedAgreement && file) {
        await callUploadClientDocument({
          clientId,
          document: file,
          documentData: {
            path: "Farther%20Records/Signed%20Documents/Custom%20Agreements",
            agreementTemplate: "Custom Client Agreements (IAA, CRS, ADV)",
            signatureType: "eSignature",
          },
        });
      }

      statusNotification("New client created", "Success");

      closeDrawer();
    } catch (e) {
      console.error(e);

      const msg =
        e instanceof Error ? e.message : "Failed to create a new client";

      statusNotification(msg, "Error");
    } finally {
      setIsMutating(false);
    }
  }, [
    advisorId,
    callPostClient,
    callUploadClientDocument,
    file,
    statusNotification,
    getValues,
    isValid,
    closeDrawer,
  ]);

  const emailValue = useWatch({ control, name: "Email" });
  const hasSignedAgreementsValue = useWatch({
    control,
    name: "HasSignedAgreements",
  });
  const isClientAgreementsFileAttached =
    hasSignedAgreementsValue === "Yes" ? Boolean(file) : true;

  const suggestedEmail = useMemo(
    () => checkEmail((emailValue as string) ?? ""),
    [emailValue]
  );

  const warnOnEmail =
    emailValue !== undefined &&
    errors["Email"] === undefined &&
    /[a-z]+@[a-z]+\.[a-z]{2,3}/i.test((emailValue as string) ?? "") === false;

  const emailIsMixedCase =
    emailValue === undefined
      ? false
      : (emailValue as string).toLowerCase() !== (emailValue as string);

  return (
    <Clients>
      <Drawer
        isDrawerOpen={isDrawerOpen}
        onClose={closeDrawer}
        SlideProps={{ onExited: navigateToClientsList }}
        footer={
          <Footer
            onClose={closeDrawer}
            createClient={createClient}
            disabled={!isValid || isMutating || !isClientAgreementsFileAttached}
          />
        }
      >
        <FormProvider {...methods}>
          <FormH1>Create a Client</FormH1>

          <Spacer verticalSpacing={"8px"} />

          <FormSubHeader>
            Enter the client's info below to create the client. You can add
            other info later by logging in to the client portal.
          </FormSubHeader>

          <FlexWrapper>
            <FormTextField
              name="FirstName"
              label="First Name"
              required="Must not be empty"
            />

            <FormTextField
              name="LastName"
              label="Last Name"
              required="Must not be empty"
            />

            <div>
              <FormTextField
                name="Email"
                label="Email"
                required="Must not be empty"
                rules={{
                  validate: {
                    isValidEmail: () => {
                      const email = getValues("Email");

                      return !emailValidationRegex.test(email ?? "")
                        ? "Enter a valid email"
                        : true;
                    },
                  },
                }}
              />

              {suggestedEmail !== null ? (
                <FormExplanatoryText>
                  This may be an incorrect email, did you mean{" "}
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setValue("Email", suggestedEmail);
                    }}
                  >
                    {suggestedEmail}
                  </a>
                  ?
                </FormExplanatoryText>
              ) : warnOnEmail ? (
                <FormExplanatoryText>
                  This may be an incorrect email, please double check it
                </FormExplanatoryText>
              ) : emailIsMixedCase && typeof emailValue === "string" ? (
                <FormExplanatoryText>
                  Emails should be all lower case, did you mean{" "}
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setValue("Email", emailValue.toLowerCase());
                    }}
                  >
                    {emailValue.toLowerCase()}
                  </a>
                </FormExplanatoryText>
              ) : null}
            </div>

            <FormDropdownField
              name="Advisor"
              label="Advisor"
              required="Must not be empty"
              values={advisorList}
            />

            <FormDropdownField
              name="Custodian"
              label="Custodian"
              required="Must not be empty"
              values={orderBy(allCustodianOptions, identity, "asc").map(
                (custodian) => ({
                  label: custodian,
                })
              )}
            />
          </FlexWrapper>

          <Stack gap="8px" marginTop="38px">
            <FormH2>Client Agreements</FormH2>
            <FormRadioField
              name="HasSignedAgreements"
              label="Has the client already signed an IAA, ADV and CRS off of the Farther portal?"
              values={yesNo}
              required="Must not be empty"
            />
          </Stack>
        </FormProvider>

        {hasSignedAgreementsValue === "Yes" && (
          <Stack className={styles.uploadArea} gap="8px" marginTop="16px">
            <Typography className={styles.uploadText}>
              Upload a copy of the agreements combined into one file
            </Typography>
            <SingleFileDropUpload
              file={file}
              text="Drop your file here or"
              pressText="browse"
              onChange={setFile}
              acceptedFileExtensions={[
                ".pdf",
                ".png",
                ".jpg",
                ".jpeg",
                ".heic",
              ]}
            />
          </Stack>
        )}

        {isMutating && (
          <div className={styles.loading}>
            <LogoLoadingStill />
          </div>
        )}
      </Drawer>
    </Clients>
  );
}

interface FooterProps {
  onClose: () => void;
  createClient: () => void;
  disabled: boolean;
}

const Footer = (props: FooterProps) => {
  return (
    <div className={styles.footer}>
      <ButtonPrimary
        text={"Cancel"}
        onClick={props.onClose}
        variant={"outlined"}
        buttonType={"primary"}
      />

      <ButtonPrimary
        text={"Create Client"}
        onClick={props.createClient}
        variant={"contained"}
        buttonType={"primary"}
        disabled={props.disabled}
      />
    </div>
  );
};
