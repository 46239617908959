import React from "react";

import { TaxBudget } from "@fartherfinance/frontend/api/InvestmentModel/Types";

import AdvisorDetailsTable from "../../Advisor/TaxBudgets/DetailsTable/DetailsTable";
import ClientDetailsTable from "../../Client/TaxBudgets/DetailsTable/DetailsTable";

import DetailTabs from "./DetailTabs";

interface Props {
  budget?: TaxBudget;
  detailsPath: string;
  isAdvisor: boolean;
  usagePath: string;
}

const DetailsTab: React.FC<Props> = ({
  budget,
  detailsPath,
  isAdvisor,
  usagePath,
}) => {
  return (
    <div>
      <DetailTabs
        curTab="Budget Details"
        detailsPath={detailsPath}
        usagePath={usagePath}
      />
      {isAdvisor ? (
        <AdvisorDetailsTable budget={budget} />
      ) : (
        <ClientDetailsTable budget={budget} />
      )}
    </div>
  );
};

export default DetailsTab;
