import * as React from "react";

import { LoadingButton } from "@mui/lab";
import { ButtonProps, Button as MUIButton } from "@mui/material";
import {
  createTheme,
  SxProps,
  Theme,
  ThemeProvider,
} from "@mui/material/styles";
import { useSelector } from "react-redux";

import { UiTheme } from "@src/multiCustodian/reducers/main_Reducer";
import { State } from "@src/store";

import styles from "./Button.module.css";

export interface Props {
  text: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  style?: React.CSSProperties;
  variant: "contained" | "outlined" | "text";
  size?: "small" | "medium" | "large";
  buttonType:
    | "primary"
    | "secondary"
    | "inherit"
    | "success"
    | "error"
    | "info"
    | "warning";
  disabled?: boolean;
  href?: string;
  target?: string;
  sx?: SxProps<Theme>;
  classes?: ButtonProps["classes"];
  tabIndex?: number;
  onKeyDown?: React.KeyboardEventHandler<HTMLParagraphElement>;
  theme?: UiTheme;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  isLoading?: boolean;
  useLoader?: boolean;
}

const Button = (props: Props) => {
  //Note: a few components specifically require the 'farther_dark' palette,
  //so for now, we allow this to come through props and control which color palette is selected.
  const { palette } = useSelector((state: State) => ({
    palette: state.main_Reducer.css_color_palettes[props.theme ?? "current"],
  }));

  const primDisabled = palette["--button_primary_color_disabled_1"];
  const primDefault = palette["--button_primary_color_default_1"];
  const secDefault = palette["--button_secondary_color_default_1"];

  const primTxtDefault = palette["--button_primary_text_color_default_1"];
  const secTxtDefault = palette["--button_secondary_text_color_default_1"];
  const primTxtDisabled = palette["--button_primary_text_color_disabled_1"];
  const secTxtDisabled = palette["--button_secondary_text_color_disabled_1"];

  const negative = palette["--bad_negative_color"];
  const positive = palette["--positive_performance_up_color"];

  const buttonSx: SxProps = React.useMemo(
    () => ({
      textTransform: "unset !important",
      "&.MuiButton-sizeSmall": {
        fontSize: 12,
      },
    }),
    []
  );

  return (
    <React.Fragment>
      {props.tabIndex !== null && props.tabIndex !== undefined ? (
        <p
          className={
            props.disabled ? styles.container : styles.container_withhover
          }
          style={{
            backgroundColor: props.disabled ? primDisabled : primDefault,
            color: primTxtDefault,
            cursor: props.disabled ? "default" : "pointer",
          }}
          onKeyDown={props.onKeyDown}
          tabIndex={props.tabIndex}
          onClick={props.disabled ? () => undefined : (e) => props.onClick(e)}
        >
          {props.text}
        </p>
      ) : props.href ? (
        <a
          className={
            props.disabled ? styles.container : styles.container_withhover
          }
          style={{
            backgroundColor: props.disabled ? primDisabled : primDefault,
            color: primTxtDefault,
            cursor: props.disabled ? "default" : "pointer",
          }}
          target={props.target}
          href={props.href}
        >
          {props.text}
        </a>
      ) : (
        <div
          className={styles.mui_btn_container}
          style={props.style ? props.style : {}}
        >
          <ThemeProvider
            theme={createTheme({
              palette: {
                primary: {
                  main: primDefault,
                },
                secondary: {
                  main: secDefault,
                },
                error: {
                  main: negative,
                },
                warning: {
                  main: negative,
                },
                info: {
                  main: positive,
                },
                success: {
                  main: positive,
                },
                text: {
                  primary: primTxtDefault,
                  secondary: secTxtDefault,
                  disabled:
                    props.buttonType === "primary" ||
                    props.buttonType === "warning"
                      ? primTxtDisabled
                      : secTxtDisabled,
                },
                action: {
                  active: primTxtDefault,
                  hover: primTxtDefault,
                  selected: primTxtDefault,
                  disabled:
                    props.buttonType === "primary" ||
                    props.buttonType === "warning"
                      ? primTxtDisabled
                      : secTxtDisabled,
                  disabledBackground: primDisabled,
                },
                background: {
                  default: primDefault,
                  paper: primDefault,
                },
              },
              typography: {
                fontFamily: "var(--fakt)",
                fontSize: 14,
              },
            })}
          >
            {props.useLoader === true && (
              <LoadingButton
                sx={{ ...buttonSx, ...props.sx }}
                classes={props.classes}
                variant={props.variant}
                size={props.size}
                color={props.buttonType}
                disabled={props.disabled}
                onClick={
                  props.disabled ? () => undefined : (e) => props.onClick(e)
                }
                startIcon={props.startIcon}
                endIcon={props.endIcon}
                loading={props.isLoading}
              >
                {props.text}
              </LoadingButton>
            )}
            {!props.useLoader && (
              <MUIButton
                sx={{ ...buttonSx, ...props.sx }}
                classes={props.classes}
                variant={props.variant}
                size={props.size}
                color={props.buttonType}
                disabled={props.disabled}
                onClick={
                  props.disabled ? () => undefined : (e) => props.onClick(e)
                }
                startIcon={props.startIcon}
                endIcon={props.endIcon}
              >
                {props.text}
              </MUIButton>
            )}
          </ThemeProvider>
        </div>
      )}
    </React.Fragment>
  );
};

export default Button;
