import React from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import useApplicationMode from "@src/multiCustodian/hooks/useApplicationMode";
import DashboardForms from "@src/multiCustodian/pages/Dashboard/Dashboard_Components/DashboardForms/DashboardForms";
import { DashboardForm } from "@src/multiCustodian/pages/Dashboard/Dashboard_Components/DashboardForms/Types";
import { MiscReducerActions } from "@src/multiCustodian/reducers/misc_Reducer";

import AdvisorNavMenu from "./AdvisorNavMenu";
import ClientNavMenu from "./ClientNavMenu";
import AdvisorProfileBox from "./ProfileBox/AdvisorProfileBox";
import ClientOrAdvisorProfileBox from "./ProfileBox/ClientOrAdvisorProfileBox";
import ReturnToDashboardButton from "./ReturnToDashboardButton/ReturnToDashboardButton";
import TopBox from "./TopBox/TopBox";

import styles from "./SideNav.module.css";

const SideNav: React.FC = () => {
  const { enableAdvisorNotifications } = useFlags();

  const { isAdvisorMode, isClientMode, isImpersonationMode } =
    useApplicationMode();

  const dispatch = useDispatch<Dispatch<MiscReducerActions>>();

  const handleSetDrawerPanelContext = (formToShow: DashboardForm): void => {
    dispatch({
      type: "SET_DRAWER_PANEL_CONTEXT",
      payload: {
        formToShow,
      },
    });
  };

  return (
    <>
      <DashboardForms />

      <div className={styles.container}>
        <div className={styles.topContainer}>
          <TopBox
            showNotifications={isAdvisorMode && enableAdvisorNotifications}
          />

          <ClientOrAdvisorProfileBox
            onSecuritySettingClick={() =>
              handleSetDrawerPanelContext("Security & Access")
            }
          />

          <div className={styles.navItemsContainer}>
            {isAdvisorMode ? <AdvisorNavMenu /> : <ClientNavMenu />}
          </div>
        </div>

        <div>
          {isImpersonationMode && <ReturnToDashboardButton />}
          {(isClientMode || isImpersonationMode) && <AdvisorProfileBox />}
        </div>
      </div>
    </>
  );
};

export default SideNav;
