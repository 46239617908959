import React, { PropsWithChildren } from "react";

import { PerformanceGroupId } from "@fartherfinance/frontend/api/Types";

import PerformanceGroupAccountActivity from "@src/multiCustodian/components/PerformanceGroups/Activity/Activity";

interface Props {
  groupId: PerformanceGroupId | null;
}

export default function ActivityTab(
  props: PropsWithChildren<Props>
): JSX.Element {
  return (
    <PerformanceGroupAccountActivity
      accountId={null}
      performanceGroupIdData={
        props.groupId
          ? {
              data: props.groupId,
              isLoading: false,
              hasError: false,
            }
          : {
              data: undefined,
              isLoading: false,
              hasError: true,
              error: "error",
            }
      }
    >
      {props.children}
    </PerformanceGroupAccountActivity>
  );
}
