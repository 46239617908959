/* eslint-disable react/prop-types */

import React, { Component } from "react";

import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { State as ReduxState } from "../../../store";
import DashboardCautionUpdateDiv from "../../components/Caution_update_status_div/Status";
import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import PageHero from "@src/sharedComponents/PageHero/PageHero";
import TitleBlock from "@src/sharedComponents/PageHero/TitleBlock";

import AltInvestmentBanner from "./Dashboard_Components/AltInvestmentBanner/AltInvestmentBanner";
import { PreloadClientCustodian } from "./Dashboard_Components/PreloadClientCustodian";
import PreloadDocuments from "./Dashboard_Components/PreloadDocuments";
import PerformanceGroups from "./Performance_Groups/PerformanceGroups";
import CustomAccountGroups from "./Performance_Groups/PerformanceGroupsCAGV2";

import styles from "./Dashboard.module.css";
import "./GlobalCSSTransition.css";

export interface ExternalAccountLink {
  institutionId: string;
  institutionName: string;
  linkId: string;
  status: string;
}

type ReduxProps = ReturnType<typeof mapStateToProps>;

interface Props extends ReduxProps {
  institutionsToRelink: ExternalAccountLink[];
  showStalePlaid: boolean;
  showAltInvestmentBanner: boolean;
  navToPlaidRelinkPage: () => void;
  navToDocumentsPage: () => void;
  enablePerformanceGroups: boolean;
  checklistIsLoading: boolean;
  accountsAreLoading: boolean;
  enableCustomPerformanceGroupsV2: boolean;
}

type State = Record<string, never>;

class Dashboard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <BaseLayout>
        <PageHero start={<TitleBlock title="Home" />} />

        {(!this.props.main_Reducer.farther_jwt ||
          !this.props.main_Reducer.isLoggedIn) && <Redirect to={"/Login"} />}

        <div className={styles.page_container}>
          <div className={styles.content_container}>
            <div className={styles.overlap_container}>
              <div className={styles.dashboard_div}>
                <div className={styles.dashboard_centering_div}>
                  <div className={styles.mainBody_container}>
                    {this.props.showAltInvestmentBanner && (
                      <AltInvestmentBanner />
                    )}

                    {this.props.showStalePlaid &&
                      this.props.institutionsToRelink.length > 0 && (
                        <React.Fragment>
                          <div className={styles.mainBody_upper_container}>
                            <DashboardCautionUpdateDiv
                              icon={
                                <WarningAmberRoundedIcon
                                  className={styles.warningIcon}
                                />
                              }
                              msg={`${this.props.institutionsToRelink.length} ${
                                this.props.institutionsToRelink.length === 1
                                  ? " institution needs to be updated"
                                  : " institutions need to be updated"
                              }`}
                              clickBanner={() =>
                                this.props.navToPlaidRelinkPage()
                              }
                            >
                              <img
                                src={
                                  this.props.main_Reducer.brand.current.images
                                    .chevron_down
                                }
                              />
                            </DashboardCautionUpdateDiv>
                          </div>

                          <div className={styles.takeUpSpace_container} />
                        </React.Fragment>
                      )}

                    <div className={styles.mainBody_lower_container}>
                      {this.props.checklistIsLoading ||
                      this.props.accountsAreLoading ? (
                        <LogoLoadingStill
                          onTop={true}
                          noBackgroundColor={true}
                        />
                      ) : this.props.enablePerformanceGroups ? (
                        <>
                          {this.props.enableCustomPerformanceGroupsV2 ? (
                            <CustomAccountGroups
                              sendToDocCenterTasksList={() =>
                                this.props.navToDocumentsPage()
                              }
                            />
                          ) : (
                            <PerformanceGroups
                              sendToDocCenterTasksList={() =>
                                this.props.navToDocumentsPage()
                              }
                            />
                          )}
                        </>
                      ) : (
                        <div className={styles.dashboardError}>
                          The dashboard is currently under maintenance
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <PreloadClientCustodian />

        <PreloadDocuments />
      </BaseLayout>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    main_Reducer: state.main_Reducer,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
