import React from "react";

const ColGroup = (): JSX.Element => {
  return (
    <colgroup>
      <col width={"20%"} />
      <col width={"20%"} />
      <col width={"25%"} />
      <col width={"35%"} />
    </colgroup>
  );
};

export default ColGroup;
