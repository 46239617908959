/* eslint-disable react/prop-types */

import React, { Component } from "react";

import { connect } from "react-redux";

import { State } from "../../../store";

import styles from "./dropdownComponent.module.css";

type ReduxState = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface IncomingProps<T> {
  containerStyle?: any;
  displayStyle?: any;
  input_title?: string;
  input_img?: string;
  title_img?: string;
  title_margin_bottom?: string;
  title_margin?: object;
  current_selection: any;
  options: readonly T[];
  style: "full" | "empty";
  widthStyle?: {
    width?: string;
    minWidth?: string;
    maxWidth?: string;
  };
  width?: string;
  placeholder: string;
  optionSelectors?: string[];
  useOptionSelectorForTitle?: boolean;
  childSelectors?: string[];
  onChange: (selection: T) => void;
  title_right_component?: any;
  disabled?: boolean;
}

interface InitialState {
  showDropdown: boolean;
  tabIndex: number | undefined;
}

const fullStyle = {
  backgroundColor: "var(--primary_color_1)",
  border: "1px solid var(--border_color_1)",
  height: "40px",
  borderRadius: "5px",
  padding: "0px 10px 0px 15px",
};

const emptyStyle = {
  borderBottom: "1px solid var(--neutral_input_underline_color_1)",
  height: "30px",
};

class DropdownComponent<T> extends Component<
  IncomingProps<T> & ReduxState,
  InitialState
> {
  dictionary: React.MutableRefObject<Record<string, string> | null>;
  [key: string]: any;

  constructor(props: IncomingProps<T> & ReduxState) {
    super(props);

    this.state = {
      showDropdown: false,
      tabIndex: undefined,
    };

    this.dictionary = React.createRef<{ [key: string]: string }>();
    this.dictionary.current = {};

    for (let i = 0; i < this.props.options.length; i++) {
      this[`option${i}`] = React.createRef<HTMLDivElement>();
    }
  }

  componentDidUpdate(
    _prevProps: IncomingProps<T>,
    prevState: InitialState
  ): void {
    if (prevState.showDropdown !== this.state.showDropdown) {
      if (this.state.showDropdown) {
        this.setState({ tabIndex: 0 });
      } else {
        this.setState({ tabIndex: undefined });
      }
    }
  }

  onChange = (item: any) => {
    this.setState({ showDropdown: false });
    this.props.onChange(item);
  };

  firstLetterSearch = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (this.dictionary.current === null) {
      this.dictionary.current = {};
    }

    if (this.dictionary.current[e.key.toLowerCase()]) {
      const ref = this[this.dictionary.current[e.key.toLowerCase()]] as
        | undefined
        | React.MutableRefObject<HTMLElement | null>;

      ref?.current?.scrollIntoView?.();
    }
  };

  updateDictionary = (letter: string, option: string) => {
    if (!this.dictionary.current) {
      this.dictionary.current = {};
    }
    if (!this.dictionary.current[`${letter.toLowerCase()}`]) {
      this.dictionary.current[`${letter.toLowerCase()}`] = `${option}`;
    }
  };

  render() {
    let currentStyle =
      this.props.style === "full" ? { ...fullStyle } : { ...emptyStyle };
    if (this.props.displayStyle) {
      currentStyle = {
        ...currentStyle,
        ...this.props.displayStyle,
      };
    }
    const selectors = this.props.optionSelectors;
    const childS = this.props.childSelectors;

    return (
      <div
        tabIndex={this.state.tabIndex}
        onKeyDown={this.firstLetterSearch}
        className={styles.container}
        onMouseEnter={() => this.setState({ showDropdown: true })}
        onMouseLeave={() => this.setState({ showDropdown: false })}
        style={
          this.props.containerStyle
            ? this.props.containerStyle
            : this.props.width
            ? { width: this.props.width }
            : this.props.widthStyle
            ? this.props.widthStyle
            : {}
        }
      >
        {this.props.input_title && (
          <div
            className={styles.title}
            style={
              this.props.title_margin
                ? { ...this.props.title_margin }
                : this.props.title_margin_bottom
                ? { marginBottom: this.props.title_margin_bottom }
                : {}
            }
          >
            <div className={styles.title_left}>
              {this.props.title_img && <img src={this.props.title_img} />}
              <p>{this.props.input_title}</p>
            </div>
            {this.props.title_right_component &&
              this.props.title_right_component()}
          </div>
        )}
        <div className={styles.selection_display} style={{ ...currentStyle }}>
          <div className={styles.input_items_left}>
            {this.props.input_img && <img src={this.props.input_img} />}
            {this.props.current_selection ? (
              <p className={styles.selection}>
                {this.props.optionSelectors &&
                this.props.optionSelectors.length === 1 &&
                this.props.useOptionSelectorForTitle
                  ? this.props.current_selection[this.props.optionSelectors[0]]
                  : this.props.current_selection.title
                  ? this.props.current_selection
                  : this.props.current_selection}
              </p>
            ) : (
              <p className={styles.placeholder}>{this.props.placeholder}</p>
            )}
          </div>
          {this.props.disabled ? (
            <></>
          ) : (
            <div className={styles.chevron_container}>
              <img
                src={this.props.main_Reducer.brand.current.images.chevron_down}
                alt=""
              />
            </div>
          )}
        </div>
        {this.state.showDropdown && !this.props.disabled ? (
          <div className={styles.menu_container}>
            <div className={styles.spacer}></div>
            <div className={styles.menu}>
              {this.props.options.map((opt: any, idx) => {
                const title =
                  selectors && childS
                    ? childS.map(
                        (selector) =>
                          `${
                            opt[`${selectors ? selectors[0] : "null"}`][
                              selector
                            ]
                          } `
                      )
                    : selectors
                    ? selectors.map((selector) => `${opt[selector]} `)
                    : opt.title
                    ? opt.title
                    : opt;

                const firstL = Array.isArray(title)
                  ? title[0].charAt(0)
                  : title.charAt(0);

                const option = `option${idx}`;

                if (this && this.dictionary) {
                  this.updateDictionary(firstL, option);
                }

                return (
                  <div
                    onClick={() => this.onChange(opt)}
                    key={`dropdown-item-${idx}`}
                    className={styles.menu_item}
                    ref={this[`${option}`]}
                  >
                    {/**
                     * We can provide an array of selectors and child selectors
                     */}
                    <p>{title}</p>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: State) => {
  return { main_Reducer: state.main_Reducer };
};

const mapDispatchToProps = () => {
  return {};
};

const Connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(DropdownComponent);

// Need to wrap it because connect kills the generic
export default function ConnectedComponent<T>(props: IncomingProps<T>) {
  return <Connected {...(props as any)} />;
}
