import React, { useCallback, useEffect, useState } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, FormHelperText, Stack } from "@mui/material";
import { every } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { Partner } from "@fartherfinance/frontend/api/Dashboard/requests/getClientDashboard";
import usePostFartherEmployeeV2 from "@fartherfinance/frontend/api/Entity/hooks/usePostFartherEmployeeV2";
import {
  FartherEmployeeInformation,
  Request,
} from "@fartherfinance/frontend/api/Entity/requests/postFartherEmployeeV2";
import { ServiceTeam } from "@fartherfinance/frontend/api/Entity/Types";
import {
  partners,
  USAState,
  USAStates,
} from "@fartherfinance/frontend/api/Types";

import Drawer from "../../Drawer/Drawer";
import ButtonPrimary from "../../MUI/Button/Button";
import { emailValidationRegex } from "@src/multiCustodian/components/Advisor/ClientProfile/Forms/constants";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import FormFieldLabel from "@src/sharedComponents/Forms/Private/FormFieldLabel";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import FileDropUploadComponent from "@src/sharedComponents/SingleFileDropUpload/SingleFileDropUpload";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import styles from "./CreateAdvisorDrawer.module.css";

const URL_REGEX = /^https:\/\/[^\s\/$.?#].[^\s]*$/;

const BIO_MIN_LENGTH = 5;

const BD_TEAM_ID_START_STR = "z04";

const FirstName = "FirstName";
const LastName = "LastName";
const FartherEmail = "FartherEmail";
const Organization = "Organization";
const BlackDiamondTeamId = "BlackDiamondTeamId";
const ServiceTeamField = "ServiceTeamField";
const CurrentCity = "CurrentCity";
const CurrentState = "CurrentState";
const Hometown = "Hometown";
const University = "University";
const Concentration = "Concentration";
const CalendarUrl = "CalendarUrl";
const LinkedinProfile = "LinkedinProfile";

interface Form {
  [FirstName]: string | undefined;
  [LastName]: string | undefined;
  [FartherEmail]: string | undefined;
  [Organization]: { label: Partner } | undefined;
  [BlackDiamondTeamId]: string | undefined;
  [ServiceTeamField]: { label: ServiceTeam } | undefined;
  [CurrentCity]: string | undefined;
  [CurrentState]: { label: USAState } | undefined;
  [Hometown]: string | undefined;
  [University]: string | undefined;
  [Concentration]: string | undefined;
  [CalendarUrl]: string | undefined;
  [LinkedinProfile]: string | undefined;
}

type ValidForm = StripUndefined<Form>;

const formIsValid = (f: Form): f is ValidForm => {
  return every(f, (v) => v !== undefined);
};

const CreateAdvisorDrawer = (): JSX.Element => {
  const history = useHistory();

  const [bio, setBio] = useState<string>("");
  const [bioIsDirty, setBioIsDirty] = useState<boolean>(false);

  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [imgError, setImgError] = useState<string | null>(null);

  const [mutating, setMutating] = useState(false);

  const statusNotification = useStatusNotification();

  const auth = useAdvisorRequestAuth();

  const postFartherEmployeeV2 = usePostFartherEmployeeV2(auth);

  const handleBio = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!bioIsDirty) {
      setBioIsDirty(true);
    }

    setBio(e.target.value);
  };

  const methods = useForm<Form>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      [Organization]: { label: "Farther" },
    },
  });

  const {
    getValues,
    formState: { isValid, isDirty },
  } = methods;

  useEffect(() => {
    if (profileImage === null) {
      setImgError(null);
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target === null || typeof event.target.result !== "string") {
        setProfileImage(null);
        setImgError("Failed to upload this file");
        return;
      }

      const img = new Image();

      img.onload = () => {
        if (img.width !== img.height) {
          setImgError(
            `The image must be square. Yours dimensions are ${img.width}x${img.height}.`
          );
        } else {
          setImgError(null);
        }
      };

      img.onerror = () => {
        setProfileImage(null);
        setImgError("Failed to upload this file. Ensure the format is PNG.");
      };

      img.src = event.target.result;
    };

    reader.readAsDataURL(profileImage);
  }, [profileImage]);

  const onClose = useCallback(
    () => history.push({ ...history.location, pathname: "/Advisor/Admin" }),
    [history]
  );

  const submit = async () => {
    if (mutating) {
      statusNotification("Form is being submitted", "Error");
      return;
    }

    if (!isDirty) {
      statusNotification("Nothing to submit", "Error");
      return;
    }

    if (profileImage === null || imgError !== null) {
      statusNotification("Invalid profile image", "Error");
      return;
    }

    if (!isValid) {
      statusNotification("Some fields are incomplete", "Error");
      return;
    }

    const values = getValues();

    if (!formIsValid(values)) {
      statusNotification("Some fields are incomplete", "Error");
      return;
    }

    if (bio.length <= BIO_MIN_LENGTH) {
      statusNotification("Missing bio", "Error");
      return;
    }

    const information: FartherEmployeeInformation = {
      first: values[FirstName],
      last: values[LastName],
      email: values[FartherEmail],
      description: bio,
      calendarUrl: values[CalendarUrl],
      linkedinUrl: values[LinkedinProfile],
      concentration: values[Concentration],
      almaMaters: values[University],
      hometown: values[Hometown],
      pershingRRCode: process.env.WEBAPP_ENV === "PROD" ? "94I" : "123",
      location: `${values[CurrentCity]}, ${values[CurrentState].label}`,
    };

    const organization = values[Organization].label;
    const blackDiamondTeamIds = values[BlackDiamondTeamId].replace(/ /g, "")
      .split(",")
      .filter((s) => s !== ""); // just in case - input rules/validation should prevent this from being needed

    const form: Request = {
      information: information,
      organization:
        organization === "Farther" ? -1 : organization === "Cogent" ? -2 : -3,
      roles: [
        organization === "Farther"
          ? "FartherAdvisor"
          : organization === "Cogent"
          ? "CogentAdvisor"
          : "WealthPlanAdvisor",
      ],
      ...(blackDiamondTeamIds.length > 0
        ? { teamIds: blackDiamondTeamIds }
        : {}),
      ...(profileImage !== null ? { headshot: profileImage } : {}),
    };

    try {
      setMutating(true);

      await postFartherEmployeeV2(form);

      statusNotification(
        `Advisor ${form.information.first} ${form.information.last} created`,
        "Success"
      );

      onClose();
    } catch {
      statusNotification("Failed to create advisor", "Error");
    } finally {
      setMutating(false);
    }
  };

  return (
    <Drawer
      isDrawerOpen
      onClose={onClose}
      footer={
        <Stack direction="row" gap="16px">
          <ButtonPrimary
            text="Cancel"
            variant="outlined"
            buttonType="primary"
            onClick={onClose}
          />

          <ButtonPrimary
            disabled={
              !isDirty ||
              !isValid ||
              mutating ||
              profileImage === null ||
              imgError !== null ||
              bio.length <= BIO_MIN_LENGTH
            }
            text="Create Advisor Member"
            variant="contained"
            buttonType="primary"
            onClick={submit}
          />
        </Stack>
      }
    >
      {mutating && <LogoLoadingStill onTop />}
      <FormProvider {...methods}>
        <div className={styles.title}>Create Advisor</div>

        <FlexWrapper>
          <FormTextField
            name={FirstName}
            label={"First Name"}
            required="Must not be empty"
          />

          <FormTextField
            name={LastName}
            label={"Last Name"}
            required="Must not be empty"
          />

          <FormTextField
            name={FartherEmail}
            label={"Farther Email"}
            required="Must not be empty"
            rules={{
              validate: {
                isValidEmail: (email) => {
                  return !emailValidationRegex.test(email ?? "")
                    ? "Please enter a valid email"
                    : true;
                },
              },
            }}
          />

          <FormTextField
            name={Hometown}
            label={"Hometown"}
            required="Must not be empty"
          />

          <FormDropdownField
            name={Organization}
            label={"Organization"}
            values={partners.map((p) => ({ label: p }))}
            required={"Must not be empty"}
            disableSearch
          />

          <FormTextField
            name={BlackDiamondTeamId}
            label={"Black Diamond Team ID"}
            required={
              process.env.WEBAPP_ENV === "UAT" ? undefined : "Must not be empty"
            }
            rules={{
              validate: {
                length: (value: string): string | true => {
                  const teamIds = value.replace(/ /g, "").split(",");

                  const incorrectLength = teamIds.some(
                    (id: string) => id.length !== 7
                  );

                  if (incorrectLength) {
                    return "Team ID(s) need to be 7 characters long";
                  }

                  return true;
                },
                eachStartsWith: (value: string): string | true => {
                  if (value === "" && process.env.WEBAPP_ENV === "UAT") {
                    return true; // not required in UAT but can be validated/sent if provided
                  }

                  const teamIds = value.replace(/ /g, "").split(",");

                  const anyMissing = !teamIds.every((id: string) =>
                    id.startsWith(BD_TEAM_ID_START_STR)
                  );

                  if (anyMissing) {
                    return `Team IDs need to start with '${BD_TEAM_ID_START_STR}'`;
                  }

                  const tooShort = teamIds.some(
                    (id: string) => id.length <= BD_TEAM_ID_START_STR.length
                  );

                  if (tooShort) {
                    return "Team ID(s) don't look valid";
                  }

                  return true;
                },
              },
            }}
          />

          <FormTextField
            name={CurrentCity}
            label={"Current City"}
            required="Must not be empty"
          />

          <FormDropdownField
            name="CurrentState"
            label="Current State"
            values={USAStates.map((s) => ({ label: s }))}
            required={"Must not be empty"}
            disableSearch
          />

          <FormTextField
            name={University}
            label={"University"}
            required="Must not be empty"
          />

          <FormTextField
            name={Concentration}
            label={"University Degree / Concentration"}
            required="Must not be empty"
          />

          <FormTextField
            name={CalendarUrl}
            label={"Hubspot Meeting Url"}
            required="Must not be empty"
            rules={{
              validate: {
                isValidUrl: (url) => {
                  return (
                    URL_REGEX.test(url) || "Invalid URL. Must be an HTTPS URL."
                  );
                },
              },
            }}
          />

          <FormTextField
            name={LinkedinProfile}
            label={"Linkedin Profile"}
            required="Must not be empty"
            rules={{
              validate: {
                isValidUrl: (url) => {
                  return (
                    URL_REGEX.test(url) || "Invalid URL. Must be an HTTPS URL."
                  );
                },
              },
            }}
          />
        </FlexWrapper>

        <Spacer verticalSpacing="25px" />

        <div className={styles.label}>Personal Bio</div>

        <textarea
          className={styles.textArea}
          placeholder={"Add Bio"}
          maxLength={1000}
          onChange={handleBio}
        >
          {bio}
        </textarea>

        {bio.length <= BIO_MIN_LENGTH && bioIsDirty && (
          <FormHelperText error>{"Need to add a bio"}</FormHelperText>
        )}

        <Spacer verticalSpacing="25px" />

        <Box>
          <FormFieldLabel
            label={
              <div className={styles.label}>
                <span>Profile Image</span>

                <Tooltip
                  tooltipText={
                    <ul className={styles.list}>
                      <li>Image must be a square</li>

                      <li>Dimensions should *ideally* be 250x250</li>

                      <li>Image format should be PNG (not JPEG)</li>

                      <li>The background should be transparent</li>
                    </ul>
                  }
                  placement={"top"}
                >
                  <InfoOutlinedIcon style={{ width: "17px", height: "17px" }} />
                </Tooltip>
              </div>
            }
          />

          <FileDropUploadComponent
            file={profileImage}
            onChange={setProfileImage}
            acceptedFileExtensions={[".png"]}
            text={"Drop your file here or "}
            pressText={<div className={styles.fileUploadText}>browse</div>}
          />

          <FormHelperText error className={styles.helperText}>
            {imgError}
          </FormHelperText>
        </Box>
      </FormProvider>
    </Drawer>
  );
};

export default CreateAdvisorDrawer;
