import { useCallback } from "react";

import useExchangeStytchToken from "@fartherfinance/frontend/api/Identity/hooks/useExchangeStytchToken";
import {
  EmailAddress,
  StytchToken,
} from "@fartherfinance/frontend/api/Identity/requests/exchangeStytchToken";

import useLogIntoRedux from "@src/multiCustodian/hooks/useLogIntoRedux";
import useNavigateOnLogin from "@src/multiCustodian/hooks/useNavigateOnLogin";
import { browser } from "@src/multiCustodian/utils/getBrowser";
import { operatingSystem } from "@src/multiCustodian/utils/getOperatingSystem";

import { LocalStorageKeys } from "./constants";
import { LoginFunction } from "./Types";

export default function useLogin(): LoginFunction {
  const logIntoRedux = useLogIntoRedux();
  const navigateOnLogin = useNavigateOnLogin();
  const exchangeToken = useExchangeStytchToken();

  const login: LoginFunction = useCallback<LoginFunction>(
    async (
      stytchToken: StytchToken,
      emailAddress: EmailAddress,
      _devEmail?: EmailAddress,
      preventRedirect?: boolean
    ): Promise<void> => {
      const res = await exchangeToken({
        stytchToken: stytchToken,
        identifierType: "email",
        identifier: emailAddress,
        sessionName: `${browser} on ${operatingSystem}`,
        sessionType: "Web",
      });

      const personId = res.personId;
      const personType = res.personType;
      const fartherToken = res.token;

      try {
        localStorage.setItem(LocalStorageKeys.personId, personId);
        localStorage.setItem(LocalStorageKeys.personType, personType);
        localStorage.setItem(LocalStorageKeys.token, fartherToken);
      } catch (e) {
        console.error("Failed to store data in localStorage", e);
      }

      console.log(
        `Logging in as a ${personType} with ID ${personId} using token ${fartherToken}`
      );

      logIntoRedux(personId, personType, fartherToken);

      if (preventRedirect === false || preventRedirect === undefined) {
        navigateOnLogin(personType);
      }

      return;
    },
    [exchangeToken, logIntoRedux, navigateOnLogin]
  );

  return login;
}
