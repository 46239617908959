import React, { ComponentProps } from "react";

import { Controller, Message, useFormContext } from "react-hook-form";

import TextBox from "../TextBox/TextBox";

import styles from "./FormAmountRow.module.css";

export interface Props {
  name: string;
  label: string;
  defaultValue?: string;
  required?: Message | false;
  rules?: ComponentProps<typeof Controller>["rules"];
  placeholder?: string;
  startAdornment?: React.ReactNode;
  valueFormatterOnChange?: <T extends string>(input: T) => string;
  valueFormatterOnBlur?: <T extends string>(input: T) => string;
}

const FormAmountRow = ({
  label,
  placeholder,
  startAdornment,
  valueFormatterOnChange,
  valueFormatterOnBlur,
  ...restProps
}: Props): JSX.Element => {
  const { control } = useFormContext();

  return (
    <Controller
      {...restProps}
      control={control}
      rules={{ required: restProps.required, ...restProps.rules }}
      render={({ field, fieldState }) => (
        <div className={styles.outerContainer}>
          <div className={styles.innerContainer}>
            <div className={styles.labelContainer}>
              <div className={styles.label}>{label}</div>
            </div>

            <TextBox
              value={field.value}
              placeholder={placeholder}
              startAdornment={startAdornment}
              hasError={fieldState.invalid}
              onChange={(val) =>
                field.onChange(
                  valueFormatterOnChange ? valueFormatterOnChange(val) : val
                )
              }
              onBlur={(val) => {
                if (valueFormatterOnBlur) {
                  field.onChange(valueFormatterOnBlur(val));
                }

                field.onBlur();
              }}
            />
          </div>

          {fieldState.invalid && fieldState.error?.message !== undefined && (
            <div className={styles.error}>{fieldState.error.message}</div>
          )}
        </div>
      )}
    />
  );
};

export default FormAmountRow;
