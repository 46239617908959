import { z } from "zod";

import { ProposalInternalStatus } from "@fartherfinance/frontend/api/Rebalance/Types";
import { custodianOptions } from "@fartherfinance/frontend/api/Types";

const locationStateQueryParamsSchema = z
  .object({
    page: z.number(),
    pageSize: z.number(),
    filterByStatus: ProposalInternalStatus.array(),
    filterByCustodian: z.enum(custodianOptions).array().optional(),
    filterByTimestampFrom: z.string().optional(),
    filterByTimestampTo: z.string().optional(),
    filterByIsMaterialOrMultiDayExecution: z.boolean().optional(),
    searchByPhrase: z.coerce.string().optional(),
  })
  .strict();

export const locationStateSchema = z
  .object({
    queryParams: locationStateQueryParamsSchema,
    from: z.string(),
  })
  .nullable();

export type ProposalDetailsLocationQueryParams = z.infer<
  typeof locationStateQueryParamsSchema
>;
export type ProposalDetailsLocationState = z.infer<typeof locationStateSchema>;
