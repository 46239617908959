import React, { useState } from "react";

import Avatar from "../../Avatar/Avatar";
import ProfilePhotoAdvisor from "../../Navbar/ProfilePhotoAdvisor";
import useAdvisorDetails from "@src/multiCustodian/hooks/Advisor/useAdvisorDetails";
import useApplicationMode from "@src/multiCustodian/hooks/useApplicationMode";
import useClientName from "@src/multiCustodian/hooks/useClientName";
import { trackEvent } from "@src/multiCustodian/services/tracking";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import AdvisorPopoverMenu from "./AdvisorPopoverMenu";
import ClientPopoverMenu from "./ClientPopoverMenu";
import ProfileBox from "./ProfileBox";

import styles from "./ProfileBox.module.css";

interface Props {
  onSecuritySettingClick: () => void;
}

const ClientOrAdvisorProfileBox: React.FC<Props> = (props) => {
  const [showPopover, setShowPopover] = useState<boolean>(false);

  const { isClientMode, isAdvisorMode, isImpersonationMode } =
    useApplicationMode();

  const client = useClientName();
  const advisor = useAdvisorDetails();

  const togglePopover = () => {
    if (!showPopover) {
      const eventName = isAdvisorMode
        ? "Advisor Open Profile-Menu"
        : "Client Open Profile-Menu";
      trackEvent({ name: eventName });
    }
    setShowPopover(!showPopover);
  };

  return (
    <div
      className={toClassName(styles.container, {
        [styles.selected]: showPopover,
      })}
      onClick={togglePopover}
    >
      {isAdvisorMode ? (
        <ProfileBox
          isAdvisor={false}
          name={
            advisor ? `${advisor?.name.first} ${advisor?.name.last}` : "..."
          }
          avatar={
            <ProfilePhotoAdvisor
              containerClassName={styles.smallAvatar}
              avatarClassName={styles.smallAvatar}
            />
          }
          popover={
            <AdvisorPopoverMenu
              showPopover={showPopover}
              setShowPopover={setShowPopover}
              onSecuritySettingClick={props.onSecuritySettingClick}
            />
          }
        />
      ) : isClientMode || isImpersonationMode ? (
        <ProfileBox
          isAdvisor={false}
          name={client ? `${client?.name.first} ${client?.name.last}` : "..."}
          avatar={<Avatar className={styles.smallAvatar} />}
          popover={
            <ClientPopoverMenu
              showPopover={showPopover}
              setShowPopover={setShowPopover}
            />
          }
        />
      ) : (
        <ProfileBox
          isAdvisor={false}
          name={""}
          avatar={<div className={styles.avatarLoading} />}
          popover={<></>}
        />
      )}
    </div>
  );
};

export default ClientOrAdvisorProfileBox;
