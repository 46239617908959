import React, {
  ComponentProps,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";

import * as Sentry from "@sentry/react";
import { useSelector } from "react-redux";
import { Redirect, Route, useParams } from "react-router-dom";

import useClientProfile from "@fartherfinance/frontend/api/Entity/hooks/useClientProfile";
import { ClientId, RequestConfig } from "@fartherfinance/frontend/api/Types";

import { LocalStorageKeys } from "@src/multiCustodian/components/Login/constants";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import useCheckSavedAdvisorMagicSession from "@src/multiCustodian/hooks/Advisor/useCheckSavedAdvisorMagicSession";
import useImpersonateClient from "@src/multiCustodian/hooks/Advisor/useImpersonateClient";
import useLogIntoSavedAdvisorSession from "@src/multiCustodian/hooks/Advisor/useLogIntoSavedAdvisorSession";
import useSyncAdvisorLogout from "@src/multiCustodian/hooks/Advisor/useSyncAdvisorLogout";
import useCheckSavedClientMagicSession from "@src/multiCustodian/hooks/useCheckSavedClientMagicSession";
import useLogIntoSavedClientSession from "@src/multiCustodian/hooks/useLogIntoSavedClientSession";
import useMagicRefreshToken from "@src/multiCustodian/hooks/useMagicRefreshToken";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useSyncClientLogout from "@src/multiCustodian/hooks/useSyncClientLogout";
import { State } from "@src/store";

import { FullScreenLoader } from "./UtilityComponents";

const SentryRoute = Sentry.withSentryRouting(Route);

function LogIntoSavedClientSession(): JSX.Element {
  useLogIntoSavedClientSession();
  return <></>;
}

function LogIntoSavedAdvisorSession(): JSX.Element {
  useLogIntoSavedAdvisorSession();
  return <></>;
}
export default function PrivateRoute({
  children,
  ...rest
}: PropsWithChildren<ComponentProps<typeof Route>>) {
  const { clientId } = useParams<{ clientId: ClientId }>();
  const { storedClientId } = useSelector((state: State) => ({
    storedClientId: state.main_Reducer.user.id_user,
  }));

  const auth = useRequestAuth();

  const { isAdvisor, advisorId, jwt } = useSelector((state: State) => ({
    isAdvisor: state.main_Reducer.user.isAdvisor,
    advisorId: state.main_Reducer.cockroach_advisor_id,
    jwt: state.main_Reducer.farther_jwt,
  }));

  const clientSession = useCheckSavedClientMagicSession();
  const advisorSession = useCheckSavedAdvisorMagicSession();

  useMagicRefreshToken(isAdvisor);

  // An advisor might be impersonating a client
  useSyncAdvisorLogout();

  useSyncClientLogout();

  const customAuth: RequestConfig | null =
    advisorId && jwt
      ? { advisorId: advisorId, clientId: clientId, jwt: jwt }
      : null;

  const clientProfile = useClientProfile(clientId, customAuth);

  const impersonate = useImpersonateClient();

  const [choseClientOverAdvisor, setChoseClientOverAdvisor] = useState<
    null | boolean
  >(null);

  useEffect(() => {
    if (clientProfile.data === undefined) {
      return;
    }

    if (storedClientId !== null) {
      return;
    }

    if (clientSession.isLoading || advisorSession.isLoading) {
      // Wait to see if we are logged in as both
      return;
    }

    if (clientSession.data?.isLoggedIn && advisorSession.data?.isLoggedIn) {
      // Logged in as both

      // IF they did NOT choose client specifically, do not impersonate
      if (choseClientOverAdvisor !== false) {
        return;
      }
    }

    if (advisorSession.data?.isLoggedIn === true) {
      // We need to impersonate a client, probably loaded this url directly
      const email =
        clientProfile.data.investorProfile.contactInformation.emailAddress;
      const name = clientProfile.data.investorProfile.personalDetails.name;

      impersonate(clientId, email, name);
    }
  }, [
    advisorSession,
    choseClientOverAdvisor,
    clientId,
    clientProfile.data,
    clientSession,
    impersonate,
    storedClientId,
  ]);

  if (storedClientId !== null) {
    // We've saved some authed user to redux, show the page
    return <SentryRoute {...rest} render={() => children} />;
  }

  if (clientSession.isLoading || advisorSession.isLoading) {
    return <FullScreenLoader />;
  }

  if (
    choseClientOverAdvisor === false &&
    advisorId !== null &&
    storedClientId === null
  ) {
    // We are still waiting to impersonate the client, delay...
    return (
      <div>
        <FullScreenLoader />

        {/* Debug info, can delete later */}
        <div style={{ position: "fixed", bottom: 5, right: 5, fontSize: 8 }}>
          Loading Client info...
        </div>
      </div>
    );
  }

  if (clientSession.data?.isLoggedIn && advisorSession.data?.isLoggedIn) {
    // Logged in as both advisor and client

    if (choseClientOverAdvisor === true) {
      // Need to log into a client session, don't use hooks so we can render conditionally
      return (
        <div>
          <FullScreenLoader />
          <LogIntoSavedClientSession />
          {/* Debug info, can delete later */}
          <div style={{ position: "fixed", bottom: 5, right: 5, fontSize: 8 }}>
            Logging in as a client instead of advisor
          </div>
        </div>
      );
    } else if (choseClientOverAdvisor === false) {
      // Need to log into a advisor session, don't use hooks so we can render conditionally
      return (
        <div>
          <FullScreenLoader />
          <LogIntoSavedAdvisorSession />
          {/* Debug info, can delete later */}
          <div style={{ position: "fixed", bottom: 5, right: 5, fontSize: 8 }}>
            Logging in as an advisor instead of client
          </div>
        </div>
      );
    }

    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Logged in as both a client and an advisor</div>

        <div style={{ marginTop: 10 }}>
          <ButtonPrimary
            text="Log in as client"
            onClick={() => setChoseClientOverAdvisor(true)}
            variant="contained"
            buttonType="primary"
          />
        </div>

        <div style={{ marginTop: 10 }}>
          <ButtonPrimary
            text="Log in as advisor"
            onClick={() => setChoseClientOverAdvisor(false)}
            variant="outlined"
            buttonType="primary"
          />
        </div>
      </div>
    );
  }

  if (clientSession.data?.isLoggedIn && storedClientId === null) {
    // Log in as a client
    return (
      <div>
        <FullScreenLoader />
        <LogIntoSavedClientSession />
        {/* Debug info, can delete later */}
        <div style={{ position: "fixed", bottom: 5, right: 5, fontSize: 8 }}>
          Logging in as a client
        </div>
      </div>
    );
  }

  if (advisorSession.data?.isLoggedIn && storedClientId === null) {
    // Log in as an advisor
    return (
      <div>
        <FullScreenLoader />
        <LogIntoSavedAdvisorSession />
        {/* Debug info, can delete later */}
        <div style={{ position: "fixed", bottom: 5, right: 5, fontSize: 8 }}>
          Logging in as an advisor
        </div>
      </div>
    );
  }

  if (auth === null) {
    return (
      <SentryRoute
        {...rest}
        render={({ location }) => {
          localStorage.setItem(
            LocalStorageKeys.redirectTo,
            `${location.pathname}${location.search}`
          );

          return (
            <Redirect
              to={{
                pathname: "/Login",
                state: { from: location },
              }}
            />
          );
        }}
      />
    );
  }

  console.error("Router - got to default routing somehow");
  return <SentryRoute {...rest} render={() => children} />;
}
