import React, { useCallback, useEffect } from "react";

import { useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import CreateEditLayout from "../CreateEditLayout/CreateEditLayout";
import { actions } from "../reducer/actions";
import { useCreateEditTaxBudgetContext } from "../reducer/Context";
import { HypotheticalTradeGroup } from "../reducer/types";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

import SelectHypotheticalModel from "./SelectHypotheticalModel";
import SelectHypotheticalModelLoading from "./SelectHypotheticalModelLoading";
import useSelectedAccountsToTradeGroups from "./useSelectedAccountsToTradeGroups";

import styles from "./SelectHypotheticalModels.module.css";

const SelectHypotheticalModels: React.FC = () => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const { state, dispatch } = useCreateEditTaxBudgetContext();

  const {
    data: { excludedVirtualAccountIds, hypotheticalTradeGroups },
  } = state;

  const setHypotheticalTradeGroups = useCallback(
    (hypotheticalTradeGroups: HypotheticalTradeGroup[]) => {
      dispatch({
        type: actions.SET_HYPOTHETICAL_TRADEGROUPS,
        payload: hypotheticalTradeGroups,
      });
    },
    [dispatch]
  );

  const clientAuth = useRequestAuth();
  const advisorReqAuth = useAdvisorRequestAuth();
  const advisorAuth = advisorReqAuth
    ? { ...advisorReqAuth, clientId: clientId }
    : null;
  const auth = clientAuth ? clientAuth : advisorAuth;

  const tradeGroupsWithAccountNames = useSelectedAccountsToTradeGroups(
    clientId,
    excludedVirtualAccountIds,
    auth
  );

  useEffect(() => {
    if (
      tradeGroupsWithAccountNames.isLoading ||
      tradeGroupsWithAccountNames.hasError
    ) {
      return;
    }

    const newAccountIds = tradeGroupsWithAccountNames.data.flatMap(
      (tg) => tg.virtualAccountIds
    );

    const accountIdsInState = hypotheticalTradeGroups.flatMap(
      (tg) => tg.virtualAccountIds
    );

    const selectedModelsAreSame =
      newAccountIds.length === accountIdsInState.length &&
      accountIdsInState.every((inStateAccountId) =>
        newAccountIds.some((newAccountId) => inStateAccountId === newAccountId)
      );

    if (!selectedModelsAreSame) {
      setHypotheticalTradeGroups(tradeGroupsWithAccountNames.data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tradeGroupsWithAccountNames,
    setHypotheticalTradeGroups,
    //hypotheticalTradeGroups <-- even though eslint complains, don't want to add this as it will trigger useEffect indefinitely
  ]);

  if (tradeGroupsWithAccountNames.hasError) {
    return <div>{tradeGroupsWithAccountNames.error}</div>;
  }

  return (
    <CreateEditLayout>
      <div className={styles.container}>
        {tradeGroupsWithAccountNames.isLoading && (
          <SelectHypotheticalModelLoading />
        )}

        {hypotheticalTradeGroups.map((group) => (
          <SelectHypotheticalModel
            key={group.tradingGroupId}
            hypotheticalTradeGroup={group}
          />
        ))}
      </div>
    </CreateEditLayout>
  );
};

export default SelectHypotheticalModels;
