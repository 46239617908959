import { isInteger as isIntegerLodash, isNil } from "lodash";

import { isValidNumber } from "@src/sharedComponents/Forms/utils/isValidNumber";

const message = "Must be an integer";

export default function isInteger(
  input: string | null | undefined,
  required: boolean
): true | string {
  const isEmpty = isNil(input) || input === "";

  const isValidNum =
    !isEmpty && isValidNumber(input) && !isNaN(parseInt(input));

  const isInt = !isEmpty && isValidNum && isIntegerLodash(Number(input)); //Number turns null, undefined, and "" to 0

  if (required) {
    return isInt ? true : message;
  }

  return isEmpty || isInt ? true : message;
}
