import React from "react";

import { defaultHeight } from "./utils/constants";

interface Props {
  verticalSpacing?: string;
}

export default function Spacer(props: Props): JSX.Element {
  return (
    <div
      style={{
        width: "100%",
        height: props.verticalSpacing ?? defaultHeight,
        minHeight: props.verticalSpacing ?? defaultHeight, //without minHeight, this component sometimes gets crushed
      }}
    />
  );
}
