import React from "react";

import { useLocation } from "react-router-dom";

import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

export function NoMatch() {
  const location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

export function RequiresPermission() {
  const location = useLocation();

  return (
    <div>
      <h3>
        Must have extra permissions to access <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

export function FullScreenLoader(): JSX.Element {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LogoLoadingStill />
    </div>
  );
}
