import React from "react";

import { TaxBudget } from "@fartherfinance/frontend/api/InvestmentModel/Types";

import CreateEditLayout from "../CreateEditLayout/CreateEditLayout";
import { taxYearOptions } from "../utils/taxYearOptions";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";

import YearRow from "./YearRow";

import styles from "./SelectYear.module.css";

interface Props {
  budgets: TaxBudget[];
}

const SelectYear: React.FC<Props> = ({ budgets }) => {
  const getBudgetForYear = (year: number): TaxBudget | undefined => {
    return budgets?.find((budget) => budget.year === year);
  };

  return (
    <CreateEditLayout>
      <BorderBox className={styles.container}>
        <div className={styles.title}>Tax Year</div>
        <BorderBox>
          <div className={styles.body}>
            {taxYearOptions.map((year) => (
              <YearRow key={year} budget={getBudgetForYear(year)} year={year} />
            ))}
          </div>
        </BorderBox>
      </BorderBox>
    </CreateEditLayout>
  );
};

export default SelectYear;
