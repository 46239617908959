import React, { useEffect, useState } from "react";

import opacity from "hex-color-opacity";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useElementSize } from "usehooks-ts";

import { TimeFrame } from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Types";
import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import CustomDot from "../../GraphComponents/Summary/CustomDot";
import graphPresets from "../../GraphComponents/Summary/graphPresets";
import { formatBalance } from "../Shared/helpers";
import { UpdateDisplayData } from "../Types";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

import { usePerformanceCustomDateRangeContext } from "./components/PerformanceCustomDateRangeContextProvider";

import * as styles from "./SummaryGraph.module.css";

const formatTickForNoData = (tickValue: number): string => {
  if (tickValue === 0) {
    return "$0";
  }

  return "$--";
};

type YYYYMMDD = string;

export interface BalanceSummary {
  startDate: YYYYMMDD;
  endDate: YYYYMMDD;
  balanceOnEndDay: number;
  relativeContributions: number;
}

interface IncomingProps {
  balanceSummary: BalanceSummary[];
  /* Hovering over the graph changes the balance on display. This brings it back to the last date's balance */
  toDate: string | null;
  updateDisplayData: UpdateDisplayData;
  setXIndexAndPosition: (
    xIndex: number,
    xPos: number,
    graphWidth: number
  ) => void; // props.cx > 165, props.index
  isLoading: boolean;
  timeRange: TimeFrame;
}

export default function SummaryGraph(props: IncomingProps) {
  const {
    color: { $chartLinePrimary, $chartLineSecondary, $borderBold, $textSubtle },
  } = useTheme();

  const { customDateRange } = usePerformanceCustomDateRangeContext();

  const areaFill = opacity($chartLinePrimary, 0.15);

  const [chartRef, { width }] = useElementSize();

  const [additionalDelay, setAdditionalDelay] = useState<boolean>(true);

  useEffect(() => {
    if (
      !props.isLoading &&
      props.balanceSummary.length <= 0 &&
      additionalDelay
    ) {
      // sometimes there is a state where props.isLoading turns false after loading but balanceSummary is []
      // in this case we don't want to show the "Data Unavailable" immediately but wait a tiny bit bc it may populate
      // so additionalDelay is there to keep the loading state going for just 500ms longer when we hit this scenario
      setTimeout(() => {
        setAdditionalDelay(false);
      }, 500);
    }

    // intentionally don't want to add additionalDelay to the deps array bc we don't want it to trigger the useEffect when it changes
    // we only want to listen for changes from props.isLoading and props.balanceSummary
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isLoading, props.balanceSummary]);

  useEffect(() => {
    setAdditionalDelay(true); // want to reset if we get new data/switch time frames
  }, [props.timeRange, customDateRange]);

  const isLoading =
    props.isLoading ||
    (!props.isLoading && props.balanceSummary.length <= 0 && additionalDelay);

  return (
    <div
      className={styles.graphContainer}
      ref={chartRef}
      style={{ height: `${graphPresets.graphHeight}px` }}
    >
      <div className={styles.graph}>
        {isLoading && (
          <div className={styles.noDataDiv}>
            <div className={styles.loading}>
              <LogoLoadingStill />
            </div>
          </div>
        )}

        {!props.isLoading &&
          props.balanceSummary.length <= 0 &&
          !additionalDelay && (
            <div className={styles.noDataDiv}>
              <div className={styles.noDataMsg}>Data Unavailable</div>
            </div>
          )}

        <ComposedChart
          width={width}
          height={graphPresets.graphHeight}
          data={props.balanceSummary}
          /* Reset to original balance display */
          onMouseLeave={() => {
            if (props.toDate === null) {
              return;
            }
            props.updateDisplayData(props.toDate);
          }}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid
            vertical={false}
            stroke={$borderBold}
            strokeDasharray="0"
            strokeWidth={1}
          />

          <XAxis
            style={{ fontSize: graphPresets.axisFontsize }}
            height={graphPresets.XAxisHeight}
            dataKey="date"
            stroke={$borderBold}
            tickLine={false}
            dy={graphPresets.dy}
            tick={false}
          />

          <YAxis
            style={{ fontSize: graphPresets.axisFontsize }}
            width={graphPresets.YAxisWidth}
            tickFormatter={(balance: number) => {
              if (props.balanceSummary.length > 0) {
                return formatBalance(balance);
              }

              return formatTickForNoData(balance);
            }}
            tickCount={graphPresets.yTickCount}
            stroke={$textSubtle}
            axisLine={false}
            tickLine={false}
            tickMargin={graphPresets.tickMargin}
            dx={graphPresets.dx}
            domain={
              props.balanceSummary.length > 0 ? undefined : [0, 1_000_000]
            }
          />

          <Tooltip cursor={false} content={<></>} />

          <Area
            isAnimationActive={false}
            type={"linear"}
            strokeWidth={graphPresets.strokeWidth}
            dataKey="balanceOnEndDay"
            stroke={$chartLinePrimary}
            fill={areaFill}
            activeDot={(dotProps) => {
              return (() => {
                //Strange syntax needed to bypass "Cannot update a component... error due to `setXIndexAndPosition`"
                props.updateDisplayData(dotProps.payload.endDate);

                props.setXIndexAndPosition(dotProps.index, dotProps.cx, width);

                return (
                  <CustomDot
                    currentDataKey={
                      dotProps.dataKey === "balanceOnEndDay"
                        ? "primary"
                        : "secondary"
                    }
                    props={dotProps}
                  />
                );
              })();
            }}
            strokeLinejoin="round" //*Important for Safari* - prevents rendering of small bumps in the stroke line
          />

          <Line
            isAnimationActive={false}
            type={"linear"}
            strokeWidth={graphPresets.strokeWidth}
            stroke={$chartLineSecondary}
            strokeDasharray={"6 3"}
            dataKey={(dataKeyArg) => dataKeyArg.relativeContributions}
            dot={false}
            activeDot={(dotProps) => (
              <CustomDot
                currentDataKey={
                  dotProps.key === "balanceOnEndDay" ? "primary" : "secondary"
                }
                props={dotProps}
              />
            )}
          />
        </ComposedChart>
      </div>
    </div>
  );
}
