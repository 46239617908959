import React from "react";

import MuiSkeleton, { SkeletonProps } from "@mui/material/Skeleton";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./Skeleton.module.css";

export default function Skeleton({
  children,
  className,
  ...restProps
}: React.PropsWithChildren<SkeletonProps>): JSX.Element {
  return (
    <MuiSkeleton
      className={toClassName(styles.skeleton, className)}
      {...restProps}
    >
      {children}
    </MuiSkeleton>
  );
}
