import React, { useCallback, useEffect, useMemo, useState } from "react";

import { format } from "date-fns";
import opacity from "hex-color-opacity";
import { useFlags } from "launchdarkly-react-client-sdk";
import { head, last, nth, throttle } from "lodash";
import { useParams } from "react-router-dom";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useElementSize } from "usehooks-ts";

import useManuallyTrackedAccountGraph from "@fartherfinance/frontend/api/Accounts/hooks/useManuallyTrackedAccountGraph";
import {
  DateRange,
  TimeFrame,
} from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Types";
import { AltAssetId } from "@fartherfinance/frontend/api/Types";
import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import TabTitle from "../Components/TabTitle";
import { dateFormat } from "@src/constants/dateFormat";
import CustomDot from "@src/multiCustodian/components/GraphComponents/Summary/CustomDot";
import DateDisplay, {
  XAxisData,
} from "@src/multiCustodian/components/GraphComponents/Summary/DateDisplay/DateDisplay";
import graphPresets from "@src/multiCustodian/components/GraphComponents/Summary/graphPresets";
import { formatBalance } from "@src/multiCustodian/components/PerformanceGroups/Shared/helpers";
import TimeRangeSelector from "@src/multiCustodian/components/PerformanceGroups/Shared/TimeRangeSelector";
import EndDate from "@src/multiCustodian/components/PerformanceGroups/Summary/components/EndDate/EndDate";
import { usePerformanceCustomDateRangeContext } from "@src/multiCustodian/components/PerformanceGroups/Summary/components/PerformanceCustomDateRangeContextProvider";
import { timeFrameOptions } from "@src/multiCustodian/components/PerformanceGroups/Summary/SummaryGraphContainer";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import { DEFAULT_TIME_FRAME } from "@src/multiCustodian/pages/Dashboard/Performance_Groups/shared";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import PortaledChip from "@src/sharedComponents/PortaledChip/PortaledChip";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import ManualBreakdownTable from "./ManualBreakdownTable/ManualBreakdownTable";

import dateDisplayStyles from "../../../../GraphComponents/Summary/DateDisplay/DateDisplay.module.css";
import sharedStyles from "../SharedStyles.module.css";

const VALUE = "value";
const DATE = "date";

const SummaryTab = () => {
  const { accountId } = useParams<{
    accountId: AltAssetId;
  }>();

  const { enableLowDataPerformanceGroups } = useFlags();

  const [isHoveringOverGraph, setIsHoveringOverGraph] = useState(false);

  const [xAxisData, setXAxisData] = useState<XAxisData | null>(null);

  const [timeFrame, setTimeFrame] = useState<TimeFrame>("1Y");

  const [dataIdx, setDataIdx] = useState<number | null>(null);

  const updateDataIdx = throttle((idx: number) => {
    setDataIdx(idx);
  }, 10);

  const changeXAxisData = useCallback((axisData: XAxisData): void => {
    setXAxisData(axisData);
  }, []);

  const changeXAxisDataThrottled = useMemo(
    () => throttle(changeXAxisData, 100),
    [changeXAxisData]
  );

  const {
    customDateRange,
    showCustomDateRangeForm,
    setShowCustomDateRangeForm,
  } = usePerformanceCustomDateRangeContext();

  const portaledChipLabel = useMemo(() => {
    if (customDateRange.from === null || customDateRange.to === null) {
      return "From - To -";
    }

    return `From: ${format(customDateRange.from, "M/d/yyyy")} - To: ${format(
      customDateRange.to,
      "M/d/yyyy"
    )}`;
  }, [customDateRange]);

  const customTimeFrame: DateRange | null = useMemo(() => {
    if (
      timeFrame !== "Custom" ||
      customDateRange.from === null ||
      customDateRange.to === null
    ) {
      return null;
    }

    return {
      startDate: format(customDateRange.from, dateFormat),
      endDate: format(customDateRange.to, dateFormat),
    };
  }, [timeFrame, customDateRange]);

  const auth = useRequestAuth();

  const data = useManuallyTrackedAccountGraph(
    accountId,
    timeFrame,
    customTimeFrame,
    auth
  );

  const startingBalance = head(data.data)?.value ?? null;

  const currentBalance = useMemo(() => {
    if (dataIdx === null) {
      return null;
    }

    return nth(data.data, dataIdx)?.value ?? null;
  }, [data.data, dataIdx]);

  const marketGains = useMemo(() => {
    if (startingBalance === null || currentBalance === null) {
      return null;
    }

    return currentBalance - startingBalance;
  }, [currentBalance, startingBalance]);

  const lastIdx =
    data.data === undefined || data.data.length === null
      ? null
      : data.data.length - 1;

  useEffect(() => {
    if (data.data === undefined || lastIdx === null) {
      return;
    }

    setDataIdx(lastIdx);
  }, [data.data, lastIdx]);

  const {
    color: { $chartLinePrimary, $textSubtle, $borderBold },
  } = useTheme();

  const areaFill = opacity($chartLinePrimary, 0.15);

  const [chartRef, { width }] = useElementSize();

  if (data.isLoading) {
    return (
      <>
        <TabTitle />
        <Skeleton height={graphPresets.graphHeight} />

        <Skeleton height={40} />

        <Spacer verticalSpacing="20px" />

        <ManualBreakdownTable
          startingBalance={null}
          marketGains={null}
          currentBalance={null}
        />
      </>
    );
  }

  if (data.hasError) {
    return (
      <>
        <TabTitle />
        <div className={sharedStyles.error}>Data unavailable</div>
      </>
    );
  }

  return (
    <>
      {timeFrame === "Custom" && (
        <PortaledChip
          portalMountElementId={"performanceCustomTimeFrameChip"}
          label={portaledChipLabel}
          onPress={() => setShowCustomDateRangeForm(!showCustomDateRangeForm)}
          onClear={() => setTimeFrame(DEFAULT_TIME_FRAME)}
        />
      )}

      <div className={sharedStyles.heading}>
        <div className={sharedStyles.headingText}>Account Summary</div>

        <div id="performanceCustomTimeFrameChip" />
      </div>

      <div
        onMouseEnter={() => setIsHoveringOverGraph(true)}
        onMouseLeave={() => setIsHoveringOverGraph(false)}
        ref={chartRef}
      >
        <ResponsiveContainer width={"100%"} height={graphPresets.graphHeight}>
          <AreaChart
            onMouseLeave={() => setDataIdx(lastIdx)}
            data={data.data}
            margin={{
              top: 10,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid
              vertical={false}
              stroke={$borderBold}
              strokeDasharray="0"
              strokeWidth={1}
            />

            <XAxis
              style={{ fontSize: graphPresets.axisFontsize }}
              height={graphPresets.XAxisHeight}
              dataKey={DATE}
              stroke={$borderBold}
              tickLine={false}
              dy={graphPresets.dy}
              tick={false}
            />

            <YAxis
              style={{ fontSize: graphPresets.axisFontsize }}
              width={graphPresets.YAxisWidth}
              tickFormatter={formatBalance}
              tickCount={graphPresets.yTickCount}
              stroke={$textSubtle}
              axisLine={false}
              tickLine={false}
              tickMargin={graphPresets.tickMargin}
              dx={graphPresets.dx}
            />

            <Tooltip cursor={false} content={<></>} />

            <Area
              isAnimationActive={false}
              type={"linear"}
              strokeWidth={graphPresets.strokeWidth}
              dataKey={VALUE}
              stroke={$chartLinePrimary}
              fill={areaFill}
              activeDot={(dotProps) =>
                (() => {
                  updateDataIdx(dotProps.index);

                  changeXAxisDataThrottled({
                    index: dotProps.index,
                    xPos: dotProps.cx,
                    graphWidth: width,
                  });

                  return (
                    <CustomDot
                      currentDataKey={
                        dotProps.dataKey === VALUE ? "primary" : "secondary"
                      }
                      props={dotProps}
                    />
                  );
                })()
              }
              strokeLinejoin="round" //*Important for Safari* - prevents rendering of small bumps in the stroke line
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>

      <DateDisplay
        isHoveringOverGraph={isHoveringOverGraph}
        xAxisData={xAxisData}
        startDate={head(data.data)?.[DATE] ?? null}
        dateKey={DATE}
        data={data.data}
      >
        {!isHoveringOverGraph && (
          <>
            <div className={dateDisplayStyles.xAxisFloat}>
              <TimeRangeSelector
                timeRange={timeFrame}
                timeFrameOptions={timeFrameOptions(
                  enableLowDataPerformanceGroups
                )}
                setTimeRange={setTimeFrame}
                isDisabled={false}
              />
            </div>

            <EndDate endDate={last(data.data)?.date ?? null} />
          </>
        )}
      </DateDisplay>

      <Spacer verticalSpacing="20px" />

      <ManualBreakdownTable
        startingBalance={startingBalance}
        marketGains={marketGains}
        currentBalance={currentBalance}
      />

      <Spacer />
    </>
  );
};

export default SummaryTab;
