import React, { useCallback, useEffect, useRef, useState } from "react";

import FilterListIcon from "@mui/icons-material/FilterList";

import {
  OfferingsListQueryParams,
  OfferingsListToolbarFilters,
} from "@src/multiCustodian/components/Marketplace/OfferingsList/useOfferingsListDataV2";
import FilterPopoverSection from "@src/multiCustodian/components/Marketplace/OfferingsListToolbar/FilterPopoverSection";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import Popover from "@src/sharedComponents/Popover/Popover";
import {
  CheckboxListConfig,
  copyConfig,
  getTotalAndNumChecked,
} from "@src/sharedComponents/ScrollableCheckboxList/ScrollableCheckboxList";
import { pluralize } from "@src/utils/pluralize";

import {
  investorQualificationFilterDefault,
  liquidityFilterDefault,
  lockUpFilterDefault,
  minimumInvestmentFilterDefault,
  objectivesFilterDefault,
  strategyFilterDefault,
  subcriptionsFilterDefault,
  subStrategyFilterDefault,
} from "./AltAssetsFilters.config";
import { mapAltAssetsFiltersToQueryParams } from "./AltAssetsFilters.utils";

import styles from "./AltAssetsFilters.module.css";

interface AltAssetsFiltersProps {
  filters: OfferingsListToolbarFilters;
  onQueryParamChange: (filters: Partial<OfferingsListQueryParams>) => void;
}

const AltAssetsFilters: React.FC<AltAssetsFiltersProps> = ({
  filters,
  onQueryParamChange,
}) => {
  const [showFilterPopover, setShowFilterPopover] = useState(false);
  const filterPopoverAnchorRef = useRef<HTMLDivElement>(null);

  const [objectivesFilters, setObjectivesFilters] = useState(
    objectivesFilterDefault
  );
  const [strategyFilters, setStrategyFilters] = useState(strategyFilterDefault);
  const [subStrategyFilters, setSubStrategyFilters] = useState(
    subStrategyFilterDefault
  );
  const [minimumInvestmentFilters, setMinimumInvestmentFilters] = useState(
    minimumInvestmentFilterDefault
  );
  const [investorQualificationFilters, setInvestorQualificationFilters] =
    useState(investorQualificationFilterDefault);
  const [liquidityFilters, setLiquidityFilters] = useState(
    liquidityFilterDefault
  );
  const [subscriptionsFilters, setSubscriptionsFilters] = useState(
    subcriptionsFilterDefault
  );
  const [lockUpFilters, setLockUpFilters] = useState(lockUpFilterDefault);

  const getSelectedFiltersLabel = useCallback(
    (filters: CheckboxListConfig<string>, label: string) => {
      const result = getTotalAndNumChecked(filters);

      return result.numChecked === result.total
        ? "All"
        : pluralize(result.numChecked, label, true);
    },
    []
  );

  const handleApplyFilters = (): void => {
    onQueryParamChange({
      page: 1,
      ...mapAltAssetsFiltersToQueryParams({
        objectivesFilters,
        strategyFilters,
        subStrategyFilters,
        minimumInvestmentFilters,
        investorQualificationFilters,
        liquidityFilters,
        subscriptionsFilters,
        lockUpFilters,
      }),
    });
    setShowFilterPopover(false);
  };

  const handleClearAllFilters = (): void => {
    setObjectivesFilters(copyConfig(objectivesFilterDefault));
    setStrategyFilters(copyConfig(strategyFilterDefault));
    setSubStrategyFilters(copyConfig(subStrategyFilterDefault));
    setMinimumInvestmentFilters(copyConfig(minimumInvestmentFilterDefault));
    setInvestorQualificationFilters(
      copyConfig(investorQualificationFilterDefault)
    );
    setLiquidityFilters(copyConfig(liquidityFilterDefault));
    setSubscriptionsFilters(copyConfig(subcriptionsFilterDefault));
    setLockUpFilters(copyConfig(lockUpFilterDefault));
  };

  const handleUpdateFiltersState = (
    filterBy: string[] | undefined,
    filterByDefault: CheckboxListConfig<string>,
    setFilterState: React.Dispatch<
      React.SetStateAction<CheckboxListConfig<string>>
    >
  ) => {
    // NOTE: sync query params filters with local state
    if (filterBy) {
      setFilterState((currState) => {
        const newConfig = copyConfig(currState);

        Object.keys(newConfig).forEach((key) => {
          newConfig[key].checked = filterBy.includes(key);
        });

        return newConfig;
      });
      // NOTE: if filterBy is undefined, we should fallback to default
    } else {
      setFilterState(filterByDefault);
    }
  };

  useEffect(() => {
    const {
      filterByObjectives,
      filterByStrategy,
      filterBySubStrategy,
      filterByMinimumInvestment,
      filterByInvestorQualification,
      filterByLiquidity,
      filterBySubscriptions,
      filterByLockUp,
    } = filters;

    // NOTE: turn query param, which is a number, into currency string so it's 1:1 with the filter config
    const minimumInvestment = filterByMinimumInvestment?.map((value) =>
      value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
      })
    );

    handleUpdateFiltersState(
      filterByObjectives,
      objectivesFilterDefault,
      setObjectivesFilters
    );
    handleUpdateFiltersState(
      filterByStrategy,
      strategyFilterDefault,
      setStrategyFilters
    );
    handleUpdateFiltersState(
      filterBySubStrategy,
      subStrategyFilterDefault,
      setSubStrategyFilters
    );
    handleUpdateFiltersState(
      minimumInvestment,
      minimumInvestmentFilterDefault,
      setMinimumInvestmentFilters
    );
    handleUpdateFiltersState(
      filterByInvestorQualification,
      investorQualificationFilterDefault,
      setInvestorQualificationFilters
    );
    handleUpdateFiltersState(
      filterByLiquidity,
      liquidityFilterDefault,
      setLiquidityFilters
    );
    handleUpdateFiltersState(
      filterBySubscriptions,
      subcriptionsFilterDefault,
      setSubscriptionsFilters
    );
    handleUpdateFiltersState(
      filterByLockUp,
      lockUpFilterDefault,
      setLockUpFilters
    );
  }, [filters]);

  return (
    <Popover
      open={showFilterPopover}
      onClose={() => setShowFilterPopover(false)}
      anchor={filterPopoverAnchorRef.current}
      transformOriginVertical={-10}
      popoverElement={
        <div ref={filterPopoverAnchorRef}>
          <ButtonPrimary
            text="Filters"
            onClick={() => setShowFilterPopover(!showFilterPopover)}
            variant="outlined"
            buttonType="primary"
            startIcon={<FilterListIcon className={styles.filterIcon} />}
            sx={{ height: "30px" }}
          />
        </div>
      }
    >
      <div className={styles.filtersContainer}>
        <FilterPopoverSection
          sectionLabel="Objectives"
          filtersConfig={objectivesFilters}
          setFiltersConfig={setObjectivesFilters}
          selectedFiltersLabel={getSelectedFiltersLabel(
            objectivesFilters,
            "Objectives"
          )}
        />
        <FilterPopoverSection
          sectionLabel="Strategy"
          filtersConfig={strategyFilters}
          setFiltersConfig={setStrategyFilters}
          selectedFiltersLabel={getSelectedFiltersLabel(
            strategyFilters,
            "Strategy"
          )}
        />
        <FilterPopoverSection
          sectionLabel="Substrategy"
          filtersConfig={subStrategyFilters}
          setFiltersConfig={setSubStrategyFilters}
          selectedFiltersLabel={getSelectedFiltersLabel(
            subStrategyFilters,
            "Substrategy"
          )}
        />
        <FilterPopoverSection
          sectionLabel="Minimum Investment"
          filtersConfig={minimumInvestmentFilters}
          setFiltersConfig={setMinimumInvestmentFilters}
          selectedFiltersLabel={getSelectedFiltersLabel(
            minimumInvestmentFilters,
            "Minimum Investment"
          )}
        />
        <FilterPopoverSection
          sectionLabel="Investor Qualification"
          filtersConfig={investorQualificationFilters}
          setFiltersConfig={setInvestorQualificationFilters}
          selectedFiltersLabel={getSelectedFiltersLabel(
            investorQualificationFilters,
            "Investor Qualification"
          )}
        />
        <FilterPopoverSection
          sectionLabel="Liquidity"
          filtersConfig={liquidityFilters}
          setFiltersConfig={setLiquidityFilters}
          selectedFiltersLabel={getSelectedFiltersLabel(
            liquidityFilters,
            "Liquidity"
          )}
        />
        <FilterPopoverSection
          sectionLabel="Subscriptions"
          filtersConfig={subscriptionsFilters}
          setFiltersConfig={setSubscriptionsFilters}
          selectedFiltersLabel={getSelectedFiltersLabel(
            subscriptionsFilters,
            "Subscriptions"
          )}
        />
        <FilterPopoverSection
          sectionLabel="Lock Up"
          filtersConfig={lockUpFilters}
          setFiltersConfig={setLockUpFilters}
          selectedFiltersLabel={getSelectedFiltersLabel(
            lockUpFilters,
            "Lock Up"
          )}
        />

        <div className={styles.filtersContainerFooter}>
          <ButtonPrimary
            text="Clear"
            onClick={handleClearAllFilters}
            variant="outlined"
            buttonType="primary"
          />

          <div className={styles.filtersActions}>
            <ButtonPrimary
              text="Cancel"
              onClick={() => setShowFilterPopover(false)}
              variant="outlined"
              buttonType="primary"
            />

            <ButtonPrimary
              text="Apply"
              onClick={handleApplyFilters}
              variant="contained"
              buttonType="primary"
            />
          </div>
        </div>
      </div>
    </Popover>
  );
};

export default AltAssetsFilters;
