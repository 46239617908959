import { alpha } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

export const useStyles = makeStyles()((theme) => ({
  textField: {
    "& label": {
      "&.Mui-focused": {
        color: alpha(theme.palette.common.white, 0.5),
      },
      "&.Mui-error": {
        color: alpha(theme.palette.common.white, 0.5),
      },
      "&.Mui-disabled": {
        color: alpha(theme.palette.common.white, 0.5),
      },
    },

    "& .MuiInputBase-root": {
      marginTop: theme.spacing(pxToSpacing(37)), // 18px spacing + 19px label height
      caretColor: "#142edb",

      "&.MuiInputBase-sizeSmall": {
        marginTop: theme.spacing(pxToSpacing(27)), // 8px spacing + 19px label height
      },

      "&:hover:before": {
        borderBottomColor: alpha(theme.palette.common.white, 0.1),
        borderBottomWidth: 1,
      },

      "&.MuiOutlinedInput-root": {
        marginTop: 0,
        paddingTop: theme.spacing(pxToSpacing(3)),
        paddingBottom: theme.spacing(pxToSpacing(3)),
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.appearance.$borderBold,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.appearance.$backgroundSubtlest,

        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: 0,
          },
        },
      },
    },

    "& .MuiInput-input": {
      "&.Mui-disabled": {
        color: alpha(theme.palette.common.white, 0.5),
        WebkitTextFillColor: "unset",
      },
    },

    "& .MuiInput-underline": {
      "&:after": {
        borderBottomWidth: 1,
        borderBottomColor: "#142edb",
      },
      "&.Mui-error:after": {
        borderBottomWidth: 1,
        borderBottomColor: theme.appearance.$borderDanger,
      },
      "&:before": {
        borderBottomColor: alpha(theme.palette.common.white, 0.1),
      },
    },

    "& .MuiFormHelperText-root": {
      fontFamily: theme.typography.fontSecondary,
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: "16px",
      color: theme.colors.primary.tints.navy50,

      "&.Mui-error": {
        color: theme.appearance.$textDanger,
      },
    },
  },
  label: {
    fontFamily: theme.typography.fontSecondary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "19px",
    letterSpacing: "0.63px",
    color: alpha(theme.palette.common.white, 0.5),
    transform: "none",
  },
  input: {
    fontFamily: theme.typography.fontSecondary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "19px",
    letterSpacing: "0.63px",
    color: theme.palette.common.white,
  },
}));
