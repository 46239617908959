import * as React from "react";

import { Link } from "react-router-dom";

import Button, {
  Props as ButtonProps,
} from "@src/multiCustodian/components/MUI/Button/Button";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./LinkButton.module.css";

interface Props
  extends Omit<ButtonProps, "onClick" | "variant" | "buttonType"> {
  to: string;
  isCustom?: boolean;
  className?: string;
  variant?: "contained" | "outlined" | "text";
  buttonType?:
    | "primary"
    | "secondary"
    | "inherit"
    | "success"
    | "error"
    | "info"
    | "warning";
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const LinkButton = (props: Props) => {
  if (props.disabled) {
    return (
      <>
        {props.isCustom ? (
          <button className={toClassName(styles.button, props.className)}>
            {props.text}
          </button>
        ) : (
          <Button
            text={props.text}
            onClick={props.onClick ?? (() => undefined)}
            variant={props.variant ?? "contained"}
            size={props.size}
            buttonType={props.buttonType ?? "primary"}
            disabled={props.disabled}
            target={props.target}
            sx={props.sx}
            tabIndex={props.tabIndex}
            onKeyDown={props.onKeyDown}
            theme={props.theme}
            startIcon={props.startIcon}
            endIcon={props.endIcon}
            style={props.style}
          />
        )}
      </>
    );
  }

  return (
    <Link to={props.to} style={{ textDecoration: "none" }}>
      {props.isCustom ? (
        <button className={toClassName(styles.button, props.className)}>
          {props.text}
        </button>
      ) : (
        <Button
          text={props.text}
          onClick={props.onClick ?? (() => undefined)}
          variant={props.variant ?? "contained"}
          size={props.size}
          buttonType={props.buttonType ?? "primary"}
          disabled={props.disabled}
          target={props.target}
          sx={props.sx}
          tabIndex={props.tabIndex}
          onKeyDown={props.onKeyDown}
          theme={props.theme}
          startIcon={props.startIcon}
          endIcon={props.endIcon}
          style={props.style}
        />
      )}
    </Link>
  );
};

export default LinkButton;
