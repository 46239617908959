import React, { useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Avatar, Button } from "@src/yellowstone/components";
import { useResubmitAccountsMutation } from "@src/yellowstone/modules/accounts";
import { formatters } from "@src/yellowstone/modules/shared";
import type { UserDto } from "@src/yellowstone/modules/users";

import { AccountEntry } from "./components";
import { useStyles } from "./UserAccounts.styles";
import { calculateTotalBalance } from "./UserAccounts.utils";

interface UserAccountsProps {
  user: UserDto;
  isSelectedUserMode: boolean;
}

export const UserAccounts: React.FC<UserAccountsProps> = ({
  user,
  isSelectedUserMode,
}) => {
  const { classes, cx } = useStyles();
  const resubmitUserAccounts = useResubmitAccountsMutation();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const isUserKickOffAccountsEligble =
    isSelectedUserMode && user.accounts.length > 0;

  const handleCollapseToggle = (): void => {
    setIsCollapsed((prevIsCollapsed) => !prevIsCollapsed);
  };

  const handleResubmitUserAccounts = (): void => {
    resubmitUserAccounts.mutate({ clientId: user.id });
  };

  return (
    <>
      <Avatar user={user} showUsername />

      <Paper className={classes.paper} elevation={0}>
        <Stack>
          <Stack
            className={classes.collapseToggle}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            onClick={handleCollapseToggle}
          >
            <Typography className={classes.accountName}>
              Farther Core
            </Typography>

            <Stack direction="row" alignItems="center">
              <Typography className={classes.balance}>
                {formatters
                  .currencyFormatter()
                  .format(calculateTotalBalance(user.accounts))}
              </Typography>
              <ExpandMoreIcon
                className={cx(classes.icon, {
                  [classes.iconRoate]: !isCollapsed,
                })}
              />
            </Stack>
          </Stack>

          <Divider
            className={cx(classes.divider, {
              [classes.dividerBottomSpacing]: !isCollapsed,
            })}
          />

          <Collapse in={!isCollapsed}>
            {user.accounts.map((account, index) => (
              <AccountEntry
                key={index}
                className={classes.accountEntry}
                account={account}
              />
            ))}
          </Collapse>
        </Stack>
      </Paper>

      {isUserKickOffAccountsEligble && (
        <Button
          className={classes.kickOffButton}
          variant="contained"
          size="large"
          onClick={handleResubmitUserAccounts}
        >
          Kick off accounts
        </Button>
      )}
    </>
  );
};
