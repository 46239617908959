import React from "react";

import { actions } from "../reducer/actions";
import { useAddCollaboratorContext } from "../reducer/Context";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import ActionRow from "@src/sharedComponents/ActionRow/ActionRow";

const NameReviewRow: React.FC = () => {
  const {
    state: {
      data: { firstName, lastName },
    },
    dispatch,
  } = useAddCollaboratorContext();

  const handleOnClick = () =>
    dispatch({ type: actions.SET_PAGE_INDEX, payload: 0 });

  return (
    <ActionRow
      start={"Name"}
      end={`${firstName} ${lastName}`}
      action={
        <Button
          text="Edit"
          variant="text"
          buttonType="primary"
          onClick={handleOnClick}
        />
      }
    />
  );
};

export default NameReviewRow;
