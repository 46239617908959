import React from "react";

const Lending: React.FC = () => (
  <svg
    width="100%"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.4375 1.53125V4.8125L1.53125 4.8125V1.53125H3.03257C3.7622 1.53125 4.35369 2.11639 4.35369 2.83821H4.50048C4.50048 2.11639 5.09196 1.53125 5.8216 1.53125H7.4375Z"
      fill="currentColor"
    />
    <path
      d="M7.4375 11.9219V12.4688H1.53125V9.1875H3.03257C3.7622 9.1875 4.35369 9.77264 4.35369 10.4945H4.50048C4.50048 9.77264 5.09196 9.1875 5.8216 9.1875H6.60947C6.57859 9.36516 6.5625 9.54789 6.5625 9.73438C6.5625 10.5824 6.89533 11.3528 7.4375 11.9219Z"
      fill="currentColor"
    />
    <path
      d="M7.4375 7.54687V5.35938H5.8216C5.09196 5.35938 4.50048 5.94452 4.50048 6.66633H4.35369C4.35369 5.94452 3.7622 5.35938 3.03257 5.35938L1.53125 5.35937V8.64062H6.75612C6.90722 8.22934 7.14107 7.85802 7.4375 7.54687Z"
      fill="currentColor"
    />
    <path
      d="M12.4688 9.73438C12.4688 11.2445 11.2445 12.4688 9.73438 12.4688C8.22422 12.4688 7 11.2445 7 9.73438C7 8.22422 8.22422 7 9.73438 7C11.2445 7 12.4688 8.22422 12.4688 9.73438Z"
      fill="currentColor"
    />
  </svg>
);

export default Lending;
