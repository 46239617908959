import React, { ComponentProps, useState } from "react";

import AlertMessageModal from "../../../components/Modals/AlertMessageModal";

import PlansHeader from "./PlansHeader";
import PlansTable from "./PlansTable";

interface PlansHeaderAndListProps {
  startPage?: number;
  startQuery?: string;
}

const PlansHeaderAndList = (props: PlansHeaderAndListProps): JSX.Element => {
  const [plansFilterStr, setPlansFilterStr] = useState<string>(
    props.startQuery ?? ""
  );
  const [isPlanBeingCreated, setPlanBeingCreated] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<ComponentProps<
    typeof AlertMessageModal
  > | null>(null);

  return (
    <React.Fragment>
      {alertMessage && (
        <AlertMessageModal
          top_text={alertMessage.top_text}
          bottom_text={alertMessage.bottom_text}
          button_blue_text={alertMessage.button_blue_text}
          button_blue_onClick={alertMessage.button_blue_onClick}
          button_yellow_text={alertMessage.button_yellow_text}
          button_yellow_onClick={alertMessage.button_yellow_onClick}
          img_src={alertMessage.img_src}
          modal_style={alertMessage.modal_style}
        />
      )}

      <PlansHeader
        plansFilterStr={plansFilterStr}
        setPlansFilterStr={setPlansFilterStr}
        setPlanBeingCreated={setPlanBeingCreated}
      />

      <PlansTable
        plansFilterStr={plansFilterStr}
        isNewPlanBeingCreated={isPlanBeingCreated}
        setPlanBeingDeleted={setPlanBeingCreated}
        setAlertMessage={setAlertMessage}
        startPage={props.startPage}
      />
    </React.Fragment>
  );
};

export default PlansHeaderAndList;
