import { useEffect } from "react";

import {
  Control,
  FieldPath,
  FieldValues,
  UseFormTrigger,
  useWatch,
} from "react-hook-form";

export default function useTriggerForm<Form extends FieldValues>(
  control: Control<Form, any>,
  trigger: UseFormTrigger<Form>,
  needsManualTriggering: FieldPath<Form>[]
) {
  const wholeFormValues = useWatch<Form>({ control });

  useEffect(() => {
    // ** THIS IS NECESSARY WHEN `required` IS DYNAMIC **
    //
    // Manual validation if date becomes required. There is a bug in `react-hook-forms` that when a
    // dependent input becomes required, validation is not re-run correctly at the component level.
    // This forces a manual validation, so errors show up on the component.
    //
    // Step to recreate bug:
    // 1. Comment out this `useEffect`
    // 2. Enter an invalid date "01/01/1001" (validation is not run as the date is not required)
    // 3. Enter a target goal "1.23" (validation should be run on the date as it is now required)
    //
    // Expectation: date input to be invalid and have an error message
    // Reality: form is invalid (correct) but an error _is not_ shown on the date input (incorrect, the bug)

    needsManualTriggering.forEach((name) => {
      const value = wholeFormValues[name];
      if (value !== undefined) {
        trigger(name);
      }
    });
  }, [trigger, needsManualTriggering, wholeFormValues]);
}
