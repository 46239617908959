import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

export const useStyles = makeStyles()((theme) => ({
  entryContainer: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(pxToSpacing(20)),
    },
  },
  title: {
    fontFamily: theme.typography.fontSecondary,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: "24px",
    letterSpacing: "0.63px",
    color: theme.appearance.$text,

    marginBottom: theme.spacing(pxToSpacing(10)),
  },
  reason: {
    fontFamily: theme.typography.fontSecondary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.fontSize,
    lineHeight: "19px",
    letterSpacing: "0.63px",
    color: theme.colors.primary.shades.cream70,
  },
  reasonTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  link: {
    fontFamily: theme.typography.fontSecondary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.fontSize,
    lineHeight: "19px",
    letterSpacing: "0.63px",
    color: theme.appearance.$link,
    textDecoration: "none",

    marginRight: theme.spacing(pxToSpacing(10)),
  },
}));
