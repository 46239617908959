import React from "react";

import { Stack, Typography } from "@mui/material";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./OfferingDetailsParagraph.module.css";

interface OfferingDetailsParagraphProps {
  title: string;
  description: string;
}

const OfferingDetailsParagraph: React.FC<OfferingDetailsParagraphProps> = ({
  title,
  description,
}) => (
  <Stack>
    <Typography className={toClassName(styles.text, styles.title)}>
      {title}
    </Typography>
    <Typography className={toClassName(styles.text, styles.description)}>
      {description}
    </Typography>
  </Stack>
);

export default OfferingDetailsParagraph;
