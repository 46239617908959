import React, { useCallback, useState } from "react";

import { isAxiosError } from "axios";
import { useHistory, useParams } from "react-router-dom";

import useDeleteEquivalentSecurityList from "@fartherfinance/frontend/api/EquivalentSecurities/hooks/useDeleteEquivalentSecurityList";
import { EquivalentSecurityListId } from "@fartherfinance/frontend/api/Types";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import Modal from "@src/sharedComponents/Modal/Modal";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./DeleteListModal.module.css";

const DeleteListModal: React.FC = () => {
  const [isMutating, setIsMutating] = useState<boolean>(false);

  const { listId } = useParams<{
    listId: EquivalentSecurityListId;
  }>();

  const history = useHistory();

  const statusNotification = useStatusNotification();

  const auth = useAdvisorRequestAuth();
  const deleteEquivalentSecurityList = useDeleteEquivalentSecurityList(
    listId,
    auth
  );

  const onDelete = async () => {
    try {
      setIsMutating(true);

      await deleteEquivalentSecurityList();

      setIsMutating(false);

      statusNotification("List deleted", "Success");
      setIsMutating(false);

      history.push({
        pathname: "/Advisor/Admin",
        state: history.location.state,
      });
    } catch (e) {
      const serverMsg = isAxiosError(e) ? e.response?.data?.message : null;
      const errorMsg = serverMsg ?? "Failed to delete list";
      statusNotification(`${errorMsg}`, "Error");
      setIsMutating(false);
    }
  };

  const onCancel = useCallback(
    () =>
      history.push({
        pathname: `/Advisor/Admin/EquivalentSecurities/${listId}`,
        state: history.location.state,
      }),
    [listId, history]
  );

  return (
    <Modal closeModal={onCancel} modalStyle={{ width: "560px" }}>
      <div className={styles.title}>
        Deleting this list may cause client portfolios to be rebalanced.
      </div>

      <div className={styles.text}>
        Securities in the list will no longer be considered equivalent.
        Depending on a client's target model portfolio, securities in the list
        may be bought or sold.
      </div>

      <div className={styles.footer}>
        {isMutating ? (
          <>
            <Skeleton width={80} height={35} style={{ transform: "none" }} />

            <Skeleton width={100} height={35} style={{ transform: "none" }} />
          </>
        ) : (
          <>
            <Button
              variant={"outlined"}
              buttonType={"secondary"}
              text={"Cancel"}
              onClick={onCancel}
            />

            <Button
              variant={"outlined"}
              buttonType={"warning"}
              text={"Delete List"}
              onClick={onDelete}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default DeleteListModal;
