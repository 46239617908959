import React from "react";

import { useParams } from "react-router-dom";

import { SleeveId } from "@fartherfinance/frontend/api/Types";

import Sleeves from "../../Sleeves";
import SleeveSharingTable from "@src/multiCustodian/components/Advisor/Investments/Sleeves/SharingTable/SleeveSharingTable";
import SleeveDrawer from "@src/multiCustodian/components/Sleeves/SleeveDrawer/SleeveDrawer";

export default function SleeveSharing(): JSX.Element {
  const { sleeveId } = useParams<{
    sleeveId: SleeveId;
  }>();

  return (
    <Sleeves>
      <SleeveDrawer curTab="Sharing" sleeveId={sleeveId}>
        <SleeveSharingTable sleeveId={sleeveId} />
      </SleeveDrawer>
    </Sleeves>
  );
}
