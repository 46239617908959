import React, { useRef } from "react";

import DropdownPopoverButton from "@src/sharedComponents/DropdownPopoverButton/DropdownPopoverButton";
import Popover from "@src/sharedComponents/Popover/Popover";
import ScrollableCheckboxList, {
  CheckboxListConfig,
} from "@src/sharedComponents/ScrollableCheckboxList/ScrollableCheckboxList";

import { ActivityTypeParentCheckboxLabel } from "./shared";

interface Props {
  onClose: () => void;
  onOpen: () => void;
  showActivityTypePopover: boolean;
  activityTypeFilter: CheckboxListConfig<ActivityTypeParentCheckboxLabel>;
  setActivityTypeFilter: (
    config: CheckboxListConfig<ActivityTypeParentCheckboxLabel>
  ) => void;
  activityTypesSelectedString: string;
  width: number;
}

const ActivityTypeFilterPopover = (props: Props): JSX.Element => {
  const activityTypePopoverAnchorRef = useRef<HTMLDivElement | null>(null);

  return (
    <Popover
      open={props.showActivityTypePopover}
      onClose={props.onClose}
      anchor={activityTypePopoverAnchorRef.current}
      transformOriginVertical={-10}
      popoverElement={
        <div ref={activityTypePopoverAnchorRef}>
          <DropdownPopoverButton
            text={props.activityTypesSelectedString}
            showPopover={props.onOpen}
            minWidth={props.width}
          />
        </div>
      }
    >
      <ScrollableCheckboxList
        haveSelectAll={true}
        checkboxesConfig={props.activityTypeFilter}
        setCheckboxesConfig={props.setActivityTypeFilter}
        minWidth={props.width}
        maxHeight={432}
      />
    </Popover>
  );
};

export default ActivityTypeFilterPopover;
