import React from "react";

import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import UploadIcon from "@mui/icons-material/Upload";
import { Stack } from "@mui/material";
import { useHistory } from "react-router-dom";

import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";

import styles from "./CreateUpdateCustomModelForm.module.css";

interface Props {
  csvFile: File | null;
  goToCSVUpload: () => void;
  callCreateModel: () => void;
  cameFromUpload: boolean;
  mutating: boolean;
  isValid: boolean;
  isDirty: boolean;
  total: number;
}

const CreateCustomModelFooter = (props: Props): JSX.Element => {
  const history = useHistory();

  return (
    <>
      <Stack direction="row" gap="8px">
        {props.csvFile !== null && (
          <div className={styles.fileNameContainer}>
            <InsertDriveFileOutlinedIcon className={styles.fileIcon} />
            <span className={styles.fileName}>{props.csvFile.name}</span>
          </div>
        )}

        <ButtonPrimary
          text={
            <div className={styles.upload}>
              <span>Upload</span>
              <UploadIcon className={styles.uploadIcon} />
            </div>
          }
          variant="outlined"
          onClick={props.goToCSVUpload}
          buttonType="primary"
        />
      </Stack>

      <Stack direction="row" justifyContent={"flex-end"} gap="16px">
        <ButtonPrimary
          text="Cancel"
          variant="outlined"
          onClick={() =>
            history.push({
              pathname: "/Advisor/Investments/ModelMarketplace",
              state: history.location.state,
            })
          }
          buttonType="primary"
        />

        <ButtonPrimary
          text="Create Model"
          variant="contained"
          onClick={props.callCreateModel}
          buttonType="primary"
          disabled={
            props.mutating ||
            !props.isValid ||
            (!props.isDirty && !props.cameFromUpload) ||
            props.total !== 100
          }
        />
      </Stack>
    </>
  );
};

export default CreateCustomModelFooter;
