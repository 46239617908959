import React from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./ThreeDotsButton.module.css";

interface Props {
  onClick: () => void;
  anchorRef?: React.MutableRefObject<HTMLDivElement | null>;
  className?: string;
}

const ThreeDotsButton: React.FC<Props> = ({
  onClick,
  anchorRef,
  className,
}) => {
  return (
    <div ref={anchorRef}>
      <button
        type="button"
        className={toClassName(styles.button, className)}
        onClick={onClick}
      >
        <MoreHorizIcon />
      </button>
    </div>
  );
};

export default ThreeDotsButton;
