import React, { useState } from "react";

import HelloSign from "hellosign-embedded";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";

import useGetPrivacyPolicy from "@fartherfinance/frontend/api/Document/hooks/useGetPrivacyPolicy";
import usePutUpdateAgreement from "@fartherfinance/frontend/api/Document/hooks/usePutUpdateAgreement";
import getSignatureUrl from "@fartherfinance/frontend/api/Document/requests/getSignatureUrl";
import {
  ClientChecklistItem,
  ClientChecklistItemStatus,
} from "@fartherfinance/frontend/api/Entity/requests/getClientChecklist";

import { DashboardForm } from "../DashboardForms/Types";
import { DocumentsCenterPath } from "@src/config/routing/RouterPaths";
import useApplicationMode from "@src/multiCustodian/hooks/useApplicationMode";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { MiscReducerActions } from "@src/multiCustodian/reducers/misc_Reducer";
import { captureException } from "@src/multiCustodian/services/tracking";
import useThemeFragment from "@src/multiCustodian/theme/useThemeFragment";

import Checklist from "./components/Checklist/Checklist";
import FartherAgreementsModal from "./components/FartherAgreementsModal/FartherAgreementsModal";

import type { ChecklistEntryDetails } from "./components/ChecklistEntry/ChecklistEntry";

interface ChecklistControllerProps {
  checklistItems: ClientChecklistItem[];
  sendToDocCenterTasksList: () => void;
}

const ChecklistController = ({
  checklistItems,
  sendToDocCenterTasksList,
}: ChecklistControllerProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { enableNewDocumentsCenter } = useFlags();
  const dispatch = useDispatch<Dispatch<MiscReducerActions>>();
  const history = useHistory();
  const t = useThemeFragment();

  const auth = useRequestAuth();
  const clientId = auth?.clientId ?? null;

  const { isImpersonationMode } = useApplicationMode();
  const privacyPolicy = useGetPrivacyPolicy(isImpersonationMode ? null : auth);
  const callPutUpdateAgreement = usePutUpdateAgreement(clientId, auth);
  const statusNotification = useStatusNotification();

  const isEveryStepCompletedBeforeCustodianAgreement = checklistItems
    .filter((item) => item.name !== "Custodian Agreement")
    .every((item) => item.status === "Completed");

  const handleSetDrawerPanelContext = (formToShow: DashboardForm): void => {
    dispatch({
      type: "SET_DRAWER_PANEL_CONTEXT",
      payload: {
        formToShow,
      },
    });
  };

  const handleNavigateToDocumentsCenter = (): void => {
    if (clientId) {
      history.push(`/Client/${clientId}/${DocumentsCenterPath}`);
    }
  };

  const handlePrivacyPolicySignature = async (
    checklistItemStatus: ClientChecklistItemStatus
  ): Promise<void> => {
    if (privacyPolicy.hasError) {
      captureException(
        new Error(
          "Error retrieving privacy policy agreement from /doc/v2/client/agreements/privacy-policy"
        ),
        {
          extra: {
            clientId: auth?.clientId,
          },
        }
      );
      return;
    }

    if (privacyPolicy.isLoading || isImpersonationMode) {
      return;
    }

    if (checklistItemStatus === "Completed") {
      return;
    }

    const { agreementId, status } = privacyPolicy.data;

    if (auth && status === "Pending") {
      try {
        const signatureUrl = await getSignatureUrl(agreementId, auth);
        const client = new HelloSign();

        client.open(signatureUrl, {
          clientId: process.env.HELLOSIGN_CLIENT_ID,
          skipDomainVerification: process.env.WEBAPP_ENV !== "PROD",
        });

        client.on("sign", async () => {
          try {
            await callPutUpdateAgreement({ agreementId, status: "Signed" });
            statusNotification("Document signed", "Success");
          } catch (e) {
            statusNotification("Error signing document", "Error");
            captureException(
              e instanceof Error
                ? e
                : new Error(
                    "Error after signing privacy policy agreement: callPutUpdateAgreement"
                  )
            );
          }
        });

        client.on("error", async () => {
          statusNotification("Error signing document on HelloSign", "Error");
          captureException(
            new Error(
              "HelloSign Error - error occurred within HelloSign client/modal while signing privacy policy agreement"
            ),
            {
              extra: {
                clientId: auth.clientId,
              },
            }
          );
        });
      } catch (e) {
        statusNotification("Error opening HelloSign", "Error");
        captureException(
          e instanceof Error
            ? e
            : new Error(
                "Error hitting: getSignatureUrl, or opening HelloSign modal"
              )
        );
      }
    } else {
      captureException(
        new Error(
          "Farther agreement Error - auth, clientId, agreementId, or status is missing and/or wrong"
        ),
        {
          extra: {
            auth: auth,
            clientId: clientId,
            agreementId,
            status,
          },
        }
      );
    }
  };

  const determineIncompleteSubtitle = (
    checklistItem: ClientChecklistItem
  ): string => {
    switch (checklistItem.name) {
      case "Onboarding Agreement":
      case "Privacy Policy":
        return "Please look over and e-sign";

      case "Custodian Agreement":
        return "Waiting for your Advisor's Plan";

      default:
        return "Please fill out";
    }
  };

  const determineOnClickHandler = (
    checklistItem: ClientChecklistItem
  ): (() => void) => {
    switch (checklistItem.name) {
      case "Investor Profile":
        return () => handleSetDrawerPanelContext("Investor Profile");

      case "Contact Information":
        return () => handleSetDrawerPanelContext("Contact Information");

      case "Additional Information":
        return () => handleSetDrawerPanelContext("Additional Information");

      case "Beneficiaries":
        return () => handleSetDrawerPanelContext("Beneficiaries");

      case "Affiliations":
        return () => handleSetDrawerPanelContext("Affiliations");

      case "Onboarding Agreement":
        return () =>
          checklistItem.status === "Completed"
            ? setIsModalOpen(false)
            : setIsModalOpen(true);

      case "Privacy Policy":
        return () => handlePrivacyPolicySignature(checklistItem.status);

      case "Custodian Agreement":
        return enableNewDocumentsCenter
          ? handleNavigateToDocumentsCenter
          : sendToDocCenterTasksList;

      default:
        return () => null;
    }
  };

  const determineIsStepDisabled = (
    checklistItem: ClientChecklistItem
  ): boolean => {
    switch (checklistItem.name) {
      case "Custodian Agreement":
        return (
          checklistItem.status !== "Completed" &&
          !isEveryStepCompletedBeforeCustodianAgreement
        );

      default:
        return false;
    }
  };

  const replaceFartherWhitelabel = (str: string): string => {
    const company = t("companyName") ?? "Farther";

    return str
      .replace("Onboarding", company)
      .replace("Agreement", "Agreements");
  };

  const checklistEntires: ChecklistEntryDetails[] = checklistItems.map(
    (checklistItem) => ({
      title:
        checklistItem.name === "Onboarding Agreement"
          ? replaceFartherWhitelabel(checklistItem.name)
          : checklistItem.name,
      subtitleIncomplete: determineIncompleteSubtitle(checklistItem),
      subtitleComplete: "Complete",
      isStepComplete: checklistItem.status === "Completed",
      isStepDisabled: determineIsStepDisabled(checklistItem),
      onClick: determineOnClickHandler(checklistItem),
    })
  );

  return (
    <>
      <FartherAgreementsModal
        isModalOpen={isModalOpen}
        clientId={clientId}
        hideFartherAgreementsModal={() => setIsModalOpen(false)}
      />

      <Checklist checklistEntires={checklistEntires} />
    </>
  );
};

export default ChecklistController;
