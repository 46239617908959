import isString from "lodash/isString";

import { SortOrder } from "@fartherfinance/frontend/api/Types";

import { TableSortOrder } from "@src/sharedComponents/Table/Types";

const isSortOrder = (value: string): value is SortOrder => {
  return value === SortOrder.Ascending || value === SortOrder.Descending;
};

export const mapToTableSortOrder = (sortOrder: SortOrder): TableSortOrder => {
  return sortOrder === SortOrder.Ascending ? "asc" : "desc";
};

export const mapToSortOrder = (tableSortOrder: TableSortOrder): SortOrder => {
  return tableSortOrder === "asc" ? SortOrder.Ascending : SortOrder.Descending;
};

type SortParts = {
  sortOrder: SortOrder;
  sortBy: string;
};

export const extractSortParts = (sort: string | undefined): SortParts => {
  // NOTE: split method gives us empty string as first element, we need to filter it out
  const [sortOrder, sortBy] = sort?.split(/([+-])/).filter(Boolean) ?? [];

  if (isString(sortOrder) && !isSortOrder(sortOrder)) {
    throw new Error(`Invalid sort order: ${sortOrder}`);
  }

  return { sortOrder, sortBy };
};
