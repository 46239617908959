import React from "react";

import Button from "@src/multiCustodian/components/MUI/Button/Button";

import BackButton from "./BackButton";
import CancelButton from "./CancelButton";

import styles from "./FlowHero.module.css";

interface Props {
  onClickBack?: () => void;
  onClickCancel?: () => void;
  onClickContinue?: () => void;
  primaryButtonText: string;
  primaryButtonIsDisabled: boolean;
  secondaryTitle?: JSX.Element | string;
  showBackButton?: boolean;
  showCancelButton?: boolean;
  title: string;
}

const FlowHero: React.FC<Props> = ({
  onClickBack = () => undefined,
  onClickCancel = () => undefined,
  onClickContinue = () => undefined,
  primaryButtonText,
  primaryButtonIsDisabled,
  showBackButton = true,
  showCancelButton = true,
  secondaryTitle,
  title,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div />
        <div className={styles.cancelContainer}>
          {showCancelButton && (
            <CancelButton className={styles.cancel} onClick={onClickCancel} />
          )}
        </div>
        <div className={styles.lowerContainer}>
          <div>
            {secondaryTitle && (
              <div className={styles.secondaryTitle}>{secondaryTitle}</div>
            )}
            <h1 className={styles.title}>{title}</h1>
          </div>
          <div className={styles.controls}>
            {showBackButton && <BackButton onClick={onClickBack} />}
            <Button
              text={primaryButtonText}
              disabled={primaryButtonIsDisabled}
              onClick={onClickContinue}
              variant="contained"
              buttonType="primary"
              sx={{ minWidth: "240px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlowHero;
