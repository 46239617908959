import React from "react";

import { format } from "date-fns";
import { useParams } from "react-router-dom";

import { TaxBudget } from "@fartherfinance/frontend/api/InvestmentModel/Types";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import Row from "@src/multiCustodian/components/TaxBudgets/DetailTabs/Row";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";
import toUSD from "@src/utils/toUSD";

import AccountRow from "./AccountRow";

interface Props {
  budget?: TaxBudget;
}

const DetailsTable: React.FC<Props> = ({ budget }) => {
  const { clientId } = useParams<{ clientId: ClientId }>();

  const spouseClientId = budget?.clientIds.find((id) => id !== clientId);

  return (
    <div>
      <BorderBox>
        <Row start="Amount" end={`${toUSD(budget?.budgetAmount || 0)}`} />

        <AccountRow clientId={clientId} budget={budget} />

        {spouseClientId && (
          <AccountRow clientId={spouseClientId} budget={budget} />
        )}

        <Row
          start="Created"
          end={`${format(budget?.createTime || new Date(), "MM/dd/yyyy")}`}
        />
      </BorderBox>
    </div>
  );
};

export default DetailsTable;
