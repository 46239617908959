import React, { PropsWithChildren, useCallback } from "react";

import { useHistory } from "react-router-dom";

import ListDropdown from "../ListDropdown/ListDropdown";
import Drawer from "@src/multiCustodian/components/Drawer/Drawer";
import Admin from "@src/multiCustodian/pages/Advisor/Admin";

import EquivalentSecuritiesDrawerContent from "./EquivalentSecuritiesDrawerContent";

import styles from "./EquivalentSecuritiesDrawer.module.css";

const EquivalentSecuritiesDrawer: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const history = useHistory();

  const onClose = useCallback(
    () =>
      history.push({
        ...history.location,
        pathname: "/Advisor/Admin",
      }),
    [history]
  );

  return (
    <Admin>
      <Drawer
        className={styles.drawer}
        isDrawerOpen
        header={<ListDropdown />}
        onClose={onClose}
      >
        <EquivalentSecuritiesDrawerContent />

        {children}
      </Drawer>
    </Admin>
  );
};

export default EquivalentSecuritiesDrawer;
