import React from "react";

import { Link, useLocation } from "react-router-dom";

interface Props {
  pathname: string;
  style?: React.CSSProperties;
}

const LinkWithLocation = (
  props: React.PropsWithChildren<Props>
): JSX.Element => {
  const location = useLocation();

  return (
    <Link
      to={{
        ...location,
        pathname: props.pathname,
      }}
      style={props.style}
    >
      {props.children}
    </Link>
  );
};

export default LinkWithLocation;
