import useCustomPortfoliosV1 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useCustomPortfoliosV1";
import useEssentialAllPortfoliosV1 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useEssentialAllPortfoliosV1";
import usePortfolioPartners from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/usePortfolioPartners";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

export default function usePreLoadModelsAndPartners(
  clientId: ClientId | null
): void {
  const clientReqAuth = useRequestAuth();
  const advisorReqAuth = useAdvisorRequestAuth();
  const clientAuth = advisorReqAuth
    ? { ...advisorReqAuth, clientId: clientId }
    : null;
  const clientAuthConfig = clientReqAuth ? clientReqAuth : clientAuth;

  const auth = clientReqAuth ? clientReqAuth : advisorReqAuth;

  usePortfolioPartners(auth);
  useCustomPortfoliosV1(clientId, clientAuthConfig);
  useEssentialAllPortfoliosV1(clientAuthConfig);
}
