import { AccountWithPortfolio } from "../../utils/useAvailableAccountFromTradingGroups";

type Disabled =
  | { isDisabled: false }
  | { isDisabled: true; reason: null | string };

const isAccountDisabled = (
  //Simplified type requirements for ease of testing
  account: Pick<
    AccountWithPortfolio,
    "isDisabled" | "accountId" | "multiAccountTradingGroupAllowed"
  >,
  selectedAccounts: Pick<
    AccountWithPortfolio,
    "multiAccountTradingGroupAllowed" | "accountId"
  >[]
): Disabled => {
  if (account.isDisabled) {
    return { isDisabled: true, reason: null };
  }

  if (selectedAccounts.some((acc) => acc.accountId === account.accountId)) {
    //Account is already selected
    return { isDisabled: false };
  }

  if (
    //The selected account does not support multi-account grouping,
    //So no other accounts can be added.
    selectedAccounts.some(
      (acc) => acc.multiAccountTradingGroupAllowed === false
    )
  ) {
    return {
      isDisabled: true,
      reason:
        "The account selected can not be grouped with any other account due to grouping restrictions. You can create a trading group that only includes that account or unselect it to group multiple accounts",
    };
  }

  //There are one ore more accounts selected.
  //The current account does not support multi-account grouping
  if (
    selectedAccounts.length > 0 &&
    account.multiAccountTradingGroupAllowed === false
  ) {
    return {
      isDisabled: true,
      reason:
        "This account cannot be grouped with any other accounts due to grouping restrictions",
    };
  }

  return {
    isDisabled: false,
  };
};

export default isAccountDisabled;
