import React, { useState } from "react";

import { useSelector } from "react-redux";

import { PlanDetails } from "@fartherfinance/frontend/api/Accounts/requests/getPlanDetailsV2";
import useClientAccounts from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAccounts";
import { PlanId, RequestConfig } from "@fartherfinance/frontend/api/Types";

import PlanValidationErrorsTable from "../PlanValidationErrorsTable/PlanValidationErrorsTable";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import { State as ReduxState } from "@src/store";

import PlanDocumentsTable from "./PlanDocumentsTable";
import AddDocumentModal, {
  AccountIdsAndCustodianIds,
} from "./SequencedAccountOpeningDocUploadModal";

import styles from "../../Plans/plans.module.css";

interface ContainerProps {
  planId: PlanId;
  planDetails: PlanDetails;
}

export default function Container({
  planId,
  planDetails,
}: ContainerProps): JSX.Element {
  const [showAddDocumentModal, setShowAddDocumentModal] =
    useState<boolean>(false);

  const auth = useAdvisorRequestAuth();

  const { plan, resources, validationErrors } = planDetails;

  const clientId = plan.planHolder.clientId;

  const requestConfig: RequestConfig | null =
    clientId && auth?.jwt && auth?.advisorId
      ? {
          clientId: clientId,
          jwt: auth.jwt,
          advisorId: auth.advisorId,
        }
      : null;

  const planAccounts = resources.accounts.map((acct) => ({
    accountId: acct.virtualAccountId,
    custodianAccountNumber: acct.custodianAccountNumber,
  }));

  const { data: clientAccounts } = useClientAccounts(clientId, requestConfig);
  const pendingAndOpenAccounts = (clientAccounts?.fartherAccounts ?? [])
    .filter(
      (acct) =>
        acct.accountDetails.operationalState === "Pending" ||
        acct.accountDetails.operationalState === "Open"
    )
    .map((acct) => ({
      accountId: acct.accountId,
      custodianAccountNumber: acct.accountDetails.custodianAccountNumber,
    }));

  const fartherAccounts: AccountIdsAndCustodianIds[] = [
    ...planAccounts,
    ...pendingAndOpenAccounts,
  ];

  return (
    <>
      <Header
        add={() => setShowAddDocumentModal(true)}
        hideAddAccountButton={
          plan.planStatus === undefined ? true : plan.planStatus === "Complete"
        }
      />

      {showAddDocumentModal && plan && (
        <AddDocumentModal
          planId={planId}
          fartherAccounts={fartherAccounts ?? []}
          planDetails={planDetails}
          closeModal={() => setShowAddDocumentModal(false)}
        />
      )}

      <PlanDocumentsTable planId={planId} planDetails={planDetails} />

      {validationErrors && validationErrors.length > 0 && (
        <PlanValidationErrorsTable validationErrors={validationErrors} />
      )}
    </>
  );
}

interface HeaderProps {
  add: () => void;
  hideAddAccountButton: boolean;
}

const Header = (props: HeaderProps): JSX.Element => {
  const { add_circle } = useSelector((state: ReduxState) => ({
    add_circle: state.main_Reducer.brand.current.images.add_circle,
  }));

  return (
    <div className={styles.flex_between_div}>
      <p className={styles.plan_table_type_header}>Documents</p>

      <div
        className={styles.flex_div}
        onClick={props.hideAddAccountButton ? undefined : props.add}
      >
        <p className={styles.table_header_add_text}>Add</p>
        <img
          className={
            props.hideAddAccountButton
              ? styles.table_header_add_icon_disabled
              : styles.table_header_add_icon
          }
          src={add_circle}
        />
      </div>
    </div>
  );
};
