import { useCallback } from "react";

import useRevokeFartherSession from "@fartherfinance/frontend/api/Identity/hooks/useRevokeFartherSession";

import { LocalStorageKeys } from "../components/Login/constants";

import useAdvisorRequestAuth from "./useAdvisorRequestAuth";
import useRequestAuth from "./useRequestAuth";

export default function useLogout() {
  const clientAuth = useRequestAuth();

  const advisorAuth = useAdvisorRequestAuth();

  const auth = clientAuth ?? advisorAuth;

  const revokeFartherSession = useRevokeFartherSession();

  return useCallback(async () => {
    try {
      const fartherToken = localStorage.getItem(LocalStorageKeys.token);

      // Do not clear email
      localStorage.removeItem(LocalStorageKeys.personId);
      localStorage.removeItem(LocalStorageKeys.personType);
      localStorage.removeItem(LocalStorageKeys.token);
      localStorage.removeItem(LocalStorageKeys.redirectTo);

      if (fartherToken !== null && auth !== null) {
        await revokeFartherSession(fartherToken, auth);
      }
    } catch (e) {
      console.error(e);
    }
  }, [auth, revokeFartherSession]);
}
