import React from "react";

import Admin from "../Admin";
import CreateAdvisorDrawer from "@src/multiCustodian/components/Admin/CreateAdvisor/CreateAdvisorDrawer";

const CreateAdvisor = (): JSX.Element => {
  return (
    <Admin>
      <CreateAdvisorDrawer />
    </Admin>
  );
};

export default CreateAdvisor;
