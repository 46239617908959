import React from "react";

import { useHistory } from "react-router-dom";

import { AltAssetOfferingType } from "@fartherfinance/frontend/api/AltAssetsService/Types";

import { AltAssetsMarketplacePath } from "@src/config/routing/RouterPaths";
import TabPanel from "@src/multiCustodian/components/Advisor/Investments/SharedComponents/TabPanel/TabPanel";
import { altAssetOfferingTypes } from "@src/multiCustodian/components/Marketplace/constants";
import OfferingTypeList from "@src/multiCustodian/components/Marketplace/OfferingTypeList/OfferingTypeList";
import ViewAllButton from "@src/multiCustodian/components/Marketplace/ViewAllButton/ViewAllButton";

import styles from "./Investments.module.css";

const AltAssetsMarketplace: React.FC<unknown> = () => {
  const history = useHistory();

  const handleRedirectToOfferingsList = (
    offeringType: AltAssetOfferingType | "all"
  ): void => {
    history.push(`/Advisor/${AltAssetsMarketplacePath}/${offeringType}`);
  };

  return (
    <TabPanel
      className={styles.tabPanel}
      value={`/Advisor/${AltAssetsMarketplacePath}`}
    >
      <ViewAllButton onClick={() => handleRedirectToOfferingsList("all")} />
      <OfferingTypeList
        offeringTypes={altAssetOfferingTypes}
        onOfferingTypeClick={handleRedirectToOfferingsList}
      />
    </TabPanel>
  );
};

export default AltAssetsMarketplace;
