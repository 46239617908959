import {
  CusipIdentifier,
  OtherIdentifier,
  TickerIdentifier,
} from "@fartherfinance/frontend/api/SecurityMaster/requests/Types";

export const isTickerIdentifier = (
  idr: CusipIdentifier | OtherIdentifier | TickerIdentifier
): idr is TickerIdentifier => {
  return idr.registrar === "TICKER";
};
