import React, { PropsWithChildren, useEffect, useLayoutEffect } from "react";

import { useLocation, useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import { AccountsPath } from "@src/config/routing/RouterPaths";
import AccountsContent from "@src/multiCustodian/components/Client/Accounts/AccountList";
import { Accordions } from "@src/multiCustodian/components/Client/Accounts/Types";
import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import { trackEvent } from "@src/multiCustodian/services/tracking";
import LinkButton from "@src/sharedComponents/LinkButton/LinkButton";
import PageHero from "@src/sharedComponents/PageHero/PageHero";
import TitleBlock from "@src/sharedComponents/PageHero/TitleBlock";

const Accounts = (props: PropsWithChildren<unknown>): JSX.Element => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  useEffect(() => {
    trackEvent({ name: "Client Open Accounts" });
  }, []);

  const { state } = useLocation<
    { openAccordions?: Accordions[]; scrollY?: number } | undefined
  >();

  useLayoutEffect(() => {
    if (state?.scrollY) {
      // instant is legal but giving errors with TSC, see https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollTo#parameters
      window.scrollTo({ top: state.scrollY, behavior: "instant" as any });
    }
  }, [state?.scrollY]);

  return (
    <BaseLayout>
      <PageHero
        start={<TitleBlock title="Accounts" />}
        end={
          <LinkButton
            text="Add Account"
            to={`/Client/${clientId}/${AccountsPath}/Add`}
            variant="contained"
            buttonType="primary"
          />
        }
      />

      {/* modals will be passed into props.children */}
      {props.children}

      <AccountsContent
        clientId={clientId}
        openAccordions={state?.openAccordions}
      />
    </BaseLayout>
  );
};

export default Accounts;
