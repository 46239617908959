import { useCallback } from "react";

import { datadogRum } from "@datadog/browser-rum";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Dispatch } from "redux";

import { ClientId } from "@fartherfinance/frontend/api/Dashboard/hooks/useClientDashboard";

import { queryClient } from "../../../reactQuery";
import { MCReduxAction, State } from "../../../store";
import { trackEvent } from "@src/multiCustodian/services/tracking";

interface MiniName {
  first: string;
  last: string;
}

export default function useImpersonateClient() {
  const dispatch = useDispatch<Dispatch<MCReduxAction>>();
  const { jwt } = useSelector((state: State) => ({
    jwt: state.main_Reducer.farther_jwt,
  }));

  const history = useHistory();
  const { pathname } = useLocation();

  const impersonateClient = useCallback(
    async (
      client: ClientId | null,
      clientEmail?: string,
      clientName?: MiniName
    ): Promise<void> => {
      if (client === null) {
        dispatch({ type: "END_IMPERSONATION" });

        datadogRum.removeUserProperty("impersonatingClientId");
        datadogRum.removeUserProperty("impersonatingClientEmail");
        datadogRum.removeUserProperty("impersonatingClientName");

        try {
          // Clear react-query cache
          queryClient.cancelQueries();
          queryClient.clear();
        } catch (e) {
          console.error(e);
        }

        return history.replace({ pathname: "/Advisor/Clients" });
      }

      if (clientEmail === undefined || clientName === undefined) {
        throw new Error(
          "Can't impersonate a client without both an email and name"
        );
      }

      // main reducer
      dispatch({
        type: "CHANGE_CUR_USER",
        payload: { cur_user: client },
      });

      dispatch({
        type: "LOG_IN",
        payload: {
          farther_jwt: jwt,
          id_user: client,
          email: clientEmail,
          password: "",
          isAdvisor: true,
          conflict: false,
        },
      });

      trackEvent({
        name: "Advisor Impersonate Client",
        properties: {
          clientId: client,
          clientEmail: clientEmail,
          clientName: `${clientName.first} ${clientName.last}`,
        },
      });

      datadogRum.setUserProperty("impersonatingClientId", client);
      datadogRum.setUserProperty("impersonatingClientEmail", clientEmail);
      datadogRum.setUserProperty(
        "impersonatingClientName",
        `${clientName.first} ${clientName.last}`
      );

      // If we are already on the Client part of the app, don't redirect
      if (pathname.startsWith("/Client")) {
        return;
      }

      return history.push(`/Client/${client}`);
    },
    [dispatch, history, jwt, pathname]
  );

  return impersonateClient;
}
