import { isNil } from "lodash";

const IRA_CONTRIBUTION_LIMIT = 10000;
const IRA_CONTRIBUTION_LIMIT_ERROR = `Must not exceed ${IRA_CONTRIBUTION_LIMIT.toLocaleString(
  "en-US",
  {
    style: "currency",
    currency: "USD",
  }
)}`;

export default function isLessThanContributionLimit(
  input: string | null | undefined
): string | true {
  const isValid =
    !isNil(input) &&
    parseFloat(input.replace(/,/g, "")) <= IRA_CONTRIBUTION_LIMIT;

  return isValid ? true : IRA_CONTRIBUTION_LIMIT_ERROR;
}
