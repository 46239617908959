import React from "react";

import { TaxBudget } from "@fartherfinance/frontend/api/InvestmentModel/Types";

import UsedBudget from "@src/multiCustodian/components/TaxBudgets/UsedBudget/UsedBudget";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";
import BorderBoxBody from "@src/sharedComponents/BorderBox/BorderBoxBody";
import BorderBoxHeader from "@src/sharedComponents/BorderBox/BorderBoxHeader";

import TaxBudgetDetailTabs from "./DetailTabs";
import GainsAndLosses from "./GainsAndLosses";

import styles from "./UsageTab.module.css";

interface Props {
  budget?: TaxBudget;
  detailsPath: string;
  usagePath: string;
}

const UsageTab: React.FC<Props> = ({ budget, detailsPath, usagePath }) => {
  return (
    <div>
      <TaxBudgetDetailTabs
        curTab="Usage"
        detailsPath={detailsPath}
        usagePath={usagePath}
      />

      <BorderBox>
        <BorderBoxHeader
          start={
            <div className={styles.header}>
              <UsedBudget budget={budget} />
            </div>
          }
        />

        <BorderBoxBody showTopBorder={true}>
          <GainsAndLosses budget={budget} />
        </BorderBoxBody>
      </BorderBox>
    </div>
  );
};

export default UsageTab;
