import { keyBy } from "lodash";

import { ProjectionsGraphData, ProjectionsKey } from "../Types";

export default function createResultsObject(
  monteCarloData: ProjectionsGraphData[],
  key: ProjectionsKey
) {
  return keyBy(
    monteCarloData.filter((d) => d.key === key),
    (d) => {
      return d.yearIndex;
    }
  );
}
