import React from "react";

import { Account } from "../Types";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import Modal from "@src/sharedComponents/Modal/Modal";

import styles from "./modal.module.css";

interface Props {
  closeModal: () => void;
  onRemove: () => void;
  tradingGroupName: string;
  accountToRemove: Account;
  isMutating: boolean;
}

const RemoveAccountModal = (props: Props): JSX.Element => {
  return (
    <Modal closeModal={props.closeModal} modalStyle={{ width: "520px" }}>
      {!props.isMutating && (
        <>
          <p className={styles.modalHeader}>
            {`Are you sure you want to remove ${props.accountToRemove.displayName} from ${props.tradingGroupName}?`}
          </p>

          <div className={styles.modalButtonsDiv}>
            <Button
              variant={"outlined"}
              buttonType={"secondary"}
              text={"Cancel"}
              onClick={props.closeModal}
            />

            <Button
              variant={"contained"}
              buttonType={"primary"}
              text={"Remove Account"}
              onClick={props.onRemove}
              style={{ marginLeft: "20px" }}
            />
          </div>
        </>
      )}

      {props.isMutating && <LogoLoadingStill onTop={true} />}
    </Modal>
  );
};

export default RemoveAccountModal;
