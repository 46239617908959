import React, { useState } from "react";

import { useSelector } from "react-redux";

import { State } from "../../../../../store";
import { IraType, iraTypes, SetScreenAndTransferType } from "../Types";

import "./css.css";

interface Props {
  setCurScreen: SetScreenAndTransferType;
}

const IRATypeSelection = (props: Props): JSX.Element => {
  const { chevron_down, left_arrow } = useSelector((state: State) => ({
    chevron_down: state.main_Reducer.brand.current.images.chevron_down,
    left_arrow: state.main_Reducer.brand.current.images.left_arrow,
  }));

  const [hoveringOver, setHoveringOver] = useState<number>(-1);

  const onNext = (iraType: IraType) => {
    props.setCurScreen({
      screen: "IRA/institutionSelection",
      transferType: "roll over",
      iraType,
    });
  };

  return (
    <div className="Transfers__container">
      <div className="Transfers__content_container">
        <div
          className="Transfers__navlink"
          onClick={() => props.setCurScreen({ screen: "transferType" })}
        >
          <div className="Transfers__back_button_container">
            <img src={left_arrow} />
            <div className="Transfers__back_button">Back</div>
          </div>
        </div>

        <p className="Transfers__title">Select your IRA type</p>

        <p className="Transfers__text">
          What type of IRA do you want to roll over?
        </p>

        {iraTypes.map((type, idx) => (
          <div
            key={type}
            className="Transfers__navlink"
            onClick={() => onNext(type)}
          >
            <div
              className="Transfers__choices_div"
              onMouseEnter={() => setHoveringOver(idx)}
              onMouseLeave={() => setHoveringOver(-1)}
              style={{
                backgroundColor:
                  hoveringOver === idx
                    ? "var(--item_div_hover_color_1)"
                    : "var(--background_div_color_1)",
              }}
            >
              <p className="Transfers__text_bold">{type}</p>
              <img src={chevron_down} className="Transfers__arrow_img" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IRATypeSelection;
