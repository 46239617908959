import React from "react";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./BorderBox.module.css";

interface Props {
  className?: string;
  showScrollbar?: boolean;
  variant?: "default" | "bold";
}

const BorderBox: React.FC<Props> = ({
  className,
  children,
  showScrollbar,
  variant = "default",
}) => {
  return (
    <div
      className={toClassName(
        {
          [styles.bold]: variant === "bold",
          [styles.default]: variant === "default",
          [styles.container]: !showScrollbar,
          [styles.containerShowScrollbar]: showScrollbar,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export default BorderBox;
