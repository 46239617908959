import React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import groupBy from "lodash/groupBy";
import { Link } from "react-router-dom-v5-compat";

import { SubmittedProposals } from "@fartherfinance/frontend/api/Rebalance/requests/postProposalSubmission";

import { Dialog } from "@src/yellowstone/components";

import { useStyles } from "./FailedProposalsDialog.styles";

interface FailedProposalsDialogProps {
  isDialogOpen: boolean;
  failedProposals: SubmittedProposals["failedProposals"];
  onClose: () => void;
}

export const FailedProposalsDialog: React.FC<FailedProposalsDialogProps> = ({
  isDialogOpen,
  failedProposals,
  onClose,
}) => {
  const { classes, cx } = useStyles();

  const groupedByDetails = groupBy(
    failedProposals,
    (failedProposal) => failedProposal.details
  );

  return (
    <Dialog
      isDialogOpen={isDialogOpen}
      confirmLabel="Close"
      onClose={onClose}
      onConfirm={onClose}
    >
      <Typography className={classes.title}>Failed Proposals</Typography>

      {Object.entries(groupedByDetails).map(
        ([details, failedProposals], index) => (
          <Box key={index} className={classes.entryContainer}>
            <Stack direction="row" spacing={0.5}>
              <Typography className={cx(classes.reason, classes.reasonTitle)}>
                Reason:
              </Typography>
              <Typography className={classes.reason}>{details}</Typography>
            </Stack>

            {failedProposals.map(({ ids: id }) => (
              <Link
                key={id}
                className={classes.link}
                to={`/Advisor/Client-Ops/Dashboard/Daily-Trading/Proposal-Details/${id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {id}
              </Link>
            ))}
          </Box>
        )
      )}
    </Dialog>
  );
};
