import React from "react";

import { actions } from "../reducer/actions";
import { useAddCollaboratorContext } from "../reducer/Context";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";
import Checkbox from "@src/sharedComponents/Checkbox/Checkbox";

import AccessReviewRow from "./AccessReviewRow";
import EmailReviewRow from "./EmailReviewRow";
import NameReviewRow from "./NameReviewRow";
import TypeReviewRow from "./TypeReviewRow";

import styles from "./Review.module.css";

const ReviewCollaborator: React.FC = () => {
  const {
    state: {
      data: { notify },
    },
    dispatch,
  } = useAddCollaboratorContext();

  const handleOnCheck = (bool: boolean) =>
    dispatch({ type: actions.SET_NOTIFY, payload: bool });

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <BorderBox className={styles.borderBox}>
          <div className={styles.title}>Sharing Summary</div>

          <BorderBox className={styles.body}>
            <NameReviewRow />

            <EmailReviewRow />

            <TypeReviewRow />

            <AccessReviewRow />
          </BorderBox>

          <Checkbox
            checked={notify}
            onChange={handleOnCheck}
            label={"Notify collaborator via email"}
            checkboxStyle={{ marginTop: "2px", marginBottom: "2px" }}
          />
        </BorderBox>
      </div>
    </div>
  );
};

export default ReviewCollaborator;
