import React, { useMemo, useState } from "react";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Box, IconButton } from "@mui/material";
import { orderBy, range } from "lodash";
import { useSelector } from "react-redux";

import useSearchSecurities from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useSearchSecurities";
import useSleeves from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useSleeves";
import type searchSecurities from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/searchSecurities";
import { SleeveId, Ticker } from "@fartherfinance/frontend/api/Types";

import DisplayName from "../../SharedComponents/SearchSecurities/DisplayName";
import {
  SelectedSecurity,
  SelectedSleeve,
  USDTicker,
} from "../../SharedComponents/SearchSecurities/Types";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import { captureException } from "@src/multiCustodian/services/tracking";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import FormNumberField from "@src/sharedComponents/Forms/FormNumberField";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import SearchSecurity from "@src/sharedComponents/SearchSecurity/SearchSecurity";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import { State } from "@src/store";
import { pluralize } from "@src/utils/pluralize";

import styles from "../../SharedComponents/SearchSecurities/SecuritySearch.module.css";

type RemoteSearch = Awaited<ReturnType<typeof searchSecurities>>[number];

interface Search extends RemoteSearch {
  label: string;
}

interface Props {
  selectedSecurities: SelectedSecurity[];
  selectedSleeves: SelectedSleeve[];
  addSecurity: (sec: SelectedSecurity) => void;
  removeSecurity: (ticker: Ticker) => void;
  removeSleeve: (sleeveId: SleeveId) => void;
  total: number;
}

const SecuritySearchWithSleeves = (props: Props): JSX.Element => {
  const [input, setInput] = useState<string | null>(null);

  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));

  const auth = useAdvisorRequestAuth();
  const sleeves = useSleeves(advisorId, auth);

  const validSearchInput = input === null || input.length <= 1 ? null : input;
  const searchResults = useSearchSecurities(validSearchInput, auth);

  const addSecurity = (sec: SelectedSecurity) => {
    props.addSecurity(sec);
    setInput(null);
  };

  const selectedSecuritiesOrderedAlphaAsc = useMemo(
    () =>
      orderBy(
        props.selectedSecurities,
        [(s) => s.ticker.toLowerCase()],
        ["asc"]
      ),
    [props.selectedSecurities]
  );

  interface SortedSelectedSleeve extends SelectedSleeve {
    sleeveId: SleeveId;
    displayName: string;
  }

  const selectedSleevesOrderedAlphaAsc: SortedSelectedSleeve[] = useMemo(() => {
    if (sleeves.data === undefined) {
      return [];
    }

    const selectedSleevesWithDisplayName = props.selectedSleeves.map((s) => {
      const matchedSleeve = sleeves.data.sleeves.find(
        (m) => m.sleeveId === s.sleeveId
      );
      if (matchedSleeve === undefined) {
        const err = new Error(
          "Failed to look up selected sleeve from all sleeves using sleeveId: matchedSleeve === undefined"
        );

        captureException(err, {
          extra: {
            sleeveId: s.sleeveId,
            call: "selectedSleevesOrderedAlphaAsc useMemo -> matchedSleeve",
            file: "SecuritySearchWithSleeves.tsx",
          },
        });
      }

      return {
        sleeveId: s.sleeveId,
        displayName: matchedSleeve?.displayName ?? "N/A",
      };
    });

    return orderBy(
      selectedSleevesWithDisplayName,
      [(s) => s.displayName.toLowerCase()],
      ["asc"]
    );
  }, [props.selectedSleeves, sleeves.data]);

  const selectedSecuritiesText = pluralize(
    props.selectedSecurities,
    "Security",
    true
  );

  const selectedSleevesText = pluralize(props.selectedSleeves, "Sleeve", true);

  const securitiesAndSleevesText = `${selectedSecuritiesText}, ${selectedSleevesText}`;

  const options = useMemo(() => {
    return (searchResults.data ?? []).map((r) => ({
      ...r,
      label: `${r.ticker} - ${r.displayName}`,
    }));
  }, [searchResults]);

  if (sleeves.hasError) {
    return <div>Error retrieving model and/or sleeves</div>;
  }

  return (
    <div className={styles.container}>
      {sleeves.isLoading && <LogoLoadingStill onTop={true} />}

      <Box padding="12px">
        <SearchSecurity<Search>
          searchInput={input}
          onChange={addSecurity}
          handleSearch={(term) => setInput(term.trim())}
          options={options}
          isLoading={searchResults.isLoading}
        />
      </Box>

      <div className={toClassName(styles.row, styles.header, styles.bold)}>
        <div className={styles.subtleText}>Ticker</div>
        <div className={styles.subtleText}>Weight</div>
      </div>

      <div className={styles.rowContainer}>
        <div className={styles.row}>
          <div className={styles.tickerContainer}>
            <div className={toClassName(styles.text, styles.ticker)}>
              Cash
              <span className={styles.subtleText}>US Dollars</span>
            </div>
          </div>

          <Box width={80}>
            <FormNumberField
              name={"Cash"}
              endAdornment={"%"}
              hideErrorMsg={true}
              customRegex={{
                regex: /^[\d\.]+$/g,
                message: `${USDTicker} must be only numerical digits`,
              }}
              rules={{
                required: false,
                min: {
                  value: 0.0,
                  message: `${USDTicker} can't be negative`,
                },
                max: {
                  value: 100,
                  message: `${USDTicker} is over 100%`,
                },
                validate: {
                  moreThan2Decimals: (value): string | true => {
                    if (typeof value !== "string") {
                      return `Invalid value for ${USDTicker}`;
                    }

                    const decimalPortion = value.split(".")[1] as
                      | string
                      | undefined;
                    if (decimalPortion === undefined) {
                      return true;
                    }

                    // This will catch even 1.110
                    return decimalPortion.length <= 2
                      ? true
                      : `Only 2 decimals allowed for ${USDTicker}`;
                  },
                },
              }}
            />
          </Box>
        </div>

        {selectedSecuritiesOrderedAlphaAsc.map((s) => {
          const asteriscCharInTicker = s.ticker.includes("*");
          // if there is a "." in the ticker, setValue from useForm will evaluate that . as a property and break apart the key/string into an obj so replaced "." with "*", now want to switch back to "." for displaying and BE look-up
          const originalTicker = asteriscCharInTicker
            ? (s.ticker.replace(/\*/g, ".") as Ticker)
            : s.ticker;

          const nameToShow = s.displayName ?? originalTicker ?? "Unknown";

          return (
            <div key={s.ticker} className={styles.row}>
              <div className={styles.tickerContainer}>
                <IconButton
                  className={styles.deleteIconContainer}
                  onClick={() => props.removeSecurity(s.ticker)}
                >
                  <RemoveCircleOutlineIcon className={styles.deleteIcon} />
                </IconButton>

                <div className={toClassName(styles.text, styles.ticker)}>
                  {originalTicker}
                  <span className={styles.subtleText}>
                    {s.displayName ?? <DisplayName ticker={originalTicker} />}
                  </span>
                </div>
              </div>

              <Box width={80}>
                <FormNumberField
                  name={`Security-${s.ticker}`}
                  endAdornment={"%"}
                  hideErrorMsg={true}
                  customRegex={{
                    regex: /^[\d\.]+$/g,
                    message: `${nameToShow} must be only numerical digits`,
                  }}
                  rules={{
                    required: `${nameToShow} must have an allocation`,
                    min: {
                      value: 0.01,
                      message: `${nameToShow} must be larger than 0.01%`,
                    },
                    max: {
                      value: 100,
                      message: `${nameToShow} is over 100%`,
                    },
                    validate: {
                      moreThan2Decimals: (value): string | true => {
                        if (typeof value !== "string") {
                          return `Invalid value for ${nameToShow}`;
                        }

                        const decimalPortion = value.split(".")[1] as
                          | string
                          | undefined;
                        if (decimalPortion === undefined) {
                          return true;
                        }

                        // This will catch even 1.110
                        return decimalPortion.length <= 2
                          ? true
                          : `Only 2 decimals allowed for ${nameToShow}`;
                      },
                    },
                  }}
                />
              </Box>
            </div>
          );
        })}

        {sleeves.isLoading ? (
          LoadingTable
        ) : (
          <>
            {selectedSleevesOrderedAlphaAsc.map((s) => {
              return (
                <div key={s.sleeveId} className={styles.row}>
                  <div className={styles.tickerContainer}>
                    <IconButton
                      className={styles.deleteIconContainer}
                      onClick={() => props.removeSleeve(s.sleeveId)}
                    >
                      <RemoveCircleOutlineIcon className={styles.deleteIcon} />
                    </IconButton>

                    <div className={toClassName(styles.text, styles.sleeve)}>
                      --
                      <span className={styles.subtleText}>{s.displayName}</span>
                    </div>
                  </div>

                  <Box width={80}>
                    <FormNumberField
                      name={`Sleeve-${s.sleeveId}`}
                      endAdornment={"%"}
                      hideErrorMsg={true}
                      customRegex={{
                        regex: /^[\d\.]+$/g,
                        message: `${s.displayName} must be only numerical digits`,
                      }}
                      rules={{
                        required: `${s.displayName} must have an allocation`,
                        min: {
                          value: 0.01,
                          message: `${s.displayName} must be larger than 0.01%`,
                        },
                        max: {
                          value: 100,
                          message: `${s.displayName} is over 100%`,
                        },
                        validate: {
                          moreThan2Decimals: (value): string | true => {
                            if (typeof value !== "string") {
                              return `Invalid value for ${s.displayName}`;
                            }

                            const decimalPortion = value.split(".")[1] as
                              | string
                              | undefined;
                            if (decimalPortion === undefined) {
                              return true;
                            }

                            // This will catch even 1.110
                            return decimalPortion.length <= 2
                              ? true
                              : `Only 2 decimals allowed for ${s.displayName}`;
                          },
                        },
                      }}
                    />
                  </Box>
                </div>
              );
            })}
          </>
        )}
      </div>

      <div className={styles.footer}>
        <div className={styles.subtleText}>{securitiesAndSleevesText}</div>

        <div className={toClassName(styles.text, styles.total)}>
          <span className={styles.bold}>Total</span>
          {(props.total / 100).toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      </div>
    </div>
  );
};

export default SecuritySearchWithSleeves;

const LoadingTable = () => {
  {
    range(2).map((_, i) => {
      return (
        <div key={i} className={styles.row}>
          <div className={styles.tickerContainer}>
            <IconButton
              className={styles.deleteIconContainer}
              onClick={() => undefined}
            >
              <RemoveCircleOutlineIcon className={styles.deleteIcon} />
            </IconButton>

            <div className={toClassName(styles.text, styles.sleeve)}>
              --
              <span className={styles.subtleText}>
                <Skeleton width={100} />
              </span>
            </div>
          </div>

          <Skeleton width={80} />
        </div>
      );
    });
  }
};
