import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

export const useStyles = makeStyles()((theme) => ({
  autocomplete: {
    width: 240,
    marginRight: theme.spacing(pxToSpacing(20)),
  },
  searchIcon: {
    width: 18,
    height: 18,
    margin: theme.spacing(pxToSpacing(4)),
    color: theme.appearance.$iconSubtle,
  },
  loadingIcon: {
    position: "absolute",
  },

  // INFO: Copied styles from nav menu
  menuPaper: {
    width: "100%",
    maxWidth: 470,
    borderRadius: theme.shape.borderRadius,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.appearance.$borderBold,
    backgroundColor: theme.appearance.$backgroundSubtlest,

    "& .MuiAutocomplete-noOptions": {
      color: theme.appearance.$text,
      fontFamily: theme.typography.fontSecondary,
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: "19px",
    },
  },
  menuList: {
    padding: theme.spacing(pxToSpacing(10), 0, pxToSpacing(16)),
  },
  menuItem: {
    height: 40,
    padding: theme.spacing(0, pxToSpacing(20)),

    transition: theme.transitions.create(["background"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.standard,
    }),

    "&:hover": {
      backgroundColor: theme.appearance.$backgroundSubtle,
    },
  },
  menuItemText: {
    color: theme.appearance.$text,
    fontFamily: theme.typography.fontSecondary,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: "19px",
    whiteSpace: "break-spaces",
  },
}));
