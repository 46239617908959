import React from "react";

import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import { DataKey } from "../Types";

import CustomHoverLines from "./CustomHoverLines";

interface Props {
  cx: number;
  cy: number;
  dataKey: DataKey;
}

const CustomDot = (props: Props): JSX.Element => {
  const {
    color: {
      $chartLineTertiary,
      $chartLineSecondary,
      $backgroundInverseSubtle,
      $chartLinePrimary,
    },
  } = useTheme();

  const strokeColor =
    props.dataKey === "results50Pct"
      ? $chartLinePrimary
      : props.dataKey === "results10Pct"
      ? $chartLineSecondary
      : $chartLineTertiary;

  return (
    <>
      {props.dataKey === "results90Pct" && ( //Whichever dataKey is here provides its coordinates to the hover lines
        <CustomHoverLines
          x={props.cx}
          y={props.cy}
          showHorizontal={true}
          showVertical={true}
        />
      )}
      {props.dataKey === "results10Pct" && ( //Whichever dataKey is here provides its coordinates to the hover lines
        <CustomHoverLines
          x={props.cx}
          y={props.cy}
          showHorizontal={false} //we only need the vertical line here so it covers the bottom-most area
          showVertical={true}
        />
      )}

      <circle
        cx={props.cx}
        cy={props.cy}
        r={8}
        fill={$backgroundInverseSubtle}
        fillOpacity={1}
        stroke={strokeColor}
        strokeWidth={1.4}
      />
      <circle cx={props.cx} cy={props.cy} r={2.5} fill={strokeColor} />
    </>
  );
};

export default CustomDot;
