import React from "react";

const FinancialPlans: React.FC = () => (
  <svg
    width="100%"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon">
      <g id="Union">
        <path
          d="M5.61746 2.08545C5.5665 2.0545 5.50129 2.09118 5.50129 2.15081V10.4452C5.50129 10.473 5.5164 10.4986 5.54073 10.5121L8.1587 11.9609C8.20967 11.9891 8.2722 11.9523 8.2722 11.894V3.74087C8.2722 3.71416 8.25826 3.68938 8.23543 3.67551L5.61746 2.08545Z"
          fill="currentColor"
        />
        <path
          d="M1.64062 4.08362C1.64062 4.03016 1.66854 3.98058 1.71425 3.95286L4.75931 2.106V10.6187L2.43189 11.886C2.07519 12.0802 1.64062 11.822 1.64062 11.4159V4.08362Z"
          fill="currentColor"
        />
        <path
          d="M9.00097 3.62644L11.4802 2.2133C11.8371 2.0099 12.2806 2.2676 12.2806 2.67835V9.98271C12.2806 10.1201 12.2069 10.2469 12.0876 10.3149L9.00097 12.0742V3.62644Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default FinancialPlans;
