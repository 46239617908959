import React, { useMemo, useState } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import usePlanDetailsV2 from "@fartherfinance/frontend/api/Accounts/hooks/usePlanDetailsV2";
import { Plan } from "@fartherfinance/frontend/api/Accounts/Types";
import useClientCustodian from "@fartherfinance/frontend/api/Dashboard/hooks/useClientCustodian";
import useExternalAccountExtended from "@fartherfinance/frontend/api/ExternalAccount/hooks/useExternalAccountExtended";
import { ManualAccount } from "@fartherfinance/frontend/api/ExternalAccount/requests/getExternalAccounts";
import { HookResult, PlanId } from "@fartherfinance/frontend/api/Types";

import { State as ReduxState } from "../../../../../store";
import StandingInstructionsDrawer from "../../Plans/Forms/StandingInstructionsForm";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import { isPlaidAccount } from "@src/multiCustodian/pages/Dashboard/Funding/utils";

import TransferAuthsTable from "./TransferAuthorizationTable";

import styles from "../../Plans/plans.module.css";

export default function Container(): JSX.Element {
  const [showStandingInstruction, setShowStandingInstruction] =
    useState<boolean>(false);
  const [chosenFundingAccount, setChosenFundingAccount] =
    useState<ManualAccount | null>(null);

  const { add_circle } = useSelector((state: ReduxState) => ({
    add_circle: state.main_Reducer.brand.current.images.add_circle,
  }));

  const auth = useAdvisorRequestAuth();

  const { planId } = useParams<{ planId: PlanId }>();

  const planDetails = usePlanDetailsV2(planId, auth);

  const plan: Plan | undefined = planDetails.data?.plan;

  const clientAuth =
    auth !== null && plan !== undefined
      ? { ...auth, clientId: plan.planHolder.clientId }
      : null;

  const clientCustodian = useClientCustodian(
    plan?.planHolder.clientId ?? null,
    clientAuth
  );

  const selectedExternalAccount = useExternalAccountExtended(
    chosenFundingAccount?.accountId ?? null,
    clientAuth
  );

  const selectedExtAccount: HookResult<ManualAccount> = useMemo(() => {
    if (selectedExternalAccount.isLoading || selectedExternalAccount.hasError) {
      return selectedExternalAccount;
    }

    if (isPlaidAccount(selectedExternalAccount.data)) {
      return {
        data: undefined,
        isLoading: false,
        hasError: true,
        error:
          "Attempting to select a Plaid account for standing instructions.",
      };
    }

    return {
      ...selectedExternalAccount,
      data: selectedExternalAccount.data,
    };
  }, [selectedExternalAccount]);

  const disableStandingInstructions = //Releasing a plan with more than one set of standing instructions causes the client's funding account to enter an unrecoverable error state
    (planDetails.data !== undefined &&
      planDetails.data.resources.fundingAccounts.length > 0) ||
    clientCustodian.data?.type !== "SingleFullCustodian" ||
    clientCustodian.data.custodian === "Apex";

  const hideAddAccountButton =
    (planDetails.data?.plan.planStatus === undefined
      ? true
      : planDetails.data.plan.planStatus === "Complete") ||
    disableStandingInstructions;

  return (
    <>
      <div className={styles.flex_between_div}>
        <p className={styles.plan_table_type_header}>Transfer Authorization</p>

        <div
          className={styles.flex_div}
          onClick={
            hideAddAccountButton
              ? undefined
              : () => {
                  setChosenFundingAccount(null);
                  setShowStandingInstruction(true);
                }
          }
        >
          <p className={styles.table_header_add_text}>Add</p>
          <img
            className={
              hideAddAccountButton
                ? styles.table_header_add_icon_disabled
                : styles.table_header_add_icon
            }
            src={add_circle}
          />
        </div>
      </div>

      <TransferAuthsTable
        planDetails={planDetails.data ?? null}
        setChosenFundingAccount={(faccount: ManualAccount) => {
          setChosenFundingAccount(faccount);
          setShowStandingInstruction(true);
        }}
      />

      {showStandingInstruction &&
        clientCustodian.data?.type === "SingleFullCustodian" &&
        clientCustodian.data.custodian !== "Apex" &&
        (chosenFundingAccount === null ||
          //If an account was chosen, only open drawer when selectedExtAccount has loaded,
          //otherwise react-hook-form wont map correctly
          selectedExtAccount.data !== undefined) && (
          <StandingInstructionsDrawer
            plan={plan}
            planId={planId}
            chosenFundingAccount={selectedExtAccount.data ?? null}
            isOpen={showStandingInstruction}
            onClose={() => setShowStandingInstruction(false)}
            clientCustodian={clientCustodian.data.custodian}
          />
        )}
    </>
  );
}
