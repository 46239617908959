import React from "react";

import useSleeve from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useSleeve";
import useSleeveAnalysis from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useSleeveAnalysis";
import { SleeveId } from "@fartherfinance/frontend/api/Types";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

import SleeveTableView from "./SleeveTableViews";
import TableLoading from "./Table/TableLoading";

import styles from "./SleeveHoldingsTable.module.css";
interface Props {
  sleeveId: SleeveId;
  isInClientPortal?: boolean;
}

const SleeveHoldingsTable = ({
  sleeveId,
  isInClientPortal,
}: Props): JSX.Element => {
  const clientAuth = useRequestAuth();
  const advisorAuth = useAdvisorRequestAuth();
  const sleeve = useSleeve(
    sleeveId,
    isInClientPortal ? clientAuth : advisorAuth
  );
  const sleeveAnalysis = useSleeveAnalysis(
    sleeveId,
    isInClientPortal ? clientAuth : advisorAuth
  );

  if (sleeve.isLoading || sleeveAnalysis.isLoading) {
    return (
      <div className={styles.tableContainer}>
        <TableLoading />
      </div>
    );
  }

  if (sleeve.hasError || sleeveAnalysis.hasError) {
    return (
      <div className={styles.tableContainer}>
        <div>Error</div>
      </div>
    );
  }

  return (
    <SleeveTableView
      sleeve={sleeve.data}
      sleeveAnalysis={sleeveAnalysis.data}
    />
  );
};

export default SleeveHoldingsTable;
