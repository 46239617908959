import { useMemo } from "react";

import { useSelector } from "react-redux";

import useAdvisorPartner from "@fartherfinance/frontend/api/Dashboard/hooks/useAdvisorPartner";
import useClientPartner from "@fartherfinance/frontend/api/Dashboard/hooks/useClientPartner";
import { Partner } from "@fartherfinance/frontend/api/Dashboard/requests/getClientDashboard";
import { HookResult } from "@fartherfinance/frontend/api/Types";

import useAdvisorRequestAuth from "../hooks/useAdvisorRequestAuth";
import useRequestAuth from "../hooks/useRequestAuth";
import { State } from "@src/store";

import cogentAssets from "./Cogent/cogentAssets";
import fartherAssets from "./Farther/fartherAssets";
import { Appearances, AssetList, Assets } from "./Types";
import wealthPlanAssets from "./WealthPlan/wealthPlanAssets";

type AssetsByPartner = Record<Partner, AssetList>;

const assets: AssetsByPartner = {
  Farther: fartherAssets,
  Cogent: cogentAssets,
  WealthPlan: wealthPlanAssets,
};

function useThemeAssets(defaultPartner: undefined): HookResult<Assets>;
function useThemeAssets(): HookResult<Assets>;
function useThemeAssets(defaultPartner: Partner): {
  data: Assets;
  isLoading: false;
  hasError: false;
};

function useThemeAssets(defaultPartner?: Partner): HookResult<Assets> {
  const { clientId, fartherAppearance } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
    fartherAppearance: state.main_Reducer.ui_theme,
  }));

  const auth = useRequestAuth();
  const advisorAuth = useAdvisorRequestAuth();
  const advisorId = advisorAuth?.advisorId ?? null;

  const partner = useClientPartner(clientId, auth);
  const advisorPartner = useAdvisorPartner(
    clientId === null ? advisorId : null,
    advisorAuth
  );

  const result: HookResult<Assets> = useMemo(() => {
    const selector: Appearances =
      fartherAppearance === "farther_dark" ? "dark" : "light";

    if (clientId === null) {
      if (advisorPartner.isLoading || advisorPartner.hasError) {
        if (defaultPartner !== undefined) {
          return {
            data: assets[defaultPartner][selector],
            isLoading: false,
            hasError: false,
          };
        }
        return advisorPartner;
      }

      const assetList = assets[advisorPartner.data][selector];

      return {
        data: assetList,
        isLoading: false,
        hasError: false,
      };
    }

    if (partner.isLoading || partner.hasError) {
      if (defaultPartner !== undefined) {
        return {
          data: assets[defaultPartner][selector],
          isLoading: false,
          hasError: false,
        };
      }
      return partner;
    }

    const assetList = assets[partner.data][selector];

    return {
      data: assetList,
      isLoading: false,
      hasError: false,
    };
  }, [defaultPartner, fartherAppearance, partner, advisorPartner, clientId]);

  return result;
}

export default useThemeAssets;
