import React from "react";

import { useSelector } from "react-redux";

import useClientCustodian from "@fartherfinance/frontend/api/Dashboard/hooks/useClientCustodian";

import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import { State } from "@src/store";

export function PreloadClientCustodian(): JSX.Element {
  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));

  const auth = useRequestAuth();

  const custodian = useClientCustodian(clientId, auth);

  return (
    <div
      id="PreloadClientCustodian"
      data-prefetch-status={custodian.isLoading ? "loading" : "success"}
      data-custodian={
        custodian.data === undefined
          ? "loading"
          : "custodian" in custodian.data
          ? custodian.data.custodian
          : "custodians" in custodian.data
          ? JSON.stringify(custodian.data.custodians)
          : JSON.stringify(custodian.data.limitedCustodians)
      }
      style={{ display: "none" }}
    />
  );
}
