import React from "react";

import { matchPath, useLocation } from "react-router-dom-v5-compat";

import { DashboardTabPanel } from "@src/yellowstone/components";

import { DailyTradingNavigation } from "./components/DailyTradingNavigation/DailyTradingNavigation";

export const DashboardDailyTrading: React.FC = ({ children }) => {
  const location = useLocation();

  const isNavigationDropdownHidden = [
    "/Advisor/Client-Ops/Dashboard/Daily-Trading/Proposal-Details/:proposalId",
  ].some((path) => matchPath(path, location.pathname));

  return (
    <DashboardTabPanel value="/Advisor/Client-Ops/Dashboard/Daily-Trading">
      {!isNavigationDropdownHidden && <DailyTradingNavigation />}
      {children}
    </DashboardTabPanel>
  );
};
