import React, { useEffect, useMemo, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { orderBy } from "lodash";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import useFartherEmployees from "@fartherfinance/frontend/api/Entity/hooks/useFartherEmployees";
import useShareSleeve from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useShareSleeve";
import useSleeve from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useSleeve";
import { ShareSleeveBody } from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/putShareSleeve";
import { AdvisorId, SleeveId } from "@fartherfinance/frontend/api/Types";

import useIsAdmin from "@src/multiCustodian/components/Advisor/utils/useIsAdmin";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import Checkbox from "@src/sharedComponents/Checkbox/Checkbox";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import { State } from "@src/store";

import styles from "./SleeveSharingTable.module.css";

interface Props {
  sleeveId: SleeveId;
}

const SleeveSharingTable = ({ sleeveId }: Props): JSX.Element => {
  const [isMutating, setIsMutating] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");

  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));
  const history = useHistory();

  const statusNotification = useStatusNotification();

  const auth = useAdvisorRequestAuth();
  const sleeve = useSleeve(sleeveId, auth);
  const employees = useFartherEmployees("All", auth);
  const shareSleeve = useShareSleeve(auth);
  const isAdmin = useIsAdmin(advisorId);

  useEffect(() => {
    // advisor should not see sharing tab if its not a shared custom model

    const isSharedSleeve =
      isAdmin.data === false &&
      sleeve.data !== undefined &&
      sleeve.data.ownerId !== advisorId;

    if (isAdmin.data === false && !isSharedSleeve) {
      history.push({
        ...history.location,
        pathname: `/Advisor/Investments/Sleeves/${sleeveId}/SleeveDetails`,
      });
    }
  }, [sleeve, isAdmin.data, history, sleeveId, advisorId]);

  interface CheckBoxOption {
    id: AdvisorId;
    label: string;
    isShared: boolean;
  }

  const checkBoxOptions = useMemo((): CheckBoxOption[] => {
    if (
      sleeve.isLoading ||
      sleeve.hasError ||
      employees.isLoading ||
      employees.hasError
    ) {
      return [];
    }

    const advisors = employees.data.advisors
      .filter((adv) => adv.roles.some((role) => role.endsWith("Advisor")))
      .map((adv) => ({
        id: adv.advisorId,
        label: `${adv.name.first} ${adv.name.last}`,
        type: "advisor" as "advisor" | "institution",
        isShared: sleeve.data.sharedWith.some(
          (personId) => personId === adv.advisorId
        ),
      }));

    return orderBy(advisors, (el) => el.label.toLowerCase(), "asc");
  }, [employees, sleeve]);

  const numberOfAdvisorsWithAccessToCustomModel = useMemo(() => {
    if (
      sleeve.isLoading ||
      sleeve.hasError ||
      employees.isLoading ||
      employees.hasError
    ) {
      return null;
    }

    if (sleeve.data.ownerId === null) {
      return sleeve.data.sharedWith.length;
    }

    const advisors = employees.data.advisors.filter((adv) =>
      adv.roles.some((role) => role.endsWith("Advisor"))
    );
    const isCustomModelOwnerAnAdvisor = advisors.some(
      (a) => a.advisorId === sleeve.data.ownerId
    );

    return (
      sleeve.data.sharedWith.length + (isCustomModelOwnerAnAdvisor ? 1 : 0)
    );
  }, [sleeve, employees]);

  if (sleeve.isLoading) {
    return <></>;
  }

  if (sleeve.hasError) {
    return <div>Error retrieving sleeve</div>;
  }

  if (isAdmin.hasError) {
    return <div>Error retrieving employee status</div>;
  }

  const shareSleeveWithAdvisors = async (checkboxElement: CheckBoxOption) => {
    if (isMutating) {
      return;
    }

    setIsMutating(true);

    const advisorsToShareWith = checkBoxOptions
      .filter(
        (cb) =>
          (cb.isShared && cb.id !== checkboxElement.id) || // all that are already selected and not just flipped
          (cb.id === checkboxElement.id && !cb.isShared) // look at the specific checkbox that was just flipped
      )
      .map((cb) => cb.id);

    const body: ShareSleeveBody = {
      personIds: advisorsToShareWith,
    };

    try {
      await shareSleeve({
        sleeveId: sleeveId,
        sleeveBody: body,
      });

      setTimeout(() => {
        statusNotification(
          `Sleeve ${checkboxElement.isShared ? "unshared" : "shared"}`,
          "Success"
        );
        setIsMutating(false);
      }, 4000); // invalidation takes a couple seconds after the initial request
    } catch (_e) {
      statusNotification("Error sharing sleeve", "Error");
      setIsMutating(false);
    }
  };

  const advisorsSharedWithText =
    numberOfAdvisorsWithAccessToCustomModel === null
      ? "- -"
      : `${numberOfAdvisorsWithAccessToCustomModel} advisor${
          numberOfAdvisorsWithAccessToCustomModel === 1 ? "" : "s"
        } with access`;

  return (
    <>
      {isAdmin.isLoading ? (
        <></>
      ) : isAdmin.data ? (
        <div className={styles.container}>
          <div className={styles.tableHeader}>
            <div className={styles.headerLeft}>Advisor Access</div>

            <div className={styles.headerRight}>{advisorsSharedWithText}</div>
          </div>

          {checkBoxOptions.length > 8 && (
            <div className={styles.searchInputContainer}>
              <SearchIcon className={styles.searchIcon} />

              <input
                className={styles.searchInput}
                value={searchInput}
                placeholder={"Search..."}
                type={"text"}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </div>
          )}

          <div className={styles.checkboxesContainer}>
            {checkBoxOptions
              .filter((el) => {
                if (searchInput === "") {
                  return true;
                }

                return el.label
                  .toLowerCase()
                  .includes(searchInput.toLowerCase());
              })
              .map((el) => {
                return (
                  <div key={el.id} className={styles.checkboxDiv}>
                    <Checkbox
                      checked={el.isShared || sleeve.data.ownerId === el.id}
                      onChange={() => shareSleeveWithAdvisors(el)}
                      label={el.label}
                      disabled={sleeve.data.ownerId === el.id}
                      checkboxStyle={{ marginTop: "2px", marginBottom: "2px" }}
                    />

                    {sleeve.data.ownerId === el.id && (
                      <div className={styles.ownerText}>{"(Owner)"}</div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <div className={styles.advisorText}>
          This sleeve was shared with you by an administrator and all your
          clients can use it. You cannot edit it or control which clients have
          access.
        </div>
      )}

      {(isMutating || isAdmin.isLoading) && <LogoLoadingStill onTop={true} />}
    </>
  );
};

export default SleeveSharingTable;
