import React from "react";

import { Divider, Stack, Typography } from "@mui/material";

import { ProposalTradingGroup } from "@fartherfinance/frontend/api/Rebalance/Types";

import { PLACEHOLDER_SIGN } from "@src/yellowstone/modules/shared";

import { useStyles } from "./TradingGroupSummary.styles";

interface TradingGroupSummaryProps {
  tradingGroup: ProposalTradingGroup;
  taxLossHarvesting: boolean;
}

export const TradingGroupSummary = ({
  tradingGroup,
  taxLossHarvesting,
}: TradingGroupSummaryProps) => {
  const { classes, cx } = useStyles();

  return (
    <Stack className={classes.container}>
      <Typography className={classes.heading} variant="caption">
        Trading Group
      </Typography>
      <Typography
        className={cx(classes.text, classes.textPrimary, classes.groupNameRow)}
      >
        {tradingGroup.groupName}
      </Typography>

      {tradingGroup.accounts.map((account, index) => {
        const isAccountWithMetadata = "info" in account;
        const accountId = isAccountWithMetadata
          ? account.info.custodianAccountId
          : account.custodianAccountId;
        const accountType = isAccountWithMetadata
          ? account.info.accountType
          : account.accountType;

        return (
          <Stack
            key={index}
            className={classes.accountRow}
            direction="row"
            justifyContent="space-between"
          >
            <Typography className={cx(classes.text, classes.textPrimary)}>
              {accountId ?? PLACEHOLDER_SIGN}
            </Typography>
            <Typography className={cx(classes.text, classes.textSecondary)}>
              {accountType}
            </Typography>
          </Stack>
        );
      })}

      <Divider className={classes.divider} />

      <Stack direction="row" justifyContent="space-between">
        <Typography className={cx(classes.text, classes.textSecondary)}>
          Tax-loss harvesting
        </Typography>
        <Typography className={cx(classes.text, classes.textSecondary)}>
          {taxLossHarvesting ? "On" : "Off"}
        </Typography>
      </Stack>
    </Stack>
  );
};
