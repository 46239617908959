import React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import { useStyles } from "./ProposalMetadata.styles";

interface ProposalMetadataEntryProps {
  title: string;
  labels: { key: string; value: React.ReactNode }[];
}

export const ProposalMetadataEntry: React.FC<ProposalMetadataEntryProps> = ({
  title,
  labels,
}) => {
  const { classes, cx } = useStyles();

  return (
    <Box>
      <Typography className={classes.title}>{title}</Typography>
      {labels.map(({ key, value }) => (
        <Stack key={key} direction="row" spacing={key.length === 0 ? 0 : 0.5}>
          <Typography className={cx(classes.label, classes.labelKey)}>
            {key}
          </Typography>

          {React.isValidElement(value) ? (
            value
          ) : (
            <Typography className={classes.label} noWrap>
              {value ? value : <Skeleton width={120} />}
            </Typography>
          )}
        </Stack>
      ))}
    </Box>
  );
};
