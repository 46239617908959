import React, { useMemo } from "react";

import { chipClasses, ChipProps, Chip as MUIChip, styled } from "@mui/material";

import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

interface Props extends ChipProps {
  labelColor?: string;
}

const Chip = (props: Props): JSX.Element => {
  const {
    color: { $backgroundSubtle, $text, $borderBold, $backgroundNeutral },
  } = useTheme();

  const StyledChip = useMemo(
    () =>
      styled(MUIChip)(() => ({
        backgroundColor:
          props.variant === "outlined" ? $backgroundNeutral : $backgroundSubtle,
        border: `1px solid ${$borderBold}`,
        height: "auto",
        [`& .${chipClasses.label}`]: {
          color: props.labelColor ? props.labelColor : $text,
          fontSize: "10px",
          lineHeight: "20px",
        },
        [`& .${chipClasses.deleteIcon}`]: {
          display: "none",
        },
      })),
    [
      $backgroundNeutral,
      $backgroundSubtle,
      $borderBold,
      $text,
      props.variant,
      props.labelColor,
    ]
  );

  return <StyledChip {...props} />;
};

export default Chip;
