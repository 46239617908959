import React, { Fragment, useCallback, useMemo, useState } from "react";

import { format, parse } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import { random, uniq } from "lodash";
import {
  DefaultValues,
  FieldPath,
  FormProvider,
  useForm,
  useFormState,
  useWatch,
} from "react-hook-form";

import usePlanDetailsV2 from "@fartherfinance/frontend/api/Accounts/hooks/usePlanDetailsV2";
import useSetPlanAccountInfoV2 from "@fartherfinance/frontend/api/Accounts/hooks/useSetPlanAccountInfoV2";
import useSetPlanAccountPeopleV2 from "@fartherfinance/frontend/api/Accounts/hooks/useSetPlanAccountPeopleV2";
import { PlanDetails } from "@fartherfinance/frontend/api/Accounts/requests/getPlanDetailsV2";
import { TrustBody } from "@fartherfinance/frontend/api/Accounts/requests/setPlanAccountPeopleV2";
import {
  Account,
  AccountInformationFormToSend,
  Client,
  TrustType,
  trustTypeOptions,
} from "@fartherfinance/frontend/api/Accounts/Types";
import useAdvisorClients from "@fartherfinance/frontend/api/Entity/hooks/useAdvisorClients";
import useClientBeneficiaries from "@fartherfinance/frontend/api/Entity/hooks/useClientBeneficiaries";
import useGetEntities from "@fartherfinance/frontend/api/Entity/hooks/useGetEntities";
import { Beneficiary } from "@fartherfinance/frontend/api/Entity/requests/getClientBeneficiaries";
import {
  ClientId,
  EntityId,
  FartherAccountId,
  PlanId,
  USAState,
  USAStates,
} from "@fartherfinance/frontend/api/Types";

import DrawerHeader from "../Components/DrawerHeader";
import useTriggerForm from "../hooks/useTriggerForm";
import { dateFormat } from "@src/constants/dateFormat";
import Drawer from "@src/multiCustodian/components/Drawer/Drawer";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { AutocompleteOption } from "@src/sharedComponents/Dropdown/Dropdown";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FlexWrapperBeneficiaries from "@src/sharedComponents/Forms/FlexWrapperBeneficiaries";
import FormAccountHolderDropdownField, {
  AutocompleteClient,
  labelMaker as clientLabelMaker,
} from "@src/sharedComponents/Forms/FormAccountHolderDropdownField";
import FormCheckboxField from "@src/sharedComponents/Forms/FormCheckboxField";
import FormDateField from "@src/sharedComponents/Forms/FormDateField";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import FormDropdownMultiSelectField from "@src/sharedComponents/Forms/FormDropdownMultiSelectField";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import FormH2 from "@src/sharedComponents/Forms/FormH2";
import FormNumberField from "@src/sharedComponents/Forms/FormNumberField";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import { extractAxiosErrorMessage, isAxiosErrorCode } from "@src/utils/axios";

import styles from "./Form.module.css";
import planStyles from "../plans.module.css";

type TrusteeSelection = `Trustee${number}`;

type DefaultTrusteeForMapping = {
  clientId: ClientId;
  placeholderId: number;
};

type TrusteeForMapping = {
  placeholderId: number;
};

const randomId = () => random(10000, 99999);

const powersOfTheTrusteeOptions = [
  "Margin/Short Selling/Lending",
  "General Borrowing",
  "Cash/Asset Management",
  "Power of Attorney",
  "Option Trading",
] as const;

const taxIdTypes = ["EIN", "SSN"] as const;

interface AutoCompleteState extends AutocompleteOption {
  label: USAState;
}

interface AutocompleteEntity extends AutocompleteOption {
  label: string;
  entityId: EntityId;
}

interface AutocompleteTrustType extends AutocompleteOption {
  label: TrustType;
}

interface AutocompleteTaxIDType extends AutocompleteOption {
  label: typeof taxIdTypes[number];
}

type TrusteeAutocomplete = AutocompleteClient;

type Form = {
  AccountName: string | undefined;
  GoverningState: AutoCompleteState | undefined;
  TrustOwner: AutocompleteClient | undefined;
  Entity: AutocompleteEntity | undefined;
  TargetGoal: string | undefined;
  TargetDate: Date | undefined;
  TrustType: AutocompleteTrustType | undefined;
  TaxIDType: AutocompleteTaxIDType | undefined;
  TaxID: string | undefined;
  PowersOfTrustee: typeof powersOfTheTrusteeOptions[number][] | undefined;
  DateOfTrust: Date | undefined;
  Grantor: AutocompleteClient | undefined;
  TrustPurpose: string | undefined;
  ActIndividually: boolean | undefined;
} & {
  [_k in TrusteeSelection]: TrusteeAutocomplete | undefined;
};

type StripUndefined<F extends object> = { [k in keyof F]: NonNullable<F[k]> };

interface InitProps {
  planId: PlanId;
  accountId: FartherAccountId;
  onClose: () => void;
  account: Account;
}

export default function Trust(props: InitProps): JSX.Element {
  const auth = useAdvisorRequestAuth();
  const clients = useAdvisorClients(undefined, auth);

  const planDetails = usePlanDetailsV2(props.planId, auth);

  const clientBeneficiaries = useClientBeneficiaries(
    planDetails.data?.plan.planHolder.clientId ?? null,
    auth
  );

  if (
    planDetails.isLoading ||
    clientBeneficiaries.isLoading ||
    clients.isLoading
  ) {
    return (
      <Drawer
        header={
          <DrawerHeader planId={props.planId} accountId={props.accountId} />
        }
        footer={
          <Footer
            onSave={() => undefined}
            saveDisabled={true}
            closeDrawer={props.onClose}
            isMutating={false}
          />
        }
        isDrawerOpen
        onClose={props.onClose}
      >
        <div className={planStyles.loadingContainer}>
          <LogoLoadingStill />
        </div>
      </Drawer>
    );
  }

  if (
    planDetails.hasError ||
    clientBeneficiaries.hasError ||
    clients.hasError
  ) {
    return (
      <Drawer
        header={
          <DrawerHeader planId={props.planId} accountId={props.accountId} />
        }
        footer={
          <Footer
            onSave={() => undefined}
            saveDisabled={true}
            closeDrawer={props.onClose}
            isMutating={false}
          />
        }
        isDrawerOpen
        onClose={props.onClose}
      >
        <div>Error</div>
      </Drawer>
    );
  }

  return (
    <TrustForm
      planId={props.planId}
      accountId={props.accountId}
      planDetails={planDetails.data}
      account={props.account}
      beneficiaries={clientBeneficiaries.data}
      onClose={props.onClose}
      clients={clients.data.clients}
    />
  );
}

interface Props {
  planId: PlanId;
  accountId: FartherAccountId;
  onClose: () => void;
  account: Account;
  planDetails: PlanDetails;
  beneficiaries: Beneficiary[];
  clients: Client[];
}

const calcDefaultValues = (account: Account): DefaultValues<Form> => {
  const defaultAccountName = account.accountInformation?.accountTitle ?? "";

  const defaultGoverningState =
    account.accountInformation?.trustData?.governingState ?? null;

  const trustOwner = account.accountInformation?.trustData?.owner ?? null;
  const defaultTrustOwner = trustOwner?.clientId
    ? {
        label: `${trustOwner.name.last}, ${
          trustOwner.name.first
        } (...${trustOwner.clientId.slice(-4)})`,
        clientId: trustOwner?.clientId,
      }
    : undefined;

  const entity = account.accountInformation?.trustData?.entity ?? null;
  const defaultEntity = entity?.entityId
    ? { label: entity.name, entityId: entity.entityId }
    : undefined;

  const accountTarget = account.accountInformation.accountTarget ?? null;

  const defaultTargetGoal = accountTarget ? `${accountTarget.goal}` : "";

  const defaultTargetDate = accountTarget
    ? parse(accountTarget.date, dateFormat, new Date())
    : undefined;

  const defaultTrustType =
    account.accountInformation?.trustData?.trustType ?? null;

  const defaultTaxIDType =
    account.accountInformation?.trustData?.taxInformation?.taxIdType ?? null;

  const taxID =
    account.accountInformation?.trustData?.taxInformation.taxId ?? "";

  const defaultTaxID =
    taxID && taxID.length === 9
      ? defaultTaxIDType === "SSN"
        ? `${taxID.substring(0, 3)}-${taxID.substring(3, 5)}-${taxID.substring(
            5,
            9
          )}`
        : `${taxID.substring(0, 2)}-${taxID.substring(2, 9)}`
      : undefined;

  const dateOfTrust =
    account.accountInformation?.trustData?.dateEstablished ?? null;

  const defaultDateOfTrust = dateOfTrust
    ? parse(dateOfTrust, dateFormat, new Date())
    : undefined;

  const grantor = account.accountInformation?.trustData?.grantor ?? null;
  const defaultGrantor = grantor?.clientId
    ? {
        label: `${grantor.name.last}, ${
          grantor.name.first
        } (...${grantor.clientId.slice(-4)})`,
        clientId: grantor?.clientId,
      }
    : undefined;

  const defaultTrustPurpose =
    account.accountInformation?.trustData?.trustPurpose ?? undefined;

  const defaultActIndividually =
    account.accountInformation?.trustData?.options?.trusteeAuthority
      ?.trusteesIndividualAuthority ?? "";

  const defaultPowersOfTrustee: typeof powersOfTheTrusteeOptions[number][] = [];
  if (account.accountInformation?.trustData?.options?.ptMargin) {
    defaultPowersOfTrustee.push("Margin/Short Selling/Lending");
  }

  if (account.accountInformation?.trustData?.options?.ptGeneralBorrowing) {
    defaultPowersOfTrustee.push("General Borrowing");
  }

  if (account.accountInformation?.trustData?.options?.ptCashManagement) {
    defaultPowersOfTrustee.push("Cash/Asset Management");
  }

  if (account.accountInformation?.trustData?.options?.ptPowerOfAttorney) {
    defaultPowersOfTrustee.push("Power of Attorney");
  }

  if (account.accountInformation?.trustData?.options?.ptOptionTrading) {
    defaultPowersOfTrustee.push("Option Trading");
  }

  return {
    AccountName: defaultAccountName,
    GoverningState: defaultGoverningState
      ? { label: defaultGoverningState }
      : undefined,
    TrustOwner: defaultTrustOwner,
    Entity: defaultEntity,
    TargetGoal: defaultTargetGoal,
    TargetDate: defaultTargetDate,
    TrustType: defaultTrustType ? { label: defaultTrustType } : undefined,
    TaxIDType:
      defaultTaxIDType === "EIN" || defaultTaxIDType === "SSN"
        ? { label: defaultTaxIDType }
        : undefined,
    TaxID: defaultTaxID,
    PowersOfTrustee: defaultPowersOfTrustee,
    DateOfTrust: defaultDateOfTrust,
    Grantor: defaultGrantor,
    TrustPurpose: defaultTrustPurpose,
    ActIndividually: defaultActIndividually ? true : false,
  };
};

const TrustForm = (props: Props): JSX.Element => {
  const [isMutating, setIsMutating] = useState<boolean>(false);

  const statusNotification = useStatusNotification();

  const { enablePlanTrustPurposeField } = useFlags();

  const clientId = props.planDetails.plan.planHolder.clientId;

  const auth = useAdvisorRequestAuth();
  const { data: entities } = useGetEntities(
    auth === null
      ? null
      : { clientId: clientId, jwt: auth.jwt, advisorId: auth.advisorId }
  );

  const { defaultValues, defaultTrustees } = useMemo(() => {
    const defaultValues = calcDefaultValues(props.account);

    const accountTrustees = props.account.accountPeople?.trustees ?? [];

    const defaultTrustees: DefaultTrusteeForMapping[] = accountTrustees.map(
      (t) => ({
        clientId: t.clientId,
        placeholderId: randomId(),
      })
    );

    defaultTrustees.forEach((t) => {
      const trustee = props.account.accountPeople?.trustees?.find(
        (el) => el.clientId === t.clientId
      );

      if (trustee === undefined) {
        // Should be am exception...
        return;
      }

      defaultValues[`Trustee${t.placeholderId}`] = {
        label: clientLabelMaker(trustee),
        clientId: trustee.clientId,
      };
    });

    return { defaultValues, defaultTrustees };
  }, [props.account]);

  const [trustees, setTrustees] = useState<TrusteeForMapping[]>(
    defaultTrustees.length > 0
      ? defaultTrustees
      : [{ placeholderId: randomId() }]
  );

  const methods = useForm<Form>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues,
  });

  const { control, getValues, trigger, unregister } = methods;

  const { isValid } = useFormState({
    control,
  });

  const targetGoalValue = useWatch<Form>({ control, name: "TargetGoal" }) as
    | string
    | undefined;
  const targetGoalNonEmpty = nonEmptyValue(targetGoalValue);

  const targetDateValue = useWatch<Form>({ control, name: "TargetDate" }) as
    | Date
    | undefined;
  const targetDateNotEmpty = targetDateValue !== undefined;

  const callPutPlanAccountInfo = useSetPlanAccountInfoV2(auth);
  const callPutPlanAccountPeople = useSetPlanAccountPeopleV2(auth);

  const needsManualTriggering: FieldPath<Form>[] = useMemo(
    () => ["TargetDate", "TargetGoal"],
    []
  );
  useTriggerForm<Form>(control, trigger, needsManualTriggering);

  const submit = useCallback(async () => {
    if (!isValid) {
      return;
    }

    const values = getValues() as StripUndefined<Form>;

    const form: AccountInformationFormToSend = {
      accountType: "Trust",
      accountTitle: values.AccountName,
      accountTarget: targetGoalNonEmpty
        ? {
            goal: parseFloat(values.TargetGoal),
            date: format(values.TargetDate, dateFormat),
          }
        : null,
      trustData: {
        trustName: values.Entity.label,
        governingState: values.GoverningState?.label ?? values.GoverningState,
        ownerId: values.TrustOwner?.clientId ?? values.TrustOwner,
        entityId: values.Entity?.entityId ?? values.Entity,
        trustType: values.TrustType?.label ?? values.TrustType,
        trustPurpose: values.TrustPurpose,
        taxInformation: {
          taxIdType: values.TaxIDType?.label ?? values.TaxIDType,
          taxId: values.TaxID.replace(/-/g, ""),
        },
        dateEstablished: format(values.DateOfTrust, dateFormat),
        grantorId: values.Grantor?.clientId ?? values.Grantor,
        revokerId: null,
        options: {
          ptCashManagement: values.PowersOfTrustee.includes(
            "Cash/Asset Management"
          )
            ? true
            : false,
          ptGeneralBorrowing: values.PowersOfTrustee.includes(
            "General Borrowing"
          )
            ? true
            : false,
          ptMargin: values.PowersOfTrustee.includes(
            "Margin/Short Selling/Lending"
          )
            ? true
            : false,
          ptOptionTrading: values.PowersOfTrustee.includes("Option Trading")
            ? true
            : false,
          ptPowerOfAttorney: values.PowersOfTrustee.includes(
            "Power of Attorney"
          )
            ? true
            : false,
          trusteeAuthority: {
            trusteesIndividualAuthority: values.ActIndividually ? true : false,
            corporateTrusteesIndividualAuthority: false,
          },
        },
      },
    };

    const peopleForm: TrustBody = {
      trusteeIds: trustees.map(
        (t) => values[`Trustee${t.placeholderId}`].clientId
      ),
      beneficiaries: [],
    };

    try {
      setIsMutating(true);

      await callPutPlanAccountInfo({
        planId: props.planId,
        virtualAccountId: props.accountId,
        accountData: form,
      });

      await callPutPlanAccountPeople({
        planId: props.planId,
        virtualAccountId: props.accountId,
        people: peopleForm,
      });

      statusNotification("Account updated", "Success");
      props.onClose();
    } catch (error) {
      const errorMessage = isAxiosErrorCode(400, error)
        ? extractAxiosErrorMessage(error)
        : "Error updating account";

      statusNotification(errorMessage, "Error");
      setIsMutating(false);
    }
  }, [
    props,
    getValues,
    isValid,
    targetGoalNonEmpty,
    callPutPlanAccountInfo,
    callPutPlanAccountPeople,
    trustees,
    statusNotification,
  ]);

  return (
    <Drawer
      transitionDuration={0}
      header={
        <DrawerHeader planId={props.planId} accountId={props.accountId} />
      }
      footer={
        <Footer
          onSave={submit}
          saveDisabled={!isValid}
          closeDrawer={props.onClose}
          isMutating={isMutating}
        />
      }
      isDrawerOpen
      onClose={props.onClose}
    >
      <FormProvider {...methods}>
        <FormH1>Trust</FormH1>

        <FormH2>Account Information</FormH2>

        <FlexWrapper>
          <FormTextField
            name="AccountName"
            label="Account Name"
            required="Must not be empty"
          />

          <FormDropdownField
            name="GoverningState"
            label="Governing State"
            required="Must not be empty"
            values={USAStates.map((s) => ({
              label: s,
            }))}
          />

          <FormAccountHolderDropdownField
            name="TrustOwner"
            label="Trust Owner"
            required="Must not be empty"
          />

          <FormDropdownField
            name="Entity"
            label="Entity"
            required="Must not be empty"
            values={(entities ?? []).map((el) => ({
              label: el.trustName,
              entityId: el.entityId,
            }))}
          />

          <FormNumberField
            name={"TargetGoal"}
            startAdornment={"$"}
            required={
              targetDateNotEmpty
                ? "This is required when Target Date is set"
                : undefined
            }
            label="Target Goal (Required when Date set)"
          />

          <FormDateField
            required={
              targetGoalNonEmpty
                ? "This is required when Target Goal is set"
                : undefined
            }
            rules={{ deps: ["TargetGoal"] }}
            dateCheck={"AfterToday"}
            label="Target Date (Required when Goal set)"
            name={"TargetDate"}
          />

          <FormDropdownField
            name="TrustType"
            label="Trust Type"
            required="Must not be empty"
            values={trustTypeOptions.map((el) => ({ label: el }))}
          />

          <FormDateField
            name="DateOfTrust"
            label="Date of Trust"
            required="Must not be empty"
          />

          <FormDropdownField
            name="TaxIDType"
            label="Tax ID Type"
            required="Must not be empty"
            values={taxIdTypes.map((el) => ({ label: el }))}
          />

          <FormTextField
            name="TaxID"
            label="Tax ID"
            required="Must not be empty"
            rules={{
              deps: ["TaxIDType"],
              validate: {
                ssnFormat: () => {
                  const taxIDType = getValues("TaxIDType");
                  const idType =
                    taxIDType === undefined ? undefined : taxIDType.label;
                  const idValue = getValues("TaxID");
                  if (idValue === undefined) {
                    return "Must not be empty";
                  }

                  switch (idType) {
                    case "SSN":
                      return /^\d{3}-\d{2}-\d{4}$/.test(idValue)
                        ? true
                        : "Must be in the format xxx-xx-xxxx";

                    case "EIN":
                      return /^\d{2}-\d{7}$/.test(idValue)
                        ? true
                        : "Must be in the format xx-xxxxxxx";

                    case undefined:
                      return "Must not be empty";
                  }
                },
              },
            }}
          />

          <FormAccountHolderDropdownField
            name="Grantor"
            label="Grantor (Optional)"
          />

          <FormDropdownMultiSelectField
            name="PowersOfTrustee"
            label="Powers of the Trustee (Optional)"
            values={powersOfTheTrusteeOptions}
          />
        </FlexWrapper>

        {enablePlanTrustPurposeField &&
          props.account.custodian === "Pershing" && (
            <FormTextField
              style={{ marginBottom: "30px" }}
              name="TrustPurpose"
              label="Trust Purpose"
              required={"Must not be empty"}
            />
          )}

        <FormCheckboxField
          name="ActIndividually"
          label="Trustees can act individually"
        />

        <Spacer />

        <ButtonPrimary
          text={"Add trustee"}
          onClick={() =>
            setTrustees([
              ...trustees,
              {
                placeholderId: randomId(),
              },
            ])
          }
          variant={"contained"}
          buttonType={"primary"}
          disabled={trustees.length >= 4}
          style={{ justifyContent: "flex-start" }}
        />

        {trustees.map((trustee, index, arr) => (
          <Fragment key={trustee.placeholderId}>
            <FlexWrapper>
              <FormH2>Trustee {index + 1}</FormH2>

              {arr.length > 1 && (
                <ButtonPrimary
                  text={"Remove this trustee"}
                  onClick={() => {
                    const newTrustees = trustees.filter(
                      (trtee) => trtee.placeholderId !== trustee.placeholderId
                    );
                    setTrustees(newTrustees);
                    unregister(`Trustee${trustee.placeholderId}`);
                  }}
                  variant={"contained"}
                  buttonType={"primary"}
                  style={{ justifyContent: "flex-end" }}
                />
              )}
            </FlexWrapper>

            <FlexWrapperBeneficiaries>
              <FormAccountHolderDropdownField
                name={`Trustee${trustee.placeholderId}`}
                label={"Trustee"}
                required="Must not be empty"
                rules={{
                  deps: [
                    "PrimaryAccountHolder",
                    ...trustees.map(
                      (t): TrusteeSelection => `Trustee${t.placeholderId}`
                    ),
                  ],
                  validate: {
                    singletons: () => {
                      const values = getValues();

                      const uniqTrustees = uniq(
                        trustees
                          .map(
                            (t): TrusteeSelection => `Trustee${t.placeholderId}`
                          )
                          .map((t): ClientId | undefined => values[t]?.clientId)
                      );

                      return uniqTrustees.length === trustees.length
                        ? true
                        : "Each trustee must be unique";
                    },
                  },
                }}
              />
            </FlexWrapperBeneficiaries>
          </Fragment>
        ))}

        <Spacer />
      </FormProvider>

      {isMutating && (
        <div className={styles.loading}>
          <LogoLoadingStill />
        </div>
      )}
    </Drawer>
  );
};

const nonEmptyValue = (value: string | undefined | null) => {
  return value !== undefined && value !== "" && value !== null;
};

interface FooterProps {
  closeDrawer: () => void;
  saveDisabled: boolean;
  onSave: () => void;
  isMutating: boolean;
}

const Footer = (props: FooterProps): JSX.Element => {
  return (
    <div className={styles.footer}>
      <ButtonPrimary
        text={"Cancel"}
        onClick={props.closeDrawer}
        variant={"outlined"}
        buttonType={"primary"}
      />

      <ButtonPrimary
        text={"Save"}
        onClick={props.onSave}
        variant={"contained"}
        buttonType={"primary"}
        disabled={props.saveDisabled || props.isMutating}
      />
    </div>
  );
};
