import { format } from "date-fns";
import { isNil } from "lodash";

import { BeneficiaryRequest } from "@fartherfinance/frontend/api/Entity/requests/postClientBeneficiary";

import { Form } from "../Types";
import { dateFormat } from "@src/constants/dateFormat";

export default function mapValuesToSubmitForm(
  values: Form
): BeneficiaryRequest | undefined {
  if (
    isNil(values.DateOfBirth) ||
    isNil(values.Relationship) ||
    isNil(values.FirstName) ||
    isNil(values.LastName)
  ) {
    return;
  }

  return {
    contactInformation: {
      emailAddress: values.Email
        ? values.Email.trim() === ""
          ? null
          : values.Email.trim()
        : null,
      phoneNumber: null,
    },
    dateOfBirth: format(values.DateOfBirth, dateFormat),
    name: {
      first: values.FirstName.trim(),
      last: values.LastName.trim(),
      middle: null,
      prefix: null,
      suffix: null,
    },
    relationshipIndicator: values.Relationship.label,
    address: isNil(values.State)
      ? null
      : {
          street: values.Address1 ? values.Address1.trim() : "", //Setting these values as empty strings allows for partial validation
          unit: values.Address2
            ? values.Address2.trim() === ""
              ? null
              : values.Address2.trim()
            : null,
          city: values.City ? values.City.trim() : "",
          state: values.State.label,
          postalCode: values.ZipCode ?? "",
          country: "US",
        },
    taxInformation: isNil(values.TaxID)
      ? null
      : {
          taxId: values.TaxID,
          taxIdType: values.TaxID.startsWith("9") ? "ITIN" : "SSN",
        },
  };
}
