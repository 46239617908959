import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import useValidateEssentialPortfolioV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useValidateEssentialPortfolioV2";
import {
  CreateEssentialPortfolio,
  UpdateEssentialPortfolio,
} from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";

import AdvisorContainer from "../../AdvisorContainer";
import UpdateModelsBody from "@src/multiCustodian/components/Advisor/Investments/ModelMarketplace/UpdateExistingModels/UpdateModelsBody";
import UpdateModelsBodyLoading from "@src/multiCustodian/components/Advisor/Investments/ModelMarketplace/UpdateExistingModels/UpdateModelsBodyLoading";
import { AddOrUpdate } from "@src/multiCustodian/components/Advisor/Investments/SharedComponents/AddPartnerModelIncrementsCheckbox/AddPartnerModelIncrementsCheckbox";
import CSVFormModal from "@src/multiCustodian/components/Advisor/Investments/SharedComponents/CSVFormModal/CSVFormModal";
import useIsAdmin from "@src/multiCustodian/components/Advisor/utils/useIsAdmin";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { State } from "@src/store";

type Portfolio = CreateEssentialPortfolio | UpdateEssentialPortfolio;

const Update = (): JSX.Element => {
  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));

  const history = useHistory();

  const auth = useAdvisorRequestAuth();

  const statusNotification = useStatusNotification();

  const [mutating, setMutating] = useState(false);

  const [pageState, setPageState] = useState<"CSV_UPLOAD" | "MODEL_UPDATES">(
    "CSV_UPLOAD"
  );

  const [csvError, setCSVError] = useState<string[] | null>(null);

  const [csvFile, setCSVFile] = useState<File | null>(null);

  const [partnerModels, setPartnerModels] = useState<Portfolio[] | null>(null);

  const [action, setAction] = useState<AddOrUpdate>("Update");

  const validateEssentialModelsInCSV = useValidateEssentialPortfolioV2(auth);

  const isAdmin = useIsAdmin(advisorId);

  useEffect(() => {
    if (isAdmin.data === false) {
      history.push({
        pathname: "/Advisor/Investments/ModelMarketplace",
        state: history.location.state,
      });
    }
  }, [isAdmin, history]);

  const uploadCSVFile = async () => {
    if (csvFile === null) {
      statusNotification("Please attach a CSV document", "Error");
      return;
    }

    if (mutating) {
      statusNotification("Uploading your document, please wait", "Error");
      return;
    }

    try {
      setMutating(true);

      const res = await validateEssentialModelsInCSV({
        file: csvFile,
        action: action,
      });
      if (res.status === "valid") {
        if (res.data.portfolios.length <= 0) {
          setCSVError([".csv file is empty"]);
          setCSVFile(null);
          return;
        }

        setPartnerModels(res.data.portfolios);
        setPageState("MODEL_UPDATES");

        statusNotification("CSV validation successful", "Success");
      } else {
        setCSVError(res.data.errors);
      }
    } catch (e) {
      if (e instanceof Error) {
        statusNotification(e.message, "Error");
      }
    } finally {
      setMutating(false);
    }
  };

  useEffect(() => {
    if (csvFile === null && pageState !== "CSV_UPLOAD") {
      //If theres no file, we have no data to display in the model_updates table
      setPageState("CSV_UPLOAD");
    }
  }, [csvFile, pageState]);

  const addNewFile = (newFile: File | null) => {
    setCSVFile(newFile);

    if (newFile === null) {
      setPartnerModels(null);
    }

    if (csvError !== null && newFile !== null) {
      //Only remove an existing error if a new file has been provided
      setCSVError(null);
    }
  };

  if (isAdmin.isLoading || isAdmin.hasError) {
    return <div>Error retrieving employee status</div>;
  }

  if (pageState === "CSV_UPLOAD" || partnerModels === null) {
    return (
      <AdvisorContainer>
        <CSVFormModal
          formObjective="Update Existing Models"
          uploadType={"Partner"}
          action={action}
          setAction={setAction}
          csvFile={csvFile}
          uploadCSVFile={uploadCSVFile}
          mutating={mutating}
          csvError={csvError}
          addNewFile={addNewFile}
          closeModal={
            partnerModels === null
              ? () =>
                  history.push({
                    pathname: "/Advisor/Investments/ModelMarketplace",
                    state: history.location.state,
                  })
              : () => setPageState("MODEL_UPDATES")
          }
        />
      </AdvisorContainer>
    );
  }

  return (
    <AdvisorContainer hideNavbar={true}>
      {partnerModels === null || mutating ? (
        <UpdateModelsBodyLoading />
      ) : (
        <UpdateModelsBody
          models={partnerModels}
          action={action}
          goToUploadCSV={() => setPageState("CSV_UPLOAD")}
          csvFile={csvFile}
        />
      )}
    </AdvisorContainer>
  );
};

export default Update;
