import React, { useMemo } from "react";

import Stack from "@mui/material/Stack";
import { isNumber, orderBy } from "lodash";

import { ProposedTrades } from "@fartherfinance/frontend/api/Rebalance/Types";

import { toCurrency, toLocaleNumber } from "../../ProposalDetails.utils";
import { TableRotatedLegend } from "../TableRotatedLegend/TableRotatedLegend.component";
import Message from "@src/sharedComponents/Message/Message";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";
import { Table } from "@src/yellowstone/components";
import { PLACEHOLDER_SIGN } from "@src/yellowstone/modules/shared";

import {
  PROPOSED_TRADES_LIST_HEADERS,
  PROPOSED_TRADES_SUMMARY_HEADERS,
} from "./ProposalProposedTrades.const";
import { useStyles } from "./ProposalProposedTrades.styles";

const TRADE_ROWS_SKELETON_COUNT = 10;

interface ProposalProposedTradesProps {
  proposedTrades: ProposedTrades | undefined;
  isLoading: boolean;
  hasError: boolean;
}

export const ProposalProposedTrades: React.FC<ProposalProposedTradesProps> = ({
  proposedTrades,
  isLoading,
  hasError,
}) => {
  const { classes } = useStyles();

  const { trades = [], summary } = proposedTrades ?? {};
  const tradesListColumnsCount = PROPOSED_TRADES_LIST_HEADERS.length;
  const tradesSummaryColumnsCount = PROPOSED_TRADES_SUMMARY_HEADERS.length;

  const sortedTrades = useMemo(
    () =>
      orderBy(
        trades,
        [(trade) => trade.ticker, (trade) => trade.account.custodianAccountId],
        ["asc", "asc"]
      ),
    [trades]
  );

  if (!hasError && !isLoading && sortedTrades.length === 0) {
    return (
      <Stack className={classes.container}>
        <Message text="Sorry, we couldn't generate proposed trades for this proposal." />
      </Stack>
    );
  }

  return (
    <Stack className={classes.container} direction="row">
      <TableRotatedLegend legendTitle="Proposed Trades" />

      <Stack className={classes.tablesContainer}>
        <Table
          className={classes.tradesContainer}
          columnsCount={tradesListColumnsCount}
        >
          {PROPOSED_TRADES_LIST_HEADERS.map((header) => (
            <Table.Header key={header}>{header}</Table.Header>
          ))}

          {isLoading && (
            <>
              {Array.from({ length: TRADE_ROWS_SKELETON_COUNT }).map(
                (_, index) => (
                  <Table.Row key={index}>
                    <Table.Cell className={classes.indexCell}>{`Trade ${
                      index + 1
                    }:`}</Table.Cell>
                    {Array.from({ length: tradesListColumnsCount - 1 }).map(
                      (_, index) => (
                        <Table.Cell key={index}>
                          <Skeleton />
                        </Table.Cell>
                      )
                    )}
                  </Table.Row>
                )
              )}
            </>
          )}

          {!hasError && !isLoading && (
            <>
              {sortedTrades.map((trade, index) => (
                <Table.Row key={index}>
                  <Table.Cell className={classes.indexCell}>{`Trade ${
                    index + 1
                  }:`}</Table.Cell>
                  <Table.Cell>
                    {trade.account.custodianAccountId ?? PLACEHOLDER_SIGN}
                  </Table.Cell>
                  <Table.Cell className={classes.tickerCell}>
                    {trade.ticker ?? PLACEHOLDER_SIGN}
                    {trade.description && (
                      <Tooltip tooltipText={trade.description} placement="top">
                        <div className={classes.tickerDescription}>
                          {trade.description}
                        </div>
                      </Tooltip>
                    )}
                  </Table.Cell>
                  <Table.Cell>{trade.side}</Table.Cell>
                  <Table.Cell>{toLocaleNumber(trade.quantity)}</Table.Cell>
                  <Table.Cell>
                    {isNumber(trade.price)
                      ? toCurrency(trade.price)
                      : PLACEHOLDER_SIGN}
                  </Table.Cell>
                  <Table.Cell>
                    {isNumber(trade.marketValue)
                      ? toCurrency(trade.marketValue)
                      : PLACEHOLDER_SIGN}
                  </Table.Cell>
                </Table.Row>
              ))}
            </>
          )}
        </Table>

        <Table
          className={classes.gridTemplate}
          columnsCount={tradesSummaryColumnsCount}
        >
          {PROPOSED_TRADES_SUMMARY_HEADERS.map((header) => (
            <Table.Header key={header}>{header}</Table.Header>
          ))}

          {isLoading && (
            <>
              <Table.Row className={classes.gridTemplate}>
                <Table.Cell className={classes.indexCell}>
                  Total Buys:
                </Table.Cell>
                {Array.from({ length: tradesSummaryColumnsCount - 1 }).map(
                  (_, index) => (
                    <Table.Cell key={index}>
                      <Skeleton />
                    </Table.Cell>
                  )
                )}
              </Table.Row>
              <Table.Row className={classes.gridTemplate}>
                <Table.Cell className={classes.indexCell}>
                  Total Sells:
                </Table.Cell>
                {Array.from({ length: tradesSummaryColumnsCount - 1 }).map(
                  (_, index) => (
                    <Table.Cell key={index}>
                      <Skeleton />
                    </Table.Cell>
                  )
                )}
              </Table.Row>
            </>
          )}

          {!hasError &&
            !isLoading &&
            (() => {
              const { totalBuys, totalSells } = summary ?? {};

              return (
                <>
                  {totalBuys && (
                    <Table.Row className={classes.gridTemplate}>
                      <Table.Cell className={classes.indexCell}>
                        Total Buys:
                      </Table.Cell>
                      <Table.Cell>{totalBuys.diffTickers}</Table.Cell>
                      <Table.Cell>{totalBuys.sideCount}</Table.Cell>
                      <Table.Cell>
                        {toLocaleNumber(totalBuys.quantity)}
                      </Table.Cell>
                      <Table.Cell>
                        {isNumber(totalBuys.sum)
                          ? toLocaleNumber(totalBuys.sum)
                          : PLACEHOLDER_SIGN}
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {totalSells && (
                    <Table.Row className={classes.gridTemplate}>
                      <Table.Cell className={classes.indexCell}>
                        Total Sells:
                      </Table.Cell>
                      <Table.Cell>{totalSells.diffTickers}</Table.Cell>
                      <Table.Cell>{totalSells.sideCount}</Table.Cell>
                      <Table.Cell>
                        {toLocaleNumber(totalSells.quantity)}
                      </Table.Cell>
                      <Table.Cell>
                        {isNumber(totalSells.sum)
                          ? toLocaleNumber(totalSells.sum)
                          : PLACEHOLDER_SIGN}
                      </Table.Cell>
                    </Table.Row>
                  )}
                </>
              );
            })()}
        </Table>
      </Stack>
    </Stack>
  );
};
