import { alpha } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

export const useStyles = makeStyles()((theme) => ({
  paper: {
    backgroundColor: alpha(theme.palette.common.white, 0.05),
    borderRadius: "10px",
    marginTop: theme.spacing(pxToSpacing(16)),
    padding: theme.spacing(pxToSpacing(20)),
  },
  collapseToggle: {
    cursor: "pointer",
  },
  accountName: {
    fontFamily: theme.typography.fontSecondary,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "19px",
    letterSpacing: "0.63px",
    color: theme.palette.common.white,
  },
  accountId: {
    color: theme.colors.primary.tints.navy50,
  },
  balance: {
    fontFamily: theme.typography.fontSecondary,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: "19px",
    letterSpacing: "0.63px",
    color: theme.palette.common.white,

    marginRight: theme.spacing(pxToSpacing(10)),
  },
  icon: {
    cursor: "pointer",
    color: theme.appearance.$iconSubtle,

    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.standard,
    }),
  },
  iconRoate: {
    transform: "rotate(180deg)",
  },
  divider: {
    borderColor: alpha(theme.palette.common.white, 0.1),
    marginTop: theme.spacing(pxToSpacing(17.5)),

    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.standard,
    }),
  },
  dividerBottomSpacing: {
    marginBottom: theme.spacing(pxToSpacing(19.5)),
  },
  accountEntry: {
    "&:not(:last-of-type)": {
      marginBottom: theme.spacing(pxToSpacing(18)),
    },
  },
  kickOffButton: {
    marginTop: theme.spacing(pxToSpacing(20)),
    float: "right",
  },
}));
