import React, { useMemo, useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { orderBy, range } from "lodash";
import { useSelector } from "react-redux";

import useSleeves from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useSleeves";
import { SleeveId } from "@fartherfinance/frontend/api/Types";

import { SelectedSleeve } from "../../SharedComponents/SearchSecurities/Types";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import { captureException } from "@src/multiCustodian/services/tracking";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import BorderedList, {
  Props as ListProps,
} from "@src/sharedComponents/List/BorderedList";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import { State } from "@src/store";
import { pluralize } from "@src/utils/pluralize";

import styles from "./SleevePicker.module.css";

const minNumViewableSleeves = 5;
const maxNumViewableSleeves = 20;

interface Props {
  selectedSleeves: SelectedSleeve[];
  addSleeve: (sleeve: SelectedSleeve) => void;
  removeSleeve: (s: SleeveId) => void;
}

const SleevePicker = ({
  selectedSleeves,
  addSleeve,
  removeSleeve,
}: Props): JSX.Element => {
  const [seeMore, setSeeMore] = useState(false);

  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));

  const auth = useAdvisorRequestAuth();
  const sleeves = useSleeves(advisorId, auth);

  const header: ListProps["rows"][number] = useMemo(() => {
    if (sleeves.data === undefined) {
      return {
        left: <></>,
        right: <></>,
        rowStyle: {},
      };
    }

    const numSleeves = `${pluralize(
      sleeves.data.sleeves,
      "Sleeve",
      true
    )} Total`;

    return {
      left: <>Add Sleeves to Model Composition</>,
      right: <div className={styles.subtleText}>{numSleeves}</div>,
      rowStyle: { padding: "16px 24px" },
    };
  }, [sleeves.data]);

  const subHeader: ListProps["rows"][number] = useMemo(() => {
    if (sleeves.data === undefined) {
      return {
        left: <></>,
        right: <></>,
        rowStyle: {},
      };
    }

    return {
      left: (
        <div className={styles.leftColumns}>
          <div
            className={toClassName(
              styles.displayNameColumn,
              styles.subtleText,
              styles.bold
            )}
          >
            Name
          </div>
          <div className={toClassName(styles.subtleText, styles.bold)}>
            Securities
          </div>
        </div>
      ),
      ...(sleeves.data.sleeves.length > minNumViewableSleeves
        ? {
            right: (
              <div
                onClick={() => setSeeMore(!seeMore)}
                className={styles.actionButton}
              >
                {seeMore ? "See Less" : "See More"}
              </div>
            ),
          }
        : {}),
      rowStyle: { padding: "8px 24px" },
    };
  }, [sleeves.data, seeMore]);

  const selectedSleeveRows: ListProps["rows"] = useMemo(() => {
    if (sleeves.data === undefined) {
      return [];
    }

    const selectedSleevesWithData = selectedSleeves.map((s) => {
      const matchedSleeve = sleeves.data.sleeves.find(
        (m) => m.sleeveId === s.sleeveId
      );
      if (matchedSleeve === undefined) {
        const err = new Error(
          "Failed to look up selected sleeve from all sleeves using sleeveId: matchedSleeve === undefined"
        );

        captureException(err, {
          extra: {
            sleeveId: s.sleeveId,
            call: "selectedSleeveRows useMemo -> matchedSleeve",
            file: "SleevePicker.tsx",
          },
        });
      }

      return {
        sleeveId: s.sleeveId,
        displayName: matchedSleeve?.displayName ?? "N/A",
        numSecurities: matchedSleeve?.numSecurities ?? "N/A",
      };
    });

    const sortedSelectedSleeves = orderBy(
      selectedSleevesWithData,
      (s) => s.displayName?.toLowerCase(),
      "asc"
    );

    return sortedSelectedSleeves.map((s) => {
      return {
        left: (
          <div className={styles.leftColumns}>
            <div
              className={toClassName(
                styles.displayNameColumn,
                styles.subtleText
              )}
            >
              {s.displayName}
            </div>
            <div className={styles.subtleText}>{`${s.numSecurities} ${
              s.numSecurities === 1 ? "Security" : "Securities"
            }`}</div>
          </div>
        ),
        right: (
          <div
            className={styles.actionButton}
            onClick={() => removeSleeve(s.sleeveId)}
          >
            Remove from Model{" "}
            <RemoveCircleOutlineIcon className={styles.actionIcon} />
          </div>
        ),
        rowStyle: { padding: "8px 24px" },
      };
    });
  }, [selectedSleeves, removeSleeve, sleeves.data]);

  const availableSleeveRows: ListProps["rows"] = useMemo(() => {
    if (sleeves.data === undefined) {
      return [];
    }

    const availableSleeves = sleeves.data.sleeves
      .filter((s) => !s.isDerived)
      .filter((s) =>
        selectedSleeves.every((sel) => sel.sleeveId !== s.sleeveId)
      );

    const availableSleevesWithData = availableSleeves.map((s) => {
      const matchedSleeve = sleeves.data.sleeves.find(
        (m) => m.sleeveId === s.sleeveId
      );
      if (matchedSleeve === undefined) {
        const err = new Error(
          "Failed to look up selected sleeve from all sleeves using sleeveId: matchedSleeve === undefined"
        );

        captureException(err, {
          extra: {
            sleeveId: s.sleeveId,
            call: "availableSleeveRows useMemo -> matchedSleeve",
            file: "SleevePicker.tsx",
          },
        });
      }

      return {
        sleeveId: s.sleeveId,
        displayName: matchedSleeve?.displayName ?? "N/A",
        numSecurities: matchedSleeve?.numSecurities ?? "N/A",
      };
    });

    const sortedAvailableSleeves = orderBy(
      availableSleevesWithData,
      (s) => s.displayName?.toLowerCase(),
      "asc"
    );

    return sortedAvailableSleeves.map((s) => {
      return {
        left: (
          <div className={styles.leftColumns}>
            <div className={styles.displayNameColumn}>{s.displayName}</div>
            <div className={styles.subtleText}>{`${s.numSecurities} ${
              s.numSecurities === 1 ? "Security" : "Securities"
            }`}</div>
          </div>
        ),
        right: (
          <div className={styles.actionButton} onClick={() => addSleeve(s)}>
            Add to Model <AddCircleOutlineIcon className={styles.actionIcon} />
          </div>
        ),
        rowStyle: { padding: "8px 24px" },
      };
    });
  }, [sleeves, selectedSleeves, addSleeve]);

  const rowsCombined = useMemo(() => {
    return [...selectedSleeveRows, ...availableSleeveRows];
  }, [availableSleeveRows, selectedSleeveRows]).slice(
    0,
    seeMore ? Infinity : minNumViewableSleeves
  );

  // The anticipated height of every sleeve row is 35.5px ( we will round to 36 ).
  // We want to ensure the height of the container does not show more than 20 (May change in the future) rows at a time
  // The minimum number of sleeves we show is 5 (may change), so we fit the height to that.
  // 36px * number of sleeves to show
  const containerHeight =
    36 * (seeMore ? maxNumViewableSleeves : minNumViewableSleeves);

  if (sleeves.hasError) {
    return <div>Error retrieving sleeves</div>;
  }

  if (sleeves.isLoading) {
    return <TableLoading subHeader={subHeader} />;
  }

  return (
    <div
      className={styles.container}
      style={{ maxHeight: `${containerHeight}px` }}
    >
      <BorderedList
        headers={[header, subHeader]}
        rows={rowsCombined}
        maxHeight={containerHeight}
      />
    </div>
  );
};

export default SleevePicker;

interface TableLoadingProps {
  subHeader: ListProps["rows"][number];
}

const TableLoading = ({ subHeader }: TableLoadingProps) => {
  const headerLoading: ListProps["rows"][number] = useMemo(() => {
    return {
      left: <>Add Sleeves to Model Composition</>,
      right: (
        <div className={styles.subtleText}>
          <Skeleton width={120} />
        </div>
      ),
      rowStyle: { padding: "16px 24px" },
    };
  }, []);

  const rowsLoading: ListProps["rows"] = useMemo(() => {
    return range(3).map(() => {
      return {
        left: (
          <div className={styles.leftColumns}>
            <div className={styles.displayNameColumn}>
              <Skeleton width={120} />
            </div>

            <div className={styles.subtleText}>
              <Skeleton width={130} />
            </div>
          </div>
        ),
        right: (
          <div className={styles.actionButton}>
            <Skeleton width={150} />
          </div>
        ),
        rowStyle: { padding: "8px 24px" },
      };
    });
  }, []);

  return (
    <div className={styles.container}>
      <BorderedList headers={[headerLoading, subHeader]} rows={rowsLoading} />
    </div>
  );
};
