import { Beneficiary } from "@fartherfinance/frontend/api/Entity/requests/getClientBeneficiaries";

import { Form } from "../Types";

export default function mapDefaultValues(beneficiary: Beneficiary): Form {
  const {
    name,
    dateOfBirth,
    contactInformation,
    relationshipIndicator,
    address,
    taxInformation,
  } = beneficiary;

  return {
    Email: contactInformation?.emailAddress,
    FirstName: name.first,
    LastName: name.last,
    Relationship: { label: relationshipIndicator },
    DateOfBirth: dateOfBirth,
    TaxID: taxInformation?.taxId ?? null,
    Address1: address?.street ?? null,
    Address2: address?.unit ?? null,
    City: address?.city ?? null,
    State: address !== null ? { label: address.state } : null,
    ZipCode: address?.postalCode ?? null,
  };
}
