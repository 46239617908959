import { isNil } from "lodash";

const fiveDigitRegex = /^[0-9]{5}$/;

export default function has5Digits(
  input: string | null | undefined,
  required: boolean
): string | true {
  const isEmpty = isNil(input) || input === "";
  const is5DigitNumber = isEmpty ? false : fiveDigitRegex.test(input);

  if (required) {
    return is5DigitNumber ? true : "Must be a 5 digit number";
  }
  return isEmpty || is5DigitNumber ? true : "Must be a 5 digit number";
}
