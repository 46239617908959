import React from "react";

import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

const ApplySMAFormLoading = (): JSX.Element => {
  return (
    <div>
      <Skeleton height={27} />

      <Spacer verticalSpacing="24px" />

      <Skeleton height={21} />

      <Spacer verticalSpacing="24px" />

      <FlexWrapper>
        <div>
          <Skeleton height={50} />
        </div>
        <div>
          <Skeleton height={50} />
        </div>
        <div>
          <Skeleton height={50} />
        </div>
        <div>
          <Skeleton height={50} />
        </div>
      </FlexWrapper>

      <Spacer verticalSpacing="24px" />

      <Skeleton height={60} />

      <Spacer verticalSpacing="48px" />

      <Skeleton height={21} />

      <Spacer verticalSpacing="24px" />

      <FlexWrapper>
        <div>
          <Skeleton height={50} />
        </div>
        <div>
          <Skeleton height={50} />
        </div>
      </FlexWrapper>

      <Spacer verticalSpacing="24px" />

      <Skeleton height={80} />
    </div>
  );
};

export default ApplySMAFormLoading;
