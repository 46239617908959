import React, { useReducer } from "react";

import SharingAddCollaboratorFlow from "@src/multiCustodian/components/Sharing/Flows/AddCollaborator/Flow";
import AddCollaboratorContext from "@src/multiCustodian/components/Sharing/Flows/AddCollaborator/reducer/Context";
import { initialState } from "@src/multiCustodian/components/Sharing/Flows/AddCollaborator/reducer/initialState";
import reducer from "@src/multiCustodian/components/Sharing/Flows/AddCollaborator/reducer/reducer";

const SharingAddCollaborator = (): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AddCollaboratorContext.Provider value={{ state, dispatch }}>
      <SharingAddCollaboratorFlow />
    </AddCollaboratorContext.Provider>
  );
};

export default SharingAddCollaborator;
