import React, { useState } from "react";

import { useSelector } from "react-redux";

import useGetAcatsInstitutions from "@fartherfinance/frontend/api/Transfer/hooks/useGetAcatsInstitutions";
import { AcatsInstitution } from "@fartherfinance/frontend/api/Transfer/requests/getAcatsInstitutions";
import {
  AccountType,
  accountTypes,
} from "@fartherfinance/frontend/api/Transfer/requests/Types";

import ButtonPrimary from "../../../../components/MUI/Button/Button";
import { IraType, SetScreenAndTransferType, TransferScreen } from "../Types";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import SAODropdown from "@src/sharedComponents/SAODropdown/Dropdown";
import { State } from "@src/store";

import "./css.css";

type AccountTypeLabel = "Individual" | "Joint" | "Trust";

function searchList(
  list: AcatsInstitution[],
  search: string
): AcatsInstitution[] {
  if (search === "") {
    return list;
  }

  return list.filter(
    (e) => e.displayName.toLowerCase().search(search.toLowerCase()) !== -1
  );
}

type Props = (
  | {
      transferType: "roll over";
      iraType: IraType;
    }
  | { transferType: "transfer" }
) & {
  screen: TransferScreen["screen"];
  setCurScreen: SetScreenAndTransferType;
};

const InstitutionSelection = (props: Props): JSX.Element => {
  const auth = useRequestAuth();

  const acatInstitutions = useGetAcatsInstitutions(auth);

  const { leftArrow } = useSelector((state: State) => ({
    leftArrow: state.main_Reducer.brand.current.images.left_arrow,
  }));

  const [institutionCode, setInstitutionCode] = useState<string | null>(null);

  const [acatsAccountType, setAcatsAccountType] = useState<{
    type: AccountType;
    label: AccountTypeLabel;
  }>({ type: "INDIVIDUAL", label: "Individual" });

  const [institutionInput, setInstitutionInput] = useState("");

  if (acatInstitutions.isLoading) {
    return (
      <div className="Dashboard_Acats_pages__container">
        <div className="Dashboard_Acats_pages__content_container">Loading</div>
      </div>
    );
  }

  if (acatInstitutions.hasError) {
    return (
      <div className="Dashboard_Acats_pages__container">
        <div className="Dashboard_Acats_pages__content_container">Error</div>
      </div>
    );
  }

  const institution = acatInstitutions.data.institutions.find(
    (e) => e.institutionCode === institutionCode
  );

  const filteredInstitutionList = searchList(
    acatInstitutions.data.institutions,
    institutionInput
  );

  if (institutionCode !== null && institution === undefined) {
    return (
      <div className="Dashboard_Acats_pages__container">
        <div className="Dashboard_Acats_pages__content_container">
          Not found
        </div>
      </div>
    );
  }

  const onContinue = (): void => {
    if (institutionCode === null) {
      alert("Institution code missing");
      return;
    }

    const { setCurScreen: _, screen: _s, ...screenInfo } = props;

    if ("iraType" in screenInfo) {
      props.setCurScreen({
        screen: "IRA/accountInformation",
        acatsInstitution: {
          type: "code",
          acatsInstitutionCode: institutionCode,
        },
        acatsAccountType: acatsAccountType.type,
        ...screenInfo,
      });
    } else {
      props.setCurScreen({
        screen: "Investment/accountInformation",
        acatsInstitution: {
          type: "code",
          acatsInstitutionCode: institutionCode,
        },
        acatsAccountType: acatsAccountType.type,
        ...screenInfo,
      });
    }
  };

  return (
    <div className="Dashboard_Acats_pages__container">
      <div className="Dashboard_Acats_pages__content_container">
        <p className="Dashboard_Acats_pages__title">
          Where is your account located?
        </p>

        <p className="Dashboard_Acats_pages__summary">
          {props.transferType === "transfer"
            ? "Great, it appears you're looking to transfer some assets. Please choose the managing institution from the search field and select the account type."
            : `Great, it appears you're looking to roll over your ${props.transferType}. Please choose the institution that manages it in the search field.`}
        </p>

        {props.transferType === "transfer" && (
          <div className="Dashboard_Acats_pages__dropdownDiv">
            <div className="Dashboard_Acats_pages__dropdownHeader">
              Account Type
            </div>

            <SAODropdown
              disableSearch={true}
              value={{
                item: acatsAccountType.type,
                label: acatsAccountType.label,
              }}
              values={accountTypes.map((acctType) => {
                const val: AccountTypeLabel =
                  acctType === "INDIVIDUAL"
                    ? "Individual"
                    : acctType === "JOINT"
                    ? "Joint"
                    : "Trust";

                return {
                  item: acctType,
                  label: val,
                };
              })}
              onChange={(e) =>
                setAcatsAccountType({
                  type: e.item,
                  label: e.label,
                })
              }
              style={{ width: "100%" }}
            />
          </div>
        )}

        <div className="Dashboard_Acats_pages__dropdownDiv">
          <div className="Dashboard_Acats_pages__dropdownHeader">
            Institution
          </div>

          <SAODropdown
            value={{
              item: institutionCode,
              label: institutionInput,
            }}
            values={filteredInstitutionList.map((ins) => ({
              item: ins.institutionCode,
              label: ins.displayName,
            }))}
            onChange={(e) => {
              setInstitutionCode(e.item);
              setInstitutionInput(e.label);
            }}
            style={{ width: "100%" }}
          />
        </div>

        <div className="Dashboard_Acats_pages__flex_start_div">
          <p
            className="Dashboard_Acats_pages__yellow_text"
            onClick={() => {
              const { setCurScreen: _, screen: _s, ...screenInfo } = props;

              if ("iraType" in screenInfo) {
                props.setCurScreen({
                  screen: "IRA/missingInstitution",
                  ...screenInfo,
                });
              } else {
                props.setCurScreen({
                  screen: "Investment/missingInstitution",
                  ...screenInfo,
                });
              }
            }}
          >
            Don't see your institution? Click here.
          </p>
        </div>

        <div className="Dashboard_Acats_pages__button_container_2">
          <div
            className="Dashboard_Acats_pages__back_button_container"
            onClick={() =>
              props.setCurScreen(
                props.transferType === "transfer"
                  ? { screen: "transferType" }
                  : { screen: "IRA/TypeSelection" }
              )
            }
          >
            <img src={leftArrow} />
            <div className="Dashboard_Acats_pages__back_button">Back</div>
          </div>

          <ButtonPrimary
            disabled={institutionCode === null}
            variant={"contained"}
            buttonType={"primary"}
            text={"Continue"}
            onClick={() => onContinue()}
          />
        </div>
      </div>
    </div>
  );
};

export default InstitutionSelection;
