import { useEffect } from "react";

import { useSelector } from "react-redux";

import {
  useAppearanceChanger,
  useTheme,
} from "@fartherfinance/frontend/theme/ThemeProvider";

import { State } from "@src/store";

function useSyncTheme() {
  const changeAppearance = useAppearanceChanger();
  const { selectedTheme } = useSelector((state: State) => ({
    selectedTheme: state.main_Reducer.ui_theme,
  }));

  useEffect(() => {
    switch (selectedTheme) {
      case "farther_dark":
        changeAppearance("dark");
        break;

      case "farther_light":
        changeAppearance("light");
    }
  }, [changeAppearance, selectedTheme]);

  const { color } = useTheme();

  useEffect(() => {
    Object.entries(color).forEach(([key, value]) => {
      document.documentElement.style.setProperty(
        `--${key.replace(/^\$/, "")}`,
        value
      );
    });
  }, [color]);
}

export default useSyncTheme;
