import React, { useCallback, useMemo, useState } from "react";

import { orderBy } from "lodash";

import useSecuritySearchByTicker from "@fartherfinance/frontend/api/SecurityMaster/hooks/useSecuritySearchByTicker";
import { Security } from "@fartherfinance/frontend/api/SecurityMaster/requests/Types";
import { SecurityId, Ticker } from "@fartherfinance/frontend/api/Types";

import { isTickerIdentifier } from "../utils";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import BorderBoxBody from "@src/sharedComponents/BorderBox/BorderBoxBody";
import SearchSecurity from "@src/sharedComponents/SearchSecurity/SearchSecurity";

import SecuritySearchTableRow from "./SecuritySearchTableRow";

import styles from "./SecuritySearchTable.module.css";

type SecuritySearch = Security[][number];

interface Search extends SecuritySearch {
  ticker: Ticker;
  securityId: SecurityId;
  description: string;
  label: string;
}

interface SelectedEquivalentSecurity {
  securityId: SecurityId;
  description: string;
  ticker: Ticker;
}

interface Props {
  selectedSecurities: SelectedEquivalentSecurity[];
  addSecurity: (sec: SelectedEquivalentSecurity) => void;
  removeSecurity: (ticker: SecurityId) => void;
}

const SecuritySearchTable: React.FC<Props> = ({
  addSecurity,
  removeSecurity,
  selectedSecurities,
}) => {
  const [throttleTimeout, setThrottleTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const [input, setInput] = useState<string | null>(null);

  const [inputForSearchRequest, setInputForSearchRequest] = useState<
    string | null
  >(null);

  const throttledSetInput = useCallback(
    (searchTerm: string) => {
      if (throttleTimeout !== null) {
        clearTimeout(throttleTimeout);
      }

      // when you select an option from the dropdown menu (instead of typing in the search bar) it triggers the onInputChange for the selected search result option (in SearchSecurity),
      // this fires handleSearch (below) which will cause another network request - we don't want to fire a network request when selecting an option so check if searchTerm
      // contains " - " = we know it was an option label not a searched for ticker
      const searchResultOption = searchTerm.includes(" - "); // search result options will be of the form: 'ticker - description'
      if (searchTerm && !searchResultOption) {
        const to = setTimeout(() => {
          setInputForSearchRequest(searchTerm);
        }, 800);

        setThrottleTimeout(to);
      }
    },
    [throttleTimeout]
  );

  const addSelectedSecurity = useCallback(
    (sec: SelectedEquivalentSecurity) => {
      addSecurity(sec);
      setInput(null);
      setInputForSearchRequest(null);
    },
    [addSecurity]
  );

  const auth = useAdvisorRequestAuth();

  const validSearchInput =
    inputForSearchRequest === null || inputForSearchRequest.length <= 1
      ? null
      : inputForSearchRequest;

  const searchResults = useSecuritySearchByTicker(validSearchInput, auth);

  const selectedSecuritiesOrderedAlphaAsc = useMemo(
    () => orderBy(selectedSecurities, [(s) => s.ticker.toLowerCase()], ["asc"]),
    [selectedSecurities]
  );

  const options = useMemo(() => {
    // filter for active tickers
    const filteredResults = (searchResults.data ?? []).filter(
      (res) =>
        res.state === "Active" &&
        res.identifiers.some((idr) => idr.registrar === "TICKER")
    );

    return filteredResults.map((res) => {
      const tickerIdentifier = res.identifiers.find(
        (idr) => idr.registrar === "TICKER"
      );
      if (
        tickerIdentifier === undefined ||
        !isTickerIdentifier(tickerIdentifier)
      ) {
        throw new Error(
          `No ticker identifier for: ${res.name ?? "No Name Available"}, id: ${
            res.id
          }`
        );
      }

      return {
        ...res,
        ticker: tickerIdentifier.identifier,
        securityId: tickerIdentifier.securityId,
        description: tickerIdentifier.description ?? "",
        label: `${tickerIdentifier.identifier} - ${
          tickerIdentifier.description ?? ""
        }`,
      };
    });
  }, [searchResults]);

  return (
    <div className={styles.container}>
      <div className={styles.searchSecurityContainer}>
        <SearchSecurity<Search>
          searchInput={input}
          onChange={addSelectedSecurity}
          handleSearch={(term: string) => {
            const searchTerm = term.trim().toUpperCase();
            setInput(searchTerm);
            throttledSetInput(searchTerm);
          }}
          options={options}
          isLoading={searchResults.isLoading}
        />
      </div>

      <div className={styles.header}>Ticker</div>

      <BorderBoxBody className={styles.borderBoxBody}>
        {selectedSecuritiesOrderedAlphaAsc.map((sec) => {
          return (
            <SecuritySearchTableRow
              key={sec.securityId}
              securityId={sec.securityId}
              ticker={sec.ticker}
              description={sec.description}
              removeSecurity={removeSecurity}
            />
          );
        })}
      </BorderBoxBody>
    </div>
  );
};

export default SecuritySearchTable;
