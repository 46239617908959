import React from "react";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./HeaderWithSubHeader.module.css";

interface Props {
  header: string;
  subHeader: string;
  className?: string;
  headerClassName?: string;
  subHeaderClassName?: string;
}

const HeaderWithSubHeader: React.FC<Props> = ({
  header,
  subHeader,
  className,
  headerClassName,
  subHeaderClassName,
}) => {
  return (
    <div className={toClassName(styles.container, className)}>
      <div className={toClassName(styles.header, headerClassName)}>
        {header}
      </div>

      <div className={toClassName(styles.subHeader, subHeaderClassName)}>
        {subHeader}
      </div>
    </div>
  );
};

export default HeaderWithSubHeader;
