import React from "react";

import Stack from "@mui/material/Stack";

import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import { useStyles } from "./UserAccountsSkeleton.styles";

export const UserAccountsSkeleton: React.FC = () => {
  const { classes } = useStyles();

  return (
    <Stack>
      <Stack direction="row" alignItems="center">
        <Skeleton
          className={classes.avatar}
          variant="circular"
          width={30}
          height={30}
        />
        <Skeleton variant="text" height={20} width={200} />
      </Stack>

      <Skeleton className={classes.body} variant="rounded" height={190} />
    </Stack>
  );
};
