import React from "react";

import styles from "./Slider.module.css";

interface Props {
  value: number;
  max: number;
}

const Slider = (props: Props): JSX.Element => {
  const percent =
    props.max === 0
      ? 0
      : props.value >= props.max
      ? 100
      : (props.value / props.max) * 100;

  return (
    <div className={styles.container}>
      <div className={styles.bar} style={{ width: `${percent}%` }} />
    </div>
  );
};

export default Slider;
