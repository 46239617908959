import { getYear } from "date-fns";

import useGetTaxBudgets from "@fartherfinance/frontend/api/InvestmentModel/hooks/useGetTaxBudgets";
import { TaxBudget } from "@fartherfinance/frontend/api/InvestmentModel/Types";
import type { HookResult } from "@fartherfinance/frontend/api/Types";

import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

export type TaxBudgetData = {
  budgets: TaxBudget[];
  currentBudget?: TaxBudget;
  futureBudgets: TaxBudget[];
  hasBudgets: boolean;
  includesSharedAccounts: boolean;
};

const useTaxBudgets = (): HookResult<TaxBudgetData> => {
  const auth = useRequestAuth();

  const budgetsResponse = useGetTaxBudgets(auth);

  if (budgetsResponse.isLoading || budgetsResponse.hasError) {
    return budgetsResponse;
  }

  const budgets = budgetsResponse.data || [];

  const currentBudget = budgets.find(
    (budget) => budget.year === getYear(new Date())
  );

  const futureBudgets = budgets
    .filter((budget) => getYear(new Date()) < budget.year)
    .sort((a, b) => a.year - b.year);

  const hasBudgets = Boolean(currentBudget) || futureBudgets.length > 0;

  const includesSharedAccounts = budgets.some(
    (budget) => budget.clientIds.length > 1
  );

  return {
    data: {
      budgets,
      currentBudget,
      futureBudgets,
      hasBudgets,
      includesSharedAccounts,
    },
    isLoading: false,
    hasError: false,
  };
};

export default useTaxBudgets;
