import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";

import { debounce } from "lodash";
import { matchPath, useLocation } from "react-router-dom";

import SideNav from "../../SideNav/SideNav";
import {
  AltAssetsMarketplacePath,
  InsuranceMarketplacePath,
  LendingMarketplacePath,
} from "@src/config/routing/RouterPaths";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./BaseLayout.module.css";

const BaseLayoutContext = createContext<HTMLDivElement | null>(null);

interface Props {
  showSideNav?: boolean;
}

const scrollRestorationPaths = [
  {
    key: "InsuranceMarketplaceScrollClient",
    path: `/Client/:clientId/${InsuranceMarketplacePath}/:offeringType`,
  },
  {
    key: "InsuranceMarketplaceScrollAdvisor",
    path: `/Advisor/${InsuranceMarketplacePath}/:offeringType`,
  },
  {
    key: "LendingMarketplaceScrollClient",
    path: `/Client/:clientId/${LendingMarketplacePath}/:offeringType`,
  },
  {
    key: "LendingMarketplaceScrollAdvisor",
    path: `/Advisor/${LendingMarketplacePath}/:offeringType`,
  },
  {
    key: "AltAssetMarketplaceScrollClient",
    path: `/Client/:clientId/${AltAssetsMarketplacePath}/:offeringType`,
  },
  {
    key: "AltAssetMarketplaceScrollAdvisor",
    path: `/Advisor/${AltAssetsMarketplacePath}/:offeringType`,
  },
];

const BaseLayout: React.FC<PropsWithChildren<Props>> = ({
  children,
  showSideNav = true,
}) => {
  const contentElementRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const scrollRestorationPath = scrollRestorationPaths.find(({ path }) =>
    matchPath(location.pathname, {
      path,
    })
  );

  const handleElementScroll = debounce((scrollTop) => {
    if (scrollRestorationPath) {
      sessionStorage.setItem(scrollRestorationPath.key, scrollTop);
    }
  }, 200);

  useLayoutEffect(() => {
    if (scrollRestorationPath) {
      contentElementRef.current?.scrollTo({
        top: Number(sessionStorage.getItem(scrollRestorationPath.key)),
      });
    }

    return () => {
      if (!scrollRestorationPath) {
        scrollRestorationPaths.forEach((scrollRestorationPath) => {
          sessionStorage.removeItem(scrollRestorationPath.key);
        });
      }
    };
  });

  return (
    <div className={styles.container}>
      {/* If this proves problematic, consider using display: none on css */}
      {showSideNav && <SideNav />}

      <div
        className={toClassName({
          [styles.contentWithSideNav]: showSideNav,
          [styles.contentWithoutSideNav]: !showSideNav,
        })}
        ref={contentElementRef}
        onScroll={(event) => handleElementScroll(event.currentTarget.scrollTop)}
      >
        <BaseLayoutContext.Provider value={contentElementRef.current}>
          {children}
        </BaseLayoutContext.Provider>
      </div>
    </div>
  );
};

export const useBaseLayoutRef = () => useContext(BaseLayoutContext);

export default BaseLayout;
