import React from "react";

import { Box, Popover, PopoverProps, Typography } from "@mui/material";

import {
  Feed,
  FeedStatus,
} from "@fartherfinance/frontend/api/Notifications/Types";
import { FeedId } from "@fartherfinance/frontend/api/Types";

import Notification from "../Notification/Notification";
import InfiniteScroll from "@src/sharedComponents/InfiniteScroll/InfiniteScroll";
import Message from "@src/sharedComponents/Message/Message";

import styles from "./NotificationsArea.module.css";

type NotificationsAreaMenuProps = Pick<
  PopoverProps,
  "anchorEl" | "open" | "onClose"
>;

type NotificationsAreaProps = NotificationsAreaMenuProps & {
  feeds: Feed[];
  isEmpty: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  hasMore: boolean;
  onLoadMore: () => void;
  onMarkAllAsRead: () => void;
  onArchive: (feedId: FeedId) => void;
  onNotificationStatusToggle: (feedId: FeedId, newStatus: FeedStatus) => void;
  onNotificationClick: (feed: Feed) => void;
};

export default function NotificationsArea({
  // Menu props
  open,
  anchorEl,
  onClose,
  // Own props
  feeds,
  isEmpty,
  isLoading,
  isLoadingMore,
  hasMore,
  onLoadMore,
  onMarkAllAsRead,
  onArchive,
  onNotificationStatusToggle,
  onNotificationClick,
}: NotificationsAreaProps): JSX.Element {
  const handleScrollIntersection = (
    entries: IntersectionObserverEntry[]
  ): void => {
    const [entry] = entries;

    if (entry.isIntersecting && hasMore && !isLoadingMore) {
      onLoadMore();
    }
  };

  return (
    <Popover
      classes={{ paper: styles.popoverPaper }}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        elevation: 0,
      }}
    >
      <Box className={styles.header}>
        <Typography className={styles.headerText}>Notifications</Typography>
        {!isLoading && !isEmpty && (
          <Typography className={styles.headerAction} onClick={onMarkAllAsRead}>
            Mark all as read
          </Typography>
        )}
      </Box>

      <Box className={styles.content}>
        {isLoading && (
          <>
            {Array.from({ length: 6 }).map((_, index) => (
              <Notification key={index} isLoading={true} />
            ))}
          </>
        )}
        {isEmpty && !isLoading && <Message text="Notification list is empty" />}
        {!isEmpty && !isLoading && (
          <InfiniteScroll
            className={styles.infiniteScrollContainer}
            onIntersection={handleScrollIntersection}
            intersectionThreshold={2}
          >
            {feeds.map((feed) => (
              <Notification
                key={feed.feedId}
                notificationId={feed.feedId}
                message={feed.data.body}
                topic={feed.data.subject}
                isUnseen={feed.status === FeedStatus.Values.UNREAD}
                isLoading={false}
                createdAt={feed.createdTs}
                onArchive={onArchive}
                onStatusToggle={onNotificationStatusToggle}
                onClick={() => onNotificationClick(feed)}
              />
            ))}
            {isLoadingMore && (
              <>
                {Array.from({ length: 3 }).map((_, index) => (
                  <Notification key={index} isLoading={true} />
                ))}
              </>
            )}
          </InfiniteScroll>
        )}
      </Box>
    </Popover>
  );
}
