import { useEffect, useState } from "react";

import { isEmpty } from "lodash";

import useAdvisorMarketplaceOfferings from "@fartherfinance/frontend/api/AltAssetsService/hooks/useAdvisorMarketplaceOfferings";
import useClientMarketplaceOfferings from "@fartherfinance/frontend/api/AltAssetsService/hooks/useClientMarketplaceOfferings";
import { GetMarketplaceOfferingsQueryParams } from "@fartherfinance/frontend/api/AltAssetsService/requests/getClientMarketplaceOfferings";
import {
  MarketplaceAssetType,
  MarketplaceOfferingType,
} from "@fartherfinance/frontend/api/AltAssetsService/Types";
import { PaginationHeaders } from "@fartherfinance/frontend/api/utils/parsePaginationHeaders";

import { usePersistedQueryParams } from "@src/multiCustodian/hooks/Advisor/usePersistedQueryParams";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

/**
 * @deprecated v2 available
 */
const useOfferingsListData = (
  type: "client" | "advisor",
  assetType: MarketplaceAssetType,
  offeringType: MarketplaceOfferingType
) => {
  const [pagination, setPagination] = useState<PaginationHeaders>();
  const [queryParams, setQueryParams] =
    usePersistedQueryParams<GetMarketplaceOfferingsQueryParams>({
      page: 1,
      pageSize: 10,
      filterByAssetType: [assetType],
      filterByOfferingType: [offeringType],
    });

  const clientAuth = useRequestAuth();
  const advisorAuth = useAdvisorRequestAuth();

  const clientAuthOrNull =
    isEmpty(queryParams) || type !== "client" ? null : clientAuth;
  const advisorAuthOrNull =
    isEmpty(queryParams) || type !== "advisor" ? null : advisorAuth;

  const clientMarketplaceOfferings = useClientMarketplaceOfferings(
    queryParams,
    clientAuthOrNull
  );
  const advisorMarketplaceOfferings = useAdvisorMarketplaceOfferings(
    queryParams,
    advisorAuthOrNull
  );
  const { data, isLoading, hasError } =
    type === "client"
      ? clientMarketplaceOfferings
      : advisorMarketplaceOfferings;

  const { values, pagination: paginationInternal } = data ?? {};

  const handleOnPageChange = (page: number): void => {
    setQueryParams({ ...queryParams, page });
  };

  useEffect(() => {
    // NOTE: we have to persist pagination values locally as they become undefined inbetween request
    if (paginationInternal) {
      setPagination(paginationInternal);
    }
  }, [paginationInternal]);

  return {
    offerings: values ?? [],
    pagination,
    isLoading,
    hasError,
    handleOnPageChange,
  };
};

export default useOfferingsListData;
