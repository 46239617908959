import { useCallback } from "react";

import { useHistory, useLocation } from "react-router-dom";

import useRevokeStytchSession from "@fartherfinance/frontend/api/Identity/hooks/useRevokeStytchSession";

import { LocalStorageKeys } from "../components/Login/constants";

import useAdvisorRequestAuth from "./useAdvisorRequestAuth";
import useRequestAuth from "./useRequestAuth";

export default function useNavigateOnLogin() {
  const history = useHistory();

  const clientAuth = useRequestAuth();

  const advisorAuth = useAdvisorRequestAuth();

  const { search, state } = useLocation<{
    from: Location | undefined;
  }>();

  const auth = clientAuth ?? advisorAuth;

  const revokeStytchSession = useRevokeStytchSession();

  return useCallback(
    (personType: "Advisor" | "Client") => {
      console.log("called useNavigateOnLogin");
      // If we came here with a "from" url then we want to not keep the login in the history list
      if (
        typeof state === "object" &&
        state !== null &&
        "from" in state &&
        state.from !== undefined
      ) {
        return history.replace(state.from);
      }

      // The stytch session is no longer needed at this point and can be revoked
      const sessionToken = localStorage.getItem(LocalStorageKeys.sessionToken);

      if (sessionToken !== null && auth !== null) {
        revokeStytchSession(sessionToken, auth);

        localStorage.removeItem(LocalStorageKeys.sessionToken);
      }

      const qs = new URLSearchParams(search);
      const requestedURL = qs.get("goto");

      if (requestedURL !== null) {
        // If we came here with a "goto" url then we want to not keep the login in the history list
        return history.replace(requestedURL);
      }

      const redirectTo = localStorage.getItem(LocalStorageKeys.redirectTo);

      if (redirectTo !== null) {
        localStorage.removeItem(LocalStorageKeys.redirectTo);

        history.push(redirectTo);

        return;
      }

      if (personType === "Advisor") {
        if (location.pathname.startsWith("/Advisor/")) {
          // do not redirect to the advisor dashboard if we are already on an advisor page
        } else if (location.pathname.startsWith("/Client/")) {
          // do not redirect to the advisor dashboard if we are already on a client page
        } else {
          // Default is to go to the advisor dashboard
          history.push("/Advisor");
        }
        return;
      }

      if (location.pathname.startsWith("/Client/")) {
        // do not redirect to the client dashboard if we are already on a client page
      } else {
        // Default is to go to the advisor dashboard
        history.push("/Client");
      }
    },
    [auth, history, revokeStytchSession, search, state]
  );
}
