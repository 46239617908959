import React, { useEffect, useMemo, useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { sub } from "date-fns";
import { isNil } from "lodash";
import { FormProvider, useForm, useFormState, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";

import useClientBeneficiaries from "@fartherfinance/frontend/api/Entity/hooks/useClientBeneficiaries";
import useDeleteClientBeneficiary from "@fartherfinance/frontend/api/Entity/hooks/useDeleteClientBeneficiary";
import usePostClientBeneficiary from "@fartherfinance/frontend/api/Entity/hooks/usePostClientBeneficiary";
import usePutClientBeneficiary from "@fartherfinance/frontend/api/Entity/hooks/usePutClientBeneficiary";
import useUpdateClientChecklist from "@fartherfinance/frontend/api/Entity/hooks/useUpdateClientChecklist";
import { Beneficiary } from "@fartherfinance/frontend/api/Entity/requests/getClientBeneficiaries";
import { BeneficiaryId, USAStates } from "@fartherfinance/frontend/api/Types";

import FormFooter from "../components/FormFooter/FormFooter";
import formatSSN from "../formatters/formatSSN";
import has5Digits from "../validators/has5Digits";
import isUnder100 from "../validators/isUnder100";
import isValidSSN from "../validators/isValidSSN";
import onlyContainsLetters from "../validators/onlyContainsLetters";
import onlyLettersAndNumbers from "../validators/onlyLettersAndNumbers";
import { relationshipOptions } from "@src/constants/relationship";
import AlertMessageModal from "@src/multiCustodian/components/Modals/AlertMessageModal";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import Portal from "@src/multiCustodian/components/Portal/Portal";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormDateField from "@src/sharedComponents/Forms/FormDateField";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import FormNumberField from "@src/sharedComponents/Forms/FormNumberField";
import FormSubHeader from "@src/sharedComponents/Forms/FormSubHeader";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import Dropdown from "@src/sharedComponents/SAODropdown/Dropdown";
import { State } from "@src/store";

import { Form } from "./Types";
import mapDefaultValues from "./utils/mapDefaultValues";
import mapValuesToSubmitForm from "./utils/mapValuesToSubmitForm";

import styles from "./BeneficiaryForm.module.css";
import sharedStyles from "../SharedFormStyle.module.css";

const emptyForm: Form = {
  Email: null,
  FirstName: null,
  LastName: null,
  Relationship: null,
  DateOfBirth: null,
  TaxID: null,
  Address1: null,
  Address2: null,
  City: null,
  State: null,
  ZipCode: null,
};

interface Props {
  drawerFooterPortalId: string;
  onClose: () => void;
}

export default function BeneficiaryFormFetcher(props: Props): JSX.Element {
  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));

  const auth = useRequestAuth();

  const beneficiaries = useClientBeneficiaries(clientId, auth);

  if (beneficiaries.isLoading) {
    return (
      <div className={sharedStyles.loading}>
        <LogoLoadingStill />
      </div>
    );
  }

  if (beneficiaries.hasError) {
    return <div>Error</div>;
  }

  return (
    <BeneficiaryForm
      drawerFooterPortalId={props.drawerFooterPortalId}
      beneficiaries={beneficiaries.data}
      onClose={props.onClose}
    />
  );
}

interface BeneficiaryFormProps {
  drawerFooterPortalId: string;
  beneficiaries: Beneficiary[];
  onClose: () => void;
}

function BeneficiaryForm(props: BeneficiaryFormProps): JSX.Element {
  const { clientId, isAdvisor } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
    isAdvisor: state.main_Reducer.user.isAdvisor,
  }));

  // If we have beneficiaries already, show the first one
  const [selectedBeneficiaryId, setSelectedBeneficiaryId] =
    useState<BeneficiaryId | null>(
      props.beneficiaries[0]?.beneficiaryId ?? null
    );

  const auth = useRequestAuth();

  const callPostClientBeneficiary = usePostClientBeneficiary(clientId, auth);

  const callPutClientBeneficiary = usePutClientBeneficiary(clientId, auth);

  const callDeleteClientBeneficiary = useDeleteClientBeneficiary(auth);

  const callUpdateClientChecklist = useUpdateClientChecklist(clientId, auth);

  const [isMutating, setIsMutating] = useState(false);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const validateWholeForm = !isAdvisor;

  const selectedBeneficiary = useMemo(() => {
    return props.beneficiaries.find((e) => {
      return e.beneficiaryId === selectedBeneficiaryId;
    });
  }, [props.beneficiaries, selectedBeneficiaryId]);

  const firstName = selectedBeneficiary?.name.first;
  const lastName = selectedBeneficiary?.name.last;

  const beneficiaryLabel = useMemo(() => {
    return selectedBeneficiary
      ? {
          label: `${firstName ?? "Unknown"} ${
            lastName ?? "Unknown"
          } (...${selectedBeneficiary.beneficiaryId.slice(-4)})`,
          beneficiaryId: selectedBeneficiary.beneficiaryId,
        }
      : null;
  }, [firstName, lastName, selectedBeneficiary]);

  const methods = useForm<Form>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues:
      selectedBeneficiary !== undefined
        ? mapDefaultValues(selectedBeneficiary)
        : undefined,
  });

  const statusNotification = useStatusNotification();

  const { control, getValues, setValue, reset } = methods;

  const { isValid } = useFormState({ control });

  const [Address1, Address2, City, ZipCode] = useWatch({
    control,
    name: ["Address1", "Address2", "City", "ZipCode"],
  });

  const isStateRequired =
    [Address1, Address2, City, ZipCode].find((e) => !isNil(e) && e !== "") !==
    undefined;

  const isSubmitDisabled = !isValid || isMutating;

  useEffect(() => {
    if (selectedBeneficiary === undefined) {
      reset(emptyForm);
    } else {
      reset(mapDefaultValues(selectedBeneficiary));
    }
  }, [reset, selectedBeneficiary]);

  const removeBeneficiary = async () => {
    if (selectedBeneficiaryId === null) {
      return;
    }

    try {
      setIsMutating(true);

      await callDeleteClientBeneficiary(selectedBeneficiaryId);

      statusNotification("Deleted beneficiary.", "Success");
    } catch {
      statusNotification("Failed to delete beneficiary.", "Error");
    } finally {
      setIsMutating(false);
    }
  };

  const addBeneficiary = () => {
    setSelectedBeneficiaryId(null);
  };

  const noBeneficiariesToAdd = async (): Promise<void> => {
    setIsMutating(true);

    try {
      await callUpdateClientChecklist({
        items: [{ name: "Beneficiaries", status: "Completed" }],
      });
      statusNotification("Chose not to add a beneficiary.", "Success");
      props.onClose();
    } catch {
      statusNotification("Failed to update checklist.", "Error");
    } finally {
      setIsMutating(false);
    }
  };

  const submit = async () => {
    if (isSubmitDisabled) {
      return;
    }

    const formValues = getValues();
    const formToSubmit = mapValuesToSubmitForm(formValues);

    if (formToSubmit === undefined) {
      return;
    }

    try {
      setIsMutating(true);

      if (selectedBeneficiary === undefined) {
        const { beneficiaryId } = await callPostClientBeneficiary(formToSubmit);

        setSelectedBeneficiaryId(beneficiaryId);
      } else {
        await callPutClientBeneficiary({
          beneficiaryId: selectedBeneficiary.beneficiaryId,
          beneficiary: formToSubmit,
        });
      }

      statusNotification("Updated beneficiary.", "Success");

      props.onClose();
    } catch {
      setIsMutating(false);

      statusNotification("Failed to update beneficiary.", "Error");
    }
  };

  const beneficiaryOptions = useMemo(() => {
    return props.beneficiaries.map((e) => ({
      label: `${e.name.first} ${e.name.last} (...${e.beneficiaryId.slice(-4)})`,
      beneficiaryId: e.beneficiaryId,
    }));
  }, [props.beneficiaries]);

  const isRequired = validateWholeForm ? "Must not be empty" : false;

  const clearField = (field: keyof Form): void => {
    setValue(field, null);
  };

  const removeButton =
    selectedBeneficiaryId !== null
      ? () => setShowConfirmModal(true)
      : undefined;

  const nameChecker =
    process.env.WEBAPP_ENV === "PROD"
      ? onlyContainsLetters
      : onlyLettersAndNumbers;

  return (
    <FormProvider {...methods}>
      <FormH1>Provide your beneficiary information</FormH1>

      <FormSubHeader>
        You can add or remove beneficiaries from your profile. Ask your advisor
        to assign a beneficiary to a specific account.
      </FormSubHeader>

      <FlexWrapper>
        <Dropdown
          value={beneficiaryLabel}
          onChange={(e) => {
            setSelectedBeneficiaryId(e.beneficiaryId);
          }}
          placeholder={
            props.beneficiaries.length > 1
              ? `${props.beneficiaries.length} beneficiaries added`
              : props.beneficiaries.length > 0
              ? `${props.beneficiaries.length} beneficiary added`
              : ""
          }
          formLabel=""
          values={beneficiaryOptions}
          onClear={() => setSelectedBeneficiaryId(null)}
        />
        <div className={styles.buttonContainer}>
          <div
            className={styles.button}
            onClick={() =>
              removeButton !== undefined ? removeButton() : undefined
            }
            style={{
              opacity: removeButton === undefined ? 0.5 : 1.0,
              cursor: removeButton === undefined ? "not-allowed" : "pointer",
            }}
          >
            <div>Remove</div>
            <RemoveCircleIcon />
          </div>
          <div
            className={styles.button}
            onClick={() =>
              selectedBeneficiaryId === null ? undefined : addBeneficiary()
            }
            style={{
              opacity: selectedBeneficiaryId === null ? 0.5 : 1.0,
              cursor:
                selectedBeneficiaryId === null ? "not-allowed" : "pointer",
            }}
          >
            <div>Add</div>
            <AddCircleIcon />
          </div>
        </div>
      </FlexWrapper>

      <FlexWrapper>
        <FormTextField
          name="FirstName"
          label="First Name"
          required={"Must not be empty"}
          rules={{
            validate: {
              nameChecker: (e) => nameChecker(e, "first", true),
            },
          }}
        />

        <FormTextField
          name="LastName"
          label="Last Name"
          required={"Must not be empty"}
          rules={{
            validate: {
              nameChecker: (e) => nameChecker(e, "last", true),
            },
          }}
        />

        <FormDropdownField
          name="Relationship"
          label="Relationship to Beneficiary"
          values={relationshipOptions.map((e) => ({ label: e }))}
          required={"Must not be empty"}
          onClear={() => clearField("Relationship")}
        />

        <FormDateField
          name="DateOfBirth"
          label="Date of Birth"
          required={"Must not be empty"}
          placeholder="MM/DD/YYYY"
          maxDate={new Date()}
          minDate={sub(new Date(), { years: 100 })}
          rules={{
            validate: {
              isUnder100,
            },
          }}
        />

        <FormTextField
          name="TaxID"
          label="SSN (or ITIN)"
          required={isRequired}
          rules={{
            validate: {
              isValidSSN: (e) => isValidSSN(e, validateWholeForm),
            },
          }}
          valueFormatterOnChange={(e) => {
            const prevTaxId = getValues("TaxID");

            return !isNil(prevTaxId) ? formatSSN(prevTaxId, e) : e;
          }}
          valueFormatterOnBlur={(e) => {
            const prevTaxId = getValues("TaxID");

            return !isNil(prevTaxId) ? formatSSN(prevTaxId, e) : e;
          }}
        />

        <FormTextField name="Email" label="Email (Optional)" required={false} />

        <FormTextField name="Address1" label="Address" required={false} />

        <FormTextField
          name="Address2"
          label="Address 2 (Optional)"
          required={false}
        />

        <FormTextField
          name="City"
          label="City"
          required={false}
          rules={{
            minLength: {
              value: 3,
              message: "City must have 3 characters or more.",
            },
          }}
        />

        <FormDropdownField
          name="State"
          label="State"
          values={USAStates.map((e) => ({ label: e }))}
          required={isStateRequired ? "Must not be empty" : false}
          onClear={() => clearField("State")}
        />

        <FormNumberField
          name="ZipCode"
          label="Zip Code"
          required={false}
          rules={{
            validate: {
              has5Digits: (e) => has5Digits(e, false),
            },
          }}
        />
      </FlexWrapper>

      <Portal targetNodeId={props.drawerFooterPortalId}>
        <FormFooter isSubmitDisabled={isSubmitDisabled} handleSubmit={submit}>
          <ButtonPrimary
            variant="contained"
            buttonType="primary"
            disabled={props.beneficiaries.length > 0}
            text="No Beneficiaries to Add"
            onClick={noBeneficiariesToAdd}
            style={{ marginRight: "15px" }}
          />
        </FormFooter>
      </Portal>

      {isMutating && (
        <div className={sharedStyles.loading}>
          <LogoLoadingStill />
        </div>
      )}

      {showConfirmModal && (
        <AlertMessageModal
          top_text={() => (
            <div>
              <p>
                Are you sure you want to <br /> remove this beneficiary <br />
                <span>{`${firstName ?? "Unknown"} ${
                  lastName ?? "Unknown"
                }`}</span>
                ?
              </p>
            </div>
          )}
          button_blue_text={"No"}
          button_blue_onClick={() => setShowConfirmModal(false)}
          button_yellow_text={"Yes"}
          button_yellow_onClick={() => {
            removeBeneficiary();
            setShowConfirmModal(false);
          }}
        />
      )}
    </FormProvider>
  );
}
