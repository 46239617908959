import { AssetList } from "../Types";

const fartherAssets: AssetList = {
  dark: {
    logoSymbol: "/src/assets/svg/logo_symbol_cream.svg",
    logoWordmark: "/src/assets/svg/logo_wordmark_cream.svg",
  },
  light: {
    logoSymbol: "/src/assets/svg/logo_symbol_navy.svg",
    logoWordmark: "/src/assets/svg/logo_wordmark_navy.svg",
  },
} as const;

export default fartherAssets;
