/* eslint-disable react/prop-types */

import React, { Component } from "react";

import { Plaid } from "plaid-link";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import linkPlaidAccount from "@fartherfinance/frontend/api/ExternalAccount/requests/plaidCreateLink";
import relinkPlaidAccount from "@fartherfinance/frontend/api/ExternalAccount/requests/plaidRelink";
import { LinkId } from "@fartherfinance/frontend/api/ExternalAccount/requests/types";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import execute_statusNotification_ts from "../multiCustodian/components/GlobalStatusNotification/notification_ts";
import isLocalStorageAvailable from "@src/multiCustodian/utils/isLocalStorageAvailable";
import { State } from "@src/store";

type ReduxProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type Props = ReduxProps;

interface ComponentState {
  link_token: null | string;
  redirectUri: null | string;
  //
  id_user: null | ClientId;
  farther_jwt: null | string;
  is_relinking: null | string;
  link_id: null | LinkId;
  //
  shouldRedirect: boolean;
}

class PlaidOAuth extends Component<Props, ComponentState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      link_token: null,
      redirectUri: null,
      //
      id_user: null,
      farther_jwt: null,
      is_relinking: null,
      link_id: null,
      //
      shouldRedirect: false,
    };
  }

  componentDidMount() {
    if (!isLocalStorageAvailable()) {
      return;
    }

    const link_token = localStorage.getItem("link_token");
    const id_user = localStorage.getItem("id_user") as ClientId;
    const farther_jwt = localStorage.getItem("farther_jwt");
    const is_relinking = localStorage.getItem("is_relinking");
    const link_id = localStorage.getItem("link_id");
    if (link_token && id_user && farther_jwt) {
      const redirectUri = window.location.href;
      localStorage.removeItem("link_token");
      localStorage.removeItem("id_user");
      localStorage.removeItem("farther_jwt");
      localStorage.removeItem("is_relinking");
      localStorage.removeItem("access_token");
      localStorage.removeItem("link_id");
      this.setState(
        {
          link_token,
          id_user,
          redirectUri,
          farther_jwt,
          is_relinking,
          link_id: link_id as LinkId,
        },
        () => this.reinitializeToken()
      );
    }
  }

  reinitializeToken = () => {
    const onSuccess: Plaid.OnSuccess = (
      public_token: string,
      metadata: Plaid.OnSuccessMetaData
    ) => {
      this.onSubmit(metadata, public_token);
    };

    const onExit: Plaid.OnExit = () => {
      return undefined;
    };

    const onEvent: Plaid.OnEvent = (eventName: Plaid.EventName) => {
      if (eventName === "HANDOFF") {
        this.setState({ shouldRedirect: true });
      }
    };

    const configs: Plaid.CreateConfig = {
      token: this.state.link_token ?? undefined,
      receivedRedirectUri: this.state.redirectUri,
      clientName: "Farther",
      countryCodes: ["US"],
      env: process.env.WEBAPP_ENV == "PROD" ? "production" : "sandbox",
      product: ["transactions"],
      language: "en",
      webhook: process.env.PLAID_FARTHER_WEBHOOK_URL,
      onSuccess,
      onEvent,
      onExit,
    };

    const handler = window.Plaid.create(configs);

    handler.open();
  };

  executeSuccessNotification = () => {
    execute_statusNotification_ts(
      "Account linked.",
      "success",
      this.props.main_Reducer.brand.current.images.small_x_icon,
      "/src/assets/svg/check_green.svg"
    );
  };

  onSubmit = (metadata: Plaid.OnSuccessMetaData, public_token: string) => {
    if (
      this.state.id_user === null ||
      this.state.farther_jwt === null ||
      metadata.institution === null
    ) {
      return;
    }

    if (this.state.is_relinking === null) {
      linkPlaidAccount(public_token, {
        clientId: this.state.id_user,
        jwt: this.state.farther_jwt,
      }).then(this.executeSuccessNotification);
    } else {
      if (this.state.link_id === null) {
        return;
      }

      relinkPlaidAccount(this.state.link_id, {
        clientId: this.state.id_user,
        jwt: this.state.farther_jwt,
      }).then(this.executeSuccessNotification);
    }
  };

  render() {
    return <>{this.state.shouldRedirect && <Redirect to={"/Dashboard"} />}</>;
  }
}

const mapStateToProps = (state: State) => {
  return { main_Reducer: state.main_Reducer };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaidOAuth);
