import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  container: {
    paddingBottom: 30,
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));
