import React from "react";

import { useParams } from "react-router-dom";

import { PortfolioId } from "@fartherfinance/frontend/api/Types";

import ModelMarketplace from "../ModelMarketplace";
import ModelDrawer from "@src/multiCustodian/components/Advisor/Investments/ModelMarketplace/ModelDrawer/ModelDrawer";
import ModelSharingTable from "@src/multiCustodian/components/Advisor/Investments/ModelMarketplace/ModelSharingTable/ModelSharingTable";

export default function ModelMarketplaceModelSharing(): JSX.Element {
  const { portfolioId } = useParams<{
    portfolioId: PortfolioId;
  }>();

  return (
    <ModelMarketplace>
      <ModelDrawer curTab="Sharing" portfolioId={portfolioId}>
        <ModelSharingTable portfolioId={portfolioId} />
      </ModelDrawer>
    </ModelMarketplace>
  );
}
