import React from "react";

import { useParams } from "react-router-dom";

import AddCollaboratorSelectResourcesCheckboxStateAndDispatcher from "../../../AddCollaborator/TypeAndAccessLevel/SelectResourcesStateAndDispatcher/CheckboxStateAndDispatcher";
import EditCollaboratorSelectResourcesCheckboxStateAndDispatcher from "../../../EditCollaborator/NameTypeAndAccessLevel/SelectResourcesStateAndDispatcher/CheckboxStateAndDispatcher";

import { CheckboxItem } from "./Types";

import styles from "./CheckboxSection.module.css";

interface Props {
  header: string;
  items: CheckboxItem[];
}

const CheckboxSection: React.FC<Props> = ({ header, items }) => {
  const { collaboratorId } = useParams<{
    collaboratorId: string;
  }>();

  return (
    <div className={styles.container}>
      <div className={styles.header}>{header}</div>

      <div className={styles.grid}>
        {items.map((item) => {
          if (collaboratorId) {
            // These 2 components are  wrappers (around the CheckboxSectionItem component) that know which state and actions to use
            // Both flows share the CheckboxSectionItem component but have different contexts and reducers so it needs to be wrapped by separate components
            // both EditCollaboratorSelectResourcesCheckboxStateAndDispatcher and AddCollaboratorSelectResourcesCheckboxStateAndDispatcher render CheckboxSectionItem
            return (
              <EditCollaboratorSelectResourcesCheckboxStateAndDispatcher
                key={item.resource.resourceId}
                item={item}
              />
            );
          }

          return (
            <AddCollaboratorSelectResourcesCheckboxStateAndDispatcher
              key={item.resource.resourceId}
              item={item}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CheckboxSection;
