import React from "react";

import { useLocation } from "react-router-dom";

import { TaxBudget } from "@fartherfinance/frontend/api/InvestmentModel/Types";

import EmptyStateText from "../EmptyText/EmptyText";
import ListHeading from "../ListHeading/ListHeading";

import FutureTaxBudgetRow from "./Row";

interface Props {
  budgets: TaxBudget[];
}

const FutureBudgets: React.FC<Props> = ({ budgets }) => {
  const { pathname } = useLocation();

  return (
    <div>
      <ListHeading
        variant={pathname.startsWith("/Advisor") ? "secondary" : "primary"}
      >
        Future Budgets
      </ListHeading>

      {budgets.length > 0 &&
        budgets.map((budget) => (
          <FutureTaxBudgetRow key={budget.year} budget={budget} />
        ))}

      {budgets.length === 0 && (
        <EmptyStateText>No future tax budgets set</EmptyStateText>
      )}
    </div>
  );
};

export default FutureBudgets;
