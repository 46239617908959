import axios, { CreateAxiosDefaults } from "axios";
import { stringify } from "query-string";

import { Config } from "@src/yellowstone/config";

import {
  authInterceptor,
  errorInterceptor,
  loggingRequestInterceptor,
  loggingResponseInterceptor,
} from "./interceptors";

const httpClientConfig: CreateAxiosDefaults = {
  headers: {
    "Content-Type": "application/json",
  },
  // NOTE: I've increased the shared default timeout as one of the proxied request takes more than 10s
  // as soon as client info manipulation is done on client-ops-server side we can lower this value
  timeout: 60 * 1000, // 1 minute
  paramsSerializer: {
    // NOTE: do not set `skipEmptyString` option to `true` otherwise we will break some of the queries
    serialize: (params) =>
      stringify(params, { arrayFormat: "bracket-separator", skipNull: true }),
  },
};

export const httpClientV1 = axios.create({
  baseURL: Config.MIDDLEWARE_BASE_URL + "/v1",
  ...httpClientConfig,
});

export const httpClientV2 = axios.create({
  baseURL: Config.MIDDLEWARE_BASE_URL + "/v2",
  ...httpClientConfig,
});

export const httpClientV3 = axios.create({
  baseURL: Config.MIDDLEWARE_BASE_URL + "/v3",
  ...httpClientConfig,
});

[httpClientV1, httpClientV2, httpClientV3].forEach((httpClient) => {
  httpClient.interceptors.request.use(authInterceptor, errorInterceptor);
  httpClient.interceptors.request.use(loggingRequestInterceptor);

  httpClient.interceptors.response.use(
    loggingResponseInterceptor,
    errorInterceptor
  );
});
