import React from "react";

import LoginIcon from "@mui/icons-material/Login";
import Box from "@mui/material/Box";
import MuiDrawer, { DrawerProps as MuiDrawerProps } from "@mui/material/Drawer";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import IconButton from "@src/sharedComponents/IconButton/IconButton";

import styles from "./Drawer.module.css";

interface DrawerProps extends Omit<MuiDrawerProps, "open" | "onClose"> {
  isDrawerOpen: boolean;
  hideCloseIcon?: boolean;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  onClose: () => void;
  container_style?: object;
  transitionDuration?: number;
  className?: string;
}

export const largeDrawer: React.CSSProperties = {
  width: 800,
  maxWidth: "100vw",
};
/* eslint-disable import/no-unused-modules */
export const standardDrawer: React.CSSProperties = {
  width: 600,
  maxWidth: "100vw",
};

const Drawer = ({
  children,
  isDrawerOpen,
  hideCloseIcon,
  header,
  footer,
  onClose,
  className,
  ...props
}: DrawerProps): JSX.Element => {
  const { PaperProps, ...restProps } = props;

  return (
    <MuiDrawer
      anchor="right"
      open={isDrawerOpen}
      onClose={onClose}
      PaperProps={{
        elevation: 0,
        className: styles.paper,
        ...PaperProps,
      }}
      {...restProps}
      transitionDuration={props.transitionDuration ?? 500}
    >
      <div
        className={toClassName(styles.container, className)}
        style={props.container_style ? { ...props.container_style } : {}}
      >
        <div className={styles.header}>
          {header}
          {!hideCloseIcon && (
            <Box sx={{ marginLeft: "auto" }}>
              <IconButton onClick={onClose} IconComponent={LoginIcon} />
            </Box>
          )}
        </div>

        <div className={styles.outerContent}>
          <div className={styles.innerContent}>{children}</div>
        </div>

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </MuiDrawer>
  );
};

export default Drawer;
