import React from "react";

import WarningIcon from "@mui/icons-material/Warning";

import styles from "./CSVError.module.css";
interface Props {
  message: string[];
}

const CSVError = (props: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      <WarningIcon className={styles.iconDanger} />

      <div className={styles.textDanger}>
        {props.message.slice(0, 5).map((m, idx) => (
          <div key={idx}>{m}</div>
        ))}
        {props.message.length > 5 && (
          <div>{`${props.message.length - 5} more errors...`}</div>
        )}
      </div>
    </div>
  );
};

export default CSVError;
