import React from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import styles from "./BackButton.module.css";

interface Props {
  onClick: () => void;
}

export default function BackButton(props: Props): JSX.Element {
  return (
    <div className={styles.container} onClick={props.onClick}>
      <ArrowBackIcon className={styles.icon} />
      <p className={styles.text}>Back</p>
    </div>
  );
}
