import React from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import Tooltip from "./Tooltip";

import styles from "./TooltipBasicIcon.module.css";

type Type = "primary" | "secondary";
interface Props {
  className?: string;
  text: string;
  type?: Type;
  placement?:
    | "top"
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start";
}

const TooltipBasic: React.FC<Props> = ({
  className,
  text,
  type = "primary",
  placement = "top",
}) => {
  return (
    <Tooltip tooltipText={text} placement={placement}>
      <InfoOutlinedIcon
        className={toClassName(
          styles.icon,
          { [styles.iconSecondary]: type === "secondary" },
          className
        )}
      />
    </Tooltip>
  );
};

export default TooltipBasic;
