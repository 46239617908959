import React from "react";

import Checkbox from "@src/sharedComponents/Checkbox/Checkbox";
import HeaderWithSubHeader from "@src/sharedComponents/HeaderWithSubHeader/HeaderWithSubHeader";

import styles from "./CheckboxSection.module.css";

interface Props {
  isChecked: boolean;
  handleToggleCheckbox: (bool: boolean) => void;
  labelTop: string;
  labelBottom: string;
}

const CheckboxSectionItem: React.FC<Props> = ({
  isChecked,
  handleToggleCheckbox,
  labelTop,
  labelBottom,
}) => {
  return (
    <div className={styles.gridCheckboxItem}>
      <Checkbox
        checked={isChecked}
        onChange={handleToggleCheckbox}
        label={
          <HeaderWithSubHeader
            header={labelTop}
            headerClassName={styles.cbLabelHeader}
            subHeader={labelBottom}
            subHeaderClassName={styles.cbLabelSubHeader}
          />
        }
        checkboxStyle={{ marginTop: "2px", marginBottom: "2px" }}
      />
    </div>
  );
};

export default CheckboxSectionItem;
