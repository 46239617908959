import { SharedResource } from "@fartherfinance/frontend/api/Sharing/Types";

import { AccessLevelForDisplay, SharedResourceId } from "../../../Types";
import { Relationship } from "@src/constants/relationship";
import { ServiceProfessionalTypeOption } from "@src/constants/serviceProffesional";

import { actions } from "./actions";

export const pageIndices = [0] as const;
export type PageIndex = typeof pageIndices[number];

export interface EditCollaboratorFlowData {
  firstName: string;
  lastName: string;
  collaboratorType: Relationship | undefined;
  serviceProfessionalType: ServiceProfessionalTypeOption | undefined;
  accessLevel: AccessLevelForDisplay | undefined;
  notify: boolean;
  resources: SharedResource[];
}

export interface FlowState<T> {
  isSaveButtonDisabled: boolean;
  pageIndex: PageIndex;
  data: T;
}

export type ActionPayload =
  | {
      type: actions.RESET_TO_DEFAULT_DATA;
      payload: undefined;
    }
  | {
      type: actions.SET_PAGE_INDEX;
      payload: number;
    }
  | {
      type: actions.INCREMENT_PAGE_INDEX;
      payload: undefined;
    }
  | {
      type: actions.DECREMENT_PAGE_INDEX;
      payload: undefined;
    }
  | {
      type: actions.SET_FIRST_NAME;
      payload: string;
    }
  | {
      type: actions.SET_LAST_NAME;
      payload: string;
    }
  | {
      type: actions.SET_COLLABORATOR_TYPE;
      payload: Relationship;
    }
  | {
      type: actions.SET_SERVICE_PRO_TYPE;
      payload: ServiceProfessionalTypeOption;
    }
  | {
      type: actions.SET_ACCESS_LEVEL;
      payload: AccessLevelForDisplay;
    }
  | {
      type: actions.SET_NOTIFY;
      payload: boolean;
    }
  | {
      type: actions.ADD_RESOURCE;
      payload: SharedResource;
    }
  | {
      type: actions.ADD_ALL_RESOURCES;
      payload: SharedResource[];
    }
  | {
      type: actions.REMOVE_RESOURCE;
      payload: SharedResourceId;
    }
  | {
      type: actions.REMOVE_ALL_RESOURCES;
      payload: undefined;
    }
  | {
      type: actions.SET_COLLABORATOR;
      payload: {
        firstName: string;
        lastName: string;
        accessLevel: AccessLevelForDisplay;
        collaboratorType: Relationship | undefined;
        serviceProfessionalType: ServiceProfessionalTypeOption | undefined;
        notify: boolean;
        resources: SharedResource[];
      };
    };
