import { alpha } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

export const useStyles = makeStyles<{ rowsCount?: number }>()(
  (theme, { rowsCount = 0 }) => ({
    container: {
      marginBottom: theme.spacing(pxToSpacing(60)),
    },
    table: {
      gridTemplateColumns: "1fr 1.5fr 1fr 1.5fr 1fr 1.5fr 1fr 1fr 1fr 1fr",
    },
    legend: {
      gridRow: `1 / ${rowsCount + 3}`,
    },
    targetPortfolioLegend: {
      marginLeft: theme.spacing(pxToSpacing(30)),
      gridColumn: "8/9",
    },
    postTradePortfolioRow: {
      gridTemplateColumns: "1.5fr 1fr 1.5fr 1fr 1.5fr 1fr !important",
      gridColumn: "2/8",
    },
    targetPortfolioRow: {
      gridTemplateColumns: "repeat(2, 1fr) !important",
      gridColumn: "9/11",
    },
    subtotal: {
      fontFamily: theme.typography.fontSecondary,
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(12),
      color: theme.appearance.$textSubtle,

      padding: theme.spacing(
        pxToSpacing(4),
        pxToSpacing(10),
        pxToSpacing(4),
        pxToSpacing(10)
      ),
    },
    subtotalFirstColumn: {
      paddingLeft: theme.spacing(pxToSpacing(62)),
    },
    total: {
      fontWeight: theme.typography.fontWeightBold,
    },
    firstColumn: {
      display: "flex",
      alignItems: "center",
    },
    firstColumnSpacer: {
      paddingLeft: theme.spacing(pxToSpacing(42)),
    },
    // INFO: same as TableRow component hover effect
    rowHighlight: {
      backgroundColor: alpha(theme.palette.common.white, 0.05),
    },
    significantDiff: {
      color: theme.appearance.$textDanger,
    },
    cashInfoContainer: {
      display: "flex",
      alignItems: "center",
    },
    cashInfoIndicator: {
      width: 20,
      height: 20,
      color: theme.appearance.$iconSubtle,
      marginLeft: theme.spacing(pxToSpacing(6)),
      cursor: "pointer",
    },
    truncate: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },

    // NOTE: APEX variant component styles
    postTradeContainer: {
      marginRight: theme.spacing(pxToSpacing(68)),
    },
    targetPortfolioContainer: {
      flexGrow: 1,
    },
  })
);
