import React, { useCallback, useReducer, useState } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { uniq } from "lodash";

import { ClientId } from "@fartherfinance/frontend/api/Types";
import { mapObject } from "@fartherfinance/frontend/utils/mapObject";

import ExternalAccountAccordion from "./AccountsAccordions/ExternalAccountAccordion";
import FartherAccountAccordion from "./AccountsAccordions/FartherAccountAccordion";
import FartherAccountAccordionV4 from "./AccountsAccordions/FartherAccountAccordionV4";
import ManualAssetsAccordion from "./AccountsAccordions/ManualAssetAccordion";
import { Accordions } from "./Types";

import styles from "./AccountList.module.css";

type LoadedStates = Record<Accordions, boolean>;

const accordions: Accordions[] = [
  "FartherManaged",
  "ManualAssets",
  "credit",
  "investment",
  "depository",
  "loan",
];

interface Props {
  clientId: ClientId;
  openAccordions?: Accordions[];
}

interface State {
  loadedState: LoadedStates;
  allLoaded: boolean;
}

interface Action {
  type: "SetLoaded";
  accordion: Accordions;
  isLoaded: boolean;
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SetLoaded":
      if (state.loadedState[action.accordion] === action.isLoaded) {
        return state;
      }

      const newLoadedStates: LoadedStates = {
        ...state.loadedState,
        [action.accordion]: action.isLoaded,
      };

      const allLoaded = mapObject(newLoadedStates, (v) => v).reduce(
        (accum, curr) => accum && curr
      );

      return {
        ...state,
        loadedState: newLoadedStates,
        allLoaded: allLoaded,
      };

    default:
      return state;
  }
};

const initialState: State = {
  loadedState: {
    FartherManaged: false,
    ManualAssets: false,
    credit: false,
    investment: false,
    depository: false,
    loan: false,
    other: true,
  },
  allLoaded: false,
};

const partialAction: Action = {
  type: "SetLoaded",
  accordion: "other",
  isLoaded: true,
};

export default function AccountList(props: Props): JSX.Element {
  const [openAccordions, setOpenAccordions] = useState<Accordions[]>(
    props.openAccordions ?? accordions
  );

  const { enableCustomPerformanceGroupsV2 } = useFlags();

  const [{ allLoaded }, dispatch] = useReducer(reducer, initialState);

  // We memo all these so the callbacks can be a in a useEffect dep array
  // without ever failing shallow comparison
  const fartherLoaded = useCallback(
    () => dispatch({ ...partialAction, accordion: "FartherManaged" }),
    []
  );
  const investmentLoaded = useCallback(
    () => dispatch({ ...partialAction, accordion: "investment" }),
    []
  );
  const manualLoaded = useCallback(
    () => dispatch({ ...partialAction, accordion: "ManualAssets" }),
    []
  );
  const loanLoaded = useCallback(
    () => dispatch({ ...partialAction, accordion: "loan" }),
    []
  );
  const bankLoaded = useCallback(
    () => dispatch({ ...partialAction, accordion: "depository" }),
    []
  );
  const creditLoaded = useCallback(
    () => dispatch({ ...partialAction, accordion: "credit" }),
    []
  );

  return (
    <div className={styles.container}>
      <div className={styles.centeringContainer}>
        <div className={styles.body}>
          <>
            {enableCustomPerformanceGroupsV2 ? (
              <FartherAccountAccordionV4
                clientId={props.clientId}
                expanded={openAccordions.includes("FartherManaged")}
                openAccordions={openAccordions}
                setExpanded={(newState: boolean) =>
                  newState
                    ? setOpenAccordions(
                        uniq([...openAccordions, "FartherManaged"])
                      )
                    : setOpenAccordions(
                        openAccordions.filter((a) => a !== "FartherManaged")
                      )
                }
                isLoading={!allLoaded}
                setLoaded={fartherLoaded}
              />
            ) : (
              <FartherAccountAccordion
                clientId={props.clientId}
                expanded={openAccordions.includes("FartherManaged")}
                openAccordions={openAccordions}
                setExpanded={(newState: boolean) =>
                  newState
                    ? setOpenAccordions(
                        uniq([...openAccordions, "FartherManaged"])
                      )
                    : setOpenAccordions(
                        openAccordions.filter((a) => a !== "FartherManaged")
                      )
                }
                isLoading={!allLoaded}
                setLoaded={fartherLoaded}
              />
            )}
          </>

          <ExternalAccountAccordion
            clientId={props.clientId}
            type="investment"
            expanded={openAccordions.includes("investment")}
            setExpanded={(newState: boolean) =>
              newState
                ? setOpenAccordions(uniq([...openAccordions, "investment"]))
                : setOpenAccordions(
                    openAccordions.filter((a) => a !== "investment")
                  )
            }
            isLoading={!allLoaded}
            setLoaded={investmentLoaded}
          />

          <ManualAssetsAccordion
            clientId={props.clientId}
            expanded={openAccordions.includes("ManualAssets")}
            openAccordions={openAccordions}
            setExpanded={(newState: boolean) =>
              newState
                ? setOpenAccordions(uniq([...openAccordions, "ManualAssets"]))
                : setOpenAccordions(
                    openAccordions.filter((a) => a !== "ManualAssets")
                  )
            }
            isLoading={!allLoaded}
            setLoaded={manualLoaded}
          />

          <ExternalAccountAccordion
            clientId={props.clientId}
            type="loan"
            expanded={openAccordions.includes("loan")}
            setExpanded={(newState: boolean) =>
              newState
                ? setOpenAccordions(uniq([...openAccordions, "loan"]))
                : setOpenAccordions(openAccordions.filter((a) => a !== "loan"))
            }
            isLoading={!allLoaded}
            setLoaded={loanLoaded}
          />

          <ExternalAccountAccordion
            clientId={props.clientId}
            type="depository"
            expanded={openAccordions.includes("depository")}
            setExpanded={(newState: boolean) =>
              newState
                ? setOpenAccordions(uniq([...openAccordions, "depository"]))
                : setOpenAccordions(
                    openAccordions.filter((a) => a !== "depository")
                  )
            }
            isLoading={!allLoaded}
            setLoaded={bankLoaded}
          />

          <ExternalAccountAccordion
            clientId={props.clientId}
            type="credit"
            expanded={openAccordions.includes("credit")}
            setExpanded={(newState: boolean) =>
              newState
                ? setOpenAccordions(uniq([...openAccordions, "credit"]))
                : setOpenAccordions(
                    openAccordions.filter((a) => a !== "credit")
                  )
            }
            isLoading={!allLoaded}
            setLoaded={creditLoaded}
          />

          {/* Needed for gap on the bottom */}
          <div style={{ height: 0 }} />
        </div>
      </div>
    </div>
  );
}
