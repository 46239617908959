import React, { useCallback, useMemo } from "react";

import { useHistory, useParams } from "react-router-dom";

import useEquivalentSecurityLists from "@fartherfinance/frontend/api/EquivalentSecurities/hooks/useEquivalentSecurityLists";
import { EquivalentSecurityList } from "@fartherfinance/frontend/api/EquivalentSecurities/requests/getEquivalentSecurityList";
import { EquivalentSecurityListId } from "@fartherfinance/frontend/api/Types";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import Select from "@src/sharedComponents/Select/Select";
import SelectItem from "@src/sharedComponents/Select/SelectItem";

import styles from "./ListDropdown.module.css";

const ListDropdown: React.FC = () => {
  const { listId } = useParams<{
    listId: EquivalentSecurityListId;
  }>();

  const history = useHistory();

  const auth = useAdvisorRequestAuth();
  const listsRequest = useEquivalentSecurityLists(auth);

  const options = useMemo(() => {
    if (listsRequest.isLoading || listsRequest.hasError) {
      return [];
    }

    return listsRequest.data;
  }, [listsRequest]);

  const navToAnotherList = useCallback(
    (list_id: string) => {
      history.push({
        ...history.location,
        pathname: `/Advisor/Admin/EquivalentSecurities/${list_id}`,
      });
    },
    [history]
  );

  if (listsRequest.hasError) {
    return <div>Error retrieving equivalent security lists</div>;
  }

  return (
    <Select
      className={styles.select}
      options={options}
      value={listId}
      onChange={navToAnotherList}
      renderValue={(list_id: EquivalentSecurityListId) => {
        if (options.length === 0) {
          return "";
        }

        const list = options.find((l) => l.listId === list_id);
        if (list === undefined) {
          throw new Error("Can't find eslist in options");
        }

        return list.name;
      }}
      renderOption={(l: EquivalentSecurityList) => {
        return <SelectItem key={l.listId} label={l.name} value={l.listId} />;
      }}
      disabled={listsRequest.isLoading}
    />
  );
};

export default ListDropdown;
