import React, { useMemo } from "react";

import DescriptionIcon from "@mui/icons-material/Description";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import { Link, useParams } from "react-router-dom";

import useGetManuallyTrackedAccounts from "@fartherfinance/frontend/api/Accounts/hooks/useGetManuallyTrackedAccounts";
import { AltAssetId, ClientId } from "@fartherfinance/frontend/api/Types";

import TabTitle from "../Components/TabTitle";
import { AccountsPath } from "@src/config/routing/RouterPaths";
import SharingResourceWhoHasOrGaveAccessTable from "@src/multiCustodian/components/Sharing/SharingResourceWhoHasOrGaveAccessTable/SharingResourceWhoHasOrGaveAccessTable";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

import styles from "../../Settings/Settings.module.css";

const SettingsTab = (): JSX.Element => {
  const { clientId, accountId } = useParams<{
    clientId: ClientId;
    accountId: AltAssetId;
  }>();

  const auth = useRequestAuth();

  const manualAssets = useGetManuallyTrackedAccounts(clientId, auth);

  const isShared = useMemo(() => {
    if (manualAssets.isLoading || manualAssets.hasError) {
      return true; // assume true for time being
    }

    const account = manualAssets.data.find((a) => a.id === accountId);
    if (account === undefined) {
      throw new Error(
        `Manual Asset: ${accountId} is not in list of all manual assets`
      );
    }

    return account.personId !== clientId;
  }, [manualAssets, clientId, accountId]);

  if (manualAssets.hasError) {
    return <div>Error retrieving client's manualAssets</div>;
  }

  return (
    <>
      <TabTitle>Account Settings</TabTitle>

      {manualAssets.isLoading && (
        <div className={styles.loading}>
          <LogoLoadingStill />
        </div>
      )}

      {!isShared && (
        <div className={styles.buttonContainer}>
          <Link
            to={`/Client/${clientId}/${AccountsPath}/Manual/${accountId}/Settings/UpdateInvestment`}
            className={toClassName(styles.buttonDiv, styles.buttonBackground)}
          >
            <EqualizerIcon className={styles.muiImg} />

            <div className={styles.text}>Update Value</div>
          </Link>

          <Link
            to={`/Client/${clientId}/${AccountsPath}/Manual/${accountId}/Settings/RenameAccount`}
            className={toClassName(styles.buttonDiv, styles.buttonBackground)}
          >
            <DriveFileRenameOutlineOutlinedIcon className={styles.muiImg} />

            <div className={styles.text}>Rename Account</div>
          </Link>

          <Link
            to={`/Client/${clientId}/${AccountsPath}/Manual/${accountId}/Settings/EditDocument`}
            className={toClassName(styles.buttonDiv, styles.buttonBackground)}
          >
            <DescriptionIcon className={styles.muiImg} />

            <div className={styles.text}>Add Documentation</div>
          </Link>
        </div>
      )}

      {/* this component will either show or hide itself and derive itself whether this account is being shared by another client or by this one solely using the resourceId prop */}
      <SharingResourceWhoHasOrGaveAccessTable resourceId={accountId} />
    </>
  );
};

export default SettingsTab;
