import { useDebugValue, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";

import isLocalStorageAvailable from "../utils/isLocalStorageAvailable";
import {
  magicCheckLogin,
  magicGetIdToken,
  magicGetUserMetadata,
} from "@src/magic";
import logUserOut from "@src/multiCustodian/utils/logUserOut";
import { MCReduxAction, State } from "@src/store";

import useClientFartherLogin, { LoginInfo } from "./useClientFartherLogin";

const clientEmailStorageKey = "farther.client.email";

type LoggedInState = {
  isLoggedIn: boolean;
};

type Return =
  | { isLoading: true; hasError: false; data: undefined }
  | { isLoading: false; hasError: false; data: LoggedInState }
  | { isLoading: false; hasError: true; data: undefined };

class ClientNotLoggedIn extends Error {
  constructor(reason: string) {
    super(`Client Not Logged In ${reason}`);
  }
}

export default function useLogIntoSavedClientSession(): Return {
  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));
  const [isLoggedIn, setIsLoggedIn] = useState<null | boolean>(null);
  const isLoading = isLoggedIn === null;

  const history = useHistory();

  useDebugValue(
    `useLogIntoSavedClientSession - isLoading: ${
      isLoading ? "Yes" : "No"
    }, isLoggedIn: ${isLoggedIn ? "Yes" : "No"}, clientId: ${
      clientId ?? "UnknownId"
    }`
  );

  const dispatch = useDispatch<Dispatch<MCReduxAction>>();
  const advisorFartherLogin = useClientFartherLogin();

  useEffect(() => {
    if (clientId !== null) {
      //Already logged in, noop
      console.log("useLogIntoSavedClientSession Already logged in, skipping");
      setIsLoggedIn(true);
      return;
    }

    magicCheckLogin("Client")
      .then(async (isLoggedIn) => {
        console.log("useLogIntoSavedClientSession isLoggedIn", isLoggedIn);

        if (!isLoggedIn) {
          setIsLoggedIn(false);
          return;
        }

        let auth: LoginInfo;
        if (process.env.WEBAPP_ENV !== "PROD") {
          auth = await nonProdAlgo();
        } else {
          auth = await prodAlgo();
        }

        await advisorFartherLogin(auth);

        setIsLoggedIn(true);

        return;
      })
      .catch((err) => {
        setIsLoggedIn(false);

        if (err instanceof ClientNotLoggedIn) {
          return;
        }

        console.error("useLogIntoSavedClientSession", err);
        logUserOut("useLogIntoSavedClientSession error", history);
      });
  }, [advisorFartherLogin, clientId, dispatch, history]);

  return useMemo((): Return => {
    if (isLoading) {
      return { isLoading, hasError: false, data: undefined };
    } else {
      return { isLoading: false, hasError: false, data: { isLoggedIn } };
    }
  }, [isLoading, isLoggedIn]);
}

const prodAlgo = async () => {
  const magicToken = await magicGetIdToken("Client");
  const userMetadata = await magicGetUserMetadata("Client");

  if (userMetadata.email === null) {
    throw new ClientNotLoggedIn("email not stored");
  }

  const auth: LoginInfo = { magicToken, clientEmail: userMetadata.email };

  return auth;
};

const nonProdAlgo = async () => {
  const magicToken = await magicGetIdToken("Client");

  const clientEmailDevMode = isLocalStorageAvailable()
    ? localStorage.getItem(clientEmailStorageKey)
    : null;

  if (clientEmailDevMode === null) {
    throw new ClientNotLoggedIn("email not stored");
  }
  const [clientEmail, _devEmail] = clientEmailDevMode.split("__");

  const auth: LoginInfo = { magicToken, clientEmail: clientEmail };

  return auth;
};
