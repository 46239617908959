import React from "react";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Chip, Typography } from "@mui/material";
import { format } from "date-fns";

import {
  MarketplaceOfferingTag,
  MarketplaceOfferingTagFormat,
  MarketplaceOfferingTagType,
} from "@fartherfinance/frontend/api/AltAssetsService/Types";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import IconButton from "@src/sharedComponents/IconButton/IconButton";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./OfferingsListCardV2.module.css";

type OfferingsListCardOwnProps = {
  partnerName: string;
  partnerDescription: string;
  tags: MarketplaceOfferingTag[];
  isFeatured: boolean;
  onClick: () => void;
  onTagClick: (tag: MarketplaceOfferingTag) => void;
};

type OfferingsListCardLoadingFalseProps = OfferingsListCardOwnProps & {
  isLoading: false;
};

type OfferingsListCardLoadingTrueProps = Never<OfferingsListCardOwnProps> & {
  isLoading: true;
};

type OfferingsListCardV2Props =
  | OfferingsListCardLoadingFalseProps
  | OfferingsListCardLoadingTrueProps;

const OfferingsListCardV2: React.FC<OfferingsListCardV2Props> = ({
  isLoading,
  partnerName,
  partnerDescription,
  tags,
  isFeatured,
  onClick,
  onTagClick,
}) => {
  const handleTagLabel = (tag: MarketplaceOfferingTag): React.ReactNode => {
    if (tag.type === MarketplaceOfferingTagType.enum.iso8601) {
      return format(new Date(tag.value), "MM-dd-yyyy");
    }

    if (tag.type === MarketplaceOfferingTagType.enum.currency_usd) {
      const parsedValue = parseInt(tag.value);

      if (tag.format === MarketplaceOfferingTagFormat.enum.reverse_short) {
        const prefix = "$";
        const suffix = tag.keyShortDisplayName
          ? ` ${tag.keyShortDisplayName}`
          : "";
        const content = parsedValue.toLocaleString("en-US", {
          notation: "compact",
        });

        return `${prefix}${content}${suffix}`;
      }

      return parsedValue.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
      });
    }

    // NOTE: if any other type just return the plain value
    return tag.value;
  };

  return (
    <>
      {isLoading ? (
        <Skeleton height={250} sx={{ transform: "unset" }} />
      ) : (
        <div className={styles.container} onClick={onClick}>
          <div className={styles.highlight} />
          <div className={styles.header}>
            <Typography className={styles.name}>{partnerName}</Typography>

            <div className={styles.actions}>
              {isFeatured && (
                <Chip
                  className={toClassName(styles.chip, styles.featuredChip)}
                  label="Featured"
                  size="small"
                  variant="filled"
                />
              )}
              <IconButton
                size="medium"
                onClick={onClick}
                IconComponent={ArrowForwardIcon}
              />
            </div>
          </div>

          <div className={styles.description}>{partnerDescription}</div>

          <div className={styles.tags}>
            {tags.map((tag, index) => (
              <Chip
                key={index}
                className={toClassName(styles.chip, styles.tagChip)}
                label={handleTagLabel(tag)}
                size="small"
                variant="outlined"
                onClick={(event) => {
                  event.stopPropagation();
                  onTagClick(tag);
                }}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default OfferingsListCardV2;
