import React from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PeopleIcon from "@mui/icons-material/People";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

import FavoriteStarLabel from "../Modals/Shared/FavoriteStarLabel/FavoriteStarLabel";

import styles from "./DropdownMenuItem.module.css";

interface Props {
  name: string;
  isChecked: boolean;
  id?: string;
  isShared?: boolean;
  isFavorite?: boolean;
  setAccountGroupId?: () => void;
}

const CustomPerformanceGroupsDropdownMenuItem: React.FC<Props> = ({
  name,
  id,
  isChecked,
  isShared,
  isFavorite,
  setAccountGroupId,
}: Props) => {
  return (
    <div className={styles.container} onClick={setAccountGroupId}>
      <div className={styles.left}>
        {isChecked ? (
          <CheckCircleIcon className={styles.iconChecked} />
        ) : (
          <RadioButtonUncheckedIcon className={styles.iconUnchecked} />
        )}

        <div className={styles.text}>
          {id ? `${name} (...${id.slice(id.length - 4)})` : name}
        </div>

        {isShared && <PeopleIcon className={styles.iconShared} />}
      </div>

      <div className={styles.right}>{isFavorite && <FavoriteStarLabel />}</div>
    </div>
  );
};

export default CustomPerformanceGroupsDropdownMenuItem;
