import React from "react";

import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "../ManualAssetDrawer.module.css";

interface Props {
  closeModal: () => void;
}

const RenameAccountFormLoading = (props: Props): JSX.Element => {
  return (
    <div>
      <FormH1>Rename Account</FormH1>

      <Spacer verticalSpacing="20px" />

      <Skeleton height={50} />

      <Spacer verticalSpacing="10px" />

      <div className={styles.modal__yes_no_div}>
        <ButtonPrimary
          variant={"outlined"}
          buttonType={"secondary"}
          text={"Cancel"}
          onClick={props.closeModal}
        />

        <ButtonPrimary
          disabled={true}
          variant={"contained"}
          buttonType={"primary"}
          text={"Confirm"}
          onClick={() => undefined}
          style={{ marginLeft: "25px" }}
        />
      </div>
    </div>
  );
};

export default RenameAccountFormLoading;
