import React from "react";

import PeopleIcon from "@mui/icons-material/People";

import { FartherManagedAccount } from "@fartherfinance/frontend/api/Accounts/Types";
import { FartherAccountId } from "@fartherfinance/frontend/api/Types";

import Checkbox from "@src/sharedComponents/Checkbox/Checkbox";

import styles from "./CheckboxRow.module.css";

interface Props {
  account: FartherManagedAccount;
  isChecked: boolean;
  isDisabled: boolean;
  onToggleAccount: (checked: boolean, id: FartherAccountId) => void;
}

const CheckboxRow: React.FC<Props> = ({
  account,
  isChecked,
  isDisabled,
  onToggleAccount,
}: Props) => {
  const custodianAccountNumber = account.accountDetails.custodianAccountNumber;
  const label = custodianAccountNumber
    ? `${account.accountDetails.displayName} (...${custodianAccountNumber.slice(
        custodianAccountNumber.length - 4
      )})`
    : account.accountDetails.displayName;

  return (
    <div className={styles.container}>
      <Checkbox
        checked={isChecked}
        onChange={(checked: boolean) =>
          onToggleAccount(checked, account.virtualAccountId)
        }
        label={
          <div className={styles.label}>
            <div className={styles.text}>{label}</div>

            {account.sharedBy.length > 0 && (
              <PeopleIcon className={styles.iconShared} />
            )}
          </div>
        }
        disabled={isDisabled}
      />
    </div>
  );
};

export default CheckboxRow;
