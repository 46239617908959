import { parse } from "date-fns";

import {
  ClientEmploymentDetails,
  ClientInvestorProfile,
} from "@fartherfinance/frontend/api/Entity/requests/getClientProfile";

import formatAmount from "../../formatters/formatAmount";
import { Form, InvestorProfilePortion } from "../types";
import { incomeRangeToLabel } from "@src/constants/incomeRange";

export default function mapDefaultValues(
  investorForm: ClientInvestorProfile,
  employmentForm: ClientEmploymentDetails
): Form {
  const {
    citizenship,
    taxInformation,
    incomeRange,
    estimatedNetWorthMillions,
    iraOutsideFarther,
    investingObjective,
    investorType,
  } = investorForm.personalDetails;

  const netWorth =
    estimatedNetWorthMillions !== null
      ? formatAmount(estimatedNetWorthMillions)
      : null;

  const contributedToIRA =
    iraOutsideFarther !== null && iraOutsideFarther > 0 ? "Yes" : "No";

  const contributionAmount =
    iraOutsideFarther !== null ? formatAmount(iraOutsideFarther) : null;

  const { taxBracketLTG, taxBracketOrdinary, capitalLossCarryover } =
    investorForm.taxBracketInfo;

  const investorProfilePortion: InvestorProfilePortion = {
    CitizenshipStatus: citizenship !== null ? { label: citizenship } : null,
    TaxID: taxInformation !== null ? taxInformation.taxId : null,
    IncomeRange:
      incomeRange !== null
        ? { label: incomeRangeToLabel[incomeRange], range: incomeRange }
        : null,
    NetWorth: netWorth,
    ContributedToIRA: contributedToIRA,
    ContributionAmount: contributionAmount,
    InvestingObjective: investingObjective
      ? { label: investingObjective }
      : null,
    InvestorType: investorType ? { label: investorType } : null,
    TaxBracketLTG: taxBracketLTG !== null ? `${taxBracketLTG}` : "20", //Default Estimate by Farther
    TaxBracketOrdinary:
      taxBracketOrdinary !== null ? `${taxBracketOrdinary}` : "37", //Default Estimate by Farther
    CapitalLossCarryover:
      capitalLossCarryover !== null ? `${capitalLossCarryover}` : "0", //Default Estimate by Farther
  };

  const { employmentStatus, employer } = employmentForm;

  if (employmentStatus === "Employed") {
    const startYear =
      employer !== null
        ? parse(`${employer.startYear}`, "yyyy", new Date())
        : null;

    const state = employer?.employerAddress.state
      ? { label: employer.employerAddress.state }
      : null;

    return {
      ...investorProfilePortion,
      EmploymentStatus: { label: employmentStatus },
      EmployerName: employer?.employerName ?? null,
      Position: employer?.occupation ?? null,
      StartYear: startYear,
      BusinessAddress1: employer?.employerAddress.street ?? null,
      BusinessAddress2: employer?.employerAddress.unit ?? null,
      City: employer?.employerAddress.city ?? null,
      State: state,
      ZipCode: employer?.employerAddress.postalCode ?? null,
    };
  }

  return {
    ...investorProfilePortion,
    EmploymentStatus:
      employmentStatus !== null ? { label: employmentStatus } : null,
  };
}
