import React, { useState } from "react";

import { useSelector } from "react-redux";

import useDeleteDocumentV2 from "@fartherfinance/frontend/api/Accounts/hooks/useDeleteDocumentV2";
import { PlanDetails } from "@fartherfinance/frontend/api/Accounts/requests/getPlanDetailsV2";
import {
  Account,
  PlanDocument,
  PlanStatus,
} from "@fartherfinance/frontend/api/Accounts/Types";
import { AgreementId, PlanId } from "@fartherfinance/frontend/api/Types";

import { State as ReduxState } from "../../../../../store";
import AlertMessageModal from "../../../../components/Modals/AlertMessageModal";
import PlanTableComponent from "../table/PlanTable";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";

import styles from "../table/table.module.css";

const headers = ["Document", "Account", "Assigned To"];
const cellWidthPercentages = [45, 20, 35];

interface Props {
  planId: PlanId;
  planDetails: PlanDetails | null;
}

const PlanDocumentsTable = (props: Props): JSX.Element => {
  const [modalData, setModalData] = useState<ModalData | null>(null);

  const statusNotification = useStatusNotification();

  const auth = useAdvisorRequestAuth();

  const callDeleteDocument = useDeleteDocumentV2(auth);

  return (
    <>
      <PlanTableComponent
        headers={headers}
        cellWidthPercentages={cellWidthPercentages}
        tableContent={(props.planDetails?.resources.documents ?? []).map(
          (doc: PlanDocument) => (
            <TableRow
              key={doc.agreementId}
              doc={doc}
              planAccounts={props.planDetails?.resources.accounts}
              setModalData={setModalData}
              planStatus={props.planDetails?.plan.planStatus}
            />
          )
        )}
        placeholderText={
          props.planDetails === null
            ? "Loading..."
            : "Add documents to this plan."
        }
      />

      {modalData !== null && (
        <AlertMessageModal
          top_text={() => (
            <p>
              Are you sure you want to delete the document{" "}
              <span>{modalData.title}</span> ?
            </p>
          )}
          bottom_text={""}
          button_blue_text={"No"}
          button_blue_onClick={() => setModalData(null)}
          button_yellow_text={"Yes"}
          button_yellow_onClick={async () => {
            try {
              await callDeleteDocument({
                planId: props.planId,
                agreementId: modalData.agreementId,
              });
              statusNotification("Document removed", "Success");
              setModalData(null);
            } catch (_e) {
              statusNotification("Error removing document", "Error");
              setModalData(null);
            }
          }}
          img_src={null}
          modal_style={null}
        />
      )}
    </>
  );
};

export default PlanDocumentsTable;

interface ModalData {
  title: string;
  agreementId: AgreementId;
}

interface TableRowProps {
  doc: PlanDocument;
  planAccounts: Account[] | undefined;
  setModalData: (modalData: ModalData) => void;
  planStatus: PlanStatus | undefined;
}

const TableRow = ({
  doc,
  planAccounts,
  setModalData,
  planStatus,
}: TableRowProps): JSX.Element => {
  const [isHovering, setHovering] = useState(false);

  const { small_x_icon } = useSelector((state: ReduxState) => ({
    small_x_icon: state.main_Reducer.brand.current.images.small_x_icon,
  }));

  return (
    <tr
      className={styles.tableRow}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <td
        className={styles.tableCol}
        style={{ width: cellWidthPercentages[0] + "%" }}
      >
        <div className={styles.tableCol_cell}>{doc.title}</div>
      </td>

      <td
        className={styles.tableCol}
        style={{ width: cellWidthPercentages[1] + "%" }}
      >
        <div className={styles.tableCol_cell}>
          {(planAccounts ?? []).find((acct) => {
            return acct.physicalAccountId === doc.physicalAccountId;
          })?.accountInformation?.accountTitle ?? "--"}
        </div>
      </td>

      <td
        className={styles.tableCol}
        style={{ width: cellWidthPercentages[2] + "%" }}
      >
        <div className={styles.tableCol_cell}>
          <div className={styles.flex_apart}>
            <p className={styles.text}>{doc.clientId}</p>

            {isHovering && planStatus !== "Complete" && (
              <img
                className={styles.table_row_x_img}
                src={small_x_icon}
                onClick={() => {
                  setModalData({
                    title: doc.title,
                    agreementId: doc.agreementId,
                  });
                }}
              />
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};
