import React, { useState } from "react";

import CircleIcon from "@mui/icons-material/Circle";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { Stack, Typography } from "@mui/material";

import usePutUpdateAgreement from "@fartherfinance/frontend/api/Document/hooks/usePutUpdateAgreement";
import { Agreement } from "@fartherfinance/frontend/api/Document/requests/getAgreements";
import getSignatureUrl from "@fartherfinance/frontend/api/Document/requests/getSignatureUrl";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import ClickToSignModal from "../ClickToSignModal/ClickToSignModal";
import { useHelloSign } from "../HelloSignProvider/HelloSignProvider";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useApplicationMode from "@src/multiCustodian/hooks/useApplicationMode";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import {
  FartherTable,
  FartherTableBody,
  FartherTableBodyCell,
  FartherTableContainer,
  FartherTableHead,
  FartherTableHeaderCell,
} from "@src/sharedComponents/Table/Components";
import TableRow from "@src/sharedComponents/Table/TableRow/TableRow";

import styles from "./Agreements.module.css";

interface AgreementsProps {
  clientId: ClientId;
  agreements: Agreement[];
  isLoading: boolean;
}

const Agreements: React.FC<AgreementsProps> = ({
  clientId,
  agreements,
  isLoading,
}) => {
  const [isSigning, setIsSigning] = useState(false);
  const [clickToSignAgreement, setClickToSignAgreement] =
    useState<Agreement | null>(null);
  const helloSignClient = useHelloSign();
  const { isImpersonationMode } = useApplicationMode();

  const auth = useRequestAuth();
  const callPutUpdateAgreement = usePutUpdateAgreement(clientId, auth);
  const statusNotification = useStatusNotification();

  const pendingAgreements = agreements.filter(
    (agreement) => agreement.status === "Pending"
  );

  const handleSignAgreementViaHelloSign = async (
    agreement: Agreement
  ): Promise<void> => {
    if (auth === null || helloSignClient === undefined || isImpersonationMode) {
      return;
    }

    try {
      setIsSigning(true);

      const signatureUrl = await getSignatureUrl(agreement.agreementId, auth);

      // NOTE: "once" method do stack for the same events, we need to clean up the listeners
      // in case user just closes the hellosign modal without signing
      helloSignClient.once("close", () => {
        helloSignClient.off("ready");
        helloSignClient.off("sign");
        helloSignClient.off("error");
      });

      helloSignClient.once("ready", () => setIsSigning(false));

      helloSignClient.once("sign", async () => {
        await callPutUpdateAgreement({
          agreementId: agreement.agreementId,
          status: "Signed",
        });
        statusNotification("Agreement signed successfully", "Success");
      });

      helloSignClient.once("error", () =>
        statusNotification(
          "Failed to sign an agreement. Please try again.",
          "Error"
        )
      );

      helloSignClient.open(signatureUrl);
    } catch {
      statusNotification("Something went wrong. Please try again.", "Error");
      setIsSigning(false);
    }
  };

  const handleSignAgreementViaClickToSign = async (
    agreement: Agreement
  ): Promise<void> => {
    if (isImpersonationMode) {
      return;
    }

    try {
      setIsSigning(true);
      await callPutUpdateAgreement({
        agreementId: agreement.agreementId,
        status: "Signed",
      });
      statusNotification("Agreement signed successfully", "Success");
    } catch (_error) {
      statusNotification(
        "Failed to sign an agreement. Please try again.",
        "Error"
      );
    } finally {
      setIsSigning(false);
      setClickToSignAgreement(null);
    }
  };

  const handleSignAgreement = (agreement: Agreement): void => {
    if (
      agreement.status === "Pending" &&
      agreement.signatureType === "eSignature"
    ) {
      handleSignAgreementViaHelloSign(agreement);
    }

    if (
      agreement.status === "Pending" &&
      agreement.signatureType === "Click to sign"
    ) {
      setClickToSignAgreement(agreement);
    }
  };

  return (
    <>
      {clickToSignAgreement && (
        <ClickToSignModal
          agreement={clickToSignAgreement}
          onClose={() => setClickToSignAgreement(null)}
          onSign={() => handleSignAgreementViaClickToSign(clickToSignAgreement)}
        />
      )}
      <FartherTableContainer>
        <FartherTable>
          <FartherTableHead>
            <FartherTableHeaderCell>
              <Stack direction="row" alignItems="center" gap="8px">
                <CircleIcon className={styles.headerIcon} />
                <Typography className={styles.headerText}>
                  Documents to Sign
                </Typography>
              </Stack>
            </FartherTableHeaderCell>
          </FartherTableHead>

          <FartherTableBody>
            {isLoading &&
              Array.from({ length: 3 }).map((_, idx) => (
                <TableRow key={idx}>
                  {() => (
                    <FartherTableBodyCell>
                      <Skeleton />
                    </FartherTableBodyCell>
                  )}
                </TableRow>
              ))}

            {pendingAgreements.map((agreement) => (
              <TableRow
                key={agreement.agreementId}
                onClick={
                  isImpersonationMode
                    ? undefined
                    : () => handleSignAgreement(agreement)
                }
              >
                {() => (
                  <FartherTableBodyCell className={styles.row}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack direction="row" alignItems="center" gap="20px">
                        <InsertDriveFileOutlinedIcon
                          className={styles.fileIcon}
                        />
                        {agreement.documentTitle}
                      </Stack>
                      <Button
                        variant="contained"
                        buttonType="primary"
                        text="Sign"
                        disabled={isImpersonationMode}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleSignAgreement(agreement);
                        }}
                      />
                    </Stack>
                  </FartherTableBodyCell>
                )}
              </TableRow>
            ))}
          </FartherTableBody>
        </FartherTable>
      </FartherTableContainer>

      {isSigning && <LogoLoadingStill onTop />}
    </>
  );
};

export default Agreements;
