import React, { PropsWithChildren } from "react";

import AdvisorDisclaimer from "@src/multiCustodian/components/Client/TaxBudgets/AdvisorDisclaimer/AdvisorDisclaimer";
import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import CapitalGainsTaxes from "@src/multiCustodian/components/TaxBudgets/CapitalGainsTaxes/CapitalGainsTaxes";
import Empty from "@src/multiCustodian/components/TaxBudgets/Card/Empty";
import Buttons from "@src/multiCustodian/components/TaxBudgets/Hero/Buttons";
import useTaxBudgets from "@src/multiCustodian/components/TaxBudgets/Hooks/useTaxBudgets";
import TaxBudgetList from "@src/multiCustodian/components/TaxBudgets/List/List";
import CenteringContainer from "@src/sharedComponents/CenteringContainer/CenteringContainer";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import PageHero from "@src/sharedComponents/PageHero/PageHero";
import TitleBlock from "@src/sharedComponents/PageHero/TitleBlock";

const CENTERING_WIDTH = 685;

const List: React.FC<PropsWithChildren<Record<string, any>>> = ({
  children,
}) => {
  const { data, hasError, isLoading } = useTaxBudgets();

  if (hasError) {
    return <div>Error retrieving Tax Budgets</div>;
  }

  if (isLoading) {
    return (
      <BaseLayout>
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LogoLoadingStill />
        </div>
      </BaseLayout>
    );
  }

  const { currentBudget, futureBudgets, hasBudgets, includesSharedAccounts } =
    data;

  return (
    <BaseLayout>
      <PageHero
        start={<TitleBlock title="Tax Budgets" />}
        end={hasBudgets ? <Buttons disabled={includesSharedAccounts} /> : <></>}
      />

      <CenteringContainer widthStyle={`${CENTERING_WIDTH}px`}>
        {includesSharedAccounts && <AdvisorDisclaimer />}

        {!includesSharedAccounts && hasBudgets && (
          <TaxBudgetList
            currentBudget={currentBudget}
            futureBudgets={futureBudgets}
          />
        )}

        {!includesSharedAccounts && !hasBudgets && (
          <>
            <CapitalGainsTaxes />

            <Empty />
          </>
        )}
      </CenteringContainer>

      {children}
    </BaseLayout>
  );
};

export default List;
