import React, { useMemo } from "react";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Typography } from "@mui/material";
import { format } from "date-fns";

import { MarketplaceAssetType } from "@fartherfinance/frontend/api/AltAssetsService/Types";
import {
  MarketplaceOfferingInterestOpportunity,
  OpportunityType,
} from "@fartherfinance/frontend/api/Opportunities/Types";

import {
  AltAssetsMarketplacePath,
  InsuranceMarketplacePath,
  LendingMarketplacePath,
} from "@src/config/routing/RouterPaths";
import {
  altAssetOfferingTypes,
  insuranceOfferingTypes,
  lendingOfferingTypes,
} from "@src/multiCustodian/components/Marketplace/constants";
import { findOfferingTypeByValue } from "@src/multiCustodian/components/Marketplace/utils";
import { isInLocalDevelopmentMode } from "@src/utils/isLocalhost";

import OpportunityMetadata from "./OpportunityMetadata/OpportunityMetadata";

import styles from "./Opportunity.module.css";

interface OpportunityOfferingProps {
  opportunity: MarketplaceOfferingInterestOpportunity;
}

export default function OpportunityOffering({
  opportunity,
}: OpportunityOfferingProps): JSX.Element {
  const { typeAttributes, createdTs, updatedTs } = opportunity;
  const opportunityAttributes = typeAttributes.value;

  const assetType = useMemo(() => {
    return {
      [MarketplaceAssetType.enum.insurance]: InsuranceMarketplacePath,
      [MarketplaceAssetType.enum.lending]: LendingMarketplacePath,
      [MarketplaceAssetType.enum.alternative_asset]: AltAssetsMarketplacePath,
    }[opportunityAttributes.assetType];
  }, [opportunityAttributes.assetType]);

  const offeringTypes = useMemo(() => {
    return {
      [MarketplaceAssetType.enum.insurance]: insuranceOfferingTypes,
      [MarketplaceAssetType.enum.lending]: lendingOfferingTypes,
      [MarketplaceAssetType.enum.alternative_asset]: altAssetOfferingTypes,
    }[opportunityAttributes.assetType];
  }, [opportunityAttributes.assetType]);

  const handleViewOffer = (): void => {
    const offeringPath = `/Advisor/${assetType}/${opportunityAttributes.offeringType}/${opportunityAttributes.offeringId}`;
    const environment = isInLocalDevelopmentMode()
      ? "http://localhost:3000"
      : process.env.WEBAPP_ENV === "UAT"
      ? "https://uat-app.farther.com"
      : "https://app.farther.com";

    window.open(environment + offeringPath, "_blank");
  };

  const marketplaceOfferingType = findOfferingTypeByValue(
    offeringTypes,
    opportunityAttributes.offeringType
  );

  return (
    <Box>
      <Typography className={styles.title}>Details</Typography>
      <Box className={styles.container}>
        {(opportunity.type === OpportunityType.enum.INSURANCE_INTEREST ||
          opportunity.type === OpportunityType.enum.LENDING_INTEREST) && (
          <>
            <OpportunityMetadata
              label="Partner"
              value={opportunity.typeAttributes.value.partnerName}
            />
            <OpportunityMetadata
              label="Offering"
              value={marketplaceOfferingType?.name}
            />
          </>
        )}

        {opportunity.type ===
          OpportunityType.enum.ALTERNATIVE_ASSET_INTEREST && (
          <>
            <OpportunityMetadata
              label="Offering"
              value={opportunity.typeAttributes.value.offeringName}
            />
            <OpportunityMetadata
              label="Firm"
              value={opportunity.typeAttributes.value.firm}
            />
            <OpportunityMetadata
              label="Platform"
              value={opportunity.typeAttributes.value.platform}
            />
            <OpportunityMetadata
              label="Objective"
              value={opportunity.typeAttributes.value.objective}
            />
            <OpportunityMetadata
              label="Strategy"
              value={opportunity.typeAttributes.value.strategy}
            />
            <OpportunityMetadata
              label="Substrategy"
              value={opportunity.typeAttributes.value.substrategy}
            />
          </>
        )}

        <OpportunityMetadata
          label="Listing"
          value={
            <div className={styles.routingValue}>
              <OpenInNewIcon className={styles.routingIcon} />
              <Typography
                className={styles.routingText}
                onClick={handleViewOffer}
              >
                View Offer
              </Typography>
            </div>
          }
        />
        <OpportunityMetadata
          label="Created"
          value={format(createdTs, "MM/dd/yy")}
        />
        <OpportunityMetadata
          label="Updated"
          value={format(updatedTs, "MM/dd/yy")}
        />
      </Box>
    </Box>
  );
}
