import React, { useState } from "react";

import { ClickAwayListener } from "@mui/material";
import { useParams } from "react-router-dom";

import usePlanDetailsV2 from "@fartherfinance/frontend/api/Accounts/hooks/usePlanDetailsV2";
import useUpdatePlanTitleV2 from "@fartherfinance/frontend/api/Accounts/hooks/useUpdatePlanTitleV2";
import { Plan } from "@fartherfinance/frontend/api/Accounts/Types";
import { PlanId } from "@fartherfinance/frontend/api/Types";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import TextInput from "@src/sharedComponents/TextInput/TextInput";
import { extractAxiosErrorMessage, isAxiosErrorCode } from "@src/utils/axios";

import styles from "../Plans/plans.module.css";

const EditablePlanName = (): JSX.Element => {
  const [editingName, setEditingName] = useState<boolean>(false);
  const [newPlanName, setNewPlanName] = useState<string>("");
  const [isMutating, setIsMutating] = useState(false);

  const statusNotification = useStatusNotification();

  const auth = useAdvisorRequestAuth();

  const { planId } = useParams<{ planId: PlanId }>();

  const callPutPlanTitle = useUpdatePlanTitleV2(auth);

  const planDetails = usePlanDetailsV2(planId, auth);
  const plan: Plan | undefined = planDetails.data?.plan;

  const editNewPlanName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const titleLengthLimit = 60;
    if (
      (e.target.value === "" || e.target.value.match(/^[a-zA-Z0-9-_\s]+$/)) &&
      e.target.value.length < titleLengthLimit
    ) {
      setNewPlanName(e.target.value);
    }
  };

  const onKeyPressEditNewPlanName = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      saveNewPlanName();
    }
  };

  const planTitle = editingName ? newPlanName : plan?.planTitle ?? "New Plan";

  const isPlanNameValid = planTitle.length <= 3;

  const saveNewPlanName = async () => {
    if (
      isPlanNameValid ||
      plan === undefined ||
      plan.planTitle === planTitle ||
      isMutating
    ) {
      setEditingName(false); //If any of the above is true, we don't update the plan name, we just exit edit mode.
    } else {
      try {
        setIsMutating(true);

        await callPutPlanTitle({ planId: plan.planId, newTitle: newPlanName });
        statusNotification("Title updated", "Success");
      } catch (error) {
        const errorMessage = isAxiosErrorCode(400, error)
          ? extractAxiosErrorMessage(error)
          : "Error updating title";

        statusNotification(errorMessage, "Error");
      } finally {
        setIsMutating(false);
        setEditingName(false);
      }
    }
  };

  const enableEditName = () => {
    if (plan === undefined) {
      return statusNotification(
        "Cannot edit name for non-existent plan.",
        "Error"
      );
    }

    if (plan.planStatus === "Complete") {
      return statusNotification(
        "Cannot edit name for a completed plan.",
        "Error"
      );
    }

    if (editingName) {
      return;
    }

    setNewPlanName(plan.planTitle);
    setEditingName(true);
  };

  return (
    <ClickAwayListener
      mouseEvent={"onMouseDown"} //avoid disabling input when drag-selecting its text and going out of its bounds
      onClickAway={editingName ? saveNewPlanName : () => undefined}
    >
      <div className={styles.editablePlanName_title}>
        <TextInput
          value={planTitle}
          onChange={editNewPlanName}
          onBlur={saveNewPlanName}
          onKeyDown={onKeyPressEditNewPlanName}
          error={isPlanNameValid}
          helperText={
            isPlanNameValid ? "Plan name must have more than 3 letters" : ""
          }
          style={{ maxWidth: "200px" }}
          inputProps={{ style: { fontSize: 18 } }}
          disabled={!plan || plan.planStatus === "Complete" || !editingName}
          onDoubleClick={enableEditName}
        />

        {editingName ? (
          <img
            className={styles.editablePlanName_edit_icon}
            src={"/src/assets/svg/checkmark-green-circle.svg"}
            onClick={saveNewPlanName}
          />
        ) : plan !== undefined && plan.planStatus !== "Complete" ? (
          <img
            className={styles.editablePlanName_edit_icon}
            src="/src/assets/svg/edit_pencil.svg"
            style={{ marginLeft: "15px" }}
            onClick={enableEditName}
          />
        ) : (
          <></>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default EditablePlanName;
