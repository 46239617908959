import React, { useCallback, useState } from "react";

import { useHistory } from "react-router-dom";

import useUploadBulkAccounts from "@fartherfinance/frontend/api/Accounts/hooks/useUploadBulkAccounts";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import Modal from "@src/sharedComponents/Modal/Modal";

import CreateBulkClientsCSVDrop from "./CreateBulkClientsModalCSVDrop";
import CreateBulkClientsForm, {
  BulkClientsForm,
} from "./CreateBulkClientsModalForm";

const CreateBulkClientsModal = (): JSX.Element => {
  const [mutating, setMutating] = useState(false);

  const [clientCSV, setClientCSV] = useState<File | null>(null);

  const [csvError, setCsvError] = useState<string | null>(null);

  const [showForm, setShowForm] = useState<boolean>(false);

  const [form, setForm] = useState<BulkClientsForm>({
    masterDisplay: true,
    investorProfile: false,
    contactInfo: false,
    additionalInfo: false,
    beneficiaries: false,
    onboardingAgreement: false,
    privacyPolicy: false,
    custodianAgreement: false,
    affiliations: false,
    normalization: false,
  });

  const auth = useAdvisorRequestAuth();

  const statusNotification = useStatusNotification();

  const uploadBulkAccounts = useUploadBulkAccounts(auth);

  const history = useHistory();

  const onClose = useCallback(
    () => history.push({ ...history.location, pathname: "/Advisor/Admin" }),
    [history]
  );

  const addNewCSV = (newFile: File | null) => {
    setClientCSV(newFile);

    if (csvError !== null && newFile !== null) {
      //Only remove an existing error if a new file has been provided
      setCsvError(null);
    }
  };

  const submit = async () => {
    if (clientCSV === null) {
      statusNotification("Missing CSV file", "Error");
      return;
    }

    if (mutating) {
      statusNotification("Uploading your document, please wait", "Error");
      return;
    }

    try {
      setMutating(true);

      // if an option is set to true it needs to be filled out but lingo is phrased "Which items should already be checked off"
      // so if an item is checked (true) and thus marked as "should already be checked off" we need to switch it to false when sending to BE
      // false meaning it does not need to be filled out.
      const onboardingOptions = form.masterDisplay
        ? {
            masterDisplay: true,
            investorProfile: !form.investorProfile,
            contactInfo: !form.contactInfo,
            additionalInfo: !form.additionalInfo,
            beneficiaries: !form.beneficiaries,
            onboardingAgreement: !form.onboardingAgreement,
            privacyPolicy: !form.privacyPolicy,
            custodianAgreement: !form.custodianAgreement,
            affiliations: !form.affiliations,
            normalization: form.normalization,
          }
        : {
            masterDisplay: false,
            investorProfile: false,
            contactInfo: false,
            additionalInfo: false,
            beneficiaries: false,
            onboardingAgreement: false,
            privacyPolicy: false,
            custodianAgreement: false,
            affiliations: false,
            normalization: form.normalization,
          };

      const res = await uploadBulkAccounts({
        bulkAccountsDocument: clientCSV,
        onboardingOptions: onboardingOptions,
      });

      if (res.status === "valid") {
        const numAccounts =
          res.data.totalAccountsAdded === 1
            ? "1 account"
            : `${res.data.totalAccountsAdded} accounts`;

        statusNotification(`Created ${numAccounts}`, "Success");

        onClose();
      } else {
        setShowForm(false);
        setCsvError(res.data.message);
      }
    } catch (_e) {
      setShowForm(false);
      statusNotification("CSV upload failed", "Error");
    } finally {
      setMutating(false);
    }
  };

  return (
    <Modal closeModal={onClose} modalStyle={{ width: "620px" }}>
      {mutating && <LogoLoadingStill onTop />}

      {showForm ? (
        <CreateBulkClientsForm
          submit={submit}
          form={form}
          setForm={setForm}
          setShowForm={setShowForm}
          clientCSV={clientCSV}
          mutating={mutating}
        />
      ) : (
        <CreateBulkClientsCSVDrop
          addNewCSV={addNewCSV}
          clientCSV={clientCSV}
          form={form}
          setForm={setForm}
          setShowForm={setShowForm}
          mutating={mutating}
          csvError={csvError}
        />
      )}
    </Modal>
  );
};

export default CreateBulkClientsModal;
