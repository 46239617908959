import React from "react";

import { range } from "lodash";

import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./ClientAdmin.module.css";

const ClientAccountRowsLoading = (): JSX.Element => {
  return (
    <>
      {range(4).map((_, idx) => {
        return (
          <div key={idx} className={styles.tableRow}>
            <div className={styles.col1Cell}>
              <Skeleton width={100} />
            </div>

            <div className={styles.col2Cell}>
              <div className={styles.col2CellText}>
                <Skeleton width={100} />
              </div>
            </div>

            <div className={styles.col3Cell}>
              <Skeleton width={80} />
            </div>

            <div className={styles.col4Cell}>
              <Skeleton width={70} />
            </div>

            <div className={styles.col5Cell}>
              <div className={styles.col5CellInner}>
                <Skeleton width={30} />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ClientAccountRowsLoading;
