import { useCallback } from "react";

import { useSelector } from "react-redux";

import execute_statusNotification_ts from "../components/GlobalStatusNotification/notification_ts";
import { State } from "@src/store";

type NotificationType = "Success" | "Error";

export type ShowStatusFn = (
  message: string,
  type: NotificationType,
  side?: "Left" | "Right",
  timeoutLengthMS?: number
) => () => void;

export default function useStatusNotification(): ShowStatusFn {
  const { smallXIcon } = useSelector((state: State) => ({
    smallXIcon: state.main_Reducer.brand.current.images.small_x_icon,
  }));

  return useCallback(
    (
      message: string,
      type: NotificationType,
      side?: "Left" | "Right",
      timeoutLengthMS?: number
    ) => {
      const successImg = "/src/assets/svg/check_green.svg";
      const errorImg = "/src/assets/svg/!.svg";

      return execute_statusNotification_ts(
        message,
        "success",
        smallXIcon,
        type === "Error" ? errorImg : successImg,
        side === "Right" ? false : true,
        timeoutLengthMS
      );
    },
    [smallXIcon]
  );
}
