import React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { endOfDay, isAfter, isBefore, isValid } from "date-fns";
import { FormProvider, useForm } from "react-hook-form";

import { OpportunitiesFilterQueryParams } from "@fartherfinance/frontend/api/Opportunities/requests/getOpportunities";
import {
  OpportunityPriority,
  OpportunityStatus,
  OpportunityType,
} from "@fartherfinance/frontend/api/Opportunities/Types";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import { OPPORTUNITIES_DEFAULT_FILTERING_QUERY_PARAMS } from "../../common";
import { useClientsOptions } from "../../hooks";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import { EnhancedAutocompleteOption } from "@src/multiCustodian/pages/Advisor/common";
import {
  mapValueToLabel,
  opportunityPriorityMap,
  opportunityStatusFilterMap,
  opportunityTypeMap,
} from "@src/multiCustodian/pages/Advisor/Opportunities/utils";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormDateField from "@src/sharedComponents/Forms/FormDateField";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import FormDropdownMultiSelectField from "@src/sharedComponents/Forms/FormDropdownMultiSelectField";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import FormSubHeader from "@src/sharedComponents/Forms/FormSubHeader";
import Modal from "@src/sharedComponents/Modal/Modal";

import {
  transformFilterQueryParamsToFormValues,
  transformFormValuesToFilterQueryParams,
} from "./OpportunitiesFilterForm.utils";

import styles from "./OpportunitiesFilterForm.module.css";

const isDateValid = (date: Date | null): true | string => {
  return date && !isValid(date) ? "Invalid date" : true;
};

const isInFuture = (date: Date | null, maxDate: Date): true | string => {
  return date && isAfter(date, maxDate) ? "Cannot select future dates" : true;
};

const allOption: EnhancedAutocompleteOption<undefined> = {
  label: "All",
  value: undefined,
};

export interface FormData {
  filterByClient?: EnhancedAutocompleteOption<ClientId | undefined>;
  filterByType?: string[];
  filterByStatus?: string[];
  filterByPriority?: string[];
  filterByCreatedAfter?: Date | null;
  filterByCreatedBefore?: Date | null;
  filterByUpdatedAfter?: Date | null;
  filterByUpdatedBefore?: Date | null;
}

interface OpportunitiesFilterFormProps {
  currentFilters: OpportunitiesFilterQueryParams;
  onSubmit: (newFilters: OpportunitiesFilterQueryParams) => void;
  onClose: () => void;
}

export default function OpportunitiesFilterForm({
  currentFilters,
  onSubmit,
  onClose,
}: OpportunitiesFilterFormProps): JSX.Element {
  const clientsOptions = [allOption, ...useClientsOptions()];

  const form = useForm<FormData>({
    mode: "onChange",
    defaultValues: transformFilterQueryParamsToFormValues({
      filters: currentFilters,
      clientsOptions,
    }),
  });

  const maxDate = endOfDay(new Date());

  const handleFormSubmit = (): void => {
    form.handleSubmit((formData) => {
      onSubmit(transformFormValuesToFilterQueryParams(formData));
      onClose();
    })();
  };

  const handleFormReset = (): void => {
    // NOTE: this operation resets form to the global default state
    const transformedFormValues = transformFilterQueryParamsToFormValues({
      filters: OPPORTUNITIES_DEFAULT_FILTERING_QUERY_PARAMS,
      clientsOptions,
    });

    form.reset({
      ...transformedFormValues,
      filterByType: [],
      filterByPriority: [],
      filterByClient: allOption,
      filterByCreatedAfter: null,
      filterByCreatedBefore: null,
      filterByUpdatedAfter: null,
      filterByUpdatedBefore: null,
    });
  };

  const handleClearAutocompleteField = (field: keyof FormData): void => {
    form.resetField(field, { defaultValue: null });
  };

  return (
    <Modal closeModal={onClose}>
      <FormH1>Filter Opportunities</FormH1>
      <FormProvider {...form}>
        <form
          className={styles.form}
          onSubmit={form.handleSubmit(handleFormSubmit)}
        >
          <FlexWrapper>
            <FormDropdownField
              name="filterByClient"
              label="Client"
              values={clientsOptions}
              getOptionKey={(option) => option.value ?? option.label}
              onClear={() => handleClearAutocompleteField("filterByClient")}
            />
          </FlexWrapper>

          <FlexWrapper className={styles.flexWrapperMultiSelectRow}>
            <FormDropdownMultiSelectField
              name="filterByStatus"
              label="Status"
              values={OpportunityStatus.options.map((opportunityStatus) =>
                mapValueToLabel(opportunityStatusFilterMap, opportunityStatus)
              )}
            />
            <FormDropdownMultiSelectField
              name="filterByType"
              label="Type"
              values={OpportunityType.options.map((opportunityType) =>
                mapValueToLabel(opportunityTypeMap, opportunityType)
              )}
            />
            <FormDropdownMultiSelectField
              name="filterByPriority"
              label="Priority"
              values={OpportunityPriority.options.map((opportunityPriority) =>
                mapValueToLabel(opportunityPriorityMap, opportunityPriority)
              )}
            />
          </FlexWrapper>

          <FormSubHeader className={styles.subHeader}>
            Date Created
          </FormSubHeader>
          <FlexWrapper className={styles.flexWrapper}>
            <FormDateField
              name="filterByCreatedAfter"
              label="From Date"
              placeholder="Select a date"
              maxDate={maxDate}
              rules={{
                deps: ["filterByCreatedBefore"],
                validate: {
                  isDateValid,
                  isInFuture: (value) => isInFuture(value, maxDate),
                  isInRange: (value, { filterByCreatedBefore }) => {
                    return value && isAfter(value, filterByCreatedBefore)
                      ? "From date cannot be after To date"
                      : true;
                  },
                },
              }}
            />
            <FormDateField
              name="filterByCreatedBefore"
              label="To Date"
              placeholder="Select a date"
              maxDate={maxDate}
              rules={{
                deps: ["filterByCreatedAfter"],
                validate: {
                  isDateValid,
                  isInFuture: (value) => isInFuture(value, maxDate),
                  isInRange: (value, { filterByCreatedAfter }) => {
                    return value && isBefore(value, filterByCreatedAfter)
                      ? "To date cannot be before From date"
                      : true;
                  },
                },
              }}
            />
          </FlexWrapper>

          <FormSubHeader className={styles.subHeader}>
            Date Updated
          </FormSubHeader>
          <FlexWrapper className={styles.flexWrapper}>
            <FormDateField
              name="filterByUpdatedAfter"
              label="From Date"
              placeholder="Select a date"
              maxDate={maxDate}
              rules={{
                deps: ["filterByUpdatedBefore"],
                validate: {
                  isDateValid,
                  isInFuture: (value) => isInFuture(value, maxDate),
                  isInRange: (value, { filterByUpdatedBefore }) => {
                    return value && isAfter(value, filterByUpdatedBefore)
                      ? "From date cannot be after To date"
                      : true;
                  },
                },
              }}
            />
            <FormDateField
              name="filterByUpdatedBefore"
              label="To Date"
              placeholder="Select a date"
              maxDate={maxDate}
              rules={{
                deps: ["filterByUpdatedAfter"],
                validate: {
                  isDateValid,
                  isInFuture: (value) => isInFuture(value, maxDate),
                  isInRange: (value, { filterByUpdatedAfter }) => {
                    return value && isBefore(value, filterByUpdatedAfter)
                      ? "To date cannot be before From date"
                      : true;
                  },
                },
              }}
            />
          </FlexWrapper>

          <Stack
            className={styles.footer}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className={styles.resetText} onClick={handleFormReset}>
              Reset to default
            </Typography>

            <Stack direction="row" gap="20px">
              <Button
                variant="outlined"
                buttonType="primary"
                text="Cancel"
                onClick={onClose}
              />
              <Button
                variant="contained"
                buttonType="primary"
                text="Apply"
                onClick={handleFormSubmit}
              />
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </Modal>
  );
}
