import { FetchStatus, QueryStatus } from "@tanstack/react-query";
import lodashIsArray from "lodash/isArray";
import lodashIsElement from "lodash/isElement";
import lodashIsPlainObject from "lodash/isPlainObject";
import lodashIsString from "lodash/isString";

export function isArray<T, A>(array: A[] | T): array is A[] {
  return lodashIsArray(array);
}

export function isPlainObject<T>(
  object: T | Record<string, T>
): object is Record<string, T> {
  return lodashIsPlainObject(object);
}

export function isElement(value: unknown): value is HTMLElement {
  return lodashIsElement(value);
}

export const isStringDefinied = (value: unknown): value is string => {
  return lodashIsString(value) && value !== "";
};

export const isCollectionEmpty = (value: unknown): boolean => {
  return isArray(value) && value.length === 0;
};

export const calculateSkeletonCount = (
  pageSize: number,
  pageNumber: number,
  totalCount: number
): number => {
  const pageZero = totalCount === 0 ? pageSize : Math.min(pageSize, totalCount);
  const pageNth = Math.min(totalCount - pageNumber * pageSize, pageSize);

  return pageNumber === 0 ? pageZero : pageNth;
};

type Renderer = Partial<{
  onDefault: () => React.ReactNode;
  onPending: () => React.ReactNode;
  onSuccess: () => React.ReactNode;
  onFailure: () => React.ReactNode;
}>;

const noop = (): React.ReactNode => null;

const mapStatusToRenderer = (
  render: Renderer
): Record<QueryStatus | FetchStatus, () => React.ReactNode> => ({
  // query mappings
  loading: render.onPending ?? noop,
  error: render.onFailure ?? noop,
  success: render.onSuccess ?? noop,
  // fetch mappings
  fetching: render.onPending ?? noop,
  paused: noop,
  idle: noop,
});

export const matchReactQueryStatus =
  (status: QueryStatus | FetchStatus) =>
  (render: Renderer): React.ReactNode => {
    const renderer = mapStatusToRenderer(render)[status];

    return renderer();
  };

export const formatters = {
  numberFormatter: (options?: Intl.NumberFormatOptions) =>
    new Intl.NumberFormat("en-US", { maximumFractionDigits: 2, ...options }),
  percentFormatter: (options?: Intl.NumberFormatOptions) =>
    new Intl.NumberFormat("en-US", { style: "percent", ...options }),
  currencyFormatter: (options?: Intl.NumberFormatOptions) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
      ...options,
    }),
};

export const arrayToCsv = (array: (string | number | boolean)[][]): string => {
  return array
    .map(
      (row) =>
        row
          .map(String) // convert every value to String
          .join(",") // join values by comma
    )
    .join("\r\n"); // each subarray starts a new row
};
