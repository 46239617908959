import React, { useState } from "react";

import { isNil } from "lodash";
import { FormProvider, useForm, useFormState, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";

import useClientProfile from "@fartherfinance/frontend/api/Entity/hooks/useClientProfile";
import usePutInvestorProfile from "@fartherfinance/frontend/api/Entity/hooks/usePutInvestorProfile";
import { ClientInvestorProfile } from "@fartherfinance/frontend/api/Entity/requests/getClientProfile";
import { USAStates } from "@fartherfinance/frontend/api/Types";

import FormFooter from "../components/FormFooter/FormFooter";
import formatPhoneNumber from "../formatters/formatPhoneNumber";
import removePhoneNumberFormatting from "../formatters/removePhoneNumberFormatting";
import has5Digits from "../validators/has5Digits";
import isValidPhoneNumber from "../validators/isValidPhoneNumber";
import Portal from "@src/multiCustodian/components/Portal/Portal";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import FormH2 from "@src/sharedComponents/Forms/FormH2";
import FormNumberField from "@src/sharedComponents/Forms/FormNumberField";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import { State } from "@src/store";

import { Form } from "./Types";
import { mapDefaultValues } from "./utils/mapDefaultValues";

import styles from "../SharedFormStyle.module.css";

interface Props {
  drawerFooterPortalId: string;
  onClose: () => void;
}

export default function ContactFormFetcher(props: Props): JSX.Element {
  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));

  const auth = useRequestAuth();

  const clientProfile = useClientProfile(clientId, auth);

  if (clientProfile.isLoading) {
    return (
      <div className={styles.loading}>
        <LogoLoadingStill />
      </div>
    );
  }

  if (clientProfile.hasError) {
    return <div>Error</div>;
  }

  return (
    <ContactForm
      drawerFooterPortalId={props.drawerFooterPortalId}
      investorProfile={clientProfile.data.investorProfile}
      onClose={props.onClose}
    />
  );
}

interface ContactFormProps {
  drawerFooterPortalId: string;
  investorProfile: ClientInvestorProfile;
  onClose: () => void;
}

function ContactForm(props: ContactFormProps): JSX.Element {
  const { isAdvisor, clientId } = useSelector((state: State) => ({
    isAdvisor: state.main_Reducer.user.isAdvisor,
    clientId: state.main_Reducer.user.id_user,
  }));

  const [isMutating, setIsMutating] = useState(false);

  const auth = useRequestAuth();

  const statusNotification = useStatusNotification();

  const callPutInvestorProfile = usePutInvestorProfile(clientId, auth);

  const validateWholeForm = !isAdvisor;

  const methods = useForm<Form>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: mapDefaultValues(props.investorProfile),
  });

  const { control, getValues, setValue } = methods;

  const { isValid } = useFormState({
    control,
  });

  const [Address1, Address2, City, ZipCode] = useWatch({
    control,
    name: ["Address1", "Address2", "City", "ZipCode"],
  });

  const isStateRequired =
    [Address1, Address2, City, ZipCode].find((e) => !isNil(e)) !== undefined ||
    validateWholeForm;

  const isRequired = validateWholeForm ? "Must not be empty" : false;

  const isSubmitDisabled = !isValid || isMutating;

  const submit = async () => {
    if (isSubmitDisabled) {
      return;
    }

    const values = getValues();

    const { City, State, Address1, ZipCode } = values;

    const anyNull =
      City === null || State === null || Address1 === null || ZipCode === null;

    const address =
      (validateWholeForm && anyNull) || isNil(State)
        ? null
        : {
            //changing these to empty strings allows for partial form validation to work.
            //However, state can only be a valid USA State.
            city: City ?? "",
            state: State.label,
            street: Address1 ?? "",
            unit: Address2 ?? "",
            postalCode: ZipCode ?? "",
            country: props.investorProfile.address?.country ?? "US",
          };

    const phoneNumber = !isNil(values.PhoneNumber)
      ? removePhoneNumberFormatting(values.PhoneNumber)
      : null;

    const investorProfileForm: ClientInvestorProfile = {
      ...props.investorProfile,
      contactInformation: {
        ...props.investorProfile.contactInformation,
        phoneNumber,
      },
      address,
    };

    try {
      setIsMutating(true);

      await callPutInvestorProfile(investorProfileForm);

      statusNotification("Contact information updated.", "Success");

      props.onClose();
    } catch {
      setIsMutating(false);

      statusNotification("Failed to update contact information.", "Error");
    }
  };

  const clearState = (): void => setValue("State", null);

  return (
    <FormProvider {...methods}>
      <FormH1>Provide your contact information</FormH1>

      <Spacer />

      <FormH2>PRIMARY CONTACT</FormH2>

      <FlexWrapper>
        <FormTextField name="Email" label="Email" disabled />

        <FormTextField
          name="PhoneNumber"
          label="Phone Number"
          required={isRequired}
          valueFormatterOnChange={(e) => {
            const prevPhoneNumber = getValues("PhoneNumber");

            return !isNil(prevPhoneNumber)
              ? formatPhoneNumber(prevPhoneNumber, e)
              : e;
          }}
          valueFormatterOnBlur={(e) => {
            const prevPhoneNumber = getValues("PhoneNumber");

            return !isNil(prevPhoneNumber)
              ? formatPhoneNumber(prevPhoneNumber, e)
              : e;
          }}
          placeholder="(123) 123-1234"
          rules={{
            validate: {
              isValidPhoneNumber: (e) =>
                isValidPhoneNumber(e, validateWholeForm),
            },
          }}
        />
      </FlexWrapper>

      <Spacer />

      <FormH2>PRIMARY ADDRESS</FormH2>

      <FlexWrapper>
        <FormTextField name="Address1" label="Address" required={isRequired} />
        <FormTextField name="Address2" label="Address 2 (Optional)" />
        <FormTextField
          name="City"
          label="City"
          required={isRequired}
          rules={{
            minLength: {
              value: 3,
              message: "City must have 3 or more characters.",
            },
          }}
        />

        <FormDropdownField
          name="State"
          label="State"
          values={USAStates.map((e) => ({ label: e }))}
          required={isStateRequired ? "Must not be empty" : false}
          onClear={clearState}
        />

        <FormNumberField
          name="ZipCode"
          label="Zip Code"
          required={isRequired}
          rules={{
            validate: {
              has5Digits: (e) => has5Digits(e, validateWholeForm),
            },
          }}
        />
      </FlexWrapper>

      <Portal targetNodeId={props.drawerFooterPortalId}>
        <FormFooter isSubmitDisabled={isSubmitDisabled} handleSubmit={submit} />
      </Portal>

      {isMutating && (
        <div className={styles.loading}>
          <LogoLoadingStill />
        </div>
      )}
    </FormProvider>
  );
}
