import { useMemo } from "react";

import { AssetClassCategory } from "@fartherfinance/frontend/api/PerformanceGroups/requests/getHoldings";
import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

interface AssetClassCategoryColors {
  [key: AssetClassCategory]: string;
}

const useAssetCategoryColors = (): AssetClassCategoryColors => {
  const {
    color: {
      $chartSegmentOne,
      $chartSegmentTwo,
      $chartSegmentThree,
      $chartSegmentFour,
      $chartSegmentFive,
      $chartSegmentSix,
    },
  } = useTheme();

  return useMemo(() => {
    return {
      Equity: $chartSegmentOne,
      "Fixed Income": $chartSegmentTwo,
      Balanced: $chartSegmentThree,
      Cash: $chartSegmentFour,
      Alternative: $chartSegmentFive,
      Other: $chartSegmentSix,
      //Unidentified: $chartSegmentSix, // this category will get merged into "Other" category but just keeping this here for
    };
  }, [
    $chartSegmentFive,
    $chartSegmentFour,
    $chartSegmentOne,
    $chartSegmentSix,
    $chartSegmentThree,
    $chartSegmentTwo,
  ]);
};

export default useAssetCategoryColors;
