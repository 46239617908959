import React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { endOfDay, isAfter, isBefore } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FormProvider, useForm } from "react-hook-form";

import { TasksFilterQueryParams } from "@fartherfinance/frontend/api/Tasks/requests/getTasks";
import {
  TaskPriority,
  TaskType,
} from "@fartherfinance/frontend/api/Tasks/Types";
import { Custodian } from "@fartherfinance/frontend/api/Types";

import {
  custodianOptions,
  TaskAssigneeAutocompleteOption,
  TASKS_DEFAULT_FILTERING_QUERY_PARAMS,
} from "../../common";
import {
  useAdvisorsOptions,
  useAssigneesOptions,
  useClientsOptions,
  useTaskStatusOptions,
} from "../../hooks";
import { isDateValid } from "../../utils";
import {
  mapTaskPriorityToLabel,
  mapTaskStatusToLabel,
  mapTaskTypeToLabel,
} from "../TasksTable/TasksTable.utils";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import { EnhancedAutocompleteOption } from "@src/multiCustodian/pages/Advisor/common";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormDateField from "@src/sharedComponents/Forms/FormDateField";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import FormDropdownMultiSelectField from "@src/sharedComponents/Forms/FormDropdownMultiSelectField";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import FormSubHeader from "@src/sharedComponents/Forms/FormSubHeader";
import Modal from "@src/sharedComponents/Modal/Modal";

import {
  transformFilterQueryParamsToFormValues,
  transformFormValuesToFilterQueryParams,
} from "./TasksFilterForm.utils";

import styles from "./TasksFilterForm.module.css";

const isInFuture = (date: Date | null, maxDate: Date): boolean | string => {
  return date && isAfter(date, maxDate) ? "Cannot select future dates" : true;
};

const allOption: EnhancedAutocompleteOption<undefined> = {
  label: "All",
  value: undefined,
};
const generationOptions = [
  allOption,
  {
    label: "System generated",
    value: true,
  },
  {
    label: "Manually created",
    value: false,
  },
];
const allCustodianOptions = [allOption, ...custodianOptions];

export interface FormData {
  filterByAssignee?:
    | EnhancedAutocompleteOption<undefined>
    | TaskAssigneeAutocompleteOption<string>;
  filterByClient?: EnhancedAutocompleteOption<string | undefined>;
  filterByAdvisor?: EnhancedAutocompleteOption<string | undefined>;
  filterByTaskStatus?: string[];
  filterByTaskType?: string[];
  filterByTaskPriority?: string[];
  filterByCustodian?: EnhancedAutocompleteOption<Custodian | undefined>;
  filterByCreatedAfter?: Date | null;
  filterByCreatedBefore?: Date | null;
  filterByUpdatedAfter?: Date | null;
  filterByUpdatedBefore?: Date | null;
  filterByDueDateAfter?: Date | null;
  filterByDueDateBefore?: Date | null;
  filterByIsSystemGenerated?: EnhancedAutocompleteOption<boolean | undefined>;
}

interface TasksFilterFormProps {
  currentFilters: TasksFilterQueryParams;
  onSubmit: (newFilters: TasksFilterQueryParams) => void;
  onClose: () => void;
}

export default function TasksFilterForm({
  currentFilters,
  onSubmit,
  onClose,
}: TasksFilterFormProps): JSX.Element {
  const { enableNewTaskStatus } = useFlags();
  const clientsOptions = [allOption, ...useClientsOptions()];
  const advisorsOptions = [allOption, ...useAdvisorsOptions()];
  const assigneesOptions = [allOption, ...useAssigneesOptions()];
  const taskStatusOptions = useTaskStatusOptions();

  const form = useForm<FormData>({
    mode: "onChange",
    defaultValues: transformFilterQueryParamsToFormValues({
      filters: currentFilters,
      clientsOptions,
      advisorsOptions,
      assigneesOptions,
      generationOptions,
      custodianOptions: allCustodianOptions,
      enableNewTaskStatus,
    }),
  });

  const maxDate = endOfDay(new Date());

  const handleFormSubmit = (): void => {
    form.handleSubmit((formData) => {
      onSubmit(
        transformFormValuesToFilterQueryParams(formData, enableNewTaskStatus)
      );
      onClose();
    })();
  };

  const handleFormReset = (): void => {
    // NOTE: this operation resets form to the global default state
    const transformedFormValues = transformFilterQueryParamsToFormValues({
      filters: TASKS_DEFAULT_FILTERING_QUERY_PARAMS,
      clientsOptions,
      advisorsOptions,
      assigneesOptions,
      generationOptions,
      custodianOptions: allCustodianOptions,
      enableNewTaskStatus,
    });

    form.reset({
      ...transformedFormValues,
      filterByTaskType: [],
      filterByAssignee: allOption,
      filterByAdvisor: allOption,
      filterByClient: allOption,
      filterByCustodian: allOption,
      filterByIsSystemGenerated: allOption,
      filterByCreatedAfter: null,
      filterByCreatedBefore: null,
      filterByUpdatedAfter: null,
      filterByUpdatedBefore: null,
      filterByDueDateAfter: null,
      filterByDueDateBefore: null,
    });
  };

  const handleClearAutocompleteField = (field: keyof FormData): void => {
    form.resetField(field, { defaultValue: null });
  };

  return (
    <Modal closeModal={onClose}>
      <FormH1>Filter Tasks</FormH1>
      <FormProvider {...form}>
        <form
          className={styles.form}
          onSubmit={form.handleSubmit(handleFormSubmit)}
        >
          <FlexWrapper>
            <FormDropdownField
              name="filterByAssignee"
              label="Assignee"
              values={assigneesOptions}
              getOptionKey={(option) => option.value ?? option.label}
              onClear={() => handleClearAutocompleteField("filterByAssignee")}
            />
            <FormDropdownField
              name="filterByAdvisor"
              label="Advisor"
              values={advisorsOptions}
              getOptionKey={(option) => option.value ?? option.label}
              onClear={() => handleClearAutocompleteField("filterByAdvisor")}
            />
            <FormDropdownField
              name="filterByClient"
              label="Client"
              values={clientsOptions}
              getOptionKey={(option) => option.value ?? option.label}
              onClear={() => handleClearAutocompleteField("filterByClient")}
            />
            <FormDropdownField
              name="filterByIsSystemGenerated"
              label="Generation Type"
              values={generationOptions}
              getOptionKey={(option) => option.label}
              onClear={() =>
                handleClearAutocompleteField("filterByIsSystemGenerated")
              }
            />
          </FlexWrapper>

          <FlexWrapper className={styles.flexWrapperMultiSelectRow}>
            <FormDropdownMultiSelectField
              name="filterByTaskStatus"
              label="Status"
              values={taskStatusOptions.map((taskStatus) =>
                mapTaskStatusToLabel(taskStatus, enableNewTaskStatus)
              )}
            />
            <FormDropdownMultiSelectField
              name="filterByTaskPriority"
              label="Priority"
              values={TaskPriority.options.map(mapTaskPriorityToLabel)}
            />
            <FormDropdownMultiSelectField
              name="filterByTaskType"
              label="Type"
              values={TaskType.options.map(mapTaskTypeToLabel)}
            />
            <FormDropdownField
              name="filterByCustodian"
              label="Custodian"
              values={allCustodianOptions}
              getOptionKey={(option) => option.value ?? option.label}
              onClear={() => handleClearAutocompleteField("filterByCustodian")}
            />
          </FlexWrapper>

          <FormSubHeader className={styles.subHeader}>
            Date Created
          </FormSubHeader>
          <FlexWrapper className={styles.flexWrapper}>
            <FormDateField
              name="filterByCreatedAfter"
              label="From"
              placeholder="Select a date"
              maxDate={maxDate}
              rules={{
                deps: ["filterByCreatedBefore"],
                validate: {
                  isDateValid,
                  isInFuture: (value) => isInFuture(value, maxDate),
                  isInRange: (value, { filterByCreatedBefore }) => {
                    return value && isAfter(value, filterByCreatedBefore)
                      ? "From date cannot be after To date"
                      : true;
                  },
                },
              }}
            />
            <FormDateField
              name="filterByCreatedBefore"
              label="To"
              placeholder="Select a date"
              maxDate={maxDate}
              rules={{
                deps: ["filterByCreatedAfter"],
                validate: {
                  isDateValid,
                  isInFuture: (value) => isInFuture(value, maxDate),
                  isInRange: (value, { filterByCreatedAfter }) => {
                    return value && isBefore(value, filterByCreatedAfter)
                      ? "To date cannot be before From date"
                      : true;
                  },
                },
              }}
            />
          </FlexWrapper>

          <FormSubHeader className={styles.subHeader}>Due Date</FormSubHeader>
          <FlexWrapper className={styles.flexWrapper}>
            <FormDateField
              name="filterByDueDateAfter"
              label="From"
              placeholder="Select a date"
              rules={{
                deps: ["filterByDueDateBefore"],
                validate: {
                  isDateValid,
                  isInRange: (value, { filterByDueDateBefore }) => {
                    return value && isAfter(value, filterByDueDateBefore)
                      ? "From date cannot be after To date"
                      : true;
                  },
                },
              }}
            />
            <FormDateField
              name="filterByDueDateBefore"
              label="To"
              placeholder="Select a date"
              rules={{
                deps: ["filterByDueDateAfter"],
                validate: {
                  isDateValid,
                  isInRange: (value, { filterByDueDateAfter }) => {
                    return value && isBefore(value, filterByDueDateAfter)
                      ? "To date cannot be before From date"
                      : true;
                  },
                },
              }}
            />
          </FlexWrapper>

          <FormSubHeader className={styles.subHeader}>
            Date Updated
          </FormSubHeader>
          <FlexWrapper className={styles.flexWrapper}>
            <FormDateField
              name="filterByUpdatedAfter"
              label="From"
              placeholder="Select a date"
              maxDate={maxDate}
              rules={{
                deps: ["filterByUpdatedBefore"],
                validate: {
                  isDateValid,
                  isInFuture: (value) => isInFuture(value, maxDate),
                  isInRange: (value, { filterByUpdatedBefore }) => {
                    return value && isAfter(value, filterByUpdatedBefore)
                      ? "From date cannot be after To date"
                      : true;
                  },
                },
              }}
            />
            <FormDateField
              name="filterByUpdatedBefore"
              label="To"
              placeholder="Select a date"
              maxDate={maxDate}
              rules={{
                deps: ["filterByUpdatedAfter"],
                validate: {
                  isDateValid,
                  isInFuture: (value) => isInFuture(value, maxDate),
                  isInRange: (value, { filterByUpdatedAfter }) => {
                    return value && isBefore(value, filterByUpdatedAfter)
                      ? "To date cannot be before From date"
                      : true;
                  },
                },
              }}
            />
          </FlexWrapper>

          <Stack
            className={styles.footer}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className={styles.resetText} onClick={handleFormReset}>
              Reset to default
            </Typography>

            <Stack direction="row" gap="20px">
              <Button
                variant="outlined"
                buttonType="primary"
                text="Cancel"
                onClick={onClose}
              />
              <Button
                variant="contained"
                buttonType="primary"
                text="Apply"
                onClick={handleFormSubmit}
              />
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </Modal>
  );
}
