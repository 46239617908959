import React, { useState } from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MuiTableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import styles from "./TableCell.module.css";

interface TableCellProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  colSpan?: number;
}

const dashSpaceRegex = /^(\s|-)+$/;

export default function TableCell({
  children,
  className,
  onClick,
  colSpan,
  style,
}: React.PropsWithChildren<TableCellProps>): JSX.Element {
  const [showTooltip, setShowTooltip] = useState(false);

  const allowCopying =
    typeof children === "string" &&
    children !== "" &&
    !dashSpaceRegex.test(children);

  const onCopyClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    if (!allowCopying) {
      return;
    }

    setShowTooltip(true);

    setTimeout(() => setShowTooltip(false), 800);

    navigator.clipboard.writeText(children);
  };

  const closeTooltip = () => {
    if (showTooltip) {
      setShowTooltip(false);
    }
  };

  return (
    <MuiTableCell
      className={toClassName(styles.cell, className)}
      onClick={onClick}
      onMouseLeave={closeTooltip}
      colSpan={colSpan}
      sx={style}
    >
      <Typography component="div" className={styles.text}>
        {children}
      </Typography>

      {allowCopying && (
        <div className={styles.copyButtonContainer}>
          <Tooltip
            tooltipText="Copied"
            placement="top"
            disableHoverListener
            open={showTooltip}
          >
            <div className={styles.iconContainer} onClick={onCopyClick}>
              <ContentCopyIcon
                classes={{
                  root: styles.copyIcon,
                }}
              />
            </div>
          </Tooltip>
        </div>
      )}
    </MuiTableCell>
  );
}
