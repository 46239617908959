import React, { useMemo } from "react";

import { isSameDay, parse } from "date-fns";

import formatDateForGraph from "../../../utils/formatDateForGraph";
import { dateFormat } from "@src/constants/dateFormat";

import styles from "./EndDate.module.css";

type YYYYMMDD = string; //yyyy-MM-dd

interface Props {
  endDate: YYYYMMDD | Date | null;
}

/** Used to display the end date at the bottom right of the Summary graph
 * null endDate will return 'Today'
 * A date that has the same day as today will also return 'Today'
 * Otherwise it will return the following format: Jan 1, 2024
 */
const EndDate = (props: Props): JSX.Element => {
  const endDate = useMemo(() => {
    if (props.endDate === null) {
      return "Today";
    }

    const date =
      typeof props.endDate === "string"
        ? parse(props.endDate, dateFormat, new Date())
        : props.endDate;

    if (isSameDay(new Date(), date)) {
      return "Today";
    }

    return formatDateForGraph(date);
  }, [props.endDate]);

  return <div className={styles.endDate}>{endDate}</div>;
};

export default EndDate;
