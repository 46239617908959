import React, { PropsWithChildren } from "react";

import { PerformanceGroupId } from "@fartherfinance/frontend/api/Types";

import PerformanceGroupAccountActivityV4 from "@src/multiCustodian/components/PerformanceGroups/Activity/ActivityV4";

interface Props {
  groupId: PerformanceGroupId | null;
}

export default function ActivityTabV4(
  props: PropsWithChildren<Props>
): JSX.Element {
  return (
    <PerformanceGroupAccountActivityV4
      accountId={null}
      performanceGroupId={props.groupId}
    >
      {props.children}
    </PerformanceGroupAccountActivityV4>
  );
}
