import React, { useState } from "react";

import { SvgIconTypeMap } from "@mui/material";
import MuiMenu, { MenuProps as MuiMenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import Typography from "@mui/material/Typography";
import isElement from "lodash/isElement";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./Menu.module.css";

export type MenuOption = {
  label: string;
  onClick: React.MouseEventHandler<HTMLLIElement>;
  className?: string;
  isDisabled?: boolean;
  IconComponent?: OverridableComponent<SvgIconTypeMap<unknown, "svg">> & {
    muiName: string;
  };
};

interface MenuProps
  extends Omit<MuiMenuProps, "open" | "anchorEl" | "onClick" | "onClose"> {
  children: (props: {
    isMenuOpen: boolean;
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void;
  }) => React.ReactNode;
  options: MenuOption[];
}

export default function Menu({
  children,
  options,
  ...restProps
}: MenuProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = isElement(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      {children({ isMenuOpen, handleOpenMenu })}

      <MuiMenu
        classes={{ paper: styles.menuPaper }}
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        autoFocus={false}
        PaperProps={{ elevation: 0 }}
        MenuListProps={{ className: styles.menuList }}
        {...restProps}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            className={styles.menuItem}
            disabled={option.isDisabled}
            onClick={option.onClick}
          >
            {option.IconComponent && (
              <option.IconComponent className={styles.menuItemIcon} />
            )}
            <Typography
              className={toClassName(styles.menuItemText, option.className)}
            >
              {option.label}
            </Typography>
          </MenuItem>
        ))}
      </MuiMenu>
    </>
  );
}
