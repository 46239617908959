export default function calculateSubIndex<T>(
  buckets: T[][],
  bucket_idx: number,
  idx: number
): number {
  return buckets.reduce((sum, cur_bucket, reduce_idx) => {
    if (reduce_idx > bucket_idx) {
      return sum;
    }

    if (reduce_idx === bucket_idx) {
      return sum + idx;
    }

    return sum + cur_bucket.length;
  }, 0);
}
