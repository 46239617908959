import React from "react";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import Modal from "@src/sharedComponents/Modal/Modal";

import styles from "./DeleteModal.module.css";

interface Props {
  onDelete: () => void;
  hideModal: () => void;
}

const DeleteModal: React.FC<Props> = ({ onDelete, hideModal }) => {
  return (
    <Modal closeModal={hideModal} className={styles.container}>
      <div className={styles.title}>Delete Collaborator</div>

      <div className={styles.text}>
        By selecting "Delete," you permanently remove this collaborator. Please
        note that this action cannot be undone.
      </div>

      <div className={styles.footer}>
        <Button
          variant={"outlined"}
          buttonType={"secondary"}
          text={"Cancel"}
          onClick={hideModal}
        />

        <Button
          variant={"outlined"}
          buttonType={"warning"}
          text={"Delete"}
          onClick={onDelete}
          sx={{ marginLeft: "var(--space-2)" }}
        />
      </div>
    </Modal>
  );
};

export default DeleteModal;
