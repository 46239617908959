import React, { useEffect } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";
import { Redirect, useLocation, useParams } from "react-router-dom";

import {
  ClientId,
  FartherAccountId,
  PortfolioId,
  SleeveId,
} from "@fartherfinance/frontend/api/Types";

import Accounts from "@src/multiCustodian/pages/Client/:clientId/Accounts";
import AccountActivity from "@src/multiCustodian/pages/Client/:clientId/Accounts/Activity";
import AddAccount from "@src/multiCustodian/pages/Client/:clientId/Accounts/AddAccount/AddAccount";
import AddManualAccount from "@src/multiCustodian/pages/Client/:clientId/Accounts/AddManualAccount/AddManualAccount";
import AddManualAccountDetails from "@src/multiCustodian/pages/Client/:clientId/Accounts/AddManualAccount/AddManualAccountDetails";
import AddOffPortalAccount from "@src/multiCustodian/pages/Client/:clientId/Accounts/AddOffPortalAccount/AddOffPortalAccount";
import AccountHoldings from "@src/multiCustodian/pages/Client/:clientId/Accounts/Holdings";
import LinkExternalAccount from "@src/multiCustodian/pages/Client/:clientId/Accounts/LinkExternalAccount/PlaidLink";
import SetTargetGoal from "@src/multiCustodian/pages/Client/:clientId/Accounts/Managed/:accountId/:path/SetTargetGoal";
import RenameAccount from "@src/multiCustodian/pages/Client/:clientId/Accounts/Managed/:accountId/Settings/Rename";
import ManualAssetActivity from "@src/multiCustodian/pages/Client/:clientId/Accounts/Manual/:accountId/Activity";
import ManualAssetSettings from "@src/multiCustodian/pages/Client/:clientId/Accounts/Manual/:accountId/Settings";
import ManualAssetsSummary from "@src/multiCustodian/pages/Client/:clientId/Accounts/Manual/:accountId/Summary";
import EditDocument from "@src/multiCustodian/pages/Client/:clientId/Accounts/Manual/Settings/EditDocument";
import RenameAltAsset from "@src/multiCustodian/pages/Client/:clientId/Accounts/Manual/Settings/RenameAccount";
import UpdateValue from "@src/multiCustodian/pages/Client/:clientId/Accounts/Manual/Settings/UpdateValue";
import AccountProjections from "@src/multiCustodian/pages/Client/:clientId/Accounts/Projections";
import RemoveExternalAccount from "@src/multiCustodian/pages/Client/:clientId/Accounts/RemoveExternalAccount";
import AccountSettings from "@src/multiCustodian/pages/Client/:clientId/Accounts/Settings";
import AccountSummary from "@src/multiCustodian/pages/Client/:clientId/Accounts/Summary";
import Advisor from "@src/multiCustodian/pages/Client/:clientId/Advisor";
import AdvisorLiquidation from "@src/multiCustodian/pages/Client/:clientId/AdvisorLiquidation";
import CashManagement from "@src/multiCustodian/pages/Client/:clientId/CashManagement";
import CancelUpcomingTransfer from "@src/multiCustodian/pages/Client/:clientId/CashManagement/CancelUpcomingTransfer";
import ChangeFundingAccount from "@src/multiCustodian/pages/Client/:clientId/CashManagement/ChangeFundingAccount";
import AddManualFundingAccount from "@src/multiCustodian/pages/Client/:clientId/CashManagement/ChangeFundingAccount/AddManualFundingAccount";
import RemoveRecurring from "@src/multiCustodian/pages/Client/:clientId/CashManagement/RemoveRecurring";
import SetupRecurring from "@src/multiCustodian/pages/Client/:clientId/CashManagement/SetupRecurring";
import DocumentsCenter from "@src/multiCustodian/pages/Client/:clientId/DocumentsCenter";
import CreateUpdateFinancialPlan from "@src/multiCustodian/pages/Client/:clientId/FinancialPlans/CreateUpdatePlan";
import FinancialPlans from "@src/multiCustodian/pages/Client/:clientId/FinancialPlans/FinancialPlans";
import InsuranceMarketplace from "@src/multiCustodian/pages/Client/:clientId/InsuranceMarketplace";
import Investments from "@src/multiCustodian/pages/Client/:clientId/Investments";
import AltAssetsMarketplace from "@src/multiCustodian/pages/Client/:clientId/Investments/AltAssetsMarketplace";
import AltAssetsOfferingDetailsClient from "@src/multiCustodian/pages/Client/:clientId/Investments/AltAssetsMarketplace/AltAssetsOfferingDetailsClient";
import AltAssetsOfferingsList from "@src/multiCustodian/pages/Client/:clientId/Investments/AltAssetsMarketplace/AltAssetsOfferingsListClient";
import LendingMarketplace from "@src/multiCustodian/pages/Client/:clientId/LendingMarketplace";
import InsuranceOfferingDetails from "@src/multiCustodian/pages/Client/:clientId/Marketplace/OfferingDetailsClient/InsuranceOfferingDetailsClient";
import LendingOfferingDetails from "@src/multiCustodian/pages/Client/:clientId/Marketplace/OfferingDetailsClient/LendingOfferingDetailsClient";
import InsuranceOfferingsList from "@src/multiCustodian/pages/Client/:clientId/Marketplace/OfferingsListClient/InsuranceOfferingsListClient";
import LendingOfferingsList from "@src/multiCustodian/pages/Client/:clientId/Marketplace/OfferingsListClient/LendingOfferingsListClient";
import Portfolios from "@src/multiCustodian/pages/Client/:clientId/Portfolios";
import PortfolioSleeveDetails from "@src/multiCustodian/pages/Client/:clientId/Portfolios/:portfolioId/:sleeveId/SleeveDetails";
import PortfolioSleeveUsage from "@src/multiCustodian/pages/Client/:clientId/Portfolios/:portfolioId/:sleeveId/SleeveUsage";
import PortfolioAccounts from "@src/multiCustodian/pages/Client/:clientId/Portfolios/:portfolioId/Accounts";
import ClientEquivalentSecurities from "@src/multiCustodian/pages/Client/:clientId/Portfolios/:portfolioId/EquivalentSecurities";
import PortfolioModelDetails from "@src/multiCustodian/pages/Client/:clientId/Portfolios/:portfolioId/ModelDetails";
import ChoosePortfolioType from "@src/multiCustodian/pages/Client/:clientId/Portfolios/Create";
import PortfolioType from "@src/multiCustodian/pages/Client/:clientId/Portfolios/Create/:portfolioType";
import CustomPortfolio from "@src/multiCustodian/pages/Client/:clientId/Portfolios/Create/Custom";
import CustomPortfolioAllocation from "@src/multiCustodian/pages/Client/:clientId/Portfolios/Create/Custom/Allocation";
import CustomPortfolioConfirm from "@src/multiCustodian/pages/Client/:clientId/Portfolios/Create/Custom/Confirm";
import CustomPortfolioAddSecurities from "@src/multiCustodian/pages/Client/:clientId/Portfolios/Create/Custom/Securities";
import PlaidRelink from "@src/multiCustodian/pages/Client/:clientId/Relink";
import SharingAddCollaborator from "@src/multiCustodian/pages/Client/:clientId/Sharing/AddCollaborator";
import SharingEditCollaborator from "@src/multiCustodian/pages/Client/:clientId/Sharing/EditCollaborator";
import Sharing from "@src/multiCustodian/pages/Client/:clientId/Sharing/Sharing";
import TaxBudgetClientCreateEdit from "@src/multiCustodian/pages/Client/:clientId/TaxBudgets/CreateEdit";
import TaxBudgetClientCreateHypothetical from "@src/multiCustodian/pages/Client/:clientId/TaxBudgets/CreateHypothetical";
import TaxBudgetClientDetails from "@src/multiCustodian/pages/Client/:clientId/TaxBudgets/Details";
import TaxBudgetClientList from "@src/multiCustodian/pages/Client/:clientId/TaxBudgets/List";
import DashboardDataFetch from "@src/multiCustodian/pages/Dashboard/DashboardDataFetch";
import EnableMFA from "@src/multiCustodian/pages/Dashboard/EnableMFA/EnableMFA";
import Transfers from "@src/multiCustodian/pages/Dashboard/Transfers/Transfers";
import { trackEvent } from "@src/multiCustodian/services/tracking";
import { State } from "@src/store";

import PrivateRoute from "./PrivateRoute";
import RedirectWithFlag from "./RedirectWithFlag";
import RedirectWithLocation from "./RedirectWithLocation";
import { RouteElement } from "./Router.types";
import {
  AccountsPath,
  AltAssetsMarketplacePath,
  CashManagementPath,
  DocumentsCenterPath,
  FinancialPlansPath,
  InsuranceMarketplacePath,
  InvestmentsPath,
  LendingMarketplacePath,
  LiquidationPath,
  SharingPath,
} from "./RouterPaths";
import { NoMatch } from "./UtilityComponents";

function DashboardRouter(): JSX.Element {
  const { clientId } = useParams<{ clientId: string }>();
  return <Redirect to={`/Client/${clientId}/Dashboard`} exact push={false} />;
}

export const clientRoutes: RouteElement[] = [
  {
    path: "/Client",
    component: function RouteToClientDashboard(): JSX.Element {
      return <Redirect to="/Dashboard" exact push={false} />;
    },
  },
  {
    path: "/Dashboard",
    component: function RouteToClientDashboard(): JSX.Element {
      const { clientId } = useSelector((state: State) => ({
        clientId: state.main_Reducer.user.id_user,
      }));

      if (clientId === null) {
        return <Redirect to={"/Login"} exact push={false} />;
      }

      return <Redirect to={`/Client/${clientId}`} exact push={false} />;
    },
  },
  {
    path: "/Client/:clientId",
    component: PrivateRoute,
    noMatch: NoMatch,
    routes: [
      {
        path: "/Client/:clientId",
        component: DashboardRouter,
      },
      { path: "/Client/:clientId/Dashboard", component: DashboardDataFetch },
      {
        path: "/Client/:clientId/Advisor",
        component: Advisor,
        trackingEvent: { name: "Client Open Advisor" },
      },
      {
        path: "/Client/:clientId/EnableMFA",
        component: EnableMFA,
      },
      // **** START Investments ****
      {
        path: `/Client/:clientId/${InvestmentsPath}`,
        component: Investments,
        routes: [
          // **** Root Redirector ****
          {
            path: `/Client/:clientId/${InvestmentsPath}`,
            component: function ClientInvestmentsRedirect(): JSX.Element {
              const { clientId } = useParams<{
                clientId: ClientId;
              }>();

              return (
                <Redirect
                  to={`/Client/${clientId}/${InvestmentsPath}/Portfolios`}
                  exact
                  push={false}
                />
              );
            },
          },
          // **** Model Portfolios ****
          {
            path: `/Client/:clientId/${InvestmentsPath}/Portfolios`,
            component: () => <Portfolios atRoot />,
          },
          {
            path: `/Client/:clientId/${InvestmentsPath}/Portfolios/Create`,
            component: ChoosePortfolioType,
            trackingEvent: { name: "Client Open Choose Portfolio Type" },
          },
          {
            path: `/Client/:clientId/${InvestmentsPath}/Portfolios/Create/Custom`,
            component: CustomPortfolio,
            trackingEvent: { name: "Client Open Create Custom Portfolio" },
          },
          {
            path: `/Client/:clientId/${InvestmentsPath}/Portfolios/Create/Custom/Securities`,
            component: CustomPortfolioAddSecurities,
            trackingEvent: { name: "Client Add Custom Model Securities" },
          },
          {
            path: `/Client/:clientId/${InvestmentsPath}/Portfolios/Create/Custom/Allocation`,
            component: CustomPortfolioAllocation,
            trackingEvent: {
              name: "Client Set Custom Model Securities Allocation",
            },
          },
          {
            path: `/Client/:clientId/${InvestmentsPath}/Portfolios/Create/Custom/Confirm`,
            component: CustomPortfolioConfirm,
            trackingEvent: { name: "Client Confirm Custom Model" },
          },
          {
            path: `/Client/:clientId/${InvestmentsPath}/Portfolios/Create/:portfolioType`,
            component: PortfolioType,
            trackingEvent: { name: "Client Open Essential Model Selector" },
          },
          {
            path: `/Client/:clientId/${InvestmentsPath}/Portfolios/:portfolioId`,
            component: function PortfolioTradingGroup(): JSX.Element {
              const { clientId, portfolioId } = useParams<{
                clientId: ClientId;
                portfolioId: PortfolioId;
              }>();

              useEffect(() => {
                trackEvent({
                  name: "Client Open Portfolios Details",
                });
              }, []);

              return (
                <RedirectWithLocation
                  pathname={`/Client/${clientId}/${InvestmentsPath}/Portfolios/${portfolioId}/Accounts`}
                />
              );
            },
          },
          {
            path: `/Client/:clientId/${InvestmentsPath}/Portfolios/:portfolioId/Accounts`,
            component: PortfolioAccounts,
            trackingEvent: { name: "Client Open Portfolio Accounts" },
          },
          {
            path: `/Client/:clientId/${InvestmentsPath}/Portfolios/:portfolioId/Accounts/EquivalentSecurities`,
            component: ClientEquivalentSecurities,
            trackingEvent: { name: "Client Open Equivalent Securities" },
          },
          {
            path: `/Client/:clientId/${InvestmentsPath}/Portfolios/:portfolioId/ModelDetails`,
            component: PortfolioModelDetails,
            trackingEvent: { name: "Client Open Portfolio Model Details" },
          },
          {
            // Sleeves in the client portal will (for the time being) be viewed and clicked on only when inside of a client's portfolio (in model drawer) - this is represented in the route architecture
            path: `/Client/:clientId/${InvestmentsPath}/Portfolios/:portfolioId/Sleeve/:sleeveId`,
            component: function PortfolioSleeve(): JSX.Element {
              const { clientId, portfolioId, sleeveId } = useParams<{
                clientId: ClientId;
                portfolioId: PortfolioId;
                sleeveId: SleeveId;
              }>();

              useEffect(() => {
                trackEvent({
                  name: "Client Open Portfolios Details",
                });
              }, []);

              return (
                <Redirect
                  to={`/Client/${clientId}/${InvestmentsPath}/Portfolios/${portfolioId}/Sleeve/${sleeveId}/SleeveDetails`}
                  exact
                  push={false}
                />
              );
            },
          },
          {
            path: `/Client/:clientId/${InvestmentsPath}/Portfolios/:portfolioId/Sleeve/:sleeveId/SleeveDetails`,
            component: PortfolioSleeveDetails,
            trackingEvent: { name: "Client Open Portfolio Sleeve Details" },
          },
          {
            path: `/Client/:clientId/${InvestmentsPath}/Portfolios/:portfolioId/Sleeve/:sleeveId/SleeveUsage`,
            component: PortfolioSleeveUsage,
            trackingEvent: { name: "Client Open Portfolio Sleeve Usage" },
          },
          // **** Alternative Assets ****
          {
            path: `/Client/:clientId/${AltAssetsMarketplacePath}`,
            component: AltAssetsMarketplace,
            trackingEvent: { name: "Client Open Alternative Assets" },
          },
          {
            path: `/Client/:clientId/${AltAssetsMarketplacePath}/:offeringType`,
            component: AltAssetsOfferingsList,
          },
          {
            path: `/Client/:clientId/${AltAssetsMarketplacePath}/:offeringType/:offeringId`,
            component: AltAssetsOfferingDetailsClient,
          },
        ],
      },
      // **** END   Investments ****
      // **** START Funding Settings ****
      {
        path: `/Client/:clientId/${CashManagementPath}`,
        component: CashManagement,
        trackingEvent: { name: "Client Open Cash Management" },
      },
      {
        path: `/Client/:clientId/${CashManagementPath}/ChangeFundingAccount`,
        component: ChangeFundingAccount,
        trackingEvent: {
          name: "Client Open Cash Management Change Funding Account",
        },
      },
      {
        path: `/Client/:clientId/${CashManagementPath}/SetupRecurring`,
        component: SetupRecurring,
        trackingEvent: { name: "Client Open Cash Management Setup Recurring" },
      },
      {
        path: `/Client/:clientId/${CashManagementPath}/CancelUpcomingTransfer`,
        component: CancelUpcomingTransfer,
        trackingEvent: {
          name: "Client Open Cash Management Cancel Upcoming Transfer",
        },
      },
      {
        path: `/Client/:clientId/${CashManagementPath}/RemoveRecurring`,
        component: RemoveRecurring,
        trackingEvent: { name: "Client Open Cash Management Remove Recurring" },
      },
      {
        path: `/Client/:clientId/${CashManagementPath}/ChangeFundingAccount/AddManualFundingAccount`,
        component: AddManualFundingAccount,
        trackingEvent: {
          name: "Client Open Cash Management Add Manual Funding Account",
        },
      },
      // **** END   Funding Settings
      {
        path: `/Client/:clientId/Relink`,
        component: PlaidRelink,
        trackingEvent: { name: "Client Open Plaid Relink" },
      },
      {
        path: `/Client/:clientId/${SharingPath}`,
        component: Sharing,
        trackingEvent: { name: "Client Open Sharing" },
      },
      {
        path: `/Client/:clientId/${SharingPath}/AddCollaborator`,
        component: SharingAddCollaborator,
        trackingEvent: { name: "Client Open Sharing Add Collaborator" },
      },
      {
        path: `/Client/:clientId/${SharingPath}/EditCollaborator/:collaboratorId`,
        component: SharingEditCollaborator,
        trackingEvent: { name: "Client Open Sharing Edit Collaborator" },
      },
      {
        path: `/Client/:clientId/${FinancialPlansPath}`,
        component: function FinancialPlansRedirect(): JSX.Element {
          const { enableGoalBasedFinancialPlanning } = useFlags();

          return (
            <RedirectWithFlag
              flag={enableGoalBasedFinancialPlanning}
              redirectPath={"/Client"}
            >
              <FinancialPlans />
            </RedirectWithFlag>
          );
        },
      },
      {
        path: `/Client/:clientId/${FinancialPlansPath}/CreateUpdate`,
        component: function CreateUpdateFinancialPlanRedirect(): JSX.Element {
          const { enableGoalBasedFinancialPlanning } = useFlags();

          return (
            <RedirectWithFlag
              flag={enableGoalBasedFinancialPlanning}
              redirectPath={"/Client"}
            >
              <CreateUpdateFinancialPlan />
            </RedirectWithFlag>
          );
        },
      },
      {
        path: `/Client/:clientId/Transfers`,
        component: Transfers,
        trackingEvent: { name: "Client Open Transfers" },
      },
      // **** START Accounts ****
      {
        path: `/Client/:clientId/${AccountsPath}`,
        component: Accounts,
        trackingEvent: { name: "Client Open Accounts" },
      },
      {
        path: `/Client/:clientId/${AccountsPath}/Add`,
        component: AddAccount,
        trackingEvent: { name: "Client Open Add Account" },
      },
      {
        path: `/Client/:clientId/${AccountsPath}/Add/Manual`,
        component: AddManualAccount,
        trackingEvent: { name: "Client Open Add Manual Account" },
      },
      {
        path: `/Client/:clientId/${AccountsPath}/Add/External`,
        component: LinkExternalAccount,
        trackingEvent: { name: "Client Open Add External Account" },
      },
      {
        path: `/Client/:clientId/${AccountsPath}/Add/OffPortal`,
        component: AddOffPortalAccount,
        trackingEvent: { name: "Client Open Add Off-Portal Account" },
      },
      {
        path: `/Client/:clientId/${AccountsPath}/Add/Manual/:manualAccountType/Details`,
        component: AddManualAccountDetails,
        trackingEvent: { name: "Client Open Add Manual Account Details" },
      },
      {
        path: `/Client/:clientId/${AccountsPath}/Manual/:accountId`,
        component: function RedirectToSummary(): JSX.Element {
          const { clientId, accountId } = useParams<{
            clientId: ClientId;
            accountId: string;
          }>();

          const { state } = useLocation();

          useEffect(() => {
            trackEvent({
              name: "Client Open Accounts ManualAccount Details",
            });
          }, []);

          return (
            <Redirect
              to={{
                state,
                pathname: `/Client/${clientId}/${AccountsPath}/Manual/${accountId}/Summary`,
              }}
              exact
              push={false}
            />
          );
        },
      },
      {
        path: `/Client/:clientId/${AccountsPath}/Manual/:accountId/Summary`,
        component: ManualAssetsSummary,
        trackingEvent: { name: "Client Open Manual Account Summary" },
      },
      {
        path: `/Client/:clientId/${AccountsPath}/Manual/:accountId/Activity`,
        component: ManualAssetActivity,
        trackingEvent: { name: "Client Open Manual Account Activity" },
      },
      {
        path: `/Client/:clientId/${AccountsPath}/Manual/:accountId/Settings`,
        component: ManualAssetSettings,
        trackingEvent: { name: "Client Open Manual Account Settings" },
      },
      {
        path: `/Client/:clientId/${AccountsPath}/Manual/:accountId/Settings/UpdateInvestment`,
        component: UpdateValue,
        trackingEvent: { name: "Client Open Update Manual Account Investment" },
      },
      {
        path: `/Client/:clientId/${AccountsPath}/Manual/:accountId/Settings/RenameAccount`,
        component: RenameAltAsset,
        trackingEvent: { name: "Client Open Rename Manual Account" },
      },
      {
        path: `/Client/:clientId/${AccountsPath}/Manual/:accountId/Settings/EditDocument`,
        component: EditDocument,
        trackingEvent: { name: "Client Open Edit Manual Account Document" },
      },
      {
        path: `/Client/:clientId/${AccountsPath}/Managed/:accountId`,
        component: function RedirectToSummary(): JSX.Element {
          const { clientId, accountId } = useParams<{
            clientId: ClientId;
            accountId: FartherAccountId;
          }>();

          const { state } = useLocation();

          useEffect(() => {
            trackEvent({
              name: "Client Open Accounts Details",
            });
          }, []);

          return (
            <Redirect
              to={{
                state,
                pathname: `/Client/${clientId}/${AccountsPath}/Managed/${accountId}/Summary`,
              }}
              exact
              push={false}
            />
          );
        },
      },
      {
        path: `/Client/:clientId/${AccountsPath}/Managed/:accountId/Summary`,
        component: AccountSummary,
        trackingEvent: { name: "Client Open Account Summary" },
      },
      {
        path: `/Client/:clientId/${AccountsPath}/Managed/:accountId/:path/SetTargetGoal`,
        component: SetTargetGoal,
        trackingEvent: { name: "Client Open Account Set-Target-Goal" },
      },
      {
        path: `/Client/:clientId/${AccountsPath}/Managed/:accountId/Activity`,
        component: AccountActivity,
        trackingEvent: { name: "Client Open Account Activity" },
      },
      {
        path: `/Client/:clientId/${AccountsPath}/Managed/:accountId/Holdings`,
        component: AccountHoldings,
        trackingEvent: { name: "Client Open Account Holdings" },
      },
      {
        path: `/Client/:clientId/${AccountsPath}/Managed/:accountId/Projections`,
        component: AccountProjections,
        trackingEvent: { name: "Client Open Account Projections" },
      },
      {
        path: `/Client/:clientId/${AccountsPath}/Managed/:accountId/Settings`,
        component: AccountSettings,
        trackingEvent: { name: "Client Open Account Settings" },
      },
      {
        path: `/Client/:clientId/${AccountsPath}/Managed/:accountId/Settings/Rename`,
        component: RenameAccount,
        trackingEvent: { name: "Client Open Account Settings Rename" },
      },
      {
        path: `/Client/:clientId/${AccountsPath}/External/:externalAccountId/Remove`,
        component: RemoveExternalAccount,
        trackingEvent: { name: "Client Open Remove External Account" },
      },
      // **** End Accounts ****
      // **** Start Advisor Requested Liquidation ****
      {
        path: `/Client/:clientId/${LiquidationPath}`,
        component: AdvisorLiquidation,
        trackingEvent: { name: "Client Open Liquidation" },
      },
      // **** End Advisor Requested Liquidation ****
      {
        path: `/Client/:clientId/TaxBudgets`,
        component: TaxBudgetClientList,
        trackingEvent: { name: "Client Open Tax-Budgets" },
      },
      {
        path: `/Client/:clientId/TaxBudgets/Create`,
        component: TaxBudgetClientCreateEdit,
        trackingEvent: { name: "Client Open Create Tax-Budget" },
      },
      {
        path: `/Client/:clientId/TaxBudgets/CreateHypothetical`,
        component: function ClientHypotheticalRedirect(): JSX.Element {
          const { clientId } = useParams<{ clientId: ClientId }>();

          const { enableTaxBudgetHypotheticals } = useFlags();

          return enableTaxBudgetHypotheticals ? (
            <TaxBudgetClientCreateHypothetical />
          ) : (
            <Redirect
              to={`/Client/${clientId}/TaxBudgets`}
              exact
              push={false}
            />
          );
        },
      },
      {
        path: `/Client/:clientId/TaxBudgets/:taxBudgetId`,
        component: function RedirectToSummary(): JSX.Element {
          const { clientId, taxBudgetId } = useParams<{
            clientId: ClientId;
            taxBudgetId: string;
          }>();

          return (
            <Redirect
              to={`/Client/${clientId}/TaxBudgets/${taxBudgetId}/Usage`}
              exact
              push={false}
            />
          );
        },
      },
      {
        path: `/Client/:clientId/TaxBudgets/:taxBudgetId/Edit`,
        component: TaxBudgetClientCreateEdit,
        trackingEvent: { name: "Client Open Edit Tax-Budget" },
      },
      {
        path: `/Client/:clientId/TaxBudgets/:taxBudgetId/*`,
        component: TaxBudgetClientDetails,
        trackingEvent: { name: "Client Open Tax-Budget" },
      },
      {
        path: `/Client/:clientId/${DocumentsCenterPath}`,
        component: function RedirectToFartherRecords(): JSX.Element {
          const { clientId } = useParams<{
            clientId: ClientId;
          }>();

          useEffect(() => {
            trackEvent({
              name: "Client Open Documents",
            });
          }, []);

          return (
            <Redirect
              to={`/Client/${clientId}/${DocumentsCenterPath}/Farther%20Records`}
              exact
              push={false}
            />
          );
        },
      },
      {
        path: `/Client/:clientId/${DocumentsCenterPath}/*`,
        component: DocumentsCenter,
        exact: false,
        trackingEvent: { name: "Client Open Documents Center" },
      },
      {
        path: `/Client/:clientId/${InsuranceMarketplacePath}`,
        component: function InsuranceMarketplaceRedirect(): JSX.Element {
          const { enableInsuranceMarketplace } = useFlags();

          return (
            <RedirectWithFlag
              flag={enableInsuranceMarketplace}
              redirectPath={"/Client"}
            >
              <InsuranceMarketplace />
            </RedirectWithFlag>
          );
        },
        trackingEvent: { name: "Client Open Insurance Marketplace" },
      },
      {
        path: `/Client/:clientId/${InsuranceMarketplacePath}/:offeringType`,
        component: function OfferingTypeRedirect(): JSX.Element {
          const { enableInsuranceMarketplace } = useFlags();

          return (
            <RedirectWithFlag
              flag={enableInsuranceMarketplace}
              redirectPath={"/Client"}
            >
              <InsuranceOfferingsList />
            </RedirectWithFlag>
          );
        },
        trackingEvent: {
          name: "Client Open Insurance Marketplace Offering Type",
        },
      },
      {
        path: `/Client/:clientId/${InsuranceMarketplacePath}/:offeringType/:offeringId`,
        component: function OfferingDetailsRedirect(): JSX.Element {
          const { enableInsuranceMarketplace } = useFlags();

          return (
            <RedirectWithFlag
              flag={enableInsuranceMarketplace}
              redirectPath={"/Client"}
            >
              <InsuranceOfferingDetails />
            </RedirectWithFlag>
          );
        },
        trackingEvent: {
          name: "Client Open Insurance Marketplace Offering Details",
        },
      },
      {
        path: `/Client/:clientId/${LendingMarketplacePath}`,
        component: function LendingMarketplaceRedirect(): JSX.Element {
          const { enableLendingMarketplace } = useFlags();

          return (
            <RedirectWithFlag
              flag={enableLendingMarketplace}
              redirectPath={"/Client"}
            >
              <LendingMarketplace />
            </RedirectWithFlag>
          );
        },
        trackingEvent: { name: "Client Open Lending Marketplace" },
      },
      {
        path: `/Client/:clientId/${LendingMarketplacePath}/:offeringType`,
        component: function OfferingTypeRedirect(): JSX.Element {
          const { enableLendingMarketplace } = useFlags();

          return (
            <RedirectWithFlag
              flag={enableLendingMarketplace}
              redirectPath={"/Client"}
            >
              <LendingOfferingsList />
            </RedirectWithFlag>
          );
        },
        trackingEvent: {
          name: "Client Open Lending Marketplace Offering Type",
        },
      },
      {
        path: `/Client/:clientId/${LendingMarketplacePath}/:offeringType/:offeringId`,
        component: function OfferingDetailsRedirect(): JSX.Element {
          const { enableLendingMarketplace } = useFlags();

          return (
            <RedirectWithFlag
              flag={enableLendingMarketplace}
              redirectPath={"/Client"}
            >
              <LendingOfferingDetails />
            </RedirectWithFlag>
          );
        },
        trackingEvent: {
          name: "Client Open Lending Marketplace Offering Details",
        },
      },
    ],
  },
];
