import React, { ComponentProps, useMemo } from "react";

import { Controller, Message, useFormContext } from "react-hook-form";

import { AutocompleteOption } from "../Dropdown/Dropdown";
import Dropdown from "../SAODropdown/Dropdown";

interface Props<T extends AutocompleteOption> {
  name: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  required?: Message | false;
  rules?: ComponentProps<typeof Controller>["rules"];
  values: T[];
  defaultValue?: T;
  isLoading?: boolean;
  onClear?: () => void;
  getOptionKey?: (option: T) => React.Key;
  disableSearch?: boolean;
  disablePortal?: boolean;
}

export default function FormDropdownField<T extends AutocompleteOption>(
  props: Props<T>
): JSX.Element {
  const { control } = useFormContext();

  const normalizedDefault: T | undefined = useMemo(() => {
    if (props.defaultValue === undefined) {
      return undefined;
    }

    return {
      ...props.defaultValue,
      label: props.defaultValue.label,
    };
  }, [props.defaultValue]);

  return (
    <Controller
      defaultValue={normalizedDefault}
      name={props.name}
      control={control}
      rules={{ required: props.required, ...props.rules }}
      render={({ field, fieldState }) => (
        <Dropdown<T>
          placeholder={props.placeholder}
          formLabel={props.label}
          value={field.value ?? null}
          onChange={field.onChange}
          onBlur={field.onBlur}
          onClear={props.onClear}
          values={props.values}
          disabled={props.disabled}
          loading={props.isLoading}
          getOptionKey={props.getOptionKey}
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
          disableSearch={props.disableSearch}
          disablePortal={props.disablePortal}
        />
      )}
    />
  );
}
