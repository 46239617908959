import React from "react";

import { range } from "lodash";

import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import RowLoading from "./RowLoading";

import styles from "./SleeveTable.module.css";

interface Props {
  headerStyle?: React.CSSProperties;
}

const TableLoading = (props: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      <div className={styles.header} style={props.headerStyle}>
        <div className={styles.headerLeft}>
          <div className={styles.description}>
            <Skeleton width={100} />
          </div>
        </div>

        <div className={styles.headerRight}>
          <Skeleton width={75} />
        </div>
      </div>

      <div className={styles.body}>
        <div className={styles.tableHeader}>
          <div className={styles.headerColumn}>Asset</div>
          <div className={styles.headerColumn}>Allocation</div>
        </div>
        <div>
          {range(3).map((r) => (
            <RowLoading key={JSON.stringify(r)} />
          ))}
        </div>
      </div>

      <div className={styles.footer}>
        <div>Total</div>
        <div>
          <Skeleton width={60} />
        </div>
      </div>
    </div>
  );
};

export default TableLoading;
