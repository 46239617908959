import React, { useMemo, useState } from "react";

import { PaginationHeaders } from "@fartherfinance/frontend/api/utils/parsePaginationHeaders";

import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import {
  FartherTable,
  FartherTableBody,
  FartherTableBodyCell,
  FartherTableBodyRow,
  FartherTableContainer,
  FartherTableHead,
  FartherTableHeaderCell,
  FartherTablePagination,
  FartherTableSortLabel,
} from "./Components";
import TableRow from "./TableRow/TableRow";
import { flipTableSortOrder } from "./utils";

import styles from "./PaginatedTable.module.css";

import type { Cell, Column, Row, TableSortOrder } from "./Types";

interface PaginatedTableProps<Keys extends string, Cells extends Cell> {
  isLoading?: boolean;
  columns: Column<Keys>[];
  rows: Row<Keys, Cells>[];
  pagination: PaginationHeaders | undefined;
  showEmptyRows?: boolean;
  fixedEmptyRowsCount?: number;
  onPageChange: (page: number) => void;
  onColumnSelect?: (sortBy: Keys, sortOrder: TableSortOrder) => void;
  columnStyles?: (
    key: Keys,
    index: number,
    arr: Readonly<Keys[]>
  ) => { className?: string; style?: React.CSSProperties } | undefined;
}

export default function PaginatedTable<
  Keys extends string,
  Cells extends Cell
>({
  children,
  isLoading,
  columns,
  rows,
  pagination,
  showEmptyRows = true,
  fixedEmptyRowsCount = 10,
  onPageChange,
  onColumnSelect,
  columnStyles,
}: React.PropsWithChildren<
  PaginatedTableProps<Keys, Cells>
>): JSX.Element | null {
  const handleOnColumnClick = (
    sortBy: Keys,
    sortOrder: TableSortOrder
  ): void => {
    onColumnSelect?.(sortBy, sortOrder);
  };
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  // INFO: Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = (pagination?.pageSize ?? fixedEmptyRowsCount) - rows.length;

  const hasExpandableRows = useMemo(
    () =>
      // If any rows have expandable data
      (rows ?? []).some(
        (r) =>
          "expandableDetails" in r && React.isValidElement(r.expandableDetails)
      ) ||
      // or we previously seen an expanded row
      expandedRows.length > 0,
    [expandedRows, rows]
  );

  return (
    <FartherTableContainer>
      <FartherTable>
        {children}

        <FartherTableHead>
          {columns.map(
            ({ label, accessor, sortable, active, sortOrder }, idx, arr) => (
              <FartherTableHeaderCell
                key={label ? label : idx}
                className={
                  columnStyles?.(
                    accessor,
                    idx,
                    arr.map((k) => k.accessor)
                  )?.className
                }
                style={
                  columnStyles?.(
                    accessor,
                    idx,
                    arr.map((k) => k.accessor)
                  )?.style
                }
              >
                {sortable ? (
                  <FartherTableSortLabel
                    active={active}
                    direction={sortOrder}
                    onClick={() =>
                      handleOnColumnClick(
                        accessor,
                        flipTableSortOrder(sortOrder)
                      )
                    }
                  >
                    {label}
                  </FartherTableSortLabel>
                ) : (
                  <>{label}</>
                )}
              </FartherTableHeaderCell>
            )
          )}
        </FartherTableHead>

        <FartherTableBody>
          {rows.map((row) => (
            <FartherTableBodyRow
              key={row.key}
              columns={columns.map((column) => column.accessor)}
              row={row}
              {...(hasExpandableRows
                ? expandedRows.includes(row.key)
                  ? {
                      expandedState: "Expanded",
                      expandedToggle: () =>
                        setExpandedRows(
                          expandedRows.filter((r) => r !== row.key)
                        ),
                    }
                  : {
                      expandedState: "Compact",
                      expandedToggle: () =>
                        setExpandedRows([...expandedRows, row.key]),
                    }
                : { expandedState: "NotExpandable" })}
            />
          ))}

          {showEmptyRows &&
            Array.from({ length: emptyRows }).map((_, idx) => (
              <TableRow key={idx}>
                {() =>
                  columns.map((column) => (
                    <FartherTableBodyCell
                      key={column.accessor}
                      className={styles.fixedCellHeight}
                    >
                      {isLoading ? (
                        <Skeleton />
                      ) : (
                        <div style={{ visibility: "hidden" }} />
                      )}
                    </FartherTableBodyCell>
                  ))
                }
              </TableRow>
            ))}
        </FartherTableBody>
      </FartherTable>

      {pagination && (
        <FartherTablePagination
          pageSize={pagination.pageSize}
          currentPage={pagination.currentPage - 1}
          totalCount={pagination.totalCount}
          onPageChange={(page) => onPageChange(page + 1)}
          isPrevButtonDisabled={pagination.prevPage === undefined}
          isNextButtonDisabled={pagination.nextPage === undefined}
        />
      )}
    </FartherTableContainer>
  );
}
