import React, { useCallback } from "react";

import { useHistory, useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import AccountsReviewRow from "../../Client/TaxBudgets/ReviewBudget/AccountsReviewRow";
import AmountReviewRow from "../../Client/TaxBudgets/ReviewBudget/AmountReviewRow";
import YearReviewRow from "../../Client/TaxBudgets/ReviewBudget/YearReviewRow";
import Button from "../../MUI/Button/Button";
import { useCreateEditTaxBudgetContext } from "../reducer/Context";
import AccountsReviewRows from "@src/multiCustodian/components/Advisor/TaxBudgets/ReviewBudget/AccountsReviewRows";
import ActionRow from "@src/sharedComponents/ActionRow/ActionRow";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";

import styles from "./Success.module.css";

const Success: React.FC = () => {
  const { clientId } = useParams<{ clientId: ClientId }>();

  const { state } = useCreateEditTaxBudgetContext();

  const { data, isAdvisor } = state;

  const { year } = data;

  const history = useHistory();

  const handleOnClickDone = useCallback(() => {
    const pathname = isAdvisor
      ? `/Advisor/Clients/${clientId}/TaxBudgets`
      : `/Client/${clientId}/TaxBudgets`;

    history.push({
      ...location,
      pathname,
    });
  }, [clientId, history, isAdvisor]);

  return (
    <div className={styles.container}>
      <BorderBox className={styles.outer}>
        <div className={styles.titleContainer}>
          <div className={styles.subtitle}>Set a Tax Budget</div>
          <div className={styles.title}>{`${year || 0} Tax Budget Set`}</div>
        </div>
        <BorderBox className={styles.inner}>
          <ActionRow start="Budget Summary" />
          <YearReviewRow />
          <AmountReviewRow />
          {isAdvisor ? <AccountsReviewRows /> : <AccountsReviewRow />}
        </BorderBox>
        <Button
          text="Done"
          onClick={handleOnClickDone}
          variant="contained"
          buttonType="primary"
          sx={{ width: "240px" }}
        />
      </BorderBox>
    </div>
  );
};

export default Success;
