import React from "react";

import { useSelector } from "react-redux";

import {
  getPartialAccountNumber,
  MASK_NUMBER,
} from "@fartherfinance/frontend/api/Accounts/utilities/accountUtil";
import useClientAccounts from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAccounts";
import useExternalAccountsExtended from "@fartherfinance/frontend/api/ExternalAccount/hooks/useExternalAccountsExtended";

import { State } from "../../../../../../../../store";
import ButtonPrimary from "../../../../../../../components/MUI/Button/Button";
import { SetScreenAndTransferType } from "../../../../Types";
import { getDateTimeString } from "../../utils/getDateTimeString";
import { StandardTransferPlan } from "../Types";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import { isManualAccount } from "@src/multiCustodian/pages/Dashboard/Funding/utils";
import useThemeFragment from "@src/multiCustodian/theme/useThemeFragment";

import "./Deposit.css";

interface Props {
  plan: StandardTransferPlan;
  setCurScreen: SetScreenAndTransferType;
}

const DepositSuccess = (props: Props): JSX.Element => {
  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));

  const t = useThemeFragment("Farther");

  const auth = useRequestAuth();

  const accounts = useClientAccounts(clientId, auth);

  const externalAccounts = useExternalAccountsExtended(undefined, auth);

  const onFinish = () => {
    props.setCurScreen({ screen: "transferType" });
  };

  if (accounts.isLoading || externalAccounts.isLoading) {
    return <div>Loading...</div>;
  }

  if (accounts.hasError || externalAccounts.hasError) {
    return <div>Error occurred</div>;
  }

  const externalAccount = (externalAccounts.data ?? []).find(
    (a) => a.accountId === props.plan.externalAccountId
  );

  const fartherAccount = (accounts.data.fartherAccounts ?? []).find(
    (a) => a.accountId === props.plan.fartherAccountId
  );

  if (fartherAccount === undefined) {
    return <div>Error finding accounts from plan</div>;
  }

  const institutionName =
    externalAccount !== undefined
      ? isManualAccount(externalAccount)
        ? externalAccount.details?.bank?.name
        : externalAccount?.institution?.name
      : undefined;

  const externalAccName =
    externalAccount !== undefined
      ? isManualAccount(externalAccount)
        ? externalAccount.details?.account.accountTitle
        : externalAccount?.details?.account.name
      : undefined;

  // https://www.notion.so/fartherfinance/RFC-Account-Id-Display-9fefdde3348b4a888cf8c4dcfa4a5eb1?pvs=4#42361a3aa39942cfa9582f1ad4c1369f
  const mask = `(...${
    externalAccount ? getPartialAccountNumber(externalAccount) : MASK_NUMBER
  })`;

  const fartherAccName = fartherAccount.accountDetails.displayName;

  const fartherAccBalance = fartherAccount.accountDetails.accountBalance;

  return (
    <div className="Dashboard_Deposit_page__container">
      <div className="Dashboard_Deposit_page__content_container">
        <p className="Dashboard_Deposit_page__title">Deposit complete</p>

        <p className="Dashboard_Deposit_page__heading_summary">
          Deposit confirmed! Here's what you should expect.
        </p>

        <div className="Dashboard_Deposit_page__checklist">
          <div className="Dashboard_Deposit_page__flex_row_div">
            <div className="Dashboard_Deposit_page__orange_number">1</div>
            <p className="Dashboard_Deposit_page__text_2">
              {`We'll instruct ${
                institutionName ?? "your bank"
              } to begin the withdrawal process.`}
            </p>
          </div>
          <div className="Dashboard_Deposit_page__flex_row_div">
            <div className="Dashboard_Deposit_page__orange_number">2</div>
            <p className="Dashboard_Deposit_page__text_2">
              {`${
                institutionName ?? "Your bank"
              } will transfer your deposit to ${t(
                "companyName"
              )} in 1-2 business days.`}
            </p>
          </div>
          <div className="Dashboard_Deposit_page__flex_row_div">
            <div className="Dashboard_Deposit_page__orange_number">3</div>
            <p className="Dashboard_Deposit_page__text_2">
              We'll invest your deposit into your {t("companyName")} portfolio
            </p>
          </div>
        </div>

        <p className="Dashboard_Deposit_page__text_2_gray">From account</p>

        <div className="Dashboard_Deposit_page__from_account_div">
          <p className="Dashboard_Deposit_page__heading">
            {institutionName ?? "Your bank"}
            {" - "}
            {externalAccName ?? "Bank account"}
          </p>
          <p className="Dashboard_Deposit_page__heading">{mask}</p>
        </div>

        <p className="Dashboard_Deposit_page__text_2_gray">Deposit to</p>

        <div className="Dashboard_Deposit_page__to_account_div_2">
          <p className="Dashboard_Deposit_page__heading">
            {`${t("companyName")} - ${
              fartherAccName === "Long-term Savings"
                ? "Long-term Growth"
                : fartherAccName
            }`}
          </p>
          <p className="Dashboard_Deposit_page__heading">
            {fartherAccBalance?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            }) ?? "N/A"}
          </p>
        </div>

        <p className="Dashboard_Deposit_page__text_2_gray">Deposit amount</p>
        <p className="Dashboard_Deposit_page__amount_heading">
          {props.plan.amount.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}
        </p>

        <p className="Dashboard_Deposit_page__text_2_gray">Transfer date</p>
        <p className="Dashboard_Deposit_page__amount_heading">
          {getDateTimeString()}
        </p>

        <p className="Dashboard_Deposit_page__text_2_gray">Transfer ID</p>
        <p className="Dashboard_Deposit_page__amount_heading">
          {props.plan.transferId}
        </p>

        <div className="Dashboard_Deposit_page__button_container">
          <ButtonPrimary
            variant={"contained"}
            buttonType={"primary"}
            text={"Finish"}
            onClick={() => onFinish()}
          />
        </div>
      </div>
    </div>
  );
};

export default DepositSuccess;
