import React, { useState } from "react";

import { Container } from "@mui/material";
import { isAxiosError } from "axios";
import { formatISO } from "date-fns";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import useClientAccounts from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAccounts";
import useClient from "@fartherfinance/frontend/api/EntityData/hooks/useClient";
import useGetTaxBudgetsForClient from "@fartherfinance/frontend/api/InvestmentModel/hooks/useGetTaxBudgetsForClient";
import useCreateLiquidation from "@fartherfinance/frontend/api/Liquidations/hooks/useCreateLiquidation";
import useTradingGroups from "@fartherfinance/frontend/api/TradingGroups/hooks/useTradingGroups";
import {
  Account,
  TradingGroup,
} from "@fartherfinance/frontend/api/TradingGroups/requests/getTradingGroup";
import { ClientId, TaskId } from "@fartherfinance/frontend/api/Types";

import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import PageHero from "@src/sharedComponents/PageHero/PageHero";
import TitleBlock from "@src/sharedComponents/PageHero/TitleBlock";

import LiquidationConfirmation from "./AdvisorLiquidation/LiquidationConfirmation";
import LiquidationDetails from "./AdvisorLiquidation/LiquidationDetails";
import LiquidationRequest from "./AdvisorLiquidation/LiquidationRequest";
import { reformatAmount } from "./AdvisorLiquidation/utils";

type ErrorMessage = Record<"message", string>;

export interface FormData {
  account: {
    label: string;
    value: string;
  };
  amount: string;
}

enum AdvisorLiquidationStep {
  LiquidationRequest = 1,
  LiquidationDetails,
  LiquidationConfirmation,
}

const AdvisorLiquidation = (): JSX.Element => {
  const [liquidationStep, setLiquidationStep] =
    useState<AdvisorLiquidationStep>(AdvisorLiquidationStep.LiquidationRequest);
  const [isMutating, setIsMutating] = useState<boolean>(false);
  const [taskId, setTaskId] = useState<TaskId | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { clientId } = useParams<Record<"clientId", ClientId>>();
  const history = useHistory();

  const form = useForm<FormData>({
    mode: "onChange",
  });

  const auth = useRequestAuth();
  const client = useClient(clientId, auth);
  const clientAccounts = useClientAccounts(clientId, auth);
  const clientTradingGroups = useTradingGroups(clientId, auth);
  const clientTaxBudgets = useGetTaxBudgetsForClient({ clientId }, auth);
  const callCreateLiquidation = useCreateLiquidation(auth);

  const accounts = clientAccounts.data?.fartherAccounts ?? [];
  const tradingGroups = clientTradingGroups.data ?? [];
  const taxBudgets = clientTaxBudgets.data ?? [];

  const handleRedirectToDashboard = (): void => {
    history.push(`/Client/${clientId}/Dashboard`);
  };

  const handleRedirectToTaskDetails = (): void => {
    if (taskId) {
      // NOTE: first redirect stops the impersonation, second redirect us where we want to go
      // we cannot freely redirect between /Client and /Advisor routes due to the impersonation logic
      history.push("/Advisor");
      setTimeout(() => {
        history.push(`/Advisor/Tasks/${taskId}`);
      }, 200);
    }
  };

  const handleMoveToStep = (step: AdvisorLiquidationStep): void => {
    setErrorMessage(null);
    setLiquidationStep(step);
  };

  const handleFindAccountInTradingGroups = (
    accountVirtualId: string,
    tradingGroups: TradingGroup[]
  ): Account | undefined => {
    const tradingGroup = tradingGroups.find((tradingGroup) =>
      tradingGroup.accounts.some(
        (account) => account.virtualAccountId === accountVirtualId
      )
    );

    return tradingGroup?.accounts.find(
      (account) => account.virtualAccountId === accountVirtualId
    );
  };

  const handleFormSubmit = (): void => {
    form.handleSubmit(async (formData) => {
      try {
        const advisorId = client.data?.advisorId;
        const account = handleFindAccountInTradingGroups(
          formData.account.value,
          tradingGroups
        );

        if (advisorId && account) {
          setIsMutating(true);
          setErrorMessage(null);

          const response = await callCreateLiquidation({
            clientId,
            advisorId,
            physicalAccountId: account.physicalAccountId,
            liquidationAmount: reformatAmount(formData.amount),
            requestDate: formatISO(new Date(), { format: "extended" }),
          });

          setTaskId(response.taskId);
          setLiquidationStep(AdvisorLiquidationStep.LiquidationConfirmation);
        }
      } catch (error) {
        if (isAxiosError<ErrorMessage>(error)) {
          const message = error.response?.data.message;

          return setErrorMessage(
            message ?? "Unexpected error from liquidation service."
          );
        }

        setErrorMessage(
          "There was an error in making the request to raise cash. Please try again."
        );
      } finally {
        setIsMutating(false);
      }
    })();
  };

  return (
    <BaseLayout>
      <PageHero start={<TitleBlock title="Raise Cash" />} />

      {isMutating && <LogoLoadingStill onTop />}

      <FormProvider {...form}>
        <Container maxWidth="md" sx={{ mt: "48px" }}>
          {liquidationStep === AdvisorLiquidationStep.LiquidationRequest && (
            <LiquidationRequest
              accounts={accounts}
              tradingGroups={tradingGroups}
              taxBudgets={taxBudgets}
              onClose={handleRedirectToDashboard}
              onContinue={() =>
                handleMoveToStep(AdvisorLiquidationStep.LiquidationDetails)
              }
            />
          )}

          {liquidationStep === AdvisorLiquidationStep.LiquidationDetails && (
            <LiquidationDetails
              errorMessage={errorMessage}
              onClose={() =>
                handleMoveToStep(AdvisorLiquidationStep.LiquidationRequest)
              }
              onContinue={handleFormSubmit}
            />
          )}

          {liquidationStep ===
            AdvisorLiquidationStep.LiquidationConfirmation && (
            <LiquidationConfirmation
              onTaskIdClick={handleRedirectToTaskDetails}
              onContinue={handleRedirectToDashboard}
            />
          )}
        </Container>
      </FormProvider>
    </BaseLayout>
  );
};

export default AdvisorLiquidation;
