import React, { useMemo } from "react";

import { Slider, sliderClasses, styled } from "@mui/material";
import { clamp } from "lodash";

import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import styles from "./AggressionSlider.module.css";

export interface Step {
  name: string;
}

interface Props {
  steps: Step[];
  leftText: string;
  rightText: string;
  onChange: (stepNumber: number) => void;
  currentStep: number;
  shiftLabelInPixelsIfAtSliderStartOrEnd?: number;
}

const AggressionSlider = (props: Props): JSX.Element => {
  const {
    color: {
      $backgroundSelectedPressed,
      $backgroundSelected,
      $textSubtle,
      $textInverse,
      $iconInverse,
      $surfaceLow,
    },
  } = useTheme();

  const noData = props.steps.length === 0;

  const createLabel = (idx: number): string | null => {
    if (noData) {
      return null;
    }

    return props.steps[idx].name.toUpperCase();
  };

  const railSidePadding = 16;
  const railColor = $backgroundSelectedPressed;
  const trackColor = $backgroundSelected;
  const thumbCenterColor = $surfaceLow;
  const thumbBorderColor = $backgroundSelected;
  const thumbOuterColor = $surfaceLow;
  const boxShadow = `0px 0px 0px 3px ${thumbOuterColor}`;
  const markColor = $iconInverse;
  const tooltipColor = $backgroundSelected;
  const tooltipTextColor = $textInverse;

  const shiftLabelLeftOrRight = useMemo(() => {
    const min = 0;
    const max = props.steps.length - 1;

    return {
      ...(props.currentStep === min && {
        right: `-${props.shiftLabelInPixelsIfAtSliderStartOrEnd ?? 0}px`,
      }), // shift right when at first position
      ...(props.currentStep === max && {
        left: `-${props.shiftLabelInPixelsIfAtSliderStartOrEnd ?? 0}px`,
      }), // shift left when at last position
    };
  }, [
    props.steps,
    props.currentStep,
    props.shiftLabelInPixelsIfAtSliderStartOrEnd,
  ]);

  const StyledSlider = useMemo(
    () =>
      styled(Slider)({
        display: "flex",
        justifyContent: "center",
        width: `calc(100% - ${railSidePadding * 2}px)`,
        left: `${railSidePadding}px`,
        [`& .${sliderClasses.thumb}`]: {
          ...(noData && { display: "none" }),
          [`&:before`]: {
            backgroundColor: thumbCenterColor,
            boxShadow: "none",
          },
          width: "42px",
          height: "42px",
          border: `17px solid ${thumbBorderColor}`,
          boxShadow: boxShadow,
          "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
            boxShadow: boxShadow,
          },
        },
        [`& .${sliderClasses.mark}`]: {
          backgroundColor: markColor,
          height: "4px",
          width: "4px",
          borderRadius: "100%",
        },
        [`& .${sliderClasses.rail}`]: {
          backgroundColor: railColor,
          height: "8px",
          padding: `0px ${railSidePadding}px`,
        },
        [`& .${sliderClasses.track}`]: {
          backgroundColor: trackColor,
          height: "8px",
          left: `-${railSidePadding}px !important`,
          border: "none",
          ...(noData && { display: "none" }),
        },
        [`& .${sliderClasses.valueLabel}`]: {
          [`&:before`]: {
            display: "none", //removes arrow
          },
          fontSize: "10px",
          fontWeight: 600,
          padding: "4px 8px",
          backgroundColor: tooltipColor,
          letterSpacing: "0.3px",
          color: tooltipTextColor,
          position: "relative",
          top: "-16px",
          ...(props.shiftLabelInPixelsIfAtSliderStartOrEnd &&
            shiftLabelLeftOrRight),
        },
      }),
    [
      boxShadow,
      markColor,
      noData,
      railColor,
      thumbBorderColor,
      thumbCenterColor,
      tooltipColor,
      tooltipTextColor,
      trackColor,
      props.shiftLabelInPixelsIfAtSliderStartOrEnd,
      shiftLabelLeftOrRight,
    ]
  );

  const onChange = (n: number | number[]): void => {
    if (typeof n !== "number") {
      return;
    }

    const min = 0;
    const max = props.steps.length - 1;
    props.onChange(clamp(n, min, max));
  };

  return (
    <div>
      <StyledSlider
        valueLabelDisplay="on"
        defaultValue={0}
        step={1}
        min={0}
        max={props.steps.length - 1}
        valueLabelFormat={createLabel}
        marks
        onChange={(_, n) => onChange(n)}
        value={props.currentStep}
      />
      <div className={styles.footer} style={{ color: $textSubtle }}>
        <div>{props.leftText}</div>
        <div>{props.rightText}</div>
      </div>
    </div>
  );
};

export default AggressionSlider;
