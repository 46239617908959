import React, { useCallback, useMemo, useState } from "react";

import { Box } from "@mui/material";
import { capitalize } from "lodash";
import { FormProvider, useForm, useFormState } from "react-hook-form";

import { Plan } from "@fartherfinance/frontend/api/Accounts/Types";
import useCreateManualAccount from "@fartherfinance/frontend/api/ExternalAccount/hooks/useCreateManualAccount";
import useUpdateManualAccount from "@fartherfinance/frontend/api/ExternalAccount/hooks/useUpdateManualAccount";
import { ManualAccount } from "@fartherfinance/frontend/api/ExternalAccount/requests/getExternalAccounts";
import { Request as ManualAccountRequest } from "@fartherfinance/frontend/api/ExternalAccount/requests/postManualAccount";
import {
  FundingAccountTypeOption,
  fundingAccountTypeOptions,
} from "@fartherfinance/frontend/api/ExternalAccount/requests/types";
import {
  Custodian,
  PlanId,
  USAState,
  USAStates,
} from "@fartherfinance/frontend/api/Types";

import custodiansRequiringVerificationDocument from "@src/constants/custodiansRequiringVerificationDocument";
import Drawer from "@src/multiCustodian/components/Drawer/Drawer";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { AutocompleteOption } from "@src/sharedComponents/Dropdown/Dropdown";
import FileUploadButton from "@src/sharedComponents/FileUploadButton/FileUploadButton";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import FormNumberField from "@src/sharedComponents/Forms/FormNumberField";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import FormFieldLabel from "@src/sharedComponents/Forms/Private/FormFieldLabel";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

import styles from "./Form.module.css";
import styles2 from "../plans.module.css";

const standingInstructionsPageTitle = "Add Funding Account Manually";

interface AccountTypeAutoComplete extends AutocompleteOption {
  label: string;
  value: FundingAccountTypeOption;
}

interface StateAutoComplete extends AutocompleteOption {
  label: USAState;
}

interface Form {
  BankName: string | undefined;
  AccountHolderName: string | undefined;
  AccountTitle: string | undefined;
  AccountType: AccountTypeAutoComplete | undefined;
  City: string | undefined;
  State: StateAutoComplete | undefined;
  AccountNumber: string | undefined;
  RoutingNumber: string | undefined;
}

type StripUndefined<F extends object> = { [k in keyof F]: NonNullable<F[k]> };

interface Props {
  plan: Plan | undefined;
  planId: PlanId;
  onClose: () => void;
  isOpen: boolean;
  chosenFundingAccount: ManualAccount | null;
  clientCustodian: Custodian | null;
}

export default function StandingInstructionsForm(props: Props): JSX.Element {
  const [isMutating, setIsMutating] = useState<boolean>(false);

  const [verificationFile, setVerificationFile] = useState<File | null>(null);

  const statusNotification = useStatusNotification();

  const auth = useAdvisorRequestAuth();

  const callCreateExternalAccount = useCreateManualAccount(auth);

  const callUpdateExternalAccount = useUpdateManualAccount(auth);

  const methods = useForm<Form>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      BankName: props.chosenFundingAccount?.details.bank.name,
      AccountHolderName:
        props.chosenFundingAccount?.details.account.accountHolderNames?.[0],
      AccountTitle: props.chosenFundingAccount?.details.account.accountTitle,
      AccountType: props.chosenFundingAccount?.details.account.accountType
        ? {
            label: capitalize(
              props.chosenFundingAccount?.details.account.accountType
            ),
            value: props.chosenFundingAccount?.details.account.accountType,
          }
        : undefined,
      City: props.chosenFundingAccount?.details.bank.city,
      State: props.chosenFundingAccount?.details.bank.state
        ? { label: props.chosenFundingAccount?.details.bank.state }
        : undefined,
      AccountNumber: props.chosenFundingAccount?.details.account.accountNumber,
      RoutingNumber: props.chosenFundingAccount?.details.bank.routingNumber,
    },
  });

  const { control, getValues } = methods;

  const { isValid } = useFormState({
    control,
  });

  const verificationDocumentRequired =
    props.clientCustodian !== null &&
    custodiansRequiringVerificationDocument.includes(props.clientCustodian) &&
    (props.chosenFundingAccount === null ||
      props.chosenFundingAccount.attachmentUrl === null);

  const planIsComplete = useMemo(() => {
    return props.plan?.planStatus === "Complete";
  }, [props.plan?.planStatus]);

  const submit = useCallback(async () => {
    if (planIsComplete) {
      statusNotification("This document has already been released", "Error");
      return;
    }

    if (!isValid || !props.plan) {
      statusNotification("Form is incomplete", "Error");
      return;
    }

    if (verificationDocumentRequired && verificationFile === null) {
      statusNotification(
        "Verification document is required for Fidelity and Schwab clients",
        "Error"
      );
      return;
    }

    const values = getValues() as StripUndefined<Form>;

    const reqBody: ManualAccountRequest = {
      data: {
        account: {
          accountType: values.AccountType.value,
          accountTitle: values.AccountTitle,
          accountNumber: values.AccountNumber,
          accountHolderNames: [values.AccountHolderName],
        },
        bank: {
          routingNumber: values.RoutingNumber,
          name: values.BankName,
          city: values.City,
          state: values.State.label,
        },
        planId: props.plan.planId,
      },
      ...(verificationFile !== null && {
        attachment: verificationFile,
      }),
    };

    try {
      setIsMutating(true);

      if (props.chosenFundingAccount === null) {
        await callCreateExternalAccount({
          ...reqBody,
          clientId: props.plan.planHolder.clientId,
        });

        statusNotification("Document generated.", "Success");
      } else {
        await callUpdateExternalAccount({
          ...reqBody,
          clientId: props.plan.planHolder.clientId,
          accountId: props.chosenFundingAccount.accountId,
        });

        statusNotification("Document updated.", "Success");
      }

      props.onClose();
    } catch {
      if (props.chosenFundingAccount === null) {
        statusNotification("Failed to generate document.", "Error");
      } else {
        statusNotification("Failed to update document.", "Error");
      }
    } finally {
      setIsMutating(false);
    }
  }, [
    planIsComplete,
    isValid,
    props,
    verificationDocumentRequired,
    verificationFile,
    getValues,
    statusNotification,
    callCreateExternalAccount,
    callUpdateExternalAccount,
  ]);

  return (
    <Drawer
      header={<DrawerHeaderLeftSI plan={props.plan} />}
      footer={
        <Footer
          onSave={submit}
          saveDisabled={
            planIsComplete ||
            !isValid ||
            (verificationDocumentRequired && verificationFile === null)
          }
          closeDrawer={props.onClose}
          isMutating={isMutating}
        />
      }
      isDrawerOpen={props.isOpen}
      onClose={props.onClose}
      transitionDuration={0}
    >
      <FormProvider {...methods}>
        <FormH1>{standingInstructionsPageTitle}</FormH1>

        <Spacer verticalSpacing="4px" />

        <FlexWrapper>
          <FormTextField
            required={"Bank Name is required"}
            label={"Bank Name"}
            name={"BankName"}
            disabled={planIsComplete}
          />

          <FormTextField
            required={"Account Name is required"}
            label={"Account Holder Name"}
            name={"AccountHolderName"}
            disabled={planIsComplete}
          />

          <FormTextField
            required={"Account Title is required"}
            label={"Account Title"}
            name={"AccountTitle"}
          />

          <FormDropdownField
            required={"Account Type is required"}
            label={"Account Type"}
            name={"AccountType"}
            disableSearch
            values={fundingAccountTypeOptions.map((el) => ({
              label: capitalize(el),
              value: el,
            }))}
            disabled={planIsComplete}
          />

          <FormTextField
            required={"City is required"}
            label={"Bank City"}
            name={"City"}
            disabled={planIsComplete}
          />

          <FormDropdownField
            required={"State is required"}
            label={"Bank State"}
            name={"State"}
            values={USAStates.map((el) => ({ label: el }))}
            disabled={planIsComplete}
          />

          <FormTextField
            required={"Account # is required"}
            label={"Account #"}
            name={"AccountNumber"}
            disabled={planIsComplete}
          />

          <FormNumberField
            required={"Routing # is required"}
            label={"Routing #"}
            name={"RoutingNumber"}
            rules={{
              minLength: {
                value: 9,
                message: "Must have 9 numbers",
              },
              maxLength: {
                value: 9,
                message: "Must have 9 numbers",
              },
            }}
            disabled={planIsComplete}
          />
        </FlexWrapper>
      </FormProvider>

      <Box marginTop={"32px"}>
        <FormFieldLabel
          label={<div className={styles2.label}>Account Verification</div>}
        />

        <FileUploadButton
          onChange={setVerificationFile}
          currentFileName={
            verificationFile?.name ? (
              verificationFile.name
            ) : props.chosenFundingAccount?.attachmentUrl ? (
              <a
                href={props.chosenFundingAccount.attachmentUrl}
                target="_blank"
                rel="noreferrer"
              >
                Document already uploaded
              </a>
            ) : (
              ""
            )
          }
        />

        <div className={styles2.helperText}>
          Upload a bank statement or voided check <br />
          associated with this funding account.
        </div>
      </Box>

      {isMutating && (
        <div className={styles.loading}>
          <LogoLoadingStill />
        </div>
      )}
    </Drawer>
  );
}

interface FooterProps {
  closeDrawer: () => void;
  saveDisabled: boolean;
  onSave: () => void;
  isMutating: boolean;
}

const Footer = (props: FooterProps): JSX.Element => {
  return (
    <div className={styles.footer}>
      <ButtonPrimary
        text={"Cancel"}
        onClick={props.closeDrawer}
        variant={"outlined"}
        buttonType={"primary"}
      />
      <ButtonPrimary
        text={"Save"}
        onClick={props.onSave}
        variant={"contained"}
        buttonType={"primary"}
        disabled={props.saveDisabled || props.isMutating}
      />
    </div>
  );
};

interface DrawerHeaderLeftSIProps {
  plan: Plan | undefined;
}

const DrawerHeaderLeftSI = ({ plan }: DrawerHeaderLeftSIProps): JSX.Element => {
  if (!plan) {
    return <></>;
  }

  return (
    <div className={styles2.drawerHeaderLeft_div}>
      <p className={styles2.drawerHeaderLeft_clientNameId}>
        {`${plan.planHolder.name.first} ${
          plan.planHolder.name.last
        } (...${plan.planHolder.clientId.slice(-5)}) | ${plan.planTitle} | `}
      </p>

      <p className={styles2.drawerHeaderLeft_planStatus}>
        {standingInstructionsPageTitle}
      </p>
    </div>
  );
};
