import { Person } from "@fartherfinance/frontend/api/Tasks/Types";

import {
  EnhancedAutocompleteOption,
  UNKNOWN_USER,
  UNKNOWN_VALUE,
} from "./common";

export const mapPersonToOption = (
  person: Person
): EnhancedAutocompleteOption => {
  return {
    label: person.name
      ? `${person.name.first} ${person.name.last}`
      : UNKNOWN_USER,
    value: person.id,
  };
};

export const getPersonName = (person: Person | null): string => {
  if (person) {
    if (person.name) {
      return `${person.name.first} ${person.name.last}`;
    }

    return UNKNOWN_USER;
  }

  return UNKNOWN_VALUE;
};
