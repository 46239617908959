import React from "react";

import Button from "../../MUI/Button/Button";
import useImpersonateClient from "@src/multiCustodian/hooks/Advisor/useImpersonateClient";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";

const ReturnToDashboardButton: React.FC = () => {
  const impersonateClient = useImpersonateClient();

  const statusNotification = useStatusNotification();

  const handleOnClick = async () => {
    try {
      impersonateClient(null);
    } catch (_e) {
      statusNotification("Could not log stop impersonating", "Error");
    }
  };

  return (
    <Button
      variant="outlined"
      buttonType="primary"
      text="Return to Portal"
      onClick={handleOnClick}
      sx={{
        marginLeft: "8px",
        marginRight: "8px",
        marginBottom: "8px",
        width: "100%",
      }}
    />
  );
};

export default ReturnToDashboardButton;
