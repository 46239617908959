import React from "react";

import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";

import { TaskStatus } from "@fartherfinance/frontend/api/Tasks/Types";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./TaskStatusIcon.module.css";

interface TaskStatusIconProps<T> {
  className?: string;
  value: T;
}

const TaskStatusIcon = ({
  className,
  value,
}: TaskStatusIconProps<TaskStatus>): JSX.Element => {
  const isFilledIcon = value === "COMPLETED" || value === "CANCELED";
  const Icon = isFilledIcon ? CircleIcon : CircleOutlinedIcon;

  return (
    <Icon
      className={toClassName(className, styles.circleIcon, {
        [styles.subtleColor]:
          value === TaskStatus.enum.NOT_STARTED ||
          value === TaskStatus.enum.CANCELED,
        [styles.selectedColor]: value === TaskStatus.enum.IN_PROGRESS,
        [styles.warningColor]:
          value === TaskStatus.enum.AWAITING_RESPONSE ||
          value.startsWith("WAITING_ON"),
        [styles.successColor]: value === TaskStatus.enum.COMPLETED,
      })}
    />
  );
};

export default TaskStatusIcon;
