import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { accountsRepository } from "../repository";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";

import type {
  PostResubmitAccountsRequest,
  PostResubmitAccountsResponse,
} from "../types";

const { postResubmitAccounts } = accountsRepository;

export const useResubmitAccountsMutation = () => {
  const notifyUser = useStatusNotification();

  const mutation = useMutation<
    AxiosResponse<PostResubmitAccountsResponse>,
    unknown,
    PostResubmitAccountsRequest
  >({
    mutationFn: postResubmitAccounts.mutationCall,
    onSuccess: () => {
      notifyUser("Successfully kicked off user accounts.", "Success");
    },
    onError: () => {
      notifyUser("Failed to kick off user accounts.", "Error");
    },
  });

  return {
    ...mutation,
    data: mutation.data?.data,
  };
};
