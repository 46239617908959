import { useMemo } from "react";

import { format, subMonths } from "date-fns";

export const useMonthList = () => {
  const monthList = useMemo(
    () =>
      Array.from({ length: 36 }, (_, idx) => idx)
        .filter((idx) => idx !== 0) // we do not want to show the current month
        .map((month) => subMonths(new Date(), month))
        .map((month) => ({
          label: `${format(month, "MMMM yyyy")}`,
          value: `${format(month, "MM-yyyy")}`,
        })),
    []
  );

  return monthList;
};
