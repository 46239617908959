import React, { useEffect, useMemo, useState } from "react";

import { format } from "date-fns";
import { orderBy } from "lodash";
import { useParams } from "react-router-dom";

import useDeleteTriggerRun from "@fartherfinance/frontend/api/CashCycle/hooks/useDeleteTriggerRun";
import useTriggerRuns from "@fartherfinance/frontend/api/CashCycle/hooks/useTriggerRuns";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import Modal from "@src/sharedComponents/Modal/Modal";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./CancelUpcomingTriggerRunModal.module.css";

interface Props {
  closeModal: () => void;
}

const CancelUpcomingTriggerRunModal = (props: Props): JSX.Element => {
  const [isMutating, setIsMutating] = useState<boolean>(false);

  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const statusNotification = useStatusNotification();

  const auth = useRequestAuth();
  const triggerRuns = useTriggerRuns(clientId, auth);
  const deleteTriggerRun = useDeleteTriggerRun(clientId, auth);

  const plannedTriggerRun = useMemo(() => {
    if (triggerRuns.data) {
      const plannedTriggerRuns = orderBy(
        (triggerRuns.data ?? []).filter((run) => run.status === "planned"),
        (r) => r.executeDate,
        "desc"
      );

      if (plannedTriggerRuns.length > 0) {
        return plannedTriggerRuns[0];
      }

      statusNotification("There are no upcoming transfers", "Error");
      props.closeModal();
    }

    return undefined;
  }, [props, triggerRuns, statusNotification]);

  useEffect(() => {
    if (triggerRuns.hasError) {
      statusNotification("Unable to get upcoming transfers", "Error");
      props.closeModal();
    }
  }, [props, triggerRuns.hasError, statusNotification]);

  const onCancel = async () => {
    if (!plannedTriggerRun) {
      return;
    }

    try {
      setIsMutating(true);

      await deleteTriggerRun({
        clientId: clientId,
        triggerRunId: plannedTriggerRun.id,
      });

      statusNotification("Upcoming transfer canceled", "Success");
    } catch (_e) {
      statusNotification("Error canceling upcoming transfer", "Error");
    } finally {
      setIsMutating(false);
      props.closeModal();
    }
  };

  if (isMutating || triggerRuns.isLoading) {
    return (
      <Modal closeModal={props.closeModal} modalStyle={{ width: "520px" }}>
        <div className={styles.paddingContainer}>
          <Skeleton width={140} className={styles.title} />

          <Skeleton width={"90%"} className={styles.subTitle} />

          <div className={styles.footer}>
            <div className={styles.modalButtonsDiv}>
              <Skeleton width={100} className={styles.marginRight} />

              <Skeleton width={100} />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal closeModal={props.closeModal} modalStyle={{ width: "520px" }}>
      <div className={styles.paddingContainer}>
        <p className={styles.title}>Cancel Upcoming Transfer</p>

        <p className={styles.subTitle}>
          {!plannedTriggerRun
            ? "Are you sure you want to cancel your upcoming funding transfer?"
            : `Are you sure you want to cancel your upcoming funding transfer on ${format(
                plannedTriggerRun.executeDate,
                "M/d/yyyy"
              )}?`}
        </p>

        <div className={styles.footer}>
          <div className={styles.modalButtonsDiv}>
            <Button
              variant={"outlined"}
              buttonType={"secondary"}
              text={"Cancel"}
              onClick={props.closeModal}
            />

            <Button
              variant={"contained"}
              buttonType={"primary"}
              text={"Confirm"}
              disabled={!plannedTriggerRun}
              onClick={() => onCancel()}
              style={{ marginLeft: "15px" }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CancelUpcomingTriggerRunModal;
