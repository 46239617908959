import React, { useMemo } from "react";

import { isNil } from "lodash";
import { useHistory, useParams } from "react-router-dom";

import { RegularDocumentType } from "@fartherfinance/frontend/api/Accounts/requests/uploadPlanDocumentV2";
import {
  getAccountBalanceString,
  getAccountName,
  getBankName,
  getPartialAccountNumber,
  MASK_NUMBER,
  UNKNOWN_BALANCE,
} from "@fartherfinance/frontend/api/Accounts/utilities/accountUtil";
import useClientCustodian from "@fartherfinance/frontend/api/Dashboard/hooks/useClientCustodian";
import useGetAgreements from "@fartherfinance/frontend/api/Document/hooks/useGetAgreements";
import { ExternalAccountExtended } from "@fartherfinance/frontend/api/ExternalAccount/hooks/useExternalAccountsExtended";
import { ExternalAccountFundingStatus } from "@fartherfinance/frontend/api/ExternalAccount/requests/types";
import { ClientId, FullCustodian } from "@fartherfinance/frontend/api/Types";

import { CashManagementPath } from "@src/config/routing/RouterPaths";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import { isPlaidAccount } from "@src/multiCustodian/pages/Dashboard/Funding/utils";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./FundingAccount.module.css";

const pershingWarning =
  'To finish setting up your funding account, open the Document Center and sign the form(s) named "ACH Authorization Agreement."';

const schwabOrFidelityWarning =
  "To finish setting up your funding account, you will need to sign a form. You should receive an email with a link to that form within one business day.";

const createPendingWarning = (
  ACHAgreementSigned: boolean,
  status: ExternalAccountFundingStatus | undefined, //For now, only single 'FullCustodians' are supported. Multiple Full custodian as are NOT supported
  custodian: FullCustodian | null
): string | null => {
  if (status !== "pending" || custodian === null) {
    return null;
  }

  switch (custodian) {
    case "Apex":
      return null;
    case "Fidelity":
      return schwabOrFidelityWarning;
    case "Pershing":
      return ACHAgreementSigned ? null : pershingWarning;
    case "Schwab":
      return schwabOrFidelityWarning;

    default: {
      const _x: never = custodian;
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Unknown custodian: ${_x}`);
    }
  }
};

interface Props {
  fundingAccount: ExternalAccountExtended | null | undefined;
}

const FundingAccount = ({ fundingAccount }: Props): JSX.Element => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const history = useHistory();

  const auth = useRequestAuth();

  const clientCustodian = useClientCustodian(clientId, auth);

  const agreements = useGetAgreements(auth);

  const unsignedACHAgreements = useMemo(() => {
    //There isn't a link (agreementId that is shared between the document and account, or accountId shared between doc and account) that would help us find documents associated to the current funding account.
    //Until one is created, we will just check if ALL ACH authorizations have been signed to determine if
    //the ACH Auth for the current funding account was signed.
    //This is a temporary solution to help determine whether to show the 'Sign ACH agreement warning' for Pershing clients.
    return (agreements.data ?? [])
      .filter(
        (a) =>
          a.documentTitle ===
          RegularDocumentType.Enum["ACH Authorization Agreement"]
      )
      .filter((a) => a.status !== "Signed");
  }, [agreements.data]);

  const custodianToUse = useMemo(() => {
    if (clientCustodian.isLoading || clientCustodian.hasError) {
      return clientCustodian;
    }

    if (clientCustodian.data.type === "SingleFullCustodian") {
      return {
        data: clientCustodian.data.custodian,
      };
    }

    return {
      clientCustodian,
      data: null,
    };
  }, [clientCustodian]);

  const accountName = useMemo(() => {
    if (isNil(fundingAccount)) {
      return "No Funding Account";
    }

    if (isPlaidAccount(fundingAccount)) {
      return `${fundingAccount.institution.name} - ${getAccountName(
        fundingAccount
      )}`;
    }

    return `${getBankName(fundingAccount)} - ${getAccountName(fundingAccount)}`;
  }, [fundingAccount]);

  const mask = fundingAccount
    ? getPartialAccountNumber(fundingAccount)
    : MASK_NUMBER;

  const balance = fundingAccount
    ? getAccountBalanceString(fundingAccount)
    : UNKNOWN_BALANCE;

  const status = fundingAccount?.fundingDetails?.fundingStatus;

  const pendingWarning = createPendingWarning(
    unsignedACHAgreements.length === 0,
    status,
    custodianToUse.data ?? null
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerInnerDiv}>
          <div className={styles.heading}>Funding Account</div>

          <ButtonPrimary
            text={
              isNil(fundingAccount)
                ? "Select a Funding Account"
                : "Change Funding Account"
            }
            variant="contained"
            buttonType="primary"
            onClick={() =>
              history.push(
                `/Client/${clientId}/${CashManagementPath}/ChangeFundingAccount`
              )
            }
            sx={{ height: "30px" }}
          />
        </div>
      </div>

      <div className={styles.body}>
        <div className={styles.bodyInnerDiv}>
          {isNil(fundingAccount) ? (
            <div className={styles.noAccount}>No Funding Account</div>
          ) : (
            <>
              <div className={styles.account}>
                {accountName}

                <div className={styles.mask}>{`${getAccountName(
                  fundingAccount
                )} (...${mask})`}</div>

                {pendingWarning !== null && (
                  <div className={styles.warning}>{pendingWarning}</div>
                )}
              </div>

              {status === "active" ? (
                <div className={styles.balance}>{balance}</div>
              ) : (
                <div className={toClassName(styles.balance, styles.selected)}>
                  {status}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FundingAccount;
