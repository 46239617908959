import React, { PropsWithChildren } from "react";

import { useHistory, useParams } from "react-router-dom";

import { MarketplaceAssetType } from "@fartherfinance/frontend/api/AltAssetsService/Types";
import { MarketplaceOfferingId } from "@fartherfinance/frontend/api/Types";

import AdvisorContainer from "../../AdvisorContainer";
import AdvisorContentContainer from "../../AdvisorContentContainer";
import OfferingsList from "@src/multiCustodian/components/Marketplace/OfferingsList/OfferingsList";
import useOfferingsListData from "@src/multiCustodian/components/Marketplace/OfferingsList/useOfferingsListData";
import {
  MarketplaceAdvisorPathParams,
  MarketplaceOfferingTypeCard,
} from "@src/multiCustodian/components/Marketplace/types";
import { findOfferingTypeByValue } from "@src/multiCustodian/components/Marketplace/utils";

interface OfferingsListAdvisorProps {
  assetType: MarketplaceAssetType;
  offeringTypes: MarketplaceOfferingTypeCard[];
  marketplacePath: string;
  pageTitleFallback: string;
}

const OfferingsListAdvisor: React.FC<
  PropsWithChildren<OfferingsListAdvisorProps>
> = ({
  children,
  assetType,
  offeringTypes,
  marketplacePath,
  pageTitleFallback,
}) => {
  const history = useHistory();
  const { offeringType } = useParams<MarketplaceAdvisorPathParams>();
  const { offerings, pagination, isLoading, hasError, handleOnPageChange } =
    useOfferingsListData("advisor", assetType, offeringType);

  const marketplaceOfferingType = findOfferingTypeByValue(
    offeringTypes,
    offeringType
  );

  const handleNavigateToOfferingTypes = (): void => {
    history.push(`/Advisor/${marketplacePath}`);
  };

  const handleNavigateToOfferingDetails = (
    offeringId: MarketplaceOfferingId
  ): void => {
    history.push(`/Advisor/${marketplacePath}/${offeringType}/${offeringId}`);
  };

  return (
    <AdvisorContainer>
      <AdvisorContentContainer
        heroTitle={marketplaceOfferingType?.name ?? pageTitleFallback}
        heroSubtitle={marketplaceOfferingType?.description}
      >
        <OfferingsList
          offerings={offerings}
          offeringTypes={offeringTypes}
          pagination={pagination}
          isLoading={isLoading}
          isErrored={hasError}
          onCardClick={handleNavigateToOfferingDetails}
          onPageChange={handleOnPageChange}
          onNavigateBackClick={handleNavigateToOfferingTypes}
        />

        {children}
      </AdvisorContentContainer>
    </AdvisorContainer>
  );
};

export default OfferingsListAdvisor;
