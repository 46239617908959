import React, { useMemo, useState } from "react";

import { primaryBaseColors } from "@fartherfinance/frontend/theme/Farther/colors";

import {
  AnyCellItem,
  DrillInState,
  isAssetClassCategoryCellItem,
  isAssetClassCellItem,
} from "../Types";

import styles from "./BarGraph.module.css";

const tooltipHeight = 30;

interface BarTooltip {
  xCoord: number;
  yCoord: number;
  dataItem: AnyCellItem;
  idx: number;
  tooltipWidth: number;
}

interface Props {
  data: AnyCellItem[];
  drillInState: DrillInState;
  setDrillInState: (obj: DrillInState) => void;
}

const BarGraph = ({
  data,
  drillInState,
  setDrillInState,
}: Props): JSX.Element => {
  const [curTooltip, setCurTooltip] = useState<BarTooltip | null>(null);

  const generateTooltip = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: AnyCellItem,
    idx: number,
    numItems: number
  ) => {
    const tooltipWidth = item.name.length * 7 + 30; // 7px per char + 30px for padding -> 15 for either side since element will be centered
    const { top, left, right, width } = e.currentTarget.getBoundingClientRect();
    const xAlignLeft = left;
    const xAlignCenter = Math.round(left + width / 2 - tooltipWidth / 2);
    const xAlignRight = right - tooltipWidth - 1; // -1 due to border
    const yCoord = Math.round(top - (tooltipHeight + 10)); // offset by tooltipHeight so to anchor tooltip by bottom left. Offset additional 15px bc don't want the tooltip centered vertically but rather closer to the top

    let xCoord = xAlignCenter;
    if (numItems === 1) {
      // pass - xAlignCenter;
    } else if (idx === 0) {
      xCoord = xAlignLeft;
    } else if (idx === numItems - 1) {
      xCoord = xAlignRight;
    }

    setCurTooltip({
      xCoord: xCoord,
      yCoord: yCoord,
      dataItem: item,
      idx: idx,
      tooltipWidth: tooltipWidth,
    });
  };

  const indexOfAssetClass: number = useMemo(() => {
    if (drillInState.class === null) {
      return -1;
    }

    const index = data.findIndex((item) => item.name === drillInState.class);
    if (index === -1) {
      throw new Error(
        "can't find where item.name === drillInState.class within bar data"
      );
    }
    return index;
  }, [data, drillInState]);

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.container}
        onMouseLeave={() => setCurTooltip(null)}
      >
        {data.map((item, idx) => {
          const placeDivider =
            (idx === 0 || idx < data.length - 1) && data.length > 1;

          let barStyle: React.CSSProperties =
            idx === indexOfAssetClass
              ? {
                  backgroundColor: item.color,
                  border: `3px solid var(--borderSelected)`,
                }
              : {
                  backgroundColor: item.color,
                };

          if (item.dontDrillIn) {
            barStyle = { ...barStyle, opacity: 1.0, cursor: "default" };
          }

          return (
            <div
              key={`${item.name}-bar`}
              className={styles.barContainer}
              style={{ width: `${item.percent * 100}%` }}
            >
              {curTooltip && curTooltip.idx === idx && (
                <div
                  className={styles.barTooltip}
                  style={{
                    top: curTooltip.yCoord,
                    left: curTooltip.xCoord,
                    width: `${curTooltip.tooltipWidth}px`,
                    height: `${tooltipHeight}px`,
                  }}
                >
                  <div className={styles.barTooltipText}>
                    {curTooltip.dataItem.name}
                  </div>
                </div>
              )}

              <div
                className={styles.barBackground}
                style={{ backgroundColor: primaryBaseColors.navy }}
              >
                <div
                  className={styles.bar}
                  style={barStyle}
                  onMouseEnter={(e) =>
                    generateTooltip(e, item, idx, data.length)
                  }
                  onClick={() => {
                    if (item.dontDrillIn) {
                      return;
                    }

                    if (
                      item.level === "Asset Class" &&
                      item.name === drillInState.class
                    ) {
                      return; // clicking on same item so no need to update state/re-render
                    }

                    setDrillInState({
                      category:
                        item.level === "Category" &&
                        isAssetClassCategoryCellItem(item)
                          ? item.name
                          : drillInState.category, // keep the same state for this - category will not change at "Asset Class" level
                      class:
                        item.level === "Asset Class" &&
                        isAssetClassCellItem(item)
                          ? item.name // change "Asset Class" - stay at same level
                          : drillInState.class, // keep state
                    });

                    setCurTooltip(null);
                  }}
                />
              </div>

              {placeDivider && <div className={styles.divider} />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BarGraph;
