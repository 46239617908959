import React from "react";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { random } from "lodash";

import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./Row.module.css";

const RowLoading = (): JSX.Element => {
  const {
    color: { $iconSubtle },
  } = useTheme();

  return (
    <div className={styles.container}>
      <div className={styles.descriptionDiv}>
        <div className={styles.description1}>
          <Skeleton width={random(140, 180, false)} />
        </div>
      </div>
      <div className={styles.allocation}>
        <div>
          <Skeleton width={random(50, 60)} />
        </div>
        <ChevronRightIcon
          style={{
            color: $iconSubtle,
            width: "18px",
            height: "18px",
            visibility: "hidden",
          }}
        />
      </div>
    </div>
  );
};

export default RowLoading;
