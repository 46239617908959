import React, { useCallback, useState } from "react";

import { Box, Slide, Typography } from "@mui/material";
import {
  createSearchParams,
  useSearchParams,
} from "react-router-dom-v5-compat";

import Portal from "@src/multiCustodian/components/Portal/Portal";
// TODO: fix direct imports breaking test suites
import { Button } from "@src/yellowstone/components/Button/Button.component";
import {
  PAGINATION_DEFAULT_PAGE_NUMBER,
  PAGINATION_DEFAULT_PAGE_SIZE,
  PAGINATION_OUTLET_NODE_ID,
} from "@src/yellowstone/modules/shared";

import { useStyles } from "./Pagination.styles";
import { generateRange } from "./Pagination.utils";

interface PaginationProps {
  collectionName: string;
  pageSize: number;
  pageNumber: number;
  totalCount: number;
  onPageChange: (pageNumber: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  collectionName,
  pageSize,
  pageNumber,
  totalCount,
  onPageChange,
}) => {
  const { classes, cx } = useStyles();

  const isPreviousButtonDisabled = pageNumber === 0;
  const isNextButtonDisabled = (pageNumber + 1) * pageSize >= totalCount;

  // NOTE: hide pagination only if there are no elements in collection
  if (totalCount === 0) {
    return null;
  }

  return (
    <Portal targetNodeId={PAGINATION_OUTLET_NODE_ID}>
      <Slide in direction="up">
        <Box className={classes.container}>
          <Box className={classes.content}>
            <Typography className={cx(classes.range, classes.spacer)}>
              {generateRange(pageSize, pageNumber, totalCount, collectionName)}
            </Typography>
            <Button
              className={classes.spacer}
              variant="contained"
              size="large"
              disabled={isPreviousButtonDisabled}
              onClick={() => onPageChange(pageNumber - 1)}
            >
              Previous
            </Button>
            <Button
              variant="contained"
              size="large"
              disabled={isNextButtonDisabled}
              onClick={() => onPageChange(pageNumber + 1)}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Slide>
    </Portal>
  );
};

export const usePagination = () => {
  const [pageSize, setPageSize] = useState(PAGINATION_DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(PAGINATION_DEFAULT_PAGE_NUMBER);

  const handleReset = useCallback((): void => {
    setPageSize(PAGINATION_DEFAULT_PAGE_SIZE);
    setPageNumber(PAGINATION_DEFAULT_PAGE_NUMBER);
  }, []);

  const start = pageSize * pageNumber;
  const end = start + pageSize;

  return {
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    resetPaginationParams: handleReset,
    sliceStart: start,
    sliceEnd: end,
  };
};

const defaultPaginationParams = createSearchParams({
  page: PAGINATION_DEFAULT_PAGE_NUMBER.toString(),
  pageSize: PAGINATION_DEFAULT_PAGE_SIZE.toString(),
});

export const usePersistedPagination = () => {
  const [paginationUrlParams, setPaginationUrlParams] = useSearchParams(
    defaultPaginationParams
  );
  const [totalCount, setTotalCount] = useState(0);

  const handleReset = (): void => {
    setPaginationUrlParams(defaultPaginationParams);
  };

  {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
  }
  const page = parseInt(paginationUrlParams.get("page")!);
  const pageSize = parseInt(paginationUrlParams.get("pageSize")!);
  {
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }

  const start = pageSize * page;
  const end = start + pageSize;

  return {
    page,
    pageSize,
    totalCount,
    setPaginationParams: setPaginationUrlParams,
    resetPaginationParams: handleReset,
    setTotalCount,
    sliceStart: start,
    sliceEnd: end,
  };
};
