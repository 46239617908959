import React, { PropsWithChildren } from "react";

import { useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import CapitalGainsTaxes from "@src/multiCustodian/components/TaxBudgets/CapitalGainsTaxes/CapitalGainsTaxes";
import useTaxBudgetsForClient from "@src/multiCustodian/components/TaxBudgets/Hooks/useTaxBudgetsForClient";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

import ClientDrawer from "./ClientDrawer";
import Empty from "./TaxBudgets/Empty/Empty";
import Overview from "./TaxBudgets/Overview/Overview";

const TaxBudget: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const { data, isLoading, hasError } = useTaxBudgetsForClient(clientId);

  if (hasError) {
    return <div>Error retrieving Tax Budgets</div>;
  }

  if (isLoading) {
    return (
      <ClientDrawer curTab={"Tax Budgets"}>
        <div
          style={{
            height: "70%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LogoLoadingStill />
        </div>
      </ClientDrawer>
    );
  }

  const { currentBudget, futureBudgets, hasBudgets } = data;

  return (
    <ClientDrawer curTab={"Tax Budgets"}>
      <div>
        {hasBudgets && (
          <Overview
            currentBudget={currentBudget}
            futureBudgets={futureBudgets}
          />
        )}

        {!hasBudgets && (
          <>
            <CapitalGainsTaxes />

            <Empty />
          </>
        )}
      </div>

      {children}
    </ClientDrawer>
  );
};

export default TaxBudget;
