import { alpha } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

const CLOSE_ICON_PADDING = 8;

export const useStyles = makeStyles()((theme) => ({
  paper: {
    minWidth: theme.breakpoints.values.sm,
    backgroundColor: theme.appearance.$backgroundSubtlest,

    borderWidth: 1,
    borderStyle: "solid",
    borderColor: alpha(theme.palette.common.white, 0.1),
    borderRadius: "10px",
  },
  header: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(
      pxToSpacing(15 - CLOSE_ICON_PADDING),
      pxToSpacing(15 - CLOSE_ICON_PADDING),
      pxToSpacing(10 - CLOSE_ICON_PADDING)
    ),
  },
  closeIcon: {
    width: 20,
    height: 20,
    color: theme.appearance.$icon,
  },
  content: {
    padding: theme.spacing(pxToSpacing(0), pxToSpacing(30), pxToSpacing(60)),
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",

    borderTopWidth: 1,
    borderTopStyle: "solid",
    borderTopColor: alpha(theme.palette.common.white, 0.1),
    padding: theme.spacing(pxToSpacing(10), pxToSpacing(20)),
  },
}));
