import useClientProfile from "@fartherfinance/frontend/api/Entity/hooks/useClientProfile";
import {
  AdvisorRequestConfig,
  ClientId,
  RequestConfig,
} from "@fartherfinance/frontend/api/Types";

const useClientNameForId = (
  clientId?: ClientId,
  auth?: AdvisorRequestConfig | null
) => {
  const clientAuth =
    auth !== null ? ({ ...auth, clientId: clientId } as RequestConfig) : null;

  const clientProfile = useClientProfile(clientId || null, clientAuth);

  if (clientId && clientProfile.data) {
    const { name } = clientProfile.data.investorProfile.personalDetails;

    return {
      name,
      fullName: `${name.first} ${name.last}`,
      initials: `${name.first.at(0) ?? ""}${
        name.last.at(0) ?? ""
      }`.toUpperCase(),
    };
  }

  return null;
};

export default useClientNameForId;
