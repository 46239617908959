import React from "react";

import CategoryCell from "../ChipCell/ChipCell";
import {
  AnyCellItem,
  DrillInState,
  isAssetClassCategoryCellItem,
  isAssetClassCellItem,
} from "../Types";

import styles from "./CategoryOrClassChips.module.css";

interface Props {
  data: AnyCellItem[];
  drillInState: DrillInState;
  setDrillInState: (obj: DrillInState) => void;
}

const CategoryOrClassChips = ({
  data,
  drillInState,
  setDrillInState,
}: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      {data.map((item) => {
        return (
          <CategoryCell
            key={item.name}
            item={item}
            showBorder={true}
            style={{ marginRight: "10px" }}
            onClick={() => {
              let category = drillInState.category; // keep the same state for this if item.level === "Asset Class"
              if (isAssetClassCategoryCellItem(item)) {
                category = item.name;
              } else if (item.level === "All") {
                category = null;
              }

              let classData = null;
              if (isAssetClassCellItem(item)) {
                classData = item.name;
              }

              setDrillInState({
                category: category,
                class: classData,
              });
            }}
          />
        );
      })}
    </div>
  );
};

export default CategoryOrClassChips;
