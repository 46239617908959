export const exchangeNameOptions = [
  "New York Stock Exchange",
  "Nasdaq",
  "Shanhai Stock Exchange",
  "Euronext Amsterdam",
  "Euronext Brussels",
  "Euronext Dublin",
  "Euronext Lisbon",
  "Euronext Milan",
  "Euronext Oslo",
  "Euronext Paris",
  "FINRA",
  "Japan Exchange Group",
  "Hong Kong Stock Exchange",
  "Shenzhen Stock Exchange",
  "London Stock Exchange",
  "Bombay Stock Exchange",
  "National Stock Exchange",
  "Toronto Stock Exchange",
  "Deutsche Börse AG",
  "Saudi Stock Exchange",
  "Korea Exchange",
  "SIX Swiss Exchange",
  "Taiwan Stock Exchange",
  "Australian Securities Exchange",
  "Johannesburg Stock Exchange",
] as const;

export type ExchangeName = typeof exchangeNameOptions[number];
