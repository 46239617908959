import React from "react";

const Accounts: React.FC = () => (
  <svg
    width="100%"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 54.4505L7 21.4232H57V31.3755C57 34.7517 54.263 37.4887 50.8868 37.4887V38.4116C54.263 38.4116 57 41.1486 57 44.5248V54.4505H7Z"
      fill="currentColor"
    />
    <path
      d="M50.4997 18.6419H14L46.9316 10.051C47.7882 9.82756 48.6593 10.3597 48.8513 11.224L50.4997 18.6419Z"
      fill="currentColor"
    />
  </svg>
);

export default Accounts;
