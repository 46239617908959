import React, { ReactNode } from "react";

import styles from "./ProfileBox.module.css";

interface Props {
  isAdvisor: boolean;
  name: string;
  avatar: ReactNode;
  popover: ReactNode;
}

const ProfileBox: React.FC<Props> = ({
  isAdvisor,
  name,
  avatar,
  popover,
}: Props) => {
  return (
    <>
      <div className={styles.profile}>
        {avatar}

        <div className={styles.textContainer}>
          {isAdvisor && <div className={styles.advisorLabel}>Advisor</div>}

          <div className={styles.text}>{name}</div>
        </div>
      </div>

      {popover}
    </>
  );
};

export default ProfileBox;
