import React, { useEffect, useMemo, useState } from "react";

import { Stack } from "@mui/material";
import { formatISO } from "date-fns";
import { identity, orderBy, truncate } from "lodash";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { SMAAsset } from "@fartherfinance/frontend/api/AltAssetsService/requests/Types";
import useAdvisorClients from "@fartherfinance/frontend/api/Entity/hooks/useAdvisorClients";
import { Client } from "@fartherfinance/frontend/api/Entity/requests/getAdvisorClients";
import useCreateTask from "@fartherfinance/frontend/api/Tasks/hooks/useCreateTask";
import { PostTaskRequest } from "@fartherfinance/frontend/api/Tasks/requests/postTask";
import {
  AssigneeType,
  TaskPriority,
  TaskStatus,
  TaskType,
} from "@fartherfinance/frontend/api/Tasks/Types";
import {
  AccountType,
  ClientId,
  Custodian,
} from "@fartherfinance/frontend/api/Types";
import { mapObject } from "@fartherfinance/frontend/utils/mapObject";

import custodianAccountTypes, {
  CustodianAccountTypesDB,
} from "@src/constants/custodianAccountTypes";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import Portal from "@src/multiCustodian/components/Portal/Portal";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { useAssigneesOptions } from "@src/multiCustodian/pages/Advisor/Tasks/hooks";
import formatAmount from "@src/multiCustodian/pages/Dashboard/Dashboard_Components/DashboardForms/formatters/formatAmount";
import isValidLocaleNumber from "@src/multiCustodian/pages/Dashboard/Dashboard_Components/DashboardForms/validators/isValidLocaleNumber";
import useThemeFragment from "@src/multiCustodian/theme/useThemeFragment";
import FileUploadButton from "@src/sharedComponents/FileUploadButton/FileUploadButton";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import FormH2 from "@src/sharedComponents/Forms/FormH2";
import FormTextareaField from "@src/sharedComponents/Forms/FormTextareaField";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import FormFieldLabel from "@src/sharedComponents/Forms/Private/FormFieldLabel";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import TextInput from "@src/sharedComponents/TextInput/TextInput";

import styles from "./ApplySMAForm.module.css";

/* Fixed Task Presets */
const ASSIGNEE_TYPE = AssigneeType.Values.GROUP;
const TASK_TYPE = TaskType.Values.OTHER;
const TASK_STATUS = TaskStatus.Values.NOT_STARTED;
const TASK_PRIORITY = TaskPriority.Values.MEDIUM;
const TASK_DUE_DATE = formatISO(new Date(), {
  representation: "date",
});

/* Form Fields */
const CustodianField = "Custodian";
const ClientField = "Client";
const ContactField = "Contact";
const AccountTypeField = "AccountType";
const FundingField = "Funding";
const InstructionsField = "Instructions";

interface Form {
  [CustodianField]: { label: Custodian };
  [ClientField]: { label: string; clientId: ClientId } | undefined;
  [ContactField]: string | undefined;
  [AccountTypeField]: { label: AccountType } | undefined;
  [FundingField]: string | undefined;
  [InstructionsField]: string | undefined;
}

interface Props {
  sma: SMAAsset;
  cancel: () => void;
  portalId: string;
}

// Based on https://fartherfinance.slack.com/archives/C04L65Q36QG/p1695763620447409?thread_ts=1695763309.210989&cid=C04L65Q36QG
const adjustedAllowedAccounts: CustodianAccountTypesDB = {
  ...custodianAccountTypes,
  Schwab: {
    Individual: true,
    IRA: true,
    "Roth IRA": true,
    "Sep IRA": true,
    Trust: true,
    "Joint WROS": true,
    "Joint TIC": true,
    "Joint CP": true,
    "Joint TE": true,
    "Asset Lending": true,
    Other: true,
    "Rollover IRA": true,
    "Inherited IRA": false,
    "Inherited Roth IRA": false,
  },
  Fidelity: {
    Individual: true,
    IRA: true,
    "Roth IRA": true,
    "Sep IRA": true,
    Trust: true,
    "Joint WROS": true,
    "Joint TIC": true,
    "Joint CP": true,
    "Joint TE": true,
    "Asset Lending": true,
    Other: true,
    "Rollover IRA": true,
    "Inherited IRA": false,
    "Inherited Roth IRA": false,
  },
};

const makeLabel = (client: Client): string =>
  `${client.name.last}, ${client.name.first} (...${client.clientId.substring(
    client.clientId.length - 4
  )})`;

const ApplySMAForm = (props: Props): JSX.Element => {
  const [proposalFile, setProposalFile] = useState<File | null>(null);

  const [mutating, setMutating] = useState(false);

  const [showPortal, setShowPortal] = useState(false);

  useEffect(() => {
    // the target element (node with id = props.portalId) does not get rendered before this component so need to wait
    setTimeout(() => setShowPortal(true), 200);
  }, []);

  const history = useHistory();

  const statusNotification = useStatusNotification();

  const auth = useAdvisorRequestAuth();

  const assigneesOptions = useAssigneesOptions();

  const t = useThemeFragment("Farther");

  const assigneeId = useMemo(() => {
    const label = `${t("companyName")} Service Team`;
    //IDs can differ between envs, so we scan for it
    const assignee = assigneesOptions.find(
      (assignee) =>
        assignee.label.toLowerCase() === label.toLowerCase() &&
        assignee.meta.type === "GROUP"
    );

    return assignee?.value ?? null;
  }, [assigneesOptions, t]);

  const createTask = useCreateTask(auth);

  const clients = useAdvisorClients(undefined, auth);

  const clientOptions = useMemo(() => {
    return (clients.data?.clients ?? []).map((c) => ({
      label: makeLabel(c),
      clientId: c.clientId,
    }));
  }, [clients.data?.clients]);

  const methods = useForm<Form>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      Custodian: {
        label: orderBy(props.sma.custodianAvailability, identity, "asc")[0],
      },
    },
  });

  const {
    getValues,
    control,
    formState: { isValid, isDirty },
  } = methods;

  const custodian = useWatch({ control, name: CustodianField });

  const accountTypeOptions = useMemo(() => {
    const mapped = mapObject(
      adjustedAllowedAccounts[custodian.label],
      (enabled, type) => ({
        enabled,
        type,
      })
    );

    return mapped
      .filter((acc) => acc.enabled === true)
      .map((acc) => ({ label: acc.type }));
  }, [custodian.label]);

  const disabled = !isValid || !isDirty;

  const apply = async () => {
    if (disabled) {
      statusNotification("Could not apply SMA", "Error");
      return;
    }

    if (auth === null) {
      statusNotification("Could not find a valid advisor ID", "Error");
      return;
    }

    if (assigneeId === null) {
      statusNotification("Could not find a valid assignee ID", "Error");
      return;
    }

    const values = getValues();

    if (
      values[ClientField] === undefined ||
      values[FundingField] === undefined ||
      values[AccountTypeField] === undefined
    ) {
      statusNotification(
        "Could not apply SMA, some fields are incomplete",
        "Error"
      );
      return;
    }

    //account for potential non-undefined empty strings
    const pointOfContact =
      values[ContactField] === undefined || values[ContactField].trim() === ""
        ? "Not provided"
        : values[ContactField];

    const fundingInstructions =
      values[InstructionsField] === undefined ||
      values[InstructionsField].trim() === ""
        ? "Not provided"
        : values[InstructionsField];

    const description = [
      `- SMA Manager: ${props.sma.firmName}`,
      `- SMA Name: ${props.sma.shortDescription}`,
      `- SMA Strategy: ${props.sma.featuredStrategy.join(", ")}`,
      `- Point of Contact: ${pointOfContact}`,
      `- Custodian: ${values[CustodianField].label}`,
      `- Account Type: ${values[AccountTypeField].label}`,
      `- Funding Amount: $${values[FundingField]}`,
      `- SMA Details: ${window.location.origin}/Advisor/Investments/SMA/${props.sma.assetId}`,
      "",
      "",
      "### Funding instructions",
      fundingInstructions,
      "",
      "",
    ].join("\n");

    const taskRequest: PostTaskRequest = {
      payload: {
        title: truncate(`Apply SMA ${props.sma.firmName}`, {
          length: 32,
        }),
        assigneeId: {
          type: ASSIGNEE_TYPE,
          id: assigneeId,
        },
        advisorId: auth.advisorId,
        clientId: values[ClientField].clientId,
        description,
        taskType: TASK_TYPE,
        taskStatus: TASK_STATUS,
        taskPriority: TASK_PRIORITY,
        dueDate: TASK_DUE_DATE,
      },
      attachments: proposalFile === null ? undefined : [proposalFile],
    };

    setMutating(true);

    try {
      const taskDetails = await createTask(taskRequest);

      history.push(`/Advisor/Tasks/${taskDetails.id}`);
    } catch {
      statusNotification("Could not apply SMA", "Error");
    } finally {
      setMutating(false);
    }
  };

  return (
    <div className={styles.container}>
      {mutating && (
        <div className={styles.loading}>
          <LogoLoadingStill />
        </div>
      )}
      <FormProvider {...methods}>
        <FormH1>Apply {props.sma.firmName} SMA to an Account</FormH1>

        <Spacer verticalSpacing="24px" />

        <FormH2>Investment Details</FormH2>

        <FlexWrapper>
          <TextInput
            label="SMA"
            value={`${props.sma.firmName} - ${props.sma.shortDescription}`}
            disabled
          />

          <FormDropdownField
            name={CustodianField}
            label="Custodian"
            values={props.sma.custodianAvailability.map((c) => ({ label: c }))}
            disableSearch
            required="Must not be empty"
          />

          <FormDropdownField
            name={ClientField}
            label="Client"
            values={clientOptions}
            required="Must not be empty"
          />

          <FormTextField
            name={ContactField}
            label={
              <div>
                Point of Contact{" "}
                <span className={styles.normalWeight}>(optional)</span>
              </div>
            }
            helperText="Name, email, phone number at investment manager"
          />
        </FlexWrapper>

        <Spacer verticalSpacing="24px" />

        <FormFieldLabel
          label={
            <div className={styles.label}>
              Proposal <span className={styles.normalWeight}>(optional)</span>
            </div>
          }
        />

        <FileUploadButton onChange={setProposalFile} />

        <Spacer verticalSpacing="48px" />

        <FormH2>New Account</FormH2>

        <FlexWrapper>
          <FormDropdownField
            name={AccountTypeField}
            label="Account Type"
            values={accountTypeOptions}
            required="Must not be empty"
            disableSearch
          />

          <FormTextField
            name={FundingField}
            label="Funding Amount"
            helperText={`Needs to be greater than or equal to ${
              props.sma.minimumInvestment ?? "$10,000"
            }`}
            required="Must not be empty"
            startAdornment="$"
            valueFormatterOnChange={formatAmount}
            valueFormatterOnBlur={formatAmount}
            rules={{
              validate: {
                isValidNumber: (e) => isValidLocaleNumber(e, true),
              },
            }}
            inputMode="numeric"
          />
        </FlexWrapper>

        <Spacer verticalSpacing="24px" />

        <FormTextareaField
          name={InstructionsField}
          label={
            <div>
              Funding Instructions{" "}
              <span className={styles.normalWeight}>(optional)</span>
            </div>
          }
          placeholder="Provide details for CX (e.g. where the funds will come from) to fund the new account..."
        />
      </FormProvider>

      {showPortal && (
        <Portal targetNodeId={props.portalId}>
          <Stack direction={"row"} gap={"16px"} alignItems={"center"}>
            <ButtonPrimary
              buttonType="primary"
              variant="outlined"
              text="Cancel"
              onClick={props.cancel}
            />

            <ButtonPrimary
              buttonType="primary"
              variant="contained"
              text="Apply SMA"
              disabled={disabled}
              onClick={apply}
            />
          </Stack>
        </Portal>
      )}
    </div>
  );
};

export default ApplySMAForm;
