import React from "react";

import { useSelector } from "react-redux";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import execute_statusNotification_ts from "@src/multiCustodian/components/GlobalStatusNotification/notification_ts";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useImpersonateClient from "@src/multiCustodian/hooks/Advisor/useImpersonateClient";
import { State as ReduxState } from "@src/store";

interface Props {
  clientId: ClientId;
  clientEmail: string;
  clientName: {
    first: string;
    last: string;
  };
}

const ImpersonateClientButton: React.FC<Props> = (props) => {
  const impersonateClient = useImpersonateClient();

  const { smallXIcon } = useSelector((state: ReduxState) => ({
    smallXIcon: state.main_Reducer.brand.current.images.small_x_icon,
  }));

  return (
    <Button
      variant="contained"
      buttonType="primary"
      style={{ display: "block", whiteSpace: "nowrap" }}
      text="Login"
      onClick={(event) => {
        // this is required so that the row's onClick does not also fire
        event.stopPropagation();

        impersonateClient(
          props.clientId,
          props.clientEmail,
          props.clientName
        ).catch((e) => {
          console.error(e);

          execute_statusNotification_ts(
            "Could not log in as client",
            "error",
            smallXIcon,
            "/src/assets/svg/!.svg",
            true
          );
        });
      }}
    />
  );
};

export default ImpersonateClientButton;
