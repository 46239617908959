import React from "react";

import { InputAdornment } from "@mui/material";

import AdvisorChips from "../../SharedComponents/AdvisorChip";
import searchIcon from "@src/assets/svg/search_icon.svg";
import TextInput from "@src/sharedComponents/TextInput/TextInput";

import styles from "./Header.module.css";

type Props = {
  tableSearch: string;
  setTableSearch: (s: string) => void;
};

const Header = (props: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      <div className={styles.searchPlusChip}>
        <TextInput
          label={""}
          value={props.tableSearch}
          onChange={(e) => props.setTableSearch(e.target.value)}
          placeholder={"Search SMAs..."}
          style={{ width: "280px", marginRight: "20px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"}>
                <img src={searchIcon} />
              </InputAdornment>
            ),
          }}
        />

        <AdvisorChips />
      </div>
    </div>
  );
};

export default Header;
