import React from "react";

import Box from "@mui/material/Box";

import { useStyles } from "./TableCell.styles";

export interface TableCellProps {
  children: React.ReactNode;
  className?: string;
}

export const TableCell: React.FC<TableCellProps> = ({
  children,
  className,
}) => {
  const { classes, cx } = useStyles();

  return <Box className={cx(classes.cell, className)}>{children}</Box>;
};
