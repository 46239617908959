import React from "react";

import { AdvisorId } from "@fartherfinance/frontend/api/Types";

import { AdvisorWithFullName } from "@src/multiCustodian/pages/Dashboard/Performance_Groups/tabs/SummaryTab/SummaryTabV4";
import Select from "@src/sharedComponents/Select/Select";
import SelectItem from "@src/sharedComponents/Select/SelectItem";

interface AdvisorDropdownProps {
  value: AdvisorWithFullName;
  options: AdvisorWithFullName[];
  onChange: (id: AdvisorId) => void;
  className?: string;
}

export default function AdvisorDropdown(props: AdvisorDropdownProps) {
  return (
    <Select<AdvisorWithFullName, AdvisorId>
      className={props.className}
      value={props.value.advisorId}
      options={props.options}
      onChange={props.onChange}
      renderValue={(v) => {
        if (v === props.value.advisorId) {
          return <>{props.value.fullName}</>;
        } else {
          return <>Unknown</>;
        }
      }}
      renderOption={(advisor) => (
        <SelectItem
          key={advisor.advisorId}
          label={advisor.fullName}
          value={advisor.advisorId}
        />
      )}
    />
  );
}
