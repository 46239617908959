import React, { createContext, Dispatch } from "react";

import { initialState } from "./initialState";
import { ActionPayload, EditCollaboratorFlowData, FlowState } from "./types";

const EditCollaboratorContext = createContext<{
  state: FlowState<EditCollaboratorFlowData>;
  dispatch: Dispatch<ActionPayload>;
}>({
  state: initialState,
  dispatch: () => null,
});

export function useEditCollaboratorContext() {
  return React.useContext(EditCollaboratorContext);
}

export default EditCollaboratorContext;
