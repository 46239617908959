import React from "react";

import { useHistory, useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import { CashManagementPath } from "@src/config/routing/RouterPaths";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";

import styles from "./RecurringTransfers.module.css";

const RecurringTransfersSetUp = (): JSX.Element => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const history = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.header2}>
        <div className={styles.headerInnerDiv}>
          <p className={styles.heading}>Recurring Transfers</p>

          <ButtonPrimary
            text={"Set Up"}
            variant="contained"
            buttonType="primary"
            onClick={() =>
              history.push(
                `/Client/${clientId}/${CashManagementPath}/SetupRecurring`
              )
            }
            sx={{ height: "30px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default RecurringTransfersSetUp;
