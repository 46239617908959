import React, { useRef } from "react";

import TodayIcon from "@mui/icons-material/Today";

import CustomDateRangeSelector from "@src/multiCustodian/components/PerformanceGroups/Summary/components/CustomDateRangeSelector";
import Popover from "@src/sharedComponents/Popover/Popover";

import { usePerformanceCustomDateRangeContext } from "./PerformanceCustomDateRangeContextProvider";

import styles from "./PerformanceCustomDateRangePopover.module.css";
interface Props {
  applyCustomDateRange: () => void;
  resetToDefaultTimeFrame: () => void;
}

const PerformanceCustomDateRangePopover: React.FC<Props> = ({
  applyCustomDateRange,
  resetToDefaultTimeFrame,
}) => {
  const calendarIconAnchorRef = useRef<HTMLDivElement | null>(null);

  const { showCustomDateRangeForm, setShowCustomDateRangeForm } =
    usePerformanceCustomDateRangeContext();

  const handleOnClose = () => setShowCustomDateRangeForm(false);

  const handleOnApply = () => {
    setShowCustomDateRangeForm(false);
    applyCustomDateRange();
  };

  return (
    <Popover
      open={showCustomDateRangeForm}
      onClose={handleOnClose}
      anchor={calendarIconAnchorRef.current}
      anchorOriginVertical={-12}
      anchorOriginHorizontal={"center"}
      transformOriginVertical={"bottom"}
      transformOriginHorizontal={"center"}
      popoverElement={
        <div
          ref={calendarIconAnchorRef}
          onClick={() => setShowCustomDateRangeForm(!showCustomDateRangeForm)}
        >
          <TodayIcon className={styles.calendarIcon} />
        </div>
      }
    >
      <CustomDateRangeSelector
        onApply={handleOnApply}
        resetToDefaultTimeFrame={resetToDefaultTimeFrame}
      />
    </Popover>
  );
};

export default PerformanceCustomDateRangePopover;
