import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  container: {
    paddingBottom: 30,
  },
  error: {
    fontSize: theme.typography.pxToRem(20),
    textAlign: "center",
    marginTop: 150,
  },
}));
