import React from "react";

import { useParams } from "react-router-dom";

import useSetMFASettings from "@fartherfinance/frontend/api/Identity/hooks/useSetMFASettings";
import { AdvisorId, ClientId } from "@fartherfinance/frontend/api/Types";

import errorHandler from "@src/multiCustodian/components/Login/errorHandler";
import { formatPhoneNumber } from "@src/multiCustodian/components/Login/formatPhoneNumber";
import Layout from "@src/multiCustodian/components/Login/Layout";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import TextInput from "@src/sharedComponents/TextInput/TextInput";

import * as styles from "@src/multiCustodian/components/Login/StytchLogin.module.css";

interface Props {
  onBack?: () => void;
  onSetMFA: (methodId: string | null) => Promise<void>;
}

const UsPhoneNumber = /^\+1\d{10}$/;

const EnterPhoneNumber: React.FC<Props> = ({ onBack, onSetMFA }) => {
  const { advisorId, clientId } = useParams<
    | {
        clientId: ClientId;
        advisorId: undefined;
      }
    | {
        clientId: undefined;
        advisorId: AdvisorId;
      }
  >();

  const personId = clientId ?? advisorId;

  const clientAuth = useRequestAuth();

  const advisorAuth = useAdvisorRequestAuth();

  const auth = clientAuth ?? advisorAuth;

  const notification = useStatusNotification();

  const setMFASettings = useSetMFASettings(auth);

  const [phone, setPhone] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const phoneForAPI = `+1${phone.replace(/[^A-Z0-9]/gi, "")}`;

  const onContinueClick = async () => {
    setIsLoading(true);

    try {
      const res = await setMFASettings({
        personId,
        body: { enable2FA: true, phoneNumber: phoneForAPI },
      });

      if (res?.enable2FA === true) {
        onSetMFA(res?.methodId);
      } else {
        throw new Error("Failed to enable MFA");
      }
    } catch (err) {
      const notificationMessage = errorHandler(err);

      notification(notificationMessage, "Error");
    } finally {
      setIsLoading(false);
    }
  };

  const phoneValid = UsPhoneNumber.test(phoneForAPI);

  const submitDisabled = phoneValid === false;

  return (
    <Layout
      onBack={onBack}
      subtitle="Add extra security to your Farther account by setting up multiple layers of protection."
      title="Two-Factor Authentication"
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (submitDisabled) {
            return;
          }

          onContinueClick();
        }}
      >
        <TextInput
          autoFocus
          autoComplete="tel"
          type="tel"
          label="Phone Number"
          placeholder="(281) 937-4192"
          value={formatPhoneNumber(phone)}
          onChange={(e) => {
            e.preventDefault();
            setPhone(e.target.value);
          }}
        />
        <div className={styles.spacer} />
        <Button
          disabled={submitDisabled}
          text="Continue"
          onClick={onContinueClick}
          variant="contained"
          buttonType="primary"
          sx={{ width: "100%" }}
          style={{ width: "100%" }}
          useLoader
          isLoading={isLoading}
        />

        <input type="submit" style={{ display: "none" }} />
      </form>
    </Layout>
  );
};

export default EnterPhoneNumber;
