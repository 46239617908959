import { EditCollaboratorFlowData, FlowState } from "./types";

export const isSaveButtonDisabled = (
  state: FlowState<EditCollaboratorFlowData>
): boolean => {
  switch (state.pageIndex) {
    case 0:
      return (
        !state.data.firstName ||
        !state.data.lastName ||
        (!state.data.collaboratorType && !state.data.serviceProfessionalType) ||
        !state.data.accessLevel ||
        (state.data.accessLevel === "Limited Access" &&
          state.data.resources.length === 0)
      );
    default:
      return false;
  }
};
