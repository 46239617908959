import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

export const useStyles = makeStyles()((theme) => ({
  menuPaper: {
    minWidth: 180,
    padding: theme.spacing(pxToSpacing(11), 0),
    borderRadius: theme.shape.borderRadius,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.appearance.$borderBold,
    backgroundColor: theme.appearance.$backgroundSubtlest,
  },
  menuList: {
    padding: 0,
  },
  menuItem: {
    height: 36,
    padding: theme.spacing(0, pxToSpacing(20)),

    transition: theme.transitions.create(["background"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.standard,
    }),

    "&:hover": {
      backgroundColor: theme.colors.primary.shades.cream20,
    },
  },
  menuItemText: {
    fontFamily: theme.typography.fontSecondary,
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: "19px",
    color: theme.appearance.$text,
  },
}));
