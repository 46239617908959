import React, { createContext, PropsWithChildren, useState } from "react";

export interface CustomDateRange {
  from: Date | null;
  to: Date | null;
}

const initialValue: CustomDateRange = {
  from: null,
  to: null,
};

const PerformanceCustomDateRangeContext = createContext<{
  customDateRange: CustomDateRange;
  setCustomDateRange: (cdr: CustomDateRange) => void;
  showCustomDateRangeForm: boolean;
  setShowCustomDateRangeForm: (b: boolean) => void;
}>({
  customDateRange: initialValue,
  setCustomDateRange: () => undefined,
  showCustomDateRangeForm: false,
  setShowCustomDateRangeForm: () => undefined,
});

export function usePerformanceCustomDateRangeContext() {
  return React.useContext(PerformanceCustomDateRangeContext);
}

const PerformanceCustomDateRangeContextProvider: React.FC<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const [customDateRange, setCustomDateRange] =
    useState<CustomDateRange>(initialValue);

  const [showCustomDateRangeForm, setShowCustomDateRangeForm] =
    useState<boolean>(false);

  return (
    <PerformanceCustomDateRangeContext.Provider
      value={{
        customDateRange,
        setCustomDateRange,
        showCustomDateRangeForm,
        setShowCustomDateRangeForm,
      }}
    >
      {children}
    </PerformanceCustomDateRangeContext.Provider>
  );
};

export default PerformanceCustomDateRangeContextProvider;
