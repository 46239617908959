import { isNil } from "lodash";

import { EmployedForm } from "../types";
import { EmploymentStatus } from "@src/constants/employment";

interface PartialForm {
  EmploymentStatus: { label: EmploymentStatus } | null | undefined;
}

export default function isEmployedForm(
  form: PartialForm
): form is EmployedForm {
  return (
    !isNil(form.EmploymentStatus) && form.EmploymentStatus.label === "Employed"
  );
}
