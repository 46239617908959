import React, { useCallback, useState } from "react";

import WarningIcon from "@mui/icons-material/Warning";
import Collapse from "@mui/material/Collapse";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import Text from "@src/sharedComponents/Typography/Text";

import styles from "./PlanValidationErrorsTable.module.css";

interface PlanValidationErrorsTableProps {
  validationErrors: string[];
}

const PlanValidationErrorsTable: React.FC<PlanValidationErrorsTableProps> = ({
  validationErrors,
}) => {
  const [isListExpanded, setIsListExpanded] = useState(false);

  const getErrorIndex = useCallback(
    (error: string) =>
      validationErrors.findIndex(
        (validationError) => validationError === error
      ) + 1,
    [validationErrors]
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Text variant="title3">
          Please address these errors to submit the plan
        </Text>

        <div className={styles.errorsCount}>
          <WarningIcon className={styles.errorCountIcon} />
          <Text variant="body" className={styles.errorCountText}>
            {validationErrors.length} errors
          </Text>
        </div>
      </div>

      {validationErrors.slice(0, 3).map((error, index) => (
        <div key={index} className={styles.validationError}>
          <Text variant="body">{getErrorIndex(error)}</Text>
          <Text variant="body" className={styles.validationErrorText}>
            {error}
          </Text>
        </div>
      ))}
      <Collapse in={isListExpanded}>
        {validationErrors.slice(3).map((error, index) => (
          <div key={index} className={styles.validationError}>
            <Text variant="body">{getErrorIndex(error)}</Text>
            <Text variant="body" className={styles.validationErrorText}>
              {error}
            </Text>
          </div>
        ))}
      </Collapse>

      {validationErrors.length > 3 && (
        <div className={styles.footer}>
          <Button
            text={isListExpanded ? "Show Less" : "Show More"}
            onClick={() =>
              setIsListExpanded((isListExpanded) => !isListExpanded)
            }
            variant="text"
            buttonType="primary"
            style={{ justifyContent: "unset" }}
            sx={{ padding: 0 }}
          />
        </div>
      )}
    </div>
  );
};

export default PlanValidationErrorsTable;
