import { isNil } from "lodash";

import { ClientAffiliations } from "@fartherfinance/frontend/api/Entity/requests/getClientProfile";

import { Form } from "../types";

import { splitEmployeeName } from "./splitEmployeeName";

export default function mapValuesToSubmitForm(
  values: Form
): ClientAffiliations {
  const exchangeName =
    values.StockExchange === "Yes"
      ? values.StockExchangeName?.label ?? null
      : null;

  const publicCompanyName =
    values.PublicCompany === "Yes" ? values.PublicCompanyName : null;

  const brokerDealerName =
    values.BrokerDealerAffiliated === "Yes" ? values.BrokerDealerName : null;

  const employeeFullName =
    "FartherEmployeeFullName" in values
      ? values.FartherEmployeeFullName ?? null
      : null;

  const { first: employeeFirst, last: employeeLast } =
    splitEmployeeName(employeeFullName);

  const fartherEmployeeRelative =
    values.FartherEmployeeRelated === "Yes" &&
    !isNil(values.FartherEmployeeFullName) &&
    !isNil(values.FartherEmployeeRelationship)
      ? {
          name: {
            first: employeeFirst,
            last: employeeLast,
            middle: null,
            prefix: null,
            suffix: null,
          },
          relationship: values.FartherEmployeeRelationship.label,
        }
      : null;

  const brokerDealerRelative =
    values.FamilyMemberBrokerAffiliated === "Yes" &&
    !isNil(values.FamilyBrokerDealerRelationship) &&
    !isNil(values.FamilyBrokerDealerName) &&
    !isNil(values.FamilyBrokerDealerCompany)
      ? {
          name: {
            first: values.FamilyBrokerDealerName,
            last: "",
            middle: null,
            prefix: null,
            suffix: null,
          },
          relationship: values.FamilyBrokerDealerRelationship.label,
          company: values.FamilyBrokerDealerCompany,
        }
      : null;

  const custodianName =
    values.CustodianAffiliated === "Yes"
      ? values.CustodianName?.label ?? null
      : null;

  const mappedForm: ClientAffiliations = {
    isUsOfficial: values.USOfficial === "Yes",
    isFartherEmployee: false, //The backend ignores this field
    exchangeName,
    publicCompanyName: publicCompanyName ?? null,
    brokerDealerName: brokerDealerName ?? null,
    fartherEmployeeRelative,
    brokerDealerRelative,
    custodianName,
  };

  return mappedForm;
}
