import React from "react";

import { Box, Typography } from "@mui/material";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./Message.module.css";

interface MessageProps {
  className?: string;
  text: string;
}

export default function Message({
  className,
  text,
}: MessageProps): JSX.Element {
  return (
    <Box className={toClassName(styles.container, className)}>
      <Typography className={styles.message}>{text}</Typography>
    </Box>
  );
}
