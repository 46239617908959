import React, { useCallback, useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import { TaxBudget } from "@fartherfinance/frontend/api/InvestmentModel/Types";
import { ClientId, TaxBudgetId } from "@fartherfinance/frontend/api/Types";

import Select from "@src/sharedComponents/Select/Select";
import SelectItem from "@src/sharedComponents/Select/SelectItem";

import styles from "./YearDropdown.module.css";

interface Props {
  budgets: TaxBudget[];
  pathPrefix: string;
}

const YearDropdown: React.FC<Props> = ({ budgets, pathPrefix }) => {
  const { clientId, taxBudgetId } = useParams<{
    clientId: ClientId;
    taxBudgetId: TaxBudgetId;
  }>();

  const history = useHistory();

  const budget = budgets?.find((b) => b?.budgetId === taxBudgetId);

  const [taxYear, setTaxYear] = useState<number | undefined>(budget?.year);

  const options = budgets?.map((b) => b?.year).sort((a, b) => a - b) || [];

  const pushToNewBudget = useCallback(
    (budgetId: TaxBudgetId) => {
      history.push({
        ...history.location,
        pathname: `${pathPrefix}/${clientId}/TaxBudgets/${budgetId}/Usage`,
      });
    },
    [clientId, history, pathPrefix]
  );

  useEffect(() => {
    if (budget && !taxYear) {
      setTaxYear(budget.year);
    }

    if (taxYear && taxYear !== budget?.year) {
      const newBudget = budgets?.find((b) => b?.year === taxYear);

      if (newBudget) {
        pushToNewBudget(newBudget.budgetId as TaxBudgetId);
      }
    }
  }, [budget, budgets, pushToNewBudget, taxYear]);

  const handleOnChange = (year: number) => {
    setTaxYear(parseInt(year as unknown as string));
  };

  if (options.length < 2) {
    return null;
  }

  return (
    <Select
      className={styles.select}
      options={options}
      value={taxYear || 0}
      onChange={handleOnChange}
      renderValue={(v: number) => `Tax Year ${v}`}
      renderOption={(o: number) => {
        return <SelectItem key={o} label={`Tax Year ${o}`} value={`${o}`} />;
      }}
    />
  );
};

export default YearDropdown;
