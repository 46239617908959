import React from "react";

import { useParams } from "react-router-dom";

import useGetTaxBudgetsForClient from "@fartherfinance/frontend/api/InvestmentModel/hooks/useGetTaxBudgetsForClient";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import AdvisorReviewBudget from "../ReviewBudget/ReviewBudget";
import AdvisorSelectAccounts from "../SelectAccounts/SelectAccounts";
import { useCreateEditTaxBudgetContext } from "@src/multiCustodian/components/TaxBudgets/reducer/Context";
import ReviewDisclosures from "@src/multiCustodian/components/TaxBudgets/ReviewDisclosures/ReviewDisclosures";
import SelectBudget from "@src/multiCustodian/components/TaxBudgets/SelectBudget/SelectBudget";
import SelectYear from "@src/multiCustodian/components/TaxBudgets/SelectYear/SelectYear";
import Success from "@src/multiCustodian/components/TaxBudgets/Success/Success";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";

const FlowBody: React.FC = () => {
  const { clientId } = useParams<{ clientId: ClientId }>();

  const { state } = useCreateEditTaxBudgetContext();

  const { pageIndex } = state;

  const advisorAuth = useAdvisorRequestAuth();

  const auth = advisorAuth ? { ...advisorAuth, clientId: clientId } : null;

  const { data: budgets } = useGetTaxBudgetsForClient({ clientId }, auth);

  switch (pageIndex) {
    case 0:
      return <SelectYear budgets={budgets || []} />;
    case 1:
      return <AdvisorSelectAccounts />;
    case 2:
      return <SelectBudget />;
    case 3:
      return <ReviewDisclosures />;
    case 4:
      return <AdvisorReviewBudget />;
    case 5:
      return <Success />;
    default:
      return null;
  }
};

export default FlowBody;
