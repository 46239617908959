import React, { useEffect, useMemo, useState } from "react";

import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { InputAdornment } from "@mui/material";
import { isAxiosError } from "axios";
import { isNil, orderBy } from "lodash";
import { useSelector } from "react-redux";

import useGetFartherManagedAccounts from "@fartherfinance/frontend/api/Accounts/hooks/useGetFartherManagedAccounts";
import { FartherManagedAccount } from "@fartherfinance/frontend/api/Accounts/Types";
import {
  getExternalAccountBalance,
  getPartialAccountNumber,
  MASK_NUMBER,
} from "@fartherfinance/frontend/api/Accounts/utilities/accountUtil";
import useClientCustodian, {
  ClientsCustodian,
} from "@fartherfinance/frontend/api/Dashboard/hooks/useClientCustodian";
import useClientDashboard from "@fartherfinance/frontend/api/Dashboard/hooks/useClientDashboard";
import useFundingAccountExtended from "@fartherfinance/frontend/api/ExternalAccount/hooks/useFundingAccountExtended";
import useAllAccountBalances from "@fartherfinance/frontend/api/PerformanceGroups/hooks/useAllAccountBalances";
import useMatchPerformanceGroup from "@fartherfinance/frontend/api/PerformanceGroups/hooks/useMatchPerformanceGroup";
import usePerformanceGroupHoldings from "@fartherfinance/frontend/api/PerformanceGroups/hooks/usePerformanceGroupHoldings";
import useTradingGroups from "@fartherfinance/frontend/api/TradingGroups/hooks/useTradingGroups";
import useCreateACHWithdrawal, {
  Withdrawal,
} from "@fartherfinance/frontend/api/Transfer/hooks/useCreateACHWithdrawal";
import {
  AccountType,
  FartherAccountId,
} from "@fartherfinance/frontend/api/Types";

import LogoLoadingStill from "../../../../../../../sharedComponents/LogoLoadingStill/LogoLoadingStill";
import { State } from "../../../../../../../store";
import CautionStatusDiv from "../../../../../../components/Caution_update_status_div/Caution";
import ButtonPrimary from "../../../../../../components/MUI/Button/Button";
import { SetScreenAndTransferType } from "../../../Types";
import { achAccountOptions } from "../utils/achAccountOptions";
import createAccLabel from "../utils/createAccLabel";
import { unknownBalanceValue } from "@src/constants/unknownBalanceValue";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import {
  isManualAccount,
  isPlaidAccount,
} from "@src/multiCustodian/pages/Dashboard/Funding/utils";
import { captureException } from "@src/multiCustodian/services/tracking";
import useThemeFragment from "@src/multiCustodian/theme/useThemeFragment";
import FormExplanatoryText from "@src/sharedComponents/Forms/FormExplanatoryText";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import Dropdown from "@src/sharedComponents/SAODropdown/Dropdown";
import TextInput from "@src/sharedComponents/TextInput/TextInput";

import "./Withdraw.css";

//LTE = Less Than or Equal to
//GT = Greater than
type AmountWithinCashBalance = "LTE" | "GT" | "Unknown";

const addToPortfolioWarning =
  "To initiate a withdrawal from this account, you must assign a model portfolio to it. Doing so will enable Farther to sell securities and generate the cash necessary for your withdrawal.";
const defaultHelperText =
  "If you have specific cash needs, consider requesting a slightly larger withdrawal than necessary to account for potential market fluctuations that may result in less cash raised than expected.";

function isAccountEnabled(
  custodian: ClientsCustodian | undefined,
  accountType: AccountType
): boolean {
  if (custodian === undefined) {
    return false;
  }

  if (custodian.type === "SingleFullCustodian") {
    return achAccountOptions[custodian.custodian][accountType];
  }

  return false; //ACH Only supported for Single Full Custodians for now
}

interface HelperTextArgs {
  amountWithinCashBalance: AmountWithinCashBalance;
  inTradingGroup: boolean;
  inPortfolio: boolean;
  accountSelected: boolean;
  isAdvisor: boolean;
}

const makeHelperText = ({
  amountWithinCashBalance,
  inTradingGroup,
  inPortfolio,
  accountSelected,
  isAdvisor,
}: HelperTextArgs): React.ReactNode => {
  if (accountSelected === false) {
    return defaultHelperText;
  }

  if (amountWithinCashBalance === "LTE") {
    return defaultHelperText;
  }

  if (amountWithinCashBalance === "Unknown") {
    if (inTradingGroup === false) {
      const text = isAdvisor
        ? "Liquidation may be required to withdraw funds from this account, please assign this account to a portfolio and trading group."
        : "Liquidation may be required to withdraw funds from this account, please contact your advisor to assign your account to a portfolio and trading group.";
      return <div className="Dashboard_Withdraw_page__warningText">{text}</div>;
    }

    if (inPortfolio === false) {
      const text = isAdvisor
        ? "Liquidation may be required to withdraw funds from this account, please assign this account to a portfolio."
        : "Liquidation may be required to withdraw funds from this account, please contact your advisor to assign your account to a portfolio.";
      return <div className="Dashboard_Withdraw_page__warningText">{text}</div>;
    }
  }

  if (inTradingGroup === false) {
    const text = isAdvisor
      ? "Due to the transfer amount being larger than cash holdings a liquidation is required. However this account is not part of a trading group. Please add this account to a trading group that is assigned to a model portfolio."
      : "Due to a required liquidation, please contact your advisor to add your account to a portfolio and trading group.";
    return <div className="Dashboard_Withdraw_page__warningText">{text}</div>;
  } else if (inPortfolio === false) {
    const text = isAdvisor
      ? "The transfer is larger than the cash holdings available. Therefore, a liquidation is required. This account is part of a trading group, but the trading group is not assigned a model portfolio. To initiate a withdrawal, which will trigger a liquidation, please add this account to a trading group that has been assigned a model portfolio."
      : addToPortfolioWarning;
    return <div className="Dashboard_Withdraw_page__warningText">{text}</div>;
  }

  return defaultHelperText;
};

type ErrorState =
  | "None"
  | "Invalid Amount"
  | "No Amount"
  | "OverBudget"
  | "OverLimit"
  | "UnderMinimum";

interface Props {
  setCurScreen: SetScreenAndTransferType;
}

const Withdraw = (props: Props): JSX.Element => {
  const t = useThemeFragment();

  const [transferAmount, setTransferAmount] = useState<string>("");

  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));

  const [withdrawFromAccountId, setWithdrawFromAccountId] =
    useState<FartherAccountId | null>(null);

  const [showIRAWarningModal, setShowIRAWarningModal] =
    useState<boolean>(false);

  const auth = useRequestAuth();

  const statusNotification = useStatusNotification();

  const callCreateWithdrawal = useCreateACHWithdrawal(auth);

  const managedAccounts = useGetFartherManagedAccounts(clientId, auth);

  const fartherAccounts = useMemo(
    () =>
      orderBy(
        managedAccounts.data ?? [],
        [(a) => a.accountDetails.displayName],
        ["asc"]
      ),
    [managedAccounts.data]
  );

  const [isMutating, setIsMutating] = useState<boolean>(false);

  const [liquidationError, setLiquidationError] = useState(false);

  const fundingAccount = useFundingAccountExtended(auth);

  const dashboard = useClientDashboard(clientId, auth);

  const tradingGroups = useTradingGroups(clientId, auth);

  const { data: performanceGroupId } = useMatchPerformanceGroup(
    clientId,
    withdrawFromAccountId,
    auth,
    "SingleAccount"
  );
  const holdings = usePerformanceGroupHoldings(
    performanceGroupId ?? null,
    auth
  );

  const custodian = useClientCustodian(clientId, auth);

  const cashSecurity = useMemo(() => {
    if (performanceGroupId === null) {
      return {
        data: null,
        isLoading: false,
        hasError: false,
      };
    }

    if (holdings.isLoading || holdings.hasError) {
      return holdings;
    }

    const allSecurities = holdings.data.categories.flatMap((cat) =>
      cat.classes.flatMap((cls) => cls.securities)
    );

    const cash = allSecurities.find((s) => s.cusip === "CASH") ?? null;

    return {
      ...holdings,
      data: cash,
    };
  }, [holdings, performanceGroupId]);

  const cashInAccount: number | null = cashSecurity?.data?.marketValue ?? null;

  const curAccountTradingGroup = useMemo(() => {
    if (tradingGroups.isLoading || tradingGroups.hasError) {
      return tradingGroups;
    }

    return {
      ...tradingGroups,
      data:
        tradingGroups.data.find((tg) =>
          tg.accounts.some(
            (tgAcc) => tgAcc.virtualAccountId === withdrawFromAccountId
          )
        ) ?? null,
    };
  }, [tradingGroups, withdrawFromAccountId]);

  const validACHAccountIds = useMemo(
    () => fundingAccount.data?.fundingDetails?.validAchAccountIds ?? [],
    [fundingAccount.data?.fundingDetails?.validAchAccountIds]
  );

  const validACHAccounts = useMemo(
    () =>
      fartherAccounts.filter((account) =>
        validACHAccountIds.includes(account.virtualAccountId)
      ),
    [fartherAccounts, validACHAccountIds]
  );

  useEffect(() => {
    const validAccountsToChooseFrom = validACHAccounts.filter((a) =>
      isAccountEnabled(custodian.data, a.accountDetails.accountType)
    );

    // on first load set the selected account to the first one
    if (withdrawFromAccountId === null) {
      if (validAccountsToChooseFrom.length > 0) {
        setWithdrawFromAccountId(validAccountsToChooseFrom[0].virtualAccountId);
        return;
      }

      if (validACHAccounts.length > 0) {
        setWithdrawFromAccountId(validACHAccounts[0].virtualAccountId);
        return;
      }
    }
  }, [custodian.data, validACHAccounts, withdrawFromAccountId]);

  if (
    managedAccounts.isLoading ||
    dashboard.isLoading ||
    fundingAccount.isLoading ||
    curAccountTradingGroup.isLoading ||
    cashSecurity.isLoading
  ) {
    return (
      <div className="Dashboard_Withdraw_page__container">
        <div className="Dashboard_Withdraw_page__loading_container">
          <LogoLoadingStill />
        </div>
      </div>
    );
  }

  // When we don't have an account id to look for, we won't find a performance group
  const performanceGroupHasError =
    withdrawFromAccountId === null ? false : cashSecurity.hasError;

  if (
    managedAccounts.hasError ||
    dashboard.hasError ||
    curAccountTradingGroup.hasError ||
    performanceGroupHasError
  ) {
    const hasError = [
      managedAccounts.hasError ? "accounts" : "",
      dashboard.hasError ? "dashboard" : "",
      fundingAccount.hasError ? "funding account" : "",
      cashSecurity.hasError ? "Getting Cash Securities" : "",
    ]
      .filter((x) => x !== "")
      .join(", ");

    return (
      <div className="Dashboard_Withdraw_page__container">
        <div className="Dashboard_Withdraw_page__loading_container">
          <div>Error with {hasError}</div>
        </div>
      </div>
    );
  }

  const fundingAccId = fundingAccount.data?.accountId ?? null;

  // https://www.notion.so/fartherfinance/RFC-Account-Id-Display-9fefdde3348b4a888cf8c4dcfa4a5eb1?pvs=4#42361a3aa39942cfa9582f1ad4c1369f
  const partialAccNumber = `(...${
    fundingAccount.data && fundingAccount.data !== null
      ? getPartialAccountNumber(fundingAccount.data)
      : MASK_NUMBER
  })`;

  const fundingAccName: string | undefined =
    fundingAccount.data && fundingAccount.data !== null
      ? isManualAccount(fundingAccount.data)
        ? fundingAccount.data.details.account.accountTitle
        : fundingAccount.data.details.account.name
      : undefined;

  const fundingAccBalance =
    !isNil(fundingAccount.data) && isPlaidAccount(fundingAccount.data)
      ? getExternalAccountBalance(fundingAccount.data)
      : null;

  const fundingAccLabel = fundingAccount.data
    ? `${fundingAccName ?? "Unknown"} ${partialAccNumber} ${
        fundingAccBalance !== null
          ? `- ${fundingAccBalance.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}`
          : unknownBalanceValue
      }`
    : ` ${unknownBalanceValue}`;

  const noFundingAccount = fundingAccount.data === null;

  const noFundingToDo =
    dashboard.data.apexClientId !== null
      ? "Please go to funding settings to select your funding account"
      : "Please contact your wealth advisor to set up ACH Authorization.";

  const selectedFartherAccount =
    fartherAccounts.find((a) => a.virtualAccountId === withdrawFromAccountId) ??
    null;

  const fartherAccBalance =
    selectedFartherAccount?.accountDetails.lastReportedBalance?.balance ?? null;

  const amount = Number(transferAmount);

  let amountErrorState: ErrorState = "None";
  if (transferAmount === "") {
    amountErrorState = "No Amount";
  } else if (isNaN(Number(transferAmount))) {
    amountErrorState = "Invalid Amount";
  } else if (amount < 1) {
    amountErrorState = "UnderMinimum";
  } else if (fartherAccBalance !== null && amount > fartherAccBalance) {
    amountErrorState = "OverBudget";
  } else if (amount > 100000) {
    amountErrorState = "OverLimit";
  }

  const overBudgetErrorDescription =
    fartherAccBalance !== null
      ? `Amount needs to be less than or equal to your account's balance: ${fartherAccBalance.toLocaleString(
          "en-US",
          {
            style: "currency",
            currency: "USD",
          }
        )}`
      : "";

  const inTradingGroup = curAccountTradingGroup.data !== null;

  const inPortfolio =
    curAccountTradingGroup.data !== null &&
    curAccountTradingGroup.data.portfolioId !== null;

  const amountValid = isNaN(amount) === false;

  const amountWithinCashBalance: AmountWithinCashBalance =
    amountValid === false // If the amount is not valid then that error handler will block actions
      ? "LTE"
      : cashInAccount === null // explicitly make it known that we do not know the cash balance
      ? "Unknown"
      : amount > (fartherAccBalance ?? Number(Infinity)) // If the amount is above the balance let that error handler show an error
      ? "LTE"
      : amount <= cashInAccount
      ? "LTE"
      : "GT";

  const withdrawalDisabled =
    amountWithinCashBalance === "GT" && (!inTradingGroup || !inPortfolio); //We allow withdrawal even if the cash balance is unknown

  const liquidationDisabled = !inTradingGroup || !inPortfolio;

  const callMakeWithdrawal = async (withdrawAll: boolean) => {
    if (isNil(withdrawFromAccountId) || isNil(fundingAccId)) {
      statusNotification(
        "Don't know which account to withdraw from or into",
        "Error"
      );
      return;
    }

    if (withdrawAll) {
      if (selectedFartherAccount === null) {
        const msg = "Withdraw all failed because selected account is not known";
        const e = new Error(msg);
        captureException(e);

        statusNotification(msg, "Error");

        return;
      }
    } else {
      if (isNaN(amount)) {
        const msg = "Withdraw failed because amount is NaN";
        const e = new Error(msg);
        captureException(e);

        statusNotification(msg, "Error");

        return;
      }
    }

    const withdrawalBody: Withdrawal = {
      fartherAccountId: withdrawFromAccountId,
      amount: withdrawAll ? null : amount,
    };

    try {
      if (liquidationError === true) {
        setLiquidationError(false);
      }

      setIsMutating(true);

      const { transferId } = await callCreateWithdrawal(withdrawalBody);

      statusNotification("Withdrawal initiated", "Success");

      setIsMutating(false);

      props.setCurScreen({
        screen: "withdrawalSuccess",
        withdrawFromAccountId: withdrawFromAccountId,
        depositToExternalId: fundingAccId,
        transferAmount: amount,
        transferId: transferId,
      });
    } catch (e) {
      setIsMutating(false);

      if (isAxiosError(e) && e.status === 400) {
        setLiquidationError(true);
      } else {
        if (liquidationError === true) {
          setLiquidationError(false);
        }
      }

      statusNotification("Failed to initiate a withdrawal", "Error");
    }
  };
  const fundingStatus =
    fundingAccount.data?.fundingDetails?.fundingStatus ?? null;

  return (
    <div className="Dashboard_Withdraw_page__container">
      {isMutating && (
        <div className={"Dashboard_Withdraw_page__container_center_loader"}>
          <LogoLoadingStill />
        </div>
      )}

      {showIRAWarningModal && (
        <IRAWarningModal
          onNoClick={() => setShowIRAWarningModal(false)}
          onYesClick={() => callMakeWithdrawal(false)}
          withdrawalDisabled={amountErrorState !== "None" || withdrawalDisabled}
        />
      )}

      <div
        className="Dashboard_Withdraw_page__back_button_container"
        onClick={() => props.setCurScreen({ screen: "bankAccount" })}
      >
        <img src="/src/assets/svg/left_arrow.svg" />
        <div className="Dashboard_Withdraw_page__back_button">Back</div>
      </div>

      {fundingStatus === "pending" && (
        <div className="Dashboard_Withdraw_page__error_div_2">
          <CautionStatusDiv
            icon={
              <WarningAmberRoundedIcon className="Dashboard_Withdraw_page__error_div_2_icon" />
            }
            msg={
              "Your bank account authorization is pending. Please check again later."
            }
          />
        </div>
      )}

      <p className="Dashboard_Withdraw_page__title">Withdraw funds</p>

      <p className="Dashboard_Withdraw_page__summary">
        Looking to make a withdrawal out of your {t("companyName")} account?
        Simply follow the steps below to initiate the transaction.
      </p>

      <WithdrawFrom
        account={selectedFartherAccount}
        selectWithdrawFrom={setWithdrawFromAccountId}
        validACHAccounts={validACHAccounts}
        inTradingGroup={inTradingGroup}
        inPortfolio={inPortfolio}
        amountWithinCashBalance={amountWithinCashBalance}
        liquidationError={liquidationError}
      />

      <Spacer />

      {noFundingAccount ? (
        <div>{noFundingToDo}</div>
      ) : (
        <TextInput label="Deposit to" disabled={true} value={fundingAccLabel} />
      )}

      <Spacer verticalSpacing="10px" />

      <FormExplanatoryText>
        If you need to change your bank account please contact your wealth
        advisor.
      </FormExplanatoryText>

      <Spacer />

      <TextInput
        label="Amount"
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        inputProps={{
          inputMode: "numeric",
          pattern: "[0-9]*",
        }}
        value={transferAmount}
        onChange={(e) => setTransferAmount(e.target.value)}
        error={amountErrorState !== "None"}
        helperText={
          amountErrorState === "Invalid Amount"
            ? "Amount needs to be a valid number"
            : amountErrorState === "OverLimit"
            ? "Amount needs to be less than or equal to $100,000"
            : amountErrorState === "OverBudget"
            ? overBudgetErrorDescription
            : amountErrorState === "UnderMinimum"
            ? "Amount needs to be greater than or equal to $1"
            : undefined
        }
      />

      <div className="Dashboard_Withdraw_page__button_container">
        {fartherAccBalance !== null && (
          <ButtonPrimary
            disabled={
              selectedFartherAccount === null ||
              fundingStatus === "pending" ||
              liquidationDisabled
            }
            variant={"outlined"}
            buttonType={"secondary"}
            text={"Withdraw All"}
            onClick={() => callMakeWithdrawal(true)}
          />
        )}

        <ButtonPrimary
          disabled={
            amountErrorState === "OverLimit" ||
            amountErrorState === "UnderMinimum" ||
            amountErrorState === "OverBudget" ||
            amountErrorState === "No Amount" ||
            fundingStatus !== "active" ||
            withdrawalDisabled
          }
          variant={"contained"}
          buttonType={"primary"}
          text={"Withdraw"}
          onClick={() => callMakeWithdrawal(false)}
          style={{ marginLeft: "25px" }}
        />
      </div>
    </div>
  );
};

export default Withdraw;

interface IRAWarningModalProps {
  withdrawalDisabled: boolean;
  onYesClick: () => void;
  onNoClick: () => void;
}

const IRAWarningModal = (props: IRAWarningModalProps): JSX.Element => {
  const { tentOutline } = useSelector((state: State) => ({
    tentOutline: state.main_Reducer.brand.farther_dark.images.tentOutline,
  }));

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="Dashboard_Withdraw_page__modal_container">
          <img
            src={tentOutline}
            className="Dashboard_Withdraw_page__modal_img"
          />

          <p className="Dashboard_Withdraw_page__modal_title">
            Withdrawal Warning
          </p>

          <p className="Dashboard_Withdraw_page__modal_summary">
            It looks like you're trying to withdraw from your IRA.
          </p>

          <p className="Dashboard_Withdraw_page__modal_summary">
            Are you sure you want to do this?
          </p>

          <p className="Dashboard_Withdraw_page__modal_gray_text">
            Withdrawing from an IRA before 59.5 years of age results in a charge
            of a 10% early distribution penalty along with income taxes on the
            amount you withdraw at your marginal income tax rate for the year.
          </p>

          <div className="Dashboard_Withdraw_page__yes_no_div">
            <ButtonPrimary
              disabled={props.withdrawalDisabled}
              variant={"outlined"}
              buttonType={"secondary"}
              text={"Yes"}
              onClick={props.onYesClick}
            />

            <ButtonPrimary
              variant={"contained"}
              buttonType={"primary"}
              text={"No"}
              onClick={props.onNoClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface WithdrawFromProps {
  account: FartherManagedAccount | null;
  selectWithdrawFrom: (accountId: FartherAccountId) => void;
  validACHAccounts: FartherManagedAccount[];
  inTradingGroup: boolean;
  inPortfolio: boolean;
  amountWithinCashBalance: AmountWithinCashBalance;
  liquidationError: boolean;
}

function WithdrawFrom({
  inTradingGroup,
  inPortfolio,
  amountWithinCashBalance,
  ...props
}: WithdrawFromProps): JSX.Element {
  const { isAdvisor, clientId } = useSelector((state: State) => ({
    isAdvisor: state.main_Reducer.user.isAdvisor ?? false,
    clientId: state.main_Reducer.user.id_user,
  }));

  const auth = useRequestAuth();

  const custodian = useClientCustodian(clientId, auth);

  const t = useThemeFragment("Farther");

  const balances = useAllAccountBalances(clientId, auth);

  const balance: number | null = useMemo(() => {
    if (balances.isLoading || balances.hasError) {
      return null;
    }

    return (
      balances.data.find((b) => b.accountId === props.account?.virtualAccountId)
        ?.balance ?? null
    );
  }, [balances, props.account?.virtualAccountId]);

  const selectedAccLabel =
    props.account !== null
      ? createAccLabel(
          props.account,
          balances.isLoading ? undefined : balance,
          isAdvisor,
          t("companyName")
        )
      : "";

  const validACHAccountsList = useMemo(() => {
    const labels = props.validACHAccounts.map((account) => {
      const enabled = isAccountEnabled(
        custodian.data,
        account.accountDetails.accountType
      );

      const accountBalance =
        (balances.data ?? []).find(
          (b) => b.accountId === account.virtualAccountId
        )?.balance ?? null;

      return {
        label: createAccLabel(
          account,
          balances.isLoading ? undefined : accountBalance,
          isAdvisor,
          t("companyName")
        ),
        accountId: account.virtualAccountId,
        enabled,
      };
    });

    const sortedLabels = orderBy(labels, [(l) => l.label], ["asc"]);

    return sortedLabels;
  }, [props.validACHAccounts, custodian.data, balances, isAdvisor, t]);

  const selectedEnabled =
    custodian.data?.type === "SingleFullCustodian" && props.account !== null //ACH Only supported for Single Full Custodians for now
      ? achAccountOptions[custodian.data.custodian][
          props.account.accountDetails.accountType
        ]
      : false;

  const withdrawFrom = useMemo(
    () =>
      props.account !== null
        ? {
            label: selectedAccLabel,
            accountId: props.account.virtualAccountId,
            enabled: selectedEnabled,
          }
        : null,
    [props.account, selectedAccLabel, selectedEnabled]
  );

  const helperText = useMemo(
    () =>
      makeHelperText({
        inPortfolio,
        inTradingGroup,
        amountWithinCashBalance,
        accountSelected: props.account !== null,
        isAdvisor,
      }),
    [
      inPortfolio,
      inTradingGroup,
      amountWithinCashBalance,
      props.account,
      isAdvisor,
    ]
  );

  return (
    <Dropdown
      formLabel="Withdraw from"
      value={withdrawFrom}
      onChange={(e) => props.selectWithdrawFrom(e.accountId)}
      getOptionDisabled={(o) => o.enabled === false}
      values={validACHAccountsList}
      helperText={
        props.liquidationError ? (
          <div className="Dashboard_Withdraw_page__warningText">
            We aren't able to process your transfer, please contact your advisor
            for assistance in completing this transfer
          </div>
        ) : validACHAccountsList.length > 0 ? (
          helperText
        ) : (
          <div className="Dashboard_Withdraw_page__warningText">
            {isAdvisor
              ? "No accounts are valid for ACH transfers. Please contact CX to investigate the client accounts' states."
              : "None of your accounts are eligible for ACH transfers"}
          </div>
        )
      }
    />
  );
}
