import React from "react";

import { format } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";

import TimeRangeSelector from "@src/multiCustodian/components/PerformanceGroups/Shared/TimeRangeSelector";
import Legend from "@src/multiCustodian/components/PerformanceGroups/Summary/Legend";
import { timeFrameOptions } from "@src/multiCustodian/components/PerformanceGroups/Summary/SummaryGraphContainer";

import styles from "./EmptyGraphPlaceholder.module.css";

interface Props {
  headerText: string;
  subText: string;
}

const EmptyGraphPlaceholder = (props: Props) => {
  const { enableLowDataPerformanceGroups } = useFlags();

  return (
    <div className={styles.overlapWrapper}>
      <div className={styles.textContainerOverlap}>
        <div className={styles.textCenteringDiv}>
          <div className={styles.headerText}>{props.headerText}</div>

          <div className={styles.subText}>{props.subText}</div>
        </div>
      </div>

      <div className={styles.graphContainer}>
        <div className={styles.graphHeader}>
          <div className={styles.noBalanceDiv}>
            <div className={styles.noBalance}>$--</div>
            <div className={styles.noPerformanceDiv}>
              <img
                className={styles.noPerformanceTriangle}
                src={"/src/assets/svg/arrow-up-green.svg"}
              />
              <div className={styles.noPerformance}>{"$-- (--%)"}</div>
              <div className={styles.noTimeRange}>All Time</div>
            </div>
          </div>

          <Legend
            displayVertical={true}
            contributionsText={"Deposits & Withdrawals:"}
          />
        </div>

        <div className={styles.noGraphDiv}>
          <div className={styles.noGraphLineDiv1}>
            <div className={styles.noGraphLineLabel}>$--</div>
            <div className={styles.noGraphLine} />
          </div>

          <div className={styles.noGraphLineDiv}>
            <div className={styles.noGraphLineLabel}>$--</div>
            <div className={styles.noGraphLine} />
          </div>

          <div className={styles.noGraphLineDiv}>
            <div className={styles.noGraphLineLabel}>$--</div>
            <div className={styles.noGraphLine} />
          </div>

          <div className={styles.noGraphLineDiv}>
            <div className={styles.noGraphLineLabel}>$--</div>
            <div className={styles.noGraphLine} />
          </div>

          <div className={styles.noGraphLineDiv}>
            <div className={styles.noGraphLineLabel}>$--</div>
            <div className={styles.noGraphLine} />
          </div>

          <div className={styles.noGraphTimeAxisDiv}>
            <div className={styles.noGraphTimeLeft}>
              {format(new Date(), "PP")}
            </div>

            <TimeRangeSelector
              timeRange={"1Y"}
              timeFrameOptions={timeFrameOptions(
                enableLowDataPerformanceGroups
              )}
              setTimeRange={() => undefined}
              isDisabled={true}
            />

            <div className={styles.noGraphTimeRight}>Today</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyGraphPlaceholder;
