import React from "react";

import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import { AccountsPath } from "@src/config/routing/RouterPaths";
import BackButton from "@src/multiCustodian/components/Client/BackButton";
import ExitButton from "@src/multiCustodian/components/Client/ExitButton";
import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import { State as ReduxState } from "@src/store";

import { ManualAssetsAccountTypes } from "./Types";

import styles from "./AddManualAccount.module.css";

const AddManualAccount = (): JSX.Element => {
  const { chevronRightImg } = useSelector((state: ReduxState) => ({
    chevronRightImg: state.main_Reducer.brand.current.images.chevron_right,
  }));

  const history = useHistory();

  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  return (
    <BaseLayout showSideNav={false}>
      <ExitButton />

      <div className={styles.container}>
        <div className={styles.centeringContainer1}>
          <div className={styles.backButtonContainer}>
            <BackButton
              onClick={() =>
                history.push(`/Client/${clientId}/${AccountsPath}/Add`)
              }
            />
          </div>

          <div className={styles.centeringContainer2}>
            <p className={styles.title}>Manually-Tracked Account</p>

            <p className={styles.summary}>
              Please select the type of Manually-Tracked account you would like
              to add
            </p>

            {ManualAssetsAccountTypes.map((assetType, i) => {
              return (
                <div
                  key={`${i}`}
                  className={styles.option_div}
                  onClick={() =>
                    history.push(
                      `/Client/${clientId}/${AccountsPath}/Add/Manual/${assetType}/Details`
                    )
                  }
                >
                  <p className={styles.option_text}>
                    {assetType === "Collectable" ? "Collectible" : assetType}
                  </p>

                  <img className={styles.option_img} src={chevronRightImg} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default AddManualAccount;
