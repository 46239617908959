import React from "react";

import { useCreateEditTaxBudgetContext } from "../../../TaxBudgets/reducer/Context";
import ReviewDisclosures from "../../../TaxBudgets/ReviewDisclosures/ReviewDisclosures";
import SelectBudget from "../../../TaxBudgets/SelectBudget/SelectBudget";
import ClientSelectAccounts from "../SelectAccounts/ClientSelectAccounts";
import HypotheticalResultsAnalysis from "@src/multiCustodian/components/TaxBudgets/HypotheticalResultsAnalysis/HypotheticalResultsAnalysis";
import SelectHypotheticalModels from "@src/multiCustodian/components/TaxBudgets/SelectHypotheticalModels/SelectHypotheticalModels";

const HypotheticalFlowBody: React.FC = () => {
  const { state } = useCreateEditTaxBudgetContext();

  const { pageIndex } = state;

  switch (pageIndex) {
    case 0:
      return <ClientSelectAccounts />;
    case 1:
      return <SelectBudget />;
    case 2:
      return <ReviewDisclosures />;
    case 3:
      return <SelectHypotheticalModels />;
    case 4:
      return <HypotheticalResultsAnalysis />;
    default:
      return null;
  }
};

export default HypotheticalFlowBody;
