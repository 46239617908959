export const TRADING_TOGGLE_DISABLED_MSG =
  "Rebalancing is frozen due to an ongoing liquidation request.";

export const DIRECT_INDEXING_TOOLTIP_TEXT =
  "Seeks to replicate a chosen benchmark using a smaller subset of stocks.";

export const TAX_LOSS_HARVESTING_TOOLTIP_TEXT =
  "Tax-loss harvesting only impacts taxable accounts.";

export const DI_MIN_BALANCE_TOOLTIP_TEXT =
  "A taxable account balance above $250k is required for direct indexing.";

export const ADVISOR_NOTE_FOR_REBALANCING =
  "Please note that holdings without a CUSIP are ignored for rebalancing purposes. If this situation applies, please reach out to the Farther Service Team.";

export const EQUIVALENT_SECURITIES_TOOLTIP_TEXT =
  "Farther will attempt to prevent trades that involve selling one security to buy another with substantially similar investment exposure. Your model portfolio will still reflect the security initially appearing in the model, but Farther will attempt to retain the legacy position in a given account.";

export const TAX_CHARACTERISTICS_ACROSS_ACCOUNTS_TOOLTIP_TEXT =
  "The tax characteristics of each account within the trading group will be considered when allocating investments across accounts.";

export const SHARED_ACCOUNT_TOOLTIP_TEXT =
  "This account is shared with you. Only the owner can make changes to the model portfolio settings.";
