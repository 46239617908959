import React from "react";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import Modal from "@src/sharedComponents/Modal/Modal";

import styles from "./InfoModal.module.css";

interface Props {
  hideModal: () => void;
}

const InfoModal: React.FC<Props> = ({ hideModal }) => {
  return (
    <Modal closeModal={hideModal} modalStyle={{ width: "800px" }}>
      <div className={styles.title}>Limited vs. All-Access</div>

      <div className={styles.heading}>Limited Access Collaborators</div>

      <div className={styles.text}>
        Limited Access collaborators can only view the specific items you've
        granted them access to, such as specific accounts and folders in the
        document center.
      </div>

      <div className={styles.heading}>All-Access Collaborators</div>

      <div className={styles.text}>
        All-access collaborators can view almost all of the information from
        your Farther portal, including account information (e.g., balances,
        account numbers) and documents.
      </div>

      <div className={styles.footer}>
        <Button
          variant={"contained"}
          buttonType={"primary"}
          text={"Done"}
          onClick={hideModal}
        />
      </div>
    </Modal>
  );
};

export default InfoModal;
