import { AxiosError } from "axios";

const errorHandler = (err: unknown): string => {
  if (err instanceof AxiosError) {
    const message = err?.response?.data?.message ?? err?.message;
    const details = err?.response?.data?.details ?? "";

    return `${message}: ${details}`;
  }

  return "An unknown authentication error occurred";
};

export default errorHandler;
