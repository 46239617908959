import React, { useMemo } from "react";

import Box from "@mui/material/Box";
import { useFlags } from "launchdarkly-react-client-sdk";
import { matchPath, useHistory, useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import {
  AltAssetsMarketplacePath,
  InvestmentsPath,
} from "@src/config/routing/RouterPaths";
import HorizontalNavigation, {
  HorizontalNavigationTab,
} from "@src/multiCustodian/components/HorizontalNavigation/HorizontalNavigation";
import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import PageHero from "@src/sharedComponents/PageHero/PageHero";
import TitleBlock from "@src/sharedComponents/PageHero/TitleBlock";

const Investments: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { clientId } = useParams<Record<"clientId", ClientId>>();
  const history = useHistory();
  const { enablePortfoliosV2, enableClientAltAssets } = useFlags();

  const tabs = useMemo<HorizontalNavigationTab[]>(() => {
    return [
      ...(enablePortfoliosV2
        ? [
            {
              label: "Models",
              path: `/Client/${clientId}/${InvestmentsPath}/Portfolios`,
              mixpanelEvent: { name: "Client Click Model-Portfolios" },
            } satisfies HorizontalNavigationTab,
          ]
        : []),
      ...(enableClientAltAssets
        ? [
            {
              label: "Alternative Assets",
              path: `/Client/${clientId}/${AltAssetsMarketplacePath}`,
              mixpanelEvent: { name: "Client Click Alternative-Assets" },
            } satisfies HorizontalNavigationTab,
          ]
        : []),
    ];
  }, [clientId, enablePortfoliosV2, enableClientAltAssets]);

  const isPathWithOwnContainer = [
    `/Client/:clientId/${InvestmentsPath}/Portfolios/Create`,
    `/Client/:clientId/${AltAssetsMarketplacePath}/*`,
  ].some((path) => matchPath(history.location.pathname, { path }));

  if (isPathWithOwnContainer) {
    return <>{children}</>;
  }

  return (
    <BaseLayout>
      <PageHero start={<TitleBlock title="Investments" />} />
      <Box sx={{ padding: "0px var(--space-3)" }}>
        <HorizontalNavigation tabs={tabs}>{children}</HorizontalNavigation>
      </Box>
    </BaseLayout>
  );
};

export default Investments;
