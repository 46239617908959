import React, { useMemo } from "react";

import {
  endOfToday,
  endOfYesterday,
  startOfToday,
  startOfYesterday,
  subDays,
} from "date-fns";

import { GetProposalsQueryParams } from "@fartherfinance/frontend/api/Rebalance/requests/getProposals";
import { ProposalInternalStatus } from "@fartherfinance/frontend/api/Rebalance/Types";

import { DailyProposals } from "../DailyProposals/DailyProposals.component";
import {
  PAGINATION_DEFAULT_PAGE_NUMBER,
  PAGINATION_DEFAULT_PAGE_SIZE,
} from "@src/yellowstone/modules/shared";

interface ProposalsControllerViewMode {
  isDailyProposalsView?: boolean;
  isSubmittedProposalsView?: boolean;
  isSkippedProposalsView?: boolean;
}

type ProposalsControllerProps = RequireOnlyOne<ProposalsControllerViewMode>;

export const ProposalsController: React.FC<ProposalsControllerProps> = ({
  isDailyProposalsView = false,
  isSubmittedProposalsView = false,
  isSkippedProposalsView = false,
}) => {
  const defaultQueryParams = useMemo(() => {
    const sharedParams: GetProposalsQueryParams = {
      page: PAGINATION_DEFAULT_PAGE_NUMBER,
      pageSize: PAGINATION_DEFAULT_PAGE_SIZE,
      filterByTimestampTo: endOfToday().toISOString(),
      filterByIsMaterialOrMultiDayExecution: true,
    };

    const dailyProposalsParams: GetProposalsQueryParams = {
      ...sharedParams,
      filterByStatus: [
        ProposalInternalStatus.enum.PROPOSED,
        ProposalInternalStatus.enum.SKIP_PROPOSAL,
        ProposalInternalStatus.enum.NEEDS_REVIEW,
        ProposalInternalStatus.enum.CONFIRM,
      ],
      filterByTimestampFrom: startOfToday().toISOString(),
      filterByTimestampTo: endOfToday().toISOString(),
    };

    const submittedProposalsParams: GetProposalsQueryParams = {
      ...sharedParams,
      filterByStatus: [
        ProposalInternalStatus.enum.SUBMITTED,
        ProposalInternalStatus.enum.FAILED,
      ],
      filterByTimestampFrom: subDays(startOfToday(), 7).toISOString(),
      filterByTimestampTo: endOfToday().toISOString(),
    };

    const skippedProposalsParams: GetProposalsQueryParams = {
      ...sharedParams,
      filterByStatus: [
        ProposalInternalStatus.enum.PROPOSED,
        ProposalInternalStatus.enum.SKIP_PROPOSAL,
        ProposalInternalStatus.enum.NEEDS_REVIEW,
        ProposalInternalStatus.enum.CONFIRM,
      ],
      filterByTimestampFrom: subDays(startOfYesterday(), 7).toISOString(),
      filterByTimestampTo: endOfYesterday().toISOString(),
    };

    const defaultParams: NonEmptyArray<[boolean, GetProposalsQueryParams]> = [
      [isDailyProposalsView, dailyProposalsParams],
      [isSubmittedProposalsView, submittedProposalsParams],
      [isSkippedProposalsView, skippedProposalsParams],
    ];

    return defaultParams
      .filter(([isViewActivated]) => isViewActivated)
      .flatMap(([, queryParams]) => queryParams)
      .at(0);
  }, [isDailyProposalsView, isSubmittedProposalsView, isSkippedProposalsView]);

  return (
    <DailyProposals
      isDailyProposalsView={isDailyProposalsView}
      isSubmittedProposalsView={isSubmittedProposalsView}
      isSkippedProposalsView={isSkippedProposalsView}
      defaultQueryParams={defaultQueryParams ?? {}}
    />
  );
};
