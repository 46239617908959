import React, { useMemo } from "react";

import { orderBy } from "lodash";
import { useHistory, useParams } from "react-router-dom";

import {
  UNKNOWN_ACCOUNT,
  UNKNOWN_BALANCE,
} from "@fartherfinance/frontend/api/Accounts/utilities/accountUtil";
import {
  FirstMonth,
  FixedAmountAccount,
  LastMonth,
  SplitMonth,
  TargetGoalWaterfallAccount,
  Trigger,
} from "@fartherfinance/frontend/api/CashCycle/requests/getCurrentTrigger";
import { AccountDetails } from "@fartherfinance/frontend/api/Dashboard/requests/getClientDashboard";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import { scheduleTypeToOptionString } from "../shared";
import { CashManagementPath } from "@src/config/routing/RouterPaths";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import { captureException } from "@src/multiCustodian/services/tracking";
import formatOrdinal from "@src/multiCustodian/utils/formatOrdinal";

import styles from "./RecurringTransfers.module.css";

const UNKNOWN_TYPE = "Unknown type";

type fundingScheduleType =
  | FirstMonth["value"]
  | LastMonth["value"]
  | SplitMonth["value"]
  | "custom";

const scheduleTypeToString = (
  type: fundingScheduleType,
  customCronScheduleValue: string
): string => {
  switch (type) {
    case "month_first":
      return scheduleTypeToOptionString("month_first");
    case "month_last":
      return scheduleTypeToOptionString("month_last");
    case "month_split":
      return scheduleTypeToOptionString("month_split");
    case "custom":
      const cronStringParts = customCronScheduleValue.split(" ");
      const cronDay = cronStringParts[3];
      const cronDayString = formatOrdinal(parseInt(cronDay));
      return `Monthly on the ${cronDayString}`;
    default:
      const emsg = new Error("Not a valid fundingScheduleType");
      captureException(emsg, {
        extra: {
          call: "scheduleTypeToString",
          file: "RecurringTransfers.tsx",
          type: type,
          customCronScheduleValue: customCronScheduleValue,
        },
      });
      throw emsg;
  }
};

const isFixedAmountAccount = (
  account: FixedAmountAccount | TargetGoalWaterfallAccount
): account is FixedAmountAccount => {
  return "amount" in account;
};

const isWaterfallAccount = (
  account: FixedAmountAccount | TargetGoalWaterfallAccount
): account is TargetGoalWaterfallAccount => {
  return "waterfallOrder" in account;
};

interface Props {
  trigger: Trigger;
  clientAccounts: AccountDetails[];
}

const RecurringTransfers = (props: Props): JSX.Element => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const history = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerInnerDiv}>
          <p className={styles.heading}>Recurring Transfers</p>

          <div className={styles.headerRight}>
            <ButtonPrimary
              text={"Cancel"}
              variant="outlined"
              buttonType="secondary"
              onClick={() =>
                history.push(
                  `/Client/${clientId}/${CashManagementPath}/RemoveRecurring`
                )
              }
              sx={{ height: "30px", marginRight: "15px" }}
            />

            <ButtonPrimary
              text={"Edit"}
              variant="contained"
              buttonType="primary"
              onClick={() =>
                history.push(
                  `/Client/${clientId}/${CashManagementPath}/SetupRecurring`
                )
              }
              sx={{ height: "30px" }}
            />
          </div>
        </div>
      </div>

      <div className={styles.body}>
        <div className={styles.bodyInnerDiv}>
          <div className={styles.row}>
            <p className={styles.heading}>Recurring</p>

            <p className={styles.text}>
              {props.trigger.amount.amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) ?? UNKNOWN_BALANCE}
            </p>
          </div>

          <div className={styles.row}>
            <p className={styles.heading}>Funding Schedule</p>

            <p className={styles.text}>
              {scheduleTypeToString(
                props.trigger.schedule.type === "system"
                  ? props.trigger.schedule.value
                  : props.trigger.schedule.type,
                props.trigger.schedule.value
              )}
            </p>
          </div>

          <div className={styles.row}>
            <p className={styles.heading}>Method</p>

            <p className={styles.text}>
              {props.trigger.method.type === "waterfall"
                ? "Waterfall"
                : "Fixed Amount"}
            </p>
          </div>

          <div className={styles.footerRow}>
            <div className={styles.footerRowLeft}>
              <p className={styles.heading}>Accounts</p>
            </div>

            <div className={styles.footerRowRight}>
              <AccountsList
                trigger={props.trigger}
                clientAccounts={props.clientAccounts}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecurringTransfers;

interface AccountsListProps {
  trigger: Trigger;
  clientAccounts: AccountDetails[];
}

const AccountsList = (props: AccountsListProps) => {
  // need to match up the accounts to add displayName for sorting by displayName in accountsListJSX
  const accountsExtended = useMemo(() => {
    return (props.trigger?.method.accounts ?? []).map((acct) => {
      const account = props.clientAccounts.find(
        (clAcct) => clAcct.accountId === acct.accountId
      );

      if (!account) {
        const emsg = new Error(
          `Account ${acct.accountId} from trigger should exist in client's accounts list`
        );
        captureException(emsg, {
          extra: {
            call: "useMemo - accountsExtended",
            file: "RecurringTransfers.tsx",
            trigger: props.trigger,
            accountFromTrigger: acct,
            clientAccounts: props.clientAccounts,
          },
        });
        throw emsg;
      }

      return {
        ...acct,
        displayName: account.accountDetails.displayName,
        accountType: account.accountDetails.accountType,
      };
    });
  }, [props.trigger, props.clientAccounts]);

  const orderedAccounts = useMemo(
    () =>
      orderBy(
        accountsExtended,
        (a) => ("waterfallOrder" in a ? a.waterfallOrder : a.displayName),
        "asc"
      ),
    [accountsExtended]
  );

  return (
    <>
      {orderedAccounts.map((acct) => {
        const account = props.clientAccounts.find(
          (clAcct) => clAcct.accountId === acct.accountId
        );

        if (props.trigger && isWaterfallAccount(acct)) {
          return (
            <p key={acct.accountId} className={styles.accountsText}>{`${
              account?.accountDetails.displayName ?? UNKNOWN_ACCOUNT
            } (${
              account?.accountDetails.accountType ?? UNKNOWN_TYPE
            }) - ${formatOrdinal(acct.waterfallOrder)}`}</p>
          );
        }

        if (props.trigger && isFixedAmountAccount(acct)) {
          const fixedAmount = acct.amount.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

          return (
            <p key={acct.accountId} className={styles.accountsText}>{`${
              account?.accountDetails.displayName ?? UNKNOWN_ACCOUNT
            } (${
              account?.accountDetails.accountType ?? UNKNOWN_TYPE
            }) - ${fixedAmount}`}</p>
          );
        }

        return <div key={acct.accountId} />;
      })}
    </>
  );
};
