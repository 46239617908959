import React, { ReactElement } from "react";

import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import SvgLine from "../Shared/SvgLine";

import styles from "./Legend.module.css";

interface Props {
  displayVertical: boolean;
  contributionsText: string;
}

const Legend = ({
  displayVertical,
  contributionsText,
}: Props): ReactElement => {
  const {
    color: { $chartLinePrimary, $chartLineSecondary },
  } = useTheme();

  return (
    <div
      className={displayVertical ? styles.containerVertical : styles.container}
    >
      <div
        className={
          displayVertical
            ? styles.centeringContainerVertical
            : styles.centeringContainer
        }
      >
        <div className={styles.flexDiv}>
          <div className={styles.text}>Balance:</div>
          <div className={styles.svgLineContainer}>
            <SvgLine color={$chartLinePrimary} width={40} dashed={false} />
          </div>
        </div>

        <div className={styles.flexDiv}>
          <div className={styles.text}>{contributionsText}</div>

          <div className={styles.svgLineContainer}>
            <SvgLine color={$chartLineSecondary} width={40} dashed={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Legend;
