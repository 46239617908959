import React, { useCallback, useRef } from "react";

import { isAxiosError } from "axios";
import { Route, Switch, useHistory, useParams } from "react-router-dom";

import useDeleteTaxBudget from "@fartherfinance/frontend/api/InvestmentModel/hooks/useDeleteTaxBudget";
import { TaxBudget } from "@fartherfinance/frontend/api/InvestmentModel/Types";
import { ClientId, TaxBudgetId } from "@fartherfinance/frontend/api/Types";

import BudgetOptionsPopOver from "../BudgetOptionsPopOver/BudgetOptionsPopOver";
import DeleteBudgetModal from "../DeleteBudgetModal/DeleteBudgetModal";
import { ErrorMessage } from "../reducer/types";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { trackEvent } from "@src/multiCustodian/services/tracking";

import DetailsTab from "./DetailsTab";
import UsageTab from "./UsageTab";

import styles from "./DetailTabsContainer.module.css";

interface Props {
  budget?: TaxBudget;
  clientName?: string;
  detailsPath: string;
  usagePath: string;
}

const DetailTabsContainer: React.FC<Props> = ({
  budget,
  clientName,
  detailsPath,
  usagePath,
}) => {
  const { clientId, taxBudgetId } = useParams<{
    clientId: ClientId;
    taxBudgetId: TaxBudgetId;
  }>();

  const history = useHistory();

  const anchorRef = useRef<HTMLDivElement | null>(null);

  const statusNotification = useStatusNotification();

  const advisorAuth = useAdvisorRequestAuth();

  const clientAuth = useRequestAuth();

  const auth = advisorAuth
    ? { ...advisorAuth, clientId: clientId }
    : clientAuth;

  const callDeleteTaxBudget = useDeleteTaxBudget(auth);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const [isPopOverOpen, setIsPopOverOpen] = React.useState(false);

  const isAdvisor = history.location.pathname.startsWith("/Advisor");

  const handleOnClickEdit = useCallback(() => {
    trackEvent({
      name: isAdvisor
        ? "Advisor Open Edit Tax-Budget"
        : "Client Open Edit Tax-Budget",
    });

    const pathname = isAdvisor
      ? `/Advisor/Clients/${clientId}/TaxBudgets/${taxBudgetId}/Edit`
      : `/Client/${clientId}/TaxBudgets/${taxBudgetId}/Edit`;

    history.push({
      ...history.location,
      pathname,
    });
  }, [clientId, history, isAdvisor, taxBudgetId]);

  const toggleModal = useCallback(() => {
    setIsModalOpen((prev) => !prev);
  }, []);

  const togglePopOver = useCallback(() => {
    setIsPopOverOpen((prev) => !prev);
  }, []);

  const handleOnConfirm = useCallback(async () => {
    try {
      await callDeleteTaxBudget(taxBudgetId);

      trackEvent({
        name: isAdvisor
          ? "Advisor Complete Delete Tax-Budget"
          : "Client Complete Delete Tax-Budget",
      });

      statusNotification("Tax Budget Deleted", "Success");

      toggleModal();

      history.push({
        ...history.location,
        pathname: isAdvisor
          ? `/Advisor/Clients/${clientId}/TaxBudgets`
          : `/Client/${clientId}/TaxBudgets`,
      });
    } catch (error) {
      if (isAxiosError<ErrorMessage>(error)) {
        const message =
          error.response?.data.message ??
          "Unexpected error from investment model service.";

        return statusNotification(message, "Error");
      }

      statusNotification("Something went wrong. Please try again.", "Error");
    }
  }, [
    callDeleteTaxBudget,
    clientId,
    history,
    isAdvisor,
    statusNotification,
    taxBudgetId,
    toggleModal,
  ]);

  const handleOnDelete = useCallback(() => {
    trackEvent({
      name: isAdvisor
        ? "Advisor Open Delete Tax-Budget"
        : "Client Open Delete Tax-Budget",
    });

    togglePopOver();
    toggleModal();
  }, [toggleModal, togglePopOver, isAdvisor]);

  if (!budget) {
    return null;
  }

  return (
    <div>
      <div className={styles.header}>
        <div>
          <h1 className={styles.headerText}>{`${
            budget?.year || 0
          } Tax Year`}</h1>
          {clientName && <span className={styles.client}>{clientName}</span>}
        </div>

        <BudgetOptionsPopOver
          anchorRef={anchorRef}
          isOpen={isPopOverOpen}
          onClose={togglePopOver}
          onDelete={handleOnDelete}
          onEdit={handleOnClickEdit}
          onOpen={togglePopOver}
          size="large"
        />
      </div>

      <Switch>
        <Route path={usagePath} exact>
          <UsageTab
            budget={budget}
            detailsPath={detailsPath}
            usagePath={usagePath}
          />
        </Route>

        <Route path={detailsPath} exact>
          <DetailsTab
            budget={budget}
            detailsPath={detailsPath}
            isAdvisor={isAdvisor}
            usagePath={usagePath}
          />
        </Route>
      </Switch>

      <DeleteBudgetModal
        budget={budget}
        isOpen={isModalOpen}
        onClose={toggleModal}
        onConfirm={handleOnConfirm}
      />
    </div>
  );
};

export default DetailTabsContainer;
