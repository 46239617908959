import store from "../../store";

const metadata_payload = () => {
  const redux_state = store.getState();
  const is_in_debug = redux_state.main_Reducer.is_in_debug;
  if (is_in_debug) {
    store.dispatch({
      type: "SET_DEBUG_FLAG",
      payload: {
        is_in_debug: false,
      },
    });
  }

  return {
    subsystem_or_ui: "Webapp",
    Isadvisor: redux_state.main_Reducer.user.isAdvisor ?? false,
    Advisorid: redux_state.main_Reducer.cockroach_advisor_id,
    debugFlag: is_in_debug ? redux_state.main_Reducer.user.id_user : null,
  };
};

export default metadata_payload;
