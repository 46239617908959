import React, { useMemo } from "react";

import { orderBy } from "lodash";

import {
  AnyPortfolioV2,
  PortfolioAnalysis,
  type PortfolioAssetCategories,
} from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";

import PortfolioTable from "../PortfolioTable/PortfolioTable";
import { getSecuritiesLabel } from "../utils/getSecuritiesLabel";

type PortfolioAssetCategory = typeof PortfolioAssetCategories[number];
interface Props {
  showDirectIndexedFilter: boolean;
  setToCategoryView: (category: PortfolioAssetCategory) => void;
  headerStyle?: React.CSSProperties;
  portfolio: AnyPortfolioV2;
  portfolioAnalysis: PortfolioAnalysis;
}

const ModelView = ({
  showDirectIndexedFilter,
  portfolio,
  portfolioAnalysis,
  setToCategoryView,
  headerStyle,
}: Props): JSX.Element => {
  const title = useMemo(() => {
    const allSecurities = (
      portfolioAnalysis.portfolioAssetClassBreakdown ?? []
    ).flatMap((acObj) => Object.values(acObj)[0].securities);

    const secondaryDescription = getSecuritiesLabel(allSecurities.length);

    return {
      name: portfolio.model.displayName,
      description: "Holdings Breakdown",
      secondaryDescription,
    };
  }, [portfolio, portfolioAnalysis]);

  const rows = useMemo(
    () =>
      orderBy(
        Object.entries(portfolioAnalysis.portfolioCategoryBreakdown ?? {}),
        [([category]) => category, ([_, allocation]) => allocation],
        ["asc", "desc"]
      ).map(([category, allocation]) => ({
        key: category,
        description: category,
        allocation: allocation,
        onClick: () => setToCategoryView(category as PortfolioAssetCategory),
      })),
    [portfolioAnalysis.portfolioCategoryBreakdown, setToCategoryView]
  );

  return (
    <PortfolioTable
      showDirectIndexedFilter={showDirectIndexedFilter}
      portfolio={portfolio}
      title={title}
      rows={rows}
      headerStyle={headerStyle}
    />
  );
};

export default ModelView;
