import React, { useState } from "react";

import { orderBy } from "lodash";
import { useParams } from "react-router-dom";

import useGetFartherManagedAccounts from "@fartherfinance/frontend/api/Accounts/hooks/useGetFartherManagedAccounts";
import { TimeFrame } from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Types";
import usePostAccountGroupsV4 from "@fartherfinance/frontend/api/PerformanceGroups/hooks/usePostAccountGroupsV4";
import {
  ClientId,
  FartherAccountId,
  PerformanceGroupId,
} from "@fartherfinance/frontend/api/Types";

import Button from "../../../MUI/Button/Button";
import { usePerformanceCustomDateRangeContext } from "../../Summary/components/PerformanceCustomDateRangeContextProvider";
import CheckboxRow from "../Shared/CheckboxRow/CheckboxRow";
import FavoriteCheckbox from "../Shared/FavoriteCheckbox/FavoriteCheckbox";
import GroupName from "../Shared/GroupName/GroupName";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import ModalWithClearButton from "@src/sharedComponents/Modal/ModalWithClearButton";

import styles from "./CreateAccountGroupModal.module.css";

interface Props {
  closeModal: () => void;
  setAwaitingAccountGroupId: (agId: PerformanceGroupId) => void;
  closeDropdown: () => void;
  setTimeFrame: (range: TimeFrame) => void;
}

const CreateAccountGroupModal: React.FC<Props> = ({
  closeModal,
  setAwaitingAccountGroupId,
  closeDropdown,
  setTimeFrame,
}: Props) => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const { setCustomDateRange } = usePerformanceCustomDateRangeContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [groupName, setGroupName] = useState<string>("");
  const [selectedAccountIds, setSelectedAccountIds] = useState<
    FartherAccountId[]
  >([]);

  const notify = useStatusNotification();

  const auth = useRequestAuth();
  const createCustomAccountGroup = usePostAccountGroupsV4(auth);
  const managedAccounts = useGetFartherManagedAccounts(clientId, auth);

  const numberOfAccounts =
    managedAccounts.isLoading || managedAccounts.hasError
      ? ""
      : `${managedAccounts.data.length}`;

  const toggleAccount = (checked: boolean, accountId: FartherAccountId) => {
    if (checked) {
      setSelectedAccountIds([...selectedAccountIds, accountId]);
    } else {
      setSelectedAccountIds(
        selectedAccountIds.filter((id) => id !== accountId)
      );
    }
  };

  const isDisabled = groupName.length < 3 || selectedAccountIds.length < 1;

  const onCreate = async () => {
    try {
      setIsLoading(true);

      const res = await createCustomAccountGroup({
        ownerId: clientId,
        displayName: groupName,
        isFavorite,
        virtualAccountIds: selectedAccountIds,
      });

      notify("Account group created", "Success");
      setTimeFrame("1Y"); // reset to default
      setCustomDateRange({ from: null, to: null }); // reset in case CustomDateRange was set
      setAwaitingAccountGroupId(res.accountGroupId);
      closeModal();
      closeDropdown();
    } catch (_e) {
      notify("Account group could not be created. Try again.", "Error");
      setIsLoading(false);
    }
  };

  return (
    <ModalWithClearButton closeModal={closeModal}>
      {isLoading && <LogoLoadingStill onTop />}

      <div className={styles.title}>Create a New Account Group</div>

      <div className={styles.subTitle}>
        Create an account group to see a combined view of summary, activity,
        holdings, and projections.
      </div>

      <div
        className={styles.heading}
      >{`Select accounts for this group (${numberOfAccounts})`}</div>

      <div className={styles.box}>
        {orderBy(
          managedAccounts.data ?? [],
          [(a) => a.accountDetails.displayName],
          ["asc"]
        ).map((a) => (
          <CheckboxRow
            key={a.virtualAccountId}
            account={a}
            isChecked={selectedAccountIds.some(
              (id) => id === a.virtualAccountId
            )}
            onToggleAccount={toggleAccount}
            isDisabled={false}
          />
        ))}
      </div>

      <GroupName
        label={<div className={styles.textInputLabel}>Group Name</div>}
        groupName={groupName}
        setGroupName={setGroupName}
        isDisabled={false}
      />

      <FavoriteCheckbox isFavorite={isFavorite} setIsFavorite={setIsFavorite} />

      <div className={styles.footer}>
        <Button
          text={"Cancel"}
          variant={"outlined"}
          buttonType={"secondary"}
          onClick={closeModal}
          sx={{ width: "80px" }}
        />

        <Button
          text={"Save"}
          variant={"contained"}
          buttonType={"primary"}
          onClick={onCreate}
          sx={{ marginLeft: "20px", width: "80px" }}
          disabled={isDisabled}
        />
      </div>
    </ModalWithClearButton>
  );
};

export default CreateAccountGroupModal;
