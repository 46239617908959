import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

export const useStyles = makeStyles()((theme) => ({
  container: {
    marginBottom: theme.spacing(pxToSpacing(24)),
  },
  icon: {
    width: 18,
    height: 18,
  },
  returnIcon: {
    marginRight: theme.spacing(pxToSpacing(6)),
    transform: "rotate(180deg)",
  },
  nextIcon: {
    marginLeft: theme.spacing(pxToSpacing(6)),
  },
  downloadIcon: {
    marginLeft: theme.spacing(pxToSpacing(4)),
  },
  timestamp: {
    fontFamily: theme.typography.fontSecondary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.fontSize,
    lineHeight: "19px",
    letterSpacing: "0.63px",
    color: theme.colors.primary.tints.navy50,

    display: "flex",
  },
  timestampAccent: {
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(pxToSpacing(6)),
  },
}));
