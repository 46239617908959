import React, { useMemo } from "react";

import { orderBy } from "lodash";
import { z } from "zod";

import useGetFartherManagedAccounts from "@fartherfinance/frontend/api/Accounts/hooks/useGetFartherManagedAccounts";
import { FartherManagedAccountDetails } from "@fartherfinance/frontend/api/Accounts/Types";
import useAvailableAccounts from "@fartherfinance/frontend/api/TradingGroups/hooks/useAvailableAccounts";
import useTradingGroups from "@fartherfinance/frontend/api/TradingGroups/hooks/useTradingGroups";
import { TradingGroupAccount } from "@fartherfinance/frontend/api/TradingGroups/requests/getAvailableAccounts";
import { TradingGroup } from "@fartherfinance/frontend/api/TradingGroups/requests/getTradingGroup";
import {
  ClientId,
  FartherAccountId,
  HookResult,
  PortfolioId,
} from "@fartherfinance/frontend/api/Types";
import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import { captureException } from "@src/multiCustodian/services/tracking";

type AssignedTo = "None" | "AnotherPortfolio" | "ThisPortfolio";

type FartherManagedAccountDetails = z.infer<
  typeof FartherManagedAccountDetails
>;

export interface AccountWithPortfolio
  extends Pick<
      FartherManagedAccountDetails,
      "displayName" | "custodianAccountNumber" | "lastReportedBalance"
    >,
    TradingGroupAccount {
  accountId: FartherAccountId;
  portfolioId: PortfolioId | null;
  sharedBy: ClientId[];
  assignedTo: AssignedTo;
  name: React.ReactNode;
  isChecked: boolean | null;
  isDisabled: boolean;
}

export const useAvailableAccountFromTradingGroups = (
  clientId: ClientId,
  portfolioId: PortfolioId
): HookResult<AccountWithPortfolio[]> => {
  const {
    color: { $textSubtlest },
  } = useTheme();

  const auth = useRequestAuth();

  const accounts = useGetFartherManagedAccounts(clientId, auth);
  const tradingGroups = useTradingGroups(clientId, auth);
  const availableAccounts = useAvailableAccounts(auth);

  const tradingGroupsByAccountId = useMemo(() => {
    return (tradingGroups.data ?? [])
      .flatMap((tg) =>
        tg.accounts.map((a) => ({
          accountId: a.virtualAccountId,
          tradingGroup: tg,
        }))
      )
      .reduce<Record<FartherAccountId, TradingGroup>>(
        (accum, curr): Record<FartherAccountId, TradingGroup> => ({
          ...accum,
          [curr.accountId]: curr.tradingGroup,
        }),
        {}
      );
  }, [tradingGroups]);

  const rows = useMemo(() => {
    if (accounts.data === undefined || availableAccounts.data === undefined) {
      return [];
    }
    const inAccountsMissingInAvailableAccounts = accounts.data
      .filter((a) =>
        availableAccounts.data.accounts.every(
          (aa) => aa.accountId !== a.virtualAccountId
        )
      )
      .map((m) => m.virtualAccountId);

    const inAvailableAccountsMissingInAccounts = availableAccounts.data.accounts
      .filter((aa) =>
        accounts.data.every((a) => a.virtualAccountId !== aa.accountId)
      )
      .map((m) => m.accountId);

    if (
      inAccountsMissingInAvailableAccounts.length > 0 ||
      inAvailableAccountsMissingInAccounts.length > 0
    ) {
      const errorMissing = new Error(
        "Some accounts are either in accounts and missing from available accounts, or are in available accounts and missing from accounts"
      );

      captureException(errorMissing, {
        extra: {
          file: "useAvailableAccountFromTradingGroups.tsx",
          inAccountsMissingInAvailableAccounts,
          inAvailableAccountsMissingInAccounts,
        },
      });
    }

    const accountsInBoth = accounts.data.filter((a) =>
      availableAccounts.data.accounts.some(
        (aa) => aa.accountId === a.virtualAccountId
      )
    );

    return accountsInBoth.map((a): AccountWithPortfolio => {
      const availableAccount = availableAccounts.data.accounts.find(
        (avAcc) => avAcc.accountId === a.virtualAccountId
      );

      if (availableAccount === undefined) {
        const err = new Error(
          `Unable to find account in getAvailableAccounts using Id: ${a.virtualAccountId}`
        );

        throw err;
      }

      const tradingGroupForAccount = tradingGroupsByAccountId[
        a.virtualAccountId
      ] as TradingGroup | undefined;
      const portfolioIdForAccount = tradingGroupForAccount
        ? tradingGroupForAccount.portfolioId
        : null;
      const assignedToThisPortfolio = portfolioIdForAccount === portfolioId;

      const assignedTo: AssignedTo = assignedToThisPortfolio
        ? "ThisPortfolio"
        : portfolioIdForAccount !== null
        ? "AnotherPortfolio"
        : "None";

      return {
        displayName: a.accountDetails.displayName,
        name: (
          <span>
            {a.accountDetails.displayName}{" "}
            <span style={{ color: $textSubtlest }}>
              {a.accountDetails.custodianAccountNumber}
            </span>
          </span>
        ),
        lastReportedBalance: a.accountDetails.lastReportedBalance,
        custodianAccountNumber: a.accountDetails.custodianAccountNumber,
        portfolioId: portfolioIdForAccount,
        sharedBy: a.sharedBy,
        assignedTo: assignedTo,
        isChecked: null,
        isDisabled: assignedTo === "AnotherPortfolio",
        ...availableAccount,
      };
    });
  }, [
    $textSubtlest,
    accounts.data,
    availableAccounts.data,
    portfolioId,
    tradingGroupsByAccountId,
  ]);

  const orderedRows = useMemo(() => {
    return orderBy(
      rows,
      [
        (a) => {
          switch (a.assignedTo) {
            case "ThisPortfolio":
              return 2;

            case "AnotherPortfolio":
              return 1;

            case "None":
              return 0;

            default: {
              const _x: never = a.assignedTo;
              throw new Error("compile error");
            }
          }
        },
        (a) => a.portfolioId ?? "-",
        (a) => a.displayName,
        (a) => a.lastReportedBalance.balance ?? 0,
      ],
      ["desc", "asc", "desc"]
    );
  }, [rows]);

  return useMemo(() => {
    if (accounts.isLoading || tradingGroups.isLoading) {
      return { isLoading: true, data: undefined, hasError: false };
    }

    if (accounts.hasError) {
      return accounts;
    }

    if (tradingGroups.hasError) {
      return tradingGroups;
    }

    return { data: orderedRows, isLoading: false, hasError: false };
  }, [accounts, orderedRows, tradingGroups]);
};
