import React from "react";

import { useHistory, useParams } from "react-router-dom";

import { AltAssetId, ClientId } from "@fartherfinance/frontend/api/Types";

import Actions from "../:accountId/Settings";
import { AccountsPath } from "@src/config/routing/RouterPaths";
import UpdateInvestmentForm from "@src/multiCustodian/components/Client/Accounts/Manual/ActionsForms/UpdateInvestmentForm";
import Modal from "@src/sharedComponents/Modal/Modal";

import styles from "@src/multiCustodian/components/Client/Accounts/Manual/ManualAssetDrawer.module.css";

const UpdateValue = (): JSX.Element => {
  const { clientId, accountId } = useParams<{
    clientId: ClientId;
    accountId: AltAssetId;
  }>();

  const history = useHistory();

  const closeModal = () => {
    history.push(
      `/Client/${clientId}/${AccountsPath}/Manual/${accountId}/Settings`
    );
  };

  return (
    <Actions>
      <Modal closeModal={closeModal}>
        <div className={styles.modal__title_div}>
          <p className={styles.modal__modal_title}>Update Investment Value</p>
        </div>

        <p className={styles.modal__modal_summary}>
          To update your investment value, please provide the new amount.
        </p>

        <UpdateInvestmentForm closeModal={closeModal} />
      </Modal>
    </Actions>
  );
};

export default UpdateValue;
