// Always goes first
/* eslint-disable import/order */
import "./multiCustodian/services/axios.ts";
import "./multiCustodian/services/sharedLibrary";
/* eslint-enable import/order */

import React from "react";

import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { StyledEngineProvider } from "@mui/material/styles";
import {
  CaptureConsole as CaptureConsoleIntegration,
  ExtraErrorData as ExtraErrorDataIntegration,
  HttpClient as HttpClientIntegration,
} from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import mixpanel from "mixpanel-browser";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";

import { ThemeProvider } from "@fartherfinance/frontend/theme/ThemeProvider";

import App from "./app";
import { history } from "./config/routing/Router";
import { queryClient } from "./reactQuery.ts";
import store from "./store";

Sentry.init({
  dsn: "https://06f5497346c94ce79c0aa34a8f98ef07@o4504314371833856.ingest.sentry.io/4504680714207232",
  release: process.env.COMMIT_SHA_FULL,
  integrations: [
    // Replay is loaded in `tracking.tsx`
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      tracePropagationTargets: [
        "middleware.farther.com",
        "uat-middleware.farther.com",
      ],

      shouldCreateSpanForRequest: (url) => {
        // Do not create spans for outgoing requests
        const onIgnoreList = [
          "browser-intake-datadoghq.com",
          "launchdarkly.com",
          "api-js.mixpanel.com",
        ].reduce((onList, curr) => onList || url.includes(curr), false);

        return !onIgnoreList;
      },
    }),
    new ExtraErrorDataIntegration({
      // Limit of how deep the object serializer should go. Anything deeper than limit will
      // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
      // a primitive value. Defaults to 3.
      depth: 3,
    }),
    new HttpClientIntegration({
      // This array can contain tuples of `[begin, end]` (both inclusive),
      // single status codes, or a combination of both.
      // default: [[500, 599]]
      failedRequestStatusCodes: [[400, 599]],

      // This array can contain Regexes, strings, or a combination of both.
      // default: [/.*/]
      // failedRequestTargets: [
      //   "http://example.com/api/test",
      //   /(staging\.)?mypage\.com/,
      // ],
    }),
    new CaptureConsoleIntegration({
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      levels: ["error", "assert"],
    }),
  ],

  // Must be uppercase to make Sentry Happy
  environment: process.env.WEBAPP_EXACT_ENV.toUpperCase(),

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,

  // This sets the sample rate. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 1.0,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // Sentry SDKs normalize any contextual data to a given depth. Any data beyond
  // this depth will be trimmed and marked using its type instead
  // ([Object] or [Array]), without walking the tree any further. By default,
  // walking is performed three levels deep.
  normalizeDepth: 3,

  // Send headers for HTTP requests
  sendDefaultPii: true,

  // When enabled, stack traces are automatically attached to all messages logged.
  // Stack traces are always attached to exceptions; however, when this option is
  // set, stack traces are also sent with messages. This option, for instance,
  // means that stack traces appear next to all log messages.
  attachStacktrace: true,

  // A list of strings or regex patterns that match error URLs that should not be
  // sent to Sentry. Errors whose entire file URL contains (string) or matches
  // (regex) at least one entry in the list will not be sent. As a result, if
  //  you add 'foo.com' to the list, it will also match on https://bar.com/myfile/foo.com.
  // By default, all errors are sent.
  denyUrls: ["localhost:"],

  // Enable for UAT and PROD unless a dev is running locally
  enabled:
    (process.env.WEBAPP_EXACT_ENV === "PROD" ||
      process.env.WEBAPP_EXACT_ENV === "UAT") &&
    window.location.hostname.includes("localhost") === false,

  beforeSend: function (event, hint) {
    const exception = hint.originalException;

    if (exception instanceof Error) {
      event.fingerprint = [String(exception.name), String(exception.message)];
    }

    return event;
  },
});

datadogRum.init({
  applicationId: "f9e735a5-ffc5-4785-ace7-2611c5830226",
  clientToken: "pub3379a22d4f067ae32fd0af671b20e2e2",
  site: "datadoghq.com",
  service: "glacier",
  env: (process.env.WEBAPP_EXACT_ENV ?? "DEV").toLowerCase(),
  version: process.env.COMMIT_SHA_FULL,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  enableExperimentalFeatures: ["clickmap", "feature_flags"],
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "allow",
  allowedTracingUrls: [
    {
      match: (url) => url.includes("farther.com"),
      propagatorTypes: ["tracecontext"],
    },
  ],
  beforeSend: (event) => {
    switch (event.type) {
      case "action":
        return true;

      case "long_task":
        return true;

      case "resource":
        return true;

      case "view":
        return true;

      case "error":
        if (window.location.origin.includes("localhost:3000")) {
          return false;
        }

        if (
          event.error.message.includes(
            "Warning: Can't perform a React state update on an unmounted component."
          )
        ) {
          return false;
        }

        if (event.error.message.includes("Magic SDK Error")) {
          return false;
        }

        if (event.error.message.includes("ResizeObserver")) {
          return false;
        }

        console.log("DD error", event.error);
        return true;

      default:
        return true;
    }
  },
});

datadogRum.startSessionReplayRecording();

datadogLogs.init({
  clientToken: "pub3379a22d4f067ae32fd0af671b20e2e2",
  site: "datadoghq.com",
  env: (process.env.WEBAPP_EXACT_ENV ?? "DEV").toLowerCase(),
  version: process.env.COMMIT_SHA_FULL,
  forwardErrorsToLogs: true,
  forwardConsoleLogs: "all",
  sessionSampleRate: 100,
});

mixpanel.init("989d818464e15d100593d9250019dba1", {
  // Cleaner than not loading because library stuff can break otherwise
  opt_out_tracking_by_default: process.env.WEBAPP_EXACT_ENV !== "PROD",
  ip: true,
  ignore_dnt: true,
  loaded: function () {
    //mixpanel has loaded
    //monkey patch to make sure that mixpanel.track() always checks for production env
    // const originalTrack = mixpanel.track;
    // mixpanel.track = function () {
    //   if (process.env.WEBAPP_EXACT_ENV === "PROD") {
    //     originalTrack.apply(mixpanel, arguments);
    //   }
    // };
    // now write your own mixpanel tracking code, listeners, etc…
  },
});

const ProfiledApp = Sentry.withProfiler(App);

const withLaunchDarkly = withLDProvider({
  clientSideID:
    process.env.WEBAPP_ENV === "PROD"
      ? "63f4fd7a6f94d413bae7db57"
      : "63f4fd7a6f94d413bae7db56",
  options: {
    evaluationReasons: true,
    streaming: true,
    application: { id: "Glacier", version: process.env.COMMIT_SHA_FULL },
    inspectors: [
      {
        type: "flag-used",
        name: "dd-inspector",
        method: (key, detail) => {
          datadogRum.addFeatureFlagEvaluation(key, detail.value);
        },
      },
    ],
  },
});

const ProfiledWithLD = withLaunchDarkly(ProfiledApp);

ReactDOM.render(
  <Sentry.ErrorBoundary
    showDialog
    fallback={<p>An error has occurred, we're on it</p>}
  >
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-left" />

      <ReduxProvider store={store}>
        <ThemeProvider>
          <StyledEngineProvider injectFirst>
            <ProfiledWithLD />
          </StyledEngineProvider>
        </ThemeProvider>
      </ReduxProvider>
    </QueryClientProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);
