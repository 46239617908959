import React, { useCallback } from "react";

import { useHistory, useParams } from "react-router-dom";

import useAuthenticateOtps from "@fartherfinance/frontend/api/Identity/hooks/useAuthenticateOtps";
import useMFAConfirm from "@fartherfinance/frontend/api/Identity/hooks/useMFAConfirm";
import useSendMFASms from "@fartherfinance/frontend/api/Identity/hooks/useSendMFASms";
import { AdvisorId, ClientId } from "@fartherfinance/frontend/api/Types";

import { LocalStorageKeys } from "@src/multiCustodian/components/Login/constants";
import errorHandler from "@src/multiCustodian/components/Login/errorHandler";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";

import EnterPhoneNumber from "./EnterPhoneNumber";
import VerifyOTP from "./VerifyOTP";

type MFAState = { type: "start" } | { type: "verifyOTP"; methodId: string };

const EnableMFA: React.FC = () => {
  const sendMFASms = useSendMFASms();

  const [state, setState] = React.useState<MFAState>({ type: "start" });

  const [error, setError] = React.useState<string | null>(null);

  const [resendDisabled, setResendDisabled] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const email = localStorage.getItem(LocalStorageKeys.email);

  const { advisorId, clientId } = useParams<
    | {
        clientId: ClientId;
        advisorId: undefined;
      }
    | {
        clientId: undefined;
        advisorId: AdvisorId;
      }
  >();

  const personId = advisorId ?? clientId;

  const clientAuth = useRequestAuth();

  const advisorAuth = useAdvisorRequestAuth();

  const auth = clientAuth ?? advisorAuth;

  const confirm = useMFAConfirm(personId, auth);

  const notification = useStatusNotification();

  const authenticate = useAuthenticateOtps();

  const backUrl =
    clientId !== undefined
      ? `/Client/${clientId}/Dashboard`
      : `/Advisor/Clients`;

  const history = useHistory();

  const authenticateOTP = useCallback(
    async (code: string, methodId: string): Promise<void> => {
      setIsLoading(true);

      try {
        await authenticate(methodId, code);

        // Clear any previous errors
        setError(null);

        await confirm();

        notification("Two-Factor Authentication enabled", "Success", "Left");

        history.push(backUrl);
      } catch (err) {
        const notificationMessage = errorHandler(err);

        notification(notificationMessage, "Error");
      } finally {
        setIsLoading(false);
      }
    },
    [authenticate, backUrl, confirm, history, notification]
  );

  const resendOTPCode = async (): Promise<string | undefined> => {
    try {
      const res = await sendMFASms(email!);

      notification("Code re-sent", "Success");

      setResendDisabled(true);

      return res.methodId;
    } catch (err) {
      const notificationMessage = errorHandler(err);

      notification(notificationMessage, "Error");
    }
  };

  switch (state.type) {
    case "start":
      return (
        <EnterPhoneNumber
          onBack={() => {
            history.push(backUrl);
          }}
          onSetMFA={async (methodId: string | null) => {
            if (methodId === null) {
              try {
                const res = await sendMFASms(email!);

                setState({ type: "verifyOTP", methodId: res?.methodId });
              } catch (_err) {
                notification("Error sending code", "Error");
              }
            } else {
              setState({ type: "verifyOTP", methodId });
            }
          }}
        />
      );
    case "verifyOTP":
      return (
        <VerifyOTP
          authenticateOTP={async (code) => {
            await authenticateOTP(code, state.methodId);
          }}
          error={error}
          isLoading={isLoading}
          methodType="phone"
          onBack={() => setState({ type: "start" })}
          resendDisabled={resendDisabled}
          resendOTPCode={async () => {
            await resendOTPCode();
          }}
        />
      );
    default:
      return null;
  }
};

export default EnableMFA;
