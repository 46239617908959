import * as Sentry from "@sentry/react";
import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { uniqueId } from "lodash";

import store from "@src/store";

export const authInterceptor = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const { farther_jwt, cockroach_advisor_id } = store.getState().main_Reducer;

  if (farther_jwt) {
    config.headers.set("Authorization", `Bearer ${farther_jwt}`);
  }

  if (cockroach_advisor_id) {
    config.headers.set("Advisor", cockroach_advisor_id);
  }

  return config;
};

export const errorInterceptor = <T, D>(error: AxiosError<T, D>) => {
  return Promise.reject(error);
};

export const loggingRequestInterceptor = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const transaction = Sentry.getCurrentHub().getScope()?.getTransaction();

  if (transaction) {
    config.sentry = {
      uuid: uniqueId("network"),
      startTime: Date.now(),
      span: Sentry.startSpan(
        {
          name: "http.client",
          op: "http.client",
          description: config.url,
        },
        () => undefined
      ),
    };
  }

  return config;
};

export const loggingResponseInterceptor = (
  response: AxiosResponse
): AxiosResponse => {
  const { span } = response.config.sentry ?? {};

  if (span !== undefined) {
    span.finish();
  }

  return response;
};
