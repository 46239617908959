import React, { useMemo } from "react";

import { useParams } from "react-router-dom";

import { TaxBudget } from "@fartherfinance/frontend/api/InvestmentModel/Types";
import useGetConsumedBudget from "@fartherfinance/frontend/api/Rebalance/hooks/useGetConsumedBudget";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import toUSD from "@src/utils/toUSD";

import styles from "./UsedBudget.module.css";

interface Props {
  budget?: TaxBudget;
}

const UsedBudget: React.FC<Props> = ({ budget }) => {
  const { clientId } = useParams<{ clientId: ClientId }>();

  const budgetMax = budget?.budgetAmount ?? 0;

  const advisorAuth = useAdvisorRequestAuth();

  const clientAuth = useRequestAuth();

  const auth = advisorAuth || clientAuth;

  const { data, isLoading } = useGetConsumedBudget({ clientId }, auth);

  const consumedBudget =
    budget?.budgetId === data?.budgetId
      ? (data?.capitalConsequences.estimatedLongTermTaxesDollars ?? 0) +
        (data?.capitalConsequences.estimatedShortTermTaxesDollars ?? 0)
      : 0;

  const progressPercent = useMemo(() => {
    if (consumedBudget >= budgetMax) {
      return 100;
    }

    if (consumedBudget <= 0) {
      return 0;
    }

    return (consumedBudget / budgetMax) * 100;
  }, [consumedBudget, budgetMax]);

  const maxValueClassName =
    progressPercent >= 100 ? styles.text3 : styles.text2;

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <div className={styles.text}>Budget Used</div>

        <div className={styles.flexRow}>
          {isLoading ? (
            <div className={styles.text}>--</div>
          ) : (
            <>
              <div className={styles.text}>{`${toUSD(consumedBudget)}`}</div>

              <div className={maxValueClassName}>{`/ ${toUSD(budgetMax)}`}</div>
            </>
          )}
        </div>
      </div>

      <div className={styles.progressBarContainer}>
        <div
          className={styles.progressBar}
          style={{ width: `${progressPercent}%` }}
        />
      </div>
    </div>
  );
};

export default UsedBudget;
