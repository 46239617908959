import React, { useMemo, useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { isEqual } from "lodash";

import {
  GetOpportunitiesQueryParams,
  OpportunitiesFilterQueryParams,
} from "@fartherfinance/frontend/api/Opportunities/requests/getOpportunities";

import { OPPORTUNITIES_DEFAULT_PAGE } from "../../common";
import OpportunitiesFilterForm from "../OpportunitiesFilterForm/OpportunitiesFilterForm";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import Select from "@src/sharedComponents/Select/Select";

import {
  filterOptions,
  FilterOptions,
  filterToOpportunityStatus,
} from "./OpportunitiesToolbar.const";

import styles from "./OpportunitiesToolbar.module.css";

interface OpportunitiesToolbarProps {
  filters: OpportunitiesFilterQueryParams;
  onQueryParamChange: (newQueryParams: GetOpportunitiesQueryParams) => void;
  onQueryParamReset: (
    resetOverwriteQueryParams: GetOpportunitiesQueryParams
  ) => void;
}

export default function OpportunitiesToolbar({
  filters,
  onQueryParamChange,
  onQueryParamReset,
}: OpportunitiesToolbarProps): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const matchedFilterOption = useMemo((): FilterOptions => {
    const activeFilters = filters.filterByStatus;
    const matching = filterOptions.filter((o) =>
      isEqual(filterToOpportunityStatus[o].sort(), activeFilters?.sort())
    );
    const topMatching = matching[0];

    if (topMatching === undefined) {
      return "Custom";
    } else {
      return topMatching;
    }
  }, [filters.filterByStatus]);

  const handleSelectFilteringOption = (filterOption: FilterOptions): void => {
    const newFilterValues = filterToOpportunityStatus[filterOption];

    if (newFilterValues === undefined) {
      return;
    }

    onQueryParamReset({ filterByStatus: newFilterValues });
  };

  const handleFilterFormSubmission = (
    newFilters: OpportunitiesFilterQueryParams
  ): void => {
    onQueryParamChange({ ...newFilters, page: OPPORTUNITIES_DEFAULT_PAGE });
  };

  return (
    <>
      <Stack className={styles.container} direction="row" alignItems="center">
        <Select
          className={styles.select}
          options={filterOptions}
          value={matchedFilterOption}
          onChange={handleSelectFilteringOption}
          renderValue={(value) => value}
        />
        <Stack
          className={styles.filtersContainer}
          direction="row"
          alignItems="center"
          onClick={() => setIsModalOpen(true)}
        >
          <Typography className={styles.filtersLabel}>Filters</Typography>
          <KeyboardArrowDownIcon
            className={toClassName({
              [styles.filtersIconRotate]: isModalOpen,
            })}
          />
        </Stack>
      </Stack>

      {isModalOpen && (
        <OpportunitiesFilterForm
          currentFilters={filters}
          onSubmit={handleFilterFormSubmission}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
}
