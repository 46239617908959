import React from "react";

import { useParams } from "react-router-dom";

import { PortfolioId } from "@fartherfinance/frontend/api/Types";
import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import ModelMarketplace from "../../ModelMarketplace";
import ArchiveModelModal from "@src/multiCustodian/components/Advisor/Investments/ModelMarketplace/ArchiveModelModal/ArchiveModelModal";
import ModelMarketplaceModelDetailsTable from "@src/multiCustodian/components/Advisor/Investments/ModelMarketplace/ModelDetailsTable";
import ModelDrawer from "@src/multiCustodian/components/Advisor/Investments/ModelMarketplace/ModelDrawer/ModelDrawer";
import ModelSharingTable from "@src/multiCustodian/components/Advisor/Investments/ModelMarketplace/ModelSharingTable/ModelSharingTable";
import HistoricPerformanceTable from "@src/multiCustodian/components/Client/Portfolio/ModelAnalysis/HistoricPerformanceTable";
import PortfolioAnalysisAssetClassCategoryTable from "@src/multiCustodian/components/PortfolioAnalysis/PortfolioAnalysisAssetClassCategoryTable";

export default function ArchiveModel(): JSX.Element {
  const { portfolioId, sharingOrModelDetails } = useParams<{
    portfolioId: PortfolioId;
    sharingOrModelDetails: "Sharing" | "ModelDetails";
  }>();

  const {
    color: { $backgroundSubtle },
  } = useTheme();

  const curTab =
    sharingOrModelDetails === "ModelDetails" ? "Model Details" : "Sharing";

  return (
    <ModelMarketplace>
      <ModelDrawer curTab={curTab} portfolioId={portfolioId}>
        {sharingOrModelDetails === "ModelDetails" ? (
          <>
            <ModelMarketplaceModelDetailsTable portfolioId={portfolioId} />

            <HistoricPerformanceTable portfolioId={portfolioId} />

            <PortfolioAnalysisAssetClassCategoryTable
              showDirectIndexedFilter={false}
              portfolioId={portfolioId}
              headerStyle={{ backgroundColor: $backgroundSubtle }}
            />
          </>
        ) : (
          <ModelSharingTable portfolioId={portfolioId} />
        )}
        <ArchiveModelModal />
      </ModelDrawer>
    </ModelMarketplace>
  );
}
