import React, { useState } from "react";

import { PortfolioId } from "@fartherfinance/frontend/api/Types";

import PortfolioAnalysisAssetClassCategoryTable from "../../../PortfolioAnalysis/PortfolioAnalysisAssetClassCategoryTable";
import TwoTextItemToggle from "@src/sharedComponents/Toggle/TwoTextItemToggle";

import Breakdown from "./components/Breakdown";
import Projections from "./components/Projections";

import styles from "./ModelAnalysis.module.css";

type GraphType = "Breakdown" | "Projections";

interface Props {
  portfolioId: PortfolioId | null;
}

const ModelAnalysis = (props: Props): JSX.Element => {
  const [graph, setGraph] = useState<GraphType>("Breakdown");

  const changeGraph = (opt: "Left" | "Right") =>
    setGraph(opt === "Left" ? "Breakdown" : "Projections");

  return (
    <div className={styles.modelAnalysis}>
      <div className={styles.tableContainerFormatter}>
        <PortfolioAnalysisAssetClassCategoryTable
          showDirectIndexedFilter={false}
          portfolioId={props.portfolioId}
          height100={true}
        />
      </div>

      <div className={styles.breakdownAndProjectionsContainer}>
        <div className={styles.header}>
          <TwoTextItemToggle
            onChange={changeGraph}
            leftOption="Breakdown"
            rightOption="Projections"
            value={graph === "Breakdown" ? "Left" : "Right"}
          />
        </div>

        <div style={{ flexGrow: 1 }}>
          {graph === "Breakdown" && (
            <Breakdown portfolioId={props.portfolioId} />
          )}

          {graph === "Projections" && (
            <Projections portfolioId={props.portfolioId} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ModelAnalysis;
