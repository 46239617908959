import React from "react";

import { Security } from "../SecuritiesAllocationTable/SecuritiesAllocationTable";
import ScrollContainer from "@src/sharedComponents/ScrollContainer/ScrollContainer";
import { pluralize } from "@src/utils/pluralize";

import styles from "./SecuritiesAllocationConfirmTable.module.css";

export interface AllocatedSecurity extends Security {
  allocation: number;
}

interface Props {
  portfolioName: string;
  securities: AllocatedSecurity[];
}

const SecuritiesAllocationConfirmTable = (props: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      <div className={styles.listHeaderDiv}>
        <div className={styles.flexDiv}>
          <p className={styles.headerText1}>{props.portfolioName}</p>

          <p className={styles.headerText2}>Model Allocation</p>
        </div>

        <p className={styles.headerText3}>
          {pluralize(props.securities, "Security", true)}
        </p>
      </div>

      <div className={styles.optionsDiv}>
        <div className={styles.firstOptionDiv}>
          <p className={styles.headerText2}>Securities</p>
          <p className={styles.headerText2}>Allocation</p>
        </div>

        <ScrollContainer maxHeight={330}>
          {props.securities.map((sec) => {
            return (
              <div key={sec.ticker} className={styles.optionDiv}>
                <div className={styles.flexDiv}>
                  <p className={styles.headerText1}>{sec.ticker}</p>
                  <p className={styles.headerText2}>{sec.displayName}</p>
                </div>

                <p className={styles.allocationText}>
                  {`${sec.allocation.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}%`}
                </p>
              </div>
            );
          })}
        </ScrollContainer>

        <div className={styles.allocationTotalDiv}>
          <p className={styles.headerText2}>Total</p>

          <p className={styles.allocationText}>100.00%</p>
        </div>
      </div>
    </div>
  );
};

export default SecuritiesAllocationConfirmTable;
