import React from "react";

import { ReceivedInvitation } from "@fartherfinance/frontend/api/Sharing/Types";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import Modal from "@src/sharedComponents/Modal/Modal";

import styles from "./RemoveModal.module.css";

interface Props {
  invitation: ReceivedInvitation;
  onRemove: () => void;
  onCancel: () => void;
}

const RemoveModal: React.FC<Props> = ({ invitation, onCancel, onRemove }) => {
  const handleOnClickCancel = () => onCancel();
  const handleOnClickRemove = () => onRemove();

  return (
    <Modal closeModal={onCancel} className={styles.container}>
      <div className={styles.title}>Are you sure?</div>

      <div className={styles.text}>
        {`By removing, you will no longer be able to access accounts that ${invitation.invitor.firstName} ${invitation.invitor.lastName} has
        shared with you.`}
      </div>

      <div className={styles.footer}>
        <Button
          variant={"outlined"}
          buttonType={"primary"}
          text={"Cancel"}
          onClick={handleOnClickCancel}
        />

        <Button
          variant={"outlined"}
          buttonType={"warning"}
          text={"Remove"}
          onClick={handleOnClickRemove}
          sx={{ marginLeft: "var(--space-2)" }}
        />
      </div>
    </Modal>
  );
};

export default RemoveModal;
