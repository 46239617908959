import React from "react";

import InfoIcon from "@mui/icons-material/Info";

import styles from "./CSVWarning.module.css";

interface Props {
  text: string;
}

const CSVWarning = ({ text }: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      <InfoIcon className={styles.icon} />

      <div className={styles.text}>{text}</div>
    </div>
  );
};

export default CSVWarning;
