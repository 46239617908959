import React, { useMemo } from "react";

import {
  AnyPortfolioV2,
  BreakdownItem,
  type PortfolioAssetCategories,
  PortfolioAssetClassBreakdown,
  type PortfolioAssetClasses,
} from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";

import PortfolioTable from "../PortfolioTable/PortfolioTable";
import { getSecuritiesLabel } from "../utils/getSecuritiesLabel";

type PortfolioAssetCategory = typeof PortfolioAssetCategories[number];
type PortfolioAssetClass = typeof PortfolioAssetClasses[number];

interface Props {
  showDirectIndexedFilter: boolean;
  portfolio: AnyPortfolioV2;
  category: PortfolioAssetCategory;
  onBack: () => void;
  assetClasses: PortfolioAssetClassBreakdown[];
  setAsset: (
    assetClass: PortfolioAssetClass,
    breakdownItem: BreakdownItem
  ) => void;
  headerStyle?: React.CSSProperties;
}

const AssetCategoryView = ({
  showDirectIndexedFilter,
  portfolio,
  category,
  onBack,
  assetClasses,
  setAsset,
  headerStyle,
}: Props): JSX.Element => {
  const title = useMemo(() => {
    const securitiesInAssetClasses = assetClasses.flatMap(
      (acObj) => Object.values(acObj)[0].securities
    );

    const secondaryDescription = getSecuritiesLabel(
      securitiesInAssetClasses.length
    );

    return {
      name: category,
      description: category,
      secondaryDescription,
    };
  }, [category, assetClasses]);

  const rows = useMemo(
    () =>
      assetClasses.map((assetClassObj) => {
        const [assetClass, data] = Object.entries(assetClassObj)[0];
        return {
          key: assetClass,
          description: assetClass,
          allocation: data.percent,
          onClick: () => setAsset(assetClass as PortfolioAssetClass, data),
        };
      }),
    [assetClasses, setAsset]
  );

  return (
    <PortfolioTable
      showDirectIndexedFilter={showDirectIndexedFilter}
      portfolio={portfolio}
      title={title}
      rows={rows}
      onBack={onBack}
      headerStyle={headerStyle}
    />
  );
};

export default AssetCategoryView;
