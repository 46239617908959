import React from "react";

import { Box, Typography } from "@mui/material";

import { Opportunity } from "@fartherfinance/frontend/api/Opportunities/Types";

import { getOpportunityClientName } from "@src/multiCustodian/pages/Advisor/Opportunities/utils";

import OpportunityMetadata from "./OpportunityMetadata/OpportunityMetadata";

import styles from "./Opportunity.module.css";

interface OpportunityClientProps {
  clientDetails: Opportunity["clientDetails"];
}

export default function OpportunityClient({
  clientDetails,
}: OpportunityClientProps): JSX.Element {
  const { email, advisorName } = clientDetails;

  return (
    <Box>
      <Typography className={styles.title}>Client</Typography>
      <Box className={styles.container}>
        <OpportunityMetadata
          label="Name"
          value={getOpportunityClientName(clientDetails)}
        />
        <OpportunityMetadata label="Email" value={email} />
        <OpportunityMetadata label="Advisor" value={advisorName} />
      </Box>
    </Box>
  );
}
