import React, { useEffect, useMemo, useRef, useState } from "react";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Stack, Typography } from "@mui/material";

import {
  DetailedProposal,
  ProposedTrades,
} from "@fartherfinance/frontend/api/Rebalance/Types";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import AlertInline from "@src/sharedComponents/AlertInline/AlertInline";
import { proposalExceptionChecker } from "@src/yellowstone/modules/trades";

import { useStyles } from "./ProposalAlerts.styles";

interface ProposalAlertsProps {
  proposal: DetailedProposal | undefined;
  proposedTrades: ProposedTrades | undefined;
}

export const ProposalAlerts: React.FC<ProposalAlertsProps> = ({
  proposal,
  proposedTrades,
}) => {
  const { classes } = useStyles();
  const [isSeeMoreDisabled, setIsSeeMoreDisabled] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const targetRef = useRef<HTMLDivElement | null>(null);

  const accounts = proposal?.tradingGroup.accounts ?? [];
  const trades = proposedTrades?.trades ?? [];

  const mutualFundException =
    proposalExceptionChecker.mutualFundException.isExceptionRaised(accounts) &&
    proposalExceptionChecker.mutualFundException.message;

  // prettier-ignore
  const targetPortfolioSecurityException =
    proposalExceptionChecker.targetPortfolioSecurityException.isExceptionRaised(trades) &&
    proposalExceptionChecker.targetPortfolioSecurityException.message;

  // prettier-ignore
  const tradingEnabledException = proposal
    ? proposalExceptionChecker.tradingEnabledException.isExceptionRaised(proposal.tradingGroup) &&
      proposalExceptionChecker.tradingEnabledException.message
    : false;

  // prettier-ignore
  const accountBalanceException =
    proposalExceptionChecker.accountBalanceException.isExceptionRaised(accounts) &&
    proposalExceptionChecker.accountBalanceException.message(accounts);

  // prettier-ignore
  const accountCashException =
    proposalExceptionChecker.accountCashException.isExceptionRaised(accounts) &&
    proposalExceptionChecker.accountCashException.message(accounts);

  // prettier-ignore
  const accountMissingTickerException = proposal
    ? proposalExceptionChecker.accountMissingTickerException.isExceptionRaised(accounts) &&
      proposalExceptionChecker.accountMissingTickerException.message
    : false;

  // prettier-ignore
  const tradingGroupVersionException = proposal
    ? proposalExceptionChecker.tradingGroupVersionException.isExceptionRaised(proposal.tradingGroup) &&
      proposalExceptionChecker.tradingGroupVersionException.message
    : false;

  const alerts = [
    tradingEnabledException,
    tradingGroupVersionException,
    accountBalanceException,
    accountCashException,
    accountMissingTickerException,
    targetPortfolioSecurityException,
    mutualFundException,
  ].filter((exception): exception is string => typeof exception === "string");

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        (entries) => {
          const [entry] = entries;

          if (entry.isIntersecting) {
            setIsSeeMoreDisabled(true);
          } else {
            setIsSeeMoreDisabled(false);
          }
        },
        {
          root: containerRef.current,
          rootMargin: "0px",
          threshold: 0.8,
        }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [containerRef.current]
  );

  useEffect(() => {
    const targetElement = targetRef.current;

    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement);
      }
    };
  }, [observer, targetRef]);

  return (
    <Box className={classes.container}>
      {alerts.length === 0 ? (
        <>
          <Typography className={classes.alertsCount}>No Alerts</Typography>
          <Box className={classes.noAlertsBox}>
            <Typography className={classes.noAlertsText}>
              No alerts associated with this proposal.
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Stack direction="row" justifyContent="space-between">
            <Typography className={classes.alertsCount}>
              {alerts.length} Alerts
            </Typography>
            {alerts.length > 4 && (
              <Box className={classes.seeMoreContainer}>
                <Typography
                  className={toClassName(classes.seeMoreText, {
                    [classes.seeMoreTextDisabled]: isSeeMoreDisabled,
                  })}
                >
                  Scroll to see more
                </Typography>
                <ArrowForwardIcon
                  className={toClassName(classes.seeMoreIcon, {
                    [classes.seeMoreIconDisabled]: isSeeMoreDisabled,
                  })}
                />
              </Box>
            )}
          </Stack>
          <Stack
            ref={containerRef}
            className={classes.alertsContainer}
            direction="row"
          >
            {alerts.map((alert, index) => (
              <AlertInline
                key={index}
                ref={index === alerts.length - 1 ? targetRef : null}
                className={classes.alert}
                serverity="warning"
                message={alert}
              />
            ))}
          </Stack>
        </>
      )}
    </Box>
  );
};
