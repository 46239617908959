import React, { useEffect } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useHistory, useParams } from "react-router-dom";

import { ClientId, FartherAccountId } from "@fartherfinance/frontend/api/Types";

import Accounts from "../Accounts";
import { AccountsPath } from "@src/config/routing/RouterPaths";
import AccountsDrawer from "@src/multiCustodian/components/Client/Accounts/AccountsDrawer";
import AccountsDrawerV4 from "@src/multiCustodian/components/Client/Accounts/AccountsDrawerV4";
import AccountProjectionsContent from "@src/multiCustodian/components/Client/Accounts/Projections";
import AccountProjectionsContentV4 from "@src/multiCustodian/components/Client/Accounts/ProjectionsV4";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { trackEvent } from "@src/multiCustodian/services/tracking";

export default function AccountProjections(): JSX.Element {
  const { clientId, accountId } = useParams<{
    clientId: ClientId;
    accountId: FartherAccountId;
  }>();

  const statusNotification = useStatusNotification();

  const history = useHistory();

  const { enableCustomPerformanceGroupsV2, enableProjectionsTab } = useFlags();

  useEffect(() => {
    if (!enableProjectionsTab) {
      statusNotification("Projections have been disabled", "Error");

      history.push(
        `/Client/${clientId}/${AccountsPath}/Managed/${accountId}/Summary`
      );
    }

    trackEvent({
      name: "Client Open Accounts Details Projections",
    });
  }, [history, clientId, accountId, enableProjectionsTab, statusNotification]);

  return (
    <Accounts>
      {enableCustomPerformanceGroupsV2 ? (
        <AccountsDrawerV4 curTab="Projections">
          <AccountProjectionsContentV4
            clientId={clientId}
            accountId={accountId}
          />
        </AccountsDrawerV4>
      ) : (
        <AccountsDrawer curTab="Projections">
          <AccountProjectionsContent
            clientId={clientId}
            accountId={accountId}
          />
        </AccountsDrawer>
      )}
    </Accounts>
  );
}
