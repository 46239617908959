import React from "react";

import { useSelector } from "react-redux";

import useClientDashboard from "@fartherfinance/frontend/api/Dashboard/hooks/useClientDashboard";

import Avatar from "../Avatar/Avatar";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import { State } from "@src/store";

import styles from "./ProfilePhotoAdvisor.module.css";

interface ProfilePhotoAdvisorProps {
  containerClassName?: string;
  avatarClassName?: string;
}

export default function ProfilePhotoAdvisor(
  props: ProfilePhotoAdvisorProps
): JSX.Element {
  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));

  const auth = useRequestAuth();

  const dashboard = useClientDashboard(clientId, auth);

  const picUrl: string | null = dashboard.data?.advisor.headshotPath ?? null;

  const advisorInitials = dashboard.data
    ? `${dashboard.data.advisor.name.first[0]}${dashboard.data.advisor.name.last[0]}`
    : "";

  return (
    <div className={toClassName(styles.profilePhoto, props.containerClassName)}>
      {picUrl !== null ? (
        <img
          src={picUrl}
          className={toClassName(styles.advisorImage, props.avatarClassName)}
        />
      ) : (
        <Avatar className={props.avatarClassName} initials={advisorInitials} />
      )}
    </div>
  );
}
