import { endOfDay, parseISO, startOfDay } from "date-fns";
import head from "lodash/head";

import type { TasksFilterQueryParams } from "@fartherfinance/frontend/api/Tasks/requests/getTasks";
import {
  AssigneeType,
  TaskStatus,
} from "@fartherfinance/frontend/api/Tasks/Types";
import { Custodian } from "@fartherfinance/frontend/api/Types";

import {
  mapLabelToTaskPriority,
  mapLabelToTaskStatus,
  mapLabelToTaskType,
  mapTaskPriorityToLabel,
  mapTaskStatusToLabel,
  mapTaskTypeToLabel,
} from "../TasksTable/TasksTable.utils";
import type { EnhancedAutocompleteOption } from "@src/multiCustodian/pages/Advisor/common";

import type { FormData } from "./TasksFilterForm";
import type { TaskAssigneeAutocompleteOption } from "../../common";

const toDate = (value?: string) => (value ? parseISO(value) : undefined);

export const transformFilterQueryParamsToFormValues = ({
  filters,
  clientsOptions,
  advisorsOptions,
  assigneesOptions,
  generationOptions,
  custodianOptions,
  enableNewTaskStatus,
}: {
  filters: TasksFilterQueryParams;
  clientsOptions: EnhancedAutocompleteOption<string | undefined>[];
  advisorsOptions: EnhancedAutocompleteOption<string | undefined>[];
  assigneesOptions: (
    | EnhancedAutocompleteOption<undefined>
    | TaskAssigneeAutocompleteOption<string>
  )[];
  generationOptions: EnhancedAutocompleteOption<boolean | undefined>[];
  custodianOptions: EnhancedAutocompleteOption<Custodian | undefined>[];
  enableNewTaskStatus: boolean;
}): FormData => {
  const {
    filterByPersonAssignee,
    filterByGroupAssignee,
    filterByAdvisor,
    filterByClient,
    filterByCreatedAfter,
    filterByCreatedBefore,
    filterByUpdatedAfter,
    filterByUpdatedBefore,
    filterByDueDateAfter,
    filterByDueDateBefore,
    filterByTaskStatus,
    filterByTaskType,
    filterByTaskPriority,
    filterByCustodian,
    filterByIsSystemGenerated,
  } = filters;

  const assigneeFilterValue =
    head(filterByPersonAssignee) ?? head(filterByGroupAssignee);

  return {
    filterByAssignee: assigneesOptions.find(
      (assignee) => assignee.value === assigneeFilterValue
    ),
    filterByAdvisor: advisorsOptions.find(
      (advisor) => advisor.value === head(filterByAdvisor)
    ),
    filterByClient: clientsOptions.find(
      (client) => client.value === head(filterByClient)
    ),
    filterByCreatedAfter: toDate(filterByCreatedAfter),
    filterByCreatedBefore: toDate(filterByCreatedBefore),
    filterByUpdatedAfter: toDate(filterByUpdatedAfter),
    filterByUpdatedBefore: toDate(filterByUpdatedBefore),
    filterByDueDateAfter: toDate(filterByDueDateAfter),
    filterByDueDateBefore: toDate(filterByDueDateBefore),
    filterByTaskStatus: filterByTaskStatus
      ?.filter((taskStatus) =>
        enableNewTaskStatus
          ? taskStatus !== TaskStatus.enum.AWAITING_RESPONSE
          : !taskStatus.startsWith("WAITING_ON")
      )
      .map((taskStatus) =>
        mapTaskStatusToLabel(taskStatus, enableNewTaskStatus)
      ),
    filterByTaskType: filterByTaskType?.map(mapTaskTypeToLabel),
    filterByTaskPriority: filterByTaskPriority?.map(mapTaskPriorityToLabel),
    filterByCustodian: custodianOptions.find(
      (custodian) => custodian.value === head(filterByCustodian)
    ),
    filterByIsSystemGenerated: generationOptions.find(
      (generation) => generation.value === filterByIsSystemGenerated
    ),
  };
};

export const transformFormValuesToFilterQueryParams = (
  formData: FormData,
  enableNewTaskStatus: boolean
): TasksFilterQueryParams => {
  const {
    filterByAssignee,
    filterByAdvisor,
    filterByClient,
    filterByCreatedAfter,
    filterByCreatedBefore,
    filterByUpdatedAfter,
    filterByUpdatedBefore,
    filterByDueDateAfter,
    filterByDueDateBefore,
    filterByTaskStatus,
    filterByTaskType,
    filterByTaskPriority,
    filterByCustodian,
    filterByIsSystemGenerated,
  } = formData;

  const filterByAssigneeType =
    filterByAssignee && "meta" in filterByAssignee
      ? filterByAssignee.meta.type === AssigneeType.enum.PERSON
        ? {
            filterByPersonAssignee: [filterByAssignee.value],
            filterByGroupAssignee: undefined,
          }
        : {
            filterByGroupAssignee: [filterByAssignee.value],
            filterByPersonAssignee: undefined,
          }
      : {
          filterByPersonAssignee: undefined,
          filterByGroupAssignee: undefined,
        };

  const mappedFilterByTaskStatus =
    filterByTaskStatus && filterByTaskStatus.length
      ? filterByTaskStatus.map((label) =>
          mapLabelToTaskStatus(label, enableNewTaskStatus)
        )
      : undefined;

  const filterByTaskStatuses_NonBeta = mappedFilterByTaskStatus?.includes(
    TaskStatus.enum.AWAITING_RESPONSE
  )
    ? mappedFilterByTaskStatus.concat(
        TaskStatus.enum.WAITING_ON_CLIENT,
        TaskStatus.enum.WAITING_ON_CLIENT_SERVICES,
        TaskStatus.enum.WAITING_ON_ADVISOR,
        TaskStatus.enum.WAITING_ON_CUSTODIAN
      )
    : mappedFilterByTaskStatus;

  const filterByTaskStatuses_Beta = mappedFilterByTaskStatus?.includes(
    TaskStatus.enum.WAITING_ON_CLIENT
  )
    ? mappedFilterByTaskStatus.concat(TaskStatus.enum.AWAITING_RESPONSE)
    : mappedFilterByTaskStatus;

  const filterByTaskStatuses = enableNewTaskStatus
    ? filterByTaskStatuses_Beta
    : filterByTaskStatuses_NonBeta;

  return {
    ...filterByAssigneeType,
    filterByAdvisor:
      filterByAdvisor && filterByAdvisor.value
        ? [filterByAdvisor.value]
        : undefined,
    filterByClient:
      filterByClient && filterByClient.value
        ? [filterByClient.value]
        : undefined,
    filterByCreatedAfter: filterByCreatedAfter
      ? startOfDay(filterByCreatedAfter).toISOString()
      : undefined,
    filterByCreatedBefore: filterByCreatedBefore
      ? endOfDay(filterByCreatedBefore).toISOString()
      : undefined,
    filterByUpdatedAfter: filterByUpdatedAfter
      ? startOfDay(filterByUpdatedAfter).toISOString()
      : undefined,
    filterByUpdatedBefore: filterByUpdatedBefore
      ? endOfDay(filterByUpdatedBefore).toISOString()
      : undefined,
    filterByDueDateAfter: filterByDueDateAfter
      ? endOfDay(filterByDueDateAfter).toISOString()
      : undefined,
    filterByDueDateBefore: filterByDueDateBefore
      ? endOfDay(filterByDueDateBefore).toISOString()
      : undefined,
    filterByTaskStatus: filterByTaskStatuses,
    filterByTaskType:
      filterByTaskType && filterByTaskType.length
        ? filterByTaskType.map(mapLabelToTaskType)
        : undefined,
    filterByTaskPriority:
      filterByTaskPriority && filterByTaskPriority.length
        ? filterByTaskPriority.map(mapLabelToTaskPriority)
        : undefined,
    filterByCustodian:
      filterByCustodian && filterByCustodian.value
        ? [filterByCustodian.value]
        : undefined,

    filterByIsSystemGenerated: filterByIsSystemGenerated?.value,
  };
};
