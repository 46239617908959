import React from "react";

const RaiseCash: React.FC = () => (
  <svg
    width="100%"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9393 13.7126C16.9393 13.7126 17.14 13.7118 17.2399 13.7104C23.5399 13.6186 30.0695 10.6494 30.0695 7H33.3812C33.3812 10.7073 40.4413 13.7126 46.8801 13.7126H55.0912V22.4545H8.72754L8.72791 13.7126H16.9393Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.7262 47.9091H55.0898V25.1818H39.2635C35.5334 25.1818 32.5095 28.3184 32.5095 32.1875H31.9072C31.9072 28.3184 28.8833 25.1818 25.1532 25.1818H8.72621L8.7262 47.9091ZM31.9093 43.3636C34.1686 43.3636 36.0002 41.5321 36.0002 39.2727C36.0002 37.0134 34.1686 35.1818 31.9093 35.1818C29.6499 35.1818 27.8184 37.0134 27.8184 39.2727C27.8184 41.5321 29.6499 43.3636 31.9093 43.3636Z"
      fill="currentColor"
    />
    <path d="M6 51.5455H57.8182V57H6V51.5455Z" fill="currentColor" />
  </svg>
);

export default RaiseCash;
