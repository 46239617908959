import React, { useMemo, useState } from "react";

import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { Grid } from "@mui/material";
import { FormProvider, useForm, useFormState, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";

import useCreateCustomPortfolioV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useCreateCustomPortfolioV2";
import useUpdateCustomPortfolioV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useUpdateCustomPortfolioV2";
import {
  CustomPortfolioCreateUpdateV2Body,
  SecurityWeight,
  SleeveWeight,
} from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";
import {
  AdvisorId,
  ClientId,
  PortfolioId,
  PortfolioTaxType,
  SleeveId,
  Ticker,
} from "@fartherfinance/frontend/api/Types";

import BottomBar from "../../SharedComponents/BottomBar/BottomBar";
import PageTitle from "../../SharedComponents/PageTitle";
import {
  SelectedSecurity,
  SelectedSleeve,
  USDTicker,
} from "../../SharedComponents/SearchSecurities/Types";
import { taxTypeToStatus } from "../../utils/taxType";
import SecuritySearchWithSleeves from "../SearchSecuritiesWithSleeves/SecuritySearchWithSleeves";
import SleevePicker from "../SleevePicker/SleevePicker";
import BackButton from "@src/multiCustodian/components/Client/BackButton";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import FormFieldLabel from "@src/sharedComponents/Forms/Private/FormFieldLabel";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import { weightAdder } from "@src/utils/weightAdder";

import CreateCustomModelFooter from "./CreateCustomModelFooter";
import UpdateCustomModelFooter from "./UpdateCustomModelFooter";

import styles from "./CreateUpdateCustomModelForm.module.css";

const ModelNameField = "ModelName";
const TaxStatusField = "TaxStatus";
const CashField = "Cash";
const SecurityPrefix = "Security-";
const SleevePrefix = "Sleeve-";

type TaxOption = { value: PortfolioTaxType; label: string };

const taxOptions: TaxOption[] = [
  { value: "any", label: "Any" },
  { value: "taxable", label: "Taxable" },
  { value: "tax_exempt", label: "Tax-Advantaged" },
];

type SecurityFieldName = `${typeof SecurityPrefix}${Ticker}`;

type SecurityField = [SecurityFieldName, string];

const isSecurityField = (field: [string, unknown]): field is SecurityField => {
  return field[0].includes(SecurityPrefix) && typeof field[1] === "string";
};

type SleeveFieldName = `${typeof SleevePrefix}${SleeveId}`;

type SleeveField = [SleeveFieldName, string];

const isSleeveField = (field: [string, unknown]): field is SleeveField => {
  return field[0].includes(SleevePrefix) && typeof field[1] === "string";
};

const isValidSecurity = (sec: {
  securityIdentifier: Ticker;
  weight: number | null;
}): sec is SecurityWeight => {
  return "securityIdentifier" in sec && sec.weight !== null;
};

const isValidSleeve = (sleeve: {
  sleeveId: SleeveId;
  weight: number | null;
}): sleeve is SleeveWeight => {
  return "sleeveId" in sleeve && sleeve.weight !== null;
};

interface Form {
  [s: string]: string | TaxOption | undefined; // Needed due to changing "." to "*" in security name
  [ModelNameField]: string | undefined;
  [TaxStatusField]: TaxOption | undefined;
  [CashField]: string;
  [s: SecurityFieldName]: string | undefined;
  [s: SleeveFieldName]: string | undefined;
}

interface Props {
  isUpdate: boolean;
  currentSecurities: SecurityWeight[] | null;
  currentSleeves: SleeveWeight[] | null;
  csvFile: File | null;
  goToCSVUpload: () => void;
  cameFromUpload: boolean; // need this to negate isDirty -> if you just uploaded a valid csv the form wont be dirty and we want the submit button to be clickable right away
  modelName: string | undefined;
  taxType: PortfolioTaxType | undefined;
  portfolioId?: PortfolioId;
  ownerId?: AdvisorId | ClientId | undefined | null;
}

const CreateUpdateCustomModel = (props: Props): JSX.Element => {
  const [mutating, setMutating] = useState(false);

  const [selectedSecurities, setSelectedSecurities] = useState<
    SelectedSecurity[]
  >(
    props.currentSecurities === null
      ? []
      : props.currentSecurities
          .filter((s) => s.securityIdentifier !== USDTicker)
          .map((s) => ({
            // if there is a "." in the ticker, setValue from useForm will evaluate that . as a property and break apart the key/string into an obj
            ticker: s.securityIdentifier.replace(/\./g, "*") as Ticker,
            displayName: null,
          }))
  );

  const [selectedSleeves, setSelectedSleeves] = useState<SelectedSleeve[]>(
    props.currentSleeves === null
      ? []
      : props.currentSleeves.map((s) => ({
          sleeveId: s.sleeveId,
          displayName: null,
        }))
  );

  const history = useHistory();

  const statusNotification = useStatusNotification();

  const auth = useAdvisorRequestAuth();

  const createModel = useCreateCustomPortfolioV2(null, auth);
  const updateModel = useUpdateCustomPortfolioV2(auth);

  const defaultValues: Partial<Form> = useMemo(() => {
    return {
      [ModelNameField]: props.modelName,
      [TaxStatusField]: props.taxType
        ? {
            value: props.taxType,
            label: taxTypeToStatus[props.taxType] as PortfolioTaxType,
          }
        : undefined,
      [CashField]: "0",
      ...(props.currentSecurities !== null &&
        props.currentSecurities.reduce((formValues, sec) => {
          if (sec.securityIdentifier === USDTicker) {
            //CSV may include US Dollars, we use this value for Cash instead of our default
            formValues[CashField] = (sec.weight / 100).toString(); //weights come in as integers from 1 - 10000
          } else {
            // if there is a "." in the ticker, setValue from useForm will evaluate that . as a property and break apart the key/string into an obj
            const securityFieldName =
              `${SecurityPrefix}${sec.securityIdentifier.replace(
                /\./g,
                "*"
              )}` as SecurityFieldName;
            formValues[securityFieldName] = (sec.weight / 100).toString();
          }

          return formValues;
        }, {} as Partial<Form>)),
      ...(props.currentSleeves !== null &&
        props.currentSleeves.reduce((formValues, sleeve) => {
          const sleeveFieldName =
            `${SleevePrefix}${sleeve.sleeveId}` as SleeveFieldName;

          formValues[sleeveFieldName] = (sleeve.weight / 100).toString();

          return formValues;
        }, {} as Partial<Form>)),
    };
  }, [
    props.currentSecurities,
    props.currentSleeves,
    props.modelName,
    props.taxType,
  ]);

  const methods = useForm<Form>({
    mode: "all",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    defaultValues,
  });

  const { control, getValues, trigger, setValue } = methods;

  const { isValid, errors, isDirty } = useFormState({
    control,
  });

  const securityErrors = useMemo(() => {
    return Object.entries(errors).filter(isSecurityField);
  }, [errors]);

  const formValues = useWatch({ control: control });

  const formSecurities = useMemo(
    () => Object.entries(formValues).filter(isSecurityField),
    [formValues]
  );

  const formSleeves = useMemo(
    () => Object.entries(formValues).filter(isSleeveField),
    [formValues]
  );

  const total = useMemo(() => {
    type weightedField = [string, string];

    return weightAdder<weightedField>((s) => s[1])([
      ...formSecurities,
      ...formSleeves,
      [CashField as SecurityFieldName, formValues[CashField] ?? "NaN"],
    ]);
  }, [formSecurities, formSleeves, formValues]);

  const addSecurity = (sec: SelectedSecurity) => {
    const dotCharInTicker = sec.ticker.includes(".");
    // if there is a "." in the ticker, setValue from useForm will evaluate that . as a property and break apart the key/string into an obj
    const sanitizedTicker = dotCharInTicker
      ? (sec.ticker.replace(/\./g, "*") as Ticker)
      : sec.ticker;

    if (selectedSecurities.some((s) => s.ticker === sanitizedTicker)) {
      statusNotification("Security already added", "Success");
      return;
    }

    setSelectedSecurities([
      ...selectedSecurities,
      { ticker: sanitizedTicker, displayName: sec.displayName ?? "" },
    ]);

    setValue(`${SecurityPrefix}${sanitizedTicker}`, "0");

    trigger(formSecurities.map((s) => s[0])); //only trigger security fields
  };

  const addSleeve = (sleeve: SelectedSleeve) => {
    if (selectedSleeves.some((s) => s.sleeveId === sleeve.sleeveId)) {
      statusNotification("Sleeve already added", "Success");
      return;
    }

    setSelectedSleeves([...selectedSleeves, { ...sleeve }]);

    setValue(`${SleevePrefix}${sleeve.sleeveId}`, "0");

    trigger(formSleeves.map((s) => s[0])); //only trigger security fields
  };

  const removeSecurity = (ticker: Ticker) => {
    if (formValues[`${SecurityPrefix}${ticker}`] !== undefined) {
      setValue(`${SecurityPrefix}${ticker}`, undefined);
    } //must reset the value in the form as well

    setSelectedSecurities(
      selectedSecurities.filter((s) => s.ticker !== ticker)
    );

    trigger(formSecurities.map((s) => s[0])); //only trigger security fields
  };

  const removeSleeve = (sleeveId: SleeveId) => {
    if (formValues[`${SleevePrefix}${sleeveId}`] !== undefined) {
      setValue(`${SleevePrefix}${sleeveId}`, undefined);
    } //must reset the value in the form as well

    setSelectedSleeves(selectedSleeves.filter((s) => s.sleeveId !== sleeveId));

    trigger(formSleeves.map((s) => s[0])); //only trigger security fields
  };

  const callCreateModel = async () => {
    if (!isValid) {
      statusNotification("Form is incomplete", "Error");
      return;
    }

    if (total !== 100) {
      statusNotification("Allocation total must be 100%", "Error");
      return;
    }

    if (!isDirty && !props.cameFromUpload) {
      statusNotification("Nothing to submit", "Error");
      return;
    }

    if (mutating) {
      statusNotification("Form is being submitted", "Error");
      return;
    }

    const values = getValues();

    if (
      values[ModelNameField] === undefined ||
      auth === null ||
      values[TaxStatusField] === undefined
    ) {
      return;
    }

    const securities: SecurityWeight[] = selectedSecurities
      .map((sec) => {
        const weight = values[`${SecurityPrefix}${sec.ticker}`];

        const weightParsed =
          weight === undefined ? null : parseFloat(weight ?? "0");

        // if there is a "." in the ticker, setValue from useForm will evaluate that . as a property and break apart the key/string into an obj, so use "*" as replacement for ".", now need to switch back to "."
        return {
          securityIdentifier: sec.ticker.replace(/\*/g, ".") as Ticker,
          weight:
            weightParsed !== null && !isNaN(weightParsed)
              ? Math.round(weightParsed * 100)
              : null,
        };
      })
      .filter(isValidSecurity);

    const sleeves: SleeveWeight[] = selectedSleeves
      .map((sleeve) => {
        const weight = values[`${SleevePrefix}${sleeve.sleeveId}`];

        const weightParsed =
          weight === undefined ? null : parseFloat(weight ?? "0");

        return {
          sleeveId: sleeve.sleeveId,
          weight:
            weightParsed !== null && !isNaN(weightParsed)
              ? Math.round(weightParsed * 100)
              : null,
        };
      })
      .filter(isValidSleeve);

    //Don't send cash if its 0%
    const isCash = parseFloat(values[CashField]) > 0;
    if (isCash) {
      securities.push({
        securityIdentifier: USDTicker,
        weight: Math.round(parseFloat(values[CashField]) * 100),
      });
    }

    const body: CustomPortfolioCreateUpdateV2Body = {
      displayName: values[ModelNameField],
      ownerId: auth.advisorId,
      description: "",
      directIndexing: false,
      taxType: values[TaxStatusField].value,
      positions: {
        securities: securities,
        sleeves: sleeves,
      },
    };

    try {
      setMutating(true);

      const res = await createModel(body);

      statusNotification("Model created", "Success");

      const newCustomModelId = res.message;
      if (newCustomModelId) {
        setMutating(false);
        history.push({
          pathname: `/Advisor/Investments/ModelMarketplace/${newCustomModelId}/ModelDetails`,
          state: history.location.state,
        });
      } else {
        setMutating(false);
        history.push({
          pathname: "/Advisor/Investments/ModelMarketplace",
          state: history.location.state,
        });
      }
    } catch {
      statusNotification("Failed to create model", "Error");
      setMutating(false);
    }
  };

  const callUpdateModel = async () => {
    if (!props.portfolioId) {
      statusNotification("No portfolio present", "Error");
      return;
    }

    if (!props.ownerId) {
      statusNotification("Portfolio does not have an owner", "Error");
      return;
    }

    if (!isValid) {
      statusNotification("Form is incomplete", "Error");
      return;
    }

    if (total !== 100) {
      statusNotification("Allocation total must be 100%", "Error");
      return;
    }

    if (!isDirty && !props.cameFromUpload) {
      statusNotification("Nothing to submit", "Error");
      return;
    }

    if (mutating) {
      statusNotification("Form is being submitted", "Error");
      return;
    }

    const values = getValues();

    if (
      values[ModelNameField] === undefined ||
      auth === null ||
      values[TaxStatusField] === undefined
    ) {
      return;
    }

    const securities: SecurityWeight[] = selectedSecurities
      .map((sec) => {
        const weight = values[`${SecurityPrefix}${sec.ticker}`];

        const weightParsed =
          weight === undefined ? null : parseFloat(weight ?? "0");

        // if there is a "." in the ticker, setValue from useForm will evaluate that . as a property and break apart the key/string into an obj, so use "*" as replacement for ".", now need to switch back to "."
        return {
          securityIdentifier: sec.ticker.replace(/\*/g, ".") as Ticker,
          weight:
            weightParsed !== null && !isNaN(weightParsed)
              ? Math.round(weightParsed * 100)
              : null,
        };
      })
      .filter(isValidSecurity);

    const sleeves: SleeveWeight[] = selectedSleeves
      .map((sleeve) => {
        const weight = values[`${SleevePrefix}${sleeve.sleeveId}`];

        const weightParsed =
          weight === undefined ? null : parseFloat(weight ?? "0");

        return {
          sleeveId: sleeve.sleeveId,
          weight:
            weightParsed !== null && !isNaN(weightParsed)
              ? Math.round(weightParsed * 100)
              : null,
        };
      })
      .filter(isValidSleeve);

    //Don't send cash if its 0%
    const isCash = parseFloat(values[CashField]) > 0;
    if (isCash) {
      securities.push({
        securityIdentifier: USDTicker,
        weight: Math.round(parseFloat(values[CashField]) * 100),
      });
    }

    const body: CustomPortfolioCreateUpdateV2Body = {
      displayName: values[ModelNameField],
      taxType: values[TaxStatusField].value,
      ownerId: props.ownerId,
      description: "",
      directIndexing: false,
      positions: {
        securities: securities,
        sleeves: sleeves,
      },
    };

    try {
      setMutating(true);

      await updateModel({ portfolioId: props.portfolioId, body: body });

      statusNotification("Model updated", "Success");
      setMutating(false);
      history.push({
        pathname: `/Advisor/Investments/ModelMarketplace/${props.portfolioId}/ModelDetails`,
        state: history.location.state,
      });
    } catch {
      statusNotification("Failed to update model", "Error");
      setMutating(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.centeringContainer}>
        {mutating && (
          <div className={styles.loading}>
            <LogoLoadingStill onTop />
          </div>
        )}

        <div className={styles.backBtnContainer}>
          <BackButton
            onClick={() => {
              if (props.isUpdate && props.portfolioId) {
                history.push({
                  pathname: `/Advisor/Investments/ModelMarketplace/${props.portfolioId}/ModelDetails`,
                  state: history.location.state,
                });
              } else {
                history.push({
                  pathname: "/Advisor/Investments/ModelMarketplace",
                  state: history.location.state,
                });
              }
            }}
          />
        </div>

        <div className={styles.bodyContainer}>
          <PageTitle>
            {props.isUpdate
              ? "Update Custom Model Portfolio"
              : "Create a Custom Model Portfolio"}
          </PageTitle>

          <Spacer verticalSpacing="32px" />

          <FormProvider {...methods}>
            <Grid container direction="row" spacing="16px">
              <Grid item xs={7}>
                <FormTextField
                  name={ModelNameField}
                  label="Model Name"
                  required="Must not be empty"
                />
              </Grid>

              <Grid item xs={5}>
                <FormDropdownField
                  name={TaxStatusField}
                  label="Tax Status"
                  values={taxOptions}
                  disableSearch
                  required="Must not be empty"
                />
              </Grid>
            </Grid>

            <Spacer verticalSpacing="24px" />

            <FormFieldLabel
              label={<div className={styles.label}>Securities & Weights</div>}
            />

            <Spacer verticalSpacing="16px" />

            <SecuritySearchWithSleeves
              selectedSecurities={selectedSecurities}
              selectedSleeves={selectedSleeves}
              addSecurity={addSecurity}
              removeSecurity={removeSecurity}
              removeSleeve={removeSleeve}
              total={total}
            />
          </FormProvider>

          {securityErrors.length > 0 || total !== 100 ? (
            <div className={styles.errorBlock}>
              <WarningAmberRoundedIcon className={styles.warningIcon} />

              <span className={styles.warningText}>
                {securityErrors.length > 0
                  ? securityErrors[0][1]?.message ?? "Unknown error"
                  : "Sum must add up to 100%"}
              </span>
            </div>
          ) : null}

          <Spacer verticalSpacing="32px" />

          <SleevePicker
            selectedSleeves={selectedSleeves}
            addSleeve={addSleeve}
            removeSleeve={removeSleeve}
          />
        </div>

        <BottomBar>
          {props.isUpdate ? (
            <UpdateCustomModelFooter
              csvFile={props.csvFile}
              goToCSVUpload={props.goToCSVUpload}
              portfolioId={props.portfolioId}
              callUpdateModel={callUpdateModel}
              cameFromUpload={props.cameFromUpload}
              mutating={mutating}
              isValid={isValid}
              isDirty={isDirty}
              total={total}
            />
          ) : (
            <CreateCustomModelFooter
              csvFile={props.csvFile}
              goToCSVUpload={props.goToCSVUpload}
              callCreateModel={callCreateModel}
              cameFromUpload={props.cameFromUpload}
              mutating={mutating}
              isValid={isValid}
              isDirty={isDirty}
              total={total}
            />
          )}
        </BottomBar>
      </div>
    </div>
  );
};

export default CreateUpdateCustomModel;
