import { Custodian } from "@fartherfinance/frontend/api/Types";

/**
 *  When creating a manual account, or setting a plaid account as the funding account,
 *A client who uses one of these custodians must attach a verification document
 */
const custodiansRequiringVerificationDocument: Custodian[] = [
  "Fidelity",
  "Schwab",
];

export default custodiansRequiringVerificationDocument;
