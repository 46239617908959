import React, { PropsWithChildren } from "react";

import { useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import { SharingPath } from "@src/config/routing/RouterPaths";
import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import SharingV2 from "@src/multiCustodian/components/Sharing/Sharing";
import LinkButton from "@src/sharedComponents/LinkButton/LinkButton";
import PageHero from "@src/sharedComponents/PageHero/PageHero";
import TitleBlock from "@src/sharedComponents/PageHero/TitleBlock";

const Sharing = (props: PropsWithChildren<unknown>): JSX.Element => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();
  return (
    <BaseLayout>
      <PageHero
        start={<TitleBlock title="Sharing" />}
        end={
          <LinkButton
            text="Add Collaborators"
            to={`/Client/${clientId}/${SharingPath}/AddCollaborator`}
            variant="contained"
            buttonType="primary"
          />
        }
      />

      {props.children}

      <SharingV2 />
    </BaseLayout>
  );
};

export default Sharing;
