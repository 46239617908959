import React, { useMemo } from "react";

import { range } from "lodash";

import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import FullDataTable from "@src/sharedComponents/Table/FullDataTable";
import { Cell, Row } from "@src/sharedComponents/Table/Types";

import ColGroup from "./ColGroup";
import Header from "./Header";
import { rowsPerPage, TableKey, tableKeys } from "./SMATable";

const SMATableLoading = (): JSX.Element => {
  const smaOptions: Row<TableKey, Cell>[] = useMemo(() => {
    return range(rowsPerPage).map((_sma, idx) => {
      return {
        key: `${idx}`,
        "Investment Manager": { value: <Skeleton /> },
        "Minimum Investment": { value: <Skeleton /> },
        Custodian: { value: <Skeleton /> },
        Strategy: { value: <Skeleton /> },
      };
    });
  }, []);

  return (
    <>
      <Header tableSearch={""} setTableSearch={() => undefined} />

      <FullDataTable
        isLoading={true}
        columns={tableKeys}
        rows={smaOptions}
        defaultRowsPerPage={rowsPerPage}
        defaultSortColumn={undefined}
        disableColumnSorting={tableKeys}
        emptyCell={"Placeholder"} // This is the highest cell we will have
      >
        <ColGroup />
      </FullDataTable>
    </>
  );
};

export default SMATableLoading;
