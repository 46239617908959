import React from "react";

import { TabPanel } from "@mui/lab";

import { useStyles } from "./DashboardTabPanel.styles";

interface DashboardTabPanelProps {
  children: React.ReactNode;
  value: string;
}

export const DashboardTabPanel: React.FC<DashboardTabPanelProps> = ({
  children,
  value,
}) => {
  const { classes } = useStyles();

  return (
    <TabPanel className={classes.root} value={value}>
      {children}
    </TabPanel>
  );
};
