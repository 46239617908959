import { Feed } from "@fartherfinance/frontend/api/Notifications/Types";

type NotificationRedirectionURL = {
  url: string;
  isExternal: boolean;
};

export const resolveRedirectionUrl = (
  feed: Feed
): NotificationRedirectionURL | null => {
  const { url } = feed.data;
  const { source, attributes } = feed.metadata;

  if (url) {
    const isExternal = url.startsWith("http://") || url.startsWith("https://");

    return { url, isExternal };
  }

  if (source === "task-server") {
    const taskId = attributes?.find((attribute) => attribute.key === "taskId");

    return taskId
      ? { url: `/Advisor/Tasks/${taskId.value}`, isExternal: false }
      : null;
  }

  return null;
};
