import { AssetClassCategory } from "@fartherfinance/frontend/api/PerformanceGroups/requests/getHoldings";

import unknownCategory from "./unknownCategory";

const narrowAssetCategory = (
  category: AssetClassCategory
): AssetClassCategory => {
  return unknownCategory(category) || category === "Unidentified"
    ? ("Other" as AssetClassCategory)
    : category;
};

export default narrowAssetCategory;
