import * as Sentry from "@sentry/react";
import ms from "ms";

import { queryClient } from "../../reactQuery";
import store from "../../store";
import { resetTracking } from "../services/tracking";
import {
  IsAdminSessionKey,
  LoggedInStateSessionKey,
} from "@src/constants/localStorageKeys";
import { LoginType } from "@src/magic";

import isLocalStorageAvailable from "./isLocalStorageAvailable";

export const advisorLogoutBroadcastChannel = new BroadcastChannel(
  "advisor.logout"
);

export const clientLogoutBroadcastChannel = new BroadcastChannel(
  "client.logout"
);

export async function clearUserData(loginType: LoginType) {
  const dispatch = store.dispatch;

  if (loginType === "Advisor") {
    // Advisors need a some extra bits set
    dispatch({
      type: "SET_IS_LOGGED_IN_AS_ADVISOR_STATE",
      payload: {
        isLoggedIn: false,
        farther_jwt: null,
        isAdvisor: null,
        cur_user: null,
        cockroach_advisor_id: null,
      },
    });

    dispatch({ type: "SET_ISADMIN", payload: { isAdmin: false } });
  }

  // All users (clients and advisors needs credentials reset)
  dispatch({ type: "CLEAR_FARTHER_JWT", payload: {} });

  // Clear all stored data
  dispatch({ type: "END_IMPERSONATION" });

  let queryPromise: Promise<unknown> = Promise.resolve();

  try {
    // Clear react-query cache
    const cancel = queryClient.cancelQueries();
    const clear = queryClient.clear();

    queryPromise = Promise.allSettled([cancel, clear]);
  } catch (e) {
    console.error(e);
  }

  try {
    sessionStorage.removeItem(LoggedInStateSessionKey);
    sessionStorage.removeItem(IsAdminSessionKey);
    sessionStorage.clear();

    console.log(
      "Removed session storage keys",
      LoggedInStateSessionKey,
      IsAdminSessionKey
    );
  } catch (e) {
    console.error(e);
  }

  try {
    Array.from({ length: localStorage.length }, (_, idx) => idx).forEach(
      (_, idx) => {
        const key = isLocalStorageAvailable() ? localStorage.key(idx) : null;
        if (key && key.startsWith("advisor.info")) {
          console.log("Removing local storage key", key);
          localStorage.removeItem(key);
        }
      }
    );
  } catch (e) {
    console.error(e);
  }

  try {
    resetTracking();
  } catch (e) {
    console.error(e);
  }

  try {
    Sentry.addBreadcrumb({ message: "Logged Out" });
  } catch (e) {
    console.error(e);
  }

  try {
    const d = new Date();

    d.setTime(d.getTime() + ms("1s"));

    ["farther_jwt", "farther_id_user"].forEach((key) => {
      document.cookie = `${key}=;expires=${d.toUTCString()};path=/;samesite=strict`;
    });
  } catch (e) {
    console.error(e);
  }

  return Promise.allSettled([queryPromise])
    .then((promises) => {
      console.log("all promises", promises);
      return promises;
    })
    .then(() => {
      if (loginType === "Advisor") {
        console.log("Broadcast logout to all advisor tabs");
        return advisorLogoutBroadcastChannel.postMessage("loggedOut");
      } else {
        console.log("Broadcast logout to all client tabs");
        return clientLogoutBroadcastChannel.postMessage("loggedOut");
      }
    });
}
