import React from "react";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Typography from "@mui/material/Typography";

import styles from "./ViewAllButton.module.css";

interface ViewAllButtonProps {
  onClick: () => void;
}

const ViewAllButton: React.FC<ViewAllButtonProps> = ({ onClick }) => {
  return (
    <div className={styles.viewAllContainer} onClick={onClick}>
      <div className={styles.viewAll}>
        <Typography className={styles.viewAllText}>View All</Typography>
        <ArrowForwardIcon className={styles.viewAllIcon} />
      </div>
    </div>
  );
};

export default ViewAllButton;
