import React from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import AggressionSlider from "@src/multiCustodian/components/Client/Portfolio/AgressionSlider/AggressionSlider";

import styles from "./RiskLevel.module.css";

interface Props {
  goBack: () => void;
}

const RiskLevelLoading = (props: Props): JSX.Element => {
  return (
    <div className={styles.riskLevel}>
      <div className={styles.clientLayoutWidth}>
        <div className={styles.header}>
          <div className={styles.back} onClick={props.goBack}>
            <ArrowBackIcon style={{ width: "10px" }} /> Back
          </div>
        </div>
        <AggressionSlider
          steps={[]}
          leftText="CONSERVATIVE"
          rightText="AGGRESSIVE"
          onChange={() => undefined}
          currentStep={0}
        />
      </div>
    </div>
  );
};

export default RiskLevelLoading;
