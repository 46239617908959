import React from "react";

import { useHistory, useParams } from "react-router-dom";

import useAdvisorClients from "@fartherfinance/frontend/api/Entity/hooks/useAdvisorClients";
import { ClientId, TaxBudgetId } from "@fartherfinance/frontend/api/Types";

import Drawer from "@src/multiCustodian/components/Drawer/Drawer";
import DetailTabsContainer from "@src/multiCustodian/components/TaxBudgets/DetailTabs/DetailTabsContainer";
import useTaxBudgetsForClient from "@src/multiCustodian/components/TaxBudgets/Hooks/useTaxBudgetsForClient";
import YearDropdown from "@src/multiCustodian/components/TaxBudgets/YearDropdown/YearDropdown";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";

import TaxBudget from "./TaxBudget";

const TaxBudgetDetails: React.FC = () => {
  const { clientId, taxBudgetId } = useParams<{
    clientId: ClientId;
    taxBudgetId: TaxBudgetId;
  }>();

  const history = useHistory();

  const { data } = useTaxBudgetsForClient(clientId);

  const auth = useAdvisorRequestAuth();

  const advisorClients = useAdvisorClients(undefined, auth);

  const budget = data?.budgets.find((b) => b?.budgetId === taxBudgetId);

  const handleOnClose = () => {
    history.push(`/Advisor/Clients/${clientId}/TaxBudgets`);
  };

  const currentClient =
    advisorClients.data?.clients.find(
      (client) => client.clientId === clientId
    ) ?? null;

  const clientName = `${currentClient?.name.first || ""} ${
    currentClient?.name.last || ""
  }`;

  return (
    <TaxBudget>
      <Drawer
        header={
          <YearDropdown
            budgets={data?.budgets || []}
            pathPrefix="/Advisor/Clients"
          />
        }
        transitionDuration={0}
        isDrawerOpen
        onClose={handleOnClose}
      >
        <DetailTabsContainer
          budget={budget}
          clientName={clientName}
          detailsPath="/Advisor/Clients/:clientId/TaxBudgets/:taxBudgetId/BudgetDetails"
          usagePath="/Advisor/Clients/:clientId/TaxBudgets/:taxBudgetId/Usage"
        />
      </Drawer>
    </TaxBudget>
  );
};

export default TaxBudgetDetails;
