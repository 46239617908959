import React, { useCallback, useEffect, useMemo, useState } from "react";

import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { useQueryClient } from "@tanstack/react-query";
import { debounce, orderBy } from "lodash";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import searchSecurities, {
  url as searchSecuritiesURL,
} from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/searchSecurities";
import { Ticker } from "@fartherfinance/frontend/api/Types";

import FileDropUploadModal from "@src/multiCustodian/components/Client/Portfolio/CustomPortfolioFileUploadModal/CustomPortfolioFileUploadModal";
import { Security } from "@src/multiCustodian/components/Client/Portfolio/SecuritiesAllocationTable/SecuritiesAllocationTable";
import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import DropdownComponent from "@src/sharedComponents/SAODropdown/Dropdown";
import { State } from "@src/store";

import BackAndExitButtonWrapper from "./BackAndExitButtonWrapper";

import styles from "./Securities.module.css";

type RemoteSearch = Awaited<ReturnType<typeof searchSecurities>>[number];

interface Search extends RemoteSearch {
  label: string;
}

const CustomPortfolioAddSecurities = (): JSX.Element => {
  const [tab, setTab] = useState<"Search" | "Import">("Search");

  const history = useHistory();

  const { search: urlSearchParams } = useLocation<{
    from: Location | undefined;
  }>();

  const qs = useMemo(
    () => new URLSearchParams(urlSearchParams),
    [urlSearchParams]
  );

  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));

  const modelTaxStatus = useMemo(() => qs.get("status"), [qs]);

  useEffect(() => {
    if (
      modelTaxStatus !== "Any" &&
      modelTaxStatus !== "Taxable" &&
      modelTaxStatus !== "Tax-Advantaged"
    ) {
      if (clientId !== null) {
        history.push(
          `/Client/${clientId}/Investments/Portfolios/Create/Custom`
        );
      }
    }
  }, [clientId, history, modelTaxStatus]);

  const notify = useStatusNotification();

  const backButtonOnClick = () => {
    if (clientId === null) {
      notify("Bad ClientId", "Error");
      return;
    }
    history.push({
      pathname: `/Client/${clientId}/Investments/Portfolios/Create/Custom`,
      search: qs.toString(),
    });
  };

  return (
    <BaseLayout showSideNav={false}>
      <BackAndExitButtonWrapper backButtonOnClick={backButtonOnClick}>
        <div className={styles.contentContainer}>
          <div className={styles.titleDiv}>
            <p className={styles.title}>Select Securities</p>
            <p className={styles.modelTypePill}>{modelTaxStatus}</p>
          </div>

          <p className={styles.subTitleText}>Add the securities you want.</p>

          <div className={styles.tabsDiv}>
            <p
              className={tab === "Search" ? styles.tabSelected : styles.tab}
              onClick={() => setTab("Search")}
            >
              Search
            </p>
            {false && (
              <p
                className={tab === "Import" ? styles.tabSelected : styles.tab}
                onClick={() => setTab("Import")}
              >
                Import
              </p>
            )}
          </div>

          {tab === "Search" && <Search />}

          {tab === "Import" && <ImportFile />}
        </div>
      </BackAndExitButtonWrapper>
    </BaseLayout>
  );
};

export default CustomPortfolioAddSecurities;

const sortTickers = (tickers: Security[]): Security[] =>
  orderBy(tickers, (sec) => sec.ticker, "asc");

const Search = (): JSX.Element => {
  const { search: urlSearchParams } = useLocation<{
    from: Location | undefined;
  }>();

  const qs = useMemo(
    () => new URLSearchParams(urlSearchParams),
    [urlSearchParams]
  );

  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));

  const [searchInput, setSearchInput] = useState<string | null>(null);

  const [searchResults, setSearchResults] = useState<Search[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const statusNotification = useStatusNotification();

  const defaultSecurities: Security[] = useMemo(
    () => sortTickers(JSON.parse(qs.get("securities") ?? "[]")),
    [qs]
  );
  const [selectedSecurities, setSelectedSecurities] =
    useState<Security[]>(defaultSecurities);

  const [search, _setSearch] = useState<Search | null>(null);

  const auth = useRequestAuth();

  const history = useHistory();

  const queryClient = useQueryClient();

  const removeSelectedSecurity = (ticker: Ticker) => {
    const newSelectedSecurities = selectedSecurities.filter(
      (sec) => sec.ticker !== ticker
    );
    setSelectedSecurities(newSelectedSecurities);
  };

  const addSelectedSecurity = (newSec: Search) => {
    if (selectedSecurities.some((sec) => newSec.ticker === sec.ticker)) {
      statusNotification("Security already added", "Success");
      setSearchInput("");
      return;
    }

    const security: Security = {
      displayName: newSec.displayName,
      ticker: newSec.ticker,
    };

    const newSelectedSecurities = sortTickers([
      ...selectedSecurities,
      security,
    ]);

    setSelectedSecurities(newSelectedSecurities);
    setSearchInput("");
  };

  const runSearch = useMemo(
    () =>
      debounce(
        async (searchTerm: string) => {
          if (!auth) {
            return;
          }

          if (clientId === null) {
            statusNotification("Could not retrieve user id", "Error");
            return;
          }

          setIsLoading(true);
          try {
            const results = await queryClient.fetchQuery({
              queryKey: [searchSecuritiesURL(searchTerm)],
              queryFn: () => searchSecurities(searchTerm, auth),
            });

            const transformedResults = results.map((sec) => ({
              ...sec,
              label: `${sec.ticker} - ${sec.displayName}`,
            }));

            setSearchResults(
              orderBy(transformedResults, (res) => res.ticker, "asc")
            );
          } catch (_e) {
            statusNotification("Could not retrieve securities", "Error");
          } finally {
            setIsLoading(false);
          }
        },
        700,
        { leading: true }
      ),
    [auth, clientId, queryClient, statusNotification]
  );

  const handleSearch = useCallback(
    async (searchTerm: string) => {
      setSearchInput(searchTerm);

      if (searchTerm.length <= 1) {
        setSearchResults([]);
        return;
      }

      runSearch(searchTerm);
    },
    [runSearch]
  );

  const notify = useStatusNotification();

  const goToSecurityAllocationPage = useCallback(() => {
    if (selectedSecurities.length === 0) {
      return;
    }

    const newParams = new URLSearchParams(urlSearchParams);
    newParams.set("securities", JSON.stringify(selectedSecurities));

    if (clientId === null) {
      notify("Bad ClientId", "Error");
      return;
    }

    history.push({
      pathname: `/Client/${clientId}/Investments/Portfolios/Create/Custom/Allocation`,
      search: newParams.toString(),
    });
  }, [clientId, history, notify, selectedSecurities, urlSearchParams]);

  return (
    <>
      <div className={styles.selectedSecuritiesGrid}>
        <p className={styles.firstGridItem}>Added Securities</p>
        {selectedSecurities.length > 0 ? (
          selectedSecurities.map((sec) => {
            return (
              <div key={sec.ticker} className={styles.selectedSecurityPill}>
                <p className={styles.selectedSecurityText}>{sec.ticker}</p>
                <ClearRoundedIcon
                  className={styles.removeIcon}
                  onClick={() => removeSelectedSecurity(sec.ticker)}
                />
              </div>
            );
          })
        ) : (
          <p className={styles.noItems}>No securities added</p>
        )}
      </div>

      <DropdownComponent
        formLabel={"Add Securities"}
        placeholder={"Search..."}
        value={search}
        values={searchResults}
        inputValue={searchInput ?? ""}
        onInputChange={(_e, searchTerm) => handleSearch(searchTerm)}
        onChange={(search) => addSelectedSecurity(search)}
        loading={isLoading}
      />

      <div className={styles.footer}>
        <Button
          text="Continue"
          onClick={goToSecurityAllocationPage}
          variant="contained"
          buttonType="primary"
          disabled={selectedSecurities.length === 0}
        />
      </div>
    </>
  );
};

const ImportFile = (): JSX.Element => {
  const [showFileDropUploadModal, setShowFileDropUploadModal] =
    useState<boolean>(false);

  const [file, setFile] = useState<File | null>(null);

  const statusNotification = useStatusNotification();

  return (
    <>
      <div className={styles.importDiv}>
        <p
          className={styles.chooseFile}
          onClick={() => setShowFileDropUploadModal(true)}
        >
          Choose File
        </p>

        {file ? (
          <div className={styles.chosenFileDiv}>
            <p className={styles.chosenFile}>{file.name}</p>
            <ClearRoundedIcon
              className={styles.removeIcon}
              onClick={() => setFile(null)}
            />
          </div>
        ) : (
          <p className={styles.noFile}>No file chosen</p>
        )}

        {showFileDropUploadModal && (
          <FileDropUploadModal
            closeModal={() => setShowFileDropUploadModal(false)}
            onUpload={(file) => setFile(file)}
          />
        )}
      </div>

      <div className={styles.footer}>
        <Button
          text="Continue"
          onClick={() => {
            statusNotification("Not implemented", "Error");
          }}
          variant="contained"
          buttonType="primary"
          disabled={file === null}
        />
      </div>
    </>
  );
};
