import React, { PropsWithChildren, useEffect, useMemo } from "react";

import { orderBy } from "lodash";
import { useHistory, useParams } from "react-router-dom";

import useGetFartherManagedAccounts from "@fartherfinance/frontend/api/Accounts/hooks/useGetFartherManagedAccounts";
import useCheckPermissions from "@fartherfinance/frontend/api/Authz/hooks/useCheckPermission";
import useAdvisorClients from "@fartherfinance/frontend/api/Entity/hooks/useAdvisorClients";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import { pluralize } from "@src/utils/pluralize";

import ClientAccountRow from "./ClientAccountRow";
import ClientAccountRowsLoading from "./ClientAccountRowsLoading";
import ClientDrawer from "./ClientDrawer";

import styles from "./ClientAdmin.module.css";

const ClientAdmin = (props: PropsWithChildren<unknown>): JSX.Element => {
  const { clientId } = useParams<{ clientId: ClientId }>();

  const auth = useAdvisorRequestAuth();
  const clientRequestConfig = useMemo(() => {
    return {
      clientId: clientId,
      advisorId: auth?.advisorId,
      jwt: auth?.jwt ?? "",
    };
  }, [auth, clientId]);

  const history = useHistory();

  const accounts = useGetFartherManagedAccounts(clientId, clientRequestConfig);
  const advisorClients = useAdvisorClients(undefined, auth);

  const canDeleteAccount = useCheckPermissions(
    {
      type: "FartherAccount",
      action: "delete",
      // We are looking for a generic permission for the Account resource
      // and not a specific instance
      id: null,
    },
    auth
  );

  useEffect(() => {
    // Safety measure, if the advisor does not have delete permission then we
    // route back to the client profile tab
    if (canDeleteAccount.data?.authorized === false) {
      history.push({
        pathname: `/Advisor/Clients/${clientId}/Profile`,
        search: location.search,
      });
    }
  }, [history, clientId, canDeleteAccount.data?.authorized]);

  const currentClient = useMemo(() => {
    if (advisorClients.data === undefined) {
      return null;
    }

    return (
      advisorClients.data.clients.find(
        (client) => client.clientId === clientId
      ) ?? null
    );
  }, [advisorClients.data, clientId]);

  const accountRows = useMemo(() => {
    if (accounts.data === undefined) {
      return [];
    }

    return orderBy(
      accounts.data,
      [(a) => a.accountDetails.displayName],
      ["asc"]
    ).map((account) => {
      return (
        <ClientAccountRow key={account.virtualAccountId} account={account} />
      );
    });
  }, [accounts.data]);

  if (accounts.hasError || advisorClients.hasError) {
    return (
      <ClientDrawer curTab={"Admin"}>
        <div className={styles.error}>
          Error getting clients or client dashboard
        </div>
      </ClientDrawer>
    );
  }

  if (advisorClients.data !== undefined && currentClient === null) {
    return (
      <ClientDrawer curTab={"Admin"}>
        <div className={styles.error}>Error, client not found</div>
      </ClientDrawer>
    );
  }

  if (accounts.isLoading || advisorClients.isLoading) {
    return (
      <ClientDrawer curTab={"Admin"}>
        <Spacer />

        <div className={styles.clientAccountsTableContainer}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              <Skeleton width={120} />
            </div>

            <div className={styles.headerRight}>
              <Skeleton width={90} />
            </div>
          </div>

          <TableBodyHeaders />

          <div className={styles.tableBody}>
            <ClientAccountRowsLoading />
          </div>
        </div>

        {props.children}
      </ClientDrawer>
    );
  }

  const clientName = currentClient?.name.first ?? "Client";

  return (
    <ClientDrawer curTab={"Admin"}>
      <Spacer />

      <div className={styles.clientAccountsTableContainer}>
        <div className={styles.header}>
          <div className={styles.headerLeft}>{`${clientName}'s Accounts`}</div>

          <div className={styles.headerRight}>
            {pluralize(accountRows, "Account", true)}
          </div>
        </div>

        {accountRows.length >= 1 ? (
          <TableBodyHeaders />
        ) : (
          <div
            className={styles.noAccounts}
          >{`${clientName} has no accounts`}</div>
        )}

        <div className={styles.tableBody}>{accountRows}</div>
      </div>

      {props.children}
    </ClientDrawer>
  );
};

export default ClientAdmin;

const TableBodyHeaders = () => {
  return (
    <div className={styles.tableHeaders}>
      <div className={styles.col1Header}>Name</div>

      <div className={styles.col2Header}>Account Number</div>

      <div className={styles.col3Header}>Type</div>

      <div className={styles.col4Header}>Custodian</div>

      <div className={styles.col5Header}>Options</div>
    </div>
  );
};
