import React from "react";

import { range } from "lodash";

import { activityRowsPerPage } from "@src/multiCustodian/components/PerformanceGroups/Activity/ActivityV4";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import FullDataTable from "@src/sharedComponents/Table/FullDataTable";
import { Cell, Row } from "@src/sharedComponents/Table/Types";

import { TableKey, tableKeys } from "./ActivityTable";

const ActivityTableLoading = (): JSX.Element => {
  const LOADING_FILLER: Row<TableKey, Cell>[] = range(activityRowsPerPage).map(
    (i) => {
      return {
        key: `${i}`,
        Date: { value: <Skeleton /> },
        Type: { value: <Skeleton /> },
        "Gain / Loss": { value: <Skeleton /> },
        Value: { value: <Skeleton /> },
      };
    }
  );

  return (
    <FullDataTable
      isLoading={true}
      columns={tableKeys}
      rows={LOADING_FILLER}
      defaultRowsPerPage={activityRowsPerPage}
      disableColumnSorting={tableKeys}
      emptyCell={<div>placeholder</div>} // This is the highest cell we will have
    />
  );
};

export default ActivityTableLoading;
