import React, { useMemo } from "react";

import styles from "./ClientCSVTips.module.css";

interface Props {
  seeMore: boolean;
}

const Tips = (props: Props): JSX.Element => {
  const PartialTips = useMemo(() => {
    return (
      <>
        <li>Account Number</li>
        <li>Advisor = Full name of the client's advisor</li>
        <li>
          Client 1 = First and last name of the primary account holder, e.g.
          "John Doe"
        </li>
      </>
    );
  }, []);

  if (props.seeMore === false) {
    return (
      <ul className={styles.list}>
        <li>What to put in the columns</li>
        <ul className={styles.list}>{PartialTips}</ul>
      </ul>
    );
  }

  return (
    <ul className={styles.list}>
      <li>
        What to put in the columns
        <ul className={styles.list}>
          {PartialTips}
          <li>
            Client 1 Email Address (Optional) = Email Address of the primary
            account holder (a placeholder email will be created if this is not
            specified)
          </li>
          <li>
            Client 2 (Optional) = First and last name of the secondary account
            holder
          </li>
          <li>
            Client 2 Email Address (Optional) = Email Address of the secondary
            account holder (a placeholder email will be created if this is not
            specified)
          </li>
          <li>
            Account Name (Optional) = Custom name of the client's account e.g.
            "My Savings Account" (a placeholder account name will be created if
            this is not specified)
          </li>
          <li>
            Account Type = The account category. Valid options include:
            <div className={styles.listWrapped}>
              <div>• Asset Lending</div>
              <div>• Individual</div>
              <div>• IRA</div>
              <div>• Inherited IRA</div>
              <div>• Inherited Roth IRA</div>
              <div>• Joint CP</div>
              <div>• Joint TE</div>
              <div>• Joint TIC</div>
              <div>• Joint WROS</div>
              <div>• Rollover IRA</div>
              <div>• Roth IRA</div>
              <div>• SEP IRA</div>
              <div>• Trust</div>
              <div>• Other</div>
            </div>
          </li>
          <li>
            Custodian = The financial institution where the account was
            established. Valid options include:
            <ul className={styles.list}>
              <div className={styles.listWrapped}>
                <div>• Apex</div>
                <div>• DPL</div>
                <div>• Fidelity</div>
                <div>• Pershing</div>
                <div>• Pontera</div>
                <div>• Schwab</div>
              </div>
            </ul>
          </li>

          <li>
            Client 1 Phone Number (Optional) = Phone number of the primary
            account holder. Expected format: "(###) ###-####", for example
            "(987) 123-4567"
          </li>
          <li>
            Client 1 Date of Birth (Optional) = The primary account holder's
            birthdate in MM/DD/YYYY format
          </li>
          <li>
            Client 1 Address: Street (Optional) = Street name. Note: other
            address details will be ignored if this value is missing
          </li>
          <li>Client 1 Address: Unit (Optional) = Unit name</li>
          <li>
            Client 1 Address: City (Optional) = City name. Note: other address
            details will be ignored if this value is missing
          </li>
          <li>
            Client 1 Address: State (Optional) = State code, for example: "NJ",
            "NY", "LA". Note: other address details will be ignored if this
            value is missing
          </li>
          <li>
            Client 1 Address: Postal Code (Optional) = Postal Code. Note: other
            address details will be ignored if this value is missing
          </li>
          <li>
            Client 1 Address: Country Code (Optional) = Country code, for
            example "US". Note: other address details will be ignored if this
            value is missing.
          </li>
          <li>
            Client 1 Tax Identification Number Type (Optional) = Tax
            identification type code. Note: other tax identification details
            will be ignored if this value is missing. Valid options include:
            <ul className={styles.list}>
              <div className={styles.listWrapped}>
                <div>• SSN</div>
                <div>• ITIN</div>
                <div>• EIN</div>
              </div>
            </ul>
          </li>
          <li>
            Client 1 Tax Identification Number (Optional) = Example:
            "030-88-6000". Note: other tax identification details will be
            ignored if this value is missing
          </li>

          <li>
            Client 2 Phone Number (Optional) = Phone number of the secondary
            account holder. Expected format: "(###) ###-####", for example
            "(987) 123-4567"
          </li>
          <li>
            Client 2 Date of Birth (Optional) = The secondary account holder's
            birthdate in MM/DD/YYYY format
          </li>
          <li>
            Client 2 Address: Street (Optional) = Street name. Note: other
            address details will be ignored if this value is missing
          </li>
          <li>Client 2 Address: Unit (Optional) = Unit name</li>
          <li>
            Client 2 Address: City (Optional) = City name. Note: other address
            details will be ignored if this value is missing
          </li>
          <li>
            Client 2 Address: State (Optional) = State code, for example: "NJ",
            "NY", "LA". Note: other address details will be ignored if this
            value is missing
          </li>
          <li>
            Client 2 Address: Postal Code (Optional) = Postal Code. Note: other
            address details will be ignored if this value is missing
          </li>
          <li>
            Client 2 Address: Country Code (Optional) = Country code, for
            example "US". Note: other address details will be ignored if this
            value is missing.
          </li>
          <li>
            Client 2 Tax Identification Number Type (Optional) = Tax
            identification type code. Note: other tax identification details
            will be ignored if this value is missing. Valid options include:
            <ul className={styles.list}>
              <div className={styles.listWrapped}>
                <div>• SSN</div>
                <div>• ITIN</div>
                <div>• EIN</div>
              </div>
            </ul>
          </li>
          <li>
            Client 2 Tax Identification Number (Optional) = Example:
            "030-88-6000". Note: other tax identification details will be
            ignored if this value is missing
          </li>
        </ul>
      </li>

      <li>
        How to format the data
        <ul className={styles.list}>
          <li>
            Account Number
            <ul className={styles.list}>
              <li>Should not contain a hyphen, e.g. "11112244"</li>
            </ul>
          </li>

          <li>
            Client 1
            <ul className={styles.list}>
              <li>
                Only first and last name (no middle name), e.g. "John Doe"
              </li>
              <li>Only one space</li>
            </ul>
          </li>

          <li>
            Client 2
            <ul className={styles.list}>
              <li>Only first and last name (no middle name)</li>
              <li>Only one space</li>
            </ul>
          </li>

          <li>
            Phone Number
            <ul className={styles.list}>
              <li>
                Only digits in the following format: "(###) ###-####", e.g.
                "(987) 123-4567"
              </li>
            </ul>
          </li>

          <li>
            Date of birth
            <ul className={styles.list}>
              <li>Expected format: "MM/DD/YYYY", e.g. "04/20/1976"</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  );
};

export default Tips;
