import React from "react";

import styles from "./ProjectionsTab.module.css";

const ProjectionsLegendForSpecificAccount = (): JSX.Element => {
  return (
    <div className={styles.rightContainer}>
      <div className={styles.legendDiv}>
        <div className={styles.bar90} />
        <div className={styles.legendText}>90%</div>
      </div>

      <div className={styles.legendDiv}>
        <div className={styles.bar50} />
        <div className={styles.legendText}>50%</div>
      </div>

      <div className={styles.legendDiv}>
        <div className={styles.bar10} />
        <div className={styles.legendText}>10%</div>
      </div>
    </div>
  );
};

export default ProjectionsLegendForSpecificAccount;
