import { useDebugValue, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";

import { FartherToken } from "@fartherfinance/frontend/api/Identity/requests/exchangeStytchToken";

import useLogIntoRedux from "../useLogIntoRedux";
import useNavigateOnLogin from "../useNavigateOnLogin";
import {
  advisorEmailLocalStorageKey,
  IsAdminSessionKey,
  LoggedInStateSessionKey,
} from "@src/constants/localStorageKeys";
import {
  magicCheckLogin,
  magicGetIdToken,
  magicGetUserMetadata,
} from "@src/magic";
import { LocalStorageKeys } from "@src/multiCustodian/components/Login/constants";
import isLocalStorageAvailable from "@src/multiCustodian/utils/isLocalStorageAvailable";
import logUserOut from "@src/multiCustodian/utils/logUserOut";
import { MCReduxAction, State } from "@src/store";

import useAdvisorFartherLogin, { LoginInfo } from "./useAdvisorFartherLogin";

type LoggedInState = {
  isLoggedIn: boolean;
};

type Return =
  | { isLoading: true; hasError: false; data: undefined }
  | { isLoading: false; hasError: false; data: LoggedInState }
  | { isLoading: false; hasError: true; data: undefined };

class AdvisorNotLoggedIn extends Error {
  constructor(reason: string) {
    super(`Advisor Not Logged In ${reason}`);
  }
}

export default function useLogIntoSavedAdvisorSession(): Return {
  const { isAdvisor, isAdmin, advisorId, jwt } = useSelector(
    (state: State) => ({
      isAdvisor: state.main_Reducer.user.isAdvisor,
      advisorId: state.main_Reducer.cockroach_advisor_id,
      isAdmin: state.main_Reducer.isAdmin,
      jwt: state.main_Reducer.farther_jwt,
    })
  );

  const isFartherToken = jwt?.startsWith("ffp_");

  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);

  const isLoading = isLoggedIn === null;

  const history = useHistory();

  useDebugValue(
    `isLoggedIn: ${isLoggedIn ? "Yes" : "No"}, isAdvisor: ${
      isAdvisor === null ? "null" : isAdvisor ? "Yes" : "No"
    }, isAdmin: ${isAdmin ? "Yes" : "No"}`
  );

  const dispatch = useDispatch<Dispatch<MCReduxAction>>();
  const advisorFartherLogin = useAdvisorFartherLogin();
  const logIntoRedux = useLogIntoRedux();
  const navigateOnLogin = useNavigateOnLogin();

  useEffect(() => {
    if (isFartherToken) {
      console.log(
        "useLogIntoSavedAdvisorSession - Setting as logged in using Farther token"
      );

      setIsLoggedIn(true);

      return;
    }

    try {
      const savedFartherToken = localStorage.getItem(LocalStorageKeys.token);
      const savedPersonId = localStorage.getItem(LocalStorageKeys.personId);
      const savedPersonType = localStorage.getItem(LocalStorageKeys.personType);

      if (
        savedFartherToken?.startsWith("ffp_") &&
        savedPersonId !== null &&
        savedPersonType !== null
      ) {
        console.log(
          "Logging in from useLogIntoSavedAdvisorSession using Stytch saved"
        );

        logIntoRedux(
          savedPersonId,
          savedPersonType as "Advisor" | "Client",
          savedFartherToken as FartherToken
        );

        navigateOnLogin(savedPersonType as "Advisor" | "Client");

        // Ignore magic
        return;
      }
    } catch (err) {
      console.error("useLogIntoSavedAdvisorSession", err);
    }

    if (isAdvisor === true && advisorId !== null) {
      //Already logged in, noop
      console.log("useLogIntoSavedAdvisorSession Already logged in, skipping");
      setIsLoggedIn(true);
      return;
    }

    // This is buggy, turn off for now
    // loadFromSessionStorage(dispatch, () => {
    //   setIsLoggedIn(true);
    //   // Keep loading in the background but allow the UI to be shown
    //   setIsLoading(false);
    // });

    magicCheckLogin("Advisor")
      .then(async (isLoggedIn) => {
        console.log("useLogIntoSavedAdvisorSession isLoggedIn", isLoggedIn);

        if (!isLoggedIn) {
          setIsLoggedIn(false);

          // if we used a sessionStorage reset state
          sessionStorage.removeItem(LoggedInStateSessionKey);
          sessionStorage.removeItem(IsAdminSessionKey);
          dispatch({
            type: "SET_IS_LOGGED_IN_AS_ADVISOR_STATE",
            payload: {
              isLoggedIn: false,
              farther_jwt: null,
              isAdvisor: null,
              cur_user: null,
              cockroach_advisor_id: null,
            },
          });

          dispatch({ type: "SET_ISADMIN", payload: { isAdmin: false } });
          return;
        }

        let auth: LoginInfo;
        if (process.env.WEBAPP_ENV !== "PROD") {
          auth = await nonProdAlgo();
        } else {
          auth = await prodAlgo();
        }

        await advisorFartherLogin(auth);

        setIsLoggedIn(true);

        return;
      })
      .catch((err) => {
        setIsLoggedIn(false);

        if (err instanceof AdvisorNotLoggedIn) {
          return;
        }

        console.error("useLogIntoSavedAdvisorSession", err);
        logUserOut("useLogIntoSavedAdvisorSession error", history);
      });
  }, [
    advisorFartherLogin,
    advisorId,
    dispatch,
    history,
    isAdvisor,
    isFartherToken,
    logIntoRedux,
    navigateOnLogin,
  ]);

  return useMemo((): Return => {
    if (isLoading) {
      return { isLoading, hasError: false, data: undefined };
    } else {
      return { isLoading: false, hasError: false, data: { isLoggedIn } };
    }
  }, [isLoading, isLoggedIn]);
}

const getCookie = (key: string) => {
  const name = key + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.startsWith(" ")) {
      c = c.substring(1);
    }
    if (c.startsWith(name)) {
      return c.substring(name.length, c.length);
    }
  }

  return null;
};

const prodAlgo = async () => {
  const magicToken = await magicGetIdToken("Advisor");
  const userMetadata = await magicGetUserMetadata("Advisor");

  if (userMetadata.email === null) {
    throw new AdvisorNotLoggedIn("email not stored");
  }

  const auth: LoginInfo = { magicToken, advisorEmail: userMetadata.email };

  return auth;
};

const nonProdAlgo = async () => {
  const magicToken = await magicGetIdToken("Advisor");

  let email: string | null = isLocalStorageAvailable()
    ? localStorage.getItem(advisorEmailLocalStorageKey)
    : null;
  if (email === null) {
    // Fallback to cookie
    email = getCookie("farther_email");
  }

  if (email === null) {
    throw new AdvisorNotLoggedIn("email not stored");
  }

  const [advisorEmail, _devEmail] = email.split("__");

  const auth: LoginInfo = { magicToken, advisorEmail: advisorEmail };

  return auth;
};
