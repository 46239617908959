import React, { useMemo, useState } from "react";

import { useParams } from "react-router-dom";

import useGetFartherManagedAccounts from "@fartherfinance/frontend/api/Accounts/hooks/useGetFartherManagedAccounts";
import useSetAccountTitle from "@fartherfinance/frontend/api/Accounts/hooks/useSetAccountTitle";
import { ClientId, FartherAccountId } from "@fartherfinance/frontend/api/Types";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { captureException } from "@src/multiCustodian/services/tracking";
import Modal, { standardWidth } from "@src/sharedComponents/Modal/Modal";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import TextInput from "@src/sharedComponents/TextInput/TextInput";

import styles from "./RenameAccountModal.module.css";

interface Props {
  closeModal: () => void;
}

const RenameAccountModal = (props: Props): JSX.Element => {
  const [isMutating, setIsMutating] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");

  const { clientId, accountId } = useParams<{
    clientId: ClientId;
    accountId: FartherAccountId;
  }>();

  const statusNotification = useStatusNotification();

  const auth = useAdvisorRequestAuth();
  const config =
    auth?.jwt && auth?.advisorId
      ? {
          clientId: clientId,
          advisorId: auth.advisorId,
          jwt: auth.jwt,
        }
      : null;

  const accounts = useGetFartherManagedAccounts(clientId, config);

  const currentAccountName = useMemo(() => {
    if (accounts.isLoading || accounts.hasError) {
      return accounts;
    }

    const currentAccountLocal = accounts.data.find(
      (acc) => acc.virtualAccountId === accountId
    );

    if (currentAccountLocal === undefined) {
      const err = new Error(`Could not find account with the ID ${accountId}`);

      captureException(err);
    }

    return {
      ...accounts,
      data: currentAccountLocal?.accountDetails.displayName ?? "",
    };
  }, [accountId, accounts]);

  const setAccountTitle = useSetAccountTitle(clientId, config);

  const renameAccount = async () => {
    try {
      setIsMutating(true);

      await setAccountTitle({
        virtualAccountId: accountId,
        accountTitle: inputValue,
      });

      statusNotification("Account renamed", "Success");
      props.closeModal();
    } catch (_) {
      statusNotification("Failed to rename account", "Error");
    } finally {
      setIsMutating(false);
    }
  };

  if (isMutating || currentAccountName.isLoading) {
    return (
      <Modal closeModal={props.closeModal} modalStyle={standardWidth}>
        <div className={styles.paddingContainer}>
          <Skeleton width={150} className={styles.title} />

          <Skeleton width={110} className={styles.inputLabel} />

          <Skeleton
            width={"100%"}
            height={40}
            className={styles.inputLoading}
          />

          <div className={styles.footer}>
            <div className={styles.modalButtonsDiv}>
              <Skeleton
                width={100}
                height={35}
                className={styles.marginRight}
              />

              <Skeleton width={100} height={35} />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  const inputNotValid = !inputValue || inputValue.length > 100;

  return (
    <Modal closeModal={props.closeModal} modalStyle={standardWidth}>
      <div className={styles.paddingContainer}>
        <div className={styles.title}>Rename Account</div>

        <div className={styles.inputLabel}>Account Name</div>

        <TextInput
          label=""
          placeholder={"John Doe's IRA"}
          value={inputValue === "" ? currentAccountName.data ?? "" : inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />

        <div className={styles.footer}>
          <div className={styles.modalButtonsDiv}>
            <Button
              variant={"outlined"}
              buttonType={"secondary"}
              text={"Cancel"}
              onClick={props.closeModal}
            />

            <Button
              disabled={inputNotValid}
              variant={"contained"}
              buttonType={"primary"}
              text={"Save"}
              onClick={() => renameAccount()}
              style={{ marginLeft: "20px" }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RenameAccountModal;
