import React, { useCallback } from "react";

import { useHistory, useParams } from "react-router-dom";

import { ClientId, PortfolioId } from "@fartherfinance/frontend/api/Types";

import Drawer from "@src/multiCustodian/components/Drawer/Drawer";

import EquivalentSecuritiesDrawerContent from "./EquivalentSecuritiesDrawerContent";

import styles from "./EquivalentSecuritiesDrawer.module.css";

const EquivalentSecuritiesDrawer: React.FC = () => {
  const { clientId, portfolioId } = useParams<{
    clientId: ClientId;
    portfolioId: PortfolioId;
  }>();

  const history = useHistory();

  const onClose = useCallback(
    () =>
      history.push({
        ...history.location,
        pathname: `/Client/${clientId}/Investments/Portfolios/${portfolioId}/Accounts`,
      }),
    [history, clientId, portfolioId]
  );

  return (
    <Drawer className={styles.drawer} isDrawerOpen onClose={onClose}>
      <EquivalentSecuritiesDrawerContent />
    </Drawer>
  );
};

export default EquivalentSecuritiesDrawer;
