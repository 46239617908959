import {
  AssetClass,
  AssetClassCategory,
} from "@fartherfinance/frontend/api/PerformanceGroups/requests/getHoldings";

export type DrillInLevel = "All" | "Category" | "Asset Class";

export interface CellItem {
  name: string;
  percent: number;
  color: string;
  level: DrillInLevel;
  dontDrillIn: boolean;
}
interface AssetClassCategoryCellItem extends Omit<CellItem, "name"> {
  name: AssetClassCategory;
  percent: number;
  color: string;
  level: DrillInLevel;
  dontDrillIn: boolean;
}
interface AssetClassCellItem extends Omit<CellItem, "name"> {
  name: AssetClass;
  percent: number;
  color: string;
  level: DrillInLevel;
  dontDrillIn: boolean;
}

export type AnyCellItem =
  | CellItem
  | AssetClassCategoryCellItem
  | AssetClassCellItem;

export interface DrillInState {
  category: AssetClassCategory | null;
  class: AssetClass | null;
}

export const isAssetClassCategoryCellItem = (
  cellItem: AnyCellItem
): cellItem is AssetClassCategoryCellItem => {
  return cellItem.level === "Category";
};

export const isAssetClassCellItem = (
  cellItem: AnyCellItem
): cellItem is AssetClassCellItem => {
  return cellItem.level === "Asset Class";
};
