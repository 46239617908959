import React, { PropsWithChildren, useMemo, useState } from "react";

import { format, isAfter, isBefore, isSameDay, parse } from "date-fns";
import { capitalize, range } from "lodash";
import { useParams } from "react-router-dom";

import { UNKNOWN_BALANCE } from "@fartherfinance/frontend/api/Accounts/utilities/accountUtil";
import useAllManagedAccounts from "@fartherfinance/frontend/api/Dashboard/hooks/useAllManagedAccounts";
import useEventsV4 from "@fartherfinance/frontend/api/PerformanceGroups/hooks/useEventsV4";
import useGetAccountGroupsV4 from "@fartherfinance/frontend/api/PerformanceGroups/hooks/useGetAccountGroupsV4";
import useGetAcatsTransfers from "@fartherfinance/frontend/api/Transfer/hooks/useGetAcatsTransfers";
import useGetACH from "@fartherfinance/frontend/api/Transfer/hooks/useGetACH";
import useGetLiquidations from "@fartherfinance/frontend/api/Transfer/hooks/useGetLiquidations";
import { StatusOptions as ACATStatus } from "@fartherfinance/frontend/api/Transfer/requests/getAcatsTransfers";
import { ACHStatus } from "@fartherfinance/frontend/api/Transfer/requests/getACH";
import { LiquidationStatus } from "@fartherfinance/frontend/api/Transfer/requests/getLiquidations";
import {
  ClientId,
  FartherAccountId,
  PerformanceGroupId,
} from "@fartherfinance/frontend/api/Types";

import { toSortable } from "../../Client/Accounts/Holdings/Components/BreakdownTable/HoldingsTable";
import { dateFormat } from "@src/constants/dateFormat";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import { Disclaimer } from "@src/multiCustodian/pages/Dashboard/Performance_Groups/PG_hardcoded_exports";
import Chip from "@src/sharedComponents/Chip/Chip";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import FullDataTable from "@src/sharedComponents/Table/FullDataTable";
import TableCell from "@src/sharedComponents/Table/TableCell/TableCell";
import { Cell, Row } from "@src/sharedComponents/Table/Types";

import FilterPopover from "./FilterPopover/FilterPopover";
import {
  defaultFilter,
  EventsFilterConfig,
  TransactionAndTransferType,
} from "./shared";

import styles from "./Activity.module.css";

export const activityRowsPerPage = 20;

interface MiniTransferRequest {
  createTime: string;
  transferId: string;
  direction: "LEAVING" | "JOINING" | "DEPOSIT" | "WITHDRAWAL" | null;
  isFullWithdrawal: boolean | null;
  status: ACHStatus | ACATStatus | LiquidationStatus;
  accountId: FartherAccountId;
  amount: number | null;
  isFullLiquidation: boolean | null;
  type:
    | "Pending Deposit"
    | "Pending Withdrawal"
    | "Pending Liquidation"
    | "Pending ACAT";
}

const TableKeys = [
  "Settle Date",
  "Type",
  "Account",
  "Quantity",
  "Amount",
] as const;
type TableKey = typeof TableKeys[number];

// Account details does not need "Account" column
const AccountTableKeys = ["Settle Date", "Type", "Quantity", "Amount"] as const;

interface Props {
  accountId: FartherAccountId | null; // When component is used by Accounts a FartherAccountId is passed in. Will be null when used by PG
  performanceGroupId: PerformanceGroupId | null;
}

export default function ActivityV4(
  props: PropsWithChildren<Props>
): JSX.Element {
  const [filter, setFilter] =
    useState<Readonly<EventsFilterConfig>>(defaultFilter);

  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const isInDashboard = props.accountId === null;

  const auth = useRequestAuth();
  const events = useEventsV4(props.performanceGroupId, auth);
  const accountGroups = useGetAccountGroupsV4(auth);
  const accounts = useAllManagedAccounts(clientId, auth);

  const [accountIdsInAccountGroup, isAllClientAccountsAG] = useMemo(() => {
    if (accountGroups.isLoading || accountGroups.hasError) {
      return [[], false];
    }

    const accountGroup = accountGroups.data?.find(
      (ag) => ag.accountGroupId === props.performanceGroupId
    );
    return [
      accountGroup?.virtualAccountIds ?? [],
      accountGroup?.accountGroupType === "ALL_CLIENT_ACCOUNTS",
    ];
  }, [accountGroups, props.performanceGroupId]);

  const transactionsRowData = useMemo(() => {
    const transactions = (events.data ?? [])
      .filter((event) => {
        return isAllClientAccountsAG
          ? true
          : accountIdsInAccountGroup.some((id) => id === event.accountId);
      })
      .flatMap((event) =>
        event.transactions.map((tr) => ({ ...tr, accountId: event.accountId }))
      );

    const transactionsFilteredByType = transactions.filter((t) => {
      const type = t.transactionType.type;
      if (type === "@Unknown") {
        return false;
      }

      return (
        type in filter.types && filter.types[type as TransactionAndTransferType]
      );
    });

    const transactionsFilteredByDate = transactionsFilteredByType.filter(
      (t) => {
        if (filter.dateFilter === null) {
          return true;
        }

        const { from: dateFrom, to: dateTo } = filter.dateFilter;

        // isAfter & isBefore are not inclusive so do same day check ourselves
        if (
          isSameDay(t.settleDate, dateFrom) ||
          isSameDay(t.settleDate, dateTo)
        ) {
          return true;
        }

        return (
          isAfter(t.settleDate, dateFrom) && isBefore(t.settleDate, dateTo)
        );
      }
    );

    return transactionsFilteredByDate.map((a): Row<TableKey, Cell> => {
      const account = (accounts.data ?? []).find(
        (acct) => acct.accountId === a.accountId
      );

      const accountName = account
        ? account.accountDetails.displayName
        : "unknown account";

      const quantity = parseFloat(a.quantity);
      const roundedQuantity = Math.round(quantity); // Not sure why we need this?
      const nonZeroQuantity = quantity === 0 ? 1 : quantity;

      return {
        key: a.id,
        "Settle Date": {
          value: (
            <div className={styles.dateRowCell}>
              {format(a.settleDate, "M/d/yyyy")}
            </div>
          ),
          fullValue: a.settleDate.toISOString(),
        },
        Type: a.transactionType
          ? a.ticker === "Security Name Unavailable" // Do not try to show buy / buy reversal for these See https://linear.app/fartherfinance/issue/FE-1579/bug-missing-security-names
            ? a.action
            : a.transactionType.type === "Buy"
            ? `${a.ticker} ${a.action}`
            : a.transactionType.type === "Sale"
            ? `${a.ticker} ${a.action}`
            : a.transactionType.type ?? a.action
          : UNKNOWN_BALANCE,
        Account: accounts.data === undefined ? "- -" : accountName,
        Quantity: {
          value:
            a.transactionType.type === "Buy" ||
            a.transactionType.type === "Sale" ? (
              <div className={styles.quantityRowCell}>
                {`${roundedQuantity} at ${(
                  a.marketValue / nonZeroQuantity
                ).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </div>
            ) : (
              <div className={styles.quantityRowCell}>{UNKNOWN_BALANCE}</div>
            ),
          fullValue: toSortable(roundedQuantity),
        },
        Amount: {
          value: (
            <div className={styles.amountRowCellNeutral}>
              {a.marketValue.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          ),
          fullValue: toSortable(a.marketValue),
        },
      };
    });
  }, [
    events.data,
    accounts.data,
    filter,
    accountIdsInAccountGroup,
    isAllClientAccountsAG,
  ]);

  const ACHTransfers = useGetACH(auth);
  const AcatTransfers = useGetAcatsTransfers(auth);
  const liquidations = useGetLiquidations(auth);

  const isLoading = useMemo(() => {
    return (
      events.isLoading ||
      accounts.isLoading ||
      accountGroups.isLoading ||
      ACHTransfers.isLoading ||
      AcatTransfers.isLoading ||
      liquidations.isLoading
    );
  }, [
    events.isLoading,
    accounts.isLoading,
    accountGroups.isLoading,
    ACHTransfers.isLoading,
    AcatTransfers.isLoading,
    liquidations.isLoading,
  ]);

  const transfersRowData = useMemo(() => {
    if (
      ACHTransfers.isLoading ||
      AcatTransfers.isLoading ||
      liquidations.isLoading ||
      accounts.isLoading ||
      ACHTransfers.hasError ||
      AcatTransfers.hasError ||
      liquidations.hasError ||
      accounts.hasError
    ) {
      return [];
    }

    const filteredPendingTransferRequests: MiniTransferRequest[] = [
      ...ACHTransfers.data.map((ach) => ({
        createTime: format(ach.createTime, dateFormat),
        transferId: ach.transferId,
        direction: ach.direction,
        isFullWithdrawal: ach.isFullWithdrawal,
        status: ach.status,
        accountId: ach.fartherAccountId,
        amount: ach.amount,
        isFullLiquidation: null,
        type: `Pending ${capitalize(ach.direction)}` as
          | "Pending Deposit"
          | "Pending Withdrawal",
      })),
      ...AcatTransfers.data.map(
        (acat): MiniTransferRequest => ({
          createTime: format(acat.createTime, dateFormat),
          transferId: acat.transferId,
          direction: acat.direction,
          isFullWithdrawal: null,
          status: acat.status,
          accountId: acat.virtualAccountId,
          amount: null,
          isFullLiquidation: null,
          type: "Pending ACAT",
        })
      ),
      ...liquidations.data.map(
        (liq): MiniTransferRequest => ({
          createTime: liq.createTime,
          transferId: liq.transferId,
          direction: null,
          isFullWithdrawal: null,
          status: liq.status,
          accountId: liq.virtualAccountId,
          amount: liq.amount,
          isFullLiquidation: liq.isFullLiquidation,
          type: "Pending Liquidation",
        })
      ),
    ]
      .filter((tr) => tr.status.includes("PENDING"))
      .filter((tr) =>
        isAllClientAccountsAG
          ? true
          : accountIdsInAccountGroup.some((id) => id === tr.accountId)
      );

    const transfersFilteredByType = filteredPendingTransferRequests.filter(
      (t) =>
        t.type in filter.types &&
        filter.types[t.type as TransactionAndTransferType]
    );

    const transfersFilteredByDate = transfersFilteredByType.filter((tr) => {
      if (filter.dateFilter === null) {
        return true;
      }

      return (
        format(filter.dateFilter.from, dateFormat) <= tr.createTime &&
        tr.createTime <= format(filter.dateFilter.to, dateFormat)
      );
    });

    return transfersFilteredByDate.map((a): Row<TableKey, Cell> => {
      const account = accounts.data.find(
        (acct) => acct.accountId === a.accountId
      );

      const accountName = account
        ? account.accountDetails.displayName
        : "unknown account";

      let typeDescription = "";
      if (a.type === "Pending Deposit" || a.type === "Pending Withdrawal") {
        typeDescription =
          a.direction === "WITHDRAWAL"
            ? a.isFullWithdrawal
              ? "Full Withdrawal"
              : "Withdrawal"
            : "Deposit";
      } else if (a.type === "Pending ACAT") {
        typeDescription = "ACAT";
      } else if (a.type === "Pending Liquidation") {
        typeDescription = a.isFullLiquidation
          ? "Full Liquidation"
          : "Liquidation";
      } else {
        throw new Error("Bad pending transfer type");
      }

      return {
        key: a.transferId,
        "Settle Date": {
          value: (
            <div className={styles.dateRowCell}>
              {format(parse(a.createTime, dateFormat, new Date()), "M/d/yyyy")}
            </div>
          ),
          fullValue: a.createTime,
        },
        Type: `${typeDescription} (Pending)`,
        Account: accountName,
        Quantity: {
          value: <div className={styles.quantityRowCell}>{"- -"}</div>,
          fullValue: "- -",
        },
        Amount: {
          value: (
            <div className={styles.amountRowCellNeutral}>
              {`${
                a.amount !== null
                  ? a.amount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "- -"
              }`}
            </div>
          ),
          fullValue: toSortable(a.amount !== null ? a.amount : 0),
        },
      };
    });
  }, [
    ACHTransfers,
    AcatTransfers,
    liquidations,
    accounts,
    filter,
    accountIdsInAccountGroup,
    isAllClientAccountsAG,
  ]);

  const rowData = useMemo(() => {
    return [...transactionsRowData, ...transfersRowData];
  }, [transactionsRowData, transfersRowData]);

  if (
    accounts.hasError ||
    accountGroups.hasError ||
    events.hasError ||
    props.performanceGroupId === null
  ) {
    return (
      <div>
        <div className={styles.errorDiv}>Activity data unavailable</div>

        <Spacer verticalSpacing="32px" />

        <Disclaimer />
      </div>
    );
  }

  const emptyRow: Row<TableKey, Cell> = {
    key: "emptyRow",
    "Settle Date": { value: <Skeleton /> },
    Type: { value: <Skeleton /> },
    Account: { value: <Skeleton /> },
    Quantity: { value: <Skeleton /> },
    Amount: { value: <Skeleton /> },
  };

  const accountEmptyRow: Row<TableKey, Cell> = {
    key: "emptyRow",
    "Settle Date": { value: <Skeleton /> },
    Type: { value: <Skeleton /> },
    Account: { value: <Skeleton /> },
    Quantity: { value: <Skeleton /> },
    Amount: { value: <Skeleton /> },
  };

  return (
    <div>
      <div
        className={
          isInDashboard ? styles.headerForDashboard : styles.headerForAccount
        }
      >
        <div className={styles.activityFilterButtonAndChipsContainer}>
          {!isInDashboard && (
            <>
              <div id="activityFilterActivityTypeChipPortalContainer" />

              <div id="activityFilterDateRangeChipPortalContainer" />
            </>
          )}

          <FilterPopover isInDashboard={isInDashboard} setFilter={setFilter} />

          {isInDashboard && (
            <>
              <div id="activityFilterActivityTypeChipPortalContainer" />

              <div id="activityFilterDateRangeChipPortalContainer" />
            </>
          )}
        </div>

        {props.children}
      </div>

      <div className={styles.tableContainer}>
        <FullDataTable
          isLoading={isLoading}
          columns={isInDashboard ? TableKeys : AccountTableKeys}
          rows={
            rowData.length > 0 || (rowData.length === 0 && !isLoading)
              ? rowData
              : range(activityRowsPerPage).map((idx) => ({
                  ...(isInDashboard ? emptyRow : accountEmptyRow),
                  key: `empty-${idx}`,
                }))
          }
          defaultRowsPerPage={activityRowsPerPage}
          defaultSortColumn={["Settle Date", "desc"]}
          disableColumnSorting={rowData.length > 0 ? undefined : TableKeys}
          emptyCell={<Chip />}
        >
          {rowData.length === 0 && !isLoading && (
            <EmptyTable isInDashboard={isInDashboard} />
          )}
        </FullDataTable>
      </div>

      <Spacer verticalSpacing="32px" />

      <Disclaimer />
    </div>
  );
}

interface EmptyTableProps {
  isInDashboard: boolean;
}

const EmptyTable = ({ isInDashboard }: EmptyTableProps) => {
  return (
    <TableCell
      colSpan={isInDashboard ? TableKeys.length : AccountTableKeys.length}
    >
      <div className={styles.emptyTable}>
        <div className={styles.emptyTableText}>No items</div>
      </div>
    </TableCell>
  );
};
