import React from "react";

import { Redirect, useLocation } from "react-router-dom";

interface Props {
  pathname: string;
}

const RedirectWithLocation = ({ pathname }: Props): JSX.Element => {
  const location = useLocation();

  return (
    <Redirect
      to={{
        ...location,
        pathname: pathname,
      }}
      exact
      push={false}
    />
  );
};

export default RedirectWithLocation;
