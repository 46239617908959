import React, { createContext, Dispatch } from "react";

import { initialState } from "./initialState";
import { ActionPayload, TaxBudgetFlowState } from "./types";

const CreateEditTaxBudgetContext = createContext<{
  state: TaxBudgetFlowState;
  dispatch: Dispatch<ActionPayload>;
}>({
  state: initialState({ flowType: "Budget", isAdvisor: false }),
  dispatch: () => null,
});

export function useCreateEditTaxBudgetContext() {
  return React.useContext(CreateEditTaxBudgetContext);
}

export default CreateEditTaxBudgetContext;
