import React from "react";

import { MarketplaceAssetType } from "@fartherfinance/frontend/api/AltAssetsService/Types";

import InsuranceOfferingsListAdvisor from "../OfferingsListAdvisor/InsuranceOfferingsListAdvisor";
import { InsuranceMarketplacePath } from "@src/config/routing/RouterPaths";
import { insuranceOfferingTypes } from "@src/multiCustodian/components/Marketplace/constants";

import OfferingDetailsAdvisor from "./OfferingDetailsAdvisor";

const InsuranceOfferingDetailsAdvisor: React.FC = () => {
  return (
    <InsuranceOfferingsListAdvisor>
      <OfferingDetailsAdvisor
        assetType={MarketplaceAssetType.enum.insurance}
        offeringTypes={insuranceOfferingTypes}
        marketplacePath={InsuranceMarketplacePath}
      />
    </InsuranceOfferingsListAdvisor>
  );
};

export default InsuranceOfferingDetailsAdvisor;
