import React from "react";

const Tasks: React.FC = () => (
  <svg
    width="100%"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="checklist">
      <mask
        id="mask0_828_9790"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="-1"
        y="0"
        width="15"
        height="15"
      >
        <rect
          id="Bounding box"
          x="-0.160156"
          y="0.154297"
          width="14"
          height="14"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_828_9790)">
        <path
          id="checklist_2"
          d="M3.22308 11.004L1.23975 9.02064L1.9835 8.27689L3.2085 9.51647L5.68766 7.0373L6.446 7.78105L3.22308 11.004ZM3.22308 6.45397L1.23975 4.47064L1.9835 3.72689L3.22308 4.96647L5.68766 2.4873L6.446 3.23105L3.22308 6.45397ZM7.53975 9.95397V8.90397H12.4397V9.95397H7.53975ZM7.53975 5.40397V4.35397H12.4397V5.40397H7.53975Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default Tasks;
