import { useMemo } from "react";

import useClientAccounts from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAccounts";
import useCustomPortfoliosV1 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useCustomPortfoliosV1";
import useEssentialAllPortfoliosV1 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useEssentialAllPortfoliosV1";
import usePortfolioPartners from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/usePortfolioPartners";
import useTradingGroups from "@fartherfinance/frontend/api/TradingGroups/hooks/useTradingGroups";
import {
  ClientId,
  FartherAccountId,
  HookResult,
  RequestConfig,
  TradingGroupId,
} from "@fartherfinance/frontend/api/Types";

import { HypotheticalTradeGroup } from "../reducer/types";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

import { NO_TRADE_GROUP_ID } from "./constants";
import { combineAccountNames } from "./utils";

type AccountIdToName = Record<FartherAccountId, string>;

type Result = HookResult<HypotheticalTradeGroup[]>;

export default function useSelectedAccountsToTradeGroups(
  clientId: ClientId | null,
  excludedVirtualAccountIds: FartherAccountId[],
  authConfig: RequestConfig | null
): Result {
  const accounts = useClientAccounts(clientId, authConfig);
  const tradingGroups = useTradingGroups(clientId, authConfig);
  const customPortfolios = useCustomPortfoliosV1(clientId, authConfig);
  const essentialPortfolios = useEssentialAllPortfoliosV1(authConfig);

  const clientAuth = useRequestAuth();
  const advisorAuth = useAdvisorRequestAuth();
  const auth = advisorAuth ? advisorAuth : clientAuth;
  const partners = usePortfolioPartners(auth);

  const result: Result = useMemo(() => {
    if (
      accounts.isLoading ||
      tradingGroups.isLoading ||
      customPortfolios.isLoading ||
      essentialPortfolios.isLoading ||
      partners.isLoading
    ) {
      return {
        data: undefined,
        isLoading: true,
        hasError: false,
      };
    }

    if (
      accounts.hasError ||
      tradingGroups.hasError ||
      customPortfolios.hasError ||
      essentialPortfolios.hasError ||
      partners.hasError
    ) {
      let msg = "accounts";
      if (tradingGroups.hasError) {
        msg = "trade groups";
      } else if (customPortfolios.hasError) {
        msg = "custom portfolios";
      } else if (essentialPortfolios.hasError) {
        msg = "essential portfolios";
      } else if (partners.hasError) {
        msg = "partners";
      }

      return {
        data: undefined,
        isLoading: false,
        hasError: true,
        error: `Error retrieving client's ${msg}`,
      };
    }

    const allPortfolios = [
      ...customPortfolios.data.portfolios.map((cp) => ({
        portfolioModelId: cp.portfolioModelId,
        displayName: cp.displayName,
      })),
      ...essentialPortfolios.data.map((ep) => ({
        portfolioModelId: ep.portfolioModelId,
        displayName: ep.displayName,
      })),
    ];

    const budgetAccountIds = accounts.data.fartherAccounts.filter(
      (a) => !excludedVirtualAccountIds.some((ea) => ea === a.accountId)
    );

    const accountIdToName = budgetAccountIds.reduce((acc, cur) => {
      const account = accounts.data.fartherAccounts.find(
        (a) => a.accountId === cur.accountId
      );
      if (account === undefined) {
        throw new Error("Can't find excludedVirtualAccountId in accounts list");
      }

      return {
        ...acc,
        [cur.accountId]: account.accountDetails.displayName,
      };
    }, {} as AccountIdToName);

    // only look at trade groups containing budgetAccountIds (accountIds not in excludedVirtualAccountIds)
    const filteredTradeGroups = tradingGroups.data.filter((tg) =>
      tg.accounts.some((a) =>
        budgetAccountIds.some((ba) => ba.accountId === a.virtualAccountId)
      )
    );

    const tradeGroupsWithPortfolioAndAccountNames = filteredTradeGroups.map(
      (tradeGroup) => {
        const portfolio = allPortfolios.find(
          (p) => p.portfolioModelId === tradeGroup.portfolioId
        );
        if (portfolio === undefined) {
          throw new Error(
            "Can't find portfolioId (from trade groups grouped by portfolio) in combined portfolios (custom + essential) list"
          );
        }

        // accountIdToName will only contain budgetAccountIds (as keys) which are the accounts the user selected
        const filteredAccounts = tradeGroup.accounts.filter(
          (a) => accountIdToName[a.virtualAccountId]
        );

        const accountNamesInTradeGroup = filteredAccounts.map((account) => {
          if (!accountIdToName[account.virtualAccountId]) {
            throw new Error(
              `account: ${account.virtualAccountId} is not in accountIdToName look-up`
            );
          }

          // should always be able to index into accountIdToName with cur_acc.virtualAccountId
          return accountIdToName[account.virtualAccountId];
        });

        const accountIds = filteredAccounts.map((a) => a.virtualAccountId);

        const hypotheticalModelType =
          partners.data.portfolioPartners[0]?.portfolioPartnerName ?? null;

        return {
          tradingGroupId: tradeGroup.groupId,
          modelName: portfolio.displayName,
          accountsInPortfolio: combineAccountNames(accountNamesInTradeGroup),
          virtualAccountIds: accountIds,
          hypotheticalModel: null,
          hypotheticalModelId: null,
          hypotheticalModelType: hypotheticalModelType,
          isDirectIndexed: false,
          isTaxExempt: false,
        };
      }
    );

    // need to look for accounts that do not have a Trade Group too
    const accountsWithoutTradeGroup = budgetAccountIds.filter((a) =>
      tradingGroups.data.every((tg) =>
        tg.accounts.every((tga) => tga.virtualAccountId !== a.accountId)
      )
    );

    if (accountsWithoutTradeGroup.length > 0) {
      const accountNamesWithoutTradeGroup = accountsWithoutTradeGroup.map(
        (account) => {
          if (!accountIdToName[account.accountId]) {
            throw new Error(
              `account: ${account.accountId} is not in accountIdToName look-up`
            );
          }

          // should always be able to index into accountIdToName with cur_acc.accountId
          return accountIdToName[account.accountId];
        }
      );

      const hypotheticalModelType =
        partners.data.portfolioPartners[0]?.portfolioPartnerName ?? null;

      tradeGroupsWithPortfolioAndAccountNames.push({
        tradingGroupId: NO_TRADE_GROUP_ID as TradingGroupId, // have to do this as we need an id for FE to use - safe bc tradingGroupId is never sent to BE
        modelName: "No current model",
        accountsInPortfolio: combineAccountNames(accountNamesWithoutTradeGroup),
        virtualAccountIds: accountsWithoutTradeGroup.map((a) => a.accountId),
        hypotheticalModel: null,
        hypotheticalModelId: null,
        hypotheticalModelType: hypotheticalModelType,
        isDirectIndexed: false,
        isTaxExempt: false,
      });
    }

    return {
      data: tradeGroupsWithPortfolioAndAccountNames,
      isLoading: false,
      hasError: false,
    };
  }, [
    accounts,
    tradingGroups,
    customPortfolios,
    essentialPortfolios,
    partners,
    excludedVirtualAccountIds,
  ]);

  return result;
}
