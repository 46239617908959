import React from "react";

import { useParams } from "react-router-dom";

import { EquivalentSecurityListId } from "@fartherfinance/frontend/api/Types";

import useEquivalentSecurityListMembersWithInfo from "../useEquivalentSecurityListMembersWithInfo";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import LinkButton from "@src/sharedComponents/LinkButton/LinkButton";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import EquivalentSecuritiesDrawerTable from "./EquivalentSecuritiesDrawerTable";
import EquivalentSecuritiesDrawerTableLoading from "./EquivalentSecuritiesDrawerTableLoading";

import styles from "./EquivalentSecuritiesDrawer.module.css";

const EquivalentSecuritiesDrawerContent: React.FC = () => {
  const { listId } = useParams<{
    listId: EquivalentSecurityListId;
  }>();

  const list = useEquivalentSecurityListMembersWithInfo(listId);

  if (list.hasError) {
    return <div>{list.error}</div>;
  }

  return (
    <div>
      <div className={styles.heading}>
        <div>
          {list.isLoading ? (
            <Skeleton
              width={160}
              height={34}
              style={{ transform: "none", marginBottom: "6px" }}
            />
          ) : (
            <div className={styles.headingTitle}>
              {list.data.name ?? "No name"}
            </div>
          )}

          {list.isLoading ? (
            <Skeleton width={260} height={18} style={{ transform: "none" }} />
          ) : (
            list.data.description && (
              <div className={styles.textSubtle}>{list.data.description}</div>
            )
          )}
        </div>

        <div className={styles.headingEnd}>
          <LinkButton
            text={"Delete"}
            variant="outlined"
            buttonType="warning"
            to={`/Advisor/Admin/EquivalentSecurities/${listId}/Delete`}
            disabled={list.isLoading}
          />

          <LinkButton
            text={"Edit"}
            variant="contained"
            buttonType="primary"
            to={`/Advisor/Admin/EquivalentSecurities/${listId}/Edit`}
            disabled={list.isLoading}
          />
        </div>
      </div>

      <div className={toClassName(styles.headerMargin, styles.textBold)}>
        List of Equivalent Securities
      </div>

      {list.isLoading ? (
        <EquivalentSecuritiesDrawerTableLoading />
      ) : (
        <EquivalentSecuritiesDrawerTable securities={list.data.members ?? []} />
      )}
    </div>
  );
};

export default EquivalentSecuritiesDrawerContent;
