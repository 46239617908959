import React from "react";

import { orderBy } from "lodash";

import { MemberWithInfo } from "../useEquivalentSecurityListMembersWithInfo";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";

import EquivalentSecuritiesDrawerTableRow from "./EquivalentSecuritiesDrawerTableRow";

import styles from "./EquivalentSecuritiesDrawer.module.css";

interface Props {
  securities: MemberWithInfo[];
}

const EquivalentSecuritiesDrawerTable: React.FC<Props> = ({ securities }) => {
  return (
    <BorderBox className={styles.borderBox}>
      {orderBy(securities, (s) => s.ticker, ["asc"]).map((sec) => (
        <EquivalentSecuritiesDrawerTableRow
          key={sec.securityId}
          ticker={sec.ticker}
          description={sec.description}
        />
      ))}
    </BorderBox>
  );
};

export default EquivalentSecuritiesDrawerTable;
