import React, { useMemo, useState } from "react";

import { InputAdornment } from "@mui/material";
import { orderBy, range } from "lodash";

import searchIcon from "@src/assets/svg/search_icon.svg";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import InfoBox from "@src/sharedComponents/InfoBox/InfoBox";
import TextInput from "@src/sharedComponents/TextInput/TextInput";
import { pluralize } from "@src/utils/pluralize";

import EquivalentSecuritiesList from "./EquivalentSecuritiesList";
import EquivalentSecuritiesListLoading from "./EquivalentSecuritiesListLoading";
import useEquivalentSecurityAllMembersWithInfo from "./useEquivalentSecurityAllMembersWithInfo";

import styles from "./EquivalentSecuritiesDrawer.module.css";

const EquivalentSecuritiesDrawerContent: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const listsRequest = useEquivalentSecurityAllMembersWithInfo();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (listsRequest.isLoading || listsRequest.hasError) {
      return;
    }

    setSearchTerm(e.target.value?.toUpperCase() ?? "");
  };

  const eslists = useMemo(() => {
    if (listsRequest.isLoading || listsRequest.hasError) {
      return [];
    }

    if (searchTerm === "") {
      return orderBy(listsRequest.data, [(l) => l.name], ["asc"]);
    }

    const filteredLists = listsRequest.data.filter((list) =>
      list.members.some((sec) =>
        sec.ticker.toUpperCase().startsWith(searchTerm)
      )
    );

    return orderBy(filteredLists, [(l) => l.name], ["asc"]);
  }, [listsRequest, searchTerm]);

  if (listsRequest.hasError) {
    return <div>Error retrieving equivalent security lists</div>;
  }

  return (
    <div>
      <div className={styles.heading}>
        <div className={styles.title}>Lists of Equivalent Securities</div>

        {listsRequest.data && (
          <div className={toClassName(styles.numItems, styles.marginBottom)}>
            {pluralize(eslists, "List", true)}
          </div>
        )}
      </div>

      <div className={styles.textSubtle}>
        If two securities are considered equivalent, Farther's rebalancer will
        attempt to avoid selling one to buy the other.
      </div>

      <InfoBox
        className={styles.infoBox}
        text={
          "The list of equivalent securities is updated when Farther Asset Management issues a rebalance update to our target model portfolios."
        }
      />

      <div className={styles.textSubtle}>Search for Tickers</div>

      <div className={styles.searchContainer}>
        <TextInput
          label={""}
          value={searchTerm}
          onChange={handleOnChange}
          placeholder={""}
          style={{ width: "100%" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"}>
                <img src={searchIcon} />
              </InputAdornment>
            ),
          }}
        />
      </div>

      {listsRequest.isLoading
        ? range(3).map((_, idx) => {
            return <EquivalentSecuritiesListLoading key={idx} />;
          })
        : eslists.map((list) => {
            return <EquivalentSecuritiesList key={list.listId} list={list} />;
          })}
    </div>
  );
};

export default EquivalentSecuritiesDrawerContent;
