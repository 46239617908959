import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { toUserDto } from "../dto";
import { usersRepository } from "../repository";

import type { GetUsersQueryParams, GetUsersResponse } from "../types";

const { getUsers } = usersRepository;

export const useUsersQuery = (
  queryParams?: GetUsersQueryParams,
  options?: UseQueryOptions<
    GetUsersResponse,
    unknown,
    GetUsersResponse,
    (string | GetUsersQueryParams | undefined)[]
  >
) => {
  const query = useQuery(
    [getUsers.queryKey, queryParams],
    async () => {
      const response = await getUsers.queryCall(queryParams);
      return response.data;
    },
    options
  );

  const { data } = query;

  return {
    ...query,
    data: data ? data.values.map(toUserDto) : undefined,
    totalCount: query.data?.totalCount,
  };
};
