import { cloneDeep } from "lodash";
import { Dispatch } from "redux";

import {
  Brand,
  CSSColorPalettes,
  Palette,
  UiTheme,
} from "@src/multiCustodian/reducers/main_Reducer";
import { Appearances } from "@src/multiCustodian/theme/Types";
import { MCReduxAction } from "@src/store";

import setThemeCookie from "./setThemeCookie";

const toggleTheme = (
  UiTheme: UiTheme,
  brand: Brand,
  cssColorPalettes: CSSColorPalettes,
  dispatch: Dispatch<MCReduxAction>,
  changeAppearance: (appearance: Appearances) => void
) => {
  const newTheme =
    UiTheme === "farther_dark" ? "farther_light" : "farther_dark";

  setThemeCookie(newTheme);

  switch (newTheme) {
    case "farther_dark":
      changeAppearance("dark");
      break;

    case "farther_light":
      changeAppearance("light");
      break;

    default:
      const _x: never = newTheme;
  }

  // sets cur theme in redux and sets theme css color palette
  const brandCopy = cloneDeep(brand);

  brandCopy.current = { ...brand[newTheme] };

  const cssColorPalettesCopy = cloneDeep(cssColorPalettes);

  cssColorPalettesCopy.current = { ...cssColorPalettes[newTheme] };

  // Dispatch early so `useSyncTheme` can catch up
  dispatch({
    type: "SET_UI_THEME",
    payload: {
      ui_theme: newTheme,
      ui_themes: null,
      brand: brandCopy,
      css_color_palettes: cssColorPalettesCopy,
    },
  });

  const rootCss: HTMLElement | null = document.querySelector(":root");
  if (rootCss === null) {
    return;
  }

  const cssVars = Object.keys(cssColorPalettesCopy.current);

  cssVars.forEach((cssVar) => {
    const key = cssVar as keyof Palette;
    rootCss.style.setProperty(key, cssColorPalettesCopy.current[key]);
  });
};

export default toggleTheme;
