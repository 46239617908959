import React from "react";

import { useHistory, useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import Modal from "@src/sharedComponents/Modal/Modal";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./DeleteAccountModal.module.css";

interface Props {
  title: string;
  description: string;
}

const LoadingModal = ({ title, description }: Props): JSX.Element => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const history = useHistory();

  return (
    <Modal
      closeModal={() =>
        history.push({
          pathname: `/Advisor/Clients/${clientId}/Admin`,
          state: history.location.state,
        })
      }
      modalStyle={{ width: "520px" }}
    >
      <div className={styles.modalTitle}>{title}</div>

      <div className={styles.modalText}>{description}</div>

      <div className={styles.table}>
        <div className={styles.tableHeader}>
          <Skeleton width={100} />
        </div>

        <div className={styles.tableBody}>
          <div className={styles.tableBodyLeft}>
            <Skeleton width={90} className={styles.tableBodyLeftTop} />

            <Skeleton width={160} className={styles.tableBodyLeftBottom} />
          </div>

          <div className={styles.tableBodyRight}>
            <Skeleton width={60} className={styles.tableBodyRightTop} />

            <Skeleton width={90} className={styles.tableBodyRightBottom} />
          </div>
        </div>
      </div>

      <div className={styles.modalFooter}>
        <Skeleton
          width={70}
          height={35}
          style={{ transform: "none", marginRight: "10px" }}
        />

        <Skeleton width={100} height={35} style={{ transform: "none" }} />
      </div>
    </Modal>
  );
};

export default LoadingModal;
