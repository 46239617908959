import React from "react";

import { Box, Stack, Typography } from "@mui/material";

import { ProposalDetails } from "@fartherfinance/frontend/api/Rebalance/Types";

import { TradingGroupSummary } from "../../../../shared/TradingGroupSummary.component";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";
import { PLACEHOLDER_SIGN } from "@src/yellowstone/modules/shared";
import {
  isMoreThanOneAccountInTradingGroup,
  mapProposalStatusToLabel,
} from "@src/yellowstone/modules/trades";

import { useStyles } from "./ProposalMetadata.styles";
import { ProposalMetadataEntry } from "./ProposalMetadataEntry.component";

interface ProposalMetadataProps {
  proposal: ProposalDetails["proposal"] | undefined;
  activeLiquidations: ProposalDetails["activeLiquidations"] | undefined;
  isHistoricalProposal: boolean;
}

export const ProposalMetadata: React.FC<ProposalMetadataProps> = ({
  proposal,
  activeLiquidations,
  isHistoricalProposal,
}) => {
  const { classes, cx } = useStyles();

  const renderAccountGroupValue = (
    accountProperty: "displayName" | "accountType" | "custodianAccountId"
  ) => {
    if (!proposal) {
      return undefined;
    }

    if (isMoreThanOneAccountInTradingGroup(proposal.tradingGroup)) {
      return (
        <Tooltip
          placement="top"
          tooltipText={
            <TradingGroupSummary
              tradingGroup={proposal.tradingGroup}
              taxLossHarvesting={proposal.tradingGroup.taxLossHarvesting}
            />
          }
        >
          <Typography
            className={cx(classes.label, classes.hoverable, classes.underlined)}
          >
            Group
          </Typography>
        </Tooltip>
      );
    }

    const firstAccount = proposal.tradingGroup.accounts.at(0);

    return firstAccount ? firstAccount.info[accountProperty] : PLACEHOLDER_SIGN;
  };

  return (
    <Box className={classes.container}>
      <ProposalMetadataEntry
        title="Farther User"
        labels={[
          {
            key: "User:",
            value:
              proposal &&
              `${proposal.client.name.first} ${proposal.client.name.last}`,
          },
          { key: "ID:", value: proposal && proposal.client.id },
          {
            key: "Advisor:",
            value:
              proposal &&
              `${proposal.advisor.name.first} ${proposal.advisor.name.last}`,
          },
          {
            key: "Tax Budget:",
            value: proposal && proposal.withTaxBudget ? "Yes" : "No",
          },
          {
            key: "",
            value: proposal && (
              <Tooltip
                placement="top"
                tooltipText={
                  <TradingGroupSummary
                    tradingGroup={proposal.tradingGroup}
                    taxLossHarvesting={proposal.tradingGroup.taxLossHarvesting}
                  />
                }
              >
                <Stack direction="row">
                  <Typography
                    className={cx(
                      classes.label,
                      classes.labelKey,
                      classes.hoverable,
                      classes.underlined
                    )}
                  >
                    Trading Group
                  </Typography>
                </Stack>
              </Tooltip>
            ),
          },
        ]}
      />
      <ProposalMetadataEntry
        title="Farther Account"
        labels={[
          { key: "Account:", value: renderAccountGroupValue("displayName") },
          { key: "Type:", value: renderAccountGroupValue("accountType") },
          {
            key: "Asset location:",
            value: proposal
              ? isMoreThanOneAccountInTradingGroup(proposal.tradingGroup)
                ? "On"
                : "Off"
              : undefined,
          },
        ]}
      />
      <ProposalMetadataEntry
        title="Target Portfolio"
        labels={[
          {
            key: "Portfolio:",
            value: proposal && (
              <Tooltip
                placement="top"
                tooltipText={
                  proposal.targetPortfolio?.fartherName ?? PLACEHOLDER_SIGN
                }
              >
                <Typography
                  className={cx(classes.label, classes.hoverable)}
                  noWrap
                >
                  {proposal.targetPortfolio?.fartherName ?? PLACEHOLDER_SIGN}
                </Typography>
              </Tooltip>
            ),
          },
          {
            key: "ID:",
            value: proposal && (
              <Tooltip
                placement="top"
                tooltipText={
                  proposal.targetPortfolio?.fartherId ?? PLACEHOLDER_SIGN
                }
                maxWidth={400}
              >
                <Typography
                  className={cx(classes.label, classes.hoverable)}
                  noWrap
                >
                  {proposal.targetPortfolio?.fartherId ?? PLACEHOLDER_SIGN}
                </Typography>
              </Tooltip>
            ),
          },
          {
            key: "Tax-loss harvesting:",
            value: proposal
              ? proposal.tradingGroup.taxLossHarvesting
                ? "On"
                : "Off"
              : undefined,
          },
        ]}
      />
      <ProposalMetadataEntry
        title="Account Custodian"
        labels={[
          {
            key: "Custodian:",
            value:
              proposal &&
              (proposal.tradingGroup.accounts.at(0)?.info.custodianName ??
                PLACEHOLDER_SIGN),
          },
          { key: "ID:", value: renderAccountGroupValue("custodianAccountId") },
          { key: "Mutual Funds:", value: PLACEHOLDER_SIGN }, // Yes or No, awaits backend change
        ]}
      />
      <ProposalMetadataEntry
        title="Trading Status"
        labels={[
          {
            key: "Trading:",
            value:
              proposal &&
              (() => {
                const isTradingEnabled = proposal.tradingGroup.isActive;

                return (
                  <Typography
                    className={cx(classes.label, {
                      [classes.flagOn]: isTradingEnabled,
                      [classes.flagOff]: !isTradingEnabled,
                    })}
                  >
                    {isTradingEnabled ? "On" : "Off"}
                  </Typography>
                );
              })(),
          },
          {
            key: "Raising Cash:",
            value:
              proposal &&
              (() => {
                const isRaisingCash =
                  activeLiquidations && activeLiquidations.length > 0;

                if (!isHistoricalProposal) {
                  return (
                    <Typography
                      className={cx(classes.label, {
                        [classes.flagOn]: isRaisingCash,
                        [classes.flagOff]: !isRaisingCash,
                      })}
                    >
                      {isRaisingCash ? "On" : "Off"}
                    </Typography>
                  );
                }

                return (
                  <Typography className={classes.label}>Unknown</Typography>
                );
              })(),
          },
          {
            key: "Status:",
            value: proposal
              ? (proposal.status.internal &&
                  mapProposalStatusToLabel(proposal.status.internal)) ??
                PLACEHOLDER_SIGN
              : undefined,
          },
          { key: "Proposal ID:", value: proposal && proposal.proposalId },
        ]}
      />
    </Box>
  );
};
