import React, { useMemo, useState } from "react";

import {
  Cell,
  Pie,
  PieChart as RechartsPieChart,
  ResponsiveContainer,
} from "recharts";

import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import {
  INNER_RADIUS_PERCENT,
  MINI_CHART,
  OUTER_RADIUS_PERCENT,
} from "./constants";
import CustomTooltip from "./CustomTooltip";
import { PieDataItem } from "./Types";

const PLACEHOLDER_DATA: [PieDataItem] = [{ label: null, value: 1 }]; //value must be above 0 for the placeholder circle to appear

type Props = {
  Equity: number | null;
  FixedIncome: number | null;
  Cash: number | null;
};

const PieChart = (props: Props): JSX.Element => {
  const {
    color: {
      $iconDisabled,
      $chartSegmentOne,
      $chartSegmentTwo,
      $chartSegmentThree,
      $icon,
      $text,
      $textSubtle,
    },
  } = useTheme();

  const data = useMemo(() => {
    let items: PieDataItem[] = [
      { label: "Fixed Income", value: props.FixedIncome },
      { label: "Equity", value: props.Equity },
      { label: "Cash", value: props.Cash },
    ];

    if (props.Cash === null) {
      items = items.filter((item) => item.label !== "Cash");
    }

    if (props.Equity === null) {
      items = items.filter((item) => item.label !== "Equity");
    }

    if (props.FixedIncome === null) {
      items = items.filter((item) => item.label !== "Fixed Income");
    }

    return items;
  }, [props]);

  const noData = data.length === 0;

  const [width, setWidth] = useState<number | null>(null);

  const isMiniChart = (width ?? Number(Infinity)) <= MINI_CHART;

  return (
    <ResponsiveContainer
      width="100%"
      aspect={1}
      onResize={(widthContainer) => {
        setWidth(widthContainer);
      }}
    >
      <RechartsPieChart>
        <Pie
          data={noData ? PLACEHOLDER_DATA : data}
          dataKey="value"
          innerRadius={`${
            isMiniChart ? INNER_RADIUS_PERCENT / 2 : INNER_RADIUS_PERCENT
          }%`}
          outerRadius={`${
            isMiniChart ? OUTER_RADIUS_PERCENT / 2 : OUTER_RADIUS_PERCENT
          }%`}
          stroke={"none"}
          label={
            noData
              ? false
              : (e) => {
                  if (e.value === null) {
                    return;
                  }

                  return CustomTooltip({
                    ...e,
                    labelLineColor: $icon,
                    labelTextColor: $text,
                    percentColor: $textSubtle,
                    isMiniChart: isMiniChart,
                  });
                }
          }
          labelLine={false}
          isAnimationActive={false}
        >
          {noData ? (
            <Cell strokeWidth={3} fill={$iconDisabled} />
          ) : (
            data.map((entry) => (
              <Cell
                strokeWidth={3}
                key={entry.label}
                fill={
                  entry.label === "Equity"
                    ? $chartSegmentOne
                    : entry.label === "Fixed Income"
                    ? $chartSegmentTwo
                    : $chartSegmentThree
                }
              />
            ))
          )}
        </Pie>
      </RechartsPieChart>
    </ResponsiveContainer>
  );
};

export default PieChart;
