import React, { PropsWithChildren } from "react";

import { MarketplaceAssetType } from "@fartherfinance/frontend/api/AltAssetsService/Types";

import { LendingMarketplacePath } from "@src/config/routing/RouterPaths";
import { lendingOfferingTypes } from "@src/multiCustodian/components/Marketplace/constants";

import OfferingsListAdvisor from "./OfferingsListAdvisor";

const LendingOfferingsListClient: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <OfferingsListAdvisor
      assetType={MarketplaceAssetType.enum.lending}
      offeringTypes={lendingOfferingTypes}
      marketplacePath={LendingMarketplacePath}
      pageTitleFallback="Lending"
    >
      {children}
    </OfferingsListAdvisor>
  );
};

export default LendingOfferingsListClient;
