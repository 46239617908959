import React from "react";

import EquivalentSecuritiesDrawer from "@src/multiCustodian/components/Client/EquivalentSecurities/EquivalentSecuritiesDrawer";
import ClientPortfolioAccounts from "@src/multiCustodian/components/Client/Portfolio/Accounts/PortfolioAccounts";

const ClientEquivalentSecurities = (): JSX.Element => {
  return (
    <ClientPortfolioAccounts>
      <EquivalentSecuritiesDrawer />
    </ClientPortfolioAccounts>
  );
};

export default ClientEquivalentSecurities;
