import React, { useMemo } from "react";

import {
  BreakdownItem,
  type PortfolioAssetCategories,
  PortfolioAssetClassBreakdown,
  type PortfolioAssetClasses,
  Sleeve,
} from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";

import { getSecuritiesLabel } from "../../Client/Portfolio/utils/getSecuritiesLabel";

import SleeveTable from "./Table/SleeveTable";

type PortfolioAssetCategory = typeof PortfolioAssetCategories[number];
type PortfolioAssetClass = typeof PortfolioAssetClasses[number];
interface Props {
  category: PortfolioAssetCategory;
  onBack: () => void;
  assetClasses: PortfolioAssetClassBreakdown[];
  setAsset: (
    assetClass: PortfolioAssetClass,
    breakdownItem: BreakdownItem
  ) => void;
  sleeve: Sleeve;
}

const SleeveAssetCategoryView = ({
  category,
  onBack,
  assetClasses,
  setAsset,
  sleeve,
}: Props): JSX.Element => {
  const title = useMemo(() => {
    const securitiesInAssetClasses = assetClasses.flatMap(
      (acObj) => Object.values(acObj)[0].securities
    );

    const secondaryDescription = getSecuritiesLabel(
      securitiesInAssetClasses.length
    );

    return {
      name: category,
      description: category,
      secondaryDescription,
    };
  }, [assetClasses, category]);

  const rows = useMemo(
    () =>
      assetClasses.map((assetClassObj) => {
        const [assetClass, data] = Object.entries(assetClassObj)[0];
        return {
          key: assetClass,
          description: assetClass,
          allocation: data.percent,
          onClick: () => setAsset(assetClass as PortfolioAssetClass, data),
        };
      }),
    [assetClasses, setAsset]
  );

  return (
    <SleeveTable title={title} rows={rows} onBack={onBack} sleeve={sleeve} />
  );
};

export default SleeveAssetCategoryView;
