import { useEffect, useMemo } from "react";

import { useLDClient } from "launchdarkly-react-client-sdk";
import { isNil } from "lodash";
import { useSelector } from "react-redux";

import useClientCustodian from "@fartherfinance/frontend/api/Dashboard/hooks/useClientCustodian";
import useClientDashboard from "@fartherfinance/frontend/api/Dashboard/hooks/useClientDashboard";
import useClientProfile from "@fartherfinance/frontend/api/Entity/hooks/useClientProfile";
import {
  AdvisorId,
  ClientId,
  Custodian,
} from "@fartherfinance/frontend/api/Types";

import { identify, setUserInfo } from "../services/tracking";
import { State } from "@src/store";

import useRequestAuth from "./useRequestAuth";

export function useLaunchDarklyClientGroups(): string[] {
  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));

  const auth = useRequestAuth();

  const dashboard = useClientDashboard(clientId, auth);
  // dashboard.data?.apexClientId is `null` if dashboard.data has loaded or `undefined` if dashboard.data has not loaded
  const isMigratedApexUser = isNil(dashboard.data?.apexClientId) === false;

  const custodian: Custodian | "Multiple" | null =
    useCompressedCustodian(clientId);

  return useMemo(
    () => [
      ...(custodian !== null ? [custodian] : []),
      ...(isMigratedApexUser ? ["MigratedApexClient"] : []),
    ],
    [custodian, isMigratedApexUser]
  );
}

export default function useAPMClientIdentify(): void {
  const { clientId, isAdvisor, advisorId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
    isAdvisor: state.main_Reducer.user.isAdvisor,
    advisorId: state.main_Reducer.cockroach_advisor_id ?? undefined,
  }));
  const auth = useRequestAuth();

  const clientProfile = useClientProfile(clientId, auth);

  const ldClient = useLDClient();
  const dashboard = useClientDashboard(clientId, auth);

  // dashboard.data?.apexClientId is `null` if dashboard.data has loaded or `undefined` if dashboard.data has not loaded
  const isMigratedApexUser = isNil(dashboard.data?.apexClientId) === false;

  const ldGroups = useLaunchDarklyClientGroups();

  const custodian: Custodian | "Multiple" | null =
    useCompressedCustodian(clientId);

  useEffect(() => {
    if (clientId === null) {
      return;
    }

    if (isAdvisor === true) {
      return;
    }

    identify(clientId);

    if (clientProfile.data !== undefined && dashboard.data !== undefined) {
      if (ldClient && custodian !== undefined) {
        const groups: string[] = ["client", ...ldGroups];

        console.log("LD Groups", groups);

        const myAdvisorsId = dashboard.data.advisor.advisorId;

        ldClient.identify({
          kind: "user",
          key: clientId,
          firstName:
            clientProfile.data.investorProfile.personalDetails.name.first,
          lastName:
            clientProfile.data.investorProfile.personalDetails.name.last,
          email:
            clientProfile.data.investorProfile.contactInformation.emailAddress,
          groups: groups,
          custodian: custodian,
          // Send this as an array so we can handle multiple advisor clients in the future
          advisorIds: myAdvisorsId ? [myAdvisorsId] : [],
        });
      }

      setUserInfo({
        id: clientId,
        email:
          clientProfile.data.investorProfile.contactInformation.emailAddress,
        name: `${clientProfile.data.investorProfile.personalDetails.name.first} ${clientProfile.data.investorProfile.personalDetails.name.last}`,
        phone:
          clientProfile.data.investorProfile.contactInformation.phoneNumber ??
          undefined,
        firstName:
          clientProfile.data.investorProfile.personalDetails.name.first,
        lastName: clientProfile.data.investorProfile.personalDetails.name.last,
        created:
          clientProfile.data.investorProfile.personalDetails.joinedDate?.toISOString(),
        city: clientProfile.data.investorProfile.address?.city,
        state: clientProfile.data.investorProfile.address?.state,
        advisorId: dashboard.data.advisor.advisorId as AdvisorId,
        advisorName: `${dashboard.data.advisor.name.first} ${dashboard.data.advisor.name.last}`,
        dateOfBirth:
          clientProfile.data.investorProfile.personalDetails.dateOfBirth ??
          undefined,
        joinedDate:
          clientProfile.data.investorProfile.personalDetails.joinedDate?.toISOString() ??
          undefined,
        impersonatedById: advisorId,
      });
    }
  }, [
    advisorId,
    clientId,
    clientProfile.data,
    custodian,
    dashboard.data,
    isAdvisor,
    isMigratedApexUser,
    ldClient,
    ldGroups,
  ]);
}

function useCompressedCustodian(
  clientId: ClientId | null
): Custodian | "Multiple" | null {
  const auth = useRequestAuth();

  const custodianRes = useClientCustodian(clientId, auth);
  const dashboard = useClientDashboard(clientId, auth);

  const custodian: Custodian | "Multiple" | null = useMemo(() => {
    if (custodianRes.data === undefined) {
      return null;
    }

    const custodianType = custodianRes.data.type;

    switch (custodianType) {
      case "SingleFullCustodian":
        return custodianRes.data.custodian;

      case "MultipleFullCustodians":
        return "Multiple";

      case "Limited": {
        if (dashboard.data === undefined) {
          return null;
        }

        // Inside SL We don't return `defaultCustodian` for "Limited" if it's a limited custodian, here we don't care
        return dashboard.data.defaultCustodian;
      }

      default: {
        const _x: never = custodianType;
        throw new Error(
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `[useCompressedCustodian] - Unknown custodian type: ${_x}`
        );
      }
    }
  }, [custodianRes.data, dashboard.data]);

  return custodian;
}
