import React, { useCallback, useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import { ClickAwayListener } from "@mui/material";

import useTradingGroup from "@fartherfinance/frontend/api/TradingGroups/hooks/useTradingGroup";
import useUpdateTradingGroupV1_5 from "@fartherfinance/frontend/api/TradingGroups/hooks/useUpdateTradingGroupV1_5";
import {
  ClientId,
  PortfolioId,
  TradingGroupId,
} from "@fartherfinance/frontend/api/Types";
import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import TextInput from "@src/sharedComponents/TextInput/TextInput";

import styles from "./EditableTradingGroupName.module.css";

interface Props {
  clientId: ClientId | null;
  portfolioId: PortfolioId;
  tradingGroupId: TradingGroupId;
}

const MAX_NAME_LENGTH = 60;

const EditableTradingGroupName = (props: Props): JSX.Element => {
  const [editingName, setEditingName] = useState<boolean>(false);
  const [newTradingGroupName, setNewTradingGroupName] = useState<string>("");
  const [isMutating, setIsMutating] = useState(false);
  const [hoveringOverInput, setHoveringOverInput] = useState<boolean>(false);

  const {
    color: { $text, $textSuccess },
  } = useTheme();

  const statusNotification = useStatusNotification();

  const auth = useRequestAuth();
  const tradingGroup = useTradingGroup(props.tradingGroupId, auth);
  const { updateTradingGroup, state: updateTradingGroupReqState } =
    useUpdateTradingGroupV1_5(props.clientId, auth);

  const editName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= MAX_NAME_LENGTH) {
      setNewTradingGroupName(e.target.value);
    }
  };

  const onKeyPressEditNewPlanName = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      saveNewTradingGroupName();
    }
  };

  const currentTradingGroupName =
    tradingGroup.data?.displayName ?? "New Trading Group";

  const tradingGroupName = editingName
    ? newTradingGroupName
    : currentTradingGroupName;

  const isTradingGroupNameValid = tradingGroupName.length > 3;

  const saveNewTradingGroupName = async () => {
    if (
      !isTradingGroupNameValid ||
      !tradingGroup.data ||
      tradingGroup.data.displayName === tradingGroupName ||
      isMutating
    ) {
      setEditingName(false); //If any of the above is true, we don't update the plan name, we just exit edit mode.
    } else {
      if (updateTradingGroupReqState !== "Ready") {
        statusNotification(
          "Please wait a few seconds for data to load",
          "Error"
        );
        return;
      }

      const body = {
        displayName: newTradingGroupName,
        accounts: [
          ...tradingGroup.data.accounts.map(
            (tgAcct) => tgAcct.virtualAccountId
          ),
        ],
        portfolioId: props.portfolioId,
        isActive: tradingGroup.data.isActive,
        directIndexing: tradingGroup.data.directIndexing,
        taxLossHarvesting: tradingGroup.data.taxLossHarvesting,
        equivalentSecuritiesEnabled:
          tradingGroup.data.equivalentSecuritiesEnabled,
      };

      try {
        setIsMutating(true);
        await updateTradingGroup({
          tradingGroupId: props.tradingGroupId,
          body: body,
        });
        statusNotification("Name updated", "Success");
      } catch (e) {
        console.error(e);
        statusNotification("Error updating name", "Error");
      } finally {
        setIsMutating(false);
        setEditingName(false);
      }
    }
  };

  const enableEditName = () => {
    if (tradingGroup.data === undefined) {
      return statusNotification(
        "Cannot edit name for non-existent trading group.",
        "Error"
      );
    }

    if (editingName) {
      return;
    }

    setNewTradingGroupName(currentTradingGroupName);
    setEditingName(true);
  };

  const cancelEditing = useCallback(() => {
    if (!editingName) {
      return;
    }

    setEditingName(false);
    setNewTradingGroupName("");
  }, [editingName]);

  if (isMutating) {
    return <Skeleton width={60} />;
  }

  return (
    <ClickAwayListener mouseEvent={"onMouseDown"} onClickAway={cancelEditing}>
      <div
        className={styles.editablePlanName_title}
        onMouseEnter={() => setHoveringOverInput(true)}
        onMouseLeave={() => setHoveringOverInput(false)}
      >
        {editingName ? (
          <>
            <TextInput
              value={newTradingGroupName}
              onChange={editName}
              onKeyDown={onKeyPressEditNewPlanName}
              error={!isTradingGroupNameValid}
              helperText={
                !isTradingGroupNameValid
                  ? "Name must have more than 3 letters"
                  : ""
              }
              style={{ width: "250px", color: $text }}
              inputProps={{ style: { fontSize: 14 } }}
              disabled={!tradingGroup.data || !editingName}
            />

            <CheckCircleIcon
              className={styles.editablePlanName_edit_icon}
              style={{ color: $textSuccess }}
              onClick={saveNewTradingGroupName}
            />

            <CancelIcon
              className={styles.editablePlanName_edit_icon}
              style={{ color: $text }}
              onClick={cancelEditing}
            />
          </>
        ) : (
          <div onClick={() => enableEditName()}>{tradingGroupName}</div>
        )}

        {hoveringOverInput && !editingName && (
          <EditIcon
            className={styles.editablePlanName_edit_icon}
            onClick={enableEditName}
          />
        )}
      </div>
    </ClickAwayListener>
  );
};

export default EditableTradingGroupName;
