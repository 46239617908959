import React, { useState } from "react";

import { AccountType } from "@fartherfinance/frontend/api/Transfer/requests/Types";

import Input_Component from "../../../../../components/Input/Input_Component_2";
import ButtonPrimary from "../../../../../components/MUI/Button/Button";
import {
  AcatsInstitutionInfo,
  IraType,
  SetScreenAndTransferType,
  TransferScreen,
} from "../../Types";

import "../css.css";

const neutral_underline_style = {
  borderBottom: "1px solid var(--neutral_input_underline_color_1)",
  width: "100%",
};

type Props = (
  | {
      transferType: "roll over";
      iraType: IraType;
    }
  | { transferType: "transfer" }
) & {
  screen: TransferScreen["screen"];
  acatsInstitution: AcatsInstitutionInfo;
  acatsAccountType: AccountType;
  setCurScreen: SetScreenAndTransferType;
};

const AccountInformation = (props: Props): JSX.Element => {
  const [accountNumber, setAccountNumber] = useState<string>("");

  const [accountHolderName, setAccountHolderName] = useState<string>("");

  const accountHolderOk = accountHolderName.length >= 3;
  const accountNumberOk = accountNumber.length >= 3;

  const onContinue = () => {
    if (!accountHolderOk || !accountNumberOk) {
      return;
    }

    const { setCurScreen: _, screen: _s, ...screenInfo } = props;

    if ("iraType" in screenInfo) {
      props.setCurScreen({
        screen: "IRA/transferDestination",
        accountHolderName,
        accountNumber,
        ...screenInfo,
      });
    } else {
      props.setCurScreen({
        screen: "Investment/transferDestination",
        accountHolderName,
        accountNumber,
        ...screenInfo,
      });
    }
  };

  return (
    <div className="Dashboard_Acats_pages__container">
      <div className="Dashboard_Acats_pages__content_container">
        <p className="Dashboard_Acats_pages__title">
          Provide your account details
        </p>

        <p className="Dashboard_Acats_pages__summary">
          To assist with the {props.transferType}, we just need two pieces of
          information surrounding your{" "}
          {"iraType" in props ? props.iraType : "transfer"}: the account number
          and the account holder name.
        </p>

        <div className="Dashboard_Acats_pages__caution_div">
          <div className="Dashboard_Acats_pages__caution_inner_left_div">
            <img
              className="Dashboard_Acats_pages__caution_img"
              src="/src/assets/svg/gold_circ_!.svg"
            />
          </div>
          <p className="Dashboard_Acats_pages__text_2">
            {`Remember to provide the exact full name that appears on the account,
            as this is critical to successfully initiating the ${props.transferType}`}
            .
          </p>
        </div>

        <Input_Component
          title={"Account Number"}
          title_img={"/src/assets/svg/briefcase.svg"}
          name={"account_number"}
          type={"text"}
          placeholder={"123456789"}
          value={accountNumber}
          validation_img={
            accountNumberOk
              ? "/src/assets/svg/check_green.svg"
              : "/src/assets/svg/!.svg"
          }
          show_checkmark={accountNumberOk}
          underline_style={
            accountNumberOk ? neutral_underline_style : neutral_underline_style
          }
          error_description={undefined}
          handleInputChange={(e) => setAccountNumber(e.target.value)}
        />

        <Input_Component
          title={"Account Holder Name"}
          title_img={"/src/assets/svg/user.svg"}
          name={"account_holder"}
          type={"text"}
          placeholder={"Jay R Bartholomew"}
          value={accountHolderName}
          validation_img={
            accountHolderOk
              ? "/src/assets/svg/check_green.svg"
              : "/src/assets/svg/!.svg"
          }
          show_checkmark={accountHolderOk}
          underline_style={
            accountHolderOk ? neutral_underline_style : neutral_underline_style
          }
          error_description={undefined}
          handleInputChange={(e) => setAccountHolderName(e.target.value)}
        />

        <div className="Dashboard_Acats_pages__button_container_2">
          <div
            className="Dashboard_Acats_pages__back_button_container"
            onClick={() => {
              const { setCurScreen: _, screen: _s, ...screenInfo } = props;

              if ("iraType" in screenInfo) {
                props.setCurScreen({
                  screen: "IRA/institutionSelection",
                  ...screenInfo,
                });
              } else {
                props.setCurScreen({
                  screen: "Investment/institutionSelection",
                  ...screenInfo,
                });
              }
            }}
          >
            <img src="/src/assets/svg/left_arrow.svg"></img>
            <div className="Dashboard_Acats_pages__back_button">Back</div>
          </div>

          <ButtonPrimary
            disabled={!accountHolderOk || !accountNumberOk}
            variant={"contained"}
            buttonType={"primary"}
            text={"Continue"}
            onClick={() => onContinue()}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountInformation;
