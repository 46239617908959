import { buttonClasses } from "@mui/material/Button";
import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

export const useStyles = makeStyles()((theme) => ({
  button: {
    [`&.${buttonClasses.root}`]: {
      fontFamily: theme.typography.fontSecondary,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.fontSize,
      lineHeight: "18px",
      letterSpacing: "0.02em",
      borderRadius: theme.shape.borderRadius,

      [`&.${buttonClasses.contained}`]: {
        color: theme.appearance.$textInverse,
      },

      [`&.${buttonClasses.outlined}`]: {
        color: theme.appearance.$text,
      },

      [`&.${buttonClasses.text}`]: {
        color: theme.palette.common.white,
      },
    },

    /**
     * Sizes
     */
    [`&.${buttonClasses.sizeSmall}`]: {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: "16px",
      padding: theme.spacing(pxToSpacing(7), pxToSpacing(20)),
    },
    [`&.${buttonClasses.sizeLarge}`]: {
      padding: theme.spacing(pxToSpacing(10), pxToSpacing(20)),
    },

    /**
     * Variant Contained
     */
    [`&.${buttonClasses.contained}`]: {
      backgroundColor: theme.appearance.$backgroundSelected,

      "&:hover": {
        backgroundColor: theme.colors.primary.shades.cream70,
      },
      "&:active": {
        backgroundColor: theme.colors.primary.tints.navy50,
      },
    },

    [`&.${buttonClasses.contained}.${buttonClasses.containedSuccess}`]: {
      color: theme.appearance.$text,
      backgroundColor: theme.appearance.$backgroundSelectedBold,

      "&:hover": {
        backgroundColor: "#2EAD33", // TODO: update global theme values - accent green 80%
      },
      "&:active": {
        backgroundColor: theme.colors.accent.shades.parakeetGreen50,
      },
    },

    [`&.${buttonClasses.contained}.${buttonClasses.disabled}`]: {
      color: theme.appearance.$textInverse,
      backgroundColor: theme.appearance.$backgroundDisabled,
    },

    /**
     * Variant Outlined
     */
    [`&.${buttonClasses.outlined}`]: {
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.colors.primary.base.cream,

      "&:hover": {
        color: theme.appearance.$textInverse,
        borderColor: "transparent",
        backgroundColor: theme.colors.primary.shades.cream80,
      },

      "&:active": {
        color: theme.appearance.$textInverse,
        borderColor: "transparent",
        backgroundColor: theme.colors.primary.tints.navy50,
      },
    },

    [`&.${buttonClasses.outlined}.${buttonClasses.disabled}`]: {
      color: theme.appearance.$textDisabled,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.appearance.$borderDisabled,
    },

    /**
     * Variant Text
     */
    [`&.${buttonClasses.text}`]: {
      "&:hover": {
        backgroundColor: theme.appearance.$backgroundNeutral,
      },
    },
  },
  normalText: {
    textTransform: "none",
  },
}));
