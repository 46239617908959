import React from "react";

import CircleIcon from "@mui/icons-material/Circle";

import { primaryBaseColors } from "@fartherfinance/frontend/theme/Farther/colors";
import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import { CellItem } from "../Types";

import styles from "./ChipCell.module.css";

interface Props {
  item: CellItem;
  showBorder: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const ChipCell = ({ item, showBorder, onClick, style }: Props): JSX.Element => {
  const {
    color: { $borderBold },
  } = useTheme();

  return (
    <div
      className={styles.cell}
      onClick={onClick}
      style={{
        border: `1px solid ${showBorder ? $borderBold : "inherit"}`,
        ...style,
      }}
    >
      <div
        className={styles.circleIconBackground}
        style={{ backgroundColor: primaryBaseColors.navy }}
      >
        <CircleIcon className={styles.circleIcon} sx={{ color: item.color }} />
      </div>

      <div className={styles.name}>{item.name}</div>

      <div className={styles.percent}>
        {item.percent.toLocaleString("en-US", {
          style: "percent",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </div>
    </div>
  );
};

export default ChipCell;
