import React, { useCallback, useMemo } from "react";

import { format } from "date-fns";
import { useHistory } from "react-router-dom";

import useAdvisorClients from "@fartherfinance/frontend/api/Entity/hooks/useAdvisorClients";
import useFartherEmployees from "@fartherfinance/frontend/api/Entity/hooks/useFartherEmployees";
import type { Client } from "@fartherfinance/frontend/api/Entity/requests/getAdvisorClients";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import formatPhoneNumber from "../ClientProfile/Forms/utils/formatPhoneNumber";
import { unknownBalanceValue } from "@src/constants/unknownBalanceValue";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import { captureException } from "@src/multiCustodian/services/tracking";
import { toPercentage } from "@src/multiCustodian/utils/commonFunctions";
import { consecutiveSpaces } from "@src/regex";
import { CheckboxListConfig } from "@src/sharedComponents/ScrollableCheckboxList/ScrollableCheckboxList";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import FullDataTable from "@src/sharedComponents/Table/FullDataTable";
import {
  Cell,
  Row,
  SophisticatedCell,
} from "@src/sharedComponents/Table/Types";

import EmptyButton from "./EmptyButton";
import ImpersonateClientButton from "./ImpersonateClientButton";

import styles from "./ClientsTable.module.css";

const EMPTY_ROW = {
  key: "emptyRow",
  Name: {
    value: "No clients found",
  },
  ID: { value: "" },
  Advisor: { value: "" },
  Email: { value: "" },
  Mobile: { value: "" },
  Created: { value: "" },
  Login: { value: "" },
};

const LOADING_ROW = {
  key: "loading",
  Name: { value: <Skeleton /> },
  ID: { value: <Skeleton /> },
  Advisor: { value: <Skeleton /> },
  Email: { value: <Skeleton /> },
  Mobile: { value: <Skeleton /> },
  Created: { value: <Skeleton /> },
  Login: { value: <Skeleton /> },
};

const TableKeys = [
  "Name",
  "Email",
  "Mobile",
  "ID",
  "Advisor",
  "Created",
  "Login",
] as const;
type TableKeys = typeof TableKeys[number];

type ClientTableKeys = Exclude<TableKeys, "ID" | "Login">;

export type ClientSearchFiltersCheckboxLabel = ClientTableKeys | "Client ID"; // "Client ID" replaces ID from TableKeys

interface ClientWithAdvisorName extends Client {
  advisorName: {
    first: string;
    last: string;
  };
}

interface Props {
  search: string;
  searchFilters: CheckboxListConfig<ClientSearchFiltersCheckboxLabel>;
}

const ClientsTable: React.FC<Props> = ({ search, searchFilters }) => {
  const history = useHistory();

  const auth = useAdvisorRequestAuth();
  const advisorClients = useAdvisorClients(undefined, auth);
  const advisors = useFartherEmployees("All", auth);

  const openClientDetails = useCallback(
    (clientId: ClientId) => {
      const location = history.location;
      history.push({ ...location, pathname: `/Advisor/Clients/${clientId}` });
    },
    [history]
  );

  const searchedData = useMemo(() => {
    const clientsWithAdvisorNames: ClientWithAdvisorName[] = (
      advisorClients.data?.clients ?? []
    ).map((c): ClientWithAdvisorName => {
      const listOfAdvisors = advisors.data?.advisors;
      if (listOfAdvisors === undefined) {
        return {
          ...c,
          advisorName: {
            first: "-",
            last: "-",
          },
        };
      }

      const advisor = listOfAdvisors.find((a) => a.advisorId === c.advisorId);

      if (advisor === undefined) {
        const e = new Error(
          "[Clients.tsx]: Client has an advisorId that does not correlate to any existing Farther employee. This client was omitted from the list of clients."
        );

        captureException(e, {
          extra: {
            clientId: c.clientId,
            advisorId: c.advisorId,
            listOfAdvisorsLength: listOfAdvisors.length,
            listOfAdvisors: listOfAdvisors.map((a) => ({
              advisorId: a.advisorId,
              name: `${a.name.first} ${a.name.last}`,
            })),
          },
        });

        return {
          ...c,
          advisorName: {
            first: "-",
            last: "-",
          },
        };
      }

      return {
        ...c,
        advisorName: {
          first: advisor.name.first,
          last: advisor.name.last,
        },
      };
    });

    const filteredMapped = clientsWithAdvisorNames
      .filter((c) => {
        const lowercaseSearch = search
          .toLowerCase()
          .replace(consecutiveSpaces, " ");

        const clientFullName = `${c.name.first.toLowerCase()} ${c.name.last.toLowerCase()}`;
        const advisorFullName = `${c.advisorName.first.toLowerCase()} ${c.advisorName.last.toLowerCase()}`;

        if (
          searchFilters.Name.checked &&
          clientFullName.includes(lowercaseSearch)
        ) {
          return true;
        }

        if (
          searchFilters.Email.checked &&
          c.emailAddress.toLowerCase().includes(lowercaseSearch)
        ) {
          return true;
        }

        if (
          searchFilters.Mobile.checked &&
          (c.phoneNumber ?? unknownBalanceValue)?.includes(search)
        ) {
          return true;
        }

        if (searchFilters["Client ID"].checked && c.clientId.includes(search)) {
          return true;
        }

        if (
          searchFilters.Advisor.checked &&
          advisorFullName.includes(lowercaseSearch)
        ) {
          return true;
        }

        if (
          searchFilters.Created.checked &&
          format(c.registeredTime, "M/d/yyyy").includes(search)
        ) {
          return true;
        }

        return false;
      })
      .map(
        (c): Row<TableKeys, Cell> => ({
          // the keys of the object here are the column names

          key: c.clientId,
          ID: c.clientId,
          Email: {
            value: c.emailAddress,
            fullValue: c.emailAddress.toLowerCase(),
          },
          Name: `${c.name.first} ${c.name.last}`,
          Mobile: formatPhoneNumber(c.phoneNumber ?? unknownBalanceValue),
          Created: {
            value: format(c.registeredTime, "M/d/yyyy"),
            fullValue: c.registeredTime.toISOString(),
          },
          Advisor: `${c.advisorName.first} ${c.advisorName.last}`,
          Login: {
            value: (
              <ImpersonateClientButton
                clientId={c.clientId}
                clientEmail={c.emailAddress}
                clientName={c.name}
              />
            ),
            hideUnlessHovering: true,
          } as SophisticatedCell,
          onClick: () => openClientDetails(c.clientId),
        })
      );

    return filteredMapped;
  }, [
    advisorClients.data?.clients,
    advisors.data?.advisors,
    openClientDetails,
    search,
    searchFilters,
  ]);

  const rows = useMemo(() => {
    if (searchedData.length > 0) {
      return searchedData;
    }

    return advisorClients.isLoading || advisors.isLoading
      ? Array.from({ length: 10 }, (_, idx) => idx).map((idx) => ({
          ...LOADING_ROW,
          key: `loading-${idx}`,
        }))
      : [EMPTY_ROW];
  }, [advisorClients.isLoading, advisors.isLoading, searchedData]);

  if (advisorClients.hasError) {
    return (
      <div className={styles.errorContainer}>Error loading client list</div>
    );
  }

  return (
    <FullDataTable
      isLoading={advisorClients.isLoading || advisors.isLoading}
      columns={TableKeys}
      rows={rows}
      disableColumnSorting={
        searchedData.length > 0 ? ["Login", "Mobile"] : TableKeys
      }
      defaultRowsPerPage={10}
      defaultSortColumn={["Created", "desc"]}
      emptyCell={<EmptyButton />} // This is the highest cell we will have
    >
      <colgroup>
        {TableKeys.slice(0, -1).map((key) => (
          <col key={key} width={toPercentage(90 / 6)} />
        ))}
        <col width="10%" />
      </colgroup>
    </FullDataTable>
  );
};

export default ClientsTable;
