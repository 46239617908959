import React, { useCallback, useRef } from "react";

import { isAxiosError } from "axios";
import { useHistory, useLocation, useParams } from "react-router-dom";

import useDeleteTaxBudget from "@fartherfinance/frontend/api/InvestmentModel/hooks/useDeleteTaxBudget";
import { TaxBudget } from "@fartherfinance/frontend/api/InvestmentModel/Types";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import BudgetOptionsPopOver from "../BudgetOptionsPopOver/BudgetOptionsPopOver";
import DeleteBudgetModal from "../DeleteBudgetModal/DeleteBudgetModal";
import { ErrorMessage } from "../reducer/types";
import UsedBudget from "../UsedBudget/UsedBudget";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { trackEvent } from "@src/multiCustodian/services/tracking";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";
import BorderBoxBody from "@src/sharedComponents/BorderBox/BorderBoxBody";
import BorderBoxHeader from "@src/sharedComponents/BorderBox/BorderBoxHeader";

import styles from "./Row.module.css";

interface Props {
  budget: TaxBudget;
}

const CurrentTaxBudgetRow: React.FC<Props> = ({ budget }) => {
  const { year } = budget;

  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const history = useHistory();

  const { pathname } = useLocation();

  const statusNotification = useStatusNotification();

  const anchorRef = useRef<HTMLDivElement | null>(null);

  const advisorAuth = useAdvisorRequestAuth();

  const clientAuth = useRequestAuth();

  const auth = advisorAuth
    ? { ...advisorAuth, clientId: clientId }
    : clientAuth;

  const callDeleteTaxBudget = useDeleteTaxBudget(auth);

  const [popOverIsOpen, setPopOverIsOpen] = React.useState(false);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const isAdvisor = pathname.startsWith("/Advisor");

  const handleOnClick = useCallback(() => {
    const budgetPath = isAdvisor
      ? `/Advisor/Clients/${clientId}/TaxBudgets/${budget.budgetId}/Usage`
      : `/Client/${clientId}/TaxBudgets/${budget.budgetId}/Usage`;

    history.push({
      ...history.location,
      pathname: budgetPath,
    });
  }, [budget.budgetId, clientId, history, isAdvisor]);

  const toggleModal = useCallback(() => setIsModalOpen((prev) => !prev), []);

  const togglePopOver = useCallback(
    () => setPopOverIsOpen((prev) => !prev),
    []
  );

  const handleOnConfirm = useCallback(async () => {
    try {
      await callDeleteTaxBudget(budget.budgetId);

      trackEvent({
        name: isAdvisor
          ? "Advisor Complete Delete Tax-Budget"
          : "Client Complete Delete Tax-Budget",
      });

      statusNotification("Tax Budget Deleted", "Success");

      toggleModal();
    } catch (error) {
      if (isAxiosError<ErrorMessage>(error)) {
        const message =
          error.response?.data.message ??
          "Unexpected error from investment model service.";

        return statusNotification(message, "Error");
      }

      statusNotification("Something went wrong. Please try again.", "Error");
    }
  }, [
    budget.budgetId,
    callDeleteTaxBudget,
    statusNotification,
    toggleModal,
    isAdvisor,
  ]);

  const handleOnDelete = useCallback(() => {
    trackEvent({
      name: isAdvisor
        ? "Advisor Open Delete Tax-Budget"
        : "Client Open Delete Tax-Budget",
    });

    togglePopOver();
    toggleModal();
  }, [toggleModal, togglePopOver, isAdvisor]);

  const handleOnEdit = useCallback(() => {
    trackEvent({
      name: isAdvisor
        ? "Advisor Open Edit Tax-Budget"
        : "Client Open Edit Tax-Budget",
    });

    togglePopOver();

    const budgetPath = isAdvisor
      ? `/Advisor/Clients/${clientId}/TaxBudgets/${budget.budgetId}/Edit`
      : `/Client/${clientId}/TaxBudgets/${budget.budgetId}/Edit`;

    history.push({
      ...history.location,
      pathname: budgetPath,
    });
  }, [budget.budgetId, clientId, history, isAdvisor, togglePopOver]);

  return (
    <>
      <BorderBox>
        <BorderBoxHeader
          start={<div className={styles.taxYear}>{`${year} Tax Year`}</div>}
          end={
            <div className={styles.actions}>
              <BudgetOptionsPopOver
                anchorRef={anchorRef}
                isOpen={popOverIsOpen}
                onClose={togglePopOver}
                onDelete={handleOnDelete}
                onEdit={handleOnEdit}
                onOpen={togglePopOver}
                size="small"
              />

              <Button
                buttonType="secondary"
                onClick={handleOnClick}
                size="small"
                text="View"
                variant="outlined"
              />
            </div>
          }
        />

        <BorderBoxBody showTopBorder={true}>
          <div className={styles.container}>
            <UsedBudget budget={budget} />
          </div>
        </BorderBoxBody>
      </BorderBox>

      <DeleteBudgetModal
        budget={budget}
        isOpen={isModalOpen}
        onClose={toggleModal}
        onConfirm={handleOnConfirm}
      />
    </>
  );
};

export default CurrentTaxBudgetRow;
