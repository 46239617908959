import { alpha } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles<{ columnsCount: number }, "noHover">()(
  (theme, { columnsCount }, classes) => ({
    row: {
      display: "grid",
      // NOTE: when CSS Grid Layout level 2 is supported we can exchange this value to 'subgrid'
      // to match parent grid settings, for now it's has to be copied 1:1
      gridTemplateColumns: `repeat(${columnsCount}, 1fr)`,
      gridColumn: `1 / ${columnsCount + 1}`,

      borderBottomWidth: 1,
      borderBottomStyle: "solid",
      borderBottomColor: alpha(theme.appearance.$borderSelected, 0.1),

      transition: theme.transitions.create(["background"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.standard,
      }),

      [`&:not(.${classes.noHover}):hover`]: {
        backgroundColor: alpha(theme.palette.common.white, 0.05),
      },
    },
    noHover: {},
  })
);
