import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { ceil, clamp } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";

import useDeletePlanV2 from "@fartherfinance/frontend/api/Accounts/hooks/useDeletePlanV2";
import usePlansV2 from "@fartherfinance/frontend/api/Accounts/hooks/usePlansV2";
import { Plan } from "@fartherfinance/frontend/api/Accounts/Types";

import LogoLoadingStill from "../../../../sharedComponents/LogoLoadingStill/LogoLoadingStill";
import { MCReduxAction, State as ReduxState } from "../../../../store";
import alertMessageModal from "@src/multiCustodian/components/Modals/AlertMessageModal";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";

import { filterByNameHelper } from "./utils/searchFilter";

const PLANS_PER_PAGE = 10;

interface IncomingProps {
  startPage?: number;
  isNewPlanBeingCreated: boolean;
  plansFilterStr: string;
  setPlanBeingDeleted: (bool: boolean) => void;
  setAlertMessage: (
    alert: ComponentProps<typeof alertMessageModal> | null
  ) => void;
}

const PlansTable = (props: IncomingProps): JSX.Element => {
  const lastSearch = useRef<string | null>(null);
  // page starts at 1
  const [page, setPage] = useState<number>(props.startPage ?? 1);

  const statusNotification = useStatusNotification();

  const auth = useAdvisorRequestAuth();

  const callDeletePlan = useDeletePlanV2(auth);

  const plans = usePlansV2(auth);

  const startingPlanValue = (page - 1) * PLANS_PER_PAGE;

  const history = useHistory();

  // When a new search is started, go back to page 1
  useEffect(() => {
    if (
      page !== 1 &&
      lastSearch.current !== props.plansFilterStr &&
      lastSearch.current !== null
    ) {
      setPage(1);
    }

    lastSearch.current = props.plansFilterStr;
  }, [page, props.plansFilterStr]);

  const { plansToDisplay, plansToDisplayFilteredTotal, endingPlanValue } =
    useMemo(() => {
      if (plans.isLoading || plans.data === undefined || plans.hasError) {
        return {
          plansToDisplay: [],
          plansToDisplayFilteredTotal: 0,
          endingPlanValue: 0,
        };
      }

      const planList = plans.data;

      let plansToDisplay = planList;

      if (props.plansFilterStr) {
        plansToDisplay = planList.filter((plan) => {
          const filterByTitle = plan.planTitle
            .toLowerCase()
            .startsWith(props.plansFilterStr.toLowerCase());

          const filterByName = filterByNameHelper(
            plan.planHolder.name.first,
            plan.planHolder.name.last,
            props.plansFilterStr.toLowerCase()
          );

          return filterByTitle || filterByName;
        });
      }

      const plansToDisplayFilteredTotal = plansToDisplay.length;

      plansToDisplay = plansToDisplay.slice(
        startingPlanValue,
        startingPlanValue + PLANS_PER_PAGE
      );

      const endingPlanValue = plansToDisplay.length + startingPlanValue;

      return {
        plansToDisplay,
        plansToDisplayFilteredTotal,
        endingPlanValue,
        startingPlanValue,
      };
    }, [plans, props.plansFilterStr, startingPlanValue]);

  const maxPages = ceil(plansToDisplayFilteredTotal / PLANS_PER_PAGE);

  const plansPaginateForward = () => {
    setPage(clamp(page + 1, 1, maxPages));
  };

  const plansPaginateBackward = () => {
    setPage(clamp(page - 1, 1, maxPages));
  };

  const dispatch = useDispatch<Dispatch<MCReduxAction>>();
  const set_is_in_a_modal = useCallback(
    (bool: boolean) => {
      dispatch({
        type: "SET_IS_IN_A_MODAL",
        payload: {
          is_in_a_modal: bool,
        },
      });
    },
    [dispatch]
  );

  const deleteAlert = useCallback(
    (plan: Plan) => {
      props.setAlertMessage({
        top_text: () => (
          <p>
            Are you sure you want to delete <span>{plan.planTitle}</span> plan?
          </p>
        ),
        bottom_text: "",
        button_blue_text: "No",
        button_blue_onClick: () => {
          props.setAlertMessage(null);
          set_is_in_a_modal(false);
        },
        button_yellow_text: "Yes",
        button_yellow_onClick: async () => {
          props.setPlanBeingDeleted(true);
          props.setAlertMessage(null);
          set_is_in_a_modal(false);
          try {
            await callDeletePlan(plan.planId);
            statusNotification("Plan deleted", "Success");
            props.setPlanBeingDeleted(false);
          } catch (_e) {
            statusNotification("Error deleting plan", "Error");
            props.setPlanBeingDeleted(false);
          }
        },
        img_src: null,
        modal_style: null,
      });
      set_is_in_a_modal(true);
    },
    [callDeletePlan, props, set_is_in_a_modal, statusNotification]
  );

  if (plans.isLoading || props.isNewPlanBeingCreated) {
    return (
      <table className="AdvisorLogin_page__table" id="open_accounts_table">
        <TableHeader />

        <tbody className="AdvisorLogin_page__table_body">
          <tr>
            <td>
              <div className="advisorLogin_centered_loader">
                <LogoLoadingStill />
              </div>
            </td>
          </tr>
        </tbody>

        <TableFooter
          startingPlanValue={0}
          endingPlanValue={0}
          plansToDisplayFilteredTotal={0}
          pageUp={() => undefined}
          padeDown={() => undefined}
        />
      </table>
    );
  }

  return (
    <table className="AdvisorLogin_page__table" id="open_accounts_table">
      <TableHeader />

      <tbody className="AdvisorLogin_page__table_body">
        {plansToDisplay.map((plan, i) => (
          <TableRow
            key={plan.planId}
            plan={plan}
            index={i + startingPlanValue + 1}
            openPlan={() => {
              const qs = new URLSearchParams();
              qs.set("page", `${page}`);
              if (props.plansFilterStr !== "") {
                qs.set("query", props.plansFilterStr);
              }

              history.push(`/Advisor/Plans/${plan.planId}?${qs.toString()}`);
            }}
            deletePlan={() => deleteAlert(plan)}
          />
        ))}
      </tbody>

      <TableFooter
        startingPlanValue={startingPlanValue + 1}
        endingPlanValue={endingPlanValue}
        plansToDisplayFilteredTotal={plansToDisplayFilteredTotal}
        pageUp={() => plansPaginateForward()}
        padeDown={() => plansPaginateBackward()}
      />
    </table>
  );
};

export default PlansTable;

const TableHeader = (): JSX.Element => {
  return (
    <thead>
      <tr key={0} className="AdvisorLogin_page__table_header_row">
        <th className="AdvisorLogin_page__table_header_id_2">
          <p className="AdvisorLogin_page__table_header_id_text">ID</p>
        </th>
        <th className="AdvisorLogin_page__table_header_name_2">
          <p className="AdvisorLogin_page__table_header_name_text">
            Account holder
          </p>
        </th>
        <th className="AdvisorLogin_page__table_header_email">
          <p className="AdvisorLogin_page__table_header_email_text">
            Plan Name
          </p>
        </th>
        <th className="AdvisorLogin_page__table_header_status">
          <p className="AdvisorLogin_page__table_header_status_text">Status</p>
        </th>
        <th className="AdvisorLogin_page__table_header_login" />
      </tr>
    </thead>
  );
};

interface TableFooterProps {
  startingPlanValue: number;
  endingPlanValue: number;
  plansToDisplayFilteredTotal: number;
  pageUp: () => void;
  padeDown: () => void;
}

const TableFooter = ({
  startingPlanValue,
  endingPlanValue,
  plansToDisplayFilteredTotal,
  padeDown,
  pageUp,
}: TableFooterProps): JSX.Element => {
  return (
    <tfoot>
      <tr key={12} className="AdvisorLogin_page__table_footer_row">
        <th className="AdvisorLogin_page__table_footer_id_2"></th>
        <th className="AdvisorLogin_page__table_footer_name_2"></th>
        <th className="AdvisorLogin_page__table_footer_email"></th>
        <th className="AdvisorLogin_page__table_footer_status">
          <p className="AdvisorLogin_page__table_footer_status_text">
            {`${startingPlanValue} - ${endingPlanValue} of ${plansToDisplayFilteredTotal}`}
          </p>
        </th>
        <th className="AdvisorLogin_page__table_footer_login">
          <div className="AdvisorLogin_page__arrows_div">
            <div
              className="AdvisorLogin_page__arrow_circle_img_div"
              onClick={padeDown}
            >
              <img
                className="AdvisorLogin_page__arrow_circle_img"
                src={"/src/assets/svg/white_arrow_left.svg"}
              />
            </div>

            <div
              className="AdvisorLogin_page__arrow_circle_img_div"
              onClick={pageUp}
            >
              <img
                className="AdvisorLogin_page__arrow_circle_img"
                src={"/src/assets/svg/white_arrow_right.svg"}
              />
            </div>
          </div>
        </th>
      </tr>
    </tfoot>
  );
};

interface TableRowProps {
  plan: Plan;
  index: number;
  deletePlan: () => void;
  openPlan: () => void;
}

const TableRow = ({
  plan,
  index,
  openPlan,
  deletePlan,
}: TableRowProps): JSX.Element => {
  const [isHovering, setIsHovering] = useState(false);

  const { chevron_right } = useSelector((state: ReduxState) => ({
    chevron_right: state.main_Reducer.brand.current.images.chevron_right,
  }));

  let statusColor = "var(--text)";
  switch (plan?.planStatus) {
    case "Not Started":
      statusColor = "var(--textDanger)";
      break;
    case "In Progress":
      statusColor = "var(--textAccentBlue)";
      break;
    case "Ready to Review":
      statusColor = "var(--textSelected)";
      break;
    case "Complete":
      statusColor = "var(--textSuccess)";
      break;
  }

  return (
    <tr
      className="AdvisorLogin_page__table_row"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <td
        className="AdvisorLogin_page__table_cell_id_2"
        onClick={() => openPlan()}
      >
        <p className="AdvisorLogin_page__table_cell_id_text">{index}</p>
      </td>

      <td
        className="AdvisorLogin_page__table_cell_name_2"
        onClick={() => openPlan()}
      >
        <p className="AdvisorLogin_page__table_cell_name_text">
          {plan.planHolder.name.first +
            " " +
            plan.planHolder.name.last +
            ` (...${plan.planHolder.clientId.substring(
              plan.planHolder.clientId.length - 4
            )})`}
        </p>
      </td>

      <td
        className="AdvisorLogin_page__table_cell_email"
        onClick={() => openPlan()}
      >
        <p className="AdvisorLogin_page__table_cell_email_text">
          {plan.planTitle}
        </p>
      </td>

      <td
        className="AdvisorLogin_page__table_cell_status"
        onClick={() => openPlan()}
      >
        <div className="AdvisorLogin_page__table_cell_status_inner_div">
          <p
            className="AdvisorLogin_page__accountOpening_status_text"
            style={{ color: statusColor }}
          >
            {plan.planStatus === "Complete" ? "Submitted" : plan.planStatus}
          </p>
        </div>
      </td>

      <td className="AdvisorLogin_page__table_cell_login">
        {isHovering && (
          <div className="AdvisorLogin_page__chevron_right_hover_div">
            <div
              className="AdvisorLogin_page__take_up_space"
              onClick={() => openPlan()}
            />

            <img
              className="AdvisorLogin_page__openAccounts_chevron_right_img"
              src={chevron_right}
              onClick={() => openPlan()}
            />

            {plan.planStatus !== "Complete" && (
              <img
                className="AdvisorLogin_page__openAccounts_trash_can_img"
                onClick={() => deletePlan()}
                src={"/src/assets/svg/trashCan.svg"}
              />
            )}
          </div>
        )}
      </td>
    </tr>
  );
};
