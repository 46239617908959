import React from "react";

import useGetTaxBudgets from "@fartherfinance/frontend/api/InvestmentModel/hooks/useGetTaxBudgets";

import { useCreateEditTaxBudgetContext } from "../../../TaxBudgets/reducer/Context";
import ReviewDisclosures from "../../../TaxBudgets/ReviewDisclosures/ReviewDisclosures";
import SelectBudget from "../../../TaxBudgets/SelectBudget/SelectBudget";
import SelectYear from "../../../TaxBudgets/SelectYear/SelectYear";
import ReviewBudget from "../ReviewBudget/ReviewBudget";
import ClientSelectAccounts from "../SelectAccounts/ClientSelectAccounts";
import Success from "@src/multiCustodian/components/TaxBudgets/Success/Success";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

const FlowBody: React.FC = () => {
  const { state } = useCreateEditTaxBudgetContext();

  const { pageIndex } = state;

  const auth = useRequestAuth();

  const { data: budgets } = useGetTaxBudgets(auth);

  switch (pageIndex) {
    case 0:
      return <SelectYear budgets={budgets || []} />;
    case 1:
      return <ClientSelectAccounts />;
    case 2:
      return <SelectBudget />;
    case 3:
      return <ReviewDisclosures />;
    case 4:
      return <ReviewBudget />;
    case 5:
      return <Success />;
    default:
      return null;
  }
};

export default FlowBody;
