import React, { PropsWithChildren, useMemo, useRef, useState } from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { orderBy } from "lodash";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import usePortfolioV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/usePortfolioV2";
import useSleeve from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useSleeve";
import useSleeveAnalysis from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useSleeveAnalysis";
import useSleeves from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useSleeves";
import useUnarchiveSleeve from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useUnarchiveSleeve";
import {
  ClientId,
  PortfolioId,
  SleeveId,
} from "@fartherfinance/frontend/api/Types";

import useIsAdmin from "@src/multiCustodian/components/Advisor/utils/useIsAdmin";
import Drawer, {
  largeDrawer,
} from "@src/multiCustodian/components/Drawer/Drawer";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import Chip from "@src/sharedComponents/Chip/Chip";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import Popover from "@src/sharedComponents/Popover/Popover";
import Select from "@src/sharedComponents/Select/Select";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import { State } from "@src/store";

import styles from "./SleeveDrawer.module.css";

type Tab = "Sharing" | "Sleeve Usage" | "Sleeve Details";

interface DropdownOption {
  sleeveId: SleeveId;
  name: string;
}

interface Props {
  curTab: Tab;
  sleeveId: SleeveId;
  isInClientPortal?: boolean;
}

const SleeveDrawer = ({
  sleeveId,
  isInClientPortal = false,
  ...props
}: PropsWithChildren<Props>): JSX.Element => {
  const { clientId, portfolioId } = useParams<{
    clientId: ClientId;
    portfolioId: PortfolioId;
  }>();

  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));
  const history = useHistory();

  const statusNotification = useStatusNotification();

  const clientAuth = useRequestAuth();
  const advisorAuth = useAdvisorRequestAuth();
  const sleeve = useSleeve(sleeveId, clientId ? clientAuth : advisorAuth);
  const sleeves = useSleeves(
    clientId ? clientId : advisorId,
    clientId ? clientAuth : advisorAuth
  );
  const sleeveAnalysis = useSleeveAnalysis(
    sleeveId,
    clientId ? clientAuth : advisorAuth
  );
  const portfolio = usePortfolioV2(
    portfolioId,
    portfolioId ? clientAuth : null
  );

  const dropdownOptions: DropdownOption[] = useMemo(() => {
    if (sleeves.data === undefined) {
      return [];
    }

    if (isInClientPortal) {
      if (portfolio.data === undefined) {
        return [];
      }

      //if in client portal, only populate dropdown with sleeves in that portfolio
      const sleevesInCurModel = sleeves.data.sleeves
        .filter((s) =>
          portfolio.data.positions.sleeves.some(
            (ps) => ps.sleeveId === s.sleeveId
          )
        )
        .map((s) => ({
          sleeveId: s.sleeveId,
          name: s.displayName,
        }));

      return orderBy(sleevesInCurModel, [(s) => s.name.toLowerCase()], ["asc"]);
    }

    const allSleeves = sleeves.data.sleeves.map((s) => ({
      sleeveId: s.sleeveId,
      name: s.displayName,
    }));

    return orderBy(allSleeves, [(s) => s.name.toLowerCase()], ["asc"]);
  }, [sleeves.data, portfolio.data, isInClientPortal]);

  if (sleeve.isLoading || sleeves.isLoading || sleeveAnalysis.isLoading) {
    return (
      <Drawer
        isDrawerOpen
        container_style={largeDrawer}
        onClose={() =>
          history.push(
            isInClientPortal
              ? {
                  pathname: `/Client/${clientId}/Investments/Portfolios/${portfolioId}`,
                  state: history.location.state,
                }
              : {
                  ...history.location,
                  pathname: "/Advisor/Investments/Sleeves",
                }
          )
        }
        transitionDuration={0}
      >
        <div className={styles.loadingContainer}>
          <LogoLoadingStill />
        </div>
      </Drawer>
    );
  }

  if (sleeve.hasError || sleeves.hasError || sleeveAnalysis.hasError) {
    return (
      <Drawer
        isDrawerOpen
        container_style={largeDrawer}
        onClose={() =>
          history.push(
            isInClientPortal
              ? {
                  pathname: `/Client/${clientId}/Investments/Portfolios/${portfolioId}`,
                  state: history.location.state,
                }
              : {
                  ...history.location,
                  pathname: "/Advisor/Investments/Sleeves",
                }
          )
        }
        transitionDuration={0}
      >
        <div>Error retrieving models</div>
      </Drawer>
    );
  }

  return (
    <Drawer
      isDrawerOpen
      container_style={largeDrawer}
      header={
        <Select
          value={sleeve.data.displayName ?? ""}
          options={dropdownOptions.map((o) => o.name)}
          onChange={(name) => {
            const chosen = dropdownOptions.find((x) => x.name === name);
            if (chosen === undefined) {
              statusNotification("Error selecting sleeve", "Error");
              return;
            }

            if (isInClientPortal) {
              history.push({
                pathname: `/Client/${clientId}/Investments/Portfolios/${portfolioId}/Sleeve/${chosen.sleeveId}/SleeveDetails`,
                state: history.location.state,
              });
              return;
            }

            history.push({
              ...history.location,
              pathname: `/Advisor/Investments/Sleeves/${chosen.sleeveId}/SleeveDetails`,
            });
          }}
          style={{ width: "200px" }}
          renderValue={(value) => value}
        />
      }
      onClose={() =>
        history.push(
          isInClientPortal
            ? {
                pathname: `/Client/${clientId}/Investments/Portfolios/${portfolioId}`,
                state: history.location.state,
              }
            : {
                ...history.location,
                pathname: "/Advisor/Investments/Sleeves",
              }
        )
      }
      transitionDuration={0}
    >
      <Header
        curTab={props.curTab}
        sleeveId={sleeveId}
        isInClientPortal={isInClientPortal}
      />

      <Tabs
        sleeveId={sleeveId}
        curTab={props.curTab}
        isInClientPortal={isInClientPortal}
        setCurTab={(tab: Tab) => {
          switch (tab) {
            case "Sharing":
              return history.push(
                isInClientPortal
                  ? {
                      pathname: `/Client/${clientId}/Investments/Portfolios/${portfolioId}/Sleeve/${sleeveId}/Sharing`,
                      state: history.location.state,
                    }
                  : {
                      ...history.location,
                      pathname: `/Advisor/Investments/Sleeves/${sleeveId}/Sharing`,
                    }
              );

            case "Sleeve Usage":
              return history.push(
                isInClientPortal
                  ? {
                      pathname: `/Client/${clientId}/Investments/Portfolios/${portfolioId}/Sleeve/${sleeveId}/SleeveUsage`,
                      state: history.location.state,
                    }
                  : {
                      ...history.location,
                      pathname: `/Advisor/Investments/Sleeves/${sleeveId}/SleeveUsage`,
                    }
              );

            case "Sleeve Details":
              return history.push(
                isInClientPortal
                  ? {
                      pathname: `/Client/${clientId}/Investments/Portfolios/${portfolioId}/Sleeve/${sleeveId}/SleeveDetails`,
                      state: history.location.state,
                    }
                  : {
                      ...history.location,
                      pathname: `/Advisor/Investments/Sleeves/${sleeveId}/SleeveDetails`,
                    }
              );

            default: {
              const _x: never = tab;
            }
          }
        }}
      />

      {props.children}
    </Drawer>
  );
};

export default SleeveDrawer;

interface HeaderProps {
  curTab: "Sharing" | "Sleeve Usage" | "Sleeve Details";
  sleeveId: SleeveId;
  isInClientPortal?: boolean;
}

const Header = ({
  curTab,
  sleeveId,
  isInClientPortal,
}: HeaderProps): JSX.Element => {
  const [isMutating, setIsMutating] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);

  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));

  const history = useHistory();

  const statusNotification = useStatusNotification();

  const auth = useAdvisorRequestAuth();
  const unarchiveSleeve = useUnarchiveSleeve(auth);
  const isAdmin = useIsAdmin(advisorId);
  const sleeve = useSleeve(sleeveId, auth);

  const isSleeveArchived =
    sleeve.data === undefined ? undefined : sleeve.data.isActive === false;

  const activateSleeve = async (sleeveId: SleeveId) => {
    try {
      setIsMutating(true);

      await unarchiveSleeve(sleeveId);

      setTimeout(() => {
        statusNotification("Sleeve activated", "Success");
        setIsMutating(false);
        setShowPopover(false);

        history.push({
          ...history.location,
          pathname: `/Advisor/Investments/Sleeves/${sleeveId}/SleeveDetails`,
          state: {
            sleevesActiveVsArchivedFilter: "Active Sleeves",
          },
        });
      }, 3000);
    } catch (_e) {
      statusNotification("Error activating sleeve", "Error");
      setIsMutating(false);
      setShowPopover(false);
    }
  };

  const canArchiveAndEdit = isAdmin.data || sleeve.data?.ownerId === advisorId;

  if (isAdmin.hasError) {
    return <div>Error retrieving employee status</div>;
  }

  const editModel = () => {
    if (!sleeve) {
      statusNotification("Sleeve is undefined", "Error");
      return;
    }

    history.push({
      pathname: `/Advisor/Investments/Sleeves/${sleeveId}/Update`,
      state: history.location.state,
    });
  };

  const advisorPortalEditMenu = (
    <Popover
      open={showPopover}
      onClose={() => setShowPopover(false)}
      anchor={anchorRef.current}
      popoverElement={
        <div
          className={styles.dotMenu}
          ref={anchorRef}
          onClick={() => setShowPopover(true)}
        >
          <MoreHorizIcon />
        </div>
      }
    >
      <div className={styles.archiveMenu}>
        {isMutating ? (
          <Skeleton
            width={100}
            style={{
              transform: "none",
              marginTop: "7px",
              marginBottom: "7px",
            }}
          />
        ) : isSleeveArchived === undefined ? (
          <>
            <Skeleton
              width={70}
              style={{
                transform: "none",
                marginTop: "7px",
                marginBottom: "7px",
              }}
            />

            <Skeleton
              width={70}
              style={{
                transform: "none",
                marginTop: "7px",
                marginBottom: "7px",
              }}
            />
          </>
        ) : (
          <>
            {isSleeveArchived ? (
              <>
                {canArchiveAndEdit ? (
                  <div
                    className={styles.archiveMenuText}
                    onClick={() => activateSleeve(sleeveId)}
                  >
                    Make Active
                  </div>
                ) : (
                  <div className={styles.archiveMenuTextDisabled}>
                    Make Active
                  </div>
                )}
              </>
            ) : (
              <>
                {canArchiveAndEdit ? (
                  <>
                    <div className={styles.archiveMenuText} onClick={editModel}>
                      Edit
                    </div>

                    <div
                      className={styles.archiveMenuRedText}
                      onClick={() => {
                        setShowPopover(false);
                        const tab =
                          curTab === "Sleeve Details"
                            ? "SleeveDetails"
                            : curTab === "Sleeve Usage"
                            ? "SleeveUsage"
                            : "Sharing";
                        history.push({
                          ...history.location,
                          pathname: `/Advisor/Investments/Sleeves/${sleeveId}/${tab}/Archive`,
                        });
                      }}
                    >
                      Archive
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.archiveMenuTextDisabled}>Edit</div>

                    <div className={styles.archiveMenuTextDisabled}>
                      Archive
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Popover>
  );

  return (
    <>
      <div className={styles.headerFlexApart}>
        <div className={styles.flexRow}>
          <div className={styles.title}>{sleeve.data?.displayName ?? ""}</div>

          {isInClientPortal ? <></> : advisorPortalEditMenu}
        </div>

        <div className={styles.pillsDiv}>
          <Chip label={"Sleeve"} />
        </div>
      </div>
    </>
  );
};

interface TabsProps {
  sleeveId: SleeveId;
  curTab: Tab;
  isInClientPortal: boolean | undefined;
  setCurTab: (tab: Tab) => void;
}

const Tabs = ({
  sleeveId,
  curTab,
  isInClientPortal,
  setCurTab,
}: TabsProps): JSX.Element => {
  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));

  const auth = useAdvisorRequestAuth();
  const sleeve = useSleeve(sleeveId, auth);

  const isAdmin = useIsAdmin(advisorId);

  const isSharedSleeve =
    isAdmin.data === false && sleeve.data && sleeve.data.ownerId !== advisorId;

  const clientPortalTabs = (
    <>
      <p
        className={curTab === "Sleeve Usage" ? styles.tabSelected : styles.tab}
        onClick={() => setCurTab("Sleeve Usage")}
      >
        Sleeve Usage
      </p>

      <p
        className={
          curTab === "Sleeve Details" ? styles.tabSelected : styles.tab
        }
        onClick={() => setCurTab("Sleeve Details")}
      >
        Sleeve Details
      </p>
    </>
  );

  const advisorPortalTabs = (
    <>
      {(isAdmin.data || isSharedSleeve) && (
        <p
          className={curTab === "Sharing" ? styles.tabSelected : styles.tab}
          onClick={() => setCurTab("Sharing")}
        >
          Sharing
        </p>
      )}

      <p
        className={curTab === "Sleeve Usage" ? styles.tabSelected : styles.tab}
        onClick={() => setCurTab("Sleeve Usage")}
      >
        Sleeve Usage
      </p>

      <p
        className={
          curTab === "Sleeve Details" ? styles.tabSelected : styles.tab
        }
        onClick={() => setCurTab("Sleeve Details")}
      >
        Sleeve Details
      </p>
    </>
  );

  return (
    <div className={styles.tabsContainer}>
      {isInClientPortal ? clientPortalTabs : advisorPortalTabs}
    </div>
  );
};
