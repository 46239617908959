import React, { PropsWithChildren } from "react";

import { TimeFrame } from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Types";

import PerformanceIndicatorArrow from "@src/sharedComponents/PerformanceIndicatorArrow/PerformanceIndicatorArrow";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import toUSD from "@src/utils/toUSD";

import * as styles from "./SummaryHeader.module.css";

export const timeRangeOptionToText = (timeFrame: TimeFrame) => {
  switch (timeFrame) {
    case "30D":
      return "Past 30 days ";
    case "3M":
      return "Past 3 months";
    case "1Y":
      return "Past Year";
    case "YTD":
      return "Year-to-Date";
    case "ALL":
      return "All Time";
    case "Custom":
      return "Custom";
  }
};

interface Props {
  isLoading: boolean;
  showAdvisorVersion?: boolean;
  balance: number | null;
  twr: number | null;
  netGains: number | null;
  timeRange: TimeFrame;
}

export default function SummaryHeader(
  props: PropsWithChildren<Props>
): JSX.Element {
  const balanceString = props.balance === null ? "$ -" : toUSD(props.balance);

  const decimalIndex = balanceString.lastIndexOf(".");

  const balanceAmount =
    decimalIndex >= 0
      ? balanceString.substring(0, decimalIndex)
      : balanceString;

  const balanceAmountDecimal =
    decimalIndex >= 0 ? balanceString.substring(decimalIndex) : "";

  const indicatorArrowStatus =
    props.netGains === null
      ? "neutral"
      : props.netGains >= 0
      ? "positive"
      : "negative";

  return (
    <div className={styles.graph_header}>
      <div className={styles.cur_balance}>
        {"showAdvisorVersion" in props && props.showAdvisorVersion && (
          <div className={styles.balanceTitle}>Assets Under Management</div>
        )}

        {props.isLoading ? (
          <Skeleton width={200} height={40} style={{ transform: "none" }} />
        ) : (
          <div className={styles.balanceAmountDiv}>
            <div className={styles.balanceAmount}>{balanceAmount}</div>

            <div className={styles.balanceAmountDecimal}>
              {balanceAmountDecimal}
            </div>
          </div>
        )}

        <div className={styles.cur_balance_net_gains_percent_container}>
          <div className={styles.cur_balance_net_gains_percent}>
            <PerformanceIndicatorArrow status={indicatorArrowStatus} />

            {props.isLoading ? (
              <Skeleton width={80} />
            ) : (
              <p id="Summary-NetGains">
                {props.netGains === null ? "-" : toUSD(props.netGains)}
              </p>
            )}

            {props.isLoading ? (
              <Skeleton width={50} />
            ) : (
              <p id="Summary-TWR">
                {props.twr === null
                  ? "-"
                  : `(${(props.twr / 100).toLocaleString("en-US", {
                      style: "percent",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })})`}
              </p>
            )}

            {props.isLoading ? (
              <Skeleton width={100} />
            ) : props.netGains ? (
              <p className={styles.timeRangeText}>
                {timeRangeOptionToText(props.timeRange)}
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      {props.children}
    </div>
  );
}
