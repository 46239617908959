import React from "react";

import CircleIcon from "@mui/icons-material/Circle";
import Stack from "@mui/material/Stack";

import { OpportunityPriority } from "@fartherfinance/frontend/api/Opportunities/Types";

import {
  FormOpportunityStatus,
  opportunityStatusFormOptions,
} from "@src/multiCustodian/pages/Advisor/Opportunities/common";
import {
  mapValueToLabel,
  opportunityPriorityMap,
  opportunityStatusFormMap,
} from "@src/multiCustodian/pages/Advisor/Opportunities/utils";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import FormSelectField from "@src/sharedComponents/Forms/FormSelectField";
import SelectItem from "@src/sharedComponents/Select/SelectItem";

import styles from "./OpportunityDrawerHeader.module.css";

interface CircleIconProps<T> {
  value: T;
}

const OpportunityStatusCircleIcon = ({
  value,
}: CircleIconProps<FormOpportunityStatus>): JSX.Element => {
  return (
    <CircleIcon
      className={toClassName(styles.circleIcon, {
        [styles.notStartedColor]: value === "NOT_STARTED",
        [styles.inProgressColor]: value === "IN_PROGRESS",
        [styles.awaitingResponseColor]: value === "AWAITING_RESPONSE",
        [styles.snoozedColor]: value === "SNOOZED",
        [styles.completedColor]: value === "COMPLETED",
        [styles.canceledColor]: value === "CANCELED",
      })}
    />
  );
};

const OpportunityPriorityCircleIcon = ({
  value,
}: CircleIconProps<OpportunityPriority>): JSX.Element => {
  return (
    <CircleIcon
      className={toClassName(styles.circleIcon, {
        [styles.lowPrioColor]: value === "LOW",
        [styles.mediumPrioColor]: value === "MEDIUM",
        [styles.highPrioColor]: value === "HIGH",
      })}
    />
  );
};

export default function OpportunityDrawerHeader(): JSX.Element {
  return (
    <Stack direction="row" spacing={2}>
      <FormSelectField
        className={styles.formSelect}
        name="status"
        options={opportunityStatusFormOptions}
        renderValue={(option) => (
          <Stack direction="row" alignItems="center" spacing={1}>
            <OpportunityStatusCircleIcon value={option} />
            <span>{mapValueToLabel(opportunityStatusFormMap, option)}</span>
          </Stack>
        )}
        renderOption={(option) => (
          <SelectItem
            key={option}
            value={option}
            label={mapValueToLabel(opportunityStatusFormMap, option)}
            preAdornment={<OpportunityStatusCircleIcon value={option} />}
          />
        )}
      />

      <FormSelectField
        className={styles.formSelect}
        name="priority"
        options={OpportunityPriority.options}
        renderValue={(option) => (
          <Stack direction="row" alignItems="center" spacing={1}>
            <OpportunityPriorityCircleIcon value={option} />
            <span>{mapValueToLabel(opportunityPriorityMap, option)}</span>
          </Stack>
        )}
        renderOption={(option) => (
          <SelectItem
            key={option}
            value={option}
            label={mapValueToLabel(opportunityPriorityMap, option)}
            preAdornment={<OpportunityPriorityCircleIcon value={option} />}
          />
        )}
      />
    </Stack>
  );
}
