import React, { useCallback, useEffect, useMemo, useState } from "react";

import { head } from "lodash";
import { useHistory, useLocation, useParams } from "react-router-dom";

import useEssentialPartnerPortfoliosV1 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useEssentialPartnerPortfoliosV1";
import { EssentialPortfolioV1 } from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/getEssentialPortfoliosV1";
import { PortfolioPartner } from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";
import {
  ClientId,
  HookResult,
  PortfolioId,
} from "@fartherfinance/frontend/api/Types";

import ModelAnalysis from "@src/multiCustodian/components/Client/Portfolio/ModelAnalysis/ModelAnalysis";
import RiskLevel from "@src/multiCustodian/components/Client/Portfolio/RiskLevel/RiskLevel";
import RiskLevelError from "@src/multiCustodian/components/Client/Portfolio/RiskLevel/RiskLevelError";
import RiskLevelLoading from "@src/multiCustodian/components/Client/Portfolio/RiskLevel/RiskLevelLoading";
import makeDisplayName from "@src/multiCustodian/components/Client/Portfolio/utils/makeDisplayName";
import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import {
  captureException,
  trackEvent,
} from "@src/multiCustodian/services/tracking";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./PortfolioType.module.css";

const portfolioQuery = "portfolioId";
const taxStatusQuery = "taxtype";
type TaxParam = "taxable" | "taxexempt";

const filterPortfolios = (
  portfolios: EssentialPortfolioV1[],
  taxExempt: boolean
): EssentialPortfolioV1[] => {
  const desiredTaxStatus = taxExempt ? "tax_exempt" : "taxable";

  return portfolios
    .filter((p) => p.isActive)
    .filter((p) => p.taxType === "any" || p.taxType === desiredTaxStatus)
    .filter((p) => p.directIndexing === false);
};

/**
 *
 * @param allocation Assumes in the format "(Number)/(Number)" ex: "80/20"
 * @returns first allocation (Equity percentage)
 */
const extractAllocation = (allocation: string): number =>
  Number(allocation.split("/")[0] ?? "0");

const PortfolioType = (): JSX.Element => {
  const { clientId, portfolioType } = useParams<{
    clientId: ClientId;
    portfolioType: PortfolioPartner;
  }>();

  useEffect(() => {
    switch (portfolioType) {
      case "FAM":
        return trackEvent({
          name: "Client Open Portfolios Farther",
        });

      default:
        return trackEvent({
          name: "Client Open Portfolios Partner",
        });
    }
  }, [portfolioType]);

  const [taxExempt, setTaxExempt] = useState(false);

  const [currentStep, setCurrentStep] = useState<number | null>(null);

  const history = useHistory();

  const location = useLocation();

  const qs = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const applyLocation = useCallback(
    (portfolioId: PortfolioId, taxParam: TaxParam) => {
      //It is OK to mutate `qs` for the rest of the code because it is only used
      //as a dependency in a useEffect that runs on first render, and in this function
      qs.set(portfolioQuery, portfolioId);

      qs.set(taxStatusQuery, taxParam);

      history.replace({ ...location, search: qs.toString() });
    },
    [history, location, qs]
  );

  const auth = useRequestAuth();

  const essentialPortfolios = useEssentialPartnerPortfoliosV1(
    portfolioType,
    auth
  );

  const statusNotification = useStatusNotification();

  const goBack = () =>
    history.push(`/Client/${clientId}/Investments/Portfolios/Create`);

  const portfoliosTaxableOrAny: HookResult<EssentialPortfolioV1[]> =
    useMemo(() => {
      if (essentialPortfolios.isLoading || essentialPortfolios.hasError) {
        return essentialPortfolios;
      }

      return {
        ...essentialPortfolios,
        data: filterPortfolios(essentialPortfolios.data, false),
      };
    }, [essentialPortfolios]);

  const portfoliosTaxExemptOrAny = useMemo(() => {
    if (essentialPortfolios.isLoading || essentialPortfolios.hasError) {
      return essentialPortfolios;
    }

    return {
      ...essentialPortfolios,
      data: filterPortfolios(essentialPortfolios.data, true),
    };
  }, [essentialPortfolios]);

  const portfoliosFilteredByTax: HookResult<EssentialPortfolioV1[]> =
    useMemo(() => {
      return taxExempt ? portfoliosTaxExemptOrAny : portfoliosTaxableOrAny;
    }, [portfoliosTaxExemptOrAny, portfoliosTaxableOrAny, taxExempt]);

  const setFirstPortfolio = useCallback(() => {
    const firstPortfolio = head(portfoliosTaxableOrAny.data); //default is taxable

    if (firstPortfolio === undefined) {
      const firstTaxExemptPortfolio = head(portfoliosTaxExemptOrAny.data); //default is taxable
      if (firstTaxExemptPortfolio === undefined) {
        const err = new Error();
        captureException(err, {
          extra: {
            partner: portfolioType,
            models: `No taxable or tax-exempt models were found for ${portfolioType}`,
            clientId: clientId,
            file: ":portfolioType.tsx",
          },
        });
        throw err;
      }
      applyLocation(firstTaxExemptPortfolio.portfolioModelId, "taxexempt");
      setTaxExempt(true);
      setCurrentStep(0);
      return;
    }

    applyLocation(firstPortfolio.portfolioModelId, "taxable");
    setCurrentStep(0);
  }, [
    applyLocation,
    portfoliosTaxableOrAny.data,
    portfoliosTaxExemptOrAny.data,
    clientId,
    portfolioType,
  ]);

  useEffect(() => {
    //Initial render, we ensure all values reflect the portfolioId found in the
    //query params, if it exists
    if (currentStep !== null) {
      return; //if currentStep is not null, we've already performed this useEffect
    }

    if (
      portfoliosTaxableOrAny.data === undefined ||
      portfoliosTaxExemptOrAny.data === undefined
    ) {
      return;
    }

    const portfolioParam = qs.get(portfolioQuery);
    const taxExemptParam = qs.get(taxStatusQuery) as TaxParam | null;

    if (portfolioParam === null) {
      setFirstPortfolio();

      return;
    }

    const portfolio = [
      ...portfoliosTaxExemptOrAny.data,
      ...portfoliosTaxableOrAny.data,
    ].find((p) => p.portfolioModelId === portfolioParam);

    if (portfolio === undefined) {
      const e = `Could not find a portfolio with the ID ${portfolioParam}`;

      console.error(e);

      setFirstPortfolio();

      return;
    }

    //We need the index specifically in one of the two portfolio arrays
    //Not in the full set of essentialPortfolios
    const idx = (
      portfolio.taxType === "tax_exempt"
        ? portfoliosTaxExemptOrAny
        : portfoliosTaxableOrAny
    ).data.findIndex((p) => p.portfolioModelId === portfolioParam);

    if (idx < 0) {
      setFirstPortfolio();
      return;
    }

    if (portfolio.taxType === "tax_exempt") {
      if (taxExemptParam !== "taxexempt") {
        //ensure taxexempt matches the selected portfolio
        applyLocation(portfolioParam as PortfolioId, "taxexempt");
      }

      setTaxExempt(true);
    } else if (
      portfolio.taxType === "taxable" &&
      taxExemptParam !== "taxable"
    ) {
      applyLocation(portfolioParam as PortfolioId, "taxable");
    } else {
      applyLocation(
        portfolioParam as PortfolioId,
        taxExempt ? "taxexempt" : "taxable"
      );
    }

    setCurrentStep(idx);
  }, [
    applyLocation,
    currentStep,
    essentialPortfolios.data,
    portfoliosTaxExemptOrAny,
    portfoliosTaxableOrAny,
    qs,
    setFirstPortfolio,
    taxExempt,
  ]);

  const setPortfolio = (step: number) => {
    const portfolio = portfoliosFilteredByTax.data?.[step] ?? null;

    if (currentStep === null || portfolio === null) {
      return;
    }

    setCurrentStep(step);

    applyLocation(
      portfolio.portfolioModelId,
      taxExempt ? "taxexempt" : "taxable"
    );
  };

  const currentPortfolio: HookResult<EssentialPortfolioV1> = useMemo(() => {
    if (portfoliosFilteredByTax.isLoading || portfoliosFilteredByTax.hasError) {
      return portfoliosFilteredByTax;
    }

    if (currentStep === null) {
      return {
        data: undefined,
        isLoading: true,
        hasError: false,
      };
    }

    const portfolio = portfoliosFilteredByTax.data[currentStep];

    if (portfolio === undefined) {
      console.error("Portfolio not available", {
        currentStep,
        portfoliosFilteredByTax,
      });
      return {
        data: undefined,
        isLoading: false,
        hasError: true,
        error: new Error("Data unavailable"),
      };
    }

    return {
      data: portfolio,
      isLoading: false,
      hasError: false,
    };
  }, [currentStep, portfoliosFilteredByTax]);

  const setTaxStatus = (taxExemptLocal: boolean) => {
    if (currentStep === null) {
      statusNotification(
        "Could not switch tax type due to unknown current portfolio number",
        "Error"
      );
      return;
    }

    const portfoliosToSearch = taxExemptLocal
      ? portfoliosTaxExemptOrAny.data
      : portfoliosTaxableOrAny.data;

    if (portfoliosToSearch === undefined) {
      statusNotification(
        "Could not switch tax type due to missing portfolio list",
        "Error"
      );
      return;
    }

    if (currentPortfolio.data === undefined) {
      statusNotification(
        "Could not switch tax type due to missing current portfolio information",
        "Error"
      );
      return;
    }

    /** Next section tries to find a similar portfolio that has the same
     *  allocation. These have similar risk profiles but different tax
     *  implications.
     * */
    const portfolioWithSameRisk = portfoliosToSearch.find(
      (p) =>
        extractAllocation(p.assetAllocation) ===
        extractAllocation(currentPortfolio.data.assetAllocation)
    );

    // Our backup is just to go to the start of the list
    const portfolioToSwitchTo = portfolioWithSameRisk ?? portfoliosToSearch[0];

    const newStep =
      portfoliosToSearch.findIndex(
        (p) => p.portfolioModelId === portfolioToSwitchTo.portfolioModelId
      ) ?? 0;

    setTaxExempt(taxExemptLocal);
    setCurrentStep(newStep);
    applyLocation(
      portfolioToSwitchTo.portfolioModelId,
      taxExemptLocal ? "taxexempt" : "taxable"
    );
  };

  const displayName = makeDisplayName(
    currentPortfolio.data?.displayName,
    taxExempt,
    currentPortfolio.data?.taxType
  );

  return (
    <BaseLayout showSideNav={false}>
      <div className={styles.container}>
        {portfoliosFilteredByTax.isLoading || currentStep === null ? (
          <RiskLevelLoading goBack={goBack} />
        ) : portfoliosFilteredByTax.hasError ? (
          <RiskLevelError goBack={goBack} />
        ) : (
          <RiskLevel
            goBack={goBack}
            steps={portfoliosFilteredByTax.data.map((p) => ({
              name: makeDisplayName(p.displayName, taxExempt, p.taxType),
            }))}
            currentStep={currentStep}
            setCurrentStep={(step) => setPortfolio(step)}
            taxExempt={taxExempt}
            setTaxExempt={() => {
              setTaxStatus(!taxExempt);
            }}
            shiftLabelInPixelsIfAtSliderStartOrEnd={50}
            isCheckboxDisabled={
              head(portfoliosTaxableOrAny.data) === undefined ||
              head(portfoliosTaxExemptOrAny.data) === undefined
            }
          />
        )}

        <div className={styles.bottomContainer}>
          <div className={styles.title}>
            {currentPortfolio.isLoading ? (
              <Skeleton width={200} />
            ) : currentPortfolio.hasError ? (
              "Error loading"
            ) : (
              displayName
            )}
          </div>

          <ModelAnalysis
            portfolioId={currentPortfolio.data?.portfolioModelId ?? null}
          />

          <div className={styles.footer}>
            <ButtonPrimary
              text="Continue"
              onClick={() => {
                if (currentPortfolio.data === undefined) {
                  return statusNotification(
                    "A model portfolio was not selected",
                    "Error"
                  );
                }

                history.push(
                  `/Client/${clientId}/Investments/Portfolios/${currentPortfolio.data?.portfolioModelId}`
                );
              }}
              variant="contained"
              buttonType="primary"
              disabled={currentPortfolio === null}
            />
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default PortfolioType;
