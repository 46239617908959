import { useCallback, useEffect, useMemo } from "react";

import { parse, ParseOptions, stringify, StringifyOptions } from "query-string";
import { useHistory, useLocation } from "react-router-dom";

export const usePersistedQueryParams = <
  T extends Partial<Record<keyof T, unknown>>
>(
  initialQueryParams?: T,
  parseOptions?: ParseOptions,
  stringifyOptions?: StringifyOptions
): [T, (queryParams: T) => void] => {
  const location = useLocation();
  const history = useHistory();

  const parseSettings = useMemo<ParseOptions>(
    () =>
      parseOptions ?? {
        parseNumbers: true,
        parseBooleans: true,
        decode: false,
        arrayFormat: "bracket-separator",
      },
    [parseOptions]
  );

  const stringifySettings = useMemo<StringifyOptions>(
    () =>
      stringifyOptions ?? {
        encode: false,
        arrayFormat: "bracket-separator",
      },
    [stringifyOptions]
  );

  const queryParams = useMemo(() => {
    return parse(location.search, parseSettings) as T;
  }, [location.search, parseSettings]);

  const setQueryParams = useCallback(
    (queryParams: T) => {
      history.push({
        ...location,
        search: stringify(queryParams, stringifySettings),
      });
    },
    [history, location, stringifySettings]
  );

  useEffect(() => {
    if (location.search === "" && initialQueryParams) {
      // NOTE: make sure that we keep the initial query params persisted in the URL
      setQueryParams(initialQueryParams);
    }
  }, [initialQueryParams, setQueryParams, location.search]);

  useEffect(() => {
    // NOTE: we just need this effect to run once, it merges query params from the URL and query params
    // from the initial variable passed to this hook and updates URL with the result
    const parsedQueryParams = parse(location.search, parseSettings) as T;

    history.replace({
      ...location,
      search: stringify(
        { ...initialQueryParams, ...parsedQueryParams },
        stringifySettings
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [queryParams, setQueryParams];
};
