import React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import { Link, matchPath, useLocation } from "react-router-dom-v5-compat";

import { PROPOSALS_ACTIONS_OUTLET_NODE_ID } from "@src/yellowstone/modules/shared";

import styles from "./DailyTradingNavigation.module.css";

const proposalDetailsPath =
  "/Advisor/Client-Ops/Dashboard/Daily-Trading/Proposal-Details/:proposalId";

const LINKS = [
  {
    title: "Daily Proposals",
    to: "/Advisor/Client-Ops/Dashboard/Daily-Trading/Daily-Proposals",
    subpaths: [proposalDetailsPath],
  },
  {
    title: "Submitted Proposals",
    to: "/Advisor/Client-Ops/Dashboard/Daily-Trading/Submitted-Proposals",
    subpaths: [proposalDetailsPath],
  },
  {
    title: "Past Skipped Proposals",
    to: "/Advisor/Client-Ops/Dashboard/Daily-Trading/Skipped-Proposals",
    subpaths: [proposalDetailsPath],
  },
];

export const DailyTradingNavigation: React.FC = () => {
  const location = useLocation();

  const { title = "" } =
    LINKS.find((link) => matchPath(link.to, location.pathname)) ??
    LINKS.find((link) =>
      link.subpaths.some((path) => matchPath(path, location.pathname))
    ) ??
    {};

  return (
    <div className={styles.container}>
      <div className={styles.dropdown}>
        <div className={styles.dropdown_title}>
          <p className={styles.title}>{title}</p>
          <ExpandMoreIcon />
        </div>
        <div className={styles.dropdown_content}>
          {LINKS.map((link) => (
            <Link key={link.to} className={styles.link} to={link.to}>
              {link.title}
            </Link>
          ))}
        </div>
      </div>
      <Box style={{ width: "100%" }} id={PROPOSALS_ACTIONS_OUTLET_NODE_ID} />
    </div>
  );
};
