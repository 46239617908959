import React, { PropsWithChildren } from "react";

import styles from "./CenteringContainer.module.css";

interface Props {
  widthStyle?: string; // ex: "100px" or "50%"
}

const CenteringContainer = (props: PropsWithChildren<Props>): JSX.Element => {
  return (
    <div className={styles.container}>
      <div
        className={styles.centeringContainer}
        style={props.widthStyle ? { width: props.widthStyle } : {}}
      >
        {props.children}
      </div>
    </div>
  );
};

export default CenteringContainer;
