import React, { useEffect, useMemo, useState } from "react";

import { Box, Fade } from "@mui/material";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import { pipe } from "lodash/fp";
import { useParams, useRouteMatch } from "react-router-dom";

import useGetDocuments, {
  docCenterGetDocumentsKeyMaker,
} from "@fartherfinance/frontend/api/CustodianDoc/hooks/useGetDocuments";
import usePostUploadDocument from "@fartherfinance/frontend/api/CustodianDoc/hooks/usePostUploadDocument";
import {
  AllDocs,
  DocOrFilePathPart,
} from "@fartherfinance/frontend/api/CustodianDoc/Types";
import { getEffectiveDocuments } from "@fartherfinance/frontend/api/CustodianDoc/utilities/getEffectiveDocuments";
import useGetAgreements from "@fartherfinance/frontend/api/Document/hooks/useGetAgreements";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import {
  UploadState,
  UploadStateMap,
} from "@src/multiCustodian/hooks/useUploadStateMap";
import { captureException } from "@src/multiCustodian/services/tracking";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import { pluralize } from "@src/utils/pluralize";

import Agreements from "./DocumentsCenter/Agreements/Agreements";
import DirectoryList from "./DocumentsCenter/DirectoryList/DirectoryList";
import DocumentsExplorer from "./DocumentsCenter/DocumentsExplorer/DocumentsExplorer";
import DocumentsToolbar from "./DocumentsCenter/DocumentsToolbar/DocumentsToolbar";
import HelloSignProvider from "./DocumentsCenter/HelloSignProvider/HelloSignProvider";
import UploadFilesProvider, {
  useUploadFiles,
} from "./DocumentsCenter/UploadFilesProvider/UploadFilesProvider";

import styles from "./DocumentsCenter.module.css";

export type DocOrFilePathPartUpload = DocOrFilePathPart & {
  uploadState: UploadState | null;
};

const DocumentsCenterInternal = (): JSX.Element => {
  const { url } = useRouteMatch();
  const { clientId } = useParams<Record<"clientId", ClientId>>();
  const [uploadCurrentDirectory, setUploadCurrentDirectory] = useState<
    string | null
  >(null);

  const {
    files,
    removeAllFiles,
    uploadStateMap,
    setUploadState,
    setUploadStateBare,
    resetUploadState,
  } = useUploadFiles();

  const queryClient = useQueryClient();
  const isPostCreateFolderMutating = useIsMutating({
    mutationKey: ["usePostCreateFolder"],
  });
  const isDeleteFolderDocumentMutating = useIsMutating({
    mutationKey: ["useDeleteFolderDocument"],
  });

  const isMutating = Boolean(
    isPostCreateFolderMutating || isDeleteFolderDocumentMutating
  );

  const auth = useRequestAuth();
  const callPostUploadDocument = usePostUploadDocument(auth);
  const statusNotification = useStatusNotification();

  const {
    data: documents,
    isLoading: isLoadingDocuments,
    hasError,
  } = useGetDocuments(clientId, auth);
  const { data: agreements = [], isLoading: isLoadingAgreements } =
    useGetAgreements(auth);

  const effectiveDocuments = getEffectiveDocuments(documents);
  const directories = url.split("/").slice(4);
  const currentDirectory = directories.at(-1) ?? "";

  const hasPendingAgreements = agreements.some(
    (agreement) => agreement.status === "Pending"
  );
  const isAgreementsScope =
    encodeURIComponent(currentDirectory) === "Farther%20Records";

  const currentDirectoryScope = pipe(
    (directories) => directories.at(0) ?? "",
    (currentDirectory) => encodeURIComponent(currentDirectory),
    (encodedCurrentDirectory) => {
      const key = encodedCurrentDirectory;

      // NOTE: on UI we show root category as "Manually-Tracked Assets",
      // while we should get documents from "Alternative Investments" property of backend response
      if (key === "Manually-Tracked%20Assets") {
        return effectiveDocuments?.["Alternative%20Investments"];
      }

      return effectiveDocuments?.[key as keyof AllDocs];
    }
  )(directories);

  const handleDocumentsRefetch = async (): Promise<void> => {
    await queryClient.invalidateQueries({
      queryKey: docCenterGetDocumentsKeyMaker(clientId),
    });

    removeAllFiles();
    resetUploadState();
    setUploadCurrentDirectory(null);
  };

  const handleShowUploadNotifications = (
    uploadStateMap: UploadStateMap | null
  ): void => {
    const uploadStateValues = Object.values(uploadStateMap ?? {});
    const successCount = uploadStateValues.filter(
      (status) => status === "success"
    ).length;
    const errorCount = uploadStateValues.filter(
      (status) => status === "error"
    ).length;

    if (successCount) {
      statusNotification(
        `${pluralize(successCount, "file", true)} uploaded.`,
        "Success"
      );
    }

    if (errorCount) {
      statusNotification(
        `${pluralize(errorCount, "file", true)} failed to upload.`,
        "Error"
      );
    }
  };

  const handleUploadFiles = async (files: File[]): Promise<void> => {
    if (files.length === 0) {
      return;
    }

    const encodedDirectories = directories.map((directory, index) => {
      if (index === 0) {
        return encodeURIComponent(directory);
      }
      return directory;
    });

    const folderPathName = encodedDirectories.join("/");

    try {
      // NOTE: save current directory for files that are being uploaded
      setUploadCurrentDirectory(currentDirectory);
      // NOTE: set all files to uploading state upfront
      files.forEach((file) => setUploadState(file.name, "uploading"));

      for (const file of files) {
        try {
          await callPostUploadDocument({
            path: folderPathName,
            file,
            refetch: false,
          });

          setUploadState(file.name, "success");
        } catch {
          setUploadState(file.name, "error");
        }
      }
    } finally {
      setUploadStateBare((currentUploadStateMap) => {
        // NOTE: as useEffect that calls this function is triggered only when files change,
        // we were getting an outdated uploadStateMap here
        handleShowUploadNotifications(currentUploadStateMap);
        return currentUploadStateMap;
      });
      setTimeout(() => handleDocumentsRefetch(), 7000);
    }
  };

  useEffect(() => {
    handleUploadFiles(files);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const currentDirectoryUploadScope: DocOrFilePathPartUpload[] = useMemo(
    () =>
      files.map((file) => {
        const fileExtension = file.name.split(".").pop();

        if (!fileExtension) {
          const error = new Error(
            `File extension not found for file name: ${file.name}`
          );
          captureException(error);
        }

        return {
          altInvestmentAssetName: null,
          baseDirectory: "",
          currentDirectory: null,
          date: new Date(),
          fileName: file.name,
          fileType: "." + fileExtension,
          parentDirectory: uploadCurrentDirectory ?? "",
          path: "",
          size: file.size,
          uploadState: uploadStateMap?.[file.name] ?? null,
        };
      }),
    [files, uploadStateMap, uploadCurrentDirectory]
  );

  return (
    <BaseLayout>
      <Fade in>
        <Box className={styles.container}>
          <DirectoryList
            clientId={clientId}
            hasPendingAgreements={hasPendingAgreements}
          />

          <Box className={styles.content}>
            <DocumentsToolbar clientId={clientId} directories={directories} />

            <Fade key={JSON.stringify(directories)} in>
              <Box className={styles.documents}>
                {isAgreementsScope &&
                  (isLoadingAgreements || hasPendingAgreements) && (
                    <Agreements
                      clientId={clientId}
                      agreements={agreements}
                      isLoading={isLoadingAgreements}
                    />
                  )}
                <DocumentsExplorer
                  clientId={clientId}
                  isLoading={isLoadingDocuments}
                  isErrored={hasError}
                  directories={directories}
                  currentDirectory={currentDirectory}
                  currentDirectoryScope={currentDirectoryScope}
                  currentDirectoryUploadScope={currentDirectoryUploadScope}
                />
              </Box>
            </Fade>
          </Box>
        </Box>
      </Fade>
      {isMutating && <LogoLoadingStill onTop />}
    </BaseLayout>
  );
};

const DocumentsCenter: React.FC = () => {
  return (
    <HelloSignProvider>
      <UploadFilesProvider>
        <DocumentsCenterInternal />
      </UploadFilesProvider>
    </HelloSignProvider>
  );
};

export default DocumentsCenter;
