import React, { useMemo, useState } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LockIcon from "@mui/icons-material/Lock";
import { orderBy } from "lodash";
import { useParams } from "react-router-dom";

import useGetFartherManagedAccounts from "@fartherfinance/frontend/api/Accounts/hooks/useGetFartherManagedAccounts";
import useDeleteAccountGroupsV4 from "@fartherfinance/frontend/api/PerformanceGroups/hooks/useDeleteAccountGroupsV4";
import usePatchAccountGroupsV4 from "@fartherfinance/frontend/api/PerformanceGroups/hooks/usePatchAccountGroupsV4";
import { AccountGroup } from "@fartherfinance/frontend/api/PerformanceGroups/requests/getSingleAccountGroupV4";
import { ClientId, FartherAccountId } from "@fartherfinance/frontend/api/Types";

import Button from "../../../MUI/Button/Button";
import DeleteAccountGroupModal from "../Delete/DeleteAccountGroupModal";
import CheckboxRow from "../Shared/CheckboxRow/CheckboxRow";
import FavoriteCheckbox from "../Shared/FavoriteCheckbox/FavoriteCheckbox";
import GroupName from "../Shared/GroupName/GroupName";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import ModalWithClearButton from "@src/sharedComponents/Modal/ModalWithClearButton";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import styles from "./EditAccountGroupModal.module.css";

interface Props {
  accountGroup: AccountGroup;
  isDefault: boolean;
  goBack: () => void;
  closeModalsAndDropdown: () => void;
}

const EditAccountGroupModal: React.FC<Props> = ({
  accountGroup,
  isDefault,
  goBack,
  closeModalsAndDropdown,
}: Props) => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFavorite, setIsFavorite] = useState<boolean>(
    accountGroup.isFavorite
  );
  const [groupName, setGroupName] = useState<string>(accountGroup.displayName);
  const [selectedAccountIds, setSelectedAccountIds] = useState<
    FartherAccountId[]
  >(accountGroup.virtualAccountIds);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const notify = useStatusNotification();

  const auth = useRequestAuth();
  const managedAccounts = useGetFartherManagedAccounts(clientId, auth);
  const updateAccountGroup = usePatchAccountGroupsV4(auth);
  const deleteAccountGroup = useDeleteAccountGroupsV4(auth);

  const accountsInAccountGroup = useMemo(() => {
    if (managedAccounts.isLoading || managedAccounts.hasError) {
      return [];
    }

    return managedAccounts.data.filter((ma) =>
      accountGroup.virtualAccountIds.some((a) => ma.virtualAccountId === a)
    );
  }, [accountGroup, managedAccounts]);

  const toggleAccount = (checked: boolean, accountId: FartherAccountId) => {
    if (checked) {
      setSelectedAccountIds([...selectedAccountIds, accountId]);
    } else {
      setSelectedAccountIds(
        selectedAccountIds.filter((id) => id !== accountId)
      );
    }
  };

  const isDefaultUpdateDirty = accountGroup.isFavorite !== isFavorite;
  const isDisabled =
    (isDefault && !isDefaultUpdateDirty) ||
    groupName.length < 3 ||
    selectedAccountIds.length < 1;

  const onUpdate = async () => {
    try {
      setIsLoading(true);

      const numAccountsAreSame =
        accountGroup.virtualAccountIds.length === selectedAccountIds.length;

      const accountsAreSame =
        numAccountsAreSame &&
        accountGroup.virtualAccountIds.every((id) =>
          selectedAccountIds.some((sa) => id === sa)
        );

      const body = isDefault
        ? {
            isFavorite,
          }
        : accountsAreSame
        ? {
            displayName: groupName,
            isFavorite,
          }
        : {
            displayName: groupName,
            isFavorite,
            virtualAccountIds: selectedAccountIds,
          };

      await updateAccountGroup({
        clientId,
        accountGroupId: accountGroup.accountGroupId,
        body,
      });

      notify("Account group saved", "Success");
      closeModalsAndDropdown();
    } catch (_e) {
      notify("Account group not saved. Try again.", "Error");
      setIsLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      setIsLoading(true);

      await deleteAccountGroup({
        clientId: clientId,
        accountGroupId: accountGroup.accountGroupId,
      });

      notify("Account group deleted", "Success");
      setShowDeleteModal(false);
      closeModalsAndDropdown();
    } catch (_e) {
      notify("Account group could not be deleted. Try again.", "Error");
      setIsLoading(false);
    }
  };

  return (
    <ModalWithClearButton closeModal={goBack}>
      {isLoading && <LogoLoadingStill onTop />}

      <div className={styles.flexRow}>
        <ArrowBackIcon className={styles.backArrowIcon} onClick={goBack} />

        <div className={styles.title}>
          {isDefault ? "Account Group" : "Edit Account Group"}
        </div>
      </div>

      <div className={styles.headingDiv}>
        <div
          className={styles.heading}
        >{`Accounts in this group (${accountsInAccountGroup.length})`}</div>

        {isDefault && (
          <Tooltip
            tooltipText="The accounts in a default group cannot be edited."
            placement="top"
          >
            <LockIcon className={styles.lockIcon} />
          </Tooltip>
        )}
      </div>

      {accountGroup.displayName === "Farther Managed Accounts" && (
        <div className={styles.subHeading}>
          All your Farther Managed Accounts are in this group.
        </div>
      )}

      <div className={styles.box}>
        {orderBy(
          managedAccounts.data ?? [],
          [(a) => a.accountDetails.displayName],
          ["asc"]
        ).map((a) => (
          <CheckboxRow
            key={a.virtualAccountId}
            account={a}
            isChecked={selectedAccountIds.some(
              (id) => id === a.virtualAccountId
            )}
            isDisabled={isDefault}
            onToggleAccount={toggleAccount}
          />
        ))}
      </div>

      <GroupName
        label={
          <div className={styles.flexRow}>
            <div className={styles.textInputLabel}>Group Name</div>

            {isDefault && (
              <Tooltip
                tooltipText="The accounts in a default group cannot be edited."
                placement="top"
              >
                <LockIcon className={styles.lockIcon} />
              </Tooltip>
            )}
          </div>
        }
        groupName={groupName}
        setGroupName={setGroupName}
        isDisabled={isDefault}
      />

      <FavoriteCheckbox isFavorite={isFavorite} setIsFavorite={setIsFavorite} />

      <div className={styles.footer}>
        <div className={styles.flexRow}>
          {!isDefault && (
            <div
              className={styles.deleteText}
              onClick={() => setShowDeleteModal(true)}
            >
              Delete Group
            </div>
          )}
        </div>

        <div className={styles.flexRow}>
          <Button
            text={"Cancel"}
            variant={"outlined"}
            buttonType={"secondary"}
            onClick={goBack}
            sx={{ width: "80px" }}
          />

          <Button
            text={"Save"}
            variant={"contained"}
            buttonType={"primary"}
            onClick={onUpdate}
            sx={{ marginLeft: "20px", width: "80px" }}
            disabled={isDisabled}
          />
        </div>
      </div>

      {showDeleteModal && (
        <DeleteAccountGroupModal
          onDelete={onDelete}
          closeModal={() => setShowDeleteModal(false)}
        />
      )}
    </ModalWithClearButton>
  );
};

export default EditAccountGroupModal;
