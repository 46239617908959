import {
  ActivityTypeParentCheckboxLabel,
  DateRangeCheckboxLabel,
} from "../shared";
import { CheckboxListConfig } from "@src/sharedComponents/ScrollableCheckboxList/ScrollableCheckboxList";

export const activityTypeFilterDefault: CheckboxListConfig<ActivityTypeParentCheckboxLabel> =
  {
    "Cash In": {
      isParent: true,
      checked: true,
      hasTopBorder: true,
      children: {
        "Cash Deposit": {
          checked: true,
        },
        "Pending ACAT": {
          checked: true,
        },
        "Pending Deposit": {
          checked: true,
        },
        "Transfer In": {
          checked: true,
        },
      },
    },
    "Cash Out": {
      isParent: true,
      checked: true,
      hasTopBorder: true,
      children: {
        "Cash Withdrawal": {
          checked: true,
        },
        "Pending Withdrawal": {
          checked: true,
        },
        "Pending Liquidation": {
          checked: true,
        },
        "Transfer Out": {
          checked: true,
        },
      },
    },
    Buy: {
      isParent: false,
      checked: true,
      hasTopBorder: true,
      children: null,
    },
    "Capital Gains": {
      isParent: false,
      checked: true,
      hasTopBorder: false,
      children: null,
    },
    "Capital Gains Reinvestment": {
      isParent: false,
      checked: true,
      hasTopBorder: false,
      children: null,
    },
    "Corporate Action": {
      isParent: false,
      checked: true,
      hasTopBorder: false,
      children: null,
    },
    Dividend: {
      isParent: false,
      checked: true,
      hasTopBorder: false,
      children: null,
    },
    "Dividend Reinvestment": {
      isParent: false,
      checked: true,
      hasTopBorder: false,
      children: null,
    },
    Expense: {
      isParent: false,
      checked: true,
      hasTopBorder: false,
      children: null,
    },
    Income: {
      isParent: false,
      checked: true,
      hasTopBorder: false,
      children: null,
    },
    "Income Reinvestment": {
      isParent: false,
      checked: true,
      hasTopBorder: false,
      children: null,
    },
    Interest: {
      isParent: false,
      checked: true,
      hasTopBorder: false,
      children: null,
    },
    "Interest Reinvestment": {
      isParent: false,
      checked: true,
      hasTopBorder: false,
      children: null,
    },
    Journal: {
      isParent: false,
      checked: true,
      hasTopBorder: false,
      children: null,
    },
    "Management Fee": {
      isParent: false,
      checked: true,
      hasTopBorder: false,
      children: null,
    },
    "Option Activity": {
      isParent: false,
      checked: true,
      hasTopBorder: false,
      children: null,
    },
    Rebate: {
      isParent: false,
      checked: true,
      hasTopBorder: false,
      children: null,
    },
    "Return of Capital": {
      isParent: false,
      checked: true,
      hasTopBorder: false,
      children: null,
    },
    Sale: {
      isParent: false,
      checked: true,
      hasTopBorder: false,
      children: null,
    },
    "Short Sale": {
      isParent: false,
      checked: true,
      hasTopBorder: false,
      children: null,
    },
    Valuation: {
      isParent: false,
      checked: true,
      hasTopBorder: false,
      children: null,
    },
  };

export const dateRangeFilterDefault: CheckboxListConfig<DateRangeCheckboxLabel> =
  {
    All: {
      isParent: false,
      checked: true,
      hasTopBorder: false,
      children: null,
    },
    "Current Month": {
      isParent: false,
      checked: false,
      hasTopBorder: false,
      children: null,
    },
    "Previous Month": {
      isParent: false,
      checked: false,
      hasTopBorder: false,
      children: null,
    },
    "Year-to-date": {
      isParent: false,
      checked: false,
      hasTopBorder: false,
      children: null,
    },
    Custom: {
      isParent: false,
      checked: false,
      hasTopBorder: false,
      children: null,
    },
  };
