import React from "react";

import LinearProgress from "@mui/material/LinearProgress";

import ChecklistEntry, {
  ChecklistEntryDetails,
} from "../ChecklistEntry/ChecklistEntry";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./Checklist.module.css";

interface ChecklistProps {
  checklistEntires: ChecklistEntryDetails[];
}

const Checklist = ({ checklistEntires }: ChecklistProps): JSX.Element => {
  const checklistItemsToComplete = checklistEntires.map(
    (entry) => entry.isStepComplete
  );
  const completedChecklistItems =
    checklistItemsToComplete.filter(Boolean).length;
  const progressValue =
    (100 / checklistItemsToComplete.length) * completedChecklistItems;

  return (
    <div className={styles.container}>
      <div className={styles.inner_container}>
        <div>
          <div className={styles.card_header}>
            <div className={styles.card_header_row}>
              <p
                className={toClassName(
                  styles.card_header_text,
                  styles.card_header_text_title
                )}
              >
                Setup Checklist
              </p>
              <div className={styles.progress_wrapper}>
                <LinearProgress
                  className={styles.progress}
                  classes={{
                    root: styles.progress_root,
                    bar: styles.progress_bar,
                  }}
                  variant="determinate"
                  value={progressValue}
                />
              </div>
            </div>

            <div className={styles.card_header_row}>
              <p
                className={toClassName(
                  styles.card_header_text,
                  styles.card_header_text_subtitle
                )}
              >
                Complete at your own pace.
              </p>
              <p
                className={toClassName(
                  styles.card_header_text,
                  styles.card_header_text_subtitle
                )}
              >
                {`${completedChecklistItems}/${checklistItemsToComplete.length}`}
              </p>
            </div>
          </div>

          <div className={styles.card_list}>
            {checklistEntires.map((entry, index) => (
              <ChecklistEntry
                key={index}
                title={entry.title}
                subtitleIncomplete={entry.subtitleIncomplete}
                subtitleComplete={entry.subtitleComplete}
                isStepComplete={entry.isStepComplete}
                isStepDisabled={entry.isStepDisabled}
                isStepDummy={entry.isStepDummy}
                onClick={entry.onClick}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checklist;
