import React, { useMemo, useState } from "react";

import { orderBy, uniq } from "lodash";
import { Link, useHistory, useParams } from "react-router-dom";

import useAdvisorClients from "@fartherfinance/frontend/api/Entity/hooks/useAdvisorClients";
import useSleeve from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useSleeve";
import { ClientId, SleeveId } from "@fartherfinance/frontend/api/Types";

import TableLoading from "../SleeveHoldingsTable/Table/TableLoading";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import { captureException } from "@src/multiCustodian/services/tracking";
import { pluralize } from "@src/utils/pluralize";

import styles from "./SleeveUsageTable.module.css";
interface Props {
  sleeveId: SleeveId;
  isInClientPortal?: boolean;
}

const SleeveUsageTable = ({
  sleeveId,
  isInClientPortal,
}: Props): JSX.Element => {
  const [showClients, setShowClients] = useState<boolean>(false);

  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const history = useHistory();

  const auth = useRequestAuth();
  const advisorAuth = useAdvisorRequestAuth();

  const sleeve = useSleeve(sleeveId, auth);
  const clients = useAdvisorClients(
    undefined,
    isInClientPortal ? null : advisorAuth
  );

  const resetSearchUrlParamAndReturnParamsString = useMemo(() => {
    const location = history.location;
    const params = new URLSearchParams(location.search);
    params.delete("search");
    params.delete("page");
    return params.toString();
  }, [history.location]);

  const numClients = useMemo(() => {
    return uniq(
      (sleeve.data?.portfoliosApplied ?? []).flatMap(
        (p) => p.clientsUsingPortfolio
      )
    ).length;
  }, [sleeve.data]);

  const modelsView = useMemo(() => {
    if (sleeve.data === undefined) {
      return [];
    }

    return orderBy(
      sleeve.data.portfoliosApplied,
      [(p) => p.displayName],
      ["asc"]
    ).map((p) => {
      return (
        <Link
          style={{ textDecoration: "none" }}
          key={p.portfolioId}
          to={
            isInClientPortal
              ? {
                  pathname: `/Client/${clientId}/Investments/Portfolios/${p.portfolioId}/ModelDetails`,
                  state: history.location.state,
                }
              : {
                  pathname: `/Advisor/Investments/ModelMarketplace/${p.portfolioId}/ModelDetails`,
                  state: history.location.state,
                  search: resetSearchUrlParamAndReturnParamsString,
                }
          }
        >
          <div className={styles.modelsRow}>
            <div className={styles.col1ModelsText}>{p.displayName}</div>

            <div className={styles.col3ModelsText}>
              {(p.weightInsidePortfolio / 10_000).toLocaleString("en-US", {
                style: "percent",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
        </Link>
      );
    });
  }, [
    sleeve.data,
    history,
    isInClientPortal,
    clientId,
    resetSearchUrlParamAndReturnParamsString,
  ]);

  const clientsView = useMemo(() => {
    if (sleeve.data === undefined || clients.data === undefined) {
      return [];
    }

    const clientsUsingModelWithSleeve = sleeve.data.portfoliosApplied.flatMap(
      (p) =>
        p.clientsUsingPortfolio.map((clientId) => {
          const client = clients.data.clients.find(
            (c) => c.clientId === clientId
          );
          if (client === undefined) {
            const err = new Error(
              `Could not map clientId: ${clientId} from portfoliosApplied from sleeve: ${sleeveId} to a client from useAdvisorClients`
            );
            captureException(err, {
              extra: {
                sleeve: sleeveId,
                targetClientId: clientId,
                file: "SleeveUsageTable.tsx",
              },
            });
          }

          // dont need clientsUsingPortfolio bc point is to flatten the sleeve obj to each client -> model pair
          return {
            portfolioId: p.portfolioId,
            displayName: p.displayName,
            weightInsidePortfolio: p.weightInsidePortfolio,
            clientId: clientId,
            clientName: `${client?.name.first ?? "?"} ${
              client?.name.last ?? "?"
            }`,
          };
        })
    );

    return orderBy(
      clientsUsingModelWithSleeve,
      [(c) => c.clientName, (c) => c.displayName],
      ["asc", "asc"]
    ).map((p) => {
      return (
        <Link
          style={{ textDecoration: "none" }}
          key={p.portfolioId}
          to={{
            pathname: `/Advisor/Investments/ModelMarketplace/${p.portfolioId}/ModelDetails`,
            state: history.location.state,
            search: resetSearchUrlParamAndReturnParamsString,
          }}
        >
          <div className={styles.clientsRow}>
            <div className={styles.col1ClientsText}>{p.clientName}</div>

            <div className={styles.col2ClientsText}>{p.displayName}</div>

            <div className={styles.col3ClientsText}>
              {(p.weightInsidePortfolio / 10_000).toLocaleString("en-US", {
                style: "percent",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
        </Link>
      );
    });
  }, [
    sleeve.data,
    clients.data,
    sleeveId,
    history,
    resetSearchUrlParamAndReturnParamsString,
  ]);

  if (sleeve.isLoading || (!isInClientPortal && clients.isLoading)) {
    return <TableLoading />;
  }

  if (sleeve.hasError || (!isInClientPortal && clients.hasError)) {
    return <div>Error</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div className={styles.headingTextLeft}>
          {numClients <= 0 && !isInClientPortal
            ? "Clients using this sleeve"
            : "Usage Breakdown"}
        </div>

        <div className={styles.headingTextRight}>
          {numClients <= 0 && !isInClientPortal
            ? "0 Clients"
            : `${pluralize(
                showClients ? numClients : sleeve.data.portfoliosApplied,
                showClients ? "Client" : "Model",
                true
              )} Using This Sleeve`}
        </div>
      </div>

      {numClients > 0 && !isInClientPortal && (
        <div className={styles.chipsSection}>
          <div
            className={!showClients ? styles.chipSelected : styles.chip}
            onClick={() => setShowClients(!showClients)}
          >
            Show Models
          </div>

          <div
            className={showClients ? styles.chipSelected : styles.chip}
            onClick={() => setShowClients(!showClients)}
          >
            Show Clients
          </div>
        </div>
      )}

      <div className={styles.body}>
        {numClients <= 0 && !isInClientPortal ? (
          <div className={styles.noClientsText}>
            No clients are using this sleeve
          </div>
        ) : showClients && !isInClientPortal ? (
          <>
            <div className={styles.clientsBodyHeader}>
              <div className={styles.col1HeaderText}>Client Name</div>

              <div className={styles.col2HeaderText}>Model</div>

              <div className={styles.col3HeaderText}>Sleeve Weight</div>
            </div>

            <div className={styles.rows}>{clientsView}</div>
          </>
        ) : (
          <>
            <div className={styles.modelsBodyHeader}>
              <div className={styles.colHeaderText}>Model Name</div>

              <div className={styles.colHeaderText}>Sleeve Weight</div>
            </div>

            <div className={styles.rows}>{modelsView}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default SleeveUsageTable;
