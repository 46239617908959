import {
  AltAssetOfferingTypeCard,
  InsuranceOfferingTypeCard,
  LendingOfferingTypeCard,
} from "./types";

export const insuranceOfferingTypes: InsuranceOfferingTypeCard[] = [
  {
    name: "Term Life",
    value: "term_life",
    description:
      "Affordable policies to protect your loved ones for a set term, usually between 10 and 30 years.",
  },
  {
    name: "Permanent Life",
    value: "permanent_life",
    description:
      "Policies with lifelong coverage and the opportunity to build cash value, which accumulates on a tax-deferred basis.",
  },
  {
    name: "Annuities",
    value: "annuities",
    description:
      "Insurance contracts used for asset accumulation or as income replacement with a stream of payments for a specified period or the rest of your life.",
  },
  {
    name: "Long-Term Care",
    value: "long-term_care",
    description:
      "Policies to cover the costs of care related to aging or disability. Helps protect your savings and get you access to better quality care.",
  },
];

export const lendingOfferingTypes: LendingOfferingTypeCard[] = [
  {
    name: "Home Loans",
    value: "home_loans",
    description: "Mortgages for buying a home.",
  },
  {
    name: "Securities-Based Loans",
    value: "securities-based_loans",
    description:
      "Using your investment portfolio as collateral for a line of credit.",
  },
  {
    name: "Commercial Loans",
    value: "commercial_loans",
    description: "Financing for business expenses.",
  },
  {
    name: "Specialty Loans",
    value: "specialty_loans",
    description: "Customized loans for uncommon, big-ticket assets.",
  },
  {
    name: "Personal Loans",
    value: "personal_loans",
    description:
      "Loans for anything from student debt to buying a new car or boat.",
  },
];

export const altAssetOfferingTypes: AltAssetOfferingTypeCard[] = [
  {
    name: "Private Equity",
    value: "private_equity",
    description:
      "Investments in private companies or buyouts of public companies, aiming for substantial long-term returns through active management and eventual sale or public offering.",
  },
  {
    name: "Private Debt",
    value: "private_debt",
    description:
      "Lending funds to private companies or projects, often yielding higher returns than public debt due to increased risk and reduced liquidity.",
  },
  {
    name: "Hedge Fund",
    value: "hedge_fund",
    description:
      "Pooled investment funds that can employ diverse strategies, including leveraging, short-selling, and trading non-traditional investments, to achieve high returns which are often uncorrelated with traditional market performance.",
  },
  {
    name: "Venture Capital",
    value: "venture_capital",
    description:
      "Investments in early-stage startups with high growth potential, either as individual startups or funds of startups.",
  },
  {
    name: "Collectibles",
    value: "collectibles",
    description:
      "Investments in art, wine and spirits, and other rare items, which have potential to appreciate in value over time, driven by rarity and demand.",
  },
  {
    name: "Digital Assets",
    value: "digital_assets",
    description:
      "Digital forms of value or ownership including cryptocurrency, tokenized assets, and NFTs, attracting investors for their potential high returns and innovative technology.",
  },
  {
    name: "Real Assets",
    value: "real_assets",
    description:
      "Purchasing property to generate rental income or capital appreciation, often seen as a stable, long-term investment with potential tax benefits.",
  },
  {
    name: "Structured Investments",
    value: "structured_investments",
    description:
      "Structured investments consist of with fixed income and equity derivative components to achieve a more defined outcome: yield, equity exposure and protection against downturns.",
  },
];
