import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

export const useStyles = makeStyles()((theme) => ({
  container: {
    marginBottom: theme.spacing(pxToSpacing(60)),
  },
  tablesContainer: {
    width: "100%",
  },
  tradesContainer: {
    marginBottom: theme.spacing(pxToSpacing(48)),
  },
  indexCell: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.colors.primary.tints.navy50,

    paddingLeft: theme.spacing(pxToSpacing(21)),
  },
  gridTemplate: {
    gridTemplateColumns: "1fr 1fr 1fr 1fr 2fr",
  },
  tickerCell: {
    display: "flex",
    alignItems: "baseline",
    overflow: "hidden",
  },
  tickerDescription: {
    fontFamily: theme.typography.fontSecondary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(10),
    letterSpacing: "0.63px",
    color: theme.appearance.$textSubtle,

    marginLeft: theme.spacing(pxToSpacing(6)),
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));
