import React, { createContext, useContext, useMemo } from "react";

import HelloSign from "hellosign-embedded";

const HelloSignContext = createContext<HelloSign | undefined>(undefined);

const HelloSignProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const helloSignClient = useMemo(
    () =>
      new HelloSign({
        clientId: process.env.HELLOSIGN_CLIENT_ID,
        skipDomainVerification: process.env.WEBAPP_ENV !== "PROD",
      }),
    []
  );

  return (
    <HelloSignContext.Provider value={helloSignClient}>
      {children}
    </HelloSignContext.Provider>
  );
};

export const useHelloSign = () => useContext(HelloSignContext);

export default HelloSignProvider;
