import React from "react";

import { TaxBudget } from "@fartherfinance/frontend/api/InvestmentModel/Types";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import Modal from "@src/sharedComponents/Modal/Modal";

import styles from "./DeleteBudgetModal.module.css";

interface Props {
  budget: TaxBudget;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DeleteBudgetModal: React.FC<Props> = ({
  budget,
  isOpen,
  onClose,
  onConfirm,
}) => {
  return (
    <Modal
      closeModal={onClose}
      isOpen={isOpen}
      modalStyle={{ overflow: "visible", width: "460px" }}
    >
      <div className={styles.container}>
        <div className={styles.title}>
          Additional capital gains taxes may be incurred without a tax budget
        </div>

        <div className={styles.body}>
          If this budget is deleted and a new budget is not immediately set, the
          portfolio may be rebalanced with no limit on realized capital gains.
        </div>

        <div className={styles.footer}>
          <Button
            text="Cancel"
            variant="outlined"
            buttonType="primary"
            onClick={onClose}
          />

          <Button
            text={`Delete ${budget.year} Tax Budget`}
            variant="outlined"
            buttonType="error"
            onClick={onConfirm}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteBudgetModal;
