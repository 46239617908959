import React, { useMemo } from "react";

import {
  createTheme,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  Tooltip,
} from "@mui/material";
import { red } from "@mui/material/colors";
import RadioGroup from "@mui/material/RadioGroup";
import { SxProps, ThemeProvider } from "@mui/system";
import { capitalize } from "lodash";
import { useSelector } from "react-redux";

import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import checkmarkIcon from "../../assets/svg/check_green.svg";
import warningIcon from "../../assets/svg/exclamation.svg";
import infoIcon from "../../assets/svg/info.svg";
import { State } from "../../store";

interface Props<T extends string> {
  label: string;
  values: T[] | readonly T[];
  onChange: (newVal: T) => void;
  onBlur?: () => void;
  value: T | null;
  isValid: boolean | undefined;
  info?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  row?: boolean;
  labelColor?: string;
  labelStyle?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
}

const RadioButtons = <T extends string>(props: Props<T>): JSX.Element => {
  const {
    color: { $icon, $textSubtle },
  } = useTheme();

  const { fontRegular } = useSelector((state: State) => ({
    fontRegular: state.main_Reducer.brand.current.fonts.regular,
  }));

  let hasDevError = false;

  const redColor = "#FF0000"; // For Development error state representation

  if (process.env.WEBAPP_ENV !== "PROD") {
    if (props.value !== null && !props.values.includes(props.value)) {
      hasDevError = true;
      console.error(
        `Error: The value of prop "curValue" (${props.value}) does not exist in props.values.`
      );
    }
  }

  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: fontRegular,
        },
      }),
    [fontRegular]
  );

  const formLabelSx: SxProps<typeof theme> = useMemo(
    () => ({
      display: "flex",
      gap: "6px",
      alignItems: "center",
      color: hasDevError ? redColor : props.labelColor ?? $textSubtle,
      "&.Mui-focused": {
        color: hasDevError ? redColor : props.labelColor ?? $textSubtle,
      },
      fontSize: "14px",
      fontWeight: 600,
    }),
    [hasDevError, $textSubtle, props.labelColor]
  );

  const radioSx: SxProps<typeof theme> = useMemo(
    () => ({
      color: hasDevError ? redColor : $icon,
      "& .MuiSvgIcon-root": {
        ...props.iconStyle,
      },
      "&.Mui-checked": {
        color: hasDevError ? redColor : $icon,
        "+ .MuiFormControlLabel-label": {
          color: hasDevError ? redColor : $icon,
        },
      },
    }),
    [hasDevError, $icon, props.iconStyle]
  );

  const formControlLabelSx = useMemo(
    () => ({
      color: hasDevError ? redColor : $textSubtle,
      ".MuiFormControlLabel-label": {
        fontSize: "14px",
        fontWeight: 600,
        ...props.labelStyle,
      },
    }),
    [hasDevError, $textSubtle, props.labelStyle]
  );

  return (
    <ThemeProvider theme={theme}>
      <FormControl fullWidth sx={props.style}>
        <FormLabel sx={formLabelSx}>
          {props.label}

          {props.info !== undefined && (
            <Tooltip title={props.info}>
              <IconButton>
                <img src={infoIcon} />
              </IconButton>
            </Tooltip>
          )}

          {props.isValid !== undefined && (
            <img src={props.isValid ? checkmarkIcon : warningIcon} />
          )}
        </FormLabel>

        <RadioGroup
          row={props.row ?? true} // Horizontal is default
          value={props.value}
          onChange={
            props.disabled
              ? undefined
              : (e) => props.onChange(e.target.value as T)
          }
          onBlur={props.onBlur}
        >
          {props.values.map((v) => (
            <FormControlLabel
              disabled={props.disabled}
              key={v}
              value={v}
              control={<Radio sx={radioSx} />}
              label={capitalize(v)}
              sx={formControlLabelSx}
            />
          ))}
        </RadioGroup>

        {props.error === true ? (
          <div style={{ color: red[700], fontSize: 12 }}>
            {props.helperText}
          </div>
        ) : null}
      </FormControl>
    </ThemeProvider>
  );
};

export default RadioButtons;
