interface LocalUser {
  clientId: string;
  name: {
    first: string;
    last: string;
  };
  emailAddress: string;
}

export default function searchFilter<U extends LocalUser>(
  searchTerm: string,
  userList: U[]
): U[] {
  return userList.filter((user) => {
    return (
      filterByNameHelper(user.name.first, user.name.last, searchTerm) ||
      filterByIdHelper(user.clientId, searchTerm) ||
      filterByEmailHelper(user.emailAddress, searchTerm)
    );
  });
}

const filterByEmailHelper = (
  email: string | null,
  searchTerm: string
): boolean => {
  return (email ?? "").toLowerCase().includes(searchTerm.toLowerCase().trim());
};

const filterByIdHelper = (
  id: string | number | null,
  searchTerm: string
): boolean => {
  return id === "" || id === null
    ? false
    : `${id}`.toLowerCase().startsWith(searchTerm.toLowerCase().trim());
};

export const filterByNameHelper = (
  first: string | null,
  last: string | null,
  searchTerm: string
): boolean => {
  const firstLC = (first ?? "").toLowerCase();
  const lastLC = (last ?? "").toLowerCase();
  const searchTermLC = searchTerm.toLowerCase().trim();

  return (
    firstLC.includes(searchTermLC) ||
    lastLC.includes(searchTermLC) ||
    `${firstLC} ${lastLC}`.includes(searchTermLC)
  );
};
