import { isNil } from "lodash";

import { isValidNumber } from "@src/sharedComponents/Forms/utils/isValidNumber";

export default function numberWithinLimit(
  input: string | null | undefined,
  min: number,
  max: number,
  required: boolean
): true | string {
  const isEmpty = isNil(input) || input === "";

  const isValidNum = !isEmpty && isValidNumber(input);

  const withinLimits = min <= Number(input) && Number(input) <= max;

  if (required) {
    return !isEmpty && withinLimits && isValidNum
      ? true
      : `Must be between ${min} - ${max}, inclusive`;
  }

  return isEmpty || (isValidNum && withinLimits)
    ? true
    : `Must be between ${min} - ${max}, inclusive`;
}
