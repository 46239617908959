import React, { useEffect } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useParams } from "react-router-dom";

import { ClientId, FartherAccountId } from "@fartherfinance/frontend/api/Types";

import Accounts from "../Accounts";
import AccountsDrawer from "@src/multiCustodian/components/Client/Accounts/AccountsDrawer";
import AccountsDrawerV4 from "@src/multiCustodian/components/Client/Accounts/AccountsDrawerV4";
import AccountHoldingsContent from "@src/multiCustodian/components/Client/Accounts/Holdings/Holdings";
import AccountHoldingsContentV4 from "@src/multiCustodian/components/Client/Accounts/Holdings/HoldingsV4";
import { trackEvent } from "@src/multiCustodian/services/tracking";

export default function AccountHoldings(): JSX.Element {
  const { clientId, accountId } = useParams<{
    clientId: ClientId;
    accountId: FartherAccountId;
  }>();

  const { enableCustomPerformanceGroupsV2 } = useFlags();

  useEffect(() => {
    trackEvent({
      name: "Client Open Accounts Details Holdings",
    });
  }, []);

  return (
    <Accounts>
      {enableCustomPerformanceGroupsV2 ? (
        <AccountsDrawerV4 curTab="Holdings">
          <AccountHoldingsContentV4 accountId={accountId} />
        </AccountsDrawerV4>
      ) : (
        <AccountsDrawer curTab="Holdings">
          <AccountHoldingsContent clientId={clientId} accountId={accountId} />
        </AccountsDrawer>
      )}
    </Accounts>
  );
}
