import React, { useCallback, useEffect, useState } from "react";

import * as Sentry from "@sentry/react";
import ms from "ms";

import useLogout from "@src/multiCustodian/hooks/useLogout";
import { clearUserData } from "@src/multiCustodian/utils/clearUserData";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

import * as styles from "./Logout.module.css";

export default function AdvisorLogout() {
  const [isLoading, setIsLoading] = useState(true);
  const stytchLogout = useLogout();

  const callLogout = useCallback(async () => {
    await stytchLogout();

    await clearUserData("Client");

    await Sentry.flush(2000);

    setTimeout(() => {
      setIsLoading(false);
    }, ms("2 seconds"));
  }, [stytchLogout]);

  useEffect(() => {
    callLogout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoading === false) {
      // do not use history we want to reset memory
      window.location.replace("/Login");
    }
  }, [isLoading]);

  return (
    <div className={styles.container}>
      <LogoLoadingStill />
    </div>
  );
}
