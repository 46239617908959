import React, { useMemo } from "react";

import formatDateForGraph from "@src/multiCustodian/components/PerformanceGroups/utils/formatDateForGraph";

import styles from "./DateDisplay.module.css";

const GRAPH_PX_POS_AFTER_FROM_DATE = 185;

const END_DATE_TEXT_PX_WIDTH = 80;

export interface XAxisData {
  index: number;
  xPos: number;
  graphWidth: number;
}

type DataLocal<DateKey extends string> = {
  //Different data types may have differing key/value pairs to represent the date
  [_key in DateKey]: Date | string;
};

interface Props<DateKey extends string> {
  isHoveringOverGraph: boolean;
  startDate: Date | string | null;
  xAxisData: XAxisData | null;
  dateKey: DateKey;
  data: DataLocal<DateKey>[];
}

const DateDisplay = <DateKey extends string>({
  isHoveringOverGraph,
  startDate,
  xAxisData,
  data,
  ...props
}: React.PropsWithChildren<Props<DateKey>>): JSX.Element => {
  const startDateFormatted =
    startDate === null ? null : formatDateForGraph(startDate);

  const curDataItem = useMemo(() => {
    return xAxisData !== null && data[xAxisData.index] !== undefined
      ? data[xAxisData.index]
      : null;
  }, [data, xAxisData]);

  const curItemEndDate = useMemo(() => {
    if (curDataItem === null) {
      return null;
    }

    return formatDateForGraph(curDataItem[props.dateKey]);
  }, [curDataItem, props.dateKey]);

  if (xAxisData === null || isHoveringOverGraph === false) {
    return (
      <div className={styles.x_axis_dates_div}>
        <div className={styles.x_axis_date}>{startDateFormatted}</div>

        {props.children}
      </div>
    );
  }

  const startDateText =
    startDateFormatted === null
      ? ""
      : xAxisData.xPos <= GRAPH_PX_POS_AFTER_FROM_DATE
      ? `${startDateFormatted} ${
          xAxisData.index === 0
            ? ""
            : `- ${curItemEndDate !== null ? curItemEndDate : ""}`
        }`
      : startDateFormatted;

  const marginRight =
    xAxisData.xPos + END_DATE_TEXT_PX_WIDTH >= xAxisData.graphWidth
      ? 0 // date text will cling to right side of div
      : xAxisData.graphWidth - (xAxisData.xPos + END_DATE_TEXT_PX_WIDTH); // in line with x index/vert hover line

  return (
    <div className={styles.x_axis_dates_div}>
      <div className={styles.x_axis_date}>{startDateText}</div>

      {xAxisData.xPos > GRAPH_PX_POS_AFTER_FROM_DATE && (
        <div
          className={styles.x_axis_cur_point_date}
          style={{
            marginRight: `${marginRight}px`,
          }}
        >
          {curItemEndDate !== null ? curItemEndDate : ""}
        </div>
      )}

      {props.children}
    </div>
  );
};

export default DateDisplay;
