import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

export const useStyles = makeStyles()((theme) => ({
  form: {
    width: 600,
  },
  formFirstRow: {
    marginTop: theme.spacing(pxToSpacing(-30)),

    "& > div:nth-child(2)": {
      paddingTop: theme.spacing(pxToSpacing(20)),
    },
  },
  footer: {
    marginTop: theme.spacing(pxToSpacing(24)),
  },
}));
