import { useEffect } from "react";

import { FartherToken } from "@fartherfinance/frontend/api/Identity/requests/exchangeStytchToken";

import { LocalStorageKeys } from "../components/Login/constants";

import useLogIntoRedux from "./useLogIntoRedux";
import useNavigateOnLogin from "./useNavigateOnLogin";

export default function useCheckSavedSession() {
  const navigateOnLogin = useNavigateOnLogin();
  const loginToRedux = useLogIntoRedux();

  useEffect(() => {
    try {
      const personTypeStorage = localStorage.getItem(
        LocalStorageKeys.personType
      );
      const token = localStorage.getItem(LocalStorageKeys.token);
      const personId = localStorage.getItem(LocalStorageKeys.personId);

      if (personTypeStorage !== null && token !== null && personId !== null) {
        const personType = personTypeStorage as "Advisor" | "Client";
        loginToRedux(personId, personType, token as FartherToken);
        navigateOnLogin(personType as "Advisor" | "Client");
      }
    } catch (error) {
      console.error(error);
    }
  }, [loginToRedux, navigateOnLogin]);
}
