import React from "react";

import { Box, Fade } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";

import { InsuranceOfferingType } from "@fartherfinance/frontend/api/AltAssetsService/Types";

import { InsuranceMarketplacePath } from "@src/config/routing/RouterPaths";
import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import { insuranceOfferingTypes } from "@src/multiCustodian/components/Marketplace/constants";
import OfferingTypeList from "@src/multiCustodian/components/Marketplace/OfferingTypeList/OfferingTypeList";
import { MarketplaceClientPathParams } from "@src/multiCustodian/components/Marketplace/types";
import PageHero from "@src/sharedComponents/PageHero/PageHero";
import TitleBlock from "@src/sharedComponents/PageHero/TitleBlock";

import offeringTypeListStyles from "@src/multiCustodian/components/Marketplace/OfferingTypeList/OfferingTypeList.module.css";

const InsuranceMarketplace = (): JSX.Element => {
  const { clientId } = useParams<MarketplaceClientPathParams>();
  const history = useHistory();

  const handleRedirectToOfferingsList = (
    offeringType: InsuranceOfferingType
  ): void => {
    history.push(
      `/Client/${clientId}/${InsuranceMarketplacePath}/${offeringType}`
    );
  };

  return (
    <BaseLayout>
      <PageHero start={<TitleBlock title="Insurance" />} />

      <Fade in>
        <Box className={offeringTypeListStyles.wrapperClient}>
          <OfferingTypeList
            offeringTypes={insuranceOfferingTypes}
            onOfferingTypeClick={handleRedirectToOfferingsList}
          />
        </Box>
      </Fade>
    </BaseLayout>
  );
};

export default InsuranceMarketplace;
