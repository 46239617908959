import React from "react";

import { MarketplaceAssetType } from "@fartherfinance/frontend/api/AltAssetsService/Types";

import LendingOfferingsListClient from "../OfferingsListClient/LendingOfferingsListClient";
import { LendingMarketplacePath } from "@src/config/routing/RouterPaths";
import { lendingOfferingTypes } from "@src/multiCustodian/components/Marketplace/constants";

import OfferingDetailsClient from "./OfferingDetailsClient";

const LendingOfferingDetailsClient: React.FC = () => {
  return (
    <LendingOfferingsListClient>
      <OfferingDetailsClient
        assetType={MarketplaceAssetType.enum.lending}
        offeringTypes={lendingOfferingTypes}
        marketplacePath={LendingMarketplacePath}
      />
    </LendingOfferingsListClient>
  );
};

export default LendingOfferingDetailsClient;
