import React from "react";

import { Stack } from "@mui/material";

import Button from "@src/multiCustodian/components/MUI/Button/Button";

interface OpportunityDrawerFooterProps {
  isPending: boolean;
  onSave: () => void;
  onCancel: () => void;
}

export default function OpportunityDrawerFooter({
  isPending,
  onSave,
  onCancel,
}: OpportunityDrawerFooterProps): JSX.Element {
  return (
    <Stack direction="row" justifyContent="flex-end" spacing={2}>
      <Button
        variant="outlined"
        buttonType="primary"
        text="Cancel"
        onClick={onCancel}
      />
      <Button
        variant="contained"
        buttonType="primary"
        text="Save"
        disabled={isPending}
        onClick={onSave}
      />
    </Stack>
  );
}
