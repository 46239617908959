import React from "react";

import { useSelector } from "react-redux";

import CheckmarkGrayCircleIcon from "@src/assets/svg/checkmark-gray-circle.svg";
import CheckmarkGreenCircleIcon from "@src/assets/svg/checkmark-green-circle.svg";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import { State } from "@src/store";

import styles from "./ChecklistEntry.module.css";

export interface ChecklistEntryDetails {
  title: string;
  subtitleIncomplete: string;
  subtitleComplete: string;
  isStepComplete: boolean;
  isStepDisabled?: boolean;
  isStepDummy?: boolean;
  onClick: () => void;
}

type ChecklistEntryProps = ChecklistEntryDetails;

const ChecklistEntry = ({
  title,
  subtitleIncomplete,
  subtitleComplete,
  isStepComplete,
  isStepDisabled,
  isStepDummy,
  onClick,
}: ChecklistEntryProps): JSX.Element => {
  const { chevronRightSrc } = useSelector((state: State) => ({
    chevronRightSrc: state.main_Reducer.brand.current.images.chevron_right,
  }));

  const subtitle = isStepComplete ? subtitleComplete : subtitleIncomplete;

  return (
    <div
      className={toClassName(styles.container, {
        [styles.container_disabled]: isStepDisabled,
      })}
      onClick={isStepDisabled ? undefined : onClick}
    >
      <div className={styles.inner_container}>
        <img
          src={
            isStepComplete ? CheckmarkGreenCircleIcon : CheckmarkGrayCircleIcon
          }
          alt=""
          width="20"
          height="20"
        />
        <div className={styles.content}>
          <div>
            <h6
              className={toClassName(styles.title, {
                [styles.title_complete]: isStepComplete,
                [styles.title_complete_dummy]: isStepDummy,
              })}
            >
              {title}
            </h6>
            <p className={styles.subtitle}>{subtitle}</p>
          </div>
          {!isStepComplete && (
            <img className={styles.anchor} src={chevronRightSrc} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChecklistEntry;
