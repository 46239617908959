import React, { useEffect, useMemo, useState } from "react";

import { orderBy, uniq } from "lodash";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import useCustomPortfoliosV1 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useCustomPortfoliosV1";
import useEssentialAllPortfoliosV1 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useEssentialAllPortfoliosV1";
import usePortfolioPartners from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/usePortfolioPartners";
import useReplacePortfolioV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useReplacePortfolioV2";
import { PortfolioTaxType } from "@fartherfinance/frontend/api/PortfolioManagement/requests/Types";
import { PortfolioId } from "@fartherfinance/frontend/api/Types";

import {
  TaxStatus,
  taxStatusToTaxType,
  taxTypeToStatus,
} from "../../utils/taxType";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { captureException } from "@src/multiCustodian/services/tracking";
import Checkbox from "@src/sharedComponents/Checkbox/Checkbox";
import Modal from "@src/sharedComponents/Modal/Modal";
import Select from "@src/sharedComponents/Select/Select";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import { State } from "@src/store";

import styles from "./ReplaceModelModal.module.css";

const taxTypeOptions = ["Taxable", "Tax-Advantaged", "Any"] as const;

const ReplaceModelModal = (): JSX.Element => {
  const [isMutating, setIsMutating] = useState<boolean>(false);
  const [modelType, setModelType] = useState<string | null>(null);
  const [modelName, setModelName] = useState<string | null>(null);
  const [taxType, setTaxType] = useState<PortfolioTaxType>("taxable");
  const [isDirectIndexed, setIsDirectIndexed] = useState<boolean>(false);
  const [newPortfolioId, setNewPortfolioId] = useState<PortfolioId | null>(
    null
  );

  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));

  const history = useHistory();

  const { portfolioId, sharingOrModelDetails } = useParams<{
    portfolioId: PortfolioId;
    sharingOrModelDetails: "Sharing" | "ModelDetails";
  }>();

  useEffect(() => {
    if (
      sharingOrModelDetails === "Sharing" ||
      sharingOrModelDetails === "ModelDetails"
    ) {
      return;
    } else {
      history.push({
        ...history.location,
        pathname: `/Advisor/Investments/ModelMarketplace/${portfolioId}/ModelDetails`,
      });
    }
  }, [history, portfolioId, sharingOrModelDetails]);

  const statusNotification = useStatusNotification();

  const auth = useAdvisorRequestAuth();
  const partners = usePortfolioPartners(auth);
  const allEssential = useEssentialAllPortfoliosV1(auth);
  const advisorCustomPortfolios = useCustomPortfoliosV1(advisorId, auth);
  const replacePortfolio = useReplacePortfolioV2(auth);

  const modelTypeOptions = useMemo((): string[] => {
    if (partners.isLoading || partners.hasError) {
      return [];
    }

    const partnerTypes = [
      "Custom",
      ...partners.data.portfolioPartners.map((p) => p.portfolioPartnerName),
    ];

    return orderBy(partnerTypes, (el) => el.toLowerCase(), "asc");
  }, [partners]);

  interface MiniPortfolio {
    portfolioId: PortfolioId;
    displayName: string;
  }

  const portfolio = useMemo((): MiniPortfolio | undefined => {
    if (
      allEssential.data === undefined ||
      advisorCustomPortfolios.data === undefined
    ) {
      return undefined;
    }

    const allPortfolios = [
      ...allEssential.data.map((ep) => ({
        portfolioId: ep.portfolioModelId,
        displayName: ep.displayName,
      })),
      ...advisorCustomPortfolios.data.portfolios.map((cp) => ({
        portfolioId: cp.portfolioModelId,
        displayName: cp.displayName,
      })),
    ];

    const targetPortfolio = allPortfolios.find(
      (p) => portfolioId === p.portfolioId
    );
    if (targetPortfolio === undefined) {
      statusNotification(
        "Provided list was not all partner institutions",
        "Error"
      );
      const e = new Error(
        "PortfolioId not found in allEssential or advisorCustomPortfolios"
      );
      captureException(e, {
        extra: {
          portfolioId: portfolioId,
          file: "ReplaceModelModal.tsx",
        },
      });
    }

    return targetPortfolio;
  }, [allEssential, advisorCustomPortfolios, portfolioId, statusNotification]);

  const modelNameOptions = useMemo((): string[] => {
    if (
      allEssential.data === undefined ||
      advisorCustomPortfolios.data === undefined
    ) {
      return [];
    }

    if (modelType === "Custom") {
      const activeCustomModels = uniq(
        advisorCustomPortfolios.data.portfolios
          .filter((p) => p.isActive)
          .map((p) => p.displayName)
      );

      return orderBy(activeCustomModels, (cm) => cm.toLowerCase(), "asc");
    } else {
      const activeEssentialModels = uniq(
        allEssential.data
          .filter((p) => p.isActive && p.set === modelType)
          .map((p) => p.displayName)
      );

      return orderBy(activeEssentialModels, (cm) => cm.toLowerCase(), "asc");
    }
  }, [allEssential, advisorCustomPortfolios, modelType]);

  const onSubmit = async () => {
    if (!newPortfolioId) {
      statusNotification("Error finding the new portfolio id", "Error");
      return;
    }

    try {
      setIsMutating(true);

      await replacePortfolio({
        oldPortfolio: portfolioId,
        newPortfolio: newPortfolioId,
      });

      statusNotification("Model replaced and archived", "Success");
      setIsMutating(false);
      history.push({
        ...history.location,
        pathname: `/Advisor/Investments/ModelMarketplace/${portfolioId}/${sharingOrModelDetails}`,
      });
    } catch (_e) {
      statusNotification("Error replacing and archiving model", "Error");
      setIsMutating(false);
    }
  };

  const onChangeModelType = (name: string): void => {
    if (
      advisorCustomPortfolios.data === undefined ||
      allEssential.data === undefined
    ) {
      return;
    }

    if (modelType === "Custom") {
      const model = advisorCustomPortfolios.data.portfolios.find(
        (p) => p.displayName === name
      );
      if (model === undefined) {
        setNewPortfolioId(null);
        setModelName(name);
        statusNotification("Model not found", "Error");
      } else {
        setModelName(name);
        setNewPortfolioId(model.portfolioModelId);
      }
    } else {
      const model = allEssential.data.find(
        (p) =>
          p.displayName === name &&
          p.taxType === taxType &&
          p.directIndexing === isDirectIndexed
      );
      if (model === undefined) {
        setNewPortfolioId(null);
        setModelName(name);
        statusNotification("Model not found", "Error");
      } else {
        setModelName(name);
        setNewPortfolioId(model.portfolioModelId);
      }
    }
  };

  const onChangeTaxStatus = (taxStatus: TaxStatus): void => {
    if (allEssential.data === undefined) {
      return;
    }

    if (modelType === "Custom") {
      return;
    }

    const taxType = taxStatusToTaxType[taxStatus];

    const model = allEssential.data.find(
      (p) =>
        p.displayName === modelName &&
        p.taxType === taxType &&
        p.directIndexing === !isDirectIndexed
    );
    if (model === undefined) {
      setNewPortfolioId(null);
      setTaxType(taxType);
      statusNotification("Model not found", "Error");
    } else {
      setTaxType(taxType);
      setNewPortfolioId(model.portfolioModelId);
    }
  };

  const onChangeIsDirectIndexed = (): void => {
    if (allEssential.data === undefined) {
      return;
    }

    if (modelType !== "FAM") {
      statusNotification("Direct Indexing only applies to FAM models", "Error");
      return;
    }

    const model = allEssential.data.find(
      (p) =>
        p.displayName === modelName &&
        p.taxType === taxType &&
        p.directIndexing === !isDirectIndexed
    );
    if (model === undefined) {
      setNewPortfolioId(null);
      setIsDirectIndexed(!isDirectIndexed);
      statusNotification("Model not found", "Error");
    } else {
      setIsDirectIndexed(!isDirectIndexed);
      setNewPortfolioId(model.portfolioModelId);
    }
  };

  if (
    partners.hasError ||
    allEssential.hasError ||
    advisorCustomPortfolios.hasError
  ) {
    return <div>Error retrieving partners and models</div>;
  }

  if (
    partners.isLoading ||
    allEssential.isLoading ||
    advisorCustomPortfolios.isLoading ||
    isMutating
  ) {
    return (
      <LoadingModal
        portfolioId={portfolioId}
        sharingOrModelDetails={sharingOrModelDetails}
        displayName={portfolio?.displayName}
      />
    );
  }

  return (
    <Modal
      closeModal={() =>
        history.push({
          ...history.location,
          pathname: `/Advisor/Investments/ModelMarketplace/${portfolioId}/${sharingOrModelDetails}`,
        })
      }
      modalStyle={{ width: "520px" }}
    >
      <div className={styles.modalTitle}>
        Choose a Replacement Model Portfolio
      </div>

      <div className={styles.modalText}>
        {`To archive the model${
          portfolio?.displayName ? ` ${portfolio?.displayName},` : ","
        } you must replace it.`}
      </div>

      <div className={styles.inputSpacer} />

      <Select
        value={modelType === null ? "Model Type" : modelType}
        options={modelTypeOptions}
        onChange={(name) => {
          setNewPortfolioId(null);
          setModelType(name);
          setModelName(null);
        }}
        style={{ width: "100%" }}
        renderValue={(value) => value}
      />

      <div className={styles.inputSpacer} />

      {modelType === null ? (
        <div className={styles.disabledModelNameSelect}>Model Name</div>
      ) : (
        <Select
          value={modelName === null ? "Model Name" : modelName}
          options={modelNameOptions}
          onChange={onChangeModelType}
          style={{ width: "100%" }}
          renderValue={(value) => value}
        />
      )}

      <div className={styles.flexRowMarginTop}>
        {modelType === null || modelType === "Custom" ? (
          <div className={styles.disabledTaxTypeSelect}>Taxable Status</div>
        ) : (
          <Select
            value={taxTypeToStatus[taxType]}
            options={taxTypeOptions}
            onChange={onChangeTaxStatus}
            style={{ width: "50%", marginRight: "15px" }}
            renderValue={(value) => value}
          />
        )}

        <Checkbox
          checked={isDirectIndexed && modelType === "FAM"}
          onChange={onChangeIsDirectIndexed}
          label={"Direct-Indexed"}
          disabled={modelType !== "FAM"}
        />
      </div>

      <div className={styles.modalFooter}>
        <Button
          variant={"outlined"}
          buttonType={"secondary"}
          text={"Cancel"}
          onClick={() =>
            history.push({
              ...history.location,
              pathname: `/Advisor/Investments/ModelMarketplace/${portfolioId}/${sharingOrModelDetails}`,
            })
          }
        />

        <Button
          disabled={!newPortfolioId}
          variant={"outlined"}
          buttonType={"warning"}
          text={"Archive Model Portfolio"}
          onClick={onSubmit}
          style={{ marginLeft: "15px" }}
        />
      </div>
    </Modal>
  );
};

export default ReplaceModelModal;

interface LoadingModalProps {
  portfolioId: PortfolioId;
  sharingOrModelDetails: "Sharing" | "ModelDetails";
  displayName: string | undefined;
}

const LoadingModal = ({
  portfolioId,
  sharingOrModelDetails,
  displayName,
}: LoadingModalProps): JSX.Element => {
  const history = useHistory();

  return (
    <Modal
      closeModal={() =>
        history.push({
          ...history.location,
          pathname: `/Advisor/Investments/ModelMarketplace/${portfolioId}/${sharingOrModelDetails}`,
        })
      }
      modalStyle={{ width: "520px" }}
    >
      <div className={styles.modalTitle}>
        Choose a Replacement Model Portfolio
      </div>

      <div className={styles.modalText}>
        {`To archive the model${
          displayName ? ` ${displayName},` : ","
        } you must replace it.`}
      </div>

      <div className={styles.inputSpacer} />

      <Skeleton width={"100%"} height={30} style={{ transform: "none" }} />

      <div className={styles.inputSpacer} />

      <Skeleton width={"100%"} height={30} style={{ transform: "none" }} />

      <div className={styles.flexRowMarginTop}>
        <Skeleton
          width={"50%"}
          height={30}
          style={{ transform: "none", marginRight: "15px", marginTop: "4px" }}
        />

        <Skeleton
          width={"35%"}
          height={30}
          style={{ transform: "none", marginTop: "4px" }}
        />
      </div>

      <div className={styles.modalFooter}>
        <Skeleton
          width={70}
          height={35}
          style={{ transform: "none", marginRight: "10px" }}
        />

        <Skeleton width={100} height={35} style={{ transform: "none" }} />
      </div>
    </Modal>
  );
};
