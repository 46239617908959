export type ScenarioOptionLowerCase =
  | "blackMonday"
  | "bondMarketCrisis"
  | "russianFinancialCrisis"
  | "dotcomBubble"
  | "globalFinancialCrisis";

type ScenarioOptionUpperCase =
  | "Dotcom Bubble"
  | "Global Financial Crisis"
  | "Black Monday"
  | "Russian Financial Crisis"
  | "Bond Market Crisis";

export function isScenarioOptionLowerCase(
  s: string
): s is ScenarioOptionLowerCase {
  return Object.keys(scenarioDescriptions).includes(s);
}

export const scenarioDescriptions: {
  [_key in ScenarioOptionLowerCase]: {
    title: ScenarioOptionUpperCase;
    desc: string;
  };
} = {
  blackMonday: {
    title: "Black Monday",
    desc: "Black Monday is the name commonly given to the global, sudden, severe, and largely unexpected stock market crash on October 19, 1987. In Australia and New Zealand, the day is also referred to as Black Tuesday because of the time zone difference from other English-speaking countries.",
  },
  bondMarketCrisis: {
    title: "Bond Market Crisis",
    desc: "The 1994 bond market crisis, or Great Bond Massacre, was a sudden drop in bond market prices across the developed world. It began in Japan and the United States (US), and spread through the rest of the world.",
  },
  russianFinancialCrisis: {
    title: "Russian Financial Crisis",
    desc: "The Russian financial crisis (also called ruble crisis or the Russian flu) began in Russia on 17 August 1998. It resulted in the Russian government and the Russian Central Bank devaluing the ruble and defaulting on its debt. The crisis had severe impacts on the economies of many neighboring countries.",
  },
  dotcomBubble: {
    title: "Dotcom Bubble",
    desc: "The dot-com bubble, also known as the dot-com boom, the tech bubble, and the Internet bubble, was a stock market bubble in the late 1990s, a period of massive growth in the use and adoption of the Internet.",
  },
  globalFinancialCrisis: {
    title: "Global Financial Crisis",
    desc: "The financial crisis of 2008, or Global Financial Crisis, was a severe worldwide economic crisis that occurred in the early 21st century. It was the most serious financial crisis since the Great Depression.",
  },
};
