export const timeRangeOptions = ["5Y", "10Y", "25Y"] as const;
export type TRO = typeof timeRangeOptions[number];

const _projectionsToInclude = ["results10Pct", "results50Pct", "results90Pct"];
export type ProjectionsKey = typeof _projectionsToInclude[number];

export interface ProjectionsGraphData {
  key: ProjectionsKey;
  yearIndex: number;
  balance: number;
}
