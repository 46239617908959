import React, { useEffect, useState } from "react";

import { isAxiosError } from "axios";
import { useHistory, useParams } from "react-router-dom";

import useGetTaxBudgets from "@fartherfinance/frontend/api/InvestmentModel/hooks/useGetTaxBudgets";
import useHypotheticalTaxBudget from "@fartherfinance/frontend/api/InvestmentModel/hooks/useHypotheticalTaxBudget";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import HypotheticalFlowBody from "../../Client/TaxBudgets/FlowBody/HypotheticalFlowBody";
import BaseLayout from "../../Layouts/BaseLayout/BaseLayout";
import { useCreateEditTaxBudgetContext } from "../reducer/Context";
import AdvisorHypotheticalFlowBody from "@src/multiCustodian/components/Advisor/TaxBudgets/FlowBody/HypotheticalFlowBody";
import FlowInit from "@src/multiCustodian/components/TaxBudgets/FlowInit/FlowInit";
import { actions } from "@src/multiCustodian/components/TaxBudgets/reducer/actions";
import { ErrorMessage } from "@src/multiCustodian/components/TaxBudgets/reducer/types";
import SecondaryTitle from "@src/multiCustodian/components/TaxBudgets/SecondaryTitle/SecondaryTitle";
import { modelIdIsNotNull } from "@src/multiCustodian/components/TaxBudgets/utils/modelIsNotNull";
import usePreLoadModelsAndPartners from "@src/multiCustodian/components/TaxBudgets/utils/usePreLoadModelsAndPartners";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { trackEvent } from "@src/multiCustodian/services/tracking";
import FlowHero from "@src/sharedComponents/FlowHero/FlowHero";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

const CreateHypothetical: React.FC = () => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const { state, dispatch } = useCreateEditTaxBudgetContext();

  const statusNotification = useStatusNotification();

  usePreLoadModelsAndPartners(clientId);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    isAdvisor,
    buttonText,
    isContinueDisabled,
    pageIndex,
    pageTitle,
    data,
  } = state;

  const history = useHistory();

  const advisorAuth = useAdvisorRequestAuth();

  const advisorWithClientAuth =
    advisorAuth !== null ? { ...advisorAuth, clientId: clientId } : null;

  const clientAuth = useRequestAuth();

  const auth = advisorWithClientAuth ?? clientAuth;

  const { data: budgets, isLoading: isLoadingBudgets } = useGetTaxBudgets(auth);

  const callGetHypotheticalProposal = useHypotheticalTaxBudget(auth);

  useEffect(() => {
    setIsLoading(isLoadingBudgets);
  }, [isLoadingBudgets]);

  const finish = () => {
    dispatch({ type: actions.RESET_TO_DEFAULT_DATA, payload: undefined });

    const pathname = isAdvisor
      ? `/Advisor/Clients/${clientId}/TaxBudgets`
      : `/Client/${clientId}/TaxBudgets`;

    history.push({
      ...history.location,
      pathname,
    });
  };

  const handleOnClickBack = () => {
    switch (pageIndex) {
      case 0:
        finish();
        break;
      default:
        dispatch({ type: actions.DECREMENT_PAGE_INDEX, payload: undefined });
        break;
    }
  };

  const getHypotheticalProposal = async () => {
    try {
      setIsLoading(true);

      const hypotheticalTradeGroups = data.hypotheticalTradeGroups.map(
        (htg) => {
          if (!modelIdIsNotNull(htg.hypotheticalModelId)) {
            throw new Error("hypotheticalModelId cannot be null!");
          }

          return {
            clientId: clientId,
            modelId: htg.hypotheticalModelId,
            virtualAccountIds: htg.virtualAccountIds,
          };
        }
      );

      const response = await callGetHypotheticalProposal({
        tradingGroups: hypotheticalTradeGroups,
        taxBudget: {
          budgetAmount: data.budgetAmount,
          excludedVirtualAccountIds: data.excludedVirtualAccountIds,
        },
      });

      trackEvent({
        name: isAdvisor
          ? "Advisor Run Hypothetical-Tax-Budget"
          : "Client Run Hypothetical-Tax-Budget",
      });

      dispatch({ type: actions.SET_HYPOTHETICAL_PROPOSAL, payload: response });

      dispatch({ type: actions.INCREMENT_PAGE_INDEX, payload: undefined });
    } catch (error) {
      if (isAxiosError<ErrorMessage>(error)) {
        const message =
          error.response?.data.message ??
          "Unexpected error from investment model service.";

        return statusNotification(message, "Error");
      }

      statusNotification("Something went wrong. Please try again.", "Error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnClickContinue = () => {
    switch (pageIndex) {
      case 3:
        getHypotheticalProposal();
        break;
      case 4:
        finish();
        break;
      default:
        dispatch({ type: actions.INCREMENT_PAGE_INDEX, payload: undefined });
        break;
    }
  };

  const handleOnClickCancel = () => finish();

  return (
    <BaseLayout showSideNav={false}>
      <FlowInit budgets={budgets || []}>
        <FlowHero
          onClickBack={handleOnClickBack}
          onClickCancel={handleOnClickCancel}
          onClickContinue={handleOnClickContinue}
          primaryButtonText={buttonText}
          primaryButtonIsDisabled={isContinueDisabled}
          secondaryTitle={<SecondaryTitle title={"Hypothetical Scenario"} />}
          title={pageTitle}
        />

        {isLoading && <LogoLoadingStill onTop noBackgroundColor />}

        {!isLoading && isAdvisor && <AdvisorHypotheticalFlowBody />}
        {!isLoading && !isAdvisor && <HypotheticalFlowBody />}
      </FlowInit>
    </BaseLayout>
  );
};

export default CreateHypothetical;
