import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

export const useStyles = makeStyles()((theme) => ({
  avatar: {
    marginRight: theme.spacing(pxToSpacing(10)),
  },
  body: {
    borderRadius: "10px",
    marginTop: theme.spacing(pxToSpacing(16)),
  },
}));
