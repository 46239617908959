import React from "react";

import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import VerticalAndHorizontalHoverLines from "./VerticalAndHorizontalHoverLines";
import VerticalHoverLine from "./VerticalHoverLine";

interface Props {
  currentDataKey: "primary" | "secondary"; //Currently, this custom dot supports up to Two data lines
  props: any;
}

const CustomDot = ({ currentDataKey, props: dotProps }: Props): JSX.Element => {
  const {
    color: {
      $chartLinePrimary,
      $chartLineSecondary,
      $chartLineTertiary,
      $backgroundSubtle,
    },
  } = useTheme();

  if (!dotProps.cx || !dotProps.cy) {
    return <></>;
  }

  /*
    <circles> are derived from graph_dot_<color>.svg.
    Since the SVGs of the circles cannot be served directly into the graph,
    we generate them on the spot.
  */

  return (
    <>
      {currentDataKey === "primary" ? (
        <>
          <VerticalAndHorizontalHoverLines
            x={dotProps.cx}
            y={dotProps.cy}
            color={$chartLineTertiary}
          />

          <circle
            cx={dotProps.cx}
            cy={dotProps.cy}
            r={13}
            fill={$chartLinePrimary}
            fillOpacity={0.1}
            stroke={$chartLinePrimary}
            strokeOpacity={0.1}
            strokeWidth={1}
          />
          <circle
            cx={dotProps.cx}
            cy={dotProps.cy}
            r={8}
            fill={$backgroundSubtle}
            fillOpacity={1}
            stroke={$chartLinePrimary}
            strokeWidth={1.4}
          />
          <circle
            cx={dotProps.cx}
            cy={dotProps.cy}
            r={2.5}
            fill={$chartLinePrimary}
          />
        </>
      ) : (
        <>
          <VerticalHoverLine
            x={dotProps.cx}
            y={dotProps.cy}
            color={$chartLineTertiary}
          />

          <circle
            cx={dotProps.cx}
            cy={dotProps.cy}
            r={13}
            fill={$chartLineSecondary}
            fillOpacity={0.1}
            stroke={$chartLineSecondary}
            strokeOpacity={0.1}
            strokeWidth={1}
          />
          <circle
            cx={dotProps.cx}
            cy={dotProps.cy}
            r={8}
            fill={$backgroundSubtle}
            fillOpacity={1}
            stroke={$chartLineSecondary}
            strokeWidth={1.4}
          />
          <circle
            cx={dotProps.cx}
            cy={dotProps.cy}
            r={2.5}
            fill={$chartLineSecondary}
          />
        </>
      )}
    </>
  );
};

export default CustomDot;
