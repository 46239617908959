import React from "react";

import { useSelector } from "react-redux";

import SwitchDisabledOff from "@src/assets/svg/switch_disabled_off.svg";
import SwitchDisabledOn from "@src/assets/svg/switch_disabled_on.svg";
import SwitchOffCream from "@src/assets/svg/switch_off_cream.svg";
import SwitchOffNavy from "@src/assets/svg/switch_off_navy.svg";
import SwitchOnCream from "@src/assets/svg/switch_on_cream.svg";
import SwitchOnNavy from "@src/assets/svg/switch_on_navy.svg";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import { State } from "@src/store";

import styles from "./Switch.module.css";

export interface IncomingProps {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  on: boolean;
  disabled?: boolean;
  className?: string;
}

export default function Switch(props: IncomingProps) {
  const theme: "farther_dark" | "farther_light" = useSelector(
    (state: State) => state.main_Reducer.ui_theme
  );

  const switchOn = theme === "farther_dark" ? SwitchOnNavy : SwitchOnCream;
  const switchOff = theme === "farther_dark" ? SwitchOffNavy : SwitchOffCream;

  return (
    <div
      className={toClassName(
        props.disabled ? styles.disabled : styles.enabled,
        props.className
      )}
      onClick={props.disabled ? undefined : props.onClick}
    >
      <img
        src={
          props.disabled
            ? props.on
              ? SwitchDisabledOn
              : SwitchDisabledOff
            : props.on
            ? switchOn
            : switchOff
        }
      />
    </div>
  );
}
