import React from "react";

import { DIRECT_INDEXING_TOOLTIP_TEXT } from "../../Client/Portfolio/const";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";
import Checkbox from "@src/sharedComponents/Checkbox/Checkbox";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import TooltipBasic from "@src/sharedComponents/Tooltip/TooltipBasic";

import styles from "./SelectHypotheticalModel.module.css";

const SelectHypotheticalModelLoading: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <Skeleton width={160} height={25} style={{ transform: "none" }} />

        <Skeleton width={200} height={25} style={{ transform: "none" }} />
      </div>

      <BorderBox className={styles.borderBox}>
        <div className={styles.header}>Hypothetical Model</div>

        <div className={styles.dropdownsContainer}>
          <div className={styles.dropdownSelectContainer}>
            <div className={styles.label}>Hypothetical Model Type</div>

            <Skeleton
              width={"100%"}
              height={35}
              style={{ transform: "none" }}
            />
          </div>

          <div className={styles.dropdownSelectContainer}>
            <div className={styles.label}>Hypothetical Model</div>

            <Skeleton
              width={"100%"}
              height={35}
              style={{ transform: "none" }}
            />
          </div>
        </div>

        <div className={styles.label}>Settings</div>

        <div className={styles.settings}>
          <Checkbox
            checked={false}
            onChange={() => undefined}
            label={"Direct-Indexed"}
            disabled={false}
          />

          <TooltipBasic
            className={styles.infoIcon}
            text={DIRECT_INDEXING_TOOLTIP_TEXT}
          />

          <Checkbox
            checked={false}
            onChange={() => undefined}
            label={"Tax-Exempt"}
            disabled={false}
          />
        </div>
      </BorderBox>
    </div>
  );
};

export default SelectHypotheticalModelLoading;
