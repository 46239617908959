import lodashIsElement from "lodash/isElement";
import { createPortal } from "react-dom";

function isElement(value: unknown): value is HTMLElement {
  return lodashIsElement(value);
}

interface PortalProps {
  children: React.ReactNode;
  targetNodeId: string;
}

const Portal: React.FC<PortalProps> = ({
  children,
  targetNodeId,
}: PortalProps) => {
  const nodes = document.querySelectorAll(`[id=${targetNodeId}]`);

  if (nodes.length === 0) {
    throw new Error(
      `Element with (Portal target) id: ${targetNodeId} does not exist in DOM tree`
    );
  } else if (nodes.length > 1) {
    throw new Error(
      `Multiple elements in the DOM tree have the same (Portal target) id: ${targetNodeId}`
    );
  }

  const targetNode = nodes[0];

  return isElement(targetNode) ? createPortal(children, targetNode) : null;
};

export default Portal;
