import React, { useMemo } from "react";

import useSearchSecurities from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useSearchSecurities";
import { Ticker } from "@fartherfinance/frontend/api/Types";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

interface Props {
  ticker: Ticker;
}

const DisplayName = (props: Props): JSX.Element => {
  const clientAuth = useRequestAuth();

  const advisorAuth = useAdvisorRequestAuth();

  const res = useSearchSecurities(props.ticker, clientAuth ?? advisorAuth);

  const tickerDisplayName = useMemo(() => {
    if (res.isLoading || res.hasError) {
      return res;
    }

    return {
      ...res,
      data:
        res.data.find((s) => s.ticker === props.ticker)?.displayName ?? null,
    };
  }, [props.ticker, res]);

  if (tickerDisplayName.isLoading) {
    return <Skeleton width={50} />;
  }

  if (tickerDisplayName.hasError) {
    return <>{'""'}</>;
  }

  return <>{tickerDisplayName.data ?? '""'}</>;
};

export default DisplayName;
