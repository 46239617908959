import React from "react";

import styles from "./DropdownMenuItem.module.css";

interface Props {
  text: string;
  icon: React.ReactNode;
  onClick: () => void;
}

const CustomPerformanceGroupsDropdownMenuActionItem: React.FC<Props> = ({
  text,
  icon,
  onClick,
}: Props) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.left}>
        {icon}

        <div className={styles.text}>{text}</div>
      </div>
    </div>
  );
};

export default CustomPerformanceGroupsDropdownMenuActionItem;
