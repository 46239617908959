import React, { useMemo, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import { orderBy } from "lodash";

import { TimeFrame } from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Types";
import useGetAccountGroupsV4 from "@fartherfinance/frontend/api/PerformanceGroups/hooks/useGetAccountGroupsV4";
import { AccountGroup } from "@fartherfinance/frontend/api/PerformanceGroups/requests/getSingleAccountGroupV4";
import { PerformanceGroupId } from "@fartherfinance/frontend/api/Types";

import CreateAccountGroupModal from "../Create/CreateAccountGroupModal";
import EditAccountGroupModal from "../Edit/EditAccountGroupModal";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import ModalWithClearButton from "@src/sharedComponents/Modal/ModalWithClearButton";

import AccountGroupRow from "./AccountGroupRow";

import styles from "./ManageAccountGroupsModal.module.css";

interface Props {
  closeModal: () => void;
  setAwaitingAccountGroupId: (agId: PerformanceGroupId) => void;
  closeDropdown: () => void;
  setTimeFrame: (range: TimeFrame) => void;
}

const ManageAccountGroupsModal: React.FC<Props> = ({
  closeModal,
  setAwaitingAccountGroupId,
  closeDropdown,
  setTimeFrame,
}: Props) => {
  const [isDefaultAG, setIsDefaultAG] = useState<boolean>(true);
  const [showCreateAccountGroupModal, setShowCreateAccountGroupModal] =
    useState<boolean>(false);
  const [showEditAccountGroupModal, setShowEditAccountGroupModal] =
    useState<boolean>(false);
  const [selectedAccountGroup, setSelectedAccountGroup] = useState<
    AccountGroup | undefined
  >(undefined);

  const auth = useRequestAuth();
  const accountGroups = useGetAccountGroupsV4(auth);

  const openEditAccountGroupModal = (ag: AccountGroup, isDefault: boolean) => {
    setSelectedAccountGroup(ag);
    setIsDefaultAG(isDefault);
    setShowEditAccountGroupModal(true);
  };

  const goBackToManageAccountGroupsModal = () => {
    setShowEditAccountGroupModal(false);
    setSelectedAccountGroup(undefined);
  };

  const closeModalsAndDropdown = () => {
    setShowEditAccountGroupModal(false);
    closeModal();
    closeDropdown();
  };

  const defaultGroup = useMemo(() => {
    if (accountGroups.data === undefined) {
      return undefined;
    }

    return accountGroups.data.find(
      (ag) => ag.accountGroupType === "ALL_CLIENT_ACCOUNTS"
    );
  }, [accountGroups.data]);

  const customGroups = useMemo(() => {
    if (accountGroups.data === undefined) {
      return [];
    }

    const groupsFiltered = accountGroups.data.filter(
      (ag) => ag.accountGroupType === "CUSTOM"
    );

    return orderBy(groupsFiltered, [(cg) => cg.displayName], ["asc"]);
  }, [accountGroups.data]);

  return (
    <ModalWithClearButton className={styles.modal} closeModal={closeModal}>
      <div className={styles.title}>Manage Account Groups</div>

      <div className={styles.subTitle}>
        Account groups allow you to customize your experience and see a combined
        view of summary, activity, holdings, and projections.
      </div>

      <div className={styles.flexRow}>
        <div className={styles.heading}>Default Group</div>
      </div>

      <div className={styles.scrollBox}>
        {defaultGroup && (
          <AccountGroupRow
            key={defaultGroup.accountGroupId}
            accountGroupId={defaultGroup.accountGroupId}
            name={defaultGroup.displayName}
            onSelectGroup={() => openEditAccountGroupModal(defaultGroup, true)}
            isFavorite={defaultGroup.isFavorite}
          />
        )}
      </div>

      <div className={styles.flexBetween}>
        <div className={styles.heading}>Custom Groups</div>

        <div
          className={styles.hoverArea}
          onClick={() => setShowCreateAccountGroupModal(true)}
        >
          <div className={styles.boldText}>New Group</div>

          <AddIcon className={styles.addIcon} />
        </div>
      </div>

      <div className={styles.scrollBox}>
        {customGroups.map((ag) => (
          <AccountGroupRow
            key={ag.accountGroupId}
            accountGroupId={ag.accountGroupId}
            name={ag.displayName}
            onSelectGroup={() => openEditAccountGroupModal(ag, false)}
            isFavorite={ag.isFavorite}
          />
        ))}

        {accountGroups.isLoading === false && customGroups.length === 0 && (
          <div className={styles.noOptionsText}>None</div>
        )}
      </div>

      {showCreateAccountGroupModal && (
        <CreateAccountGroupModal
          closeModal={() => setShowCreateAccountGroupModal(false)}
          setAwaitingAccountGroupId={setAwaitingAccountGroupId}
          closeDropdown={closeDropdown}
          setTimeFrame={setTimeFrame}
        />
      )}

      {showEditAccountGroupModal && selectedAccountGroup && (
        <EditAccountGroupModal
          accountGroup={selectedAccountGroup}
          isDefault={isDefaultAG}
          goBack={goBackToManageAccountGroupsModal}
          closeModalsAndDropdown={closeModalsAndDropdown}
        />
      )}
    </ModalWithClearButton>
  );
};

export default ManageAccountGroupsModal;
