import { SleeveId, Ticker } from "@fartherfinance/frontend/api/Types";

export const USDTicker = "US Dollars" as Ticker;

export type SelectedSecurity = {
  ticker: Ticker;
  displayName: string | null;
};

export type SelectedSleeve = {
  sleeveId: SleeveId;
  displayName: string | null;
};
