import React from "react";

import { range } from "lodash";

import EquivalentSecuritiesDrawerTableRowLoading from "../../Admin/EquivalentSecurities/Drawer/EquivalentSecuritiesDrawerTableRowLoading";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./EquivalentSecuritiesDrawer.module.css";

const EquivalentSecuritiesListLoading: React.FC = () => {
  return (
    <div className={styles.listContainer}>
      <div className={styles.heading}>
        <Skeleton width={180} height={20} style={{ transform: "none" }} />

        <Skeleton width={110} height={20} style={{ transform: "none" }} />
      </div>

      <Skeleton
        width={280}
        height={20}
        style={{ marginTop: "4px", transform: "none" }}
      />

      <BorderBox className={styles.borderBox}>
        {range(3).map((_, idx) => (
          <EquivalentSecuritiesDrawerTableRowLoading key={idx} />
        ))}
      </BorderBox>
    </div>
  );
};

export default EquivalentSecuritiesListLoading;
