import React, { useState } from "react";

import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import useUpdateValueClientAlternativeAsset from "@fartherfinance/frontend/api/Dashboard/hooks/useUpdateValueClientAlternativeAsset";
import { AltAssetId, ClientId } from "@fartherfinance/frontend/api/Types";

import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import isInteger from "@src/multiCustodian/pages/Dashboard/Dashboard_Components/DashboardForms/validators/isInteger";
import isValidLocaleNumber from "@src/multiCustodian/pages/Dashboard/Dashboard_Components/DashboardForms/validators/isValidLocaleNumber";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

import sharedStyles from "./SharedStyles.module.css";
import styles from "../ManualAssetDrawer.module.css";

const AssetValue = "AssetValue";

interface Form {
  [AssetValue]: string;
}

interface Props {
  closeModal: () => void;
}

const UpdateInvestmentForm = (props: Props): JSX.Element => {
  const [mutating, setMutating] = useState(false);

  const { clientId, accountId } = useParams<{
    clientId: ClientId;
    accountId: AltAssetId;
  }>();

  const auth = useRequestAuth();

  const statusNotification = useStatusNotification();

  const updateManualAssetValue = useUpdateValueClientAlternativeAsset(
    clientId,
    auth
  );

  const methods = useForm<Form>({
    mode: "all",
    reValidateMode: "onChange",
  });

  const {
    getValues,
    formState: { isValid, isDirty },
  } = methods;

  const submit = async () => {
    if (!isValid || !isDirty || mutating) {
      return;
    }

    const values = getValues();

    try {
      setMutating(true);

      await updateManualAssetValue({
        assetId: accountId,
        assetValue: parseInt(values[AssetValue].replace(/[^\d\.]/g, "")),
      });

      statusNotification("Investment value updated", "Success");
    } catch (e) {
      console.error(e);

      statusNotification("Error updating investment value", "Error");
    } finally {
      setMutating(false);

      props.closeModal();
    }
  };

  return (
    <>
      {mutating && (
        <div className={sharedStyles.loading}>
          <LogoLoadingStill />
        </div>
      )}
      <FormProvider {...methods}>
        <FormTextField
          name="AssetValue"
          label="Asset Value"
          startAdornment={"$"}
          required="Must not be empty"
          rules={{
            validate: {
              validNumber: (v) => isValidLocaleNumber(v, true),
              isInt: (v) => isInteger(v.replace(/,/g, ""), true),
              greaterThan0: (v) =>
                parseFloat(v) > 0 ? true : "Must be greater than $0",
            },
          }}
        />
      </FormProvider>

      <div className={styles.modal__yes_no_div}>
        <ButtonPrimary
          variant={"outlined"}
          buttonType={"secondary"}
          text={"Cancel"}
          onClick={props.closeModal}
        />

        <ButtonPrimary
          disabled={!isValid || !isDirty || mutating}
          variant={"contained"}
          buttonType={"primary"}
          text={"Confirm"}
          onClick={submit}
          style={{ marginLeft: "25px" }}
        />
      </div>
    </>
  );
};

export default UpdateInvestmentForm;
