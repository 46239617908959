import React, { useCallback, useMemo } from "react";

import { orderBy } from "lodash";

import useEssentialPartnerPortfoliosV1 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useEssentialPartnerPortfoliosV1";
import usePortfolioPartners from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/usePortfolioPartners";
import { PortfolioPartner } from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";
import { PortfolioId } from "@fartherfinance/frontend/api/Types";

import { DIRECT_INDEXING_TOOLTIP_TEXT } from "../../Client/Portfolio/const";
import { actions } from "../reducer/actions";
import { useCreateEditTaxBudgetContext } from "../reducer/Context";
import { HypotheticalTradeGroup } from "../reducer/types";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";
import Checkbox from "@src/sharedComponents/Checkbox/Checkbox";
import Select from "@src/sharedComponents/Select/Select";
import SelectItem from "@src/sharedComponents/Select/SelectItem";
import TooltipBasic from "@src/sharedComponents/Tooltip/TooltipBasic";

import styles from "./SelectHypotheticalModel.module.css";

const LOADING_STRING = "Loading...";

const NO_OPTIONS = "No options";

const FAM = "FAM" as PortfolioPartner;

const Farther_Asset_Management = "Farther Asset Management" as PortfolioPartner;

type VALUE_FOR_SELECT_MODEL = PortfolioId | "" | "Loading..." | null;

interface SelectModel {
  label: string;
  value: PortfolioId | string;
}

interface Props {
  hypotheticalTradeGroup: HypotheticalTradeGroup;
}

const SelectHypotheticalModel: React.FC<Props> = ({
  hypotheticalTradeGroup,
}) => {
  const { state, dispatch } = useCreateEditTaxBudgetContext();

  const {
    data: { hypotheticalTradeGroups },
  } = state;

  const clientAuth = useRequestAuth();
  const advisorAuth = useAdvisorRequestAuth();
  const auth = advisorAuth ? advisorAuth : clientAuth;

  const portfolioType = useMemo(() => {
    return hypotheticalTradeGroup.hypotheticalModelType ?? null;
  }, [hypotheticalTradeGroup.hypotheticalModelType]);

  const partners = usePortfolioPartners(auth);

  const essentialPortfolios = useEssentialPartnerPortfoliosV1(
    portfolioType,
    portfolioType ? auth : null // don't fetch if no portfolioType
  );

  const portfolioPartners = useMemo(() => {
    if (partners.isLoading || partners.hasError) {
      return [];
    }

    // REMOVE when we add more partners
    const portfolioPartners = partners.data.portfolioPartners.filter(
      (p) => p.portfolioPartnerName === FAM
    );

    return orderBy(
      portfolioPartners.map((p) =>
        p.portfolioPartnerName === FAM
          ? Farther_Asset_Management
          : p.portfolioPartnerName
      ), // use Farther_Asset_Management for verbose display purposes instead of "FAM"
      [(x) => x],
      ["asc"]
    );
  }, [partners]);

  const portfolios = useMemo(() => {
    if (
      essentialPortfolios.isLoading ||
      essentialPortfolios.hasError ||
      essentialPortfolios.data === undefined
    ) {
      return [];
    }

    const filteredModels: SelectModel[] = essentialPortfolios.data
      .filter(
        (p) =>
          p.directIndexing === hypotheticalTradeGroup.isDirectIndexed &&
          p.taxType
      )
      .filter((p) => {
        const taxType = hypotheticalTradeGroup.isTaxExempt
          ? "tax_exempt"
          : "taxable";
        return p.taxType === "any" || p.taxType === taxType;
      })
      .map((p) => ({
        label: p.displayName,
        value: p.portfolioModelId,
      }));

    if (filteredModels.length === 0) {
      filteredModels.push({ label: NO_OPTIONS, value: "" });
    }

    return filteredModels;
  }, [
    essentialPortfolios,
    hypotheticalTradeGroup.isDirectIndexed,
    hypotheticalTradeGroup.isTaxExempt,
  ]);

  const setHypotheticalTradeGroups = useCallback(
    (hypotheticalTradeGroups: HypotheticalTradeGroup[]) => {
      dispatch({
        type: actions.SET_HYPOTHETICAL_TRADEGROUPS,
        payload: hypotheticalTradeGroups,
      });
    },
    [dispatch]
  );

  const updateHypotheticalModelType = (modelType: PortfolioPartner) => {
    if (modelType === Farther_Asset_Management) {
      modelType = FAM; // Farther_Asset_Management is used for verbose display purposes - switch back to "FAM"
    }

    const payload = hypotheticalTradeGroups.map((group) => {
      if (group.tradingGroupId === hypotheticalTradeGroup.tradingGroupId) {
        return {
          ...group,
          hypotheticalModelType: modelType,
          hypotheticalModel: null,
          hypotheticalModelId: null,
        };
      }

      return {
        ...group,
      };
    });

    setHypotheticalTradeGroups(payload);
  };

  const updateHypotheticalModel = (value: VALUE_FOR_SELECT_MODEL) => {
    if (value === null || value === "" || value === LOADING_STRING) {
      return; // this is just a placeholder and should not be selectable
    }

    // at this point value should be a modelId
    const selectedModel = portfolios.find((p) => p.value === value);
    if (selectedModel === undefined) {
      throw new Error(
        "Can't find selected portfolioId from filteredModels list"
      );
    }

    const payload = hypotheticalTradeGroups.map((group) => {
      if (group.tradingGroupId === hypotheticalTradeGroup.tradingGroupId) {
        return {
          ...group,
          hypotheticalModel: selectedModel.label,
          hypotheticalModelId: value,
        };
      }

      return {
        ...group,
      };
    });

    setHypotheticalTradeGroups(payload);
  };

  const setIsDirectIndexed = (newBool: boolean) => {
    const payload = hypotheticalTradeGroups.map((group) => {
      if (group.tradingGroupId === hypotheticalTradeGroup.tradingGroupId) {
        return {
          ...group,
          isDirectIndexed: newBool,
          hypotheticalModel: null,
          hypotheticalModelId: null,
        };
      }

      return {
        ...group,
      };
    });

    setHypotheticalTradeGroups(payload);
  };

  const setIsTaxExempt = (newBool: boolean) => {
    const payload = hypotheticalTradeGroups.map((group) => {
      if (group.tradingGroupId === hypotheticalTradeGroup.tradingGroupId) {
        return {
          ...group,
          isTaxExempt: newBool,
          hypotheticalModel: null,
          hypotheticalModelId: null,
        };
      }

      return {
        ...group,
      };
    });

    setHypotheticalTradeGroups(payload);
  };

  const selectModelTypeValue = useMemo(() => {
    if (hypotheticalTradeGroup.hypotheticalModelType === null) {
      return "" as PortfolioPartner;
    }

    if (hypotheticalTradeGroup.hypotheticalModelType === FAM) {
      return Farther_Asset_Management;
    }

    return hypotheticalTradeGroup.hypotheticalModelType;
  }, [hypotheticalTradeGroup.hypotheticalModelType]);

  const selectModelValue: VALUE_FOR_SELECT_MODEL = useMemo(() => {
    if (essentialPortfolios.isLoading) {
      return LOADING_STRING;
    }

    if (hypotheticalTradeGroup.hypotheticalModel === null) {
      return "";
    }

    return hypotheticalTradeGroup.hypotheticalModelId;
  }, [
    essentialPortfolios,
    hypotheticalTradeGroup.hypotheticalModel,
    hypotheticalTradeGroup.hypotheticalModelId,
  ]);

  const renderValueForSelectModel = useCallback(
    (value) => {
      if (!value) {
        return "";
      }

      if (value === LOADING_STRING || value === "") {
        return value;
      }

      // At this point value  should be a modelId
      const model = portfolios.find((p) => p.value === value);
      if (model === undefined) {
        throw new Error(
          "Can't find selected portfolioId from filteredModels list"
        );
      }

      return model.label;
    },
    [portfolios]
  );

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div className={styles.header}>
          {hypotheticalTradeGroup.accountsInPortfolio}
        </div>

        <div className={styles.headerEnd}>
          {`Current Model: ${hypotheticalTradeGroup.modelName}`}
        </div>
      </div>

      <BorderBox className={styles.borderBox}>
        <div className={styles.header}>Hypothetical Model</div>

        <div className={styles.dropdownsContainer}>
          <div className={styles.dropdownSelectContainer}>
            <div className={styles.label}>Hypothetical Model Type</div>

            <Select
              className={styles.dropdownSelect}
              menuClassName={styles.dropdownSelectMenu}
              value={selectModelTypeValue}
              options={portfolioPartners}
              onChange={updateHypotheticalModelType}
              style={{ width: "100%" }}
              renderValue={(value) => value}
            />
          </div>

          <div className={styles.dropdownSelectContainer}>
            <div className={styles.label}>Hypothetical Model</div>

            <Select
              className={styles.dropdownSelect}
              menuClassName={styles.dropdownSelectMenu}
              value={selectModelValue}
              options={portfolios}
              onChange={updateHypotheticalModel}
              style={{ width: "100%" }}
              renderValue={renderValueForSelectModel}
              disabled={portfolios.length === 0}
              renderOption={(model) => (
                <SelectItem
                  key={model.value}
                  label={model.label}
                  value={model.value}
                />
              )}
            />
          </div>
        </div>

        <div className={styles.label}>Settings</div>

        <div className={styles.settings}>
          <Checkbox
            checked={hypotheticalTradeGroup.isDirectIndexed}
            onChange={setIsDirectIndexed}
            label={"Direct-Indexed"}
            disabled={false}
          />

          <TooltipBasic
            className={styles.infoIcon}
            text={DIRECT_INDEXING_TOOLTIP_TEXT}
          />

          <Checkbox
            checked={hypotheticalTradeGroup.isTaxExempt}
            onChange={setIsTaxExempt}
            label={"Tax-Exempt"}
            disabled={false}
          />
        </div>
      </BorderBox>
    </div>
  );
};

export default SelectHypotheticalModel;
