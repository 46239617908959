import React, { useMemo } from "react";

import { useHistory, useParams } from "react-router-dom";

import { ClientId, TaxBudgetId } from "@fartherfinance/frontend/api/Types";

import DetailTabsContainer from "../../../../components/TaxBudgets/DetailTabs/DetailTabsContainer";
import TaxBudgetYearSelect from "../../../../components/TaxBudgets/YearDropdown/YearDropdown";
import Drawer from "@src/multiCustodian/components/Drawer/Drawer";
import useTaxBudgets from "@src/multiCustodian/components/TaxBudgets/Hooks/useTaxBudgets";

import TaxBudgets from "./List";

const TaxBudgetDetail = () => {
  const { clientId, taxBudgetId } = useParams<{
    clientId: ClientId;
    taxBudgetId: TaxBudgetId;
  }>();

  const history = useHistory();

  const { data } = useTaxBudgets();

  const budgets = useMemo(() => {
    return data?.budgets || [];
  }, [data?.budgets]);

  const budget = budgets?.find((b) => b?.budgetId === taxBudgetId);

  const handleOnClose = () =>
    history.push({
      ...history.location,
      pathname: `/Client/${clientId}/TaxBudgets`,
    });

  return (
    <TaxBudgets>
      <Drawer
        isDrawerOpen
        header={<TaxBudgetYearSelect budgets={budgets} pathPrefix="/Client" />}
        onClose={handleOnClose}
      >
        <DetailTabsContainer
          budget={budget}
          detailsPath="/Client/:clientId/TaxBudgets/:taxBudgetId/BudgetDetails"
          usagePath="/Client/:clientId/TaxBudgets/:taxBudgetId/Usage"
        />
      </Drawer>
    </TaxBudgets>
  );
};

export default TaxBudgetDetail;
