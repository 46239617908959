import React from "react";

import Stack from "@mui/material/Stack";
import { useFlags } from "launchdarkly-react-client-sdk";

import {
  TaskPriority,
  TaskStatus,
  TaskType,
} from "@fartherfinance/frontend/api/Tasks/Types";

import TaskPriorityIcon from "../../Components/TaskPriorityIcon/TaskPriorityIcon";
import {
  mapTaskPriorityToLabel,
  mapTaskStatusToLabel,
} from "../../Components/TasksTable/TasksTable.utils";
import TaskStatusIcon from "../../Components/TaskStatusIcon/TaskStatusIcon";
import { useTaskStatusOptions } from "../../hooks";
import { isTaskFieldEditingRestricted } from "../../utils";
import FormSelectField from "@src/sharedComponents/Forms/FormSelectField";
import SelectItem from "@src/sharedComponents/Select/SelectItem";

import styles from "./TaskDrawerHeader.module.css";

interface TaskDrawerHeaderProps {
  task?: {
    taskType: TaskType;
    taskStatus: TaskStatus;
    isSystemGenerated: boolean;
  };
}

export default function TaskDrawerHeader({
  task,
}: TaskDrawerHeaderProps): JSX.Element {
  const { enableNewTaskStatus } = useFlags();
  const taskStatusOptions = useTaskStatusOptions(task?.taskType);

  return (
    <Stack direction="row" spacing={2}>
      <FormSelectField
        className={styles.formSelect}
        name="taskStatus"
        options={taskStatusOptions}
        disabled={isTaskFieldEditingRestricted("taskStatus", task)}
        renderValue={(option) => (
          <Stack direction="row" alignItems="center" spacing={1}>
            <TaskStatusIcon value={option} />
            <span>{mapTaskStatusToLabel(option, enableNewTaskStatus)}</span>
          </Stack>
        )}
        renderOption={(option) => (
          <SelectItem
            key={option}
            value={option}
            label={mapTaskStatusToLabel(option, enableNewTaskStatus)}
            preAdornment={<TaskStatusIcon value={option} />}
          />
        )}
      />

      <FormSelectField
        name="taskPriority"
        options={TaskPriority.options}
        disabled={isTaskFieldEditingRestricted("taskPriority", task)}
        renderValue={(option) => (
          <Stack direction="row" alignItems="center" spacing={1}>
            <TaskPriorityIcon value={option} />
            <span>{mapTaskPriorityToLabel(option)}</span>
          </Stack>
        )}
        renderOption={(option) => (
          <SelectItem
            key={option}
            value={option}
            label={mapTaskPriorityToLabel(option)}
            preAdornment={<TaskPriorityIcon value={option} />}
          />
        )}
      />
    </Stack>
  );
}
