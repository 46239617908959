import { isNil } from "lodash";

const nameRegex = /^[A-Za-z0-9]+$/;

const onlyLettersAndNumbers = (
  val: string | null | undefined,
  nameType: "first" | "middle" | "last",
  isRequired: boolean
): string | true => {
  const isEmpty = isNil(val) || val === "";

  const validName = nameRegex.test(val ?? "");

  if (isRequired) {
    return !isEmpty && validName
      ? true
      : `Your ${nameType} name should only contain letters and numbers.`;
  }
  return isEmpty || validName
    ? true
    : `Your ${nameType} name should only contain letters and numbers.`;
};

export default onlyLettersAndNumbers;
