import { useEffect, useState } from "react";

import { identity, isEmpty, omit, pickBy } from "lodash";

import useAdvisorMarketplaceOfferingsQuery from "@fartherfinance/frontend/api/AltAssetsService/hooks/useAdvisorMarketplaceOfferingsQuery";
import useClientMarketplaceOfferingsQuery from "@fartherfinance/frontend/api/AltAssetsService/hooks/useClientMarketplaceOfferingsQuery";
import { PostMarketplaceOfferingsQueryBodyParams } from "@fartherfinance/frontend/api/AltAssetsService/requests/Types";
import {
  MarketplaceAssetType,
  MarketplaceOfferingType,
} from "@fartherfinance/frontend/api/AltAssetsService/Types";
import { PaginationHeaders } from "@fartherfinance/frontend/api/utils/parsePaginationHeaders";

import { usePersistedQueryParams } from "@src/multiCustodian/hooks/Advisor/usePersistedQueryParams";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

export interface OfferingsListQueryParams {
  page: number;
  pageSize: number;
  searchByKeyword?: string;
  filterByAssetType: MarketplaceAssetType[];
  filterByOfferingType?: MarketplaceOfferingType[];
  filterByObjectives?: string[];
  filterByStrategy?: string[];
  filterBySubStrategy?: string[];
  filterByMinimumInvestment?: number[];
  filterByInvestorQualification?: string[];
  filterByLiquidity?: string[];
  filterBySubscriptions?: string[];
  filterByLockUp?: string[];
}

export type OfferingsListToolbarFilters = Omit<
  OfferingsListQueryParams,
  "page" | "pageSize" | "searchByKeyword" | "filterByAssetType"
>;

const mapQueryParamsToBodyParams = ({
  page,
  pageSize,
  searchByKeyword,
  filterByAssetType,
  filterByOfferingType,
  filterByObjectives,
  filterByStrategy,
  filterBySubStrategy,
  filterByMinimumInvestment,
  filterByInvestorQualification,
  filterByLiquidity,
  filterBySubscriptions,
  filterByLockUp,
}: OfferingsListQueryParams): PostMarketplaceOfferingsQueryBodyParams => {
  // NOTE: turn numbers into strings before sending to the API
  const minimumInvestment = filterByMinimumInvestment?.map((value) =>
    value.toString()
  );

  return {
    searchByKeyword,
    filterBy: {
      assetType: filterByAssetType,
      offeringType: filterByOfferingType,
      // NOTE: pickBy identity removes undefined values
      dynamicParameters: pickBy(
        {
          objective: filterByObjectives,
          strategy: filterByStrategy,
          "sub-strategy": filterBySubStrategy,
          minimum_investment: minimumInvestment,
          investor_qualification: filterByInvestorQualification,
          liquidity: filterByLiquidity,
          subscriptions: filterBySubscriptions,
          "lock-up": filterByLockUp,
        },
        identity
      ),
    },
    pagination: {
      page,
      pageSize,
    },
  };
};

const useOfferingsListDataV2 = (
  type: "client" | "advisor",
  assetType: MarketplaceAssetType,
  offeringType?: MarketplaceOfferingType
) => {
  const defaultQueryParams: OfferingsListQueryParams = {
    page: 1,
    pageSize: 10,
    filterByAssetType: [assetType],
    filterByOfferingType: offeringType ? [offeringType] : undefined,
  };

  const [pagination, setPagination] = useState<PaginationHeaders>();
  const [queryParams, setQueryParams] =
    usePersistedQueryParams<OfferingsListQueryParams>(
      defaultQueryParams,
      {
        decode: true,
        parseNumbers: true,
        arrayFormat: "bracket-separator",
      },
      {
        encode: true,
        arrayFormat: "bracket-separator",
      }
    );

  const filters = omit(queryParams, [
    "page",
    "pageSize",
    "searchByKeyword",
    // NOTE: filterByAssetType is static and cannot be modified by direct user action other than navigating to a different route
    "filterByAssetType",
  ]);

  const clientAuth = useRequestAuth();
  const advisorAuth = useAdvisorRequestAuth();

  const clientAuthOrNull =
    isEmpty(queryParams) || type !== "client" ? null : clientAuth;
  const advisorAuthOrNull =
    isEmpty(queryParams) || type !== "advisor" ? null : advisorAuth;

  const bodyParams = mapQueryParamsToBodyParams(queryParams);

  const clientMarketplaceOfferings = useClientMarketplaceOfferingsQuery(
    bodyParams,
    clientAuthOrNull
  );
  const advisorMarketplaceOfferings = useAdvisorMarketplaceOfferingsQuery(
    bodyParams,
    advisorAuthOrNull
  );
  const { data, isLoading, hasError } =
    type === "client"
      ? clientMarketplaceOfferings
      : advisorMarketplaceOfferings;

  const { values, pagination: paginationInternal } = data ?? {};

  const handleOnPageChange = (page: number): void => {
    setQueryParams({ ...queryParams, page });
  };

  const handleOnQueryParamChange = (
    newQueryParams: Partial<OfferingsListQueryParams>
  ) => {
    setQueryParams({ ...queryParams, ...newQueryParams });
  };

  const handleOnQueryParamReset = (
    queryParamsToPersist: Partial<OfferingsListQueryParams>
  ) => {
    setQueryParams({ ...defaultQueryParams, ...queryParamsToPersist });
  };

  useEffect(() => {
    // NOTE: we have to persist pagination values locally as they become undefined inbetween request
    if (paginationInternal) {
      setPagination(paginationInternal);
    }
  }, [paginationInternal]);

  return {
    offerings: values ?? [],
    pagination,
    queryParams,
    filters,
    isLoading,
    hasError,
    handlePageChange: handleOnPageChange,
    handleQueryParamChange: handleOnQueryParamChange,
    handleQueryParamReset: handleOnQueryParamReset,
  };
};

export default useOfferingsListDataV2;
