import React, { useMemo, useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RefreshIcon from "@mui/icons-material/Refresh";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { isEqual } from "lodash";
import { useHistory, useLocation } from "react-router-dom";

import {
  GetTasksQueryParams,
  TasksFilterQueryParams,
} from "@fartherfinance/frontend/api/Tasks/requests/getTasks";

import { TASKS_DEFAULT_PAGE } from "../../common";
import TasksFilterForm from "../TasksFilterForm/TasksFilterForm";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import IconButton from "@src/sharedComponents/IconButton/IconButton";
import Select from "@src/sharedComponents/Select/Select";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import {
  filterOptions,
  FilterOptions,
  filterToTaskStatus,
} from "./TasksToolbar.const";

import styles from "./TasksToolbar.module.css";

interface TasksToolbarProps {
  filters: TasksFilterQueryParams;
  onQueryParamChange: (newQueryParams: GetTasksQueryParams) => void;
  onQueryParamReset: (resetOverwriteQueryParams: GetTasksQueryParams) => void;
  onListRefetch: () => void;
}

export default function TasksToolbar({
  filters,
  onQueryParamChange,
  onQueryParamReset,
  onListRefetch,
}: TasksToolbarProps): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const matchedFilterOption = useMemo((): FilterOptions => {
    const activeFilters = filters.filterByTaskStatus;
    const matching = filterOptions.filter((o) =>
      isEqual(filterToTaskStatus[o].sort(), activeFilters?.sort())
    );
    const topMatching = matching[0];

    if (topMatching === undefined) {
      return "Custom Selection";
    } else {
      return topMatching;
    }
  }, [filters.filterByTaskStatus]);

  const handleSelectFilteringOption = (filterOption: FilterOptions): void => {
    const newFilterValues = filterToTaskStatus[filterOption];

    if (newFilterValues === undefined) {
      return;
    }

    onQueryParamReset({ filterByTaskStatus: newFilterValues });
  };

  const handleFilterFormSubmission = (
    newFilters: TasksFilterQueryParams
  ): void => {
    onQueryParamChange({ ...newFilters, page: TASKS_DEFAULT_PAGE });
  };

  const handleNavigateToTaskCreation = (): void => {
    history.push({
      pathname: "/Advisor/Tasks/Create",
      search: location.search,
    });
  };

  return (
    <>
      <Stack
        className={styles.container}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row">
          <Select
            className={styles.select}
            options={filterOptions}
            value={matchedFilterOption}
            onChange={handleSelectFilteringOption}
            renderValue={(value) => value}
          />
          <Stack
            className={styles.filtersContainer}
            direction="row"
            alignItems="center"
            onClick={() => setIsModalOpen(true)}
          >
            <Typography className={styles.filtersLabel}>Filters</Typography>
            <KeyboardArrowDownIcon
              className={toClassName({
                [styles.filtersIconRotate]: isModalOpen,
              })}
            />
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={2}>
          <Tooltip tooltipText="Refresh list" placement="top">
            <IconButton onClick={onListRefetch} IconComponent={RefreshIcon} />
          </Tooltip>

          <Button
            variant="outlined"
            size="small"
            buttonType="primary"
            text="Create Task"
            onClick={handleNavigateToTaskCreation}
          />
        </Stack>
      </Stack>

      {isModalOpen && (
        <TasksFilterForm
          currentFilters={filters}
          onSubmit={handleFilterFormSubmission}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
}
