import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

export const useStyles = makeStyles()((theme) => ({
  container: {
    minWidth: 220,
  },
  heading: {
    fontFamily: theme.typography.fontSecondary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: "16px",
    letterSpacing: "0.45px",
    color: theme.colors.primary.tints.navy50,
    marginBottom: theme.spacing(pxToSpacing(4)),
  },
  text: {
    fontFamily: theme.typography.fontSecondary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "19px",
  },
  textPrimary: {
    color: theme.appearance.$textSoft,
  },
  textSecondary: {
    color: theme.colors.primary.tints.navy50,
  },
  groupNameRow: {
    marginBottom: theme.spacing(pxToSpacing(12)),
  },
  accountRow: {
    marginBottom: theme.spacing(pxToSpacing(4)),
  },
  divider: {
    marginTop: theme.spacing(pxToSpacing(12)),
    marginBottom: theme.spacing(pxToSpacing(8)),
    borderColor: theme.appearance.$borderDisabled,
  },
}));
