import React, { useEffect } from "react";

import useGetDocumentFile from "@fartherfinance/frontend/api/CustodianDoc/hooks/useGetDocumentFile";

import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

interface DocumentLinksFetcherProps {
  path: string;
  onLinksPropagation: (previewUrl: string, downloadUrl: string) => void;
}

const DocumentLinksFetcher: React.FC<DocumentLinksFetcherProps> = ({
  path,
  onLinksPropagation,
}) => {
  const auth = useRequestAuth();
  const { data: documentPreviewUrl } = useGetDocumentFile("view", path, auth);
  const { data: documentDownloadUrl } = useGetDocumentFile(
    "download",
    path,
    auth
  );

  useEffect(() => {
    if (documentPreviewUrl && documentDownloadUrl) {
      onLinksPropagation(documentPreviewUrl, documentDownloadUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentPreviewUrl, documentDownloadUrl]);

  return null;
};

export default DocumentLinksFetcher;
