import React, { ComponentProps, useMemo } from "react";

import { Controller, Message, useFormContext } from "react-hook-form";

import useAdvisorClients from "@fartherfinance/frontend/api/Entity/hooks/useAdvisorClients";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import { AutocompleteOption } from "../Dropdown/Dropdown";
import Dropdown from "../SAODropdown/Dropdown";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";

interface Props {
  name: string;
  label: string;
  defaultValue?: AutocompleteClient;
  placeholder?: string;
  disabled?: boolean;
  required?: Message | false;
  rules?: ComponentProps<typeof Controller>["rules"];
  onChange?: (newValue: AutocompleteClient) => void;
}

export interface AutocompleteClient extends AutocompleteOption {
  clientId: ClientId;
  label: string;
}

interface PseudoClient {
  name: {
    first: string;
    last: string;
  };
  clientId: ClientId;
}

export const labelMaker = (client: PseudoClient): string =>
  `${client.name.first} ${client.name.last} (...${client.clientId.slice(-5)})`;

export default function FormAccountHolderDropdownField({
  ...props
}: Props): JSX.Element {
  const { control } = useFormContext();

  const auth = useAdvisorRequestAuth();

  const advisorClients = useAdvisorClients(undefined, auth);

  const clientList: AutocompleteClient[] = useMemo(() => {
    if (advisorClients.data === undefined) {
      if (props.defaultValue) {
        return [props.defaultValue];
      } else {
        return [];
      }
    }

    return advisorClients.data.clients.map((el) => ({
      clientId: el.clientId,
      label: labelMaker(el),
    }));
  }, [advisorClients.data, props.defaultValue]);

  const normalizedDefault: AutocompleteClient | undefined = useMemo(() => {
    if (props.defaultValue === undefined) {
      return undefined;
    }

    return {
      clientId: props.defaultValue.clientId,
      label: props.defaultValue.label,
    };
  }, [props.defaultValue]);

  return (
    <Controller
      defaultValue={normalizedDefault}
      name={props.name}
      control={control}
      rules={{ required: props.required, ...props.rules }}
      render={({ field, fieldState }) =>
        // Otherwise we got option not found errors
        advisorClients.isLoading ? (
          <Dropdown
            placeholder={props.placeholder}
            formLabel={props.label}
            value={null}
            onChange={() => undefined}
            values={[]}
            disabled={true}
            loading={true}
          />
        ) : (
          <Dropdown<AutocompleteClient>
            placeholder={props.placeholder}
            formLabel={props.label}
            value={field.value ?? null}
            onChange={(newValue) => {
              try {
                props.onChange?.(newValue);
              } catch (e) {
                console.error(e);
              }

              field.onChange(newValue);
            }}
            onBlur={field.onBlur}
            values={clientList}
            disabled={props.disabled}
            loading={advisorClients.isLoading}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
          />
        )
      }
    />
  );
}
