import React, { useCallback } from "react";

import PerformanceCustomDateRangePopover from "@src/multiCustodian/components/PerformanceGroups/Summary/components/PerformanceCustomDateRangePopover";
import { DEFAULT_TIME_FRAME } from "@src/multiCustodian/pages/Dashboard/Performance_Groups/shared";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./MultiToggle.module.css";

interface Item<T> {
  label: T;
}

interface Props<T extends string, I extends Item<T>> {
  items: I[] | readonly I[];
  currentItem: Item<T>;
  onChange: (item: Item<T>) => void;
}

const MultiToggle = <T extends string, I extends Item<T>>(
  props: Props<T, I>
): JSX.Element => {
  const onReset = useCallback(
    () => props.onChange({ label: DEFAULT_TIME_FRAME as T }),
    [props]
  );

  return (
    <div className={styles.container}>
      {props.items.map((i) => (
        <div
          className={
            i.label === props.currentItem.label
              ? toClassName(styles.item, styles.selected)
              : styles.item
          }
          key={i.label}
          onClick={() => {
            if (i.label !== "Custom") {
              props.onChange(i);
            }
          }}
        >
          <>
            {i.label === "Custom" ? (
              <PerformanceCustomDateRangePopover
                applyCustomDateRange={() => props.onChange(i)}
                resetToDefaultTimeFrame={onReset}
              />
            ) : (
              <div className={styles.label}>{i.label}</div>
            )}
          </>
        </div>
      ))}
    </div>
  );
};

export default MultiToggle;
