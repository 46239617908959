import React, { useRef, useState } from "react";

import DropdownPopoverButton from "@src/sharedComponents/DropdownPopoverButton/DropdownPopoverButton";
import Popover from "@src/sharedComponents/Popover/Popover";
import ScrollableCheckboxList, {
  CheckboxListConfig,
} from "@src/sharedComponents/ScrollableCheckboxList/ScrollableCheckboxList";

import { DateRangeCheckboxLabel, getDateRangeSelectedString } from "./shared";

interface Props {
  dateRangeFilter: CheckboxListConfig<DateRangeCheckboxLabel>;
  dateRangeSelectedString: string;
  onClose: () => void;
  onOpen: () => void;
  setDateRangeFilter: (
    config: CheckboxListConfig<DateRangeCheckboxLabel>
  ) => void;
  showDateRangePopover: boolean;
  width: number;
}

const DateRangeFilterPopover = (props: Props): JSX.Element => {
  const [curDateRangeString, setCurDateRangeString] =
    useState<DateRangeCheckboxLabel>("All");

  const dateRangePopoverAnchorRef = useRef<HTMLDivElement | null>(null);

  const handleDateRangeChange = (
    config: CheckboxListConfig<DateRangeCheckboxLabel>
  ) => {
    const newDateRange = getDateRangeSelectedString(config);
    const curDateRange = curDateRangeString;

    setCurDateRangeString(newDateRange);

    props.setDateRangeFilter(config);

    if (newDateRange === "Custom" && curDateRange !== "Custom") {
      setTimeout(() => {
        props.onClose();
      }, 200);
    }
  };

  return (
    <Popover
      open={props.showDateRangePopover}
      onClose={props.onClose}
      anchor={dateRangePopoverAnchorRef.current}
      transformOriginVertical={-10}
      popoverElement={
        <div ref={dateRangePopoverAnchorRef}>
          <DropdownPopoverButton
            text={props.dateRangeSelectedString}
            showPopover={props.onOpen}
            minWidth={props.width}
          />
        </div>
      }
    >
      <ScrollableCheckboxList
        haveSelectAll={false}
        checkboxesConfig={props.dateRangeFilter}
        setCheckboxesConfig={handleDateRangeChange}
        multiSelectOff={true}
        minWidth={props.width}
      />
    </Popover>
  );
};

export default DateRangeFilterPopover;
