import React from "react";

import { toClassName } from "../utils/to-class-name";
import useThemeAssets from "@src/multiCustodian/theme/useThemeAssets";

import styles from "./LogoWordmark.module.css";

interface Props {
  className?: string;
}

export default function LogoWordmark(props: Props): JSX.Element {
  const assets = useThemeAssets();

  if (assets.isLoading || assets.hasError) {
    return <></>;
  }

  return (
    <img
      className={toClassName(styles.navbarFartherLogoTitle, props.className)}
      src={assets.data.logoWordmark}
    />
  );
}
