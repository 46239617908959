import { Environment, getEnvironment } from "./environment";

interface EnvironmentConfig {
  MIDDLEWARE_BASE_URL: string;
}

const getEnvConfig = (env: Environment): EnvironmentConfig => {
  switch (env) {
    case Environment.UAT:
      return {
        MIDDLEWARE_BASE_URL: "https://middleware.uat.farther.com/clientops",
      };

    case Environment.PRODUCTION:
      return {
        MIDDLEWARE_BASE_URL: "https://middleware.prod.farther.com/clientops",
      };

    default:
      const _x: never = env;
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Cannot generate configuration for: ${env}`);
  }
};

export const Config = getEnvConfig(getEnvironment());
