import React, { useMemo, useState } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import usePortfolioAnalysisV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/usePortfolioAnalysisV2";
import { PortfolioId } from "@fartherfinance/frontend/api/Types";
import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import useApplicationMode from "@src/multiCustodian/hooks/useApplicationMode";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import PerformanceIndicatorArrow from "@src/sharedComponents/PerformanceIndicatorArrow/PerformanceIndicatorArrow";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import styles from "./HistoricPerformanceTable.module.css";

const timeFrameOptions = ["1Y", "3Y", "5Y", "10Y"] as const;

type TimeFrame = typeof timeFrameOptions[number];

interface Props {
  portfolioId: PortfolioId;
}

const HistoricPerformanceTable = (props: Props): JSX.Element => {
  const [selectedTF, setSelectedTF] = useState<TimeFrame>("1Y");

  const setClassName = (timeFrame: TimeFrame): string => {
    return `${styles.timeframe} ${
      selectedTF === timeFrame ? styles.selected : ""
    }`;
  };

  const auth = useRequestAuth();
  const portfolioAnalysis = usePortfolioAnalysisV2(props.portfolioId, auth);

  const performance: number | null = useMemo(() => {
    if (portfolioAnalysis.data === undefined) {
      return null;
    }

    switch (selectedTF) {
      case "1Y":
        return portfolioAnalysis.data.trailingReturns.oneYearReturn;
      case "3Y":
        return portfolioAnalysis.data.trailingReturns.threeYearReturn;
      case "5Y":
        return portfolioAnalysis.data.trailingReturns.fiveYearReturn;
      case "10Y":
        return portfolioAnalysis.data.trailingReturns.tenYearReturn;
      default:
        return null;
    }
  }, [portfolioAnalysis, selectedTF]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p className={styles.headerText}>Historical Performance</p>

        <div className={styles.perfDiv}>
          <PerformanceText
            performance={performance}
            portfolioAnalysisIsLoading={portfolioAnalysis.isLoading}
          />
        </div>
      </div>

      <div className={styles.body}>
        <div className={styles.tabs}>
          {timeFrameOptions.map((tf) => (
            <div
              key={tf}
              className={setClassName(tf)}
              onClick={() => setSelectedTF(tf)}
            >
              {tf}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HistoricPerformanceTable;

interface PerformanceTextProps {
  performance: number | null;
  portfolioAnalysisIsLoading: boolean;
}

const PerformanceText = ({
  performance,
  portfolioAnalysisIsLoading,
}: PerformanceTextProps) => {
  const {
    color: { $iconPerformancePositive, $iconPerformanceNegative },
  } = useTheme();

  const { isClientMode, isImpersonationMode } = useApplicationMode();

  if (portfolioAnalysisIsLoading) {
    return <Skeleton width={100} className={styles.loadingFiller} />;
  }

  if (performance === null) {
    return (
      <div className={styles.flexDiv}>
        <p className={styles.noDataText}>No data available</p>

        {(isClientMode || isImpersonationMode) && (
          <Tooltip
            tooltipText="One or more of the accounts in the model portfolio is not old enough to display this time frame."
            placement="top"
          >
            <InfoOutlinedIcon className={styles.infoIcon} />
          </Tooltip>
        )}
      </div>
    );
  }

  return (
    <>
      <div className={styles.perfTriangle}>
        <PerformanceIndicatorArrow
          status={
            performance === null
              ? "neutral"
              : performance > 0
              ? "positive"
              : "negative"
          }
        />
      </div>

      <p
        className={styles.perfText}
        style={{
          color:
            performance >= 0
              ? $iconPerformancePositive
              : $iconPerformanceNegative,
        }}
      >
        {`${performance >= 0 ? "+" : ""}${performance}%`}
      </p>
    </>
  );
};
