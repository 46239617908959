import { ServiceProfessional } from "@fartherfinance/frontend/api/Sharing/Types";

import {
  serviceProfessionalOptions,
  ServiceProfessionalTypeOption,
  serviceProfessionalTypes,
} from "@src/constants/serviceProffesional";

export const getServiceProfessionalOptionFromType = (
  servicePro?: ServiceProfessional
): ServiceProfessionalTypeOption | undefined => {
  if (!servicePro) {
    return undefined;
  }

  return serviceProfessionalOptions[
    serviceProfessionalTypes.indexOf(servicePro)
  ];
};
