import React from "react";

import StarIcon from "@mui/icons-material/Star";

import styles from "./FavoriteStarLabel.module.css";

const FavoriteStarLabel: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>Favorite</div>

      <StarIcon className={styles.icon} />
    </div>
  );
};

export default FavoriteStarLabel;
