import { isValid } from "date-fns";

import {
  AssigneeType,
  TaskAssignee,
  TaskStatus,
} from "@fartherfinance/frontend/api/Tasks/Types";
import { isGroupAssignee } from "@fartherfinance/frontend/api/Tasks/utils/isGroupAssignee";

import {
  UNKNOWN_GROUP,
  UNKNOWN_USER,
} from "@src/multiCustodian/pages/Advisor/common";

import { TaskAssigneeAutocompleteOption } from "./common";

export const mapAssigneeToOption = (
  assignee: TaskAssignee
): TaskAssigneeAutocompleteOption => {
  return isGroupAssignee(assignee)
    ? {
        label: assignee.groupName ?? UNKNOWN_GROUP,
        value: assignee.id,
        meta: { type: AssigneeType.enum.GROUP },
      }
    : {
        label: assignee.personName
          ? `${assignee.personName.first} ${assignee.personName.last}`
          : UNKNOWN_USER,
        value: assignee.id,
        meta: { type: AssigneeType.enum.PERSON },
      };
};

export const isDateValid = (date: Date | null): boolean | string => {
  return date && !isValid(date) ? "Invalid date" : true;
};

type TaskEditableField =
  | "taskStatus"
  | "taskPriority"
  | "taskType"
  | "title"
  | "description"
  | "assignee"
  | "client"
  | "advisor"
  | "dueDate"
  | "custodian";

export const systemGeneratedAllowList: TaskEditableField[] = [
  "taskStatus",
  "taskPriority",
  "assignee",
  "title",
  "dueDate",
];

const taskTerminalStatuses: TaskStatus[] = [
  TaskStatus.enum.COMPLETED,
  TaskStatus.enum.CANCELED,
];

export const isTaskFieldEditingRestricted = (
  fieldName: TaskEditableField,
  task: { taskStatus: TaskStatus; isSystemGenerated: boolean } | undefined
): boolean => {
  if (!task) {
    return false;
  }

  const isOutOfAllowList =
    systemGeneratedAllowList.includes(fieldName) === false;

  // NOTE: order matters, we need to list the restrictions from the most broad to the most specific

  // NOTE: if task is in terminal status, only taskStatus can be edited
  if (
    taskTerminalStatuses.includes(task.taskStatus) &&
    fieldName !== "taskStatus"
  ) {
    return true;
  }

  // NOTE: if task is system generated, only fields from allow list can be edited
  if (task.isSystemGenerated && isOutOfAllowList) {
    return true;
  }

  return false;
};
