import React, { useState } from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Fade, Stack, Typography } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";

import useClientMarketplaceOffering from "@fartherfinance/frontend/api/AltAssetsService/hooks/useClientMarketplaceOffering";
import useExpressClientOfferingInterest from "@fartherfinance/frontend/api/AltAssetsService/hooks/useExpressClientOfferingInterest";
import { OfferingInterestType } from "@fartherfinance/frontend/api/AltAssetsService/requests/postClientOfferingInterest";
import { MarketplaceAssetType } from "@fartherfinance/frontend/api/AltAssetsService/Types";
import useClientDashboard from "@fartherfinance/frontend/api/Dashboard/hooks/useClientDashboard";

import Drawer from "@src/multiCustodian/components/Drawer/Drawer";
import OfferingDetailsParagraph from "@src/multiCustodian/components/Marketplace/OfferingDetails/OfferingDetailsParagraph/OfferingDetailsParagraph";
import TabularSection from "@src/multiCustodian/components/Marketplace/OfferingDetails/TabularSection/TabularSection";
import {
  MarketplaceOfferingClientPathParams,
  MarketplaceOfferingTypeCard,
} from "@src/multiCustodian/components/Marketplace/types";
import { findOfferingTypeByValue } from "@src/multiCustodian/components/Marketplace/utils";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

// NOTE: shared css styles
import styles from "@src/multiCustodian/components/Marketplace/OfferingDetails/OfferingDetails.module.css";

interface OfferingDetailsClientProps {
  assetType: MarketplaceAssetType;
  offeringTypes: MarketplaceOfferingTypeCard[];
  marketplacePath: string;
}

const OfferingDetailsClient: React.FC<OfferingDetailsClientProps> = ({
  assetType,
  offeringTypes,
  marketplacePath,
}) => {
  const history = useHistory();
  const { clientId, offeringType, offeringId } =
    useParams<MarketplaceOfferingClientPathParams>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const auth = useRequestAuth();
  const {
    data: offering,
    isLoading,
    hasError,
  } = useClientMarketplaceOffering(offeringId, auth);
  const { data: dashboard } = useClientDashboard(clientId, auth);
  const statusNotification = useStatusNotification();

  const callExpressClientOfferingInterest =
    useExpressClientOfferingInterest(auth);

  const marketplaceOfferingType = findOfferingTypeByValue(
    offeringTypes,
    offeringType
  );
  const scheduleMeetingUrl = dashboard?.advisor.calendarUrl;

  const handleNavigateToOfferingsList = (): void => {
    history.push({
      pathname: `/Client/${clientId}/${marketplacePath}/${offeringType}`,
      search: history.location.search,
    });
  };

  const handleExpressClientOfferingInterest = async (
    offeringInterest: OfferingInterestType
  ): Promise<void> => {
    try {
      await callExpressClientOfferingInterest({ offeringId, offeringInterest });

      if (offeringInterest === OfferingInterestType.enum.interest) {
        statusNotification("We let your advisor know.", "Success");
      }
    } catch (_error) {
      if (offeringInterest === OfferingInterestType.enum.interest) {
        statusNotification(
          "Something went wrong, please try again later.",
          "Error"
        );
      }
    }
  };

  const handleScheduleMeeting = (meetingUrl: string): void => {
    window.open(meetingUrl, "_blank");

    if (offering?.hasUserExpressedInterest === false) {
      handleExpressClientOfferingInterest(
        OfferingInterestType.enum["requested-meeting"]
      );
    }
  };

  return (
    <Drawer
      isDrawerOpen={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
      SlideProps={{ onExited: handleNavigateToOfferingsList }}
    >
      {offering && (
        <Fade in>
          <Stack className={styles.container}>
            <Stack className={styles.details}>
              <Typography className={styles.name}>
                {offering.partner.name}
              </Typography>

              {marketplaceOfferingType && (
                <OfferingDetailsParagraph
                  title={`${marketplaceOfferingType.name} Offering`}
                  description={marketplaceOfferingType.description}
                />
              )}

              <OfferingDetailsParagraph
                title={`About ${offering.partner.name}`}
                description={offering.partner.description}
              />

              <OfferingDetailsParagraph
                title="How It Works"
                description={offering.howItWorks}
              />
            </Stack>

            <div className={styles.getStarted}>
              <OfferingDetailsParagraph
                title="Get Started"
                description="To get started, schedule a meeting with your advisor or tell them you’re interested in this offering."
              />

              <div className={styles.actions}>
                <Button
                  variant="outlined"
                  buttonType="primary"
                  text="I'm Interested"
                  classes={{
                    disabled: styles.disabledAction,
                  }}
                  disabled={offering.hasUserExpressedInterest}
                  startIcon={
                    offering.hasUserExpressedInterest && <CheckCircleIcon />
                  }
                  onClick={() =>
                    handleExpressClientOfferingInterest(
                      OfferingInterestType.enum.interest
                    )
                  }
                />
                {scheduleMeetingUrl && (
                  <Button
                    variant="contained"
                    buttonType="primary"
                    text="Schedule a Meeting"
                    startIcon={<OpenInNewIcon />}
                    onClick={() => handleScheduleMeeting(scheduleMeetingUrl)}
                  />
                )}
              </div>
            </div>

            <TabularSection
              title="Details"
              entries={[
                {
                  key: "Other Offerings",
                  value: offering.otherOfferings.join(", "),
                },
                {
                  key:
                    assetType === MarketplaceAssetType.enum.insurance
                      ? "Top Carriers"
                      : "Top Underwriters",
                  value: offering.providers.join(", "),
                },
              ]}
            />
          </Stack>
        </Fade>
      )}

      {hasError && (
        <Stack
          className={styles.errorContainer}
          justifyContent="center"
          alignItems="center"
        >
          <Typography className={styles.errorMessage}>
            Something went wrong, please try again later.
          </Typography>
        </Stack>
      )}

      {isLoading && (
        <div className={styles.loading}>
          <LogoLoadingStill />
        </div>
      )}
    </Drawer>
  );
};

export default OfferingDetailsClient;
