import React, { useCallback } from "react";

import DownloadIcon from "@mui/icons-material/Download";
import { Stack } from "@mui/material";
import { useHistory } from "react-router-dom";

import CSVError from "../../Advisor/Investments/SharedComponents/CSVError/CSVError";
import ButtonPrimary from "../../MUI/Button/Button";
import Checkbox from "@src/sharedComponents/Checkbox/Checkbox";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import Modal from "@src/sharedComponents/Modal/Modal";
import FileDropUploadComponent from "@src/sharedComponents/SingleFileDropUpload/SingleFileDropUpload";

import ClientCSVTips from "./ClientCSVTips/ClientCSVTips";
import { BulkClientsForm } from "./CreateBulkClientsModalForm";

import styles from "./CreateBulkClientModal.module.css";

const BULK_PORTAL_CREATION_TEMPLATE =
  "https://uat-self-service.s3.amazonaws.com/Bulk_Portal_Creation_Extended_Template.csv";

interface Props {
  addNewCSV: (file: File | null) => void;
  clientCSV: File | null;
  form: BulkClientsForm;
  setForm: (form: BulkClientsForm) => void;
  setShowForm: (bool: boolean) => void;
  mutating: boolean;
  csvError: string | null;
}

const CreateBulkClientsModalCSVDrop = (props: Props): JSX.Element => {
  const changeForm = (key: keyof BulkClientsForm) => {
    props.setForm({
      ...props.form,
      [key]: !props.form[key], // flip bool
    });
  };

  const history = useHistory();

  const onClose = useCallback(
    () => history.push({ ...history.location, pathname: "/Advisor/Admin" }),
    [history]
  );

  return (
    <Modal closeModal={onClose} modalStyle={{ width: "620px" }}>
      <div className={styles.subtleText}>Bulk Create Client Accounts</div>

      <Spacer verticalSpacing="5px" />

      <FormH1>Upload a Spreadsheet (CSV)</FormH1>

      <Spacer verticalSpacing="24px" />

      <FileDropUploadComponent
        onChange={props.addNewCSV}
        file={props.clientCSV}
        acceptedFileExtensions={[".csv"]}
        text={"Drop your file here or "}
        pressText={<div className={styles.fileUploadText}>browse</div>}
      />

      {props.csvError !== null && (
        <>
          <CSVError message={[props.csvError]} />
          <Spacer verticalSpacing="16px" />
        </>
      )}

      <div className={styles.formHeader}>CSV Processing</div>

      <Checkbox
        checked={props.form.normalization}
        onChange={() => changeForm("normalization")}
        label={"Automatically capitalize client names and account names"}
        disabled={false}
      />

      <Spacer verticalSpacing="20px" />

      <ClientCSVTips />

      <Spacer verticalSpacing="4px" />

      <Stack direction={"row"} justifyContent={"space-between"}>
        <ButtonPrimary
          text={
            <a
              className={styles.downloadLink}
              href={BULK_PORTAL_CREATION_TEMPLATE}
            >
              <span>Download CSV Template</span>
              <DownloadIcon className={styles.icon} />
            </a>
          }
          variant="outlined"
          onClick={() => undefined}
          buttonType="primary"
        />

        <ButtonPrimary
          disabled={props.clientCSV === null || props.mutating}
          text="Continue"
          variant="contained"
          buttonType="primary"
          onClick={() => props.setShowForm(true)}
        />
      </Stack>
    </Modal>
  );
};

export default CreateBulkClientsModalCSVDrop;
