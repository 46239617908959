import React from "react";

import { Stack } from "@mui/material";

import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import ButtonPrimary from "../../MUI/Button/Button";
import Checkbox from "@src/sharedComponents/Checkbox/Checkbox";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import RadioButtons from "@src/sharedComponents/Radio/RadioButtons";

import styles from "./CreateBulkClientModal.module.css";

const RADIO_BTN_OPTIONS = {
  yes: "Yes, show the checklist",
  no: "No, do not show the checklist",
};

export interface BulkClientsForm {
  masterDisplay: boolean;
  investorProfile: boolean;
  contactInfo: boolean;
  additionalInfo: boolean;
  affiliations: boolean;
  beneficiaries: boolean;
  onboardingAgreement: boolean;
  privacyPolicy: boolean;
  custodianAgreement: boolean;
  normalization: boolean;
}

interface Props {
  form: BulkClientsForm;
  setForm: (form: BulkClientsForm) => void;
  setShowForm: (bool: boolean) => void;
  submit: () => void;
  clientCSV: File | null;
  mutating: boolean;
}

const CreateBulkClientsModalForm = (props: Props): JSX.Element => {
  const {
    color: { $text, $textDisabled },
  } = useTheme();

  const changeForm = (key: keyof BulkClientsForm) => {
    props.setForm({
      ...props.form,
      [key]: !props.form[key], // flip bool
    });
  };

  const changeFormFromRadioBtn = (newValue: string) => {
    props.setForm({
      ...props.form,
      masterDisplay: newValue === RADIO_BTN_OPTIONS.yes,
    });
  };

  return (
    <div className={styles.form}>
      <div className={styles.subtleText}>Bulk Create Client Accounts</div>

      <Spacer verticalSpacing="5px" />

      <FormH1>Control the Client's Checklist</FormH1>

      <div className={styles.description}>
        Every user will receive the settings you specify below. Split up your
        spreadsheet to specify different settings for the different clients.
      </div>

      <RadioButtons
        label={"Should we show the checklist on the client's dashboard?"}
        values={[RADIO_BTN_OPTIONS.yes, RADIO_BTN_OPTIONS.no]}
        onChange={changeFormFromRadioBtn}
        value={
          props.form.masterDisplay
            ? RADIO_BTN_OPTIONS.yes
            : RADIO_BTN_OPTIONS.no
        }
        isValid={undefined}
        labelColor={$text}
        labelStyle={{ marginRight: "15px", fontWeight: 400 }}
        iconStyle={{ width: "20px", height: "20px" }}
      />

      <div
        className={styles.formHeader2}
        style={{ color: props.form.masterDisplay ? $text : $textDisabled }}
      >
        Which items should already be checked off?
      </div>

      <Checkbox
        checked={props.form.investorProfile}
        onChange={() => changeForm("investorProfile")}
        label={"Investor Profile"}
        disabled={!props.form.masterDisplay}
      />

      <Checkbox
        checked={props.form.contactInfo}
        onChange={() => changeForm("contactInfo")}
        label={"Contact Info"}
        disabled={!props.form.masterDisplay}
      />

      <Checkbox
        checked={props.form.additionalInfo}
        onChange={() => changeForm("additionalInfo")}
        label={"Additional Info"}
        disabled={!props.form.masterDisplay}
      />

      <Checkbox
        checked={props.form.affiliations}
        onChange={() => changeForm("affiliations")}
        label={"Affiliations"}
        disabled={!props.form.masterDisplay}
      />

      <Checkbox
        checked={props.form.beneficiaries}
        onChange={() => changeForm("beneficiaries")}
        label={"Beneficiaries"}
        disabled={!props.form.masterDisplay}
      />

      <Checkbox
        checked={props.form.onboardingAgreement}
        onChange={() => changeForm("onboardingAgreement")}
        label={"Farther Agreements"}
        disabled={!props.form.masterDisplay}
      />

      <Checkbox
        checked={props.form.privacyPolicy}
        onChange={() => changeForm("privacyPolicy")}
        label={"Privacy Policy"}
        disabled={!props.form.masterDisplay}
      />

      <Checkbox
        checked={props.form.custodianAgreement}
        onChange={() => changeForm("custodianAgreement")}
        label={"Custodian Agreements"}
        disabled={!props.form.masterDisplay}
      />

      <Stack direction={"row"} justifyContent={"flex-end"}>
        <ButtonPrimary
          text={"Back"}
          variant="outlined"
          onClick={() => props.setShowForm(false)}
          buttonType="primary"
        />

        <ButtonPrimary
          disabled={props.clientCSV === null || props.mutating}
          text="Upload"
          variant="contained"
          buttonType="primary"
          onClick={() => props.submit()}
          sx={{ marginLeft: "15px" }}
        />
      </Stack>
    </div>
  );
};

export default CreateBulkClientsModalForm;
