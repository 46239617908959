import React, { useCallback, useMemo, useState } from "react";

import { format } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import { last, throttle } from "lodash";

import {
  SummaryScreenData,
  TimeFrame,
} from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Types";

import DateDisplay, {
  XAxisData,
} from "../../GraphComponents/Summary/DateDisplay/DateDisplay";
import TimeRangeSelector from "../Shared/TimeRangeSelector";
import { UpdateDisplayData } from "../Types";
import { DEFAULT_TIME_FRAME } from "@src/multiCustodian/pages/Dashboard/Performance_Groups/shared";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import PortaledChip from "@src/sharedComponents/PortaledChip/PortaledChip";

import EndDate from "./components/EndDate/EndDate";
import { usePerformanceCustomDateRangeContext } from "./components/PerformanceCustomDateRangeContextProvider";
import SummaryGraph, { BalanceSummary } from "./SummaryGraph";

import styles from "./SummaryGraphContainer.module.css";

// All cannot be re-used between accounts so it's expensive
export const timeFrameOptions = (lowDataMode: boolean): TimeFrame[] =>
  lowDataMode
    ? ["30D", "3M", "YTD", "1Y"]
    : ["30D", "3M", "YTD", "1Y", "ALL", "Custom"];

interface Props {
  isLoading: boolean;
  summaryData: SummaryScreenData | null;
  timeRange: TimeFrame;
  setTimeRange: (range: TimeFrame) => void;
  balanceSummary: BalanceSummary[];
  throttledUpdate: UpdateDisplayData;
  isInDashboard?: boolean;
}

const SummaryGraphContainer = (props: Props): JSX.Element => {
  const { enableLowDataPerformanceGroups, enablePerformanceCustomTimeFrame } =
    useFlags();

  const [isHoveringOverGraph, setIsHoveringOverGraph] =
    useState<boolean>(false);

  const [xAxisData, setXAxisData] = useState<XAxisData | null>(null);

  const changeXAxisData = useCallback((axisData: XAxisData): void => {
    setXAxisData(axisData);
  }, []);

  const throttledUpdate = useMemo(
    () => throttle(changeXAxisData, 100),
    [changeXAxisData]
  );

  const {
    customDateRange,
    showCustomDateRangeForm,
    setShowCustomDateRangeForm,
  } = usePerformanceCustomDateRangeContext();

  const portaledChipLabel = useMemo(() => {
    if (customDateRange.from === null || customDateRange.to === null) {
      return "From - To -";
    }

    return `From: ${format(customDateRange.from, "M/d/yyyy")} - To: ${format(
      customDateRange.to,
      "M/d/yyyy"
    )}`;
  }, [customDateRange]);

  const filteredTimeFrameOptions = useMemo(() => {
    return timeFrameOptions(enableLowDataPerformanceGroups).filter(
      (tf) =>
        enablePerformanceCustomTimeFrame ||
        (!enablePerformanceCustomTimeFrame && tf !== "Custom") // hides custom date range
    );
  }, [enablePerformanceCustomTimeFrame, enableLowDataPerformanceGroups]);

  const handleOnPress = useCallback(() => {
    setShowCustomDateRangeForm(!showCustomDateRangeForm);
  }, [showCustomDateRangeForm, setShowCustomDateRangeForm]);

  const handleOnClear = () => props.setTimeRange(DEFAULT_TIME_FRAME);

  return (
    <>
      {props.timeRange === "Custom" && (
        <PortaledChip
          portalMountElementId={"performanceCustomTimeFrameChip"}
          className={toClassName({
            [styles.portaledChip]: props.isInDashboard,
          })}
          label={portaledChipLabel}
          onPress={handleOnPress}
          onClear={handleOnClear}
        />
      )}

      <div>
        <div
          onMouseEnter={() => setIsHoveringOverGraph(true)}
          onMouseLeave={() => setIsHoveringOverGraph(false)}
        >
          <SummaryGraph
            isLoading={props.isLoading}
            updateDisplayData={props.throttledUpdate}
            toDate={props.summaryData?.endDate ?? null}
            balanceSummary={props.balanceSummary}
            setXIndexAndPosition={(
              xIndex: number,
              xPos: number,
              graphWidth: number
            ) =>
              throttledUpdate({
                index: xIndex,
                xPos: xPos,
                graphWidth: graphWidth,
              })
            }
            timeRange={props.timeRange}
          />
        </div>

        <DateDisplay
          isHoveringOverGraph={isHoveringOverGraph}
          startDate={props.summaryData?.startDate ?? null}
          xAxisData={xAxisData}
          dateKey="endDate"
          data={props.balanceSummary}
        >
          {!isHoveringOverGraph && (
            <div className={styles.xAxisFloat}>
              <TimeRangeSelector
                isDisabled={props.isLoading}
                timeRange={props.timeRange}
                timeFrameOptions={filteredTimeFrameOptions}
                setTimeRange={props.setTimeRange}
              />
            </div>
          )}

          {!isHoveringOverGraph && (
            <EndDate endDate={last(props.balanceSummary)?.endDate ?? null} />
          )}
        </DateDisplay>
      </div>
    </>
  );
};

export default SummaryGraphContainer;
