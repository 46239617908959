import React, { ComponentProps } from "react";

import { NavLink } from "react-router-dom";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import * as styles from "./TabBar.module.css";

interface OnClickTab<S extends React.ReactNode> {
  tab: S;
  onClick: () => void;
  tabKey: string;
}

export interface HrefTab<S extends React.ReactNode> {
  tab: S;
  href: string | ComponentProps<typeof NavLink>["to"];
  tabKey: string;
}

type TabButton<S extends React.ReactNode> = OnClickTab<S> | HrefTab<S>;

interface Props<S extends React.ReactNode, O extends TabButton<S>> {
  currentTabKey: string;
  tabs: O[];
}

export default function TabBar<
  S extends React.ReactNode,
  O extends TabButton<S>
>(props: Props<S, O>): JSX.Element {
  return (
    <div className={styles.tabBar}>
      {props.tabs.map((t) =>
        "href" in t ? (
          <NavLink
            key={t.tabKey}
            className={toClassName(styles.tab, {
              [styles.selectedTab]: t.tabKey === props.currentTabKey,
            })}
            to={t.href}
          >
            {t.tab}
          </NavLink>
        ) : (
          <a
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              t.onClick();
            }}
            key={t.tabKey}
            className={toClassName(styles.tab, {
              [styles.selectedTab]: t.tabKey === props.currentTabKey,
            })}
          >
            {t.tab}
          </a>
        )
      )}
    </div>
  );
}
