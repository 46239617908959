import { isNil } from "lodash";

const ssnRegex = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;

export default function isValidSSN(
  taxID: string | null | undefined,
  required: boolean
): string | true {
  const isEmpty = isNil(taxID) || taxID === "";

  const validTaxID = ssnRegex.test(taxID ?? "");

  if (required) {
    return validTaxID
      ? true
      : "Please enter your SSN (or ITIN) as 'xxx-xx-xxxx'";
  }
  return isEmpty || validTaxID
    ? true
    : "Please enter your SSN (or ITIN) as 'xxx-xx-xxxx'";
}
