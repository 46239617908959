import React, { useEffect, useRef } from "react";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./InfiniteScroll.module.css";

interface InfiniteScrollProps {
  className?: string;
  onIntersection: (entries: IntersectionObserverEntry[]) => void;
  intersectionThreshold?: number;
  intersectionObserverOptions?: Omit<IntersectionObserverInit, "root">;
}

export default function InfiniteScroll({
  children,
  className,
  onIntersection,
  intersectionObserverOptions,
  intersectionThreshold = 1,
}: React.PropsWithChildren<InfiniteScrollProps>): JSX.Element {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const targetRef = useRef<HTMLDivElement | null>(null);

  const threshold =
    React.Children.toArray(children).length - Math.abs(intersectionThreshold);

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersection, {
      // INFO: the element that is used as the viewport for checking visibility of the target, must be the ancestor of the target
      root: containerRef.current,
      // INFO: margin of the root element, can have values similar to the CSS margin property,
      // e.g. "10px 20px 30px 40px" (top, right, bottom, left),
      // this set of values serves to grow or shrink each side of the root element's bounding box before computing intersections
      rootMargin: "0px",
      // INFO: the threshold at which the callback is invoked, starting at 0.0 and ending at 1.0,
      // this is the percentage of the target's visibility the observer's callback should be executed,
      // setting it to 0.5 means that the callback will be executed when 50% of the target is visible,
      // we can also pass and array of thresholds, for example [0, 0.25, 0.5, 0.75, 1],
      // in this case the callback will be executed each time one of the thresholds is reached
      threshold: 0,
      ...intersectionObserverOptions,
    });
    const targetElement = targetRef.current;

    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement);
      }
    };
  }, [containerRef, targetRef, onIntersection, intersectionObserverOptions]);

  return (
    <div
      id="infinite-scroll-container"
      ref={containerRef}
      className={toClassName(styles.container, className)}
    >
      {React.Children.map(children, (child, index) => (
        <div
          key={index}
          id="infinite-scroll-child-wrapper"
          ref={index === threshold ? targetRef : null}
        >
          {child}
        </div>
      ))}
    </div>
  );
}
