import { isAxiosError } from "axios";

// NOTE: as per MDN documentation, https://developer.mozilla.org/en-US/docs/Web/HTTP/Status

type HttpStatus300s =
  | 300 // Multiple Choices
  | 301 // Moved Permanently
  | 302 // Found (Previously "Moved temporarily")
  | 303 // See Other
  | 304 // Not Modified
  | 305 // Use Proxy
  | 307 // Temporary Redirect
  | 308; // Permanent Redirect;

type HttpStatus400s =
  | 400 // Bad Request
  | 401 // Unauthorized
  | 403 // Forbidden
  | 404 // Not Found
  | 405 // Method Not Allowed
  | 406 // Not Acceptable
  | 407 // Proxy Authentication Required
  | 408 // Request Timeout
  | 409 // Conflict
  | 410 // Gone
  | 411 // Length Required
  | 412 // Precondition Failed
  | 413 // Payload Too Large
  | 414 // URI Too Long
  | 415 // Unsupported Media Type
  | 416 // Range Not Satisfiable
  | 417 // Expectation Failed
  | 418 // I'm a teapot (RFC 2324)
  | 421 // Misdirected Request
  | 422 // Unprocessable Entity (WebDAV)
  | 423 // Locked (WebDAV)
  | 424 // Failed Dependency (WebDAV)
  | 425 // Too Early
  | 426 // Upgrade Required
  | 428 // Precondition Required
  | 429 // Too Many Requests
  | 431 // Request Header Fields Too Large
  | 451; // Unavailable For Legal Reasons;

type HttpStatus500s =
  | 500 // Internal Server Error
  | 501 // Not Implemented
  | 502 // Bad Gateway
  | 503 // Service Unavailable
  | 504 // Gateway Timeout
  | 505 // HTTP Version Not Supported
  | 506 // Variant Also Negotiates
  | 507 // Insufficient Storage (WebDAV)
  | 508 // Loop Detected (WebDAV)
  | 510 // Not Extended
  | 511; // Network Authentication Required;

type HttpStatusCode = HttpStatus300s | HttpStatus400s | HttpStatus500s;

export const isAxiosErrorCode = (
  code: HttpStatusCode,
  error: unknown
): boolean => {
  return isAxiosError(error) && error.response?.status === code;
};

export const extractAxiosErrorMessage = (
  error: unknown,
  fallbackMessage?: string
): string => {
  const fallback = fallbackMessage ?? "Something went wrong, please try again.";

  if (isAxiosError(error)) {
    return error.response?.data.message ?? fallback;
  }

  return fallback;
};
