import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";

import {
  EventSourceMessage,
  fetchEventSource,
} from "@microsoft/fetch-event-source";
import CloseRounded from "@mui/icons-material/CloseRounded";
import Send from "@mui/icons-material/Send";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import IconButton from "@src/sharedComponents/IconButton/IconButton";
import TextInput from "@src/sharedComponents/TextInput/TextInput";
import { State } from "@src/store";

import { markdownListSplit } from "./markdown";

import * as styles from "./ChatWindow.module.css";

interface Props {
  prompt: string;
  onClose: () => void;
  clientId: ClientId;
}

type TextAction =
  | { type: "Concat"; text: string }
  | { type: "Replace"; find: string; replace: string }
  | { type: "Set"; text: string };

const textReducer = (state: string, action: TextAction) => {
  switch (action.type) {
    case "Concat":
      return `${state}${action.text}`;

    case "Replace": {
      // Use regex to replace ALL **current** templates
      const regex = new RegExp(
        `${action.find.replace(/\[/g, "\\[").replace(/\]/g, "\\]")}`,
        "g"
      );
      const newStr = state.replace(regex, action.replace);
      return newStr;
    }

    case "Set":
      return action.text;

    default:
      return state;
  }
};

interface OpenAIChat {
  role: "system" | "user" | "assistant";
  content: string;
}

type ChatAction = { type: "Push"; chat: OpenAIChat };

const chatReducer = (state: OpenAIChat[], action: ChatAction) => {
  switch (action.type) {
    case "Push":
      return [...state, action.chat];
    default:
      return state;
  }
};

export default function GenAIChatWindow(props: Props): JSX.Element {
  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));

  // Keeps track of active remote template replacement network requests
  const activeReplacements = useRef<string[]>([]);
  const [loading, setLoading] = useState(false);

  const [userMessage, setUserMessage] = useState("");

  const [text, dispatchText] = useReducer(textReducer, "");
  const [prevMessages, dispatchChat] = useReducer(chatReducer, []);

  const chatRef = useRef<HTMLDivElement>(null);

  const startTalking = useCallback(
    (messages?: OpenAIChat[]) => {
      if (advisorId === null) {
        return;
      }

      const endpoint =
        messages === undefined ? props.prompt : "/advisors/chat/freeform";
      const method = endpoint === "/advisors/chat/freeform" ? "POST" : "GET";
      const body = method === "GET" ? undefined : JSON.stringify(messages);

      const url =
        process.env.WEBAPP_ENV === "PROD"
          ? `https://genai.farther.com${endpoint}`
          : `https://uat-genai.farther.com${endpoint}`;

      setLoading(true);
      const ctrl = new AbortController();
      fetchEventSource(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          client: props.clientId,
          advisor: advisorId,
        },
        body: body,
        signal: ctrl.signal,
        onmessage: (ev: EventSourceMessage) => {
          switch (ev.event) {
            case "message": {
              const parsed: OpenAIChat = JSON.parse(ev.data);
              dispatchChat({ type: "Push", chat: parsed });
              chatRef.current?.scrollIntoView({ behavior: "smooth" });
              return;
            }

            case "end":
              ctrl.abort();
              setLoading(false);
              return;

            case "token": {
              const parsed: { token: string } = JSON.parse(ev.data);
              dispatchText({ type: "Concat", text: parsed.token });
              chatRef.current?.scrollIntoView({ behavior: "smooth" });
              return;
            }
          }
        },
      });

      return () => {
        ctrl.abort();
      };
    },
    [advisorId, props.clientId, props.prompt]
  );

  useEffect(() => startTalking(), [startTalking]);

  useEffect(() => {
    // Search for all text that is enclosed in square brackets like `[first name]`
    const findTemplates = /(\[.+?\])/g;
    let allMatches: string[] = [];

    // loop till we get all the matches in the `text` string
    let matches: RegExpExecArray | null = null;
    while ((matches = findTemplates.exec(text))) {
      allMatches = [...allMatches, matches[1]];
    }

    // See if we are already retrieving the template replacement, don't look
    // for it twice
    const currentSearches = activeReplacements.current;
    const newSearches = allMatches.filter(
      (x) => currentSearches.includes(x) === false
    );

    activeReplacements.current = [...currentSearches, ...newSearches];

    newSearches.forEach(async (search) => {
      if (advisorId === null) {
        return;
      }

      const url =
        process.env.WEBAPP_ENV === "PROD"
          ? `https://genai.farther.com/template/replace`
          : `https://uat-genai.farther.com/template/replace`;

      // GenAI server knows best how to replace templates ask it for the
      // replacement strings
      const res = await fetch(`${url}?template=${search}`, {
        headers: {
          "Content-Type": "application/json",
          client: props.clientId,
          advisor: advisorId,
        },
      });

      const body = (await res.json()) as {
        template: string;
        replacement: string;
      };

      // Remove from the list so we replace it if the template shows up again
      // later. We should probably cache the template replacements in another
      // useRef.
      activeReplacements.current = activeReplacements.current.filter(
        (x) => x !== search
      );

      dispatchText({
        type: "Replace",
        find: body.template,
        replace: body.replacement,
      });
    });
  }, [advisorId, props.clientId, text]);

  const formattedMarkdown = markdownListSplit(text);

  const normalizedText =
    loading === false || formattedMarkdown.trim() === ""
      ? formattedMarkdown
      : removeLastWord(formattedMarkdown);

  return (
    <div className={styles.chatContainer}>
      <div className={styles.closeButton}>
        <IconButton onClick={props.onClose} IconComponent={CloseRounded} />
      </div>

      <div className={styles.messagesContainer}>
        {prevMessages.flatMap((message) => (
          <div
            className={
              message.role !== "assistant" ? styles.chatLeft : styles.chatRight
            }
          >
            {markdownListSplit(message.content)
              .split("\n\n")
              .map((section) => (
                <div className={styles.messageSection} key={section}>
                  {section}
                </div>
              ))}
          </div>
        ))}

        {normalizedText === "" ? (
          <div className={styles.loading}>
            {prevMessages.length === 0
              ? "Gathering client details..."
              : "Reticulating splines..."}
          </div>
        ) : (
          <div className={styles.chatRight}>
            {normalizedText.split("\n\n").map((s) => (
              <div className={styles.messageSection} key={s}>
                {s.split(" ").map((word, idx, arr) => (
                  <motion.span
                    initial={idx === arr.length - 1 ? { opacity: 0 } : false}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3, ease: "easeIn" }}
                    key={idx}
                  >
                    {word}{" "}
                  </motion.span>
                ))}
              </div>
            ))}

            <div id="chat-bottom" style={{ height: 0 }} ref={chatRef} />
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <TextInput
          placeholder="Ask copilot for something... ex: Make it shorter"
          className={styles.messageEntry}
          value={userMessage}
          onChange={(e) => setUserMessage(e.target.value)}
        />
        <IconButton
          sx={{ marginLeft: 10 }}
          disabled={loading}
          onClick={() => {
            const assistantChatMessage: OpenAIChat = {
              role: "assistant",
              content: text,
            };
            const userChatMessage: OpenAIChat = {
              role: "user",
              content: userMessage,
            };

            dispatchChat({
              type: "Push",
              chat: assistantChatMessage,
            });
            dispatchChat({
              type: "Push",
              chat: userChatMessage,
            });

            dispatchText({ type: "Set", text: "" });

            setUserMessage("");
            startTalking([
              ...prevMessages,
              assistantChatMessage,
              userChatMessage,
            ]);
          }}
          IconComponent={Send}
        />
      </div>
    </div>
  );
}

// The last word can be an incomplete token so we want to remove it so we don't
// flicker our entry animations
function removeLastWord(str: string): string {
  return str
    .trim()
    .split("\n")
    .map((l, idx, arr) => {
      if (idx === arr.length - 1) {
        const lastWord = /(.*) \w+?$/.exec(l);
        if (lastWord === null) {
          return l;
        }

        return lastWord[1];
      } else {
        return l;
      }
    })
    .join("\n");
}
