import { LocalStorageKeys } from "./constants";

export default function getDefaultEmail(): string | null {
  const qs = new URLSearchParams(window.location.search);

  // if we have an email query string, use it
  const email = qs.get("email");
  if (email !== null) {
    return email;
  }

  try {
    const savedEmail = localStorage.getItem(LocalStorageKeys.email);
    if (savedEmail !== null) {
      return savedEmail;
    }
  } catch (e) {
    console.error("Failed to get email from localStorage", e);
  }

  // Legacy
  try {
    const savedEmail = localStorage.getItem("farther.advisor.email");
    if (savedEmail !== null) {
      return savedEmail;
    }
  } catch (e) {
    console.error("Failed to get email from localStorage", e);
  }

  return null;
}
