import { HypotheticalProposalResponse } from "@fartherfinance/frontend/api/InvestmentModel/requests/postHypotheticalTaxBudget";
import { TaxBudget } from "@fartherfinance/frontend/api/InvestmentModel/Types";
import { PortfolioPartner } from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";
import {
  ClientId,
  FartherAccountId,
  PortfolioId,
  TaxBudgetId,
  TradingGroupId,
} from "@fartherfinance/frontend/api/Types";

import { actions } from "./actions";

export const pageIndices = [0, 1, 2, 3, 4, 5] as const;
export type PageIndex = typeof pageIndices[number];

export const pageIndicesHypotheticalFlow = [0, 1, 2, 3, 4] as const;
export type PageIndexHypotheticalFlow =
  typeof pageIndicesHypotheticalFlow[number];

export type ButtonText = "Continue" | "Confirm and Set Budget" | "Done";

export type ErrorState = "None" | "Invalid Amount" | "UnderMinimum";

export type PageTitle =
  | "Select Year"
  | "Select Accounts"
  | "Enter Budget Amount"
  | "Review Disclosures"
  | "Review Budget Details"
  | "Budget Set"
  | "Select Hypothetical Models"
  | "Tax Analysis";

export type Disclosure = {
  title: DisclosureTitle;
  body: DisclosureBody;
};

type DisclosureBody =
  | "Taxes that have already been accrued will count towards the current year's budget."
  | "If a new account is opened it will automatically be included in this tax budget."
  | "This budget will be used for both short-term and long-term gains taxes."
  | 'Tax budget calculations assume "First In First Out" (FIFO) methodology. If you use a different methodology, you can switch to FIFO for closer alignment with our calculations, or you should expect some differences between target and actual taxes.'
  | "Any capital gains taxes accrued since the start of the year will count towards the current year's budget."
  | "Accounts with asset location turned on are grouped together for trading purposes. As such, they must have the same target model portfolio."
  | null;

type DisclosureTitle =
  | "Disclosure"
  | "Calculation Methodology"
  | "Accounts Are Grouped by Asset Location"
  | null;

interface TaxBudgetFlowData {
  budgetAmount: number;
  budgetId?: TaxBudgetId;
  clientIds: ClientId[];
  clientAccountIds: FartherAccountId[];
  errorMessage: ErrorState;
  excludedVirtualAccountIds: FartherAccountId[];
  hypotheticalTradeGroups: HypotheticalTradeGroup[];
  hypotheticalProposal: HypotheticalProposalResponse | null;
  year?: number;
}

interface FlowState<T> {
  buttonText: ButtonText;
  isContinueDisabled: boolean;
  pageIndex: number;
  pageTitle: PageTitle;
  pageSubTitle: string;
  data: T;
}

//This is something that can be set by a <FlowInit /> component (not the existing one, a new one)
export type FlowType = "Budget" | "Hypothetical";

export interface InitialStateParams {
  flowType: FlowType;
  isAdvisor: boolean;
}

export interface TaxBudgetFlowState extends FlowState<TaxBudgetFlowData> {
  isAdvisor: boolean;
  disclosures: Disclosure[] | null;
  flowType: FlowType;
}

export interface HypotheticalTradeGroup {
  // current model
  tradingGroupId: TradingGroupId;
  modelName: string;
  accountsInPortfolio: string;
  virtualAccountIds: FartherAccountId[];
  // hypothetical/target
  hypotheticalModel: string | null;
  hypotheticalModelId: PortfolioId | null;
  hypotheticalModelType: PortfolioPartner | null;
  isDirectIndexed: boolean;
  isTaxExempt: boolean;
}

export type ErrorMessage = Record<"message", string>;

export type ActionPayload =
  | {
      type: actions.RESET_TO_DEFAULT_DATA;
      payload: undefined;
    }
  | {
      type: actions.SET_PAGE_INDEX;
      payload: number;
    }
  | {
      type: actions.INCREMENT_PAGE_INDEX;
      payload: undefined;
    }
  | {
      type: actions.DECREMENT_PAGE_INDEX;
      payload: undefined;
    }
  | {
      type: actions.SET_YEAR;
      payload: number;
    }
  | {
      type: actions.SET_EXCLUDED_ACCOUNT_IDS;
      payload: FartherAccountId[];
    }
  | {
      type: actions.SET_ACCOUNT_IDS;
      payload: FartherAccountId[];
    }
  | {
      type: actions.SET_BUDGET_AMOUNT;
      payload: number;
    }
  | {
      type: actions.TOGGLE_EXCLUDED_ACCOUNT_ID;
      payload: FartherAccountId;
    }
  | {
      type: actions.SET_TAX_BUDGET_ID;
      payload: TaxBudgetId;
    }
  | {
      type: actions.SET_EXISTING_BUDGET;
      payload: TaxBudget;
    }
  | {
      type: actions.SET_BLANK_BUDGET_FOR_YEAR;
      payload: {
        clientId: ClientId;
        year: number;
      };
    }
  | {
      type: actions.SET_IS_ADVISOR;
      payload: boolean;
    }
  | {
      type: actions.SET_CLIENT_IDS;
      payload: ClientId[];
    }
  | {
      type: actions.SET_HYPOTHETICAL_TRADEGROUPS;
      payload: HypotheticalTradeGroup[];
    }
  | {
      type: actions.SET_HYPOTHETICAL_PROPOSAL;
      payload: HypotheticalProposalResponse;
    };
