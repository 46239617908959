import React from "react";

import { range } from "lodash";

import Chip from "@src/sharedComponents/Chip/Chip";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import FullDataTable from "@src/sharedComponents/Table/FullDataTable";

import { tableKeys } from "./PortfoliosTable";

const NUM_ROWS = 10;

const LOADING_FILLER = range(NUM_ROWS).map((i) => {
  return {
    key: `${i}`,
    Name: { value: <Skeleton /> },
    "Model Type": { value: <Skeleton /> },
    "Tax Type": { value: <Skeleton /> },
    "Accounts Applied": { value: <Skeleton /> },
    Created: { value: <Skeleton /> },
  };
});

const PortfoliosTableLoading = (): JSX.Element => {
  return (
    <FullDataTable
      isLoading={true}
      columns={tableKeys}
      rows={LOADING_FILLER}
      defaultRowsPerPage={NUM_ROWS}
      defaultSortColumn={undefined}
      disableColumnSorting={tableKeys}
      emptyCell={<Chip label="filler" />}
    />
  );
};

export default PortfoliosTableLoading;
