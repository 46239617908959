import React from "react";

import Box from "@mui/material/Box";

import { TableCell } from "./components/TableCell/TableCell.component";
import { TableHeader } from "./components/TableHeader/TableHeader.component";
import { TableRow } from "./components/TableRow/TableRow.component";
import { TableContext } from "./Context";
import { useStyles } from "./Table.styles";

import type {
  TableCellProps,
  TableHeaderProps,
  TableRowProps,
} from "./components";

interface TableComposition {
  Header: React.FC<TableHeaderProps>;
  Row: React.FC<TableRowProps>;
  Cell: React.FC<TableCellProps>;
}

interface TableProps {
  children: React.ReactNode;
  className?: string;
  columnsCount: number;
}

/* eslint-disable react/prop-types  */
export const Table: React.FC<TableProps> & TableComposition = ({
  children,
  className,
  columnsCount,
}) => {
  const { classes, cx } = useStyles({ columnsCount });

  return (
    <TableContext.Provider value={{ columnsCount }}>
      <Box className={cx(classes.gridContainer, className)}>{children}</Box>
    </TableContext.Provider>
  );
};

// NOTE: compound components
Table.Header = TableHeader;
Table.Row = TableRow;
Table.Cell = TableCell;
