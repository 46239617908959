import React from "react";

import { actions } from "../reducer/actions";
import { useAddCollaboratorContext } from "../reducer/Context";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import ActionRow from "@src/sharedComponents/ActionRow/ActionRow";
import { pluralize } from "@src/utils/pluralize";

const AccessReviewRow: React.FC = () => {
  const {
    state: {
      data: { accessLevel, resources },
    },
    dispatch,
  } = useAddCollaboratorContext();

  const goBackToAccessLevelPage = () =>
    dispatch({ type: actions.SET_PAGE_INDEX, payload: 1 });

  if (accessLevel === undefined) {
    goBackToAccessLevelPage();
  }

  const limitedAccessText = `${
    accessLevel ?? "No access level set..."
  } (${pluralize(resources.length, "Resource", true)})`;

  return (
    <ActionRow
      start={"Access"}
      end={accessLevel === "Limited Access" ? limitedAccessText : accessLevel}
      action={
        <Button
          text="Edit"
          variant="text"
          buttonType="primary"
          onClick={goBackToAccessLevelPage}
        />
      }
    />
  );
};

export default AccessReviewRow;
