import React from "react";

import { trackEvent } from "@src/multiCustodian/services/tracking";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import IsHiddenIcon from "./IsHiddenIcon";
import NavIcon from "./NavIcon";
import { NavItemDetails } from "./Types";

import styles from "./NavItem.module.css";

type Props = Omit<NavItemDetails, "isDisabled">;

const NavItemToOutsideLink: React.FC<Props> = ({
  iconName,
  text,
  path,
  isHidden,
  mixpanelEvent,
}) => {
  const onTrackEvent = () => {
    if (mixpanelEvent) {
      trackEvent(mixpanelEvent);
    }
  };

  return (
    <div className={styles.container}>
      <a
        className={toClassName(styles.navItem, styles.navItemHover)}
        href={path}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onTrackEvent}
      >
        <div className={styles.flexDiv}>
          {iconName && (
            <div className={styles.icon}>
              <NavIcon iconName={iconName} />
            </div>
          )}

          <div className={styles.text}>{text}</div>
        </div>

        <IsHiddenIcon isHidden={isHidden} />
      </a>
    </div>
  );
};

export default NavItemToOutsideLink;
