import React from "react";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./EquivalentSecuritiesDrawer.module.css";

interface Props {
  ticker: string;
  description: string;
}

const EquivalentSecuritiesDrawerTableRow: React.FC<Props> = ({
  ticker,
  description,
}) => {
  return (
    <div className={styles.row}>
      <div className={toClassName(styles.rowCol1, styles.text)}>{ticker}</div>

      <div className={styles.textSubtle}>{description}</div>
    </div>
  );
};

export default EquivalentSecuritiesDrawerTableRow;
