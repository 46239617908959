import React, { useEffect, useState } from "react";

import AssignmentIcon from "@mui/icons-material/Assignment";
import Typography from "@mui/material/Typography";
import { useHistory, useParams } from "react-router-dom";

import { AltAssetOfferingType } from "@fartherfinance/frontend/api/AltAssetsService/Types";
import useClientProfile from "@fartherfinance/frontend/api/Entity/hooks/useClientProfile";
import useUpdateClient from "@fartherfinance/frontend/api/Entity/hooks/useUpdateClient";

import { AltAssetsMarketplacePath } from "@src/config/routing/RouterPaths";
import { altAssetOfferingTypes } from "@src/multiCustodian/components/Marketplace/constants";
import OfferingTypeList from "@src/multiCustodian/components/Marketplace/OfferingTypeList/OfferingTypeList";
import { MarketplaceClientPathParams } from "@src/multiCustodian/components/Marketplace/types";
import ViewAllButton from "@src/multiCustodian/components/Marketplace/ViewAllButton/ViewAllButton";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

import AccreditedInvestorModal from "./AccreditedInvestorModal/AccreditedInvestorModal";

import styles from "./AltAssetsMarketplace.module.css";

/** NOTE:
 * isAccreditedInvestor === null, user has not yet answered the accredited investor question
 * isAccreditedInvestor === true, user is an accredited investor
 * isAccreditedInvestor === false, user isn't an accredited investor
 */
const AltAssetsMarketplace: React.FC = () => {
  const { clientId } = useParams<MarketplaceClientPathParams>();
  const history = useHistory();
  const [isAccreditedInvestorModalOpen, setIsAccreditedInvestorModalOpen] =
    useState(false);
  const [isMutating, setIsMutating] = useState(false);

  const auth = useRequestAuth();
  const clientProfile = useClientProfile(clientId, auth);
  const callUpdateClient = useUpdateClient(clientId, auth);
  const notificationStatus = useStatusNotification();

  const { isAccreditedInvestor } = clientProfile.data ?? {};

  useEffect(() => {
    if (isAccreditedInvestor === null) {
      setIsAccreditedInvestorModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIsAccreditedInvestor = async (
    isAccreditedInvestor: boolean
  ): Promise<void> => {
    try {
      setIsMutating(true);

      await callUpdateClient({ isAccreditedInvestor });
    } catch (_e) {
      notificationStatus(
        "Failed to update accredited investor status",
        "Error"
      );
    } finally {
      setIsMutating(false);
      setIsAccreditedInvestorModalOpen(false);
    }
  };

  const handleRedirectToOfferingsList = (
    offeringType: AltAssetOfferingType | "all"
  ): void => {
    history.push(
      `/Client/${clientId}/${AltAssetsMarketplacePath}/${offeringType}`
    );
  };

  return (
    <div className={styles.container}>
      {isMutating && <LogoLoadingStill onTop />}
      {isAccreditedInvestorModalOpen && (
        <AccreditedInvestorModal
          onConfirm={() => handleIsAccreditedInvestor(true)}
          onDecline={() => handleIsAccreditedInvestor(false)}
          onClose={() => setIsAccreditedInvestorModalOpen(false)}
        />
      )}

      {(isAccreditedInvestor === null || isAccreditedInvestor === false) && (
        <div className={styles.accessDenied}>
          <Typography className={styles.info}>
            You must be an accredited investor to view this content and our
            records indicate that you are not.
          </Typography>

          <Button
            variant="contained"
            buttonType="primary"
            text="Update Investor Status"
            onClick={() => setIsAccreditedInvestorModalOpen(true)}
          />
        </div>
      )}

      {isAccreditedInvestor === true && (
        <div className={styles.content}>
          <ViewAllButton onClick={() => handleRedirectToOfferingsList("all")} />

          <OfferingTypeList
            offeringTypes={altAssetOfferingTypes}
            onOfferingTypeClick={handleRedirectToOfferingsList}
          />

          <div
            className={styles.investorStatusContainer}
            onClick={() => setIsAccreditedInvestorModalOpen(true)}
          >
            <div className={styles.investorStatus}>
              <Typography className={styles.investorStatusText}>
                Update Investor Status
              </Typography>
              <AssignmentIcon className={styles.investorStatusIcon} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AltAssetsMarketplace;
