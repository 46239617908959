import React, { useCallback, useEffect, useMemo } from "react";

import { useHistory, useParams } from "react-router-dom";

import useClientAltAssets from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAltAssets";
import { AltAssetId, ClientId } from "@fartherfinance/frontend/api/Types";

import Actions from "../:accountId/Settings";
import { AccountsPath } from "@src/config/routing/RouterPaths";
import RenameAccountForm from "@src/multiCustodian/components/Client/Accounts/Manual/ActionsForms/RenameAccountForm";
import RenameAccountFormLoading from "@src/multiCustodian/components/Client/Accounts/Manual/ActionsForms/RenameAccountFormLoading";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { captureException } from "@src/multiCustodian/services/tracking";
import Modal from "@src/sharedComponents/Modal/Modal";

const RenameAccount = (): JSX.Element => {
  const { clientId, accountId } = useParams<{
    clientId: ClientId;
    accountId: AltAssetId;
  }>();

  const history = useHistory();

  const statusNotification = useStatusNotification();

  const closeModal = useCallback(() => {
    history.push(
      `/Client/${clientId}/${AccountsPath}/Manual/${accountId}/Settings`
    );
  }, [accountId, clientId, history]);

  const auth = useRequestAuth();

  const manualAssets = useClientAltAssets(clientId, auth);

  const currentAssetName = useMemo(() => {
    if (manualAssets.isLoading || manualAssets.hasError) {
      return manualAssets;
    }

    const currentAsset = manualAssets.data.find((a) => a.alt.id === accountId);

    if (currentAsset === undefined) {
      const e = new Error(
        `Manual Account with the ID ${accountId} was not found in ${useClientAltAssets.name}`
      );

      captureException(e);

      throw e;
    }

    return {
      ...manualAssets,
      data: currentAsset.alt.assetName,
    };
  }, [accountId, manualAssets]);

  useEffect(() => {
    if (currentAssetName.hasError) {
      statusNotification("Error getting current account name", "Error");
      closeModal();
    }
  }, [closeModal, currentAssetName.hasError, statusNotification]);

  return (
    <Actions>
      <Modal closeModal={closeModal} modalStyle={{ width: "460px" }}>
        {currentAssetName.isLoading ? (
          <RenameAccountFormLoading closeModal={closeModal} />
        ) : currentAssetName.hasError ? null : (
          <RenameAccountForm
            currentAssetName={currentAssetName.data}
            closeModal={closeModal}
          />
        )}
      </Modal>
    </Actions>
  );
};

export default RenameAccount;
