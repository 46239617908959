import React, { useEffect, useState } from "react";

import { Fade, Stack, Typography } from "@mui/material";
import { format, isPast } from "date-fns";
import { head, isNull, isNumber, isUndefined, orderBy } from "lodash";
import { stringifyUrl } from "query-string";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import useGetProposal from "@fartherfinance/frontend/api/Rebalance/hooks/useGetProposal";
import useGetProposalPostTrade from "@fartherfinance/frontend/api/Rebalance/hooks/useGetProposalPostTrade";
import useGetProposalsV4 from "@fartherfinance/frontend/api/Rebalance/hooks/useGetProposalsV4";
import usePatchProposal from "@fartherfinance/frontend/api/Rebalance/hooks/usePatchProposal";
import { ProposalInternalStatus } from "@fartherfinance/frontend/api/Rebalance/Types";
import { ProposalId } from "@fartherfinance/frontend/api/Types";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import { arrayToCsv, PLACEHOLDER_SIGN } from "@src/yellowstone/modules/shared";

import {
  ProposalActions,
  ProposalAlerts,
  ProposalHistoricalTrades,
  ProposalMetadata,
  ProposalNavigation,
  ProposalPostTradePortfolio,
  ProposalProposedTrades,
} from "./components";
import {
  POST_TRADE_PORTFOLIO_HEADERS,
  TARGET_PORTFOLIO_HEADERS,
} from "./components/ProposalPostTradePortfolio/ProposalPostTradePortfolio.const";
import { PROPOSED_TRADES_LIST_HEADERS } from "./components/ProposalProposedTrades/ProposalProposedTrades.const";
import { useStyles } from "./ProposalDetails.styles";
import {
  findNextProposalId,
  generateNextPageQueryParams,
  parseLocationState,
} from "./ProposalDetails.utils";

interface ProposalDetailsProps {
  currentProposalId: ProposalId;
}

export const ProposalDetails: React.FC<ProposalDetailsProps> = ({
  currentProposalId,
}) => {
  const { classes } = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [isMutating, setIsMutating] = useState(false);

  const parsedLocationState = parseLocationState(location.state);
  const locationStateQueryParams = parsedLocationState?.queryParams ?? null;
  const from = parsedLocationState?.from ?? null;

  const [queryParams, setQueryParams] = useState(locationStateQueryParams);
  const [isNextPageQueryEnabled, setIsNextPageQueryEnabled] = useState(false);

  const auth = useAdvisorRequestAuth();
  const statusNotification = useStatusNotification();

  /**
   * Proposals
   */
  const currentPageProposalsQuery = useGetProposalsV4(
    queryParams,
    auth,
    false,
    {
      cacheTime: 0,
    }
  );
  const nextPageProposalsQuery = useGetProposalsV4(
    generateNextPageQueryParams(queryParams),
    isNextPageQueryEnabled ? auth : null,
    false,
    {
      cacheTime: 0,
    }
  );

  const currentPageProposals = currentPageProposalsQuery.data?.values ?? [];
  const nextPageProposals = nextPageProposalsQuery.data?.values ?? [];

  const isProposalsCurrentPageEmpty = currentPageProposals.length === 0;
  const isProposalOnCurrentPage = Boolean(
    currentPageProposals.find(
      ({ proposalId }) => proposalId === currentProposalId
    )
  );

  const { nextProposalId, shouldFetchNextPage } = findNextProposalId(
    currentPageProposals,
    nextPageProposals,
    currentProposalId
  );

  /**
   * Proposal details
   */
  const proposalDetailsQuery = useGetProposal(currentProposalId, auth);
  const proposalPostTradeQuery = useGetProposalPostTrade(
    currentProposalId,
    auth
  );
  const proposalMutation = usePatchProposal(queryParams, auth);

  const { proposal, proposedTrades, activeLiquidations, isConfirmable } =
    proposalDetailsQuery.data?.proposalDetails ?? {};
  const { aggregatedItems = [], total } = proposalPostTradeQuery.data ?? {};

  const isDirectlyAccessed = isUndefined(queryParams);
  const isProposalErrored = proposalDetailsQuery.hasError;
  const isHistoricalProposal = proposal
    ? isPast(new Date(proposal.createdOn))
    : false;
  const isNextTradeButtonVisible = isDirectlyAccessed === false;
  const isNextTradeButtonDisabled = isNull(nextProposalId);
  const isCsvDownloadButtonVisible =
    !proposalDetailsQuery.hasError || !proposalPostTradeQuery.hasError;

  useEffect(() => {
    if (!isProposalsCurrentPageEmpty && !isProposalOnCurrentPage) {
      setQueryParams((prevQueryParams) =>
        generateNextPageQueryParams(prevQueryParams)
      );
    }
  }, [isProposalsCurrentPageEmpty, isProposalOnCurrentPage]);

  useEffect(() => {
    setIsNextPageQueryEnabled(shouldFetchNextPage);
  }, [shouldFetchNextPage]);

  useEffect(() => {
    window.scrollTo({ left: 0, top: 0 });
  }, []);

  const handleNavigationToProposalsList = (): void => {
    navigate(
      stringifyUrl(
        {
          url:
            from ??
            "/Advisor/Client-Ops/Dashboard/Daily-Trading/Daily-Proposals",
          query: queryParams ?? undefined,
        },
        { encode: false, arrayFormat: "bracket-separator" }
      )
    );
  };

  const handleNavigationToNextProposal = (): void => {
    if (nextProposalId) {
      navigate(
        `/Advisor/Client-Ops/Dashboard/Daily-Trading/Proposal-Details/${nextProposalId}`,
        {
          state: { queryParams, from },
        }
      );
    }
  };

  const handleProposalAction =
    (internalProposalStatus: ProposalInternalStatus) =>
    async (): Promise<void> => {
      if (proposal) {
        try {
          setIsMutating(true);
          await proposalMutation({
            proposalId: proposal.proposalId,
            request: { internalProposalStatus },
          });
        } catch (_error) {
          statusNotification(
            `Failed to update proposal ${proposal.proposalId}.`,
            "Error"
          );
        } finally {
          setIsMutating(false);
        }
      }
    };

  const handlePrepareCsvBlobs = (): Blob[] => {
    const proposedTradesHeaders = PROPOSED_TRADES_LIST_HEADERS.slice(1);
    const postTradePortfolioHeaders = [
      ...POST_TRADE_PORTFOLIO_HEADERS,
      "",
      ...TARGET_PORTFOLIO_HEADERS,
    ];
    const csvMimeType = "text/csv;charset=utf-8;";
    const csvBlobs: Blob[] = [];

    // Proposed Trades
    if (proposedTrades && proposedTrades.trades.length > 0) {
      const sortedTrades = orderBy(
        proposedTrades.trades,
        [(trade) => trade.ticker, (trade) => trade.account.custodianAccountId],
        ["asc", "asc"]
      );
      const proposedTradesData = sortedTrades.map((trade) => [
        trade.account.custodianAccountId ?? PLACEHOLDER_SIGN,
        trade.ticker ?? PLACEHOLDER_SIGN,
        trade.side,
        trade.quantity,
        isNumber(trade.price) ? trade.price : PLACEHOLDER_SIGN,
        isNumber(trade.marketValue) ? trade.marketValue : PLACEHOLDER_SIGN,
      ]);
      const proposedTradesCsv = arrayToCsv([
        proposedTradesHeaders,
        ...proposedTradesData,
      ]);
      const proposedTradesBlob = new Blob([proposedTradesCsv], {
        type: csvMimeType,
      });

      csvBlobs.push(proposedTradesBlob);
    }

    // Post Trade Portfolio
    if (aggregatedItems.length > 0) {
      const postTradePortfolioData = aggregatedItems.map(
        (postTradeAggregate) => {
          const isMultiple = postTradeAggregate.accountItems.length > 1;
          const account = isMultiple
            ? "Subtotal"
            : head(postTradeAggregate.accountItems)?.account
                .custodianAccountId ?? PLACEHOLDER_SIGN;

          return [
            [
              account,
              postTradeAggregate.ticker ?? PLACEHOLDER_SIGN,
              postTradeAggregate.subtotal.totalQuantity,
              isNumber(postTradeAggregate.subtotal.price)
                ? postTradeAggregate.subtotal.price
                : PLACEHOLDER_SIGN,
              isNumber(postTradeAggregate.subtotal.totalMarketValue)
                ? postTradeAggregate.subtotal.totalMarketValue
                : PLACEHOLDER_SIGN,
              isNumber(postTradeAggregate.subtotal.weight)
                ? postTradeAggregate.subtotal.weight
                : PLACEHOLDER_SIGN,
              "",
              postTradeAggregate.subtotal.targetWeight,
              isNumber(postTradeAggregate.subtotal.targetWeightDifference)
                ? postTradeAggregate.subtotal.targetWeightDifference
                : PLACEHOLDER_SIGN,
            ],
            ...(isMultiple
              ? postTradeAggregate.accountItems.map((item) => [
                  item.account.custodianAccountId ?? PLACEHOLDER_SIGN,
                  postTradeAggregate.ticker ?? PLACEHOLDER_SIGN,
                  item.totalQuantity,
                  isNumber(item.price) ? item.price : PLACEHOLDER_SIGN,
                  isNumber(item.totalMarketValue)
                    ? item.totalMarketValue
                    : PLACEHOLDER_SIGN,
                  isNumber(item.weight) ? item.weight : PLACEHOLDER_SIGN,
                  "",
                  PLACEHOLDER_SIGN,
                  PLACEHOLDER_SIGN,
                ])
              : []),
          ];
        }
      );
      const postTradePortfolioCsv = arrayToCsv([
        postTradePortfolioHeaders,
        ...postTradePortfolioData.flat(),
      ]);
      const postTradePortfolioBlob = new Blob([postTradePortfolioCsv], {
        type: csvMimeType,
      });

      csvBlobs.push(postTradePortfolioBlob);
    }

    return csvBlobs;
  };

  const handleDownloadCsv = (): void => {
    if (!proposal) {
      return;
    }

    handlePrepareCsvBlobs().forEach((blob, index) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      const clientName = `${proposal.client.name.first}-${proposal.client.name.last}`;
      const date = format(new Date(proposal.createdOn), "MM-dd-yyyy");
      const suffix = index === 0 ? "proposed-trades" : "post-trade-portfolio";
      const fileName = `${clientName}-${date}-${suffix}`;

      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    });
  };

  if (isProposalErrored) {
    return (
      <Fade in>
        <Stack className={classes.container}>
          <ProposalNavigation
            proposalCreatedOn={undefined}
            onReturnToTable={handleNavigationToProposalsList}
            onNextProposal={() => null}
            onCsvDownload={() => null}
            isNextTradeButtonVisible={false}
            isNextTradeButtonDisabled={true}
            isProposalCreationDateHidden={true}
            isCsvDownloadButtonVisible={false}
          />
          <Typography className={classes.error}>
            Something went wrong, please try again later.
          </Typography>
        </Stack>
      </Fade>
    );
  }

  return (
    <Fade in>
      <Stack className={classes.container}>
        {isMutating && <LogoLoadingStill onTop />}

        <ProposalNavigation
          proposalCreatedOn={proposal?.createdOn}
          onReturnToTable={handleNavigationToProposalsList}
          onNextProposal={handleNavigationToNextProposal}
          onCsvDownload={handleDownloadCsv}
          isNextTradeButtonVisible={isNextTradeButtonVisible}
          isNextTradeButtonDisabled={isNextTradeButtonDisabled}
          isCsvDownloadButtonVisible={isCsvDownloadButtonVisible}
        />
        <ProposalMetadata
          proposal={proposal}
          activeLiquidations={activeLiquidations}
          isHistoricalProposal={isHistoricalProposal}
        />
        <ProposalAlerts proposal={proposal} proposedTrades={proposedTrades} />
        {isConfirmable && (
          <ProposalHistoricalTrades
            /* NOTE: key prop makes sure we fully remount the component each time trading group id changes */
            key={proposal?.tradingGroup.groupId}
            tradingGroupId={proposal?.tradingGroup.groupId}
          />
        )}
        <ProposalProposedTrades
          proposedTrades={proposedTrades}
          isLoading={proposalDetailsQuery.isLoading}
          hasError={proposalDetailsQuery.hasError}
        />
        <ProposalPostTradePortfolio
          postTradePortfolioAggregates={aggregatedItems}
          postTradePortfolioTotal={total}
          isLoading={proposalPostTradeQuery.isLoading}
          hasError={proposalPostTradeQuery.hasError}
        />
        <ProposalActions
          isProposalConfirmable={Boolean(isConfirmable)}
          isLoading={isMutating || proposalDetailsQuery.isLoading}
          onSkip={handleProposalAction(
            ProposalInternalStatus.enum.SKIP_PROPOSAL
          )}
          onNeedsReview={handleProposalAction(
            ProposalInternalStatus.enum.NEEDS_REVIEW
          )}
          onConfirm={handleProposalAction(ProposalInternalStatus.enum.CONFIRM)}
        />
      </Stack>
    </Fade>
  );
};
