import React, { useMemo } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Checkbox,
  createTheme,
  FormControl,
  FormLabel,
  Input,
  ListItemText,
  MenuItem,
  ThemeProvider,
} from "@mui/material";
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select";
import { SxProps, Theme } from "@mui/system";

import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import { fontSize } from "../constants";

export interface Props<T extends string>
  extends Omit<SelectProps<T[]>, "style"> {
  values: readonly T[];
  value: T[] | undefined;
  onChange: (event: SelectChangeEvent<T[]>) => void;
  formLabel?: string;
  style?: SxProps<Theme>;
  error?: boolean;
  helperText?: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    sx: {
      "& .MuiMenu-list": {
        paddingBottom: "0px",
      },
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  autoFocus: false, //This ensures that we don't scroll down when the menu is opened, even if items are
};

export default function DropdownMultiSelect<T extends string>({
  style,
  value = [],
  values = [],
  onChange,
  formLabel,
  error,
  helperText,
  ...props
}: Props<T>): JSX.Element {
  const {
    color: {
      $text,
      $textSubtle,
      $textDisabled,
      $textDanger,
      $icon,
      $iconDisabled,
      $iconSubtle,
      $borderBold,
      $backgroundNeutralSubtle,
    },
  } = useTheme();

  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: "var(--fakt)",
          fontSize: fontSize,
        },
        palette: {
          text: {
            primary: $text,
            secondary: $textSubtle, // Placeholder
            disabled: $textDisabled,
          },
          action: {
            active: $icon, // Chevron and X
            disabled: $iconDisabled,
          },
          background: {
            paper: $backgroundNeutralSubtle, // Dropdown Menu Background
          },
          primary: { main: $text },
        },
        components: {
          MuiInput: {},
          MuiCheckbox: {
            styleOverrides: {},
          },
        },
      }),
    [
      $text,
      $textSubtle,
      $textDisabled,
      $icon,
      $iconDisabled,
      $backgroundNeutralSubtle,
    ]
  );

  const sx: SxProps<typeof theme> = useMemo(
    () => ({
      ":before": { borderBottomColor: $borderBold },
      ":after": { borderBottomColor: $borderBold },
      marginTop: "0px !important",
      "& .MuiSvgIcon-root": {
        color: $iconSubtle,
        right: 0,
      },
    }),
    [$borderBold, $iconSubtle]
  );

  return (
    <ThemeProvider theme={theme}>
      <FormControl fullWidth={true} sx={{ marginBottom: "30px", ...style }}>
        {formLabel && (
          <FormLabel sx={{ fontSize: "14px", fontWeight: 600 }} focused={false}>
            {formLabel}
          </FormLabel>
        )}

        <Select
          {...props}
          sx={{
            ...sx,
            ...props.sx,
          }}
          multiple
          value={value}
          onChange={onChange}
          input={<Input />}
          renderValue={(selected) =>
            selected.length === 0
              ? "None Selected"
              : `${selected.length} Selected`
          }
          displayEmpty
          MenuProps={MenuProps}
          SelectDisplayProps={{ style: { fontSize: fontSize } }}
          IconComponent={ExpandMoreIcon}
        >
          {values.map((element) => (
            <MenuItem key={element} value={element}>
              <Checkbox checked={value.indexOf(element) > -1} />
              <ListItemText
                primaryTypographyProps={{
                  style: { fontSize: fontSize, whiteSpace: "pre-line" },
                }}
                primary={element}
              />
            </MenuItem>
          ))}
        </Select>

        {error === true ? (
          <div style={{ color: $textDanger, fontSize: 16 }}>{helperText}</div>
        ) : null}
      </FormControl>
    </ThemeProvider>
  );
}
