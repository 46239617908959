import { CheckboxListConfig } from "@src/sharedComponents/ScrollableCheckboxList/ScrollableCheckboxList";

// NOTE: this list is temporarily hardcoded until we see the need for a backend endpoint to fetch the list instead

const objectivesFilters = [
  "Diversify Risk",
  "Enhance Returns",
  "Generate Income",
  "Infrastructure",
  "Long-term Capital Appreciation",
  "Objective Rating",
  "Preserve Capital",
  "Supplement Income",
  "Superior risk-adjusted returns",
];

const strategyFilters = [
  "Aerospace & Defense",
  "Artwork",
  "Asset-Backed Lending",
  "Basic Material",
  "Buyout",
  "Buyout & Growth Equity",
  "Buyout, Portofolio",
  "Capital Protection",
  "Coinvestment",
  "Computer Storage & Peripherals",
  "Credit",
  "Cryptocurrency",
  "Digital Assets",
  "Direct",
  "Direct Lending",
  "Distressed Debt",
  "E-Commerce",
  "Early Stage",
  "Energy Storage",
  "Equity",
  "Equity Hedge",
  "Event-Driven",
  "FinTech",
  "Fund of Funds",
  "GP Stakes",
  "Gaming",
  "Growth",
  "Growth Equity",
  "Infrastructure",
  "Internet Software & Services",
  "Late Stage",
  "Machinery & Equipment",
  "Macro",
  "Meat, Fish, Seafood & Alternative Proteins",
  "Medical Facilities & Services",
  "Mezzanine",
  "Mobile Commerce",
  "Mobile Software & Services",
  "Multi-Stage",
  "Multi-Strategy",
  "Network-Powered Venture Capital",
  "Non-Alcoholic Beverages",
  "Opportunistic",
  "Real Estate",
  "Real Estate Lending",
  "Relative Value",
  "Renewables",
  "Secondaries",
  "Seed-Early Stage",
  "Seed-Late Stage",
  "Servers & Mainframes",
  "Special Situations",
  "Specialty Computer Hardware",
  "Supercomputers",
  "Telecom Devices & Equipment",
  "Venture Capital",
  "Wine and Spirits",
];

const subStrategyFilters = [
  "3D Printing",
  "Activist",
  "Activist Equity",
  "Aerospace & Defense",
  "Artificial Intelligence",
  "Clean Tech",
  "Consumer",
  "Core",
  "Core Plus",
  "Corporate Credit",
  "Cybersecurity",
  "Data",
  "Debt",
  "Digital Assets",
  "Discretionary",
  "Discretionary Thematic",
  "Distressed Restructuring",
  "Diversified",
  "E-Commerce",
  "Equity Market Neutral",
  "FinTech",
  "Fitness Wearables",
  "Fixed Income - Asset Backed",
  "Fixed Income - Sovereign",
  "Food & Beverage",
  "Fundamental Growth",
  "Fusion Energy",
  "Gaming",
  "Generalist",
  "HR Tech",
  "HealthTech",
  "Internet",
  "Large & Mide-Cap",
  "Logistics Tech",
  "Marketing Software",
  "Merger Arbitrage",
  "Multi-Asset",
  "Multi-Manager",
  "Multi-PM",
  "Multi-Strategy",
  "Opportunistic",
  "Opportunistic Credit",
  "Productivty Software",
  "Quantitative",
  "Quantitative Directional",
  "Quantum Computing",
  "Secondaries",
  "Software (RPA)",
  "Special Situations",
  "Specialist",
  "Systemic",
];

const minimumInvestmentFilters = [
  "$0",
  "$1,000",
  "$2,500",
  "$5,000",
  "$10,000",
  "$15,000",
  "$25,000",
  "$50,000",
  "$100,000",
  "$125,000",
  "$150,000",
  "$250,000",
  "$500,000",
  "$1,000,000",
  "$3,000,000",
];

const investorQualificationFilters = [
  "Accredited Investor",
  "No Minimum Qualification",
  "Non-Accredited",
  "Qualified Client",
  "Qualified Purchaser",
  "Sub-Accredited",
];

const liquidityFilters = ["Monthly", "Quarterly", "Semi-annually"];

const subscriptionsFilters = ["Monthly", "Per Fund's Closing Schedule"];

const lockUpFilters = ["No Lock-Up", "1 Year Soft Lock", "4 Years"];

// NOTE: Alt assets have a flat filtering structure so this reduces the boilerplate substantially
const createDefaultFilter = (filter: string[]) => {
  return filter.reduce((acc, curr) => {
    const hasTopBorder = Object.keys(acc).length === 0;

    acc[curr] = {
      isParent: false,
      checked: true,
      hasTopBorder,
      children: null,
    };
    return acc;
  }, {} as CheckboxListConfig<string>);
};

export const objectivesFilterDefault = createDefaultFilter(objectivesFilters);

export const strategyFilterDefault = createDefaultFilter(strategyFilters);

export const subStrategyFilterDefault = createDefaultFilter(subStrategyFilters);

export const minimumInvestmentFilterDefault = createDefaultFilter(
  minimumInvestmentFilters
);

export const investorQualificationFilterDefault = createDefaultFilter(
  investorQualificationFilters
);

export const liquidityFilterDefault = createDefaultFilter(liquidityFilters);

export const subcriptionsFilterDefault =
  createDefaultFilter(subscriptionsFilters);

export const lockUpFilterDefault = createDefaultFilter(lockUpFilters);
