import { actions } from "./actions";
import { initialState } from "./initialState";
import {
  ActionPayload,
  FlowType,
  PageIndex,
  PageIndexHypotheticalFlow,
  pageIndices,
  pageIndicesHypotheticalFlow,
  TaxBudgetFlowState,
} from "./types";
import {
  advisorDisclosureIndexMap,
  determineButtonText,
  disclosureIndexMap,
  hypotheticalAdvisorDisclosureIndexMap,
  hypotheticalDisclosureIndexMap,
  isContinueButtonDisabled,
  pageIndexMap,
  pageIndexMapForHypothetical,
} from "./utils";

const getPageIndices = (flowType: FlowType) => {
  return flowType === "Budget" ? pageIndices : pageIndicesHypotheticalFlow;
};

const isPageIndexInRange = (index: number | PageIndex): index is PageIndex => {
  return index >= 0 && index <= pageIndices.length - 1;
};

const getDisclosures = (
  flowType: FlowType,
  isAdvisor: boolean,
  pageIndex: number
) => {
  if (flowType === "Hypothetical" && pageIndex === 4) {
    return null;
  }

  if (flowType === "Budget") {
    return isAdvisor
      ? advisorDisclosureIndexMap[pageIndex as PageIndex]
      : disclosureIndexMap[pageIndex as PageIndex];
  }

  return isAdvisor
    ? hypotheticalAdvisorDisclosureIndexMap[
        pageIndex as PageIndexHypotheticalFlow
      ]
    : hypotheticalDisclosureIndexMap[pageIndex as PageIndexHypotheticalFlow];
};

function reducer(
  state: TaxBudgetFlowState,
  action: ActionPayload
): TaxBudgetFlowState {
  const { type, payload } = action;

  switch (type) {
    case actions.RESET_TO_DEFAULT_DATA: {
      return {
        ...initialState({
          flowType: state.flowType,
          isAdvisor: state.isAdvisor,
        }),
      };
    }

    case actions.SET_PAGE_INDEX: {
      const pageIndex = payload;

      const indices = getPageIndices(state.flowType);
      const firstIndex = indices[0];
      const lastIndex = indices[indices.length - 1];

      // ensure the incoming pageIndex is not beyond or below our indexing bounds...
      if (pageIndex > lastIndex || pageIndex < firstIndex) {
        return state;
      }

      if (!isPageIndexInRange(pageIndex)) {
        throw new Error(`pageIndex: ${pageIndex}, is out of range`);
      }

      const pageTitle =
        state.flowType === "Budget"
          ? pageIndexMap[pageIndex as PageIndex]
          : pageIndexMapForHypothetical[pageIndex as PageIndexHypotheticalFlow];

      const updatedState: TaxBudgetFlowState = {
        ...state,
        pageIndex,
        pageTitle,
        buttonText: determineButtonText(state.flowType, pageIndex),
        disclosures: getDisclosures(state.flowType, state.isAdvisor, pageIndex),
      };

      return {
        ...updatedState,
        isContinueDisabled: isContinueButtonDisabled(updatedState),
      };
    }

    case actions.INCREMENT_PAGE_INDEX: {
      const { pageIndex } = state;

      const indices = getPageIndices(state.flowType);
      const newPageIndex = pageIndex + 1;
      const lastIndex = indices[indices.length - 1];

      // ensure the new pageIndex is not beyond our indexing bounds...
      if (newPageIndex > lastIndex) {
        return state;
      }

      if (!isPageIndexInRange(newPageIndex)) {
        throw new Error(`pageIndex: ${newPageIndex}, is out of range`);
      }

      const pageTitle =
        state.flowType === "Budget"
          ? pageIndexMap[newPageIndex as PageIndex]
          : pageIndexMapForHypothetical[
              newPageIndex as PageIndexHypotheticalFlow
            ];

      const updatedState: TaxBudgetFlowState = {
        ...state,
        pageIndex: newPageIndex,
        pageTitle,
        buttonText: determineButtonText(state.flowType, newPageIndex),
        disclosures: getDisclosures(
          state.flowType,
          state.isAdvisor,
          newPageIndex
        ),
      };

      return {
        ...updatedState,
        isContinueDisabled: isContinueButtonDisabled(updatedState),
      };
    }

    case actions.DECREMENT_PAGE_INDEX: {
      const { flowType, isAdvisor, pageIndex } = state;

      const indices = getPageIndices(state.flowType);
      const newPageIndex = pageIndex - 1;
      const firstIndex = indices[0];

      // ensure the new pageIndex is not below our indexing bounds...
      if (newPageIndex < firstIndex) {
        return state;
      }

      if (!isPageIndexInRange(newPageIndex)) {
        throw new Error(`pageIndex: ${newPageIndex}, is out of range`);
      }

      const pageTitle =
        state.flowType === "Budget"
          ? pageIndexMap[newPageIndex as PageIndex]
          : pageIndexMapForHypothetical[
              newPageIndex as PageIndexHypotheticalFlow
            ];

      const updatedState: TaxBudgetFlowState = {
        ...state,
        pageIndex: newPageIndex,
        pageTitle,
        buttonText: determineButtonText(state.flowType, newPageIndex),
        disclosures: getDisclosures(flowType, isAdvisor, newPageIndex),
      };

      return {
        ...updatedState,
        isContinueDisabled: isContinueButtonDisabled(updatedState),
      };
    }

    case actions.SET_YEAR: {
      const selectedYear = payload;

      return {
        ...state,
        isContinueDisabled: false,
        data: { ...state.data, year: selectedYear },
      };
    }

    case actions.SET_EXCLUDED_ACCOUNT_IDS: {
      const excludedAccounts = payload;

      const updatedState: TaxBudgetFlowState = {
        ...state,
        data: {
          ...state.data,
          excludedVirtualAccountIds: excludedAccounts,
        },
      };

      return {
        ...updatedState,
        isContinueDisabled: isContinueButtonDisabled(updatedState),
      };
    }

    case actions.TOGGLE_EXCLUDED_ACCOUNT_ID: {
      const accountId = payload;

      // ifFound ? remove from list (selected) : add to list (de-select)
      const isFound = state.data.excludedVirtualAccountIds.includes(accountId);

      const updatedList = isFound
        ? state.data.excludedVirtualAccountIds.filter((x) => x !== accountId)
        : [...state.data.excludedVirtualAccountIds, accountId];

      const updatedState = {
        ...state,
        data: {
          ...state.data,
          excludedVirtualAccountIds: updatedList,
        },
      };

      return {
        ...updatedState,
        isContinueDisabled: isContinueButtonDisabled(updatedState),
      };
    }

    case actions.SET_ACCOUNT_IDS: {
      const clientAccountIds = payload;

      const updatedState = {
        ...state,
        data: {
          ...state.data,
          clientAccountIds,
        },
      };

      return {
        ...updatedState,
        isContinueDisabled: isContinueButtonDisabled(updatedState),
      };
    }

    case actions.SET_BUDGET_AMOUNT: {
      const budgetAmount = action.payload;

      const updatedState: TaxBudgetFlowState = {
        ...state,
        data: { ...state.data, budgetAmount: budgetAmount },
      };

      return {
        ...updatedState,
        isContinueDisabled: isContinueButtonDisabled(updatedState),
      };
    }

    case actions.SET_TAX_BUDGET_ID: {
      return {
        ...state,
        data: {
          ...state.data,
          budgetId: payload,
        },
      };
    }

    case actions.SET_EXISTING_BUDGET: {
      const budget = action.payload;

      const updatedState = {
        ...state,
        data: {
          ...state.data,
          budgetId: budget.budgetId,
          budgetAmount: budget.budgetAmount,
          clientIds: budget.clientIds,
          year: budget.year,
          excludedVirtualAccountIds: budget.excludedVirtualAccountIds,
        },
      };

      return {
        ...updatedState,
        isContinueDisabled: isContinueButtonDisabled(updatedState),
      };
    }

    case actions.SET_BLANK_BUDGET_FOR_YEAR: {
      const { clientId, year } = action.payload;

      const updatedState = {
        ...state,
        data: {
          ...state.data,
          budgetId: undefined,
          budgetAmount: 0,
          clientIds: [clientId],
          excludedVirtualAccountIds: [],
          year: year,
        },
      };

      return {
        ...updatedState,
        isContinueDisabled: isContinueButtonDisabled(updatedState),
      };
    }

    case actions.SET_IS_ADVISOR: {
      const isAdvisor = action.payload;

      return {
        ...state,
        isAdvisor,
        disclosures: getDisclosures(state.flowType, isAdvisor, state.pageIndex),
      };
    }

    case actions.SET_CLIENT_IDS: {
      const clientIds = action.payload;

      const updatedState = {
        ...state,
        data: {
          ...state.data,
          clientIds,
        },
      };

      return {
        ...updatedState,
        isContinueDisabled: isContinueButtonDisabled(updatedState),
      };
    }

    case actions.SET_HYPOTHETICAL_TRADEGROUPS: {
      const hypotheticalTradeGroups = action.payload;

      const updatedState = {
        ...state,
        data: {
          ...state.data,
          hypotheticalTradeGroups,
        },
      };

      return {
        ...updatedState,
        isContinueDisabled: isContinueButtonDisabled(updatedState),
      };
    }

    case actions.SET_HYPOTHETICAL_PROPOSAL: {
      const hypotheticalProposal = action.payload;

      const updatedState = {
        ...state,
        data: {
          ...state.data,
          hypotheticalProposal,
        },
      };

      return {
        ...updatedState,
      };
    }

    default:
      return state;
  }
}

export default reducer;
