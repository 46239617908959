import React from "react";

import CheckIcon from "@mui/icons-material/Check";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import OutlinedCircleIcon from "@mui/icons-material/CircleOutlined";
import { Radio } from "@mui/material";
import { useParams } from "react-router-dom";

import { TaxBudget } from "@fartherfinance/frontend/api/InvestmentModel/Types";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import { actions } from "../reducer/actions";
import { useCreateEditTaxBudgetContext } from "../reducer/Context";
import { thisYear } from "../utils/taxYearOptions";
import Chip from "@src/sharedComponents/Chip/Chip";
import toUSD from "@src/utils/toUSD";

import styles from "./YearRow.module.css";

interface Props {
  budget?: TaxBudget;
  year: number;
}

const YearRow: React.FC<Props> = ({ budget, year }) => {
  const { clientId } = useParams<{ clientId: ClientId }>();
  const { state, dispatch } = useCreateEditTaxBudgetContext();

  const {
    data: { year: selectedYear },
  } = state;

  const isSelected = selectedYear === year;

  const isCurrentYear = year === thisYear;

  const handleOnChange = () => {
    if (budget) {
      dispatch({
        type: actions.SET_EXISTING_BUDGET,
        payload: budget,
      });
    } else {
      dispatch({
        type: actions.SET_BLANK_BUDGET_FOR_YEAR,
        payload: {
          clientId,
          year,
        },
      });
    }
  };

  return (
    <div className={styles.container}>
      <Radio
        color="primary"
        checked={isSelected}
        checkedIcon={
          <CheckCircleRoundedIcon
            sx={{
              color: "#39D435",
            }}
          />
        }
        icon={<OutlinedCircleIcon sx={{ color: "#354052" }} />}
        onChange={handleOnChange}
      />
      <span className={styles.text}>{`${year}${
        isCurrentYear ? " (Current Year)" : ""
      }`}</span>
      {budget && (
        <div className={styles.budgetContainer}>
          <Chip
            label="Budget already set"
            icon={
              <CheckIcon
                style={{
                  width: "10px",
                  height: "10px",
                  color: "var(--iconSuccess)",
                }}
              />
            }
            variant="outlined"
          />
          <div className={styles.budget}>
            Budget
            <span className={styles.budgetAmount}>{`${toUSD(
              budget.budgetAmount
            )}`}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default YearRow;
