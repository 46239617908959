import React from "react";

import { useParams } from "react-router-dom";

import { TaxBudget } from "@fartherfinance/frontend/api/InvestmentModel/Types";
import useGetConsumedBudget from "@fartherfinance/frontend/api/Rebalance/hooks/useGetConsumedBudget";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import { REALIZED_CAPITAL_GAINS_TOOLTIP_TEXT } from "../constants";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import TooltipBasic from "@src/sharedComponents/Tooltip/TooltipBasic";
import toUSD from "@src/utils/toUSD";

import styles from "./GainsAndLosses.module.css";

interface Props {
  budget?: TaxBudget;
  ignoreTaxBudget?: boolean;
}

const GainsAndLosses: React.FC<Props> = ({ budget, ignoreTaxBudget }) => {
  const { clientId } = useParams<{ clientId: ClientId }>();
  const advisorAuth = useAdvisorRequestAuth();

  const clientAuth = useRequestAuth();

  const auth = advisorAuth || clientAuth;

  const params = ignoreTaxBudget
    ? { clientId, ignoreTaxBudget: true }
    : { clientId };
  const { data, isLoading, hasError } = useGetConsumedBudget(params, auth);

  if (isLoading) {
    return (
      <div className={toClassName(styles.container, styles.title)}>
        Loading...
      </div>
    );
  }

  if (hasError) {
    return (
      <div className={toClassName(styles.container, styles.title)}>
        Error loading tax consumption
      </div>
    );
  }

  if (!ignoreTaxBudget && data.budgetId !== budget?.budgetId) {
    return (
      <div className={toClassName(styles.container, styles.subTitle)}>
        {`Budget usage data will be available in ${budget?.year || 0}.`}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.flexRow}>
        <div className={styles.title}>Realized Capital Gains</div>

        <TooltipBasic text={REALIZED_CAPITAL_GAINS_TOOLTIP_TEXT} />
      </div>

      <div className={styles.segment}>
        <div className={styles.row}>
          <div className={toClassName(styles.subTitle, styles.column)}>
            Short-Term
          </div>

          <div
            className={toClassName(styles.subTitle, styles.column)}
          >{`${toUSD(
            data.capitalConsequences.shortTermNetGainsLossesDollars
          )}`}</div>
        </div>
      </div>

      <div>
        <div className={styles.row}>
          <div className={toClassName(styles.subTitle, styles.column)}>
            Long-Term
          </div>

          <div
            className={toClassName(styles.subTitle, styles.column)}
          >{`${toUSD(
            data.capitalConsequences.longTermNetGainsLossesDollars
          )}`}</div>
        </div>
      </div>
    </div>
  );
};

export default GainsAndLosses;
