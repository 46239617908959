import React from "react";

const Admin: React.FC = () => (
  <svg
    width="100%"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon">
      <path
        id="Vector"
        d="M7 0.583008L1.75 2.91634V6.41634C1.75 9.65384 3.99 12.6813 7 13.4163C10.01 12.6813 12.25 9.65384 12.25 6.41634V2.91634L7 0.583008Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default Admin;
