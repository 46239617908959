import React, { useEffect, useMemo, useState } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { UNKNOWN_ACCOUNT } from "@fartherfinance/frontend/api/Accounts/utilities/accountUtil";
import useClientAccounts from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAccounts";
import useSetPlaidAccountVisibility from "@fartherfinance/frontend/api/ExternalAccount/hooks/useSetPlaidAccountVisibility";
import {
  ClientId,
  ExternalAccountId,
} from "@fartherfinance/frontend/api/Types";
import { formatExternalAccount } from "@fartherfinance/frontend/formatting/account";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import Modal from "@src/sharedComponents/Modal/Modal";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import { State } from "@src/store";

import styles from "./RemovePlaidAccountModal.module.css";

interface Props {
  closeModal: () => void;
}

const RemovePlaidAccountModal = (props: Props): JSX.Element => {
  const [isMutating, setIsMutating] = useState<boolean>(false);

  const { isAdvisor } = useSelector((state: State) => ({
    isAdvisor: state.main_Reducer.user.isAdvisor,
  }));

  const { clientId, externalAccountId } = useParams<{
    clientId: ClientId;
    externalAccountId: ExternalAccountId;
  }>();

  const statusNotification = useStatusNotification();

  const auth = useRequestAuth();
  const accounts = useClientAccounts(clientId, auth);

  const setPlaidAccountVisibility = useSetPlaidAccountVisibility(auth);

  const removeAccount = async () => {
    if (externalAccountId === null || clientId === null) {
      return;
    }

    try {
      setIsMutating(true);

      await setPlaidAccountVisibility({
        isVisible: false,
        accountId: externalAccountId,
      });

      statusNotification("Account removed", "Success");
    } catch {
      statusNotification("Error removing account", "Error");
    } finally {
      setIsMutating(false);
      props.closeModal();
    }
  };

  const formattedAccountNameToRemove = useMemo(() => {
    if (accounts.isLoading || accounts.hasError) {
      return UNKNOWN_ACCOUNT;
    }

    const accountToRemove = accounts.data?.externalAccounts.find(
      (acct) => acct.accountId === externalAccountId
    );

    if (accountToRemove) {
      return formatExternalAccount(accountToRemove);
    }

    return UNKNOWN_ACCOUNT;
  }, [accounts, externalAccountId]);

  useEffect(() => {
    if (accounts.hasError) {
      statusNotification("Error fetching accounts", "Error");
      props.closeModal();
    }
  }, [props, statusNotification, accounts]);

  useEffect(() => {
    if (isAdvisor) {
      statusNotification(
        "Advisors are not allowed to remove external accounts",
        "Error"
      );
      props.closeModal();
    }
  }, [props, statusNotification, isAdvisor]);

  if (isMutating || accounts.isLoading) {
    return (
      <Modal closeModal={props.closeModal} modalStyle={{ width: "520px" }}>
        <div className={styles.paddingContainer}>
          <Skeleton width={140} className={styles.title} />

          <Skeleton width={"90%"} className={styles.subTitle} />

          <Skeleton width={"100%"} height={100} className={styles.summary} />

          <div className={styles.footer}>
            <div className={styles.modalButtonsDiv}>
              <Skeleton
                width={100}
                height={25}
                className={styles.marginRight}
              />

              <Skeleton width={100} height={25} />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal closeModal={props.closeModal} modalStyle={{ width: "520px" }}>
      <div className={styles.paddingContainer}>
        <p className={styles.title}>Remove External Account</p>

        <p className={styles.subTitle}>
          Are you sure you want to remove your
          <span className={styles.accountToRemove}>
            {formattedAccountNameToRemove}
          </span>
          ?
        </p>

        <p className={styles.summary}>
          Removing your account will immediately hide it from the dashboard. You
          will not be able to view it again unless you re-link your institution
          using Plaid.
        </p>

        <div className={styles.footer}>
          <div className={styles.modalButtonsDiv}>
            <Button
              variant={"outlined"}
              buttonType={"secondary"}
              text={"Cancel"}
              onClick={props.closeModal}
            />

            <Button
              variant={"contained"}
              buttonType={"primary"}
              text={"Confirm"}
              onClick={() => removeAccount()}
              style={{ marginLeft: "15px" }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RemovePlaidAccountModal;
