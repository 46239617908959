import React, { PropsWithChildren, useMemo } from "react";

import { useHistory, useLocation } from "react-router-dom";

import useCustomPortfoliosV1 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useCustomPortfoliosV1";
import useEssentialAllPortfoliosV1 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useEssentialAllPortfoliosV1";
import useTradingGroups from "@fartherfinance/frontend/api/TradingGroups/hooks/useTradingGroups";
import {
  ClientId,
  PortfolioId,
  PortfolioTaxType,
} from "@fartherfinance/frontend/api/Types";

import { FARTHER_MODELS_TYPE_NAME } from "@src/multiCustodian/components/Advisor/Investments/constants";
import Drawer, {
  largeDrawer,
} from "@src/multiCustodian/components/Drawer/Drawer";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import { portfolioLocationState } from "@src/multiCustodian/pages/Client/:clientId/Portfolios";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import Select from "@src/sharedComponents/Select/Select";

import styles from "./PortfolioDrawer.module.css";

type Tab = "Accounts" | "Model Details";

interface Props {
  curTab: Tab;
  clientId: ClientId;
  portfolioId: PortfolioId;
}

const PortfolioDrawer = ({
  clientId,
  portfolioId,
  ...props
}: PropsWithChildren<Props>): JSX.Element => {
  const history = useHistory();
  const { state, search } = useLocation();

  const auth = useRequestAuth();
  const tradingGroups = useTradingGroups(clientId, auth);
  const allEssential = useEssentialAllPortfoliosV1(auth);
  const customPortfoliosV1 = useCustomPortfoliosV1(clientId, auth);

  const [portfolio, modelType, dropdownOptions] = useMemo(() => {
    const portfolioWithFam = [
      ...(allEssential.data ?? []).map((p) => ({
        family: "Essential",
        portfolio: p,
      })),
      ...(customPortfoliosV1.data?.portfolios ?? []).map((p) => ({
        family: "Custom",
        portfolio: p,
      })),
    ].find(({ portfolio }) => portfolio.portfolioModelId === portfolioId);

    const modelType = portfolioWithFam
      ? "set" in portfolioWithFam.portfolio
        ? portfolioWithFam.portfolio.set
        : "Custom"
      : "N/A";

    const portfolio = portfolioWithFam?.portfolio;

    let dropdownOptions: { portfolioId: PortfolioId; name: string }[] = [];
    if (tradingGroups.data && allEssential.data && customPortfoliosV1.data) {
      const essentialPortfolios = allEssential.data
        .filter((fp) =>
          tradingGroups.data.some(
            (tg) => fp.portfolioModelId === tg.portfolioId
          )
        )
        .map((fp) => ({
          portfolioId: fp.portfolioModelId,
          name: fp.displayName,
        }));

      const clientCustomPortfolios = customPortfoliosV1.data.portfolios.map(
        (cp) => ({
          portfolioId: cp.portfolioModelId,
          name: cp.displayName,
        })
      );

      dropdownOptions = [...essentialPortfolios, ...clientCustomPortfolios];
    }

    return [portfolio, modelType, dropdownOptions];
  }, [
    allEssential.data,
    customPortfoliosV1.data,
    tradingGroups.data,
    portfolioId,
  ]);

  if (
    tradingGroups.isLoading ||
    allEssential.isLoading ||
    customPortfoliosV1.isLoading
  ) {
    return (
      <Drawer
        isDrawerOpen
        container_style={largeDrawer}
        onClose={() =>
          history.push({
            pathname: `/Client/${clientId}/Investments/Portfolios`,
            search: search,
            state: { ...(state ?? {}), ...portfolioLocationState },
          })
        }
        transitionDuration={0}
      >
        <div className={styles.loadingContainer}>
          <LogoLoadingStill />
        </div>
      </Drawer>
    );
  }

  if (
    tradingGroups.hasError ||
    allEssential.hasError ||
    customPortfoliosV1.hasError
  ) {
    return (
      <Drawer
        isDrawerOpen
        container_style={largeDrawer}
        onClose={() =>
          history.push({
            pathname: `/Client/${clientId}/Investments/Portfolios`,
            search: search,
            state: { ...(state ?? {}), ...portfolioLocationState },
          })
        }
        transitionDuration={0}
      >
        <div>Error</div>
      </Drawer>
    );
  }

  return (
    <Drawer
      isDrawerOpen
      container_style={largeDrawer}
      header={
        <Select
          value={portfolio?.displayName ?? ""}
          options={dropdownOptions.map((o) => o.name)}
          onChange={(name) => {
            const chosen = dropdownOptions.find((x) => x.name === name);
            if (chosen === undefined) {
              return;
            }

            history.push({
              pathname: `/Client/${clientId}/Investments/Portfolios/${chosen.portfolioId}`,
              search: search,
            });
          }}
          style={{ width: "200px" }}
          renderValue={(value) => value} // required for MUI to match strings
        />
      }
      onClose={() =>
        history.push({
          pathname: `/Client/${clientId}/Investments/Portfolios`,
          search: search,
          state: { ...(state ?? {}), ...portfolioLocationState },
        })
      }
      transitionDuration={0}
    >
      <Header
        name={portfolio?.displayName ?? "N/A"}
        type={modelType ?? "N/A"}
        taxType={portfolio?.taxType}
      />

      <Tabs
        curTab={props.curTab}
        setCurTab={(tab: Tab) => {
          switch (tab) {
            case "Accounts":
              return history.push({
                pathname: `/Client/${clientId}/Investments/Portfolios/${portfolioId}/Accounts`,
                search: search,
              });

            case "Model Details":
              return history.push({
                pathname: `/Client/${clientId}/Investments/Portfolios/${portfolioId}/ModelDetails`,
                search: search,
              });

            default: {
              const _x: never = tab;
            }
          }
        }}
      />

      {props.children}
    </Drawer>
  );
};

export default PortfolioDrawer;

interface HeaderProps {
  name: string;
  type: string;
  taxType: PortfolioTaxType | undefined;
}

const Header = ({ name, type, taxType }: HeaderProps): JSX.Element => {
  return (
    <div className={styles.headerFlexApart}>
      <div className={styles.title}>{name}</div>

      <div className={styles.pillsDiv}>
        {taxType !== undefined && (
          <div className={styles.pill}>
            {taxType === "taxable"
              ? "Taxable"
              : taxType === "tax_exempt"
              ? "Tax-Advantaged"
              : "Any"}
          </div>
        )}

        <div className={styles.pillSpacer} />

        <div className={styles.pill}>
          {type === "FAM" ? FARTHER_MODELS_TYPE_NAME : type}
        </div>
      </div>
    </div>
  );
};

interface TabsProps {
  curTab: Tab;
  setCurTab: (tab: Tab) => void;
}

const Tabs = ({ curTab, setCurTab }: TabsProps): JSX.Element => {
  return (
    <div className={styles.tabsContainer}>
      <p
        className={curTab === "Accounts" ? styles.tabSelected : styles.tab}
        onClick={() => setCurTab("Accounts")}
      >
        Accounts
      </p>

      <p
        className={curTab === "Model Details" ? styles.tabSelected : styles.tab}
        onClick={() => setCurTab("Model Details")}
      >
        Model Details
      </p>
    </div>
  );
};
