import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

import Router from "./config/routing/Router";
import useSyncTheme from "./multiCustodian/hooks/useSyncTheme";
import APMAdvisorIdentify from "./multiCustodian/pages/Advisor/components/APMAdvisorIdentify";
import APMClientIdentify from "./multiCustodian/pages/Dashboard/APMClientIdentify";
import {
  Brand,
  CSSColorPalettes,
  UiTheme,
} from "./multiCustodian/reducers/main_Reducer";
import { MCReduxAction, State } from "./store";

import "./app.css";
import "./global_styles.css";

export default function App() {
  const dispatch = useDispatch<Dispatch<MCReduxAction>>();

  const { brand, cssColorPalettes } = useSelector((state: State) => ({
    brand: state.main_Reducer.brand,
    cssColorPalettes: state.main_Reducer.css_color_palettes,
  }));

  useEffect(() => {
    console.log(
      `(( Glacier | ${process.env.WEBAPP_EXACT_ENV} | v${
        process.env.COMMIT_SHA ?? "Unknown SHA"
      } | ${process.env.COMMIT_DATE ?? "Unknown Date"} ))`
    );
  }, []);

  useEffect(() => {
    // only run on startup
    getSetUiThemeFromCookie(dispatch, brand, cssColorPalettes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSyncTheme();

  return (
    <div id="App_Root_div">
      <Router />

      <APMAdvisorIdentify />
      <APMClientIdentify />
    </div>
  );
}

const getCookie = (key: string) => {
  const name = key + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.startsWith(" ")) {
      c = c.substring(1);
    }
    if (c.startsWith(name)) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

const getSetUiThemeFromCookie = async (
  dispatch: Dispatch<MCReduxAction>,
  brandFromRedux: Brand,
  cssColorPalettesFromRedux: CSSColorPalettes
): Promise<void> => {
  const uiThemeFromCookie = getCookie("farther_ui_theme").toLowerCase().trim();

  const uiTheme: UiTheme =
    uiThemeFromCookie === "farther_dark" ||
    uiThemeFromCookie === "farther_light"
      ? uiThemeFromCookie
      : "farther_dark";

  const brand: Brand = {
    ...brandFromRedux,
  };

  brand.current = { ...brand[uiTheme] };

  const cssColorPalettes = { ...cssColorPalettesFromRedux };
  cssColorPalettes.current = { ...cssColorPalettes[uiTheme] };

  const root_css: HTMLElement | null = document.querySelector(":root");
  const cssVars = Object.keys(cssColorPalettes.current);

  if (root_css !== null) {
    for (let i = 0; i < cssVars.length; i++) {
      root_css.style.setProperty(
        cssVars[i],
        cssColorPalettes.current[
          cssVars[i] as keyof typeof cssColorPalettes.current
        ]
      );
    }
  }

  dispatch({
    type: "SET_UI_THEME",
    payload: {
      ui_theme: uiTheme,
      ui_themes: null,
      brand: brand,
      css_color_palettes: cssColorPalettes,
    },
  });
};
