export const relationshipOptions = [
  "Aunt",
  "Brother",
  "Daughter",
  "Domestic partner",
  "Father",
  "Father-in-law",
  "Grandfather",
  "Grandmother",
  "Granddaughter",
  "Grandson",
  "Mother",
  "Mother-in-law",
  "Nephew",
  "Niece",
  "Other individual",
  "Sister",
  "Son",
  "Spouse",
  "Uncle",
] as const;

export type Relationship = typeof relationshipOptions[number];
