import React from "react";

import DownloadIcon from "@mui/icons-material/Download";
import { Stack } from "@mui/system";

import AddPartnerModelIncrementsCheckbox, {
  AddOrUpdate,
} from "../AddPartnerModelIncrementsCheckbox/AddPartnerModelIncrementsCheckbox";
import CSVError from "../CSVError/CSVError";
import CSVTips from "../CSVTips/CSVTips";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import Modal from "@src/sharedComponents/Modal/Modal";
import FileDropUploadComponent from "@src/sharedComponents/SingleFileDropUpload/SingleFileDropUpload";

import styles from "./CSVFormModal.module.css";

const PARTNER_CSV_TEMPLATE_URL =
  "https://uat-multi-custodian-portfolio-models.s3.amazonaws.com/Templates/PartnerCSVTemplate.csv";

const CUSTOM_CSV_TEMPLATE_URL =
  "https://uat-multi-custodian-portfolio-models.s3.amazonaws.com/Templates/CustomCSVTemplate.csv";

const SLEEVE_CSV_TEMPLATE_URL =
  "https://uat-multi-custodian-portfolio-models.s3.amazonaws.com/Templates/SleeveCSVTemplate.csv";

interface Props {
  closeModal: () => void;
  formObjective: string;
  uploadType: "Sleeve" | "Partner" | "Custom";
  csvFile: File | null;
  addNewFile: (file: File | null) => void;
  uploadCSVFile: () => void;
  mutating: boolean;
  csvError: string[] | null;
  action?: AddOrUpdate;
  setAction?: (action: AddOrUpdate) => void;
}

const CSVFormModal = (props: Props): JSX.Element => {
  return (
    <Modal
      modalStyle={{ width: "620px", position: "relative" }}
      closeModal={props.closeModal}
    >
      {props.mutating && (
        <div className={styles.loading}>
          <LogoLoadingStill />
        </div>
      )}

      <Stack>
        <div className={styles.subtleText}>{props.formObjective}</div>

        <Spacer verticalSpacing="5px" />

        <FormH1>Upload a Spreadsheet (CSV)</FormH1>
      </Stack>

      {props.uploadType === "Partner" &&
        props.setAction !== undefined &&
        (props.action === "Update" || props.action === "Add") && (
          <AddPartnerModelIncrementsCheckbox
            action={props.action}
            setAction={props.setAction}
          />
        )}

      <Spacer verticalSpacing="24px" />

      <FileDropUploadComponent
        file={props.csvFile}
        acceptedFileExtensions={[".csv"]}
        onChange={props.addNewFile}
        text={"Drop your file here or "}
        pressText={<div className={styles.fileUploadText}>browse</div>}
      />

      {props.csvError !== null && (
        //Errors will come in with special formatting. We may need to use something like <pre>
        <>
          <CSVError message={props.csvError} />
          <Spacer verticalSpacing="16px" />
        </>
      )}

      <CSVTips uploadType={props.uploadType} />

      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <ButtonPrimary
          text={
            <a
              className={styles.downloadLink}
              href={
                props.uploadType === "Custom"
                  ? CUSTOM_CSV_TEMPLATE_URL
                  : props.uploadType === "Partner"
                  ? PARTNER_CSV_TEMPLATE_URL
                  : SLEEVE_CSV_TEMPLATE_URL
              }
            >
              <span>Download CSV Template</span>
              <DownloadIcon className={styles.icon} />
            </a>
          }
          variant="outlined"
          onClick={() => undefined}
          buttonType="primary"
        />

        <ButtonPrimary
          text="Upload"
          variant="contained"
          buttonType="primary"
          onClick={props.uploadCSVFile}
          disabled={props.csvFile === null}
          style={{ justifyContent: "flex-end" }}
        />
      </Stack>
    </Modal>
  );
};

export default CSVFormModal;
