import { z } from "zod";

import { DocOrFilePathPart } from "@fartherfinance/frontend/api/CustodianDoc/Types";

import {
  extractSortParts,
  mapToTableSortOrder,
} from "@src/multiCustodian/utils/sorting";
import { Column } from "@src/sharedComponents/Table/Types";

import {
  DocumentLinksMap,
  LocalDocumentsExplorerQueryParams,
  TableColumns,
} from "./DocumentsExplorer.types";

export const createTableColumns = (
  sort: LocalDocumentsExplorerQueryParams["sort"]
): Column<TableColumns>[] => {
  const { sortBy, sortOrder } = extractSortParts(sort);

  return [
    { label: "", accessor: "typeIcon", sortable: false },
    {
      label: "Name",
      accessor: "name",
      sortable: true,
      active: sortBy === "name",
      sortOrder: mapToTableSortOrder(sortOrder),
    },
    {
      label: "Created",
      accessor: "created",
      sortable: true,
      active: sortBy === "created",
      sortOrder: mapToTableSortOrder(sortOrder),
    },
    {
      label: "Type",
      accessor: "type",
      sortable: true,
      active: sortBy === "type",
      sortOrder: mapToTableSortOrder(sortOrder),
    },
    {
      label: "Size",
      accessor: "size",
      sortable: true,
      active: sortBy === "size",
      sortOrder: mapToTableSortOrder(sortOrder),
    },
    { label: "", accessor: "action", sortable: false },
  ];
};

export const isDocumentUrlReady = (
  documentLinks: DocumentLinksMap,
  path: string,
  action: "preview" | "download"
): boolean => {
  switch (action) {
    case "preview":
      return Boolean(documentLinks[path]?.previewUrl);

    case "download":
      return Boolean(documentLinks[path]?.downloadUrl);

    default:
      return false;
  }
};

export const isUUID = (value: string): boolean => {
  return z.string().uuid().safeParse(value).success;
};

export const isAlternativeInvestmentDirectory = (
  directory: DocOrFilePathPart
): boolean => {
  return Boolean(
    directory.baseDirectory === "Alternative%20Investments" &&
      directory.currentDirectory &&
      isUUID(directory.currentDirectory)
  );
};

export const trimPath = (path: string): string => {
  return path.split("/").slice(2).join("/");
};
