import React from "react";

import { MarketplaceAssetType } from "@fartherfinance/frontend/api/AltAssetsService/Types";

import LendingOfferingsListAdvisor from "../OfferingsListAdvisor/LendingOfferingsListAdvisor";
import { LendingMarketplacePath } from "@src/config/routing/RouterPaths";
import { lendingOfferingTypes } from "@src/multiCustodian/components/Marketplace/constants";

import OfferingDetailsAdvisor from "./OfferingDetailsAdvisor";

const LendingOfferingDetailsAdvisor: React.FC = () => {
  return (
    <LendingOfferingsListAdvisor>
      <OfferingDetailsAdvisor
        assetType={MarketplaceAssetType.enum.lending}
        offeringTypes={lendingOfferingTypes}
        marketplacePath={LendingMarketplacePath}
      />
    </LendingOfferingsListAdvisor>
  );
};

export default LendingOfferingDetailsAdvisor;
