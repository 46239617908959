import React from "react";

import { useHistory, useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import { CashManagementPath } from "@src/config/routing/RouterPaths";
import ChangeFundingAccountModal from "@src/multiCustodian/components/Client/CashManagement/ChangeFundingAccountModal/ChangeFundingAccountModal";
import FundingSettings from "@src/multiCustodian/pages/Client/:clientId/CashManagement";

const ChangeFundingAccount = (): JSX.Element => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const history = useHistory();

  return (
    <FundingSettings>
      <ChangeFundingAccountModal
        closeModal={() =>
          history.push(`/Client/${clientId}/${CashManagementPath}`)
        }
      />
    </FundingSettings>
  );
};

export default ChangeFundingAccount;
