import React from "react";

import Typography from "@mui/material/Typography";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./InputHelperText.module.css";

interface InputHelperTextProps {
  isVisible: boolean;
}

export default function InputHelperText({
  isVisible,
}: InputHelperTextProps): JSX.Element {
  const isMacOS = navigator.platform.startsWith("Mac");
  const modifierKey = isMacOS ? "Option" : "Shift";

  return (
    <Typography
      className={toClassName(styles.helperText, {
        [styles.helperTextVisible]: isVisible,
      })}
    >
      {modifierKey} + Return to add a new line
    </Typography>
  );
}
