import React from "react";

import Accounts from "../../../Accounts";
import ManualAssetDrawer from "@src/multiCustodian/components/Client/Accounts/Manual/ManualAssetDrawer";
import SettingsTab from "@src/multiCustodian/components/Client/Accounts/Manual/SettingsTab/SettingsTab";

const Settings = (props: React.PropsWithChildren<unknown>): JSX.Element => {
  return (
    <Accounts>
      <ManualAssetDrawer curTab="Settings">
        <SettingsTab />

        {props.children}
      </ManualAssetDrawer>
    </Accounts>
  );
};

export default Settings;
