import React, { useEffect } from "react";

import { useParams } from "react-router-dom";

import useClientAccounts from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAccounts";
import useTradingGroups from "@fartherfinance/frontend/api/TradingGroups/hooks/useTradingGroups";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import AccountList from "../../../TaxBudgets/AccountList/AccountList";
import CreateEditLayout from "../../../TaxBudgets/CreateEditLayout/CreateEditLayout";
import { actions } from "../../../TaxBudgets/reducer/actions";
import { useCreateEditTaxBudgetContext } from "../../../TaxBudgets/reducer/Context";
import useClientName from "@src/multiCustodian/hooks/useClientName";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";

import styles from "./ClientSelectAccounts.module.css";

const ClientSelectAccounts: React.FC = () => {
  const { clientId } = useParams<{ clientId: ClientId }>();

  const { state, dispatch } = useCreateEditTaxBudgetContext();

  const {
    data: { clientAccountIds, excludedVirtualAccountIds },
  } = state;

  const auth = useRequestAuth();

  const clientName = useClientName();

  const { data: accountData } = useClientAccounts(clientId, auth);

  const { data: tradingGroups } = useTradingGroups(clientId, auth);

  const accounts = accountData?.fartherAccounts || [];

  const accountIds = accounts.map((account) => account.accountId);

  useEffect(() => {
    if (clientAccountIds.length === 0) {
      dispatch({
        type: actions.SET_ACCOUNT_IDS,
        payload: accountIds,
      });
    }
  }, [accountIds, clientAccountIds, dispatch]);

  const numSelected =
    clientAccountIds.length - excludedVirtualAccountIds.length;

  return (
    <CreateEditLayout>
      <BorderBox className={styles.container}>
        <div className={styles.titleContainer}>
          <div
            className={styles.title}
          >{`Available Accounts (${accounts.length})`}</div>

          <div className={styles.selected}>{`${numSelected} Account${
            numSelected === 1 ? "" : "s"
          } selected`}</div>
        </div>

        <div className={styles.name}>{`${
          clientName?.name.first || "Client"
        }'s Accounts`}</div>
        <AccountList accounts={accounts} tradingGroups={tradingGroups || []} />
      </BorderBox>
    </CreateEditLayout>
  );
};

export default ClientSelectAccounts;
