/* eslint-disable react/prop-types */

import * as React from "react";

import execute_statusNotification from "../GlobalStatusNotification/notification";

import styles from "./fileInputDrop.module.css";

interface IncomingProps {
  file_input_id: string;
  accepted_file_types: string;
  prompt: string;
  fileSettled: (file: File | null, clearFile: boolean) => void;
  clearFile: boolean;
}

interface InitialState {
  file_name: string;
  file: File | null;
  fileReady: boolean;
  fileHover: boolean;
}

class FileInputDrop extends React.Component<IncomingProps, InitialState> {
  constructor(props: IncomingProps) {
    super(props);
    this.state = {
      file_name: "",
      file: null,
      fileReady: false,
      fileHover: false,
    };
  }

  componentDidUpdate(prevProps: IncomingProps) {
    if (this.props.clearFile && !prevProps.clearFile) {
      this.clearFile();
    }
  }

  handleDragEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ fileHover: true });
  };

  handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ fileHover: false });
  };

  handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ fileHover: true });
  };

  uploadFile = () => {
    this.setState({ fileReady: false });
    const fileInput: any = document.getElementById(
      "file_input__" + this.props.file_input_id
    );
    fileInput.click();
  };

  fileReady = () => {
    const fileInput: any = document.getElementById(
      "file_input__" + this.props.file_input_id
    );
    const file = fileInput.files[0];
    const filename = file.name;
    this.setState({ file_name: filename, fileReady: true, file: file });
    this.props.fileSettled(file, false);
  };

  clearFile = () => {
    const fileInput: any = document.getElementById(
      "file_input__" + this.props.file_input_id
    );
    fileInput.value = "";
    this.setState({
      file: null,
      fileReady: false,
      file_name: "",
      fileHover: false,
    });
    this.props.fileSettled(null, false);
  };

  fileDrop = (ev: any) => {
    ev.preventDefault();
    ev.stopPropagation();
    let file = null;
    let file_name = null;
    if (ev.dataTransfer.items) {
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === "file") {
          file = ev.dataTransfer.items[i].getAsFile();
          file_name = file.name;
          break;
        }
      }
    } else {
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        file = ev.dataTransfer.files[i];
        file_name = ev.dataTransfer.files[i].name;
        break;
      }
    }

    if (file.type) {
      const accepted_file_types = this.props.accepted_file_types
        .replace(/./g, "")
        .replace(/ /g, "")
        .split(",");
      let valid_file = false;
      for (let i = 0; i < accepted_file_types.length; i++) {
        const file_type =
          accepted_file_types[i] === "jpg" ? "jpeg" : accepted_file_types[i];
        if (file.type.includes(file_type)) {
          valid_file = true;
          break;
        }
      }
      //valid_file = file_name && file_name.lastIndexOf(".") > 0 && accepted_file_types.includes(file_name.substring(file_name.lastIndexOf(".")));
      if (valid_file) {
        this.setState({
          file_name: file_name,
          fileReady: true,
          file: file,
          fileHover: false,
        });
        this.props.fileSettled(file, false);
      } else {
        this.setState({ fileHover: false });
        execute_statusNotification(
          "/src/assets/svg/!.svg",
          "File is not valid"
        );
      }
    } else {
      this.setState({ fileHover: false });
      execute_statusNotification("/src/assets/svg/!.svg", "File is not valid");
    }
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.overlap}>
          <div
            className={styles.file_drop_div}
            style={
              this.state.fileHover
                ? { backgroundColor: "var(--background_div_color_1)" }
                : { backgroundColor: "var(--primary_color_1)" }
            }
            onDrop={(e) => this.fileDrop(e)}
            onDragEnter={(e) => this.handleDragEnter(e)}
            onDragLeave={(e) => this.handleDragLeave(e)}
          >
            {!this.state.file && !this.state.fileReady && (
              <div
                className={styles.inner_upload_div}
                onDragOver={(e) => this.handleDragOver(e)}
              >
                <img
                  src="/src/assets/svg/gray_folder.svg"
                  className={styles.file_upload_img}
                ></img>
                <p className={styles.file_upload_text}>{this.props.prompt}</p>
                <div
                  className={styles.gold_browse_text}
                  onClick={this.uploadFile}
                >
                  browse
                </div>
              </div>
            )}

            {this.state.file && this.state.fileReady && (
              <div className={styles.file_upload_pill}>
                <p className={styles.file_upload_filename_text}>
                  {this.state.file_name.length > 30
                    ? this.state.file_name.substring(0, 15) +
                      "..." +
                      this.state.file_name.substring(
                        this.state.file_name.length - 15
                      )
                    : this.state.file_name}
                </p>
                <img
                  src="/src/assets/svg/white_x.svg"
                  className={styles.file_upload_x_img}
                  onClick={this.clearFile}
                ></img>
              </div>
            )}
          </div>
          <input
            type="file"
            accept={this.props.accepted_file_types}
            onChange={this.fileReady}
            className={styles.html_file_input}
            id={"file_input__" + this.props.file_input_id}
          />
        </div>
      </div>
    );
  }
}

export default FileInputDrop;
