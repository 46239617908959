import React, { useCallback, useMemo, useState } from "react";

import { format, parse } from "date-fns";
import {
  FieldPath,
  FormProvider,
  useForm,
  useFormState,
  useWatch,
} from "react-hook-form";

import useSetPlanAccountInfoV2 from "@fartherfinance/frontend/api/Accounts/hooks/useSetPlanAccountInfoV2";
import {
  Account,
  AccountInformationFormToSend,
} from "@fartherfinance/frontend/api/Accounts/Types";
import { FartherAccountId, PlanId } from "@fartherfinance/frontend/api/Types";

import DrawerHeader from "../Components/DrawerHeader";
import useTriggerForm from "../hooks/useTriggerForm";
import { dateFormat } from "@src/constants/dateFormat";
import Drawer from "@src/multiCustodian/components/Drawer/Drawer";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormDateField from "@src/sharedComponents/Forms/FormDateField";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import FormNumberField from "@src/sharedComponents/Forms/FormNumberField";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import { extractAxiosErrorMessage, isAxiosErrorCode } from "@src/utils/axios";

import styles from "./Form.module.css";

interface Form {
  AccountName: string | undefined;
  TargetGoal: string | undefined;
  TargetDate: Date | undefined;
}

type StripUndefined<F extends object> = { [k in keyof F]: NonNullable<F[k]> };

interface Props {
  planId: PlanId;
  accountId: FartherAccountId;
  onClose: () => void;
  account: Account;
}

export default function Individual(props: Props): JSX.Element {
  const [isMutating, setIsMutating] = useState<boolean>(false);

  const statusNotification = useStatusNotification();

  const accountTarget = props.account.accountInformation.accountTarget ?? null;

  const defaultAccountName =
    props.account.accountInformation.accountTitle ?? "Individual";

  const defaultTargetGoal = accountTarget ? `${accountTarget.goal}` : "";

  const defaultTargetDate = accountTarget
    ? parse(accountTarget.date, dateFormat, new Date())
    : undefined;

  const methods = useForm<Form>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      AccountName: defaultAccountName,
      TargetGoal: defaultTargetGoal,
      TargetDate: defaultTargetDate,
    },
  });

  const { control, getValues, trigger } = methods;

  const { isValid } = useFormState({
    control,
  });

  const targetGoalValue = useWatch<Form>({ control, name: "TargetGoal" }) as
    | string
    | undefined;
  const targetGoalNonEmpty = nonEmptyValue(targetGoalValue);

  const targetDateValue = useWatch<Form>({ control, name: "TargetDate" }) as
    | Date
    | undefined;
  const targetDateNotEmpty = targetDateValue !== undefined;

  const auth = useAdvisorRequestAuth();

  const callPutPlanAccountInfo = useSetPlanAccountInfoV2(auth);

  const needsManualTriggering: FieldPath<Form>[] = useMemo(
    () => ["TargetDate", "TargetGoal"],
    []
  );
  useTriggerForm<Form>(control, trigger, needsManualTriggering);

  const submit = useCallback(async () => {
    if (!isValid) {
      return;
    }

    const values = getValues() as StripUndefined<Form>;

    const form: AccountInformationFormToSend = {
      accountTitle: values.AccountName,
      accountType: "Individual",
      accountTarget: targetGoalNonEmpty
        ? {
            goal: parseFloat(values.TargetGoal),
            date: format(values.TargetDate, dateFormat),
          }
        : null,
    };

    try {
      setIsMutating(true);

      await callPutPlanAccountInfo({
        planId: props.planId,
        virtualAccountId: props.accountId,
        accountData: form,
      });

      statusNotification("Account updated", "Success");
      props.onClose();
    } catch (error) {
      const errorMessage = isAxiosErrorCode(400, error)
        ? extractAxiosErrorMessage(error)
        : "Error updating account";

      statusNotification(errorMessage, "Error");
      setIsMutating(false);
    }
  }, [
    callPutPlanAccountInfo,
    getValues,
    isValid,
    props,
    targetGoalNonEmpty,
    statusNotification,
  ]);

  return (
    <Drawer
      transitionDuration={0}
      header={
        <DrawerHeader planId={props.planId} accountId={props.accountId} />
      }
      footer={
        <Footer
          onSave={submit}
          saveDisabled={!isValid}
          closeDrawer={props.onClose}
          isMutating={isMutating}
        />
      }
      isDrawerOpen
      onClose={props.onClose}
    >
      <FormProvider {...methods}>
        <FormH1>Individual Account</FormH1>

        <FlexWrapper>
          <FormTextField
            required={"Account Name is required"}
            label={"Account Name"}
            name={"AccountName"}
          />
        </FlexWrapper>

        <FlexWrapper>
          <FormNumberField
            name={"TargetGoal"}
            startAdornment={"$"}
            required={
              targetDateNotEmpty
                ? "This is required when Target Date is set"
                : undefined
            }
            label="Target Goal (Required when Date set)"
          />

          <FormDateField
            required={
              targetGoalNonEmpty
                ? "This is required when Target Goal is set"
                : undefined
            }
            rules={{ deps: ["TargetGoal"] }}
            dateCheck={"AfterToday"}
            label="Target Date (Required when Goal set)"
            name={"TargetDate"}
          />
        </FlexWrapper>
      </FormProvider>
      {isMutating && (
        <div className={styles.loading}>
          <LogoLoadingStill />
        </div>
      )}
    </Drawer>
  );
}

const nonEmptyValue = (value: string | undefined | null) => {
  return value !== undefined && value !== "" && value !== null;
};

interface FooterProps {
  closeDrawer: () => void;
  saveDisabled: boolean;
  onSave: () => void;
  isMutating: boolean;
}

const Footer = (props: FooterProps): JSX.Element => {
  return (
    <div className={styles.footer}>
      <ButtonPrimary
        text={"Cancel"}
        onClick={props.closeDrawer}
        variant={"outlined"}
        buttonType={"primary"}
      />
      <ButtonPrimary
        text={"Save"}
        onClick={props.onSave}
        variant={"contained"}
        buttonType={"primary"}
        disabled={props.saveDisabled || props.isMutating}
      />
    </div>
  );
};
