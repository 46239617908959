import { useCallback } from "react";

import { useHistory } from "react-router-dom";

export default function useResetTablePaginationPage() {
  const history = useHistory();

  return useCallback(() => {
    const queryParams = new URLSearchParams(history.location.search);
    queryParams.set("page", "0");
    history.replace({ ...history.location, search: queryParams.toString() });
  }, [history]);
}
