import React from "react";

import { Stack } from "@mui/system";

import { ManuallyTrackedAccount } from "@fartherfinance/frontend/api/Accounts/Types";

import styles from "./Header.module.css";

interface Props {
  manualAsset: ManuallyTrackedAccount | null;
}

const Header = (props: Props): JSX.Element => {
  if (props.manualAsset === null) {
    return <></>;
  }

  const { assetName, assetType, assetValue } = props.manualAsset;

  const assetTypeNormalized =
    assetType === "Collectable" ? "Collectible" : assetType;

  return (
    <div className={styles.container}>
      <Stack gap={"4px"}>
        <div className={styles.title}>{assetName}</div>

        <div className={styles.subtleText}>{assetTypeNormalized}</div>
      </Stack>

      <div className={styles.valueContainer}>
        <div className={styles.subtleText}>Value</div>
        <div className={styles.value}>
          {assetValue.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </div>
      </div>
    </div>
  );
};

export default Header;
