import React from "react";

const Sharing: React.FC = () => (
  <svg
    width="100%"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.1016 19.353C32.1016 25.6231 27.0187 30.7061 20.7486 30.7061C14.4784 30.7061 9.39551 25.6231 9.39551 19.353C9.39551 13.0829 14.4784 8 20.7486 8C27.0187 8 32.1016 13.0829 32.1016 19.353Z"
      fill="currentColor"
    />
    <path
      d="M20.0596 44.1483C20.0596 39.5351 16.3199 35.7954 11.7067 35.7954H0V55.7611H41.0745V35.7954H29.3678C24.7546 35.7954 21.0149 39.5351 21.0149 44.1483H20.0596Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.4869 35.4064C52.5807 35.4064 56.7101 31.277 56.7101 26.1832C56.7101 21.0893 52.5807 16.9599 47.4869 16.9599C42.393 16.9599 38.2637 21.0893 38.2637 26.1832C38.2637 31.277 42.393 35.4064 47.4869 35.4064ZM44.1586 55.7611H63.9996V39.5409H44.1586V55.7611Z"
      fill="currentColor"
    />
  </svg>
);

export default Sharing;
