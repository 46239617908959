import React from "react";

import { useFlags } from "launchdarkly-react-client-sdk";

import Profile from "@src/multiCustodian/components/Advisor/ClientProfile/Components/Profile";
import ProfileV4 from "@src/multiCustodian/components/Advisor/ClientProfile/Components/ProfileV4";

export default function ClientProfile() {
  const { enableCustomPerformanceGroupsV2 } = useFlags();

  if (enableCustomPerformanceGroupsV2) {
    return <ProfileV4 />;
  }

  return <Profile />;
}
