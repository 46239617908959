import React, { useState } from "react";

import { Stack, Typography } from "@mui/material";

import Tips from "./Tips";

import styles from "./ClientCSVTips.module.css";

const ClientCSVTips = (): JSX.Element => {
  const [seeMore, setSeeMore] = useState(false);

  return (
    <Stack className={styles.stack}>
      <Typography fontWeight={600} fontSize="13px">
        Tips on preparing the CSV
      </Typography>

      <Tips seeMore={seeMore} />

      {seeMore ? (
        <div className={styles.seeLess}>
          <div
            className={styles.expanderText}
            onClick={() => setSeeMore(false)}
          >
            See Less
          </div>
        </div>
      ) : (
        <div className={styles.seeMore}>
          <div className={styles.expanderText} onClick={() => setSeeMore(true)}>
            See More
          </div>
        </div>
      )}
    </Stack>
  );
};

export default ClientCSVTips;
