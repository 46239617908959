import { ServiceProfessional } from "@fartherfinance/frontend/api/Sharing/Types";

import {
  serviceProfessionalOptions,
  ServiceProfessionalTypeOption,
  serviceProfessionalTypes,
} from "@src/constants/serviceProffesional";

export const getServiceProfessionalTypeFromOption = (
  servicePro?: ServiceProfessionalTypeOption
): ServiceProfessional | undefined => {
  if (!servicePro) {
    return undefined;
  }

  return serviceProfessionalTypes[
    serviceProfessionalOptions.indexOf(servicePro)
  ];
};
