import React, { PropsWithChildren, useMemo, useState } from "react";

import { format } from "date-fns";

import { UNKNOWN_BALANCE } from "@fartherfinance/frontend/api/Accounts/utilities/accountUtil";
import useGetForwardLooking from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Projections/useGetForwardLooking";
import useGetHistorical from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Projections/useGetHistorical";
import useGetProjections from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Projections/useGetProjections";
import usePerformanceGroupProjections from "@fartherfinance/frontend/api/PerformanceGroups/hooks/usePerformanceGroupProjections";
import {
  ClientId,
  PerformanceGroupId,
} from "@fartherfinance/frontend/api/Types";
import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import ProjectionsGraph from "@src/multiCustodian/components/PerformanceGroups/Projections/ProjectionsGraph";
import {
  timeRangeOptions,
  TRO,
} from "@src/multiCustodian/components/PerformanceGroups/Projections/Types";
import { formatToProjectionsGraphData } from "@src/multiCustodian/components/PerformanceGroups/Projections/utils/formatToProjectionsGraphData";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import finScoreToLabel from "@src/multiCustodian/utils/finScoreToLabel";
import MultiToggle from "@src/sharedComponents/MultiToggle/MultiToggle";
import PerformanceIndicatorArrow from "@src/sharedComponents/PerformanceIndicatorArrow/PerformanceIndicatorArrow";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import ProjectionsLegendForDashboard from "./ProjectionsLegendForDashboard";

import styles from "./ProjectionsTab.module.css";

const UNKNOWN_RISK = "- -";

interface Props {
  groupId: PerformanceGroupId | null;
  clientId: ClientId;
  graphHeight: number;
  displayContentSideBySide: boolean;
}

export default function ProjectionsTab(props: PropsWithChildren<Props>) {
  const {
    color: { $chartLinePrimary, $chartLineSecondary, $chartLineTertiary },
  } = useTheme();

  const auth = useRequestAuth();

  const projectionsData = usePerformanceGroupProjections(
    props.groupId,
    props.clientId,
    auth
  );
  const groupProjections = useGetProjections(props.groupId, auth);
  const groupForwardLooking = useGetForwardLooking(props.groupId, auth);
  const groupHistorical = useGetHistorical(props.groupId, auth);

  const isLoading =
    projectionsData.isLoading ||
    groupProjections.isLoading ||
    groupForwardLooking.isLoading ||
    groupHistorical.isLoading;

  const hasError =
    projectionsData.hasError ||
    groupProjections.hasError ||
    groupForwardLooking.hasError ||
    groupHistorical.hasError;

  const [timeRange, setTimeRange] = useState<TRO>("25Y");

  const formatted = useMemo(() => {
    return formatToProjectionsGraphData(
      projectionsData.data?.projections,
      Number(timeRange.replace("Y", ""))
    );
  }, [projectionsData.data?.projections, timeRange]);

  const dateTo = useMemo(() => {
    if (formatted.length > 0) {
      const yearsOut = formatted[formatted.length - 1].yearIndex;
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth();
      const day = today.getDate();
      return new Date(year + yearsOut, month, day);
    }
    return null;
  }, [formatted]);

  const noMonteCarloData =
    groupProjections.data !== undefined &&
    Object.values(groupProjections.data.monteCarloSimulationResults).every(
      (v) => v === null || Object.values(v).length === 0
    );

  if (noMonteCarloData) {
    return (
      <div className={styles.container}>
        <div
          className={
            props.displayContentSideBySide
              ? styles.contentHorizontal
              : styles.contentVertical
          }
        >
          <div className={styles.graphContent}>
            <div className={styles.noData}>
              Projections data is not available
            </div>
          </div>

          <div
            className={
              props.displayContentSideBySide
                ? styles.detailsContentFixedWidth
                : styles.detailsContentFullWidth
            }
          >
            <RiskLevelBarNoData />

            <ProjectionDetailsTableNoData />
          </div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={styles.container}>
        <div
          className={
            props.displayContentSideBySide
              ? styles.contentHorizontal
              : styles.contentVertical
          }
        >
          <div className={styles.graphContent}>
            <Skeleton
              width={"100%"}
              height={props.graphHeight}
              style={{ transform: "none" }}
            />

            <div className={styles.belowGraphLoading}>
              <Skeleton width={"100%"} style={{ transform: "none" }} />
            </div>
          </div>

          <div
            className={
              props.displayContentSideBySide
                ? styles.detailsContentFixedWidth
                : styles.detailsContentFullWidth
            }
          >
            {props.children}

            <RiskLevelBarLoading />

            <ProjectionDetailsTableLoading />
          </div>
        </div>
      </div>
    );
  }

  if (hasError) {
    return <div>Error</div>;
  }

  return (
    <div className={styles.container}>
      <div
        className={
          props.displayContentSideBySide
            ? styles.contentHorizontal
            : styles.contentVertical
        }
      >
        <div className={styles.graphContent}>
          {props.displayContentSideBySide && <ProjectionsLegendForDashboard />}

          <ProjectionsGraph
            monteCarloData={formatted}
            color90pct={$chartLineTertiary}
            color50pct={$chartLinePrimary}
            color10pct={$chartLineSecondary}
            graphHeight={props.graphHeight}
          />

          <div className={styles.belowGraph}>
            <div className={styles.xAxis}>
              <div className={styles.xAxisDateFrom}>Today</div>

              <div className={styles.dateRangeFilterContainer}>
                <MultiToggle
                  items={timeRangeOptions.map((tf) => ({ label: tf }))}
                  currentItem={{ label: timeRange }}
                  onChange={(e) => setTimeRange(e.label)}
                />
              </div>

              <div className={styles.xAxisDateTo}>
                {dateTo ? format(dateTo, "MMM d, yyyy") : ""}
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            props.displayContentSideBySide
              ? styles.detailsContentFixedWidth
              : styles.detailsContentFullWidth
          }
        >
          {props.children}

          <RiskLevelBar finScore={groupHistorical.data.finScore} />

          <ProjectionDetailsTable
            expectedReturns={groupForwardLooking.data.expectedReturns}
            expectedVolatility={groupForwardLooking.data.expectedVolatility}
            sharpeRatio={groupForwardLooking.data.sharpeRatio}
          />
        </div>
      </div>
    </div>
  );
}

interface RiskLevelBarProps {
  finScore: number | null;
}

const RiskLevelBar = ({ finScore }: RiskLevelBarProps) => {
  return (
    <div className={styles.riskContainer}>
      <div className={styles.riskHeader}>
        <div className={styles.riskTitle}>Risk Level</div>

        <div className={styles.riskLabel}>
          {finScoreToLabel(finScore ?? undefined)}
        </div>
      </div>

      <div className={styles.riskBarBackground}>
        <div
          className={styles.riskBarOverlay}
          style={{ width: `${finScore ? Math.max(100 - finScore, 0) : 100}%` }}
        />
      </div>
    </div>
  );
};

const RiskLevelBarLoading = () => {
  return (
    <div className={styles.riskContainer}>
      <div className={styles.riskHeader}>
        <div className={styles.riskTitle}>Risk Level</div>

        <div className={styles.riskLabel}>{UNKNOWN_RISK}</div>
      </div>

      <Skeleton width={"100%"} />
    </div>
  );
};

const RiskLevelBarNoData = () => {
  return (
    <div className={styles.riskContainer}>
      <div className={styles.riskHeader}>
        <div className={styles.riskTitle}>Risk Level</div>

        <div className={styles.riskLabel}>{UNKNOWN_RISK}</div>
      </div>

      <div className={styles.riskBarNoData} />
    </div>
  );
};

interface ProjectionsDetailsTableProps {
  expectedReturns: number | null;
  expectedVolatility: number | null;
  sharpeRatio: number | null;
}

const ProjectionDetailsTable = ({
  expectedReturns,
  expectedVolatility,
  sharpeRatio,
}: ProjectionsDetailsTableProps) => {
  return (
    <div className={styles.projectionDetailsTable}>
      <div className={styles.projectionDetailsTableItem}>
        <div className={styles.projectionDetailsTableItemHeading}>
          Projected Returns
        </div>

        <div className={styles.performance}>
          {expectedReturns && (
            <PerformanceIndicatorArrow
              style={{ marginRight: "5px" }}
              status={
                expectedReturns >= 0
                  ? "positive"
                  : expectedReturns < 0
                  ? "negative"
                  : "neutral"
              }
            />
          )}

          <span className={styles.projectionDetailsTableItemText}>
            {expectedReturns
              ? `${expectedReturns.toFixed(2)}%`
              : UNKNOWN_BALANCE}
          </span>
        </div>
      </div>

      <div className={styles.projectionDetailsTableSeparator} />

      <div className={styles.projectionDetailsTableItem}>
        <div className={styles.projectionDetailsTableItemHeading}>
          Projected Volatility
        </div>

        <div className={styles.performance}>
          {expectedVolatility && (
            <PerformanceIndicatorArrow
              style={{ marginRight: "5px" }}
              status={
                expectedVolatility >= 0
                  ? "positive"
                  : expectedVolatility < 0
                  ? "negative"
                  : "neutral"
              }
            />
          )}

          <span className={styles.projectionDetailsTableItemText}>
            {expectedVolatility !== null
              ? `${expectedVolatility.toFixed(2)}%`
              : UNKNOWN_BALANCE}
          </span>
        </div>
      </div>

      <div className={styles.projectionDetailsTableSeparator} />

      <div className={styles.projectionDetailsTableItem}>
        <div className={styles.projectionDetailsTableItemHeading}>
          Sharpe Ratio
        </div>

        <div className={styles.projectionDetailsTableItemText}>
          {sharpeRatio ?? UNKNOWN_BALANCE}
        </div>
      </div>
    </div>
  );
};

const ProjectionDetailsTableLoading = () => {
  return (
    <div className={styles.projectionDetailsTable}>
      <div className={styles.projectionDetailsTableItem}>
        <div className={styles.projectionDetailsTableItemHeading}>
          Projected Returns
        </div>

        <Skeleton width={100} />
      </div>

      <div className={styles.projectionDetailsTableSeparator} />

      <div className={styles.projectionDetailsTableItem}>
        <div className={styles.projectionDetailsTableItemHeading}>
          Projected Volatility
        </div>

        <Skeleton width={100} />
      </div>

      <div className={styles.projectionDetailsTableSeparator} />

      <div className={styles.projectionDetailsTableItem}>
        <div className={styles.projectionDetailsTableItemHeading}>
          Sharpe Ratio
        </div>

        <Skeleton width={100} />
      </div>
    </div>
  );
};

const ProjectionDetailsTableNoData = () => {
  return (
    <div className={styles.projectionDetailsTable}>
      <div className={styles.projectionDetailsTableItem}>
        <div className={styles.projectionDetailsTableItemHeading}>
          Projected Returns
        </div>

        <div className={styles.projectionDetailsTableItemText}>
          {UNKNOWN_BALANCE}
        </div>
      </div>

      <div className={styles.projectionDetailsTableSeparator} />

      <div className={styles.projectionDetailsTableItem}>
        <div className={styles.projectionDetailsTableItemHeading}>
          Projected Volatility
        </div>

        <div className={styles.projectionDetailsTableItemText}>
          {UNKNOWN_BALANCE}
        </div>
      </div>

      <div className={styles.projectionDetailsTableSeparator} />

      <div className={styles.projectionDetailsTableItem}>
        <div className={styles.projectionDetailsTableItemHeading}>
          Sharpe Ratio
        </div>

        <div className={styles.projectionDetailsTableItemText}>
          {UNKNOWN_BALANCE}
        </div>
      </div>
    </div>
  );
};
