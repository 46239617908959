import React from "react";

import { TabContext, TabList } from "@mui/lab";
import { Fade, Tab } from "@mui/material";
import { matchPath, useHistory } from "react-router-dom";

import { TrackingEvents } from "@fartherfinance/frontend/eventTracking/events";

import { trackEvent } from "@src/multiCustodian/services/tracking";

import styles from "./HorizontalNavigation.module.css";

export type HorizontalNavigationTab = {
  label: string;
  path: string;
  mixpanelEvent?: TrackingEvents;
};

interface HorizontalNavigationProps {
  tabs: HorizontalNavigationTab[];
}

const HorizontalNavigation: React.FC<
  React.PropsWithChildren<HorizontalNavigationProps>
> = ({ children, tabs }) => {
  const history = useHistory();

  const matchingTab = tabs.find((tab) =>
    matchPath(history.location.pathname, {
      path: tab.path,
      exact: false,
    })
  );

  // NOTE: pass through if the current path is not matching any of the provided tabs paths
  if (!matchingTab) {
    return <>{children}</>;
  }

  const handleOnTrackEvent = (mixpanelEvent?: TrackingEvents) => {
    if (mixpanelEvent) {
      trackEvent(mixpanelEvent);
    }
  };

  return (
    <TabContext value={matchingTab.path}>
      <TabList
        classes={{
          root: styles.tabsRoot,
          indicator: styles.tabsIndicator,
          flexContainer: styles.tabsContainer,
        }}
        onChange={(_, value) => history.push(value)}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab
            classes={{ root: styles.tabRoot, selected: styles.tabSelected }}
            key={tab.label}
            label={tab.label}
            value={tab.path}
            onClick={() => handleOnTrackEvent(tab.mixpanelEvent)}
            disableRipple
            disableFocusRipple
          />
        ))}
      </TabList>

      <Fade key={matchingTab.path} in>
        <div>{children}</div>
      </Fade>
    </TabContext>
  );
};

export default HorizontalNavigation;
