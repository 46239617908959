import React, { useCallback, useState } from "react";

import { FormProvider, useForm, useFormState } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import useCreateClientAlternativeAsset from "@fartherfinance/frontend/api/Dashboard/hooks/useCreateClientAlternativeAsset";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import { AccountsPath } from "@src/config/routing/RouterPaths";
import BackButton from "@src/multiCustodian/components/Client/BackButton";
import ExitButton from "@src/multiCustodian/components/Client/ExitButton";
import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import FormNumberField from "@src/sharedComponents/Forms/FormNumberField";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

import { ManualAssetsAccountType } from "./Types";

import styles from "./AddManualAccount.module.css";

interface Form {
  AssetType: ManualAssetsAccountType | "Collectible";
  AssetName: string;
  AssetValue: string;
}

type StripUndefined<F extends object> = { [k in keyof F]: NonNullable<F[k]> };

const AddManualAccountDetails = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const history = useHistory();

  const { clientId, manualAccountType } = useParams<{
    clientId: ClientId;
    manualAccountType: ManualAssetsAccountType;
  }>();

  const notify = useStatusNotification();

  const auth = useRequestAuth();
  const createManualAsset = useCreateClientAlternativeAsset(clientId, auth);

  const methods = useForm<Form>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      AssetType:
        manualAccountType === "Collectable" ? "Collectible" : manualAccountType,
      AssetName: "",
      AssetValue: "",
    },
  });

  const { control, getValues } = methods;

  const { isValid } = useFormState({
    control,
  });

  const submit = useCallback(async () => {
    if (!isValid || isLoading || !clientId) {
      return;
    }

    const values = getValues() as StripUndefined<Form>;

    try {
      setIsLoading(true);

      await createManualAsset({
        assetType:
          values.AssetType === "Collectible" ? "Collectable" : values.AssetType,
        assetName: values.AssetName,
        assetValue: Number(values.AssetValue),
      });

      notify("Account created", "Success");
      history.push(`/Client/${clientId}/${AccountsPath}`);
    } catch (e) {
      notify("Error creating account", "Error");
      console.error(e);
      setIsLoading(false);
    }
  }, [
    getValues,
    isValid,
    createManualAsset,
    clientId,
    isLoading,
    history,
    notify,
  ]);

  return (
    <BaseLayout showSideNav={false}>
      <ExitButton />

      <div className={styles.container}>
        <div className={styles.centeringContainer1}>
          <div className={styles.backButtonContainer}>
            <BackButton
              onClick={() =>
                history.push(`/Client/${clientId}/${AccountsPath}/Add/Manual`)
              }
            />
          </div>

          <div className={styles.centeringContainer2}>
            {isLoading && (
              <div className={styles.loading_div}>
                <div className={styles.loading_inner_div}>
                  <LogoLoadingStill />
                </div>
              </div>
            )}

            <p className={styles.title}>Manually-Tracked Account</p>

            <p className={styles.summary}>
              To finish creating your account fill in the information below and
              click save
            </p>

            <FormProvider {...methods}>
              <div className={styles.input_container}>
                <FormTextField
                  label={"Asset Type"}
                  name={"AssetType"}
                  required={"Asset Type is required"}
                  disabled={true}
                />
              </div>

              <div className={styles.input_container}>
                <FormTextField
                  label={"Asset Name"}
                  name={"AssetName"}
                  required={"Asset Name is required"}
                />
              </div>

              <div className={styles.input_container}>
                <FormNumberField
                  label={"Asset Value"}
                  name={"AssetValue"}
                  startAdornment={"$"}
                  required={"Must be a number >= 0.01"}
                  rules={{
                    validate: {
                      number: () => {
                        const values = getValues();
                        const assetValue = values.AssetValue;
                        return assetValue &&
                          assetValue.match(/^\d{1,}?$/) &&
                          parseFloat(assetValue) > 0
                          ? true
                          : "Must be a number > $0. No decimals.";
                      },
                    },
                  }}
                />
              </div>
            </FormProvider>

            <div className={styles.footer}>
              <Button
                disabled={!isValid || isLoading || !clientId}
                variant={"contained"}
                buttonType={"primary"}
                text={"Save"}
                onClick={() => submit()}
                style={{ marginLeft: "25px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default AddManualAccountDetails;
