import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

interface MakeStylesProps {
  columnsCount: number;
  isSubmittedProposalsView?: boolean;
}

export const useStyles = makeStyles<MakeStylesProps>()(
  (theme, { columnsCount, isSubmittedProposalsView }) => ({
    container: {
      paddingBottom: 40,
    },
    gridTemplate: {
      gridTemplateColumns: "1fr 1fr 1fr 1fr 1.5fr 1fr 1.3fr",
    },
    gridTemplateApex: {
      gridTemplateColumns: isSubmittedProposalsView
        ? "1fr 1fr 1fr 1.5fr 1fr 1fr 1.3fr"
        : "1fr 1fr 1fr 1.5fr 1fr 1.3fr",
    },
    gridCellWarning: {
      height: 200,
      gridColumn: `1 / ${columnsCount + 1}`,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    warningMessage: {
      fontSize: theme.typography.pxToRem(18),
      marginBottom: theme.spacing(pxToSpacing(40)),
    },
    alignCenter: {
      textAlign: "center",
    },
    icon: {
      color: theme.colors.primary.shades.cream60,
    },
    iconButton: {
      padding: 0,
    },

    menuButton: {
      marginLeft: theme.spacing(pxToSpacing(7)),
    },
    menuIcon: {
      color: theme.appearance.$icon,

      transition: theme.transitions.create(["transform"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.short,
      }),
    },
    rotate: {
      transform: "rotate(180deg)",
    },
    menuIconColor: {
      path: {
        fill: theme.colors.primary.tints.navy50,
      },
    },

    statusRow: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    statusExceptionIndicator: {
      width: 18,
      height: 18,
      color: theme.appearance.$iconDanger,
      marginRight: theme.spacing(pxToSpacing(6)),
    },

    textShade: {
      color: theme.colors.primary.shades.cream60,
    },
    textSuccess: {
      color: theme.appearance.$textSuccess,
    },
    textDanger: {
      color: theme.appearance.$textDanger,
    },
    text: {
      color: theme.appearance.$text,
    },

    tradingGroup: {
      cursor: "pointer",
      textDecoration: "underline",

      "&:hover": {
        textDecoration: "none",
      },
    },
  })
);
