import React, { ComponentProps, PropsWithChildren } from "react";

import * as Sentry from "@sentry/react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { Route } from "react-router-dom";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";

import { FullScreenLoader, RequiresPermission } from "./UtilityComponents";

const SentryRoute = Sentry.withSentryRouting(Route);

export default function PrivateAdminRoute({
  children,
  ...rest
}: PropsWithChildren<ComponentProps<typeof Route>>) {
  const ldClient = useLDClient();
  const isAnonymous = ldClient?.getContext().anonymous;

  const auth = useAdvisorRequestAuth();

  // Once isAdvisor is loaded we know we have already loaded data and can skip this
  if (isAnonymous === true) {
    return <FullScreenLoader />;
  }

  if (auth === null) {
    return <SentryRoute {...rest} render={() => <RequiresPermission />} />;
  }

  return <SentryRoute {...rest} render={() => children} />;
}
