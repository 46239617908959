import React, { useState } from "react";

import { ArrowForward, Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";

import Button from "../MUI/Button/Button";
import TextInput from "@src/sharedComponents/TextInput/TextInput";
import Text from "@src/sharedComponents/Typography/Text";
import normalizeEmail from "@src/utils/normalizeEmail";

import Layout from "./Layout";

import * as styles from "./StytchLogin.module.css";

interface Props {
  onPasswordLoginClick: (email: string, password: string) => Promise<void>;
  onResetPasswordClick: (email: string) => Promise<void>;
  error: string | null;
  onBack?: () => void;
  emailAddress: string;
  isLoading: boolean;
}

const PasswordEntry: React.FC<Props> = ({
  emailAddress,
  error,
  isLoading,
  onBack,
  onPasswordLoginClick,
  onResetPasswordClick,
}) => {
  const [email, setEmail] = useState<string>(emailAddress);
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const loginDisabled = email === "" || password === "";

  return (
    <Layout
      onBack={onBack}
      subtitle="Enter your email and password."
      title="Sign In"
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (loginDisabled) {
            return;
          }
          onPasswordLoginClick(email, password);
        }}
      >
        <TextInput
          label="Email address"
          type={"email"}
          autoComplete="email"
          placeholder="email@example.com"
          value={email}
          onChange={(e) => {
            e.preventDefault();
            setEmail(normalizeEmail(e.target.value));
          }}
        />
        <div className={styles.spacer} />

        <TextInput
          label="Password"
          type={showPassword ? "text" : "password"}
          autoComplete="current-password"
          placeholder="••••••••••"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  className={styles.showIcon}
                  onClick={() => setShowPassword((prev) => !prev)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {error !== null ? (
          <Text className={styles.helper} variant="helperError">
            {error}
          </Text>
        ) : (
          <div className={styles.helperSpacer} />
        )}

        <div className={styles.spacer} />

        <Button
          disabled={loginDisabled}
          text="Login"
          onClick={() => onPasswordLoginClick(email, password)}
          variant="contained"
          buttonType="primary"
          sx={{ width: "100%" }}
          isLoading={isLoading}
          useLoader
        />

        <div className={styles.spacer_sm} />

        <Button
          disabled={email === ""}
          text={
            <>
              Forgot Password? <ArrowForward className={styles.arrow} />
            </>
          }
          onClick={() => onResetPasswordClick(email)}
          variant="text"
          buttonType="primary"
          sx={{
            width: "100%",
            textDecoration: "underline",
            color: email === "" ? "var(--textDisabled) !important" : "inherit",
          }}
        />
        <input type="submit" style={{ display: "none" }} />
      </form>
    </Layout>
  );
};

export default PasswordEntry;
