import React from "react";

import { useHistory, useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import ClientAdmin from "@src/multiCustodian/components/Advisor/ClientProfile/Components/ClientAdmin";
import RenameAccountModal from "@src/multiCustodian/components/Advisor/ClientProfile/Components/Rename/RenameAccountModal";

const AdminRenameAccount = (): JSX.Element => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const history = useHistory();

  return (
    <ClientAdmin>
      <RenameAccountModal
        closeModal={() =>
          history.push({
            pathname: `/Advisor/Clients/${clientId}/Admin`,
            state: history.location.state,
          })
        }
      />
    </ClientAdmin>
  );
};

export default AdminRenameAccount;
