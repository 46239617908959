import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

export const useStyles = makeStyles()((theme) => ({
  container: {
    maxWidth: 620,
    margin: "0 auto",
  },
  userAccountsContainer: {
    marginBottom: theme.spacing(pxToSpacing(20)),
  },
  warning: {
    height: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: theme.typography.pxToRem(18),
  },
}));
