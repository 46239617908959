import useGetFartherEmployees from "@fartherfinance/frontend/api/Entity/hooks/useFartherEmployees";
import type { Advisor } from "@fartherfinance/frontend/api/Entity/requests/getFartherEmployees";

import useAdvisorRequestAuth from "../useAdvisorRequestAuth";

export default function useAdvisorDetails(): Advisor | undefined {
  const auth = useAdvisorRequestAuth();
  const advisors = useGetFartherEmployees("All", auth);

  const currentAdvisor = advisors.data
    ? advisors.data.advisors.find(
        (advisor) => advisor.advisorId === auth?.advisorId
      )
    : undefined;

  return currentAdvisor;
}
