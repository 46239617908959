import React from "react";

import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import { Box } from "@mui/material";

import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import styles from "./NotificationActions.module.css";

interface NotificationActionsProps {
  isUnseen: boolean;
  onArchive: () => void;
  onStatusToggle: () => void;
}

export default function NotificationActions({
  isUnseen,
  onArchive,
  onStatusToggle,
}: NotificationActionsProps): JSX.Element {
  const handleOnArchive = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    onArchive();
  };

  const handleOnStatusToggle = (
    event: React.MouseEvent<HTMLDivElement>
  ): void => {
    event.stopPropagation();
    onStatusToggle();
  };

  return (
    <Box className={styles.container}>
      <Tooltip tooltipText="Archive" placement="top">
        <Box className={styles.action} onClick={handleOnArchive}>
          <Inventory2OutlinedIcon className={styles.icon} />
        </Box>
      </Tooltip>

      <Tooltip
        tooltipText={isUnseen ? "Mark as read" : "Mark as unread"}
        placement="top"
      >
        <Box className={styles.action} onClick={handleOnStatusToggle}>
          <CircleOutlinedIcon className={styles.icon} />
        </Box>
      </Tooltip>
    </Box>
  );
}
