import React from "react";

import { EquivalentSecurityList } from "@fartherfinance/frontend/api/EquivalentSecurities/requests/getEquivalentSecurityList";

import BorderBoxBody from "@src/sharedComponents/BorderBox/BorderBoxBody";

import EquivalentSecuritiesAdminTableRow from "./EquivalentSecuritiesAdminTableRow";

import styles from "./EquivalentSecuritiesAdminTable.module.css";

interface Props {
  eslists: EquivalentSecurityList[];
}

const EquivalentSecuritiesAdminTable: React.FC<Props> = ({ eslists }) => {
  return (
    <BorderBoxBody className={styles.borderBoxBody} showTopBorder={true}>
      {eslists.length > 0 ? (
        <>
          {eslists.map((eslist) => (
            <EquivalentSecuritiesAdminTableRow
              key={eslist.listId}
              eslist={eslist}
            />
          ))}
        </>
      ) : (
        <div className={styles.tableRowContent}>
          <div className={styles.textSubtle}>No Lists</div>
        </div>
      )}
    </BorderBoxBody>
  );
};

export default EquivalentSecuritiesAdminTable;
