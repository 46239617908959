import React from "react";

import { useParams } from "react-router-dom";

import usePortfolioV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/usePortfolioV2";
import { PortfolioId } from "@fartherfinance/frontend/api/Types";

import AdvisorContainer from "../../../AdvisorContainer";
import CreateUpdateCustomModel from "@src/multiCustodian/components/Advisor/Investments/ModelMarketplace/CreateUpdateCustomModel/CreateUpdateCustomModel";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

const UpdateCustomModel = (): JSX.Element => {
  const { portfolioId } = useParams<{
    portfolioId: PortfolioId;
  }>();

  const auth = useAdvisorRequestAuth();
  const customPortfolio = usePortfolioV2(portfolioId, auth);

  if (customPortfolio.hasError) {
    return <div>Error retrieving model and/or sleeves</div>;
  }

  if (customPortfolio.isLoading) {
    return <LogoLoadingStill onTop={true} />;
  }

  return (
    <AdvisorContainer hideNavbar={true}>
      <CreateUpdateCustomModel
        isUpdate={true}
        portfolioId={portfolioId}
        modelName={customPortfolio.data.model.displayName}
        taxType={customPortfolio.data.model.taxType}
        ownerId={customPortfolio.data.model.ownerId}
        currentSecurities={customPortfolio.data.positions.securities.map(
          (s) => ({
            securityIdentifier: s.ticker,
            weight: s.value,
          })
        )}
        currentSleeves={customPortfolio.data.positions.sleeves}
      />
    </AdvisorContainer>
  );
};

export default UpdateCustomModel;
