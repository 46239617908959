import React from "react";

import { useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import { useCreateEditTaxBudgetContext } from "@src/multiCustodian/components/TaxBudgets/reducer/Context";

import AccountsReviewRow from "./AccountsReviewRow";

const AccountsReviewRows: React.FC = () => {
  const { clientId } = useParams<{ clientId: ClientId }>();

  const { state } = useCreateEditTaxBudgetContext();

  const {
    data: { clientIds },
  } = state;

  const spouseClientId = clientIds.find((id) => id !== clientId) || null;

  return (
    <div>
      <AccountsReviewRow clientId={clientId} />
      {spouseClientId && <AccountsReviewRow clientId={spouseClientId} />}
    </div>
  );
};

export default AccountsReviewRows;
