import { useEffect } from "react";

import { useLDClient } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";

import useFartherEmployees from "@fartherfinance/frontend/api/Entity/hooks/useFartherEmployees";

import { identify, setUserInfo } from "../../services/tracking";
import useAdvisorRequestAuth from "../useAdvisorRequestAuth";
import { useLaunchDarklyClientGroups } from "../useAPMClientIdentify";
import { State } from "@src/store";

export default function useAPMAdvisorIdentify(): void {
  const { advisorId, clientId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
    clientId: state.main_Reducer.user.id_user,
  }));
  const auth = useAdvisorRequestAuth();

  const advisors = useFartherEmployees("All", auth);

  const ldClient = useLDClient();
  const ldClientGroups = useLaunchDarklyClientGroups();

  useEffect(() => {
    if (advisorId === null) {
      return;
    }

    identify(advisorId);

    if (advisors.data !== undefined) {
      const myself = advisors.data.advisors.find(
        (e) => e.advisorId === advisorId
      );

      if (ldClient) {
        const groups: string[] = [
          "advisor",
          ...(myself?.details.isAdmin === true ? ["admin"] : []),
          ...ldClientGroups,
        ];

        console.log("LD Groups", groups);

        ldClient.identify({
          kind: "user",
          key: advisorId,
          firstName: myself?.name.first,
          lastName: myself?.name.last,
          email: myself?.emailAddress,
          avatar: myself?.details.headshotPath ?? undefined,
          roles: myself?.roles,
          groups,
        });
      }

      if (myself === undefined) {
        return;
      }

      setUserInfo({
        id: advisorId,
        email: myself.emailAddress,
        name: `${myself.name.first} ${myself.name.last}`,
        firstName: myself.name.first,
        lastName: myself.name.last,
        isAdvisor: true,
        isAdmin: myself.details.isAdmin,
        profileImageUrl: myself.details.headshotPath ?? undefined,
        impersonatingClientId: clientId ?? undefined,
      });
    }
  }, [advisorId, advisors.data, clientId, ldClient, ldClientGroups]);
}
