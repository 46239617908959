import React from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useParams } from "react-router-dom";

import useAllClientCustodians from "@fartherfinance/frontend/api/Dashboard/hooks/useAllClientCustodians";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import SharingNotificationsNavItemWrapper from "../Sharing/NotificationsNavItemWrapper";
import {
  AccountsPath,
  CashManagementPath,
  DocumentsCenterPath,
  FinancialPlansPath,
  InsuranceMarketplacePath,
  InvestmentsPath,
  LendingMarketplacePath,
  LiquidationPath,
} from "@src/config/routing/RouterPaths";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

import NavItem from "./NavItem/NavItem";

const ClientNavMenu: React.FC = () => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const auth = useRequestAuth();

  const allCustodians = useAllClientCustodians(clientId, auth);

  const {
    enableTransferApp,
    enableOneTimeAch,
    enableOneTimeAcats,
    enablePortfoliosV2,
    enableCashManagementV2,
    enableClientAltAssets,
    enableTransferAppVisibilityBadge,
    enableCashManagementV2VisibilityBadge,
    enableRightCapitalLink,
    enableCustodianPortalLink,
    enableAdvisorLiquidation,
    enableInsuranceMarketplace,
    enableLendingMarketplace,
    enableGoalBasedFinancialPlanning,
  } = useFlags();

  const hasSchwabAccount = (allCustodians.data ?? []).includes("Schwab");
  const hasFidelityAccount = (allCustodians.data ?? []).includes("Fidelity");

  return (
    <>
      <NavItem
        iconName="Home"
        text={"Home"}
        path={`/Client/${clientId}/Dashboard`}
        isHidden={false}
        mixpanelEvent={{ name: "Client Click Home" }}
      />

      <NavItem
        iconName="Accounts"
        text={"Accounts"}
        path={`/Client/${clientId}/${AccountsPath}`}
        isHidden={false}
        mixpanelEvent={{ name: "Client Click Accounts" }}
      />

      <NavItem
        iconName="Documents"
        text={"Documents"}
        path={`/Client/${clientId}/${DocumentsCenterPath}`}
        isHidden={false}
        mixpanelEvent={{ name: "Client Click Documents" }}
      />

      <SharingNotificationsNavItemWrapper />

      {enableGoalBasedFinancialPlanning && (
        <NavItem
          iconName="FinancialPlans"
          text={"Financial Plans"}
          path={`/Client/${clientId}/${FinancialPlansPath}`}
          isHidden={false}
        />
      )}

      {(enablePortfoliosV2 || enableClientAltAssets) && (
        <NavItem
          iconName="Investments"
          text={"Investments"}
          path={`/Client/${clientId}/${InvestmentsPath}`}
          isHidden={false}
          mixpanelEvent={{ name: "Client Click Investments" }}
        />
      )}

      {enableInsuranceMarketplace && (
        <NavItem
          iconName="Insurance"
          text={"Insurance"}
          path={`/Client/${clientId}/${InsuranceMarketplacePath}`}
          isHidden={false}
          mixpanelEvent={{ name: "Client Click Insurance-Marketplace" }}
        />
      )}

      {enableLendingMarketplace && (
        <NavItem
          iconName="Lending"
          text={"Lending"}
          path={`/Client/${clientId}/${LendingMarketplacePath}`}
          isHidden={false}
          mixpanelEvent={{ name: "Client Click Lending-Marketplace" }}
        />
      )}

      {enableCashManagementV2 && (
        <NavItem
          iconName="CashManagement"
          text={"Cash Management"}
          path={`/Client/${clientId}/${CashManagementPath}`}
          isHidden={enableCashManagementV2VisibilityBadge}
          mixpanelEvent={{ name: "Client Click Cash-Management" }}
        />
      )}

      {enableTransferApp && (enableOneTimeAch || enableOneTimeAcats) && (
        <NavItem
          iconName="Transfers"
          text={"Transfers"}
          path={`/Client/${clientId}/Transfers`}
          isHidden={enableTransferAppVisibilityBadge}
          mixpanelEvent={{ name: "Client Click Transfers" }}
        />
      )}

      <NavItem
        iconName="TaxBudgets"
        text={"Tax Budgets"}
        path={`/Client/${clientId}/TaxBudgets`}
        isHidden={false}
        mixpanelEvent={{ name: "Client Click Tax-Budgets" }}
      />

      {enableAdvisorLiquidation && (
        <NavItem
          iconName="RaiseCash"
          text={"Raise Cash"}
          path={`/Client/${clientId}/${LiquidationPath}`}
          isHidden={true}
          mixpanelEvent={{ name: "Client Click Raise-Cash" }}
        />
      )}

      {enableRightCapitalLink && (
        <NavItem
          iconName="RaiseCash"
          text={"Right Capital"}
          path={"https://app.rightcapital.com/account/login"}
          isHidden={false}
          toOutsideLink={true}
          mixpanelEvent={{ name: "Client Click Right-Capital" }}
        />
      )}

      {hasSchwabAccount && enableCustodianPortalLink && (
        <NavItem
          iconName="RaiseCash"
          text={"Charles Schwab"}
          path={
            "https://client.schwab.com/login/signon/customercenterlogin.aspx"
          }
          isHidden={false}
          toOutsideLink={true}
          mixpanelEvent={{ name: "Client Click Charles-Schwab" }}
        />
      )}

      {hasFidelityAccount && enableCustodianPortalLink && (
        <NavItem
          iconName="RaiseCash"
          text={"Fidelity"}
          path={"https://digital.fidelity.com/prgw/digital/login/full-page"}
          isHidden={false}
          toOutsideLink={true}
          mixpanelEvent={{ name: "Client Click Fidelity" }}
        />
      )}
    </>
  );
};

export default ClientNavMenu;
