import React, { useMemo, useState } from "react";

import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { Stack } from "@mui/material";
import { FormProvider, useForm, useFormState, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useElementSize } from "usehooks-ts";

import useCreateSleeve from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useCreateSleeve";
import useUpdateSleeve from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useUpdateSleeve";
import {
  SecurityWeight,
  SleeveCreateUpdateBody,
} from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";
import {
  AdvisorId,
  ClientId,
  SleeveId,
  Ticker,
} from "@fartherfinance/frontend/api/Types";

import BottomBar from "../../SharedComponents/BottomBar/BottomBar";
import minBottomMargin from "../../SharedComponents/constants/minBottomMargin";
import PageTitle from "../../SharedComponents/PageTitle";
import {
  SelectedSecurity,
  USDTicker,
} from "../../SharedComponents/SearchSecurities/Types";
import BackButton from "@src/multiCustodian/components/Client/BackButton";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import { weightAdder } from "@src/utils/weightAdder";

import SecuritySearch from "./SecuritySearch";

import styles from "./CreateSleeve.module.css";

const SleeveNameField = "SleeveName";
const CashField = "Cash";
const SecurityPrefix = "Security-";

type SecurityFieldName = `${typeof SecurityPrefix}${Ticker}`;

type SecurityField = [SecurityFieldName, string];

const isSecurityField = (field: [string, unknown]): field is SecurityField => {
  return field[0].includes(SecurityPrefix) && typeof field[1] === "string";
};

const isValidSecurity = (sec: {
  securityIdentifier: Ticker;
  weight: number | null;
}): sec is SecurityWeight => {
  return sec.weight !== null;
};

interface Form {
  [SleeveNameField]: string | undefined;
  [CashField]: string;
  [s: SecurityFieldName]: string | undefined;
}

interface Props {
  isUpdate: boolean;
  currentSecurities: SecurityWeight[] | null;
  sleeveName: string | undefined;
  goToCSVUpload: () => void;
  sleeveId?: SleeveId;
  sleeveOwnerId?: AdvisorId | ClientId;
  cameFromUpload: boolean; // need this to negate isDirty -> if you just uploaded a valid csv the form wont be dirty and we want the submit button to be clickable right away
}

const CreateUpdateSleeveForm = (props: Props): JSX.Element => {
  const [bottomBarRef, { height }] = useElementSize();

  const auth = useAdvisorRequestAuth();

  const history = useHistory();

  const [mutating, setMutating] = useState(false);

  const [selectedSecurities, setSelectedSecurities] = useState<
    SelectedSecurity[]
  >(
    props.currentSecurities === null
      ? []
      : props.currentSecurities
          .filter((s) => s.securityIdentifier !== USDTicker)
          .map((s) => ({
            // if there is a "." in the ticker, setValue from useForm will evaluate that . as a property and break apart the key/string into an obj
            ticker: s.securityIdentifier.replace(/\./g, "*") as Ticker,
            displayName: null,
          }))
  );

  const createSleeve = useCreateSleeve(auth);
  const updateSleeve = useUpdateSleeve(auth);

  const statusNotification = useStatusNotification();

  const defaultValues: Partial<Form> = useMemo(() => {
    return {
      [SleeveNameField]: props.sleeveName,
      [CashField]: "0",
      ...(props.currentSecurities !== null &&
        props.currentSecurities.reduce((formValues, sec) => {
          if (sec.securityIdentifier === USDTicker) {
            //CSV may include US Dollars, we use this value for Cash instead of our default
            formValues[CashField] = (sec.weight / 100).toString(); //weights come in as integers from 1 - 10000
          } else {
            // if there is a "." in the ticker, setValue from useForm will evaluate that . as a property and break apart the key/string into an obj
            const securityFieldName =
              `${SecurityPrefix}${sec.securityIdentifier.replace(
                /\./g,
                "*"
              )}` as SecurityFieldName;
            formValues[securityFieldName] = (sec.weight / 100).toString();
          }

          return formValues;
        }, {} as Partial<Form>)),
    };
  }, [props.currentSecurities, props.sleeveName]);

  const methods = useForm<Form>({
    mode: "all",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    defaultValues,
  });

  const { control, getValues, trigger, setValue } = methods;

  const { isValid, errors, isDirty } = useFormState({
    control,
  });

  const securityErrors = useMemo(() => {
    return Object.entries(errors).filter(isSecurityField);
  }, [errors]);

  const formValues = useWatch({ control: control });

  const formSecurities = useMemo(
    () => Object.entries(formValues).filter(isSecurityField),
    [formValues]
  );

  const total = useMemo(() => {
    return weightAdder<SecurityField>((s) => s[1])([
      ...formSecurities,
      [CashField as SecurityFieldName, formValues[CashField] ?? "NaN"],
    ]);
  }, [formSecurities, formValues]);

  const addSecurity = (sec: SelectedSecurity) => {
    const dotCharInTicker = sec.ticker.includes(".");
    // if there is a "." in the ticker, setValue from useForm will evaluate that . as a property and break apart the key/string into an obj
    const sanitizedTicker = dotCharInTicker
      ? (sec.ticker.replace(/\./g, "*") as Ticker)
      : sec.ticker;

    if (selectedSecurities.some((s) => s.ticker === sanitizedTicker)) {
      statusNotification("Security already added", "Success");
      return;
    }

    setSelectedSecurities([
      ...selectedSecurities,
      { ticker: sanitizedTicker, displayName: sec.displayName ?? "" },
    ]);

    setValue(`${SecurityPrefix}${sanitizedTicker}`, "0");

    trigger(formSecurities.map((s) => s[0])); //only trigger security fields
  };

  const removeSecurity = (ticker: Ticker) => {
    if (formValues[`${SecurityPrefix}${ticker}`] !== undefined) {
      setValue(`${SecurityPrefix}${ticker}`, undefined);
    } //must reset the value in the form as well

    setSelectedSecurities(
      selectedSecurities.filter((s) => s.ticker !== ticker)
    );

    trigger(formSecurities.map((s) => s[0])); //only trigger security fields
  };

  const callCreateSleeve = async () => {
    if (!isValid) {
      statusNotification("Form is incomplete", "Error");
      return;
    }

    if (total !== 100) {
      statusNotification("Allocation total must be 100%", "Error");
      return;
    }

    if (!isDirty && !props.cameFromUpload) {
      statusNotification("Nothing to submit", "Error");
      return;
    }

    if (mutating) {
      statusNotification("Form is being submitted", "Error");
      return;
    }

    const values = getValues();

    if (values[SleeveNameField] === undefined || auth === null) {
      return;
    }

    const securities: SecurityWeight[] = selectedSecurities
      .map((sec) => {
        const weight = values[`${SecurityPrefix}${sec.ticker}`];

        const weightParsed =
          weight === undefined ? null : parseFloat(weight ?? "0");

        // if there is a "." in the ticker, setValue from useForm will evaluate that . as a property and break apart the key/string into an obj, so use "*" as replacement for ".", now need to switch back to "."
        return {
          securityIdentifier: sec.ticker.replace(/\*/g, ".") as Ticker,
          weight:
            weightParsed !== null && !isNaN(weightParsed)
              ? Math.round(weightParsed * 100)
              : null,
        };
      })
      .filter(isValidSecurity);

    //Don't send cash if its 0%
    const isCash = parseFloat(values[CashField]) > 0;
    if (isCash) {
      securities.push({
        securityIdentifier: USDTicker,
        weight: Math.round(parseFloat(values[CashField]) * 100),
      });
    }

    const body: SleeveCreateUpdateBody = {
      displayName: values[SleeveNameField],
      ownerId: auth.advisorId,
      description: "",
      positions: {
        securities: securities,
      },
    };

    try {
      setMutating(true);

      const res = await createSleeve(body);

      statusNotification("Sleeve created", "Success");

      setMutating(false);
      history.push({
        pathname: "/Advisor/Investments/Sleeves",
        state: history.location.state,
      });

      const newSleeveId = res.message;
      if (newSleeveId) {
        setMutating(false);
        history.push({
          pathname: `/Advisor/Investments/Sleeves/${newSleeveId}/SleeveDetails`,
          state: history.location.state,
        });
      } else {
        setMutating(false);
        history.push({
          pathname: "/Advisor/Investments/Sleeves",
          state: history.location.state,
        });
      }
    } catch {
      statusNotification("Failed to create sleeve", "Error");
      setMutating(false);
    }
  };

  const callUpdateSleeve = async () => {
    if (!props.sleeveId || !props.sleeveOwnerId) {
      statusNotification("No sleeveId present", "Error");
      return;
    }

    if (!isValid) {
      statusNotification("Form is incomplete", "Error");
      return;
    }

    if (total !== 100) {
      statusNotification("Allocation total must be 100%", "Error");
      return;
    }

    if (!isDirty && !props.cameFromUpload) {
      statusNotification("Nothing to submit", "Error");
      return;
    }

    if (mutating) {
      statusNotification("Form is being submitted", "Error");
      return;
    }

    const values = getValues();

    if (values[SleeveNameField] === undefined || auth === null) {
      return;
    }

    const securities: SecurityWeight[] = selectedSecurities
      .map((sec) => {
        const weight = values[`${SecurityPrefix}${sec.ticker}`];

        const weightParsed =
          weight === undefined ? null : parseFloat(weight ?? "0");

        // if there is a "." in the ticker, setValue from useForm will evaluate that . as a property and break apart the key/string into an obj, so use "*" as replacement for ".", now need to switch back to "."
        return {
          securityIdentifier: sec.ticker.replace(/\*/g, ".") as Ticker,
          weight:
            weightParsed !== null && !isNaN(weightParsed)
              ? Math.round(weightParsed * 100)
              : null,
        };
      })
      .filter(isValidSecurity);

    //Don't send cash if its 0%
    const isCash = parseFloat(values[CashField]) > 0;
    if (isCash) {
      securities.push({
        securityIdentifier: USDTicker,
        weight: Math.round(parseFloat(values[CashField]) * 100),
      });
    }

    const body: SleeveCreateUpdateBody = {
      displayName: values[SleeveNameField],
      ownerId: props.sleeveOwnerId,
      description: "",
      positions: {
        securities: securities,
      },
    };

    try {
      setMutating(true);

      await updateSleeve({ sleeveId: props.sleeveId, body: body });

      statusNotification("Sleeve update", "Success");

      setMutating(false);
      history.push({
        pathname: `/Advisor/Investments/Sleeves/${props.sleeveId}`,
        state: history.location.state,
      });
    } catch {
      statusNotification("Failed to update sleeve", "Error");
      setMutating(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.centeringContainer}>
        <BackButton
          onClick={() =>
            history.push({
              pathname: "/Advisor/Investments/Sleeves",
              state: history.location.state,
            })
          }
        />

        <Spacer verticalSpacing="8px" />

        <div className={styles.bodyContainer}>
          {mutating && (
            <div className={styles.loading}>
              <LogoLoadingStill onTop />
            </div>
          )}

          <PageTitle>
            {props.isUpdate ? "Update Sleeve" : "Create a Sleeve"}
          </PageTitle>

          <Spacer verticalSpacing="32px" />

          <FormProvider {...methods}>
            <FormTextField
              name={SleeveNameField}
              label="Sleeve Name"
              required="Must not be empty"
            />

            <Spacer verticalSpacing="24px" />

            <SecuritySearch
              selectedSecurities={selectedSecurities}
              addSecurity={addSecurity}
              removeSecurity={removeSecurity}
              total={total}
            />
          </FormProvider>

          {securityErrors.length > 0 || total !== 100 ? (
            <div className={styles.errorBlock}>
              <WarningAmberRoundedIcon className={styles.warningIcon} />

              <span className={styles.warningText}>
                {securityErrors.length > 0
                  ? securityErrors[0][1]?.message ?? "Unknown error"
                  : "Sum must add up to 100%"}
              </span>
            </div>
          ) : null}
        </div>

        {/* Ensure we can see the bottom of bodyContainer, since BottomBar has position: fixed */}
        <Spacer verticalSpacing={`${height + minBottomMargin}px`} />

        <BottomBar ref={bottomBarRef}>
          <ButtonPrimary
            text="Upload"
            variant="outlined"
            onClick={props.goToCSVUpload}
            buttonType="primary"
          />

          <Stack direction="row" justifyContent={"flex-end"} gap="16px">
            {props.isUpdate ? (
              <>
                <ButtonPrimary
                  text="Cancel"
                  variant="outlined"
                  onClick={() =>
                    props.sleeveId
                      ? history.push({
                          pathname: `/Advisor/Investments/Sleeves/${props.sleeveId}`,
                          state: history.location.state,
                        })
                      : history.push({
                          pathname: "/Advisor/Investments/Sleeves",
                          state: history.location.state,
                        })
                  }
                  buttonType="primary"
                />

                <ButtonPrimary
                  text="Update Sleeve"
                  variant="contained"
                  onClick={callUpdateSleeve}
                  buttonType="primary"
                  disabled={
                    mutating ||
                    !isValid ||
                    (!isDirty && !props.cameFromUpload) ||
                    total !== 100
                  }
                />
              </>
            ) : (
              <>
                <ButtonPrimary
                  text="Cancel"
                  variant="outlined"
                  onClick={() =>
                    history.push({
                      pathname: "/Advisor/Investments/Sleeves",
                      state: history.location.state,
                    })
                  }
                  buttonType="primary"
                />

                <ButtonPrimary
                  text="Create Sleeve"
                  variant="contained"
                  onClick={callCreateSleeve}
                  buttonType="primary"
                  disabled={
                    mutating ||
                    !isValid ||
                    (!isDirty && !props.cameFromUpload) ||
                    total !== 100
                  }
                />
              </>
            )}
          </Stack>
        </BottomBar>
      </div>
    </div>
  );
};

export default CreateUpdateSleeveForm;
