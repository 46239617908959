import React, { Fragment, useCallback, useMemo, useState } from "react";

import { format, parse } from "date-fns";
import { random, uniq } from "lodash";
import {
  DefaultValues,
  FieldPath,
  FormProvider,
  useForm,
  useFormState,
  useWatch,
} from "react-hook-form";

import usePlanDetailsV2 from "@fartherfinance/frontend/api/Accounts/hooks/usePlanDetailsV2";
import useSetPlanAccountInfoV2 from "@fartherfinance/frontend/api/Accounts/hooks/useSetPlanAccountInfoV2";
import useSetPlanAccountPeopleV2 from "@fartherfinance/frontend/api/Accounts/hooks/useSetPlanAccountPeopleV2";
import { PlanDetails } from "@fartherfinance/frontend/api/Accounts/requests/getPlanDetailsV2";
import { AccountInfoIRAWithoutRetirement } from "@fartherfinance/frontend/api/Accounts/requests/setPlanAccountInfoV2";
import { RetirementBody } from "@fartherfinance/frontend/api/Accounts/requests/setPlanAccountPeopleV2";
import { Account } from "@fartherfinance/frontend/api/Accounts/Types";
import useAdvisorClients from "@fartherfinance/frontend/api/Entity/hooks/useAdvisorClients";
import useClientBeneficiaries from "@fartherfinance/frontend/api/Entity/hooks/useClientBeneficiaries";
import { Beneficiary } from "@fartherfinance/frontend/api/Entity/requests/getClientBeneficiaries";
import {
  BeneficiaryId,
  ClientId,
  FartherAccountId,
  PlanId,
} from "@fartherfinance/frontend/api/Types";

import DrawerHeader from "../Components/DrawerHeader";
import useTriggerForm from "../hooks/useTriggerForm";
import { dateFormat } from "@src/constants/dateFormat";
import Drawer from "@src/multiCustodian/components/Drawer/Drawer";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FlexWrapperBeneficiaries from "@src/sharedComponents/Forms/FlexWrapperBeneficiaries";
import FormAccountHolderDropdownField, {
  labelMaker as accountLabelMaker,
  AutocompleteClient,
} from "@src/sharedComponents/Forms/FormAccountHolderDropdownField";
import ClientBeneficiaryDropdown, {
  labelMaker as beneficiaryLabelMaker,
  FormBeneficiary,
} from "@src/sharedComponents/Forms/FormClientBeneficiaryDropdownField";
import FormDateField from "@src/sharedComponents/Forms/FormDateField";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import FormH2 from "@src/sharedComponents/Forms/FormH2";
import FormNumberField from "@src/sharedComponents/Forms/FormNumberField";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import { extractAxiosErrorMessage, isAxiosErrorCode } from "@src/utils/axios";

import styles from "./Form.module.css";
import planStyles from "../plans.module.css";

type BeneficiarySelection = `Beneficiary${number}`;
type BeneficiaryPercent = `BeneficiaryPercent${number}`;
type BeneficiaryType = `BeneficiaryType${number}`;

type DefaultBeneficiaryForMapping = {
  beneficiaryId: ClientId;
  placeholderId: number;
};

type BeneficiaryForMapping = {
  placeholderId: number;
};

type Form = {
  TargetGoal: string | undefined;
  TargetDate: Date | undefined;
  PrimaryAccountHolder: AutocompleteClient | undefined;
} & {
  [_k in BeneficiarySelection]: FormBeneficiary | undefined;
} & {
  [_k in BeneficiaryPercent]: string | undefined;
} & {
  [_k in BeneficiaryType]: { label: "Primary" | "Contingent" } | undefined;
};

type StripUndefined<F extends object> = { [k in keyof F]: NonNullable<F[k]> };

const randomId = () => random(10000, 99999);

interface Props {
  planId: PlanId;
  accountId: FartherAccountId;
  onClose: () => void;
  account: Account;
}

export default function TraditionalIRA(props: Props): JSX.Element {
  const auth = useAdvisorRequestAuth();
  const clients = useAdvisorClients(undefined, auth);

  const planDetails = usePlanDetailsV2(props.planId, auth);

  const clientBeneficiaries = useClientBeneficiaries(
    planDetails.data?.plan.planHolder.clientId ?? null,
    auth
  );

  if (
    planDetails.isLoading ||
    clientBeneficiaries.isLoading ||
    clients.isLoading
  ) {
    return (
      <Drawer
        header={
          <DrawerHeader planId={props.planId} accountId={props.accountId} />
        }
        footer={
          <Footer
            onSave={() => undefined}
            saveDisabled={true}
            closeDrawer={props.onClose}
            isMutating={false}
            planComplete={false}
          />
        }
        isDrawerOpen
        onClose={props.onClose}
      >
        <div className={planStyles.loadingContainer}>
          <LogoLoadingStill />
        </div>
      </Drawer>
    );
  }

  if (
    planDetails.hasError ||
    clientBeneficiaries.hasError ||
    clients.hasError
  ) {
    return (
      <Drawer
        header={
          <DrawerHeader planId={props.planId} accountId={props.accountId} />
        }
        footer={
          <Footer
            onSave={() => undefined}
            saveDisabled={true}
            closeDrawer={props.onClose}
            isMutating={false}
            planComplete={false}
          />
        }
        isDrawerOpen
        onClose={props.onClose}
      >
        <div>Error</div>
      </Drawer>
    );
  }

  return (
    <IRAForm
      planId={props.planId}
      accountId={props.accountId}
      planDetails={planDetails.data}
      accountDetails={props.account}
      beneficiaries={clientBeneficiaries.data}
      onClose={props.onClose}
    />
  );
}

const nonEmptyValue = (value: string | undefined | null) => {
  return value !== undefined && value !== "" && value !== null;
};

interface IRAFormProps {
  planId: PlanId;
  accountId: FartherAccountId;
  planDetails: PlanDetails;
  accountDetails: Account;
  beneficiaries: Beneficiary[];
  onClose: () => void;
}

const IRAForm = (props: IRAFormProps): JSX.Element => {
  const statusNotification = useStatusNotification();

  const [isMutating, setIsMutating] = useState(false);

  const {
    defaultBeneficiaries,
    defaultTargetGoal,
    defaultTargetDate,
    defaultPrimaryHolder,
  } = useMemo(() => {
    const accountBeneficiaries =
      props.accountDetails.accountPeople.beneficiaries;

    const defaultBeneficiaries: DefaultBeneficiaryForMapping[] =
      accountBeneficiaries !== undefined
        ? accountBeneficiaries.map((b) => ({
            beneficiaryId: b.beneficiaryId,
            placeholderId: randomId(),
          }))
        : [];

    const accountTarget =
      props.accountDetails.accountInformation.accountTarget ?? null;

    const defaultTargetGoal = accountTarget ? `${accountTarget.goal}` : "";

    const defaultTargetDate = accountTarget
      ? parse(accountTarget.date, dateFormat, new Date())
      : undefined;

    const defaultPrimaryHolder = props.planDetails.plan.planHolder;

    return {
      defaultBeneficiaries,
      defaultTargetGoal,
      defaultTargetDate,
      defaultPrimaryHolder,
    };
    // This should only be run on mount when we can set default values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [beneficiaries, setBeneficiaries] = useState<BeneficiaryForMapping[]>(
    // All plans require at least 1 beneficiary
    defaultBeneficiaries.length > 0
      ? defaultBeneficiaries
      : [{ placeholderId: randomId() }]
  );

  const planComplete = props.planDetails.plan.planStatus === "Complete";

  const defaultValues: DefaultValues<Form> = useMemo(() => {
    const dv: DefaultValues<Form> = {
      TargetGoal: defaultTargetGoal,
      TargetDate: defaultTargetDate,
    };

    defaultBeneficiaries.forEach((b) => {
      const beneficiary =
        props.accountDetails.accountPeople.beneficiaries?.find(
          (el) => el.beneficiaryId === b.beneficiaryId
        );

      if (beneficiary === undefined) {
        // Should be am exception...
        return;
      }

      dv[`Beneficiary${b.placeholderId}`] = {
        label: beneficiaryLabelMaker(beneficiary),
        beneficiaryId: beneficiary.beneficiaryId,
      };
      dv[`BeneficiaryPercent${b.placeholderId}`] = `${
        beneficiary.allocationPercentage ?? 0
      }`;
      dv[`BeneficiaryType${b.placeholderId}`] = {
        label: beneficiary.beneficiaryType,
      };
    });

    return dv;
    // This should only be run on mount when we can set default values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const methods = useForm<Form>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues,
  });

  const { control, getValues, trigger, unregister } = methods;

  const { isValid } = useFormState({
    control,
  });

  const targetGoalValue = useWatch<Form>({ control, name: "TargetGoal" });
  const primaryAccountHolder = useWatch<Form>({
    control,
    name: "PrimaryAccountHolder",
  });

  const targetGoalNonEmpty =
    typeof targetGoalValue !== "string"
      ? false
      : nonEmptyValue(targetGoalValue);

  const targetDateValue = useWatch<Form>({ control, name: "TargetDate" }) as
    | Date
    | undefined;
  const targetDateNotEmpty = targetDateValue !== undefined;

  const auth = useAdvisorRequestAuth();
  const callPutPlanAccountInfo = useSetPlanAccountInfoV2(auth);
  const callPutPlanAccountPeople = useSetPlanAccountPeopleV2(auth);

  const needsManualTriggering: FieldPath<Form>[] = useMemo(
    () => [
      "TargetDate",
      "TargetGoal",
      ...beneficiaries.map(
        ({ placeholderId: id }): FieldPath<Form> => `BeneficiaryPercent${id}`
      ),
    ],
    [beneficiaries]
  );
  useTriggerForm<Form>(control, trigger, needsManualTriggering);

  const submit = useCallback(async () => {
    if (!isValid) {
      return;
    }

    const values = getValues() as StripUndefined<Form>;

    const accountForm: AccountInfoIRAWithoutRetirement = {
      accountTitle: props.accountDetails.accountInformation.accountTitle, //Account titles for all IRAs are set by the backend.
      accountType: "IRA",
      retirementData: {},
      accountTarget: targetGoalNonEmpty
        ? {
            goal: parseFloat(values.TargetGoal),
            date: format(values.TargetDate, dateFormat),
          }
        : null,
    };

    const peopleForm: RetirementBody = {
      accountHolderId: props.planDetails.plan.planHolder.clientId,
      beneficiaries: beneficiaries.map((beni) => {
        const type = values[`BeneficiaryType${beni.placeholderId}`].label;
        const beneficiaryId =
          values[`Beneficiary${beni.placeholderId}`].beneficiaryId;
        const allocation = values[`BeneficiaryPercent${beni.placeholderId}`];

        return {
          beneficiaryId: beneficiaryId as unknown as ClientId,
          allocation: parseFloat(allocation),
          beneficiaryType: type,
        };
      }),
    };

    try {
      setIsMutating(true);

      await callPutPlanAccountInfo({
        planId: props.planId,
        virtualAccountId: props.accountId,
        accountData: accountForm,
      });

      await callPutPlanAccountPeople({
        planId: props.planId,
        virtualAccountId: props.accountId,
        people: peopleForm,
      });

      statusNotification("Account updated", "Success");
      props.onClose();
    } catch (error) {
      const errorMessage = isAxiosErrorCode(400, error)
        ? extractAxiosErrorMessage(error)
        : "Error updating account";

      statusNotification(errorMessage, "Error");
      setIsMutating(false);
    }
  }, [
    beneficiaries,
    callPutPlanAccountInfo,
    callPutPlanAccountPeople,
    getValues,
    isValid,
    props,
    targetGoalNonEmpty,
    statusNotification,
  ]);

  const defaultPrimary = useMemo(
    () => ({
      clientId: defaultPrimaryHolder.clientId,
      label: accountLabelMaker(defaultPrimaryHolder),
    }),
    [defaultPrimaryHolder]
  );

  return (
    <Drawer
      header={
        <DrawerHeader planId={props.planId} accountId={props.accountId} />
      }
      footer={
        <Footer
          onSave={submit}
          saveDisabled={!isValid}
          closeDrawer={props.onClose}
          isMutating={isMutating}
          planComplete={planComplete}
        />
      }
      isDrawerOpen
      onClose={props.onClose}
    >
      <FormProvider {...methods}>
        <FormH1>Traditional IRA Account</FormH1>
        <Spacer />
        <FormH2>ACCOUNT</FormH2>

        <FlexWrapper>
          <FormNumberField
            name={"TargetGoal"}
            startAdornment={"$"}
            required={
              targetDateNotEmpty
                ? "This is required when Target Date is set"
                : undefined
            }
            label="Target Goal (Required when Date set)"
          />

          <FormDateField
            required={
              targetGoalNonEmpty
                ? "This is required when Target Goal is set"
                : undefined
            }
            rules={{ deps: ["TargetGoal"] }}
            dateCheck={"AfterToday"}
            label="Target Date (Required when Goal set)"
            name={"TargetDate"}
          />
        </FlexWrapper>

        <Spacer />

        <FormH2>PEOPLE</FormH2>

        <FlexWrapper>
          <FormAccountHolderDropdownField
            label={"Primary Account Holder"}
            name={"PrimaryAccountHolder"}
            required="Must not be empty"
            // As the data is pulled dynamically we need can't provide default values in `useForm`
            defaultValue={defaultPrimary}
            onChange={() => {
              const oldBeneficiaries = [...beneficiaries];

              oldBeneficiaries.forEach((b) => {
                unregister(`Beneficiary${b.placeholderId}`);
                unregister(`BeneficiaryType${b.placeholderId}`);
                unregister(`BeneficiaryPercent${b.placeholderId}`);
              });

              setBeneficiaries([{ placeholderId: randomId() }]);
            }}
          />
        </FlexWrapper>

        <Spacer />

        <ButtonPrimary
          text={"Add beneficiary"}
          onClick={() =>
            setBeneficiaries([
              ...beneficiaries,
              {
                placeholderId: randomId(),
              },
            ])
          }
          variant={"contained"}
          buttonType={"primary"}
          disabled={props.beneficiaries.length <= beneficiaries.length}
          style={{ justifyContent: "flex-start" }}
        />

        {beneficiaries.map((beneficiary, index, arr) => (
          <Fragment key={beneficiary.placeholderId}>
            <FlexWrapper>
              <FormH2>Beneficiary {index + 1}</FormH2>

              {/* Once a plan is complete we can't change the beneficiaries,
               * We must have at least 1 primary beneficiary for an IRA
               */}
              {arr.length > 1 && (
                <ButtonPrimary
                  text={"Remove this beneficiary"}
                  onClick={() => {
                    const newBeneficiaries = beneficiaries.filter(
                      (beni) => beni.placeholderId !== beneficiary.placeholderId
                    );
                    setBeneficiaries(newBeneficiaries);

                    unregister(`Beneficiary${beneficiary.placeholderId}`);
                    unregister(`BeneficiaryType${beneficiary.placeholderId}`);
                    unregister(
                      `BeneficiaryPercent${beneficiary.placeholderId}`
                    );

                    // Delay for react-hook-form to catch up with conditionally rendered
                    // This stops a bug where deleting a beneficiary does not cause a
                    // re-calc of allocation percentages
                    setTimeout(() => {
                      // re-run validation on percents
                      newBeneficiaries.forEach((b) => {
                        trigger(`BeneficiaryPercent${b.placeholderId}`);
                      });
                    }, 100);
                  }}
                  variant={"contained"}
                  buttonType={"primary"}
                  style={{ justifyContent: "flex-end" }}
                />
              )}
            </FlexWrapper>

            <FlexWrapperBeneficiaries>
              <ClientBeneficiaryDropdown
                name={`Beneficiary${beneficiary.placeholderId}`}
                clientId={
                  typeof primaryAccountHolder === "object" &&
                  "clientId" in primaryAccountHolder
                    ? primaryAccountHolder.clientId
                    : props.planDetails.plan.planHolder.clientId
                }
                label={"Beneficiary"}
                required="Must not be empty"
                rules={{
                  deps: [
                    "PrimaryAccountHolder",
                    ...beneficiaries.map(
                      (b): BeneficiarySelection =>
                        `Beneficiary${b.placeholderId}`
                    ),
                  ],
                  validate: {
                    singletons: () => {
                      const values = getValues();

                      const uniqBeneficiaries = uniq(
                        beneficiaries
                          .map(
                            (b): BeneficiarySelection =>
                              `Beneficiary${b.placeholderId}`
                          )
                          .map(
                            (b): BeneficiaryId | undefined =>
                              values[b]?.beneficiaryId
                          )
                      );

                      return uniqBeneficiaries.length === beneficiaries.length
                        ? true
                        : "Each beneficiary must be unique";
                    },
                  },
                }}
              />

              <FormDropdownField
                name={`BeneficiaryType${beneficiary.placeholderId}`}
                label={"Type"}
                required="Must not be empty"
                values={[{ label: "Primary" }, { label: "Contingent" }]}
                rules={{
                  deps: [
                    "PrimaryAccountHolder",
                    ...beneficiaries.map(
                      (b): BeneficiaryType =>
                        `BeneficiaryType${b.placeholderId}`
                    ),
                  ],
                  validate: {
                    atLeaseOnePrimary: () => {
                      const values = getValues();

                      const allTypes = beneficiaries
                        .map(
                          (b): BeneficiaryType =>
                            `BeneficiaryType${b.placeholderId}`
                        )
                        .map(
                          (b): "Primary" | "Contingent" | undefined =>
                            values[b]?.label
                        );

                      return allTypes.find((el) => el === "Primary") !==
                        undefined
                        ? true
                        : "At least 1 beneficiary must be Primary";
                    },
                  },
                }}
              />

              <FormNumberField
                name={`BeneficiaryPercent${beneficiary.placeholderId}`}
                label="Percent"
                required="Percent can't be empty"
                rules={{
                  deps: beneficiaries.flatMap(
                    // If the type changes for any beneficiary, we need to re-calculate
                    // If the allocation value changes, we need to re-calculate
                    (b): (BeneficiaryPercent | BeneficiaryType)[] => [
                      `BeneficiaryPercent${b.placeholderId}`,
                      `BeneficiaryType${b.placeholderId}`,
                    ]
                  ),
                  validate: {
                    sumIs100: () => {
                      const values = getValues();

                      const currentType: "Primary" | "Contingent" | undefined =
                        values[`BeneficiaryType${beneficiary.placeholderId}`]
                          ?.label;

                      if (currentType === undefined) {
                        return true;
                      }

                      const sum = beneficiaries
                        .filter(
                          (b) =>
                            values[`BeneficiaryType${b.placeholderId}`]
                              ?.label === currentType
                        )
                        .map((b) =>
                          Number(values[`BeneficiaryPercent${b.placeholderId}`])
                        )
                        .reduce(
                          (prev, curr) => prev + (isNaN(curr) ? 0 : curr),
                          0
                        );

                      return sum === 100
                        ? true
                        : `All ${currentType} Beneficiary Percents must add up to 100, now at ${sum}`;
                    },
                  },
                }}
              />
            </FlexWrapperBeneficiaries>
          </Fragment>
        ))}
      </FormProvider>

      {isMutating && (
        <div className={styles.loading}>
          <LogoLoadingStill />
        </div>
      )}
    </Drawer>
  );
};

interface FooterProps {
  closeDrawer: () => void;
  saveDisabled: boolean;
  onSave: () => void;
  isMutating: boolean;
  planComplete: boolean;
}

const Footer = (props: FooterProps): JSX.Element => {
  return (
    <div className={styles.footer}>
      <ButtonPrimary
        text={"Cancel"}
        onClick={props.closeDrawer}
        variant={"outlined"}
        buttonType={"primary"}
      />

      <ButtonPrimary
        text={"Save"}
        onClick={props.onSave}
        variant={"contained"}
        buttonType={"primary"}
        disabled={props.planComplete || props.saveDisabled || props.isMutating}
      />
    </div>
  );
};
