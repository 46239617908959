import React from "react";

import useSMAAsset, {
  SMAAssetId,
} from "@fartherfinance/frontend/api/AltAssetsService/hooks/useSMAAsset";

import Drawer from "@src/multiCustodian/components/Drawer/Drawer";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";

import SMADetailsBody from "./SMADetailsBody/SMADetailsBody";
import SMADetailsBodyLoading from "./SMADetailsBody/SMADetailsBodyLoading";
import SMADropdown from "./SMADropdown";

import styles from "./SMADetails.module.css";

interface Props {
  SMAId: SMAAssetId;
  onClose: () => void;
  selectDifferentSMA: (smaId: string) => void;
  applyToAccount: () => void;
}

const SMADetails = (props: Props): JSX.Element => {
  const auth = useAdvisorRequestAuth();

  const currentSMA = useSMAAsset(props.SMAId, auth);

  return (
    <Drawer
      isDrawerOpen
      onClose={props.onClose}
      transitionDuration={0}
      header={
        <SMADropdown smaId={props.SMAId} onChange={props.selectDifferentSMA} />
      }
      footer={
        <ButtonPrimary
          text="Apply to Account"
          variant="contained"
          buttonType="primary"
          disabled={currentSMA.isLoading || currentSMA.hasError}
          onClick={props.applyToAccount}
        />
      }
    >
      {currentSMA.isLoading ? (
        <SMADetailsBodyLoading />
      ) : currentSMA.hasError ? (
        <div className={styles.text}>Error getting SMA details</div>
      ) : (
        <SMADetailsBody smaId={props.SMAId} />
      )}
    </Drawer>
  );
};

export default SMADetails;
