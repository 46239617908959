/* eslint-disable react/prop-types */

import React from "react";

import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./AlertInline.module.css";

interface AlertInlineProps {
  className?: string;
  message: string | TrustedHTML;
  serverity: "error" | "warning" | "info";
}

const AlertInline = React.forwardRef<HTMLDivElement, AlertInlineProps>(
  function AlertInline({ className, message, serverity }, ref) {
    const Icon = serverity === "info" ? InfoIcon : WarningIcon;

    return (
      <Box
        ref={ref}
        className={toClassName(styles.container, className, {
          [styles.borderError]: serverity === "error",
          [styles.borderWarning]: serverity === "warning",
          [styles.borderInfo]: serverity === "info",
        })}
      >
        <Icon
          className={toClassName(styles.alertIcon, {
            [styles.colorError]: serverity === "error",
            [styles.colorWarning]: serverity === "warning",
            [styles.colorInfo]: serverity === "info",
          })}
        />
        <Typography
          className={toClassName(styles.alertMessage, {
            [styles.colorError]: serverity === "error",
            [styles.colorWarning]: serverity === "warning",
            [styles.colorInfo]: serverity === "info",
          })}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </Box>
    );
  }
);

export default AlertInline;
