import React from "react";

import useMatchPerformanceGroupV4 from "@fartherfinance/frontend/api/PerformanceGroups/hooks/useMatchPerformanceGroupV4";
import { FartherAccountId } from "@fartherfinance/frontend/api/Types";

import HoldingsV4 from "@src/multiCustodian/components/PerformanceGroups/Holdings/HoldingsV4";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

import styles from "./Holdings.module.css";

interface Props {
  accountId: FartherAccountId;
}

export default function AccountHoldingsV4({ accountId }: Props): JSX.Element {
  const auth = useRequestAuth();

  const performanceGroupId = useMatchPerformanceGroupV4(accountId, auth);

  return (
    <>
      <div className={styles.heading}>Account Holdings</div>

      <HoldingsV4 groupId={performanceGroupId.data ?? null} />
    </>
  );
}
