import React, { createContext, Dispatch } from "react";

import { initialState } from "./initialState";
import { ActionPayload, AddCollaboratorFlowData, FlowState } from "./types";

const AddCollaboratorContext = createContext<{
  state: FlowState<AddCollaboratorFlowData>;
  dispatch: Dispatch<ActionPayload>;
}>({
  state: initialState,
  dispatch: () => null,
});

export function useAddCollaboratorContext() {
  return React.useContext(AddCollaboratorContext);
}

export default AddCollaboratorContext;
