import React, { useEffect, useState } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useHistory, useParams } from "react-router-dom";

import useDeleteTrigger from "@fartherfinance/frontend/api/CashCycle/hooks/useDeleteTrigger";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import { CashManagementPath } from "@src/config/routing/RouterPaths";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import Modal from "@src/sharedComponents/Modal/Modal";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./CancelCurrentTriggerModal.module.css";

interface Props {
  closeModal: () => void;
}

const CancelCurrentTriggerModal = (props: Props): JSX.Element => {
  const [isMutating, setIsMutating] = useState<boolean>(false);

  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const statusNotification = useStatusNotification();

  const history = useHistory();

  const auth = useRequestAuth();
  const deleteCurrentTrigger = useDeleteTrigger(clientId, auth);

  const { enableRecurringTransfers } = useFlags();

  useEffect(() => {
    if (!enableRecurringTransfers) {
      history.replace(`/Client/${clientId}/${CashManagementPath}`);
    }
  }, [enableRecurringTransfers, clientId, history]);

  const onCancel = async () => {
    try {
      setIsMutating(true);

      await deleteCurrentTrigger(clientId);

      statusNotification("Removed funding plan", "Success");
    } catch (_e) {
      statusNotification("Error removing funding plan", "Error");
    } finally {
      setIsMutating(false);
      props.closeModal();
    }
  };

  if (isMutating) {
    return (
      <Modal closeModal={props.closeModal} modalStyle={{ width: "520px" }}>
        <div className={styles.paddingContainer}>
          <Skeleton width={140} className={styles.title} />

          <Skeleton width={"90%"} className={styles.subTitle} />

          <div className={styles.footer}>
            <div className={styles.modalButtonsDiv}>
              <Skeleton width={100} className={styles.marginRight} />

              <Skeleton width={100} />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal closeModal={props.closeModal} modalStyle={{ width: "520px" }}>
      <div className={styles.paddingContainer}>
        <p className={styles.title}>Remove Funding Plan</p>

        <p className={styles.subTitle}>
          Are you sure you want to remove your current funding plan?
        </p>

        <div className={styles.footer}>
          <div className={styles.modalButtonsDiv}>
            <Button
              variant={"outlined"}
              buttonType={"secondary"}
              text={"Cancel"}
              onClick={props.closeModal}
            />

            <Button
              variant={"contained"}
              buttonType={"primary"}
              text={"Confirm"}
              onClick={() => onCancel()}
              style={{ marginLeft: "15px" }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CancelCurrentTriggerModal;
