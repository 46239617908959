import React, { useState } from "react";

import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Box from "@mui/material/Box";

import NewFolderModal from "../NewFolderModal/NewFolderModal";
import { useUploadFiles } from "../UploadFilesProvider/UploadFilesProvider";
import { isDirectoryLocked } from "../utils";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import styles from "./DocumentsActions.module.css";

interface DocumentsActionsProps {
  directories: string[];
}

const DocumentsActions: React.FC<DocumentsActionsProps> = ({ directories }) => {
  const [isNewFolderModalOpen, setIsNewFolderModalOpen] = useState(false);
  const { isUploading, fileUploadIntent } = useUploadFiles();

  const areActionsDisabled = isDirectoryLocked(directories) || isUploading;

  return (
    <>
      {isNewFolderModalOpen && (
        <NewFolderModal
          directories={directories}
          onClose={() => setIsNewFolderModalOpen(false)}
        />
      )}

      <Box className={styles.container}>
        <Tooltip
          placement="top"
          tooltipText="You cannot create new folders here."
          disableHoverListener={!areActionsDisabled}
          disableFocusListener={!areActionsDisabled}
        >
          <span>
            <Button
              variant="text"
              buttonType="secondary"
              text="New Folder"
              style={{ whiteSpace: "nowrap" }}
              disabled={areActionsDisabled}
              onClick={() => setIsNewFolderModalOpen(true)}
              endIcon={<CreateNewFolderOutlinedIcon />}
            />
          </span>
        </Tooltip>

        <Tooltip
          placement="top"
          tooltipText="You cannot upload files to this folder."
          disableHoverListener={!areActionsDisabled}
          disableFocusListener={!areActionsDisabled}
        >
          <span>
            <Button
              variant="text"
              buttonType="secondary"
              text="Upload"
              style={{ whiteSpace: "nowrap" }}
              disabled={areActionsDisabled}
              onClick={fileUploadIntent}
              endIcon={<FileUploadOutlinedIcon />}
            />
          </span>
        </Tooltip>
      </Box>
    </>
  );
};

export default DocumentsActions;
