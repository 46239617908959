import React, { useState } from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Stack, Typography } from "@mui/material";
import { delay, isString } from "lodash";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import styles from "./OpportunityMetadata.module.css";

interface MetadataProps {
  label: string;
  value: React.ReactNode;
}

export default function OpportunityMetadata({
  label,
  value,
}: MetadataProps): JSX.Element {
  const [isHovering, setIsHovering] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleMouseEnter = (): void => {
    setIsHovering(true);
  };

  const handleMouseLeave = (): void => {
    setIsHovering(false);
  };

  const handleCopyText = async (text: string): Promise<void> => {
    setIsTooltipVisible(true);
    await navigator.clipboard.writeText(text);
    delay(() => setIsTooltipVisible(false), 600);
  };

  return (
    <>
      <Typography className={toClassName(styles.text, styles.label)}>
        {label}
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        className={styles.valueContainer}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {value && React.isValidElement(value) ? (
          value
        ) : (
          <>
            <Typography className={toClassName(styles.text, styles.value)}>
              {value ?? "-"}
            </Typography>
            {isString(value) && (
              <Tooltip
                open={isTooltipVisible}
                placement="top"
                tooltipText="Copied"
              >
                <ContentCopyIcon
                  className={toClassName(styles.icon, {
                    [styles.iconVisible]: isHovering,
                  })}
                  onClick={() => handleCopyText(value)}
                />
              </Tooltip>
            )}
          </>
        )}
      </Stack>
    </>
  );
}
