import React, { useCallback } from "react";

import { InputAdornment } from "@mui/material";

import CreateEditLayout from "../CreateEditLayout/CreateEditLayout";
import { actions } from "../reducer/actions";
import { useCreateEditTaxBudgetContext } from "../reducer/Context";
import { positiveNumericRegex } from "../utils/positiveNumericRegex";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";
import TextInput from "@src/sharedComponents/TextInput/TextInput";

import styles from "./SelectBudget.module.css";

const SelectBudget: React.FC = () => {
  const { state, dispatch } = useCreateEditTaxBudgetContext();

  const {
    data: { year, budgetAmount },
  } = state;

  const setBudgetAmount = useCallback(
    (
      e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
    ) => {
      if (!e || e.target.value === "") {
        dispatch({
          type: actions.SET_BUDGET_AMOUNT,
          payload: 0,
        });
        return;
      }

      let amount = e.target.value;
      while (amount.startsWith("0")) {
        amount = amount.slice(1);
      }

      if (positiveNumericRegex.test(amount)) {
        dispatch({
          type: actions.SET_BUDGET_AMOUNT,
          payload: parseFloat(amount),
        });
      }
    },
    [dispatch]
  );

  return (
    <CreateEditLayout>
      <BorderBox className={styles.container}>
        <div className={styles.title}>{`${year || 0} Tax Budget`}</div>
        <TextInput
          autoFocus
          label={<div className={styles.label}>Budget Amount</div>}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          value={budgetAmount}
          onChange={setBudgetAmount}
        />
      </BorderBox>
    </CreateEditLayout>
  );
};

export default SelectBudget;
