import React from "react";

import InfoIcon from "@mui/icons-material/Info";

import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import styles from "./TickerContainer.module.css";

interface Props {
  ticker: string;
}

const CashContainer = (props: Props): JSX.Element => {
  return (
    <div className={styles.cashContainer}>
      <span>{props.ticker}</span>
      <Tooltip
        tooltipText="When implementing a model portfolio, we must maintain some level of cash to facilitate ongoing trading and rebalancing."
        placement="top"
      >
        <InfoIcon className={styles.infoIcon} />
      </Tooltip>
    </div>
  );
};

export default CashContainer;
