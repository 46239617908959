import React from "react";

import MuiTabPanel, {
  TabPanelProps as MuiTabPanelProps,
} from "@mui/lab/TabPanel";

const TabPanel: React.FC<React.PropsWithChildren<MuiTabPanelProps>> = ({
  children,
  ...props
}) => {
  return <MuiTabPanel {...props}>{children}</MuiTabPanel>;
};

export default TabPanel;
