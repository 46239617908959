import React from "react";

import LinkIcon from "@mui/icons-material/Link";
import ShieldIcon from "@mui/icons-material/Shield";
import { useSelector } from "react-redux";

import useIsAdmin from "@src/multiCustodian/components/Advisor/utils/useIsAdmin";
import Chip from "@src/sharedComponents/Chip/Chip";
import { State } from "@src/store";

const AdvisorChip = (): JSX.Element => {
  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));

  const isAdmin = useIsAdmin(advisorId);

  if (isAdmin.isLoading || isAdmin.hasError) {
    return <></>;
  }

  if (isAdmin.data === true) {
    return (
      <Chip
        variant="outlined"
        icon={
          <ShieldIcon
            style={{
              width: "10px",
              height: "10px",
              color: "var(--icon)",
              marginLeft: "10px",
            }}
          />
        }
        label={"Admin"}
      />
    );
  }

  return (
    <Chip
      variant="outlined"
      icon={
        <LinkIcon
          style={{
            width: "14px",
            height: "14px",
            color: "var(--icon)",
            marginLeft: "10px",
          }}
        />
      }
      label={"Advisor"}
    />
  );
};

export default AdvisorChip;
