import React from "react";

const Transfers: React.FC = () => (
  <svg
    width="100%"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.3437 48.8117V54.6634L63.9999 41.4364L47.3437 28.2095V34.0881H21.8691V48.8117H47.3437Z"
      fill="currentColor"
    />
    <path
      d="M16.6562 30.6023V36.4539L0 23.227L16.6562 10V15.8786L42.1308 15.8786V30.6023H16.6562Z"
      fill="currentColor"
    />
  </svg>
);

export default Transfers;
