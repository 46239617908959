import React, { useMemo, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import useUploadManualAssetDocument from "@fartherfinance/frontend/api/CustodianDoc/hooks/useUploadManualAssetDocument";
import useClientAltAssets from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAltAssets";
import { AltAssetId, ClientId } from "@fartherfinance/frontend/api/Types";

import Actions from "../:accountId/Settings";
import { AccountsPath } from "@src/config/routing/RouterPaths";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { captureException } from "@src/multiCustodian/services/tracking";
import FileUploadButton from "@src/sharedComponents/FileUploadButton/FileUploadButton";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import FormSubHeader from "@src/sharedComponents/Forms/FormSubHeader";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import Modal from "@src/sharedComponents/Modal/Modal";

import styles from "./EditDocument.module.css";

const EditDocument = (): JSX.Element => {
  const { clientId, accountId } = useParams<{
    clientId: ClientId;
    accountId: AltAssetId;
  }>();

  const history = useHistory();

  const closeModal = () => {
    history.push(
      `/Client/${clientId}/${AccountsPath}/Manual/${accountId}/Settings`
    );
  };

  const auth = useRequestAuth();

  const statusNotification = useStatusNotification();

  const altAssets = useClientAltAssets(clientId, auth);

  const curAccount = useMemo(() => {
    if (altAssets.isLoading || altAssets.hasError) {
      return altAssets;
    }

    const account = altAssets.data.find((acc) => acc.alt.id === accountId);

    if (account === undefined) {
      const err = new Error(
        `[Manual/Graph]: altAssets.data is missing an account with the Id ${accountId}`
      );

      captureException(err);

      throw err;
    }

    return {
      ...altAssets,
      data: account,
    };
  }, [accountId, altAssets]);

  const [file, setFile] = useState<File | null>(null);
  const [mutating, setMutating] = useState(false);

  const updateDocument = useUploadManualAssetDocument(auth);

  const isDisabled =
    file === null ||
    clientId === null ||
    curAccount.data === undefined ||
    mutating;

  const callUpdateDocument = async () => {
    if (isDisabled) {
      statusNotification("Could not save document", "Error");
      return;
    }

    setMutating(true);

    try {
      await updateDocument({
        clientId: clientId,
        altId: curAccount.data.alt.id,
        document: file,
      });

      statusNotification("Document updated", "Success");

      closeModal();
    } catch {
      setMutating(false);

      statusNotification("Error updating document", "Error");
    }
  };

  return (
    <Actions>
      <Modal closeModal={closeModal} modalStyle={{ width: "460px" }}>
        {mutating && (
          <div className={styles.loading}>
            <LogoLoadingStill />
          </div>
        )}
        <FormH1>Add Documentation</FormH1>

        <Spacer verticalSpacing="12px" />

        <FormSubHeader>
          Upload new documentation for this manually-tracked account, which will
          be saved in the Document Center.
        </FormSubHeader>

        <Spacer verticalSpacing="20px" />

        <FileUploadButton
          onChange={setFile}
          currentFileName={curAccount.data?.alt.documentName ?? undefined}
        />

        <Spacer verticalSpacing="20px" />

        <div className={styles.buttons}>
          <ButtonPrimary
            variant={"outlined"}
            buttonType={"secondary"}
            text={"Close"}
            onClick={closeModal}
          />

          <ButtonPrimary
            disabled={isDisabled}
            variant={"contained"}
            buttonType={"primary"}
            text={"Save"}
            onClick={callUpdateDocument}
          />
        </div>
      </Modal>
    </Actions>
  );
};

export default EditDocument;
