import React, { useMemo } from "react";

import { useParams } from "react-router-dom";

import useGetFartherManagedAccounts from "@fartherfinance/frontend/api/Accounts/hooks/useGetFartherManagedAccounts";
import { PortfolioPartner } from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";
import useTradingGroups from "@fartherfinance/frontend/api/TradingGroups/hooks/useTradingGroups";
import { ClientId, PortfolioId } from "@fartherfinance/frontend/api/Types";

import AccountsListSelection from "@src/multiCustodian/components/Client/Portfolio/AccountsListSelection/AccountsListSelection";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

import styles from "./PortfolioAccounts.module.css";

interface Props {
  modelType: PortfolioPartner | "Custom";
  isDerived: boolean;
}

const PortfolioContent = ({ isDerived, modelType }: Props): JSX.Element => {
  const { clientId, portfolioId } = useParams<{
    clientId: ClientId;
    portfolioId: PortfolioId;
  }>();

  const auth = useRequestAuth();

  const accounts = useGetFartherManagedAccounts(clientId, auth);
  const tradingGroups = useTradingGroups(clientId, auth);

  const fartherAccounts = useMemo(() => {
    return accounts.data ?? [];
  }, [accounts.data]);

  if (tradingGroups.isLoading) {
    return <div></div>;
  }

  if (tradingGroups.hasError) {
    return <div>Error retrieving trading groups</div>;
  }

  return (
    <div className={styles.content}>
      <AccountsListSelection
        accounts={fartherAccounts}
        isDerived={isDerived}
        tradingGroups={tradingGroups.data}
        portfolioModelType={modelType}
        portfolioId={portfolioId}
      />
    </div>
  );
};

export default PortfolioContent;
