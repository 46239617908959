import React, { PropsWithChildren } from "react";

import { useHistory, useParams } from "react-router-dom";

import {
  MarketplaceAssetType,
  MarketplaceOfferingTag,
} from "@fartherfinance/frontend/api/AltAssetsService/Types";
import { MarketplaceOfferingId } from "@fartherfinance/frontend/api/Types";

import AdvisorContainer from "../../AdvisorContainer";
import AdvisorContentContainer from "../../AdvisorContentContainer";
import AltAssetsFilters from "@src/multiCustodian/components/Investments/AltAssetsMarketplace/AltAssetsFilters";
import OfferingsListV2 from "@src/multiCustodian/components/Marketplace/OfferingsList/OfferingsListV2";
import useOfferingsListDataV2 from "@src/multiCustodian/components/Marketplace/OfferingsList/useOfferingsListDataV2";
import OfferingsListToolbar from "@src/multiCustodian/components/Marketplace/OfferingsListToolbar/OfferingsListToolbar";
import {
  MarketplaceAdvisorPathParamsV2,
  MarketplaceOfferingTypeCard,
} from "@src/multiCustodian/components/Marketplace/types";
import {
  findOfferingTypeByName,
  findOfferingTypeByValue,
  mapTagKeyToFilterName,
} from "@src/multiCustodian/components/Marketplace/utils";

interface OfferingsListAdvisorV2Props {
  assetType: MarketplaceAssetType;
  offeringTypes: MarketplaceOfferingTypeCard[];
  marketplacePath: string;
  pageTitleFallback: string;
}

// NOTE: offeringType of "all" means that we shouldn't be filtering by this param
const OfferingsListAdvisorV2: React.FC<
  PropsWithChildren<OfferingsListAdvisorV2Props>
> = ({
  children,
  assetType,
  offeringTypes,
  marketplacePath,
  pageTitleFallback,
}) => {
  const history = useHistory();
  const { offeringType } = useParams<MarketplaceAdvisorPathParamsV2>();

  const isViewAllMode = offeringType === "all";

  const {
    offerings,
    pagination,
    queryParams,
    filters,
    isLoading,
    hasError,
    handlePageChange,
    handleQueryParamChange,
    handleQueryParamReset,
  } = useOfferingsListDataV2(
    "advisor",
    assetType,
    isViewAllMode ? undefined : offeringType
  );

  const marketplaceOfferingType = isViewAllMode
    ? undefined
    : findOfferingTypeByValue(offeringTypes, offeringType);

  const pageTitle = marketplaceOfferingType
    ? marketplaceOfferingType.name
    : isViewAllMode
    ? `View All ${pageTitleFallback}`
    : pageTitleFallback;

  const pageSubtitle = marketplaceOfferingType?.description;
  const offeringsCount = pagination?.totalCount ?? 0;

  const handleNavigateToOfferingTypes = (): void => {
    history.push(`/Advisor/${marketplacePath}`);
  };

  const handleNavigateToOfferingDetails = (
    offeringId: MarketplaceOfferingId
  ): void => {
    history.push({
      ...history.location,
      pathname: `/Advisor/${marketplacePath}/${offeringType}/${offeringId}`,
    });
  };

  const handleApplyTagFilter = (tag: MarketplaceOfferingTag): void => {
    const filterName = mapTagKeyToFilterName(tag.key);

    if (filterName === "filterByOfferingType") {
      const offeringType = findOfferingTypeByName(offeringTypes, tag.value);
      const isOfferingTypeFilterApplied = filters.filterByOfferingType?.find(
        (filterOfferingType) => filterOfferingType === offeringType?.value
      );

      if (offeringType && !isOfferingTypeFilterApplied) {
        handleQueryParamChange({
          page: 1,
          [filterName]: [offeringType.value],
        });
      }

      return;
    }

    if (filterName) {
      handleQueryParamChange({ page: 1, [filterName]: [tag.value] });
    }
  };

  return (
    <AdvisorContainer>
      <AdvisorContentContainer
        heroTitle={pageTitle}
        heroSubtitle={pageSubtitle}
      >
        <OfferingsListToolbar
          isViewAllMode={isViewAllMode}
          offeringsCount={offeringsCount}
          filters={filters}
          filterComponent={
            <AltAssetsFilters
              filters={filters}
              onQueryParamChange={handleQueryParamChange}
            />
          }
          searchByKeyword={queryParams.searchByKeyword}
          onNavigateBackClick={handleNavigateToOfferingTypes}
          onQueryParamChange={handleQueryParamChange}
          onQueryParamReset={handleQueryParamReset}
        />

        <OfferingsListV2
          offerings={offerings}
          pagination={pagination}
          isLoading={isLoading}
          isErrored={hasError}
          onCardClick={handleNavigateToOfferingDetails}
          onTagClick={handleApplyTagFilter}
          onPageChange={handlePageChange}
        />

        {children}
      </AdvisorContentContainer>
    </AdvisorContainer>
  );
};

export default OfferingsListAdvisorV2;
