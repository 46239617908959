import React from "react";

import { InvestmentsPath } from "@src/config/routing/RouterPaths";
import TabPanel from "@src/multiCustodian/components/Advisor/Investments/SharedComponents/TabPanel/TabPanel";
import SMABody from "@src/multiCustodian/components/Advisor/Investments/SMA/SMABody";

import styles from "./Investments.module.css";

const SMA = (props: React.PropsWithChildren<unknown>): JSX.Element => {
  return (
    <TabPanel
      className={styles.tabPanel}
      value={`/Advisor/${InvestmentsPath}/SMA`}
    >
      <SMABody />

      {props.children}
    </TabPanel>
  );
};

export default SMA;
