import React, { useCallback, useMemo, useState } from "react";

import CheckIcon from "@mui/icons-material/Check";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { CircularProgress, Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { compareAsc, format } from "date-fns";
import cloneDeep from "lodash/cloneDeep";
import prettyBytes from "pretty-bytes";
import { useHistory } from "react-router-dom";

import useDeleteFolderDocument from "@fartherfinance/frontend/api/CustodianDoc/hooks/useDeleteFolderDocument";
import { docCenterGetDocumentsKeyMaker } from "@fartherfinance/frontend/api/CustodianDoc/hooks/useGetDocuments";
import { DocOrFilePathPart } from "@fartherfinance/frontend/api/CustodianDoc/Types";
import { ClientId, SortOrder } from "@fartherfinance/frontend/api/Types";

import DocumentLinksFetcher from "../DocumentLinksFetcher/DocumentLinksFetcher";
import RemoveFolderModal from "../RemoveFolderModal/RemoveFolderModal";
import { useUploadFiles } from "../UploadFilesProvider/UploadFilesProvider";
import { isDirectoryLocked, isFile } from "../utils";
import { usePersistedQueryParams } from "@src/multiCustodian/hooks/Advisor/usePersistedQueryParams";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import {
  extractSortParts,
  mapToSortOrder,
} from "@src/multiCustodian/utils/sorting";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import IconButton from "@src/sharedComponents/IconButton/IconButton";
import Menu, { MenuOption } from "@src/sharedComponents/Menu/Menu";
import PaginatedTable from "@src/sharedComponents/Table/PaginatedTable";
import { TableSortOrder } from "@src/sharedComponents/Table/Types";

import DocumentsExplorerEmptyState from "./components/DocumentsExplorerEmptyState";
import DocumentsExplorerErrorState from "./components/DocumentsExplorerErrorState";
import {
  DocumentLinksMap,
  LocalDocumentsExplorerQueryParams,
  TableColumns,
} from "./DocumentsExplorer.types";
import {
  createTableColumns,
  isAlternativeInvestmentDirectory,
  isDocumentUrlReady,
  isUUID,
  trimPath,
} from "./DocumentsExplorer.utils";

import styles from "./DocumentsExplorer.module.css";

import type { DocOrFilePathPartUpload } from "../../DocumentsCenter";

interface DocumentsExplorerProps {
  clientId: ClientId;
  isLoading: boolean;
  isErrored: boolean;
  directories: string[];
  currentDirectory: string;
  currentDirectoryScope: DocOrFilePathPart[] | null | undefined;
  currentDirectoryUploadScope: DocOrFilePathPartUpload[];
}

const DocumentsExplorer: React.FC<DocumentsExplorerProps> = ({
  clientId,
  isLoading,
  isErrored,
  directories,
  currentDirectory,
  currentDirectoryScope,
  currentDirectoryUploadScope,
}) => {
  const [directoryToRemove, setDirectoryToRemove] =
    useState<DocOrFilePathPart | null>(null);
  const [documentLinks, setDocumentLinks] = useState<DocumentLinksMap>({});
  const history = useHistory();
  const queryClient = useQueryClient();
  const [queryParams, setQueryParams] =
    usePersistedQueryParams<LocalDocumentsExplorerQueryParams>({
      sort: "-created",
    });

  const { fileUploadIntent } = useUploadFiles();

  const auth = useRequestAuth();
  const callDeleteFolderDocument = useDeleteFolderDocument(auth);
  const statusNotification = useStatusNotification();

  const isDirectoryNonActionable = isDirectoryLocked(directories);
  const currentDirectories = (currentDirectoryScope ?? [])
    // NOTE: get rid of the root s3 user directory
    .filter((directory) => directory.parentDirectory !== clientId)
    // NOTE: pass through only current directory entries
    .filter((directory) => {
      const encodedCurrentDirectory = encodeURIComponent(currentDirectory);

      // NOTE: allow filter to pass in case of mapping the Manually-Tracked Assets to the Alternative Investments on the root level
      if (encodedCurrentDirectory === "Manually-Tracked%20Assets") {
        return directory.parentDirectory === "Alternative%20Investments";
      }

      // NOTE: nested directories of the Alternative Investments has to use different passthrough logic in order to show in the explorer
      if (
        directory.altInvestmentAssetName === currentDirectory &&
        isUUID(directory.parentDirectory)
      ) {
        return true;
      }

      // NOTE: proceed as usual
      return directory.parentDirectory === encodedCurrentDirectory;
    });
  const currentDirectoryUploads = currentDirectoryUploadScope
    // NOTE: pass through only current directory entries
    .filter((directory) => directory.parentDirectory === currentDirectory);

  const sortedCurrentDirectories = useMemo(() => {
    const { sortBy, sortOrder } = extractSortParts(queryParams.sort);
    const orderModifier = sortOrder === SortOrder.Ascending ? 1 : -1;
    const clonedCurrentDirectories = cloneDeep(currentDirectories);

    return clonedCurrentDirectories.sort((directoryA, directoryB) => {
      if (sortBy === "name") {
        const a =
          (isFile(directoryA)
            ? directoryA.fileName
            : directoryA.currentDirectory) ?? "";
        const b =
          (isFile(directoryB)
            ? directoryB.fileName
            : directoryB.currentDirectory) ?? "";

        return a.localeCompare(b) * orderModifier;
      }

      if (sortBy === "created") {
        const a = directoryA.date ?? new Date();
        const b = directoryB.date ?? new Date();

        return compareAsc(a, b) * orderModifier;
      }

      if (sortBy === "type") {
        const a = directoryA.fileType ?? "";
        const b = directoryB.fileType ?? "";

        return a.localeCompare(b) * orderModifier;
      }

      if (sortBy === "size") {
        const a = directoryA.size ?? 0;
        const b = directoryB.size ?? 0;

        return (a - b) * orderModifier;
      }

      return 0;
    });
  }, [currentDirectories, queryParams.sort]);

  const determineDirectoryTypeLabel = (
    directory: DocOrFilePathPart
  ): string => {
    const fileType = (directory.fileType ?? "").substring(1).toUpperCase();

    return isFile(directory) ? `${fileType} File` : "Folder";
  };

  const determineDirectoryName = (directory: DocOrFilePathPart): string => {
    const unknownFolder = "Unknown folder";

    // NOTE: Alternative Investments documents uses different property for display name
    if (isAlternativeInvestmentDirectory(directory)) {
      return directory.altInvestmentAssetName ?? unknownFolder;
    }

    return directory.currentDirectory
      ? decodeURIComponent(directory.currentDirectory)
      : unknownFolder;
  };

  const handleNavigateToDirectory = useCallback(
    (directory: DocOrFilePathPart) => {
      // NOTE: use the same value for navigation as for displaying the directory name
      const directoryName = isAlternativeInvestmentDirectory(directory)
        ? directory.altInvestmentAssetName
        : directory.currentDirectory;

      if (directoryName) {
        history.push({
          ...history.location,
          pathname: `${history.location.pathname}/${directoryName}`,
        });
      }
    },
    [history]
  );

  const handlePreviewFile = useCallback(
    (directory: DocOrFilePathPart): void => {
      try {
        const url = documentLinks[directory.path].previewUrl;
        window.open(url, "_blank");
      } catch (_error) {
        statusNotification(
          `Failed to open preview for file: ${directory.fileName ?? ""}`,
          "Error"
        );
      }
    },
    [documentLinks, statusNotification]
  );

  const handleDownloadFile = useCallback(
    (directory: DocOrFilePathPart): void => {
      try {
        const url = documentLinks[directory.path].downloadUrl;
        window.location.href = url;
      } catch (_error) {
        statusNotification(
          `Failed to download file: ${directory.fileName ?? ""}`,
          "Error"
        );
      }
    },
    [documentLinks, statusNotification]
  );

  const handleTableRowClick = useCallback(
    (directory: DocOrFilePathPart) => {
      return isFile(directory)
        ? handlePreviewFile(directory)
        : handleNavigateToDirectory(directory);
    },
    [handlePreviewFile, handleNavigateToDirectory]
  );

  const handleDetermineOnClickHandler = useCallback(
    (directory: DocOrFilePathPart) => {
      // NOTE: in case we are dealing with file and preview url is not ready yet, we want to disable the row interaction by not passing any onClick handler
      return isFile(directory)
        ? isDocumentUrlReady(documentLinks, directory.path, "preview")
          ? () => handleTableRowClick(directory)
          : undefined
        : () => handleTableRowClick(directory);
    },
    [documentLinks, handleTableRowClick]
  );

  const handleDeleteRecord = useCallback(
    async (directory: DocOrFilePathPart) => {
      const isFileType = isFile(directory);

      try {
        await callDeleteFolderDocument(trimPath(directory.path));
        statusNotification(
          `${isFileType ? "File" : "Folder"} deleted`,
          "Success"
        );
      } catch {
        statusNotification(
          `Failed to remove ${isFileType ? "file" : "folder"}`,
          "Error"
        );
      } finally {
        setDirectoryToRemove(null);
      }
    },
    [callDeleteFolderDocument, statusNotification]
  );

  const handleCreateMenuOptions = useCallback(
    (directory: DocOrFilePathPart): MenuOption[] => {
      const isFileType = isFile(directory);

      return [
        ...(isFileType
          ? [
              {
                label: "Download",
                onClick: () => handleDownloadFile(directory),
                IconComponent: FileDownloadOutlinedIcon,
              },
            ]
          : []),
        ...(isFileType
          ? [
              {
                label: "View",
                onClick: () => handlePreviewFile(directory),
                IconComponent: OpenInNewIcon,
              },
            ]
          : []),
        {
          label: "Delete",
          isDisabled: isDirectoryNonActionable,
          onClick: () =>
            isFileType
              ? handleDeleteRecord(directory)
              : setDirectoryToRemove(directory),
          IconComponent: DeleteIcon,
        },
      ];
    },
    [
      handleDownloadFile,
      handlePreviewFile,
      handleDeleteRecord,
      isDirectoryNonActionable,
    ]
  );

  const handlePreserveDocumentLinks = (
    directory: DocOrFilePathPart,
    previewUrl: string,
    downloadUrl: string
  ): void => {
    setDocumentLinks((currentLinks) => ({
      ...currentLinks,
      [directory.path]: {
        previewUrl,
        downloadUrl,
      },
    }));
  };

  const handleDocumentsReload = (): void => {
    queryClient.resetQueries({
      queryKey: docCenterGetDocumentsKeyMaker(clientId),
    });
  };

  const handleOnColumnSelect = (
    column: TableColumns,
    tableSortOrder: TableSortOrder
  ): void => {
    if (
      column === "name" ||
      column === "created" ||
      column === "type" ||
      column === "size"
    ) {
      setQueryParams({
        sort: `${mapToSortOrder(tableSortOrder)}${column}`,
      });
    }
  };

  const currentDirectoryEntries = useMemo(
    () =>
      sortedCurrentDirectories.map((directory, index) => ({
        key: directory.path + index,
        typeIcon: {
          value: isFile(directory) ? (
            <InsertDriveFileOutlinedIcon className={styles.typeIcon} />
          ) : (
            <FolderIcon className={styles.typeIcon} />
          ),
        },
        name: {
          value: isFile(directory) ? (
            <span>
              {directory.fileName
                ? decodeURIComponent(directory.fileName)
                : "Unknown file"}
            </span>
          ) : (
            <Stack direction="row" alignItems="center">
              {determineDirectoryName(directory)}
              <ChevronRightIcon className={styles.icon} />
            </Stack>
          ),
        },
        created: {
          value: (
            <span>
              {directory.date ? format(directory.date, "MM/dd/yy") : "--"}
            </span>
          ),
        },
        type: {
          value: <span>{determineDirectoryTypeLabel(directory)}</span>,
        },
        size: {
          value: (
            <span>
              {directory.size
                ? prettyBytes(directory.size, { space: false })
                : "--"}
            </span>
          ),
        },
        action: {
          value: (
            <Menu
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              options={handleCreateMenuOptions(directory)}
            >
              {({ handleOpenMenu }) => {
                return (
                  <IconButton
                    onClick={handleOpenMenu}
                    iconClassName={styles.icon}
                    IconComponent={MoreHorizIcon}
                  />
                );
              }}
            </Menu>
          ),
        },
        onClick: handleDetermineOnClickHandler(directory),
      })),
    [
      sortedCurrentDirectories,
      handleCreateMenuOptions,
      handleDetermineOnClickHandler,
    ]
  );

  const currentDiretoryUploadEntries = useMemo(
    () =>
      currentDirectoryUploads.map((directory, index) => ({
        key: "document-upload" + index,
        typeIcon: {
          value: (
            <>
              {directory.uploadState === "uploading" && (
                <CircularProgress
                  size={18}
                  className={toClassName(
                    styles.uploadProgressIcon,
                    styles.uploadPending
                  )}
                />
              )}
              {directory.uploadState === "success" && (
                <CheckIcon
                  className={toClassName(
                    styles.uploadProgressIcon,
                    styles.uploadSuccess
                  )}
                />
              )}
              {directory.uploadState === "error" && (
                <ErrorOutlineIcon
                  className={toClassName(
                    styles.uploadProgressIcon,
                    styles.uploadError
                  )}
                />
              )}
            </>
          ),
        },
        name: {
          value: (
            <span
              className={toClassName({
                [styles.textDisabled]: directory.uploadState !== "success",
              })}
            >
              {directory.fileName}
            </span>
          ),
        },
        created: {
          value: (
            <span
              className={toClassName({
                [styles.textDisabled]: directory.uploadState !== "success",
              })}
            >
              {directory.date ? format(directory.date, "MM/dd/yy") : "--"}
            </span>
          ),
        },
        type: {
          value: (
            <span
              className={toClassName({
                [styles.textDisabled]: directory.uploadState !== "success",
              })}
            >
              {determineDirectoryTypeLabel(directory)}
            </span>
          ),
        },
        size: {
          value: (
            <span
              className={toClassName({
                [styles.textDisabled]: directory.uploadState !== "success",
              })}
            >
              {directory.size
                ? prettyBytes(directory.size, { space: false })
                : "--"}
            </span>
          ),
        },
        action: {
          value: (
            <IconButton
              disabled
              iconClassName={styles.icon}
              IconComponent={MoreHorizIcon}
            />
          ),
        },
      })),
    [currentDirectoryUploads]
  );

  const tableRows = [
    ...currentDirectoryEntries,
    ...currentDiretoryUploadEntries,
  ];
  const tableColumns = useMemo(
    () => createTableColumns(queryParams.sort),
    [queryParams.sort]
  );

  return (
    <>
      {directoryToRemove && currentDirectoryScope && (
        <RemoveFolderModal
          directoryToRemove={directoryToRemove}
          currentDirectoryScope={currentDirectoryScope}
          onConfirm={handleDeleteRecord}
          onClose={() => setDirectoryToRemove(null)}
        />
      )}

      {currentDirectories.filter(isFile).map((directory) => (
        <DocumentLinksFetcher
          key={directory.path}
          path={trimPath(directory.path)}
          onLinksPropagation={(previewUrl, downloadUrl) =>
            handlePreserveDocumentLinks(directory, previewUrl, downloadUrl)
          }
        />
      ))}

      <PaginatedTable
        isLoading={isLoading}
        columns={tableColumns}
        rows={tableRows}
        pagination={undefined}
        showEmptyRows={isLoading}
        fixedEmptyRowsCount={3}
        onPageChange={() => null}
        onColumnSelect={handleOnColumnSelect}
      >
        <colgroup>
          <col width="3%" />
          <col width="60%" />
          <col width="13%" />
          <col width="10%" />
          <col width="10%" />
          <col width="4%" />
        </colgroup>

        {tableRows.length === 0 && !isErrored && !isLoading && (
          <tbody>
            <tr>
              <td colSpan={6}>
                <DocumentsExplorerEmptyState
                  currentDirectory={currentDirectory}
                  isDirectoryNonActionable={isDirectoryNonActionable}
                  onCallToActionClick={fileUploadIntent}
                />
              </td>
            </tr>
          </tbody>
        )}

        {isErrored && !isLoading && (
          <tbody>
            <tr>
              <td colSpan={6}>
                <DocumentsExplorerErrorState
                  onCallToActionClick={handleDocumentsReload}
                />
              </td>
            </tr>
          </tbody>
        )}
      </PaginatedTable>
    </>
  );
};

export default DocumentsExplorer;
