import React from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import styles from "./RiskLevel.module.css";

interface Props {
  goBack: () => void;
}

const RiskLevelError = (props: Props): JSX.Element => {
  return (
    <div className={styles.riskLevel}>
      <div className={styles.clientLayoutWidth}>
        <div className={styles.header}>
          <div className={styles.back} onClick={props.goBack}>
            <ArrowBackIcon style={{ width: "10px" }} /> Back
          </div>
        </div>
        Error
      </div>
    </div>
  );
};

export default RiskLevelError;
