import React from "react";

import { DashboardTabPanel } from "@src/yellowstone/components";

import { UserList } from "./components";

interface DashboardAccountsProps {
  isSelectedUserMode: boolean;
}

export const DashboardAccounts: React.FC<DashboardAccountsProps> = ({
  isSelectedUserMode,
}) => {
  return (
    <DashboardTabPanel value="/Advisor/Client-Ops/Dashboard/Accounts">
      <UserList isSelectedUserMode={isSelectedUserMode} />
    </DashboardTabPanel>
  );
};
