import {
  CategoryOfClasses,
  ClassOfSecurities,
  HydratedSecurity,
} from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Holdings/utils";
import { AssetClassCategory } from "@fartherfinance/frontend/api/PerformanceGroups/requests/getHoldings";

import combineAssetCategoryClassLists from "./combineAssetCategoryClassLists";

const combineOtherAndUnexpectedCategory = (
  existingOtherCategory: CategoryOfClasses | undefined, // can be undefined bc parent reduces starting with an empty obj {}, "Other" category/data might not exist yet in reduction obj
  currentCategory: CategoryOfClasses
): CategoryOfClasses => {
  const existingCategoryMarketValue = existingOtherCategory?.marketValue ?? 0;
  const currentCategoryMarketValue = currentCategory.marketValue ?? 0;
  const combinedCategoryMarketValue =
    existingCategoryMarketValue + currentCategoryMarketValue;

  // need to update percentOfCategory field in each class and in each security within that class
  const existingCategoryClasses = (existingOtherCategory?.classes ?? []).map(
    (cls: ClassOfSecurities) => {
      const securities = cls.securities.map((sec: HydratedSecurity) => ({
        ...sec,
        category: "Other" as AssetClassCategory,
        percentOfCategory: sec.marketValue / combinedCategoryMarketValue,
      }));

      return {
        ...cls,
        category: "Other" as AssetClassCategory,
        securities: securities,
        percentOfCategory: cls.marketValue / combinedCategoryMarketValue,
      };
    }
  );

  // need to update percentOfCategory field in each class and in each security within that class
  const currentCategoryClasses = currentCategory.classes.map(
    (cls: ClassOfSecurities) => {
      const securities = cls.securities.map((sec: HydratedSecurity) => ({
        ...sec,
        category: "Other" as AssetClassCategory,
        percentOfCategory: sec.marketValue / combinedCategoryMarketValue,
      }));

      return {
        ...cls,
        category: "Other" as AssetClassCategory,
        securities: securities,
        percentOfCategory: cls.marketValue / combinedCategoryMarketValue,
      };
    }
  );

  return {
    ...currentCategory,
    category: "Other" as AssetClassCategory,
    marketValue: combinedCategoryMarketValue,
    percentOfTotal:
      (existingOtherCategory?.percentOfTotal ?? 0) +
      (currentCategory.percentOfTotal ?? 0),
    classes: combineAssetCategoryClassLists(
      existingCategoryClasses,
      currentCategoryClasses
    ), // when combining categories they may have the same classes so combine those - will not have to combine securities bc don't expect there to ever be duplicate securities
  };
};

export default combineOtherAndUnexpectedCategory;
