import React, { useCallback, useMemo, useRef, useState } from "react";

import FilterListIcon from "@mui/icons-material/FilterList";
import {
  endOfMonth,
  format,
  set,
  setDate,
  setMonth,
  subMonths,
} from "date-fns";

import { DateFilter } from "@fartherfinance/frontend/api/PerformanceGroups/Types";

import ActivityTypeFilterPopover from "../ActivityTypeFilterPopover";
import CustomDateRangePicker, {
  CustomDateRange,
} from "../CustomDateRangePicker/CustomDateRangePicker";
import DateRangeFilterPopover from "../DateRangeFilterPopover";
import {
  ActivityTypeParentCheckboxLabel,
  DateRangeCheckboxLabel,
  EventsFilterConfig,
  getDateRangeSelectedString,
} from "../shared";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import Popover from "@src/sharedComponents/Popover/Popover";
import PortaledFilterChip from "@src/sharedComponents/PortaledChip/PortaledChip";
import {
  CheckboxListConfig,
  copyConfig,
  getTotalAndNumChecked,
  setConfigToAllChecked,
  setConfigToNoneChecked,
} from "@src/sharedComponents/ScrollableCheckboxList/ScrollableCheckboxList";
import useResetTablePaginationPage from "@src/sharedComponents/Table/useResetTablePaginationPage";

import { activityTypeFilterDefault, dateRangeFilterDefault } from "./config";

import styles from "./FilterPopover.module.css";

const PopoverButtonAndDropdownMenuWidth = 240;

const isDateFilter = (
  customDateRange: CustomDateRange | DateFilter
): customDateRange is DateFilter => {
  return customDateRange.from !== null && customDateRange.to !== null;
};

const zeroOutDate = (d: Date) =>
  set(d, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

interface Props {
  setFilter: (filter: EventsFilterConfig) => void;
  isInDashboard: boolean;
}

const FilterPopover = (props: Props): JSX.Element => {
  const filterPopoverAnchorRef = useRef<HTMLDivElement | null>(null);

  const [showFilterPopover, setShowFilterPopover] = useState<boolean>(false);
  const [showActivityTypePopover, setShowActivityTypePopover] =
    useState<boolean>(false);
  const [showDateRangePopover, setShowDateRangePopover] =
    useState<boolean>(false);

  const [currentActivityTypeFilter, setCurrentActivityTypeFilter] = useState<
    CheckboxListConfig<ActivityTypeParentCheckboxLabel>
  >(activityTypeFilterDefault);
  const [currentDateRangeFilter, setCurrentDateRangeFilter] = useState<
    CheckboxListConfig<DateRangeCheckboxLabel>
  >(dateRangeFilterDefault);
  const [currentCustomDateRange, setCurrentCustomDateRange] =
    useState<CustomDateRange>({
      from: null,
      to: null,
    });

  const [activityTypeFilter, setActivityTypeFilter] = useState<
    CheckboxListConfig<ActivityTypeParentCheckboxLabel>
  >(activityTypeFilterDefault);
  const [dateRangeFilter, setDateRangeFilter] = useState<
    CheckboxListConfig<DateRangeCheckboxLabel>
  >(dateRangeFilterDefault);
  const [customDateRange, setCustomDateRange] = useState<CustomDateRange>({
    from: null,
    to: null,
  });

  const resetTablePaginationPage = useResetTablePaginationPage();

  const currentlyAppliedActivityTypesString: string = useMemo(() => {
    const result = getTotalAndNumChecked(currentActivityTypeFilter);
    return result.numChecked === result.total
      ? "All"
      : `${result.numChecked} activity type${
          result.numChecked === 1 ? "" : "s"
        }`;
  }, [currentActivityTypeFilter]);

  const activityTypesSelectedString: string = useMemo(() => {
    const result = getTotalAndNumChecked(activityTypeFilter);
    return result.numChecked === result.total
      ? "All"
      : `${result.numChecked} activity type${
          result.numChecked === 1 ? "" : "s"
        }`;
  }, [activityTypeFilter]);

  const getDateRange = useCallback(
    (
      dateRangeSelectedString: DateRangeCheckboxLabel,
      customDateRange: CustomDateRange
    ) => {
      const today = zeroOutDate(new Date());

      switch (dateRangeSelectedString) {
        case "All":
          return null;
        case "Current Month":
          return {
            from: setDate(today, 1),
            to: today,
          };
        case "Previous Month":
          return {
            from: subMonths(setDate(today, 1), 1),
            to: zeroOutDate(endOfMonth(subMonths(setDate(today, 1), 1))),
          };
        case "Year-to-date":
          return {
            from: setMonth(setDate(today, 1), 0),
            to: today,
          };
        case "Custom":
          return isDateFilter(customDateRange) ? customDateRange : null;
        default:
          return null;
      }
    },
    []
  );

  const dateRangeSelectedString: DateRangeCheckboxLabel = useMemo(() => {
    return getDateRangeSelectedString(dateRangeFilter);
  }, [dateRangeFilter]);

  const currentlyAppliedDateRange: DateFilter | null = useMemo(() => {
    const currentlyAppliedDateRangeCheckboxLabel = getDateRangeSelectedString(
      currentDateRangeFilter
    );
    return getDateRange(
      currentlyAppliedDateRangeCheckboxLabel,
      currentCustomDateRange
    );
  }, [getDateRange, currentDateRangeFilter, currentCustomDateRange]);

  const applyNewFilter = useCallback(
    (
      filterConfig: CheckboxListConfig<ActivityTypeParentCheckboxLabel>,
      dateRange: DateFilter | null
    ) => {
      const newFilter: EventsFilterConfig = {
        dateFilter: dateRange,
        types: {
          // transaction types - from events
          "Cash Deposit":
            filterConfig["Cash In"].children?.["Cash Deposit"].checked ?? false,
          "Cash Withdrawal":
            filterConfig["Cash Out"].children?.["Cash Withdrawal"].checked ??
            false,
          Dividend: filterConfig.Dividend.checked,
          Rebate: filterConfig.Rebate.checked,
          "Transfer In":
            filterConfig["Cash In"].children?.["Transfer In"].checked ?? false,
          "Transfer Out":
            filterConfig["Cash Out"].children?.["Transfer Out"].checked ??
            false,
          Buy: filterConfig.Buy.checked,
          Sale: filterConfig.Sale.checked,
          "Management Fee": filterConfig["Management Fee"].checked,
          Journal: filterConfig.Journal.checked,
          "Corporate Action": filterConfig["Corporate Action"].checked,
          "Capital Gains": filterConfig["Capital Gains"].checked,
          "Dividend Reinvestment":
            filterConfig["Dividend Reinvestment"].checked,
          Expense: filterConfig.Expense.checked,
          Interest: filterConfig.Interest.checked,
          "Capital Gains Reinvestment":
            filterConfig["Capital Gains Reinvestment"].checked,
          Income: filterConfig.Income.checked,
          "Interest Reinvestment":
            filterConfig["Interest Reinvestment"].checked,
          "Income Reinvestment": filterConfig["Income Reinvestment"].checked,
          "Option Activity": filterConfig["Option Activity"].checked,
          "Short Sale": filterConfig["Short Sale"].checked,
          Valuation: filterConfig.Valuation.checked,
          "Return of Capital": filterConfig["Return of Capital"].checked,
          // transfer types (made locally) - from Requests
          "Pending Deposit":
            filterConfig["Cash In"].children?.["Pending Deposit"].checked ??
            false,
          "Pending Withdrawal":
            filterConfig["Cash Out"].children?.["Pending Withdrawal"].checked ??
            false,
          "Pending ACAT":
            filterConfig["Cash In"].children?.["Pending ACAT"].checked ?? false,
          "Pending Liquidation":
            filterConfig["Cash Out"].children?.["Pending Liquidation"]
              .checked ?? false,
        },
      };

      props.setFilter(newFilter);
    },
    [props]
  );

  const syncActivityTypeChanges = (
    updatedActivityTypeFilter: CheckboxListConfig<ActivityTypeParentCheckboxLabel>
  ) => {
    const activityTypeFilterCopy = copyConfig(updatedActivityTypeFilter);
    setCurrentActivityTypeFilter(activityTypeFilterCopy);
  };

  const syncDateRangeChanges = (
    updatedDateRangeFilter: CheckboxListConfig<DateRangeCheckboxLabel>,
    updatedCustomDateRange: CustomDateRange
  ) => {
    const dateRangeFilterCopy = copyConfig(updatedDateRangeFilter);
    setCurrentDateRangeFilter(dateRangeFilterCopy);

    const customDateRangeCopy = { ...updatedCustomDateRange };
    setCurrentCustomDateRange(customDateRangeCopy);
  };

  const [formIsValid, setFormIsValid] = useState<boolean>(false);

  const resetChanges = () => {
    const currentActivityTypeFilterCopy = copyConfig(currentActivityTypeFilter);
    setActivityTypeFilter(currentActivityTypeFilterCopy);

    const currentDateRangeFilterCopy = copyConfig(currentDateRangeFilter);
    setDateRangeFilter(currentDateRangeFilterCopy);

    const currentCustomDateRangeCopy = { ...currentCustomDateRange };
    setCustomDateRange(currentCustomDateRangeCopy);
  };

  const resetActivityTypeFilter = useCallback(() => {
    resetTablePaginationPage();
    const resetFilter = setConfigToAllChecked(activityTypeFilter);
    setActivityTypeFilter(resetFilter);
    syncActivityTypeChanges(resetFilter);
    // date range filter isn't changing so use current dateRangeFilter
    const curDateRangeCheckboxLabel = getDateRangeSelectedString(
      currentDateRangeFilter
    );
    const curDateRange = getDateRange(
      curDateRangeCheckboxLabel,
      customDateRange
    );
    applyNewFilter(resetFilter, curDateRange);
  }, [
    activityTypeFilter,
    applyNewFilter,
    getDateRange,
    currentDateRangeFilter,
    customDateRange,
    resetTablePaginationPage,
  ]);

  const resetDateRangeFilter = useCallback(() => {
    resetTablePaginationPage();
    const resetFilter = setConfigToNoneChecked(dateRangeFilter);
    resetFilter.All.checked = true;
    setDateRangeFilter(resetFilter);
    const resetCustomDateRange = { from: null, to: null };
    syncDateRangeChanges(resetFilter, resetCustomDateRange);
    const resetDateRangeCheckboxLabel = getDateRangeSelectedString(resetFilter);
    const resetDateRange = getDateRange(
      resetDateRangeCheckboxLabel,
      resetCustomDateRange
    );
    // activity type filter isn't changing so use current activityTypeFilter
    applyNewFilter(activityTypeFilter, resetDateRange);
  }, [
    activityTypeFilter,
    applyNewFilter,
    dateRangeFilter,
    getDateRange,
    resetTablePaginationPage,
  ]);

  const handleOnCloseActivity = () => setShowActivityTypePopover(false);
  const handleOnOpenActivity = () => setShowActivityTypePopover(true);
  const handleOnCloseDate = () => setShowDateRangePopover(false);
  const handleOnOpenDate = () => setShowDateRangePopover(true);

  const isValid = (b: boolean) => setFormIsValid(b);

  const handleOnPressActivityTypeFilter = useCallback(() => {
    setShowFilterPopover(true);
    setTimeout(() => {
      setShowActivityTypePopover(true);
    }, 300);
  }, []);

  const handleOnPressDateRangeFilter = useCallback(() => {
    setShowFilterPopover(true);
    if (dateRangeSelectedString !== "Custom") {
      setTimeout(() => {
        setShowDateRangePopover(true);
      }, 300);
    }
  }, [dateRangeSelectedString]);

  const handleOnApply = useCallback(() => {
    resetTablePaginationPage();
    syncActivityTypeChanges(activityTypeFilter);
    syncDateRangeChanges(dateRangeFilter, customDateRange);
    const newDateRangeSelectedString =
      getDateRangeSelectedString(dateRangeFilter);
    const newDateRange = getDateRange(
      newDateRangeSelectedString,
      customDateRange
    );
    applyNewFilter(activityTypeFilter, newDateRange);
    setShowFilterPopover(false);
  }, [
    activityTypeFilter,
    applyNewFilter,
    customDateRange,
    dateRangeFilter,
    getDateRange,
    resetTablePaginationPage,
  ]);

  return (
    <>
      <Popover
        open={showFilterPopover}
        onClose={() => {
          resetChanges();
          setShowFilterPopover(false);
        }}
        anchor={filterPopoverAnchorRef.current}
        transformOriginVertical={-10}
        popoverElement={
          <div ref={filterPopoverAnchorRef}>
            <ButtonPrimary
              text="Filters"
              onClick={() => setShowFilterPopover(!showFilterPopover)}
              variant="outlined"
              buttonType="primary"
              startIcon={<FilterListIcon className={styles.filterIcon} />}
              sx={{ height: "30px" }}
            />
          </div>
        }
      >
        <div className={styles.filtersContainer}>
          <div className={styles.filtersActivityTypeDiv}>
            <div className={styles.filtersText}>Activity Type</div>

            <ActivityTypeFilterPopover
              onClose={handleOnCloseActivity}
              onOpen={handleOnOpenActivity}
              showActivityTypePopover={showActivityTypePopover}
              activityTypeFilter={activityTypeFilter}
              setActivityTypeFilter={setActivityTypeFilter}
              activityTypesSelectedString={activityTypesSelectedString}
              width={PopoverButtonAndDropdownMenuWidth}
            />
          </div>

          <div className={styles.filtersDateRangeDiv}>
            <div className={styles.filtersText}>Date Range</div>

            <DateRangeFilterPopover
              onClose={handleOnCloseDate}
              onOpen={handleOnOpenDate}
              dateRangeSelectedString={dateRangeSelectedString}
              dateRangeFilter={dateRangeFilter}
              setDateRangeFilter={setDateRangeFilter}
              showDateRangePopover={showDateRangePopover}
              width={PopoverButtonAndDropdownMenuWidth}
            />
          </div>

          {dateRangeSelectedString === "Custom" && (
            <CustomDateRangePicker
              customDateRange={customDateRange}
              setCustomDateRange={setCustomDateRange}
              setFormIsValid={isValid}
            />
          )}

          <div className={styles.filtersContainerFooter}>
            <ButtonPrimary
              text="Cancel"
              onClick={() => {
                resetChanges();
                setShowFilterPopover(false);
              }}
              variant="outlined"
              buttonType="primary"
            />

            <ButtonPrimary
              text="Apply"
              onClick={handleOnApply}
              variant="contained"
              buttonType="primary"
              sx={{ marginLeft: "15px" }}
              disabled={
                dateRangeSelectedString === "Custom" &&
                (customDateRange.from === null ||
                  customDateRange.to === null ||
                  !formIsValid)
              }
            />
          </div>
        </div>
      </Popover>

      {currentlyAppliedActivityTypesString !== "All" && (
        <PortaledFilterChip
          portalMountElementId={"activityFilterActivityTypeChipPortalContainer"}
          className={
            props.isInDashboard ? styles.addLeftMargin : styles.addRightMargin
          }
          label={currentlyAppliedActivityTypesString}
          onPress={handleOnPressActivityTypeFilter}
          onClear={resetActivityTypeFilter}
        />
      )}

      {currentlyAppliedDateRange !== null && (
        <PortaledFilterChip
          portalMountElementId={"activityFilterDateRangeChipPortalContainer"}
          className={
            props.isInDashboard ? styles.addLeftMargin : styles.addRightMargin
          }
          label={`From: ${format(
            currentlyAppliedDateRange.from,
            "M/d/yyyy"
          )} - To: ${format(currentlyAppliedDateRange.to, "M/d/yyyy")}`}
          onPress={handleOnPressDateRangeFilter}
          onClear={resetDateRangeFilter}
        />
      )}
    </>
  );
};

export default FilterPopover;
