import React from "react";

import { Box, Typography } from "@mui/material";
import { format } from "date-fns";

import OpportunityMetadata from "./OpportunityMetadata/OpportunityMetadata";

import styles from "./Opportunity.module.css";

interface OpportunityClientProps {
  createdTs: Date;
  updatedTs: Date;
}

export default function OpportunityTimestamps({
  createdTs,
  updatedTs,
}: OpportunityClientProps): JSX.Element {
  return (
    <Box>
      <Typography className={styles.title}>Opportunity Details</Typography>
      <Box className={styles.container}>
        <OpportunityMetadata
          label="Created"
          value={format(createdTs, "MM/dd/yy")}
        />
        <OpportunityMetadata
          label="Updated"
          value={format(updatedTs, "MM/dd/yy")}
        />
      </Box>
    </Box>
  );
}
