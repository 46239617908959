import React, { useEffect, useMemo, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { orderBy } from "lodash";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import useFartherEmployees from "@fartherfinance/frontend/api/Entity/hooks/useFartherEmployees";
import useInstitutions from "@fartherfinance/frontend/api/Entity/hooks/useInstitutions";
import usePortfolioPartners from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/usePortfolioPartners";
import usePortfolioV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/usePortfolioV2";
import useSharePartnerWithInstitution from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useSharePartnerWithInstitution";
import useShareCustomPortfolioV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useSharePortfolioV2";
import {
  AdvisorId,
  PartnerInstitutionId,
  PortfolioId,
} from "@fartherfinance/frontend/api/Types";

import useIsAdmin from "@src/multiCustodian/components/Advisor/utils/useIsAdmin";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import Checkbox from "@src/sharedComponents/Checkbox/Checkbox";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import { State } from "@src/store";
import { pluralize } from "@src/utils/pluralize";

import styles from "./ModelSharingTable.module.css";

const isArrayOfInstitutionIds = (
  array: (AdvisorId | PartnerInstitutionId)[]
): array is PartnerInstitutionId[] => {
  return array.every((el) => typeof el === "number");
};

const isArrayOfAdvisorIds = (
  array: (AdvisorId | PartnerInstitutionId)[]
): array is AdvisorId[] => {
  return array.every((el) => typeof el !== "number");
};
interface Props {
  portfolioId: PortfolioId;
}

const ModelSharingTable = ({ portfolioId }: Props): JSX.Element => {
  const [isMutating, setIsMutating] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");

  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));

  const history = useHistory();

  const statusNotification = useStatusNotification();

  const auth = useAdvisorRequestAuth();
  const portfolio = usePortfolioV2(portfolioId, auth);
  const partners = usePortfolioPartners(auth);
  const institutions = useInstitutions(auth);
  const employees = useFartherEmployees("All", auth);
  const isAdmin = useIsAdmin(advisorId);

  const sharePartnerWithInstitution = useSharePartnerWithInstitution(auth);
  const shareCustomPortfolioWithAdvisor = useShareCustomPortfolioV2(auth);

  useEffect(() => {
    if (portfolio.data === undefined) {
      return;
    }
    // advisor should not see sharing tab if its not a shared custom model
    const isSharedCustomModel =
      portfolio.data.model.portfolioType === "Custom" &&
      isAdmin.data === false &&
      portfolio.data.model.ownerId !== advisorId;

    if (isAdmin.data === false && !isSharedCustomModel) {
      history.push({
        ...history.location,
        pathname: `/Advisor/Investments/ModelMarketplace/${portfolioId}/ModelDetails`,
      });
    }
  }, [portfolio, isAdmin.data, history, portfolioId, advisorId]);

  interface CheckBoxOption {
    id: AdvisorId | PartnerInstitutionId;
    label: string;
    type: "advisor" | "institution";
    isShared: boolean;
  }

  const checkBoxOptions = useMemo((): CheckBoxOption[] => {
    if (
      portfolio.data === undefined ||
      institutions.isLoading ||
      institutions.hasError ||
      employees.isLoading ||
      employees.hasError
    ) {
      return [];
    }

    if (portfolio.data.model.portfolioType === "Custom") {
      const advisors = employees.data.advisors
        .filter((adv) => adv.roles.some((role) => role.endsWith("Advisor")))
        .map((adv) => ({
          id: adv.advisorId,
          label: `${adv.name.first} ${adv.name.last}`,
          type: "advisor" as "advisor" | "institution",
          isShared: portfolio.data.model.sharedWith.some(
            (personId) => personId === adv.advisorId
          ),
        }));

      return orderBy(advisors, (el) => el.label.toLowerCase(), "asc");
    }

    const allInstitutions = institutions.data.institutions.map((p) => ({
      id: p.id,
      label: p.name,
      type: "institution" as "advisor" | "institution",
      isShared: portfolio.data.model.institutionsSharedWith.some(
        (instId) => instId === p.id
      ),
    }));

    return orderBy(allInstitutions, (el) => el.label.toLowerCase(), "asc");
  }, [institutions, employees, portfolio]);

  const numberOfAdvisorsWithAccessToCustomModel = useMemo(() => {
    if (
      portfolio.data === undefined ||
      employees.isLoading ||
      employees.hasError
    ) {
      return null;
    }

    if (portfolio.data?.model.portfolioType !== "Custom") {
      return 0;
    }

    if (portfolio.data.model.ownerId === null) {
      return portfolio.data.model.sharedWith.length;
    }

    const advisors = employees.data.advisors.filter((adv) =>
      adv.roles.some((role) => role.endsWith("Advisor"))
    );
    const isCustomModelOwnerAnAdvisor = advisors.some(
      (a) => a.advisorId === portfolio.data.model.ownerId
    );

    return (
      portfolio.data.model.sharedWith.length +
      (isCustomModelOwnerAnAdvisor ? 1 : 0)
    );
  }, [portfolio, employees]);

  if (portfolio.isLoading) {
    return <></>;
  }

  if (portfolio.hasError) {
    return <></>;
  }

  const filterCheckboxes = (search: string) => {
    setSearchInput(search);
  };

  const shareModel = async (checkboxElement: CheckBoxOption) => {
    if (isMutating) {
      return;
    }

    if (partners.isLoading || partners.hasError) {
      statusNotification("Still loading partners", "Error");
      return;
    }

    if (checkboxElement.type === "institution") {
      const portfolioPartner = partners.data.portfolioPartners.find(
        (p) => p.portfolioPartnerName === portfolio.data.model.portfolioType
      );
      if (portfolioPartner === undefined) {
        statusNotification("Cant find institution", "Error");
        throw new Error(
          "Can't match the selected portfolio set (portfolio partner enum) to any names in portfolio partners hook (ModelSharingTable.tsx)"
        );
      }

      const institutionsToShareWith = checkBoxOptions
        .filter(
          (cb) =>
            (cb.isShared && cb.id !== checkboxElement.id) || // all that are already selected and not just flipped
            (cb.id === checkboxElement.id && !cb.isShared) // look at the specific checkbox that was just flipped
        )
        .map((cb) => cb.id);

      if (!isArrayOfInstitutionIds(institutionsToShareWith)) {
        statusNotification(
          "Provided list was not all partner institutions",
          "Error"
        );
        throw new Error(
          "Provided list of institutionIdsToShareWith was not all partner institutions (ModelSharingTable.tsx)"
        );
      }

      try {
        setIsMutating(true);

        await sharePartnerWithInstitution({
          partner: portfolioPartner.portfolioPartnerId,
          institutions: institutionsToShareWith,
        });

        setTimeout(() => {
          statusNotification(
            `Model ${checkboxElement.isShared ? "unshared" : "shared"}`,
            "Success"
          );
          setIsMutating(false);
        }, 2000); // invalidation takes a couple seconds after the initial request
      } catch (_e) {
        statusNotification("Error sharing model", "Error");
        setIsMutating(false);
      }
    } else {
      const advisorsToShareWith = checkBoxOptions
        .filter(
          (cb) =>
            (cb.isShared && cb.id !== checkboxElement.id) || // all that are already selected and not just flipped
            (cb.id === checkboxElement.id && !cb.isShared) // look at the specific checkbox that was just flipped
        )
        .map((cb) => cb.id);

      if (!isArrayOfAdvisorIds(advisorsToShareWith)) {
        statusNotification("Provided list was not all advisor ids", "Error");
        throw new Error(
          "Provided list of advisorIdsToShareWith was not all advisor ids (ModelSharingTable.tsx)"
        );
      }

      try {
        setIsMutating(true);

        await shareCustomPortfolioWithAdvisor({
          portfolioId: portfolioId,
          body: {
            personIds: advisorsToShareWith,
          },
        });

        setTimeout(() => {
          statusNotification(
            `Model ${checkboxElement.isShared ? "unshared" : "shared"}`,
            "Success"
          );
          setIsMutating(false);
        }, 4000); // invalidation takes a couple seconds after the initial request
      } catch (_e) {
        statusNotification("Error sharing model", "Error");
        setIsMutating(false);
      }
    }
  };

  if (isAdmin.hasError) {
    return <div>Error retrieving employee status</div>;
  }

  const advisorsSharedWithText =
    numberOfAdvisorsWithAccessToCustomModel === null
      ? "- -"
      : `${numberOfAdvisorsWithAccessToCustomModel} advisor${
          numberOfAdvisorsWithAccessToCustomModel === 1 ? "" : "s"
        } with access`;

  const institutionsSharedWithText = `${pluralize(
    portfolio.data.model.institutionsSharedWith,
    "institution",
    true
  )} with access`;

  return (
    <>
      {isAdmin.isLoading ? (
        <></>
      ) : isAdmin.data ? (
        <div className={styles.container}>
          <div className={styles.tableHeader}>
            <div className={styles.headerLeft}>
              {portfolio.data.model.portfolioType === "Custom"
                ? "Advisor Access"
                : "Institution Access"}
            </div>

            <div className={styles.headerRight}>
              {portfolio.data.model.portfolioType === "Custom"
                ? advisorsSharedWithText
                : institutionsSharedWithText}
            </div>
          </div>

          {checkBoxOptions.length > 8 && (
            <div className={styles.searchInputContainer}>
              <SearchIcon className={styles.searchIcon} />

              <input
                className={styles.searchInput}
                value={searchInput}
                placeholder={"Search..."}
                type={"text"}
                onChange={(e) => filterCheckboxes(e.target.value)}
              />
            </div>
          )}

          <div className={styles.checkboxesContainer}>
            {checkBoxOptions
              .filter((el) => {
                if (searchInput === "") {
                  return true;
                }

                return el.label
                  .toLowerCase()
                  .includes(searchInput.toLowerCase());
              })
              .map((el) => {
                return (
                  <div key={el.id} className={styles.checkboxDiv}>
                    <Checkbox
                      checked={
                        el.isShared || portfolio.data.model.ownerId === el.id
                      }
                      onChange={() => shareModel(el)}
                      label={el.label}
                      disabled={portfolio.data.model.ownerId === el.id}
                      checkboxStyle={{ marginTop: "2px", marginBottom: "2px" }}
                    />

                    {portfolio && portfolio.data.model.ownerId === el.id && (
                      <div className={styles.ownerText}>{"(Owner)"}</div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <div className={styles.advisorText}>
          This model was shared with you by an administrator and all your
          clients can use it. You cannot edit it or control which clients have
          access.
        </div>
      )}

      {(isMutating || isAdmin.isLoading) && <LogoLoadingStill onTop={true} />}
    </>
  );
};

export default ModelSharingTable;
