/* eslint-disable react/prop-types */

import React, { Component } from "react";

import { connect } from "react-redux";
import { Dispatch } from "redux";

import deleteTrust from "@fartherfinance/frontend/api/Entity/requests/deleteTrust";
import getTrusts from "@fartherfinance/frontend/api/Entity/requests/getTrusts";
import postTrust from "@fartherfinance/frontend/api/Entity/requests/postTrust";
import putTrust from "@fartherfinance/frontend/api/Entity/requests/putTrust";
import {
  EntityId,
  USAState,
  USAStates,
} from "@fartherfinance/frontend/api/Types";

import execute_statusNotification from "@src/multiCustodian/components/GlobalStatusNotification/notification";
import DropdownComponent from "@src/multiCustodian/components/Input/dropdownComponent";
import FileInputDrop from "@src/multiCustodian/components/Input/FileInputDrop";
import Input_Component from "@src/multiCustodian/components/Input/Input_Component_2";
import Input_WithDropdownMenu from "@src/multiCustodian/components/Input/InputWithDropdownMenu";
import AlertMessageModal from "@src/multiCustodian/components/Modals/AlertMessageModal";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import Portal from "@src/multiCustodian/components/Portal/Portal";
import { captureException } from "@src/multiCustodian/services/tracking";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import { MCReduxAction, State } from "@src/store";

import styles from "./trusts.module.css";

type TrustsProps = { drawerFooterPortalId: string } & ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

const neutral_underline_style = {
  borderBottom: "1px solid var(--neutral_input_underline_color_1)",
  width: "100%",
};

const good_underline_style = {
  borderBottom: "1px solid var(--good_input_underline_color_1)",
  width: "100%",
};

const bad_underline_style = {
  borderBottom: "1px solid var(--bad_input_underline_color_1)",
  width: "100%",
};

interface CreateTrustObject {
  trustName: string;
  contactInformation: {
    emailAddress: string;
    phoneNumber: string;
  };
  entityAddress: {
    street: string;
    city: string;
    state: USAState;
    postalCode: string;
    unit: string | null;
    country: string;
  };
}

interface Trust {
  entityId: EntityId;
  trustName: string;
  contactInformation: {
    emailAddress: string;
    phoneNumber: string;
  };
  entityAddress: {
    city: string;
    country: string;
    postalCode: string;
    state: USAState;
    street: string;
    unit: string | null;
  };
  trustFormationDocuments: string;
}

interface InitialState {
  isLoading: boolean;
  all_ok: boolean;
  show_modal: boolean;

  entity_name: string;
  entity_name_error_description: string;
  entity_name_underline_style: object;
  entity_name_ok: boolean;
  entity_name__untouched: boolean;

  address: string;
  address_error_description: string;
  address_underline_style: object;
  address_ok: boolean;
  address__untouched: boolean;

  city: string;
  city_error_description: string;
  city_underline_style: object;
  city_ok: boolean;
  city__untouched: boolean;

  state: USAState | null;
  state_ok: boolean;

  zip: string;
  zip_error_description: string;
  zip_underline_style: object;
  zip_ok: boolean;
  zip__untouched: boolean;

  email: string;
  email_error_description: string;
  email_underline_style: object;
  email_ok: boolean;
  email__untouched: boolean;

  phone: string;
  phone_error_description: string;
  phone_underline_style: object;
  phone_ok: boolean;
  phone__untouched: boolean;

  trustFormation_file: File | null;
  adding_trust: boolean;
  trusts: Trust[];
  cur_trust: Trust | null;
  clearFile: boolean;
}

class Trusts extends Component<TrustsProps, InitialState> {
  constructor(props: TrustsProps) {
    super(props);
    this.state = {
      isLoading: false,
      all_ok: false,
      show_modal: false,

      entity_name: "",
      entity_name_error_description: "",
      entity_name_underline_style: neutral_underline_style,
      entity_name_ok: false,
      entity_name__untouched: true,

      address: "",
      address_ok: false,
      address_underline_style: neutral_underline_style,
      address_error_description: "",
      address__untouched: true,

      city: "",
      city_ok: false,
      city_underline_style: neutral_underline_style,
      city_error_description: "",
      city__untouched: true,

      state: null,
      state_ok: false,

      zip: "",
      zip_ok: false,
      zip_underline_style: neutral_underline_style,
      zip_error_description: "",
      zip__untouched: true,

      email: "",
      email_ok: false,
      email_underline_style: neutral_underline_style,
      email_error_description: "",
      email__untouched: true,

      phone: "",
      phone_ok: false,
      phone_underline_style: neutral_underline_style,
      phone_error_description: "",
      phone__untouched: true,

      trustFormation_file: null,
      adding_trust: true,
      trusts: [],
      cur_trust: null,
      clearFile: false,
    };
  }

  componentDidMount() {
    this.getTrusts(null);
  }

  handleAnInputChange = () => {
    let all_ok = false;
    if (
      this.state.entity_name_ok &&
      this.state.email_ok &&
      this.state.phone_ok &&
      this.state.address_ok &&
      this.state.city_ok &&
      this.state.state_ok &&
      this.state.zip_ok
    ) {
      if (
        this.state.adding_trust &&
        this.state.trustFormation_file
        //this.state.trusteeCertification_file
      ) {
        all_ok = true;
      } else {
        if (
          this.state.cur_trust &&
          this.state.cur_trust.entityId &&
          (this.state.cur_trust.trustName !== this.state.entity_name ||
            this.state.cur_trust.contactInformation.emailAddress !==
              this.state.email ||
            this.state.cur_trust.contactInformation.phoneNumber !==
              this.state.phone.substring(0, 3) +
                this.state.phone.substring(4, 7) +
                this.state.phone.substring(8, 12) ||
            this.state.cur_trust.entityAddress.street !== this.state.address ||
            this.state.cur_trust.entityAddress.city !== this.state.city ||
            this.state.cur_trust.entityAddress.state !== this.state.state ||
            this.state.cur_trust.entityAddress.postalCode !== this.state.zip ||
            this.state.trustFormation_file)
          //this.state.trusteeCertification_file
        ) {
          all_ok = true;
        }
      }
    }

    this.setState({ all_ok: all_ok });
  };

  handleEntityName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!value.match(/^[a-zA-Z0-9-\s]+$/)) {
      this.setState(
        {
          entity_name: value,
          entity_name_error_description: "Contains unusable symbols",
          entity_name_underline_style: bad_underline_style,
          entity_name_ok: false,
          entity_name__untouched: false,
        },
        () => {
          this.handleAnInputChange();
        }
      );
    } else {
      this.setState(
        {
          entity_name: value,
          entity_name_error_description: "",
          entity_name_underline_style: good_underline_style,
          entity_name_ok: true,
          entity_name__untouched: false,
        },
        () => {
          this.handleAnInputChange();
        }
      );
    }
  };

  handleAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.toLowerCase().indexOf("po box") >= 0) {
      this.setState(
        {
          address: value,
          address_ok: false,
          address_underline_style: bad_underline_style,
          address_error_description: "A PO Box address is not a valid address",
          address__untouched: false,
        },
        () => {
          this.handleAnInputChange();
        }
      );
    } else if (value.match(/^[a-zA-Z0-9\s\-\#,.]+$/)) {
      this.setState(
        {
          address: value,
          address_ok: true,
          address_underline_style: good_underline_style,
          address_error_description: "",
          address__untouched: false,
        },
        () => {
          this.handleAnInputChange();
        }
      );
    } else {
      this.setState(
        {
          address: value,
          address_ok: false,
          address_underline_style: bad_underline_style,
          address_error_description: "This does not look like a valid address",
          address__untouched: false,
        },
        () => {
          this.handleAnInputChange();
        }
      );
    }
  };

  handleCity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.match(/^[A-Z]{1}[a-zA-Z\s]+$/)) {
      this.setState(
        {
          city: value,
          city_ok: true,
          city_underline_style: good_underline_style,
          city_error_description: "",
          city__untouched: false,
        },
        () => {
          this.handleAnInputChange();
        }
      );
    } else {
      this.setState(
        {
          city: value,
          city_ok: false,
          city_underline_style: bad_underline_style,
          city_error_description:
            "Only letters and spaces. Capitalize first letters",
          city__untouched: false,
        },
        () => {
          this.handleAnInputChange();
        }
      );
    }
  };

  handleStateChange = (state: USAState) => {
    this.setState({ state: state, state_ok: true }, () => {
      this.handleAnInputChange();
    });
  };

  handleZip = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value && value.length >= 6) {
      return;
    }
    if (value.match(/^[0-9]{5}$/)) {
      this.setState(
        {
          zip: value,
          zip_ok: true,
          zip_underline_style: good_underline_style,
          zip_error_description: "",
          zip__untouched: false,
        },
        () => {
          this.handleAnInputChange();
        }
      );
    } else {
      this.setState(
        {
          zip: value,
          zip_ok: false,
          zip_underline_style: bad_underline_style,
          zip_error_description: "Must be a 5 digit number",
          zip__untouched: false,
        },
        () => {
          this.handleAnInputChange();
        }
      );
    }
  };

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const email_regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid_email = value.match(email_regex);
    if (!valid_email) {
      this.setState(
        {
          email_underline_style: bad_underline_style,
          email_error_description: "Email does not look valid",
          email_ok: false,
          email: value,
          email__untouched: false,
        },
        () => {
          this.handleAnInputChange();
        }
      );
    } else {
      this.setState(
        {
          email_underline_style: good_underline_style,
          email_error_description: "",
          email_ok: true,
          email: value,
          email__untouched: false,
        },
        () => {
          this.handleAnInputChange();
        }
      );
    }
  };

  handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event &&
      event.target &&
      event.target.value &&
      event.target.value.length > 12
    ) {
      return;
    }
    let error_description = "";
    let style = neutral_underline_style;
    let show_checkmark = false;
    let phone_value = event.target.value;
    if (phone_value.length !== 12) {
      error_description = "Should be a 10 digit number";
      style = bad_underline_style;
      show_checkmark = false;
    } else if (
      !phone_value.match(
        /^[0-9][0-9][0-9]\-[0-9][0-9][0-9]\-[0-9][0-9][0-9][0-9]+$/
      )
    ) {
      error_description = "Must only contain digits";
      style = bad_underline_style;
      show_checkmark = false;
    } else if (phone_value.length === 12) {
      show_checkmark = true;
      style = good_underline_style;
    }
    if (
      (phone_value.length === 3 && this.state.phone.length === 2) ||
      (phone_value.length === 7 && this.state.phone.length === 6)
    ) {
      phone_value = phone_value + "-";
    }
    if (
      (phone_value.length === 3 && this.state.phone.length === 4) ||
      (phone_value.length === 7 && this.state.phone.length === 8)
    ) {
      phone_value = phone_value.substring(0, phone_value.length - 1);
    }
    this.setState(
      {
        phone_underline_style: style,
        phone_error_description: error_description,
        phone_ok: show_checkmark,
        phone: phone_value,
        phone__untouched: false,
      },
      () => {
        this.handleAnInputChange();
      }
    );
  };

  setTrustFormation = (file: File, clearFile: boolean) => {
    this.setState({ trustFormation_file: file, clearFile: clearFile }, () => {
      this.handleAnInputChange();
    });
  };

  setTrusteeCertification = (_file: File, clearFile: boolean) => {
    this.setState({ clearFile: clearFile }, () => {
      this.handleAnInputChange();
    });
  };

  createTrust = () => {
    if (
      this.state.state === null ||
      !this.state.all_ok ||
      this.state.trustFormation_file === null
    ) {
      return;
    }

    const clientId = this.props.main_Reducer.user.id_user;
    const jwt = this.props.main_Reducer.farther_jwt;
    const isAdvisor = this.props.main_Reducer.user.isAdvisor;
    const advisorId = this.props.main_Reducer.cockroach_advisor_id;

    if (
      clientId === null ||
      jwt === null ||
      (isAdvisor && advisorId === null)
    ) {
      const err = new Error("clientId or jwt or advisorId is null");

      captureException(err, {
        extra: {
          clientId: clientId ?? "null",
          jwt: jwt ?? "null",
          advisorId: advisorId ?? "null",
          isAdvisor: isAdvisor ?? "null",
        },
      });

      throw err;
    }

    const auth = {
      clientId,
      jwt,
      ...(isAdvisor && advisorId !== null && { advisorId }),
    };

    this.setState({ isLoading: true });

    const trustData: CreateTrustObject = {
      trustName: this.state.entity_name.trim(),
      contactInformation: {
        emailAddress: this.state.email,
        phoneNumber:
          this.state.phone.substring(0, 3) +
          this.state.phone.substring(4, 7) +
          this.state.phone.substring(8, 12),
      },
      entityAddress: {
        street: this.state.address,
        city: this.state.city,
        state: this.state.state,
        postalCode: this.state.zip,
        unit: null,
        country: "US",
      },
    };

    postTrust(trustData, this.state.trustFormation_file, auth)
      .then((entity) => {
        execute_statusNotification(
          "/src/assets/svg/check_green.svg",
          "Trust created"
        );

        this.setState({
          entity_name_underline_style: neutral_underline_style,
          address_underline_style: neutral_underline_style,
          city_underline_style: neutral_underline_style,
          zip_underline_style: neutral_underline_style,
          email_underline_style: neutral_underline_style,
          phone_underline_style: neutral_underline_style,
          clearFile: true,
          all_ok: false,
          adding_trust: false,
        });

        this.getTrusts(entity.entityId);
      })
      .catch(() => {
        execute_statusNotification(
          "/src/assets/svg/!.svg",
          "Trust was not created"
        );
        this.setState({ isLoading: false });
      });
  };

  updateTrust = () => {
    if (
      !this.state.all_ok ||
      this.state.cur_trust === null ||
      this.state.state === null
    ) {
      return;
    }

    const clientId = this.props.main_Reducer.user.id_user;
    const jwt = this.props.main_Reducer.farther_jwt;
    const isAdvisor = this.props.main_Reducer.user.isAdvisor;
    const advisorId = this.props.main_Reducer.cockroach_advisor_id;

    if (
      clientId === null ||
      jwt === null ||
      (isAdvisor && advisorId === null)
    ) {
      const err = new Error("clientId or jwt or advisorId is null");

      captureException(err, {
        extra: {
          clientId: clientId ?? "null",
          jwt: jwt ?? "null",
          advisorId: advisorId ?? "null",
          isAdvisor: isAdvisor ?? "null",
        },
      });

      throw err;
    }

    const auth = {
      clientId,
      jwt,
      ...(isAdvisor && advisorId !== null && { advisorId }),
    };

    const trustData: CreateTrustObject = {
      trustName: this.state.entity_name.trim(),
      contactInformation: {
        emailAddress: this.state.email,
        phoneNumber:
          this.state.phone.substring(0, 3) +
          this.state.phone.substring(4, 7) +
          this.state.phone.substring(8, 12),
      },
      entityAddress: {
        street: this.state.address,
        city: this.state.city,
        state: this.state.state,
        postalCode: this.state.zip,
        unit: null,
        country: "US",
      },
    };

    this.setState({ isLoading: true });

    putTrust(
      this.state.cur_trust.entityId,
      trustData,
      this.state.trustFormation_file,
      auth
    )
      .then(() => {
        execute_statusNotification(
          "/src/assets/svg/check_green.svg",
          "Trust updated"
        );

        this.setState({
          entity_name_underline_style: neutral_underline_style,
          address_underline_style: neutral_underline_style,
          city_underline_style: neutral_underline_style,
          zip_underline_style: neutral_underline_style,
          email_underline_style: neutral_underline_style,
          phone_underline_style: neutral_underline_style,
          clearFile: true,
          all_ok: false,
        });

        this.getTrusts(
          this.state.cur_trust ? this.state.cur_trust.entityId : null
        );
      })
      .catch(() => {
        execute_statusNotification(
          "/src/assets/svg/!.svg",
          "Trust did not update"
        );
        this.setState({ isLoading: false });
      });
  };

  getTrusts = (entityId: EntityId | null) => {
    const clientId = this.props.main_Reducer.user.id_user;
    const jwt = this.props.main_Reducer.farther_jwt;
    const isAdvisor = this.props.main_Reducer.user.isAdvisor;
    const advisorId = this.props.main_Reducer.cockroach_advisor_id;

    if (
      clientId === null ||
      jwt === null ||
      (isAdvisor && advisorId === null)
    ) {
      const err = new Error("clientId or jwt or advisorId is null");

      captureException(err, {
        extra: {
          clientId: clientId ?? "null",
          jwt: jwt ?? "null",
          advisorId: advisorId ?? "null",
          isAdvisor: isAdvisor ?? "null",
        },
      });

      throw err;
    }

    const auth = {
      clientId,
      jwt,
      ...(isAdvisor && advisorId !== null && { advisorId }),
    };

    this.setState({ isLoading: true });

    getTrusts(auth)
      .then((trusts) => {
        if (entityId) {
          let trust = null;
          for (let i = 0; i < trusts.length; i++) {
            if (entityId === trusts[i].entityId) {
              trust = trusts[i];
              break;
            }
          }

          if (trust) {
            this.setState({
              adding_trust: false,
              cur_trust: trust,

              entity_name: trust.trustName,
              entity_name_error_description: "",
              entity_name_underline_style: neutral_underline_style,
              entity_name_ok: true,
              entity_name__untouched: true,

              address: trust.entityAddress.street,
              address_ok: true,
              address_underline_style: neutral_underline_style,
              address_error_description: "",
              address__untouched: true,

              city: trust.entityAddress.city,
              city_ok: true,
              city_underline_style: neutral_underline_style,
              city_error_description: "",
              city__untouched: true,

              state: trust.entityAddress.state,
              state_ok: true,

              zip: trust.entityAddress.postalCode,
              zip_ok: true,
              zip_underline_style: neutral_underline_style,
              zip_error_description: "",
              zip__untouched: true,

              email: trust.contactInformation.emailAddress,
              email_ok: true,
              email_underline_style: neutral_underline_style,
              email_error_description: "",
              email__untouched: true,

              phone:
                trust.contactInformation.phoneNumber.substring(0, 3) +
                "-" +
                trust.contactInformation.phoneNumber.substring(3, 6) +
                "-" +
                trust.contactInformation.phoneNumber.substring(6, 10),
              phone_ok: true,
              phone_underline_style: neutral_underline_style,
              phone_error_description: "",
              phone__untouched: true,

              clearFile: true,
              trusts,
              isLoading: false,
            });
          } else {
            this.setState({
              entity_name: "",
              entity_name_error_description: "",
              entity_name_underline_style: neutral_underline_style,
              entity_name_ok: false,

              address: "",
              address_ok: false,
              address_underline_style: neutral_underline_style,
              address_error_description: "",
              address__untouched: true,

              city: "",
              city_ok: false,
              city_underline_style: neutral_underline_style,
              city_error_description: "",
              city__untouched: true,

              state: null,
              state_ok: false,

              zip: "",
              zip_ok: false,
              zip_underline_style: neutral_underline_style,
              zip_error_description: "",
              zip__untouched: true,

              email: "",
              email_ok: false,
              email_underline_style: neutral_underline_style,
              email_error_description: "",
              email__untouched: true,

              phone: "",
              phone_ok: false,
              phone_underline_style: neutral_underline_style,
              phone_error_description: "",
              phone__untouched: true,

              clearFile: true,
              trusts,
              isLoading: false,
              adding_trust: true,
              cur_trust: null,
            });
          }
        } else {
          const trust = trusts.length >= 1 ? trusts[0] : null;
          if (trust) {
            this.setState({
              adding_trust: false,
              cur_trust: trust,
              trusts,
              isLoading: false,

              entity_name: trust.trustName,
              entity_name_error_description: "",
              entity_name_underline_style: neutral_underline_style,
              entity_name_ok: true,
              entity_name__untouched: true,

              address: trust.entityAddress.street,
              address_ok: true,
              address_underline_style: neutral_underline_style,
              address_error_description: "",
              address__untouched: true,

              city: trust.entityAddress.city,
              city_ok: true,
              city_underline_style: neutral_underline_style,
              city_error_description: "",
              city__untouched: true,

              state: trust.entityAddress.state,
              state_ok: true,

              zip: trust.entityAddress.postalCode,
              zip_ok: true,
              zip_underline_style: neutral_underline_style,
              zip_error_description: "",
              zip__untouched: true,

              email: trust.contactInformation.emailAddress,
              email_ok: true,
              email_underline_style: neutral_underline_style,
              email_error_description: "",
              email__untouched: true,

              phone:
                trust.contactInformation.phoneNumber.substring(0, 3) +
                "-" +
                trust.contactInformation.phoneNumber.substring(3, 6) +
                "-" +
                trust.contactInformation.phoneNumber.substring(6, 10),
              phone_ok: true,
              phone_underline_style: neutral_underline_style,
              phone_error_description: "",
              phone__untouched: true,
            });
          } else {
            this.setState({
              entity_name: "",
              entity_name_error_description: "",
              entity_name_underline_style: neutral_underline_style,
              entity_name_ok: false,
              entity_name__untouched: true,

              address: "",
              address_ok: false,
              address_underline_style: neutral_underline_style,
              address_error_description: "",
              address__untouched: true,

              city: "",
              city_ok: false,
              city_underline_style: neutral_underline_style,
              city_error_description: "",
              city__untouched: true,

              state: null,
              state_ok: false,

              zip: "",
              zip_ok: false,
              zip_underline_style: neutral_underline_style,
              zip_error_description: "",
              zip__untouched: true,

              email: "",
              email_ok: false,
              email_underline_style: neutral_underline_style,
              email_error_description: "",
              email__untouched: true,

              phone: "",
              phone_ok: false,
              phone_underline_style: neutral_underline_style,
              phone_error_description: "",
              phone__untouched: true,

              clearFile: true,
              trusts,
              isLoading: false,
              adding_trust: true,
              cur_trust: null,
            });
          }
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });

        execute_statusNotification(
          "/src/assets/svg/!.svg",
          "Could not get trusts"
        );
      });
  };

  deleteEntity = () => {
    if (this.state.cur_trust === null) {
      return;
    }
    const clientId = this.props.main_Reducer.user.id_user;
    const jwt = this.props.main_Reducer.farther_jwt;
    const isAdvisor = this.props.main_Reducer.user.isAdvisor;
    const advisorId = this.props.main_Reducer.cockroach_advisor_id;

    if (
      clientId === null ||
      jwt === null ||
      (isAdvisor && advisorId === null)
    ) {
      const err = new Error("clientId or jwt or advisorId is null");

      captureException(err, {
        extra: {
          clientId: clientId ?? "null",
          jwt: jwt ?? "null",
          advisorId: advisorId ?? "null",
          isAdvisor: isAdvisor ?? "null",
        },
      });

      throw err;
    }

    const auth = {
      clientId,
      jwt,
      ...(isAdvisor && advisorId !== null && { advisorId }),
    };

    this.setState({ isLoading: true });

    deleteTrust(this.state.cur_trust.entityId, auth)
      .then(() => {
        execute_statusNotification(
          "/src/assets/svg/check_green.svg",
          "Trust removed"
        );

        this.getTrusts(null);
      })
      .catch(() => {
        this.setState({ isLoading: false });
        execute_statusNotification(
          "/src/assets/svg/check_green.svg",
          "Trust was not removed"
        );
      });
  };

  saveChanges = () => {
    if (this.state.adding_trust) {
      this.createTrust();
    } else {
      this.updateTrust();
    }
  };

  addEntity = () => {
    this.setState({
      adding_trust: true,

      entity_name: "",
      entity_name_error_description: "",
      entity_name_underline_style: neutral_underline_style,
      entity_name_ok: false,
      entity_name__untouched: true,

      address: "",
      address_ok: false,
      address_underline_style: neutral_underline_style,
      address_error_description: "",
      address__untouched: true,

      city: "",
      city_ok: false,
      city_underline_style: neutral_underline_style,
      city_error_description: "",
      city__untouched: true,

      state: null,
      state_ok: false,

      zip: "",
      zip_ok: false,
      zip_underline_style: neutral_underline_style,
      zip_error_description: "",
      zip__untouched: true,

      email: "",
      email_ok: false,
      email_underline_style: neutral_underline_style,
      email_error_description: "",
      email__untouched: true,

      phone: "",
      phone_ok: false,
      phone_underline_style: neutral_underline_style,
      phone_error_description: "",
      phone__untouched: true,

      clearFile: true,
      cur_trust: null,
    });
  };

  removeEntity = () => {
    if (!this.state.adding_trust) {
      this.setState({ show_modal: true });
      this.props.set_is_in_a_modal(true);
    }
  };

  confirmRemoval = () => {
    this.setState({ show_modal: false });
    this.props.set_is_in_a_modal(false);
    this.deleteEntity();
  };

  selectTrust = (trust: Trust) => {
    this.setState({
      adding_trust: false,
      cur_trust: trust,

      entity_name: trust.trustName,
      entity_name_error_description: "",
      entity_name_underline_style: neutral_underline_style,
      entity_name_ok: true,
      entity_name__untouched: true,

      address: trust.entityAddress.street,
      address_ok: true,
      address_underline_style: neutral_underline_style,
      address_error_description: "",
      address__untouched: true,

      city: trust.entityAddress.city,
      city_ok: true,
      city_underline_style: neutral_underline_style,
      city_error_description: "",
      city__untouched: true,

      state: trust.entityAddress.state,
      state_ok: true,

      zip: trust.entityAddress.postalCode,
      zip_ok: true,
      zip_underline_style: neutral_underline_style,
      zip_error_description: "",
      zip__untouched: true,

      email: trust.contactInformation.emailAddress,
      email_ok: true,
      email_underline_style: neutral_underline_style,
      email_error_description: "",
      email__untouched: true,

      phone:
        trust.contactInformation.phoneNumber.substring(0, 3) +
        "-" +
        trust.contactInformation.phoneNumber.substring(3, 6) +
        "-" +
        trust.contactInformation.phoneNumber.substring(6, 10),
      phone_ok: true,
      phone_underline_style: neutral_underline_style,
      phone_error_description: "",
      phone__untouched: true,

      clearFile: true,
      all_ok: false,
    });
  };

  render() {
    return (
      <div className={styles.container}>
        {this.state.isLoading && (
          <div className={styles.loading_container}>
            <LogoLoadingStill />
          </div>
        )}

        <div className={styles.content_container}>
          <div className={styles.main_div}>
            <div className={styles.header}>
              <p className={styles.title}>Trusts</p>

              <p className={styles.text}>
                Have a trust in mind for an account or want to make an update?
                Please add the necessary info for your trust below.
              </p>

              <p className={styles.subtitle}>Trust</p>
            </div>

            <div className={styles.hr_line}></div>

            <div className={styles.subheader}>
              <div className={styles.left}>
                <DropdownComponent
                  current_selection={this.state.cur_trust}
                  style={"full"}
                  options={this.state.trusts}
                  optionSelectors={["trustName"]}
                  useOptionSelectorForTitle={true}
                  placeholder={"New"}
                  onChange={(trust: Trust) => this.selectTrust(trust)}
                />
              </div>

              <div className={styles.right}>
                <div onClick={this.removeEntity} className={styles.remove}>
                  <p>Remove</p>
                  <img
                    src={
                      this.props.main_Reducer.brand.current.images.remove_circle
                    }
                    alt="remove"
                  ></img>
                </div>

                <div onClick={this.addEntity} className={styles.add}>
                  <p>Add</p>
                  <img
                    src={
                      this.props.main_Reducer.brand.current.images.add_circle
                    }
                    style={{ width: "20px", height: "20px" }}
                    alt="add"
                  ></img>
                </div>
              </div>
            </div>

            <div className={styles.form_container}>
              <div className={styles.input_pair_container}>
                <div className={styles.input_container_2}>
                  <Input_Component
                    title={"Trust Name"}
                    name={"entity_name"}
                    type={"text"}
                    placeholder={"John's Trust"}
                    value={this.state.entity_name}
                    validation_img={
                      this.state.entity_name_ok
                        ? "/src/assets/svg/check_green.svg"
                        : "/src/assets/svg/!.svg"
                    }
                    show_checkmark={!this.state.entity_name__untouched}
                    underline_style={this.state.entity_name_underline_style}
                    error_description={this.state.entity_name_error_description}
                    handleInputChange={this.handleEntityName}
                  />
                </div>

                <div className={styles.input_container}>
                  <Input_Component
                    title={"Country"}
                    name={"country"}
                    type={"text"}
                    placeholder={""}
                    value={"United States"}
                    validation_img={"/src/assets/svg/check_green.svg"}
                    show_checkmark={false}
                    underline_style={neutral_underline_style}
                    hideCaretCursor={true}
                    handleInputChange={() => undefined}
                  />
                </div>
              </div>

              <div className={styles.input_pair_container}>
                <div className={styles.input_container_2}>
                  <Input_Component
                    title={"Address"}
                    name={"address"}
                    type={"text"}
                    placeholder={"123 Hancock St"}
                    value={this.state.address}
                    validation_img={
                      this.state.address_ok
                        ? "/src/assets/svg/check_green.svg"
                        : "/src/assets/svg/!.svg"
                    }
                    show_checkmark={!this.state.address__untouched}
                    underline_style={this.state.address_underline_style}
                    error_description={this.state.address_error_description}
                    handleInputChange={this.handleAddress}
                  />
                </div>

                <div className={styles.input_container}>
                  <Input_Component
                    title={"City"}
                    name={"city"}
                    type={"text"}
                    placeholder={"Los Angeles"}
                    value={this.state.city}
                    validation_img={
                      this.state.city_ok
                        ? "/src/assets/svg/check_green.svg"
                        : "/src/assets/svg/!.svg"
                    }
                    show_checkmark={!this.state.city__untouched}
                    underline_style={this.state.city_underline_style}
                    error_description={this.state.city_error_description}
                    handleInputChange={this.handleCity}
                  />
                </div>
              </div>

              <div className={styles.input_pair_container}>
                <div className={styles.input_container_2}>
                  <Input_WithDropdownMenu
                    title={"State"}
                    name={"state"}
                    type={"text"}
                    placeholder={""}
                    validation_img={"/src/assets/svg/chevron-down-grey.svg"}
                    show_validation_img={true}
                    underline_style={neutral_underline_style}
                    cur_item={this.state.state ?? undefined}
                    items={USAStates}
                    changeMenuState={(new_item: USAState) =>
                      this.handleStateChange(new_item)
                    }
                    container_style={{ margin: "0px 0px 0px 0px" }}
                    dropdownMenuStyle={{ height: "195px" }}
                  />
                </div>

                <div className={styles.input_container}>
                  <Input_Component
                    title={"Zip Code"}
                    name={"zip"}
                    type={"text"}
                    placeholder={"12345"}
                    value={this.state.zip}
                    validation_img={
                      this.state.zip_ok
                        ? "/src/assets/svg/check_green.svg"
                        : "/src/assets/svg/!.svg"
                    }
                    show_checkmark={!this.state.zip__untouched}
                    underline_style={this.state.zip_underline_style}
                    error_description={this.state.zip_error_description}
                    handleInputChange={this.handleZip}
                  />
                </div>
              </div>

              <div className={styles.input_pair_container}>
                <div className={styles.input_container_2}>
                  <Input_Component
                    title={"Phone Number"}
                    name={"phone"}
                    type={"text"}
                    pre_text={"+1"}
                    placeholder={"123-456-7890"}
                    value={this.state.phone}
                    validation_img={
                      this.state.phone_ok
                        ? "/src/assets/svg/check_green.svg"
                        : "/src/assets/svg/!.svg"
                    }
                    show_checkmark={!this.state.phone__untouched}
                    underline_style={this.state.phone_underline_style}
                    error_description={this.state.phone_error_description}
                    handleInputChange={this.handlePhoneChange}
                  />
                </div>

                <div className={styles.input_container}>
                  <Input_Component
                    title={"Email Address"}
                    name={"email"}
                    type={"text"}
                    placeholder={"johnjaydoe@email.com"}
                    value={this.state.email}
                    validation_img={
                      this.state.email_ok
                        ? "/src/assets/svg/check_green.svg"
                        : "/src/assets/svg/!.svg"
                    }
                    show_checkmark={!this.state.email__untouched}
                    underline_style={this.state.email_underline_style}
                    error_description={this.state.email_error_description}
                    handleInputChange={this.handleEmailChange}
                  />
                </div>
              </div>

              <div className={styles.docUploadContainer}>
                <div className={styles.docUploadDivLeft}>
                  <p className={styles.docUploadHeaderText}>Document Type 1</p>

                  <p className={styles.docUploadTitleText}>
                    Trust Formation Document
                  </p>

                  <FileInputDrop
                    file_input_id={"1"}
                    accepted_file_types={".pdf"}
                    prompt={"Drop pdf file here or"}
                    clearFile={this.state.clearFile}
                    fileSettled={(file: File | null, clearFile: boolean) =>
                      file !== null
                        ? this.setTrustFormation(file, clearFile)
                        : undefined
                    }
                  />
                </div>

                {false && (
                  <div className={styles.docUploadDivRight}>
                    <p className={styles.docUploadHeaderText}>
                      Document Type 2
                    </p>

                    <p className={styles.docUploadTitleText}>
                      Trustee Certification Of Powers
                    </p>

                    <FileInputDrop
                      file_input_id={"2"}
                      accepted_file_types={".pdf"}
                      prompt={"Drop pdf file here or"}
                      clearFile={this.state.clearFile}
                      fileSettled={(file: File | null, clearFile: boolean) =>
                        file !== null
                          ? this.setTrusteeCertification(file, clearFile)
                          : null
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Portal targetNodeId={this.props.drawerFooterPortalId}>
          <Button
            disabled={
              this.state.adding_trust
                ? this.state.all_ok && this.state.trustFormation_file
                  ? false
                  : true
                : this.state.all_ok &&
                  this.state.cur_trust &&
                  this.state.cur_trust.entityId
                ? false
                : true
            }
            variant={"contained"}
            buttonType={"primary"}
            text={this.state.adding_trust ? "Create" : "Save"}
            onClick={this.saveChanges}
          />
        </Portal>

        {this.state.show_modal && (
          <AlertMessageModal
            top_text={() =>
              this.state.cur_trust ? (
                <div>
                  <p>{`Are you sure you want to remove this trust:`}</p>
                  <p>
                    <span>{`"${this.state.cur_trust.trustName}"`}</span>
                  </p>
                </div>
              ) : (
                <p>{`Are you sure you want to remove this trust?`}</p>
              )
            }
            bottom_text={null}
            button_blue_text={"No"}
            button_blue_onClick={() => {
              this.setState({ show_modal: false });
              this.props.set_is_in_a_modal(false);
            }}
            button_yellow_text={"Yes"}
            button_yellow_onClick={this.confirmRemoval}
            img_src={null}
            modal_style={null}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    main_Reducer: state.main_Reducer,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<MCReduxAction>) => {
  return {
    set_is_in_a_modal: (bool: boolean) => {
      dispatch({
        type: "SET_IS_IN_A_MODAL",
        payload: {
          is_in_a_modal: bool,
        },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Trusts);
