import React, { useEffect, useMemo, useState } from "react";

import { orderBy } from "lodash";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import useReplaceSleeve from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useReplaceSleeve";
import useSleeve from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useSleeve";
import useSleeves from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useSleeves";
import { SleeveId } from "@fartherfinance/frontend/api/Types";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import Modal from "@src/sharedComponents/Modal/Modal";
import Select from "@src/sharedComponents/Select/Select";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import { State } from "@src/store";

import styles from "./ReplaceSleeveModal.module.css";

const ReplaceSleeveModal = (): JSX.Element => {
  const [isMutating, setIsMutating] = useState<boolean>(false);
  const [sleeveName, setSleeveName] = useState<string | null>(null);
  const [newSleeveId, setNewSleeveId] = useState<SleeveId | null>(null);

  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));

  const { sleeveId, sleeveDrawerTab } = useParams<{
    sleeveId: SleeveId;
    sleeveDrawerTab: "Sharing" | "SleeveUsage" | "SleeveDetails";
  }>();

  const history = useHistory();

  useEffect(() => {
    if (
      sleeveDrawerTab === "Sharing" ||
      sleeveDrawerTab === "SleeveUsage" ||
      sleeveDrawerTab === "SleeveDetails"
    ) {
      return;
    } else {
      history.push({
        ...history.location,
        pathname: `/Advisor/Investments/Sleeves/${sleeveId}`,
      });
    }
  }, [history, sleeveId, sleeveDrawerTab]);

  const statusNotification = useStatusNotification();

  const auth = useAdvisorRequestAuth();
  const sleeve = useSleeve(sleeveId, auth);
  const sleeves = useSleeves(advisorId, auth);
  const replaceSleeve = useReplaceSleeve(auth);

  const sleeveNameOptions = useMemo((): string[] => {
    if (sleeves.data === undefined) {
      return [];
    }

    const activeSleeves = sleeves.data.sleeves
      .filter((s) => s.sleeveId !== sleeveId)
      .filter((s) => s.isActive)
      .map((s) => s.displayName);

    return orderBy(activeSleeves, (cm) => cm.toLowerCase(), "asc");
  }, [sleeves.data, sleeveId]);

  const onSubmit = async () => {
    if (!newSleeveId) {
      statusNotification("Error finding the new portfolio id", "Error");
      return;
    }

    try {
      setIsMutating(true);

      await replaceSleeve({
        oldSleeve: sleeveId,
        newSleeve: newSleeveId,
      });

      setTimeout(() => {
        statusNotification("Sleeve replaced and archived", "Success");
        setIsMutating(false);

        history.push({
          ...history.location,
          pathname: `/Advisor/Investments/Sleeves/${sleeveId}/${sleeveDrawerTab}`,
          state: {
            sleevesActiveVsArchivedFilter: "Archived Sleeves",
          },
        });
      }, 3000);
    } catch (_e) {
      statusNotification("Error replacing and archiving sleeve", "Error");
      setIsMutating(false);
    }
  };

  const onChangeSleeve = (name: string): void => {
    if (sleeves.data === undefined) {
      return;
    }

    setSleeveName(name);

    const targetSleeve = sleeves.data.sleeves.find(
      (s) => s.displayName === name
    );

    if (targetSleeve === undefined) {
      setNewSleeveId(null);
      statusNotification("Sleeve not found", "Error");
    } else {
      setNewSleeveId(targetSleeve.sleeveId);
    }
  };

  if (sleeve.hasError || sleeves.hasError) {
    return <div>Error retrieving sleeves</div>;
  }

  if (sleeve.isLoading || sleeves.isLoading || isMutating) {
    return (
      <LoadingModal sleeveId={sleeveId} sleeveDrawerTab={sleeveDrawerTab} />
    );
  }

  return (
    <Modal
      closeModal={() =>
        history.push({
          ...history.location,
          pathname: `/Advisor/Investments/Sleeves/${sleeveId}/${sleeveDrawerTab}`,
        })
      }
      modalStyle={{ width: "520px" }}
    >
      <div className={styles.modalTitle}>Choose a Replacement Sleeve</div>

      <div className={styles.modalText}>
        {`To archive the sleeve${
          sleeve.data.displayName ? ` ${sleeve.data.displayName},` : ","
        } you must replace it.`}
      </div>

      <div className={styles.flexRowMarginTop}>
        <Select
          value={sleeveName === null ? "Sleeve Name" : sleeveName}
          options={sleeveNameOptions}
          onChange={onChangeSleeve}
          style={{ width: "100%" }}
          renderValue={(value) => value}
        />
      </div>

      <div className={styles.modalFooter}>
        <Button
          variant={"outlined"}
          buttonType={"secondary"}
          text={"Cancel"}
          onClick={() =>
            history.push({
              ...history.location,
              pathname: `/Advisor/Investments/Sleeves/${sleeveId}/${sleeveDrawerTab}`,
            })
          }
        />

        <Button
          disabled={!newSleeveId}
          variant={"contained"}
          buttonType={"primary"}
          text={"Submit"}
          onClick={onSubmit}
          style={{ marginLeft: "15px" }}
        />
      </div>
    </Modal>
  );
};

export default ReplaceSleeveModal;

interface LoadingModalProps {
  sleeveId: SleeveId;
  sleeveDrawerTab: "Sharing" | "SleeveUsage" | "SleeveDetails";
}

const LoadingModal = ({
  sleeveId,
  sleeveDrawerTab,
}: LoadingModalProps): JSX.Element => {
  const history = useHistory();

  return (
    <Modal
      closeModal={() =>
        history.push({
          ...history.location,
          pathname: `/Advisor/Investments/Sleeves/${sleeveId}/${sleeveDrawerTab}`,
        })
      }
      modalStyle={{ width: "520px" }}
    >
      <div className={styles.modalTitle}>Choose a Replacement Sleeve</div>

      <div className={styles.modalText}>
        To archive the sleeve, you must replace it.
      </div>

      <div className={styles.flexRowMarginTop}>
        <Skeleton
          width={"35%"}
          height={30}
          style={{ transform: "none", marginTop: "4px" }}
        />
      </div>

      <div className={styles.modalFooter}>
        <Skeleton
          width={70}
          height={35}
          style={{ transform: "none", marginRight: "10px" }}
        />

        <Skeleton width={100} height={35} style={{ transform: "none" }} />
      </div>
    </Modal>
  );
};
