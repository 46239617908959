import {
  AccountWithMetadata,
  PostTradePortfolioAggregate,
  ProposalInternalStatus,
  ProposalTradingGroup,
  Trade,
} from "@fartherfinance/frontend/api/Rebalance/Types";

export const mapProposalStatusToLabel = (
  proposalStatus: ProposalInternalStatus
): string => {
  return {
    [ProposalInternalStatus.enum.NEEDS_REVIEW]: "Needs Review",
    [ProposalInternalStatus.enum.SKIP_PROPOSAL]: "Skipped",
    [ProposalInternalStatus.enum.CONFIRM]: "Confirmed",
    [ProposalInternalStatus.enum.PROPOSED]: "Proposed",
    [ProposalInternalStatus.enum.SUBMITTED]: "Submitted",
    [ProposalInternalStatus.enum.FAILED]: "Failed",
    [ProposalInternalStatus.enum.CANCELED]: "Canceled",
  }[proposalStatus];
};

export const mapProposalStatusToActionLabel = (
  proposalStatus: ProposalInternalStatus
): string => {
  return {
    [ProposalInternalStatus.enum.NEEDS_REVIEW]: "Needs Review",
    [ProposalInternalStatus.enum.SKIP_PROPOSAL]: "Skip Proposal",
    [ProposalInternalStatus.enum.CONFIRM]: "Confirm",
    [ProposalInternalStatus.enum.PROPOSED]: "Unconfirm",
    // NOTE: below statuses are not selectable as an action user can take
    [ProposalInternalStatus.enum.SUBMITTED]: "",
    [ProposalInternalStatus.enum.FAILED]: "",
    [ProposalInternalStatus.enum.CANCELED]: "",
  }[proposalStatus];
};

export const isCashSecurity = (cusip: string): boolean => {
  return cusip === "Cash";
};

export const isSignificantDiff = (targetWeightDifference: number): boolean => {
  return Math.abs(targetWeightDifference) > 5;
};

export const isMoreThanQuater = (
  targetWeight: number,
  targetWeightDifference: number
): boolean => {
  const isApplicable = targetWeight > 0;
  const isGreaterThanQuater =
    Math.abs(targetWeightDifference) > targetWeight * 0.25;

  return isApplicable && isGreaterThanQuater;
};

export const proposalExceptionChecker = {
  tradingGroupVersionException: {
    isExceptionRaised: (tradingGroup: ProposalTradingGroup) => {
      return tradingGroup.isNewerVersionAvailable;
    },
    message: "Trading group was modified after this proposal was generated.",
  },

  accountBalanceException: {
    isExceptionRaised: (accounts: AccountWithMetadata[]) => {
      return accounts.some(
        (account) => account.metadata.isAccountBalanceNonPositive
      );
    },
    message: (accounts: AccountWithMetadata[]) => {
      const affectedAccounts = accounts.filter(
        (account) => account.metadata.isAccountBalanceNonPositive
      );

      return affectedAccounts.length === 1
        ? `This trading group contains an account with a non positive balance: ${
            affectedAccounts[0].info.custodianAccountId ?? "Unknown"
          }.`
        : `This trading group contains accounts with a non positive balance: ${affectedAccounts
            .map((account) => account.info.custodianAccountId ?? "Unknown")
            .join(", ")}.`;
    },
  },

  accountCashException: {
    isExceptionRaised: (accounts: AccountWithMetadata[]) => {
      return accounts.some(
        (account) => account.metadata.isAccountCashNonPositive
      );
    },
    message: (accounts: AccountWithMetadata[]) => {
      const affectedAccounts = accounts.filter(
        (account) => account.metadata.isAccountCashNonPositive
      );

      return affectedAccounts.length === 1
        ? `This trading group contains an account with a non positive cash balance: ${
            affectedAccounts[0].info.custodianAccountId ?? "Unknown"
          }.`
        : `This trading group contains accounts with a non positive cash balance: ${affectedAccounts
            .map((account) => account.info.custodianAccountId ?? "Unknown")
            .join(", ")}.`;
    },
  },

  accountMissingTickerException: {
    isExceptionRaised: (accounts: AccountWithMetadata[]) => {
      return accounts.some(
        (account) => account.metadata.isAccountHoldingMissingTicker
      );
    },
    message:
      "This trading group contains at least one security with an invalid ticker.",
  },

  mutualFundException: {
    isExceptionRaised: (accounts: AccountWithMetadata[]) => {
      return (
        accounts.length > 0 &&
        accounts.every((account) => account.info.custodianName === "Apex")
      );
    },
    message:
      "Manual trading is required for the mutual fund portion of Apex rebalancing proposals.",
  },

  tradingEnabledException: {
    isExceptionRaised: (tradingGroup: ProposalTradingGroup) => {
      return !tradingGroup.isActive;
    },
    message: "Trading is turned off in the Farther portal.",
  },

  // NOTE: currently not used
  targetPortfolioWeightException: {
    isExceptionRaised: (
      postTradePortfolioAggregate: PostTradePortfolioAggregate[]
    ) => {
      return postTradePortfolioAggregate.some(
        ({ subtotal: { targetWeight, targetWeightDifference } }) => {
          return (
            isSignificantDiff(targetWeightDifference ?? 0) ||
            isMoreThanQuater(targetWeight, targetWeightDifference ?? 0)
          );
        }
      );
    },
    message:
      "Suggested trades do not bring portfolio in line with expected target portfolio.",
  },

  targetPortfolioSecurityException: {
    isExceptionRaised: (trades: Trade[]) => {
      return trades.some((trade) => !trade.isInTargetPortfolio);
    },
    message:
      "This proposal includes purchases of securities which were not found in the target portfolio.",
  },
};

export const isMoreThanOneAccountInTradingGroup = (
  tradingGroup: ProposalTradingGroup
): boolean => {
  return tradingGroup.accounts.length > 1;
};
