import React from "react";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./ActionRow.module.css";

interface Props {
  start?: JSX.Element | string;
  end?: JSX.Element | string;
  action?: JSX.Element;
}

const ActionRow: React.FC<Props> = ({ start, end, action }) => {
  return (
    <div className={toClassName(styles.container, styles.text)}>
      <div className={styles.start}>{start}</div>
      <div className={styles.end}>
        <div>{end}</div>
        <div className={styles.action}>{action}</div>
      </div>
    </div>
  );
};

export default ActionRow;
