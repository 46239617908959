import React from "react";

import { Link, useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";

export default function PlaidReconnectButton(): JSX.Element {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  return (
    <Link to={`/Client/${clientId}/Relink`} style={{ textDecoration: "none" }}>
      <ButtonPrimary
        variant="outlined"
        buttonType="primary"
        text="Reconnect"
        sx={{
          height: "30px",
          marginRight: "10px",
        }}
        onClick={() => undefined}
      />
    </Link>
  );
}
