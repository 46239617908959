import React from "react";

import Typography from "@mui/material/Typography";

import styles from "./OfferingTypeCard.module.css";

interface OfferingTypeCardProps {
  name: string;
  description: string;
  onClick: () => void;
}

const OfferingTypeCard: React.FC<OfferingTypeCardProps> = ({
  name,
  description,
  onClick,
}) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.highlight} />
      <Typography className={styles.name}>{name}</Typography>
      <Typography className={styles.description}>{description}</Typography>
    </div>
  );
};

export default OfferingTypeCard;
