export const CashManagementPath = "CashManagement";
export const AccountsPath = "Accounts";
export const LiquidationPath = "Liquidation";
export const DocumentsCenterPath = "Documents";
export const FinancialPlansPath = "FinancialPlans";
export const InsuranceMarketplacePath = "InsuranceMarketplace";
export const LendingMarketplacePath = "LendingMarketplace";
export const MobileHelloSignBaseUrl = "/mobile/hellosign/client";
export const SharingPath = "Sharing";
export const InvestmentsPath = "Investments";
export const AltAssetsMarketplacePath = `${InvestmentsPath}/AltAssetsMarketplace`;
