import { isNil } from "lodash";

const nineDigitRegex = /^[0-9]{9}$/;
const ssnRegex = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;

export default function isValidSSN(taxID: string | undefined): boolean {
  if (isNil(taxID) || taxID === "") {
    return false;
  }

  const validTaxID = ssnRegex.test(taxID) || nineDigitRegex.test(taxID);

  return validTaxID;
}
