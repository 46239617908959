import React, { useEffect, useMemo, useState } from "react";

import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { InputAdornment } from "@mui/material";
import { add, isBefore } from "date-fns";
import { isNil } from "lodash";
import { useSelector } from "react-redux";

import useGetFartherManagedAccounts from "@fartherfinance/frontend/api/Accounts/hooks/useGetFartherManagedAccounts";
import { FartherManagedAccount } from "@fartherfinance/frontend/api/Accounts/Types";
import {
  getExternalAccountBalance,
  getPartialAccountNumber,
  MASK_NUMBER,
} from "@fartherfinance/frontend/api/Accounts/utilities/accountUtil";
import useClientDashboard from "@fartherfinance/frontend/api/Dashboard/hooks/useClientDashboard";
import useFundingAccountExtended from "@fartherfinance/frontend/api/ExternalAccount/hooks/useFundingAccountExtended";
import useAllAccountBalances from "@fartherfinance/frontend/api/PerformanceGroups/hooks/useAllAccountBalances";
import useCreateACHDeposit, {
  IraDeposit,
  Deposit as NonIRADeposit,
} from "@fartherfinance/frontend/api/Transfer/hooks/useCreateACHDeposit";
import { FartherAccountId } from "@fartherfinance/frontend/api/Types";

import LogoLoadingStill from "../../../../../../../../sharedComponents/LogoLoadingStill/LogoLoadingStill";
import { State } from "../../../../../../../../store";
import CautionStatusDiv from "../../../../../../../components/Caution_update_status_div/Caution";
import ButtonPrimary from "../../../../../../../components/MUI/Button/Button";
import { SetScreenAndTransferType } from "../../../../Types";
import { achAccountOptions } from "../../utils/achAccountOptions";
import createAccLabel from "../../utils/createAccLabel";
import { SetDepositContainerScreen } from "../Types";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import {
  isManualAccount,
  isPlaidAccount,
} from "@src/multiCustodian/pages/Dashboard/Funding/utils";
import useThemeFragment from "@src/multiCustodian/theme/useThemeFragment";
import FormExplanatoryText from "@src/sharedComponents/Forms/FormExplanatoryText";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import Dropdown from "@src/sharedComponents/SAODropdown/Dropdown";
import TextInput from "@src/sharedComponents/TextInput/TextInput";

import WireTransferInstructions from "./WireTransferInstructions";

import "./Deposit.css";

const createYearOptions = (currentTime: Date): { label: string }[] => {
  const thisYear = currentTime.getFullYear();

  // We can contribute to the previous year up to April 15th
  const isTodayBefore16April = isBefore(new Date(), new Date(thisYear, 3, 16));

  const lastYear = add(new Date(), { years: -1 }).getFullYear();

  return [
    { label: thisYear.toString() },
    ...(isTodayBefore16April ? [{ label: lastYear.toString() }] : []),
  ];
};

type ErrorState =
  | "None"
  | "Invalid Amount"
  | "No Amount"
  | "StalePlaid"
  | "OverLimit"
  | "UnderMinimum";

interface Props {
  setCurScreen: SetScreenAndTransferType;
  setDepositContainerScreen: SetDepositContainerScreen;
}

const Deposit = (props: Props): JSX.Element => {
  const t = useThemeFragment("Farther");

  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));

  const [transferAmount, setTransferAmount] = useState<string>("");

  const [retirementContributionYear, setRetirementContributionYear] =
    useState<number>(new Date().getFullYear());

  const [selectedFartherAccountId, setSelectedFartherAccountId] =
    useState<FartherAccountId | null>(null);

  const [isMutating, setIsMutating] = useState<boolean>();

  const statusNotification = useStatusNotification();

  const auth = useRequestAuth();

  const managedAccounts = useGetFartherManagedAccounts(clientId, auth);

  const callCreateDeposit = useCreateACHDeposit(auth);

  const fundingAccount = useFundingAccountExtended(auth);

  const dashboard = useClientDashboard(clientId, auth);

  const validACHAccountIds =
    fundingAccount.data?.fundingDetails?.validAchAccountIds ?? [];

  const validACHAccounts = (managedAccounts.data ?? []).filter((account) =>
    validACHAccountIds.includes(account.virtualAccountId)
  );

  const fundingAccName: string | undefined =
    fundingAccount.data && fundingAccount.data !== null
      ? isManualAccount(fundingAccount.data)
        ? fundingAccount.data?.details?.account.accountTitle
        : fundingAccount.data?.details?.account.name
      : undefined;

  // https://www.notion.so/fartherfinance/RFC-Account-Id-Display-9fefdde3348b4a888cf8c4dcfa4a5eb1?pvs=4#42361a3aa39942cfa9582f1ad4c1369f
  const partialAccNumber: string = `(...${
    fundingAccount.data && fundingAccount.data !== null
      ? getPartialAccountNumber(fundingAccount.data)
      : MASK_NUMBER
  })`;

  const fundingAccHealth =
    fundingAccount.data &&
    fundingAccount.data !== null &&
    isPlaidAccount(fundingAccount.data)
      ? fundingAccount.data.details?.account.connectionHealthType ??
        "unavailable"
      : null;

  const fundingAccBalance =
    fundingAccount.data &&
    fundingAccount.data !== null &&
    isPlaidAccount(fundingAccount.data)
      ? getExternalAccountBalance(fundingAccount.data)
      : null;

  const selectedFartherAccount =
    (managedAccounts.data ?? []).find(
      (a) => a.virtualAccountId === selectedFartherAccountId
    ) ?? null;

  useEffect(() => {
    // on first load set the selected Farther account to the first one
    if (selectedFartherAccountId === null && validACHAccounts.length > 0) {
      setSelectedFartherAccountId(validACHAccounts[0].virtualAccountId);
    }
  }, [validACHAccounts, selectedFartherAccountId]);

  const amount = parseFloat(transferAmount);

  let amountErrorState: ErrorState = "None";
  if (transferAmount === "") {
    amountErrorState = "No Amount";
  } else if (isNaN(Number(transferAmount))) {
    amountErrorState = "Invalid Amount";
  } else if (amount < 1) {
    amountErrorState = "UnderMinimum";
  } else if (amount > 100000) {
    amountErrorState = "OverLimit";
  } else if (fundingAccHealth === "unavailable") {
    amountErrorState = "StalePlaid";
  }

  const fundingStatus = fundingAccount.data?.fundingDetails?.fundingStatus;

  const callMakeDeposit = async () => {
    if (selectedFartherAccount === null || isNil(fundingAccount.data)) {
      return;
    }

    const isIRA =
      selectedFartherAccType === "IRA" ||
      selectedFartherAccType === "Roth IRA" ||
      selectedFartherAccType === "Sep IRA";

    const isSepIRA = selectedFartherAccType === "Sep IRA";

    const depositBody: NonIRADeposit | IraDeposit = {
      fartherAccountId: selectedFartherAccount.virtualAccountId,
      amount,
      ...(isIRA && {
        // See https://linear.app/fartherfinance/issue/BE-520/bug-with-contribution-to-sep-iras#comment-8069b8a3
        // when to choose which options are in doc below
        // https://developer.apexclearing.com/apexclearing/docs/ira-details#ira-contribution-types
        contributionType: isSepIRA ? "EMPLOYER" : "REGULAR",
        contributionYear: retirementContributionYear,
      }),
    };

    setIsMutating(true);

    try {
      const { transferId } = await callCreateDeposit(depositBody);

      setIsMutating(false);

      statusNotification("Deposit initiated", "Success");

      props.setDepositContainerScreen("deposit_success", {
        type: "Standard",
        externalAccountId: fundingAccount.data.accountId,
        fartherAccountId: selectedFartherAccount.virtualAccountId,
        amount,
        transferId: transferId,
      });
    } catch {
      setIsMutating(false);

      statusNotification("Failed to initiate a deposit", "Error");
    }
  };

  if (
    managedAccounts.isLoading ||
    dashboard.isLoading ||
    fundingAccount.isLoading
  ) {
    return (
      <div className="Dashboard_Deposit_page__container">
        <div className="Dashboard_Deposit_page__content_container">
          <div className="Dashboard_Deposit_page__loading_container">
            <LogoLoadingStill />
          </div>
        </div>
      </div>
    );
  }

  if (
    managedAccounts.hasError ||
    dashboard.hasError ||
    fundingAccount.hasError
  ) {
    return (
      <div className="Dashboard_Deposit_page__container">
        <div className="Dashboard_Deposit_page__content_container">
          <div className="Dashboard_Deposit_page__loading_container">
            Error loading accounts
          </div>
        </div>
      </div>
    );
  }

  const noFundingAccount = fundingAccount.data === null;

  const noFundingToDo =
    dashboard.data.apexClientId !== null
      ? "Please go to funding settings to select your funding account."
      : "Please contact your wealth advisor to set up ACH Authorization.";

  const selectedFartherAccType =
    selectedFartherAccount?.accountDetails.accountType ?? null;

  const isSelectedAccIRA =
    selectedFartherAccType === "IRA" ||
    selectedFartherAccType === "Roth IRA" ||
    selectedFartherAccType === "Sep IRA";

  const fundingAccLabel = (
    fundingAccount.data
      ? `${fundingAccName ?? "Unknown"} ${partialAccNumber} ${
          fundingAccBalance !== null
            ? `- ${fundingAccBalance.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}`
            : ""
        }`
      : ""
  ).trim();

  return (
    <div className="Dashboard_Deposit_page__container">
      {isMutating && (
        <div className={"Dashboard_Deposit_page__container_center_loader"}>
          <LogoLoadingStill />
        </div>
      )}
      <div className="Dashboard_Deposit_page__content_container">
        {fundingAccHealth === "unavailable" && (
          <Warning
            text={`We were unable to retrieve an up-to-date balance from your bank: ${
              fundingAccName ?? "Unknown"
            }. Make sure you have sufficient funds in your bank account before making a deposit.`}
          />
        )}

        {fundingStatus === "pending" && (
          <Warning text="Your bank account authorization is pending. Please check again later." />
        )}

        <p className="Dashboard_Deposit_page__title">One-time deposit</p>

        <p className="Dashboard_Deposit_page__summary">
          {`Looking to make a deposit into a ${t(
            "companyName"
          )} account? Simply follow the steps below to initiate the transaction.`}
        </p>

        <FartherAccountPicker
          selectedFartherAccountId={selectedFartherAccountId}
          setSelectedFartherAccountId={setSelectedFartherAccountId}
          validACHAccounts={validACHAccounts}
        />

        <Spacer />

        {noFundingAccount ? (
          <div>{noFundingToDo}</div>
        ) : (
          <TextInput
            label="Withdraw from"
            disabled={true}
            value={fundingAccLabel}
          />
        )}

        <Spacer verticalSpacing="10px" />

        <FormExplanatoryText>
          If you need to change your bank account please contact your wealth
          advisor.
        </FormExplanatoryText>

        <Spacer />

        <TextInput
          label={
            <div style={{ display: "flex", gap: "10px" }}>
              <div>Amount</div>
              <Tooltip />
            </div>
          }
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          value={transferAmount}
          onChange={(e) => setTransferAmount(e.target.value)}
          error={amountErrorState !== "None"}
          helperText={
            amountErrorState === "Invalid Amount"
              ? "Amount needs to be a valid number"
              : amountErrorState === "OverLimit"
              ? "Amount needs to be less than or equal to $100,000"
              : amountErrorState === "StalePlaid"
              ? "Warning! We were unable to get an up-to-date account balance. Please ensure these funds are available."
              : amountErrorState === "UnderMinimum"
              ? "Amount needs to be greater than or equal to $1"
              : undefined
          }
        />

        <WireTransferInstructions />

        {isSelectedAccIRA && (
          <Dropdown
            formLabel="Contribution Year"
            value={{ label: retirementContributionYear.toString() }}
            onChange={(e) =>
              setRetirementContributionYear(parseInt(e.label, 10))
            }
            values={createYearOptions(new Date())}
          />
        )}

        <div className="Dashboard_Deposit_page__button_container_2">
          <div
            className="Dashboard_Deposit_page__back_button_container"
            onClick={() => props.setCurScreen({ screen: "bankAccount" })}
          >
            <img src="/src/assets/svg/left_arrow.svg" />

            <div className="Dashboard_Deposit_page__back_button">Back</div>
          </div>

          <ButtonPrimary
            disabled={
              amountErrorState === "No Amount" ||
              amountErrorState === "OverLimit" ||
              amountErrorState === "UnderMinimum" ||
              noFundingAccount ||
              selectedFartherAccountId === null ||
              fundingStatus !== "active"
            }
            variant={"contained"}
            buttonType={"primary"}
            text={"Deposit"}
            onClick={() => callMakeDeposit()}
          />
        </div>
      </div>
    </div>
  );
};

export default Deposit;

interface FartherAccountPickerProps {
  selectedFartherAccountId: FartherAccountId | null;
  setSelectedFartherAccountId: (id: FartherAccountId) => void;
  validACHAccounts: FartherManagedAccount[];
}

function FartherAccountPicker({
  selectedFartherAccountId: selectedFartherAccountId,
  validACHAccounts,
  setSelectedFartherAccountId: setSelectedFartherAccountId,
}: FartherAccountPickerProps): JSX.Element {
  const t = useThemeFragment("Farther");

  const { isAdvisor, clientId } = useSelector((state: State) => ({
    isAdvisor: state.main_Reducer.user.isAdvisor,
    clientId: state.main_Reducer.user.id_user,
  }));

  const auth = useRequestAuth();

  const balances = useAllAccountBalances(clientId, auth);

  const balance: number | null = useMemo(() => {
    if (balances.isLoading || balances.hasError) {
      return null;
    }

    return (
      balances.data.find((b) => b.accountId === selectedFartherAccountId)
        ?.balance ?? null
    );
  }, [balances, selectedFartherAccountId]);

  const selectedFartherAccount = useMemo(
    () =>
      validACHAccounts.find(
        (a) => a.virtualAccountId === selectedFartherAccountId
      ) ?? null,
    [selectedFartherAccountId, validACHAccounts]
  );

  const selectedAccLabel = useMemo(
    () =>
      selectedFartherAccount !== null && isAdvisor !== null
        ? createAccLabel(
            selectedFartherAccount,
            balances.isLoading ? undefined : balance,
            isAdvisor,
            t("companyName")
          )
        : "",
    [balance, balances, isAdvisor, selectedFartherAccount, t]
  );

  const validACHAccountsList = useMemo(
    () =>
      validACHAccounts.map((account) => {
        const custodian = account.accountDetails.custodian;
        const type = account.accountDetails.accountType;
        const enabled = achAccountOptions[custodian][type];

        const accountBalance =
          (balances.data ?? []).find(
            (b) => b.accountId === account.virtualAccountId
          )?.balance ?? null;

        return {
          label: createAccLabel(
            account,
            balances.isLoading ? undefined : accountBalance,
            isAdvisor ?? false,
            t("companyName")
          ),
          accountId: account.virtualAccountId,
          enabled,
        };
      }),
    [balances, isAdvisor, t, validACHAccounts]
  );

  const selectedEnabled =
    selectedFartherAccount !== null
      ? achAccountOptions[selectedFartherAccount.accountDetails.custodian][
          selectedFartherAccount.accountDetails.accountType
        ]
      : false;

  const depositTo = useMemo(
    () =>
      selectedFartherAccount !== null
        ? {
            label: selectedAccLabel,
            accountId: selectedFartherAccount.virtualAccountId,
            enabled: selectedEnabled,
          }
        : null,
    [selectedAccLabel, selectedEnabled, selectedFartherAccount]
  );
  return (
    <Dropdown
      formLabel="Deposit to"
      value={depositTo}
      onChange={(e) => setSelectedFartherAccountId(e.accountId)}
      getOptionDisabled={(o) => o.enabled === false}
      values={validACHAccountsList}
    />
  );
}

const Tooltip = (): JSX.Element => {
  return (
    <div className="Dashboard_Deposit_page__tooltip_div">
      <img
        src="/src/assets/svg/info.svg"
        className="Dashboard_Deposit_page__tooltip_img"
      />

      <div className="Dashboard_Deposit_page__tooltip">
        <p className="Dashboard_Deposit_page__tooltip_text">
          ACH transfers are capped at $100,000 per day and usually settle after
          2-5 days.
        </p>
        <p className="Dashboard_Deposit_page__tooltip_text">
          If you are looking to send over $100,000 or process a transaction
          faster, we recommend sending a wire transfer. Wires are processed same
          day.
        </p>
      </div>
    </div>
  );
};

interface WarningProps {
  text: string;
}

const Warning = (props: WarningProps): JSX.Element => {
  return (
    <div className="Dashboard_Deposit_page__error_div_2">
      <CautionStatusDiv
        icon={
          <WarningAmberRoundedIcon className="Dashboard_Deposit_page__error_div_2_icon" />
        }
        msg={props.text}
      />

      <div className="Dashboard_Deposit_page__space" />
    </div>
  );
};
