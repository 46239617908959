import React from "react";

import ClientAdmin from "@src/multiCustodian/components/Advisor/ClientProfile/Components/ClientAdmin";
import DeleteAccountModal from "@src/multiCustodian/components/Advisor/ClientProfile/Components/Delete/DeleteAccountModal";

const AdminDeleteAccount = (): JSX.Element => {
  return (
    <ClientAdmin>
      <DeleteAccountModal />
    </ClientAdmin>
  );
};

export default AdminDeleteAccount;
