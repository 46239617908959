import React from "react";

import Accounts from "../../../Accounts";
import ActivityTab from "@src/multiCustodian/components/Client/Accounts/Manual/ActivityTab/ActivityTab";
import ManualAssetDrawer from "@src/multiCustodian/components/Client/Accounts/Manual/ManualAssetDrawer";

const Activity = (): JSX.Element => {
  return (
    <Accounts>
      <ManualAssetDrawer curTab="Activity">
        <ActivityTab />
      </ManualAssetDrawer>
    </Accounts>
  );
};

export default Activity;
