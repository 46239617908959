import React from "react";

import { Stack, Typography } from "@mui/material";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import Modal from "@src/sharedComponents/Modal/Modal";

import styles from "./TaxBudgetsModal.module.css";

interface TaxBudgetsModalProps {
  onContinue: () => void;
  onClose: () => void;
}

const TaxBudgetsModal: React.FC<TaxBudgetsModalProps> = ({
  onContinue,
  onClose,
}) => {
  return (
    <Modal closeModal={onClose} modalStyle={{ width: "620px" }}>
      <Typography className={styles.primaryText}>
        Tax budgets may be exceeded
      </Typography>

      <Stack gap="16px">
        <Typography className={styles.secondaryText}>
          If a client has a tax budget set up, the budget amount could be
          exceeded while raising cash. While Farther will attempt to sell
          overweight positions with unrealized losses first, it may be necessary
          to sell other securities as well to meet the requested amount - this
          could cause capital gains taxes in excess of the budgeted amount.
        </Typography>
      </Stack>

      <Stack
        direction="row"
        gap="20px"
        marginTop="24px"
        justifyContent="flex-end"
      >
        <Button
          variant="outlined"
          buttonType="primary"
          text="Cancel"
          onClick={onClose}
        />
        <Button
          variant="contained"
          buttonType="primary"
          text="Continue"
          onClick={onContinue}
        />
      </Stack>
    </Modal>
  );
};

export default TaxBudgetsModal;
