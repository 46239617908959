import React, { useEffect, useMemo, useState } from "react";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Collapse, Stack, Typography } from "@mui/material";
import { endOfYesterday, format, startOfToday, subDays } from "date-fns";
import { isNumber, orderBy } from "lodash";

import useGetProposal from "@fartherfinance/frontend/api/Rebalance/hooks/useGetProposal";
import useGetProposalsV4 from "@fartherfinance/frontend/api/Rebalance/hooks/useGetProposalsV4";
import { GetProposalsQueryParams } from "@fartherfinance/frontend/api/Rebalance/requests/getProposals";
import { ProposalInternalStatus } from "@fartherfinance/frontend/api/Rebalance/Types";
import { ProposalId, TradingGroupId } from "@fartherfinance/frontend/api/Types";

import { toCurrency, toLocaleNumber } from "../../ProposalDetails.utils";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import Chip from "@src/sharedComponents/Chip/Chip";
import Select from "@src/sharedComponents/Select/Select";
import SelectItem from "@src/sharedComponents/Select/SelectItem";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import { PLACEHOLDER_SIGN } from "@src/yellowstone/modules/shared";

import { useStyles } from "./ProposalHistoricalTrades.styles";

const PROPOSALS_QUERY_PARAMS: GetProposalsQueryParams = {
  page: 0,
  pageSize: 999, // NOTE: I think this is a safe value, we only want to gather 6 months of data
  filterByStatus: [ProposalInternalStatus.enum.SUBMITTED],
  filterByTimestampFrom: subDays(startOfToday(), 45).toISOString(),
  filterByTimestampTo: endOfYesterday().toISOString(),
};

const TABLE_HEADERS = [
  "",
  "Date",
  "Account",
  "Ticker",
  "Side",
  "Quantity",
  "Price",
  "Market Value",
];

interface ProposalHistoricalTradesProps {
  tradingGroupId: TradingGroupId | undefined;
}

export const ProposalHistoricalTrades: React.FC<
  ProposalHistoricalTradesProps
> = ({ tradingGroupId }) => {
  const { classes, cx } = useStyles();
  const [historicalProposalId, setHistoricalProposalId] =
    useState<ProposalId | null>(null);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const auth = useAdvisorRequestAuth();
  const proposalsQuery = useGetProposalsV4(PROPOSALS_QUERY_PARAMS, auth);
  const proposalDetailsQuery = useGetProposal(historicalProposalId, auth);

  const { proposal, proposedTrades } =
    proposalDetailsQuery.data?.proposalDetails ?? {};
  const { trades = [], summary } = proposedTrades ?? {};
  const { totalBuys, totalSells } = summary ?? {};

  const { hasError } = proposalDetailsQuery;

  const historicalProposals = useMemo(() => {
    const proposals = proposalsQuery.data?.values ?? [];

    if (proposalsQuery.data && isInitialLoading) {
      setIsInitialLoading(false);
    }

    return proposals
      .filter((proposal) => proposal.tradingGroup.groupId === tradingGroupId)
      .map(({ proposalId, createdOn }) => ({
        proposalId,
        createdOn: format(new Date(createdOn), "MM/dd/yyyy"),
      }));
  }, [isInitialLoading, proposalsQuery.data, tradingGroupId]);

  const sortedTrades = useMemo(
    () =>
      orderBy(
        trades,
        [(trade) => trade.ticker, (trade) => trade.account.custodianAccountId],
        ["asc", "asc"]
      ),
    [trades]
  );

  const determineChipLabel = (): string => {
    if (historicalProposals.length === 0) {
      return "No Past Proposals";
    }

    if (hasError) {
      return "Failed to load";
    }

    return `${trades.length} Trades`;
  };

  useEffect(() => {
    const [firstProposal] = historicalProposals;

    // NOTE: we want to select first available historical proposal on mount
    if (!historicalProposalId && firstProposal) {
      setHistoricalProposalId(firstProposal.proposalId);
    }
  }, [historicalProposalId, historicalProposals]);

  return (
    <Box className={classes.container}>
      <Box
        className={cx(classes.header, {
          [classes.textHighlight]: !isCollapsed,
        })}
        onClick={() =>
          setIsCollapsed((currentIsCollapsed) => !currentIsCollapsed)
        }
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" spacing={8}>
            <Stack direction="row" alignItems="center">
              <ChevronRightIcon
                className={cx(classes.icon, {
                  [classes.iconRoate]: !isCollapsed,
                })}
              />
              <Typography className={classes.text}>
                Recent Trading Proposal
              </Typography>
              <Chip
                className={classes.chip}
                variant="outlined"
                label={determineChipLabel()}
              />
            </Stack>
            <Stack direction="row" alignItems="center">
              <Typography className={classes.text}>
                {isInitialLoading ? (
                  <Skeleton width={100} />
                ) : (
                  <>
                    {totalBuys && isNumber(totalBuys.sum)
                      ? `${toCurrency(totalBuys.sum)} Buys`
                      : "--"}
                  </>
                )}
              </Typography>
              <Typography
                className={cx(classes.text, classes.summarySeparator)}
              >
                /
              </Typography>
              <Typography className={classes.text}>
                {isInitialLoading ? (
                  <Skeleton width={100} />
                ) : (
                  <>
                    {totalSells && isNumber(totalSells.sum)
                      ? `${toCurrency(totalSells.sum)} Sells`
                      : "--"}
                  </>
                )}
              </Typography>
            </Stack>
          </Stack>

          <Select
            className={classes.select}
            options={historicalProposals}
            value={historicalProposalId ?? ("None" as ProposalId)}
            disabled={historicalProposals.length === 0}
            onChange={setHistoricalProposalId}
            onClick={(event) => event.stopPropagation()}
            renderValue={(value) => {
              const matchingProposal = historicalProposals.find(
                ({ proposalId }) => proposalId === value
              );

              return matchingProposal
                ? `${matchingProposal.createdOn}`
                : "None";
            }}
            renderOption={(value) => (
              <SelectItem
                key={value.proposalId}
                value={value.proposalId}
                label={value.createdOn}
              />
            )}
          />
        </Stack>
      </Box>
      <Collapse in={!isCollapsed} timeout={400}>
        <Box className={classes.tableContainer}>
          <Box className={classes.tableHeader}>
            {TABLE_HEADERS.map((header, index) => (
              <Typography key={index} className={classes.tableHeaderText}>
                {header}
              </Typography>
            ))}
          </Box>

          {isInitialLoading &&
            Array.from({ length: 15 }).map((_, index) => (
              <Box key={index} className={classes.tableRow}>
                {Array.from({ length: TABLE_HEADERS.length }).map(
                  (_, index, array) => (
                    <Typography key={index} className={classes.tableRowText}>
                      {index === array.length - 1 ? (
                        <Skeleton width={100} />
                      ) : (
                        <Skeleton />
                      )}
                    </Typography>
                  )
                )}
              </Box>
            ))}

          {hasError && (
            <Typography className={classes.message}>
              Trading proposal failed to load
            </Typography>
          )}

          {!hasError &&
            !proposalDetailsQuery.isLoading &&
            historicalProposals.length === 0 && (
              <Typography className={classes.message}>
                No recent trading proposals
              </Typography>
            )}

          {!hasError &&
            !proposalDetailsQuery.isLoading &&
            historicalProposals.length > 0 && (
              <>
                {sortedTrades.map((trade, index) => (
                  <Box key={index} className={classes.tableRow}>
                    <Typography
                      className={cx(
                        classes.tableRowText,
                        classes.tableRowIndex
                      )}
                    >
                      {index + 1}
                    </Typography>
                    <Typography className={classes.tableRowText}>
                      {proposal
                        ? format(new Date(proposal.createdOn), "MM/dd/yyyy")
                        : PLACEHOLDER_SIGN}
                    </Typography>
                    <Typography
                      className={cx(classes.tableRowText, classes.tableRowData)}
                    >
                      {trade.account.custodianAccountId ?? PLACEHOLDER_SIGN}
                    </Typography>
                    <Typography
                      className={cx(classes.tableRowText, classes.tableRowData)}
                    >
                      {trade.ticker ?? PLACEHOLDER_SIGN}
                    </Typography>
                    <Typography
                      className={cx(classes.tableRowText, classes.tableRowData)}
                    >
                      {trade.side}
                    </Typography>
                    <Typography
                      className={cx(classes.tableRowText, classes.tableRowData)}
                    >
                      {toLocaleNumber(trade.quantity)}
                    </Typography>
                    <Typography
                      className={cx(classes.tableRowText, classes.tableRowData)}
                    >
                      {isNumber(trade.price)
                        ? toCurrency(trade.price)
                        : PLACEHOLDER_SIGN}
                    </Typography>
                    <Typography className={classes.tableRowText}>
                      {isNumber(trade.marketValue)
                        ? toCurrency(trade.marketValue)
                        : PLACEHOLDER_SIGN}
                    </Typography>
                  </Box>
                ))}
                <Box className={classes.stickyContainer}>
                  {totalBuys && (
                    <Box className={classes.tableRow}>
                      <Typography />
                      <Typography className={classes.tableRowText}>
                        Total Buys
                      </Typography>
                      <Typography
                        className={cx(
                          classes.tableRowText,
                          classes.tableRowData
                        )}
                      >
                        --
                      </Typography>
                      <Typography className={classes.tableRowText}>
                        {totalBuys.diffTickers}
                      </Typography>
                      <Typography className={classes.tableRowText}>
                        {totalBuys.sideCount}
                      </Typography>
                      <Typography className={classes.tableRowText}>
                        {toLocaleNumber(totalBuys.quantity)}
                      </Typography>
                      <Typography
                        className={cx(
                          classes.tableRowText,
                          classes.tableRowData
                        )}
                      >
                        --
                      </Typography>
                      <Typography className={classes.tableRowText}>
                        {isNumber(totalBuys.sum)
                          ? toCurrency(totalBuys.sum)
                          : "--"}
                      </Typography>
                    </Box>
                  )}
                  {totalSells && (
                    <Box className={classes.tableRow}>
                      <Typography />
                      <Typography className={classes.tableRowText}>
                        Total Sells
                      </Typography>
                      <Typography
                        className={cx(
                          classes.tableRowText,
                          classes.tableRowData
                        )}
                      >
                        --
                      </Typography>
                      <Typography className={classes.tableRowText}>
                        {totalSells.diffTickers}
                      </Typography>
                      <Typography className={classes.tableRowText}>
                        {totalSells.sideCount}
                      </Typography>
                      <Typography className={classes.tableRowText}>
                        {toLocaleNumber(totalSells.quantity)}
                      </Typography>
                      <Typography
                        className={cx(
                          classes.tableRowText,
                          classes.tableRowData
                        )}
                      >
                        --
                      </Typography>
                      <Typography className={classes.tableRowText}>
                        {isNumber(totalSells.sum)
                          ? toCurrency(totalSells.sum)
                          : "--"}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </>
            )}
        </Box>
      </Collapse>
    </Box>
  );
};
