import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { format } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import { throttle } from "lodash";
import { useSelector } from "react-redux";

import useClientChecklist from "@fartherfinance/frontend/api/Entity/hooks/useClientChecklist";
import type { Advisor } from "@fartherfinance/frontend/api/Entity/requests/getFartherEmployees";
import { TimeFrame } from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Types";
import useManagedAccountCustodiansV4 from "@fartherfinance/frontend/api/PerformanceGroups/hooks/useManagedAccountCustodiansV4";
import {
  DateRange,
  DateStringYYYYMMDD,
} from "@fartherfinance/frontend/api/PerformanceGroups/requests/getSingleAccountGroupPerformanceV4";
import {
  OperationalState,
  PerformanceGroupId,
} from "@fartherfinance/frontend/api/Types";

import {
  no_account_summary_headingText,
  no_account_summary_subText,
  no_data_from_farther_accounts_subText,
  no_data_from_farther_headingText,
} from "../../shared";
import { dateFormat } from "@src/constants/dateFormat";
import PerformanceGroupsSummaryBreakdownTable from "@src/multiCustodian/components/PerformanceGroups/Summary/BreakdownTable";
import { usePerformanceCustomDateRangeContext } from "@src/multiCustodian/components/PerformanceGroups/Summary/components/PerformanceCustomDateRangeContextProvider";
import Legend from "@src/multiCustodian/components/PerformanceGroups/Summary/Legend";
import SummaryGraphContainer from "@src/multiCustodian/components/PerformanceGroups/Summary/SummaryGraphContainer";
import SummaryHeader from "@src/multiCustodian/components/PerformanceGroups/Summary/SummaryHeader";
import { UpdateDisplayData } from "@src/multiCustodian/components/PerformanceGroups/Types";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import ChecklistController from "@src/multiCustodian/pages/Dashboard/Dashboard_Components/ChecklistController/ChecklistController";
import EmptyGraphPlaceholder from "@src/multiCustodian/pages/Dashboard/Dashboard_Components/EmptyGraphPlaceholder";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import BreakdownChecklistToggle from "@src/sharedComponents/Toggle/TwoTextItemToggle";
import { State } from "@src/store";
import toUSD from "@src/utils/toUSD";

import createBalanceSummary from "./utils/createBalanceSummary";

import styles from "./SummaryTab.module.css";

export interface AdvisorWithFullName extends Advisor {
  fullName: string;
}

interface Props {
  accountOperationalState: OperationalState | undefined;
  hasAccounts: boolean;
  anyAccountsOpen: boolean;
  groupId: PerformanceGroupId | null;
  sendToDocCenterTasksList: () => void;
  timeFrame: TimeFrame;
  setTimeFrame: (range: TimeFrame) => void;
}

export default function SummaryTab(props: PropsWithChildren<Props>) {
  const [balance, setBalance] = useState<number | null>(null);
  const [twrNum, setTwrNum] = useState<number | null>(null);
  const [currentBalance, setCurrentBalance] = useState<string | null>(null);
  const [marketGains, setMarketGains] = useState<number | null>(null);
  const [deposits, setDeposits] = useState<string | null>(null);
  const [withdrawals, setWithdrawals] = useState<string | null>(null);
  const [showSetupChecklist, setShowSetupChecklist] = useState<boolean>(false);
  const [netAdditions, setNetAdditions] = useState<string | null>(null);

  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));

  const { enableBdPerformancePassthrough } = useFlags();

  const clientAuth = useRequestAuth();
  const auth = clientAuth;
  const checklist = useClientChecklist(clientId, auth);

  const checklistItems = useMemo(() => {
    return checklist.data?.items ?? [];
  }, [checklist]);

  const { customDateRange } = usePerformanceCustomDateRangeContext();

  const customTimeFrame: DateRange | null = useMemo(() => {
    if (
      props.timeFrame !== "Custom" ||
      customDateRange.from === null ||
      customDateRange.to === null
    ) {
      return null;
    }

    return {
      startDate: DateStringYYYYMMDD.parse(
        format(customDateRange.from, dateFormat)
      ),
      endDate: DateStringYYYYMMDD.parse(format(customDateRange.to, dateFormat)),
    };
  }, [props.timeFrame, customDateRange]);

  const summary = useManagedAccountCustodiansV4(
    props.groupId,
    props.timeFrame,
    customTimeFrame,
    clientId,
    auth
  );

  const balSumFirstItem = useMemo(
    () =>
      summary.data && summary.data.data.length > 0
        ? summary.data.data[0]
        : null,
    [summary.data]
  );

  const balSumLastItem = useMemo(
    () =>
      summary.data && summary.data.data.length > 0
        ? summary.data.data[summary.data.data.length - 1]
        : null,
    [summary.data]
  );

  const updateDisplayData: UpdateDisplayData = useCallback(
    (toDate: string) => {
      const dataPoint = summary.data?.data.find((e) => e.endDate === toDate);
      if (dataPoint === undefined) {
        console.error("Unknown point, toDate", toDate);
        return;
      }

      const contributions = dataPoint.depositsSinceStartDay;

      setDeposits(toUSD(contributions));

      const withdrawals = dataPoint.withdrawalsSinceStartDay;

      setWithdrawals(toUSD(withdrawals));

      const marketGains =
        dataPoint.balanceOnEndDay -
        (dataPoint.balanceOnStartDay +
          dataPoint.depositsSinceStartDay -
          dataPoint.withdrawalsSinceStartDay);

      const gains = enableBdPerformancePassthrough
        ? dataPoint.netGainsSinceStartDay
        : marketGains;

      setMarketGains(gains);

      const balance = dataPoint.balanceOnEndDay;

      setBalance(balance);

      setCurrentBalance(toUSD(balance));

      const returns = dataPoint.returnsSinceStartDay;

      setTwrNum(returns);

      setNetAdditions(toUSD(dataPoint.netAdditions));
    },
    [summary.data?.data, enableBdPerformancePassthrough]
  );

  const throttledUpdate = useMemo(
    () => throttle(updateDisplayData, 10),
    [updateDisplayData]
  );

  useEffect(() => {
    if (summary.data) {
      const lastItem =
        summary.data.data.length > 0
          ? summary.data.data[summary.data.data.length - 1]
          : null;

      if (lastItem !== null) {
        updateDisplayData(summary.data.endDate);
      } else {
        // set SummaryHeader data to null so won't show previous data if there was any
        setBalance(null);
        setMarketGains(null);
        setTwrNum(null);
      }
    }

    if (summary.isLoading === false && summary.data === undefined) {
      // set SummaryHeader data to null so won't show previous data if there was any
      setBalance(null);
      setMarketGains(null);
      setTwrNum(null);
    }

    if (summary.isLoading) {
      setMarketGains(null);
    }
  }, [summary.data, summary.isLoading, updateDisplayData]);

  const balanceSummary = useMemo(
    () =>
      createBalanceSummary(
        summary.data?.data ?? [],
        enableBdPerformancePassthrough
      ),
    [summary.data?.data, enableBdPerformancePassthrough]
  );

  if (checklist.isLoading) {
    return (
      <div className={styles.container}>
        <div className={styles.loading}>
          <LogoLoadingStill />
        </div>
      </div>
    );
  }

  const noData =
    summary.isLoading === false &&
    (!summary.data ||
      (summary.data !== undefined && summary.data.data.length === 0));

  const showPlaceholderGraph =
    !props.hasAccounts ||
    !props.anyAccountsOpen ||
    (noData &&
      (props.accountOperationalState === "Pending" ||
        props.accountOperationalState === "Planning"));

  const showChecklist = checklist.data?.showChecklist ?? false;

  const completeChecklistToOpenAccounts = !props.hasAccounts && showChecklist;

  // NO accounts OR NO account data, AND YES OR NO Checklist - either no accounts open or an account is open but no Black Diamond account data yet, AND checklist is complete or incomplete
  // If checklist is incomplete no need to toggle between checklist and breakdown table since there is no breakdown data so will just show checklist
  if (showPlaceholderGraph || checklist.hasError) {
    return (
      <div className={styles.container}>
        <div className={styles.graph_container}>
          <EmptyGraphPlaceholder
            headerText={
              checklist.hasError
                ? "Error retrieving client checklist"
                : completeChecklistToOpenAccounts
                ? no_account_summary_headingText
                : no_data_from_farther_headingText
            }
            subText={
              checklist.hasError
                ? ""
                : completeChecklistToOpenAccounts
                ? no_account_summary_subText
                : no_data_from_farther_accounts_subText
            }
          />
        </div>

        <div className={styles.breakdownTableContainer}>
          {showChecklist ? (
            <div className={styles.checklistContainer}>
              <ChecklistController
                checklistItems={checklistItems}
                sendToDocCenterTasksList={props.sendToDocCenterTasksList}
              />
            </div>
          ) : (
            <>
              <div className={styles.accountGroupDropdownContainer}>
                {props.children}
              </div>

              <div className={styles.noDataBreakdownTableContainer}>
                <PerformanceGroupsSummaryBreakdownTable
                  accountId={null}
                  noData={true}
                  balSumFirstItem={null}
                  balSumLastItem={null}
                  currentBalance={null}
                  marketGains={null}
                  deposits={null}
                  withdrawals={null}
                  netAdditions={null}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  // YES accounts/account data, AND YES OR NO Checklist
  return (
    <div className={styles.outerContainer}>
      <div>
        <div
          id="performanceCustomTimeFrameChip"
          className={styles.portaledChipContainer}
        />
      </div>

      <div className={styles.container}>
        <div className={styles.graph_container}>
          <>
            <SummaryHeader
              isLoading={summary.isLoading}
              showAdvisorVersion={false}
              balance={balance}
              netGains={marketGains}
              twr={twrNum}
              timeRange={props.timeFrame}
            >
              <Legend
                displayVertical={true}
                contributionsText={"Deposits & Withdrawals:"}
              />
            </SummaryHeader>
          </>

          <SummaryGraphContainer
            isLoading={summary.isLoading}
            summaryData={summary.data ?? null}
            timeRange={props.timeFrame}
            setTimeRange={props.setTimeFrame}
            balanceSummary={balanceSummary}
            throttledUpdate={throttledUpdate}
            isInDashboard={true}
          />
        </div>

        <div className={styles.breakdownTableContainer}>
          {showChecklist ? (
            <>
              <div className={styles.breakdownChecklistDropdownContainer2}>
                <div
                  className={styles.breakdownChecklistDropdownInnerContainerTop}
                >
                  <BreakdownChecklistToggle
                    leftOption={"Breakdown"}
                    rightOption={"Checklist"}
                    value={showSetupChecklist ? "Right" : "Left"}
                    onChange={(option: "Right" | "Left") =>
                      setShowSetupChecklist(option === "Right")
                    }
                  />
                </div>

                <div
                  className={
                    styles.breakdownChecklistDropdownInnerContainerBottom
                  }
                >
                  {props.children}
                </div>
              </div>

              {showSetupChecklist ? (
                <div className={styles.checklistContainer}>
                  <ChecklistController
                    checklistItems={checklistItems}
                    sendToDocCenterTasksList={props.sendToDocCenterTasksList}
                  />
                </div>
              ) : (
                <PerformanceGroupsSummaryBreakdownTable
                  accountId={null}
                  noData={noData}
                  balSumFirstItem={balSumFirstItem}
                  balSumLastItem={balSumLastItem}
                  currentBalance={currentBalance}
                  marketGains={marketGains}
                  deposits={deposits}
                  withdrawals={withdrawals}
                  netAdditions={netAdditions}
                />
              )}
            </>
          ) : (
            <>
              <div className={styles.accountGroupDropdownContainer}>
                {props.children}
              </div>

              <PerformanceGroupsSummaryBreakdownTable
                accountId={null}
                noData={noData}
                balSumFirstItem={balSumFirstItem}
                balSumLastItem={balSumLastItem}
                currentBalance={currentBalance}
                marketGains={marketGains}
                deposits={deposits}
                withdrawals={withdrawals}
                netAdditions={netAdditions}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
