import React from "react";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./BorderBoxHeader.module.css";

interface Props {
  className?: string;
  start: React.ReactNode;
  end?: React.ReactNode;
}

const BorderBoxHeader = (props: Props): JSX.Element => {
  return (
    <div className={toClassName(styles.container, props.className)}>
      {props.start}

      {props.end && props.end}
    </div>
  );
};

export default BorderBoxHeader;
