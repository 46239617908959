import React, { PropsWithChildren } from "react";

import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";

import styles from "./FormFooter.module.css";

interface Props {
  isSubmitDisabled: boolean;
  handleSubmit: () => void;
}

export default function FormFooter(
  props: PropsWithChildren<Props>
): JSX.Element {
  return (
    <div className={styles.footerContainer}>
      {props.children}
      <ButtonPrimary
        disabled={props.isSubmitDisabled}
        variant="contained"
        buttonType="primary"
        text="Save"
        onClick={props.handleSubmit}
      />
    </div>
  );
}
