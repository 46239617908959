import React, { Component } from "react";

import "./MobileBrowser.css";

class MobileBrowser extends Component<
  Record<string, never>,
  Record<string, never>
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="MobileBrowser_page__page_container">
        <div className="MobileBrowser_page__centered_container">
          <img
            src="/src/assets/png/img-teamwork-1@3x.png"
            className="MobileBrowser_page__big_img"
          />

          <p className="MobileBrowser_page__title">
            Mobile Browser Unavailable
          </p>

          <p className="MobileBrowser_page__summary">
            We're currently working on optimizing our platform to be mobile
            browser-friendly, so in the meantime, please visit our web app on
            desktop at
            <span className="MobileBrowser_page__span">app.farther.com</span>
            or download the app for Android or iOS.
          </p>

          <div className="MobileBrowser_page__img_container">
            <div className="MobileBrowser_page__img_div">
              <a
                href="https://apps.apple.com/us/app/farther-finance/id1495817724?ls=1"
                className="MobileBrowser_page__a"
              >
                <img
                  src="/src/assets/png/appleAppStore.png"
                  className="MobileBrowser_page__ios_img"
                />
              </a>
            </div>

            <div className="MobileBrowser_page__img_div">
              <a
                href="https://play.google.com/store/apps/details?id=com.ff.fartherfinanceprod"
                className="MobileBrowser_page__a"
              >
                <img
                  src="/src/assets/png/googlePlayStore.png"
                  className="MobileBrowser_page__android_img"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MobileBrowser;
