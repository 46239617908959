import React from "react";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./PageHero.module.css";

interface Props {
  className?: string;
  start: JSX.Element;
  end?: JSX.Element;
}

const PageHero: React.FC<Props> = ({ className, start, end }) => {
  return (
    <div className={toClassName(styles.container, className)}>
      <div className={styles.start}>{start}</div>
      {end && <div className={styles.end}>{end}</div>}
    </div>
  );
};

export default PageHero;
