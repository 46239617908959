import React, { PropsWithChildren } from "react";

import PageHero from "@src/sharedComponents/PageHero/PageHero";
import TitleBlock from "@src/sharedComponents/PageHero/TitleBlock";

import styles from "./AdvisorContentContainer.module.css";

interface Props {
  heroClassName?: string;
  heroTitle: string;
  heroSubtitle?: string;
  heroEnd?: JSX.Element;
}

const AdvisorContentContainer: React.FC<PropsWithChildren<Props>> = ({
  heroClassName,
  heroTitle,
  heroSubtitle,
  heroEnd,
  children,
}) => {
  return (
    <>
      <PageHero
        className={heroClassName}
        start={<TitleBlock title={heroTitle} subTitle={heroSubtitle} />}
        end={heroEnd}
      />

      <div className={styles.container}>{children}</div>
    </>
  );
};

export default AdvisorContentContainer;
