import { useSelector } from "react-redux";

import useClientProfile from "@fartherfinance/frontend/api/Entity/hooks/useClientProfile";

import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import { State } from "@src/store";

export default function useClientName() {
  const currentClientId = useSelector(
    (state: State) => state.main_Reducer.user.id_user
  );
  const auth = useRequestAuth();
  const clientProfile = useClientProfile(currentClientId, auth);

  if (currentClientId && clientProfile.data) {
    const { name } = clientProfile.data.investorProfile.personalDetails;

    return {
      name,
      fullName: `${name.first} ${name.last}`,
      initials: `${name.first.at(0) ?? ""}${
        name.last.at(0) ?? ""
      }`.toUpperCase(),
    };
  }

  return null;
}
