import React from "react";

import { Box, Typography } from "@mui/material";
import isNull from "lodash/isNull";

import { OpportunityAccount as OpportunityAccountDetails } from "@fartherfinance/frontend/api/Opportunities/Types";
import { formatCurrency } from "@fartherfinance/frontend/formatting/numbers";

import OpportunityMetadata from "./OpportunityMetadata/OpportunityMetadata";

import styles from "./Opportunity.module.css";

interface OpportunityAccountProps {
  account: OpportunityAccountDetails;
}

export default function OpportunityAccount({
  account,
}: OpportunityAccountProps): JSX.Element {
  return (
    <Box>
      <Typography className={styles.title}>Account</Typography>
      <Box className={styles.container}>
        <OpportunityMetadata
          label="Balance"
          value={
            isNull(account.accountBalance)
              ? null
              : formatCurrency(account.accountBalance.toString(), {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                })
          }
        />
        <OpportunityMetadata label="Name" value={account.accountName} />
        <OpportunityMetadata label="Type" value={account.accountType} />
        <OpportunityMetadata
          label="Institution"
          value={account.accountInstitution}
        />
      </Box>
    </Box>
  );
}
