import React, { useEffect, useRef } from "react";

import lottie from "lottie-web";

interface LottieProps {
  animationData: any;
  width: number;
  height: number;
}

const Lottie = ({ animationData, width, height }: LottieProps): JSX.Element => {
  const element = useRef<HTMLDivElement>(null);
  const lottieInstance = useRef<any>();
  const executeRef = useRef(false); // For React 18 so we don't load animation twice

  useEffect(() => {
    // In React 18 useEffect gets run twice in dev mode
    if (executeRef.current === true) {
      return;
    }

    if (element.current) {
      lottieInstance.current = lottie.loadAnimation({
        animationData,
        container: element.current,
      });
    }

    executeRef.current = true;

    return () => {
      lottieInstance.current?.destroy();
    };
  }, [animationData]);

  return <div style={{ width, height }} ref={element}></div>;
};

export default Lottie;
