import React from "react";

import AmountReviewRow from "@src/multiCustodian/components/Client/TaxBudgets/ReviewBudget/AmountReviewRow";
import YearReviewRow from "@src/multiCustodian/components/Client/TaxBudgets/ReviewBudget/YearReviewRow";
import CreateEditLayout from "@src/multiCustodian/components/TaxBudgets/CreateEditLayout/CreateEditLayout";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";

import AccountsReviewRows from "./AccountsReviewRows";

import styles from "./ReviewBudget.module.css";

const AdvisorReviewBudget: React.FC = () => {
  return (
    <CreateEditLayout>
      <BorderBox className={styles.container}>
        <div className={styles.title}>Budget Summary</div>
        <BorderBox className={styles.body}>
          <YearReviewRow />
          <AmountReviewRow />
          <AccountsReviewRows />
        </BorderBox>
      </BorderBox>
    </CreateEditLayout>
  );
};

export default AdvisorReviewBudget;
