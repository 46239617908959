import React, { useEffect, useMemo, useState } from "react";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useHistory, useParams } from "react-router-dom";

import useGetFartherManagedAccounts from "@fartherfinance/frontend/api/Accounts/hooks/useGetFartherManagedAccounts";
import useClientChecklist from "@fartherfinance/frontend/api/Entity/hooks/useClientChecklist";
import useClientProfile from "@fartherfinance/frontend/api/Entity/hooks/useClientProfile";
import { TimeFrame } from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Types";
import useGetAccountGroupsV4 from "@fartherfinance/frontend/api/PerformanceGroups/hooks/useGetAccountGroupsV4";
import {
  ClientId,
  Custodian,
  CustodianAccountId,
  FartherAccountId,
  PerformanceGroupId,
} from "@fartherfinance/frontend/api/Types";

import EmptyGraphPlaceholder from "../Dashboard_Components/EmptyGraphPlaceholder";
import CustomPerformanceGroupsDropdown from "@src/multiCustodian/components/PerformanceGroups/Dropdown/Dropdown";
import PerformanceGroupsSummaryBreakdownTable from "@src/multiCustodian/components/PerformanceGroups/Summary/BreakdownTable";
import PerformanceCustomDateRangeContextProvider from "@src/multiCustodian/components/PerformanceGroups/Summary/components/PerformanceCustomDateRangeContextProvider";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import ChecklistController from "@src/multiCustodian/pages/Dashboard/Dashboard_Components/ChecklistController/ChecklistController";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";
import { assertUnreachable } from "@src/utils/assertUnreachable";

import {
  no_account_summary_headingText,
  no_account_summary_subText,
  no_data_from_farther_accounts_subText,
  no_data_from_farther_headingText,
} from "./shared";
import ActivityTabV4 from "./tabs/ActivityTab/ActivityTabV4";
import HoldingsTabV4 from "./tabs/HoldingsTab/HoldingsTabV4";
import ProjectionsTabV4 from "./tabs/ProjectionsTab/ProjectionsTabV4";
import SummaryTabV4 from "./tabs/SummaryTab/SummaryTabV4";

import styles from "./PerformanceGroups.module.css";

type TabOption = "Summary" | "Activity" | "Holdings" | "Projections";

const tabOptions: TabOption[] = [
  "Summary",
  "Activity",
  "Holdings",
  "Projections",
];

interface MiniDPLorPonteraAccount {
  accountId: FartherAccountId;
  custodian: Custodian;
  custodianAccountNumber: CustodianAccountId | null | undefined;
}

interface Props {
  sendToDocCenterTasksList: () => void;
}

export default function PerformanceGroupsCAGV2(props: Props): JSX.Element {
  const [curAccountGroupId, setCurAccountGroupId] = useState<
    PerformanceGroupId | undefined
  >(undefined);
  const [awaitingAccountGroupId, setAwaitingAccountGroupId] = useState<
    PerformanceGroupId | undefined
  >(undefined); // this is for when the user creates a new AG and that data has not been invalidated yet so even though we get the id from the POST res we don't have its data yet
  const [curTab, setCurTab] = useState<TabOption>("Summary");
  const [timeFrame, setTimeFrame] = useState<TimeFrame>("1Y");
  const [fullWarningIcon, setFullWarningIcon] = useState(false);

  const history = useHistory();

  const updateCurTab = (newTab: TabOption) => {
    const qs = new URLSearchParams(history.location.search);
    qs.delete("rowsPerPage");
    qs.delete("page");
    history.replace({ search: qs.toString() });
    setCurTab(newTab);
  };

  const { enableProjectionsTab } = useFlags();

  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const auth = useRequestAuth();
  const accountGroups = useGetAccountGroupsV4(auth);
  const clientProfile = useClientProfile(clientId, auth);
  const accounts = useGetFartherManagedAccounts(clientId, auth);

  const hasAccounts = useMemo(() => {
    if (accounts.isLoading || accounts.hasError) {
      return false;
    }

    return accounts.data.length >= 1;
  }, [accounts]);

  const anyAccountsOpen = useMemo(() => {
    if (accounts.isLoading || accounts.hasError) {
      return false;
    }

    const anyAccounts = accounts.data.some((a) => {
      return (
        a.accountDetails.operationalState !== "Planning" &&
        a.accountDetails.operationalState !== "Pending" &&
        a.accountDetails.operationalState !== "Closed"
      );
    });

    return anyAccounts;
  }, [accounts]);

  const DPLorPonteraAccounts: MiniDPLorPonteraAccount[] = useMemo(() => {
    return (accounts.data ?? [])
      .filter(
        (acc) =>
          acc.accountDetails.custodian === "DPL" ||
          acc.accountDetails.custodian === "Pontera"
      )
      .map((acc) => ({
        accountId: acc.virtualAccountId,
        custodian: acc.accountDetails.custodian,
        custodianAccountNumber: acc.accountDetails
          .custodianAccountNumber as CustodianAccountId,
      }));
  }, [accounts.data]);

  useEffect(() => {
    if (accountGroups.data) {
      if (awaitingAccountGroupId !== undefined) {
        const awaitedAccountGroupData = accountGroups.data.find(
          (ag) => ag.accountGroupId === awaitingAccountGroupId
        );
        if (awaitedAccountGroupData !== undefined) {
          setCurAccountGroupId(awaitingAccountGroupId); // set the id/ag that was just created and waiting for its data
          setAwaitingAccountGroupId(undefined); // reset so we don't keep using that id/ag
          return;
        }
      }

      if (curAccountGroupId === undefined) {
        let defaultGroup = undefined;

        defaultGroup = accountGroups.data.find((ag) => ag.isFavorite);
        if (defaultGroup === undefined) {
          defaultGroup = accountGroups.data.find(
            (ag) => ag.accountGroupType === "ALL_CLIENT_ACCOUNTS"
          );

          if (defaultGroup === undefined) {
            defaultGroup = accountGroups.data[0];
          }
        }

        if (defaultGroup !== undefined) {
          setCurAccountGroupId(defaultGroup.accountGroupId);
        }
      } else if (curAccountGroupId && awaitingAccountGroupId === undefined) {
        // this flow/path is for if an AG has been deleted AND that AG was currently selected and displayed
        // -> there is a curAccountGroupId but it's no longer in the accountGroups.data
        // so will set either the Favorite or fallback to the all accounts AG if Favorite is not found
        const curSelectedGroup = accountGroups.data.find(
          (ag) => ag.accountGroupId === curAccountGroupId
        );
        if (curSelectedGroup === undefined) {
          let newDefaultGroup = accountGroups.data.find((ag) => ag.isFavorite);
          if (newDefaultGroup === undefined) {
            newDefaultGroup = accountGroups.data.find(
              (ag) => ag.accountGroupType === "ALL_CLIENT_ACCOUNTS"
            );

            if (newDefaultGroup === undefined) {
              newDefaultGroup = accountGroups.data[0];
            }
          }

          setCurAccountGroupId(newDefaultGroup?.accountGroupId);
        }
      }
    }
  }, [accountGroups.data, curAccountGroupId, awaitingAccountGroupId]);

  const curAccountGroup = useMemo(() => {
    if (curAccountGroupId === undefined) {
      return undefined;
    }

    return (accountGroups.data ?? []).find(
      (ag) => ag.accountGroupId === curAccountGroupId
    );
  }, [accountGroups.data, curAccountGroupId]);

  const curGroupDPLorPontera = useMemo(() => {
    if (curAccountGroup === undefined) {
      return [];
    }

    return DPLorPonteraAccounts.filter((acc) =>
      curAccountGroup.virtualAccountIds.some((accId) => accId === acc.accountId)
    );
  }, [DPLorPonteraAccounts, curAccountGroup]);

  const curAccountOperationalState = useMemo(() => {
    if (
      curAccountGroup === undefined ||
      curAccountGroup.accountGroupType !== "SINGLE_CLIENT_ACCOUNT"
    ) {
      return undefined;
    }

    if (accounts.isLoading || accounts.hasError) {
      return undefined;
    }

    const account = accounts.data.find(
      (a) => a.virtualAccountId === curAccountGroup.virtualAccountIds[0]
    );
    if (account === undefined) {
      throw new Error(
        "Could not find account in individual or shared accounts..."
      );
    }
  }, [curAccountGroup, accounts]);

  if (clientProfile.hasError) {
    return <div>Error retrieving client profile</div>;
  }

  if (accounts.hasError) {
    return <div>Error retrieving client accounts</div>;
  }

  if (accountGroups.hasError) {
    return <div>Error retrieving accounts groups</div>;
  }

  const isLoading =
    clientProfile.isLoading || accounts.isLoading || accountGroups.isLoading;

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <LogoLoadingStill />
      </div>
    );
  }

  if (curAccountGroup === undefined || !hasAccounts || !anyAccountsOpen) {
    return (
      <NoGroups
        setCurTab={updateCurTab}
        curTab={curTab}
        clientId={clientId}
        hasAccounts={hasAccounts}
        sendToDocCenterTasksList={props.sendToDocCenterTasksList}
      />
    );
  }

  const onWarningEligibleTab =
    curTab === "Projections" || curTab === "Holdings" || curTab === "Activity";

  const showWarningTooltip =
    curAccountGroup?.accountGroupType === "ALL_CLIENT_ACCOUNTS" &&
    curGroupDPLorPontera.length >= 1 &&
    onWarningEligibleTab;

  return (
    <div className={styles.pageContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.centeringContainer}>
          <div className={styles.headerDiv}>
            {showWarningTooltip && (
              <Tooltip
                onMouseEnter={() => setFullWarningIcon(true)}
                onMouseLeave={() => setFullWarningIcon(false)}
                placement="right"
                tooltipText={
                  <>
                    <div className={styles.tooltipTextHeader}>
                      The following accounts are not included in the data below:
                    </div>
                    {curGroupDPLorPontera.map((acc) => {
                      const { custodian, custodianAccountNumber } = acc;

                      const displayName = custodianAccountNumber
                        ? `${custodian} (...${custodianAccountNumber.slice(
                            -4
                          )})`
                        : `${custodian} - Account Number Unavailable`;

                      return (
                        <div
                          className={styles.tooltipAccount}
                          key={acc.accountId}
                        >
                          <span>•</span>
                          {displayName}
                        </div>
                      );
                    })}
                  </>
                }
              >
                <div className={styles.warningDiv}>
                  {fullWarningIcon ? (
                    <WarningRoundedIcon className={styles.warningIconFull} />
                  ) : (
                    <WarningAmberRoundedIcon className={styles.warningIcon} />
                  )}
                </div>
              </Tooltip>
            )}
          </div>

          {hasAccounts && <Tabs setCurTab={updateCurTab} curTab={curTab} />}

          <div className={styles.tabScreenContainer}>
            {curTab === "Summary" && (
              <>
                <Spacer verticalSpacing="24px" />

                <PerformanceCustomDateRangeContextProvider>
                  <SummaryTabV4
                    accountOperationalState={curAccountOperationalState}
                    hasAccounts={hasAccounts}
                    anyAccountsOpen={anyAccountsOpen}
                    groupId={curAccountGroup.accountGroupId ?? null}
                    sendToDocCenterTasksList={props.sendToDocCenterTasksList}
                    timeFrame={timeFrame}
                    setTimeFrame={setTimeFrame}
                  >
                    <CustomPerformanceGroupsDropdown
                      performanceGroupId={curAccountGroup.accountGroupId}
                      setAccountGroupId={(agId: PerformanceGroupId) =>
                        setCurAccountGroupId(agId)
                      }
                      setAwaitingAccountGroupId={(agId: PerformanceGroupId) =>
                        setAwaitingAccountGroupId(agId)
                      }
                      setTimeFrame={setTimeFrame}
                      width={444}
                    />
                  </SummaryTabV4>
                </PerformanceCustomDateRangeContextProvider>
              </>
            )}

            {curTab === "Activity" && (
              <ActivityTabV4 groupId={curAccountGroup.accountGroupId ?? null}>
                <CustomPerformanceGroupsDropdown
                  performanceGroupId={curAccountGroup.accountGroupId}
                  setAccountGroupId={(agId: PerformanceGroupId) =>
                    setCurAccountGroupId(agId)
                  }
                  setAwaitingAccountGroupId={(agId: PerformanceGroupId) =>
                    setAwaitingAccountGroupId(agId)
                  }
                  setTimeFrame={setTimeFrame}
                  width={444}
                />
              </ActivityTabV4>
            )}

            {curTab === "Holdings" && (
              <>
                <HoldingsTabV4 groupId={curAccountGroup.accountGroupId ?? null}>
                  <CustomPerformanceGroupsDropdown
                    performanceGroupId={curAccountGroup.accountGroupId}
                    setAccountGroupId={(agId: PerformanceGroupId) =>
                      setCurAccountGroupId(agId)
                    }
                    setAwaitingAccountGroupId={(agId: PerformanceGroupId) =>
                      setAwaitingAccountGroupId(agId)
                    }
                    setTimeFrame={setTimeFrame}
                    width={444}
                  />
                </HoldingsTabV4>
              </>
            )}

            {curTab === "Projections" && enableProjectionsTab && (
              <>
                <Spacer verticalSpacing="24px" />

                <ProjectionsTabV4
                  clientId={clientId}
                  groupId={curAccountGroup.accountGroupId ?? null}
                >
                  <CustomPerformanceGroupsDropdown
                    performanceGroupId={curAccountGroup.accountGroupId}
                    setAccountGroupId={(agId: PerformanceGroupId) =>
                      setCurAccountGroupId(agId)
                    }
                    setAwaitingAccountGroupId={(agId: PerformanceGroupId) =>
                      setAwaitingAccountGroupId(agId)
                    }
                    setTimeFrame={setTimeFrame}
                    width={343}
                  />
                </ProjectionsTabV4>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

interface TabProps {
  setCurTab: (tab: TabOption) => void;
  curTab: TabOption;
}

const Tabs = (props: TabProps) => {
  const { enableProjectionsTab, enableProjectionsTabVisibilityBadge } =
    useFlags();

  return (
    <div className={styles.tabs}>
      {tabOptions
        .filter((t) => {
          if (t === "Projections") {
            return enableProjectionsTab;
          }

          return true;
        })
        .map((tab: TabOption) => {
          return enableProjectionsTabVisibilityBadge &&
            tab === "Projections" ? (
            <div
              key={`tab-${tab}`}
              onClick={() => props.setCurTab(tab)}
              className={toClassName(styles.tab, {
                [styles.tabSelected]: props.curTab === tab,
              })}
            >
              <div className={styles.visibilityBadge}>
                <Tooltip
                  tooltipText="This is not visible to the client"
                  placement="top"
                >
                  <VisibilityOffIcon
                    style={{ width: 16, height: 16, color: "black" }}
                  />
                </Tooltip>
              </div>

              <p>{tab}</p>
            </div>
          ) : (
            <div
              key={`tab-${tab}`}
              onClick={() => props.setCurTab(tab)}
              className={toClassName(styles.tab, {
                [styles.tabSelected]: props.curTab === tab,
              })}
            >
              <p>{tab}</p>
            </div>
          );
        })}
    </div>
  );
};

interface NoGroupsProps extends TabProps {
  clientId: ClientId;
  hasAccounts: boolean;
  sendToDocCenterTasksList: () => void;
}

// NO accounts OR NO account data AND YES OR NO checklist - no Black Diamond data but an account can be open | checklist can be complete or incomplete
const NoGroups = (props: NoGroupsProps) => {
  const auth = useRequestAuth();
  const checklist = useClientChecklist(props.clientId, auth);

  const checklistItems = checklist.data?.items ?? [];

  const checklistStatus = {
    isLoading: checklist.isLoading,
    showChecklist: checklist.data?.showChecklist ?? false,
  };

  const showChecklist =
    checklistStatus.isLoading === false && checklistStatus.showChecklist;

  const completeChecklistToOpenAccounts = !props.hasAccounts && showChecklist;

  return (
    <div className={styles.pageContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.centeringContainer}>
          {props.hasAccounts && (
            <Tabs setCurTab={props.setCurTab} curTab={props.curTab} />
          )}

          {props.curTab === "Summary" ? (
            <div className={styles.tabScreenContainer}>
              <Spacer verticalSpacing="24px" />

              <div className={styles.container}>
                <div className={styles.graphContainer}>
                  <EmptyGraphPlaceholder
                    headerText={
                      completeChecklistToOpenAccounts
                        ? no_account_summary_headingText
                        : no_data_from_farther_headingText
                    }
                    subText={
                      completeChecklistToOpenAccounts
                        ? no_account_summary_subText
                        : no_data_from_farther_accounts_subText
                    }
                  />
                </div>

                <div
                  className={styles.noDataBreakdownTableOrChecklistContainer}
                >
                  {showChecklist ? (
                    <ChecklistController
                      checklistItems={checklistItems}
                      sendToDocCenterTasksList={props.sendToDocCenterTasksList}
                    />
                  ) : (
                    <>
                      <div className={styles.disabledAccountGroupDropdown}>
                        Farther Managed Accounts
                      </div>

                      <PerformanceGroupsSummaryBreakdownTable
                        accountId={null}
                        noData={true}
                        balSumFirstItem={null}
                        balSumLastItem={null}
                        currentBalance={null}
                        marketGains={null}
                        deposits={null}
                        withdrawals={null}
                        netAdditions={null}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.noDataDiv}>
              <div>{noDataText(props.curTab)}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const noDataText = (tab: TabOption): string => {
  switch (tab) {
    case "Activity":
      return "No activity data to show";
    case "Summary":
      return "No performance data to show";
    case "Holdings":
      return "No holdings data to show";
    case "Projections":
      return "No projection data to show";
    default:
      return assertUnreachable(
        tab,
        "This should be unreachable: get noDataText in Switch stmt for Dashboard tab (type: TabOption)"
      );
  }
};
