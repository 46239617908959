import { isEqual } from "lodash";

import getClientDashboard, {
  Response,
} from "@fartherfinance/frontend/api/Dashboard/requests/getClientDashboard";
import { ClientId, RequestConfig } from "@fartherfinance/frontend/api/Types";

import { ShowStatusFn } from "@src/multiCustodian/hooks/useStatusNotification";

const LONG_POLL_INTERVAL_TIMELENGTH = 5000;
const LONG_POLL_TIMEOUT_TIMELENGTH = 60_000;

type Invalidate = (clientId: ClientId) => Promise<void>;

const hasAnyExternalAccountsChanged = (
  // check if data changed on BE
  // both are arrays of lastUpdated timestamps for external accounts
  original: string[],
  current: string[]
): boolean => {
  return !isEqual(original.sort(), current.sort());
};

const pollClientDashboard = (
  clientId: ClientId,
  auth: RequestConfig | null,
  statusNotification: ShowStatusFn,
  dashboard: Response | undefined,
  invalidateDashboard: Invalidate,
  longNotificationText: string,
  notificationSuccessText: string,
  notificationErrorText: string
) => {
  if (!auth || !dashboard) {
    return;
  }

  const removeNotificationEarly = statusNotification(
    longNotificationText,
    "Success",
    "Right",
    LONG_POLL_TIMEOUT_TIMELENGTH
  );

  let longTimeout: NodeJS.Timeout | null = null;
  let interv: NodeJS.Timeout | null = null;

  const resetAll = () => {
    if (longTimeout !== null) {
      clearTimeout(longTimeout);
    }

    if (interv !== null) {
      clearInterval(interv);
    }

    invalidateDashboard(clientId);
  };

  longTimeout = setTimeout(() => {
    statusNotification(notificationErrorText, "Error", "Right");
    resetAll();
  }, LONG_POLL_TIMEOUT_TIMELENGTH);

  const originalExternalLinksStatusArray =
    dashboard.externalAccountDetails.accounts.map((acct) => acct.lastUpdated);

  // every so often fetch and check if data on BE has been updated
  interv = setInterval(async () => {
    const res = await getClientDashboard(clientId, auth);
    const currentExternalLinksStatusArray =
      res.externalAccountDetails.accounts.map((acct) => acct.lastUpdated);

    if (
      hasAnyExternalAccountsChanged(
        originalExternalLinksStatusArray,
        currentExternalLinksStatusArray
      )
    ) {
      resetAll();
      removeNotificationEarly();
      statusNotification(notificationSuccessText, "Success");
    }
  }, LONG_POLL_INTERVAL_TIMELENGTH);
};

export default pollClientDashboard;
