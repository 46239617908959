import React, { useMemo } from "react";

import { useSelector } from "react-redux";

import useCustomPortfoliosV1 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useCustomPortfoliosV1";
import useEssentialAllPortfoliosV1 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useEssentialAllPortfoliosV1";
import { EssentialPortfolioV1 } from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/getEssentialPortfoliosV1";
import { CustomPortfolioV1 } from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";
import { HookResult } from "@fartherfinance/frontend/api/Types";

import { InvestmentsPath } from "@src/config/routing/RouterPaths";
import ModelsTable from "@src/multiCustodian/components/Advisor/Investments/ModelMarketplace/Table/Table";
import TableLoading from "@src/multiCustodian/components/Advisor/Investments/ModelMarketplace/Table/TableLoading";
import TabPanel from "@src/multiCustodian/components/Advisor/Investments/SharedComponents/TabPanel/TabPanel";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import { State } from "@src/store";

import styles from "./Investments.module.css";

const tabPanelValue = `/Advisor/${InvestmentsPath}/ModelMarketplace`;

const ModelMarketplace = (
  props: React.PropsWithChildren<unknown>
): JSX.Element => {
  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));

  const auth = useAdvisorRequestAuth();

  const essentialPortfolios = useEssentialAllPortfoliosV1(auth);
  const advisorCustomPortfolios = useCustomPortfoliosV1(advisorId, auth);

  const portfolios: HookResult<(EssentialPortfolioV1 | CustomPortfolioV1)[]> =
    useMemo(() => {
      if (essentialPortfolios.isLoading || essentialPortfolios.hasError) {
        return essentialPortfolios;
      }

      if (
        advisorCustomPortfolios.isLoading ||
        advisorCustomPortfolios.hasError
      ) {
        return advisorCustomPortfolios;
      }

      const data = [
        ...essentialPortfolios.data,
        ...advisorCustomPortfolios.data.portfolios,
        // Remove derived portfolios with equivalent securities from list
      ].filter((port) => !port.isDerived);

      return {
        ...essentialPortfolios,
        data,
      };
    }, [advisorCustomPortfolios, essentialPortfolios]);

  if (portfolios.isLoading) {
    return (
      <TabPanel className={styles.tabPanel} value={tabPanelValue}>
        <TableLoading />
        {props.children}
      </TabPanel>
    );
  }

  if (portfolios.hasError) {
    return (
      <TabPanel className={styles.tabPanel} value={tabPanelValue}>
        <div>Error loading portfolios</div>
        {props.children}
      </TabPanel>
    );
  }

  return (
    <TabPanel className={styles.tabPanel} value={tabPanelValue}>
      <ModelsTable portfolios={portfolios.data} />
      {props.children}
    </TabPanel>
  );
};

export default ModelMarketplace;
