import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

export const useStyles = makeStyles()((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
    gridGap: 68.5,
    width: "100%",
    padding: theme.spacing(pxToSpacing(48), pxToSpacing(40), pxToSpacing(30)),
    marginBottom: theme.spacing(pxToSpacing(60)),
    backgroundColor: theme.appearance.$backgroundSubtlest,
    borderRadius: theme.shape.borderRadius + 1,
  },
  flagOn: {
    color: theme.appearance.$textSuccess,
  },
  flagOff: {
    color: theme.appearance.$textDanger,
  },
  statusRow: {
    display: "flex",
    alignItems: "center",
  },
  statusExceptionIndicator: {
    width: 18,
    height: 18,
    marginRight: theme.spacing(pxToSpacing(6)),
    color: theme.appearance.$iconDanger,
    cursor: "pointer",
  },
  title: {
    fontFamily: theme.typography.fontSecondary,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: "24px",
    letterSpacing: "0.63px",
    color: theme.appearance.$text,
  },
  label: {
    fontFamily: theme.typography.fontSecondary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.fontSize,
    lineHeight: "19px",
    letterSpacing: "0.63px",
    color: theme.colors.primary.shades.cream70,
    whiteSpace: "nowrap",
  },
  labelKey: {
    fontWeight: theme.typography.fontWeightBold,
  },
  hoverable: {
    cursor: "pointer",
  },
  underlined: {
    textDecoration: "underline",

    "&:hover": {
      textDecoration: "none",
    },
  },
}));
