import { AccountDetails } from "@fartherfinance/frontend/api/Dashboard/requests/getClientDashboard";

export const isValidAccountTypeForCashMgmt = (
  account: AccountDetails
): boolean => {
  const type = account.accountDetails.accountType;
  if (
    type === "Individual" ||
    type === "Trust" ||
    type === "Joint WROS" ||
    type === "Joint TIC" ||
    type === "Joint CP" ||
    type === "Joint TE"
  ) {
    return true;
  }
  return false;
};

export const fundingDaysInMonth = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
] as const;
