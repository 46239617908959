import React from "react";

import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "../SetupRecurringTransfersModal.module.css";

interface Props {
  closeModal: () => void;
}

const WaterfallFundingMethodSetupLoading = (props: Props): JSX.Element => {
  return (
    <>
      <Skeleton height={70} />
      <Spacer />
      <Skeleton height={50} />
      <Spacer />
      <Skeleton height={50} />
      <Spacer />
      <Skeleton height={100} />
      <div className={styles.footer}>
        <div className={styles.modalButtonsDiv}>
          <ButtonPrimary
            variant={"outlined"}
            buttonType={"secondary"}
            text={"Cancel"}
            onClick={props.closeModal}
          />

          <ButtonPrimary
            disabled={true}
            variant={"contained"}
            buttonType={"primary"}
            text={"Setup"}
            onClick={() => undefined}
            style={{ marginLeft: "15px" }}
          />
        </div>
      </div>
    </>
  );
};

export default WaterfallFundingMethodSetupLoading;
