import { isNil } from "lodash";

export default function formatSSN(
  prevTaxID: string,
  curTaxID: string | null | undefined
): string {
  if (isNil(curTaxID)) {
    return "";
  }

  if (curTaxID.length > 11) {
    return prevTaxID;
  }

  if (prevTaxID.length > curTaxID.length) {
    return curTaxID;
  }

  const noDashes = curTaxID.replace(/-/g, "");

  if (prevTaxID.length > curTaxID.length) {
    if (noDashes.length >= 6) {
      const first3 = noDashes.slice(0, 3);
      const second2 = noDashes.slice(3, 5);
      const lastAll = noDashes.slice(5, noDashes.length);

      return `${first3}-${second2}-${lastAll}`;
    }

    if (noDashes.length === 5) {
      const first3 = noDashes.slice(0, 3);
      const second2 = noDashes.slice(3, 5);

      return `${first3}-${second2}`;
    }

    if (noDashes.length === 4) {
      const first3 = noDashes.slice(0, 3);
      const second1 = noDashes.slice(3, 4);

      return `${first3}-${second1}`;
    }

    if (noDashes.length === 3) {
      const first3 = noDashes.slice(0, 3);

      return `${first3}`;
    }

    return curTaxID;
  }

  if (noDashes.length === 3 && curTaxID.length > prevTaxID.length) {
    return `${noDashes}-`;
  }

  if (noDashes.length === 5 && curTaxID.length > prevTaxID.length) {
    const first3 = noDashes.slice(0, 3);
    const second2 = noDashes.slice(3, 5);

    return `${first3}-${second2}-`;
  }

  if (curTaxID.length === prevTaxID.length) {
    if (noDashes.length >= 5) {
      const first3 = noDashes.slice(0, 3);
      const second2 = noDashes.slice(3, 5);
      const lastAll = noDashes.slice(5, noDashes.length);

      return `${first3}-${second2}-${lastAll}`;
    }

    if (noDashes.length >= 3) {
      const first3 = noDashes.slice(0, 3);
      const second1 = noDashes.slice(3, 4);

      return `${first3}-${second1}`;
    }
  }

  return curTaxID;
}
