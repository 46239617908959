import React from "react";

import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";

import styles from "./Card.module.css";

interface Props {
  start: React.ReactNode;
  end: React.ReactNode;
}

const Card = (props: Props): JSX.Element => {
  return (
    <BorderBox className={styles.taxBudgetCard}>
      <div className={styles.container}>
        <div className={styles.start}>{props.start}</div>

        <div className={styles.end}>{props.end}</div>
      </div>
    </BorderBox>
  );
};

export default Card;
