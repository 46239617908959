import React, { useEffect, useMemo, useState } from "react";

import { orderBy, uniq } from "lodash";

import useAddPlanAccountsV2 from "@fartherfinance/frontend/api/Accounts/hooks/useAddPlanAccountsV2";
import { CustodianAccountTypes } from "@fartherfinance/frontend/api/Accounts/requests/getPlanAccountTypesV2";
import useClientAccounts from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAccounts";
import useClientDashboard from "@fartherfinance/frontend/api/Dashboard/hooks/useClientDashboard";
import {
  ClientId,
  Custodian,
  PlanId,
  RequestConfig,
} from "@fartherfinance/frontend/api/Types";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";

import AddAccountModal, {
  AccountTypeAutocompleteOption,
} from "./AddAccountModal";

interface Props {
  planId: PlanId;
  planHolderClientId: ClientId;
  currentPlanAccountsCustodian: Custodian | null;
  planAccountTypes: CustodianAccountTypes[];
  closeModal: () => void;
}

const AddAccountState = (props: Props): JSX.Element => {
  const [selectedCustodian, setSelectedCustodian] = useState<Custodian | null>(
    props.currentPlanAccountsCustodian
  );

  useEffect(() => {
    if (props.currentPlanAccountsCustodian === null) {
      return;
    }

    // Keep `selectedCustodian` in sync with `currentPlanAccountsCustodian`
    // If we already have this custodian selected, do nothing
    if (selectedCustodian !== props.currentPlanAccountsCustodian) {
      setSelectedCustodian(props.currentPlanAccountsCustodian);
    }
  }, [props.currentPlanAccountsCustodian, selectedCustodian]);

  const [currentAccountType, setCurrentAccountType] =
    useState<AccountTypeAutocompleteOption | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const auth = useAdvisorRequestAuth();

  const addPlanAccounts = useAddPlanAccountsV2(auth);

  const statusNotification = useStatusNotification();

  // We need to use a client auth cred but are in advisor mode
  // so use the plan's clientId
  const adjustedAuth: RequestConfig | null = useMemo(
    () =>
      auth === null
        ? null
        : {
            clientId: props.planHolderClientId,
            jwt: auth.jwt,
            advisorId: auth.advisorId,
          },
    [auth, props.planHolderClientId]
  );

  // We don't use `useClientCustodian` because we don't want to use defaultCustodian
  const accountList = useClientAccounts(props.planHolderClientId, adjustedAuth);
  const custodians: Custodian[] = useMemo(
    () =>
      uniq(accountList.data?.fartherAccounts ?? []).map(
        (a) => a.accountDetails.custodian
      ),
    [accountList.data]
  );

  const custodianIsLoading = accountList.isLoading;
  const custodianFromAccounts: Custodian | null = useMemo(
    () => (custodians.length === 1 ? custodians[0] : null),
    [custodians]
  );

  useEffect(() => {
    // If we have no accounts just do nothing
    if (custodianFromAccounts === null) {
      return;
    }

    // If we are explicitly given a custodian change nothing;
    if (props.currentPlanAccountsCustodian !== null) {
      return;
    }

    // If we have accounts with a custodian already, lock us to that custodian.
    // If we already have this custodian selected, do nothing
    if (selectedCustodian !== custodianFromAccounts) {
      setSelectedCustodian(custodianFromAccounts);
    }
  }, [
    custodianFromAccounts,
    props.currentPlanAccountsCustodian,
    selectedCustodian,
  ]);

  const dashboard = useClientDashboard(props.planHolderClientId, adjustedAuth);

  useEffect(() => {
    if (props.currentPlanAccountsCustodian !== null) {
      return;
    }

    if (accountList.isLoading) {
      return;
    }

    if (custodianFromAccounts !== null) {
      return;
    }

    // If we have not selected a custodian or already have on then use the
    // `defaultCustodian` as a suggestion
    if (dashboard.data !== undefined && selectedCustodian === null) {
      setSelectedCustodian(dashboard.data.defaultCustodian);
    }
  }, [
    accountList.isLoading,
    custodianFromAccounts,
    dashboard.data,
    props.currentPlanAccountsCustodian,
    selectedCustodian,
  ]);

  const custodianDropdownDisabled =
    //If at least one account exists for this plan, lock it to that plan's custodian
    props.currentPlanAccountsCustodian !== null
      ? true
      : // If the client already has a custodian for other accounts lock it to that one
      custodianFromAccounts !== null
      ? true
      : // If we are still loading the custodian, lock it
      custodianIsLoading === true
      ? true
      : // Change as you please
        false;

  const currentCustodian: Custodian | null =
    props.currentPlanAccountsCustodian ??
    custodianFromAccounts ??
    selectedCustodian;

  const planCustodians = props.planAccountTypes.map(([custodian]) => custodian);

  const custodianOptions = orderBy(planCustodians);

  const accountTypeOptions = useMemo(() => {
    const [_, custodianAccountTypes] =
      props.planAccountTypes.find(
        ([custodian]) => custodian === currentCustodian
      ) ?? [];

    return custodianAccountTypes ?? [];
  }, [currentCustodian, props.planAccountTypes]);

  const sortedAccountTypeOptions = useMemo(() => {
    // NOTE: infering went crazy here, had to manually type this
    return orderBy<CustodianAccountTypes[1][number]>(
      accountTypeOptions,
      (accountType) => accountType.displayName,
      "asc"
    ).map(({ value, displayName }) => ({ label: displayName, value }));
  }, [accountTypeOptions]);

  const handleChangeCustodian = (custodian: Custodian | null) => {
    setCurrentAccountType(null);
    setSelectedCustodian(custodian);
  };

  const handleAddAccountToPlan = async () => {
    if (currentAccountType === null || currentCustodian === null) {
      return;
    }

    try {
      setIsLoading(true);
      await addPlanAccounts({
        planId: props.planId,
        custodian: currentCustodian,
        accountType: currentAccountType.value,
      });
      statusNotification("Account added", "Success");
      props.closeModal();
    } catch {
      statusNotification("Error adding account", "Error");
      setIsLoading(false);
    }
  };

  return (
    <AddAccountModal
      closeModal={props.closeModal}
      isLoading={isLoading}
      currentCustodian={currentCustodian ? { label: currentCustodian } : null}
      custodianOptions={
        custodianOptions ? custodianOptions.map((el) => ({ label: el })) : []
      }
      onCustodianChange={handleChangeCustodian}
      custodianDropdownDisabled={custodianDropdownDisabled}
      custodianDropdownLoading={custodianIsLoading && custodianDropdownDisabled}
      currentAccountType={currentAccountType}
      accountTypeOptions={sortedAccountTypeOptions}
      onAccountChange={setCurrentAccountType}
      addPlanAccount={handleAddAccountToPlan}
    />
  );
};

export default AddAccountState;
