import React from "react";

import { Modal as MUIModal } from "@mui/material";

import { CustodianAccountType } from "@fartherfinance/frontend/api/Accounts/requests/getPlanAccountTypesV2";
import { Custodian } from "@fartherfinance/frontend/api/Types";

import LogoLoadingStill from "../../../../../sharedComponents/LogoLoadingStill/LogoLoadingStill";
import Modal from "../../../../../sharedComponents/Modal/Modal";
import ButtonPrimary from "../../../../components/MUI/Button/Button";
import { AutocompleteOption } from "@src/sharedComponents/Dropdown/Dropdown";
import DropdownComponent from "@src/sharedComponents/SAODropdown/Dropdown";

import styles from "./AddAccountModal.module.css";

interface CustodianAutocompleteOption extends AutocompleteOption {
  label: Custodian;
}

export interface AccountTypeAutocompleteOption extends AutocompleteOption {
  label: string;
  value: CustodianAccountType;
}

interface Props {
  closeModal: () => void;
  isLoading: boolean;
  currentCustodian: CustodianAutocompleteOption | null;
  custodianOptions: CustodianAutocompleteOption[];
  onCustodianChange: (custodian: Custodian | null) => void;
  custodianDropdownDisabled: boolean;
  custodianDropdownLoading: boolean;
  currentAccountType: AccountTypeAutocompleteOption | null;
  accountTypeOptions: AccountTypeAutocompleteOption[];
  onAccountChange: (accountTypeOption: AccountTypeAutocompleteOption) => void;
  addPlanAccount: () => Promise<void>;
}

const AddAccountModal = (props: Props): JSX.Element => {
  return (
    <MUIModal open onClose={props.closeModal}>
      <Modal
        closeModal={props.closeModal}
        modalStyle={{ width: "350px", overflowY: "visible" }}
      >
        <p className={styles.header}>Add Accounts</p>

        <DropdownComponent
          formLabel={"Custodian"}
          placeholder={"Select Custodian"}
          value={props.currentCustodian}
          values={props.custodianOptions}
          onChange={(custodian) =>
            props.onCustodianChange(custodian.label ?? custodian)
          }
          disabled={props.custodianDropdownDisabled}
          loading={props.custodianDropdownLoading}
        />

        <p className={styles.centerText}>
          All accounts in a plan must have the same custodian.
        </p>

        <div style={{ width: "100%", position: "relative", height: 80 }}>
          <div style={{ position: "absolute", top: 0, left: 0, right: 0 }}>
            <DropdownComponent
              formLabel={"Account"}
              placeholder={"Select Account"}
              value={props.currentAccountType}
              values={props.accountTypeOptions}
              onChange={props.onAccountChange}
            />
          </div>
        </div>

        <div className={styles.footer}>
          <ButtonPrimary
            buttonType={"secondary"}
            variant={"outlined"}
            text={"Cancel"}
            onClick={props.closeModal}
            disabled={props.isLoading}
            sx={{ width: "80px" }}
          />

          <ButtonPrimary
            buttonType={"primary"}
            variant={"contained"}
            text={"Add"}
            onClick={props.addPlanAccount}
            disabled={
              props.currentCustodian === null ||
              props.currentAccountType === null ||
              props.isLoading
            }
            sx={{ width: "80px" }}
          />
        </div>

        {(props.isLoading || props.custodianDropdownLoading) && (
          <div className={styles.loadingContainer}>
            <LogoLoadingStill />
          </div>
        )}
      </Modal>
    </MUIModal>
  );
};

export default AddAccountModal;
