import React, { useContext } from "react";

import Box from "@mui/material/Box";

import { TableContext } from "../../Context";

import { useStyles } from "./TableRow.styles";

export interface TableRowProps {
  children: React.ReactNode;
  className?: string;
  noHover?: boolean;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
}

export const TableRow: React.FC<TableRowProps> = ({
  children,
  className,
  noHover,
  onMouseEnter,
  onMouseLeave,
}) => {
  const { columnsCount } = useContext(TableContext);
  const { classes, cx } = useStyles({ columnsCount });

  return (
    <Box
      className={cx(classes.row, className, { [classes.noHover]: noHover })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </Box>
  );
};
