import React, { useEffect, useState } from "react";

import ms from "ms";
import { Redirect } from "react-router-dom";

import isLocalStorageAvailable from "../utils/isLocalStorageAvailable";
import { clearUserData } from "@src/multiCustodian/utils/clearUserData";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

import * as styles from "./Logout.module.css";

/**
 * Nukes all the data in the browser that we can
 */
export default function ClearData() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Promise.allSettled([clearUserData("Client"), clearUserData("Client")])
      .then(() => {
        if (isLocalStorageAvailable()) {
          localStorage.clear();
        }
        sessionStorage.clear();
      })
      .then(() => {
        document.cookie = `farther_ui_theme=farther_dark; Max-Age=-99999999; path=/`;
        document.cookie = `farther_email=cleardata@example.com; Max-Age=-99999999; path=/`;

        // Cribbed https://stackoverflow.com/questions/179355/clearing-all-cookies-with-javascript
        const cookies = document.cookie.split("; ");
        cookies.forEach((c) => {
          const d = window.location.hostname.split(".");
          while (d.length > 0) {
            const cookieBase =
              encodeURIComponent(c.split(";")[0].split("=")[0]) +
              "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
              d.join(".") +
              " ;path=/";

            console.log("clearing cookie", cookieBase + "/");
            document.cookie = cookieBase + "/";

            const p = location.pathname.split("/");
            while (p.length > 0) {
              console.log("clearing cookie", cookieBase + p.join("/"));
              document.cookie = cookieBase + p.join("/");
              p.pop();
            }
            d.shift();
          }
        });
      })
      .then(() => {
        // Give Magic a few seconds to log out
        setTimeout(() => {
          setIsLoading(false);
        }, ms("2 seconds"));
      });
  }, []);

  if (isLoading === false) {
    return (
      <Redirect
        to={{ pathname: "/Login", search: "clear=true" }}
        push={false}
      />
    );
  }

  return (
    <div className={styles.container}>
      <LogoLoadingStill />
    </div>
  );
}
