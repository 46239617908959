export const REALIZED_CAPITAL_GAINS_TOOLTIP_TEXT =
  "Given that Farther does not have full knowledge of all investors' external accounts, Farther runs a simplified tax calculation where short and long term capital gains taxes are calculated separately and then summed to arrive at the total tax amount. If Farther is an investor's only source of capital gains, actual taxes may differ due to different netting of gains and losses.";

export const ACCOUNTS_NO_TRADINGGROUP_TOOLTIP_TEXT =
  "Due to system limitations, these accounts have been grouped together for the purposes of hypothetical scenarios.";

export const TARGET_WEIGHT_TOOLTIP_TEXT =
  "The weights in the columns Target and Without Tax Budget may diverge due to rounding.";

export const WEIGHT_DIFFERENCE_TOOLTIP_TEXT =
  "Difference between: With and Without a Tax Budget";

export const CAPITAL_GAINS_TAXES_TOOLTIP_TEXT =
  "The capital gains taxes displayed here are limited to only accounts that are in a trading group.";

export const MULTIPLE_ACCOUNTS_TOOLTIP_TEXT =
  "When multiple accounts share a tax budget, we rebalance the largest account first and then move in descending order, with priority given to accounts that have tax-loss harvesting turned on.";

export const TAX_BUDGETS_TOOLTIP_TEXT =
  "Tax budgets are not a guaranteed amount. If the client has precise requirements, add a buffer to the budget amount.";

export const ACCOUNT_IS_TAX_EXEMPT =
  "Further, gains and losses associated with tax-advantaged accounts do not contribute to taxes in this analysis.";

export const ACCOUNT_UNDER_10_000 =
  "Accounts with balances below $10,000 may experience greater drift from their target model portfolio.";

export const COUNT_TOWARDS_CURRENT_YEAR =
  "Any capital gains taxes accrued since the start of the year will count towards the current year's budget.";
