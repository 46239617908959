import React from "react";

import { useFlags } from "launchdarkly-react-client-sdk";

import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import toUSD from "@src/utils/toUSD";

import styles from "./Table.module.css";

interface Props {
  isLoading: boolean;
  title: React.ReactNode;
  balanceStart: number | null;
  balanceEnd: number | null;
  netAdditions: number | null;
  marketGains: number | null;
  fees: number | null;
}

const AdvisorBookAnalyticsTable = (props: Props) => {
  const { enableAdvisorBookFees } = useFlags();

  return (
    <div className={styles.data_summary_table}>
      <div className={styles.data_summary_table_header}>
        <p className={styles.bold}>{props.title}</p>

        <p className={styles.headerSecondaryText}>AUM Breakdown</p>
      </div>

      <div className={styles.data_summary_table_body}>
        <div className={styles.data_summary_table_item}>
          <p className={styles.regular}>Starting Balance</p>

          <p className={styles.regular}>
            {props.isLoading ? (
              <Skeleton width={100} />
            ) : props.balanceStart !== null ? (
              toUSD(props.balanceStart)
            ) : (
              "--"
            )}
          </p>
        </div>

        <div className={styles.data_summary_table_item}>
          <p className={styles.regular}>Net Additions</p>

          <p className={styles.regular}>
            {props.isLoading ? (
              <Skeleton width={80} />
            ) : props.netAdditions !== null ? (
              toUSD(props.netAdditions)
            ) : (
              "--"
            )}
          </p>
        </div>

        <div className={styles.data_summary_table_item}>
          <p className={styles.alignSelfStart}>Market Gains</p>

          <div className={styles.flexColumnEnd}>
            {props.isLoading ? (
              <Skeleton width={80} />
            ) : props.marketGains !== null ? (
              <p className={styles.regular}>{toUSD(props.marketGains)}</p>
            ) : (
              <p className={styles.regular}>--</p>
            )}
          </div>
        </div>

        <div className={styles.hr_line} />

        <div className={styles.data_summary_table_item}>
          <p className={styles.bold}>Total AUM</p>

          {props.isLoading ? (
            <Skeleton width={100} />
          ) : props.balanceEnd !== null ? (
            <p className={styles.boldGreen}>{toUSD(props.balanceEnd)}</p>
          ) : (
            <p className={styles.bold}>--</p>
          )}
        </div>

        {enableAdvisorBookFees ? (
          <div className={styles.data_summary_table_item}>
            <p className={styles.regular}>Fees</p>

            {props.isLoading ? (
              <Skeleton width={70} />
            ) : props.fees !== null ? (
              <p className={styles.regular}>{toUSD(props.fees)}</p>
            ) : (
              <p className={styles.regular}>--</p>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AdvisorBookAnalyticsTable;
