import React from "react";

import { useFlags } from "launchdarkly-react-client-sdk";

import useCheckPermission from "@fartherfinance/frontend/api/Authz/hooks/useCheckPermission";

import {
  InsuranceMarketplacePath,
  InvestmentsPath,
  LendingMarketplacePath,
} from "@src/config/routing/RouterPaths";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";

import NavItem from "./NavItem/NavItem";

const AdvisorNavMenu: React.FC = () => {
  const auth = useAdvisorRequestAuth();

  const canCreateCX = useCheckPermission(
    {
      type: "Organization",
      action: "create.ops",
      id: "Farther",
    },
    auth
  );

  const canBulkCreateClient = useCheckPermission(
    {
      type: "Organization",
      action: "create.client.bulk",
      id: "Farther",
    },
    auth
  );

  const canGenerateBilling = useCheckPermission(
    {
      type: "TradingGroup",
      action: "billing.generate",
      // We are looking for a generic permission for the TradingGroup resource
      // and not a specific instance
      id: null,
    },
    auth
  );

  const {
    enableClientOpsInGlacier,
    enableAdvisorBookAnalytics,
    enableInvestmentsTab,
    enableAdvisorOpportunities,
    enableInsuranceMarketplace,
    enableLendingMarketplace,
  } = useFlags();

  const enableAdminTab =
    canCreateCX.data?.authorized === true ||
    canBulkCreateClient.data?.authorized === true ||
    canGenerateBilling.data?.authorized === true;

  return (
    <>
      <NavItem
        iconName="BookAnalytics"
        text={"Book Analytics"}
        path={"/Advisor/BookAnalytics"}
        isHidden={false}
        isDisabled={!enableAdvisorBookAnalytics}
        mixpanelEvent={{ name: "Advisor Click Book-Analytics" }}
      />

      <NavItem
        iconName="Clients"
        text={"Clients"}
        path={"/Advisor/Clients"}
        isHidden={false}
        mixpanelEvent={{ name: "Advisor Click Clients" }}
      />

      <NavItem
        iconName="Plans"
        text={"Plans"}
        path={"/Advisor/Plans"}
        isHidden={false}
        mixpanelEvent={{ name: "Advisor Click Plans" }}
      />

      <NavItem
        iconName="Tasks"
        text={"Tasks"}
        path={"/Advisor/Tasks"}
        isHidden={false}
        mixpanelEvent={{ name: "Advisor Click Tasks" }}
      />

      <NavItem
        iconName="Investments"
        text={"Investments"}
        path={`/Advisor/${InvestmentsPath}`}
        isHidden={false}
        isDisabled={!enableInvestmentsTab}
        mixpanelEvent={{ name: "Advisor Click Investments" }}
      />

      {enableInsuranceMarketplace && (
        <NavItem
          iconName="Insurance"
          text={"Insurance"}
          path={`/Advisor/${InsuranceMarketplacePath}`}
          isHidden={false}
          isDisabled={!enableInsuranceMarketplace}
          mixpanelEvent={{ name: "Advisor Click Insurance-Marketplace" }}
        />
      )}

      {enableLendingMarketplace && (
        <NavItem
          iconName="Lending"
          text={"Lending"}
          path={`/Advisor/${LendingMarketplacePath}`}
          isHidden={false}
          isDisabled={!enableLendingMarketplace}
          mixpanelEvent={{ name: "Advisor Click Lending-Marketplace" }}
        />
      )}

      {enableAdvisorOpportunities && (
        <NavItem
          iconName="Opportunities"
          text={"Opportunities"}
          path={"/Advisor/Opportunities"}
          isHidden={false}
          isDisabled={!enableAdvisorOpportunities}
          mixpanelEvent={{ name: "Advisor Click Opportunities" }}
        />
      )}

      <NavItem
        iconName="ClientOps"
        text={"Client Ops"}
        path={"/Advisor/Client-Ops"}
        isHidden={false}
        isDisabled={!enableClientOpsInGlacier}
        mixpanelEvent={{ name: "Advisor Click Client-Ops" }}
      />

      {enableAdminTab && (
        <NavItem
          iconName="Admin"
          text={"Admin"}
          path={"/Advisor/Admin"}
          isHidden={false}
          isDisabled={!enableAdminTab}
          mixpanelEvent={{ name: "Advisor Click Admin" }}
        />
      )}
    </>
  );
};

export default AdvisorNavMenu;
