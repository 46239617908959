import React from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import Popover from "@src/sharedComponents/Popover/Popover";

import styles from "./BudgetOptionsPopOver.module.css";

type Size = "large" | "small";

interface Props {
  anchorRef: React.MutableRefObject<HTMLDivElement | null>;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onDelete: () => void;
  onEdit: () => void;
  size: Size;
}

const BudgetOptionsPopOver: React.FC<Props> = ({
  anchorRef,
  isOpen,
  onClose,
  onDelete,
  onEdit,
  onOpen,
  size,
}) => {
  return (
    <Popover
      open={isOpen}
      onClose={onClose}
      anchorOriginVertical={"bottom"}
      anchorOriginHorizontal={"right"}
      transformOriginHorizontal={"right"}
      anchor={anchorRef.current}
      popoverElement={
        <div className={styles.dotMenu} onClick={onOpen} ref={anchorRef}>
          <MoreHorizIcon
            classes={{
              root: toClassName(styles.dotsIcon, {
                [styles.iconLarge]: size === "large",
                [styles.iconSmall]: size === "small",
              }),
            }}
          />
        </div>
      }
    >
      <div className={styles.popoverMenu}>
        <Button
          text="Edit Budget"
          onClick={onEdit}
          variant="text"
          buttonType="primary"
        />

        <Button
          text="Delete Budget"
          onClick={onDelete}
          variant="text"
          buttonType="error"
        />
      </div>
    </Popover>
  );
};

export default BudgetOptionsPopOver;
