import React from "react";

import {
  autocompleteClasses,
  inputClasses,
  textFieldClasses,
} from "@mui/material";

import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import Dropdown, {
  AutocompleteOption,
} from "@src/sharedComponents/SAODropdown/Dropdown";

const borderRadius = 8;

interface Props<S extends AutocompleteOption> {
  searchInput: string | null;
  handleSearch: (searchTerm: string) => void;
  onChange: (search: S) => void;
  options: S[];
  isLoading: boolean;
}

const SearchSecurity = <S extends AutocompleteOption>(props: Props<S>) => {
  const {
    color: { $surfaceMedium },
  } = useTheme();

  return (
    <Dropdown
      placeholder={"Search for Securities..."}
      value={null}
      values={props.options}
      inputValue={props.searchInput ?? ""}
      onInputChange={(_e, searchTerm) => props.handleSearch(searchTerm)}
      onChange={props.onChange}
      loading={
        props.isLoading &&
        props.searchInput !== null &&
        props.searchInput !== ""
      }
      sx={{
        [`& .${autocompleteClasses.popper}`]: {
          marginTop: "8px !important", //Without !important, this gets overwritten every time the popper is toggled
        },
        [`& .${autocompleteClasses.paper}`]: {
          borderRadius: `${borderRadius}px`,
        },
        [`& .${autocompleteClasses.root}`]: {
          [`& .${inputClasses.root}`]: {
            padding: `10px 14px`,
          },
        },
        [`& .${autocompleteClasses.inputRoot}`]: {
          backgroundColor: $surfaceMedium,
          borderRadius: `${borderRadius}px`,
        },
        [`& .${autocompleteClasses.endAdornment}`]: {
          display: "none", //chevron
        },
        [`& .${inputClasses.root}`]: {
          "&:before": {
            display: "none", //on-hover border
          },
        },
        [`& .${textFieldClasses.root}`]: {
          [`& .${inputClasses.underline}`]: {
            "&:after": {
              display: "none", //subtle border
            },
          },
        },
      }}
    />
  );
};

export default SearchSecurity;
