import { isNil } from "lodash";

const numberLTEMax = (
  //LTE = Less Then or Equal to
  input: string | null | undefined,
  max: number
): boolean => {
  const isEmpty = isNil(input) || input === "";

  if (isEmpty) {
    return true;
  }

  const normalizedInput = !isEmpty ? input.replace(/,/g, "") : "";

  const toNumber = Number(normalizedInput);

  const isValidNum = !isNaN(toNumber);

  const isOrBelowMax = toNumber <= max;

  return isValidNum && isOrBelowMax;
};

export default numberLTEMax;
