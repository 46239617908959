import React, { useMemo } from "react";

import { useParams } from "react-router-dom";

import useInvitations from "@fartherfinance/frontend/api/Sharing/hooks/useInvitations";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import NotificationDot from "../SideNav/NavItem/icons/NotificationDot";
import NavItem from "../SideNav/NavItem/NavItem";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

import styles from "./Sharing.module.css";

const SharingNotificationsNavItemWrapper = (): JSX.Element => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const auth = useRequestAuth();
  const invitations = useInvitations(auth);

  const invitationsReceived = useMemo(() => {
    if (invitations.isLoading || invitations.hasError) {
      return 0;
    }

    return invitations.data.received.filter((ir) => ir.status === "Pending")
      .length;
  }, [invitations]);

  return (
    <NavItem
      iconName="Sharing"
      text={"Sharing"}
      path={`/Client/${clientId}/Sharing`}
      isHidden={false}
      mixpanelEvent={{ name: "Client Click Sharing" }}
      notifications={
        invitationsReceived > 0 ? (
          <div className={styles.notificationsTally}>
            <NotificationDot />
          </div>
        ) : undefined
      }
    />
  );
};

export default SharingNotificationsNavItemWrapper;
