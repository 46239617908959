import React, { ForwardedRef, forwardRef, useMemo } from "react";

import {
  createTheme,
  FormControl,
  FormLabel,
  StandardTextFieldProps,
  SxProps,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { useSelector } from "react-redux";

import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import { fontSize } from "../constants";
import { State } from "@src/store";

// This is used by a storybook file
export type Props = StandardTextFieldProps; // eslint-disable-line import/no-unused-modules

const TextInputReffed = forwardRef(function TextInput(
  props: Props,
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element {
  const {
    color: { $text, $textSubtle, $textDisabled, $borderBold },
  } = useTheme();

  const { fontRegular } = useSelector((state: State) => ({
    fontRegular: state.main_Reducer.brand.current.fonts.regular,
  }));

  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: fontRegular,
        },
        palette: {
          primary: {
            main: $text,
          },
          text: {
            primary: $text,
            secondary: $textSubtle,
            disabled: $textDisabled,
          },
        },
      }),
    [fontRegular, $text, $textSubtle, $textDisabled]
  );

  const sx: SxProps<typeof theme> = useMemo(
    () => ({
      "& .MuiInput-underline": {
        "&:after": {
          borderBottomWidth: 1,
          borderBottomColor: $borderBold,
        },
        "&:before": {
          borderBottomWidth: 1,
          borderBottomColor: $borderBold,
          borderBottomStyle: "solid",
        },
      },
      "& .MuiInputBase-root": {
        "&:hover:before": {
          borderBottomWidth: 1,
          borderBottomColor: $borderBold,
        },
      },
    }),
    [$borderBold]
  );

  return (
    <ThemeProvider theme={theme}>
      <FormControl fullWidth={props.fullWidth ?? true} sx={{ ...props.style }}>
        {props.label && (
          <FormLabel sx={{ fontSize: "14px" }}>{props.label}</FormLabel>
        )}

        <TextField
          {...props}
          ref={ref}
          label={""}
          sx={sx}
          style={{}} //should remain empty. style should only apply to formControl.
          variant={"standard"}
          hiddenLabel={true}
          value={props.value ?? ""}
          type={props.type ?? "text"}
          focused={true}
          inputProps={{
            ...(props.inputProps ? { ...props.inputProps } : {}),
            style: { fontSize: fontSize, ...props.inputProps?.style },
          }}
        />
      </FormControl>
    </ThemeProvider>
  );
});

export default TextInputReffed;
