const fullNameUnder31Char = (
  first: string | null | undefined,
  last: string | null | undefined
): string | true => {
  const fullName = `${first ?? ""} ${last ?? ""}`;

  return fullName.length < 31
    ? true
    : `Your full name (${fullName}) needs to be under 31 characters.`;
};

export default fullNameUnder31Char;
