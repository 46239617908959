import React from "react";

import { Stack, Typography } from "@mui/material";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./TabularSection.module.css";

interface TabularSectionProps {
  title: string;
  entries: Array<{
    key: string;
    value: string | React.ReactNode;
  }>;
}

const TabularSection: React.FC<TabularSectionProps> = ({ title, entries }) => (
  <Stack className={styles.container}>
    <Typography className={styles.title}>{title}</Typography>
    <Stack className={styles.content}>
      {entries.map(({ key, value }) => (
        <div key={key} className={styles.row}>
          <Typography className={toClassName(styles.entry, styles.leftEntry)}>
            {key}
          </Typography>

          {React.isValidElement(value) ? (
            value
          ) : (
            <Typography
              className={toClassName(styles.entry, styles.rightEntry)}
            >
              {value}
            </Typography>
          )}
        </div>
      ))}
    </Stack>
  </Stack>
);

export default TabularSection;
