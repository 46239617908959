import React, { useMemo } from "react";

import {
  BreakdownItem,
  type PortfolioAssetClasses,
  Security,
  Sleeve,
} from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";

import { getSecuritiesLabel } from "../../Client/Portfolio/utils/getSecuritiesLabel";

import SleeveTable from "./Table/SleeveTable";

type MiniSecurity = Pick<Security, "ticker" | "name">;

function tickerDescription(sec: MiniSecurity): string {
  switch (sec.ticker) {
    case "CASH$":
      return "CASH";
    default:
      return sec.ticker;
  }
}

function tickerSecondaryDescription(sec: MiniSecurity): string {
  switch (sec.ticker) {
    case "CASH$":
      return "US Dollars";
    default:
      return sec.name;
  }
}

type PortfolioAssetClass = typeof PortfolioAssetClasses[number];

interface Props {
  assetClass: PortfolioAssetClass;
  asset: BreakdownItem;
  onBack: () => void;
  sleeve: Sleeve;
}

const SleeveAssetView = ({
  assetClass,
  asset,
  onBack,
  sleeve,
}: Props): JSX.Element => {
  const title = useMemo(() => {
    const secondaryDescription = getSecuritiesLabel(asset.securities.length);

    return {
      name: asset.category,
      description: assetClass,
      secondaryDescription,
    };
  }, [asset, assetClass]);

  const rows = useMemo(
    () =>
      asset.securities.map((s) => {
        return {
          key: s.ticker,
          description: tickerDescription(s),
          secondaryDescription: tickerSecondaryDescription(s),
          allocation: parseFloat(s.percent),
        };
      }),
    [asset.securities]
  );

  return (
    <SleeveTable title={title} rows={rows} onBack={onBack} sleeve={sleeve} />
  );
};

export default SleeveAssetView;
