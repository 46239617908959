import { add, isBefore } from "date-fns";
import { isNil } from "lodash";

const isUnder100 = (date: Date | null | undefined): string | true => {
  const years100Ago = add(new Date(), { years: -100 });

  return !isNil(date) && isBefore(date, years100Ago)
    ? "Need to be 100 years or younger."
    : true;
};

export default isUnder100;
