import React, { PropsWithChildren, useEffect } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { head, orderBy } from "lodash";
import { useParams } from "react-router-dom";

import useCurrentTrigger from "@fartherfinance/frontend/api/CashCycle/hooks/useCurrentTrigger";
import useTriggerRuns from "@fartherfinance/frontend/api/CashCycle/hooks/useTriggerRuns";
import useClientAccounts from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAccounts";
import useFundingAccountExtended from "@fartherfinance/frontend/api/ExternalAccount/hooks/useFundingAccountExtended";
import { ClientId } from "@fartherfinance/frontend/api/Types";
import { formatExternalAccount } from "@fartherfinance/frontend/formatting/account";

import FundingAccount from "@src/multiCustodian/components/Client/CashManagement/FundingAccount/FundingAccount";
import FundingAccountLoading from "@src/multiCustodian/components/Client/CashManagement/FundingAccount/FundingAccountLoading";
import RecurringTransfers from "@src/multiCustodian/components/Client/CashManagement/RecurringTransfers/RecurringTransfers";
import RecurringTransfersLoading from "@src/multiCustodian/components/Client/CashManagement/RecurringTransfers/RecurringTransfersLoading";
import RecurringTransfersSetUp from "@src/multiCustodian/components/Client/CashManagement/RecurringTransfers/RecurringTransfersSetUp";
import UpcomingTransfers from "@src/multiCustodian/components/Client/CashManagement/UpcomingTransfers/UpcomingTransfers";
import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { trackEvent } from "@src/multiCustodian/services/tracking";
import PageHero from "@src/sharedComponents/PageHero/PageHero";
import TitleBlock from "@src/sharedComponents/PageHero/TitleBlock";

import styles from "./CashManagement.module.css";

const CashManagement = (props: PropsWithChildren<unknown>): JSX.Element => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const statusNotification = useStatusNotification();

  const { enableRecurringTransfers } = useFlags();

  const auth = useRequestAuth();

  const currentTrigger = useCurrentTrigger(clientId, auth);
  const triggerRuns = useTriggerRuns(clientId, auth);

  const accounts = useClientAccounts(clientId, auth);

  const fundingAccount = useFundingAccountExtended(auth);

  useEffect(() => {
    if (triggerRuns.hasError) {
      statusNotification("Unable to get upcoming transfers", "Error");
    }
  }, [triggerRuns.hasError, statusNotification]);

  useEffect(() => {
    trackEvent({ name: "Client Open Funding Settings" });
  }, []);

  const plannedTriggerRuns = orderBy(
    (triggerRuns.data ?? []).filter((run) => run.status === "planned"),
    (r) => r.executeDate,
    "desc"
  );

  const triggerRun = head(plannedTriggerRuns) ?? null;

  const noUpcomingTransfers =
    triggerRuns.isLoading ||
    triggerRun === null ||
    currentTrigger.isLoading ||
    currentTrigger.hasError ||
    accounts.isLoading ||
    accounts.hasError;

  return (
    <BaseLayout>
      <PageHero start={<TitleBlock title="Cash Management" />} />

      {/* Modals (change funding, edit method, remove trigger, remove trigger run) will be passed in as children */}
      {props.children}

      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <p className={styles.title}>Funding Settings</p>

          {noUpcomingTransfers ? (
            <></>
          ) : (
            <UpcomingTransfers
              recurringTransferAmount={currentTrigger.data?.amount.amount}
              triggerRun={triggerRun}
              formattedExternalAccountName={
                fundingAccount.data
                  ? formatExternalAccount(fundingAccount.data)
                  : "No Funding Account"
              }
              clientAccounts={accounts.data.fartherAccounts}
            />
          )}

          {fundingAccount.hasError ? (
            <div className={styles.error}>Error getting funding account</div>
          ) : fundingAccount.isLoading ? (
            <FundingAccountLoading />
          ) : (
            <FundingAccount fundingAccount={fundingAccount.data} />
          )}

          {enableRecurringTransfers && fundingAccount.data && (
            <>
              {accounts.hasError ? (
                <div className={styles.error}>
                  Error getting client accounts
                </div>
              ) : currentTrigger.hasError ? (
                <div className={styles.error}>
                  Error getting recurring transfers
                </div>
              ) : currentTrigger.isLoading || accounts.isLoading ? (
                <RecurringTransfersLoading />
              ) : currentTrigger.data ? (
                <RecurringTransfers
                  trigger={currentTrigger.data}
                  clientAccounts={accounts.data.fartherAccounts}
                />
              ) : (
                <RecurringTransfersSetUp />
              )}
            </>
          )}
        </div>
      </div>
    </BaseLayout>
  );
};

export default CashManagement;
