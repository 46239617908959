import React from "react";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import DocumentsActions from "../DocumentsActions/DocumentsActions";
import DocumentsBreadcrumbs from "../DocumentsBreadcrumbs/DocumentsBreadcrumbs";
import PageHero from "@src/sharedComponents/PageHero/PageHero";

interface DocumentsToolbarProps {
  clientId: ClientId;
  directories: string[];
}

const DocumentsToolbar: React.FC<DocumentsToolbarProps> = ({
  clientId,
  directories,
}) => {
  return (
    <PageHero
      start={
        <DocumentsBreadcrumbs clientId={clientId} directories={directories} />
      }
      end={<DocumentsActions directories={directories} />}
    />
  );
};

export default DocumentsToolbar;
