import React, { useState } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";

import useClientAccounts from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAccounts";
import useExternalAccountsExtended from "@fartherfinance/frontend/api/ExternalAccount/hooks/useExternalAccountsExtended";

import { SetScreenAndTransferType } from "../../../Types";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import { State } from "@src/store";

import Deposit from "./DepositScreens/Deposit";
import DepositSuccess from "./DepositScreens/DepositSuccess";
import DepositV4 from "./DepositScreens/DepositV4";
import {
  DepositScreenOption,
  SetDepositContainerScreen,
  TransferData,
} from "./Types";

interface Props {
  setCurScreen: SetScreenAndTransferType;
}

const DepositContainer = (props: Props): JSX.Element => {
  const clientId = useSelector(
    (state: State) => state.main_Reducer.user.id_user
  );

  const { enableCustomPerformanceGroupsV2 } = useFlags();

  const auth = useRequestAuth();

  const externalAccounts = useExternalAccountsExtended(undefined, auth);

  const accounts = useClientAccounts(clientId, auth);

  const [curDepositScreen, setCurDepositScreen] =
    useState<DepositScreenOption>("deposit");
  const [transferData, setTransferData] = useState<TransferData | null>(null);

  const setDepositContainerScreen: SetDepositContainerScreen = (
    next_screen,
    transfer_data
  ) => {
    setCurDepositScreen(next_screen);
    setTransferData(transfer_data);
  };

  if (accounts.isLoading || externalAccounts.isLoading) {
    return <div>Loading</div>;
  }

  if (accounts.hasError || externalAccounts.hasError) {
    return <div>Error</div>;
  }

  switch (curDepositScreen) {
    case "deposit":
      return (
        <>
          {enableCustomPerformanceGroupsV2 ? (
            <DepositV4
              setCurScreen={props.setCurScreen}
              setDepositContainerScreen={setDepositContainerScreen}
            />
          ) : (
            <Deposit
              setCurScreen={props.setCurScreen}
              setDepositContainerScreen={setDepositContainerScreen}
            />
          )}
        </>
      );

    case "deposit_success":
      return transferData?.type === "Standard" ? (
        <DepositSuccess
          setCurScreen={props.setCurScreen}
          plan={{
            externalAccountId: transferData.externalAccountId,
            fartherAccountId: transferData.fartherAccountId,
            amount: transferData.amount,
            transferId: transferData.transferId,
          }}
        />
      ) : (
        <div>
          Error with deposit_success Bad Transfer type{" "}
          {transferData?.type ?? "U"}
        </div>
      );

    default:
      return (
        <div>
          Error with ${curDepositScreen} {transferData?.type ?? "U"}
        </div>
      );
  }
};

export default DepositContainer;
