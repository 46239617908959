import React, { useMemo, useState } from "react";

import { throttle } from "lodash";
import { useSelector } from "react-redux";

import useClientAccounts, {
  AccountDetails,
} from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAccounts";
import { AccountType } from "@fartherfinance/frontend/api/Transfer/requests/Types";
import { FartherAccountId } from "@fartherfinance/frontend/api/Types";

import { State } from "../../../../../../store";
import Input_Component from "../../../../../components/Input/Input_Component";
import ButtonPrimary from "../../../../../components/MUI/Button/Button";
import {
  AcatsInstitutionInfo,
  IraType,
  SetScreenAndTransferType,
  TransferScreen,
} from "../../Types";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useThemeFragment from "@src/multiCustodian/theme/useThemeFragment";

import "../css.css";

const neutralUnderlineStyle = {
  borderBottom: "1px solid var(--neutral_input_underline_color_1)",
  width: "100%",
};

type Props = (
  | {
      transferType: "roll over";
      iraType: IraType;
    }
  | { transferType: "transfer" }
) & {
  screen: TransferScreen["screen"];
  acatsInstitution: AcatsInstitutionInfo;
  acatsAccountType: AccountType;
  accountHolderName: string;
  accountNumber: string;
  setCurScreen: SetScreenAndTransferType;
};

const TransferDestination = (props: Props): JSX.Element => {
  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));

  const auth = useRequestAuth();

  const accounts = useClientAccounts(clientId, auth);

  const [toAccountId, setToAccountId] = useState<FartherAccountId | null>(null);
  const [showTransferToMenu, setShowTransferToMenu] = useState<boolean>(false);
  const [showPartialTransfer, setShowPartialTransfer] =
    useState<boolean>(false);

  const throttledSetShowTransferToMenu = useMemo(
    () => throttle((show: boolean) => setShowTransferToMenu(show), 1000),
    []
  );

  const t = useThemeFragment("Farther");

  const onContinue = () => {
    if (toAccountId == null) {
      return;
    }

    const { setCurScreen: _, screen: _s, ...screenInfo } = props;

    if ("iraType" in screenInfo) {
      props.setCurScreen({
        screen: "IRA/optionalInfo",
        toAccountId,
        ...screenInfo,
      });
    } else {
      props.setCurScreen({
        screen: "Investment/optionalInfo",
        toAccountId,
        ...screenInfo,
      });
    }
  };

  if (accounts.isLoading) {
    return <div>loading</div>;
  }

  if (accounts.hasError) {
    return <div>Error</div>;
  }

  const toAccount = accounts.data.fartherAccounts.find(
    (e) => e.accountId === toAccountId
  );

  const filterAccountsByTransferType = (a: AccountDetails): boolean => {
    if (props.transferType === "roll over") {
      return a.accountDetails.accountType === "IRA" ||
        a.accountDetails.accountType === "Roth IRA" ||
        a.accountDetails.accountType === "Sep IRA"
        ? true
        : false;
    } else {
      return true;
    }
  };

  const fartherAccountList = accounts.data.fartherAccounts.filter(
    filterAccountsByTransferType
  );

  return (
    <div className="Dashboard_Acats_pages__container">
      <div className="Dashboard_Acats_pages__content_container">
        <p className="Dashboard_Acats_pages__title">
          Select destination account
        </p>

        <p className="Dashboard_Acats_pages__summary_2">
          {`Before we initiate the ${props.transferType} into ${t(
            "companyName"
          )}, which ${t(
            "companyName"
          )} account would you like the assets moved into? You
        can select the account from the dropdown below.`}
        </p>

        <Input_Component
          title={"Transfer to"}
          title_img={"/src/assets/svg/wallet-outlined.svg"}
          name={"depositTo"}
          type={"text"}
          placeholder={""}
          validation_img={"/src/assets/svg/chevron-down-grey.svg"}
          show_checkmark={false}
          underline_style={neutralUnderlineStyle}
          error_description={""}
          value={
            toAccount
              ? `${t("companyName")} - ` +
                (toAccount.accountDetails.displayName === "Long-term Savings"
                  ? "Long-term Growth"
                  : toAccount.accountDetails.displayName)
              : ""
          }
          handleInputChange={() => undefined}
        />

        <div className="Dashboard_Acats_pages__float_right">
          <img
            src="/src/assets/svg/chevron-down-grey.svg"
            className="Dashboard_Acats_pages__dropdown_arrow_img"
            onMouseEnter={() => throttledSetShowTransferToMenu(true)}
            onMouseLeave={() => throttledSetShowTransferToMenu(false)}
          />
        </div>

        <div className="Dashboard_Acats_pages__dropdown_div">
          {showTransferToMenu && (
            <div
              className="Dashboard_Acats_pages__deposit_to_menu"
              onMouseEnter={() => throttledSetShowTransferToMenu(true)}
              onMouseLeave={() => throttledSetShowTransferToMenu(false)}
            >
              {fartherAccountList.map((a) => (
                <FartherAccountOption
                  key={a.accountId}
                  account={a}
                  selectAccount={(accountId) => setToAccountId(accountId)}
                />
              ))}

              {fartherAccountList.length === 0 && (
                <div className="Dashboard_Acats_pages__deposit_to_menu_option">
                  <p className="Dashboard_Acats_pages__option_text">
                    {`None of your ${t("companyName")} accounts are valid
                    destinations for your {props.transferType}.`}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="Dashboard_Acats_pages__flex_start_div">
          <p
            className="Dashboard_Acats_pages__yellow_text"
            onMouseEnter={() => setShowPartialTransfer(true)}
            onMouseLeave={() => setShowPartialTransfer(false)}
          >
            Looking to make a partial transfer?
          </p>
        </div>

        {showPartialTransfer && (
          <div className="Dashboard_Acats_pages__wire_transfer_div">
            <p className="Dashboard_Acats_pages__wire_transfer_text">
              Our account transfer process is automatically set to transfer the
              entire account, but if you would like to make a partial transfer,
              reach out to us at farther.com in the help widget to facilitate
              the request.
            </p>
          </div>
        )}

        <div className="Dashboard_Acats_pages__spacing_2" />

        <div className="Dashboard_Acats_pages__button_container_2">
          <div
            className="Dashboard_Acats_pages__back_button_container"
            onClick={() => {
              const { setCurScreen: _, screen: _s, ...screenInfo } = props;

              if ("iraType" in screenInfo) {
                props.setCurScreen({
                  screen: "IRA/accountInformation",
                  ...screenInfo,
                });
              } else {
                props.setCurScreen({
                  screen: "Investment/accountInformation",
                  ...screenInfo,
                });
              }
            }}
          >
            <img src="/src/assets/svg/left_arrow.svg" />

            <div className="Dashboard_Acats_pages__back_button">Back</div>
          </div>

          <ButtonPrimary
            disabled={toAccountId === null}
            variant={"contained"}
            buttonType={"primary"}
            text={"Continue"}
            onClick={onContinue}
          />
        </div>
      </div>
    </div>
  );
};

export default TransferDestination;

interface FartherAccountOptionProps {
  account: AccountDetails;
  selectAccount: (id: FartherAccountId) => void;
}

function FartherAccountOption({
  account,
  selectAccount,
}: FartherAccountOptionProps) {
  return (
    <div
      className="Dashboard_Acats_pages__deposit_to_menu_option"
      onClick={() => selectAccount(account.accountId)}
    >
      <p className="Dashboard_Acats_pages__option_text">
        {account.accountDetails.displayName === "Long-term Savings"
          ? "Long-term Growth"
          : account.accountDetails.displayName}
      </p>
      <p className="Dashboard_Acats_pages__option_text">
        {account.accountDetails.accountBalance?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}
      </p>
    </div>
  );
}
