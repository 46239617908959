import React from "react";

import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material";

import { useStyles } from "./TextField.styles";

export type TextFieldProps = MuiTextFieldProps;

export const TextField: React.FC<TextFieldProps> = React.forwardRef<
  HTMLDivElement,
  TextFieldProps
>(function TextField(props: TextFieldProps, ref) {
  const { classes, cx } = useStyles();
  const { className, InputLabelProps, InputProps, ...restProps } = props;

  return (
    <MuiTextField
      ref={ref}
      className={cx(className, classes.textField)}
      variant="standard"
      size="small"
      fullWidth
      spellCheck={false}
      InputLabelProps={{
        shrink: true,
        classes: { root: classes.label },
        ...InputLabelProps,
      }}
      InputProps={{
        classes: { root: classes.input },
        ...InputProps,
      }}
      {...restProps}
    />
  );
});
