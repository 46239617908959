import React from "react";

import { matchPath, useLocation } from "react-router-dom";

import {
  AltAssetsMarketplacePath,
  InvestmentsPath,
} from "@src/config/routing/RouterPaths";
import HorizontalNavigation, {
  HorizontalNavigationTab,
} from "@src/multiCustodian/components/HorizontalNavigation/HorizontalNavigation";

import AdvisorContainer from "./AdvisorContainer";
import AdvisorContentContainer from "./AdvisorContentContainer";

const tabs: HorizontalNavigationTab[] = [
  {
    label: "Models",
    path: `/Advisor/${InvestmentsPath}/ModelMarketplace`,
  },
  {
    label: "Separately-Managed Accounts",
    path: `/Advisor/${InvestmentsPath}/SMA`,
  },
  {
    label: "Sleeves",
    path: `/Advisor/${InvestmentsPath}/Sleeves`,
  },
  {
    label: "Alternative Assets",
    path: `/Advisor/${AltAssetsMarketplacePath}`,
  },
];

const Investments: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const location = useLocation();

  const isPathWithOwnContainer = [
    `/Advisor/${InvestmentsPath}/ModelMarketplace/Custom/Create`,
    `/Advisor/${InvestmentsPath}/ModelMarketplace/Custom/:portfolioId/Update`,
    `/Advisor/${InvestmentsPath}/ModelMarketplace/Update`,
    `/Advisor/${InvestmentsPath}/ModelMarketplace/NewPartner`,
    `/Advisor/${InvestmentsPath}/Sleeves/Create`,
    `/Advisor/${InvestmentsPath}/Sleeves/:sleeveId/Update`,
    `/Advisor/${AltAssetsMarketplacePath}/*`,
  ].some((path) => matchPath(location.pathname, { path }));

  if (isPathWithOwnContainer) {
    return <>{children}</>;
  }

  return (
    <AdvisorContainer>
      <AdvisorContentContainer heroTitle={"Investments"}>
        <HorizontalNavigation tabs={tabs}>{children}</HorizontalNavigation>
      </AdvisorContentContainer>
    </AdvisorContainer>
  );
};

export default Investments;
