import React, { useReducer } from "react";

import CreateHypothetical from "@src/multiCustodian/components/TaxBudgets/CreateHypothetical/CreateHypothetical";
import CreateEditTaxBudgetContext from "@src/multiCustodian/components/TaxBudgets/reducer/Context";
import { initialState } from "@src/multiCustodian/components/TaxBudgets/reducer/initialState";
import reducer from "@src/multiCustodian/components/TaxBudgets/reducer/reducer";

const CreateHypotheticalPage: React.FC = () => {
  const [state, dispatch] = useReducer(
    reducer,
    initialState({ flowType: "Hypothetical", isAdvisor: false })
  );

  return (
    <CreateEditTaxBudgetContext.Provider value={{ state, dispatch }}>
      <CreateHypothetical />
    </CreateEditTaxBudgetContext.Provider>
  );
};

export default CreateHypotheticalPage;
