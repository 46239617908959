import React, { PropsWithChildren } from "react";

import { PerformanceGroupId } from "@fartherfinance/frontend/api/Types";

import HoldingsV4 from "@src/multiCustodian/components/PerformanceGroups/Holdings/HoldingsV4";

import styles from "./HoldingsTab.module.css";

interface Props {
  groupId: PerformanceGroupId | null;
}

export default function HoldingsTabV4(
  props: PropsWithChildren<Props>
): JSX.Element {
  return (
    <HoldingsV4 groupId={props.groupId}>
      <div className={styles.accountGroupDropdownContainer}>
        {props.children}
      </div>
    </HoldingsV4>
  );
}
