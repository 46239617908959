import React from "react";

import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./FundingAccount.module.css";

const FundingAccountLoading = (): JSX.Element => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerInnerDiv}>
          <Skeleton width={140} />

          <Skeleton width={100} />
        </div>
      </div>

      <div className={styles.body}>
        <div className={styles.bodyInnerDiv}>
          <Skeleton width={220} />

          <Skeleton width={140} />
        </div>
      </div>
    </div>
  );
};

export default FundingAccountLoading;
