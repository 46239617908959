import React from "react";

import { SharedResource } from "@fartherfinance/frontend/api/Sharing/Types";

import SelectResourcesToShareForm from "../../../shared/SelectResources/SelectResourcesToShareForm";
import { actions } from "../../reducer/actions";
import { useAddCollaboratorContext } from "../../reducer/Context";

const AddCollaboratorSelectResourcesFormStateAndDispatcher: React.FC = () => {
  const { state, dispatch } = useAddCollaboratorContext();

  const {
    data: { resources },
  } = state;

  const handleToggleSelectAll = (
    bool: boolean,
    resources: SharedResource[]
  ) => {
    if (bool) {
      dispatch({
        type: actions.ADD_ALL_RESOURCES,
        payload: resources,
      });
    } else {
      dispatch({ type: actions.REMOVE_ALL_RESOURCES, payload: undefined });
    }
  };

  return (
    <SelectResourcesToShareForm
      resourcesSelected={resources.length}
      handleToggleSelectAll={handleToggleSelectAll}
    />
  );
};

export default AddCollaboratorSelectResourcesFormStateAndDispatcher;
