import React from "react";

import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";

import AdvisorLogout from "@src/multiCustodian/pages/Advisor/Logout";
import ClearData from "@src/multiCustodian/pages/ClearData";
import ClientLogout from "@src/multiCustodian/pages/Logout";
import MobileHelloSignPage from "@src/multiCustodian/pages/mobile/hellosign/client/:clientId/url/:url";
import PrivacyPolicyText from "@src/multiCustodian/pages/privacy-policy";
import StytchLoginPage from "@src/multiCustodian/pages/StytchLogin";
import PlaidOAuth from "@src/sharedComponents/PlaidOAuth";

import { advisorRoutes } from "./AdvisorRouting";
import { clientRoutes } from "./ClientRouting";
import MobileRedirect from "./MobileRedirect";
import { useTrackPageChanges } from "./Router.hooks";
import { RouteElement } from "./Router.types";
import { NoMatch } from "./UtilityComponents";

// TODO: think if we should move login/logout pages to the corresponding client/advisor routes
const routes: RouteElement[] = [
  {
    path: "/",
    component: () => <Redirect from={"/"} to={"/Login"} exact push={false} />,
  },
  { path: "/Login", component: StytchLoginPage },
  {
    path: "/Login/Stytch",
    component: () => (
      <Redirect from={"/Login/Stytch"} to={"/Login"} exact push={false} />
    ),
  },
  {
    path: "/AdvisorLogin",
    component: () => (
      <Redirect from={"/AdvisorLogin"} to={"/Login"} exact push={false} />
    ),
  },
  { path: "/plaid-oauth", component: PlaidOAuth },
  { path: "/privacy-policy", component: PrivacyPolicyText },
  {
    // Requires a `url` query string
    path: "/mobile/hellosign/client/:clientId/url",
    component: MobileHelloSignPage,
    trackingEvent: { name: "Client Open Mobile HelloSign" },
  },
  {
    path: "/ClearData",
    component: ClearData,
  },
  {
    path: "/Logout",
    component: ClientLogout,
  },
  ...clientRoutes,
  {
    // This is not inside PrivateAdvisorRoute so we Magic time to logout
    // This must be above "/Advisor/*"
    path: "/Advisor/Logout",
    component: () => <AdvisorLogout />,
  },
  ...advisorRoutes,
];

export const history = createBrowserHistory();

const SentryRoute = Sentry.withSentryRouting(Route);

const MixPanelRouteListener: React.FC<{ routes: RouteElement[] }> = ({
  routes,
}) => {
  useTrackPageChanges(routes);
  return <></>;
};

function mapRoute(route: RouteElement): JSX.Element {
  return "routes" in route ? (
    <SentryRoute
      key={route.path}
      path={route.path}
      render={(props) => {
        return (
          <route.component {...props}>
            <Switch>
              {route.routes.map(mapRoute)}
              <SentryRoute path={route.path} exact render={() => null} />
              <route.noMatch />
            </Switch>
          </route.component>
        );
      }}
    />
  ) : (
    <SentryRoute
      key={route.path}
      path={route.path}
      exact={route.exact ?? true}
      component={route.component}
    />
  );
}

export default function FartherRouter() {
  return (
    <div id="router">
      <Router history={history}>
        <MixPanelRouteListener routes={routes} />

        <MobileRedirect />

        <CompatRouter>
          <Switch>
            {routes.map(mapRoute)}
            <NoMatch />
          </Switch>
        </CompatRouter>
      </Router>
    </div>
  );
}
