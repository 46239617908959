const agent = window.navigator.userAgent.toLowerCase();
export const operatingSystem =
  agent.indexOf("win") > -1
    ? "Windows"
    : agent.indexOf("mac") > -1
    ? "MacOS"
    : agent.indexOf("x11") > -1
    ? "Unix"
    : agent.indexOf("linux") > -1
    ? "Linux"
    : "Other";
