import { TimeFrame } from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Types";

export const no_account_summary_headingText = "No Account Summary";

export const no_account_summary_subText =
  "Complete the setup checklist to connect a Farther managed account.";

export const no_data_from_farther_headingText =
  "No Data From Farther Managed Accounts";

export const no_data_from_farther_accounts_subText =
  "Once we have data from a Farther managed account, you will see performance, holdings, and activity here.";

export const DEFAULT_TIME_FRAME = "1Y" as TimeFrame;
