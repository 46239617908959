import { useMemo } from "react";

import useEquivalentSecurityList from "@fartherfinance/frontend/api/EquivalentSecurities/hooks/useEquivalentSecurityList";
import {
  EquivalentSecurityList,
  Member,
} from "@fartherfinance/frontend/api/EquivalentSecurities/requests/getEquivalentSecurityList";
import useSecuritiesBySecurityIds from "@fartherfinance/frontend/api/SecurityMaster/hooks/useSecuritiesBySecurityIds";
import {
  EquivalentSecurityListId,
  HookResult,
  Ticker,
} from "@fartherfinance/frontend/api/Types";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";

import { isTickerIdentifier } from "./utils";

export interface MemberWithInfo extends Member {
  ticker: Ticker;
  description: string;
}

export interface EquivalentSecurityListWithInfo extends EquivalentSecurityList {
  members: MemberWithInfo[];
}

export default function useEquivalentSecurityListMembersWithInfo(
  listId: EquivalentSecurityListId
): HookResult<EquivalentSecurityListWithInfo> {
  const auth = useAdvisorRequestAuth();
  const listRequest = useEquivalentSecurityList(listId, auth);

  const securityIds = useMemo(() => {
    if (listRequest.data === undefined) {
      return [];
    }

    return listRequest.data.members.map((m) => m.securityId);
  }, [listRequest.data]);

  const securitiesRequest = useSecuritiesBySecurityIds(
    securityIds,
    securityIds.length > 0 ? auth : null
  );

  const list = useMemo(() => {
    if (
      listRequest.data === undefined ||
      securitiesRequest.data === undefined
    ) {
      return {} as EquivalentSecurityListWithInfo;
    }

    // add ticker and displayName (security description), for display, to obj with securityId in members array
    return {
      ...listRequest.data,
      members: listRequest.data.members.map((m) => {
        const security = securitiesRequest.data.find(
          (s) => s.id === m.securityId
        );

        if (security === undefined) {
          throw new Error(
            `Could not find Active security with identifier: registrar = TICKER, having securityId: ${m.securityId}`
          );
        }

        const tickerIdentifier = security.identifiers.find(
          (idr) => idr.state === "Active" && idr.registrar === "TICKER"
        );

        if (
          tickerIdentifier === undefined ||
          !isTickerIdentifier(tickerIdentifier)
        ) {
          throw new Error(
            `No ticker identifier for: ${
              security.name ?? "No Name Available"
            }, id: ${security.id}`
          );
        }

        return {
          ...m,
          ticker: tickerIdentifier.identifier,
          description: tickerIdentifier.description ?? "",
        };
      }),
    };
  }, [listRequest.data, securitiesRequest.data]);

  if (listRequest.hasError || securitiesRequest.hasError) {
    return {
      data: undefined,
      isLoading: false,
      hasError: true,
      error: listRequest.hasError
        ? "Error retrieving equivalent security list"
        : "Error retrieving security data",
    };
  }

  if (listRequest.isLoading || securitiesRequest.isLoading) {
    return {
      data: undefined,
      isLoading: true,
      hasError: false,
    };
  }

  return {
    data: list,
    isLoading: false,
    hasError: false,
  };
}
