import React from "react";

import { useHistory } from "react-router-dom";

import ModelSecuritiesTableLoading from "../../SharedComponents/ModelSecuritiesTable/ModelSecuritiesTableLoading";
import PageTitle from "../../SharedComponents/PageTitle";
import BackButton from "@src/multiCustodian/components/Client/BackButton";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./NewPartnerBody.module.css";

const NewPartnerBodyLoading = (): JSX.Element => {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.centeringContainer}>
        <BackButton
          onClick={() => history.push("/Advisor/Investments/ModelMarketplace")}
        />

        <div className={styles.bodyContainer}>
          <PageTitle>Create New Partner</PageTitle>

          <Spacer verticalSpacing="24px" />

          <Skeleton height={96} />

          <Spacer verticalSpacing="32px" />

          <Skeleton height={16} />

          <Spacer verticalSpacing="16px" />

          <ModelSecuritiesTableLoading />
        </div>
      </div>
    </div>
  );
};

export default NewPartnerBodyLoading;
