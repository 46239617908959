import React, { createContext, useContext } from "react";

import { fileUploadAcceptedExtensions } from "../const";
import useFilesManagement from "@src/multiCustodian/hooks/useFilesManagement";
import useUploadStateMap from "@src/multiCustodian/hooks/useUploadStateMap";
import Upload from "@src/sharedComponents/Upload/Upload";

type UploadFilesContexValue = Omit<
  ReturnType<typeof useFilesManagement>,
  "setFiles"
> &
  ReturnType<typeof useUploadStateMap> & {
    isUploading: boolean;
    fileUploadIntent: () => void;
  };

const UploadFilesContext = createContext<UploadFilesContexValue | undefined>(
  undefined
);

const UploadFilesProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { files, setFiles, removeFile, removeAllFiles } = useFilesManagement({
    countLimit: 10,
  });
  const {
    uploadStateMap,
    setUploadState,
    setUploadStateBare,
    resetUploadState,
  } = useUploadStateMap();

  const isUploading = Object.values(uploadStateMap ?? {}).some(
    (uploadState) => uploadState === "uploading"
  );

  const handlePersistFiles = (files: FileList | null): void => {
    if (files && files.length) {
      setFiles(files);
    }
  };

  return (
    <Upload
      multiple
      accept={fileUploadAcceptedExtensions.join(", ")}
      onChange={handlePersistFiles}
      renderChild={(onClick) => (
        <UploadFilesContext.Provider
          value={{
            files,
            removeFile,
            removeAllFiles,
            uploadStateMap,
            setUploadState,
            setUploadStateBare,
            resetUploadState,
            isUploading,
            fileUploadIntent: onClick,
          }}
        >
          {children}
        </UploadFilesContext.Provider>
      )}
    />
  );
};

const useUploadFiles = () => {
  const context = useContext(UploadFilesContext);

  if (!context) {
    throw new Error("useUploadFiles must be used within a UploadFilesProvider");
  }

  return context;
};

export { useUploadFiles };
export default UploadFilesProvider;
