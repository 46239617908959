import React from "react";

const BookAnalytics: React.FC = () => (
  <svg
    width="100%"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_655_8069)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 30.9547V48.8238L34.2151 56L51.9055 45.2355L64.5039 29.7346L62.1483 27.1405C61.2927 26.1981 60.0981 26.0842 59.1551 26.855L48.6508 35.4398L30.9342 36.337V32.7488L41.564 32.0222C42.9609 31.9267 44.0576 30.3505 44.0576 28.4385V25.5724L23.7163 21.9842L0 30.9547Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.9776 23.696L45.4773 24.5852V26.1417C49.9159 25.8012 53.417 21.8756 53.417 17.0828C53.417 12.0665 49.5815 7.99999 44.8501 7.99999C40.1187 7.99999 36.2832 12.0665 36.2832 17.0828C36.2832 19.6894 37.3188 22.0396 38.9776 23.696Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_655_8069">
        <rect width="64" height="64" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BookAnalytics;
