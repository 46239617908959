import { useEffect } from "react";

import { isNil } from "lodash";
import { useHistory } from "react-router-dom";

import usePortfolioV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/usePortfolioV2";
import { ClientId, PortfolioId } from "@fartherfinance/frontend/api/Types";

import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

export default function useFamReRouter(
  clientId: ClientId,
  portfolioId: PortfolioId
) {
  const auth = useRequestAuth();
  const portfolio = usePortfolioV2(portfolioId, auth);

  const history = useHistory();

  useEffect(() => {
    if (!isNil(portfolio.data)) {
      // If we are a FAM portfolio with direct indexing we should re-route to the non-DI portfolio
      if (
        portfolio.data.model.portfolioType === "FAM" &&
        portfolio.data.model.directIndexing &&
        portfolio.data.model.directIndexingInverse !== null
      ) {
        history.replace(
          `/Client/${clientId}/Investments/Portfolios/${portfolio.data.model.directIndexingInverse}/Accounts`
        );
      }
    }
  }, [clientId, history, portfolio.data]);
}
