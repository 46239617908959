import type { GetTasksQueryParams } from "@fartherfinance/frontend/api/Tasks/requests/getTasks";
import type {
  TaskAssignee,
  TaskPriority,
  TaskStatus,
  TaskType,
} from "@fartherfinance/frontend/api/Tasks/Types";
import { isGroupAssignee } from "@fartherfinance/frontend/api/Tasks/utils/isGroupAssignee";

import {
  UNKNOWN_GROUP,
  UNKNOWN_USER,
} from "@src/multiCustodian/pages/Advisor/common";
import {
  extractSortParts,
  mapToTableSortOrder,
} from "@src/multiCustodian/utils/sorting";
import type { Column } from "@src/sharedComponents/Table/Types";

import type { TaskTableColumns } from "./TasksTable";

export const getAssigneeName = (assignee: TaskAssignee): string => {
  return isGroupAssignee(assignee)
    ? assignee.groupName ?? UNKNOWN_GROUP
    : assignee.personName
    ? `${assignee.personName.first} ${assignee.personName.last}`
    : UNKNOWN_USER;
};

const taskStatusLabelObject_NonBeta: Record<TaskStatus, string> = {
  NOT_STARTED: "Not Started",
  IN_PROGRESS: "In Progress",
  AWAITING_RESPONSE: "Awaiting Response",
  COMPLETED: "Completed",
  // NOTE: map new waiting on statues to existing "Awaiting Response" label for non beta users
  WAITING_ON_CLIENT: "Awaiting Response",
  WAITING_ON_CLIENT_SERVICES: "Awaiting Response",
  WAITING_ON_ADVISOR: "Awaiting Response",
  WAITING_ON_CUSTODIAN: "Awaiting Response",
  CANCELED: "Canceled",
};

const taskStatusLabelTuples_NonBeta = Object.entries(
  taskStatusLabelObject_NonBeta
).map(([key, value]) => [key as TaskStatus, value] as const);

const taskStatusLabelObject_Beta: Record<TaskStatus, string> = {
  NOT_STARTED: "Not Started",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
  WAITING_ON_CLIENT: "Waiting on Client",
  WAITING_ON_CLIENT_SERVICES: "Waiting on Client Services",
  WAITING_ON_ADVISOR: "Waiting on Advisor",
  WAITING_ON_CUSTODIAN: "Waiting on Custodian",
  // NOTE: map old AWAITING_RESPONSE status to new "Waiting on Client" label for beta users
  AWAITING_RESPONSE: "Waiting on Client",
  CANCELED: "Canceled",
};

const taskStatusLabelTuples_Beta = Object.entries(
  taskStatusLabelObject_Beta
).map(([key, value]) => [key as TaskStatus, value] as const);

export const mapTaskStatusToLabel = (
  taskStatus: TaskStatus,
  enableNewTaskStatus: boolean
): string => {
  const tuples = enableNewTaskStatus
    ? taskStatusLabelTuples_Beta
    : taskStatusLabelTuples_NonBeta;
  const tuple = tuples.find(
    ([thisStatus, _label]) => thisStatus === taskStatus
  );

  if (tuple === undefined) {
    throw new Error(`Tuple did not have ${taskStatus}`);
  }

  return tuple[1];
};

export const mapLabelToTaskStatus = (
  taskLabel: string,
  enableNewTaskStatus: boolean
): TaskStatus => {
  const tuples = enableNewTaskStatus
    ? taskStatusLabelTuples_Beta
    : taskStatusLabelTuples_NonBeta;
  const tuple = tuples.find(([_thisStatus, label]) => label === taskLabel);

  if (tuple === undefined) {
    throw new Error(`Tuple did not have ${taskLabel}`);
  }

  return tuple[0];
};

const taskTypeLabelObject: Record<TaskType, string> = {
  ACCOUNT_OPENING: "Account Opening",
  ACCOUNT_MAINTENANCE: "Account Maintenance",
  ACCOUNT_TRANSFER: "Account Transfer (ACAT)",
  ALTERNATIVE_INVESTMENT: "Alternative/SMA Investment",
  TRADE_REQUEST: "Trade Request",
  MONEY_MOVEMENT: "Money Movement",
  STANDING_INSTRUCTIONS: "Standing Instructions",
  LIQUIDATION: "Raise Cash",
  OTHER: "Other",
};

const taskTypeLabelTuples = Object.entries(taskTypeLabelObject).map(
  ([key, value]) => [key as TaskType, value] as const
);

export const mapTaskTypeToLabel = (taskType: TaskType): string => {
  const tuple = taskTypeLabelTuples.find(
    ([thisType, _label]) => thisType === taskType
  );
  if (tuple === undefined) {
    throw new Error(`Tuple did not have ${taskType}`);
  }

  return tuple[1];
};

export const mapLabelToTaskType = (taskLabel: string): TaskType => {
  const tuple = taskTypeLabelTuples.find(
    ([_thisType, label]) => label === taskLabel
  );
  if (tuple === undefined) {
    throw new Error(`Tuple did not have ${taskLabel}`);
  }

  return tuple[0];
};

const taskPriorityLabelTuples: [TaskPriority, string][] = [
  ["LOW", "Low"],
  ["MEDIUM", "Medium"],
  ["HIGH", "High"],
];

export const mapTaskPriorityToLabel = (taskPriority: TaskPriority): string => {
  const tuple = taskPriorityLabelTuples.find(
    ([thisType, _label]) => thisType === taskPriority
  );
  if (tuple === undefined) {
    throw new Error(`Tuple did not have ${taskPriority}`);
  }

  return tuple[1];
};

export const mapLabelToTaskPriority = (taskLabel: string): TaskPriority => {
  const tuple = taskPriorityLabelTuples.find(
    ([_thisType, label]) => label === taskLabel
  );
  if (tuple === undefined) {
    throw new Error(`Tuple did not have ${taskLabel}`);
  }

  return tuple[0];
};

export const createTableColumns = (
  sort: GetTasksQueryParams["sort"]
): Column<TaskTableColumns>[] => {
  const { sortBy, sortOrder } = extractSortParts(sort);

  return [
    { label: "Title", accessor: "title", sortable: false },
    { label: "Client", accessor: "client", sortable: false },
    { label: "Advisor", accessor: "advisor", sortable: false },
    { label: "Assignee", accessor: "assignee", sortable: false },
    {
      label: "Status",
      accessor: "taskStatus",
      sortable: true,
      active: sortBy === "taskStatus",
      sortOrder: mapToTableSortOrder(sortOrder),
    },
    {
      label: "Type",
      accessor: "taskType",
      sortable: true,
      active: sortBy === "taskType",
      sortOrder: mapToTableSortOrder(sortOrder),
    },
    {
      label: "Due",
      accessor: "dueDate",
      sortable: true,
      active: sortBy === "dueDate",
      sortOrder: mapToTableSortOrder(sortOrder),
    },
    {
      label: "Updated",
      accessor: "updatedTs",
      sortable: true,
      active: sortBy === "updatedTs",
      sortOrder: mapToTableSortOrder(sortOrder),
    },
    // NOTE: action column
    { label: "", accessor: "action", sortable: false },
  ];
};
