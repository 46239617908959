import React from "react";

import useSMAAssetList from "@fartherfinance/frontend/api/AltAssetsService/hooks/useSMAAssetList";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import Select from "@src/sharedComponents/Select/Select";
import SelectItem from "@src/sharedComponents/Select/SelectItem";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

interface Props {
  smaId: string;
  onChange: (smaId: string) => void;
}

const SMADropdown = (props: Props): JSX.Element => {
  const auth = useAdvisorRequestAuth();

  const smaAssets = useSMAAssetList(auth);

  if (smaAssets.isLoading) {
    return <Skeleton width={180} height={33} />;
  }

  if (smaAssets.hasError) {
    return <></>;
  }

  return (
    <Select
      style={{
        width: 180,
      }}
      value={props.smaId}
      onChange={props.onChange}
      options={smaAssets.data}
      renderValue={(smaId) => {
        const smaById = smaAssets.data.find((sma) => sma.assetId === smaId);

        if (smaById === undefined) {
          return "Unknown";
        }

        return `${smaById.firmName} - ${smaById.shortDescription}`;
      }}
      renderOption={(sma) => (
        <SelectItem
          key={sma.assetId}
          label={`${sma.firmName} - ${sma.shortDescription}`}
          value={sma.assetId}
        />
      )}
    />
  );
};

export default SMADropdown;
