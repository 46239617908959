import React from "react";

import { useElementSize } from "usehooks-ts";

import usePortfolioAnalysisV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/usePortfolioAnalysisV2";
import { PortfolioId } from "@fartherfinance/frontend/api/Types";

import PieChart from "../../PieChart/PieChart";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

import styles from "../ModelAnalysis.module.css";

interface Props {
  portfolioId: PortfolioId | null;
}

const Breakdown = (props: Props) => {
  const [chartRef, { width, height }] = useElementSize();

  const auth = useRequestAuth();

  const analysis = usePortfolioAnalysisV2(props.portfolioId, auth);

  return (
    <div ref={chartRef} className={styles.breakdownContainer}>
      <div
        style={{
          width: Math.min(width, height),
          height: Math.min(width, height),
        }}
      >
        <PieChart
          Cash={analysis.data?.portfolioCategoryBreakdown?.Cash ?? null}
          Equity={analysis.data?.portfolioCategoryBreakdown?.Equity ?? null}
          FixedIncome={
            analysis.data?.portfolioCategoryBreakdown?.["Fixed Income"] ?? null
          }
        />
      </div>
    </div>
  );
};

export default Breakdown;
