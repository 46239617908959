import React, { useMemo, useState } from "react";

import LinkIcon from "@mui/icons-material/Link";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import useFartherEmployees from "@fartherfinance/frontend/api/Entity/hooks/useFartherEmployees";
import { Sleeve } from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";

import useIsAdmin from "@src/multiCustodian/components/Advisor/utils/useIsAdmin";
import { toSortable } from "@src/multiCustodian/components/Client/Accounts/Holdings/Components/BreakdownTable/HoldingsTable";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import Chip from "@src/sharedComponents/Chip/Chip";
import FullDataTable from "@src/sharedComponents/Table/FullDataTable";
import { Cell, Row } from "@src/sharedComponents/Table/Types";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";
import { State } from "@src/store";

import Header, { FilterOption } from "./Header";

export const rowsPerPage = 10;

export const tableKeys = [
  "Name",
  "Type",
  "Securities",
  "Active Portfolios",
  "Updated",
] as const;

export type TableKey = typeof tableKeys[number];
interface Props {
  sleeves: Sleeve[];
}

const SleevesTable = (props: Props): JSX.Element => {
  const { search: urlQueryString } = useLocation();
  const paramsObj = useMemo(
    () => new URLSearchParams(urlQueryString),
    [urlQueryString]
  );

  const updateUrlQueryString = (newSearchString: string) => {
    const location = history.location;
    const params = new URLSearchParams(location.search);

    params.set("search", newSearchString);
    if (newSearchString === "") {
      params.delete("search");
    }

    history.replace({ ...location, search: params.toString() });
  };

  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));

  const { state } = useLocation<
    { sleevesActiveVsArchivedFilter: FilterOption | undefined } | undefined
  >();
  const filterFromUrl = state?.sleevesActiveVsArchivedFilter;

  const [filter, setFilter] = useState<FilterOption>(
    filterFromUrl ?? "Active Sleeves"
  );

  const [tableSearch, setTableSearch] = useState<string>(
    paramsObj.get("search") ?? ""
  );

  const history = useHistory();

  const auth = useAdvisorRequestAuth();
  const employees = useFartherEmployees("All", auth);

  const filtered: Sleeve[] = useMemo(() => {
    const activeOrInactive = props.sleeves.filter((p) => {
      switch (filter) {
        case "Active Sleeves":
          return p.isActive === true;

        case "Archived Sleeves":
          return p.isActive === false;

        default:
          const _x: never = filter;
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          throw new Error(`[Sleeves]: Unknown filter: ${_x}`);
      }
    });

    return activeOrInactive.filter((m) =>
      m.displayName.toLowerCase().includes(tableSearch.toLowerCase())
    );
  }, [filter, props.sleeves, tableSearch]);

  const isAdmin = useIsAdmin(advisorId);

  const sleeves: Row<TableKey, Cell>[] = useMemo(() => {
    return filtered.map((s) => {
      const isSharedSleeve = isAdmin.data === false && s.ownerId !== advisorId;

      let sleeveOwner = null;
      if (employees.data) {
        const employee = employees.data.advisors.find(
          (e) => e.advisorId === s.ownerId
        );
        if (employee !== undefined) {
          sleeveOwner = `${employee.name.first} ${employee.name.last}`;
        }
      }

      return {
        key: s.sleeveId,
        Name: { value: s.displayName, fullValue: s.displayName.toLowerCase() },
        Type: {
          value: isSharedSleeve ? (
            <div>
              <Tooltip
                tooltipText={`This sleeve has been shared with you${
                  sleeveOwner ? ` by ${sleeveOwner}.` : "."
                }`}
                placement={"top"}
              >
                <div>
                  <Chip
                    variant="outlined"
                    icon={
                      <LinkIcon
                        style={{
                          width: "14px",
                          height: "14px",
                          color: "var(--icon)",
                          marginLeft: "10px",
                        }}
                      />
                    }
                    label={"Sleeve"}
                  />
                </div>
              </Tooltip>
            </div>
          ) : (
            <Chip variant="outlined" label={s.portfolioType} />
          ),
          fullValue: s.portfolioType,
        },
        Securities: {
          value: s.numSecurities,
          fullValue: toSortable(s.numSecurities),
        },
        "Active Portfolios": {
          value: s.portfoliosApplied.length,
          fullValue: toSortable(s.portfoliosApplied.length),
        },
        Updated: { value: format(s.createTime, "MM/dd/yy") },
        onClick: () =>
          history.push({
            ...history.location,
            pathname: `/Advisor/Investments/Sleeves/${s.sleeveId}/SleeveDetails`,
            state: {
              sleevesActiveVsArchivedFilter: filter,
            },
          }),
      };
    });
  }, [filtered, history, filter, advisorId, isAdmin.data, employees.data]);

  return (
    <>
      <Header
        filter={filter}
        setFilter={setFilter}
        tableSearch={tableSearch}
        setTableSearch={(searchString: string) => {
          setTableSearch(searchString);
          updateUrlQueryString(searchString);
        }}
      />

      <FullDataTable
        columns={tableKeys}
        rows={sleeves}
        defaultRowsPerPage={rowsPerPage}
        defaultSortColumn={["Active Portfolios", "desc"]}
        disableColumnSorting={sleeves.length > 0 ? undefined : tableKeys}
        emptyCell={<Chip label="Placeholder" />} // This is the highest cell we will have
      />
    </>
  );
};

export default SleevesTable;
