import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import { orderBy } from "lodash";
import { useParams } from "react-router-dom";

import useAdvisorClients from "@fartherfinance/frontend/api/Entity/hooks/useAdvisorClients";
import useGetFartherEmployees from "@fartherfinance/frontend/api/Entity/hooks/useFartherEmployees";
import useUpdateClient from "@fartherfinance/frontend/api/Entity/hooks/useUpdateClient";
import { AdvisorId, ClientId } from "@fartherfinance/frontend/api/Types";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import Select from "@src/sharedComponents/Select/Select";
import SelectItem from "@src/sharedComponents/Select/SelectItem";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import TextInputReffed from "@src/sharedComponents/TextInput/TextInput";

import ClientDrawer from "./ClientDrawer";

import styles from "./ClientActions.module.css";

const ClientAction = (props: PropsWithChildren<unknown>): JSX.Element => {
  const { clientId } = useParams<{ clientId: ClientId }>();

  const [clientEmail, setClientEmail] = React.useState<string | null>(null);
  const [clientAdvisor, setClientAdvisor] = React.useState<AdvisorId | null>(
    null
  );

  const auth = useAdvisorRequestAuth();

  const clients = useAdvisorClients(undefined, auth);
  const client = useMemo(() => {
    if (clients.data !== undefined) {
      return clients.data.clients.find((c) => c.clientId === clientId);
    }
  }, [clientId, clients.data]);

  const advisors = useGetFartherEmployees("All", auth);

  // When the data is loaded from the server set the initial value of the state
  useEffect(() => {
    setClientEmail(client?.emailAddress ?? null);
  }, [client?.emailAddress]);

  // When the data is loaded from the server set the initial value of the state
  useEffect(() => {
    setClientAdvisor(client?.advisorId ?? null);
  }, [client?.advisorId]);

  const sortedAdvisors = useMemo(() => {
    return orderBy(
      advisors.data?.advisors ?? [],
      ["name.last", "name.first"],
      ["asc", "asc"]
    );
  }, [advisors.data?.advisors]);

  const updateClient = useUpdateClient(clientId, auth);

  const notify = useStatusNotification();

  const sendNewEmail = useCallback(async () => {
    if (clientEmail === null) {
      notify("Please enter an email", "Error");
    } else {
      try {
        await updateClient({
          emailAddress: clientEmail,
        });

        notify("Email updated", "Success");
      } catch (_err) {
        notify("Error updating email", "Error");
      }
    }
  }, [clientEmail, notify, updateClient]);

  const sendNewAdvisor = useCallback(async () => {
    if (clientAdvisor === null) {
      notify("Please select an advisor", "Error");
    } else {
      try {
        await updateClient({ advisorId: clientAdvisor });

        notify("Advisor updated", "Success");
      } catch (_err) {
        notify("Error updating advisor", "Error");
      }
    }
  }, [clientAdvisor, notify, updateClient]);

  if (advisors.hasError || clients.hasError) {
    return (
      <ClientDrawer curTab={"Admin"}>
        <div className={styles.error}>
          Error getting clients or client dashboard
        </div>
      </ClientDrawer>
    );
  }

  if (advisors.isLoading || clients.isLoading) {
    return (
      <ClientDrawer curTab={"Admin"}>
        <Spacer />

        <div className={styles.clientAccountsTableContainer}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              <Skeleton width={120} />
            </div>

            <div className={styles.headerRight}>
              <Skeleton width={90} />
            </div>
          </div>
        </div>

        {props.children}
      </ClientDrawer>
    );
  }

  return (
    <ClientDrawer curTab={"Actions"}>
      <Spacer />

      <div className={styles.clientAccountsTableContainer}>
        <div className={styles.header}>
          <div className={styles.headerLeft}>Change Client's Email</div>
        </div>
        <div className={styles.flexMargin}>
          <div style={{ width: "33%" }}>
            <TextInputReffed
              placeholder={client?.emailAddress ?? "client@example.com"}
              value={clientEmail ?? undefined}
              onChange={(evt) => {
                setClientEmail(evt.target.value);
              }}
            />
          </div>

          <Button
            text="Change Email"
            onClick={sendNewEmail}
            buttonType="primary"
            variant="outlined"
          />
        </div>
      </div>

      <Spacer />

      <div className={styles.clientAccountsTableContainer}>
        <div className={styles.header}>
          <div className={styles.headerLeft}>Change Client's Advisor</div>
        </div>

        <div className={styles.flexMargin}>
          <Select
            value={clientAdvisor}
            options={sortedAdvisors}
            renderValue={(val) => {
              const advisorInfo = advisors.data?.advisors.find(
                (a) => a.advisorId === val
              );
              if (advisorInfo) {
                return `${advisorInfo.name.first} ${advisorInfo.name.last} -- ${advisorInfo.emailAddress}`;
              }

              return undefined;
            }}
            renderOption={(val) => (
              <SelectItem
                key={val.advisorId}
                label={`${val.name.first} ${val.name.last} -- ${val.emailAddress}`}
                value={val.advisorId}
              />
            )}
            onChange={(val) => {
              setClientAdvisor(val);
            }}
          />

          <Button
            text="Change Advisor"
            onClick={sendNewAdvisor}
            buttonType="primary"
            variant="outlined"
          />
        </div>
      </div>

      {props.children}
    </ClientDrawer>
  );
};

export default ClientAction;
