import React, { useCallback, useMemo } from "react";

import { useParams } from "react-router-dom";

import useAllAccounts from "@fartherfinance/frontend/api/Accounts/hooks/useAllAccounts";
import {
  ExternalAccountSharedResource,
  FartherAccountSharedResource,
  ManualAccountSharedResource,
  SharedResource,
} from "@fartherfinance/frontend/api/Sharing/Types";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import Checkbox from "@src/sharedComponents/Checkbox/Checkbox";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

import CheckboxSection from "./CheckboxSection/CheckboxSection";

import styles from "./SelectResourcesToShare.module.css";

interface Props {
  resourcesSelected: number;
  handleToggleSelectAll: (
    bool: boolean,
    allResources: SharedResource[]
  ) => void;
}

const SelectResourcesToShareForm: React.FC<Props> = ({
  resourcesSelected,
  handleToggleSelectAll,
}) => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const auth = useRequestAuth();
  const accounts = useAllAccounts(clientId, auth);

  const { fartherManagedAccounts, externalAccounts, manuallyTrackedAccounts } =
    useMemo(() => {
      if (accounts.isLoading || accounts.hasError) {
        return {
          fartherManagedAccounts: [],
          externalAccounts: [],
          manuallyTrackedAccounts: [],
        };
      }

      const nonSharedFartherManaged = accounts.data.fartherAccounts.filter(
        (a) => a.sharedBy?.length === 0
      );

      const fartherManagedAccounts = nonSharedFartherManaged.map((fa) => {
        const resource = {
          resourceId: fa.virtualAccountId,
          resourceType: "FartherAccount",
        } as FartherAccountSharedResource;

        return {
          resource,
          labelTop: fa.accountDetails.displayName,
          labelBottom: `${fa.accountDetails.custodian} - ${
            fa.accountDetails.accountType
          } (${fa.accountDetails.custodianAccountNumber ?? ""})`,
        };
      });

      const nonSharedExternal = accounts.data.externalAccounts.filter(
        (ea) => ea.clientId === clientId
      );

      const externalAccounts = nonSharedExternal.map((ea) => {
        const resource = {
          resourceId: ea.accountId,
          resourceType: "ExternalAccount",
        } as ExternalAccountSharedResource;

        if (ea.accountProvider === "manual") {
          return {
            resource,
            labelTop: ea.details.account.accountTitle,
            labelBottom: `${ea.details.bank.name} - ${
              ea.details.account.accountType
            } (...${ea.details.account.accountNumber.slice(-4)})`,
          };
        }

        return {
          resource,
          labelTop: ea.details.account.name,
          labelBottom: `${
            ea.details.account.officialName
              ? `${ea.details.account.officialName} - ${ea.details.account.accountType}`
              : ea.details.account.accountType
          } ${
            ea.details.account.accountNumberMask
              ? `(...${ea.details.account.accountNumberMask})`
              : ""
          }`,
        };
      });

      const nonSharedManuallyTracked =
        accounts.data.manuallyTrackedAccounts.filter(
          (ma) => ma.personId === clientId
        );

      const manuallyTrackedAccounts = nonSharedManuallyTracked.map((mt) => {
        const resource = {
          resourceId: mt.id,
          resourceType: "ManualAccount",
        } as ManualAccountSharedResource;

        return {
          resource,
          labelTop: mt.assetName,
          labelBottom: mt.assetType,
        };
      });

      return {
        fartherManagedAccounts,
        externalAccounts,
        manuallyTrackedAccounts,
      };
    }, [accounts, clientId]);

  const onToggleSelectAll = useCallback(
    (bool: boolean) => {
      handleToggleSelectAll(bool, [
        ...fartherManagedAccounts.map((fa) => ({
          resourceId: fa.resource.resourceId,
          resourceType: fa.resource.resourceType,
        })),
        ...externalAccounts.map((ea) => ({
          resourceId: ea.resource.resourceId,
          resourceType: ea.resource.resourceType,
        })),
        ...manuallyTrackedAccounts.map((mt) => ({
          resourceId: mt.resource.resourceId,
          resourceType: mt.resource.resourceType,
        })),
      ]);
    },
    [
      handleToggleSelectAll,
      fartherManagedAccounts,
      externalAccounts,
      manuallyTrackedAccounts,
    ]
  );

  if (accounts.hasError) {
    return <div>Error retrieving accounts</div>;
  }

  if (accounts.isLoading) {
    return (
      <div className={styles.formContainer}>
        <div className={styles.loadingContainer}>
          <LogoLoadingStill />
        </div>
      </div>
    );
  }

  const numAllResources =
    fartherManagedAccounts.length +
    externalAccounts.length +
    manuallyTrackedAccounts.length;

  return (
    <div className={styles.formContainer}>
      <div className={styles.marginLeft}>
        <Checkbox
          checked={resourcesSelected === numAllResources}
          onChange={onToggleSelectAll}
          label={"Select All"}
          checkboxStyle={{ marginTop: "2px", marginBottom: "2px" }}
        />
      </div>

      {fartherManagedAccounts.length > 0 && (
        <CheckboxSection
          header={"Farther Managed"}
          items={fartherManagedAccounts}
        />
      )}

      {externalAccounts.length > 0 && (
        <CheckboxSection
          header={"External Investments"}
          items={externalAccounts}
        />
      )}

      {manuallyTrackedAccounts.length > 0 && (
        <CheckboxSection
          header={"External Manually-Tracked"}
          items={manuallyTrackedAccounts}
        />
      )}
    </div>
  );
};

export default SelectResourcesToShareForm;
