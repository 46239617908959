import React, { useEffect } from "react";

import { Redirect, useParams } from "react-router-dom";

import { TaskId } from "@fartherfinance/frontend/api/Types";

import { trackEvent } from "@src/multiCustodian/services/tracking";

import TaskDetails from "./TaskDetails";

interface TaskDetailsRouteParams {
  taskId: TaskId;
}

export default function TaskDetailsGuard(): JSX.Element {
  const { taskId } = useParams<TaskDetailsRouteParams>();
  const parsedTaskId = TaskId.safeParse(taskId);

  useEffect(() => {
    trackEvent({ name: "Advisor Open Tasks Specific" });
  }, []);

  if (parsedTaskId.success) {
    return <TaskDetails taskId={parsedTaskId.data} />;
  }

  return <Redirect to="/Advisor/Tasks" />;
}
