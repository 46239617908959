import React from "react";

import { useWatch } from "react-hook-form";

import {
  OpportunityCompletionReason,
  OpportunityType,
} from "@fartherfinance/frontend/api/Opportunities/Types";

import { isMarketplaceOfferingOpportunityType } from "../../OpportunityDetails.utils";
import {
  mapLabelToValue,
  opportunityCompletionReasonMap,
} from "@src/multiCustodian/pages/Advisor/Opportunities/utils";

import OpportunityOfferingCompletedForm from "./OpportunityOfferingCompletedForm";
import OpportunityStandardCompletedForm from "./OpportunityStandardCompletedForm";

interface OpportunityCompletedFormProps {
  opportunityType: OpportunityType;
}

export default function OpportunityCompletedForm({
  opportunityType,
}: OpportunityCompletedFormProps): JSX.Element {
  const [assetWon, revenueWon, assetLostReason] = useWatch({
    name: ["assetWon", "revenueWon", "completeReason"],
  });

  const isAssetWon = assetWon === "Yes";
  const isAssetLost = assetWon === "No";
  const isRevenueWon = revenueWon === "Yes";
  const isOtherReasonSelected =
    assetLostReason &&
    mapLabelToValue(opportunityCompletionReasonMap, assetLostReason) ===
      OpportunityCompletionReason.enum.OtherReason;

  return isMarketplaceOfferingOpportunityType(opportunityType) ? (
    <OpportunityOfferingCompletedForm
      isAssetWon={isAssetWon}
      isRevenueWon={isRevenueWon}
    />
  ) : (
    <OpportunityStandardCompletedForm
      isAssetWon={isAssetWon}
      isAssetLost={isAssetLost}
      isOtherReasonSelected={isOtherReasonSelected}
    />
  );
}
