import React from "react";

import { CacheProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { TssCacheProvider } from "tss-react";
/* eslint-disable-next-line */
import createCache from "@emotion/cache";
import { ThemeProvider } from "./theme";

const muiCache = createCache({
  key: "mui",
  prepend: true,
});
const tssCache = createCache({
  key: "tss",
});

export const Providers: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <CacheProvider value={muiCache}>
      <TssCacheProvider value={tssCache}>
        <ThemeProvider>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </TssCacheProvider>
    </CacheProvider>
  );
};
