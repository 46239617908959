const format10DigitPhone = (phone: string): string =>
  `(${phone.slice(0, 3)}) ${phone.slice(3, 6)} ${phone.slice(6, 10)}`;

const format7DigitPhone = (phone: string): string =>
  `${phone.slice(0, 3)} ${phone.slice(3, 7)}`;

export default function formatPhoneNumber(phone: string): string {
  return phone.length === 10
    ? format10DigitPhone(phone)
    : phone.length === 7
    ? format7DigitPhone(phone)
    : phone;
}
