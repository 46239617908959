import React from "react";

import { useParams } from "react-router-dom";

import { SleeveId } from "@fartherfinance/frontend/api/Types";

import Sleeves from "../../../Sleeves";
import ReplaceSleeveModal from "@src/multiCustodian/components/Advisor/Investments/Sleeves/ReplaceSleeveModal/ReplaceSleeveModal";
import SleeveSharingTable from "@src/multiCustodian/components/Advisor/Investments/Sleeves/SharingTable/SleeveSharingTable";
import SleeveDetailsTable from "@src/multiCustodian/components/Sleeves/SleeveDetailsTable/SleeveDetailsTable";
import SleeveDrawer from "@src/multiCustodian/components/Sleeves/SleeveDrawer/SleeveDrawer";
import SleeveHistoricPerformanceTable from "@src/multiCustodian/components/Sleeves/SleeveHistoricPerformanceTable/SleeveHistoricPerformanceTable";
import SleeveAnalysisAssetClassCategoryTable from "@src/multiCustodian/components/Sleeves/SleeveHoldingsTable/SleeveHoldingsTable";
import SleeveUsageTable from "@src/multiCustodian/components/Sleeves/SleeveUsageTable/SleeveUsageTable";

export default function ReplaceSleeve(): JSX.Element {
  const { sleeveId, sleeveDrawerTab } = useParams<{
    sleeveId: SleeveId;
    sleeveDrawerTab: "Sharing" | "SleeveUsage" | "SleeveDetails";
  }>();

  const curTab =
    sleeveDrawerTab === "SleeveDetails"
      ? "Sleeve Details"
      : sleeveDrawerTab === "SleeveUsage"
      ? "Sleeve Usage"
      : "Sharing";

  return (
    <Sleeves>
      <SleeveDrawer curTab={curTab} sleeveId={sleeveId}>
        {sleeveDrawerTab === "SleeveDetails" ? (
          <>
            <SleeveDetailsTable sleeveId={sleeveId} />

            <SleeveHistoricPerformanceTable sleeveId={sleeveId} />

            <SleeveAnalysisAssetClassCategoryTable sleeveId={sleeveId} />
          </>
        ) : sleeveDrawerTab === "SleeveUsage" ? (
          <SleeveUsageTable sleeveId={sleeveId} />
        ) : (
          <SleeveSharingTable sleeveId={sleeveId} />
        )}
        <ReplaceSleeveModal />
      </SleeveDrawer>
    </Sleeves>
  );
}
