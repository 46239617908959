import React, { useEffect, useState } from "react";

import useValidateCustomPortfolioV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useValidateCustomPortfolioV2";
import {
  SecurityWeight,
  SleeveWeight,
} from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";
import {
  AdvisorId,
  ClientId,
  PortfolioId,
  PortfolioTaxType,
} from "@fartherfinance/frontend/api/Types";

import CSVFormModal from "../../SharedComponents/CSVFormModal/CSVFormModal";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";

import CreateUpdateCustomModelForm from "./CreateUpdateCustomModelForm";

interface Props {
  isUpdate: boolean;
  currentSecurities?: SecurityWeight[];
  currentSleeves?: SleeveWeight[];
  portfolioId?: PortfolioId;
  modelName?: string;
  taxType?: PortfolioTaxType;
  ownerId?: AdvisorId | ClientId | null;
}

const CreateNewCustomModel = (props: Props): JSX.Element => {
  const auth = useAdvisorRequestAuth();

  const [mutating, setMutating] = useState(false);

  const [csvSecurities, setCsvSecurities] = useState<SecurityWeight[] | null>(
    null
  );

  const [csvSleeves, setCsvSleeves] = useState<SleeveWeight[] | null>(null);

  const [csvModelName, setCsvModelName] = useState<string | undefined>(
    undefined
  );

  const [csvTaxType, setCsvTaxType] = useState<PortfolioTaxType | undefined>(
    undefined
  );

  const [cameFromUpload, setCameFromUpload] = useState<boolean>(false);

  const [pageState, setPageState] = useState<"CSV_UPLOAD" | "MODEL_EDIT">(
    "MODEL_EDIT"
  );

  const [csvFile, setCSVFile] = useState<File | null>(null);

  const [csvErrors, setCSVErrors] = useState<string[] | null>(null);

  const statusNotification = useStatusNotification();

  const validateCustomCSV = useValidateCustomPortfolioV2(auth);

  const reset = () => {
    setCSVFile(null);
    setCSVErrors(null);
    setCsvSecurities(null);
    setCsvSleeves(null);
    setCsvModelName(undefined);
    setCsvTaxType(undefined);
    setCameFromUpload(false);
  };

  useEffect(() => {
    if (
      pageState === "CSV_UPLOAD" &&
      csvSecurities !== null &&
      csvSleeves !== null &&
      csvModelName !== undefined &&
      csvTaxType !== undefined &&
      cameFromUpload === true
    ) {
      setPageState("MODEL_EDIT"); //Ensure csv data is saved in state before opening the model edit form, otherwise the default values will not be mapped correctly onto the form
    }
  }, [
    csvSecurities,
    csvSleeves,
    pageState,
    csvModelName,
    csvTaxType,
    cameFromUpload,
  ]);

  const addNewFile = (newFile: File | null) => {
    setCSVFile(newFile);

    if (csvErrors !== null && newFile !== null) {
      //Only remove an existing error if a new file has been provided
      setCSVErrors(null);
    }
  };

  const uploadCSVFile = async () => {
    if (csvFile === null) {
      statusNotification("Please attach a CSV document", "Error");
      return;
    }

    if (mutating) {
      statusNotification("Uploading your document, please wait", "Error");
      return;
    }

    try {
      setMutating(true);

      const res = await validateCustomCSV(csvFile);

      if (res.status === "valid") {
        if (res.data.portfolios.length <= 0) {
          setCSVErrors(["No custom portfolios were found in the CSV"]);
        } else {
          if (res.data.portfolios.length >= 2) {
            statusNotification(
              "Can only upload 1 custom model at a time - only the first model was uploaded",
              "Error"
            );
          }

          setCsvSecurities(res.data.portfolios[0].positions.securities);
          setCsvSleeves(res.data.portfolios[0].positions.sleeves);
          setCsvModelName(res.data.portfolios[0].displayName);
          setCsvTaxType(res.data.portfolios[0].taxType);
          setCameFromUpload(true);

          statusNotification("CSV validation successful", "Success");
        }
      } else if (res.data.errors) {
        statusNotification("CSV validation failed", "Error");
        setCSVErrors(res.data.errors);
      } else if (res.data.message) {
        statusNotification("CSV validation failed", "Error");
        setCSVErrors([res.data.message]);
      }
    } catch (e) {
      if (e instanceof Error) {
        statusNotification(e.message, "Error");
      }
    } finally {
      setMutating(false);
    }
  };

  if (pageState === "CSV_UPLOAD") {
    return (
      <CSVFormModal
        closeModal={() => setPageState("MODEL_EDIT")}
        formObjective={`${props.isUpdate ? "Update" : "Create"} Custom Model`}
        uploadType={"Custom"}
        csvFile={csvFile}
        addNewFile={addNewFile}
        uploadCSVFile={uploadCSVFile}
        mutating={mutating}
        csvError={csvErrors}
      />
    );
  }

  if (props.isUpdate) {
    if (props.portfolioId === undefined) {
      throw new Error(
        "props.portfolioId is undefined - this should not be undefined"
      );
    }

    if (props.currentSecurities === undefined) {
      throw new Error(
        "props.currentSecurities is undefined - this should not be undefined"
      );
    }

    if (props.currentSleeves === undefined) {
      throw new Error(
        "props.currentSleeves is undefined - this should not be undefined"
      );
    }

    if (props.modelName === undefined) {
      throw new Error(
        "props.modelName is undefined - this should not be undefined"
      );
    }

    if (props.taxType === undefined) {
      throw new Error(
        "props.taxType is undefined - this should not be undefined"
      );
    }

    if (props.ownerId === undefined) {
      throw new Error(
        "props.ownerId is undefined - this should not be undefined"
      );
    }

    return (
      <CreateUpdateCustomModelForm
        isUpdate={props.isUpdate}
        csvFile={csvFile}
        goToCSVUpload={() => {
          reset();
          setPageState("CSV_UPLOAD");
        }}
        cameFromUpload={cameFromUpload}
        portfolioId={props.portfolioId}
        ownerId={props.ownerId}
        currentSecurities={csvSecurities ?? props.currentSecurities}
        currentSleeves={csvSleeves ?? props.currentSleeves}
        modelName={csvModelName ?? props.modelName}
        taxType={csvTaxType ?? props.taxType}
      />
    );
  }

  return (
    <CreateUpdateCustomModelForm
      isUpdate={props.isUpdate}
      csvFile={csvFile}
      goToCSVUpload={() => {
        reset();
        setPageState("CSV_UPLOAD");
      }}
      currentSecurities={csvSecurities}
      currentSleeves={csvSleeves}
      modelName={csvModelName}
      taxType={csvTaxType}
      cameFromUpload={cameFromUpload}
    />
  );
};

export default CreateNewCustomModel;
