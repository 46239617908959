import { useCallback, useMemo, useState } from "react";

import uniqBy from "lodash/uniqBy";

import { sanitizeFilename } from "@fartherfinance/frontend/utils/sanitizeFilename";

interface FilesManagementOptions {
  countLimit?: number;
}

export default function useFilesManagement(options: FilesManagementOptions) {
  const [files, setFiles] = useState<File[]>([]);

  const handleSetFiles = useCallback(
    (incomingFiles: FileList): void => {
      const sanitizedIncomingFiles = Array.from(incomingFiles).map(
        (file) =>
          // NOTE: we need to create new files as file name is readonly property due to security reasons
          new File([file], sanitizeFilename(file.name), { type: file.type })
      );

      setFiles((currentFiles) => {
        const { countLimit } = options;

        if (currentFiles.length < (countLimit ?? Infinity)) {
          return uniqBy(
            currentFiles.concat(sanitizedIncomingFiles).slice(0, countLimit),
            "name"
          );
        }

        return currentFiles;
      });
    },
    [options]
  );

  const handleRemoveFile = (fileName: string): void => {
    setFiles((currentFiles) =>
      currentFiles.filter((file) => file.name !== fileName)
    );
  };

  const handleRemoveAllFiles = (): void => {
    setFiles([]);
  };

  return useMemo(
    () => ({
      files,
      setFiles: handleSetFiles,
      removeFile: handleRemoveFile,
      removeAllFiles: handleRemoveAllFiles,
    }),
    [files, handleSetFiles]
  );
}
