import { getYear } from "date-fns";

import useGetTaxBudgetsForClient from "@fartherfinance/frontend/api/InvestmentModel/hooks/useGetTaxBudgetsForClient";
import { ClientId, HookResult } from "@fartherfinance/frontend/api/Types";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

import { TaxBudgetData } from "./useTaxBudgets";

const useTaxBudgetsForClient = (
  clientId: ClientId
): HookResult<TaxBudgetData> => {
  const advisorAuth = useAdvisorRequestAuth();

  const clientAuth = useRequestAuth();

  const auth = advisorAuth
    ? { ...advisorAuth, clientId: clientId }
    : clientAuth;

  const budgetsResponse = useGetTaxBudgetsForClient({ clientId }, auth);

  if (budgetsResponse.isLoading || budgetsResponse.hasError) {
    return budgetsResponse;
  }

  const budgets = budgetsResponse.data || [];

  const currentBudget = budgets.find(
    (budget) => budget.year === getYear(new Date())
  );

  const futureBudgets = budgets
    .filter((budget) => getYear(new Date()) < budget.year)
    .sort((a, b) => a.year - b.year);

  const hasBudgets = Boolean(currentBudget) || futureBudgets.length > 0;

  const includesSharedAccounts = (currentBudget?.clientIds.length || 0) > 1;

  return {
    data: {
      budgets,
      currentBudget,
      futureBudgets,
      hasBudgets,
      includesSharedAccounts,
    },
    isLoading: false,
    hasError: false,
  };
};

export default useTaxBudgetsForClient;
