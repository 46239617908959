import React from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import { range } from "lodash";

import { MarketplaceOffering } from "@fartherfinance/frontend/api/AltAssetsService/Types";
import { MarketplaceOfferingId } from "@fartherfinance/frontend/api/Types";
import { PaginationHeaders } from "@fartherfinance/frontend/api/utils/parsePaginationHeaders";

import OfferingsListCard from "../OfferingsListCard/OfferingsListCard";
import { MarketplaceOfferingTypeCard } from "../types";
import { findOfferingTypeByValue } from "../utils";
import IconButton from "@src/sharedComponents/IconButton/IconButton";
import Message from "@src/sharedComponents/Message/Message";
import { FartherTablePagination as Pagination } from "@src/sharedComponents/Table/Components";

import styles from "./OfferingsList.module.css";

interface OfferingsListProps {
  offerings: MarketplaceOffering["v1"][];
  offeringTypes: MarketplaceOfferingTypeCard[];
  pagination: PaginationHeaders | undefined;
  isLoading: boolean;
  isErrored: boolean;
  onCardClick: (offeringId: MarketplaceOfferingId) => void;
  onPageChange: (page: number) => void;
  onNavigateBackClick: () => void;
}

const OfferingsList: React.FC<OfferingsListProps> = ({
  offerings,
  offeringTypes,
  pagination,
  isLoading,
  isErrored,
  onCardClick,
  onPageChange,
  onNavigateBackClick,
}) => {
  return (
    <div>
      <div className={styles.header}>
        <IconButton
          size="medium"
          onClick={onNavigateBackClick}
          IconComponent={ArrowBackIcon}
        />
        <Typography className={styles.counter}>
          Partners ({offerings.length})
        </Typography>
      </div>

      {isErrored ? (
        <Message text="Something went wrong, please try again later" />
      ) : (
        <div>
          <div className={styles.listContainer}>
            {isLoading ? (
              <>
                {range(0, 3).map((_, index) => (
                  <OfferingsListCard key={index} isLoading={true} />
                ))}
              </>
            ) : (
              <>
                {offerings.map((offering) => (
                  <OfferingsListCard
                    key={offering.offeringId}
                    isLoading={false}
                    offeringType={
                      findOfferingTypeByValue(
                        offeringTypes,
                        offering.offeringType
                      )?.name ?? "Offering"
                    }
                    partnerName={offering.partnerName}
                    partnerDescription={offering.about}
                    howItWorks={offering.howItWorks}
                    isFeatured={offering.isFeatured}
                    onClick={() => onCardClick(offering.offeringId)}
                  />
                ))}
              </>
            )}
          </div>

          {pagination && (
            <Pagination
              className={styles.pagination}
              pageSize={pagination.pageSize}
              currentPage={pagination.currentPage - 1}
              totalCount={pagination.totalCount}
              onPageChange={(page) => onPageChange(page + 1)}
              isPrevButtonDisabled={pagination.prevPage === undefined}
              isNextButtonDisabled={pagination.nextPage === undefined}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default OfferingsList;
