import React, { useCallback, useMemo, useState } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { uniq } from "lodash";

import {
  ClientId,
  FartherAccountId,
  PortfolioId,
} from "@fartherfinance/frontend/api/Types";
import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import Button from "../../../MUI/Button/Button";
import { SHARED_ACCOUNT_TOOLTIP_TEXT } from "../const";
import AccountListDetails from "../utils/AccountListDetails";
import {
  AccountWithPortfolio,
  useAvailableAccountFromTradingGroups,
} from "../utils/useAvailableAccountFromTradingGroups";
import Checkbox from "@src/sharedComponents/Checkbox/Checkbox";
import RadioButtons from "@src/sharedComponents/Radio/RadioButtons";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import ApplyToAccountsModal from "./modals/ApplyToAccountsModal";
import isAccountDisabled from "./utils/isAccountDisabled";

import styles from "../AccountsListSelection/AccountsListSelection.module.css";

const OPTION_DIV_HEIGHT_IN_PX = 40;

type RadioBtnValue =
  | "Tax-aware coordination across accounts (asset location)"
  | "Invest the accounts individually";

interface Props {
  clientId: ClientId;
  portfolioId: PortfolioId;
  onCreate: (accountIds: FartherAccountId[]) => void;
  onCreateMultipleIndividually: (accountIds: FartherAccountId[]) => void;
  maxItemsBeforeScroll?: number; // default is 5 -> 200px
}

const CreateTradingGroupTable = (props: Props): JSX.Element => {
  const [showApplyToAccountsModal, setShowApplyToAccountsModal] =
    useState<boolean>(false);

  const {
    color: { $text },
  } = useTheme();

  const [radioBtnValue, setRadioBtnValue] = useState<RadioBtnValue | null>(
    null
  );

  const [selectedAccounts, setSelectedAccounts] = useState<FartherAccountId[]>(
    []
  );

  const rawOrderedRows = useAvailableAccountFromTradingGroups(
    props.clientId,
    props.portfolioId
  );

  const orderedRows = useMemo(
    () =>
      (rawOrderedRows.data ?? [])
        .filter((a) => a.assignedTo !== "ThisPortfolio")
        .map(
          (row): AccountWithPortfolio => ({
            ...row,
            isChecked: selectedAccounts.some((a) => a === row.accountId),
          })
        ),
    [rawOrderedRows.data, selectedAccounts]
  );

  const updateAccountSelected = useCallback(
    (anId: FartherAccountId, value: boolean) => {
      let newList = selectedAccounts;
      if (value) {
        newList = uniq([...selectedAccounts, anId]);
      } else {
        newList = selectedAccounts.filter((a) => a !== anId);
      }

      if (newList.length === 0) {
        // reset radio btn value to 1st option
        setRadioBtnValue(null);
      } else if (newList.length >= 1 && radioBtnValue === null) {
        setRadioBtnValue(
          "Tax-aware coordination across accounts (asset location)"
        );
      }
      setSelectedAccounts(newList);
    },
    [radioBtnValue, selectedAccounts]
  );

  const applySelectedAccounts = useCallback(() => {
    if (radioBtnValue === "Invest the accounts individually") {
      props.onCreateMultipleIndividually(selectedAccounts);
    } else {
      props.onCreate(selectedAccounts);
    }
  }, [props, radioBtnValue, selectedAccounts]);

  const availableAccounts = orderedRows.filter(
    (a) => a.assignedTo === "None"
  ).length;

  const availableAccountsText =
    availableAccounts === 0
      ? "No Accounts Available"
      : availableAccounts === 1
      ? "1 Account Available"
      : `${availableAccounts} Accounts Available`;

  if (rawOrderedRows.isLoading) {
    return <div>Loading</div>;
  }

  if (rawOrderedRows.hasError) {
    return <div>Error</div>;
  }

  return (
    <>
      {showApplyToAccountsModal && (
        <ApplyToAccountsModal
          closeModal={() => setShowApplyToAccountsModal(false)}
          onConfirm={() => {
            applySelectedAccounts();
            setShowApplyToAccountsModal(false);
          }}
        />
      )}

      <div className={styles.shell}>
        <div
          className={
            selectedAccounts.length >= 2
              ? styles.containerMultipleSelected
              : styles.container
          }
        >
          <div className={styles.listHeaderDiv}>
            <div className={styles.flexDiv}>
              <p className={styles.headerText1}>Apply to accounts</p>

              <p className={styles.headerText2}>{availableAccountsText}</p>
            </div>

            <Button
              disabled={selectedAccounts.length <= 0}
              buttonType={"primary"}
              variant={"contained"}
              text={"Save"}
              onClick={() => setShowApplyToAccountsModal(true)}
              sx={{ height: "30px" }}
            />
          </div>

          <div
            className={styles.optionsDiv}
            style={{
              maxHeight: props.maxItemsBeforeScroll
                ? `${OPTION_DIV_HEIGHT_IN_PX * props.maxItemsBeforeScroll}px`
                : "200px",
            }}
          >
            {orderedRows.length > 0 ? (
              orderedRows.map((account) => (
                <SelectableAccountRow
                  key={account.accountId}
                  selectedAccounts={orderedRows.filter((acc) =>
                    selectedAccounts.includes(acc.accountId)
                  )}
                  updateAccountSelected={updateAccountSelected}
                  account={account}
                  currentPortfolioId={props.portfolioId}
                />
              ))
            ) : (
              <NoSelectableAccounts />
            )}
          </div>
        </div>
        {selectedAccounts.length >= 2 && (
          <div className={styles.radioButtonsDiv}>
            <RadioButtons
              label={
                "How should we invest your funds across the accounts you've selected?"
              }
              values={[
                "Tax-aware coordination across accounts (asset location)",
                "Invest the accounts individually",
              ]}
              onChange={(newValue: RadioBtnValue) => setRadioBtnValue(newValue)}
              value={radioBtnValue}
              isValid={undefined}
              labelColor={$text}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CreateTradingGroupTable;

interface SelectableAccountRowProps {
  account: AccountWithPortfolio;
  updateAccountSelected: (anId: FartherAccountId, value: boolean) => void;
  selectedAccounts: AccountWithPortfolio[];
  currentPortfolioId: PortfolioId;
}

const SelectableAccountRow = (
  props: SelectableAccountRowProps
): JSX.Element => {
  const disabled = isAccountDisabled(props.account, props.selectedAccounts);

  const isShared = props.account.sharedBy.length > 0;

  return (
    <div className={styles.optionDiv}>
      <div className={styles.checkboxDiv}>
        <Checkbox
          label={
            <div className={styles.checkboxLabel}>
              <div className={styles.checkboxLabelText}>
                {props.account.name}
              </div>

              {disabled.isDisabled && disabled.reason !== null && (
                <Tooltip tooltipText={disabled.reason} placement="top">
                  <InfoOutlinedIcon className={styles.infoIconDisabled} />
                </Tooltip>
              )}
            </div>
          }
          checked={props.account.isChecked ?? false}
          onChange={(v) =>
            props.updateAccountSelected(props.account.accountId, v)
          }
          disabled={disabled.isDisabled || isShared}
        />

        {isShared && (
          <Tooltip tooltipText={SHARED_ACCOUNT_TOOLTIP_TEXT} placement={"top"}>
            <PeopleAltIcon className={styles.sharedIcon} />
          </Tooltip>
        )}
      </div>

      <div className={styles.detailsDiv}>
        <AccountListDetails
          account={props.account}
          currentPortfolioId={props.currentPortfolioId}
        />
      </div>
    </div>
  );
};

const NoSelectableAccounts = () => {
  return (
    <div className={styles.optionDiv}>
      <p className={styles.noAccountsText}>No available accounts</p>
    </div>
  );
};
