import React, { useEffect, useMemo } from "react";

import { FormProvider, useForm, useFormState } from "react-hook-form";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import { portfolioLocationState } from "../../Portfolios";
import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import { trackEvent } from "@src/multiCustodian/services/tracking";
import FormRadioField from "@src/sharedComponents/Forms/FormRadioField";
import FormSubHeader from "@src/sharedComponents/Forms/FormSubHeader";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import Spacer from "@src/sharedComponents/Forms/Spacer";

import BackAndExitButtonWrapper from "./Custom/BackAndExitButtonWrapper";

import styles from "./Custom.module.css";

const taxStatusOptions = ["Any", "Taxable", "Tax-Advantaged"] as const;
export type TaxStatus = typeof taxStatusOptions[number];

interface Form {
  Name: string | undefined;
  TaxStatus: TaxStatus | undefined;
}

const Custom = (): JSX.Element => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const { search: urlSearchParams, state } = useLocation<{
    from: Location | undefined;
  }>();

  useEffect(() => {
    trackEvent({
      name: "Client Open Custom Portfolios",
    });
  }, []);

  const qs = useMemo(
    () => new URLSearchParams(urlSearchParams),
    [urlSearchParams]
  );
  const modelName = qs.get("name");
  let modelTaxStatus = qs.get("status");
  if (
    modelTaxStatus !== "Any" &&
    modelTaxStatus !== "Taxable" &&
    modelTaxStatus !== "Tax-Advantaged"
  ) {
    modelTaxStatus = null;
  }

  const history = useHistory();

  const methods = useForm<Form>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      Name: modelName ?? undefined,
      TaxStatus: modelTaxStatus ?? "Any",
    },
  });

  const { control, getValues } = methods;

  const { isValid } = useFormState({
    control,
  });

  const goToSelectSecuritiesPage = () => {
    if (!isValid) {
      return;
    }

    const values = getValues();

    if (values.Name === undefined || values.TaxStatus === undefined) {
      return;
    }

    const newParams = new URLSearchParams(urlSearchParams);
    newParams.set("name", values.Name);
    newParams.set("status", values.TaxStatus);

    history.push({
      pathname: `/Client/${clientId}/Investments/Portfolios/Create/Custom/Securities`,
      search: newParams.toString(),
    });
  };

  const backButtonOnClick = () =>
    history.replace(`/Client/${clientId}/Investments/Portfolios/Create`);

  const exitButtonOnClick = () =>
    history.push({
      pathname: `/Client/${clientId}/Investments/Portfolios`,
      state: { ...(state ?? {}), ...portfolioLocationState },
    });

  return (
    <BaseLayout showSideNav={false}>
      <BackAndExitButtonWrapper
        backButtonOnClick={backButtonOnClick}
        exitButtonOnClick={exitButtonOnClick}
      >
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <FormProvider {...methods}>
              <p className={styles.heading}>Create a custom portfolio</p>

              <FormSubHeader>
                Enter a name and choose the tax status
              </FormSubHeader>

              <Spacer />
              <FormTextField
                name="Name"
                label="Custom Model Name"
                required="Must not be empty"
              />

              <Spacer />
              <FormRadioField
                name="TaxStatus"
                label="Tax Status"
                values={taxStatusOptions}
              />
            </FormProvider>

            <ButtonPrimary
              text="Continue"
              onClick={goToSelectSecuritiesPage}
              variant="contained"
              buttonType="primary"
              disabled={!isValid}
              style={{ justifyContent: "flex-end" }}
            />
          </div>
        </div>
      </BackAndExitButtonWrapper>
    </BaseLayout>
  );
};

export default Custom;
