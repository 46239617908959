import React, { useEffect, useState } from "react";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useSelector } from "react-redux";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import Dashboard_Caution_Update_Component from "../../../../components/Caution_update_status_div/Status";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import isLocalStorageAvailable from "@src/multiCustodian/utils/isLocalStorageAvailable";
import { State } from "@src/store";

import styles from "./AltInvestmentBanner.module.css";

const WAITLIST_URL = "https://gofarther.typeform.com/Farther";

const altInvestmentBannerClicked = "altInvestmentBannerClicked";

//Each client should have individual control over the banner's visibility
//So we create a client-specific localStorage key
const createLocalStorageKey = (clientId: ClientId | null): string | null => {
  if (clientId === null) {
    return null;
  }

  return `${altInvestmentBannerClicked} ${clientId}`;
};

const AltInvestmentBanner = (): JSX.Element => {
  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));

  const localStorageKey = createLocalStorageKey(clientId);

  const [bannerClicked, setBannerClicked] = useState(
    localStorageKey !== null &&
      isLocalStorageAvailable() &&
      localStorage.getItem(localStorageKey) === "true"
  );

  const handleDismiss = () => {
    setBannerClicked(true);
  };

  const handleJoin = () => {
    setBannerClicked(true);

    window.open(WAITLIST_URL);
  };

  useEffect(() => {
    if (bannerClicked === false || localStorageKey === null) {
      return;
    }

    if (isLocalStorageAvailable()) {
      localStorage.setItem(localStorageKey, "true");
    }
  }, [bannerClicked, localStorageKey]);

  if (bannerClicked === true) {
    return <></>;
  }

  return (
    <div className={styles.container}>
      <Dashboard_Caution_Update_Component msg="Interested in adding alternative investments to your portfolio?">
        <div className={styles.buttonContainer}>
          <ButtonPrimary
            text="Dismiss"
            onClick={handleDismiss}
            variant={"outlined"}
            buttonType={"primary"}
          />

          <ButtonPrimary
            text={
              <div className={styles.joinButtonText}>
                Join Waitlist
                <OpenInNewIcon style={{ height: "14px", width: "14px" }} />
              </div>
            }
            onClick={handleJoin}
            variant={"contained"}
            buttonType={"primary"}
          />
        </div>
      </Dashboard_Caution_Update_Component>
    </div>
  );
};

export default AltInvestmentBanner;
