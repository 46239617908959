import React from "react";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import IsHiddenIcon from "./IsHiddenIcon";
import NavIcon from "./NavIcon";
import { NavItemDetails } from "./Types";

import styles from "./NavItem.module.css";

type Props = Omit<NavItemDetails, "isDisabled" | "path">;

const NavItemDisabled: React.FC<Props> = ({ iconName, text, isHidden }) => {
  return (
    <div className={styles.container}>
      <div className={toClassName(styles.navItem, styles.navItemDisabled)}>
        <div className={styles.flexDiv}>
          {iconName && (
            <div className={styles.icon}>
              <NavIcon iconName={iconName} />
            </div>
          )}

          <div className={styles.text}>{text}</div>
        </div>

        <IsHiddenIcon isHidden={isHidden} />
      </div>
    </div>
  );
};

export default NavItemDisabled;
