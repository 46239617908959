import React from "react";

import { ArrowForward } from "@mui/icons-material";

import Button from "../MUI/Button/Button";

import Layout from "./Layout";

import * as styles from "./StytchLogin.module.css";

interface Props {
  emailAddress: string;
  action: "Create" | "Reset";
  onBack?: () => void;
  onResendPasswordEmailClick: (email: string) => void;
}

const PasswordEmailSent: React.FC<Props> = ({
  emailAddress,
  action,
  onBack,
  onResendPasswordEmailClick,
}) => {
  switch (action) {
    case "Create":
      return (
        <Layout
          onBack={onBack}
          subtitle={`We have sent an email to ${emailAddress} to verify your email address and activate your account. The link in the email will expire in 15 minutes.`}
          title="Check Your Email"
        >
          <Button
            text={
              <>
                Resend Email <ArrowForward className={styles.arrow} />
              </>
            }
            onClick={() => onResendPasswordEmailClick(emailAddress)}
            variant="text"
            buttonType="primary"
            sx={{
              width: "100%",
              textDecoration: "underline",
            }}
          />
        </Layout>
      );

    case "Reset":
      return (
        <Layout
          onBack={onBack}
          subtitle="Check your email, and click the link to reset your password; the code expires in 15 minutes."
          title="Check Your Email"
        >
          <Button
            text={
              <>
                Resend Email <ArrowForward className={styles.arrow} />
              </>
            }
            onClick={() => onResendPasswordEmailClick(emailAddress)}
            variant="text"
            buttonType="primary"
            sx={{
              width: "100%",
              textDecoration: "underline",
            }}
          />
        </Layout>
      );
  }
};

export default PasswordEmailSent;
