import React, { PropsWithChildren } from "react";

import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";

interface Props {
  hideNavbar?: boolean;
}

export default function AdvisorContainer(
  props: PropsWithChildren<Props>
): JSX.Element {
  return (
    <BaseLayout showSideNav={!props.hideNavbar}>{props.children}</BaseLayout>
  );
}
