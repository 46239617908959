import React, { useMemo, useState } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import useSleeveAnalysis from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useSleeveAnalysis";
import { SleeveId } from "@fartherfinance/frontend/api/Types";
import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import PerformanceIndicatorArrow from "@src/sharedComponents/PerformanceIndicatorArrow/PerformanceIndicatorArrow";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import styles from "./SleeveHistoricPerformanceTable.module.css";

const timeFrameOptions = ["1Y", "3Y", "5Y", "10Y"] as const;

type TimeFrame = typeof timeFrameOptions[number];

interface Props {
  sleeveId: SleeveId;
  isInClientPortal?: boolean;
}

const SleeveHistoricPerformanceTable = ({
  sleeveId,
  isInClientPortal,
}: Props): JSX.Element => {
  const [selectedTF, setSelectedTF] = useState<TimeFrame>("1Y");

  const setClassName = (timeFrame: TimeFrame): string => {
    return `${styles.timeframe} ${
      selectedTF === timeFrame ? styles.selected : ""
    }`;
  };

  const clientAuth = useRequestAuth();
  const advisorAuth = useAdvisorRequestAuth();

  const sleeveAnalysis = useSleeveAnalysis(
    sleeveId,
    isInClientPortal ? clientAuth : advisorAuth
  );

  const performance: number | null = useMemo(() => {
    if (sleeveAnalysis.data === undefined) {
      return null;
    }

    switch (selectedTF) {
      case "1Y":
        return sleeveAnalysis.data.trailingReturns.oneYearReturn;
      case "3Y":
        return sleeveAnalysis.data.trailingReturns.threeYearReturn;
      case "5Y":
        return sleeveAnalysis.data.trailingReturns.fiveYearReturn;
      case "10Y":
        return sleeveAnalysis.data.trailingReturns.tenYearReturn;
      default:
        return null;
    }
  }, [sleeveAnalysis, selectedTF]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p className={styles.headerText}>Historical Performance</p>

        <div className={styles.perfDiv}>
          <PerformanceText
            performance={performance}
            sleeveAnalysisIsLoading={sleeveAnalysis.isLoading}
          />
        </div>
      </div>

      <div className={styles.body}>
        <div className={styles.tabs}>
          {timeFrameOptions.map((tf) => (
            <div
              key={tf}
              className={setClassName(tf)}
              onClick={() => setSelectedTF(tf)}
            >
              {tf}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SleeveHistoricPerformanceTable;

interface PerformanceTextProps {
  performance: number | null;
  sleeveAnalysisIsLoading: boolean;
}

const PerformanceText = ({
  performance,
  sleeveAnalysisIsLoading,
}: PerformanceTextProps) => {
  const {
    color: { $iconPerformancePositive, $iconPerformanceNegative },
  } = useTheme();

  if (sleeveAnalysisIsLoading) {
    return <Skeleton width={100} className={styles.loadingFiller} />;
  }

  if (performance === null) {
    return (
      <div className={styles.flexDiv}>
        <p className={styles.noDataText}>No data available</p>
        <Tooltip
          tooltipText="One or more of the accounts in the model portfolio is not old enough to display this time frame."
          placement="top"
        >
          <InfoOutlinedIcon className={styles.infoIcon} />
        </Tooltip>
      </div>
    );
  }

  return (
    <>
      <div className={styles.perfTriangle}>
        <PerformanceIndicatorArrow
          status={
            performance === null
              ? "neutral"
              : performance > 0
              ? "positive"
              : "negative"
          }
        />
      </div>

      <p
        className={styles.perfText}
        style={{
          color:
            performance >= 0
              ? $iconPerformancePositive
              : $iconPerformanceNegative,
        }}
      >
        {`${performance >= 0 ? "+" : ""}${performance}%`}
      </p>
    </>
  );
};
