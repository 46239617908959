import React from "react";

import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { useStyles } from "./Dashboard.styles";

const tabs = [
  {
    label: "Daily Trading",
    path: "/Advisor/Client-Ops/Dashboard/Daily-Trading",
  },
  {
    label: "Accounts",
    path: "/Advisor/Client-Ops/Dashboard/Accounts",
  },
];

export const Dashboard: React.FC = ({ children }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const matchingTabPath =
    tabs.find((tab) => location.pathname.includes(tab.path))?.path ?? "";

  return (
    <>
      <Tabs
        classes={{
          root: classes.tabsRoot,
          indicator: classes.tabsIndicator,
          flexContainer: classes.tabsContainer,
        }}
        value={matchingTabPath || false}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab
            className={classes.tab}
            key={tab.label}
            label={tab.label}
            value={tab.path}
            onClick={() => navigate(tab.path)}
            disableRipple
            disableFocusRipple
          />
        ))}
      </Tabs>

      <TabContext value={matchingTabPath}>{children}</TabContext>
    </>
  );
};
