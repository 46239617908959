import React, { useMemo } from "react";

import { TooltipProps } from "recharts";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";

import { formatShortCash } from "@fartherfinance/frontend/formatting/numbers";
import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import createDateByYear from "../../ModelAnalysis/components/utils/createDateByYear";
import { DataKey } from "../Types";

import styles from "./CustomTooltip.module.css";

interface Props {
  tooltipProps: TooltipProps<ValueType, DataKey>;
  setToDate: (toDate: string) => void;
}

const CustomTooltip = ({ tooltipProps, setToDate }: Props): JSX.Element => {
  const {
    color: { $chartLineTertiary, $chartLinePrimary, $chartLineSecondary },
  } = useTheme();

  const data = useMemo(() => {
    const payload = tooltipProps.payload;

    if (!payload) {
      return null;
    }

    const results90Pct = payload.find((p) => p.name === "results90Pct");

    const year = results90Pct?.payload.year;

    const pct90 = results90Pct?.value;

    const pct50 = payload.find((p) => p.name === "results50Pct")?.value;

    const pct10 = payload.find((p) => p.name === "results10Pct")?.value;

    if (
      typeof pct90 !== "number" ||
      typeof pct50 !== "number" ||
      typeof pct10 !== "number"
    ) {
      return null;
    }

    setToDate(createDateByYear(year));

    return {
      results90Pct: formatShortCash(pct90, 2),
      results50Pct: formatShortCash(pct50, 2),
      results10Pct: formatShortCash(pct10, 2),
    };
  }, [setToDate, tooltipProps.payload]);

  if (data === null) {
    return <></>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.percent} style={{ color: $chartLineTertiary }}>
        90% <span className={styles.amount}>{data.results90Pct}</span>
      </div>
      <div className={styles.percent} style={{ color: $chartLinePrimary }}>
        50% <span className={styles.amount}>{data.results50Pct}</span>
      </div>
      <div className={styles.percent} style={{ color: $chartLineSecondary }}>
        10% <span className={styles.amount}>{data.results10Pct}</span>
      </div>
    </div>
  );
};

export default CustomTooltip;
