import React from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";

import {
  ClientId,
  PortfolioId,
  SleeveId,
} from "@fartherfinance/frontend/api/Types";

import CreateEquivalentSecurities from "@src/multiCustodian/components/Admin/EquivalentSecurities/CreateEquivalentSecurities";
import DeleteEquivalentSecurities from "@src/multiCustodian/components/Admin/EquivalentSecurities/DeleteEquivalentSecurities";
import EquivalentSecuritiesDrawer from "@src/multiCustodian/components/Admin/EquivalentSecurities/Drawer/EquivalentSecuritiesDrawer";
import EditEquivalentSecurities from "@src/multiCustodian/components/Admin/EquivalentSecurities/EditEquivalentSecurities";
import TaxBudgetDetails from "@src/multiCustodian/components/Advisor/ClientProfile/Components/TaxBudgetDetails";
import Admin from "@src/multiCustodian/pages/Advisor/Admin";
import CreateAdvisor from "@src/multiCustodian/pages/Advisor/Admin/CreateAdvisor";
import CreateBulkClients from "@src/multiCustodian/pages/Advisor/Admin/CreateBulkClients";
import CreateCXMember from "@src/multiCustodian/pages/Advisor/Admin/CreateCXMember";
import CustomModelBilling from "@src/multiCustodian/pages/Advisor/Admin/CustomModelBilling";
import FAMBilling from "@src/multiCustodian/pages/Advisor/Admin/FAMBilling";
import SleeveBilling from "@src/multiCustodian/pages/Advisor/Admin/SleeveBilling";
import BookAnalytics from "@src/multiCustodian/pages/Advisor/BookAnalytics/BookAnalyticsWrapper";
import Clients from "@src/multiCustodian/pages/Advisor/Clients";
import AdminArchiveAccount from "@src/multiCustodian/pages/Advisor/Clients/AdminArchiveAccount";
import AdminDeleteAccount from "@src/multiCustodian/pages/Advisor/Clients/AdminDeleteAccount";
import AdminRenameAccount from "@src/multiCustodian/pages/Advisor/Clients/AdminRenameAccount";
import AdvisorClientActions from "@src/multiCustodian/pages/Advisor/Clients/AdvisorClientActions";
import AdvisorClientAdmin from "@src/multiCustodian/pages/Advisor/Clients/AdvisorClientAdmin";
import AdvisorClientTaxBudget from "@src/multiCustodian/pages/Advisor/Clients/AdvisorClientTaxBudget";
import AdvisorCopilot from "@src/multiCustodian/pages/Advisor/Clients/AdvisorCopilot";
import ClientProfile from "@src/multiCustodian/pages/Advisor/Clients/ClientProfile";
import CreateClient from "@src/multiCustodian/pages/Advisor/Clients/CreateNewClient";
import InsuranceMarketplace from "@src/multiCustodian/pages/Advisor/InsuranceMarketplace";
import Investments from "@src/multiCustodian/pages/Advisor/Investments";
import ArchiveModel from "@src/multiCustodian/pages/Advisor/Investments/:portfolioId/:sharingOrModelDetails/Archive";
import ReplaceModel from "@src/multiCustodian/pages/Advisor/Investments/:portfolioId/:sharingOrModelDetails/Replace";
import ModelMarketplaceModelDetails from "@src/multiCustodian/pages/Advisor/Investments/:portfolioId/ModelDetails";
import ModelMarketplaceModelSharing from "@src/multiCustodian/pages/Advisor/Investments/:portfolioId/Sharing";
import AltAssetsMarketplace from "@src/multiCustodian/pages/Advisor/Investments/AltAssetsMarketplace";
import AltAssetsOfferingDetails from "@src/multiCustodian/pages/Advisor/Investments/AltAssetsMarketplace/AltAssetsOfferingDetailsAdvisor";
import AltAssetsOfferingsList from "@src/multiCustodian/pages/Advisor/Investments/AltAssetsMarketplace/AltAssetsOfferingsListAdvisor";
import ModelMarketplace from "@src/multiCustodian/pages/Advisor/Investments/ModelMarketplace";
import CreateModelPortfolio from "@src/multiCustodian/pages/Advisor/Investments/ModelMarketplace/Custom/Create";
import UpdateCustomModelPortfolio from "@src/multiCustodian/pages/Advisor/Investments/ModelMarketplace/Custom/Update";
import NewPartner from "@src/multiCustodian/pages/Advisor/Investments/ModelMarketplace/NewPartner";
import UpdateModelPortfolio from "@src/multiCustodian/pages/Advisor/Investments/ModelMarketplace/Update";
import Sleeves from "@src/multiCustodian/pages/Advisor/Investments/Sleeves";
import ArchiveSleeve from "@src/multiCustodian/pages/Advisor/Investments/Sleeves/:sleeveId/:sleeveDrawerTab/Archive";
import ReplaceSleeve from "@src/multiCustodian/pages/Advisor/Investments/Sleeves/:sleeveId/:sleeveDrawerTab/Replace";
import SleeveSharing from "@src/multiCustodian/pages/Advisor/Investments/Sleeves/:sleeveId/Sharing";
import SleeveDetails from "@src/multiCustodian/pages/Advisor/Investments/Sleeves/:sleeveId/SleeveDetails";
import SleeveUsage from "@src/multiCustodian/pages/Advisor/Investments/Sleeves/:sleeveId/SleeveUsage";
import UpdateSleeve from "@src/multiCustodian/pages/Advisor/Investments/Sleeves/:sleeveId/Update";
import CreateSleeve from "@src/multiCustodian/pages/Advisor/Investments/Sleeves/CreateSleeve";
import SMA from "@src/multiCustodian/pages/Advisor/Investments/SMA";
import SMADetails from "@src/multiCustodian/pages/Advisor/Investments/SMA/:SMAId";
import ApplySMA from "@src/multiCustodian/pages/Advisor/Investments/SMA/:SMAId/Apply";
import LendingMarketplace from "@src/multiCustodian/pages/Advisor/LendingMarketplace";
import InsuranceOfferingDetails from "@src/multiCustodian/pages/Advisor/Marketplace/OfferingDetailsAdvisor/InsuranceOfferingDetailsAdvisor";
import LendingOfferingDetails from "@src/multiCustodian/pages/Advisor/Marketplace/OfferingDetailsAdvisor/LendingOfferingDetailsAdvisor";
import InsuranceOfferingsList from "@src/multiCustodian/pages/Advisor/Marketplace/OfferingsListAdvisor/InsuranceOfferingsListAdvisor";
import LendingOfferingsList from "@src/multiCustodian/pages/Advisor/Marketplace/OfferingsListAdvisor/LendingOfferingsListAdvisor";
import Opportunities from "@src/multiCustodian/pages/Advisor/Opportunities";
import OpportunityDetailsGuard from "@src/multiCustodian/pages/Advisor/Opportunities/Components/OpportunityDetails/OpportunityDetailsGuard";
import Plans from "@src/multiCustodian/pages/Advisor/Plans";
import EditAccount from "@src/multiCustodian/pages/Advisor/Plans/Account";
import EditPlan from "@src/multiCustodian/pages/Advisor/Plans/EditPlan";
import Tasks from "@src/multiCustodian/pages/Advisor/Tasks";
import CreateTask from "@src/multiCustodian/pages/Advisor/Tasks/Forms/CreateTask/CreateTask";
import TaskDetailsGuard from "@src/multiCustodian/pages/Advisor/Tasks/Forms/TaskDetails/TaskDetailsGuard";
import TaxBudgetAdvisorCreateEdit from "@src/multiCustodian/pages/Advisor/TaxBudgets/CreateEdit";
import TaxBudgetAdvisorCreateHypothetical from "@src/multiCustodian/pages/Advisor/TaxBudgets/CreateHypothetical";
import EnableMFA from "@src/multiCustodian/pages/Dashboard/EnableMFA/EnableMFA";
import { State } from "@src/store";
import { YellowstoneShell } from "@src/yellowstone/App";
import * as YellowstoneDashboard from "@src/yellowstone/views/Dashboard";

import PrivateAdminRoute from "./PrivateAdminRoute";
import PrivateAdvisorRoute from "./PrivateAdvisorRoute";
import RedirectWithFlag from "./RedirectWithFlag";
import RedirectWithLocation from "./RedirectWithLocation";
import { RouteElement } from "./Router.types";
import {
  AltAssetsMarketplacePath,
  InsuranceMarketplacePath,
  InvestmentsPath,
  LendingMarketplacePath,
} from "./RouterPaths";
import { NoMatch, RequiresPermission } from "./UtilityComponents";

export const advisorRoutes: RouteElement[] = [
  {
    path: "/Advisor",
    component: PrivateAdvisorRoute,
    noMatch: NoMatch,
    routes: [
      {
        path: "/Advisor",
        component: () => (
          <Redirect to={"/Advisor/BookAnalytics"} exact push={false} />
        ),
      },
      {
        path: "/Advisor/:advisorId/EnableMFA",
        component: EnableMFA,
      },
      {
        path: "/Advisor/Clients",
        component: Clients,
        trackingEvent: { name: "Advisor Open Clients" },
      },
      {
        path: "/Advisor/Clients/Create",
        component: CreateClient,
        trackingEvent: { name: "Advisor Open Create Client" },
      },

      {
        path: "/Advisor/Clients/:clientId",
        component: function RedirectToClientProfile(): JSX.Element {
          const { clientId } = useParams<{
            clientId: ClientId;
          }>();

          return (
            <RedirectWithLocation
              pathname={`/Advisor/Clients/${clientId}/Profile`}
            />
          );
        },
      },
      {
        path: "/Advisor/Clients/:clientId/Profile",
        component: ClientProfile,
        trackingEvent: { name: "Advisor Open Client Profile" },
      },
      {
        path: "/Advisor/Clients/:clientId/Admin",
        component: AdvisorClientAdmin,
        trackingEvent: { name: "Advisor Open Client Admin" },
      },
      {
        path: "/Advisor/Clients/:clientId/Copilot",
        component: AdvisorCopilot,
        trackingEvent: { name: "Advisor Open Client Copilot" },
      },
      {
        path: "/Advisor/Clients/:clientId/Actions",
        component: AdvisorClientActions,
        trackingEvent: { name: "Advisor Open Client Actions" },
      },
      {
        path: "/Advisor/Clients/:clientId/TaxBudgets",
        component: AdvisorClientTaxBudget,
        trackingEvent: { name: "Advisor Open Client Tax-Budgets" },
      },
      {
        path: "/Advisor/Clients/:clientId/TaxBudgets/Create",
        component: TaxBudgetAdvisorCreateEdit,
        trackingEvent: { name: "Advisor Open Create Client Tax-Budget" },
      },
      {
        path: `/Advisor/Clients/:clientId/TaxBudgets/CreateHypothetical`,
        component: function ClientHypotheticalRedirect(): JSX.Element {
          const { clientId } = useParams<{ clientId: ClientId }>();

          const { enableTaxBudgetHypotheticals } = useFlags();

          return enableTaxBudgetHypotheticals ? (
            <TaxBudgetAdvisorCreateHypothetical />
          ) : (
            <Redirect
              to={`/Advisor/Clients/${clientId}/TaxBudgets`}
              exact
              push={false}
            />
          );
        },
        trackingEvent: {
          name: "Advisor Open Create Client Hypothetical-Tax-Budget",
        },
      },
      {
        path: `/Advisor/Clients/:clientId/TaxBudgets/:taxBudgetId`,
        component: function RedirectToSummary(): JSX.Element {
          const { clientId, taxBudgetId } = useParams<{
            clientId: ClientId;
            taxBudgetId: string;
          }>();

          return (
            <Redirect
              to={`/Advisor/Clients/${clientId}/TaxBudgets/${taxBudgetId}/Usage`}
              exact
              push={false}
            />
          );
        },
      },
      {
        path: "/Advisor/Clients/:clientId/TaxBudgets/:taxBudgetId/Edit",
        component: TaxBudgetAdvisorCreateEdit,
        trackingEvent: { name: "Advisor Open Edit Client Tax-Budget" },
      },
      {
        path: "/Advisor/Clients/:clientId/TaxBudgets/:taxBudgetId/*",
        component: TaxBudgetDetails,
        trackingEvent: { name: "Advisor Open Client Tax-Budget" },
      },
      {
        path: "/Advisor/Clients/:clientId/Admin/Account/:accountId/Rename",
        component: AdminRenameAccount,
        trackingEvent: { name: "Advisor Open Admin Rename Client Account" },
      },
      {
        path: "/Advisor/Clients/:clientId/Admin/Account/:accountId/Archive",
        component: AdminArchiveAccount,
        trackingEvent: { name: "Advisor Open Admin Archive Client Account" },
      },
      {
        path: "/Advisor/Clients/:clientId/Admin/Account/:accountId/Delete",
        component: AdminDeleteAccount,
        trackingEvent: { name: "Advisor Open Admin Delete Client Account" },
      },
      {
        path: "/Advisor/BookAnalytics",
        component: function RedirectToAdvisorBookAnalytics(): JSX.Element {
          const { advisorId } = useSelector((state: State) => ({
            advisorId: state.main_Reducer.cockroach_advisor_id,
          }));

          if (advisorId === null) {
            return <div>Error finding advisor id</div>;
          }

          return (
            <Redirect
              to={`/Advisor/BookAnalytics/Advisor/${advisorId}`}
              exact
              push={false}
            />
          );
        },
      },
      {
        path: "/Advisor/BookAnalytics/Advisor/:advisorId",
        component: BookAnalytics,
        trackingEvent: { name: "Advisor Open Book Analytics" },
      },
      {
        path: "/Advisor/Tasks",
        component: Tasks,
        trackingEvent: { name: "Advisor Open Tasks" },
      },
      {
        path: "/Advisor/Tasks/Create",
        component: CreateTask,
        trackingEvent: { name: "Advisor Open Create Task" },
      },
      {
        path: "/Advisor/Tasks/:taskId",
        component: TaskDetailsGuard,
        trackingEvent: { name: "Advisor Open Task Details" },
      },
      {
        path: `/Advisor/${InvestmentsPath}`,
        component: Investments,
        routes: [
          // **** Root Redirector ****
          {
            path: `/Advisor/${InvestmentsPath}`,
            component: function AdvisorInvestmentsRedirect(): JSX.Element {
              return (
                <Redirect
                  to={`/Advisor/${InvestmentsPath}/ModelMarketplace`}
                  exact
                  push={false}
                />
              );
            },
          },
          // **** Model Marketplace ****
          {
            path: `/Advisor/${InvestmentsPath}/ModelMarketplace`,
            component: ModelMarketplace,
            trackingEvent: { name: "Advisor Open Model Marketplace" },
          },
          {
            path: `/Advisor/${InvestmentsPath}/ModelMarketplace/Custom/Create`,
            component: CreateModelPortfolio,
            trackingEvent: { name: "Advisor Open Create Custom Model" },
          },
          {
            path: `/Advisor/${InvestmentsPath}/ModelMarketplace/Custom/:portfolioId/Update`,
            component: UpdateCustomModelPortfolio,
            trackingEvent: { name: "Advisor Open Update Custom Model" },
          },
          {
            path: `/Advisor/${InvestmentsPath}/ModelMarketplace/Update`,
            component: UpdateModelPortfolio,
            trackingEvent: { name: "Advisor Open Update Essential Model" },
          },
          {
            path: `/Advisor/${InvestmentsPath}/ModelMarketplace/NewPartner`,
            component: NewPartner,
            trackingEvent: {
              name: "Advisor Open Add New Essential Partner Model",
            },
          },
          {
            path: `/Advisor/${InvestmentsPath}/ModelMarketplace/:portfolioId`,
            component:
              function AdvisorInvestmentsModelMarketplaceModelRedirect(): JSX.Element {
                const { portfolioId } = useParams<{
                  portfolioId: PortfolioId;
                }>();

                return (
                  <Redirect
                    to={`/Advisor/${InvestmentsPath}/ModelMarketplace/${portfolioId}/ModelDetails`}
                    exact
                    push={false}
                  />
                );
              },
          },
          {
            path: `/Advisor/${InvestmentsPath}/ModelMarketplace/:portfolioId/Sharing`,
            component: ModelMarketplaceModelSharing,
            trackingEvent: { name: "Advisor Open Model Sharing" },
          },
          {
            path: `/Advisor/${InvestmentsPath}/ModelMarketplace/:portfolioId/ModelDetails`,
            component: ModelMarketplaceModelDetails,
            trackingEvent: { name: "Advisor Open Model Details" },
          },
          {
            path: `/Advisor/${InvestmentsPath}/ModelMarketplace/:portfolioId/:sharingOrModelDetails/Archive`,
            component: ArchiveModel,
            trackingEvent: { name: "Advisor Open Archive Model" },
          },
          {
            path: `/Advisor/${InvestmentsPath}/ModelMarketplace/:portfolioId/:sharingOrModelDetails/Replace`,
            component: ReplaceModel,
            trackingEvent: { name: "Advisor Open Replace Model" },
          },
          // **** Separately-Managed Accounts ****
          {
            path: `/Advisor/${InvestmentsPath}/SMA`,
            component: SMA,
            trackingEvent: { name: "Advisor Open SMA" },
          },
          {
            path: `/Advisor/${InvestmentsPath}/SMA/:SMAId`,
            component: SMADetails,
            trackingEvent: { name: "Advisor Open SMA Details" },
          },
          {
            path: `/Advisor/${InvestmentsPath}/SMA/:SMAId/Apply`,
            component: ApplySMA,
            trackingEvent: { name: "Advisor Open Apply SMA" },
          },
          // **** Sleeves ****
          {
            path: `/Advisor/${InvestmentsPath}/Sleeves`,
            component: Sleeves,
            trackingEvent: { name: "Advisor Open Sleeves" },
          },
          {
            path: `/Advisor/${InvestmentsPath}/Sleeves/Create`,
            component: CreateSleeve,
            trackingEvent: { name: "Advisor Open Create Sleeve" },
          },
          {
            path: `/Advisor/${InvestmentsPath}/Sleeves/:sleeveId/Update`,
            component: UpdateSleeve,
            trackingEvent: { name: "Advisor Open Update Sleeve" },
          },
          {
            path: `/Advisor/${InvestmentsPath}/Sleeves/:sleeveId`,
            component: function PortfolioSleeve(): JSX.Element {
              const { sleeveId } = useParams<{
                sleeveId: SleeveId;
              }>();

              return (
                <Redirect
                  to={`/Advisor/${InvestmentsPath}/Sleeves/${sleeveId}/SleeveDetails`}
                  exact
                  push={false}
                />
              );
            },
          },
          {
            path: `/Advisor/${InvestmentsPath}/Sleeves/:sleeveId/Sharing`,
            component: SleeveSharing,
            trackingEvent: { name: "Advisor Open Share Sleeve" },
          },
          {
            path: `/Advisor/${InvestmentsPath}/Sleeves/:sleeveId/SleeveUsage`,
            component: SleeveUsage,
            trackingEvent: { name: "Advisor Open Sleeve Usage" },
          },
          {
            path: `/Advisor/${InvestmentsPath}/Sleeves/:sleeveId/SleeveDetails`,
            component: SleeveDetails,
            trackingEvent: { name: "Advisor Open Sleeve Details" },
          },
          {
            path: `/Advisor/${InvestmentsPath}/Sleeves/:sleeveId/:sleeveDrawerTab/Archive`,
            component: ArchiveSleeve,
            trackingEvent: { name: "Advisor Open Archive Sleeve" },
          },
          {
            path: `/Advisor/${InvestmentsPath}/Sleeves/:sleeveId/:sleeveDrawerTab/Replace`,
            component: ReplaceSleeve,
            trackingEvent: { name: "Advisor Open Replace Sleeve" },
          },
          // **** Alt Assets v2 ****
          {
            path: `/Advisor/${AltAssetsMarketplacePath}`,
            component: AltAssetsMarketplace,
            trackingEvent: { name: "Advisor Open Alternative Asset" },
          },
          {
            path: `/Advisor/${AltAssetsMarketplacePath}/:offeringType`,
            component: AltAssetsOfferingsList,
          },
          {
            path: `/Advisor/${AltAssetsMarketplacePath}/:offeringType/:offeringId`,
            component: AltAssetsOfferingDetails,
          },
        ],
      },
      {
        path: "/Advisor/Opportunities",
        component: Opportunities,
        trackingEvent: { name: "Advisor Open Opportunities" },
      },
      {
        path: "/Advisor/Opportunities/:opportunityId",
        component: OpportunityDetailsGuard,
        trackingEvent: { name: "Advisor Open Opportunity Details" },
      },
      // Any non special pages must come ABOVE here
      {
        path: "/Advisor/Plans",
        component: PrivateAdminRoute,
        noMatch: RequiresPermission,
        routes: [
          {
            path: "/Advisor/Plans",
            component: Plans,
            trackingEvent: { name: "Advisor Open Plans" },
          },
          {
            path: "/Advisor/Plans/:planId",
            component: EditPlan,
            trackingEvent: { name: "Advisor Open Edit Plan" },
          },
          {
            path: "/Advisor/Plans/:planId/Accounts/:accountId",
            component: EditAccount,
            trackingEvent: { name: "Advisor Open Plan Account Details" },
          },
        ],
      },
      {
        path: `/Advisor/${InsuranceMarketplacePath}`,
        component: function InsuranceMarketplaceRedirect(): JSX.Element {
          const { enableInsuranceMarketplace } = useFlags();

          return (
            <RedirectWithFlag
              flag={enableInsuranceMarketplace}
              redirectPath={"/Advisor"}
            >
              <InsuranceMarketplace />
            </RedirectWithFlag>
          );
        },
        trackingEvent: { name: "Advisor Open Insurance Marketplace" },
      },
      {
        path: `/Advisor/${InsuranceMarketplacePath}/:offeringType`,
        component: function OfferingTypeRedirect(): JSX.Element {
          const { enableInsuranceMarketplace } = useFlags();

          return (
            <RedirectWithFlag
              flag={enableInsuranceMarketplace}
              redirectPath={"/Advisor"}
            >
              <InsuranceOfferingsList />
            </RedirectWithFlag>
          );
        },
        trackingEvent: {
          name: "Advisor Open Insurance Marketplace Offering Type",
        },
      },
      {
        path: `/Advisor/${InsuranceMarketplacePath}/:offeringType/:offeringId`,
        component: function OfferingDetailsRedirect(): JSX.Element {
          const { enableInsuranceMarketplace } = useFlags();

          return (
            <RedirectWithFlag
              flag={enableInsuranceMarketplace}
              redirectPath={"/Advisor"}
            >
              <InsuranceOfferingDetails />
            </RedirectWithFlag>
          );
        },
        trackingEvent: {
          name: "Advisor Open Insurance Marketplace Offering Details",
        },
      },
      {
        path: `/Advisor/${LendingMarketplacePath}`,
        component: function LendingMarketplaceRedirect(): JSX.Element {
          const { enableLendingMarketplace } = useFlags();

          return (
            <RedirectWithFlag
              flag={enableLendingMarketplace}
              redirectPath={"/Advisor"}
            >
              <LendingMarketplace />
            </RedirectWithFlag>
          );
        },
        trackingEvent: { name: "Advisor Open Lending Marketplace" },
      },
      {
        path: `/Advisor/${LendingMarketplacePath}/:offeringType`,
        component: function OfferingTypeRedirect(): JSX.Element {
          const { enableLendingMarketplace } = useFlags();

          return (
            <RedirectWithFlag
              flag={enableLendingMarketplace}
              redirectPath={"/Advisor"}
            >
              <LendingOfferingsList />
            </RedirectWithFlag>
          );
        },
        trackingEvent: {
          name: "Advisor Open Lending Marketplace Offering Type",
        },
      },
      {
        path: `/Advisor/${LendingMarketplacePath}/:offeringType/:offeringId`,
        component: function OfferingDetailsRedirect(): JSX.Element {
          const { enableLendingMarketplace } = useFlags();

          return (
            <RedirectWithFlag
              flag={enableLendingMarketplace}
              redirectPath={"/Advisor"}
            >
              <LendingOfferingDetails />
            </RedirectWithFlag>
          );
        },
        trackingEvent: {
          name: "Advisor Open Lending Marketplace Offering Details",
        },
      },
      // Yellowstone routes
      {
        path: "/Advisor/Client-Ops",
        component: PrivateAdminRoute,
        noMatch: NoMatch,
        routes: [
          {
            path: "/Advisor/Client-Ops",
            component: YellowstoneShell,
            noMatch: NoMatch,
            routes: [
              {
                path: "/Advisor/Client-Ops",
                component: () => (
                  <Redirect
                    to="/Advisor/Client-Ops/Dashboard"
                    exact
                    push={false}
                  />
                ),
              },
              {
                path: "/Advisor/Client-Ops/Dashboard",
                component: YellowstoneDashboard.Dashboard,
                noMatch: NoMatch,
                routes: [
                  {
                    path: "/Advisor/Client-Ops/Dashboard",
                    component: () => (
                      <Redirect
                        to="/Advisor/Client-Ops/Dashboard/Daily-Trading"
                        exact
                        push={false}
                      />
                    ),
                  },
                  // Accounts
                  {
                    path: "/Advisor/Client-Ops/Dashboard/Accounts",
                    component: () => (
                      <YellowstoneDashboard.DashboardAccounts
                        isSelectedUserMode={false}
                      />
                    ),
                  },
                  {
                    path: "/Advisor/Client-Ops/Dashboard/Accounts/:userId",
                    component: () => (
                      <YellowstoneDashboard.DashboardAccounts
                        isSelectedUserMode={true}
                      />
                    ),
                  },
                  // Daily-Trading
                  {
                    path: "/Advisor/Client-Ops/Dashboard/Daily-Trading",
                    component: YellowstoneDashboard.DashboardDailyTrading,
                    noMatch: NoMatch,
                    routes: [
                      {
                        path: "/Advisor/Client-Ops/Dashboard/Daily-Trading",
                        component: () => (
                          <Redirect
                            to="/Advisor/Client-Ops/Dashboard/Daily-Trading/Daily-Proposals"
                            exact
                            push={false}
                          />
                        ),
                      },
                      // PQS proposals
                      {
                        path: "/Advisor/Client-Ops/Dashboard/Daily-Trading/Daily-Proposals",
                        component: () => (
                          <YellowstoneDashboard.ProposalsController
                            isDailyProposalsView={true}
                          />
                        ),
                      },
                      {
                        path: "/Advisor/Client-Ops/Dashboard/Daily-Trading/Submitted-Proposals",
                        component: () => (
                          <YellowstoneDashboard.ProposalsController
                            isSubmittedProposalsView={true}
                          />
                        ),
                      },
                      {
                        path: "/Advisor/Client-Ops/Dashboard/Daily-Trading/Skipped-Proposals",
                        component: () => (
                          <YellowstoneDashboard.ProposalsController
                            isSkippedProposalsView={true}
                          />
                        ),
                      },
                      {
                        path: "/Advisor/Client-Ops/Dashboard/Daily-Trading/Proposal-Details/:proposalId",
                        component: () => (
                          <YellowstoneDashboard.ProposalDetailsGuard />
                        ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/Advisor/Admin",
        component: PrivateAdminRoute,
        noMatch: RequiresPermission,
        routes: [
          {
            path: "/Advisor/Admin",
            component: () => <Admin />,
            trackingEvent: { name: "Advisor Open Admin" },
          },
          {
            path: "/Advisor/Admin/CreateCXMember",
            component: CreateCXMember,
            trackingEvent: { name: "Advisor Open Admin Create CX Member" },
          },
          {
            path: "/Advisor/Admin/CreateBulkClients",
            component: CreateBulkClients,
            trackingEvent: { name: "Advisor Open Admin Create Bulk Clients" },
          },
          {
            path: "/Advisor/Admin/CreateAdvisor",
            component: function CreateAdvisorV2Redirect(): JSX.Element {
              const { enableCreateAdvisorV2 } = useFlags();

              return (
                <RedirectWithFlag
                  flag={enableCreateAdvisorV2}
                  redirectPath={"/Advisor/Admin"}
                >
                  <CreateAdvisor />
                </RedirectWithFlag>
              );
            },
            trackingEvent: {
              name: "Advisor Open Admin Create Advisor",
            },
          },
          {
            path: "/Advisor/Admin/CustomModelBilling",
            component: CustomModelBilling,
            trackingEvent: { name: "Advisor Open Admin Custom Model Billing" },
          },
          {
            path: "/Advisor/Admin/FAMBilling",
            component: FAMBilling,
            trackingEvent: { name: "Advisor Open Admin FAM Billing" },
          },
          {
            path: "/Advisor/Admin/SleeveBilling",
            component: SleeveBilling,
            trackingEvent: { name: "Advisor Open Admin Sleeve Billing" },
          },
          {
            path: "/Advisor/Admin/EquivalentSecurities/Create",
            component: function EquivalentSecuritiesRedirect(): JSX.Element {
              const { enableEquivalentSecurities } = useFlags();

              return (
                <RedirectWithFlag
                  flag={enableEquivalentSecurities}
                  redirectPath={"/Advisor/Admin"}
                >
                  <CreateEquivalentSecurities />
                </RedirectWithFlag>
              );
            },
            trackingEvent: {
              name: "Advisor Open Create Equivalent Securities List",
            },
          },
          {
            path: "/Advisor/Admin/EquivalentSecurities/:listId/Edit",
            component: function EquivalentSecuritiesRedirect(): JSX.Element {
              const { enableEquivalentSecurities } = useFlags();

              return (
                <RedirectWithFlag
                  flag={enableEquivalentSecurities}
                  redirectPath={"/Advisor/Admin"}
                >
                  <EditEquivalentSecurities />
                </RedirectWithFlag>
              );
            },
            trackingEvent: {
              name: "Advisor Open Edit Equivalent Securities List",
            },
          },
          {
            path: "/Advisor/Admin/EquivalentSecurities/:listId/Delete",
            component: function EquivalentSecuritiesRedirect(): JSX.Element {
              const { enableEquivalentSecurities } = useFlags();

              return (
                <RedirectWithFlag
                  flag={enableEquivalentSecurities}
                  redirectPath={"/Advisor/Admin"}
                >
                  <DeleteEquivalentSecurities />
                </RedirectWithFlag>
              );
            },
            trackingEvent: {
              name: "Advisor Open Delete Equivalent Securities List",
            },
          },
          {
            path: "/Advisor/Admin/EquivalentSecurities/:listId",
            component: function EquivalentSecuritiesRedirect(): JSX.Element {
              const { enableEquivalentSecurities } = useFlags();

              return (
                <RedirectWithFlag
                  flag={enableEquivalentSecurities}
                  redirectPath={"/Advisor/Admin"}
                >
                  <EquivalentSecuritiesDrawer />
                </RedirectWithFlag>
              );
            },
            trackingEvent: { name: "Advisor Open Equivalent Securities List" },
          },
          {
            path: "/Advisor/Admin/EquivalentSecurities",
            component: () => (
              <Redirect to={"/Advisor/Admin"} exact push={false} />
            ),
          },
        ],
      },
    ],
  },
];
