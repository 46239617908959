import React from "react";

import { format } from "date-fns";

import usePortfolioAnalysisV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/usePortfolioAnalysisV2";
import usePortfolioV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/usePortfolioV2";
import { PortfolioId } from "@fartherfinance/frontend/api/Types";

import { taxTypeToStatus } from "../utils/taxType";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import finScoreToLabel from "@src/multiCustodian/utils/finScoreToLabel";
import Chip from "@src/sharedComponents/Chip/Chip";
import BorderedList from "@src/sharedComponents/List/BorderedList";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "@src/multiCustodian/components/Client/Portfolio/ModelAnalysis/ModelDetailsTable.module.css";
interface Props {
  portfolioId: PortfolioId;
}

const ModelDetailsTable = ({ portfolioId }: Props): JSX.Element => {
  const auth = useAdvisorRequestAuth();

  const portfolioAnalysis = usePortfolioAnalysisV2(portfolioId, auth);
  const portfolio = usePortfolioV2(portfolioId, auth);

  if (portfolio.isLoading) {
    return (
      <div className={styles.container}>
        <BorderedList
          rows={[
            {
              left: "Risk Level",
              right: <Skeleton width={120} />,
            },
            {
              left: "Tax Type",
              right: <Skeleton width={120} />,
            },
            {
              left: "Created",
              right: <Skeleton width={120} />,
            },
          ]}
        />
      </div>
    );
  }

  if (portfolio.hasError) {
    return (
      <div className={styles.container}>
        <p className={styles.textLeft}>Error</p>
      </div>
    );
  }

  const riskLabel = finScoreToLabel(
    portfolioAnalysis.data?.finScore ?? undefined
  );

  const taxLabel = portfolio.data.model.taxType
    ? taxTypeToStatus[portfolio.data.model.taxType]
    : "N/A";

  const createTime = portfolio.data.model.createTime
    ? format(portfolio.data.model.createTime, "M/d/yyyy")
    : "N/A";

  return (
    <div className={styles.container}>
      <BorderedList
        rows={[
          {
            left: "Risk Level",
            right: <Chip label={riskLabel} />,
          },
          {
            left: "Tax Type",
            right: <Chip label={taxLabel} />,
          },
          {
            left: "Created",
            right: <div className={styles.dateText}>{createTime}</div>,
          },
        ]}
      />
    </div>
  );
};

export default ModelDetailsTable;
