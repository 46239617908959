import React from "react";

import { EssentialPortfolioValidateV2Action } from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/postEssentialPortfolioValidateV2";

import CSVWarning from "../CSVWarning/CSVWarning";
import Checkbox from "@src/sharedComponents/Checkbox/Checkbox";

import styles from "./AddPartnerModelIncrementsCheckbox.module.css";

export type AddOrUpdate = Extract<
  EssentialPortfolioValidateV2Action,
  "Add" | "Update"
>;

interface Props {
  action: AddOrUpdate;
  setAction: (action: AddOrUpdate) => void;
}

const AddPartnerModelIncrementsCheckbox = (props: Props) => {
  return (
    <div className={styles.container}>
      <Checkbox
        checked={props.action === "Add"}
        onChange={(bool: boolean) => props.setAction(bool ? "Add" : "Update")}
        label={"I'm adding new increments to an existing model"}
      />

      {props.action === "Add" && (
        <CSVWarning
          text={"Only include the new increments in your spreadsheet."}
        />
      )}
    </div>
  );
};

export default AddPartnerModelIncrementsCheckbox;
