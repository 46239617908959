import React from "react";

import { PieDataItem } from "./Types";

const RADIAN = Math.PI / 180;

//Helps adjust spacing between each <text> element
//using its 'dy' property
const VERTICAL_DISTANCE = 15;

//Helps adjust the length of the part of the labelLine which faces/points at the label
const LABEL_LINE_SECOND_PART_LENGTH = 20;
const LABEL_LINE_SECOND_PART_LENGTH_AT_HORIZ_AXIS = 10;
const LABEL_LINE_SECOND_PART_LENGTH_AT_VERT_AXIS = 40;

//Helps adjust the distance from the label to the tip of the labelLine
const LABEL_TO_LINE_DISTANCE = 6;

type TooltipProps = {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  labelLineColor: string;
  labelTextColor: string;
  percentColor: string;
  isMiniChart: boolean;
} & PieDataItem;

/**
 * Code Source: https://recharts.org/en-US/examples/CustomActiveShapePieChart
 */
const CustomTooltip = (props: TooltipProps): JSX.Element => {
  const { cx, cy, midAngle, outerRadius, innerRadius, label, value } = props;

  if (label === null || value === null) {
    return <></>;
  }

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);

  const PIE_BORDER_THICKNESS = outerRadius - innerRadius;

  //sx & sy control the horizontal & vertical positioning of the labelLine side that face/touch the Pie
  //Adding "- PIE_BORDER_THICKNESS / 2" to the original code ensures that
  //the start of the tooltip's labelLine and circle is in the center of the pie's border
  const sx = cx + (outerRadius - PIE_BORDER_THICKNESS / 2) * cos;
  const sy = cy + (outerRadius - PIE_BORDER_THICKNESS / 2) * sin;

  //mx & my control the horizontal and vertical placement of the label
  //while responsively adjusting the labelLine size and angle
  const mx = cx + (outerRadius + 8) * cos;
  const my = cy + (outerRadius + 13) * sin;

  const secondPartLength =
    distanceFromAngle(midAngle, 180) < 10
      ? LABEL_LINE_SECOND_PART_LENGTH_AT_HORIZ_AXIS
      : distanceFromAngle(midAngle, 90) < 10
      ? LABEL_LINE_SECOND_PART_LENGTH_AT_VERT_AXIS
      : LABEL_LINE_SECOND_PART_LENGTH;

  //controls the distance from the label to the tip of the labelLine
  const ex = mx + (cos >= 0 ? 1 : -1) * secondPartLength;

  //Aligns text to the right if the tooltip is on the left, and vice-versa.
  const textAnchor = cos >= 0 ? "start" : "end";

  //When a label has more than one word, we break it up and use
  //the textCentering variable to center the text container compared to its labelLine
  const labelWords = label.split(" ");

  const numOfWords = labelWords.length;

  const textCentering =
    numOfWords > 1 ? (VERTICAL_DISTANCE / 2) * numOfWords : 0;

  return (
    <g>
      <LabelLine
        sx={sx}
        sy={sy}
        mx={mx}
        my={my}
        ex={ex}
        labelLineColor={props.labelLineColor}
      />

      {labelWords.map((w, i) => (
        <DescriptionText
          key={`${w}-${i}`}
          text={w}
          ex={ex}
          cos={cos}
          textCentering={textCentering}
          my={my}
          index={i}
          textAnchor={textAnchor}
          labelLineColor={props.labelTextColor}
        />
      ))}

      {!props.isMiniChart && (
        <ValueText
          textCentering={textCentering}
          cos={cos}
          ex={ex}
          my={my}
          numOfWords={numOfWords}
          percentColor={props.percentColor}
          textAnchor={textAnchor}
          value={value}
        />
      )}
    </g>
  );
};

export default CustomTooltip;

interface LabelLineProps {
  sx: number;
  sy: number;
  mx: number;
  my: number;
  ex: number;
  labelLineColor: string;
}

const LabelLine = ({ sx, sy, mx, my, ex, labelLineColor }: LabelLineProps) => {
  return (
    <>
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${my}`}
        stroke={labelLineColor}
        fill="none"
      />
      <circle cx={sx} cy={sy} r={2} fill={labelLineColor} stroke="none" />
    </>
  );
};

interface DescriptionTextProps {
  text: string;
  ex: number;
  cos: number;
  textCentering: number;
  my: number;
  index: number;
  textAnchor: "start" | "end";
  labelLineColor: string;
}

const DescriptionText = ({
  text,
  ex,
  cos,
  textCentering,
  my,
  index,
  textAnchor,
  labelLineColor,
}: DescriptionTextProps) => {
  return (
    <text
      key={text}
      x={ex + (cos >= 0 ? 1 : -1) * LABEL_TO_LINE_DISTANCE}
      y={my - textCentering}
      dy={index > 0 ? VERTICAL_DISTANCE * index : 0} //Each word is set below the previous
      textAnchor={textAnchor}
      fill={labelLineColor}
      fontSize={12}
    >
      {text}
    </text>
  );
};

interface ValueTextProps {
  textCentering: number;
  cos: number;
  ex: number;
  my: number;
  numOfWords: number;
  percentColor: string;
  textAnchor: "start" | "end";
  value: number;
}

const ValueText = ({
  textCentering,
  cos,
  ex,
  my,
  numOfWords,
  percentColor,
  textAnchor,
  value,
}: ValueTextProps) => {
  return (
    <text
      x={ex + (cos >= 0 ? 1 : -1) * LABEL_TO_LINE_DISTANCE}
      y={my - textCentering}
      dy={VERTICAL_DISTANCE * numOfWords} //Ensure percent shows up below all words
      textAnchor={textAnchor}
      fill={percentColor}
      fontSize={12}
    >
      {(value / 100).toLocaleString("en-US", {
        style: "percent",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}
    </text>
  );
};

function distanceFromAngle(x: number, y: number) {
  const rem = x % y;
  if (rem > y / 2) {
    return y - rem;
  }
  return rem;
}
