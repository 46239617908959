import React, { useEffect, useState } from "react";

import useValidateSleeve from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useValidateSleeve";
import { SecurityWeight } from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";

import CSVFormModal from "../../SharedComponents/CSVFormModal/CSVFormModal";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";

import CreateUpdateSleeveForm from "./CreateUpdateSleeveForm";

const CreateSleeve = (): JSX.Element => {
  const auth = useAdvisorRequestAuth();

  const [mutating, setMutating] = useState(false);

  const [csvSecurities, setCsvSecurities] = useState<SecurityWeight[] | null>(
    null
  );

  const [csvSleeveName, setCsvSleeveName] = useState<string | undefined>(
    undefined
  );

  const [cameFromUpload, setCameFromUpload] = useState<boolean>(false);

  const [pageState, setPageState] = useState<"CSV_UPLOAD" | "SLEEVE_EDIT">(
    "SLEEVE_EDIT"
  );

  const [csvFile, setCSVFile] = useState<File | null>(null);

  const [csvErrors, setCSVErrors] = useState<string[] | null>(null);

  const statusNotification = useStatusNotification();

  const validateSleeveCSV = useValidateSleeve(auth);

  const reset = () => {
    setCSVFile(null);
    setCSVErrors(null);
    setCsvSecurities(null);
    setCsvSleeveName(undefined);
    setCameFromUpload(false);
  };

  useEffect(() => {
    if (
      pageState === "CSV_UPLOAD" &&
      csvSecurities !== null &&
      csvSleeveName !== undefined &&
      cameFromUpload === true
    ) {
      setPageState("SLEEVE_EDIT"); //Ensure csv data is saved in state before opening the model edit form, otherwise the default values will not be mapped correctly onto the form
    }
  }, [csvSecurities, pageState, csvSleeveName, cameFromUpload]);

  const addNewFile = (newFile: File | null) => {
    setCSVFile(newFile);

    if (csvErrors !== null && newFile !== null) {
      //Only remove an existing error if a new file has been provided
      setCSVErrors(null);
    }
  };

  const uploadCSVFile = async () => {
    if (csvFile === null) {
      statusNotification("Please attach a CSV document", "Error");
      return;
    }

    if (mutating) {
      statusNotification("Uploading document, please wait", "Error");
      return;
    }

    try {
      setMutating(true);

      const res = await validateSleeveCSV(csvFile);

      if (res.status === "valid") {
        if (res.data.sleeves.length <= 0) {
          setCSVErrors(["No sleeves were found in the CSV"]);
        } else {
          if (res.data.sleeves.length >= 2) {
            statusNotification(
              "Can only upload 1 sleeve at a time - only the first sleeve was uploaded",
              "Error"
            );
          }

          setCsvSleeveName(res.data.sleeves[0].displayName);
          setCsvSecurities(res.data.sleeves[0].positions.securities);
          setCameFromUpload(true);

          statusNotification("CSV validation successful", "Success");
        }
      } else {
        setCSVErrors(res.data.errors ?? null);
      }
    } catch (e) {
      if (e instanceof Error) {
        statusNotification(e.message, "Error");
      }
    } finally {
      setMutating(false);
    }
  };

  if (pageState === "CSV_UPLOAD") {
    return (
      <CSVFormModal
        closeModal={() => setPageState("SLEEVE_EDIT")}
        formObjective={"Create Sleeve"}
        uploadType={"Sleeve"}
        csvFile={csvFile}
        addNewFile={addNewFile}
        uploadCSVFile={uploadCSVFile}
        mutating={mutating}
        csvError={csvErrors}
      />
    );
  }

  return (
    <CreateUpdateSleeveForm
      isUpdate={false}
      goToCSVUpload={() => {
        reset();
        setPageState("CSV_UPLOAD");
      }}
      currentSecurities={csvSecurities}
      sleeveName={csvSleeveName}
      cameFromUpload={cameFromUpload}
    />
  );
};

export default CreateSleeve;
