import React from "react";

import { identity, orderBy } from "lodash";

import { UNKNOWN_BALANCE } from "@fartherfinance/frontend/api/Accounts/utilities/accountUtil";
import useSMAAsset, {
  SMAAssetId,
} from "@fartherfinance/frontend/api/AltAssetsService/hooks/useSMAAsset";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import BorderedList from "@src/sharedComponents/List/BorderedList";

import SMADetailsBodyLoading from "./SMADetailsBodyLoading";
import Subheader from "./Subheader";

import * as styles from "./SMADetails.module.css";

interface Props {
  smaId: SMAAssetId;
}

const SMADetailsBody = (props: Props): JSX.Element => {
  const auth = useAdvisorRequestAuth();

  const details = useSMAAsset(props.smaId, auth);

  if (details.isLoading) {
    return <SMADetailsBodyLoading />;
  }

  if (details.hasError) {
    return <div className={styles.text}>Error getting SMA details</div>;
  }

  const {
    shortDescription,
    longDescription,
    contactInfoName: contactName,
    contactInfoEmail: email,
    contactInfoPhone: phone,
  } = details.data;

  return (
    <div>
      <FormH1>{shortDescription}</FormH1>

      <p className={styles.description}>{longDescription}</p>

      <Subheader />

      <Spacer verticalSpacing="24px" />

      <div className={styles.sectionTitle}>Investment Details</div>

      <BorderedList
        rows={[
          { left: "Investment Manager", right: details.data.firmName },
          {
            left: "Minimum Investment",
            right: (
              <div className={styles.textSubtle}>
                {details.data.minimumInvestment ?? UNKNOWN_BALANCE}
              </div>
            ),
          },
          {
            left: "Strategy",
            right: (
              <div className={styles.textSubtle}>
                {details.data.featuredStrategy.map((val) => (
                  <div key={val} className={styles.rightAligned}>
                    {val}
                  </div>
                ))}
              </div>
            ),
          },
          {
            left: "Custodian",
            right: (
              <div className={styles.textSubtle}>
                {orderBy(
                  details.data.custodianAvailability,
                  identity,
                  "asc"
                ).join(", ")}
              </div>
            ),
          },
          {
            left: "Pricing",
            right: (
              <div className={styles.textSubtle}>
                {details.data.feeRange ?? UNKNOWN_BALANCE}
              </div>
            ),
          },
        ]}
      />

      <Spacer verticalSpacing="24px" />

      <div className={styles.sectionTitle}>Investment Manager Contact Info</div>

      <BorderedList
        rows={[
          { left: "Name", right: contactName ?? UNKNOWN_BALANCE },
          {
            left: "Email",
            right: (
              <div className={styles.textSubtle}>
                {email ?? UNKNOWN_BALANCE}
              </div>
            ),
          },
          {
            left: "Phone",
            right: (
              <div className={styles.textSubtle}>
                {phone ?? UNKNOWN_BALANCE}
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default SMADetailsBody;
