import React, { useEffect, useReducer, useRef } from "react";

import {
  AnyPortfolioV2,
  BreakdownItem,
  PortfolioAnalysis,
  type PortfolioAssetCategories,
  type PortfolioAssetClasses,
} from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";
import { PortfolioId } from "@fartherfinance/frontend/api/Types";

import AssetCategoryView from "./AssetCategoryView";
import AssetView from "./AssetView";
import ModelView from "./ModelView";

import styles from "./TableViews.module.css";

type PortfolioAssetCategory = typeof PortfolioAssetCategories[number];
type PortfolioAssetClass = typeof PortfolioAssetClasses[number];

type State =
  | { tableView: "Model" }
  | {
      tableView: "AssetCategory";
      category: PortfolioAssetCategory;
    }
  | {
      tableView: "Asset";
      asset: BreakdownItem;
      assetClass: PortfolioAssetClass;
      category: PortfolioAssetCategory;
    };

type Action =
  | { type: "ViewModel" }
  | {
      type: "ViewAssetCategory";
      category: PortfolioAssetCategory;
    }
  | {
      type: "ViewAsset";
      asset: BreakdownItem;
      assetClass: PortfolioAssetClass;
      category: PortfolioAssetCategory;
    };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "ViewModel":
      return { tableView: "Model" };
    case "ViewAssetCategory":
      return {
        tableView: "AssetCategory",
        category: action.category,
      };
    case "ViewAsset":
      return {
        tableView: "Asset",
        asset: action.asset,
        assetClass: action.assetClass,
        category: action.category,
      };
    default:
      return state;
  }
};

interface Props {
  showDirectIndexedFilter: boolean;
  portfolio: AnyPortfolioV2;
  portfolioAnalysis: PortfolioAnalysis;
  headerStyle?: React.CSSProperties;
  height100?: boolean;
}

const TableViews = ({
  showDirectIndexedFilter,
  portfolio,
  portfolioAnalysis,
  headerStyle,
  height100,
}: Props): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, { tableView: "Model" });
  const portfolioIdRef = useRef<PortfolioId | null>(null);

  const portfolioId = portfolio.model.portfolioModelId;

  const getAssetClassesOfCategory = (category: PortfolioAssetCategory) => {
    return (portfolioAnalysis.portfolioAssetClassBreakdown ?? []).filter(
      (assetClassObj) => {
        const data = Object.values(assetClassObj)[0];
        return data.category === category;
      }
    );
  };

  useEffect(() => {
    if (portfolioIdRef.current !== portfolioId) {
      dispatch({ type: "ViewModel" });
      portfolioIdRef.current = portfolioId;
    }
  }, [portfolioId]);

  switch (state.tableView) {
    case "Model":
      return (
        <div
          className={styles.tableContainer}
          style={height100 ? { height: "100%" } : {}}
        >
          <ModelView
            showDirectIndexedFilter={showDirectIndexedFilter}
            portfolio={portfolio}
            portfolioAnalysis={portfolioAnalysis}
            setToCategoryView={(category: PortfolioAssetCategory) =>
              dispatch({ type: "ViewAssetCategory", category: category })
            }
            headerStyle={headerStyle}
          />
        </div>
      );

    case "AssetCategory":
      return (
        <div
          className={styles.tableContainer}
          style={height100 ? { height: "100%" } : {}}
        >
          <AssetCategoryView
            showDirectIndexedFilter={showDirectIndexedFilter}
            portfolio={portfolio}
            category={state.category}
            onBack={() => dispatch({ type: "ViewModel" })}
            assetClasses={getAssetClassesOfCategory(state.category)}
            setAsset={(
              assetClass: PortfolioAssetClass,
              breakdownItem: BreakdownItem
            ) => {
              dispatch({
                type: "ViewAsset",
                assetClass: assetClass,
                category: state.category,
                asset: breakdownItem,
              });
            }}
            headerStyle={headerStyle}
          />
        </div>
      );

    case "Asset":
      return (
        <div
          className={styles.tableContainer}
          style={height100 ? { height: "100%" } : {}}
        >
          <AssetView
            showDirectIndexedFilter={showDirectIndexedFilter}
            portfolio={portfolio}
            assetClass={state.assetClass}
            asset={state.asset}
            onBack={() =>
              dispatch({
                type: "ViewAssetCategory",
                category: state.category,
              })
            }
            headerStyle={headerStyle}
          />
        </div>
      );

    default:
      const _x: never = state;
      return <div>Error</div>;
  }
};

export default TableViews;
