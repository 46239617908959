import React, { ReactElement, useCallback, useMemo } from "react";

import { parse } from "date-fns";
import { isNil } from "lodash";
import { useHistory, useParams } from "react-router-dom";

import useClientAccounts from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAccounts";
import { ClientId, FartherAccountId } from "@fartherfinance/frontend/api/Types";

import { AccountsPath } from "@src/config/routing/RouterPaths";
import { dateFormat } from "@src/constants/dateFormat";
import SetGoalForm from "@src/multiCustodian/components/Client/Accounts/SharedComponents/SetGoalForm";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import AccountSettings from "@src/multiCustodian/pages/Client/:clientId/Accounts/Settings";
import AccountSummary from "@src/multiCustodian/pages/Client/:clientId/Accounts/Summary";
import Modal, { standardWidth } from "@src/sharedComponents/Modal/Modal";

const SetTargetGoal = (): ReactElement => {
  const { clientId, accountId, path } = useParams<{
    clientId: ClientId;
    accountId: FartherAccountId;
    path: "Summary" | "Settings";
  }>();

  const history = useHistory();

  const auth = useRequestAuth();

  const accounts = useClientAccounts(clientId, auth);

  const account = useMemo(() => {
    if (accounts.isLoading || accounts.hasError) {
      return accounts;
    }

    const curAccount = accounts.data.fartherAccounts.find(
      (acc) => acc.accountId === accountId
    );

    return {
      ...accounts,
      data: curAccount ?? null,
    };
  }, [accountId, accounts]);

  const closeModal = useCallback(() => {
    history.push(
      `/Client/${clientId}/${AccountsPath}/Managed/${accountId}/${path}`
    );
  }, [accountId, clientId, history, path]);

  const { targetDate, targetGoal } = account.data?.accountDetails ?? {};

  const curTargetDate = useMemo(() => {
    return isNil(targetDate) ? null : parse(targetDate, dateFormat, new Date());
  }, [targetDate]);

  const curTargetGoal = targetGoal ?? null;

  return (
    <>
      {path === "Summary" && (
        <AccountSummary>
          <Modal closeModal={closeModal} modalStyle={standardWidth}>
            <SetGoalForm
              closeModal={closeModal}
              accountId={accountId}
              clientId={clientId}
              currentTargetDate={curTargetDate}
              currentTargetGoal={curTargetGoal}
            />
          </Modal>
        </AccountSummary>
      )}

      {path === "Settings" && (
        <AccountSettings>
          <Modal closeModal={closeModal} modalStyle={standardWidth}>
            <SetGoalForm
              closeModal={closeModal}
              accountId={accountId}
              clientId={clientId}
              currentTargetDate={curTargetDate}
              currentTargetGoal={curTargetGoal}
            />
          </Modal>
        </AccountSettings>
      )}
    </>
  );
};

export default SetTargetGoal;
