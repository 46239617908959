import React, { useState } from "react";

import Dialog from "@mui/material/Dialog";
import HelloSign from "hellosign-embedded";

import useGetFartherAgreement from "@fartherfinance/frontend/api/Document/hooks/useGetFartherAgreement";
import usePutUpdateAgreement from "@fartherfinance/frontend/api/Document/hooks/usePutUpdateAgreement";
import getSignatureUrl from "@fartherfinance/frontend/api/Document/requests/getSignatureUrl";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useApplicationMode from "@src/multiCustodian/hooks/useApplicationMode";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { captureException } from "@src/multiCustodian/services/tracking";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./FartherAgreementsModal.module.css";

interface AgreementSectionProps {
  heading: string;
  summary: string;
}

const AgreementSection = ({
  heading,
  summary,
}: AgreementSectionProps): JSX.Element => {
  return (
    <div className={styles.agreement_div}>
      <p className={styles.agreement_heading}>{heading}</p>

      <p className={styles.agreement_summary}>{summary}</p>
    </div>
  );
};

interface FartherAgreementsModalProps {
  isModalOpen: boolean;
  clientId: ClientId | null;
  hideFartherAgreementsModal: () => void;
}

const FartherAgreementsModal = ({
  isModalOpen,
  clientId,
  hideFartherAgreementsModal,
}: FartherAgreementsModalProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);

  const { isImpersonationMode } = useApplicationMode();
  const auth = useRequestAuth();
  const fartherAgreement = useGetFartherAgreement(
    isImpersonationMode ? null : auth
  );
  const callPutUpdateAgreement = usePutUpdateAgreement(clientId, auth);
  const statusNotification = useStatusNotification();

  const openHelloSign = async () => {
    if (fartherAgreement.hasError) {
      captureException(
        new Error(
          "Error retrieving agreementId and signatureRequestId from doc/v2/client/agreements/farther - general Farther agreement GET endpoint"
        ),
        {
          extra: {
            clientId: clientId,
          },
        }
      );
      return;
    }

    if (fartherAgreement.isLoading || isImpersonationMode) {
      return;
    }

    if (
      auth &&
      fartherAgreement.data.agreementId &&
      fartherAgreement.data.status.toLowerCase() === "pending"
    ) {
      try {
        setIsLoading(true);

        const res_signatureUrl = await getSignatureUrl(
          fartherAgreement.data.agreementId,
          auth
        );

        if (res_signatureUrl) {
          const client = new HelloSign();

          client.open(res_signatureUrl, {
            clientId: process.env.HELLOSIGN_CLIENT_ID,
            skipDomainVerification: process.env.WEBAPP_ENV !== "PROD",
          });

          client.on("sign", async () => {
            try {
              await callPutUpdateAgreement({
                agreementId: fartherAgreement.data.agreementId,
                status: "Signed",
              });
              statusNotification("Document signed", "Success");
              hideFartherAgreementsModal();
            } catch (e) {
              statusNotification("Error signing document", "Error");
              captureException(
                e instanceof Error
                  ? e
                  : new Error(
                      "Error after signing farther general agreement: callPutUpdateAgreement"
                    )
              );
            } finally {
              setIsLoading(false);
            }
          });

          client.on("cancel", () => {
            setIsLoading(false);
          });

          client.on("error", async () => {
            setIsLoading(false);
            statusNotification("Error signing document on HelloSign", "Error");
            captureException(
              new Error(
                "HelloSign Error - error occurred within HelloSign client/modal while signing general Farther agreement"
              ),
              {
                extra: {
                  clientId: clientId,
                },
              }
            );
          });
        } else {
          setIsLoading(false);
          statusNotification("Document already signed", "Success");
        }
      } catch (e) {
        setIsLoading(false);
        statusNotification("Error opening HelloSign", "Error");
        captureException(
          e instanceof Error
            ? e
            : new Error(
                "Error hitting: getSignatureUrl, or opening HelloSign modal"
              )
        );
      }
    } else {
      captureException(
        new Error(
          "Farther agreement Error - auth, clientId, agreementId, or status is missing and/or wrong"
        ),
        {
          extra: {
            auth: auth,
            clientId: clientId,
            agreementId: fartherAgreement.data.agreementId,
            status: fartherAgreement.data.status,
          },
        }
      );
    }
  };

  if (fartherAgreement.hasError) {
    return (
      <Dialog
        open={isModalOpen}
        onClose={hideFartherAgreementsModal}
        PaperProps={{ elevation: 0, className: styles.paper }}
      >
        <div className={styles.modal_content}>
          <div>
            <p className={styles.title}>E-sign Farther Agreements</p>

            <p className={styles.description}>Error loading agreements</p>
          </div>
        </div>

        <div className={styles.modal_footer} />
      </Dialog>
    );
  }

  if ((fartherAgreement.isLoading && !isImpersonationMode) || isLoading) {
    return (
      <Dialog
        open={isModalOpen}
        onClose={hideFartherAgreementsModal}
        PaperProps={{ elevation: 0, className: styles.paper }}
      >
        <div className={styles.modal_content}>
          <div>
            <p className={styles.title}>E-sign Farther Agreements</p>

            <p className={styles.description}>
              Here is an overview of the Farther Agreements. Review the
              agreements and e-sign.
            </p>

            <div style={{ height: 20, width: "100%" }} />

            <Skeleton
              width={478 + 40} // 20 padding
              height={78 + 40} // 20 padding
              style={{ transform: "none" }}
            />

            <div style={{ height: 20, width: "100%" }} />

            <Skeleton
              width={478 + 40} // 20 padding
              height={78 + 40} // 20 padding
              style={{ transform: "none" }}
            />

            <div style={{ height: 20, width: "100%" }} />

            <Skeleton
              width={478 + 40} // 20 padding
              height={78 + 40} // 20 padding
              style={{ transform: "none" }}
            />
          </div>
        </div>

        <div className={styles.modal_footer}>
          <Skeleton
            width={103 + 32} // 16 padding
            height={25 + 12} // 6 padding
            style={{ transform: "none" }}
          />
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={isModalOpen}
      onClose={hideFartherAgreementsModal}
      PaperProps={{ elevation: 0, className: styles.paper }}
    >
      <>
        <div className={styles.modal_content}>
          <div>
            <p className={styles.title}>E-sign Farther Agreements</p>

            <p className={styles.description}>
              Here is an overview of the Farther Agreements. Review the
              agreements and e-sign.
            </p>

            <AgreementSection
              heading="Farther IAA"
              summary="This document details the terms and conditions of your
                    relationship with Farther as your Investment Advisor."
            />

            <AgreementSection
              heading="Farther ADV"
              summary="This document provides information about the qualifications and
                    business practices of Farther Finance Advisors, LLC."
            />

            <AgreementSection
              heading="Farther CRS"
              summary="This document details our investment advisory and financial planning services."
            />
          </div>
        </div>

        <div className={styles.modal_footer}>
          <Button
            style={{
              flexShrink: 0,
              cursor: isImpersonationMode ? "not-allowed" : "pointer",
            }}
            disabled={isImpersonationMode}
            variant="contained"
            buttonType="primary"
            text="Continue to Sign"
            onClick={() => openHelloSign()}
          />
        </div>
      </>
    </Dialog>
  );
};

export default FartherAgreementsModal;
