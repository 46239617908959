import React from "react";

import { range } from "lodash";

import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";

import EquivalentSecuritiesDrawerTableRowLoading from "./EquivalentSecuritiesDrawerTableRowLoading";

import styles from "./EquivalentSecuritiesDrawer.module.css";

const EquivalentSecuritiesDrawerTableLoading: React.FC = () => {
  return (
    <BorderBox className={styles.borderBox}>
      {range(3).map((_, idx) => (
        <EquivalentSecuritiesDrawerTableRowLoading key={idx} />
      ))}
    </BorderBox>
  );
};

export default EquivalentSecuritiesDrawerTableLoading;
