import React, { useMemo, useState } from "react";

import { useHistory, useLocation, useParams } from "react-router-dom";

import useCreateCustomPortfolioV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useCreateCustomPortfolioV2";
import { CustomPortfolioCreateUpdateV2Body } from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";
import { ClientId, PortfolioTaxType } from "@fartherfinance/frontend/api/Types";

import { TaxStatus } from "../Custom";
import SecuritiesAllocationConfirmTable, {
  AllocatedSecurity,
} from "@src/multiCustodian/components/Client/Portfolio/SecuritiesAllocationConfirmTable/SecuritiesAllocationConfirmTable";
import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

import BackAndExitButtonWrapper from "./BackAndExitButtonWrapper";

import styles from "./Confirm.module.css";

const isTaxType = (taxStatus: string): taxStatus is TaxStatus => {
  return (
    taxStatus === "Any" ||
    taxStatus === "Taxable" ||
    taxStatus === "Tax-Advantaged"
  );
};

const taxTypeToArgType: Record<TaxStatus, PortfolioTaxType> = {
  Any: "any",
  Taxable: "taxable",
  "Tax-Advantaged": "tax_exempt", //Although backend accepts a string of 'tax_exempt' even though we actually support a Tax-Advantaged type. This string cannot be changed on the backend yet.
};

const CustomPortfolioConfirm = (): JSX.Element => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const auth = useRequestAuth();

  const history = useHistory();

  const { search: urlSearchParams } = useLocation<{
    from: Location | undefined;
  }>();

  const goToCreateCustom = () => {
    history.push(`/Client/${clientId}/Investments/Portfolios/Create/Custom`);
  };

  const qs = useMemo(
    () => new URLSearchParams(urlSearchParams),
    [urlSearchParams]
  );

  const [isMutating, setIsMutating] = useState(false);

  const modelName = qs.get("name");

  const modelTaxStatus = qs.get("status");

  if (modelTaxStatus === null || !isTaxType(modelTaxStatus)) {
    goToCreateCustom();
  }

  const securities: AllocatedSecurity[] = useMemo(() => {
    const str = qs.get("securities") ?? "[]";

    const parsed = JSON.parse(str) as AllocatedSecurity[];

    return parsed;
  }, [qs]);

  const statusNotification = useStatusNotification();

  const createCustomPortfolio = useCreateCustomPortfolioV2(clientId, auth);

  const onConfirm = () => {
    if (modelTaxStatus === null || !isTaxType(modelTaxStatus)) {
      return goToCreateCustom();
    }

    if (modelName === null) {
      return statusNotification("Model name is missing", "Error");
    }

    if (securities.length === 0) {
      return statusNotification("No securities have been added", "Error");
    }

    setIsMutating(true);

    const body: CustomPortfolioCreateUpdateV2Body = {
      displayName: modelName,
      ownerId: clientId,
      directIndexing: false,
      description: "Custom Portfolio", //No UI field is available for this yet. This hard-coded description currently just helps the backend to debug.
      taxType: taxTypeToArgType[modelTaxStatus],
      positions: {
        securities: securities.map((s) => ({
          securityIdentifier: s.ticker,
          weight: Math.round(s.allocation * 100),
        })),
        sleeves: [],
      },
    };

    createCustomPortfolio(body)
      .then(({ message: portfolioId }) => {
        statusNotification("Custom portfolio created", "Success");

        history.push(
          `/Client/${clientId}/Investments/Portfolios/${portfolioId}`
        );
      })
      .catch(() => {
        setIsMutating(false);
        statusNotification("Failed to create a custom portfolio", "Error");
      });
  };

  const backButtonOnClick = () =>
    history.push({
      pathname: `/Client/${clientId}/Investments/Portfolios/Create/Custom/Allocation`,
      search: qs.toString(),
    });

  return (
    <BaseLayout showSideNav={false}>
      <BackAndExitButtonWrapper backButtonOnClick={backButtonOnClick}>
        <div className={styles.contentContainer}>
          <div>
            <div className={styles.titleDiv}>
              <div className={styles.title}>Confirm Selection</div>
              <div className={styles.modelTypePill}>{modelTaxStatus}</div>
            </div>

            <div className={styles.subTitleText}>
              Prior to finalizing your selections, please take a look below and
              confirm all the information is correct.
            </div>

            <div className={styles.tableContainer}>
              <SecuritiesAllocationConfirmTable
                portfolioName={modelName ? modelName : "Great Portfolio!"}
                securities={securities}
              />
            </div>
          </div>

          <div className={styles.footer}>
            <Button
              text="Confirm"
              onClick={onConfirm}
              variant="contained"
              buttonType="primary"
              disabled={isMutating}
            />
          </div>

          {isMutating && (
            <div className={styles.loading}>
              <LogoLoadingStill onTop={true} />
            </div>
          )}
        </div>
      </BackAndExitButtonWrapper>
    </BaseLayout>
  );
};

export default CustomPortfolioConfirm;
