import React, { useCallback, useMemo, useRef, useState } from "react";

import { TimeFrame } from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Types";
import useGetAccountGroupsV4 from "@fartherfinance/frontend/api/PerformanceGroups/hooks/useGetAccountGroupsV4";
import { PerformanceGroupId } from "@fartherfinance/frontend/api/Types";

import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import DropdownPopoverButton from "@src/sharedComponents/DropdownPopoverButton/DropdownPopoverButton";
import Popover from "@src/sharedComponents/Popover/Popover";

import CustomPerformanceGroupsDropdownMenu from "./DropdownMenu";

import styles from "./Dropdown.module.css";

interface Props {
  performanceGroupId: PerformanceGroupId;
  setAccountGroupId: (agId: PerformanceGroupId) => void;
  setAwaitingAccountGroupId: (agId: PerformanceGroupId) => void;
  setTimeFrame: (range: TimeFrame) => void;
  width?: number;
}

const CustomPerformanceGroupsDropdown: React.FC<Props> = ({
  performanceGroupId,
  setAccountGroupId,
  setAwaitingAccountGroupId,
  setTimeFrame,
  width,
}: Props) => {
  const anchorRef = useRef<HTMLDivElement | null>(null);

  const [showPopover, setShowPopover] = useState<boolean>(false);

  const auth = useRequestAuth();
  const accountGroups = useGetAccountGroupsV4(auth);

  const accountGroupName: string | undefined = useMemo(() => {
    if (accountGroups.isLoading || accountGroups.hasError) {
      return undefined;
    }

    const accountGroup = accountGroups.data.find(
      (ag) => ag.accountGroupId === performanceGroupId
    );
    if (accountGroup === undefined) {
      return undefined;
    }

    return accountGroup.displayName;
  }, [accountGroups, performanceGroupId]);

  const isDisabled = accountGroups.isLoading || accountGroups.hasError;

  const showOrHidePopover = () => {
    if (isDisabled) {
      return;
    }

    setShowPopover(!showPopover);
  };

  const setAccountGroupIdAndCloseMenu = useCallback(
    (agId: PerformanceGroupId) => {
      setAccountGroupId(agId);
      setShowPopover(false);
    },
    [setAccountGroupId, setShowPopover]
  );

  return (
    <Popover
      open={showPopover}
      onClose={() => setShowPopover(false)}
      anchor={anchorRef.current}
      transformOriginVertical={-10}
      popoverElement={
        <div ref={anchorRef}>
          <DropdownPopoverButton
            text={accountGroupName ?? "Loading..."}
            showPopover={showOrHidePopover}
            className={styles.dropdownButton}
            textClassName={styles.dropdownButtonText}
            iconClassName={styles.dropdownButtonIcon}
            minWidth={width}
            disabled={isDisabled}
          />
        </div>
      }
    >
      <CustomPerformanceGroupsDropdownMenu
        width={width}
        accoundGroupId={performanceGroupId}
        setAccountGroupId={setAccountGroupIdAndCloseMenu}
        setAwaitingAccountGroupId={setAwaitingAccountGroupId}
        closeDropdown={() => setShowPopover(false)}
        setTimeFrame={setTimeFrame}
      />
    </Popover>
  );
};

export default CustomPerformanceGroupsDropdown;
