import React, { useCallback, useMemo, useState } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { orderBy, range } from "lodash";
import { useParams } from "react-router-dom";

import useGetFartherManagedAccounts from "@fartherfinance/frontend/api/Accounts/hooks/useGetFartherManagedAccounts";
import useCreateTradingGroup from "@fartherfinance/frontend/api/TradingGroups/hooks/useCreateTradingGroup";
import useTradingGroupsForPortfolio from "@fartherfinance/frontend/api/TradingGroups/hooks/useTradingGroupsForPortfolio";
import {
  ClientId,
  FartherAccountId,
  PortfolioId,
} from "@fartherfinance/frontend/api/Types";

import { TAX_CHARACTERISTICS_ACROSS_ACCOUNTS_TOOLTIP_TEXT } from "../const";
import CreateTradingGroupTable from "@src/multiCustodian/components/Client/Portfolio/TradingGroupsTable/CreateTradingGroupTable";
import TradingGroupsTable from "@src/multiCustodian/components/Client/Portfolio/TradingGroupsTable/TradingGroupsTable";
import TradingGroupsTableLoading from "@src/multiCustodian/components/Client/Portfolio/TradingGroupsTable/TradingGroupsTableLoading";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { captureException } from "@src/multiCustodian/services/tracking";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import styles from "./PortfolioAccounts.module.css";

interface Props {
  isDerived: boolean;
}

const FAMPortfolioContent = ({ isDerived }: Props): JSX.Element => {
  const { clientId, portfolioId } = useParams<{
    clientId: ClientId;
    portfolioId: PortfolioId;
  }>();

  const auth = useRequestAuth();

  const accounts = useGetFartherManagedAccounts(clientId, auth);
  const tradingGroups = useTradingGroupsForPortfolio(
    clientId,
    portfolioId,
    auth
  );

  const fartherAccounts = useMemo(() => {
    return accounts.data ?? [];
  }, [accounts.data]);

  const createTradingGroup = useCreateTradingGroup(clientId, auth);
  const [isMutating, setIsMutating] = useState<boolean>(false);

  const statusNotification = useStatusNotification();

  const callCreateTradingGroup = useCallback(
    async (accountIds: FartherAccountId[]) => {
      const body = {
        displayName: "New Trading Group",
        accounts: [...accountIds],
        portfolioId: portfolioId,
        isActive: true,
        directIndexing: false,
        taxLossHarvesting: false,
        equivalentSecuritiesEnabled: false,
      };

      try {
        setIsMutating(true);
        await createTradingGroup(body);
        statusNotification("Trading group created", "Success");
      } catch (e) {
        console.error(e);
        statusNotification("Error creating trading group", "Error");
        captureException(e, {
          extra: {
            clientId: clientId,
            portfolioId: portfolioId,
            op: "createTradingGroup",
            body: body,
          },
        });
      } finally {
        setIsMutating(false);
      }
    },
    [clientId, createTradingGroup, portfolioId, statusNotification]
  );

  const callCreateTradingGroupMultipleTimes = useCallback(
    async (accountIds: FartherAccountId[]) => {
      setIsMutating(true);

      const promises = accountIds.map((accountId, i) => {
        const body = {
          displayName: `New Trading Group ${i + 1}`,
          accounts: [accountId],
          portfolioId: portfolioId,
          isActive: true,
          directIndexing: false,
          taxLossHarvesting: false,
          equivalentSecuritiesEnabled: false,
        };
        return createTradingGroup(body);
      });

      await Promise.allSettled(promises).then((results) => {
        results.forEach((result, i) => {
          if (result.status === "fulfilled") {
            setTimeout(() => {
              statusNotification("Trading group created", "Success");
            }, i * 500);
          } else {
            // "rejected"
            setTimeout(() => {
              statusNotification("Error creating trading group", "Error");
            }, i * 500);
          }
        });
      });

      setIsMutating(false);
    },
    [createTradingGroup, portfolioId, statusNotification]
  );

  const tradingGroupsToShow = useMemo(
    () =>
      orderBy(
        (tradingGroups.data ?? []).filter((tg) => tg.accounts.length > 0),
        [(tg) => tg.displayName],
        ["asc"]
      ),
    [tradingGroups]
  );

  if (tradingGroups.isLoading || accounts.isLoading) {
    return (
      <>
        {range(2).map((idx) => (
          <TradingGroupsTableLoading key={`loading-table-${idx}`} />
        ))}
      </>
    );
  }

  if (tradingGroups.hasError || accounts.hasError) {
    return <div>Error loading data</div>;
  }

  return (
    <div className={styles.content}>
      <div className={styles.heading}>
        <p className={styles.tradingGroupsHeaderText}>Trading Groups</p>

        <Tooltip
          tooltipText={TAX_CHARACTERISTICS_ACROSS_ACCOUNTS_TOOLTIP_TEXT}
          placement={"top"}
        >
          <InfoOutlinedIcon className={styles.infoIcon} />
        </Tooltip>
      </div>

      {isMutating ? (
        range(Math.max(tradingGroupsToShow.length, 1)).map((idx) => (
          <TradingGroupsTableLoading key={`loading-table-${idx}`} />
        ))
      ) : (
        <>
          {tradingGroupsToShow.length > 0 ? (
            <>
              {tradingGroupsToShow.map((tg) => (
                <TradingGroupsTable
                  key={tg.groupId}
                  tradingGroupId={tg.groupId}
                  portfolioId={portfolioId}
                  fartherAccounts={fartherAccounts}
                />
              ))}
            </>
          ) : (
            <p className={styles.noTradingGroupsText}>
              No active trading groups
            </p>
          )}

          {!isDerived && (
            <CreateTradingGroupTable
              clientId={clientId}
              portfolioId={portfolioId}
              onCreate={callCreateTradingGroup}
              onCreateMultipleIndividually={callCreateTradingGroupMultipleTimes}
            />
          )}
        </>
      )}
    </div>
  );
};

export default FAMPortfolioContent;
