import React, { useMemo, useState } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Plaid } from "plaid-link";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import useClientDashboard, {
  ClientId,
} from "@fartherfinance/frontend/api/Dashboard/hooks/useClientDashboard";
import useInvalidateClientDashboard from "@fartherfinance/frontend/api/Dashboard/hooks/useInvalidateClientAccounts";
import usePlaidRelink from "@fartherfinance/frontend/api/ExternalAccount/hooks/usePlaidRelink";
import getPlaidLinkUpdateToken from "@fartherfinance/frontend/api/ExternalAccount/requests/getPlaidLinkUpdateToken";
import { LinkId } from "@fartherfinance/frontend/api/ExternalAccount/requests/types";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { captureException } from "@src/multiCustodian/services/tracking";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";
import { State as ReduxState } from "@src/store";

import pollClientDashboard from "./pollClientDashboard";

import styles from "./Relink.module.css";

const Relink = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isAdvisor, chevron_right } = useSelector((state: ReduxState) => ({
    isAdvisor: state.main_Reducer.user.isAdvisor,
    farther_jwt: state.main_Reducer.farther_jwt,
    chevron_right: state.main_Reducer.brand.current.images.chevron_right,
  }));

  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const history = useHistory();

  const statusNotification = useStatusNotification();

  const auth = useRequestAuth();

  const plaidRelink = usePlaidRelink(auth);

  const dashboard = useClientDashboard(clientId, auth);
  const invalidateDashboard = useInvalidateClientDashboard();

  const institutionsToRelink = useMemo(() => {
    if (dashboard.isLoading || dashboard.hasError) {
      return dashboard;
    }

    return {
      ...dashboard,
      data: dashboard.data.externalAccountDetails.links.filter((link) => {
        return link.status === "Error" || link.status === "Expired";
      }),
    };
  }, [dashboard]);

  const relink = async (linkId: LinkId) => {
    if (isLoading || isAdvisor) {
      return false;
    }

    if (!clientId || !auth) {
      const err = new Error("clientId or auth is null");

      captureException(err, {
        extra: {
          clientId: clientId ?? "null",
          auth: auth ?? "null",
        },
      });

      throw err;
    }

    setIsLoading(true);

    const handleExit: Plaid.OnExit = () => {
      setIsLoading(false);
    };

    const linkEnv = window.location.href.includes("localhost")
      ? "localhost"
      : window.location.href.includes("uat-stagingapp")
      ? "uat-stagingapp"
      : null;

    try {
      const res = await getPlaidLinkUpdateToken(linkId, linkEnv, auth);

      const handler = window.Plaid.create({
        token: res.token,
        clientName: "Farther",
        countryCodes: ["US"],
        env: process.env.WEBAPP_ENV == "PROD" ? "production" : "sandbox",
        language: "en",
        webhook: process.env.PLAID_FARTHER_WEBHOOK_URL,
        onLoad: () => undefined,
        onSuccess: () => relinkPlaidItem(linkId),
        onExit: handleExit,
      });

      handler.open();
    } catch (e) {
      console.error(e);
      captureException(e, {
        extra: {
          client: clientId,
          linkEnv: linkEnv,
          linkId: linkId,
          call: "getPlaidUpdateLinkToken or window.Plaid.create()",
          file: "Relink.tsx",
        },
      });
      statusNotification("Error opening Plaid client", "Error");
      setIsLoading(false);
    }
  };

  const relinkPlaidItem = async (linkId: LinkId) => {
    try {
      await plaidRelink(linkId);

      statusNotification("Institution relinked", "Success");

      pollClientDashboard(
        clientId,
        auth,
        statusNotification,
        dashboard.data,
        invalidateDashboard,
        "Retrieving newly relinked accounts...",
        "Relinked accounts retrieved",
        "Error retrieving newly relinked accounts"
      );
    } catch (_e) {
      statusNotification("Failed to relink institution", "Error");
    } finally {
      setIsLoading(false);
    }
  };

  if (dashboard.isLoading || institutionsToRelink.isLoading) {
    return (
      <div className={styles.container}>
        <div className={styles.loadingContainer}>
          <LogoLoadingStill />
        </div>
      </div>
    );
  }

  if (dashboard.hasError || institutionsToRelink.hasError) {
    return (
      <div className={styles.container}>
        <div className={styles.loadingContainer}>Error</div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <LogoLoadingStill />
        </div>
      ) : (
        <>
          {institutionsToRelink.data.length > 0 ? (
            <div className={styles.contentContainer}>
              <div className={styles.mainDiv}>
                <p className={styles.title}>Institution update</p>

                <div className={styles.flexDiv}>
                  <p className={styles.text}>
                    Please choose which institution you'd like to update.
                  </p>

                  <Tooltip
                    tooltipText="Common reasons why you need to relink your institution(s)
                    are password changes, address changes, deactivated or closed
                    accounts, and institution security changes."
                    placement="bottom"
                  >
                    <InfoOutlinedIcon className={styles.tooltipImg} />
                  </Tooltip>
                </div>

                {institutionsToRelink.data.map((inst) => {
                  return (
                    <div
                      key={inst.institutionId}
                      className={styles.institutionDiv}
                      onClick={() => relink(inst.linkId as LinkId)}
                      style={{
                        cursor: isAdvisor ? "not-allowed" : "pointer",
                      }}
                    >
                      <p className={styles.textBold}>{inst.institutionName}</p>
                      <img src={chevron_right} className={styles.arrowImg} />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className={styles.contentContainer}>
              <div className={styles.doneContainer}>
                <p className={styles.doneTitle}>Perfect!</p>

                <p className={styles.doneText}>
                  All of your institutions are up-to-date
                </p>

                <Button
                  variant={"contained"}
                  buttonType={"primary"}
                  text={"Finish"}
                  onClick={() => history.push(`/Client/${clientId}/Dashboard`)}
                  style={{ marginBottom: "40px" }}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Relink;
