import React from "react";

import Accounts from "../../../Accounts";
import ManualAssetDrawer from "@src/multiCustodian/components/Client/Accounts/Manual/ManualAssetDrawer";
import SummaryTab from "@src/multiCustodian/components/Client/Accounts/Manual/SummaryTab/SummaryTab";
import PerformanceCustomDateRangeContextProvider from "@src/multiCustodian/components/PerformanceGroups/Summary/components/PerformanceCustomDateRangeContextProvider";

const Summary = (): JSX.Element => {
  return (
    <Accounts>
      <PerformanceCustomDateRangeContextProvider>
        <ManualAssetDrawer curTab="Summary">
          <SummaryTab />
        </ManualAssetDrawer>
      </PerformanceCustomDateRangeContextProvider>
    </Accounts>
  );
};

export default Summary;
