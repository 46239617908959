import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";

import { orderBy, sumBy } from "lodash";

import useGetAllHoldingsV3 from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Holdings/useGetAllHoldingsV3";
import { CategoryOfClasses } from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Holdings/utils";
import usePerformanceGroupHoldings from "@fartherfinance/frontend/api/PerformanceGroups/hooks/usePerformanceGroupHoldings";
import {
  AssetClass,
  AssetClassCategory,
} from "@fartherfinance/frontend/api/PerformanceGroups/requests/getHoldings";
import {
  HookResult,
  PerformanceGroupId,
} from "@fartherfinance/frontend/api/Types";
import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import {
  AnyCellItem,
  CellItem,
  DrillInLevel,
  DrillInState,
} from "../../Client/Accounts/Holdings/Components/Types";
import narrowAssetCategory from "../../Client/Accounts/Holdings/utils/narrowAssetCategory";
import reduceHoldingsBreakdown from "../../Client/Accounts/Holdings/utils/reduceHoldingsBreakdown";
import useAssetCategoryColors from "../../Client/Accounts/Holdings/utils/useAssetCategoryColors";
import BarGraph from "@src/multiCustodian/components/Client/Accounts/Holdings/Components/BarGraph/BarGraph";
import HoldingsTable, {
  SecuritiesWithExtraData,
} from "@src/multiCustodian/components/Client/Accounts/Holdings/Components/BreakdownTable/HoldingsTable";
import HoldingsTableLoading from "@src/multiCustodian/components/Client/Accounts/Holdings/Components/BreakdownTable/HoldingsTableLoading";
import CategoryOrClassChips from "@src/multiCustodian/components/Client/Accounts/Holdings/Components/CategoryOrClassChips/CategoryOrClassChips";
import DrillInChipsHiearchy from "@src/multiCustodian/components/Client/Accounts/Holdings/Components/DrillInChipsHiearchy/DrillInChipsHiearchy";
import calculateProfitPercent from "@src/multiCustodian/components/Client/Accounts/Holdings/utils/calculateProfitPercent";
import generateColorShadeFromBaseColor from "@src/multiCustodian/components/Client/Accounts/Holdings/utils/generateColorShadeFromBaseColor";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import { Disclaimer } from "@src/multiCustodian/pages/Dashboard/Performance_Groups/PG_hardcoded_exports";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./Holdings.module.css";

type AssetClassCategoryObj = Record<AssetClassCategory, CategoryOfClasses>;

const defaultDrillInState = {
  category: null,
  class: null,
};

interface Props {
  groupId: PerformanceGroupId | null;
}

export default function Holdings(props: PropsWithChildren<Props>): JSX.Element {
  const {
    color: { $iconSubtle },
  } = useTheme();

  const allCategoriesItem = useMemo(() => {
    return {
      name: "All Categories",
      percent: 1,
      color: $iconSubtle,
      level: "All" as DrillInLevel,
      dontDrillIn: false,
    };
  }, [$iconSubtle]);

  const [curGroupId, setCurGroupId] = useState<PerformanceGroupId | null>(
    props.groupId
  );

  const [drillInStateUnmemoed, setDrillInState] =
    useState<DrillInState>(defaultDrillInState);

  const assetCategoryColors = useAssetCategoryColors();

  const auth = useRequestAuth();

  const allHoldings = useGetAllHoldingsV3(props.groupId, auth);

  const holdings = usePerformanceGroupHoldings(props.groupId, auth);

  useEffect(() => {
    setDrillInState(defaultDrillInState); // reset state when groupId changes
    setCurGroupId(props.groupId);
  }, [props.groupId]);

  const drillInState: DrillInState = useMemo(() => {
    // curGroupId could be old -> props.groupId could be new/diff
    return curGroupId === props.groupId
      ? drillInStateUnmemoed
      : defaultDrillInState;
  }, [props.groupId, curGroupId, drillInStateUnmemoed]);

  const transformedholdings: HookResult<AssetClassCategoryObj | null> =
    useMemo(() => {
      if (holdings.isLoading || holdings.hasError) {
        return holdings;
      }

      const sanitizedHoldings: AssetClassCategoryObj = reduceHoldingsBreakdown(
        holdings.data.categories
      );

      return {
        ...holdings,
        data: sanitizedHoldings,
      };
    }, [holdings]);

  const drillInItems = useMemo((): CellItem[] | undefined => {
    if (transformedholdings.data === undefined) {
      return undefined;
    }

    if (transformedholdings.data === null) {
      return [];
    }

    const items = [allCategoriesItem]; // base item which is always shown

    // First drill-in level: category
    const category: AssetClassCategory | null = drillInState.category;
    if (category) {
      items.push({
        name: category,
        percent: transformedholdings.data[category].percentOfTotal,
        color: assetCategoryColors[category],
        level: "Category" as DrillInLevel,
        dontDrillIn: false,
      });
    }

    // Second drill-in level: asset class
    const assetClass: AssetClass | null = drillInState.class;
    if (category && assetClass) {
      const orderedAssetClasses = orderBy(
        transformedholdings.data[category].classes,
        [(c) => c.percentOfTotal],
        ["desc"]
      );

      const assetClassIndex = orderedAssetClasses.findIndex(
        (c) => c.class === assetClass
      );

      if (assetClassIndex < 0) {
        throw new Error(
          `Can't find ${assetClass} from array of classes within ${category}`
        );
      }

      const assetClassData = orderedAssetClasses[assetClassIndex];

      // derive the selected Asset Class color by feeding these and the assetClassIndex into generateColorShadeFromBaseColor
      const darkBaseAssetClassColor = assetCategoryColors[category];
      const numAssetClasses = transformedholdings.data[category].classes.length;

      items.push({
        name: assetClass,
        percent: assetClassData.percentOfTotal,
        color: generateColorShadeFromBaseColor(
          darkBaseAssetClassColor,
          assetClassIndex,
          numAssetClasses,
          true
        ),
        level: "Asset Class" as DrillInLevel,
        dontDrillIn: false,
      });
    }

    return items;
  }, [
    transformedholdings.data,
    drillInState,
    allCategoriesItem,
    assetCategoryColors,
  ]);

  const barChartData = useMemo((): AnyCellItem[] | undefined => {
    if (transformedholdings.data === undefined) {
      return undefined;
    }

    if (transformedholdings.data === null) {
      return [];
    }

    // Base/init level: display category percentage makeup
    const category: AssetClassCategory | null = drillInState.category;
    if (category === null) {
      const assetCategories = Object.keys(
        transformedholdings.data
      ) as AssetClassCategory[];

      const barChartItems = assetCategories.map((cat) => {
        if (transformedholdings.data === null) {
          throw new Error("transformedholdings.data === null in barChartData");
        }

        return {
          name: transformedholdings.data[cat].category,
          percent: transformedholdings.data[cat].percentOfTotal,
          color: assetCategoryColors[cat],
          level: "Category" as DrillInLevel,
          dontDrillIn: assetCategories.length === 1,
        };
      });

      return orderBy(barChartItems, [(el) => el.percent], ["desc"]);
    }

    // First drill-in level: display asset class percentage makeup
    const assetCategory: CategoryOfClasses = transformedholdings.data[category];
    const Cash = "Cash" as AssetClassCategory;

    const barChartItems = assetCategory.classes.map((ac) => {
      return {
        name:
          assetCategory.classes.length === 1 && ac.class === "Other"
            ? category
            : ac.class, // don't want to display "Other" as "Asset Class" in tooltip, just use category name instead for that case
        percent: ac.percentOfCategory,
        level: "Asset Class" as DrillInLevel,
        dontDrillIn: assetCategory.classes.length === 1,
      };
    });

    return orderBy(barChartItems, [(el) => el.percent], ["desc"]).map(
      (el, idx) => {
        return {
          ...el,
          color:
            category === Cash
              ? assetCategoryColors[Cash] // use the primary color assigned to Cash category
              : generateColorShadeFromBaseColor(
                  assetCategoryColors[category],
                  idx,
                  barChartItems.length,
                  barChartItems.length !== 1
                ),
        };
      }
    );
  }, [
    transformedholdings.data,
    drillInState.category,
    // drillInState.class // don't update bar chart data if this changes as bar chart should display the same state: asset classes when an asset class chip has been clicked
    assetCategoryColors,
  ]);

  const selectableChips = useMemo((): CellItem[] | undefined => {
    if (transformedholdings.data === undefined) {
      return undefined;
    }

    if (transformedholdings.data === null) {
      return [];
    }

    // First drill-in level
    if (drillInState.category === null) {
      const assetCategories = Object.keys(
        transformedholdings.data
      ) as AssetClassCategory[];

      const chips = assetCategories.map((category) => {
        if (transformedholdings.data === null) {
          throw new Error(
            "transformedholdings.data === null in selectableChips"
          );
        }

        return {
          name: transformedholdings.data[category].category,
          percent: transformedholdings.data[category].percentOfTotal,
          color: assetCategoryColors[category],
          level: "Category" as DrillInLevel,
          dontDrillIn: false,
        };
      });

      return orderBy(chips, [(el) => el.percent], ["desc"]);
    }

    // Second drill-in level
    const assetCategory: CategoryOfClasses =
      transformedholdings.data[drillInState.category];

    const chips = assetCategory.classes.map((assetClass) => {
      return {
        name: assetClass.class,
        percent: assetClass.percentOfCategory,
        level: "Asset Class" as DrillInLevel,
        dontDrillIn: false,
      };
    });

    return orderBy(chips, [(el) => el.percent], ["desc"]).map((chip, idx) => {
      if (drillInState.category === null) {
        throw new Error(
          "drillInState.category === null - this can't be null here"
        );
      }

      return {
        ...chip,
        color: generateColorShadeFromBaseColor(
          assetCategoryColors[drillInState.category],
          idx,
          chips.length,
          true
        ),
      };
    });
  }, [transformedholdings.data, drillInState, assetCategoryColors]);

  const taxLotsWithExtraData: HookResult<SecuritiesWithExtraData[] | null> =
    useMemo(() => {
      if (holdings.isLoading || holdings.hasError) {
        return holdings;
      }

      const category: AssetClassCategory | null = drillInState.category;
      const categories =
        category === null
          ? holdings.data.categories
          : holdings.data.categories.filter(
              (c) => narrowAssetCategory(c.category) === category // category on right (from state) side will already be narrowed
            ); // filter by drill-ins selected

      const assetClass: AssetClass | null = drillInState.class;
      const allSecurities = categories.flatMap((c) =>
        c.classes
          .filter((cl) =>
            assetClass === null ? true : cl.class === assetClass
          )
          .flatMap((cl) => cl.securities)
      );

      const securitiesWithProfit = allSecurities.map((s) => ({
        ...s,
        profit: calculateProfitPercent(s),
      }));

      return {
        ...holdings,
        data: securitiesWithProfit,
      };
    }, [holdings, drillInState]);

  const totalValue = useMemo(() => {
    return holdings.data === undefined
      ? 0
      : sumBy(holdings.data.categories, (item) => item.marketValue);
  }, [holdings.data]);

  if (
    props.groupId === null ||
    holdings.hasError ||
    taxLotsWithExtraData.hasError ||
    (holdings.data !== undefined && holdings.data.categories.length === 0)
  ) {
    return (
      <div className={styles.container}>
        <div className={styles.errorDiv}>Holdings data unavailable</div>

        <Spacer verticalSpacing="32px" />

        <Disclaimer />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <DrillInChipsHiearchy
          items={drillInItems}
          drillInState={drillInState}
          setDrillInState={setDrillInState}
        />

        {props.children && <div className={styles.sideMargin} />}

        {/* If in Dashboard, account group dropdown will come in from props.children */}
        {props.children}
      </div>

      {!props.children && <Spacer verticalSpacing="15px" />}

      {barChartData === undefined ? (
        <Skeleton height={40} />
      ) : (
        <BarGraph
          data={barChartData}
          drillInState={drillInState}
          setDrillInState={setDrillInState}
        />
      )}

      {drillInState.class === null &&
        selectableChips &&
        selectableChips.length > 1 && (
          <>
            <Spacer verticalSpacing="16px" />

            {selectableChips === undefined ? (
              <Skeleton height={30} />
            ) : (
              <CategoryOrClassChips
                data={selectableChips}
                drillInState={drillInState}
                setDrillInState={setDrillInState}
              />
            )}
          </>
        )}

      <Spacer verticalSpacing="24px" />

      {taxLotsWithExtraData.isLoading ? (
        <HoldingsTableLoading />
      ) : (
        <HoldingsTable
          totalSecuritiesValue={totalValue}
          securities={taxLotsWithExtraData.data ?? []}
          taxLots={allHoldings.data?.taxLots ?? []}
          drillInState={drillInState}
        />
      )}

      <Spacer />

      <Disclaimer />
    </div>
  );
}
