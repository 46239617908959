import React, { useCallback, useState } from "react";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { InputAdornment } from "@mui/material";
import { isAxiosError } from "axios";
import { useParams } from "react-router-dom";

import useHypotheticalTaxBudget from "@fartherfinance/frontend/api/InvestmentModel/hooks/useHypotheticalTaxBudget";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import { actions } from "../../reducer/actions";
import { useCreateEditTaxBudgetContext } from "../../reducer/Context";
import { ErrorMessage } from "../../reducer/types";
import { modelIdIsNotNull } from "../../utils/modelIsNotNull";
import { positiveNumericRegex } from "../../utils/positiveNumericRegex";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";
import BorderBoxBody from "@src/sharedComponents/BorderBox/BorderBoxBody";
import BorderBoxHeader from "@src/sharedComponents/BorderBox/BorderBoxHeader";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import TextInput from "@src/sharedComponents/TextInput/TextInput";

import styles from "./ScenarioSettings.module.css";

interface Props {
  setIsOpen: () => void;
}

const ScenarioSettingsOpened: React.FC<Props> = ({ setIsOpen }) => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const statusNotification = useStatusNotification();

  const clientAuth = useRequestAuth();
  const advisorReqAuth = useAdvisorRequestAuth();
  const advisorAuth = advisorReqAuth
    ? { ...advisorReqAuth, clientId: clientId }
    : null;
  const auth = clientAuth ? clientAuth : advisorAuth;

  const callGetHypotheticalProposal = useHypotheticalTaxBudget(auth);

  const { state, dispatch } = useCreateEditTaxBudgetContext();

  const {
    data: {
      budgetAmount,
      clientAccountIds,
      excludedVirtualAccountIds,
      hypotheticalTradeGroups,
    },
  } = state;

  const [amount, setAmount] = useState<string>(`${budgetAmount}`);

  const onHandleAmount = useCallback((e) => {
    if (!e || e.target.value === "") {
      setAmount("0");
      return;
    }

    let value = e.target.value;
    while (value.startsWith("0")) {
      value = value.slice(1);
    }

    if (positiveNumericRegex.test(value)) {
      setAmount(value);
    }
  }, []);

  const resetBudgetAmount = useCallback(() => {
    setAmount(`${budgetAmount}`);
  }, [budgetAmount]);

  const handleOnClickEditAccounts = useCallback(
    () => dispatch({ type: actions.SET_PAGE_INDEX, payload: 0 }),
    [dispatch]
  );

  const handleOnClickEditHypotheticalModels = useCallback(
    () => dispatch({ type: actions.SET_PAGE_INDEX, payload: 3 }),
    [dispatch]
  );

  const getHypotheticalProposal = useCallback(async () => {
    try {
      setIsLoading(true);

      const tradeGroups = hypotheticalTradeGroups.map((htg) => {
        if (!modelIdIsNotNull(htg.hypotheticalModelId)) {
          throw new Error("hypotheticalModelId cannot be null!");
        }

        return {
          clientId: clientId,
          modelId: htg.hypotheticalModelId,
          virtualAccountIds: htg.virtualAccountIds,
        };
      });

      const response = await callGetHypotheticalProposal({
        tradingGroups: tradeGroups,
        taxBudget: {
          budgetAmount: parseInt(amount),
          excludedVirtualAccountIds: excludedVirtualAccountIds,
        },
      });

      dispatch({ type: actions.SET_HYPOTHETICAL_PROPOSAL, payload: response });
    } catch (error) {
      if (isAxiosError<ErrorMessage>(error)) {
        const message =
          error.response?.data.message ??
          "Unexpected error from investment model service.";

        return statusNotification(message, "Error");
      }

      statusNotification("Something went wrong. Please try again.", "Error");
    } finally {
      setIsLoading(false);
    }
  }, [
    amount,
    callGetHypotheticalProposal,
    clientId,
    dispatch,
    excludedVirtualAccountIds,
    hypotheticalTradeGroups,
    statusNotification,
  ]);

  const setYearAndBudgetAmount = useCallback(() => {
    dispatch({
      type: actions.SET_BUDGET_AMOUNT,
      payload: parseInt(amount === "" ? "0" : amount),
    });

    getHypotheticalProposal();
  }, [dispatch, amount, getHypotheticalProposal]);

  return (
    <>
      {isLoading && <LogoLoadingStill onTop />}

      <BorderBox className={styles.borderBox}>
        <BorderBoxHeader
          className={styles.borderBoxHeader2}
          start={"Scenario Settings"}
          end={
            <ExpandLessIcon className={styles.chevronUp} onClick={setIsOpen} />
          }
        />

        <BorderBoxBody className={styles.borderBoxBody} showTopBorder={false}>
          <div className={styles.flexRow}>
            <TextInput
              label={<div className={styles.label}>Budget Amount</div>}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              value={amount}
              onChange={onHandleAmount}
            />
          </div>

          <div className={styles.flexRow}>
            <BorderBox className={styles.borderBox2}>
              <BorderBoxHeader
                className={styles.borderBoxHeader2}
                start={
                  <div className={styles.start}>
                    {`Accounts (${
                      clientAccountIds.length - excludedVirtualAccountIds.length
                    })`}
                  </div>
                }
                end={
                  <div
                    className={styles.edit}
                    onClick={handleOnClickEditAccounts}
                  >
                    Edit
                  </div>
                }
              />
            </BorderBox>

            <BorderBox className={styles.borderBox2}>
              <BorderBoxHeader
                className={styles.borderBoxHeader2}
                start={
                  <div className={styles.start}>
                    {`Hypothetical Models (${hypotheticalTradeGroups.length})`}
                  </div>
                }
                end={
                  <div
                    className={styles.edit}
                    onClick={handleOnClickEditHypotheticalModels}
                  >
                    Edit
                  </div>
                }
              />
            </BorderBox>
          </div>

          <div className={styles.flexRowEnd}>
            <Button
              variant={"outlined"}
              buttonType={"secondary"}
              text={"Cancel"}
              onClick={resetBudgetAmount}
            />

            <Button
              variant={"contained"}
              buttonType={"primary"}
              text={"Update"}
              onClick={setYearAndBudgetAmount}
              disabled={parseInt(amount) === budgetAmount}
            />
          </div>
        </BorderBoxBody>
      </BorderBox>
    </>
  );
};

export default ScenarioSettingsOpened;
