import React, { useEffect, useState } from "react";

import { uniq } from "lodash";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import useValidateEssentialPortfolioV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useValidateEssentialPortfolioV2";
import {
  CreateEssentialPortfolio,
  UpdateEssentialPortfolio,
} from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";

import AdvisorContainer from "../../AdvisorContainer";
import NewPartnerBody from "@src/multiCustodian/components/Advisor/Investments/ModelMarketplace/AddNewPartner/NewPartnerBody";
import NewPartnerBodyLoading from "@src/multiCustodian/components/Advisor/Investments/ModelMarketplace/AddNewPartner/NewPartnerBodyLoading";
import CSVFormModal from "@src/multiCustodian/components/Advisor/Investments/SharedComponents/CSVFormModal/CSVFormModal";
import useIsAdmin from "@src/multiCustodian/components/Advisor/utils/useIsAdmin";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { State } from "@src/store";

type Portfolio = CreateEssentialPortfolio | UpdateEssentialPortfolio;

const NewPartner = (): JSX.Element => {
  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));

  const history = useHistory();

  const auth = useAdvisorRequestAuth();

  const statusNotification = useStatusNotification();

  const [pageState, setPageState] = useState<"CSV_UPLOAD" | "MODEL_EDIT">(
    "CSV_UPLOAD"
  );

  const [mutating, setMutating] = useState(false);

  const [csvFile, setCSVFile] = useState<File | null>(null);

  const [csvError, setCSVError] = useState<string[] | null>(null);

  const [partnerName, setPartnerName] = useState<string | null>(null);

  const [partnerModels, setPartnerModels] = useState<Portfolio[] | null>(null);

  const validateEssentialModelsInCSV = useValidateEssentialPortfolioV2(auth);

  const isAdmin = useIsAdmin(advisorId);

  useEffect(() => {
    if (isAdmin.data === false) {
      history.push({
        pathname: "/Advisor/Investments/ModelMarketplace",
        state: history.location.state,
      });
    }
  }, [isAdmin, history]);

  useEffect(() => {
    if (csvFile === null && pageState !== "CSV_UPLOAD") {
      //If theres no file, we have no data to display in the model_updates table
      setPageState("CSV_UPLOAD");
    }
  }, [csvFile, pageState]);

  const addNewFile = (newFile: File | null) => {
    setCSVFile(newFile);

    if (newFile === null) {
      setPartnerModels(null);
    }

    if (csvError !== null && newFile !== null) {
      //Only remove an existing error if a new file has been provided
      setCSVError(null);
    }
  };

  const uploadCSVFile = async () => {
    if (csvFile === null) {
      statusNotification("Please attach a CSV document", "Error");
      return;
    }

    if (mutating) {
      statusNotification("Uploading your document, please wait", "Error");
      return;
    }

    try {
      setMutating(true);

      const res = await validateEssentialModelsInCSV({
        file: csvFile,
        action: "Create",
      });
      if (res.status === "valid") {
        const portfolios = res.data.portfolios;

        if (portfolios.length <= 0) {
          setCSVError([".csv file is empty"]);
          setCSVFile(null);
          return;
        }

        const partners = uniq(portfolios.map((p) => p.set));
        const mainPartner = partners[0];
        setPartnerName(mainPartner);
        setPartnerModels(portfolios);
        setPageState("MODEL_EDIT");

        statusNotification("CSV validation successful", "Success");

        const areAnyModelsDirectIndexed = portfolios.some(
          (p) => p.directIndexing === true && p.set !== "FAM"
        );
        if (areAnyModelsDirectIndexed) {
          statusNotification(
            "Direct indexing for non-FAM models is not available at this time",
            "Error"
          );
        }
      } else {
        setCSVError(res.data.errors);
      }
    } catch (e) {
      if (e instanceof Error) {
        statusNotification(e.message, "Error");
      }
    } finally {
      setMutating(false);
    }
  };

  if (isAdmin.isLoading || isAdmin.hasError) {
    return <div>Error retrieving employee status</div>;
  }

  if (pageState === "CSV_UPLOAD" || partnerModels === null) {
    return (
      <AdvisorContainer>
        <CSVFormModal
          formObjective="Create New Partner"
          uploadType={"Partner"}
          csvFile={csvFile}
          csvError={csvError}
          addNewFile={addNewFile}
          mutating={mutating}
          uploadCSVFile={uploadCSVFile}
          closeModal={
            partnerModels === null
              ? () =>
                  history.push({
                    pathname: "/Advisor/Investments/ModelMarketplace",
                    state: history.location.state,
                  })
              : () => setPageState("MODEL_EDIT")
          }
        />
      </AdvisorContainer>
    );
  }

  return (
    <AdvisorContainer hideNavbar={true}>
      {partnerModels === null || partnerName === null || mutating ? (
        <NewPartnerBodyLoading />
      ) : (
        <NewPartnerBody
          partnerName={partnerName}
          directIndexing={partnerModels.some((m) => m.directIndexing === true)}
          models={partnerModels}
          goToUploadCSV={() => setPageState("CSV_UPLOAD")}
          csvFile={csvFile}
        />
      )}
    </AdvisorContainer>
  );
};

export default NewPartner;
