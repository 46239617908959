import React, { useCallback, useMemo } from "react";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CircleIcon from "@mui/icons-material/Circle";
import Chip from "@mui/material/Chip";
import { format, formatDistanceToNow, isBefore, startOfDay } from "date-fns";
import { useHistory, useLocation } from "react-router-dom";

import { GetOpportunitiesQueryParams } from "@fartherfinance/frontend/api/Opportunities/requests/getOpportunities";
import { ListOpportunity } from "@fartherfinance/frontend/api/Opportunities/Types";
import { OpportunityId } from "@fartherfinance/frontend/api/Types";
import { PaginationHeaders } from "@fartherfinance/frontend/api/utils/parsePaginationHeaders";

import { OPPORTUNITIES_DEFAULT_PAGE } from "../../common";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import { UNKNOWN_VALUE } from "@src/multiCustodian/pages/Advisor/common";
import {
  getOpportunityClientName,
  mapValueToLabel,
  opportunityPriorityMap,
  opportunityStatusMap,
  opportunityTypeMap,
} from "@src/multiCustodian/pages/Advisor/Opportunities/utils";
import { mapToSortOrder } from "@src/multiCustodian/utils/sorting";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import Message from "@src/sharedComponents/Message/Message";
import PaginatedTable from "@src/sharedComponents/Table/PaginatedTable";
import { TableSortOrder } from "@src/sharedComponents/Table/Types";

import { createTableColumns } from "./OpportunitiesTable.utils";

import styles from "./OpportunitiesTable.module.css";

export type OpportunitiesTableColumns =
  | keyof Pick<
      ListOpportunity,
      | "client"
      | "type"
      | "status"
      | "priority"
      | "createdTs"
      | "updatedTs"
      | "dueDate"
    >
  | "action";

interface OpportunitiesTableProps {
  opportunities: ListOpportunity[];
  isLoading: boolean;
  isErrored: boolean;
  pagination: PaginationHeaders | undefined;
  sort: GetOpportunitiesQueryParams["sort"];
  onQueryParamChange: (newQueryParams: GetOpportunitiesQueryParams) => void;
}

export default function OpportunitiesTable({
  opportunities,
  isLoading,
  isErrored,
  pagination,
  sort,
  onQueryParamChange,
}: OpportunitiesTableProps): JSX.Element {
  const history = useHistory();
  const location = useLocation();

  const handleOnPageChange = (page: number): void => {
    onQueryParamChange({ page });
  };

  const handleOnColumnSelect = (
    column: OpportunitiesTableColumns,
    tableSortOrder: TableSortOrder
  ): void => {
    if (column !== "action") {
      onQueryParamChange({
        sort: `${mapToSortOrder(tableSortOrder)}${column}`,
        page: OPPORTUNITIES_DEFAULT_PAGE,
      });
    }
  };

  const handleNavigateToOpportunityDetails = useCallback(
    (opportunityId: OpportunityId): void => {
      history.push({
        ...location,
        pathname: `/Advisor/Opportunities/${opportunityId}`,
        search: location.search,
      });
    },
    [history, location]
  );

  const tableHeaders = useMemo(() => createTableColumns(sort), [sort]);
  const tableRows = useMemo(
    () =>
      opportunities.map((opportunity) => ({
        key: opportunity.id,
        client: getOpportunityClientName(opportunity.client),
        type: mapValueToLabel(opportunityTypeMap, opportunity.type),
        status: {
          value: (
            <Chip
              classes={{
                root: styles.chip,
                label: styles.chipLabel,
                icon: toClassName(styles.chipIcon, {
                  [styles.notStartedColor]:
                    opportunity.status === "NOT_STARTED",
                  [styles.inProgressColor]:
                    opportunity.status === "IN_PROGRESS",
                  [styles.awaitingResponseColor]:
                    opportunity.status === "AWAITING_RESPONSE",
                  [styles.snoozedColor]: opportunity.status === "SNOOZED",
                  [styles.completedColor]: opportunity.status === "CANCELED",
                  [styles.completedColor]:
                    opportunity.status === "COMPLETED_WITH_ASSETS_WON" ||
                    opportunity.status === "COMPLETED_NO_ASSETS_WON",
                }),
              }}
              variant="outlined"
              label={mapValueToLabel(opportunityStatusMap, opportunity.status)}
              icon={<CircleIcon />}
            />
          ),
        },
        priority: {
          value: (
            <Chip
              classes={{
                root: styles.chip,
                label: styles.chipLabel,
                icon: toClassName(styles.chipIcon, {
                  [styles.lowPrioColor]: opportunity.priority === "LOW",
                  [styles.mediumPrioColor]: opportunity.priority === "MEDIUM",
                  [styles.highPrioColor]: opportunity.priority === "HIGH",
                }),
              }}
              variant="outlined"
              label={mapValueToLabel(
                opportunityPriorityMap,
                opportunity.priority
              )}
              icon={<CircleIcon />}
            />
          ),
        },
        createdTs: formatDistanceToNow(opportunity.createdTs, {
          addSuffix: true,
        }),
        updatedTs: formatDistanceToNow(opportunity.updatedTs, {
          addSuffix: true,
        }),
        dueDate: {
          value: opportunity.dueDate ? (
            <span
              className={toClassName({
                [styles.overdueColor]: isBefore(
                  opportunity.dueDate,
                  startOfDay(new Date())
                ),
              })}
            >
              {format(opportunity.dueDate, "MM/dd/yyyy")}
            </span>
          ) : (
            UNKNOWN_VALUE
          ),
        },
        action: {
          value: (
            <Button
              sx={{
                minWidth: "unset",
                padding: "8.25px 10px",
              }}
              style={{ display: "inline-block" }}
              variant="contained"
              buttonType="primary"
              text={<ArrowForwardIcon className={styles.arrowIcon} />}
              onClick={() => handleNavigateToOpportunityDetails(opportunity.id)}
            />
          ),
          hideUnlessHovering: true,
        },
        onClick: () => handleNavigateToOpportunityDetails(opportunity.id),
      })),
    [opportunities, handleNavigateToOpportunityDetails]
  );

  if (isErrored) {
    return <Message text="Something went wrong, please try again later" />;
  }

  if (opportunities.length === 0 && !isLoading) {
    return <Message text="No results found" />;
  }

  return (
    <PaginatedTable
      isLoading={isLoading}
      columns={tableHeaders}
      rows={tableRows}
      pagination={pagination}
      onPageChange={handleOnPageChange}
      onColumnSelect={handleOnColumnSelect}
    >
      <colgroup>
        <col width="13.9%" />
        <col width="13.9%" />
        <col width="13.9%" />
        <col width="13.9%" />
        <col width="13.9%" />
        <col width="13.9%" />
        <col width="13.9%" />
        <col width="2.7%" />
      </colgroup>
    </PaginatedTable>
  );
}
