import React, { useCallback } from "react";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import { State } from "@src/store";

import * as styles from "./ExitButton.module.css";

interface Props {
  isPositionedAbsolute?: boolean;
  overrideBackRoute?: () => void;
}

export default function ExitButton({
  isPositionedAbsolute = true,
  overrideBackRoute,
}: Props): JSX.Element {
  const { clientId, small_x_icon } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
    small_x_icon: state.main_Reducer.brand.current.images.small_x_icon,
  }));
  const history = useHistory();

  const notify = useStatusNotification();

  const goHome = useCallback(() => {
    if (clientId === null) {
      notify("Bad Client Id", "Error");
      return;
    }
    history.push(`/Client/${clientId}`);
  }, [clientId, history, notify]);

  return (
    <img
      className={toClassName(styles.exitButton, {
        [styles.exitButtonAbsolute]: isPositionedAbsolute,
      })}
      src={small_x_icon}
      onClick={overrideBackRoute ?? goHome}
    />
  );
}
