import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { toUserDto } from "../dto";
import { usersRepository } from "../repository";

import type { GetUsersResponse, User } from "../types";

const { getUsers } = usersRepository;

export const useUserDetailsQuery = (
  userId: User["id"],
  options?: UseQueryOptions<
    GetUsersResponse,
    unknown,
    GetUsersResponse,
    string[]
  >
) => {
  const query = useQuery(
    [getUsers.queryKey, userId],
    async () => {
      const response = await getUsers.queryCall({ searchById: userId });
      return response.data;
    },
    { staleTime: Infinity, ...options }
  );
  const { data } = query;

  return {
    ...query,
    data: data ? data.values.map(toUserDto) : undefined,
    totalCount: 1,
  };
};
