import React from "react";

import styles from "./PerformanceGroups.module.css";

const disclaimer =
  "This material includes information drawn from third-party sources believed reliable but not independently verified or guaranteed by Farther. The material contained herein should not be construed as recommendations or investment advice and should be used only for informational purposes. Data and calculations are generally as of the close of the prior business day unless otherwise indicated.";

export const Disclaimer = () => {
  return (
    <div className={styles.disclaimer}>
      <p>{disclaimer}</p>
    </div>
  );
};
