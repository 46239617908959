import React, { PropsWithChildren } from "react";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import MuiTableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import IconButton from "../IconButton/IconButton";
import { captureException } from "@src/multiCustodian/services/tracking";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import TableCell from "./TableCell/TableCell";
import TablePaginationActions from "./TablePaginationActions/TablePaginationActions";
import TableRow from "./TableRow/TableRow";

import styles from "./Components.module.css";

import type { Cell, Row, TableSortOrder } from "./Types";

interface FartherTableProps {
  isLayoutFixed?: boolean;
  className?: string;
}

export const FartherTable = (props: PropsWithChildren<FartherTableProps>) => (
  <Table
    className={toClassName(props.className, {
      [styles.tableFixedLayout]: props.isLayoutFixed,
    })}
  >
    {props.children}
  </Table>
);

export const FartherTableContainer = (props: PropsWithChildren<unknown>) => (
  <TableContainer className={styles.container} component={Paper}>
    {props.children}
  </TableContainer>
);

export const FartherTableHead = (props: PropsWithChildren<unknown>) => (
  <TableHead className={styles.header}>
    <MuiTableRow>{props.children}</MuiTableRow>
  </TableHead>
);

export const FartherTableBody = (props: PropsWithChildren<unknown>) => (
  <TableBody>{props.children}</TableBody>
);

type TableBodyRowProps<Keys extends string, C extends Cell> = {
  columns: NonEmptyArray<Keys> | Keys[];
  row: Row<Keys, C>;
} & (
  | { expandedState: "Expanded" | "Compact"; expandedToggle: () => void }
  | { expandedState: "NotExpandable" }
);

export function FartherTableBodyRow<Keys extends string, C extends Cell>({
  columns,
  row,
  children,
  ...props
}: PropsWithChildren<TableBodyRowProps<Keys, C>>): JSX.Element {
  const onClick: (() => void) | "ExpandRow" | undefined =
    "onClick" in row ? row.onClick : undefined;

  const rowOnClick: (() => void) | undefined =
    onClick === "ExpandRow" && props.expandedState !== "NotExpandable"
      ? props.expandedToggle
      : onClick === "ExpandRow"
      ? () => {
          const e = new Error("Trying to expand row when not available");
          console.error(e);
          captureException(e);
        }
      : onClick;

  return (
    <>
      <TableRow
        onClick={rowOnClick}
        className={toClassName({
          [styles.noBorder]:
            React.isValidElement(children) &&
            props.expandedState === "Expanded",
        })}
      >
        {({ isHovering }) => (
          <>
            {columns.map((h) => {
              const cell: C = row[h];
              const cellIsString = typeof cell === "string";

              if (cellIsString) {
                return (
                  <FartherTableBodyCell key={`${row.key}-${h}`}>
                    {cell}
                  </FartherTableBodyCell>
                );
              }

              // hide if cell has `hideUnlessHovering` set to true and we are not hovering
              const isHidden: boolean =
                typeof cell === "object" &&
                "hideUnlessHovering" in cell &&
                cell.hideUnlessHovering === true
                  ? !isHovering
                  : false;

              return (
                <FartherTableBodyCell key={`${row.key}-${h}`}>
                  {isHidden ? (
                    <span style={{ visibility: "hidden" }}>{cell.value}</span>
                  ) : (
                    cell.value
                  )}
                </FartherTableBodyCell>
              );
            })}

            {props.expandedState !== "NotExpandable" ? (
              <FartherTableBodyCell>
                {React.isValidElement(children) === false ? null : (
                  <IconButton
                    iconClassName={toClassName(styles.expandIcon, {
                      [styles.expandIconHover]: isHovering,
                    })}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.expandedToggle();
                    }}
                    IconComponent={
                      props.expandedState === "Compact"
                        ? ExpandMoreIcon
                        : ExpandLessIcon
                    }
                  />
                )}
              </FartherTableBodyCell>
            ) : null}
          </>
        )}
      </TableRow>

      {React.isValidElement(children) && props.expandedState === "Expanded" ? (
        <TableRow>{() => children}</TableRow>
      ) : null}
    </>
  );
}

interface FartherTableCellProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  colSpan?: number;
}

export const FartherTableHeaderCell = (
  props: PropsWithChildren<FartherTableCellProps>
) => (
  <TableCell
    className={toClassName(styles.headerCell, props.className)}
    style={props.style}
    onClick={props.onClick}
    colSpan={props.colSpan}
  >
    {props.children}
  </TableCell>
);

export const FartherTableBodyCell = (
  props: PropsWithChildren<FartherTableCellProps>
) => (
  <TableCell
    className={toClassName(styles.bodyCell, props.className)}
    style={props.style}
    onClick={props.onClick}
    colSpan={props.colSpan}
  >
    {props.children}
  </TableCell>
);

interface FartherTablePaginationProps {
  className?: string;
  pageSize: number;
  currentPage: number;
  totalCount: number;
  onPageChange: (page: number) => void;
  isPrevButtonDisabled: boolean;
  isNextButtonDisabled: boolean;
  hidePagination?: boolean;
}

export const FartherTablePagination = (props: FartherTablePaginationProps) => (
  <TablePagination
    component="div"
    classes={{
      root: toClassName(styles.footer, props.className),
      toolbar: props.hidePagination
        ? styles.footerToolbarNone
        : styles.footerToolbar,
      displayedRows: styles.footerLabel,
    }}
    rowsPerPage={props.pageSize}
    rowsPerPageOptions={[props.pageSize]}
    page={props.currentPage}
    count={props.totalCount}
    onPageChange={(_, page) => props.onPageChange(page)}
    ActionsComponent={(actionProps) => (
      <TablePaginationActions
        {...actionProps}
        isPrevButtonDisabled={props.isPrevButtonDisabled}
        isNextButtonDisabled={props.isNextButtonDisabled}
      />
    )}
  />
);

interface FartherTableSortLabelProps {
  disabled?: boolean;
  active?: boolean;
  direction?: TableSortOrder;
  onClick?: () => void;
}

export const FartherTableSortLabel = (
  props: PropsWithChildren<FartherTableSortLabelProps>
) => (
  <TableSortLabel
    classes={{
      root: styles.sortLabelRoot,
      active: styles.sortLabelActive,
      icon: styles.sortLabelIcon,
    }}
    disabled={props.disabled}
    active={props.active}
    direction={props.direction}
    onClick={props.onClick}
    IconComponent={(iconProps) =>
      props.disabled ? (
        <></>
      ) : (
        <ArrowUpwardIcon
          {...iconProps}
          className={toClassName(iconProps.className, styles.arrowIcon)}
        />
      )
    }
  >
    {props.children}
  </TableSortLabel>
);
