import React, { useState } from "react";

import { Box, Stack, Typography } from "@mui/material";

import useGetClickToSignDocUrl from "@fartherfinance/frontend/api/Document/hooks/useGetClickToSignDocUrl";
import { Agreement } from "@fartherfinance/frontend/api/Document/requests/getAgreements";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useApplicationMode from "@src/multiCustodian/hooks/useApplicationMode";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import Checkbox from "@src/sharedComponents/Checkbox/Checkbox";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import Modal from "@src/sharedComponents/Modal/Modal";

import styles from "./ClickToSignModal.module.css";

interface ClickToSignModalProps {
  agreement: Agreement;
  onClose: () => void;
  onSign: () => void;
}

const ClickToSignModal = ({
  agreement,
  onClose,
  onSign,
}: ClickToSignModalProps) => {
  const [isSigned, setIsSigned] = useState(false);

  const auth = useRequestAuth();
  const { isImpersonationMode } = useApplicationMode();

  const { data: clickToSignDocumentUrl, isLoading } = useGetClickToSignDocUrl(
    agreement.accountType,
    auth
  );

  return (
    <Modal closeModal={onClose} modalStyle={{ width: "490px" }}>
      <Stack gap="4px">
        <FormH1>Click to Sign</FormH1>
        <Typography className={styles.secondaryText}>
          Please read the contents below and check the e-sign box for
          confirmation.
        </Typography>
      </Stack>

      <Stack mt="20px" gap="10px">
        <Box className={styles.agreementContainer}>
          <Stack gap="15px">
            <Typography className={styles.agreementTitle}>
              {agreement.documentTitle}
            </Typography>
            <Typography className={styles.agreementSummary}>
              {`This document details the terms and conditions associated with
                    your ${
                      agreement.documentTitle.toLowerCase() ?? "account"
                    }.`}
            </Typography>
            <a
              className={styles.clickToSignLink}
              href={clickToSignDocumentUrl}
              target="_blank"
              rel="noreferrer"
            >
              {isLoading
                ? "Loading..."
                : clickToSignDocumentUrl
                ? "Learn More"
                : "Document unavailable"}
            </a>
          </Stack>
        </Box>

        <Checkbox
          checked={agreement.status === "Signed" ? true : isSigned}
          onChange={
            isImpersonationMode || agreement.status === "Signed"
              ? () => undefined
              : () => setIsSigned(!isSigned)
          }
          label={
            "By checking the box, you are agreeing to all of our terms set forth in the above agreement with an e-signature, the legal equivalent of a written signature."
          }
        />
      </Stack>

      <Stack
        direction="row"
        gap="16px"
        marginTop="40px"
        justifyContent="flex-end"
      >
        <Button
          variant="outlined"
          buttonType="primary"
          text="Cancel"
          onClick={onClose}
        />
        <Button
          disabled={!isSigned || isImpersonationMode}
          variant="contained"
          buttonType="primary"
          text={agreement.status === "Signed" ? "Already Signed" : "Sign"}
          onClick={onSign}
        />
      </Stack>
    </Modal>
  );
};

export default ClickToSignModal;
