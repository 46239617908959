import React from "react";

import { AltAssetsMarketplacePath } from "@src/config/routing/RouterPaths";
import { altAssetOfferingTypes } from "@src/multiCustodian/components/Marketplace/constants";
import OfferingDetailsClient from "@src/multiCustodian/components/Marketplace/OfferingDetails/OfferingDetailsClient";

import AltAssetsOfferingsListClient from "./AltAssetsOfferingsListClient";

const AltAssetsOfferingDetailsClient: React.FC = () => {
  return (
    <AltAssetsOfferingsListClient>
      <OfferingDetailsClient
        offeringTypes={altAssetOfferingTypes}
        marketplacePath={AltAssetsMarketplacePath}
      />
    </AltAssetsOfferingsListClient>
  );
};

export default AltAssetsOfferingDetailsClient;
