import { useEffect } from "react";

import { useSelector } from "react-redux";

import useStatusNotification from "../useStatusNotification";
import { State } from "@src/store";

import useImpersonateClient from "./useImpersonateClient";

const useStopImpersonation = () => {
  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));

  const impersonateClient = useImpersonateClient();

  const statusNotification = useStatusNotification();

  useEffect(() => {
    if (clientId === null) {
      return;
    }

    impersonateClient(null).catch((e) => {
      console.error(e);
      statusNotification("Could not stop impersonating", "Error");
    });
  }, [clientId, impersonateClient, statusNotification]);
};

export default useStopImpersonation;
