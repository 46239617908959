import React from "react";

import { Box, Stack } from "@mui/material";

import OpportunityAdditionalDetails from "../OpportunityAdditionalDetails";
import { opportunitySnoozingPeriodMap } from "@src/multiCustodian/pages/Advisor/Opportunities/utils";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import FormTextareaField from "@src/sharedComponents/Forms/FormTextareaField";

import styles from "./OpportunitySnoozedForm.module.css";

const periodOptions = Array.from(opportunitySnoozingPeriodMap.entries()).map(
  ([value, label]) => ({ value, label })
);

export default function OpportunitySnoozedForm(): JSX.Element {
  return (
    <Stack className={styles.container}>
      <Box>
        <Box className={styles.snoozeDropdown}>
          <FormDropdownField
            name="period"
            label="Snooze Duration"
            required="Please select a snoozing period."
            values={periodOptions}
            getOptionKey={(option) => option.value}
            disableSearch
          />
        </Box>
        <FormTextareaField
          containerClassName={styles.snoozeReasonTextarea}
          name="snoozeDetails"
          label="What is the reason?"
          required="Please provide a reason for snoozing the opportunity."
          placeholder="Explain..."
          minRows={1}
        />
      </Box>
      <OpportunityAdditionalDetails fieldName="snoozeExtraDetails" />
    </Stack>
  );
}
