import { endOfDay, parseISO, startOfDay } from "date-fns";

import { ProposalsFilterQueryParams } from "@fartherfinance/frontend/api/Rebalance/requests/getProposals";

import type { FormData } from "./ProposalsFilterForm.component";

const toDate = (value?: string) => (value ? parseISO(value) : undefined);

export const transformFilterQueryParamsToFormValues = (
  filters: ProposalsFilterQueryParams
): FormData => {
  const {
    filterByCustodian,
    filterByIsMaterialOrMultiDayExecution,
    filterByTimestampFrom,
    filterByTimestampTo,
  } = filters;

  return {
    filterByCustodian,
    // NOTE: we need to reverse the value to match field label wording
    filterByIsMaterialOrMultiDayExecution:
      !filterByIsMaterialOrMultiDayExecution,
    filterByTimestampFrom: toDate(filterByTimestampFrom),
    filterByTimestampTo: toDate(filterByTimestampTo),
  };
};

export const transformFormValuesToFilterQueryParams = (
  formData: FormData
): ProposalsFilterQueryParams => {
  const {
    filterByCustodian,
    filterByIsMaterialOrMultiDayExecution,
    filterByTimestampFrom,
    filterByTimestampTo,
  } = formData;

  return {
    filterByCustodian:
      filterByCustodian && filterByCustodian.length
        ? filterByCustodian
        : undefined,
    filterByIsMaterialOrMultiDayExecution:
      !filterByIsMaterialOrMultiDayExecution,
    filterByTimestampFrom: filterByTimestampFrom
      ? startOfDay(filterByTimestampFrom).toISOString()
      : undefined,
    filterByTimestampTo: filterByTimestampTo
      ? endOfDay(filterByTimestampTo).toISOString()
      : undefined,
  };
};
