/* eslint-disable import/no-unused-modules */
import React, { ComponentProps, useCallback, useMemo, useState } from "react";

import { add, isAfter, isBefore, isValid } from "date-fns";
import { Controller, Message, useFormContext } from "react-hook-form";

import DatePicker from "../DatePicker/DatePicker";

import FormFieldLabel from "./Private/FormFieldLabel";

type BeforeAfterCheck = "BeforeToday" | "AfterToday";

export interface Props {
  name: string;
  label?: React.ReactNode;
  labelTooltip?: string;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  required?: Message | false;
  dateCheck?: BeforeAfterCheck;
  rules?: ComponentProps<typeof Controller>["rules"];
  validate?: (date: Date) => boolean | Message;
  onBlur?: () => void;
  disableOpenPicker?: boolean;
  inputFormat?: "MM/dd/yyyy" | "yyyy";
  placeholder?: string;
  helperText?: string;
}

export default function FormDateField({ ...props }: Props): JSX.Element {
  const [text, setText] = useState("");
  const [firstBlur, setFirstBlur] = useState(false);

  const { control } = useFormContext();

  const tomorrow = useMemo(() => add(new Date(), { days: 1 }), []);

  const validator = useCallback(
    (value): boolean | string => {
      if (!isValid(value)) {
        return "Invalid date";
      }

      if (value instanceof Date) {
        if (props.validate) {
          return props.validate(value);
        }

        if (props.dateCheck) {
          const now = new Date();

          switch (props.dateCheck) {
            case "AfterToday":
              return isAfter(value, now) ? true : "Date must be after today";

            case "BeforeToday":
              return isBefore(value, now) ? true : "Date must be before today";
          }
        }

        return true;
      } else {
        if (props.required && firstBlur) {
          if (text.length !== "xx/xx/xxxx".length) {
            return "This is not a date";
          }
          return "This is a required field";
        }
        return false;
      }
    },
    [firstBlur, props, text.length]
  );

  return (
    <Controller
      name={props.name}
      control={control}
      rules={{
        required: props.required,
        validate: {
          ...(props.required !== undefined && { validDate: validator }),
        },
        ...props.rules,
      }}
      render={({ field, fieldState }) => (
        <DatePicker
          label={
            <FormFieldLabel
              label={props.label}
              labelTooltip={props.labelTooltip}
            />
          }
          value={field.value ?? null}
          onChange={field.onChange}
          minDate={
            props.minDate ??
            (props.dateCheck === "AfterToday" ? tomorrow : undefined)
          }
          placeholder={props.placeholder}
          maxDate={props.maxDate}
          disabled={props.disabled}
          error={fieldState.invalid}
          helperText={fieldState.error?.message ?? props.helperText}
          onBlur={() => {
            field.onBlur();
            setFirstBlur(true);
            props.onBlur?.();
          }}
          onTextChange={setText}
          disableOpenPicker={props.disableOpenPicker}
          inputFormat={props.inputFormat}
        />
      )}
    />
  );
}
