import React, { PropsWithChildren } from "react";

import { PerformanceGroupId } from "@fartherfinance/frontend/api/Types";

import Holdings from "@src/multiCustodian/components/PerformanceGroups/Holdings/Holdings";

import styles from "./HoldingsTab.module.css";

interface Props {
  groupId: PerformanceGroupId | null;
}

export default function HoldingsTab(
  props: PropsWithChildren<Props>
): JSX.Element {
  return (
    <Holdings groupId={props.groupId}>
      <div className={styles.accountGroupDropdownContainer}>
        {props.children}
      </div>
    </Holdings>
  );
}
