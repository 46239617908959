import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useStyles } from "./TableRotatedLegend.styles";

interface TableRotatedLegendProps {
  className?: string;
  legendTitle: string;
}

export const TableRotatedLegend: React.FC<TableRotatedLegendProps> = ({
  className,
  legendTitle,
}) => {
  const { classes, cx } = useStyles();

  return (
    <Box className={cx(classes.legendContainer, className)}>
      <Typography className={classes.legendText} noWrap>
        {legendTitle}
      </Typography>
    </Box>
  );
};
