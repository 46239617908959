import { useMemo } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { pipe } from "lodash/fp";
import orderBy from "lodash/orderBy";

import {
  taskStatusTypeMap,
  taskTypeStatusMap,
} from "@fartherfinance/frontend/api/Tasks/constants/options";
import useGetAdvisors from "@fartherfinance/frontend/api/Tasks/hooks/persons/useGetAdvisors";
import useGetAssignees from "@fartherfinance/frontend/api/Tasks/hooks/persons/useGetAssignees";
import useGetClients from "@fartherfinance/frontend/api/Tasks/hooks/persons/useGetClients";
import {
  AssigneeType,
  Task,
  TaskComment,
  TaskHumanManipulator,
  TaskManipulator,
  TaskManipulatorType,
  TaskStatus,
  TaskType,
} from "@fartherfinance/frontend/api/Tasks/Types";
import { isGroupAssignee } from "@fartherfinance/frontend/api/Tasks/utils/isGroupAssignee";

import useAdvisorDetails from "@src/multiCustodian/hooks/Advisor/useAdvisorDetails";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import { mapPersonToOption } from "@src/multiCustodian/pages/Advisor/utils";

import { mapAssigneeToOption } from "./utils";

export const useTaskStatusOptions = (taskType?: TaskType) => {
  const { enableNewTaskStatus, enableTaskCanceledStatus } = useFlags();

  const taskStatuses = useMemo(() => {
    return taskType ? taskTypeStatusMap[taskType] : taskTypeStatusMap["all"];
  }, [taskType]);

  return useMemo(
    () =>
      pipe<TaskStatus[][], TaskStatus[], TaskStatus[]>(
        (taskStatuses) =>
          enableNewTaskStatus
            ? taskStatuses.filter(
                (taskStatus) => taskStatus !== TaskStatus.enum.AWAITING_RESPONSE
              )
            : taskStatuses.filter((option) => !option.startsWith("WAITING_ON")),
        (taskStatuses) =>
          enableTaskCanceledStatus
            ? taskStatuses
            : taskStatuses.filter(
                (taskStatus) => taskStatus !== TaskStatus.enum.CANCELED
              )
      )(taskStatuses),
    [taskStatuses, enableNewTaskStatus, enableTaskCanceledStatus]
  );
};

export const useTaskTypeOptions = (
  taskStatus?: TaskStatus,
  isSystemGenerated?: boolean
) => {
  const taskTypes = useMemo(() => {
    return taskStatus
      ? taskStatusTypeMap[taskStatus]
      : taskStatusTypeMap["all"];
  }, [taskStatus]);

  return useMemo(
    () =>
      isSystemGenerated
        ? taskTypes
        : taskTypes.filter(
            (taskType) => taskType !== TaskType.enum.LIQUIDATION
          ),
    [taskTypes, isSystemGenerated]
  );
};

export const useClientsOptions = () => {
  const auth = useAdvisorRequestAuth();
  const clientsQuery = useGetClients(auth);

  return useMemo(
    () =>
      orderBy(
        clientsQuery.data?.clients.map(mapPersonToOption) ?? [],
        (entity) => entity.label
      ),

    [clientsQuery.data]
  );
};

export const useAdvisorsOptions = () => {
  const auth = useAdvisorRequestAuth();
  const advisorsQuery = useGetAdvisors(auth);

  return useMemo(
    () =>
      orderBy(
        advisorsQuery.data?.advisors.map(mapPersonToOption) ?? [],
        (entity) => entity.label
      ),

    [advisorsQuery.data]
  );
};

// NOTE: this dictates which assignee type should be listed first
const assigneeTypePriority: AssigneeType[] = [
  AssigneeType.enum.GROUP,
  AssigneeType.enum.PERSON,
];

export const useAssigneesOptions = () => {
  const auth = useAdvisorRequestAuth();
  const assigneesQuery = useGetAssignees(auth);

  return useMemo(() => {
    const assignees = assigneesQuery.data?.assignees ?? [];

    return orderBy(assignees, [
      (assignee) => assigneeTypePriority.indexOf(assignee.type),
      (assignee) =>
        isGroupAssignee(assignee)
          ? assignee.groupName
          : assignee.personName.first,
    ]).map(mapAssigneeToOption);
  }, [assigneesQuery.data]);
};

type UpdatePermission = { isAllowedToUpdate: boolean };
type DeletePermission = { isAllowedToDelete: boolean };

interface TaskingPermissions {
  taskPermissions: (task: Task) => DeletePermission;
  commentPermissions: (
    comment: TaskComment
  ) => UpdatePermission & DeletePermission;
  attachmentPermissions: (
    taskOrComment: Task | TaskComment
  ) => DeletePermission;
}

const isTask = (value: Task | TaskComment): value is Task => {
  return "creator" in value;
};

const isHumanManipulator = (
  manipulator: TaskManipulator
): manipulator is TaskHumanManipulator => {
  return manipulator.type === TaskManipulatorType.enum.HUMAN;
};

export function useTaskingPermissions(): TaskingPermissions {
  const currentAdvisor = useAdvisorDetails();

  const advisorId = currentAdvisor?.advisorId;
  const isAdmin = currentAdvisor?.details.isAdmin ?? false;

  return {
    taskPermissions: (task: Task) => {
      return {
        isAllowedToDelete:
          (isHumanManipulator(task.creator) && task.creator.id === advisorId) ||
          isAdmin,
      };
    },
    commentPermissions: (comment: TaskComment) => {
      return {
        isAllowedToUpdate: comment.author.id === advisorId,
        isAllowedToDelete: comment.author.id === advisorId || isAdmin,
      };
    },
    attachmentPermissions: (taskOrComment: Task | TaskComment) => {
      return {
        isAllowedToDelete:
          (isTask(taskOrComment)
            ? isHumanManipulator(taskOrComment.creator) &&
              taskOrComment.creator.id === advisorId
            : taskOrComment.author.id === advisorId) || isAdmin,
      };
    },
  };
}

export const usePrefetchDictionaryOptions = () => {
  useClientsOptions();
  useAdvisorsOptions();
  useAssigneesOptions();
};
