import React from "react";

import Admin from "../Admin";
import FAMBillingPage from "@src/multiCustodian/components/Admin/FAMBilling/FAMBilling";

const FAMBilling = (): JSX.Element => {
  return (
    <Admin>
      <FAMBillingPage />
    </Admin>
  );
};

export default FAMBilling;
