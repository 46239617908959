const DEFAULT_NEGLIGIBLE_DECIMAL = 0.0000001;

const roundToIntIfNegligibleDecimal = (
  num: number,
  negligibleDecimal: number = DEFAULT_NEGLIGIBLE_DECIMAL
): number => {
  const rounded = Math.round(num);

  if (num === rounded) {
    return num;
  }

  if (Math.abs(num - rounded) <= negligibleDecimal) {
    return rounded;
  }

  return num;
};

export default roundToIntIfNegligibleDecimal;
