import React from "react";

import Admin from "../Admin";
import SleeveBillingPage from "@src/multiCustodian/components/Admin/SleeveBilling/SleeveBilling";

const SleeveBilling = (): JSX.Element => {
  return (
    <Admin>
      <SleeveBillingPage />
    </Admin>
  );
};

export default SleeveBilling;
