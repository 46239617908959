import React from "react";

import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { range } from "lodash";

import {
  DIRECT_INDEXING_TOOLTIP_TEXT,
  TAX_LOSS_HARVESTING_TOOLTIP_TEXT,
} from "../const";
import Select from "@src/sharedComponents/Select/Select";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import Switch from "@src/sharedComponents/Switch/Switch";
import TooltipBasic from "@src/sharedComponents/Tooltip/TooltipBasic";

import styles from "./TradingGroupsTable.module.css";

const TradingGroupsTableLoading = (): JSX.Element => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.listHeaderDiv}>
          <div className={styles.flexDiv}>
            <Skeleton width={100} style={{ marginRight: "15px" }} />

            <Skeleton width={100} />
          </div>

          <Select
            value={"Add Accounts"}
            options={["Loading Available Accounts..."]}
            onChange={(_) => undefined}
            style={{ width: "200px" }}
            renderValue={(value) => value}
          />
        </div>

        <div className={styles.optionsDiv}>
          <div className={styles.half_width}>
            <p className={styles.headingText}>Accounts</p>
          </div>

          <div className={styles.divRight}>
            {range(2).map((i) => {
              return (
                <div key={i} className={styles.optionDiv}>
                  <div className={styles.flexDiv}>
                    <Skeleton width={100} style={{ marginRight: "15px" }} />
                    <Skeleton width={100} />
                  </div>

                  <RemoveCircleOutlineOutlinedIcon
                    className={styles.removeIcon}
                    onClick={() => undefined}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className={styles.settingsDiv}>
          <div className={styles.half_width}>
            <p className={styles.headingText}>Trading group Settings</p>
          </div>

          <div className={styles.half_width}>
            <div className={styles.flexApart}>
              <p className={styles.textDisabled}>Trading</p>

              <Switch disabled={true} on={false} onClick={() => undefined} />
            </div>

            <div className={styles.flexApart}>
              <div className={styles.flexDiv}>
                <p className={styles.textDisabled}>Tax-loss harvesting</p>

                <TooltipBasic
                  type={"secondary"}
                  text={TAX_LOSS_HARVESTING_TOOLTIP_TEXT}
                />
              </div>

              <Switch disabled={true} on={false} onClick={() => undefined} />
            </div>

            <div className={styles.flexApart}>
              <div className={styles.flexDiv}>
                <p className={styles.textDisabled}>Direct Indexing</p>

                <TooltipBasic
                  type={"secondary"}
                  text={DIRECT_INDEXING_TOOLTIP_TEXT}
                />
              </div>

              <Switch disabled={true} on={false} onClick={() => undefined} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TradingGroupsTableLoading;
