import React from "react";

import { Box, Fade } from "@mui/material";
import { useHistory } from "react-router-dom";

import { LendingOfferingType } from "@fartherfinance/frontend/api/AltAssetsService/Types";

import { LendingMarketplacePath } from "@src/config/routing/RouterPaths";
import { lendingOfferingTypes } from "@src/multiCustodian/components/Marketplace/constants";
import OfferingTypeList from "@src/multiCustodian/components/Marketplace/OfferingTypeList/OfferingTypeList";

import AdvisorContainer from "./AdvisorContainer";
import AdvisorContentContainer from "./AdvisorContentContainer";

import offeringTypeListStyles from "@src/multiCustodian/components/Marketplace/OfferingTypeList/OfferingTypeList.module.css";

const LendingMarketplace: React.FC = () => {
  const history = useHistory();

  const handleRedirectToOfferingsList = (
    offeringType: LendingOfferingType
  ): void => {
    history.push(`/Advisor/${LendingMarketplacePath}/${offeringType}`);
  };

  return (
    <AdvisorContainer>
      <AdvisorContentContainer heroTitle="Lending">
        <Fade in>
          <Box className={offeringTypeListStyles.wrapperAdvisor}>
            <OfferingTypeList
              offeringTypes={lendingOfferingTypes}
              onOfferingTypeClick={handleRedirectToOfferingsList}
            />
          </Box>
        </Fade>
      </AdvisorContentContainer>
    </AdvisorContainer>
  );
};

export default LendingMarketplace;
