import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

export const useStyles = makeStyles()((theme) => ({
  cell: {
    fontFamily: theme.typography.fontSecondary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.fontSize,
    lineHeight: "19px",
    letterSpacing: "0.63px",
    color: theme.appearance.$text,

    padding: theme.spacing(
      pxToSpacing(9),
      pxToSpacing(10),
      pxToSpacing(8),
      pxToSpacing(10)
    ),
  },
}));
