import React from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";

import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import styles from "./BackButton.module.css";

const BackButton = (): JSX.Element => {
  const history = useHistory();

  const {
    color: { $icon },
  } = useTheme();

  return (
    <div
      className={styles.backContainer}
      onClick={() => history.push("/Advisor/Investments/ModelMarketplace")}
    >
      <ArrowBackIcon style={{ color: $icon, height: "18px", width: "18px" }} />

      <span>Back</span>
    </div>
  );
};

export default BackButton;
