import React, { PropsWithChildren } from "react";

import { useParams } from "react-router-dom";

import { ClientId, PortfolioId } from "@fartherfinance/frontend/api/Types";

import PortfolioDrawer from "@src/multiCustodian/components/Client/Portfolio/PortfolioDrawer/PortfolioDrawer";
import Portfolios from "@src/multiCustodian/pages/Client/:clientId/Portfolios";

const PortfolioAccountsWrapper = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const { clientId, portfolioId } = useParams<{
    clientId: ClientId;
    portfolioId: PortfolioId;
  }>();

  return (
    <Portfolios>
      <PortfolioDrawer
        curTab="Accounts"
        clientId={clientId}
        portfolioId={portfolioId}
      >
        {children}
      </PortfolioDrawer>
    </Portfolios>
  );
};

export default PortfolioAccountsWrapper;
