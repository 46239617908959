import React from "react";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningIcon from "@mui/icons-material/Warning";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import head from "lodash/head";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { PatchProposalPayload } from "@fartherfinance/frontend/api/Rebalance/hooks/usePatchProposal";
import { GetProposalsQueryParams } from "@fartherfinance/frontend/api/Rebalance/requests/getProposals";
import {
  ListProposal,
  ProposalInternalStatus,
} from "@fartherfinance/frontend/api/Rebalance/Types";
import { ProposalId } from "@fartherfinance/frontend/api/Types";

import {
  DropdownMenu,
  DropdownMenuOption,
} from "../DropdownMenu/DropdownMenu.component";
import AlertInline from "@src/sharedComponents/AlertInline/AlertInline";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";
import { Table } from "@src/yellowstone/components";
import {
  isCollectionEmpty,
  PLACEHOLDER_SIGN,
} from "@src/yellowstone/modules/shared";
import {
  isMoreThanOneAccountInTradingGroup,
  mapProposalStatusToLabel,
  proposalExceptionChecker,
} from "@src/yellowstone/modules/trades";
import { TradingGroupSummary } from "@src/yellowstone/views/Dashboard/DashboardDailyTrading/shared/TradingGroupSummary.component";

import { PROPOSALS_TABLE_HEADERS } from "./ProposalsTable.const";
import { useStyles } from "./ProposalsTable.styles";
import {
  enrichProposalStatusOption,
  generateProposalStatusOptions,
} from "./ProposalsTable.utils";

interface ProposalsTableProps {
  proposals: ListProposal[];
  proposalsSkeletonCount: number;
  queryParams: GetProposalsQueryParams;
  isLoading: boolean;
  isErrored: boolean;
  isSubmittedProposalsView: boolean;
  isSkippedProposalsView: boolean;
  onProposalPatch: (payload: PatchProposalPayload) => void;
}

export const ProposalsTable: React.FC<ProposalsTableProps> = ({
  proposals,
  proposalsSkeletonCount,
  queryParams,
  isLoading,
  isErrored,
  isSubmittedProposalsView,
  isSkippedProposalsView,
  onProposalPatch,
}) => {
  const columnsCount = PROPOSALS_TABLE_HEADERS.length;

  const { classes, cx } = useStyles({ columnsCount });
  const location = useLocation();
  const navigate = useNavigate();

  const isAlertVisible = !isSubmittedProposalsView;
  const isChangeStatusActionDisabled =
    isSubmittedProposalsView || isSkippedProposalsView;

  const handleEnrichProposalStatusOption = (
    proposalStatusOption: DropdownMenuOption<ProposalInternalStatus>
  ) => {
    return enrichProposalStatusOption(
      proposalStatusOption,
      cx({
        [classes.textSuccess]:
          proposalStatusOption.value === ProposalInternalStatus.enum.CONFIRM,
        [classes.textDanger]:
          proposalStatusOption.value === ProposalInternalStatus.enum.PROPOSED,
        [classes.text]:
          proposalStatusOption.value ===
            ProposalInternalStatus.enum.NEEDS_REVIEW ||
          proposalStatusOption.value ===
            ProposalInternalStatus.enum.SKIP_PROPOSAL,
      })
    );
  };

  const mapStatusToClassName = (status: ProposalInternalStatus | null) =>
    cx({
      [classes.textShade]: status === ProposalInternalStatus.enum.PROPOSED,
      [classes.textSuccess]:
        status === ProposalInternalStatus.enum.CONFIRM ||
        status === ProposalInternalStatus.enum.SUBMITTED,
      [classes.textDanger]: status === ProposalInternalStatus.enum.FAILED,
      [classes.text]:
        status === ProposalInternalStatus.enum.NEEDS_REVIEW ||
        status === ProposalInternalStatus.enum.SKIP_PROPOSAL,
    });

  const handlePatchProposal = (
    proposalId: ProposalId,
    proposalStatusOption: DropdownMenuOption<ProposalInternalStatus>
  ): void => {
    onProposalPatch({
      proposalId,
      request: {
        internalProposalStatus: proposalStatusOption.value,
      },
    });
  };

  const handleNavigationToProposalDetails = (proposalId: ProposalId): void => {
    navigate(
      `/Advisor/Client-Ops/Dashboard/Daily-Trading/Proposal-Details/${proposalId}`,
      {
        state: {
          queryParams,
          from: location.pathname,
        },
      }
    );
  };

  return (
    <Table
      className={cx(classes.container, classes.gridTemplate)}
      columnsCount={columnsCount}
    >
      {PROPOSALS_TABLE_HEADERS.map((header, index) => (
        <Table.Header
          key={header}
          className={cx({
            [classes.alignCenter]: index === columnsCount - 1,
          })}
        >
          {header}
        </Table.Header>
      ))}

      {isErrored && (
        <Box className={classes.gridCellWarning}>
          Something went wrong, please try again
        </Box>
      )}

      {isLoading && (
        <>
          {Array.from({ length: proposalsSkeletonCount }).map((_, index) => (
            <Table.Row key={index} className={classes.gridTemplate}>
              {Array.from({ length: columnsCount }).map((_, index) => (
                <Table.Cell key={index}>
                  <Skeleton />
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </>
      )}

      {!isLoading && isCollectionEmpty(proposals) && (
        <Box className={classes.gridCellWarning}>
          <Typography className={classes.warningMessage}>
            No Results Found
          </Typography>
          {isAlertVisible && (
            <AlertInline
              serverity="info"
              message="This might be caused by being outside of proposals availability time window."
            />
          )}
        </Box>
      )}

      {proposals.map(
        ({
          proposalId,
          createdOn,
          client,
          status,
          tradingGroup,
          targetPortfolio,
        }) => (
          <Table.Row key={proposalId} className={classes.gridTemplate}>
            <Table.Cell>{proposalId}</Table.Cell>

            <Table.Cell>{format(new Date(createdOn), "MM/dd/yyyy")}</Table.Cell>

            <Table.Cell>
              {client.name.first} {client.name.last}
            </Table.Cell>

            {isMoreThanOneAccountInTradingGroup(tradingGroup) ? (
              <Tooltip
                placement="top"
                tooltipText={
                  <TradingGroupSummary
                    tradingGroup={tradingGroup}
                    taxLossHarvesting={tradingGroup.taxLossHarvesting}
                  />
                }
              >
                <Box>
                  <Table.Cell className={classes.tradingGroup}>
                    Trading group
                  </Table.Cell>
                </Box>
              </Tooltip>
            ) : (
              <Table.Cell>
                {head(tradingGroup.accounts)?.custodianAccountId}
              </Table.Cell>
            )}

            <Table.Cell>
              {targetPortfolio?.fartherName ?? PLACEHOLDER_SIGN}
            </Table.Cell>

            {isChangeStatusActionDisabled ? (
              <Table.Cell className={mapStatusToClassName(status.internal)}>
                {status.internal
                  ? mapProposalStatusToLabel(status.internal)
                  : PLACEHOLDER_SIGN}
              </Table.Cell>
            ) : (
              <Table.Cell className={mapStatusToClassName(status.internal)}>
                {status.internal ? (
                  <DropdownMenu
                    options={generateProposalStatusOptions(status.internal).map(
                      handleEnrichProposalStatusOption
                    )}
                    onSelect={(newProposalStatus) =>
                      handlePatchProposal(proposalId, newProposalStatus)
                    }
                  >
                    {({ isMenuOpen, handleOpenMenu }) => {
                      const { tradingEnabledException } =
                        proposalExceptionChecker;
                      const exceptionIndicatorText =
                        tradingEnabledException.isExceptionRaised(
                          tradingGroup
                        ) && tradingEnabledException.message;

                      return (
                        <Box
                          className={classes.statusRow}
                          onClick={handleOpenMenu}
                        >
                          {exceptionIndicatorText && (
                            <Tooltip
                              placement="top"
                              tooltipText={exceptionIndicatorText}
                            >
                              <WarningIcon
                                className={classes.statusExceptionIndicator}
                              />
                            </Tooltip>
                          )}

                          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                          {mapProposalStatusToLabel(status.internal!)}

                          <IconButton
                            className={cx(
                              classes.iconButton,
                              classes.menuButton
                            )}
                            disableRipple
                          >
                            <ExpandMoreIcon
                              className={cx(classes.menuIcon, {
                                [classes.rotate]: isMenuOpen,
                                [classes.menuIconColor]: isMenuOpen,
                              })}
                            />
                          </IconButton>
                        </Box>
                      );
                    }}
                  </DropdownMenu>
                ) : (
                  PLACEHOLDER_SIGN
                )}
              </Table.Cell>
            )}

            <Table.Cell className={classes.alignCenter}>
              <IconButton
                className={classes.iconButton}
                onClick={() => handleNavigationToProposalDetails(proposalId)}
                disableRipple
              >
                <ArrowForwardIcon className={classes.icon} />
              </IconButton>
            </Table.Cell>
          </Table.Row>
        )
      )}
    </Table>
  );
};
