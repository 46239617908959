import { TaskStatus } from "@fartherfinance/frontend/api/Tasks/Types";

export const filterOptions = [
  "Active Tasks",
  "Completed Tasks",
  "Canceled Tasks",
];
export type FilterOptions = typeof filterOptions[number] | "Custom selection";

export const filterToTaskStatus: Record<FilterOptions, TaskStatus[]> = {
  "Active Tasks": [
    "NOT_STARTED",
    "IN_PROGRESS",
    "AWAITING_RESPONSE",
    "WAITING_ON_CLIENT",
    "WAITING_ON_ADVISOR",
    "WAITING_ON_CUSTODIAN",
    "WAITING_ON_CLIENT_SERVICES",
  ],
  "Completed Tasks": ["COMPLETED"],
  "Canceled Tasks": ["CANCELED"],
};
