import { GetAccountGroupsRes } from "@fartherfinance/frontend/api/PerformanceGroups/requests/getAccountGroups";

// Check whether at least one group has an array of dateRangePoints that has more than 0 items
export const checkIfValidGroupExists = (
  accGroups: GetAccountGroupsRes
): boolean => {
  for (const acc of accGroups) {
    if (acc.dateRangePoints.length > 0) {
      return true;
    }
  }
  return false;
};
