import { OpportunityStatus } from "@fartherfinance/frontend/api/Opportunities/Types";

export const filterOptions = ["Active", "Canceled", "Completed"];
export type FilterOptions = typeof filterOptions[number] | "Custom selection";

export const filterToOpportunityStatus: Record<
  FilterOptions,
  OpportunityStatus[]
> = {
  Active: ["NOT_STARTED", "IN_PROGRESS", "AWAITING_RESPONSE", "SNOOZED"],
  Canceled: ["CANCELED"],
  Completed: ["COMPLETED_WITH_ASSETS_WON", "COMPLETED_NO_ASSETS_WON"],
};
