import React from "react";

import { useParams } from "react-router-dom";

import { SleeveId } from "@fartherfinance/frontend/api/Types";

import Portfolios from "../../../Portfolios";
import SleeveDrawer from "@src/multiCustodian/components/Sleeves/SleeveDrawer/SleeveDrawer";
import SleeveUsageTable from "@src/multiCustodian/components/Sleeves/SleeveUsageTable/SleeveUsageTable";

export default function SleeveUsage(): JSX.Element {
  const { sleeveId } = useParams<{
    sleeveId: SleeveId;
  }>();

  return (
    <Portfolios>
      <SleeveDrawer
        curTab="Sleeve Usage"
        sleeveId={sleeveId}
        isInClientPortal={true}
      >
        <SleeveUsageTable sleeveId={sleeveId} isInClientPortal={true} />
      </SleeveDrawer>
    </Portfolios>
  );
}
