import React from "react";

import { Box, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import { lockedDirectories, regularDirectories } from "../const";
import DirectoryItem from "../DirectoryItem/DirectoryItem";
import { isDirectoryLocked } from "../utils";
import { DocumentsCenterPath } from "@src/config/routing/RouterPaths";

import styles from "./DirectoryList.module.css";

const getLocationRoot = (pathname: string): string | undefined => {
  return pathname.split("/").at(4);
};

interface DirectoryListProps {
  clientId: ClientId;
  hasPendingAgreements: boolean;
}

export default function DirectoryList({
  clientId,
  hasPendingAgreements,
}: DirectoryListProps): JSX.Element {
  const history = useHistory();

  const isDirectoryActive = (directory: string): boolean => {
    return (
      getLocationRoot(history.location.pathname) ===
      decodeURIComponent(directory)
    );
  };

  const handleNavigateToDirectory = (directory: string): void => {
    history.push({
      ...history.location,
      pathname: `/Client/${clientId}/${DocumentsCenterPath}/${directory}`,
    });
  };

  return (
    <Box className={styles.container}>
      {lockedDirectories.map((directory) => (
        <DirectoryItem
          key={directory.value}
          directoryName={directory.label}
          directoryValue={directory.value}
          isActive={isDirectoryActive(directory.value)}
          isLocked={isDirectoryLocked(directory.value)}
          hasSignableDocuments={
            directory.value === "Farther%20Records" && hasPendingAgreements
          }
          onClick={handleNavigateToDirectory}
        />
      ))}

      <Typography className={styles.sections}>Sections</Typography>

      {regularDirectories.map((directory) => (
        <DirectoryItem
          key={directory.value}
          directoryName={directory.label}
          directoryValue={directory.value}
          isActive={isDirectoryActive(directory.value)}
          isLocked={isDirectoryLocked(directory.value)}
          hasSignableDocuments={false}
          onClick={handleNavigateToDirectory}
        />
      ))}
    </Box>
  );
}
