import React, { useMemo } from "react";

import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CrisisAlertOutlinedIcon from "@mui/icons-material/CrisisAlertOutlined";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Link } from "react-router-dom-v5-compat";

import useGetFartherManagedAccounts from "@fartherfinance/frontend/api/Accounts/hooks/useGetFartherManagedAccounts";
import useMatchPortfolio from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useMatchPortfolio";
import { ClientId, FartherAccountId } from "@fartherfinance/frontend/api/Types";

import { AccountsPath } from "@src/config/routing/RouterPaths";
import SharingResourceWhoHasOrGaveAccessTable from "@src/multiCustodian/components/Sharing/SharingResourceWhoHasOrGaveAccessTable/SharingResourceWhoHasOrGaveAccessTable";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

import PortfolioModelLink from "./Components/PortfolioModelLink";

import styles from "./Settings.module.css";

interface Props {
  clientId: ClientId;
  accountId: FartherAccountId;
}

const AccountSettingsContent = ({
  clientId,
  accountId,
}: Props): JSX.Element => {
  const auth = useRequestAuth();

  const { enablePortfoliosV2 } = useFlags();

  const portfolioId = useMatchPortfolio(accountId, auth);

  const accounts = useGetFartherManagedAccounts(clientId, auth);

  const isShared = useMemo(() => {
    if (accounts.isLoading || accounts.hasError) {
      return true; // assume true for time being
    }

    const account = accounts.data.find((a) => a.virtualAccountId === accountId);
    if (account === undefined) {
      throw new Error(
        `Account: ${accountId} is not in list of all farther managed accounts`
      );
    }

    return account.sharedBy[0] && account.sharedBy[0] !== clientId;
  }, [accounts, clientId, accountId]);

  if (accounts.hasError) {
    return <div>Error retrieving client's accounts</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>Account Settings</div>

      {accounts.isLoading && (
        <div className={styles.loading}>
          <LogoLoadingStill />
        </div>
      )}

      {!isShared && (
        <div className={styles.buttonContainer}>
          {enablePortfoliosV2 ? (
            <PortfolioModelLink clientId={clientId} portfolioId={portfolioId} />
          ) : null}

          <Link
            to={`/Client/${clientId}/Sharing`}
            className={toClassName(styles.buttonDiv, styles.buttonBackground)}
          >
            <SupervisorAccountIcon className={styles.muiImg} />

            <div className={styles.text}>Sharing Permissions</div>
          </Link>

          <Link
            to={`/Client/${clientId}/${AccountsPath}/Managed/${accountId}/Settings/Rename`}
            className={toClassName(styles.buttonDiv, styles.buttonBackground)}
          >
            <BorderColorOutlinedIcon className={styles.muiImg} />

            <div className={styles.text}>Rename Account</div>
          </Link>

          <Link
            to={`/Client/${clientId}/${AccountsPath}/Managed/${accountId}/Settings/SetTargetGoal`}
            className={toClassName(styles.buttonDiv, styles.buttonBackground)}
          >
            <CrisisAlertOutlinedIcon className={styles.muiImg} />

            <div className={styles.text}>Target Goal</div>
          </Link>
        </div>
      )}

      {/* this component will either show or hide itself and derive itself whether this account is being shared by another client or by this one solely using the resourceId prop */}
      <SharingResourceWhoHasOrGaveAccessTable resourceId={accountId} />
    </div>
  );
};

export default AccountSettingsContent;
