import React, { PropsWithChildren, useMemo, useRef, useState } from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import useCustomPortfoliosV1 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useCustomPortfoliosV1";
import useEssentialAllPortfoliosV1 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useEssentialAllPortfoliosV1";
import usePortfolioV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/usePortfolioV2";
import useToggleCustomPortfolioV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useToggleCustomPortfolioV2";
import useToggleEssentialPortfolio from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useToggleEssentialPortfolio";
import {
  AdvisorId,
  ClientId,
  PortfolioId,
  PortfolioTaxType,
} from "@fartherfinance/frontend/api/Types";

import { FARTHER_MODELS_TYPE_NAME } from "../../constants";
import { taxTypeToStatus } from "../../utils/taxType";
import useIsAdmin from "@src/multiCustodian/components/Advisor/utils/useIsAdmin";
import Drawer, {
  largeDrawer,
} from "@src/multiCustodian/components/Drawer/Drawer";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { captureException } from "@src/multiCustodian/services/tracking";
import Chip from "@src/sharedComponents/Chip/Chip";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import Popover from "@src/sharedComponents/Popover/Popover";
import Select from "@src/sharedComponents/Select/Select";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import { State } from "@src/store";

import styles from "./ModelDrawer.module.css";

type Tab = "Sharing" | "Model Details";

interface MiniPortfolio {
  portfolioId: PortfolioId;
  displayName: string;
  isActive: boolean;
  taxType: PortfolioTaxType;
  ownerId: ClientId | AdvisorId | null;
  type: string;
}

interface Props {
  curTab: Tab;
  portfolioId: PortfolioId;
}

const ModelDrawer = ({
  portfolioId,
  ...props
}: PropsWithChildren<Props>): JSX.Element => {
  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));

  const history = useHistory();

  const statusNotification = useStatusNotification();

  const auth = useAdvisorRequestAuth();
  const allEssential = useEssentialAllPortfoliosV1(auth);
  const advisorCustomPortfolios = useCustomPortfoliosV1(advisorId, auth);

  interface DropdownOption {
    portfolioId: PortfolioId;
    name: string;
  }

  const [portfolio, dropdownOptions] = useMemo((): [
    MiniPortfolio | undefined,
    DropdownOption[]
  ] => {
    if (
      allEssential.data === undefined ||
      advisorCustomPortfolios.data === undefined
    ) {
      return [undefined, []];
    }

    const allPortfolios = [
      ...allEssential.data.map((ep) => ({
        portfolioId: ep.portfolioModelId,
        displayName: ep.displayName,
        isActive: ep.isActive,
        taxType: ep.taxType,
        ownerId: null,
        type: ep.set,
      })),
      ...advisorCustomPortfolios.data.portfolios.map((cp) => ({
        portfolioId: cp.portfolioModelId,
        displayName: cp.displayName,
        isActive: cp.isActive,
        taxType: cp.taxType,
        ownerId: cp.ownerId,
        type: "Custom",
      })),
    ];

    const targetPortfolio = allPortfolios.find(
      (p) => portfolioId === p.portfolioId
    );
    if (targetPortfolio === undefined) {
      statusNotification("Portfolio not found", "Error");
      const e = new Error(
        "PortfolioId not found in allEssential or advisorCustomPortfolios"
      );
      captureException(e, {
        extra: {
          portfolioId: portfolioId,
          file: "ModelDrawer.tsx",
        },
      });
    }

    const dropdownOptions = allPortfolios.map((p) => ({
      portfolioId: p.portfolioId,
      name: p.displayName,
    }));

    return [targetPortfolio, dropdownOptions];
  }, [allEssential, advisorCustomPortfolios, portfolioId, statusNotification]);

  if (
    allEssential.isLoading ||
    advisorCustomPortfolios.isLoading ||
    portfolio === undefined
  ) {
    return (
      <Drawer
        isDrawerOpen
        container_style={largeDrawer}
        onClose={() =>
          history.push({
            ...history.location,
            pathname: `/Advisor/Investments/ModelMarketplace`,
          })
        }
        transitionDuration={0}
      >
        <div className={styles.loadingContainer}>
          <LogoLoadingStill />
        </div>
      </Drawer>
    );
  }

  if (allEssential.hasError || advisorCustomPortfolios.hasError) {
    return (
      <Drawer
        isDrawerOpen
        container_style={largeDrawer}
        onClose={() =>
          history.push({
            ...history.location,
            pathname: `/Advisor/Investments/ModelMarketplace`,
          })
        }
        transitionDuration={0}
      >
        <div>Error retrieving models</div>
      </Drawer>
    );
  }

  return (
    <Drawer
      isDrawerOpen
      container_style={largeDrawer}
      header={
        <Select
          value={portfolio?.displayName ?? ""}
          options={dropdownOptions.map((o) => o.name)}
          onChange={(name) => {
            const chosen = dropdownOptions.find((x) => x.name === name);
            if (chosen === undefined) {
              statusNotification("Error selecting model", "Error");
              return;
            }

            history.push({
              ...history.location,
              pathname: `/Advisor/Investments/ModelMarketplace/${chosen.portfolioId}/ModelDetails`,
            });
          }}
          style={{ width: "200px" }}
          renderValue={(value) => value}
        />
      }
      onClose={() =>
        history.push({
          ...history.location,
          pathname: `/Advisor/Investments/ModelMarketplace`,
        })
      }
      transitionDuration={0}
    >
      <Header curTab={props.curTab} portfolioId={portfolioId} />

      <Tabs
        portfolio={portfolio}
        curTab={props.curTab}
        setCurTab={(tab: Tab) => {
          switch (tab) {
            case "Sharing":
              return history.push({
                ...history.location,
                pathname: `/Advisor/Investments/ModelMarketplace/${portfolioId}/Sharing`,
              });

            case "Model Details":
              return history.push({
                ...history.location,
                pathname: `/Advisor/Investments/ModelMarketplace/${portfolioId}/ModelDetails`,
              });

            default: {
              const _x: never = tab;
            }
          }
        }}
      />

      {props.children}
    </Drawer>
  );
};

export default ModelDrawer;

interface HeaderProps {
  curTab: "Sharing" | "Model Details";
  portfolioId: PortfolioId;
}

const Header = ({ curTab, portfolioId }: HeaderProps): JSX.Element => {
  const [isMutating, setIsMutating] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);

  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));

  const history = useHistory();

  const statusNotification = useStatusNotification();

  const auth = useAdvisorRequestAuth();
  const portfolio = usePortfolioV2(portfolioId, auth);
  const toggleEssentialPortfolio = useToggleEssentialPortfolio(auth);
  const toggleCustomPortfolioV2 = useToggleCustomPortfolioV2(auth);
  const isAdmin = useIsAdmin(advisorId);

  if (portfolio.hasError) {
    return <div>Error retrieving portfolio</div>;
  }

  const isModelArchived =
    portfolio.data === undefined
      ? undefined
      : portfolio.data.model.isActive === false;

  const activatePortfolio = async (
    portfolioId: PortfolioId,
    modelType: string | undefined
  ) => {
    if (modelType === undefined) {
      statusNotification("Model type is undefined", "Error");
      return;
    }

    try {
      setIsMutating(true);

      if (modelType === "Custom") {
        await toggleCustomPortfolioV2(portfolioId);
      } else {
        await toggleEssentialPortfolio(portfolioId);
      }

      statusNotification("Model activated", "Success");
    } catch (_e) {
      statusNotification("Error activating model", "Error");
    } finally {
      setIsMutating(false);
      setShowPopover(false);
    }
  };

  const canArchiveAndEdit =
    isAdmin.data ||
    (portfolio.data?.model.portfolioType === "Custom" &&
      portfolio.data?.model.ownerId === advisorId);

  if (isAdmin.hasError) {
    return <div>Error retrieving employee status</div>;
  }

  const editModel = () => {
    if (portfolio.data === undefined) {
      statusNotification("Model is undefined", "Error");
      return;
    }

    if (portfolio.data.model.portfolioType === "Custom") {
      history.push({
        pathname: `/Advisor/Investments/ModelMarketplace/Custom/${portfolioId}/Update`,
        state: history.location.state,
      });
    } else {
      history.push({
        pathname: "/Advisor/Investments/ModelMarketplace/Update",
        state: history.location.state,
      });
    }
  };

  return (
    <>
      <div className={styles.headerFlexApart}>
        <div className={styles.flexRow}>
          <div className={styles.title}>
            {portfolio.data?.model.displayName ?? ""}
          </div>

          <Popover
            open={showPopover}
            onClose={() => setShowPopover(false)}
            anchor={anchorRef.current}
            popoverElement={
              <div
                className={styles.dotMenu}
                ref={anchorRef}
                onClick={() => setShowPopover(true)}
              >
                <MoreHorizIcon />
              </div>
            }
          >
            <div className={styles.archiveMenu}>
              {isMutating ? (
                <Skeleton
                  width={70}
                  style={{
                    transform: "none",
                    marginTop: "7px",
                    marginBottom: "7px",
                  }}
                />
              ) : isModelArchived === undefined ? (
                <>
                  <Skeleton
                    width={70}
                    style={{
                      transform: "none",
                      marginTop: "7px",
                      marginBottom: "7px",
                    }}
                  />

                  <Skeleton
                    width={70}
                    style={{
                      transform: "none",
                      marginTop: "7px",
                      marginBottom: "7px",
                    }}
                  />
                </>
              ) : (
                <>
                  {isModelArchived ? (
                    <>
                      {canArchiveAndEdit ? (
                        <div
                          className={styles.archiveMenuText}
                          onClick={() =>
                            activatePortfolio(
                              portfolioId,
                              portfolio.data?.model.portfolioType
                            )
                          }
                        >
                          Make Active
                        </div>
                      ) : (
                        <div className={styles.archiveMenuTextDisabled}>
                          Make Active
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {canArchiveAndEdit ? (
                        <>
                          <div
                            className={styles.archiveMenuText}
                            onClick={editModel}
                          >
                            Edit
                          </div>

                          <div
                            className={styles.archiveMenuRedText}
                            onClick={() => {
                              setShowPopover(false);
                              const tab =
                                curTab === "Model Details"
                                  ? "ModelDetails"
                                  : curTab;
                              history.push({
                                ...history.location,
                                pathname: `/Advisor/Investments/ModelMarketplace/${portfolioId}/${tab}/Archive`,
                              });
                            }}
                          >
                            Archive
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={styles.archiveMenuTextDisabled}>
                            Edit
                          </div>

                          <div className={styles.archiveMenuTextDisabled}>
                            Archive
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </Popover>
        </div>

        <div className={styles.pillsDiv}>
          {portfolio.data?.model.taxType !== undefined && (
            <Chip label={taxTypeToStatus[portfolio.data?.model.taxType]} />
          )}

          <div className={styles.pillSpacer} />

          {portfolio.data?.model.portfolioType !== undefined && (
            <Chip
              label={
                portfolio.data?.model.portfolioType === "FAM"
                  ? FARTHER_MODELS_TYPE_NAME
                  : portfolio.data?.model.portfolioType
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

interface TabsProps {
  portfolio: MiniPortfolio | undefined;
  curTab: Tab;
  setCurTab: (tab: Tab) => void;
}

const Tabs = ({ portfolio, curTab, setCurTab }: TabsProps): JSX.Element => {
  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));

  const isAdmin = useIsAdmin(advisorId);

  const isSharedCustomModel =
    portfolio?.type === "Custom" &&
    isAdmin.data === false &&
    "ownerId" in portfolio &&
    portfolio.ownerId !== advisorId;

  return (
    <div className={styles.tabsContainer}>
      {(isAdmin.data || isSharedCustomModel) && (
        <p
          className={curTab === "Sharing" ? styles.tabSelected : styles.tab}
          onClick={() => setCurTab("Sharing")}
        >
          Sharing
        </p>
      )}

      <p
        className={curTab === "Model Details" ? styles.tabSelected : styles.tab}
        onClick={() => setCurTab("Model Details")}
      >
        Model Details
      </p>
    </div>
  );
};
