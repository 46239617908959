import React from "react";

import NotificationsIcon from "@mui/icons-material/Notifications";
import { Badge, IconButton } from "@mui/material";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./NotificationBell.module.css";

const CAP_LIMIT = 99;

interface NotificationBellProps {
  unseenCount: number | undefined;
  isFocused: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function NotificationBell({
  unseenCount = 0,
  isFocused,
  onClick,
}: NotificationBellProps): JSX.Element {
  const isSingleDigit = unseenCount < 10;
  const isMultipleDigit = unseenCount >= 10;
  const isCapped = unseenCount > CAP_LIMIT;

  return (
    <IconButton
      onClick={onClick}
      disableRipple
      disableFocusRipple
      disableTouchRipple
    >
      <Badge
        className={toClassName(styles.unseenIndicator, {
          [styles.unseenIndicatorSingleDigit]: isSingleDigit,
          [styles.unseenIndicatorMultipleDigit]: isMultipleDigit,
          [styles.unseenIndicatorCapped]: isCapped,
        })}
        badgeContent={unseenCount}
        max={CAP_LIMIT}
      >
        <NotificationsIcon
          className={toClassName(styles.bellIcon, {
            [styles.bellIconUnfocused]: !isFocused,
            [styles.bellIconFocused]: isFocused,
          })}
        />
      </Badge>
    </IconButton>
  );
}
