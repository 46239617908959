import React from "react";

import MuiTooltip, {
  TooltipProps as MuiTooltipProps,
} from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import styles from "./Tooltip.module.css";

type TooltipProps = Omit<MuiTooltipProps, "title"> & {
  tooltipText: React.ReactNode;
  maxWidth?: number;
};

export default function Tooltip({
  children,
  tooltipText,
  maxWidth = 260,
  ...restProps
}: TooltipProps) {
  return (
    <MuiTooltip
      sx={{ maxWidth }}
      classes={{
        tooltip: styles.tooltip,
        tooltipPlacementLeft: styles.tooltipLeft,
        tooltipPlacementRight: styles.tooltipRight,
        tooltipPlacementTop: styles.tooltipTop,
        tooltipPlacementBottom: styles.tooltipBottom,
        ...restProps.classes,
      }}
      title={
        typeof tooltipText === "string" && tooltipText ? (
          <Typography className={styles.tooltipText}>{tooltipText}</Typography>
        ) : React.isValidElement(tooltipText) ? (
          tooltipText
        ) : null
      }
      enterDelay={300}
      {...restProps}
    >
      {children}
    </MuiTooltip>
  );
}
