import React, { useMemo } from "react";

import { orderBy } from "lodash";

import useEquivalentSecurityLists from "@fartherfinance/frontend/api/EquivalentSecurities/hooks/useEquivalentSecurityLists";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";
import BorderBoxHeader from "@src/sharedComponents/BorderBox/BorderBoxHeader";
import LinkButton from "@src/sharedComponents/LinkButton/LinkButton";

import EquivalentSecuritiesAdminTable from "./EquivalentSecuritiesAdminTable";
import EquivalentSecuritiesAdminTableLoading from "./EquivalentSecuritiesAdminTableLoading";

import styles from "./EquivalentSecuritiesAdminTable.module.css";

const EquivalentSecurities: React.FC = () => {
  const auth = useAdvisorRequestAuth();
  const listsRequest = useEquivalentSecurityLists(auth);

  const eslists = useMemo(() => {
    if (listsRequest.isLoading || listsRequest.hasError) {
      return undefined;
    }

    return orderBy(listsRequest.data, [(l) => l.name], ["asc"]);
  }, [listsRequest]);

  if (listsRequest.hasError) {
    return <div>Error retrieving equivalent security lists</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div className={styles.textBold}>Equivalent Securities</div>

        <LinkButton
          variant={"outlined"}
          buttonType={"secondary"}
          text={"New List"}
          sx={{ width: "94px", height: "32px" }}
          to={`/Advisor/Admin/EquivalentSecurities/Create`}
        />
      </div>

      <BorderBox>
        <BorderBoxHeader
          className={styles.borderBoxHeader}
          start={
            <div className={toClassName(styles.paddingLeft, styles.textBold)}>
              List Name
            </div>
          }
          end={
            <div
              className={toClassName(styles.secondColumnWidth, styles.textBold)}
            >
              Equivalent Securities
            </div>
          }
        />

        {eslists === undefined ? (
          <EquivalentSecuritiesAdminTableLoading />
        ) : (
          <EquivalentSecuritiesAdminTable eslists={eslists} />
        )}
      </BorderBox>
    </div>
  );
};

export default EquivalentSecurities;
