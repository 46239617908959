import React from "react";

import BackButton from "../../SharedComponents/BackButton/BackButton";
import ModelSecuritiesTableLoading from "../../SharedComponents/ModelSecuritiesTable/ModelSecuritiesTableLoading";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./UpdateModelsBody.module.css";

const UpdateModelsBodyLoading = (): JSX.Element => {
  return (
    <div className={styles.container}>
      <div className={styles.centeringContainer}>
        <BackButton />

        <div className={styles.bodyContainer}>
          <Spacer verticalSpacing="8px" />

          <Skeleton height={34} />

          <Spacer verticalSpacing="16px" />

          <Skeleton height={16} />

          <Spacer verticalSpacing="16px" />

          <ModelSecuritiesTableLoading />
        </div>
      </div>
    </div>
  );
};

export default UpdateModelsBodyLoading;
