import React from "react";

import { format } from "date-fns";

import useSleeve from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useSleeve";
import useSleeveAnalysis from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useSleeveAnalysis";
import { SleeveId } from "@fartherfinance/frontend/api/Types";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import finScoreToLabel from "@src/multiCustodian/utils/finScoreToLabel";
import Chip from "@src/sharedComponents/Chip/Chip";
import BorderedList from "@src/sharedComponents/List/BorderedList";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "@src/multiCustodian/components/Client/Portfolio/ModelAnalysis/ModelDetailsTable.module.css";
interface Props {
  sleeveId: SleeveId;
  isInClientPortal?: boolean;
}

const SleeveDetailsTable = ({
  sleeveId,
  isInClientPortal,
}: Props): JSX.Element => {
  const clientAuth = useRequestAuth();
  const advisorAuth = useAdvisorRequestAuth();
  const sleeve = useSleeve(
    sleeveId,
    isInClientPortal ? clientAuth : advisorAuth
  );
  const sleeveAnalysis = useSleeveAnalysis(
    sleeveId,
    isInClientPortal ? clientAuth : advisorAuth
  );

  if (sleeve.isLoading || sleeveAnalysis.isLoading) {
    return (
      <div className={styles.container}>
        <BorderedList
          rows={[
            {
              left: "Risk Level",
              right: <Skeleton width={120} />,
            },
            {
              left: "Created",
              right: <Skeleton width={120} />,
            },
          ]}
        />
      </div>
    );
  }

  if (sleeve.hasError || sleeveAnalysis.hasError) {
    return (
      <div className={styles.container}>
        <p className={styles.textLeft}>Error</p>
      </div>
    );
  }

  const riskLabel = finScoreToLabel(sleeveAnalysis.data.finScore ?? undefined);

  const createTime = sleeve.data.createTime
    ? format(sleeve.data.createTime, "M/d/yyyy")
    : "N/A";

  return (
    <div className={styles.container}>
      <BorderedList
        rows={[
          {
            left: "Risk Level",
            right: <Chip label={riskLabel} />,
          },
          {
            left: "Created",
            right: <div className={styles.dateText}>{createTime}</div>,
          },
        ]}
      />
    </div>
  );
};

export default SleeveDetailsTable;
