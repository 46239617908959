import React, { useState } from "react";

import { useSelector } from "react-redux";

import { State } from "../../../../../../store";
import ButtonPrimary from "../../../../../components/MUI/Button/Button";
import { SetScreenAndTransferType, TransferType } from "../../Types";
import useThemeFragment from "@src/multiCustodian/theme/useThemeFragment";

import "../css.css";

interface Props {
  transferType: TransferType;
  setCurScreen: SetScreenAndTransferType;
}

const ERAProcedures = (props: Props): JSX.Element => {
  const { add_circle, remove_circle } = useSelector((state: State) => ({
    add_circle: state.main_Reducer.brand.current.images.add_circle,
    remove_circle: state.main_Reducer.brand.current.images.remove_circle,
  }));

  const t = useThemeFragment();

  const [isShowingInstructions, setShowingInstructions] =
    useState<boolean>(true);

  return (
    <div className="Dashboard_Acats_pages__container">
      <div className="Dashboard_Acats_pages__content_container">
        <p className="Dashboard_Acats_pages__title">
          {`${props.transferType} rollover`}
        </p>

        <p className="Dashboard_Acats_pages__summary">
          Looking to roll over your {props.transferType} into {t("companyName")}
          ? Simply read the instructions below to initiate the rollover. You
          will also need to initiate the process at your employer retirement
          plan's recordkeeper.
        </p>

        <div className="Dashboard_Acats_pages__flex_row_div_2">
          {isShowingInstructions ? (
            <img
              src={remove_circle}
              className="Dashboard_Acats_pages__collapsable_img"
              onClick={() => setShowingInstructions(!isShowingInstructions)}
            />
          ) : (
            <img
              src={add_circle}
              className="Dashboard_Acats_pages__collapsable_img"
              onClick={() => setShowingInstructions(!isShowingInstructions)}
            />
          )}
          <p className="Dashboard_Acats_pages__text_2">Instructions</p>
        </div>

        <div className="Dashboard_Acats_pages__hr_line"></div>

        {isShowingInstructions && (
          <div className="Dashboard_Acats_pages__procedure_div">
            <p className="Dashboard_Acats_pages__text_3">
              Initiate rollover on recordkeeper's website
            </p>

            <p className="Dashboard_Acats_pages__text_2_gray">
              {`1.   Log onto the recordkeeper's website`}
            </p>

            <p className="Dashboard_Acats_pages__text_2_gray">
              {`2.   Navigate to rollovers or withdrawals`}
            </p>

            <p className="Dashboard_Acats_pages__text_2_gray">
              {`3.   Choose to rollover to another financial institution`}
            </p>

            <p className="Dashboard_Acats_pages__text_2_gray">
              {`4.   Transfer the funds to Apex either electronically or via check:`}
            </p>

            <div className="Dashboard_Acats_pages__inner_procedure_div">
              <p className="Dashboard_Acats_pages__text_2_gray">
                <span className="Dashboard_Acats_pages__text_4">
                  Payable to:
                </span>
                Apex Clearing FBO [full name on your account]
              </p>

              <p className="Dashboard_Acats_pages__text_2_gray">
                <span className="Dashboard_Acats_pages__text_4">Memo:</span>
                [your account number] - you can find your account number on your
                monthly statements
              </p>

              <p className="Dashboard_Acats_pages__text_3">Mailing address:</p>

              <p className="Dashboard_Acats_pages__text_5_gray">
                Apex Clearing Corporation
              </p>
              <p className="Dashboard_Acats_pages__text_5_gray">c/o Banking</p>
              <p className="Dashboard_Acats_pages__text_5_gray">
                350 N St Paul Street, Ste 1300
              </p>
              <p className="Dashboard_Acats_pages__text_5_gray">
                Dallas, TX 75201
              </p>
            </div>
          </div>
        )}

        <div className="Dashboard_Acats_pages__button_container_2">
          <div
            className="Dashboard_Acats_pages__back_button_container"
            onClick={() =>
              props.setCurScreen({
                screen: "ERA/Selection",
              })
            }
          >
            <img src="/src/assets/svg/left_arrow.svg" />
            <div className="Dashboard_Acats_pages__back_button">Back</div>
          </div>

          <ButtonPrimary
            variant={"contained"}
            buttonType={"primary"}
            text={"Return"}
            onClick={() => props.setCurScreen({ screen: "transferType" })}
          />
        </div>
      </div>
    </div>
  );
};

export default ERAProcedures;
