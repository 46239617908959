import React from "react";

const Insurance: React.FC = () => (
  <svg
    width="100%"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon">
      <path
        id="Vector"
        d="M11.5305 7.80198C11.5305 9.71304 10.0847 10.6997 7.07776 12.4688C4.07079 10.6997 2.625 9.68577 2.625 7.80198V1.90932L4.29896 1.57453C5.65426 1.30347 6.91875 2.34009 6.91875 3.72223H7.2368C7.2368 2.34009 8.50127 1.30347 9.85657 1.57453L11.5305 1.90932V7.80198Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default Insurance;
