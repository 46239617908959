import React, { useCallback } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useParams } from "react-router-dom";

import { TaxBudget } from "@fartherfinance/frontend/api/InvestmentModel/Types";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import { TAX_BUDGETS_TOOLTIP_TEXT } from "@src/multiCustodian/components/TaxBudgets/constants";
import List from "@src/multiCustodian/components/TaxBudgets/List/List";
import { trackEvent } from "@src/multiCustodian/services/tracking";
import LinkButton from "@src/sharedComponents/LinkButton/LinkButton";
import TooltipBasic from "@src/sharedComponents/Tooltip/TooltipBasic";

import styles from "./Overview.module.css";

interface Props {
  currentBudget?: TaxBudget;
  futureBudgets: TaxBudget[];
}

const Overview: React.FC<Props> = ({ currentBudget, futureBudgets }) => {
  const { clientId } = useParams<{ clientId: ClientId }>();

  const { enableTaxBudgetHypotheticals } = useFlags();

  const onTrackOpenCreate = useCallback(() => {
    trackEvent({ name: "Advisor Open Create Tax-Budget" });
  }, []);

  const onTrackOpenHypothetical = useCallback(() => {
    trackEvent({ name: "Advisor Open Hypothetical-Tax-Budget" });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <span className={styles.title}>
          Tax Budgets
          <TooltipBasic text={TAX_BUDGETS_TOOLTIP_TEXT} />
        </span>

        <div className={styles.buttonContainer}>
          {enableTaxBudgetHypotheticals && (
            <LinkButton
              buttonType="primary"
              variant="text"
              size="small"
              text="New Hypothetical Scenario"
              to={`/Advisor/Clients/${clientId}/TaxBudgets/CreateHypothetical`}
              onClick={onTrackOpenHypothetical}
            />
          )}

          <LinkButton
            buttonType="primary"
            variant="outlined"
            size="small"
            text="New Tax Budget"
            to={`/Advisor/Clients/${clientId}/TaxBudgets/Create`}
            onClick={onTrackOpenCreate}
          />
        </div>
      </div>

      <List currentBudget={currentBudget} futureBudgets={futureBudgets} />
    </div>
  );
};

export default Overview;
