import React, { useEffect, useState } from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Fade, Stack, Typography } from "@mui/material";
import { isAxiosError } from "axios";
import { add, formatISO, isValid, sub } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import { debounce, isDate } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import useGetTask from "@fartherfinance/frontend/api/Tasks/hooks/useGetTask";
import useGetTaskComments from "@fartherfinance/frontend/api/Tasks/hooks/useGetTaskComments";
import useRemoveAttachment from "@fartherfinance/frontend/api/Tasks/hooks/useRemoveAttachment";
import useRemoveTask from "@fartherfinance/frontend/api/Tasks/hooks/useRemoveTask";
import useUpdateTask from "@fartherfinance/frontend/api/Tasks/hooks/useUpdateTask";
import type { GetTaskCommentsQueryParams } from "@fartherfinance/frontend/api/Tasks/requests/getTaskComments";
import type { PatchTaskRequest } from "@fartherfinance/frontend/api/Tasks/requests/patchTask";
import {
  Attachment,
  SystemTaskType,
  Task,
  TaskPriority,
  TaskStatus,
} from "@fartherfinance/frontend/api/Tasks/Types";
import {
  AttachmentId,
  Custodian,
  SortOrder,
  TaskId,
} from "@fartherfinance/frontend/api/Types";

import Tasks from "../../../Tasks";
import {
  ATTACHMENTS_LIMIT_COUNT,
  custodianOptions,
  TaskAssigneeAutocompleteOption,
} from "../../common";
import { mapTaskTypeToLabel } from "../../Components/TasksTable/TasksTable.utils";
import {
  useAdvisorsOptions,
  useAssigneesOptions,
  useClientsOptions,
  useTaskingPermissions,
  useTaskTypeOptions,
} from "../../hooks";
import {
  isDateValid,
  isTaskFieldEditingRestricted,
  mapAssigneeToOption,
} from "../../utils";
import TaskDrawerFooter from "../Components/TaskDrawerFooter";
import TaskDrawerHeader from "../Components/TaskDrawerHeader";
import WarningRedIcon from "@src/assets/svg/warning_red.svg";
import Drawer from "@src/multiCustodian/components/Drawer/Drawer";
import AlertMessageModal from "@src/multiCustodian/components/Modals/AlertMessageModal";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { EnhancedAutocompleteOption } from "@src/multiCustodian/pages/Advisor/common";
import { mapPersonToOption } from "@src/multiCustodian/pages/Advisor/utils";
import { trackEvent } from "@src/multiCustodian/services/tracking";
import AttachmentComponent from "@src/sharedComponents/Attachment/Attachment";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormDateField from "@src/sharedComponents/Forms/FormDateField";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import FormSubHeader from "@src/sharedComponents/Forms/FormSubHeader";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import IconButton from "@src/sharedComponents/IconButton/IconButton";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import Menu, { MenuOption } from "@src/sharedComponents/Menu/Menu";
import { extractAxiosErrorMessage } from "@src/utils/axios";

import TaskActivity from "./Components/TaskActivity";
import TaskDescription from "./Components/TaskDescription";
import TaskTitle from "./Components/TaskTitle";

import styles from "./TaskDetails.module.css";

const mapTaskDetailsToFormData = (
  {
    taskStatus,
    taskPriority,
    taskType,
    assignee,
    client,
    advisor,
    dueDate,
    custodian,
  }: Task,
  enableNewTaskStatus: boolean
): FormData => {
  const taskStatus_Beta =
    taskStatus === TaskStatus.enum.AWAITING_RESPONSE
      ? TaskStatus.enum.WAITING_ON_CLIENT
      : taskStatus;
  const taskStatus_NonBeta = taskStatus.startsWith("WAITING_ON")
    ? TaskStatus.enum.AWAITING_RESPONSE
    : taskStatus;

  return {
    taskStatus: enableNewTaskStatus ? taskStatus_Beta : taskStatus_NonBeta,
    taskPriority,
    taskType: { label: mapTaskTypeToLabel(taskType), value: taskType },
    assignee: mapAssigneeToOption(assignee),
    client: client && mapPersonToOption(client),
    advisor: advisor && mapPersonToOption(advisor),
    dueDate,
    custodian: custodian && { label: custodian, value: custodian },
  };
};

const mapFormDataToPayload = (
  formData: FormData,
  fieldName: keyof FormData
): PatchTaskRequest => {
  const mappedKey = {
    assignee: "assigneeId",
    client: "clientId",
    advisor: "advisorId",
    taskType: "taskType",
    taskStatus: "taskStatus",
    taskPriority: "taskPriority",
    dueDate: "dueDate",
    custodian: "custodian",
  }[fieldName];

  const fieldValue = formData[fieldName];
  const isFieldNullable = mappedKey === "clientId" || mappedKey === "custodian";
  const isValueObject =
    fieldValue !== null &&
    typeof fieldValue === "object" &&
    "value" in fieldValue;

  // NOTE: assigneeId needs special handling as it's structure is different than other options
  if (mappedKey === "assigneeId" && isValueObject && "meta" in fieldValue) {
    return {
      [mappedKey]: {
        type: fieldValue.meta.type,
        id: fieldValue.value,
      },
    };
  }

  // NOTE: dueDate needs special handling as we need to format it to ISO date
  if (mappedKey === "dueDate" && isDate(fieldValue)) {
    return {
      [mappedKey]: formatISO(fieldValue, { representation: "date" }),
    };
  }

  // prettier-ignore
  const value = fieldValue && isValueObject
    ? isFieldNullable ? { value: fieldValue.value } : fieldValue.value
    : isFieldNullable ? { value: fieldValue } : fieldValue

  return { [mappedKey]: value };
};

const TASK_COMMENTS_STATIC_QUERY_PARAMS: GetTaskCommentsQueryParams = {
  page: 1,
  pageSize: 999,
  sort: `${SortOrder.Descending}createdTs`,
};

interface FormData {
  taskStatus: TaskStatus;
  taskPriority: TaskPriority;
  taskType: EnhancedAutocompleteOption<SystemTaskType>;
  assignee: TaskAssigneeAutocompleteOption;
  client: EnhancedAutocompleteOption | null;
  advisor: EnhancedAutocompleteOption | null;
  dueDate: Date | null;
  custodian: EnhancedAutocompleteOption<Custodian> | null;
}

interface TaskDetailsProps {
  taskId: TaskId;
}

export default function TaskDetails({ taskId }: TaskDetailsProps): JSX.Element {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isAttachmentLoading, setIsAttachmentLoading] = useState(false);
  const history = useHistory();

  const { enableNewTaskStatus } = useFlags();
  const auth = useAdvisorRequestAuth();
  const clientsOptions = useClientsOptions();
  const advisorsOptions = useAdvisorsOptions();
  const assigneesOptions = useAssigneesOptions();
  const statusNotification = useStatusNotification();
  const callUpdateTask = useUpdateTask(auth);
  const callRemoveTask = useRemoveTask(auth);
  const callRemoveAttachment = useRemoveAttachment(auth);
  const getTaskQuery = useGetTask(taskId, auth);
  const getTaskCommentsQuery = useGetTaskComments(
    taskId,
    TASK_COMMENTS_STATIC_QUERY_PARAMS,
    auth
  );
  const { attachmentPermissions } = useTaskingPermissions();

  const {
    data: taskDetails,
    isLoading: isTaskDetailsLoading,
    hasError: taskDetailsHasError,
  } = getTaskQuery;
  const {
    data: taskComments,
    isLoading: isTaskCommentsLoading,
    hasError: taskCommentsHasError,
  } = getTaskCommentsQuery;
  const { values: comments = [] } = taskComments ?? {};

  const taskTypeOptions = useTaskTypeOptions(
    taskDetails?.taskStatus,
    taskDetails?.isSystemGenerated
  );
  const taskTypeFormOptions = taskTypeOptions.map((taskType) => ({
    label: mapTaskTypeToLabel(taskType),
    value: taskType,
  }));

  const isLoading =
    isTaskDetailsLoading || isTaskCommentsLoading || isAttachmentLoading;
  const hasError = taskDetailsHasError || taskCommentsHasError;
  const shouldShowHeaderAndFooter = !isLoading && !hasError;
  const minDueDate = sub(new Date(), { years: 2 });
  const maxDueDate = add(new Date(), { years: 2 });

  const form = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      taskStatus: "NOT_STARTED",
      taskPriority: "MEDIUM",
    },
    values:
      taskDetails && mapTaskDetailsToFormData(taskDetails, enableNewTaskStatus),
  });
  const { watch } = form;

  const determineErrorMessage = (): string => {
    if (
      taskDetailsHasError &&
      isAxiosError(getTaskQuery.error) &&
      getTaskQuery.error.response?.status === 403
    ) {
      return "You don’t have access to this task.";
    }

    return "Something went wrong, please try again later.";
  };

  const handleNavigateToTasksList = (): void => {
    history.push({
      pathname: "/Advisor/Tasks",
      search: history.location.search,
    });
  };

  const handleDeleteTask = async (): Promise<void> => {
    try {
      await callRemoveTask(taskId);

      trackEvent({ name: "Advisor Tasks Delete" });

      statusNotification("Task removed successfully.", "Success");
    } catch (error) {
      statusNotification(
        extractAxiosErrorMessage(error, "Failed to delete task."),
        "Error"
      );
    } finally {
      handleNavigateToTasksList();
    }
  };

  const handleDeleteAttachment = async (
    taskId: TaskId,
    attachmentId: AttachmentId
  ): Promise<void> => {
    try {
      setIsAttachmentLoading(true);
      await callRemoveAttachment({ taskId, attachmentId });

      trackEvent({ name: "Advisor Tasks Attachment Delete" });

      statusNotification("Attachment removed successfully.", "Success");
    } catch (error) {
      statusNotification(
        extractAxiosErrorMessage(error, "Failed to delete attachment."),
        "Error"
      );
    } finally {
      setIsAttachmentLoading(false);
    }
  };

  const handleCreateMenuOptions = (
    task: Task,
    attachment: Attachment
  ): MenuOption[] => {
    const downloadOption: MenuOption = {
      label: "Download File",
      onClick: () => (window.location.href = attachment.downloadUrl),
    };
    const deleteOption: MenuOption = {
      label: "Delete File",
      className: styles.textWarning,
      onClick: () => handleDeleteAttachment(task.id, attachment.id),
    };

    return [
      downloadOption,
      ...(attachmentPermissions(task).isAllowedToDelete ? [deleteOption] : []),
    ];
  };

  const handleClearAutocompleteField = (field: keyof FormData): void => {
    form.setValue(field, null);
  };

  useEffect(() => {
    const subscription = watch(
      debounce(async (formData, { name }) => {
        if (name === "dueDate" && !isValid(formData[name])) {
          return;
        }

        if (name) {
          try {
            await callUpdateTask({
              taskId,
              request: mapFormDataToPayload(formData, name),
            });

            trackEvent({ name: "Advisor Tasks Update" });
          } catch (error) {
            statusNotification(
              extractAxiosErrorMessage(error, "Failed to update task."),
              "Error"
            );
          }
        }
      }, 1000)
    );

    return () => subscription.unsubscribe();
  }, [watch, callUpdateTask, statusNotification, taskId]);

  return (
    <Tasks>
      <FormProvider {...form}>
        <Drawer
          isDrawerOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          SlideProps={{ onExited: handleNavigateToTasksList }}
          {...(shouldShowHeaderAndFooter && {
            header: <TaskDrawerHeader task={taskDetails} />,
            footer: <TaskDrawerFooter taskId={taskId} />,
          })}
        >
          {taskDetails && (
            <Fade in>
              <Stack className={styles.container}>
                <TaskTitle
                  task={taskDetails}
                  openAlertDialog={() => setIsAlertVisible(true)}
                />

                <TaskDescription task={taskDetails} />

                <FlexWrapper style={{ marginTop: "-30px" }}>
                  <FormDropdownField<EnhancedAutocompleteOption>
                    name="taskType"
                    label="Task Type"
                    values={taskTypeFormOptions}
                    disabled={isTaskFieldEditingRestricted(
                      "taskType",
                      taskDetails
                    )}
                    getOptionKey={(option) => option.value}
                  />
                  <FormDropdownField<TaskAssigneeAutocompleteOption>
                    name="assignee"
                    label="Assignee"
                    values={assigneesOptions}
                    disabled={isTaskFieldEditingRestricted(
                      "assignee",
                      taskDetails
                    )}
                    getOptionKey={(option) => option.value}
                  />
                </FlexWrapper>

                <Spacer verticalSpacing="10px" />

                <FlexWrapper>
                  <FormDropdownField<EnhancedAutocompleteOption>
                    name="client"
                    label="Client (Optional)"
                    values={clientsOptions}
                    disabled={isTaskFieldEditingRestricted(
                      "client",
                      taskDetails
                    )}
                    getOptionKey={(option) => option.value}
                    onClear={() => handleClearAutocompleteField("client")}
                  />
                  <FormDropdownField<EnhancedAutocompleteOption>
                    name="advisor"
                    label="Advisor"
                    values={advisorsOptions}
                    disabled={isTaskFieldEditingRestricted(
                      "advisor",
                      taskDetails
                    )}
                    getOptionKey={(option) => option.value}
                  />
                </FlexWrapper>

                <Spacer verticalSpacing="10px" />

                <FlexWrapper>
                  <FormDateField
                    name="dueDate"
                    label="Due Date"
                    placeholder=""
                    helperText="MM/DD/YYYY"
                    minDate={minDueDate}
                    maxDate={maxDueDate}
                    required="Please select a due date"
                    disabled={isTaskFieldEditingRestricted(
                      "dueDate",
                      taskDetails
                    )}
                    rules={{
                      validate: { isDateValid },
                    }}
                  />
                  <FormDropdownField<EnhancedAutocompleteOption>
                    name="custodian"
                    label="Custodian (Optional)"
                    values={custodianOptions}
                    disabled={isTaskFieldEditingRestricted(
                      "custodian",
                      taskDetails
                    )}
                    getOptionKey={(option) => option.value}
                    onClear={() => handleClearAutocompleteField("custodian")}
                  />
                </FlexWrapper>

                {taskDetails.attachments.length > 0 && (
                  <Stack className={styles.attachments} spacing={1}>
                    <FormSubHeader>Attachments</FormSubHeader>
                    {taskDetails.attachments.map((attachment) => (
                      <Stack
                        key={attachment.id}
                        direction="row"
                        justifyContent="space-between"
                      >
                        <AttachmentComponent
                          url={attachment.previewUrl}
                          name={attachment.filename}
                        />
                        <Menu
                          options={handleCreateMenuOptions(
                            taskDetails,
                            attachment
                          )}
                        >
                          {({ handleOpenMenu }) => (
                            <IconButton
                              iconClassName={styles.attachmentMenuIcon}
                              onClick={handleOpenMenu}
                              IconComponent={MoreHorizIcon}
                            />
                          )}
                        </Menu>
                      </Stack>
                    ))}
                    <Typography className={styles.attachmentsCounter}>
                      {taskDetails.attachments.length}/{ATTACHMENTS_LIMIT_COUNT}
                    </Typography>
                  </Stack>
                )}

                <TaskActivity task={taskDetails} taskComments={comments} />
              </Stack>
            </Fade>
          )}

          {hasError && (
            <Stack
              className={styles.errorContainer}
              justifyContent="center"
              alignItems="center"
            >
              <Typography className={styles.errorMessage}>
                {determineErrorMessage()}
              </Typography>
            </Stack>
          )}

          {isLoading && (
            <div className={styles.loading}>
              <LogoLoadingStill />
            </div>
          )}

          {isAlertVisible && taskDetails && (
            <AlertMessageModal
              top_text={() => (
                <p>
                  Are you sure you want to delete{" "}
                  <strong>"{taskDetails.title}"</strong> task?
                </p>
              )}
              button_blue_text="Yes"
              button_blue_onClick={handleDeleteTask}
              button_yellow_text="No"
              button_yellow_onClick={() => setIsAlertVisible(false)}
              img_src={WarningRedIcon}
            />
          )}
        </Drawer>
      </FormProvider>
    </Tasks>
  );
}
