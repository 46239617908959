import React, { useEffect, useState } from "react";

import HelloSign from "hellosign-embedded";
import { useLocation, useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

function postToNativeApp(str: string) {
  if ("ReactNativeWebView" in window) {
    const webView = window.ReactNativeWebView as any;
    if ("postMessage" in webView) {
      console.log({ event: str });
      webView.postMessage?.(str);
    }
  }
}

export default function MobileHelloSignPage(): JSX.Element {
  const { search } = useLocation();
  const { clientId: _clientId } = useParams<{ clientId: ClientId }>();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const qs = new URLSearchParams(search);
    const client = new HelloSign();

    const url = qs.get("url");

    if (url === null) {
      setError("No URL");
      alert("Must have a url");
      return;
    }

    const decodedURL = decodeURIComponent(url);

    client.open(decodedURL, {
      clientId: process.env.HELLOSIGN_CLIENT_ID,
      skipDomainVerification: process.env.WEBAPP_ENV !== "PROD",
    });

    /* Primary events */
    client.on("sign", async () => {
      postToNativeApp("sign");
    });

    client.on("cancel", () => {
      postToNativeApp("cancel");
    });

    client.on("error", async () => {
      postToNativeApp("error");
    });

    /* Secondary events */
    client.on("send", async () => {
      postToNativeApp("send");
    });

    client.on("close", async () => {
      postToNativeApp("close");
    });

    client.on("createTemplate", async () => {
      postToNativeApp("createTemplate");
    });

    client.on("decline", async () => {
      postToNativeApp("decline");
    });

    client.on("finish", async () => {
      postToNativeApp("finish");
    });

    client.on("message", async () => {
      postToNativeApp("message");
    });

    client.on("open", async () => {
      postToNativeApp("open");
    });

    client.on("ready", async () => {
      postToNativeApp("ready");
    });

    client.on("reassign", async () => {
      postToNativeApp("reassign");
    });
  }, [search]);

  if (error !== null) {
    return <div>{error}</div>;
  }

  return <div></div>;
}
