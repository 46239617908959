import React, { PropsWithChildren } from "react";

import AdminPage from "@src/multiCustodian/components/Admin/AdminPage";

import AdvisorContainer from "./AdvisorContainer";
import AdvisorContentContainer from "./AdvisorContentContainer";

const Admin: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <AdvisorContainer>
      <AdvisorContentContainer heroTitle={"Admin"}>
        <AdminPage>{children}</AdminPage>
      </AdvisorContentContainer>
    </AdvisorContainer>
  );
};

export default Admin;
