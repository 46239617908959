import React from "react";

import Slider from "./Slider";

import styles from "./SliderContainer.module.css";

interface Props {
  max: number;
  value: number;
  leftText: React.ReactNode;
  rightText: React.ReactNode;
}

const SliderContainer = (props: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.leftText}>{props.leftText}</div>
        <div className={styles.rightText}>{props.rightText}</div>
      </div>

      <Slider max={props.max} value={props.value} />
    </div>
  );
};

export default SliderContainer;
