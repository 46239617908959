import React, { PropsWithChildren } from "react";

import useGetProjections from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Projections/useGetProjections";
import {
  ClientId,
  PerformanceGroupId,
} from "@fartherfinance/frontend/api/Types";

import { noMonteCarloWarning } from "@src/multiCustodian/components/Client/Accounts/Projections";
import Projections from "@src/multiCustodian/components/Client/Accounts/Projections/ProjectionsTab";
import ScenariosTable from "@src/multiCustodian/components/Client/Accounts/Projections/ScenariosTable";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import { Disclaimer } from "@src/multiCustodian/pages/Dashboard/Performance_Groups/PG_hardcoded_exports";
import Spacer from "@src/sharedComponents/Forms/Spacer";

import styles from "@src/multiCustodian/components/Client/Accounts/Projections.module.css";

interface Props {
  clientId: ClientId | null;
  groupId: PerformanceGroupId | null;
}

const ProjectionsTab = (props: PropsWithChildren<Props>): JSX.Element => {
  const auth = useRequestAuth();

  const groupProjections = useGetProjections(props.groupId, auth);

  if (props.groupId === null || props.clientId === null) {
    return (
      <div className={styles.container}>
        <div className={styles.errorDiv}>Projections data unavailable</div>

        <Spacer verticalSpacing="10px" />

        <Disclaimer />
      </div>
    );
  }

  const securitiesRemoved =
    groupProjections.data !== undefined &&
    groupProjections.data.securitiesRemoved !== null &&
    groupProjections.data.securitiesRemoved.length > 0;

  if (securitiesRemoved) {
    return (
      <div className={styles.container}>
        <div className={styles.errorDiv}>{noMonteCarloWarning}</div>

        <Spacer verticalSpacing="10px" />

        <Disclaimer />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Projections
        clientId={props.clientId}
        groupId={props.groupId}
        graphHeight={300}
        displayContentSideBySide={true}
      >
        <div className={styles.accountGroupDropdownContainer}>
          {props.children}
        </div>
      </Projections>

      <Spacer verticalSpacing="30px" />

      <div className={styles.scenariosTitle}>Scenarios</div>

      <Spacer verticalSpacing="15px" />

      <ScenariosTable groupId={props.groupId} />

      <Spacer verticalSpacing="10px" />

      <Disclaimer />
    </div>
  );
};

export default ProjectionsTab;
