import React from "react";

import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./EquivalentSecuritiesAdminTable.module.css";

const EquivalentSecuritiesAdminTableLoadingRow: React.FC = () => {
  return (
    <div className={styles.row}>
      <Skeleton width={40} height={25} style={{ transform: "none" }} />

      <div className={styles.rowEnd}>
        <Skeleton width={100} height={25} style={{ transform: "none" }} />
      </div>
    </div>
  );
};

export default EquivalentSecuritiesAdminTableLoadingRow;
