import React, { useMemo, useState } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useHistory, useParams } from "react-router-dom";

import useDeleteAccount from "@fartherfinance/frontend/api/Accounts/hooks/useDeleteAccount";
import useGetFartherManagedAccounts from "@fartherfinance/frontend/api/Accounts/hooks/useGetFartherManagedAccounts";
import { FartherManagedAccount } from "@fartherfinance/frontend/api/Accounts/Types";
import useAdvisorClients from "@fartherfinance/frontend/api/Entity/hooks/useAdvisorClients";
import { ClientId, FartherAccountId } from "@fartherfinance/frontend/api/Types";

import AccountBalance from "../Shared/AccountBalance";
import AccountBalanceV4 from "../Shared/AccountBalanceV4";
import LoadingModal from "../Shared/LoadingModal";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import Modal from "@src/sharedComponents/Modal/Modal";
import { extractAxiosErrorMessage, isAxiosErrorCode } from "@src/utils/axios";

import styles from "../Shared/DeleteAccountModal.module.css";

const ArchiveAccountModal = (): JSX.Element => {
  const [isMutating, setIsMutating] = useState<boolean>(false);

  const { clientId, accountId } = useParams<{
    clientId: ClientId;
    accountId: FartherAccountId;
  }>();

  const { enableCustomPerformanceGroupsV2 } = useFlags();

  const history = useHistory();

  const statusNotification = useStatusNotification();

  const auth = useAdvisorRequestAuth();
  const clientRequestConfig = {
    clientId: clientId,
    advisorId: auth?.advisorId,
    jwt: auth?.jwt ?? "",
  };
  const accounts = useGetFartherManagedAccounts(clientId, clientRequestConfig);
  const softDeleteAccount = useDeleteAccount(auth);

  const account: FartherManagedAccount | null | undefined = useMemo(() => {
    if (accounts.data === undefined) {
      return null;
    }

    const targetAccount = accounts.data.find(
      (acc) => acc.virtualAccountId === accountId
    );

    return targetAccount;
  }, [accounts.data, accountId]);

  const advisorClients = useAdvisorClients(undefined, auth);

  const currentClient = useMemo(() => {
    if (advisorClients.data === undefined) {
      return null;
    }

    return (
      advisorClients.data.clients.find(
        (client) => client.clientId === clientId
      ) ?? null
    );
  }, [advisorClients.data, clientId]);

  const onDelete = async () => {
    if (!accountId) {
      statusNotification("Error finding the account", "Error");
      return;
    }

    try {
      setIsMutating(true);

      await softDeleteAccount({
        clientId: clientId,
        accountId: accountId,
        deleteAccountAction: "Soft",
      });

      setIsMutating(false);

      statusNotification("Account archived", "Success");

      history.push({
        pathname: `/Advisor/Clients/${clientId}/Admin`,
        state: history.location.state,
      });
    } catch (error) {
      const errorMessage = isAxiosErrorCode(400, error)
        ? extractAxiosErrorMessage(error)
        : "Error archiving account";

      statusNotification(errorMessage, "Error");
      setIsMutating(false);
    }
  };

  if (accounts.hasError || account === undefined) {
    return <div>Error retrieving account</div>;
  }

  if (accounts.isLoading || isMutating || account === null) {
    return (
      <LoadingModal
        title={"Are you sure you want to archive this account?"}
        description={
          "This action will remove the account from the client portal, but we will still preserve the account's records in our system."
        }
      />
    );
  }

  return (
    <Modal
      closeModal={() =>
        history.push({
          pathname: `/Advisor/Clients/${clientId}/Admin`,
          state: history.location.state,
        })
      }
      modalStyle={{ width: "560px" }}
    >
      <div className={styles.modalTitle}>
        Are you sure you want to archive this account?
      </div>

      <div className={styles.modalText}>
        This action will remove the account from the client portal, but we will
        still preserve the account's records in our system.
      </div>

      <div className={styles.table}>
        <div className={styles.tableHeader}>
          {currentClient
            ? `${currentClient.name.first} ${currentClient.name.last}`
            : "N/A"}
        </div>

        <div className={styles.tableBody}>
          <div className={styles.tableBodyLeft}>
            <div className={styles.tableBodyLeftTop}>
              {account.accountDetails.displayName}
            </div>

            <div className={styles.tableBodyLeftBottom}>{`${
              account.accountDetails.custodian
            } - ${account.accountDetails.accountType} (${
              account.accountDetails.custodianAccountNumber ?? ""
            })`}</div>
          </div>

          <div className={styles.tableBodyRight}>
            <div className={styles.tableBodyRightTop}>Balance</div>

            {enableCustomPerformanceGroupsV2 ? (
              <AccountBalanceV4 clientId={clientId} accountId={accountId} />
            ) : (
              <AccountBalance clientId={clientId} accountId={accountId} />
            )}
          </div>
        </div>
      </div>

      <div className={styles.modalFooter}>
        <Button
          variant={"outlined"}
          buttonType={"secondary"}
          text={"Cancel"}
          onClick={() =>
            history.push({
              pathname: `/Advisor/Clients/${clientId}/Admin`,
              state: history.location.state,
            })
          }
        />

        <Button
          variant={"outlined"}
          buttonType={"warning"}
          text={"Archive Account"}
          onClick={onDelete}
          style={{ marginLeft: "15px" }}
        />
      </div>
    </Modal>
  );
};

export default ArchiveAccountModal;
