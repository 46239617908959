import React, { useCallback, useMemo, useState } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import { Collapse, InputAdornment, Typography } from "@mui/material";
import { debounce, partition } from "lodash";

import {
  OfferingsListQueryParams,
  OfferingsListToolbarFilters,
} from "@src/multiCustodian/components/Marketplace/OfferingsList/useOfferingsListDataV2";
import DeletableChip from "@src/sharedComponents/DeletableChip/DeletableChip";
import IconButton from "@src/sharedComponents/IconButton/IconButton";
import TextInput from "@src/sharedComponents/TextInput/TextInput";

import { mapFilterKeyToLabel } from "./OfferingsListToolbar.utils";

import styles from "./OfferingsListToolbar.module.css";

interface OfferingsListToolbarProps {
  isViewAllMode: boolean;
  offeringsCount: number;
  filters: OfferingsListToolbarFilters;
  filterComponent: React.ReactNode;
  searchByKeyword: string | undefined;
  onNavigateBackClick: () => void;
  onQueryParamChange: (queryParams: Partial<OfferingsListQueryParams>) => void;
  onQueryParamReset: (queryParams: Partial<OfferingsListQueryParams>) => void;
}

const OfferingsListToolbar: React.FC<OfferingsListToolbarProps> = ({
  isViewAllMode,
  offeringsCount,
  filters,
  filterComponent,
  searchByKeyword = "",
  onNavigateBackClick,
  onQueryParamChange,
  onQueryParamReset,
}) => {
  const [searchValue, setSearchValue] = useState(searchByKeyword);

  const { hasFilters, hasMoreThanTwoFilters, standardFilters, categoryFilter } =
    useMemo(() => {
      const [categoryFilter, standardFilters] = partition(
        Object.entries(filters),
        ([filterKey]) => filterKey === "filterByOfferingType"
      );

      const combinedFilters = [
        ...standardFilters,
        ...(isViewAllMode ? categoryFilter : []),
      ];
      const hasFilters = Object.values(combinedFilters).flat().length > 0;
      const hasMoreThanTwoFilters = Object.keys(combinedFilters).length > 1;

      return {
        hasFilters,
        hasMoreThanTwoFilters,
        standardFilters,
        categoryFilter,
      };
    }, [filters, isViewAllMode]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchValueChange = useCallback(
    debounce<(value: string) => void>((value) => {
      const keyword = value.length > 0 ? value.trim() : undefined;

      onQueryParamChange({ page: 1, searchByKeyword: keyword });
    }, 600),
    [onQueryParamChange]
  );

  const handleResetQueryParams = (): void => {
    onQueryParamReset({ searchByKeyword: searchValue });
  };

  return (
    <div>
      <div className={styles.header}>
        <IconButton
          size="medium"
          onClick={onNavigateBackClick}
          IconComponent={ArrowBackIcon}
        />
      </div>

      <div className={styles.toolbar}>
        <div className={styles.actions}>
          <TextInput
            label=""
            value={searchValue}
            onChange={(event) => {
              setSearchValue(event.target.value);
              handleSearchValueChange(event.target.value);
            }}
            placeholder="Search By Fund or Firm..."
            style={{ width: "240px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={styles.searchIcon} />
                </InputAdornment>
              ),
            }}
          />

          {filterComponent}
        </div>

        <Typography className={styles.counter}>
          Assets ({offeringsCount})
        </Typography>
      </div>

      <Collapse in={hasFilters} unmountOnExit={true} mountOnEnter={false}>
        <div className={styles.filters}>
          {hasMoreThanTwoFilters && (
            <DeletableChip
              label="Clear"
              onPress={handleResetQueryParams}
              onClear={handleResetQueryParams}
            />
          )}

          {isViewAllMode &&
            categoryFilter.map(([filterKey]) => (
              <DeletableChip
                key={filterKey}
                label={mapFilterKeyToLabel(
                  filterKey as keyof OfferingsListToolbarFilters
                )}
                onPress={() => null}
                onClear={() => onQueryParamChange({ [filterKey]: undefined })}
              />
            ))}

          {standardFilters.map(([filterKey, values]) => (
            <DeletableChip
              key={filterKey}
              label={`${values.length} ${mapFilterKeyToLabel(
                filterKey as keyof OfferingsListToolbarFilters
              )}`}
              onPress={() => null}
              onClear={() => onQueryParamChange({ [filterKey]: undefined })}
            />
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export default OfferingsListToolbar;
