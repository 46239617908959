import { Custodian } from "@fartherfinance/frontend/api/Dashboard/requests/getClientDashboard";
import { AccountType } from "@fartherfinance/frontend/api/Types";

type AccountTypesEnabled = Record<AccountType, boolean>;
type CustodianAccountTypesDB = Record<Custodian, AccountTypesEnabled>;

export const achAccountOptions: CustodianAccountTypesDB = {
  Apex: {
    Individual: true,
    IRA: true,
    "Roth IRA": true,
    "Sep IRA": true,
    Trust: true,
    "Joint WROS": true,
    "Joint TIC": true,
    "Joint CP": true,
    "Joint TE": true,
    "Asset Lending": true,
    Other: true,
    "Rollover IRA": true,
    "Inherited IRA": false,
    "Inherited Roth IRA": false,
  },
  DPL: {
    Individual: false,
    IRA: false,
    "Roth IRA": false,
    "Sep IRA": false,
    Trust: false,
    "Joint WROS": false,
    "Joint TIC": false,
    "Joint CP": false,
    "Joint TE": false,
    "Asset Lending": false,
    Other: false,
    "Rollover IRA": false,
    "Inherited IRA": false,
    "Inherited Roth IRA": false,
  },
  Fidelity: {
    Individual: false,
    IRA: false,
    "Roth IRA": false,
    "Sep IRA": false,
    Trust: false,
    "Joint WROS": false,
    "Joint TIC": false,
    "Joint CP": false,
    "Joint TE": false,
    "Asset Lending": false,
    Other: false,
    "Rollover IRA": false,
    "Inherited IRA": false,
    "Inherited Roth IRA": false,
  },
  Pershing: {
    Individual: true,
    IRA: false,
    "Roth IRA": false,
    "Sep IRA": false,
    Trust: true,
    "Joint WROS": true,
    "Joint TIC": true,
    "Joint CP": true,
    "Joint TE": false,
    "Asset Lending": false,
    Other: false,
    "Rollover IRA": false,
    "Inherited IRA": false,
    "Inherited Roth IRA": false,
  },
  Pontera: {
    Individual: false,
    IRA: false,
    "Roth IRA": false,
    "Sep IRA": false,
    Trust: false,
    "Joint WROS": false,
    "Joint TIC": false,
    "Joint CP": false,
    "Joint TE": false,
    "Asset Lending": false,
    Other: false,
    "Rollover IRA": false,
    "Inherited IRA": false,
    "Inherited Roth IRA": false,
  },
  Schwab: {
    Individual: false,
    IRA: false,
    "Roth IRA": false,
    "Sep IRA": false,
    Trust: false,
    "Joint WROS": false,
    "Joint TIC": false,
    "Joint CP": false,
    "Joint TE": false,
    "Asset Lending": false,
    Other: false,
    "Rollover IRA": false,
    "Inherited IRA": false,
    "Inherited Roth IRA": false,
  },
};
