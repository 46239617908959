import { AccessLevel } from "@fartherfinance/frontend/api/Sharing/Types";

import { AccessLevelForDisplay } from "../Types";
import { assertUnreachable } from "@src/utils/assertUnreachable";

export const getAccessLevelForDisplay = (
  accessLevel: AccessLevel
): Exclude<AccessLevelForDisplay, "Delete"> => {
  switch (accessLevel) {
    case "All":
      return "All-Access";
    case "Limited":
      return "Limited Access";
    case "None": // "Delete" should not be shown in the UI
      return "Revoke";
    default:
      return assertUnreachable(accessLevel);
  }
};
