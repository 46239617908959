import React from "react";

const ClientOps: React.FC = () => (
  <svg
    width="100%"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="table">
      <mask
        id="mask0_828_9850"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="14"
        height="14"
      >
        <rect id="Bounding box" width="14" height="14" fill="currentColor" />
      </mask>
      <g mask="url(#mask0_828_9850)">
        <path
          id="table_2"
          d="M2.1001 10.8496V3.14961C2.1001 2.86086 2.20291 2.61367 2.40854 2.40805C2.61416 2.20242 2.86135 2.09961 3.1501 2.09961H10.8501C11.1388 2.09961 11.386 2.20242 11.5917 2.40805C11.7973 2.61367 11.9001 2.86086 11.9001 3.14961V10.8496C11.9001 11.1384 11.7973 11.3855 11.5917 11.5912C11.386 11.7968 11.1388 11.8996 10.8501 11.8996H3.1501C2.86135 11.8996 2.61416 11.7968 2.40854 11.5912C2.20291 11.3855 2.1001 11.1384 2.1001 10.8496ZM3.1501 5.20586H10.8501V3.14961H3.1501V5.20586ZM6.06676 8.03503H7.93343V6.25586H6.06676V8.03503ZM6.06676 10.8496H7.93343V9.08503H6.06676V10.8496ZM3.1501 8.03503H5.01676V6.25586H3.1501V8.03503ZM8.98343 8.03503H10.8501V6.25586H8.98343V8.03503ZM3.1501 10.8496H5.01676V9.08503H3.1501V10.8496ZM8.98343 10.8496H10.8501V9.08503H8.98343V10.8496Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default ClientOps;
