import React, { useMemo } from "react";

import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { FormProvider, useForm, useFormState, useWatch } from "react-hook-form";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { ClientId, Ticker } from "@fartherfinance/frontend/api/Types";

import { AllocatedSecurity } from "@src/multiCustodian/components/Client/Portfolio/SecuritiesAllocationConfirmTable/SecuritiesAllocationConfirmTable";
import SecuritiesAllocationTable, {
  Security,
} from "@src/multiCustodian/components/Client/Portfolio/SecuritiesAllocationTable/SecuritiesAllocationTable";
import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import { weightAdder } from "@src/utils/weightAdder";

import BackAndExitButtonWrapper from "./BackAndExitButtonWrapper";

import styles from "./Allocation.module.css";

type SecurityAllocation = Ticker;

type Form = {
  [_k in SecurityAllocation]: string | undefined;
};

const CustomPortfolioSecuritiesAllocation = (): JSX.Element => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const history = useHistory();

  const { search: urlSearchParams } = useLocation<{
    from: Location | undefined;
  }>();

  const qs = useMemo(
    () => new URLSearchParams(urlSearchParams),
    [urlSearchParams]
  );
  const modelName = qs.get("name");
  const modelTaxStatus = qs.get("status");

  const parsedSecurities: (Security | AllocatedSecurity)[] = useMemo(
    () =>
      JSON.parse(qs.get("securities") ?? "[]").map(
        (security: Security | AllocatedSecurity): Security => ({
          displayName: security.displayName,
          ticker: security.ticker,
        })
      ),
    [qs]
  );

  if (
    modelTaxStatus !== "Any" &&
    modelTaxStatus !== "Taxable" &&
    modelTaxStatus !== "Tax-Advantaged"
  ) {
    history.push(`/Client/${clientId}/Investments/Portfolios/Create/Custom`);
  }

  const defaultValues = useMemo(
    () =>
      JSON.parse(qs.get("securities") ?? "[]").reduce(
        (acc: Form, security: Security | AllocatedSecurity) => ({
          ...acc,
          [security.ticker]:
            "allocation" in security
              ? security.allocation.toString()
              : undefined,
        }),
        {}
      ),
    [qs]
  );

  const methods = useForm<Form>({
    mode: "all",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    defaultValues: defaultValues,
  });

  const { control, getValues } = methods;

  const { isValid, errors } = useFormState({
    control,
  });

  const formValues = useWatch({ control: control });

  const sum = useMemo(
    () =>
      weightAdder<[string, string | undefined]>(([_key, val]) => val ?? "0")(
        Object.entries(formValues)
      ),
    [formValues]
  );

  const allFilledOut =
    Object.entries(formValues).length > 0 &&
    Object.entries(formValues).reduce(
      (filledOut, [_key, val]) => filledOut && val !== "" && val !== undefined,
      true
    );

  const sumIs100 = sum === 100;

  const onContinue = () => {
    const values = getValues();

    const newParams = new URLSearchParams(urlSearchParams);

    const allocatedSec = parsedSecurities.map(
      (security): AllocatedSecurity => ({
        ...security,
        allocation: parseFloat(values[security.ticker] ?? "0"),
      })
    );

    newParams.set("securities", JSON.stringify(allocatedSec));

    history.push({
      pathname: `/Client/${clientId}/Investments/Portfolios/Create/Custom/Confirm`,
      search: newParams.toString(),
    });
  };

  const backButtonOnClick = () =>
    history.push({
      pathname: `/Client/${clientId}/Investments/Portfolios/Create/Custom/Securities`,
      search: qs.toString(),
    });

  return (
    <BaseLayout showSideNav={false}>
      <BackAndExitButtonWrapper backButtonOnClick={backButtonOnClick}>
        <div className={styles.contentContainer}>
          <>
            <div className={styles.titleDiv}>
              <div className={styles.title}>Establish Allocation</div>
              <div className={styles.modelTypePill}>{modelTaxStatus}</div>
            </div>

            <div className={styles.subTitleText}>
              Please provide a percentage allocation for each security.
            </div>

            <div className={styles.tableContainer}>
              <FormProvider {...methods}>
                <SecuritiesAllocationTable
                  portfolioName={modelName ? modelName : "Great Portfolio!"}
                  securities={parsedSecurities}
                  total={sum}
                />
              </FormProvider>

              {Object.entries(errors).length > 0 ||
              (allFilledOut && !sumIs100) ? (
                <div className={styles.errorBlock}>
                  <div className={styles.flexDiv}>
                    <WarningAmberRoundedIcon className={styles.warningIcon} />

                    <p className={styles.warningText}>
                      {Object.entries(errors).length > 0
                        ? Object.entries(errors)[0][1]?.message ??
                          "Unknown error"
                        : "Sum must add up to 100%"}
                    </p>
                  </div>
                </div>
              ) : null}
            </div>
          </>

          <div className={styles.footer}>
            <Button
              text="Review"
              onClick={onContinue}
              variant="contained"
              buttonType="primary"
              disabled={!isValid || !sumIs100}
            />
          </div>
        </div>
      </BackAndExitButtonWrapper>
    </BaseLayout>
  );
};

export default CustomPortfolioSecuritiesAllocation;
