// returns plural form of word, if necessary
export const pluralize = (
  collection: unknown[] | number,
  word: string,
  showNumInFront?: boolean
): string => {
  const length = Array.isArray(collection) ? collection.length : collection;

  if (length === 1) {
    return showNumInFront ? `${length} ${word}` : word;
  }

  const plural = word.endsWith("y") ? `${word.slice(0, -1)}ies` : `${word}s`;

  return showNumInFront ? `${length} ${plural}` : plural;
};
