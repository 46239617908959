import React, { useMemo } from "react";

import { format, parseISO } from "date-fns";
import { orderBy } from "lodash";

import { ManualAccountDetails } from "@fartherfinance/frontend/api/Accounts/requests/getManualAccountDetailVanadium";

import { toSortable } from "../../../Holdings/Components/BreakdownTable/HoldingsTable";
import { activityRowsPerPage } from "@src/multiCustodian/components/PerformanceGroups/Activity/ActivityV4";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import PerformanceIndicatorArrow from "@src/sharedComponents/PerformanceIndicatorArrow/PerformanceIndicatorArrow";
import FullDataTable from "@src/sharedComponents/Table/FullDataTable";
import { Cell, Row } from "@src/sharedComponents/Table/Types";
import toUSD from "@src/utils/toUSD";

import styles from "./ActivityTable.module.css";

export const tableKeys = ["Date", "Type", "Gain / Loss", "Value"] as const;

export type TableKey = typeof tableKeys[number];

interface Props {
  manualAsset: ManualAccountDetails;
}

const ActivityTable = ({ manualAsset }: Props): JSX.Element => {
  const sortedPrices = useMemo(
    () => orderBy(manualAsset.values, (a) => a.createdAt, "desc"),
    [manualAsset]
  );

  const activity: Row<TableKey, Cell>[] = useMemo(() => {
    return sortedPrices.map((ap, idx, arr) => {
      const type = idx === arr.length - 1 ? "Added Account" : "Updated Value";

      const nextAssetValue = arr[idx + 1]?.value ?? null;

      const gain = nextAssetValue === null ? 0 : ap.value - nextAssetValue;

      return {
        key: `${idx}`,
        Date: {
          value: (
            <div className={toClassName(styles.subtleText)}>
              {format(parseISO(ap.createdAt), "M/d/yyyy")}
            </div>
          ),
          fullValue: ap.createdAt,
        },
        Type: {
          value: type,
        },
        "Gain / Loss": {
          value: (
            <div className={styles.subtleText}>
              <span
                className={toClassName({ [styles.arrowHidden]: gain === 0 })}
              >
                <PerformanceIndicatorArrow
                  style={{ marginRight: "5px" }}
                  status={
                    gain > 0 ? "positive" : gain < 0 ? "negative" : "neutral"
                  }
                />
              </span>

              <span>{toUSD(gain)}</span>
            </div>
          ),
          fullValue: toSortable(gain),
        },
        Value: {
          value: <div>{toUSD(ap.value)}</div>,
          fullValue: toSortable(ap.value),
        },
      };
    });
  }, [sortedPrices]);

  return (
    <FullDataTable
      columns={tableKeys}
      rows={activity}
      defaultRowsPerPage={activityRowsPerPage}
      defaultSortColumn={["Date", "desc"]}
      disableColumnSorting={activity.length > 0 ? undefined : tableKeys}
      emptyCell={<div>placeholder</div>}
    />
  );
};

export default ActivityTable;
