import React from "react";

const Advisor: React.FC = () => (
  <svg
    width="100%"
    viewBox="0 0 64 64"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.04 44.5565V28.1273L42.5956 19H30.1511L27.7948 22.9318L23.04 30.8655L28.1362 31.6131C28.4122 31.6536 28.6885 31.6354 28.9493 31.5647C29.3481 31.4567 29.7106 31.2259 29.9794 30.8946L33.5317 26.5174L33.7067 26.3019H38.1511L47.04 45.88L55.04 44.5565ZM63.9997 25.3999H58V47.1599H63.9997V25.3999ZM0 21.5601H6V43.3201H0V21.5601ZM25.0705 21.6415L8.95996 24.1201V40.7601H11.6576L18.56 52.2801H23.1636L21.1458 48.6482C20.7435 47.924 21.0044 47.0108 21.7286 46.6085C22.4528 46.2061 23.366 46.4671 23.7683 47.1912L26.5954 52.2801H30.9857L27.5457 46.0881C27.1434 45.3639 27.4043 44.4507 28.1285 44.0484C28.8527 43.6461 29.7659 43.907 30.1682 44.6312L34.4176 52.2801H37.12L38.352 51.4587L33.9461 43.5281C33.5438 42.8039 33.8047 41.8907 34.5289 41.4884C35.2531 41.086 36.1663 41.3469 36.5686 42.0711L40.8564 49.7891L44.4365 47.4024L36.2184 29.3019H35.1356L32.3088 32.785C31.1972 34.1547 29.4461 34.8373 27.7008 34.5813L18.1548 33.181L25.0705 21.6415Z"
      fill="currentColor"
    />
  </svg>
);

export default Advisor;
