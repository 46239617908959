import React from "react";

import styles from "./TitleBlock.module.css";

interface Props {
  title: string;
  subTitle?: string;
}

const TitleBlock: React.FC<Props> = ({ title, subTitle }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
    </div>
  );
};

export default TitleBlock;
