import { HydratedSecurity } from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Holdings/utils";

/**
 * @returns null or a number representing the profit (or loss) in percent as a decimal.
 * Considers the quantity of securities and the price at which they were purchased,
 * compared to the current market value of the sum of all securities.
 *
 * @info
 * null is returned if the total cost is 0. typically this is the case with CASH.
 *
 * @example
 * calculate({quantity: 5, costBasis: 10, marketValue: 100})
 * // (marketValue - (quantity * costBasis)) / (quantity * costBasis)
 * //      (100    - (    5    *    10   )) / (    5    *    10   )
 * // ->    1 (profit of 100%)
 */
const calculateProfitPercent = (lot: HydratedSecurity): number | null => {
  const totalCost = lot.quantity * lot.costBasis;

  if (totalCost === 0) {
    return null;
  }

  return (lot.marketValue - totalCost) / totalCost;
};

export default calculateProfitPercent;
