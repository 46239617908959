import React, { PropsWithChildren } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useParams } from "react-router-dom";

import { ClientId, FartherAccountId } from "@fartherfinance/frontend/api/Types";

import Accounts from "../Accounts";
import AccountsDrawer from "@src/multiCustodian/components/Client/Accounts/AccountsDrawer";
import AccountsDrawerV4 from "@src/multiCustodian/components/Client/Accounts/AccountsDrawerV4";
import AccountSummaryContent from "@src/multiCustodian/components/Client/Accounts/Summary/Summary";
import AccountSummaryContentV4 from "@src/multiCustodian/components/Client/Accounts/Summary/SummaryV4";
import PerformanceCustomDateRangeContextProvider from "@src/multiCustodian/components/PerformanceGroups/Summary/components/PerformanceCustomDateRangeContextProvider";

export default function AccountSummary(
  props: PropsWithChildren<unknown>
): JSX.Element {
  const { clientId, accountId } = useParams<{
    clientId: ClientId;
    accountId: FartherAccountId;
  }>();

  const { enableCustomPerformanceGroupsV2 } = useFlags();

  return (
    <Accounts>
      <PerformanceCustomDateRangeContextProvider>
        {enableCustomPerformanceGroupsV2 ? (
          <AccountsDrawerV4 curTab="Summary">
            <AccountSummaryContentV4
              clientId={clientId}
              accountId={accountId}
            />

            {props.children}
          </AccountsDrawerV4>
        ) : (
          <AccountsDrawer curTab="Summary">
            <AccountSummaryContent clientId={clientId} accountId={accountId} />

            {props.children}
          </AccountsDrawer>
        )}
      </PerformanceCustomDateRangeContextProvider>
    </Accounts>
  );
}
