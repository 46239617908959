import React from "react";

import Stack from "@mui/material/Stack";
import {
  endOfToday,
  endOfYesterday,
  isAfter,
  isBefore,
  isValid,
} from "date-fns";
import { FormProvider, useForm } from "react-hook-form";

import { ProposalsFilterQueryParams } from "@fartherfinance/frontend/api/Rebalance/requests/getProposals";
import {
  Custodian,
  custodianOptions,
} from "@fartherfinance/frontend/api/Types";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormCheckboxField from "@src/sharedComponents/Forms/FormCheckboxField";
import FormDateField from "@src/sharedComponents/Forms/FormDateField";
import FormDropdownMultiSelectField from "@src/sharedComponents/Forms/FormDropdownMultiSelectField";
import Modal from "@src/sharedComponents/Modal/Modal";

import { useStyles } from "./ProposalsFilterForm.styles";
import {
  transformFilterQueryParamsToFormValues,
  transformFormValuesToFilterQueryParams,
} from "./ProposalsFilterForm.utils";

const isDateValid = (date: Date | null): boolean | string => {
  return date && !isValid(date) ? "Invalid date" : true;
};

const isInFuture = (date: Date | null, maxDate: Date): boolean | string => {
  return date && isAfter(date, maxDate) ? "Cannot select future dates" : true;
};

export interface FormData {
  filterByCustodian?: Custodian[];
  filterByIsMaterialOrMultiDayExecution?: boolean;
  filterByTimestampFrom?: Date | null;
  filterByTimestampTo?: Date | null;
}

interface ProposalsFilterFormProps {
  isDailyProposalsView: boolean;
  isSkippedProposalsView: boolean;
  currentFilters: ProposalsFilterQueryParams;
  onSubmit: (newFilters: ProposalsFilterQueryParams) => void;
  onClose: () => void;
}

export const ProposalsFilterForm: React.FC<ProposalsFilterFormProps> = ({
  isDailyProposalsView,
  isSkippedProposalsView,
  currentFilters,
  onSubmit,
  onClose,
}) => {
  const { classes } = useStyles();
  const form = useForm<FormData>({
    mode: "onChange",
    defaultValues: transformFilterQueryParamsToFormValues(currentFilters),
  });

  const maxDate = isSkippedProposalsView ? endOfYesterday() : endOfToday();

  const handleFormSubmit = (): void => {
    form.handleSubmit((formData) => {
      onSubmit(transformFormValuesToFilterQueryParams(formData));
      onClose();
    })();
  };

  return (
    <Modal closeModal={onClose}>
      <FormProvider {...form}>
        <form
          className={classes.form}
          onSubmit={form.handleSubmit(handleFormSubmit)}
        >
          <FlexWrapper className={classes.formFirstRow}>
            <FormDropdownMultiSelectField
              name="filterByCustodian"
              label="Custodian"
              values={custodianOptions}
            />
            <FormCheckboxField
              name="filterByIsMaterialOrMultiDayExecution"
              label="Show all proposals"
            />
          </FlexWrapper>

          <FlexWrapper>
            <FormDateField
              name="filterByTimestampFrom"
              label="Date Created From"
              maxDate={maxDate}
              disabled={isDailyProposalsView}
              rules={{
                deps: ["filterByTimestampTo"],
                validate: {
                  isDateValid,
                  isInFuture: (value) => isInFuture(value, maxDate),
                  isInRange: (value, { filterByTimestampTo }) => {
                    return value && isAfter(value, filterByTimestampTo)
                      ? "From date cannot be after To date"
                      : true;
                  },
                },
              }}
            />
            <FormDateField
              name="filterByTimestampTo"
              label="Date Created To"
              maxDate={maxDate}
              disabled={isDailyProposalsView}
              rules={{
                deps: ["filterByTimestampFrom"],
                validate: {
                  isDateValid,
                  isInFuture: (value) => isInFuture(value, maxDate),
                  isInRange: (value, { filterByTimestampFrom }) => {
                    return value && isBefore(value, filterByTimestampFrom)
                      ? "To date cannot be before From date"
                      : true;
                  },
                },
              }}
            />
          </FlexWrapper>

          <Stack className={classes.footer} direction="row">
            <Button
              style={{ marginLeft: "auto" }}
              variant="contained"
              buttonType="primary"
              text="Apply changes"
              onClick={handleFormSubmit}
            />
          </Stack>
        </form>
      </FormProvider>
    </Modal>
  );
};
