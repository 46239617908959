import React from "react";

import { Layout } from "./Layout";
import { Providers } from "./Providers";

export const YellowstoneShell: React.FC = ({ children }) => {
  return (
    <Providers>
      <Layout>{children}</Layout>
    </Providers>
  );
};
