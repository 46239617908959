import React from "react";

import FormTextareaField from "@src/sharedComponents/Forms/FormTextareaField";

interface OpportunityAdditionalDetailsProps {
  fieldName: string;
}

export default function OpportunityAdditionalDetails({
  fieldName,
}: OpportunityAdditionalDetailsProps): JSX.Element {
  return (
    <FormTextareaField
      name={fieldName}
      label="Additional Details (Optional)"
      placeholder="Provide additional details..."
      minRows={3}
    />
  );
}
