import React, { PropsWithChildren } from "react";

import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import useAdvisorClients from "@fartherfinance/frontend/api/Entity/hooks/useAdvisorClients";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import Clients from "../../../../pages/Advisor/Clients";
import useIsAdmin from "@src/multiCustodian/components/Advisor/utils/useIsAdmin";
import Drawer, {
  largeDrawer,
} from "@src/multiCustodian/components/Drawer/Drawer";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import useImpersonateClient from "@src/multiCustodian/hooks/Advisor/useImpersonateClient";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import FormSubHeader from "@src/sharedComponents/Forms/FormSubHeader";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import { State } from "@src/store";

import Tabs, { ClientDrawerTab } from "./Tabs";

import styles from "./ClientProfile.module.css";

interface Props {
  curTab: ClientDrawerTab;
  footer?: React.ReactNode;
}

export default function ClientDrawer(props: PropsWithChildren<Props>) {
  const { clientId } = useParams<{ clientId: ClientId }>();

  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));

  const closeDrawer = (): void => {
    history.push({ pathname: "/Advisor/Clients", search: location.search });
  };

  const history = useHistory();

  const location = useLocation();

  const isAdmin = useIsAdmin(advisorId);

  const impersonateClient = useImpersonateClient();

  const auth = useAdvisorRequestAuth();

  const statusNotification = useStatusNotification();

  const advisorClients = useAdvisorClients(undefined, auth);

  if (isAdmin.hasError || advisorClients.hasError) {
    return (
      <Clients>
        <Drawer
          isDrawerOpen
          onClose={closeDrawer}
          transitionDuration={0}
          container_style={largeDrawer}
        >
          <div className={styles.error}>
            Error getting clients or checking permission
          </div>
        </Drawer>
      </Clients>
    );
  }

  if (isAdmin.isLoading || advisorClients.isLoading) {
    return (
      <Clients>
        <Drawer
          isDrawerOpen
          onClose={closeDrawer}
          transitionDuration={0}
          container_style={largeDrawer}
        >
          <div className={styles.loading}>
            <LogoLoadingStill />
          </div>
        </Drawer>
      </Clients>
    );
  }

  const currentClient =
    advisorClients.data.clients.find(
      (client) => client.clientId === clientId
    ) ?? null;

  if (currentClient === null) {
    return (
      <Clients>
        <Drawer
          isDrawerOpen
          onClose={closeDrawer}
          transitionDuration={0}
          container_style={largeDrawer}
        >
          <div className={styles.error}>Client not found</div>
        </Drawer>
      </Clients>
    );
  }

  const clientName = `${currentClient.name.first} ${currentClient.name.last}`;

  return (
    <Clients>
      <Drawer
        isDrawerOpen
        onClose={closeDrawer}
        transitionDuration={0}
        footer={props.footer}
        container_style={largeDrawer}
      >
        <div className={styles.header}>
          <FormH1>{clientName}</FormH1>

          <ButtonPrimary
            variant="contained"
            buttonType="primary"
            text="Log In"
            style={{ whiteSpace: "nowrap" }}
            onClick={() => {
              if (currentClient === null) {
                return;
              }

              impersonateClient(
                currentClient.clientId,
                currentClient.emailAddress,
                currentClient.name
              ).catch((e) => {
                console.error(e);
                statusNotification("Could not log in as client", "Error");
              });
            }}
          />
        </div>

        <FormSubHeader>Client Profile</FormSubHeader>

        <Spacer />

        <Tabs curTab={props.curTab} />

        {props.children}
      </Drawer>
    </Clients>
  );
}
