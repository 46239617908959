import React from "react";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import Modal from "@src/sharedComponents/Modal/Modal";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./modal.module.css";

interface Props {
  closeModal: () => void;
  isMutating: boolean;
  onConfirm: () => void;
}

const EnableTaxLossHarvestingModal = (props: Props): JSX.Element => {
  const auth = useRequestAuth();
  const advisorId = auth?.advisorId;

  return (
    <Modal closeModal={props.closeModal} modalStyle={{ width: "520px" }}>
      <p className={styles.modalHeader}>Enable Tax-Loss Harvesting</p>

      {advisorId === undefined ? (
        <>
          <p className={styles.modalText2}>
            If you would like to link your Farther accounts to your spouse's
            Farther accounts for wash sale prevention purposes, please reach out
            to your advisor.
          </p>

          <p className={styles.modalText2}>
            If this situation does not apply to you, please click Confirm to
            enable tax-loss harvesting.
          </p>
        </>
      ) : (
        <>
          <p className={styles.modalText2}>
            If you would like to link the client's Farther accounts with their
            spouse's Farther accounts for wash sale prevention purposes, please
            fill out a Client Ops Request in the
            <a
              className={styles.link}
              href={"https://farther-linear.onrender.com/home"}
              target={"_blank"}
              rel={"noreferrer"}
            >
              Farther Reporter
            </a>
            which includes:
          </p>

          <div className={styles.modalLTextListItems}>
            <p className={styles.modalText3}>
              1. Spousal Linking in the request title
            </p>

            <p className={styles.modalText3}>2. Full name of their spouse</p>

            <p className={styles.modalText3}>
              3. Email address associated with their spouse's Farther account
            </p>
          </div>

          <p className={styles.modalText2}>
            Once this is complete, or if this situation does not apply for this
            client, please click Confirm to activate tax loss harvesting.
          </p>
        </>
      )}

      {props.isMutating ? (
        <div className={styles.modalButtonsDiv}>
          <Skeleton width={70} height={35} style={{ transform: "none" }} />

          <Skeleton
            width={70}
            height={35}
            style={{ transform: "none", marginLeft: "20px" }}
          />
        </div>
      ) : (
        <div className={styles.modalButtonsDiv}>
          <Button
            variant={"outlined"}
            buttonType={"secondary"}
            text={"Cancel"}
            onClick={props.closeModal}
          />

          <Button
            variant={"contained"}
            buttonType={"primary"}
            text={"Confirm"}
            onClick={props.onConfirm}
            style={{ marginLeft: "20px" }}
          />
        </div>
      )}
    </Modal>
  );
};

export default EnableTaxLossHarvestingModal;
