import React from "react";

import CircleIcon from "@mui/icons-material/Circle";

import { TaskPriority } from "@fartherfinance/frontend/api/Tasks/Types";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./TaskPriorityIcon.module.css";

interface CircleIconProps<T> {
  value: T;
}

const TaskPriorityIcon = ({
  value,
}: CircleIconProps<TaskPriority>): JSX.Element => {
  return (
    <CircleIcon
      className={toClassName(styles.circleIcon, {
        [styles.lowPrioColor]: value === "LOW",
        [styles.mediumPrioColor]: value === "MEDIUM",
        [styles.highPrioColor]: value === "HIGH",
      })}
    />
  );
};

export default TaskPriorityIcon;
