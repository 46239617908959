import React from "react";

import AdvisorContainer from "../../../AdvisorContainer";
import CreateUpdateCustomModel from "@src/multiCustodian/components/Advisor/Investments/ModelMarketplace/CreateUpdateCustomModel/CreateUpdateCustomModel";

const CreateCustomModel = (): JSX.Element => {
  return (
    <AdvisorContainer hideNavbar={true}>
      <CreateUpdateCustomModel isUpdate={false} />
    </AdvisorContainer>
  );
};

export default CreateCustomModel;
