import React, { useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import usePostInvitation from "@fartherfinance/frontend/api/Sharing/hooks/usePostInvitation";
import { ServiceProfessional } from "@fartherfinance/frontend/api/Sharing/Types";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import BaseLayout from "../../../Layouts/BaseLayout/BaseLayout";
import { getAccessLevel } from "../../utils/getAccessLevel";
import FlowFooter from "../shared/Footer/FlowFooter";
import FlowHero from "../shared/Hero/FlowHero";
import { SharingPath } from "@src/config/routing/RouterPaths";
import { relationshipOptions } from "@src/constants/relationship";
import {
  serviceProfessionalOptions,
  serviceProfessionalTypes,
} from "@src/constants/serviceProffesional";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

import FlowProgressBar from "./FlowProgressBar/FlowProgressBar";
import AddCollaboratorFlowSteps from "./FlowSteps";
import { actions } from "./reducer/actions";
import { useAddCollaboratorContext } from "./reducer/Context";

const SharingAddCollaboratorFlow: React.FC = () => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { state, dispatch } = useAddCollaboratorContext();
  const { buttonText, isNextButtonDisabled, pageIndex, data } = state;
  const {
    firstName,
    lastName,
    email,
    accessLevel,
    collaboratorType,
    serviceProfessionalType,
    resources,
    notify,
  } = data;

  const history = useHistory();
  const statusNotification = useStatusNotification();

  const auth = useRequestAuth();
  const postInvitation = usePostInvitation(auth);

  const finish = () => {
    dispatch({ type: actions.RESET_TO_DEFAULT_DATA, payload: undefined });

    history.push({
      ...history.location,
      pathname: `/Client/${clientId}/${SharingPath}`,
    });
  };

  const handleOnClickBack = () => {
    switch (pageIndex) {
      case 0:
        finish();
        break;
      default:
        dispatch({ type: actions.DECREMENT_PAGE_INDEX, payload: undefined });
        break;
    }
  };

  const handleOnClickNext = () => {
    switch (pageIndex) {
      case 2:
        addCollaborator();
        break;
      default:
        dispatch({ type: actions.INCREMENT_PAGE_INDEX, payload: undefined });
        break;
    }
  };

  const addCollaborator = async () => {
    if (accessLevel === undefined) {
      throw new Error("accessLevel is undefined");
    }

    let relationTypeId = undefined;

    if (collaboratorType !== undefined) {
      relationTypeId =
        relationshipOptions.findIndex((r) => r === collaboratorType) + 1;

      if (relationTypeId <= 0 || relationTypeId > 19) {
        throw new Error("collaboratorType not in relationshipOptions"); // 19 is the limit as there are only 19 options
      }
    }

    let serviceProType: ServiceProfessional | undefined = undefined;

    if (serviceProfessionalType !== undefined) {
      serviceProType =
        serviceProfessionalTypes[
          serviceProfessionalOptions.indexOf(serviceProfessionalType)
        ];
    }

    try {
      setIsLoading(true);

      const body = {
        invitorId: clientId,
        collaborator: {
          firstName,
          lastName,
          email,
        },
        accessLevel: getAccessLevel(accessLevel),
        relationTypeId,
        serviceProfessionalType: serviceProType,
        resources,
        createAuthorization: false,
        notify,
      };

      await postInvitation(body);

      statusNotification("Collaborator invited", "Success");
      setIsLoading(false);
      finish();
    } catch (_e) {
      statusNotification("Error inviting collaborator", "Error");
      setIsLoading(false);
    }
  };

  return (
    <BaseLayout showSideNav={false}>
      <FlowProgressBar />

      <FlowHero
        title={"Sharing"}
        headingLeft={"Sharing"}
        headingRight={"Add Collaborator"}
      />

      {isLoading && <LogoLoadingStill onTop noBackgroundColor />}

      {!isLoading && <AddCollaboratorFlowSteps />}

      <FlowFooter
        onClickBack={handleOnClickBack}
        onClickNext={handleOnClickNext}
        nextButtonText={buttonText}
        nextButtonIsDisabled={isNextButtonDisabled}
        showCancelButton={true}
        showBackButton={pageIndex !== 0}
        showNextButton={true}
      />
    </BaseLayout>
  );
};

export default SharingAddCollaboratorFlow;
