import React from "react";

import Button from "../../../MUI/Button/Button";
import { actions } from "../../../TaxBudgets/reducer/actions";
import { useCreateEditTaxBudgetContext } from "../../../TaxBudgets/reducer/Context";
import ActionRow from "@src/sharedComponents/ActionRow/ActionRow";

const YearReviewRow: React.FC = () => {
  const {
    state: {
      data: { year },
    },
    dispatch,
  } = useCreateEditTaxBudgetContext();

  const handleOnClick = () =>
    dispatch({ type: actions.SET_PAGE_INDEX, payload: 0 });

  return (
    <ActionRow
      start="Tax Year"
      end={`${year || 0}`}
      action={
        <Button
          text="Edit"
          variant="text"
          buttonType="primary"
          onClick={handleOnClick}
        />
      }
    />
  );
};

export default YearReviewRow;
