import React from "react";

import { useSelector } from "react-redux";

import { TimeFrame } from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Types";

import { trackEvent } from "@src/multiCustodian/services/tracking";
import MultiToggle from "@src/sharedComponents/MultiToggle/MultiToggle";
import { State } from "@src/store";

import styles from "./TimeRangeSelector.module.css";

interface TimeFrameOption {
  label: TimeFrame;
}

const trackTimeFrame = (tf: TimeFrame, isAdvisor: boolean) => {
  if (tf === "Custom") {
    return; // Custom tracking is handled in src/multiCustodian/components/PerformanceGroups/Summary/components/CustomDateRangeSelector.tsx
  }

  switch (tf) {
    case "30D":
      if (isAdvisor) {
        trackEvent({ name: "Advisor Performance 30D Time Frame" });
      } else {
        trackEvent({ name: "Client Performance 30D Time Frame" });
      }
      break;
    case "3M":
      if (isAdvisor) {
        trackEvent({ name: "Advisor Performance 3M Time Frame" });
      } else {
        trackEvent({ name: "Client Performance 3M Time Frame" });
      }
      break;
    case "YTD":
      if (isAdvisor) {
        trackEvent({ name: "Advisor Performance YTD Time Frame" });
      } else {
        trackEvent({ name: "Client Performance YTD Time Frame" });
      }
      break;
    case "ALL":
      if (isAdvisor) {
        trackEvent({ name: "Advisor Performance ALL Time Frame" });
      } else {
        trackEvent({ name: "Client Performance ALL Time Frame" });
      }
      break;
    default:
      break;
  }
};

interface Props {
  timeRange: TimeFrame;
  timeFrameOptions: TimeFrame[];
  setTimeRange: (timeRange: TimeFrame) => void;
  isDisabled: boolean;
}

export default function TimeRangeSelector({
  timeRange,
  timeFrameOptions,
  setTimeRange,
  isDisabled,
}: Props) {
  const isAdvisor = useSelector(
    (state: State) => state.main_Reducer.user.isAdvisor
  );

  const handleOnChange = (tfo: TimeFrameOption) => {
    if (isDisabled === false) {
      setTimeRange(tfo.label);
      trackTimeFrame(tfo.label, isAdvisor ?? false);
    }
  };

  return (
    <div className={styles.dateRangeFilterContainer}>
      <MultiToggle
        items={timeFrameOptions.map((tf) => ({ label: tf }))}
        currentItem={{ label: timeRange }}
        onChange={handleOnChange}
      />
    </div>
  );
}
