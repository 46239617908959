import React, { PropsWithChildren } from "react";

import { ArrowBack } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import useThemeAssets from "@src/multiCustodian/theme/useThemeAssets";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";
import Text from "@src/sharedComponents/Typography/Text";

import * as styles from "./StytchLogin.module.css";

interface Props {
  onBack?: () => void;
  subtitle?: string;
  title?: string;
  widget?: JSX.Element;
}

const Layout: React.FC<PropsWithChildren<Props>> = ({
  children,
  onBack,
  subtitle,
  title,
  widget,
}) => {
  const theme = useThemeAssets("Farther");

  return (
    <div className={styles.center}>
      <div className={styles.form}>
        <BorderBox className={styles.borderBox} variant="bold">
          {onBack !== undefined && (
            <IconButton className={styles.backButton} onClick={() => onBack()}>
              <ArrowBack className={styles.backIcon} />
            </IconButton>
          )}
          <div className={styles.innerContainer}>
            <div className={toClassName(styles.logoContainer, styles.gap)}>
              <img className={styles.logo} src={theme.data.logoSymbol} />
            </div>
            <div>
              {title !== undefined && (
                <Text
                  className={toClassName(styles.title, styles.gap)}
                  variant="title1"
                >
                  {title}
                </Text>
              )}
              {subtitle !== undefined && (
                <Text className={styles.gap} variant="body">
                  {subtitle}
                </Text>
              )}
              {widget !== undefined && (
                <div className={styles.gap}>{widget}</div>
              )}
            </div>
            <div>{children}</div>
          </div>
        </BorderBox>
      </div>
    </div>
  );
};

export default Layout;
