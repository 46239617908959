import React, { useEffect, useState } from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, Stack, Typography } from "@mui/material";
import { add, formatISO, sub } from "date-fns";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";

import { TASK_TITLE_MAX_LENGTH } from "@fartherfinance/frontend/api/Tasks/constants/common";
import useCreateTask from "@fartherfinance/frontend/api/Tasks/hooks/useCreateTask";
import type { PostTaskRequest } from "@fartherfinance/frontend/api/Tasks/requests/postTask";
import {
  ManualTaskType,
  TaskPriority,
  TaskStatus,
} from "@fartherfinance/frontend/api/Tasks/Types";
import {
  AdvisorId,
  ClientId,
  Custodian,
} from "@fartherfinance/frontend/api/Types";

import Tasks from "../../../Tasks";
import {
  ATTACHMENTS_LIMIT_COUNT,
  custodianOptions,
  TASK_TITLE_MAX_LENGTH_ERROR_MSG,
  TaskAssigneeAutocompleteOption,
} from "../../common";
import { mapTaskTypeToLabel } from "../../Components/TasksTable/TasksTable.utils";
import {
  useAdvisorsOptions,
  useAssigneesOptions,
  useClientsOptions,
  useTaskTypeOptions,
} from "../../hooks";
import { isDateValid } from "../../utils";
import InputHelperText from "../Components/InputHelperText";
import TaskDrawerHeader from "../Components/TaskDrawerHeader";
import Drawer from "@src/multiCustodian/components/Drawer/Drawer";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useFilesManagement from "@src/multiCustodian/hooks/useFilesManagement";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { EnhancedAutocompleteOption } from "@src/multiCustodian/pages/Advisor/common";
import { trackEvent } from "@src/multiCustodian/services/tracking";
import Attachment from "@src/sharedComponents/Attachment/Attachment";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormDateField from "@src/sharedComponents/Forms/FormDateField";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import FormSubHeader from "@src/sharedComponents/Forms/FormSubHeader";
import FormTextareaField from "@src/sharedComponents/Forms/FormTextareaField";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import IconButton from "@src/sharedComponents/IconButton/IconButton";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import Menu, { MenuOption } from "@src/sharedComponents/Menu/Menu";
import Upload from "@src/sharedComponents/Upload/Upload";
import { extractAxiosErrorMessage } from "@src/utils/axios";

import styles from "./CreateTask.module.css";

const transformFormDataToPayload = (
  formData: FormData
): PostTaskRequest["payload"] => {
  return {
    assigneeId: {
      type: formData.assignee.meta.type,
      id: formData.assignee.value,
    },
    advisorId: formData.advisor.value,
    clientId: formData.client?.value,
    taskPriority: formData.taskPriority,
    taskStatus: formData.taskStatus,
    taskType: formData.taskType.value,
    title: formData.title,
    description: formData.description,
    dueDate: formData.dueDate
      ? formatISO(formData.dueDate, { representation: "date" })
      : undefined,
    custodian: formData.custodian?.value,
  };
};

interface FormData {
  taskStatus: TaskStatus;
  taskPriority: TaskPriority;
  taskType: EnhancedAutocompleteOption<ManualTaskType>;
  assignee: TaskAssigneeAutocompleteOption;
  client: EnhancedAutocompleteOption<ClientId> | null;
  advisor: EnhancedAutocompleteOption<AdvisorId>;
  title: string;
  description: string;
  dueDate: Date | null;
  custodian: EnhancedAutocompleteOption<Custodian> | null;
}

export default function CreateTask(): JSX.Element {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [isMutating, setIsMutating] = useState(false);
  const [isHelperTextVisible, setIsHelperTextVisible] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const auth = useAdvisorRequestAuth();
  const clientsOptions = useClientsOptions();
  const advisorsOptions = useAdvisorsOptions();
  const assigneesOptions = useAssigneesOptions();
  const callCreateTask = useCreateTask(auth);
  const statusNotification = useStatusNotification();
  const { files, setFiles, removeFile } = useFilesManagement({
    countLimit: ATTACHMENTS_LIMIT_COUNT,
  });

  const form = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      taskStatus: "NOT_STARTED",
      taskPriority: "MEDIUM",
    },
  });

  const formTaskType = form.watch("taskType");
  const formTaskStatus = form.watch("taskStatus");
  const formTaskDrawer = {
    taskType: formTaskType?.value,
    taskStatus: formTaskStatus,
    isSystemGenerated: false,
  };
  const taskTypeOptions = useTaskTypeOptions(formTaskStatus);
  const taskTypeFormOptions = taskTypeOptions.map((taskType) => ({
    label: mapTaskTypeToLabel(taskType),
    value: taskType,
  }));

  const isAttachmentsSectionVisible = files.length > 0;
  const minDueDate = sub(new Date(), { years: 2 });
  const maxDueDate = add(new Date(), { years: 2 });

  const handleNavigateToTasksList = (): void => {
    history.push({
      pathname: "/Advisor/Tasks",
      search: location.search,
    });
  };

  const handleClearAutocompleteField = (field: keyof FormData): void => {
    form.setValue(field, null);
  };

  const handleFormSubmit = (): void => {
    form.handleSubmit(async (formData) => {
      try {
        setIsMutating(true);
        await callCreateTask({
          payload: transformFormDataToPayload(formData),
          ...(files.length && { attachments: files }),
        });
        setIsMutating(false);

        trackEvent({
          name: "Advisor Tasks Create",
          properties: {
            type: formData.taskType.value,
            priority: formData.taskPriority,
            status: formData.taskStatus,
          },
        });

        handleNavigateToTasksList();
      } catch (error) {
        console.log(error);
        setIsMutating(false);
        statusNotification(
          extractAxiosErrorMessage(error, "Failed to create task."),
          "Error"
        );
      }
    })();
  };

  const handlePersistFiles = (files: FileList | null): void => {
    if (files && files.length) {
      setFiles(files);
    }
  };

  const handleCreateMenuOptions = (fileName: string): MenuOption[] => {
    return [
      {
        label: "Delete File",
        className: styles.textWarning,
        onClick: () => removeFile(fileName),
      },
    ];
  };

  useEffect(() => {
    // NOTE: with the inline version of watch we were getting performance issues
    const watchSubscription = form.watch((values, { name, type }) => {
      if (type === "change" && name === "description") {
        setIsHelperTextVisible((values.description ?? "").length > 2);
      }
    });

    return () => watchSubscription.unsubscribe();
  }, [form]);

  return (
    <Tasks>
      <FormProvider {...form}>
        <Drawer
          isDrawerOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          SlideProps={{ onExited: handleNavigateToTasksList }}
          header={<TaskDrawerHeader task={formTaskDrawer} />}
          footer={
            <Stack
              sx={{ width: "100%" }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Upload
                multiple
                onChange={handlePersistFiles}
                renderChild={(onClick) => (
                  <IconButton
                    iconClassName={styles.attachmentIcon}
                    onClick={onClick}
                    IconComponent={AttachFileIcon}
                  />
                )}
              />

              <Button
                variant="contained"
                buttonType="primary"
                text="Create Task"
                disabled={isMutating}
                onClick={handleFormSubmit}
              />
            </Stack>
          }
        >
          <FormTextField
            name="title"
            label="Title"
            required="Must not be empty"
            rules={{
              validate: {
                max32: (value) =>
                  value.length > TASK_TITLE_MAX_LENGTH
                    ? TASK_TITLE_MAX_LENGTH_ERROR_MSG
                    : true,
              },
            }}
          />

          <FlexWrapper>
            <FormDropdownField<EnhancedAutocompleteOption>
              name="taskType"
              label="Task Type"
              values={taskTypeFormOptions}
              required="Please select a task type"
              getOptionKey={(option) => option.value}
              onClear={() => handleClearAutocompleteField("taskType")}
            />
            <FormDropdownField<TaskAssigneeAutocompleteOption>
              name="assignee"
              label="Assignee"
              values={assigneesOptions}
              required="Please select an assignee"
              getOptionKey={(option) => option.value}
              onClear={() => handleClearAutocompleteField("assignee")}
            />
          </FlexWrapper>

          <Spacer verticalSpacing="10px" />

          <FlexWrapper>
            <FormDropdownField<EnhancedAutocompleteOption>
              name="client"
              label="Client (Optional)"
              values={clientsOptions}
              getOptionKey={(option) => option.value}
              onClear={() => handleClearAutocompleteField("client")}
            />
            <FormDropdownField<EnhancedAutocompleteOption>
              name="advisor"
              label="Advisor"
              required="Please select an advisor"
              values={advisorsOptions}
              getOptionKey={(option) => option.value}
              onClear={() => handleClearAutocompleteField("advisor")}
            />
          </FlexWrapper>

          <Spacer verticalSpacing="10px" />

          <FlexWrapper>
            <FormDateField
              name="dueDate"
              label="Due Date"
              placeholder=""
              helperText="MM/DD/YYYY"
              minDate={minDueDate}
              maxDate={maxDueDate}
              required="Please select a due date"
              rules={{
                validate: { isDateValid },
              }}
            />
            <FormDropdownField<EnhancedAutocompleteOption>
              name="custodian"
              label="Custodian (Optional)"
              values={custodianOptions}
              getOptionKey={(option) => option.value}
              onClear={() => handleClearAutocompleteField("custodian")}
            />
          </FlexWrapper>

          <Box sx={{ marginTop: "40px" }}>
            <FormTextareaField
              name="description"
              label="Task Description (Optional)"
              placeholder="Describe the task and provide additional details..."
              minRows={7}
            />
            <Spacer verticalSpacing="4px" />
            <InputHelperText isVisible={isHelperTextVisible} />
          </Box>

          {isAttachmentsSectionVisible && (
            <Stack className={styles.attachments} spacing={1}>
              <FormSubHeader>Attachments</FormSubHeader>
              {files.map((file) => (
                <Stack
                  key={file.name}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Attachment
                    url={URL.createObjectURL(file)}
                    name={file.name}
                  />
                  <Menu options={handleCreateMenuOptions(file.name)}>
                    {({ handleOpenMenu }) => (
                      <IconButton
                        iconClassName={styles.menuIcon}
                        onClick={handleOpenMenu}
                        IconComponent={MoreHorizIcon}
                      />
                    )}
                  </Menu>
                </Stack>
              ))}
              <Typography className={styles.attachmentsCounter}>
                {files.length}/{ATTACHMENTS_LIMIT_COUNT}
              </Typography>
            </Stack>
          )}

          {isMutating && (
            <div className={styles.loading}>
              <LogoLoadingStill />
            </div>
          )}
        </Drawer>
      </FormProvider>
    </Tasks>
  );
}
