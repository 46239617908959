import { add, isAfter } from "date-fns";
import { isNil } from "lodash";

const isOver18 = (date: Date | null | undefined): string | true => {
  const years18Ago = add(new Date(), { years: -18 });

  return !isNil(date) && isAfter(date, years18Ago)
    ? "Need to be 18 years or older."
    : true;
};

export default isOver18;
