import React, { useState } from "react";

import isElement from "lodash/isElement";
import { useHistory } from "react-router-dom";

import useGetFeeds from "@fartherfinance/frontend/api/Notifications/hooks/useGetFeeds";
import useGetFeedsSummary from "@fartherfinance/frontend/api/Notifications/hooks/useGetFeedsSummary";
import useUpdateFeed from "@fartherfinance/frontend/api/Notifications/hooks/useUpdateFeed";
import useUpdateFeeds from "@fartherfinance/frontend/api/Notifications/hooks/useUpdateFeeds";
import { GetFeedsQueryParams } from "@fartherfinance/frontend/api/Notifications/requests/getFeeds";
import {
  Feed,
  FeedStatus,
} from "@fartherfinance/frontend/api/Notifications/Types";
import { AdvisorId, FeedId } from "@fartherfinance/frontend/api/Types";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { trackEvent } from "@src/multiCustodian/services/tracking";
import NotificationBell from "@src/sharedComponents/Notifications/NotificationBell/NotificationBell";
import NotificationsArea from "@src/sharedComponents/Notifications/NotificationsArea/NotificationsArea";

import { resolveRedirectionUrl } from "./utils/resolveRedirectionUrl";

interface FeedNotificationsProps {
  personId: AdvisorId;
}

const queryParams: GetFeedsQueryParams = {
  filterByStatus: [FeedStatus.Values.UNREAD, FeedStatus.Values.READ],
  pageSize: 10,
  page: 1,
};

export default function FeedNotifications({
  personId,
}: FeedNotificationsProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = isElement(anchorEl);

  const auth = useAdvisorRequestAuth();
  const statusNotification = useStatusNotification();
  const history = useHistory();

  const {
    data: feedsData,
    isLoading,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
  } = useGetFeeds(personId, auth, queryParams);
  const { data: feedsSummary } = useGetFeedsSummary(personId, auth);
  const callUpdateFeed = useUpdateFeed(auth);
  const callUpdateFeeds = useUpdateFeeds(auth);

  const feeds = feedsData?.pages.flatMap((page) => page?.values ?? []) ?? [];
  const isFeedsListEmpty = feeds.length === 0;
  const unseenCount =
    !isFeedsListEmpty && feedsSummary ? parseInt(feedsSummary.unreadCount) : 0;

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  const handleMarkAllNotificationsAsRead = async (): Promise<void> => {
    try {
      await callUpdateFeeds({
        personId,
        request: {
          updateStatusFrom: FeedStatus.Values.UNREAD,
          updateStatusTo: FeedStatus.Values.READ,
        },
      });
    } catch (_error) {
      statusNotification(
        "Failed to mark all feed notification as read.",
        "Error"
      );
    }
  };

  const handleArchiveNotification = async (feedId: FeedId): Promise<void> => {
    try {
      await callUpdateFeed({
        feedId,
        personId,
        request: { status: FeedStatus.Values.ARCHIVED },
      });
    } catch (_error) {
      statusNotification("Failed to archive feed notification.", "Error");
    }
  };

  const handleToggleNotificationStatus = async (
    feedId: FeedId,
    newStatus: FeedStatus
  ): Promise<void> => {
    try {
      await callUpdateFeed({
        feedId,
        personId,
        request: { status: newStatus },
      });
    } catch (_error) {
      statusNotification("Failed to update feed notification.", "Error");
    }
  };

  const handleClickNotification = async (feed: Feed): Promise<void> => {
    const redirection = resolveRedirectionUrl(feed);

    if (redirection) {
      handleToggleNotificationStatus(feed.feedId, FeedStatus.Values.READ);
      if (redirection.isExternal) {
        window.open(redirection.url, "_blank");
      } else {
        history.push(redirection.url);
      }
    }
  };

  const onTrackEvent = () => {
    if (!isMenuOpen) {
      trackEvent({ name: "Advisor Open Notification-Menu" });
    }
  };

  return (
    <div onClick={onTrackEvent}>
      <NotificationBell
        unseenCount={unseenCount}
        isFocused={isMenuOpen}
        onClick={handleOpenMenu}
      />

      <NotificationsArea
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        feeds={feeds}
        isEmpty={isFeedsListEmpty}
        isLoading={isLoading}
        isLoadingMore={isFetchingNextPage}
        hasMore={Boolean(hasNextPage)}
        // NOTE: react-query is very specific about not requesting next page while there is an active refetch
        // as in case of infinite queries this might procude duplicate data
        onLoadMore={() => !isFetching && fetchNextPage()}
        onMarkAllAsRead={handleMarkAllNotificationsAsRead}
        onArchive={handleArchiveNotification}
        onNotificationStatusToggle={handleToggleNotificationStatus}
        onNotificationClick={handleClickNotification}
      />
    </div>
  );
}
