import React, { useCallback, useMemo, useState } from "react";

import { identity, orderBy } from "lodash";
import { useHistory, useLocation } from "react-router-dom";

import { UNKNOWN_BALANCE } from "@fartherfinance/frontend/api/Accounts/utilities/accountUtil";
import { SMAAsset } from "@fartherfinance/frontend/api/AltAssetsService/requests/Types";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import FullDataTable from "@src/sharedComponents/Table/FullDataTable";
import { Cell, Row } from "@src/sharedComponents/Table/Types";

import ColGroup from "./ColGroup";
import Header from "./Header";

import styles from "./SMATable.module.css";

export const rowsPerPage = 10;

export const tableKeys = [
  "Investment Manager",
  "Minimum Investment",
  "Custodian",
  "Strategy",
] as const;

export type TableKey = typeof tableKeys[number];

interface Props {
  smaOptions: SMAAsset[];
}

const SMATable = (props: Props): JSX.Element => {
  const { search: urlQueryString } = useLocation();
  const paramsObj = useMemo(
    () => new URLSearchParams(urlQueryString),
    [urlQueryString]
  );

  const updateUrlQueryString = (newSearchString: string) => {
    const location = history.location;
    const params = new URLSearchParams(location.search);

    params.set("search", newSearchString);
    if (newSearchString === "") {
      params.delete("search");
    }

    history.replace({ ...location, search: params.toString() });
  };

  const history = useHistory();

  const [tableSearch, setTableSearch] = useState<string>(
    paramsObj.get("search") ?? ""
  );

  const openSMADetails = useCallback(
    (SMAId: string) => {
      const location = history.location;

      history.push({
        ...location,
        pathname: `/Advisor/Investments/SMA/${SMAId}`,
      });
    },
    [history]
  );

  const filtered: SMAAsset[] = useMemo(() => {
    return props.smaOptions.filter((sma) =>
      sma.firmName.toLowerCase().includes(tableSearch.toLowerCase())
    );
  }, [props.smaOptions, tableSearch]);

  const smaOptions: Row<TableKey, Cell>[] = useMemo(() => {
    return filtered.map((sma) => {
      const custodian = orderBy(sma.custodianAvailability, identity, [
        "asc",
      ]).join(", ");

      return {
        key: sma.assetId,
        "Investment Manager": { value: sma.firmName },
        "Minimum Investment": {
          value: (
            <div className={styles.textSubtle}>
              {sma.minimumInvestment ?? UNKNOWN_BALANCE}
            </div>
          ),
          fullValue: sma.minimumInvestment ?? UNKNOWN_BALANCE,
        },
        Custodian: {
          value: <div className={styles.textSubtle}>{custodian}</div>,
          fullValue: custodian,
        },
        Strategy: {
          value: (
            <div className={toClassName(styles.textSubtle, styles.ellipsis)}>
              <span>{sma.featuredStrategy.join(", ")}</span>
            </div>
          ),
          fullValue: sma.featuredStrategy.join(", "),
        },
        onClick: () => openSMADetails(sma.assetId),
      };
    });
  }, [openSMADetails, filtered]);

  return (
    <>
      <Header
        tableSearch={tableSearch}
        setTableSearch={(searchString: string) => {
          setTableSearch(searchString);
          updateUrlQueryString(searchString);
        }}
      />

      <FullDataTable
        columns={tableKeys}
        rows={smaOptions}
        defaultRowsPerPage={rowsPerPage}
        defaultSortColumn={["Investment Manager", "asc"]}
        disableColumnSorting={smaOptions.length > 0 ? undefined : tableKeys}
        emptyCell={"Loading"} // This is the highest cell we will have
      >
        <ColGroup />
      </FullDataTable>
    </>
  );
};

export default SMATable;
