import React from "react";

import Portal from "@src/multiCustodian/components/Portal/Portal";
import DeletableChip from "@src/sharedComponents/DeletableChip/DeletableChip";

interface Props {
  portalMountElementId: string;
  label: string;
  onPress: () => void;
  onClear: () => void;
  className?: string;
}

const PortaledChip = (props: Props) => {
  return (
    <Portal targetNodeId={props.portalMountElementId}>
      <DeletableChip
        className={props.className}
        label={props.label}
        onPress={props.onPress}
        onClear={props.onClear}
      />
    </Portal>
  );
};

export default PortaledChip;
