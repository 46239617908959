import React from "react";

import { Stack, Typography } from "@mui/material";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./DocumentsExplorerMessage.module.css";

interface DocumentsExplorerMessageProps {
  title: string;
  subtitle: string;
  action?: {
    label: string;
    icon: React.ReactNode;
    onClick: () => void;
  };
}

const DocumentsExplorerMessage: React.FC<DocumentsExplorerMessageProps> = ({
  title,
  subtitle,
  action,
}) => {
  return (
    <Stack className={styles.container} alignItems="center" gap="8px">
      <Typography className={toClassName(styles.messageText, styles.title)}>
        {title}
      </Typography>
      <Typography className={toClassName(styles.messageText, styles.subtitle)}>
        {subtitle}
      </Typography>

      {action && (
        <Button
          sx={{ marginTop: "16px" }}
          variant="contained"
          buttonType="primary"
          text={action.label}
          endIcon={action.icon}
          onClick={action.onClick}
        />
      )}
    </Stack>
  );
};

export default DocumentsExplorerMessage;
