import React, { useState } from "react";

import { useSelector } from "react-redux";

import useClientDashboard from "@fartherfinance/frontend/api/Dashboard/hooks/useClientDashboard";

import { State as RootState } from "../../../../store";
import Button from "../../MUI/Button/Button";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

import "./add_advisor.css";
import "./advisors.css";
import "./ClientsAdvisor.css";

const unfocus: React.CSSProperties = {
  color: "var(--primary_text_color_1)",
};

const focus: React.CSSProperties = {
  color: "var(--selected_text_color_1)",
};

export default function ClientsAdvisor(): JSX.Element {
  const [subSection, setSubSection] = useState<"bio" | "loc" | "edu">("bio");
  const { clientId } = useSelector((state: RootState) => ({
    clientId: state.main_Reducer.user.id_user,
  }));

  const auth = useRequestAuth();

  const dashboard = useClientDashboard(clientId, auth);

  if (dashboard.isLoading) {
    return <LogoLoadingStill />;
  }

  if (dashboard.hasError) {
    return <div>Error loading</div>;
  }

  const alma_maters = dashboard.data.advisor.almaMaters
    ? (JSON.parse(dashboard.data.advisor.almaMaters) as Record<string, string>)
    : null;

  const alma_maters_keys = alma_maters ? Object.keys(alma_maters) : [];

  return (
    <React.Fragment>
      {
        <div className="Dashboard_Chosen_Advisor_page__container">
          <div className="Dashboard_Chosen_Advisor_page__centeringContainer">
            <div className="Dashboard_Chosen_Advisor_page__flex_row_space_between">
              <div className="Dashboard_Chosen_Advisor_page__flex_row">
                <img
                  src={
                    dashboard.isLoading
                      ? undefined
                      : dashboard.data.advisor.headshotPath ??
                        "https://fartheradvisorphotos.s3.amazonaws.com/tmp_logo.png"
                  }
                  className="Dashboard_Chosen_Advisor_page__img"
                />

                <div className="Dashboard_Chosen_Advisor_page__flex_col">
                  <p className="Dashboard_Chosen_Advisor_page__title">
                    {dashboard.data.advisor.name
                      ? `${dashboard.data.advisor.name.first} ${dashboard.data.advisor.name.last}`
                      : "N/A"}
                  </p>

                  <div className="Dashboard_Chosen_Advisor_page__flex_row_2">
                    <img
                      src={"/src/assets/svg/email.svg"}
                      className="Dashboard_Chosen_Advisor_page__email_icon_img"
                    />

                    <p className="Dashboard_Chosen_Advisor_page__email_text">
                      {dashboard.data.advisor.email ?? "N/A"}
                    </p>
                  </div>

                  <div className="Dashboard_Chosen_Advisor_page__flex_row_2">
                    <p className="Dashboard_Chosen_Advisor_page__linkedin_text_icon">
                      in
                    </p>

                    <a
                      className="Dashboard_Chosen_Advisor_page__linkedin_2"
                      target="_blank"
                      rel="noreferrer"
                      href={
                        dashboard.data.advisor.linkedinUrl ??
                        "https://www.farther.com"
                      }
                    >
                      Linkedin
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="Dashboard_Add_Advisor_page__text_container">
              <React.Fragment>
                <div className="Dashboard_Add_Advisor_page__flex">
                  <div className="Dashboard_Chosen_Advisor_page__flex_row_3">
                    <img
                      src={"/src/assets/svg/user.svg"}
                      className="Dashboard_Chosen_Advisor_page__icon_img"
                    />

                    <p
                      className="Dashboard_Chosen_Advisor_page__desc_header"
                      style={subSection === "bio" ? focus : unfocus}
                      onClick={() => setSubSection("bio")}
                    >
                      Bio
                    </p>
                  </div>

                  <div className="Dashboard_Chosen_Advisor_page__flex_row_3">
                    <img
                      src={"/src/assets/svg/poi.svg"}
                      className="Dashboard_Chosen_Advisor_page__icon_img"
                    />

                    <p
                      className="Dashboard_Chosen_Advisor_page__desc_header"
                      style={subSection === "loc" ? focus : unfocus}
                      onClick={() => setSubSection("loc")}
                    >
                      Location
                    </p>
                  </div>

                  <div className="Dashboard_Chosen_Advisor_page__flex_row_3">
                    <img
                      src={"/src/assets/svg/grad_hat.svg"}
                      className="Dashboard_Chosen_Advisor_page__icon_img"
                    />

                    <p
                      className="Dashboard_Chosen_Advisor_page__desc_header"
                      style={subSection === "edu" ? focus : unfocus}
                      onClick={() => setSubSection("edu")}
                    >
                      Education
                    </p>
                  </div>
                </div>

                <div className="Dashboard_Add_Advisor_page__hr_line"></div>

                <div className="Dashboard_Add_Advisor_page__adv_content">
                  {subSection === "bio" ? (
                    <p className="Dashboard_Add_Advisor_page__text_2">
                      {dashboard.data.advisor.description ?? "N/A"}
                    </p>
                  ) : subSection === "loc" ? (
                    <div className="Dashboard_Add_Advisor_page__flex">
                      <div className="Dashboard_Add_Advisor_page__flex_col">
                        <p className="Dashboard_Add_Advisor_page__desc_header_2">
                          Location
                        </p>
                        <p className="Dashboard_Add_Advisor_page__text_2">
                          {dashboard.data.advisor.location ?? "N/A"}
                        </p>
                      </div>
                      <div className="Dashboard_Add_Advisor_page__flex_col">
                        <p className="Dashboard_Add_Advisor_page__desc_header_2">
                          Hometown
                        </p>
                        <p className="Dashboard_Add_Advisor_page__text_2">
                          {dashboard.data.advisor.hometown ?? "N/A"}
                        </p>
                      </div>
                    </div>
                  ) : subSection === "edu" ? (
                    <div className="Dashboard_Add_Advisor_page__flex">
                      <div className="Dashboard_Add_Advisor_page__flex_col">
                        <p className="Dashboard_Add_Advisor_page__desc_header_2">
                          Education
                        </p>
                        {alma_maters_keys.map((alma_mater) => {
                          return (
                            <p
                              key={alma_mater}
                              className="Dashboard_Add_Advisor_page__text_2"
                            >
                              {alma_mater}
                            </p>
                          );
                        })}
                      </div>
                      <div className="Dashboard_Add_Advisor_page__flex_col">
                        <p className="Dashboard_Add_Advisor_page__desc_header_2">
                          Concentration
                        </p>
                        {alma_maters_keys.map((alma_mater) => {
                          return (
                            <p
                              key={alma_mater}
                              className="Dashboard_Add_Advisor_page__text_2"
                            >
                              {alma_maters ? alma_maters[alma_mater] : "N/A"}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </React.Fragment>
            </div>

            <div className="Dashboard_Chosen_Advisor_page__footer_container">
              <div className="Dashboard_Chosen_Advisor_page__button_container">
                {dashboard.data.advisor.calendarUrl && (
                  <Button
                    variant={"contained"}
                    buttonType={"primary"}
                    text={"Book a session"}
                    onClick={() => undefined}
                    href={dashboard.data.advisor.calendarUrl}
                    target={"_blank"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
}
