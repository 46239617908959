import React, { useCallback } from "react";

import { useHistory, useLocation, useParams } from "react-router-dom";

import { TaxBudget } from "@fartherfinance/frontend/api/InvestmentModel/Types";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";
import BorderBoxHeader from "@src/sharedComponents/BorderBox/BorderBoxHeader";

import Meta from "./Meta";

import styles from "./Row.module.css";

interface Props {
  budget: TaxBudget;
}

const Row: React.FC<Props> = ({ budget }) => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const history = useHistory();

  const { pathname } = useLocation();

  const isAdvisor = pathname.startsWith("/Advisor");

  const handleOnClick = useCallback(() => {
    const budgetPath = isAdvisor
      ? `/Advisor/Clients/${clientId}/TaxBudgets/${budget.budgetId}/Usage`
      : `/Client/${clientId}/TaxBudgets/${budget.budgetId}/Usage`;

    history.push({
      ...history.location,
      pathname: budgetPath,
    });
  }, [budget.budgetId, clientId, history, isAdvisor]);

  return (
    <div className={styles.container}>
      <BorderBox>
        <BorderBoxHeader
          className={styles.headerContainer}
          start={<Meta budget={budget} />}
          end={
            <Button
              buttonType={"secondary"}
              onClick={handleOnClick}
              size="small"
              text="View"
              variant="outlined"
            />
          }
        />
      </BorderBox>
    </div>
  );
};

export default Row;
