import React from "react";

import { graphSettings } from "../../Client/Portfolio/ProjectionsGraph/utils/graphSettings";

const SvgLine = ({
  color,
  width,
  dashed,
}: {
  color: string;
  width: number;
  dashed: boolean;
}) => {
  //y1 and y2 should be equal to make a straight line.
  //The higher y is, the thinner the line.
  const y = 1;

  return (
    <svg
      width={width}
      height={graphSettings.strokeWidth}
      viewBox={`0 0 ${width} ${graphSettings.strokeWidth}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1={1} //left-hand starting point
        x2={width}
        y1={y}
        y2={y}
        stroke={color}
        strokeLinecap="round"
        strokeWidth={dashed ? graphSettings.dashedStrokeWidth : undefined}
        strokeDasharray={dashed ? graphSettings.strokeDasharray : undefined}
      />
    </svg>
  );
};

export default SvgLine;
