import React from "react";

import { TAX_CHARACTERISTICS_ACROSS_ACCOUNTS_TOOLTIP_TEXT } from "../../const";
import { Account, TradingGroupAccountLocal } from "../Types";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import Modal from "@src/sharedComponents/Modal/Modal";

import styles from "./modal.module.css";

interface Props {
  closeModal: () => void;
  onAdd: () => void;
  tradingGroupName: string;
  accountsInTradingGroupText: string;
  accountsInTradingGroup: Account[];
  accountToAdd: TradingGroupAccountLocal;
  isMutating: boolean;
}

const AddAccountModal = (props: Props): JSX.Element => {
  return (
    <Modal closeModal={props.closeModal} modalStyle={{ width: "520px" }}>
      {!props.isMutating && (
        <>
          <p className={styles.modalHeader}>
            {`Are you sure you want to add ${props.accountToAdd.displayName} to ${props.tradingGroupName}?`}
          </p>

          <p className={styles.modalText}>
            {TAX_CHARACTERISTICS_ACROSS_ACCOUNTS_TOOLTIP_TEXT}
          </p>

          <div className={styles.modalBody}>
            <div className={styles.container}>
              <div className={styles.listHeaderDiv}>
                <div className={styles.flexDiv}>
                  <p className={styles.headerText}>{props.tradingGroupName}</p>

                  <p className={styles.secondaryText}>
                    {props.accountsInTradingGroupText}
                  </p>
                </div>

                <div></div>
              </div>

              <div className={styles.optionsDiv2}>
                {props.accountsInTradingGroup.map((acct) => {
                  return (
                    <div key={acct.accountId} className={styles.optionDiv}>
                      <div className={styles.flexDiv}>
                        <p className={styles.text}>{acct.displayName}</p>
                        <p className={styles.secondaryText}>
                          {acct.custodianAccountNumber}
                        </p>
                      </div>

                      <p className={styles.text}></p>
                    </div>
                  );
                })}

                <div
                  key={props.accountToAdd.accountId}
                  className={styles.optionDiv}
                >
                  <div className={styles.flexDiv}>
                    <p className={styles.text}>
                      {props.accountToAdd.displayName}
                    </p>
                    <p className={styles.secondaryText}>
                      {props.accountToAdd.custodianAccountNumber}
                    </p>
                  </div>

                  <p className={styles.text}>New Account</p>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.modalButtonsDiv}>
            <Button
              variant={"outlined"}
              buttonType={"secondary"}
              text={"Cancel"}
              onClick={props.closeModal}
            />

            <Button
              variant={"contained"}
              buttonType={"primary"}
              text={"Add Account"}
              onClick={props.onAdd}
              style={{ marginLeft: "20px" }}
            />
          </div>
        </>
      )}

      {props.isMutating && <LogoLoadingStill onTop={true} />}
    </Modal>
  );
};

export default AddAccountModal;
