import { useMemo } from "react";

import useFartherEmployees from "@fartherfinance/frontend/api/Entity/hooks/useFartherEmployees";
import { AdvisorId } from "@fartherfinance/frontend/api/Types";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";

export default function useIsAdmin(advisorId: AdvisorId | null) {
  const auth = useAdvisorRequestAuth();
  const employees = useFartherEmployees("All", auth);

  return useMemo(() => {
    if (employees.isLoading || employees.hasError) {
      return employees;
    }

    if (!advisorId) {
      return {
        data: false,
        isLoading: false,
        hasError: false,
      };
    }

    const employee = employees.data.advisors.find(
      (e) => e.advisorId === advisorId
    );

    if (employee === undefined) {
      return {
        data: false,
        isLoading: false,
        hasError: false,
      };
    }

    const isAdmin = employee.roles.some(
      (r) => r === "Client Ops" || r === "Engineering" || r === "Farther Admin"
    );

    return {
      data: isAdmin,
      isLoading: false,
      hasError: false,
    };
  }, [employees, advisorId]);
}
