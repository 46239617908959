import React from "react";

import { Box, Fade } from "@mui/material";
import { useLocation } from "react-router-dom-v5-compat";

import AdvisorContainer from "@src/multiCustodian/pages/Advisor/AdvisorContainer";
import AdvisorContentContainer from "@src/multiCustodian/pages/Advisor/AdvisorContentContainer";
import { UserSearchBar, WidthLimiter } from "@src/yellowstone/components";
import { PAGINATION_OUTLET_NODE_ID } from "@src/yellowstone/modules/shared";

import { useStyles } from "./Layout.styles";

import styles from "./Layout.module.css";

const rootPaths = ["/Advisor/Client-Ops/Dashboard"];

export const Layout: React.FC = ({ children }) => {
  const { classes } = useStyles();
  const location = useLocation();

  // INFO: we do not want fade transition to kick in under child routes of root paths
  const key = rootPaths.find((rootPath) =>
    location.pathname !== rootPath
      ? location.pathname.includes(rootPath)
      : false
  )
    ? "const-key"
    : location.pathname;

  return (
    <div className={classes.root}>
      <AdvisorContainer>
        <AdvisorContentContainer
          heroClassName={styles.hero}
          heroTitle={"Client Ops"}
          heroEnd={<UserSearchBar />}
        >
          <Fade key={key} in>
            <WidthLimiter className={classes.container} component="main">
              {children}
            </WidthLimiter>
          </Fade>
        </AdvisorContentContainer>
      </AdvisorContainer>

      <Box id={PAGINATION_OUTLET_NODE_ID} className={classes.pagination} />
    </div>
  );
};
