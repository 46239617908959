import React, { useCallback, useMemo } from "react";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Chip from "@mui/material/Chip";
import { format, isAfter, isBefore, startOfDay } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useHistory, useLocation } from "react-router-dom";

import { GetTasksQueryParams } from "@fartherfinance/frontend/api/Tasks/requests/getTasks";
import { Task, TaskStatus } from "@fartherfinance/frontend/api/Tasks/Types";
import { TaskId } from "@fartherfinance/frontend/api/Types";
import { PaginationHeaders } from "@fartherfinance/frontend/api/utils/parsePaginationHeaders";

import { UNKNOWN_VALUE } from "../../../common";
import { TASKS_DEFAULT_PAGE } from "../../common";
import TaskStatusIcon from "../TaskStatusIcon/TaskStatusIcon";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import { getPersonName } from "@src/multiCustodian/pages/Advisor/utils";
import { mapToSortOrder } from "@src/multiCustodian/utils/sorting";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import Message from "@src/sharedComponents/Message/Message";
import PaginatedTable from "@src/sharedComponents/Table/PaginatedTable";
import { TableSortOrder } from "@src/sharedComponents/Table/Types";

import {
  createTableColumns,
  getAssigneeName,
  mapTaskStatusToLabel,
  mapTaskTypeToLabel,
} from "./TasksTable.utils";

import styles from "./TasksTable.module.css";

export type TaskTableColumns =
  | keyof Pick<
      Task,
      | "title"
      | "client"
      | "advisor"
      | "assignee"
      | "taskStatus"
      | "taskType"
      | "updatedTs"
      | "dueDate"
    >
  | "action";

interface TasksTableProps {
  tasks: Task[];
  isLoading: boolean;
  isErrored: boolean;
  pagination: PaginationHeaders | undefined;
  sort: GetTasksQueryParams["sort"];
  onQueryParamChange: (newQueryParams: GetTasksQueryParams) => void;
}

export default function TasksTable({
  tasks,
  isLoading,
  isErrored,
  pagination,
  sort,
  onQueryParamChange,
}: TasksTableProps): JSX.Element {
  const { enableNewTaskStatus } = useFlags();
  const location = useLocation();
  const history = useHistory();

  const handleOnPageChange = (page: number): void => {
    onQueryParamChange({ page });
  };

  const handleOnColumnSelect = (
    column: TaskTableColumns,
    tableSortOrder: TableSortOrder
  ): void => {
    if (
      column === "dueDate" ||
      column === "updatedTs" ||
      column === "taskStatus" ||
      column === "taskType"
    ) {
      onQueryParamChange({
        sort: `${mapToSortOrder(tableSortOrder)}${column}`,
        page: TASKS_DEFAULT_PAGE,
      });
    }
  };

  const handleNavigateToTaskDetails = useCallback(
    (taskId: TaskId): void => {
      history.push({
        pathname: `/Advisor/Tasks/${taskId}`,
        search: location.search,
      });
    },
    [history, location]
  );

  const handleDueDateLabelColor = useCallback((task: Task): boolean => {
    if (!task.dueDate) {
      return false;
    }

    if (task.taskStatus === TaskStatus.enum.COMPLETED && task.completedTs) {
      return isAfter(new Date(task.completedTs), task.dueDate);
    }

    return isBefore(task.dueDate, startOfDay(new Date()));
  }, []);

  const tableHeaders = useMemo(() => createTableColumns(sort), [sort]);

  const tableRows = useMemo(
    () =>
      tasks.map((task) => ({
        key: task.id,
        title: task.title,
        client: getPersonName(task.client),
        advisor: getPersonName(task.advisor),
        assignee: getAssigneeName(task.assignee),
        taskStatus: {
          value: (
            <Chip
              classes={{
                root: styles.chip,
                label: styles.chipLabel,
                icon: styles.chipIcon,
              }}
              variant="outlined"
              label={mapTaskStatusToLabel(task.taskStatus, enableNewTaskStatus)}
              icon={<TaskStatusIcon value={task.taskStatus} />}
            />
          ),
        },
        taskType: mapTaskTypeToLabel(task.taskType),
        dueDate: {
          value: task.dueDate ? (
            <span
              className={toClassName({
                [styles.overdueColor]: handleDueDateLabelColor(task),
              })}
            >
              {format(task.dueDate, "M/dd/yy")}
            </span>
          ) : (
            UNKNOWN_VALUE
          ),
        },
        updatedTs: format(new Date(task.updatedTs), "M/dd/yy"),
        action: {
          value: (
            <Button
              sx={{
                minWidth: "unset",
                padding: "8.25px 10px",
              }}
              style={{ display: "inline-block" }}
              variant="contained"
              buttonType="primary"
              text={<ArrowForwardIcon className={styles.arrowIcon} />}
              onClick={() => handleNavigateToTaskDetails(task.id)}
            />
          ),
          hideUnlessHovering: true,
        },
        onClick: () => handleNavigateToTaskDetails(task.id),
      })),
    [
      tasks,
      handleNavigateToTaskDetails,
      handleDueDateLabelColor,
      enableNewTaskStatus,
    ]
  );

  if (isErrored) {
    return <Message text="Something went wrong, please try again later" />;
  }

  if (tasks.length === 0 && !isLoading) {
    return <Message text="No results found" />;
  }

  return (
    <PaginatedTable
      isLoading={isLoading}
      columns={tableHeaders}
      rows={tableRows}
      pagination={pagination}
      onPageChange={handleOnPageChange}
      onColumnSelect={handleOnColumnSelect}
    >
      <colgroup>
        <col width="17.66%" />
        <col width="11.21%" />
        <col width="11.47%" />
        <col width="11.56%" />
        <col width="13.13%" />
        <col width="12.78%" />
        <col width="9.91%" />
        <col width="9.91%" />
        <col width="2.36%" />
      </colgroup>
    </PaginatedTable>
  );
}
