import React from "react";

import { UNKNOWN_BALANCE } from "@fartherfinance/frontend/api/Accounts/utilities/accountUtil";

import toUSD from "@src/utils/toUSD";

import styles from "../../../../../PerformanceGroups/Summary/BreakdownTable.module.css";
interface Props {
  startingBalance: number | null;
  marketGains: number | null;
  currentBalance: number | null;
}

const ManualBreakdownTable = (props: Props): JSX.Element => {
  return (
    <div className={styles.data_summary_table}>
      <div className={styles.data_summary_table_header}>
        <p>Balance Breakdown</p>
      </div>

      <div className={styles.data_summary_table_body}>
        <div className={styles.data_summary_table_item}>
          <p className={styles.tableTextItem}>Starting Balance</p>

          <p className={styles.tableTextItem}>
            {props.startingBalance !== null
              ? props.startingBalance.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
              : UNKNOWN_BALANCE}
          </p>
        </div>

        <div className={styles.data_summary_table_item}>
          <p className={styles.tableTextItem}>Market Gains</p>

          {props.marketGains !== null ? (
            <p className={styles.tableTextItem}>{toUSD(props.marketGains)}</p>
          ) : (
            <p className={styles.tableTextItem}>{UNKNOWN_BALANCE}</p>
          )}
        </div>

        <div className={styles.hr_line} />

        <div className={styles.data_summary_table_item}>
          <p className={styles.p_total}>Current Balance</p>

          {props.currentBalance !== null ? (
            <p className={styles.p_total_right}>
              {toUSD(props.currentBalance)}
            </p>
          ) : (
            <p className={styles.p_total_right}>{UNKNOWN_BALANCE}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManualBreakdownTable;
