import React, { useMemo } from "react";

import { orderBy } from "lodash";
import { useHistory, useLocation, useParams } from "react-router-dom";

import useGetManuallyTrackedAccounts from "@fartherfinance/frontend/api/Accounts/hooks/useGetManuallyTrackedAccounts";
import { AltAssetId, ClientId } from "@fartherfinance/frontend/api/Types";

import { Accordions } from "../Types";
import { AccountsPath } from "@src/config/routing/RouterPaths";
import Drawer, {
  largeDrawer,
} from "@src/multiCustodian/components/Drawer/Drawer";
import DropdownMenuSelectOne from "@src/multiCustodian/components/DropdownMenuSelectOne/DropdownMenuSelectOne";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import TabBar, { HrefTab } from "@src/sharedComponents/TabBar/TabBar";

import Header from "./Components/Header";

import styles from "./ManualAssetDrawer.module.css";

const tabs = ["Summary", "Activity", "Settings"] as const;
type Tab = typeof tabs[number];

interface Props {
  curTab: Tab;
}

const ManualAssetDrawer = (
  props: React.PropsWithChildren<Props>
): JSX.Element => {
  const { clientId, accountId } = useParams<{
    clientId: ClientId;
    accountId: AltAssetId;
  }>();
  const { state } = useLocation<
    { openAccordions?: Accordions[] } | undefined
  >();

  const history = useHistory();

  const auth = useRequestAuth();

  const manualAssets = useGetManuallyTrackedAccounts(clientId, auth);

  const manualAssetOptions = useMemo(() => {
    const withName = (manualAssets.data ?? []).map((ma) => ({
      ...ma,
      name: ma.assetName,
    }));

    return orderBy(withName, (acc) => acc.name.toLowerCase(), "asc");
  }, [manualAssets.data]);

  const curManualAsset = manualAssets.data
    ? manualAssets.data.find((ma) => accountId === ma.id)
    : undefined;

  const fullAccountsPath = `/Client/${clientId}/${AccountsPath}`;
  const manualAccountRoute = `${fullAccountsPath}/Manual/${accountId}`;

  return (
    <div className={styles.drawer_wrapper}>
      <Drawer
        container_style={largeDrawer}
        isDrawerOpen
        onClose={() => history.push({ state, pathname: fullAccountsPath })}
        transitionDuration={0}
        header={
          !manualAssets.isLoading && curManualAsset ? (
            <DropdownMenuSelectOne
              menu_width="220px"
              cur_item={{
                ...curManualAsset,
                name: curManualAsset.assetName,
              }}
              items={manualAssetOptions}
              changeMenuState={({ name: _name, ...manualAsset }) => {
                if (clientId) {
                  history.push({
                    state,
                    pathname: `${fullAccountsPath}/Manual/${manualAsset.id}/${props.curTab}`,
                  });
                }
              }}
            />
          ) : (
            <div />
          )
        }
        footer={null}
      >
        <div className={styles.container}>
          <Header manualAsset={curManualAsset ?? null} />

          <TabBar
            tabs={tabs.map(
              (t): HrefTab<Tab> => ({
                tabKey: t,
                tab: t,
                href: (loc) => ({
                  state: loc.state,
                  pathname: `${manualAccountRoute}/${t}`,
                }),
              })
            )}
            currentTabKey={props.curTab}
          />

          {props.children}
        </div>
      </Drawer>
    </div>
  );
};

export default ManualAssetDrawer;
