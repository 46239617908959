import React from "react";

import styles from "./Header.module.css";

const Header: React.FC = () => (
  <div className={styles.tableHeader}>
    <div className={styles.headerCol1}>Collaborators</div>
    <div className={styles.headerCol2}>Access</div>
    <div className={styles.headerCol3And4}>Status</div>
  </div>
);

export default Header;
