import React from "react";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ClearIcon from "@mui/icons-material/Clear";

import CategoryCell from "../ChipCell/ChipCell";
import { AnyCellItem, DrillInState, isAssetClassCellItem } from "../Types";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./DrillInChipsHiearchy.module.css";

interface Props {
  items?: AnyCellItem[];
  drillInState: DrillInState;
  setDrillInState: (obj: DrillInState) => void;
}

const DrillInChipsHiearchy = ({
  items,
  drillInState,
  setDrillInState,
}: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      <div className={styles.drillInItems}>
        {items === undefined ? (
          <Skeleton width={120} height={30} style={{ transform: "none" }} />
        ) : (
          items.map((item, idx) => {
            return (
              <div key={item.name} className={styles.cellWithPreChevronRight}>
                {idx > 0 && (
                  <ChevronRightIcon className={styles.chevronRightIcon} />
                )}

                <CategoryCell
                  item={item}
                  showBorder={false}
                  onClick={() => {
                    const alreadyAtBaseState =
                      drillInState.category === null &&
                      drillInState.class === null &&
                      item.level === "All";

                    const alreadyAtDrillInLevel1 =
                      drillInState.category !== null &&
                      drillInState.class === null &&
                      item.level === "Category";

                    const alreadyAtDrillInLevel2 =
                      drillInState.category !== null &&
                      drillInState.class !== null &&
                      item.level === "Asset Class";

                    if (
                      alreadyAtBaseState ||
                      alreadyAtDrillInLevel1 ||
                      alreadyAtDrillInLevel2
                    ) {
                      return; // don't cause re-render just to show same state
                    }

                    setDrillInState({
                      category:
                        item.level === "All" ? null : drillInState.category, // keep the same state for this - doesn't matter if "Category" or "Asset Class" level
                      class: isAssetClassCellItem(item)
                        ? item.name // stay at same "Asset Class" level
                        : null, // reset
                    });
                  }}
                />
              </div>
            );
          })
        )}
      </div>

      {drillInState.category !== null && (
        <div
          className={styles.clearChip}
          onClick={() =>
            setDrillInState({
              category: null,
              class: null,
            })
          }
        >
          <div className={styles.clearChipText}>Clear</div>

          <ClearIcon className={styles.clearChipIcon} />
        </div>
      )}
    </div>
  );
};

export default DrillInChipsHiearchy;
