import React from "react";

import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import useMFASettings from "@fartherfinance/frontend/api/Identity/hooks/useMFASettings";
import useSetMFASettings from "@fartherfinance/frontend/api/Identity/hooks/useSetMFASettings";
import { AdvisorId, ClientId } from "@fartherfinance/frontend/api/Types";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useApplicationMode from "@src/multiCustodian/hooks/useApplicationMode";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import TextInputReffed from "@src/sharedComponents/TextInput/TextInput";
import Text from "@src/sharedComponents/Typography/Text";
import { State } from "@src/store";

import { MFASettings } from "./Types";

import styles from "../SharedFormStyle.module.css";

const SecuritySettingsFetcher: React.FC = () => {
  const { clientId, advisorId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));

  const clientAuth = useRequestAuth();
  const advisorAuth = useAdvisorRequestAuth();

  const auth = clientAuth ?? advisorAuth;

  const personId = clientId ?? advisorId;

  const mfaSettings = useMFASettings(personId, auth);

  if (personId === null) {
    return <div>Error with person id</div>;
  }

  if (mfaSettings.isLoading) {
    return (
      <div className={styles.loading}>
        <LogoLoadingStill />
      </div>
    );
  }

  if (mfaSettings.hasError) {
    return <div>Error</div>;
  }

  return (
    <SecuritySettingsForm
      personId={personId}
      serverData={{
        mfaEnabled: mfaSettings.data?.enable2FA ?? null,
        phoneNumber: mfaSettings.data?.phoneNumber ?? null,
      }}
    />
  );
};

export default SecuritySettingsFetcher;

interface ProfileFormProps {
  personId: ClientId | AdvisorId;
  serverData: MFASettings;
}

function SecuritySettingsForm(props: ProfileFormProps): JSX.Element {
  return props.serverData.mfaEnabled ? (
    <TwoFAEnabled
      personId={props.personId}
      phoneNumber={props.serverData.phoneNumber}
    />
  ) : (
    <TwoFADisabled />
  );
}

interface TwoFAProps {
  personId: ClientId | AdvisorId;
  phoneNumber: string | null;
}

const TwoFAEnabled: React.FC<TwoFAProps> = ({ personId, phoneNumber }) => {
  const clientAuth = useRequestAuth();
  const advisorAuth = useAdvisorRequestAuth();

  const auth = clientAuth ?? advisorAuth;

  const setMFASettings = useSetMFASettings(auth);

  const notification = useStatusNotification();

  const handleOnClickTurnOff = async () => {
    await setMFASettings({
      personId: personId,
      body: { enable2FA: false },
    });

    notification("Two-Factor Authentication disabled", "Success", "Left");
  };

  return (
    <div className={styles.column}>
      <Text variant="title1">Turn Off Two-Factor Authentication</Text>

      <div className={styles.spacer} />

      <Text variant="body">
        If you’d like a quicker sign-on experience, you may turn off Two-Factor
        Authentication. This will allow you to login with your email; no code
        will be sent to your mobile device.
      </Text>

      <div className={styles.spacer} />

      <TextInputReffed value={phoneNumber} disabled label="Phone Number" />

      <div className={styles.spacer} />

      <Button
        text="Turn Off Two-Factor Authentication"
        variant="outlined"
        buttonType="primary"
        onClick={handleOnClickTurnOff}
      />
    </div>
  );
};

const TwoFADisabled: React.FC = () => {
  const history = useHistory();

  const { clientId } = useParams<{ clientId: ClientId }>();

  const { isAdvisor } = useSelector((state: State) => state.main_Reducer.user);
  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));

  const { isImpersonationMode } = useApplicationMode();

  const handleOnClickTurnOn = () => {
    const url =
      isImpersonationMode || !isAdvisor
        ? `/Client/${clientId}/EnableMFA`
        : `/Advisor/${advisorId!}/EnableMFA`;

    history.push(url);
  };

  return (
    <div className={styles.column}>
      <Text variant="title1">Turn On Two-Factor Authentication</Text>

      <div className={styles.spacer} />

      <Text variant="body">
        For additional security, turn on Two-Factor Authentication. A code will
        be sent to your mobile device for you to enter each time you log in.
      </Text>

      <div className={styles.spacer} />

      <Button
        text="Turn On Two-Factor Authentication"
        variant="outlined"
        buttonType="primary"
        onClick={handleOnClickTurnOn}
      />
    </div>
  );
};
