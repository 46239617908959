import { useCallback } from "react";

import { useSelector } from "react-redux";

import useClientPartner from "@fartherfinance/frontend/api/Dashboard/hooks/useClientPartner";
import { Partner } from "@fartherfinance/frontend/api/Dashboard/requests/getClientDashboard";

import useRequestAuth from "../hooks/useRequestAuth";
import { State } from "@src/store";

import cogentCopy from "./Cogent/cogentCopy";
import fartherCopy from "./Farther/fartherCopy";
import { Fragments } from "./Types";
import wealthPlanCopy from "./WealthPlan/wealthPlanCopy";

type FragmentsByPartner = Record<Partner, Fragments>;

const fragments: FragmentsByPartner = {
  Farther: fartherCopy,
  Cogent: cogentCopy,
  WealthPlan: wealthPlanCopy,
};

type Return = (s: keyof Fragments) => string;
type ReturnWithEmpty = (s: keyof Fragments) => string | null;

function useThemeFragment(): ReturnWithEmpty;
function useThemeFragment(defaultPartner: undefined): ReturnWithEmpty;
function useThemeFragment(defaultPartner: Partner): Return;

function useThemeFragment(defaultPartner?: Partner): ReturnWithEmpty {
  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));

  const auth = useRequestAuth();
  const partner = useClientPartner(clientId, auth);

  const t = useCallback(
    (s: keyof Fragments): string | null => {
      if (partner.isLoading || partner.hasError) {
        if (defaultPartner !== undefined) {
          return fragments[defaultPartner][s];
        }

        return null;
      }

      return fragments[partner.data][s];
    },
    [defaultPartner, partner]
  );

  return t;
}

export default useThemeFragment;
