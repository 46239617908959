import { ProjectionsGraphData } from "../Types";

const projectionsToInclude = ["results10Pct", "results50Pct", "results90Pct"];

/*  Flatten Projections into Array of ProjectionsGraphData. And Filter on keys (10%, 50%, and 90%).
  Projections example: results10Pct: [0, 1036611.4858], [1, 928253.4601766202], [2, 913669.7500586774]]...
  ProjectionsGraphData example: [{ key: results10Pct, yearIndex: 0, balance: 1036611.4858 }]
 */

const formatToProjectionsGraphData = (
  input: Record<string, [number, number][]> | undefined,
  maxNumberOfYears: number | null
): ProjectionsGraphData[] => {
  if (input === undefined) {
    return [];
  }

  const entries = Object.entries(input);

  const filteredYears = entries.filter(([year]) =>
    projectionsToInclude.includes(year)
  );

  return filteredYears.reduce(
    (acc: ProjectionsGraphData[], [projectionKey, projectionKeyArray]) => {
      const filtered =
        maxNumberOfYears === null
          ? projectionKeyArray
          : projectionKeyArray.filter(
              ([yearIndex]) => yearIndex <= maxNumberOfYears
            );

      const graphData: ProjectionsGraphData[] = filtered.map(
        ([yearIndex, balance]) => ({
          key: projectionKey,
          yearIndex,
          balance,
        })
      );

      return [...acc, ...graphData];
    },
    []
  );
};

export { formatToProjectionsGraphData };
