import React from "react";

import NavItemDisabled from "./NavItemDisabled";
import NavItemEnabled from "./NavItemEnabled";
import NavItemToOutsideLink from "./NavItemToOutsideLink";
import { NavItemDetails } from "./Types";

const NavItem: React.FC<NavItemDetails> = ({
  iconName,
  text,
  path,
  isDisabled,
  isHidden,
  toOutsideLink,
  mixpanelEvent,
  notifications,
}) => {
  if (toOutsideLink) {
    return (
      <NavItemToOutsideLink
        iconName={iconName}
        text={text}
        path={path}
        isHidden={isHidden}
        mixpanelEvent={mixpanelEvent}
      />
    );
  }

  if (isDisabled) {
    return (
      <NavItemDisabled iconName={iconName} text={text} isHidden={isHidden} />
    );
  }

  return (
    <NavItemEnabled
      iconName={iconName}
      text={text}
      path={path}
      isHidden={isHidden}
      mixpanelEvent={mixpanelEvent}
      notifications={notifications}
    />
  );
};

export default NavItem;
