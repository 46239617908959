import * as Sentry from "@sentry/react";
import axios, { InternalAxiosRequestConfig } from "axios";

import logUserOut from "../utils/logUserOut";
import normalizeURL from "@src/utils/normalizeURL";

import { captureException } from "./tracking";

export class FartherHTTPError extends Error {
  public method: string;
  public url: string;
  public status: string;

  constructor(method: string, url: string, status: number | "[no status]") {
    let message = `${method} ${status} ${url}`;
    if (
      method !== "[no method]" &&
      status !== "[no status]" &&
      url !== "[no url]"
    ) {
      message = `${method} ${status} ${url}`;
    } else if (method !== "[no method]" && url !== "[no url]") {
      message = `${method} ${url}`;
    }

    super(message);

    // Enhance the name and message to be a bit more specific
    if (method !== "[no method]" && status !== "[no status]") {
      this.name = `FartherHTTPError - ${method} ${status} - ${url}`;
    } else if (method !== "[no method]") {
      this.name = `FartherHTTPError - ${method} - ${url}`;
    } else {
      this.name = `FartherHTTPError - ${url}`;
    }

    this.method = method;
    this.url = url;
    this.status = `${status}`;
  }
}

const DomainIgnoreList = ["uat-altsmp.farther.com", "altsmp.farther.com"];

const shouldIgnore401Error = (url: string | undefined): boolean => {
  if (url === undefined) {
    return false;
  }

  return DomainIgnoreList.reduce(
    (prev, curr) => prev || url.includes(curr),
    false
  );
};

axios.interceptors.request.use(
  function (config): InternalAxiosRequestConfig<any> {
    // Do something before request is sent

    // From https://github.com/getsentry/sentry-javascript/issues/3169#issuecomment-1251179809
    const existingTransaction = Sentry.getCurrentHub()
      .getScope()
      ?.getTransaction();

    if (existingTransaction !== undefined) {
      return config;
    }

    const method = (config.method ?? "").toUpperCase();
    const url = normalizeURL(config.url ?? "");

    const transaction = Sentry.startTransaction({
      name: `API Request: ${method} ${url}`,
    });

    // From https://docs.sentry.io/platforms/javascript/performance/instrumentation/custom-instrumentation/
    // Set transaction on scope to associate with errors and get included span instrumentation
    // If there's currently an unfinished transaction, it may be dropped
    Sentry.getCurrentHub().configureScope((scope) =>
      scope.setSpan(transaction)
    );

    (config as any).tracing = {
      transaction,
    };

    return config;
  },
  async function (error) {
    console.log("Axios: Pre-Request error");

    // We need to be in a transaction to send this to Sentry
    let result = Sentry.getCurrentHub().getScope()?.getTransaction();
    if (result === undefined) {
      result = Sentry.startTransaction({
        name: `API Request`,
      });
    }

    if (error instanceof Error) {
      captureException(error);
    } else {
      captureException(new Error("Axios Request Error"));
    }
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    (response.config as any).tracing?.transaction?.finish?.();
    return response;
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    const url: string = error?.config?.url ?? "[no url]";

    if (axios.isAxiosError(error)) {
      const method = error.config?.method?.toUpperCase() ?? "[no method]";
      const status = error.response?.status ?? "[no status]";
      const errorURL = normalizeURL(url);

      const customError = new FartherHTTPError(method, errorURL, status);

      // With the proxy we no longer need to send http errors from Web, the
      // middleware will handle it
      let sendToSentry = false;
      if (error.response?.status === 401) {
        sendToSentry = false;
      }

      if (sendToSentry) {
        captureException(customError);
      }
    } else if (error instanceof Error) {
      const errorURL = normalizeURL(url);

      const customError = new FartherHTTPError(
        "[no method]",
        errorURL,
        "[no status]"
      );
      customError.name = `${customError.name} - not isAxiosError - ${errorURL}`;

      captureException(customError);
    } else {
      captureException(new Error("Axios Response Error"));
    }

    // Do app-specific action based on error
    if (axios.isAxiosError(error)) {
      const method = error.config?.method?.toUpperCase() ?? "[no method]";
      const status = error.response?.status ?? "[no status]";

      if (error.response) {
        switch (error.response?.status ?? 0) {
          case 401:
            if (shouldIgnore401Error(url)) {
              console.log(`isAxiosError error ${method} ${status} ${url}`);
              break;
            }

            // Log the user out
            console.log(`Log user out due to ${error.response.status}`);
            logUserOut(`HTTP code: ${error.response.status}`);
            break;

          case 403:
            console.log(`${method} ${status} ${url}`);
            break;

          default:
            console.log(`isAxiosError error ${method} ${status} ${url}`);
            break;
        }
      }
    }

    // This should be last so that any errors are reported in this transaction
    (error.config as any).tracing?.transaction?.finish?.();

    return Promise.reject(error);
  }
);
