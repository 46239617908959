import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

export const useStyles = makeStyles()((theme) => ({
  container: {
    marginBottom: theme.spacing(pxToSpacing(64)),
  },
  alertsCount: {
    fontFamily: theme.typography.fontSecondary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "19px",
    letterSpacing: "0.2px",
    color: theme.appearance.$text,

    marginBottom: theme.spacing(pxToSpacing(16)),
  },
  seeMoreContainer: {
    display: "flex",
  },
  seeMoreText: {
    fontFamily: theme.typography.fontSecondary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "19px",
    letterSpacing: "0.2px",
    color: theme.appearance.$textSoft,

    marginRight: theme.spacing(pxToSpacing(4)),
  },
  seeMoreTextDisabled: {
    color: theme.appearance.$textDisabled,
  },
  seeMoreIcon: {
    width: 18,
    height: 18,
    color: theme.appearance.$iconSoft,
  },
  seeMoreIconDisabled: {
    color: theme.appearance.$iconDisabled,
  },
  noAlertsBox: {
    width: 280,
    padding: theme.spacing(pxToSpacing(16)),

    borderRadius: "5px",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.appearance.$border,
  },
  noAlertsText: {
    fontFamily: theme.typography.fontSecondary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "19px",
    color: theme.appearance.$textSubtlest,
  },
  alertsContainer: {
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: "285px",
    gap: 16,
    overflowX: "auto",

    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  alert: {
    alignSelf: "stretch",
  },
}));
