import React from "react";

import { TaxBudget } from "@fartherfinance/frontend/api/InvestmentModel/Types";

import toUSD from "@src/utils/toUSD";

import styles from "./Meta.module.css";

interface Props {
  budget: TaxBudget;
}

const FutureTaxBudgetMeta: React.FC<Props> = ({ budget }) => {
  const { year, budgetAmount } = budget;

  return (
    <div className={styles.row}>
      <div className={styles.taxYear}>{`${year} Tax Year`}</div>

      <div className={styles.budgetMax}>{toUSD(budgetAmount)}</div>
    </div>
  );
};

export default FutureTaxBudgetMeta;
