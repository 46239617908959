import React from "react";

import { Box, Collapse, Stack } from "@mui/material";

import OpportunityAdditionalDetails from "../OpportunityAdditionalDetails";
import { yesNoValues } from "@src/multiCustodian/pages/Advisor/Opportunities/common";
import { opportunityCompletionReasonMap } from "@src/multiCustodian/pages/Advisor/Opportunities/utils";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormDateField from "@src/sharedComponents/Forms/FormDateField";
import FormNumberField from "@src/sharedComponents/Forms/FormNumberField";
import FormRadioField from "@src/sharedComponents/Forms/FormRadioField";
import FormTextareaField from "@src/sharedComponents/Forms/FormTextareaField";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";

import { isDateValid } from "./OpportunityCompletedForm.utils";

import styles from "./OpportunityCompletedForm.module.css";

const completionReasons = Array.from(opportunityCompletionReasonMap.values());

interface OpportunityStandardCompletedFormProps {
  isAssetWon: boolean;
  isAssetLost: boolean;
  isOtherReasonSelected: boolean;
}

export default function OpportunityStandardCompletedForm({
  isAssetWon,
  isAssetLost,
  isOtherReasonSelected,
}: OpportunityStandardCompletedFormProps): JSX.Element {
  return (
    <Stack className={styles.container}>
      <FormTextField
        style={{ display: "none" }}
        name="completeFormType"
        defaultValue="STANDARD"
      />
      <FormRadioField
        name="assetWon"
        label="Did you win new assets?"
        required="Please select an option"
        values={yesNoValues}
      />
      {isAssetWon && (
        <FlexWrapper className={styles.assetWonContainer}>
          <FormNumberField
            name="amount"
            startAdornment="$"
            required="Please enter an amount"
            label="How much?"
          />
          <FormDateField
            name="expectedClearanceDate"
            label="Expected date funds will clear"
            required="Please select a date"
            rules={{
              validate: { isDateValid },
            }}
          />
        </FlexWrapper>
      )}
      {isAssetLost && (
        <Box>
          <FormRadioField
            name="completeReason"
            label="What is the reason?"
            required="Please select a reason for completing this opportunity."
            values={completionReasons}
            row={false}
          />
          <Collapse in={isOtherReasonSelected}>
            <Box>
              <FormTextareaField
                containerClassName={styles.otherReasonTextarea}
                name="completeDetails"
                label=""
                placeholder="Provide details..."
                required={
                  isOtherReasonSelected
                    ? "Please provide a reason for completing the opportunity."
                    : false
                }
                minRows={1}
              />
            </Box>
          </Collapse>
        </Box>
      )}
      <OpportunityAdditionalDetails fieldName="completeExtraDetails" />
    </Stack>
  );
}
