import React from "react";

import { last } from "lodash";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";

import { formatShortCash } from "@fartherfinance/frontend/formatting/numbers";
import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import createDateByYear from "../ModelAnalysis/components/utils/createDateByYear";

import CustomDot from "./components/CustomDot";
import CustomTooltip from "./components/CustomTooltip";
import { DataKey } from "./Types";
import { graphSettings } from "./utils/graphSettings";

const PLACEHOLDER_DATA: DataItem[] = [
  { year: 0, results10Pct: 10000, results50Pct: null, results90Pct: null },
  { year: 0, results10Pct: 20000, results50Pct: null, results90Pct: null },
];

export interface DataItem {
  year: number;
  results10Pct: number | null;
  results50Pct: number | null;
  results90Pct: number | null;
}

interface Props {
  monteCarloData: DataItem[];
  setEndDate: (toDate: string) => void;
}

const ProjectionsGraph = (props: Props): JSX.Element => {
  const {
    color: {
      $textSubtle,
      $chartLineSecondary,
      $chartLinePrimary,
      $chartLineTertiary,
    },
  } = useTheme();

  const resetEndDate = () => {
    const lastItem = last(props.monteCarloData);

    if (lastItem === undefined) {
      return;
    }

    props.setEndDate(createDateByYear(lastItem.year));
  };

  const noData = props.monteCarloData.length === 0;

  if (noData) {
    return (
      <Container data={PLACEHOLDER_DATA} resetEndDate={() => undefined}>
        <YAxis
          style={{ fontSize: graphSettings.fontSize }}
          width={graphSettings.YAxisWidth}
          tickFormatter={formatShortCash}
          tickCount={graphSettings.yTickCount}
          stroke={$textSubtle}
          tickLine={false}
          axisLine={false}
          dx={graphSettings.dx}
          dataKey="results10Pct"
          domain={["dataMin", "dataMax"]}
        />
      </Container>
    );
  }

  return (
    <Container data={props.monteCarloData} resetEndDate={resetEndDate}>
      <YAxis
        style={{ fontSize: graphSettings.fontSize }}
        width={graphSettings.YAxisWidth}
        tickFormatter={formatShortCash}
        tickCount={graphSettings.yTickCount}
        stroke={$textSubtle}
        tickLine={false}
        axisLine={false}
        dx={graphSettings.dx}
      />

      <Tooltip
        cursor={false}
        content={(tooltipProps: TooltipProps<ValueType, DataKey>) => {
          return (
            <CustomTooltip
              tooltipProps={tooltipProps}
              setToDate={props.setEndDate}
            />
          );
        }}
      />
      <Area
        type={"linear"}
        strokeWidth={graphSettings.strokeWidth}
        dataKey="results90Pct"
        stroke={$chartLineTertiary}
        fill={$chartLineTertiary}
        fillOpacity={0.1}
        activeDot={(p) => <CustomDot {...p} />}
        isAnimationActive={false}
        strokeLinejoin="round" //*Important for Safari* - prevents rendering of small bumps in the stroke line
      />
      <Area
        type={"linear"}
        strokeWidth={graphSettings.strokeWidth}
        dataKey="results50Pct"
        stroke={$chartLinePrimary}
        fill={$chartLinePrimary}
        activeDot={(p) => <CustomDot {...p} />}
        fillOpacity={0.1}
        isAnimationActive={false}
        strokeLinejoin="round" //*Important for Safari* - prevents rendering of small bumps in the stroke line
      />
      <Area
        type={"linear"}
        strokeWidth={graphSettings.strokeWidth}
        dataKey="results10Pct"
        stroke={$chartLineSecondary}
        fill={$chartLineSecondary}
        dot={false}
        activeDot={(p) => <CustomDot {...p} />}
        fillOpacity={0.3}
        isAnimationActive={false}
        strokeLinejoin="round" //*Important for Safari* - prevents rendering of small bumps in the stroke line
      />
    </Container>
  );
};

export default ProjectionsGraph;

type ContainerProps = {
  data: DataItem[];
  resetEndDate: () => void;
};

const Container = (props: React.PropsWithChildren<ContainerProps>) => {
  const {
    color: { $textSubtle, $borderBold },
  } = useTheme();

  return (
    <ResponsiveContainer width="100%" height={320}>
      <ComposedChart
        data={props.data}
        onMouseLeave={props.resetEndDate}
        margin={{
          top: 20,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid
          vertical={false}
          stroke={$borderBold}
          strokeDasharray="0"
          strokeWidth={1}
        />
        <XAxis
          style={{ fontSize: graphSettings.fontSize }}
          height={graphSettings.XAxisHeight}
          dataKey="year"
          tickFormatter={createDateByYear}
          stroke={$textSubtle}
          tickLine={false}
          axisLine={false}
          tick={false}
          dy={graphSettings.dy}
        />
        {props.children}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
