import React from "react";

import MuiAvatar, { AvatarProps as MuiAvatarProps } from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import type { User } from "@src/yellowstone/modules/users";

import { useStyles } from "./Avatar.styles";

type AvatarProps = MuiAvatarProps &
  (
    | {
        user: User | null;
        showUsername: true;
      }
    | {
        user?: never;
        showUsername: false;
      }
  );

export const Avatar: React.FC<AvatarProps> = ({
  children,
  className,
  user,
  showUsername = false,
  ...restProps
}) => {
  const { classes, cx } = useStyles();

  return (
    <Stack direction="row" alignItems="center">
      <MuiAvatar className={cx(classes.avatar, className)} {...restProps}>
        {children}
      </MuiAvatar>
      {showUsername && (
        <Typography className={classes.avatarText}>
          {user
            ? `${user.name.first} ${user.name.middle ?? ""} ${user.name.last}`
            : "No user selected"}
        </Typography>
      )}
    </Stack>
  );
};
