import React from "react";

import styles from "./Row.module.css";

interface Props {
  start?: JSX.Element | string;
  end?: JSX.Element | string;
}

const Row: React.FC<Props> = ({ start, end }) => {
  return (
    <div className={styles.row}>
      <div className={styles.text}>{start}</div>
      <div className={styles.text}>{end}</div>
    </div>
  );
};

export default Row;
