import React from "react";

const Documents: React.FC = () => (
  <svg
    width="100%"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M57.0002 17.8607V60.8765H20.9434V45.8083C20.9434 42.2523 23.8261 39.3696 27.3821 39.3696V39.3696V38.6547V38.6547C23.8261 38.6547 20.9434 35.7719 20.9434 32.2159V17.8607H57.0002Z"
      fill="currentColor"
    />
    <path
      d="M47.8644 14.2337H17.2239V52.7512H7L7.0001 35.0846C7.00011 31.502 9.90437 28.5978 13.487 28.5978V28.5978V27.8743V27.8743C9.90437 27.8743 7.00011 24.9701 7.00009 21.3875L7 4H47.8644V14.2337Z"
      fill="currentColor"
    />
  </svg>
);

export default Documents;
