import React, { useState } from "react";

import ScenarioSettingsClosed from "./ScenarioSettingsClosed";
import ScenarioSettingsOpened from "./ScenarioSettingsOpened";

const ScenarioSettings: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      {isOpen ? (
        <ScenarioSettingsOpened setIsOpen={() => setIsOpen(false)} />
      ) : (
        <ScenarioSettingsClosed setIsOpen={() => setIsOpen(true)} />
      )}
    </>
  );
};

export default ScenarioSettings;
