import React, { useMemo } from "react";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FolderIcon from "@mui/icons-material/Folder";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import { DocumentsCenterPath } from "@src/config/routing/RouterPaths";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import IconButton from "@src/sharedComponents/IconButton/IconButton";
import Menu from "@src/sharedComponents/Menu/Menu";

import styles from "./DocumentsBreadcrumbs.module.css";

interface DocumentsBreadcrumbsProps {
  clientId: ClientId;
  directories: string[];
}

const DocumentsBreadcrumbs: React.FC<DocumentsBreadcrumbsProps> = ({
  clientId,
  directories,
}) => {
  const history = useHistory();

  const handleNavigateToNestedDirectory = (directory: string) => {
    const directoryIndex = directories.indexOf(directory);
    const directoriesToKeep = directories.slice(0, directoryIndex + 1);

    const base = `/Client/${clientId}/${DocumentsCenterPath}`;
    const pathname = base + "/" + directoriesToKeep.join("/");

    history.push({ ...history.location, pathname });
  };

  const { foldedDirectories, visibleDirectories } = useMemo(() => {
    // NOTE: show only two last directories, rest should be folded
    const visibilityThreshold = 2;

    return {
      foldedDirectories: directories.slice(
        0,
        directories.length - visibilityThreshold
      ),
      visibleDirectories: directories.slice(-visibilityThreshold),
    };
  }, [directories]);

  return (
    <Box className={styles.container}>
      <Typography className={toClassName(styles.breadcrumb, styles.inactive)}>
        Documents
      </Typography>
      {foldedDirectories.length > 0 && (
        <Box className={styles.breadcrumbContainer}>
          <ChevronRightIcon className={styles.separator} />
          <Menu
            transformOrigin={{ horizontal: "left", vertical: "top" }}
            options={foldedDirectories.map((directory) => ({
              label: directory,
              onClick: () => handleNavigateToNestedDirectory(directory),
              IconComponent: FolderIcon,
            }))}
          >
            {({ handleOpenMenu }) => (
              <IconButton
                onClick={handleOpenMenu}
                IconComponent={MoreHorizIcon}
              />
            )}
          </Menu>
        </Box>
      )}
      {visibleDirectories.map((directory, index, array) => {
        const isLastElement = index === array.length - 1;

        return (
          <Box key={directory} className={styles.breadcrumbContainer}>
            <ChevronRightIcon className={styles.separator} />
            <Typography
              className={toClassName(styles.breadcrumb, {
                [styles.inactive]: isLastElement,
              })}
              onClick={
                isLastElement
                  ? undefined
                  : () => handleNavigateToNestedDirectory(directory)
              }
              noWrap
            >
              {directory}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default DocumentsBreadcrumbs;
