import React, { PropsWithChildren, useState } from "react";

import HeaderWithSubHeader from "@src/sharedComponents/HeaderWithSubHeader/HeaderWithSubHeader";
import OptionWithExpandCollapseContent from "@src/sharedComponents/OptionWithExpandCollapseContent/OptionWithExpandCollapseContent";

import styles from "./SelectResourcesToShare.module.css";

const SelectResourcesToShare: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [showSelectResources, setShowSelectResources] =
    useState<boolean>(false);

  return (
    <>
      <OptionWithExpandCollapseContent
        className={styles.optionWithExpandCollapseContent}
        headerClassName={styles.optionWithExpandCollapseContentHeader}
        expand={showSelectResources}
        setExpand={setShowSelectResources}
        isLoading={false}
        headerLeftContent={
          <HeaderWithSubHeader
            header={"Accounts"}
            headerClassName={styles.optionHeader}
            subHeader={"Grant access to specific accounts (select one or more)"}
          />
        }
      >
        {children}
      </OptionWithExpandCollapseContent>

      {/* Need this here to provide space between the max-height overflow scroll container bottom (which is right up against the button footer) and the OptionWithExpandCollapseContent element above */}
      <div className={styles.takeUpSpace} />
    </>
  );
};

export default SelectResourcesToShare;
