import React from "react";

import { useHistory, useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import { CashManagementPath } from "@src/config/routing/RouterPaths";
import CancelCurrentTriggerModal from "@src/multiCustodian/components/Client/CashManagement/CancelCurrentTriggerModal/CancelCurrentTriggerModal";
import FundingSettings from "@src/multiCustodian/pages/Client/:clientId/CashManagement";

const CancelFundingPlan = (): JSX.Element => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const history = useHistory();

  return (
    <FundingSettings>
      <CancelCurrentTriggerModal
        closeModal={() =>
          history.push(`/Client/${clientId}/${CashManagementPath}`)
        }
      />
    </FundingSettings>
  );
};

export default CancelFundingPlan;
