import {
  OpportunityCancelationReason,
  OpportunityClient,
  OpportunityCompletionReason,
  OpportunityPriority,
  OpportunitySnoozingPeriod,
  OpportunityStatus,
  OpportunityType,
} from "@fartherfinance/frontend/api/Opportunities/Types";

import { UNKNOWN_USER } from "@src/multiCustodian/pages/Advisor/common";
import { captureException } from "@src/multiCustodian/services/tracking";

import { FormOpportunityStatus } from "./common";

export const opportunityStatusMap = new Map<OpportunityStatus, string>([
  ["NOT_STARTED", "Not Started"],
  ["IN_PROGRESS", "In Progress"],
  ["AWAITING_RESPONSE", "Awaiting Response"],
  ["SNOOZED", "Snoozed"],
  ["CANCELED", "Canceled"],
  ["COMPLETED_WITH_ASSETS_WON", "Completed"],
  ["COMPLETED_NO_ASSETS_WON", "Completed"],
]);

export const opportunityStatusFormMap = new Map<FormOpportunityStatus, string>([
  ["NOT_STARTED", "Not Started"],
  ["IN_PROGRESS", "In Progress"],
  ["AWAITING_RESPONSE", "Awaiting Response"],
  ["SNOOZED", "Snoozed"],
  ["CANCELED", "Canceled"],
  ["COMPLETED", "Completed"],
]);

export const opportunityStatusFilterMap = new Map<OpportunityStatus, string>([
  ["NOT_STARTED", "Not Started"],
  ["IN_PROGRESS", "In Progress"],
  ["AWAITING_RESPONSE", "Awaiting Response"],
  ["SNOOZED", "Snoozed"],
  ["CANCELED", "Canceled"],
  ["COMPLETED_WITH_ASSETS_WON", "Completed Won"],
  ["COMPLETED_NO_ASSETS_WON", "Completed Lost"],
]);

export const opportunityTypeMap = new Map<OpportunityType, string>([
  ["LARGE_DEPOSIT", "Large Bank Deposit"],
  ["LARGE_BANK_ACCOUNT_BALANCE", "Large Bank Account Balance"],
  ["LARGE_INVESTMENT_ACCOUNT_BALANCE", "Large Investment Account Balance"],
  ["INSURANCE_INTEREST", "Interested In Insurance"],
  ["LENDING_INTEREST", "Interested In Lending"],
  ["ALTERNATIVE_ASSET_INTEREST", "Interested In Alternative Asset"],
]);

export const opportunityPriorityMap = new Map<OpportunityPriority, string>([
  ["LOW", "Low"],
  ["MEDIUM", "Medium"],
  ["HIGH", "High"],
]);

export const opportunitySnoozingPeriodMap = new Map<
  OpportunitySnoozingPeriod,
  string
>([
  ["OneWeek", "1 Week"],
  ["OneMonth", "1 Month"],
  ["ThreeMonths", "3 Months"],
  ["SixMonths", "6 Months"],
]);

export const opportunityCancelationReasonMap = new Map<
  OpportunityCancelationReason,
  string
>([
  ["IncorrectData", "Incorrect data"],
  ["DuplicateOpportunity", "Duplicated opportunity"],
  ["DuplicatedBySharedAccount", "Duplicated by shared account"],
  ["OtherCancelReason", "Other"],
]);

export const opportunityCompletionReasonMap = new Map<
  OpportunityCompletionReason,
  string
>([
  ["ClientNotInterested", "Client is not interested in moving these assets"],
  ["OtherReason", "Other"],
]);

export const mapValueToLabel = <K extends string, V extends string>(
  map: Map<K, V>,
  value: K
): V => {
  const label = map.get(value);

  if (!label) {
    const error = new Error(
      `"mapValueToLabel" label not found for value: ${value}`
    );
    captureException(error);

    throw error;
  }

  return label;
};

export const mapLabelToValue = <K extends string, V extends string>(
  map: Map<K, V>,
  label: V
): K => {
  for (const [key, value] of map) {
    if (value === label) {
      return key;
    }
  }

  const error = new Error(
    `"mapLabelToValue" value not found for label: ${label}`
  );
  captureException(error);

  throw error;
};

export const getOpportunityClientName = (client: OpportunityClient): string => {
  const { firstName, lastName } = client;

  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  return UNKNOWN_USER;
};
