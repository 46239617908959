import { captureException } from "@src/multiCustodian/services/tracking";

const execute_statusNotification = (
  img_src: string,
  text: string,
  pinToleftSideOfScreenInsteadOfRight = true
) => {
  // .css is in App.css
  let el_container = document.getElementById("Status_Notification__container");
  if (pinToleftSideOfScreenInsteadOfRight) {
    el_container = document.getElementById(
      "Status_Notification__containerBottomLeft"
    );
  }
  if (el_container === null) {
    captureException(new Error("el_container === null"), {
      extra: { pinToleftSideOfScreenInsteadOfRight, text, img_src },
    });
    return;
  }
  const new_div = document.createElement("div");
  new_div.className = "Status_Notification__div";
  if (pinToleftSideOfScreenInsteadOfRight) {
    new_div.className = "Status_Notification__divBottomLeft";
  }
  const new_img = document.createElement("img");
  new_img.className = "Status_Notification__img";
  new_img.src = img_src; // ex: "/src/assets/svg/check_green.svg"
  const new_p = document.createElement("p");
  const close_img = document.createElement("img"); // this is used to close the statusNotification automatically on press.
  close_img.className = "Status_Notification__close";
  close_img.src = "/src/assets/svg/x_circle_grey.svg";
  close_img.style.cursor = "pointer";
  close_img.addEventListener("click", () => {
    new_div.remove();
  });
  new_p.className = "Status_Notification__text";
  new_p.innerHTML = text; // ex: "Nice! Action completed successfully.";
  new_div.appendChild(new_img);
  new_div.appendChild(new_p);
  new_div.appendChild(close_img);
  el_container.appendChild(new_div);
  new_div.addEventListener("click", () => {
    new_div.remove();
  });
  new_div.style.cursor = "pointer";
  setTimeout(() => {
    new_div.remove();
  }, 8000);
};

export default execute_statusNotification;
