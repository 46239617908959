/* eslint-disable react/prop-types */

import React from "react";

import {
  IconButton as MUIIconButton,
  IconButtonProps as MUIIconButtonProps,
  SvgIconTypeMap,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

import styles from "./IconButton.module.css";

type Props = MUIIconButtonProps & {
  IconComponent: OverridableComponent<SvgIconTypeMap<unknown, "svg">> & {
    muiName: string;
  };
  iconClassName?: string;
};

export default React.forwardRef<HTMLButtonElement, Props>(function IconButton(
  { IconComponent, iconClassName, ...restProps }: Props,
  ref
) {
  return (
    <MUIIconButton
      {...restProps}
      ref={ref}
      classes={{
        root: styles.root,
        sizeSmall: styles.sizeSmall,
        ...restProps.classes,
      }}
      color="primary"
      disableRipple
      disableFocusRipple
      disableTouchRipple
    >
      <IconComponent className={iconClassName} />
    </MUIIconButton>
  );
});
