import { AssetList } from "../Types";

const wealthPlanAssets: AssetList = {
  dark: {
    logoSymbol: "/src/assets/png/wealthPlan_symbol_dark.png",
    logoWordmark: "/src/assets/png/wealthPlan_wordmark_dark.png",
  },
  light: {
    logoSymbol: "/src/assets/png/wealthPlan_symbol_light.png",
    logoWordmark: "/src/assets/png/wealthPlan_wordmark_light.png",
  },
} as const;

export default wealthPlanAssets;
