import React, { useMemo } from "react";

import { format } from "date-fns";

import { graphSettings } from "../../ProjectionsGraph/utils/graphSettings";

import styles from "../ModelAnalysis.module.css";

interface Props {
  toDate: string | null;
}

const DateDisplay = ({ toDate }: Props): JSX.Element => {
  const today = useMemo(() => new Date(), []);
  const fromDate = useMemo(() => format(today, "LLL d, yyyy"), [today]); //ex: Jan 3, 2025

  return (
    <Container>
      <div className={styles.date}>
        From <span>{fromDate}</span>
      </div>

      <div className={styles.date}>
        To <span>{toDate ?? "Unknown"}</span>
      </div>
    </Container>
  );
};

export default DateDisplay;

const Container = (props: React.PropsWithChildren<unknown>) => {
  return (
    <div
      className={styles.dateDisplay}
      style={{
        width: `calc(100% - ${graphSettings.YAxisWidth}px)`,
        marginLeft: "auto",
      }}
    >
      {props.children}
    </div>
  );
};
