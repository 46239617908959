import React, { useCallback } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useParams } from "react-router-dom";

import useCheckPermissions from "@fartherfinance/frontend/api/Authz/hooks/useCheckPermission";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import { trackEvent } from "@src/multiCustodian/services/tracking";
import LinkWithLocation from "@src/sharedComponents/LinkWithLocation/LinkWithLocation";

import styles from "./ClientProfile.module.css";

export type ClientDrawerTab =
  | "Profile"
  | "Admin"
  | "Tax Budgets"
  | "Copilot"
  | "Actions";

interface Props {
  curTab: ClientDrawerTab;
}

const ClientDrawerTabs = (props: Props): JSX.Element => {
  const { clientId } = useParams<{ clientId: ClientId }>();

  const auth = useAdvisorRequestAuth();

  const canDeleteAccount = useCheckPermissions(
    {
      type: "FartherAccount",
      action: "delete",
      // We are looking for a generic permission for the Account resource
      // and not a specific instance
      id: null,
    },
    auth
  );

  const { enableAdvisorCoPilot } = useFlags();

  const trackAdvisorOpenTaxBudgets = useCallback(() => {
    trackEvent({ name: "Advisor Open Tax-Budget" });
  }, []);

  return (
    <div className={styles.tabsContainer}>
      <LinkWithLocation
        pathname={`/Advisor/Clients/${clientId}/Profile`}
        style={{ textDecoration: "none" }}
      >
        <div
          className={
            props.curTab === "Profile" ? styles.tabSelected : styles.tab
          }
        >
          Profile
        </div>
      </LinkWithLocation>

      {canDeleteAccount && (
        <LinkWithLocation
          pathname={`/Advisor/Clients/${clientId}/Admin`}
          style={{ textDecoration: "none" }}
        >
          <div
            className={
              props.curTab === "Admin" ? styles.tabSelected : styles.tab
            }
          >
            Accounts
          </div>
        </LinkWithLocation>
      )}

      <div onClick={trackAdvisorOpenTaxBudgets}>
        <LinkWithLocation
          pathname={`/Advisor/Clients/${clientId}/TaxBudgets`}
          style={{ textDecoration: "none" }}
        >
          <div
            className={
              props.curTab === "Tax Budgets" ? styles.tabSelected : styles.tab
            }
          >
            Tax Budgets
          </div>
        </LinkWithLocation>
      </div>

      {enableAdvisorCoPilot && (
        <LinkWithLocation
          pathname={`/Advisor/Clients/${clientId}/Copilot`}
          style={{ textDecoration: "none" }}
        >
          <div
            className={
              props.curTab === "Copilot" ? styles.tabSelected : styles.tab
            }
          >
            Advisor Copilot (Beta)
          </div>
        </LinkWithLocation>
      )}

      <LinkWithLocation
        pathname={`/Advisor/Clients/${clientId}/Actions`}
        style={{ textDecoration: "none" }}
      >
        <div
          className={
            props.curTab === "Actions" ? styles.tabSelected : styles.tab
          }
        >
          Actions
        </div>
      </LinkWithLocation>
    </div>
  );
};

export default ClientDrawerTabs;
