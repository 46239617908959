import React, { useMemo } from "react";

import { useHistory, useLocation, useParams } from "react-router-dom";

import useGetPlanAccountDetails from "@fartherfinance/frontend/api/Accounts/hooks/useGetPlanAccountDetails";
import { FartherAccountId, PlanId } from "@fartherfinance/frontend/api/Types";

import Drawer from "@src/multiCustodian/components/Drawer/Drawer";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

import EditPlan from "./EditPlan";
import AssetLending from "./Forms/AssetLending";
import IndividualBrokerage from "./Forms/Individual";
import JointCP from "./Forms/JointCP";
import JointTE from "./Forms/JointTE";
import JointTIC from "./Forms/JointTIC";
import JointWROS from "./Forms/JointWROS";
import RolloverIRA from "./Forms/RolloverIRA";
import RothIRA from "./Forms/RothIRA";
import SepIRA from "./Forms/SepIRA";
import TraditionalIRA from "./Forms/TraditionalIRA";
import Trust from "./Forms/Trust";

import styles from "./plans.module.css";

export default function EditAccount(): JSX.Element {
  const { planId, accountId } = useParams<{
    planId: PlanId;
    accountId: FartherAccountId;
  }>();
  const { search } = useLocation();
  const qs = useMemo(() => new URLSearchParams(search), [search]);
  const history = useHistory();

  const auth = useAdvisorRequestAuth();

  const account = useGetPlanAccountDetails(planId, accountId, auth);

  if (account.isLoading) {
    return (
      <EditPlan selectedAccount={accountId}>
        <Drawer
          transitionDuration={0}
          isDrawerOpen
          onClose={() => {
            history.push(`/Advisor/Plans/${planId}?${qs.toString()}`);
          }}
        >
          <div className={styles.loadingContainer}>
            <LogoLoadingStill />
          </div>
        </Drawer>
      </EditPlan>
    );
  }

  if (account.hasError) {
    return (
      <EditPlan selectedAccount={accountId}>
        <Drawer
          transitionDuration={0}
          isDrawerOpen
          onClose={() => {
            history.push(`/Advisor/Plans/${planId}?${qs.toString()}`);
          }}
        >
          <div>Error getting account.</div>
        </Drawer>
      </EditPlan>
    );
  }

  switch (account.data.accountInformation.accountType) {
    case "Individual":
      return (
        <EditPlan selectedAccount={accountId}>
          <IndividualBrokerage
            planId={planId}
            accountId={accountId}
            onClose={() => {
              history.push(`/Advisor/Plans/${planId}?${qs.toString()}`);
            }}
            account={account.data}
          />
        </EditPlan>
      );

    case "IRA":
      return (
        <EditPlan selectedAccount={accountId}>
          <TraditionalIRA
            planId={planId}
            accountId={accountId}
            onClose={() => {
              history.push(`/Advisor/Plans/${planId}?${qs.toString()}`);
            }}
            account={account.data}
          />
        </EditPlan>
      );

    case "Roth IRA":
      return (
        <EditPlan selectedAccount={accountId}>
          <RothIRA
            planId={planId}
            accountId={accountId}
            onClose={() => {
              history.push(`/Advisor/Plans/${planId}?${qs.toString()}`);
            }}
            account={account.data}
          />
        </EditPlan>
      );

    case "Sep IRA":
      return (
        <EditPlan selectedAccount={accountId}>
          <SepIRA
            planId={planId}
            accountId={accountId}
            onClose={() => {
              history.push(`/Advisor/Plans/${planId}?${qs.toString()}`);
            }}
            account={account.data}
          />
        </EditPlan>
      );

    case "Trust":
      return (
        <EditPlan selectedAccount={accountId}>
          <Trust
            planId={planId}
            accountId={accountId}
            onClose={() => {
              history.push(`/Advisor/Plans/${planId}?${qs.toString()}`);
            }}
            account={account.data}
          />
        </EditPlan>
      );

    case "Joint TIC":
      return (
        <EditPlan selectedAccount={accountId}>
          <JointTIC
            planId={planId}
            accountId={accountId}
            onClose={() => {
              history.push(`/Advisor/Plans/${planId}?${qs.toString()}`);
            }}
            account={account.data}
          />
        </EditPlan>
      );

    case "Joint CP":
      return (
        <EditPlan selectedAccount={accountId}>
          <JointCP
            planId={planId}
            accountId={accountId}
            onClose={() => {
              history.push(`/Advisor/Plans/${planId}?${qs.toString()}`);
            }}
            account={account.data}
          />
        </EditPlan>
      );

    case "Joint WROS":
      return (
        <EditPlan selectedAccount={accountId}>
          <JointWROS
            planId={planId}
            accountId={accountId}
            onClose={() => {
              history.push(`/Advisor/Plans/${planId}?${qs.toString()}`);
            }}
            account={account.data}
          />
        </EditPlan>
      );

    case "Joint TE":
      return (
        <EditPlan selectedAccount={accountId}>
          <JointTE
            planId={planId}
            accountId={accountId}
            onClose={() => {
              history.push(`/Advisor/Plans/${planId}?${qs.toString()}`);
            }}
            account={account.data}
          />
        </EditPlan>
      );

    case "Asset Lending":
      return (
        <EditPlan selectedAccount={accountId}>
          <AssetLending
            planId={planId}
            accountId={accountId}
            onClose={() => {
              history.push(`/Advisor/Plans/${planId}?${qs.toString()}`);
            }}
            account={account.data}
          />
        </EditPlan>
      );

    case "Rollover IRA":
      return (
        <EditPlan selectedAccount={accountId}>
          <RolloverIRA
            planId={planId}
            accountId={accountId}
            onClose={() => {
              history.push(`/Advisor/Plans/${planId}?${qs.toString()}`);
            }}
            account={account.data}
          />
        </EditPlan>
      );

    case "Inherited IRA":
      return (
        <EditPlan selectedAccount={accountId}>
          <Drawer
            transitionDuration={0}
            isDrawerOpen
            onClose={() => {
              history.push(`/Advisor/Plans/${planId}?${qs.toString()}`);
            }}
          >
            <div className={styles.warningText}>
              Account type of "{account.data.accountInformation.accountType}"
              not implemented.
            </div>
          </Drawer>
        </EditPlan>
      );

    case "Inherited Roth IRA":
      return (
        <EditPlan selectedAccount={accountId}>
          <Drawer
            transitionDuration={0}
            isDrawerOpen
            onClose={() => {
              history.push(`/Advisor/Plans/${planId}?${qs.toString()}`);
            }}
          >
            <div className={styles.warningText}>
              Account type of "{account.data.accountInformation.accountType}"
              not implemented.
            </div>
          </Drawer>
        </EditPlan>
      );

    case "Other":
      return (
        <EditPlan selectedAccount={accountId}>
          <Drawer
            transitionDuration={0}
            isDrawerOpen
            onClose={() => {
              history.push(`/Advisor/Plans/${planId}?${qs.toString()}`);
            }}
          >
            <div className={styles.warningText}>
              No additional details to display.
            </div>
          </Drawer>
        </EditPlan>
      );

    default:
      const exhaustiveCheck: never =
        account.data.accountInformation.accountType;
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Unhandled case: ${exhaustiveCheck}`);
  }
}
