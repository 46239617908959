import React from "react";

import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import styles from "./Select.module.css";

// NOTE: these props are passed by Material UI Select component internally
interface MuiSelectItemProps<T extends string> {
  "aria-selected": "true" | "false";
  "data-value": T;
  onClick: () => void;
  onKeyUp: () => void;
  role: string;
  selected: boolean;
}

type SelectItemProps<T extends string> = Partial<MuiSelectItemProps<T>> & {
  label: string;
  value: T;
  preAdornment?: React.ReactNode;
  postAdornment?: React.ReactNode;
};

export default function SelectItem<T extends string>({
  label,
  value,
  preAdornment,
  postAdornment,
  ...restProps
}: SelectItemProps<T>): JSX.Element {
  return (
    <MenuItem className={styles.menuItem} value={value} {...restProps}>
      <Stack direction="row" alignItems="center" spacing={1}>
        {preAdornment}
        <Typography className={styles.menuItemText}>{label}</Typography>
        {postAdornment}
      </Stack>
    </MenuItem>
  );
}
