import React, { PropsWithChildren } from "react";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./ListHeading.module.css";

type HeadingVariant = "primary" | "secondary";

interface Props {
  variant?: HeadingVariant;
}

const ListHeading: React.FC<PropsWithChildren<Props>> = ({
  children,
  variant = "primary",
}) => (
  <div
    className={toClassName(styles.text, {
      [styles.primary]: variant === "primary",
      [styles.secondary]: variant === "secondary",
    })}
  >
    {children}
  </div>
);

export default ListHeading;
