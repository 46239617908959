import React from "react";

import CollaboratorNameAndEmail from "./NameAndEmail/CollaboratorNameAndEmail";
import { useAddCollaboratorContext } from "./reducer/Context";
import ReviewCollaborator from "./Review/Review";
import CollaboratorTypeAndAccessLevel from "./TypeAndAccessLevel/CollaboratorTypeAndAccessLevel";

const AddCollaboratorFlowSteps: React.FC = () => {
  const { state } = useAddCollaboratorContext();

  const { pageIndex } = state;

  switch (pageIndex) {
    case 0:
      return <CollaboratorNameAndEmail />;
    case 1:
      return <CollaboratorTypeAndAccessLevel />;
    case 2:
      return <ReviewCollaborator />;
    default:
      return null;
  }
};

export default AddCollaboratorFlowSteps;
