import React from "react";

import { Ticker } from "@fartherfinance/frontend/api/Types";

import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import styles from "./TickerContainer.module.css";

interface Props {
  ticker: Ticker | "Placeholder";
  tickerName: string | null;
}

const TickerContainer = (props: Props): JSX.Element => {
  return (
    <div className={styles.tickerContainer}>
      <div className={styles.ticker}>{props.ticker}</div>

      {props.tickerName !== null && (
        <Tooltip tooltipText={props.tickerName} placement="top">
          <div className={styles.tickerName}>{props.tickerName}</div>
        </Tooltip>
      )}
    </div>
  );
};

export default TickerContainer;
