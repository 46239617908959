import { useMemo } from "react";

import { useSelector } from "react-redux";

import { AdvisorRequestConfig } from "@fartherfinance/frontend/api/Types";

import { State } from "@src/store";

export default function useAdvisorRequestAuth(): AdvisorRequestConfig | null {
  const { farther_jwt, cockroach_advisor_id } = useSelector((state: State) => ({
    farther_jwt: state.main_Reducer.farther_jwt,
    cockroach_advisor_id: state.main_Reducer.cockroach_advisor_id,
  }));

  const auth: AdvisorRequestConfig | null = useMemo(() => {
    if (farther_jwt === null || cockroach_advisor_id === null) {
      return null;
    }

    return {
      jwt: farther_jwt,
      advisorId: cockroach_advisor_id,
    };
  }, [cockroach_advisor_id, farther_jwt]);

  return auth;
}
