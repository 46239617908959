import React, { useEffect } from "react";

import { isAfter, isBefore, isEqual, isValid, sub } from "date-fns";
import { FormProvider, useForm, useFormState } from "react-hook-form";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import FormDateField from "@src/sharedComponents/Forms/FormDateField";

import { CustomDateRange } from "./PerformanceCustomDateRangeContextProvider";

import styles from "./CustomDateRangeSelector.module.css";

interface Form {
  FromDate: Date | null;
  ToDate: Date | null;
}

interface Props {
  customDateRange: CustomDateRange;
  setCustomDateRange: (dateRangeObj: CustomDateRange) => void;
  setFormIsValid: (b: boolean) => void;
}

const CustomDateRangeSelectorForm: React.FC<Props> = ({
  customDateRange,
  setCustomDateRange,
  setFormIsValid,
}) => {
  const methods = useForm<Form>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      FromDate: customDateRange.from ?? null,
      ToDate: customDateRange.to ?? null,
    },
  });

  const { control, getValues, setValue, reset, trigger } = methods;

  const { isDirty: formIsDirty, isValid: formIsValid } = useFormState({
    control,
  });

  useEffect(() => {
    if (customDateRange.from !== null && customDateRange.to !== null) {
      trigger(); // validate form on mount
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFormIsValid(formIsValid);
  }, [setFormIsValid, formIsValid]);

  const values = getValues();

  useEffect(() => {
    if (formIsDirty) {
      const from = values.FromDate ?? null;
      const to = values.ToDate ?? null;

      reset({}, { keepValues: true });

      setCustomDateRange({
        from: sanitizeDate(from),
        to: sanitizeDate(to),
      });
    }
    // eslint-disable-next-line
  }, [values, formIsDirty]);

  useEffect(() => {
    if (
      customDateRange.from !== values.FromDate ||
      customDateRange.to !== values.ToDate
    ) {
      setValue("FromDate", customDateRange.from);
      setValue("ToDate", customDateRange.to);
    }
    // eslint-disable-next-line
  }, [customDateRange]);

  const sanitizeDate = (date: Date | null) => {
    if (!date) {
      return null;
    }

    if (
      isValid(date) &&
      isAfter(date, sub(new Date(), { years: 5 })) &&
      isBefore(date, new Date())
    ) {
      return date;
    }

    return null;
  };

  return (
    <div>
      <div
        className={toClassName(styles.text, styles.textBoldWithBottomMargin)}
      >
        Custom Date Range
      </div>

      <FormProvider {...methods}>
        <div className={styles.text}>From</div>

        <div className={styles.picker}>
          <FormDateField
            required={"Add a date"}
            label={undefined}
            name={"FromDate"}
            placeholder={"MM/DD/YYYY"}
            rules={{
              deps: ["ToDate"],
              validate: {
                isValid: (value) => {
                  if (value && !isValid(value)) {
                    return "Date is not valid";
                  }

                  if (value && isBefore(value, sub(new Date(), { years: 5 }))) {
                    return "Date is too far in the past";
                  }

                  return true;
                },
                isNotFuture: (value) => {
                  if (value && isAfter(value, new Date())) {
                    return "Cannot select a future date";
                  }
                  return true;
                },
                isInRange: (value, { ToDate }) => {
                  if (value && isAfter(value, ToDate)) {
                    return "Needs to be a date before To date";
                  }
                  return true;
                },
                isNotSameDay: (value, { ToDate }) => {
                  if (value && ToDate && isEqual(value, ToDate)) {
                    return "Dates need to be different";
                  }
                  return true;
                },
              },
            }}
          />
        </div>

        <div className={styles.text}>To</div>

        <div className={styles.picker}>
          <FormDateField
            required={"Add a date"}
            label={undefined}
            name={"ToDate"}
            placeholder={"MM/DD/YYYY"}
            rules={{
              deps: ["FromDate"],
              validate: {
                isValid: (value) => {
                  if (value && !isValid(value)) {
                    return "Date is not valid";
                  }

                  if (value && isBefore(value, sub(new Date(), { years: 5 }))) {
                    return "Date is too far in the past";
                  }

                  return true;
                },
                isNotFuture: (value) => {
                  if (
                    value &&
                    isAfter(value, new Date()) &&
                    isBefore(value, sub(new Date(), { years: 5 }))
                  ) {
                    return "Cannot select a future date";
                  }
                  return true;
                },
                isInRange: (value, { FromDate }) => {
                  if (value && isBefore(value, FromDate)) {
                    return "Needs to be a date after From date";
                  }
                  return true;
                },
                isNotSameDay: (value, { FromDate }) => {
                  if (value && FromDate && isEqual(value, FromDate)) {
                    return "Dates need to be different";
                  }
                  return true;
                },
              },
            }}
          />
        </div>
      </FormProvider>
    </div>
  );
};

export default CustomDateRangeSelectorForm;
