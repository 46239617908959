import React from "react";

import { range } from "lodash";

import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import FullDataTable from "@src/sharedComponents/Table/FullDataTable";
import { Cell, Row } from "@src/sharedComponents/Table/Types";

import { TableKey, tableKeys } from "./HoldingsTable";

import styles from "./HoldingsTable.module.css";

const LOADING_FILLER: Row<TableKey, Cell>[] = range(10).map((i) => {
  return {
    key: `${i}`,
    Name: { value: <Skeleton /> },
    Shares: { value: <Skeleton /> },
    Allocation: { value: <Skeleton /> },
    Price: { value: <Skeleton /> },
    "Cost Basis": { value: <Skeleton /> },
    Value: { value: <Skeleton /> },
  };
});

const HoldingsTableLoading = (): JSX.Element => {
  return (
    <div className={styles.container}>
      <FullDataTable
        isLoading={true}
        columns={tableKeys}
        rows={LOADING_FILLER}
        defaultRowsPerPage={10}
        defaultSortColumn={undefined}
        disableColumnSorting={tableKeys}
        emptyCell={<Skeleton />}
      />
    </div>
  );
};

export default HoldingsTableLoading;
