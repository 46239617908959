import React, { useCallback, useRef } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";

import { useAppearanceChanger } from "@fartherfinance/frontend/theme/ThemeProvider";

import toggleTheme from "../../Navbar/utils/toggleTheme";
import { DashboardForm } from "@src/multiCustodian/pages/Dashboard/Dashboard_Components/DashboardForms/Types";
import { captureException } from "@src/multiCustodian/services/tracking";
import logUserOut from "@src/multiCustodian/utils/logUserOut";
import Popover from "@src/sharedComponents/Popover/Popover";
import PopoverMenu from "@src/sharedComponents/PopoverMenu/PopoverMenu";
import { MCReduxAction, State } from "@src/store";

import styles from "./PopoverMenu.module.css";

interface Props {
  showPopover: boolean;
  setShowPopover: (b: boolean) => void;
}

const ClientPopoverMenu: React.FC<Props> = ({
  showPopover,
  setShowPopover,
}: Props) => {
  const { isAdvisor, ui_theme, brand, css_color_palettes } = useSelector(
    (state: State) => ({
      isAdvisor: state.main_Reducer.user.isAdvisor,
      ui_theme: state.main_Reducer.ui_theme,
      brand: state.main_Reducer.brand,
      css_color_palettes: state.main_Reducer.css_color_palettes,
    })
  );

  const changeAppearance = useAppearanceChanger();

  const dispatch = useDispatch<Dispatch<MCReduxAction>>();

  const history = useHistory();

  const clientRef = useRef<HTMLDivElement | null>(null);

  const { authenticationProvider } = useFlags();

  const handleClientClick = useCallback(() => {
    setShowPopover(true);
  }, [setShowPopover]);

  const handleClientClose = useCallback(() => {
    setShowPopover(false);
  }, [setShowPopover]);

  const setDrawerPanelContext = (formToShow: DashboardForm) => {
    dispatch({
      type: "SET_DRAWER_PANEL_CONTEXT",
      payload: {
        formToShow,
      },
    });
  };

  const enhanceHandlerWithOnCloseDropdown = (callback: () => void): void => {
    setShowPopover(false);
    callback();
  };

  const onLogout = async () => {
    if (isAdvisor === null) {
      const message =
        "Navbar.tsx, onLogout: isAdvisor is null while user is logged in.";
      const e = new Error(message);
      captureException(e);
      throw e;
    }

    logUserOut("Clicked logout in Navbar", history);
  };

  const navLinks = [
    {
      label: "Profile",
      isEnabled: true,
      onClick: () => setDrawerPanelContext("Investor Profile"),
    },
    {
      label: "Contact Info",
      isEnabled: true,
      onClick: () => setDrawerPanelContext("Contact Information"),
    },
    {
      label: "Additional Info",
      isEnabled: true,
      onClick: () => setDrawerPanelContext("Additional Information"),
    },
    {
      label: "Beneficiaries",
      isEnabled: true,
      onClick: () => setDrawerPanelContext("Beneficiaries"),
    },
    {
      label: "Affiliations",
      isEnabled: true,
      onClick: () => setDrawerPanelContext("Affiliations"),
    },
    {
      label: "Trusts",
      isEnabled: true,
      onClick: () => setDrawerPanelContext("Trusts"),
    },
    ...(authenticationProvider === "stytch" &&
    process.env.WEBAPP_EXACT_ENV !== "UAT"
      ? [
          {
            label: "Security & Access",
            isEnabled: true,
            onClick: () => setDrawerPanelContext("Security & Access"),
          },
        ]
      : []),
    {
      label: "Change Theme",
      isEnabled: true,
      onClick: () =>
        toggleTheme(
          ui_theme,
          brand,
          css_color_palettes,
          dispatch,
          changeAppearance
        ),
    },
    {
      label: "Log Out",
      isEnabled: true,
      onClick: onLogout,
    },
  ].map((navLink) => ({
    ...navLink,
    // @INFO: enhance each onClick handler with dropdown closing functionality
    onClick: (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      enhanceHandlerWithOnCloseDropdown(navLink.onClick);
    },
  }));

  const navLinksLastIndex = navLinks.length - 1;

  return (
    <Popover
      open={showPopover}
      onClose={handleClientClose}
      anchor={clientRef.current}
      transformOriginVertical={-5}
      popoverElement={
        <div ref={clientRef} className={styles.refContainer}>
          <ExpandMoreIcon
            className={styles.popoverIcon}
            onClick={handleClientClick}
          />
        </div>
      }
    >
      <PopoverMenu
        items={navLinks.slice(0, navLinksLastIndex)}
        bottomItem={navLinks[navLinksLastIndex]}
      />
    </Popover>
  );
};

export default ClientPopoverMenu;
