import { formatISO } from "date-fns";
import replace from "lodash/replace";

import { MarketplaceAssetType } from "@fartherfinance/frontend/api/AltAssetsService/Types";
import { PatchOpportunityRequest } from "@fartherfinance/frontend/api/Opportunities/requests/patchOpportunity";
import { PostOpportunityStatusRequest } from "@fartherfinance/frontend/api/Opportunities/requests/postOpportunityStatus";
import {
  MarketplaceOfferingInterestOpportunity,
  Opportunity,
  OpportunityCancelationReason,
  OpportunityClient,
  OpportunityCompletionReason,
  OpportunityOfferingInterest,
  OpportunitySnoozingPeriod,
  OpportunityStatus,
  OpportunityType,
} from "@fartherfinance/frontend/api/Opportunities/Types";

import { EnhancedAutocompleteOption } from "@src/multiCustodian/pages/Advisor/common";
import {
  FormOpportunityStatus,
  yesNoOptions,
  type yesNoValues,
} from "@src/multiCustodian/pages/Advisor/Opportunities/common";
import {
  getOpportunityClientName,
  mapLabelToValue,
  mapValueToLabel,
  opportunityCancelationReasonMap,
  opportunityCompletionReasonMap,
  opportunitySnoozingPeriodMap,
} from "@src/multiCustodian/pages/Advisor/Opportunities/utils";
import { captureException } from "@src/multiCustodian/services/tracking";

type CommonFormData = Pick<Opportunity, "priority" | "dueDate">;
type StatusOnlyFormData = {
  status: "NOT_STARTED" | "IN_PROGRESS" | "AWAITING_RESPONSE";
};
type SnoozedFormData = {
  status: "SNOOZED";
  period: EnhancedAutocompleteOption<OpportunitySnoozingPeriod>;
  snoozeDetails: string;
  snoozeExtraDetails: string | null;
};
type CanceledFormData = {
  status: "CANCELED";
  cancelReason: string;
  cancelDetails?: string;
  cancelExtraDetails: string | null;
};
type CompletedStandardFormData = {
  completeFormType: "STANDARD";
  status: "COMPLETED";
  assetWon: typeof yesNoValues[number];
  amount?: number;
  expectedClearanceDate?: Date;
  completeReason?: string;
  completeDetails?: string;
  completeExtraDetails: string | null;
};
type CompletedOfferingFormData = {
  completeFormType: "OFFERING";
  status: "COMPLETED";
  assetWon: typeof yesNoValues[number];
  revenueWon?: typeof yesNoValues[number];
  amount?: number;
  expectedClearanceDate?: Date;
  completeExtraDetails: string | null;
};

export type FormData = CommonFormData &
  (
    | StatusOnlyFormData
    | SnoozedFormData
    | CanceledFormData
    | CompletedStandardFormData
    | CompletedOfferingFormData
  );

export const mapOpportunityToFormData = ({
  status,
  type,
  priority,
  dueDate,
  statusAttributes,
}: Opportunity): FormData => {
  const common = {
    priority,
    dueDate,
  };

  if (status === "SNOOZED") {
    const { period, details, extraDetails } = statusAttributes.value;

    return {
      ...common,
      status,
      snoozeDetails: details,
      snoozeExtraDetails: extraDetails,
      period: {
        label: mapValueToLabel(opportunitySnoozingPeriodMap, period),
        value: period,
      },
    };
  }

  if (status === "CANCELED") {
    const { reason, extraDetails } = statusAttributes.value;

    return {
      ...common,
      status,
      cancelDetails:
        reason === OpportunityCancelationReason.enum.OtherCancelReason
          ? statusAttributes.value.details
          : undefined,
      cancelExtraDetails: extraDetails,
      cancelReason: mapValueToLabel(opportunityCancelationReasonMap, reason),
    };
  }

  if (isMarketplaceOfferingOpportunityType(type)) {
    if (status === "COMPLETED_WITH_ASSETS_WON") {
      const { amount, expectedClearanceDate, extraDetails } =
        statusAttributes.value;

      return {
        ...common,
        assetWon: yesNoOptions.yes,
        revenueWon: yesNoOptions.yes,
        completeFormType: "OFFERING",
        status: "COMPLETED",
        amount,
        expectedClearanceDate,
        completeExtraDetails: extraDetails,
      };
    }

    if (status === "COMPLETED_NO_ASSETS_WON") {
      const { reason, extraDetails } = statusAttributes.value;

      if (reason === OpportunityCompletionReason.enum.ClientNotInterested) {
        return {
          ...common,
          assetWon: yesNoOptions.yes,
          revenueWon: yesNoOptions.no,
          completeFormType: "OFFERING",
          status: "COMPLETED",
          completeExtraDetails: extraDetails,
        };
      }

      if (reason === OpportunityCompletionReason.enum.OtherReason) {
        return {
          ...common,
          assetWon: yesNoOptions.no,
          completeFormType: "OFFERING",
          status: "COMPLETED",
          completeExtraDetails: extraDetails,
        };
      }
    }
  }

  if (status === "COMPLETED_WITH_ASSETS_WON") {
    const { amount, expectedClearanceDate, extraDetails } =
      statusAttributes.value;

    return {
      ...common,
      assetWon: yesNoOptions.yes,
      completeFormType: "STANDARD",
      status: "COMPLETED",
      amount,
      expectedClearanceDate,
      completeExtraDetails: extraDetails,
    };
  }

  if (status === "COMPLETED_NO_ASSETS_WON") {
    const { reason, extraDetails } = statusAttributes.value;

    return {
      ...common,
      assetWon: yesNoOptions.no,
      completeFormType: "STANDARD",
      status: "COMPLETED",
      completeDetails:
        reason === OpportunityCompletionReason.enum.OtherReason
          ? statusAttributes.value.details
          : undefined,
      completeExtraDetails: extraDetails,
      completeReason: mapValueToLabel(opportunityCompletionReasonMap, reason),
    };
  }

  return {
    ...common,
    status,
  };
};

export const mapFormDataToPayload = (
  formData: FormData
): PatchOpportunityRequest | PostOpportunityStatusRequest => {
  const { status, priority, dueDate } = formData;

  const common = {
    status,
    priority,
    dueDate: {
      value: dueDate && formatISO(dueDate, { representation: "date" }),
    },
  };

  if (
    status === "NOT_STARTED" ||
    status === "IN_PROGRESS" ||
    status === "AWAITING_RESPONSE"
  ) {
    return common;
  }

  if (status === "SNOOZED") {
    const { period, snoozeDetails, snoozeExtraDetails } = formData;

    return {
      ...common,
      status,
      period: period.value,
      details: snoozeDetails,
      extraDetails: snoozeExtraDetails,
    };
  }

  if (status === "CANCELED") {
    const { cancelReason, cancelDetails, cancelExtraDetails } = formData;

    return {
      ...common,
      status,
      reason: mapLabelToValue(opportunityCancelationReasonMap, cancelReason),
      details: cancelDetails,
      extraDetails: cancelExtraDetails,
    };
  }

  if (status === "COMPLETED" && formData.completeFormType === "STANDARD") {
    const {
      assetWon,
      amount,
      expectedClearanceDate,
      completeReason,
      completeDetails,
      completeExtraDetails,
    } = formData;

    if (assetWon === yesNoOptions.yes) {
      return {
        ...common,
        status: "COMPLETED_WITH_ASSETS_WON",
        amount,
        expectedClearanceDate:
          expectedClearanceDate &&
          formatISO(expectedClearanceDate, { representation: "date" }),
        extraDetails: completeExtraDetails,
      };
    }

    return {
      ...common,
      status: "COMPLETED_NO_ASSETS_WON",
      reason:
        completeReason &&
        mapLabelToValue(opportunityCompletionReasonMap, completeReason),
      details: completeDetails,
      extraDetails: completeExtraDetails,
    };
  }

  if (status === "COMPLETED" && formData.completeFormType === "OFFERING") {
    const {
      assetWon,
      revenueWon,
      amount,
      expectedClearanceDate,
      completeExtraDetails,
    } = formData;

    if (assetWon === yesNoOptions.yes && revenueWon === yesNoOptions.yes) {
      return {
        ...common,
        status: "COMPLETED_WITH_ASSETS_WON",
        amount,
        expectedClearanceDate:
          expectedClearanceDate &&
          formatISO(expectedClearanceDate, { representation: "date" }),
        extraDetails: completeExtraDetails,
      };
    }

    if (assetWon === yesNoOptions.yes && revenueWon === yesNoOptions.no) {
      return {
        ...common,
        status: "COMPLETED_NO_ASSETS_WON",
        reason: OpportunityCompletionReason.enum.ClientNotInterested,
        extraDetails: completeExtraDetails,
      };
    }

    return {
      ...common,
      status: "COMPLETED_NO_ASSETS_WON",
      reason: OpportunityCompletionReason.enum.OtherReason,
      details: "",
      extraDetails: completeExtraDetails,
    };
  }

  const error = new Error(
    "Opportunity details form: unknown form data variation in 'mapFormDataToPayload'"
  );
  captureException(error);
  throw error;
};

export const areStatusesEqual = (
  opportunityStatus: OpportunityStatus,
  formOpportunityStatus: FormOpportunityStatus
): boolean => {
  if (
    (opportunityStatus === "COMPLETED_WITH_ASSETS_WON" ||
      opportunityStatus === "COMPLETED_NO_ASSETS_WON") &&
    formOpportunityStatus === "COMPLETED"
  ) {
    return true;
  }

  return opportunityStatus === formOpportunityStatus;
};

export const getOpportunityOfferingDescription = (
  client: OpportunityClient,
  assetType: MarketplaceAssetType,
  interestType: OpportunityOfferingInterest
): string => {
  const clientName = getOpportunityClientName(client);
  const assetTypeLabel = replace(assetType, "_", " ");
  const howToGetStarted =
    "Click on the listing below to learn how to get started with this offering.";

  return interestType === "INTEREST"
    ? `${clientName} is interested in an ${assetTypeLabel} offering (details below). ${howToGetStarted}`
    : `${clientName} clicked on a link to schedule a call with you to discuss an ${assetTypeLabel} offering (details below). ${howToGetStarted}`;
};

export const isMarketplaceOfferingOpportunityType = (
  opportunityType: OpportunityType
): opportunityType is Extract<
  OpportunityType,
  "INSURANCE_INTEREST" | "LENDING_INTEREST" | "ALTERNATIVE_ASSET_INTEREST"
> => {
  return (
    opportunityType === OpportunityType.enum.INSURANCE_INTEREST ||
    opportunityType === OpportunityType.enum.LENDING_INTEREST ||
    opportunityType === OpportunityType.enum.ALTERNATIVE_ASSET_INTEREST
  );
};

export const isMarketplaceOfferingOpportunity = (
  opportunity: Opportunity
  // @ts-expect-error MarketplaceOfferingInterestOpportunity type is missing the 'status' and 'statusAttributes' fields as per the Opportunity intersection
  // thus TS complains that a type predicate's type be assignable to its parameter's type
): opportunity is MarketplaceOfferingInterestOpportunity => {
  return isMarketplaceOfferingOpportunityType(opportunity.type);
};
