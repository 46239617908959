import React from "react";

import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";

import { Button } from "@src/yellowstone/components";
import { pxToSpacing } from "@src/yellowstone/theme";

import { useStyles } from "./ProposalActions.styles";

interface ProposalActionsProps {
  isProposalConfirmable: boolean;
  isLoading: boolean;
  onSkip: () => void;
  onNeedsReview: () => void;
  onConfirm: () => void;
}

export const ProposalActions: React.FC<ProposalActionsProps> = ({
  isProposalConfirmable,
  isLoading,
  onSkip,
  onNeedsReview,
  onConfirm,
}) => {
  const { classes } = useStyles();

  return (
    <Stack direction="row" justifyContent="flex-end" spacing={pxToSpacing(20)}>
      {!isProposalConfirmable ? (
        <Typography className={classes.info}>
          This proposal is no longer confirmable.
        </Typography>
      ) : (
        <>
          <Button
            variant="outlined"
            size="large"
            disabled={isLoading}
            disableTextTransform
            onClick={onSkip}
          >
            Skip
          </Button>
          <Button
            variant="contained"
            size="large"
            disabled={isLoading}
            disableTextTransform
            onClick={onNeedsReview}
          >
            Needs Review
          </Button>
          <Button
            color="success"
            variant="contained"
            size="large"
            disabled={isLoading}
            disableTextTransform
            onClick={onConfirm}
          >
            Confirm Trade
          </Button>
        </>
      )}
    </Stack>
  );
};
