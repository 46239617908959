import React, { useState } from "react";

import { useSelector } from "react-redux";

import { State } from "../../../../../store";
import { SetScreenAndTransferType, TransferType } from "../Types";

import "./css.css";

interface Props {
  setCurScreen: SetScreenAndTransferType;
}

const retirementOptions: TransferType[] = [
  "Traditional 401(k)",
  "Roth 401(k)",
  "Solo 401(k)",
  "Simple 401(k)",
  "403(b)",
];

const RetirementTransfer = (props: Props): JSX.Element => {
  const [hoveringOver, setHoveringOver] = useState<number>(-1);

  const { chevron_down, left_arrow } = useSelector((state: State) => ({
    chevron_down: state.main_Reducer.brand.current.images.chevron_down,
    left_arrow: state.main_Reducer.brand.current.images.left_arrow,
  }));

  return (
    <div className="Transfers__container">
      <div className="Transfers__content_container">
        <div
          className="Transfers__navlink"
          onClick={() => props.setCurScreen({ screen: "transferType" })}
        >
          <div className="Transfers__back_button_container">
            <img src={left_arrow} />
            <div className="Transfers__back_button">Back</div>
          </div>
        </div>

        <p className="Transfers__title">Select your Retirement Account</p>

        <p className="Transfers__text">
          Great, what type of retirement account do you want to roll over?
        </p>

        {retirementOptions.map((opt, idx) => {
          return (
            <div
              key={opt}
              className="Transfers__navlink"
              onClick={() =>
                props.setCurScreen({
                  screen: "Employer Retirement Account Instructions",
                  transferType: opt,
                })
              }
            >
              <div
                className="Transfers__choices_div"
                onMouseEnter={() => setHoveringOver(idx)}
                onMouseLeave={() => setHoveringOver(-1)}
                style={{
                  backgroundColor:
                    hoveringOver === idx
                      ? "var(--item_div_hover_color_1)"
                      : "var(--background_div_color_1)",
                }}
              >
                <p className="Transfers__text_bold">{opt}</p>
                <img src={chevron_down} className="Transfers__arrow_img" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RetirementTransfer;
