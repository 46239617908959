import React, { PropsWithChildren } from "react";

import { Redirect } from "react-router-dom";

interface Props {
  flag: boolean;
  redirectPath: string;
}

const RedirectWithFlag: React.FC<PropsWithChildren<Props>> = ({
  flag,
  redirectPath,
  children,
}) => {
  if (flag) {
    return <>{children}</>;
  }

  return <Redirect to={redirectPath} exact push={false} />;
};

export default RedirectWithFlag;
