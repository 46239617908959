import React, { useCallback, useRef } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { Link, useParams } from "react-router-dom";

import useClientDashboard, {
  ClientId,
} from "@fartherfinance/frontend/api/Dashboard/hooks/useClientDashboard";

import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import Popover from "@src/sharedComponents/Popover/Popover";

import styles2 from "./ClientLoginAdvisorPopoverMenu.module.css";
import styles from "./PopoverMenu.module.css";

interface Props {
  showPopover: boolean;
  setShowPopover: (b: boolean) => void;
}

const ClientLoginAdvisorPopoverMenu: React.FC<Props> = ({
  showPopover,
  setShowPopover,
}: Props) => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const auth = useRequestAuth();

  const dashboard = useClientDashboard(clientId, auth);

  const advisorRef = useRef<HTMLDivElement | null>(null);

  const handleAdvisorClick = useCallback(() => {
    setShowPopover(true);
  }, [setShowPopover]);

  const handleAdvisorClose = useCallback(() => {
    setShowPopover(false);
  }, [setShowPopover]);

  const advisorLocation = dashboard.data?.advisor.location
    ? dashboard.data?.advisor.location
    : dashboard.data?.advisor.hometown;

  return (
    <Popover
      open={showPopover}
      onClose={handleAdvisorClose}
      anchor={advisorRef.current}
      anchorOriginVertical={"top"}
      transformOriginVertical={210}
      popoverElement={
        <div ref={advisorRef} className={styles.refContainer}>
          <ExpandMoreIcon
            className={styles.popoverIcon}
            onClick={handleAdvisorClick}
          />
        </div>
      }
    >
      <div className={styles2.dropdownMenu}>
        <div className={styles2.topHalf}>
          <div className={styles2.header}>
            {dashboard.data
              ? `${dashboard.data?.advisor.name.first} ${dashboard.data.advisor.name.last}`
              : "..."}
          </div>

          {advisorLocation && (
            <div className={styles2.subHeader}>{advisorLocation}</div>
          )}

          {dashboard.data?.advisor.email && (
            <a
              className={styles2.text}
              href={`mailto:${dashboard.data.advisor.email}`}
            >
              {dashboard.data.advisor.email}
            </a>
          )}
        </div>

        <Link
          className={styles2.row}
          to={`/Client/${clientId ?? "none"}/Advisor`}
        >
          View profile
        </Link>

        <a
          className={styles2.row}
          href={dashboard.data?.advisor.calendarUrl ?? "#"}
          target="_blank"
          rel="noreferrer"
          onClick={handleAdvisorClose}
        >
          <div className={styles2.flexStart}>
            Book a session <span>&nbsp;</span>{" "}
            <OpenInNew className={styles2.icon} />
          </div>
        </a>
      </div>
    </Popover>
  );
};

export default ClientLoginAdvisorPopoverMenu;
