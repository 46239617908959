import React, { useEffect, useReducer, useRef } from "react";

import {
  BreakdownItem,
  PortfolioAnalysis,
  type PortfolioAssetCategories,
  type PortfolioAssetClasses,
  Sleeve,
} from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";
import { SleeveId } from "@fartherfinance/frontend/api/Types";

import AssetCategoryView from "./SleeveAssetCategoryView";
import AssetView from "./SleeveAssetView";
import SleeveView from "./SleeveView";

import styles from "./SleeveTableViews.module.css";

type SleeveAssetCategory = typeof PortfolioAssetCategories[number];
type SleeveAssetClass = typeof PortfolioAssetClasses[number];

type State =
  | { tableView: "Sleeve" }
  | {
      tableView: "AssetCategory";
      category: SleeveAssetCategory;
    }
  | {
      tableView: "Asset";
      asset: BreakdownItem;
      assetClass: SleeveAssetClass;
      category: SleeveAssetCategory;
    };

type Action =
  | { type: "ViewSleeve" }
  | {
      type: "ViewAssetCategory";
      category: SleeveAssetCategory;
    }
  | {
      type: "ViewAsset";
      asset: BreakdownItem;
      assetClass: SleeveAssetClass;
      category: SleeveAssetCategory;
    };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "ViewSleeve":
      return { tableView: "Sleeve" };
    case "ViewAssetCategory":
      return {
        tableView: "AssetCategory",
        category: action.category,
      };
    case "ViewAsset":
      return {
        tableView: "Asset",
        asset: action.asset,
        assetClass: action.assetClass,
        category: action.category,
      };
    default:
      return state;
  }
};

interface Props {
  sleeve: Sleeve;
  sleeveAnalysis: PortfolioAnalysis;
}

const SleeveTableViews = ({ sleeve, sleeveAnalysis }: Props): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, { tableView: "Sleeve" });
  const sleeveIdRef = useRef<SleeveId | null>(null);

  const sleeveId = sleeve.sleeveId;

  const getAssetClassesOfCategory = (category: SleeveAssetCategory) => {
    return (sleeveAnalysis.portfolioAssetClassBreakdown ?? []).filter(
      (assetClassObj) => {
        const data = Object.values(assetClassObj)[0];
        return data.category === category;
      }
    );
  };

  useEffect(() => {
    if (sleeveIdRef.current !== sleeveId) {
      dispatch({ type: "ViewSleeve" });
      sleeveIdRef.current = sleeveId;
    }
  }, [sleeveId]);

  switch (state.tableView) {
    case "Sleeve":
      return (
        <div className={styles.tableContainer}>
          <SleeveView
            sleeve={sleeve}
            sleeveAnalysis={sleeveAnalysis}
            setToCategoryView={(category: SleeveAssetCategory) =>
              dispatch({ type: "ViewAssetCategory", category: category })
            }
          />
        </div>
      );

    case "AssetCategory":
      return (
        <div className={styles.tableContainer}>
          <AssetCategoryView
            sleeve={sleeve}
            category={state.category}
            onBack={() => dispatch({ type: "ViewSleeve" })}
            assetClasses={getAssetClassesOfCategory(state.category)}
            setAsset={(
              assetClass: SleeveAssetClass,
              breakdownItem: BreakdownItem
            ) => {
              dispatch({
                type: "ViewAsset",
                assetClass: assetClass,
                category: state.category,
                asset: breakdownItem,
              });
            }}
          />
        </div>
      );

    case "Asset":
      return (
        <div className={styles.tableContainer}>
          <AssetView
            sleeve={sleeve}
            assetClass={state.assetClass}
            asset={state.asset}
            onBack={() =>
              dispatch({
                type: "ViewAssetCategory",
                category: state.category,
              })
            }
          />
        </div>
      );

    default:
      const _x: never = state;
      return <div>Error</div>;
  }
};

export default SleeveTableViews;
