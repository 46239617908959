import React, { useEffect } from "react";

import ExitButton from "@src/multiCustodian/components/Client/ExitButton";
import PlaidRelink from "@src/multiCustodian/components/Client/PlaidRelink/Relink";
import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import { trackEvent } from "@src/multiCustodian/services/tracking";

const Relink = (): JSX.Element => {
  useEffect(() => {
    trackEvent({ name: "Client Open Plaid Relink" });
  }, []);

  return (
    <BaseLayout>
      <ExitButton />

      <PlaidRelink />
    </BaseLayout>
  );
};

export default Relink;
