import React, { ComponentProps, PropsWithChildren } from "react";

import * as Sentry from "@sentry/react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

import { LocalStorageKeys } from "@src/multiCustodian/components/Login/constants";
import useCheckSavedAdvisorMagicSession from "@src/multiCustodian/hooks/Advisor/useCheckSavedAdvisorMagicSession";
import useStopImpersonation from "@src/multiCustodian/hooks/Advisor/useStopImpersonation";
import useSyncAdvisorLogout from "@src/multiCustodian/hooks/Advisor/useSyncAdvisorLogout";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useMagicRefreshToken from "@src/multiCustodian/hooks/useMagicRefreshToken";
import { State } from "@src/store";

import { FullScreenLoader } from "./UtilityComponents";

const SentryRoute = Sentry.withSentryRouting(Route);

export default function PrivateAdvisorRoute({
  children,
  ...rest
}: PropsWithChildren<ComponentProps<typeof Route>>) {
  const auth = useAdvisorRequestAuth();
  const loginState = useCheckSavedAdvisorMagicSession();

  const isAdvisor = useSelector(
    (state: State) => state.main_Reducer.user.isAdvisor
  );

  useMagicRefreshToken(isAdvisor);

  useSyncAdvisorLogout();

  useStopImpersonation();

  /* If we do not have auth info then wait and see if Magic is still grabbing 
     it from local storage */
  if (auth === null && loginState.isLoading === true) {
    return <FullScreenLoader />;
  }

  if (auth === null) {
    return (
      <SentryRoute
        {...rest}
        render={({ location }) => {
          localStorage.setItem(
            LocalStorageKeys.redirectTo,
            `${location.pathname}${location.search}`
          );
          return (
            <Redirect
              to={{
                pathname: `/AdvisorLogin`,
                search: `goto=${location.pathname}`,
                state: { from: location },
              }}
            />
          );
        }}
      />
    );
  }

  return <SentryRoute {...rest} render={() => children} />;
}
