import type {
  GetOpportunitiesQueryParams,
  OpportunitiesFilterQueryParams,
} from "@fartherfinance/frontend/api/Opportunities/requests/getOpportunities";
import { OpportunityStatus } from "@fartherfinance/frontend/api/Opportunities/Types";
import { SortOrder } from "@fartherfinance/frontend/api/Types";

export const OPPORTUNITIES_DEFAULT_PAGE = 1;
const OPPORTUNITIES_DEFAULT_PAGE_SIZE = 10;

export const OPPORTUNITIES_DEFAULT_FILTERING_QUERY_PARAMS: OpportunitiesFilterQueryParams =
  {
    filterByStatus: [
      "NOT_STARTED",
      "IN_PROGRESS",
      "AWAITING_RESPONSE",
      "SNOOZED",
    ],
  };

export const OPPORTUNITIES_DEFAULT_QUERY_PARAMS: GetOpportunitiesQueryParams = {
  page: OPPORTUNITIES_DEFAULT_PAGE,
  pageSize: OPPORTUNITIES_DEFAULT_PAGE_SIZE,
  sort: `${SortOrder.Descending}updatedTs`,
  ...OPPORTUNITIES_DEFAULT_FILTERING_QUERY_PARAMS,
};

export const yesNoOptions = { yes: "Yes", no: "No" } as const;
export const yesNoValues = Object.values(yesNoOptions);

export type FormOpportunityStatus =
  | "NOT_STARTED"
  | "IN_PROGRESS"
  | "AWAITING_RESPONSE"
  | "SNOOZED"
  | "CANCELED"
  | "COMPLETED";
export const opportunityStatusFormOptions: FormOpportunityStatus[] =
  OpportunityStatus.options
    .filter((status) => !status.includes("COMPLETED"))
    .map((status) => status as FormOpportunityStatus)
    .concat("COMPLETED");
