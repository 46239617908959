import React, { PropsWithChildren } from "react";

import { Popover as MuiPopover, popoverClasses } from "@mui/material";

interface Props {
  open: boolean;
  onClose: () => void;
  anchor: HTMLDivElement | null;
  popoverElement: React.ReactNode;
  anchorOriginVertical?: "top" | "center" | "bottom" | number;
  anchorOriginHorizontal?: "left" | "center" | "right" | number;
  transformOriginVertical?: "top" | "center" | "bottom" | number;
  transformOriginHorizontal?: "left" | "center" | "right" | number;
}

export default function Popover(props: PropsWithChildren<Props>) {
  return (
    <div>
      <>{props.popoverElement}</>

      <MuiPopover
        sx={{
          [`& .${popoverClasses.paper}`]: {
            backgroundColor: "transparent",
          },
        }}
        open={props.open}
        anchorEl={props.anchor}
        onClose={props.onClose}
        anchorOrigin={{
          vertical: props.anchorOriginVertical ?? "bottom",
          horizontal: props.anchorOriginHorizontal ?? "left",
        }}
        transformOrigin={{
          vertical: props.transformOriginVertical ?? "top",
          horizontal: props.transformOriginHorizontal ?? "left",
        }}
      >
        {props.children}
      </MuiPopover>
    </div>
  );
}
