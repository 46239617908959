import React from "react";

import { useFlags } from "launchdarkly-react-client-sdk";

import useAccountBalance from "@fartherfinance/frontend/api/PerformanceGroups/hooks/useAccountBalance";
import { ClientId, FartherAccountId } from "@fartherfinance/frontend/api/Types";

import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";
import toUSD from "@src/utils/toUSD";

import styles from "./DeleteAccountModal.module.css";

interface Props {
  clientId: ClientId;
  accountId: FartherAccountId;
}

const AccountBalance = (props: Props): JSX.Element => {
  const auth = useAdvisorRequestAuth();

  const config =
    auth?.advisorId && auth?.jwt
      ? {
          clientId: props.clientId,
          advisorId: auth?.advisorId,
          jwt: auth?.jwt,
        }
      : null;

  const { enablePerformanceGroups } = useFlags();

  const balance = useAccountBalance(
    props.clientId,
    props.accountId,
    enablePerformanceGroups ? config : null
  );

  if (!enablePerformanceGroups || balance.hasError) {
    return (
      <div className={styles.tableBodyRightBottom}>Unable to retrieve</div>
    );
  }

  if (balance.isLoading) {
    return <Skeleton width={90} />;
  }

  if (balance.data === null) {
    return (
      <Tooltip
        tooltipText={
          "This account appears to have a $0 balance. If you believe this is inaccurate, please contact your advisor."
        }
        placement="top"
      >
        <div className={styles.tableBodyRightBottom}>{"$0*"}</div>
      </Tooltip>
    );
  }

  return (
    <div className={styles.tableBodyRightBottom}>{toUSD(balance.data)}</div>
  );
};

export default AccountBalance;
