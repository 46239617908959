import { QueryClient } from "@tanstack/react-query";
import ms from "ms";

/**
 * CacheTime: The duration until inactive queries will be removed from the
 * cache. This defaults to 5 minutes. Queries transition to the inactive
 * state as soon as there are no observers registered, so when all components
 * which use that query have unmounted.
 */
const DefaultCacheTime = ms("5minutes");

// https://tanstack.com/query/v4/docs/react/guides/ssr#staleness-is-measured-from-when-the-query-was-fetched-on-the-server
/**
 * StaleTime: The duration until a query transitions from fresh to stale. As
 * long as the query is fresh, data will always be read from the cache only -
 * no network request will happen! If the query is stale (which per default is:
 * instantly), you will still get data from the cache, but a background refetch
 * can happen under [certain conditions](https://react-query.tanstack.com/guides/caching).
 */
const DefaultStaleTime = ms("5minutes");

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: DefaultCacheTime,
      staleTime: DefaultStaleTime,
      refetchOnReconnect: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});
