import React from "react";

import { format } from "date-fns";

import usePortfolioAnalysisV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/usePortfolioAnalysisV2";
import usePortfolioV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/usePortfolioV2";
import { PortfolioId } from "@fartherfinance/frontend/api/Types";

import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import finScoreToLabel from "@src/multiCustodian/utils/finScoreToLabel";
import Chip from "@src/sharedComponents/Chip/Chip";
import BorderedList from "@src/sharedComponents/List/BorderedList";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./ModelDetailsTable.module.css";
interface Props {
  portfolioId: PortfolioId;
}

const ModelDetailsTable = ({ portfolioId }: Props): JSX.Element => {
  const auth = useRequestAuth();
  const portfolio = usePortfolioV2(portfolioId, auth);
  const portfolioAnalysis = usePortfolioAnalysisV2(portfolioId, auth);

  if (portfolio.isLoading) {
    return (
      <BorderedList
        rows={[
          {
            left: "Risk Level",
            right: <Skeleton width={120} />,
          },
          {
            left: "Tax Type",
            right: <Skeleton width={120} />,
          },
          {
            left: "Created",
            right: <Skeleton width={120} />,
          },
        ]}
      />
    );
  }

  if (portfolio.hasError) {
    return (
      <div className={styles.container}>
        <p className={styles.textLeft}>Error</p>
      </div>
    );
  }

  const riskLabel = finScoreToLabel(
    portfolioAnalysis.data?.finScore ?? undefined
  );

  const taxLabel = portfolio.data.model.taxType
    ? portfolio.data.model.taxType === "tax_exempt"
      ? "Tax-advantaged"
      : portfolio.data.model.taxType === "taxable"
      ? "Taxable"
      : "Any"
    : "N/A";

  const createTime = portfolio.data.model.createTime
    ? format(portfolio.data.model.createTime, "M/d/yyyy")
    : "N/A";

  return (
    <div className={styles.container}>
      <BorderedList
        rows={[
          {
            left: "Risk Level",
            right: <Chip label={riskLabel} />,
          },
          {
            left: "Tax Type",
            right: <Chip label={taxLabel} />,
          },
          {
            left: "Created",
            right: <div className={styles.dateText}>{createTime}</div>,
          },
        ]}
      />
    </div>
  );
};

export default ModelDetailsTable;
