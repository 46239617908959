import React, { ReactElement } from "react";

import { Link } from "react-router-dom";

import { ClientId, FartherAccountId } from "@fartherfinance/frontend/api/Types";

import { AccountsPath } from "@src/config/routing/RouterPaths";
import toUSD from "@src/utils/toUSD";

import styles from "./SetGoal.module.css";

type PropsPartial = {
  clientId: ClientId;
  accountId: FartherAccountId;
};

type Props = PropsPartial &
  (
    | {
        currentTargetGoal: null;
      }
    | { accountBalance: number | null; currentTargetGoal: number }
  );

const SetGoalButton = (props: Props): ReactElement => {
  const linkText =
    props.currentTargetGoal === null
      ? "Set Goal →"
      : `${
          props.accountBalance ? toUSD(props.accountBalance) : "Unknown balance"
        } / ${toUSD(props.currentTargetGoal)}`;

  return (
    <>
      <Link
        to={`/Client/${props.clientId}/${AccountsPath}/Managed/${props.accountId}/Summary/SetTargetGoal`}
        className={styles.text}
      >
        {linkText}
      </Link>
    </>
  );
};

export default SetGoalButton;
