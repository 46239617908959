import React, { useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import { Box, Collapse, Fade, Stack, Typography } from "@mui/material";

import useUpdateTask from "@fartherfinance/frontend/api/Tasks/hooks/useUpdateTask";
import { Task } from "@fartherfinance/frontend/api/Tasks/Types";

import { isTaskFieldEditingRestricted } from "../../../utils";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { trackEvent } from "@src/multiCustodian/services/tracking";
import IconButton from "@src/sharedComponents/IconButton/IconButton";
import Textarea from "@src/sharedComponents/Textarea/Textarea";
import { extractAxiosErrorMessage } from "@src/utils/axios";

import styles from "./TaskDescription.module.css";

interface TaskDescriptionProps {
  task: Task;
}

export default function TaskDescription({
  task,
}: TaskDescriptionProps): JSX.Element {
  const [isHovering, setIsHovering] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [descriptionValue, setDescriptionValue] = useState<string | null>(
    task.description
  );

  const auth = useAdvisorRequestAuth();
  const callUpdateTask = useUpdateTask(auth);
  const statusNotification = useStatusNotification();

  const handleUpdateTask = async (): Promise<void> => {
    try {
      setIsEditingDescription(false);
      await callUpdateTask({
        taskId: task.id,
        request: {
          description: {
            value: descriptionValue,
          },
        },
      });

      trackEvent({ name: "Advisor Tasks Update" });
    } catch (error) {
      // INFO: rollback to the initial value
      setDescriptionValue(task.description);
      statusNotification(
        extractAxiosErrorMessage(error, "Failed to update task."),
        "Error"
      );
    }
  };

  const handleMouseEnter = (): void => {
    setIsHovering(true);
  };

  const handleMouseLeave = (): void => {
    setIsHovering(false);
  };

  const handleSetEditingMode = (): void => {
    setIsEditingDescription(true);
  };

  const handleCancelEditingMode = (): void => {
    setDescriptionValue(task.description);
    setIsEditingDescription(false);
    setIsHovering(false);
  };

  const handleDescriptionValue = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    setDescriptionValue(event.target.value);
  };

  return (
    <Stack className={styles.container}>
      {isEditingDescription ? (
        <Textarea
          textareaClassName={styles.textarea}
          value={descriptionValue ?? ""}
          onChange={handleDescriptionValue}
          maxRows={20}
        />
      ) : (
        <Stack
          direction="row"
          alignItems="center"
          gap="5px"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Typography className={styles.description}>
            {descriptionValue}
          </Typography>
          {isTaskFieldEditingRestricted("description", task) === false && (
            <Fade in={isHovering}>
              <Box className={styles.editIconContainer}>
                <IconButton
                  onClick={handleSetEditingMode}
                  IconComponent={EditIcon}
                />
              </Box>
            </Fade>
          )}
        </Stack>
      )}
      <Collapse in={isEditingDescription}>
        <Stack
          className={styles.updateActions}
          direction="row"
          justifyContent="flex-end"
          spacing={1}
        >
          <Button
            variant="outlined"
            buttonType="primary"
            text="Cancel"
            onClick={handleCancelEditingMode}
          />
          <Button
            variant="contained"
            buttonType="primary"
            text="Save"
            onClick={handleUpdateTask}
          />
        </Stack>
      </Collapse>
    </Stack>
  );
}
