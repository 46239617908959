import { Relationship, relationshipOptions } from "@src/constants/relationship";

export const getRelationTypeFromId = (
  relationTypeId: number | undefined
): Relationship | undefined => {
  if (relationTypeId === undefined) {
    return undefined;
  }

  const relationType = relationshipOptions[relationTypeId - 1]; // relationTypeId should be 1 - 19

  if (relationType === undefined) {
    throw new Error(
      "relationTypeId index is out of range of relationshipOptions"
    ); // 19 is the limit as there are only 19 options and it will be 1 based
  }

  return relationType;
};
