import { AssetList } from "../Types";

const cogentAssets: AssetList = {
  dark: {
    logoSymbol: "/src/assets/svg/cogent_symbol_dark.svg",
    logoWordmark: "/src/assets/png/cogent_wordmark_dark.png",
  },
  light: {
    logoSymbol: "/src/assets/svg/cogent_symbol_light.svg",
    logoWordmark: "/src/assets/png/cogent_wordmark_light.png",
  },
} as const;

export default cogentAssets;
