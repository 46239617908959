import { useMemo } from "react";

import useEquivalentSecurityLists from "@fartherfinance/frontend/api/EquivalentSecurities/hooks/useEquivalentSecurityLists";
import useSecuritiesBySecurityIds from "@fartherfinance/frontend/api/SecurityMaster/hooks/useSecuritiesBySecurityIds";
import { HookResult } from "@fartherfinance/frontend/api/Types";

import { EquivalentSecurityListWithInfo } from "../../Admin/EquivalentSecurities/useEquivalentSecurityListMembersWithInfo";
import { isTickerIdentifier } from "../../Admin/EquivalentSecurities/utils";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

export default function useEquivalentSecurityAllMembersWithInfo(): HookResult<
  EquivalentSecurityListWithInfo[]
> {
  const auth = useRequestAuth();
  const listsRequest = useEquivalentSecurityLists(auth);

  const securityIds = useMemo(() => {
    if (listsRequest.data === undefined) {
      return [];
    }

    return listsRequest.data.flatMap((list) =>
      list.members.map((m) => m.securityId)
    );
  }, [listsRequest.data]);

  const securitiesRequest = useSecuritiesBySecurityIds(
    securityIds,
    securityIds.length > 0 ? auth : null
  );

  const lists = useMemo(() => {
    if (
      listsRequest.data === undefined ||
      securitiesRequest.data === undefined
    ) {
      return [] as EquivalentSecurityListWithInfo[];
    }

    // add ticker and displayName (security description), for display, to obj with securityId in members array
    return listsRequest.data.map((list) => ({
      ...list,
      members: list.members.map((m) => {
        const security = securitiesRequest.data.find(
          (s) => s.id === m.securityId
        );

        if (security === undefined) {
          throw new Error(
            `Could not find Active security with identifier: registrar = TICKER, having securityId: ${m.securityId}`
          );
        }

        const tickerIdentifier = security.identifiers.find(
          (idr) => idr.state === "Active" && idr.registrar === "TICKER"
        );

        if (
          tickerIdentifier === undefined ||
          !isTickerIdentifier(tickerIdentifier)
        ) {
          throw new Error(
            `No ticker identifier for: ${
              security.name ?? "No Name Available"
            }, id: ${security.id}`
          );
        }

        return {
          ...m,
          ticker: tickerIdentifier.identifier,
          description: tickerIdentifier.description ?? "",
        };
      }),
    }));
  }, [listsRequest.data, securitiesRequest.data]);

  if (listsRequest.hasError || securitiesRequest.hasError) {
    return {
      data: undefined,
      isLoading: false,
      hasError: true,
      error: listsRequest.hasError
        ? "Error retrieving equivalent security list"
        : "Error retrieving security data",
    };
  }

  if (listsRequest.isLoading || securitiesRequest.isLoading) {
    return {
      data: undefined,
      isLoading: true,
      hasError: false,
    };
  }

  return {
    data: lists,
    isLoading: false,
    hasError: false,
  };
}
