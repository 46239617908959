const pr = new Intl.PluralRules("en-US", { type: "ordinal" });

const pr_suffixes = new Map([
  ["one", "st"],
  ["two", "nd"],
  ["few", "rd"],
  ["other", "th"],
]);

const formatOrdinal = (num: number) => {
  const rule = pr.select(num);
  const suffix = pr_suffixes.get(rule) ?? "";
  return `${num}${suffix}`;
};
export default formatOrdinal;
