import React from "react";

import { Redirect, useParams } from "react-router-dom";

import { OpportunityId } from "@fartherfinance/frontend/api/Types";

import OpportunityDetails from "./OpportunityDetails";

interface OpportunityDetailsRouteParams {
  opportunityId: OpportunityId;
}

export default function OpportunityDetailsGuard(): JSX.Element {
  const { opportunityId } = useParams<OpportunityDetailsRouteParams>();
  const parsedOpportunityId = OpportunityId.safeParse(opportunityId);

  if (parsedOpportunityId.success) {
    return <OpportunityDetails opportunityId={parsedOpportunityId.data} />;
  }

  return <Redirect to="/Advisor/Opportunities" />;
}
