import React from "react";

import { useHistory, useParams } from "react-router-dom";

import { SMAAssetId } from "@fartherfinance/frontend/api/AltAssetsService/requests/Types";

import SMADetails from "../:SMAId";
import ApplySMADrawer from "@src/multiCustodian/components/Advisor/Investments/SMA/SMADetails/ApplySMA/ApplySMADrawer";

const Apply = (): JSX.Element => {
  const { SMAId } = useParams<{
    SMAId: SMAAssetId;
  }>();

  const history = useHistory();

  const onClose = () => {
    //Preserve original sorting of SMA Table
    history.push({
      ...history.location,
      pathname: `/Advisor/Investments/SMA/${SMAId}`,
    });
  };

  return (
    <SMADetails>
      <ApplySMADrawer SMAId={SMAId} onClose={onClose} cancel={onClose} />
    </SMADetails>
  );
};

export default Apply;
