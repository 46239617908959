import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { usersRepository } from "../repository";

import type {
  GetUsersSearchQueryParams,
  GetUsersSearchResponse,
} from "../types";

const { getUsersSearch } = usersRepository;

export const useUsersSearchMutation = () => {
  const mutation = useMutation<
    AxiosResponse<GetUsersSearchResponse>,
    unknown,
    GetUsersSearchQueryParams
  >({
    mutationFn: (queryParams) => getUsersSearch.mutationCall(queryParams),
  });

  return {
    ...mutation,
    data: mutation.data?.data.values,
  };
};
