import React, { useEffect, useState } from "react";

import { isEmpty, omit } from "lodash";

import useGetOpportunities from "@fartherfinance/frontend/api/Opportunities/hooks/useGetOpportunities";
import { GetOpportunitiesQueryParams } from "@fartherfinance/frontend/api/Opportunities/requests/getOpportunities";
import { PaginationHeaders } from "@fartherfinance/frontend/api/utils/parsePaginationHeaders";

import { usePersistedQueryParams } from "@src/multiCustodian/hooks/Advisor/usePersistedQueryParams";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import Spacer from "@src/sharedComponents/Forms/Spacer";

import AdvisorContainer from "./AdvisorContainer";
import AdvisorContentContainer from "./AdvisorContentContainer";
import { OPPORTUNITIES_DEFAULT_QUERY_PARAMS } from "./Opportunities/common";
import OpportunitiesTable from "./Opportunities/Components/OpportunitiesTable/OpportunitiesTable";
import OpportunitiesToolbar from "./Opportunities/Components/OpportunitiesToolbar/OpportunitiesToolbar";

export default function Opportunities({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element {
  const auth = useAdvisorRequestAuth();
  const [paginationState, setPaginationState] = useState<PaginationHeaders>();
  const [queryParams, setQueryParams] =
    usePersistedQueryParams<GetOpportunitiesQueryParams>(
      OPPORTUNITIES_DEFAULT_QUERY_PARAMS,
      {
        parseBooleans: true,
        decode: false,
        arrayFormat: "bracket-separator",
      }
    );

  // NOTE: we do not want this request to fire if query params are empty
  const authOrNull = isEmpty(queryParams) ? null : auth;
  const { data, isLoading, hasError } = useGetOpportunities(
    queryParams,
    authOrNull
  );

  const { values: opportunities = [], pagination } = data ?? {};

  const handleChangeQueryParams = (
    newQueryParams: GetOpportunitiesQueryParams
  ): void => {
    setQueryParams({ ...queryParams, ...newQueryParams });
  };

  const handleResetQueryParams = (
    resetOverwriteQueryParams: GetOpportunitiesQueryParams
  ): void => {
    setQueryParams({
      ...OPPORTUNITIES_DEFAULT_QUERY_PARAMS,
      ...resetOverwriteQueryParams,
    });
  };

  useEffect(() => {
    // NOTE: we have to persist pagination values locally as they become undefined inbetween request
    if (pagination) {
      setPaginationState(pagination);
    }
  }, [pagination]);

  return (
    <AdvisorContainer>
      <AdvisorContentContainer heroTitle={"Opportunities"}>
        <OpportunitiesToolbar
          filters={omit(queryParams, ["page", "pageSize", "sort"])}
          onQueryParamChange={handleChangeQueryParams}
          onQueryParamReset={handleResetQueryParams}
        />
        <OpportunitiesTable
          opportunities={opportunities}
          isLoading={isLoading}
          isErrored={hasError}
          pagination={paginationState}
          sort={queryParams.sort}
          onQueryParamChange={handleChangeQueryParams}
        />

        <Spacer verticalSpacing="50px" />

        {children}
      </AdvisorContentContainer>
    </AdvisorContainer>
  );
}
