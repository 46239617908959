import {
  calcAreAllChecked,
  calcAreAllUnchecked,
  CheckboxListConfig,
} from "@src/sharedComponents/ScrollableCheckboxList/ScrollableCheckboxList";

const isAllCheckedOrUnchecked = (
  filters: CheckboxListConfig<string>
): boolean => {
  return calcAreAllChecked(filters) || calcAreAllUnchecked(filters);
};

const mapFiltersToQueryParam = (filters: CheckboxListConfig<string>) => {
  const shouldOmit = isAllCheckedOrUnchecked(filters);

  return shouldOmit
    ? undefined
    : Object.keys(filters).filter((key) => filters[key].checked);
};

type AltAssetsFilters = {
  objectivesFilters: CheckboxListConfig<string>;
  strategyFilters: CheckboxListConfig<string>;
  subStrategyFilters: CheckboxListConfig<string>;
  minimumInvestmentFilters: CheckboxListConfig<string>;
  investorQualificationFilters: CheckboxListConfig<string>;
  liquidityFilters: CheckboxListConfig<string>;
  subscriptionsFilters: CheckboxListConfig<string>;
  lockUpFilters: CheckboxListConfig<string>;
};

export const mapAltAssetsFiltersToQueryParams = ({
  objectivesFilters,
  strategyFilters,
  subStrategyFilters,
  minimumInvestmentFilters,
  investorQualificationFilters,
  liquidityFilters,
  subscriptionsFilters,
  lockUpFilters,
}: AltAssetsFilters) => {
  // NOTE: turn currency strings into numbers so they can be persisted as query params
  const minimumInvestment = mapFiltersToQueryParam(
    minimumInvestmentFilters
  )?.map((value) => parseInt(value.match(/\d+/g)!.join("")));

  return {
    filterByObjectives: mapFiltersToQueryParam(objectivesFilters),
    filterByStrategy: mapFiltersToQueryParam(strategyFilters),
    filterBySubStrategy: mapFiltersToQueryParam(subStrategyFilters),
    filterByMinimumInvestment: minimumInvestment,
    filterByInvestorQualification: mapFiltersToQueryParam(
      investorQualificationFilters
    ),
    filterByLiquidity: mapFiltersToQueryParam(liquidityFilters),
    filterBySubscriptions: mapFiltersToQueryParam(subscriptionsFilters),
    filterByLockUp: mapFiltersToQueryParam(lockUpFilters),
  };
};
