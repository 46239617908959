import React from "react";

import { useSelector } from "react-redux";

import {
  getPartialAccountNumber,
  MASK_NUMBER,
} from "@fartherfinance/frontend/api/Accounts/utilities/accountUtil";
import useClientAccounts from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAccounts";
import useExternalAccountsExtended from "@fartherfinance/frontend/api/ExternalAccount/hooks/useExternalAccountsExtended";
import {
  ExternalAccountId,
  FartherAccountId,
} from "@fartherfinance/frontend/api/Types";

import { State } from "../../../../../../../store";
import ButtonPrimary from "../../../../../../components/MUI/Button/Button";
import { SetScreenAndTransferType } from "../../../Types";
import { getDateTimeString } from "../utils/getDateTimeString";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import { isManualAccount } from "@src/multiCustodian/pages/Dashboard/Funding/utils";
import useThemeFragment from "@src/multiCustodian/theme/useThemeFragment";

import "./Withdraw.css";

interface Props {
  withdrawFromAccountId: FartherAccountId;
  depositToExternalId: ExternalAccountId;
  transferAmount: number;
  setCurScreen: SetScreenAndTransferType;
}

const WithdrawSuccess = (props: Props): JSX.Element => {
  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));

  const t = useThemeFragment();

  const auth = useRequestAuth();

  const accounts = useClientAccounts(clientId, auth);

  const fartherAccounts = accounts.data?.fartherAccounts ?? [];

  const externalAccounts = useExternalAccountsExtended(undefined, auth);

  const onFinish = () => {
    props.setCurScreen({ screen: "transferType" });
  };

  if (accounts.isLoading || externalAccounts.isLoading) {
    return <div>Loading...</div>;
  }

  if (accounts.hasError || externalAccounts.hasError) {
    return <div>Error</div>;
  }

  const withdrawFrom = fartherAccounts.find(
    (e) => e.accountId === props.withdrawFromAccountId
  );
  const depositTo = (externalAccounts.data ?? []).find(
    (e) => e.accountId === props.depositToExternalId
  );

  const institutionName =
    depositTo !== undefined
      ? isManualAccount(depositTo)
        ? depositTo.details?.bank?.name
        : depositTo?.institution?.name
      : undefined;

  const externalAccName =
    depositTo !== undefined
      ? isManualAccount(depositTo)
        ? depositTo.details?.account.accountTitle
        : depositTo?.details?.account.name
      : undefined;

  // https://www.notion.so/fartherfinance/RFC-Account-Id-Display-9fefdde3348b4a888cf8c4dcfa4a5eb1?pvs=4#42361a3aa39942cfa9582f1ad4c1369f
  const mask = `(...${
    depositTo ? getPartialAccountNumber(depositTo) : MASK_NUMBER
  })`;

  const fartherAccName = withdrawFrom?.accountDetails.displayName;

  const fartherAccBalance = withdrawFrom?.accountDetails.accountBalance;

  return (
    <div className="Dashboard_Withdraw_page__container">
      <div className="Dashboard_Withdraw_page__content_container">
        <p className="Dashboard_Withdraw_page__title">Withdrawal complete</p>

        <p className="Dashboard_Withdraw_page__heading_summary">
          Withdrawal confirmed! Here's what you should expect.
        </p>

        <div className="Dashboard_Withdraw_page__checklist">
          <div className="Dashboard_Withdraw_page__flex_row_div">
            <div className="Dashboard_Withdraw_page__orange_number">1</div>
            <p className="Dashboard_Withdraw_page__text_2">
              It can take up to five business days from the time you requested
              the withdrawal to the time you see funds arrive in your bank
              account.
            </p>
          </div>
        </div>

        <p className="Dashboard_Withdraw_page__text_2_gray">From account</p>

        <div className="Dashboard_Withdraw_page__from_account_div">
          <p className="Dashboard_Withdraw_page__heading">
            {t("companyName")}
            {" - " +
              (fartherAccName === "Long-term Savings"
                ? "Long-term Growth"
                : fartherAccName ?? "Unknown")}
          </p>
          <p className="Dashboard_Withdraw_page__heading">
            {fartherAccBalance
              ? fartherAccBalance.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
              : "N/A"}
          </p>
        </div>

        <p className="Dashboard_Withdraw_page__text_2_gray">Deposit to</p>

        <div className="Dashboard_Withdraw_page__to_account_div">
          <p className="Dashboard_Withdraw_page__heading">
            {institutionName ?? "Your bank"}
            {" - "}
            {externalAccName ?? "Your Account"}
          </p>
          <p className="Dashboard_Withdraw_page__heading">{mask}</p>
        </div>

        <p className="Dashboard_Withdraw_page__text_2_gray">
          Withdrawal amount
        </p>
        <p className="Dashboard_Withdraw_page__amount_heading">
          {props.transferAmount.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>

        <p className="Dashboard_Withdraw_page__text_2_gray">Transfer date</p>
        <p className="Dashboard_Withdraw_page__amount_heading">
          {getDateTimeString()}
        </p>

        <div className="Dashboard_Withdraw_page__button_container">
          <ButtonPrimary
            variant={"contained"}
            buttonType={"primary"}
            text={"Finish"}
            onClick={() => onFinish()}
          />
        </div>
      </div>
    </div>
  );
};

export default WithdrawSuccess;
