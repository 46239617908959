import { SharedResource } from "@fartherfinance/frontend/api/Sharing/Types";

import { PageIndex } from "./types";

export const initialState = {
  isSaveButtonDisabled: true,
  pageIndex: 0 as PageIndex,
  data: {
    firstName: "",
    lastName: "",
    collaboratorType: undefined,
    serviceProfessionalType: undefined,
    accessLevel: undefined,
    notify: false,
    resources: [] as SharedResource[],
  },
};
