import React from "react";

const InProgress: React.FC = () => (
  <svg
    width="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame 10124968">
      <circle id="Ellipse 706" cx="8" cy="8" r="7.5" stroke="#FFFEF4" />
      <path
        id="Ellipse 707"
        d="M0 8C0 12.4183 3.58172 16 8 16V0C3.58172 0 0 3.58172 0 8Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default InProgress;
