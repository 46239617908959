import React, { PropsWithChildren, useMemo } from "react";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isNil, last, orderBy } from "lodash";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import useGetFartherManagedAccounts from "@fartherfinance/frontend/api/Accounts/hooks/useGetFartherManagedAccounts";
import {
  Data,
  TimeFrame,
} from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Types";
import useManagedAccountCustodians from "@fartherfinance/frontend/api/PerformanceGroups/hooks/useManagedAccountCustodians";
import useMatchPerformanceGroup from "@fartherfinance/frontend/api/PerformanceGroups/hooks/useMatchPerformanceGroup";
import { ClientId, FartherAccountId } from "@fartherfinance/frontend/api/Types";
import {
  formatFartherAccount,
  formatFartherAccountType,
} from "@fartherfinance/frontend/formatting/account";
import pipe from "@fartherfinance/frontend/utils/pipe";

import { timeRangeOptionToText } from "../../PerformanceGroups/Summary/SummaryHeader";
import { AccountsPath } from "@src/config/routing/RouterPaths";
import Drawer, {
  largeDrawer,
} from "@src/multiCustodian/components/Drawer/Drawer";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import Select from "@src/sharedComponents/Select/Select";
import SelectItem from "@src/sharedComponents/Select/SelectItem";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import TabBar, { HrefTab } from "@src/sharedComponents/TabBar/TabBar";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";
import { State } from "@src/store";

import Header from "./AccountsDrawer/Header";
import { Accordions } from "./Types";

import * as styles from "./AccountsDrawer.module.css";

const tabs = [
  "Summary",
  "Activity",
  "Holdings",
  "Projections",
  "Settings",
] as const;
type Tab = typeof tabs[number];

const limitedTabs = tabs.filter(
  (t) => t !== "Activity" && t !== "Holdings" && t !== "Projections"
);

interface Props {
  curTab: Tab;
}

const AccountsDrawer = (props: PropsWithChildren<Props>): JSX.Element => {
  const isAdvisor = useSelector(
    (state: State) => state.main_Reducer.user.isAdvisor ?? false
  );

  const {
    enableBdPerformancePassthrough,
    enablePerformanceGroups,
    enableLowDataPerformanceGroups,
    enableProjectionsTab,
    enableProjectionsTabVisibilityBadge,
  } = useFlags();

  const timeFrame: TimeFrame = useMemo(
    (): TimeFrame => (enableLowDataPerformanceGroups ? "1Y" : "ALL"),
    [enableLowDataPerformanceGroups]
  );

  const history = useHistory();
  const { clientId, accountId } = useParams<{
    clientId: ClientId;
    accountId: FartherAccountId;
  }>();
  const { state } = useLocation<
    { openAccordions?: Accordions[] } | undefined
  >();

  const auth = useRequestAuth();
  const performanceGroup = useMatchPerformanceGroup(
    clientId,
    accountId,
    enablePerformanceGroups ? auth : null // This uses a PG endpoint to do the match
  );

  const managedAccounts = useGetFartherManagedAccounts(clientId, auth);

  const dropdownAccountOptions = useMemo(() => {
    if (managedAccounts.data === undefined) {
      return [];
    }

    return orderBy(
      managedAccounts.data,
      [
        (a) => a.accountDetails.displayName,
        (a) => a.accountDetails.custodianAccountNumber,
        (a) => a.virtualAccountId,
      ],
      ["asc", "asc", "asc"]
    );
  }, [managedAccounts.data]);

  const summary = useManagedAccountCustodians(
    performanceGroup.data ?? null,
    timeFrame,
    null, // will never be Custom, only "1Y" or "ALL" depending on LD flag: enableLowDataPerformanceGroups
    clientId,
    enablePerformanceGroups ? auth : null
  );

  const thisAccount = useMemo(() => {
    if (managedAccounts.isLoading || managedAccounts.hasError) {
      return managedAccounts;
    }

    return {
      ...managedAccounts,
      data:
        managedAccounts.data.find((a) => a.virtualAccountId === accountId) ??
        null,
    };
  }, [accountId, managedAccounts]);

  const useLimitedTabs =
    !isNil(thisAccount.data) &&
    (thisAccount.data.accountDetails.custodian === "DPL" ||
      thisAccount.data.accountDetails.custodian === "Pontera");

  if (managedAccounts.hasError || thisAccount.hasError) {
    return (
      <Drawer
        container_style={largeDrawer}
        isDrawerOpen
        header={null}
        onClose={() =>
          history.push({
            state,
            pathname: `/Client/${clientId}/${AccountsPath}`,
          })
        }
        transitionDuration={0}
      >
        <div>Error: Account data not found</div>
      </Drawer>
    );
  }

  return (
    <Drawer
      container_style={largeDrawer}
      isDrawerOpen
      header={
        managedAccounts.data !== undefined ? (
          <Select
            value={accountId}
            options={dropdownAccountOptions}
            onChange={(anAccountId) => {
              history.push({
                state,
                pathname: `/Client/${clientId}/${AccountsPath}/Managed/${anAccountId}`,
              });
            }}
            style={{ width: "200px" }}
            renderValue={(value) => {
              const matching = managedAccounts.data.find(
                (a) => a.virtualAccountId === value
              );
              if (matching === undefined) {
                return "Unknown";
              }

              return <>{formatFartherAccount(matching, isAdvisor)}</>;
            }}
            renderOption={(account) => (
              <SelectItem
                key={account.virtualAccountId}
                label={formatFartherAccount(account, isAdvisor)}
                value={account.virtualAccountId}
              />
            )}
          />
        ) : (
          <Skeleton width={200} />
        )
      }
      onClose={() =>
        history.push({ state, pathname: `/Client/${clientId}/${AccountsPath}` })
      }
      transitionDuration={0}
    >
      <Header
        name={thisAccount.data?.accountDetails.displayName ?? null}
        subHeader={
          thisAccount.data
            ? formatFartherAccountType(thisAccount.data, isAdvisor)
            : null
        }
        clientId={clientId}
        accountId={accountId}
        marketChange={
          enablePerformanceGroups === false || performanceGroup.data === null // error getting performanceGroupId
            ? undefined
            : pipe(last(summary.data?.data), (dataPoint: Data | undefined) => {
                if (dataPoint === undefined) {
                  return null;
                }

                if (enableBdPerformancePassthrough) {
                  return dataPoint.netGainsSinceStartDay; //same as: dataPoint.balanceOnEndDay - (dataPoint.balanceOnStartDay + dataPoint.netAdditions)
                }

                return (
                  dataPoint.balanceOnEndDay -
                  (dataPoint.balanceOnStartDay +
                    dataPoint.depositsSinceStartDay -
                    dataPoint.withdrawalsSinceStartDay)
                );
              })
        }
        twr={
          enablePerformanceGroups === false || performanceGroup.data === null // error getting performanceGroupId
            ? undefined
            : pipe(
                last(summary.data?.data)?.returnsSinceStartDay ?? null,
                (n: number) => n / 100
              )
        }
        timeFrame={
          enablePerformanceGroups === false || performanceGroup.data === null
            ? "(Performance data unavailable)"
            : timeRangeOptionToText(timeFrame)
        }
      />

      <TabBar
        currentTabKey={props.curTab}
        tabs={(useLimitedTabs ? limitedTabs : tabs)
          .filter((t) => {
            if (t === "Projections") {
              return enableProjectionsTab;
            }

            return true;
          })
          .map(
            (t): HrefTab<React.ReactNode> => ({
              tabKey: t,
              tab: (
                <div className={styles.tab}>
                  {enableProjectionsTabVisibilityBadge &&
                    t === "Projections" && (
                      <div className={styles.visibilityBadge}>
                        <Tooltip
                          tooltipText="This is not visible to the client"
                          placement="top"
                        >
                          <VisibilityOffIcon
                            style={{ width: 16, height: 16, color: "black" }}
                          />
                        </Tooltip>
                      </div>
                    )}

                  {t}
                </div>
              ),
              href: (loc) => ({
                state: loc.state,
                pathname: `/Client/${clientId}/${AccountsPath}/Managed/${accountId}/${t}`,
              }),
            })
          )}
      />

      {enablePerformanceGroups ? (
        // Only show children (and allow them to make requests when PG is enabled)
        <div className={styles.content}>{props.children}</div>
      ) : (
        <div className={styles.text}>
          Performance data is currently under maintenance
        </div>
      )}
    </Drawer>
  );
};

export default AccountsDrawer;
