import React, { useMemo } from "react";

import { useSelector } from "react-redux";

import useSleeves from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useSleeves";

import { InvestmentsPath } from "@src/config/routing/RouterPaths";
import TabPanel from "@src/multiCustodian/components/Advisor/Investments/SharedComponents/TabPanel/TabPanel";
import SleevesTable from "@src/multiCustodian/components/Advisor/Investments/Sleeves/SleevesTable/SleevesTable";
import SleeveTableLoading from "@src/multiCustodian/components/Advisor/Investments/Sleeves/SleevesTable/TableLoading";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import { State } from "@src/store";

import styles from "./Investments.module.css";

const tabPanelValue = `/Advisor/${InvestmentsPath}/Sleeves`;

const Sleeves = (props: React.PropsWithChildren<unknown>): JSX.Element => {
  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));

  const auth = useAdvisorRequestAuth();
  const sleeves = useSleeves(advisorId, auth);

  const allSleeves = useMemo(() => {
    if (sleeves.isLoading || sleeves.hasError) {
      return [];
    }

    return sleeves.data.sleeves.filter((sleeve) => !sleeve.isDerived);
  }, [sleeves]);

  if (sleeves.isLoading) {
    return (
      <TabPanel className={styles.tabPanel} value={tabPanelValue}>
        <SleeveTableLoading />
        {props.children}
      </TabPanel>
    );
  }

  if (sleeves.hasError) {
    return (
      <TabPanel className={styles.tabPanel} value={tabPanelValue}>
        <div>Error loading sleeves</div>
        {props.children}
      </TabPanel>
    );
  }

  return (
    <TabPanel className={styles.tabPanel} value={tabPanelValue}>
      <SleevesTable sleeves={allSleeves} />
      {props.children}
    </TabPanel>
  );
};

export default Sleeves;
