/* eslint-disable react/prop-types */

import React, { Component, PropsWithChildren } from "react";

import "./input.css";

interface Props {
  title_img?: string;
  title: string;
  error_description?: string;
  show_checkmark?: boolean;
  validation_img?: string;
  underline_style?: React.CSSProperties;
  pre_text?: string;
  placeholder?: string;
  name?: string;
  type?: React.HTMLInputTypeAttribute;
  disabled?: boolean;
  handleInputChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: () => void;
  value: string;
  style?: React.CSSProperties;
  hideCaretCursor?: boolean;
  is_caution?: boolean;
}

class Input_Component_2 extends Component<PropsWithChildren<Props>, unknown> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="input_component__div_container"
        style={{
          opacity: this.props.disabled ? 0.3 : 1,
        }}
      >
        <div className="input_component__cred_container">
          <div className="input_component__cred_container_leftSide">
            {this.props.title_img && (
              <img
                src={this.props.title_img}
                className="input_component__cred_img"
              />
            )}
            <div className="input_component__input_title">
              {this.props.title}
            </div>
            {this.props.children}
          </div>
          {((this.props.error_description &&
            this.props.error_description.length > 0) ||
            this.props.show_checkmark === true) && (
            <img
              src={this.props.validation_img}
              className="input_component__validation_img"
            />
          )}
        </div>
        <div
          className="input_component__input_shell"
          style={this.props.underline_style}
        >
          {this.props.pre_text && (
            <p className="input_component__pre_text">{this.props.pre_text}</p>
          )}
          <input
            className="input_component__input_2"
            placeholder={this.props.placeholder}
            autoComplete="off"
            name={this.props.name}
            type={this.props.type}
            disabled={this.props.disabled || false}
            onChange={this.props.handleInputChange}
            onBlur={this.props.onBlur}
            value={this.props.value ?? ""}
            style={
              this.props.pre_text
                ? {
                    ...(this.props.style ? this.props.style : {}),
                    caretColor: this.props.hideCaretCursor
                      ? "transparent"
                      : "default",
                  }
                : {
                    ...(this.props.style ? this.props.style : {}),
                    caretColor: this.props.hideCaretCursor
                      ? "transparent"
                      : "default",
                  }
            }
          />
        </div>
        {this.props.error_description &&
          this.props.error_description.length > 0 && (
            <div
              className="input_component__error_text"
              style={
                this.props.is_caution
                  ? { color: "var(--bad_input_underline_color_1)" }
                  : { color: "var(--bad_input_underline_color_1)" }
              }
            >
              {this.props.error_description}
            </div>
          )}
      </div>
    );
  }
}

export default Input_Component_2;
