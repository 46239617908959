import {
  ButtonText,
  Disclosure,
  FlowType,
  PageIndex,
  PageIndexHypotheticalFlow,
  PageTitle,
  TaxBudgetFlowState,
} from "./types";

export const pageIndexMap: Record<PageIndex, PageTitle> = {
  0: "Select Year",
  1: "Select Accounts",
  2: "Enter Budget Amount",
  3: "Review Disclosures",
  4: "Review Budget Details",
  5: "Budget Set",
};

export const pageIndexMapForHypothetical: Record<
  PageIndexHypotheticalFlow,
  PageTitle
> = {
  0: "Select Accounts",
  1: "Enter Budget Amount",
  2: "Review Disclosures",
  3: "Select Hypothetical Models",
  4: "Tax Analysis",
};

const clientDisclosure1: Disclosure = {
  title: "Disclosure",
  body: "Taxes that have already been accrued will count towards the current year's budget.",
};

const clientDisclosure2: Disclosure = {
  title: "Disclosure",
  body: "If a new account is opened it will automatically be included in this tax budget.",
};

const clientDisclosure3: Disclosure = {
  title: "Disclosure",
  body: "This budget will be used for both short-term and long-term gains taxes.",
};

const clientDisclosure4: Disclosure = {
  title: "Accounts Are Grouped by Asset Location",
  body: "Accounts with asset location turned on are grouped together for trading purposes. As such, they must have the same target model portfolio.",
};

const advisorDisclosure1: Disclosure = {
  title: "Disclosure",
  body: "Any capital gains taxes accrued since the start of the year will count towards the current year's budget.",
};

const advisorDisclosure2: Disclosure = {
  title: "Calculation Methodology",
  body: 'Tax budget calculations assume "First In First Out" (FIFO) methodology. If you use a different methodology, you can switch to FIFO for closer alignment with our calculations, or you should expect some differences between target and actual taxes.',
};

export const disclosureIndexMap: Record<PageIndex, Disclosure[]> = {
  0: [clientDisclosure1],
  1: [clientDisclosure2],
  2: [clientDisclosure3],
  3: [],
  4: [],
  5: [],
};

export const hypotheticalDisclosureIndexMap: Record<
  PageIndexHypotheticalFlow,
  Disclosure[]
> = {
  0: [clientDisclosure2],
  1: [clientDisclosure3],
  2: [],
  3: [clientDisclosure4],
  4: [],
};

export const advisorDisclosureIndexMap: Record<PageIndex, Disclosure[]> = {
  0: [advisorDisclosure1, advisorDisclosure2],
  1: [clientDisclosure2],
  2: [clientDisclosure3, advisorDisclosure2],
  3: [],
  4: [],
  5: [],
};

export const hypotheticalAdvisorDisclosureIndexMap: Record<
  PageIndexHypotheticalFlow,
  Disclosure[]
> = {
  0: [clientDisclosure2],
  1: [clientDisclosure3, advisorDisclosure2],
  2: [],
  3: [clientDisclosure4],
  4: [],
};

export const isContinueButtonDisabled = (
  state: TaxBudgetFlowState
): boolean => {
  switch (state.pageTitle) {
    case "Select Year":
      return state.data.year === undefined;
    case "Select Accounts":
      // this indicates no accounts have been loaded yet
      if (state.data.clientAccountIds.length === 0) {
        return true;
      }
      // this indicates all accounts are de-selected
      return (
        state.data.clientAccountIds.length ===
        state.data.excludedVirtualAccountIds.length
      );
    case "Enter Budget Amount":
      return state.data.budgetAmount < 0;
    case "Select Hypothetical Models":
      if (state.data.hypotheticalTradeGroups.length === 0) {
        return true;
      }

      return state.data.hypotheticalTradeGroups.some(
        (htg) => htg.hypotheticalModelId === null
      );
    default:
      return false;
  }
};

export const determineButtonText = (
  flowType: FlowType,
  pageIndex: PageIndex
): ButtonText => {
  if (flowType === "Budget" && pageIndex === 4) {
    return "Confirm and Set Budget";
  } else if (flowType === "Hypothetical" && pageIndex === 4) {
    return "Done";
  }

  return "Continue";
};
