import React, { useCallback, useMemo, useState } from "react";

import { format } from "date-fns";
import { identity, orderBy, uniq } from "lodash";
import {
  FieldPath,
  FormProvider,
  useForm,
  useFormState,
  useWatch,
} from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import useAddOffPortalAccount from "@fartherfinance/frontend/api/Accounts/hooks/useAddOffPortalAccount";
import useGetFartherManagedAccounts from "@fartherfinance/frontend/api/Accounts/hooks/useGetFartherManagedAccounts";
import { OffPortalAccountBody } from "@fartherfinance/frontend/api/Accounts/requests/postAddOffPortalAccount";
import useClientDashboard from "@fartherfinance/frontend/api/Dashboard/hooks/useClientDashboard";
import useClientProfile, {
  ClientProfile,
} from "@fartherfinance/frontend/api/Entity/hooks/useClientProfile";
import {
  AccountType,
  accountTypes,
  custodianOptions as allCustodianOptions,
  ClientId,
  Custodian,
} from "@fartherfinance/frontend/api/Types";

import { AccountsPath } from "@src/config/routing/RouterPaths";
import { dateFormat } from "@src/constants/dateFormat";
import BackButton from "@src/multiCustodian/components/Client/BackButton";
import ExitButton from "@src/multiCustodian/components/Client/ExitButton";
import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import useTriggerForm from "@src/multiCustodian/pages/Advisor/Plans/hooks/useTriggerForm";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormAccountHolderDropdownField, {
  AutocompleteClient,
} from "@src/sharedComponents/Forms/FormAccountHolderDropdownField";
import FormDateField from "@src/sharedComponents/Forms/FormDateField";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import FormNumberField from "@src/sharedComponents/Forms/FormNumberField";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import { State as ReduxState } from "@src/store";

import styles from "./AddOffPortalAccount.module.css";

interface Form {
  PrimaryAccountHolder: string;
  SecondaryAccountHolder: AutocompleteClient | undefined;
  AccountType: { label: AccountType } | undefined;
  AccountCustodian: { label: Custodian } | undefined;
  CustodianAccountNumber: string;
  AccountTitle: string;
  TargetGoal: string | undefined;
  TargetDate: Date | undefined;
}

const AddOffPortalAccount = (): JSX.Element => {
  const { isAdvisor } = useSelector((state: ReduxState) => ({
    isAdvisor: state.main_Reducer.user.isAdvisor,
  }));

  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const history = useHistory();

  const clientAuth = useRequestAuth();

  const clientProfile = useClientProfile(clientId, clientAuth);

  const accounts = useGetFartherManagedAccounts(clientId, clientAuth);

  const uniqueCustodians = useMemo(() => {
    const allCustodians = (accounts.data ?? []).map(
      (d) => d.accountDetails.custodian
    );

    return uniq(allCustodians);
  }, [accounts.data]);

  const statusNotification = useStatusNotification();

  if (!isAdvisor) {
    statusNotification(
      "Clients are not allowed to add Off-Portal accounts",
      "Error"
    );
    history.push(`/Client/${clientId}/${AccountsPath}`);
  }

  if (clientProfile.isLoading || accounts.isLoading) {
    return (
      <div className={styles.container}>
        <div className={styles.loading_inner_div}>
          <LogoLoadingStill />
        </div>
      </div>
    );
  }

  if (clientProfile.hasError || accounts.hasError) {
    return <div className={styles.container}>Error</div>;
  }

  return (
    <AddOffPortalAccountForm
      clientProfile={clientProfile.data}
      detectedCustodians={uniqueCustodians}
    />
  );
};

type StripUndefined<F extends object> = { [k in keyof F]: NonNullable<F[k]> };

interface FormProps {
  clientProfile: ClientProfile;
  detectedCustodians: Custodian[];
}

function AddOffPortalAccountForm(props: FormProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const history = useHistory();

  const statusNotification = useStatusNotification();

  const advisorAuth = useAdvisorRequestAuth();
  const clientAuth = useRequestAuth();

  const addOffPortalAccount = useAddOffPortalAccount(clientId, advisorAuth);

  const dashboard = useClientDashboard(clientId, clientAuth);

  const defaultCustodian = useMemo(() => {
    return dashboard.data?.defaultCustodian ?? null;
  }, [dashboard.data]);

  const allowedCustodians: readonly Custodian[] = useMemo(() => {
    if (
      props.detectedCustodians.length === 0 ||
      props.detectedCustodians.every((c) => c === "DPL" || c === "Pontera")
    ) {
      return allCustodianOptions;
    }

    if (defaultCustodian !== null) {
      return uniq([
        ...props.detectedCustodians,
        "DPL",
        "Pontera",
        defaultCustodian,
      ]);
    }

    return uniq([...props.detectedCustodians, "DPL", "Pontera"]);
  }, [props.detectedCustodians, defaultCustodian]);

  const sortedAllowedCustodians = useMemo(() => {
    return orderBy(allowedCustodians, identity, "asc").map((el) => ({
      label: el,
    }));
  }, [allowedCustodians]);

  const fullName = `${props.clientProfile.investorProfile.personalDetails.name.first} ${props.clientProfile.investorProfile.personalDetails.name.last}`;

  const methods = useForm<Form>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      PrimaryAccountHolder: fullName,
      SecondaryAccountHolder: undefined,
      AccountType: undefined,
      AccountCustodian:
        props.detectedCustodians.length > 0
          ? { label: props.detectedCustodians[0] }
          : defaultCustodian !== null
          ? { label: defaultCustodian }
          : undefined,
      CustodianAccountNumber: undefined,
      AccountTitle: undefined,
      TargetGoal: undefined,
      TargetDate: undefined,
    },
  });

  const { control, getValues, trigger } = methods;

  const { isValid } = useFormState({
    control,
  });

  const targetGoalValue = useWatch({ control, name: "TargetGoal" });
  const targetGoalNonEmpty = nonEmptyValue(targetGoalValue);

  const targetDateValue = useWatch({ control, name: "TargetDate" });
  const targetDateNotEmpty = targetDateValue !== undefined;

  const values = getValues() as StripUndefined<Form>;

  const AccountTypeValueIsJoint =
    values.AccountType?.label?.startsWith("Joint");

  const AccountTypeValueIsTrust = values.AccountType?.label === "Trust";

  const AccountTypeValueIsJointAndJointHolderIsEmpty =
    AccountTypeValueIsJoint && !values.SecondaryAccountHolder?.clientId;

  const needsManualTriggering: FieldPath<Form>[] = useMemo(
    () => ["TargetDate", "TargetGoal"],
    []
  );
  useTriggerForm<Form>(control, trigger, needsManualTriggering);

  const displayWarning = useMemo(() => {
    if (!values.AccountCustodian?.label) {
      return false;
    }

    const stableCustodians = uniq([
      ...props.detectedCustodians,
      "DPL",
      "Pontera",
    ]);

    const isStableCustodian = stableCustodians.some(
      (c) => c === values.AccountCustodian.label
    );

    const detectedCustodiansMinusPonteraAndDPL =
      props.detectedCustodians.filter((c) => c !== "DPL" && c !== "Pontera");

    // if no accounts exist under any custodian other than "DPL" and "Pontera" then adding any custodian should not trigger showing the warning
    return (
      !isStableCustodian && detectedCustodiansMinusPonteraAndDPL.length >= 1
    );
  }, [values.AccountCustodian.label, props.detectedCustodians]);

  const submit = useCallback(async () => {
    if (!isValid || isLoading) {
      return;
    }

    try {
      setIsLoading(true);
      const accountTitle = (values.AccountTitle ?? "").trim();

      const secondaryAccountHolderClientId =
        AccountTypeValueIsJoint || AccountTypeValueIsTrust
          ? values.SecondaryAccountHolder?.clientId
          : undefined;

      const body: OffPortalAccountBody = {
        accountHolderClientId: clientId,
        secondaryAccountHolderClientId: secondaryAccountHolderClientId ?? null,
        accountType: values.AccountType.label,
        custodian: values.AccountCustodian.label,
        custodianAccountNumber: values.CustodianAccountNumber?.trim(),
        accountTitle: accountTitle === "" ? null : accountTitle,
        accountTarget: targetGoalNonEmpty
          ? {
              goal: parseFloat(values.TargetGoal),
              date: format(values.TargetDate, dateFormat),
            }
          : null,
      };
      await addOffPortalAccount(body);

      statusNotification("Account added", "Success");
      setIsLoading(false);
      history.push(`/Client/${clientId}/${AccountsPath}`);
    } catch (e) {
      statusNotification("Error adding account", "Error");
      setIsLoading(false);
      console.error(e);
    }
  }, [
    isValid,
    isLoading,
    statusNotification,
    targetGoalNonEmpty,
    values,
    addOffPortalAccount,
    clientId,
    history,
    AccountTypeValueIsJoint,
    AccountTypeValueIsTrust,
  ]);

  if (advisorAuth === null) {
    return (
      <div className={styles.centerDiv}>
        You must be an advisor to use this page
      </div>
    );
  }

  if (dashboard.hasError) {
    return (
      <div className={styles.centerDiv}>Error retrieving default custodian</div>
    );
  }

  return (
    <BaseLayout showSideNav={false}>
      <ExitButton />

      <div className={styles.container}>
        <div className={styles.centeringContainer1}>
          <div className={styles.backButtonContainer}>
            <BackButton
              onClick={() =>
                history.push(`/Client/${clientId}/${AccountsPath}/Add`)
              }
            />
          </div>

          <div className={styles.innerContainer}>
            <div className={styles.centeringContainer2}>
              {isLoading || dashboard.isLoading ? (
                <div className={styles.loadingDiv}>
                  <LogoLoadingStill />
                </div>
              ) : (
                <>
                  <p className={styles.title}>Add Off-Portal Account</p>

                  <p className={styles.summary}>
                    Please enter the account details for the account you'd like
                    to add:
                  </p>

                  <FormProvider {...methods}>
                    <div className={styles.inputContainer}>
                      <FormTextField
                        label={"Primary Account Holder"}
                        name={"PrimaryAccountHolder"}
                        required={"Primary Account Holder is required"}
                        disabled={true}
                      />
                    </div>

                    <div className={styles.inputContainer}>
                      <FormDropdownField
                        name={"AccountType"}
                        label={"Account Type"}
                        placeholder={"Select a type"}
                        required={"Must not be empty"}
                        values={orderBy(
                          accountTypes.map((el) => ({ label: el })),
                          [(e) => e.label.toLowerCase()],
                          ["asc"]
                        )}
                      />
                    </div>

                    {(AccountTypeValueIsJoint || AccountTypeValueIsTrust) && (
                      <div className={styles.inputContainer}>
                        <FormAccountHolderDropdownField
                          name={"SecondaryAccountHolder"}
                          label={
                            AccountTypeValueIsTrust
                              ? "Additional Trustee (Optional)"
                              : "Joint Account Holder"
                          }
                          required={
                            AccountTypeValueIsJointAndJointHolderIsEmpty
                              ? "Joint Account Holder is required"
                              : undefined
                          }
                          rules={{ deps: ["AccountType"] }}
                        />
                      </div>
                    )}

                    <div className={styles.inputContainer}>
                      <FormDropdownField
                        name={"AccountCustodian"}
                        label={"Account Custodian"}
                        placeholder={"Select a custodian"}
                        required={"Must not be empty"}
                        values={sortedAllowedCustodians}
                      />
                    </div>

                    {displayWarning && (
                      <p className={styles.warning}>
                        If you add this account, the client will have multiple
                        custodians connected to their Farther account. If you
                        proceed, the client will not have access to Transfers
                        and Cash Management and the portal may be less stable.
                      </p>
                    )}

                    <div className={styles.inputContainer}>
                      <FormTextField
                        label={"Custodian Account Number"}
                        name={"CustodianAccountNumber"}
                        required={"Must not be empty"}
                        rules={{
                          validate: {
                            onlyLettersNumbers: () => {
                              const inpValues = getValues();
                              return inpValues.CustodianAccountNumber?.match(
                                /^[a-zA-Z0-9]*$/
                              )
                                ? true
                                : "Can only contain letters and numbers";
                            },
                          },
                          minLength: {
                            value: 5,
                            message: "Should have at least 5 characters.",
                          },
                        }}
                        startAdornment={"#"}
                        placeholder={"Enter a number"}
                      />
                    </div>

                    <div className={styles.inputContainer}>
                      <FormTextField
                        label={"Account title (optional)"}
                        name={"AccountTitle"}
                        required={false}
                        placeholder={"Enter a title"}
                      />
                    </div>

                    <p className={styles.inputHeader}>
                      "Target Goal (optional)"
                    </p>

                    <FlexWrapper>
                      <FormNumberField
                        name={"TargetGoal"}
                        startAdornment={"$"}
                        required={
                          targetDateNotEmpty
                            ? "This is required when Goal Date is set"
                            : undefined
                        }
                        label={"Goal Amount"}
                      />

                      <FormDateField
                        required={
                          targetGoalNonEmpty
                            ? "This is required when Goal Amount is set"
                            : undefined
                        }
                        rules={{ deps: ["TargetGoal"] }}
                        dateCheck={"AfterToday"}
                        label={"Goal Date"}
                        name={"TargetDate"}
                      />
                    </FlexWrapper>
                  </FormProvider>

                  <div className={styles.footer}>
                    <Button
                      disabled={!isValid || isLoading}
                      variant={"contained"}
                      buttonType={"primary"}
                      text={"Connect Account"}
                      onClick={() => submit()}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}

const nonEmptyValue = (value: string | undefined | null) => {
  return value !== undefined && value !== "" && value !== null;
};

export default AddOffPortalAccount;
