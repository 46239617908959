import React, {
  Fragment,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { isEqual, omit, orderBy } from "lodash";
import { useHistory, useLocation } from "react-router-dom";

import {
  FartherTable,
  FartherTableBody,
  FartherTableBodyCell,
  FartherTableBodyRow,
  FartherTableContainer,
  FartherTableHead,
  FartherTableHeaderCell,
  FartherTablePagination,
  FartherTableSortLabel,
} from "./Components";
import TableRow from "./TableRow/TableRow";
import { flipTableSortOrder } from "./utils";

import * as componentStyles from "./Components.module.css";

import type { Cell, Row, TableSortOrder } from "./Types";

interface Props<Keys extends string, Cells extends Cell> {
  columns: NonEmptyArray<Keys>;
  rows?: Row<Keys, Cells>[];
  defaultSortColumn?: readonly [Keys, TableSortOrder];
  secondaryDefaultSortColumn?: readonly [Keys, TableSortOrder];
  disableColumnSorting?: readonly Keys[];
  defaultRowsPerPage?: number;
  emptyCell: React.ReactNode;
  columnStyles?: (
    key: Keys,
    index: number,
    arr: Readonly<Keys[]>
  ) => { className?: string; style?: React.CSSProperties } | undefined;
  hidePagination?: boolean;
  className?: string;
  isLoading?: boolean;
}

const defaultSortOrder: TableSortOrder = "asc";

const systemDefaultRowsPerPage: number = 10;

/**
 * This creates a function that knows how sort any of the following types
 * string | SophisticatedCell | FormattedString
 *
 * By default we do a case-insensitive sort
 */
const extractSortableValue =
  <Keys extends string, C extends Cell>(sortOnColumn: Keys) =>
  (a: Row<Keys, C>): string | number => {
    const value = a[sortOnColumn];
    if (typeof value === "object") {
      if ("fullValue" in value) {
        return value.fullValue.toLowerCase();
      }

      if (typeof value.value === "string") {
        return value.value.toLowerCase();
      }

      // better to not sort vs crashing the render loop with the `throw Error()`
      console.error(
        "Trying to sort on object - React.ReactNode, column:",
        sortOnColumn
      );
      return "";
    }

    const valueAsNumber = Number(value.replace(/[$,]/g, ""));

    if (isNaN(valueAsNumber)) {
      return value.toLowerCase();
    } else {
      return valueAsNumber;
    }
  };

const defaultStartPage = 0;

export default function FullDataTable<Keys extends string, C extends Cell>({
  defaultRowsPerPage = systemDefaultRowsPerPage,
  ...props
}: PropsWithChildren<Props<Keys, C>>): JSX.Element {
  const { search: searchParam } = useLocation();
  const { queryStringPage, queryStringSort, queryStringRowsPerPage } =
    useMemo(() => {
      const defaultParams = new URLSearchParams(searchParam);

      const defaultPage = defaultParams.get("page");
      const defaultSortOn = defaultParams.get("sortOn");
      const defaultSortDir = defaultParams.get("sortDir");
      const defaultRowsPerPage = defaultParams.get("rowsPerPage");

      let defaultSort: [Keys, TableSortOrder] | null = null;

      if (
        defaultSortOn !== null &&
        defaultSortDir !== null &&
        props.columns.includes(defaultSortOn as Keys) &&
        (defaultSortDir === "asc" || defaultSortDir === "desc")
      ) {
        defaultSort = [defaultSortOn as Keys, defaultSortDir];
      }

      return {
        queryStringPage:
          defaultPage !== null ? parseInt(defaultPage) : undefined,
        queryStringSort: defaultSort,
        queryStringRowsPerPage:
          defaultRowsPerPage !== null
            ? parseInt(defaultRowsPerPage)
            : undefined,
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  const defaultSort: readonly [Keys, TableSortOrder] = useMemo(
    () => props.defaultSortColumn ?? [props.columns[0], "asc"],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [sortOnColumn, setSortOnColumn] = useState<
    readonly [Keys, TableSortOrder]
  >(queryStringSort ?? props.defaultSortColumn ?? defaultSort);

  useEffect(() => {
    if (props.secondaryDefaultSortColumn) {
      setSortOnColumn(props.secondaryDefaultSortColumn);
    }
  }, [props.secondaryDefaultSortColumn]);

  const [page, setPage] = useState(queryStringPage ?? defaultStartPage);
  const [rowsPerPage, setRowsPerPage] = useState(
    queryStringRowsPerPage ?? defaultRowsPerPage
  );
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const totalPages = useMemo(
    () => Math.ceil((props.rows?.length ?? 0) / rowsPerPage) - 1,
    [props.rows?.length, rowsPerPage]
  );

  const currentRows = useRef<Row<Keys, C>[] | null>(null);

  const history = useHistory();

  useEffect(() => {
    const location = history.location;
    const params = new URLSearchParams(location.search);

    params.set("page", page.toString());
    if (page === defaultStartPage) {
      params.delete("page");
    }

    params.set("sortOn", sortOnColumn[0]);
    params.set("sortDir", sortOnColumn[1]);

    if (
      sortOnColumn[0] === defaultSort[0] &&
      sortOnColumn[1] === defaultSort[1]
    ) {
      params.delete("sortOn");
      params.delete("sortDir");
    }

    params.set("rowsPerPage", rowsPerPage.toString());
    if (rowsPerPage === systemDefaultRowsPerPage) {
      params.delete("rowsPerPage");
    }

    history.replace({ ...location, search: params.toString() });
  }, [defaultSort, history, page, rowsPerPage, sortOnColumn]);

  useEffect(() => {
    if (currentRows.current !== null && props.rows !== undefined) {
      const currentRowsMinusOnClick = currentRows.current.map((row) =>
        omit(row, ["onClick"])
      );

      const rowsMinusOnClick = currentRows.current.map((row) =>
        omit(row, ["onClick"])
      );

      const sameData = isEqual(currentRowsMinusOnClick, rowsMinusOnClick);

      if (!sameData) {
        // reset table view when incoming data changes
        setPage(0);
        setRowsPerPage(defaultRowsPerPage);
        setExpandedRows([]);
        // do not return early, we must update `currentRows`
      }
    }

    if (props.rows !== undefined) {
      currentRows.current = props.rows;
    }
  }, [defaultRowsPerPage, props.rows]);

  useEffect(() => {
    // this is used for when other components set the "page" query param so that this table gets set to that page
    const params = new URLSearchParams(history.location.search);
    const pageQueryParam = params.get("page");
    const newPage = pageQueryParam ? parseInt(pageQueryParam) : null;
    if (newPage !== null && newPage !== page) {
      setPage(newPage);
    }
    // eslint-disable-next-line
  }, [history.location.search]);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const sortedRows = useMemo(() => {
    const columnToSort = sortOnColumn[0];

    const rows = props.rows ?? [];

    const sorted = (props.disableColumnSorting ?? []).includes(columnToSort)
      ? rows
      : orderBy(rows, [extractSortableValue(columnToSort)], [sortOnColumn[1]]);

    const curPage = props.isLoading ? 0 : page;

    const sliced = sorted.slice(
      curPage * rowsPerPage,
      rowsPerPage > 0 ? page * rowsPerPage + rowsPerPage : undefined
    );
    return sliced;
  }, [
    page,
    props.disableColumnSorting,
    props.rows,
    rowsPerPage,
    sortOnColumn,
    props.isLoading,
  ]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const curPage = props.isLoading ? 0 : page;
  const emptyRows =
    curPage > 0
      ? Math.max(0, (1 + curPage) * rowsPerPage - (props.rows ?? []).length)
      : 0;

  const hasExpandableRows = useMemo(
    () =>
      (props.rows ?? []).some(
        (r) =>
          "expandableDetails" in r && React.isValidElement(r.expandableDetails)
      ),
    [props.rows]
  );

  return (
    <FartherTableContainer>
      <FartherTable className={props.className}>
        {props.children}

        <FartherTableHead>
          {props.columns.map((h, idx, arr) => (
            <FartherTableHeaderCell
              key={h}
              className={props.columnStyles?.(h, idx, arr)?.className}
              style={props.columnStyles?.(h, idx, arr)?.style}
            >
              <FartherTableSortLabel
                disabled={(props.disableColumnSorting ?? []).includes(h)}
                active={sortOnColumn[0] === h}
                direction={sortOnColumn[1]}
                onClick={() => {
                  if (sortOnColumn[0] === h) {
                    const newDirection = flipTableSortOrder(sortOnColumn[1]);
                    setSortOnColumn([sortOnColumn[0], newDirection]);
                  } else {
                    setSortOnColumn([h, defaultSortOrder]);
                  }
                  setPage(0);
                }}
              >
                {h}
              </FartherTableSortLabel>
            </FartherTableHeaderCell>
          ))}

          {hasExpandableRows ? (
            <FartherTableHeaderCell
              className={componentStyles.expandableColumn}
            />
          ) : null}
        </FartherTableHead>

        <FartherTableBody>
          {sortedRows.map((row) => (
            <Fragment key={row.key}>
              <FartherTableBodyRow
                columns={props.columns}
                row={row}
                {...(hasExpandableRows
                  ? expandedRows.includes(row.key)
                    ? {
                        expandedState: "Expanded",
                        expandedToggle: () =>
                          setExpandedRows(
                            expandedRows.filter((r) => r !== row.key)
                          ),
                      }
                    : {
                        expandedState: "Compact",
                        expandedToggle: () =>
                          setExpandedRows([...expandedRows, row.key]),
                      }
                  : { expandedState: "NotExpandable" })}
              >
                {"expandableDetails" in row ? row.expandableDetails : null}
              </FartherTableBodyRow>
            </Fragment>
          ))}

          {Array.from({ length: emptyRows }).map((_, idx) => (
            <TableRow key={idx}>
              {() =>
                props.columns.map((h) => (
                  <FartherTableBodyCell key={h}>
                    <span style={{ visibility: "hidden" }}>
                      {props.emptyCell}
                    </span>
                  </FartherTableBodyCell>
                ))
              }
            </TableRow>
          ))}
        </FartherTableBody>
      </FartherTable>

      <FartherTablePagination
        pageSize={rowsPerPage}
        currentPage={props.isLoading ? 0 : page}
        totalCount={props.rows?.length ?? 0}
        onPageChange={(page) => handleChangePage(null, page)}
        isNextButtonDisabled={props.isLoading ? true : page >= totalPages}
        isPrevButtonDisabled={props.isLoading ? true : page === 0}
        hidePagination={props.hidePagination}
      />
    </FartherTableContainer>
  );
}
