import React, { useState } from "react";

import LinkIcon from "@mui/icons-material/Link";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import SyncIcon from "@mui/icons-material/Sync";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import isFunction from "lodash/isFunction";

import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import styles from "./Attachment.module.css";

interface AttachmentProps {
  url: string;
  name: string;
  isLoading?: boolean;
  onRemove?: () => void;
}

export default function Attachment({
  url,
  name,
  isLoading,
  onRemove,
}: AttachmentProps): JSX.Element {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = (): void => {
    setIsHovering(true);
  };

  const handleMouseLeave = (): void => {
    setIsHovering(false);
  };

  const handleRemoveAttachment = (): void => {
    onRemove?.();
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <LinkIcon className={styles.linkIcon} />
      <Link className={styles.attachment} href={url} target="_blank">
        {name}
      </Link>
      {isHovering && isFunction(onRemove) && !isLoading && (
        <Tooltip tooltipText="Remove File" placement="top">
          <RemoveCircleIcon
            className={styles.removeIcon}
            onClick={handleRemoveAttachment}
          />
        </Tooltip>
      )}
      {isLoading && <SyncIcon className={styles.loadingIcon} />}
    </Stack>
  );
}
