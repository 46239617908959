import { head } from "lodash";

import { Data } from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Types";

const createBalanceSummary = (
  summary: Data[],
  enableBdPerformancePassthrough: boolean
) => {
  return summary
    .map((el, _, array) => ({
      ...el,
      // remove the initial deposit values as we will sync this to the first point's `balanceOnEndDay`
      depositsSinceStartDay:
        el.depositsSinceStartDay - (head(array)?.depositsSinceStartDay ?? 0),
      withdrawalsSinceStartDay:
        el.withdrawalsSinceStartDay -
        (head(array)?.withdrawalsSinceStartDay ?? 0),
    }))
    .map((el, i, array) => {
      const offset = array[0].balanceOnEndDay - array[0].netAdditions; // want the netAdditions to start at same y-axis point as the balance - this is only for graph display purposes - the table will always show absolute netAdditions for a given date (no offset applied)
      const netAdditions = array[i].netAdditions + offset;

      const relativeContributions =
        i === 0
          ? array[0].balanceOnEndDay
          : array[0].balanceOnEndDay +
            array[i].depositsSinceStartDay -
            array[i].withdrawalsSinceStartDay;

      return {
        ...el,
        relativeContributions: enableBdPerformancePassthrough
          ? netAdditions
          : relativeContributions,
      };
    });
};

export default createBalanceSummary;
