import React from "react";

import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import FeedNotifications from "../../Navbar/FeedNotifications";
import useApplicationMode from "@src/multiCustodian/hooks/useApplicationMode";
import { trackEvent } from "@src/multiCustodian/services/tracking";
import LogoWordmark from "@src/multiCustodian/theme/LogoWordmark";
import { State } from "@src/store";

import styles from "./TopBox.module.css";

interface Props {
  showNotifications?: boolean;
}

const TopBox: React.FC<Props> = ({ showNotifications = false }: Props) => {
  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));

  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const { isAdvisorMode } = useApplicationMode();

  const onTrackEvent = () => {
    trackEvent({
      name: isAdvisorMode
        ? "Advisor Click Farther-logo"
        : "Client Click Farther-logo",
    });
  };

  return (
    <div className={styles.container}>
      <NavLink
        to={
          isAdvisorMode ? "/Advisor/Clients" : `/Client/${clientId}/Dashboard`
        }
        onClick={onTrackEvent}
      >
        <LogoWordmark className={styles.fartherLogo} />
      </NavLink>

      {showNotifications && advisorId ? (
        <FeedNotifications personId={advisorId} />
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default TopBox;
