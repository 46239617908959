import React, { useState } from "react";

import { AccountType } from "@fartherfinance/frontend/api/Transfer/requests/Types";
import { FartherAccountId } from "@fartherfinance/frontend/api/Types";

import ButtonPrimary from "../../../../../components/MUI/Button/Button";
import {
  AcatsInstitutionInfo,
  IraType,
  SetScreenAndTransferType,
  TransferScreen,
} from "../../Types";
import { captureException } from "@src/multiCustodian/services/tracking";

import "./TransferVerification.css";

type Props = (
  | {
      transferType: "roll over";
      iraType: IraType;
    }
  | { transferType: "transfer" }
) & {
  screen: TransferScreen["screen"];
  acatsInstitution: AcatsInstitutionInfo;
  acatsAccountType: AccountType;
  accountHolderName: string;
  accountNumber: string;
  toAccountId: FartherAccountId;
  setCurScreen: SetScreenAndTransferType;
};

const TransferVerification = (props: Props): JSX.Element => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);

  const uploadFile = (): void => {
    const inputField = document.querySelector(
      "input[type=file]"
    ) as HTMLElement;
    inputField?.click();
  };

  const fileReady = (): void => {
    const fileInputElement = document.querySelector(
      "input[type=file]"
    ) as HTMLInputElement;

    const file =
      fileInputElement !== null && fileInputElement.files !== null
        ? fileInputElement.files[0]
        : null;

    if (file === null) {
      const e = new Error("[Verification.tsx], fileReady: file is null");
      captureException(e);
      throw e;
    }

    const filename = file.name;
    setFileName(filename);
    setFile(file);
  };

  const onContinue = (): void => {
    if (file === null || fileName === null) {
      return;
    }

    const { setCurScreen: _, screen: _s, ...screenInfo } = props;

    if ("iraType" in screenInfo) {
      props.setCurScreen({
        screen: "IRA/transferInitiated",
        statement: file,
        uploadFilename: fileName,
        ...screenInfo,
      });
    } else {
      props.setCurScreen({
        screen: "Investment/transferInitiated",
        statement: file,
        uploadFilename: fileName,
        ...screenInfo,
      });
    }
  };

  return (
    <div className="Dashboard_Acats_Verification_page__container">
      <React.Fragment>
        <div className="Dashboard_Acats_Verification_page__content_container">
          <p className="Dashboard_Acats_Verification_page__title">
            Upload account statement
          </p>

          <p className="Dashboard_Acats_Verification_page__summary">
            In the case your institution attempts to reject your{" "}
            {props.transferType}, we got you covered if you upload a document
            with your most recent account information that includes your:
          </p>

          <div className="Dashboard_Acats_Verification_page__negative_spacing"></div>

          <div className="Dashboard_Acats_Initiated_page__checklist">
            <div className="Dashboard_Acats_Initiated_page__flex_row_div">
              <div className="Dashboard_Acats_Initiated_page__orange_number">
                1
              </div>
              <p className="Dashboard_Acats_Initiated_page__text_2">Name</p>
            </div>
            <div className="Dashboard_Acats_Initiated_page__flex_row_div">
              <div className="Dashboard_Acats_Initiated_page__orange_number">
                2
              </div>
              <p className="Dashboard_Acats_Initiated_page__text_2">
                Account Number
              </p>
            </div>
            <div className="Dashboard_Acats_Initiated_page__flex_row_div">
              <div className="Dashboard_Acats_Initiated_page__orange_number">
                3
              </div>
              <p className="Dashboard_Acats_Initiated_page__text_2">
                Institution Name
              </p>
            </div>
          </div>

          <div className="Dashboard_Acats_Verification_page__negative_spacing_2"></div>

          <div className="Dashboard_Acats_Verification_page__flex_row_div_4">
            <img
              src="/src/assets/svg/doc_icon.svg"
              className="Dashboard_Acats_Verification_page__img"
            ></img>

            <p className="Dashboard_Acats_Verification_page__text_3_gray">
              Account Verification
            </p>

            <img
              src="/src/assets/svg/info.svg"
              className="Dashboard_Acats_Verification_page__tooltip_img"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            ></img>
            {showTooltip && (
              <div
                className="Dashboard_Acats_Verification_page__tooltip"
                style={{ marginLeft: "210px", marginTop: "25px" }}
              >
                For file format extensions, we accept pdf, png, jpg, and jpeg
                with your upload.
              </div>
            )}
          </div>

          <div className="Dashboard_Acats_Verification_page__flex_row_div">
            <p
              className="Dashboard_Acats_Verification_page__choose_file_button"
              onClick={uploadFile}
            >
              Choose file
            </p>

            <input
              type="file"
              accept=".png, .jpg, .jpeg, .pdf"
              id="Dashboard_Acats_pages__file_input"
              onChange={fileReady}
              className="Dashboard_Acats_Verification_page__html_input"
            />

            <p
              className="Dashboard_Acats_Verification_page__file_text"
              style={{
                color: fileName
                  ? "var(--primary_text_color_1)"
                  : "var(--primary_text_color_2)",
              }}
            >
              {fileName ? fileName : "No file chosen"}
            </p>
          </div>

          <div className="Dashboard_Acats_Verification_page__button_container_2">
            <div
              className="Dashboard_Acats_Verification_page__back_button_container"
              onClick={() => {
                const { setCurScreen: _, screen: _s, ...screenInfo } = props;

                if ("iraType" in screenInfo) {
                  props.setCurScreen({
                    screen: "IRA/optionalInfo",
                    ...screenInfo,
                  });
                } else {
                  props.setCurScreen({
                    screen: "Investment/optionalInfo",
                    ...screenInfo,
                  });
                }
              }}
            >
              <img src="/src/assets/svg/left_arrow.svg"></img>
              <div className="Dashboard_Acats_Verification_page__back_button">
                Back
              </div>
            </div>

            <ButtonPrimary
              //disabled={buttonStyleBad}
              variant={"contained"}
              buttonType={"primary"}
              text={"Continue"}
              onClick={() => onContinue()}
            />
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

export default TransferVerification;
