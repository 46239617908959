import { ClientInvestorProfile } from "@fartherfinance/frontend/api/Entity/requests/getClientProfile";

import formatPhoneNumber from "../../formatters/formatPhoneNumber";
import { Form } from "../Types";

export const mapDefaultValues = (profile: ClientInvestorProfile): Form => {
  const {
    contactInformation: { emailAddress, phoneNumber },
    address,
  } = profile;

  const PhoneNumber =
    phoneNumber === null ? null : formatPhoneNumber("", phoneNumber);

  return {
    Email: emailAddress,
    PhoneNumber,
    Address1: address?.street ?? null,
    Address2: address?.unit ?? null,
    City: address?.city ?? null,
    State: address !== null ? { label: address.state } : null,
    ZipCode: address?.postalCode ?? null,
  };
};
