import React from "react";

import { useAddCollaboratorContext } from "../reducer/Context";

import Complete from "./Icons/Complete";
import InProgress from "./Icons/InProgress";
import NotStarted from "./Icons/NotStarted";

import styles from "./FlowProgressBar.module.css";

const FlowProgressBar: React.FC = () => {
  const {
    state: { pageIndex },
  } = useAddCollaboratorContext();

  let img1 = <InProgress />;
  let text1 = <div className={styles.activeComplete}>Add Collaborator</div>;
  let img2 = <NotStarted />;
  let text2 = <div className={styles.notStarted}>Access & Type</div>;

  if (pageIndex === 1) {
    img1 = <Complete />;
    text1 = <div className={styles.activeComplete}>Add Collaborator</div>;
    img2 = <InProgress />;
    text2 = <div className={styles.activeComplete}>Access & Type</div>;
  } else if (pageIndex === 2) {
    img1 = <Complete />;
    text1 = <div className={styles.activeComplete}>Add Collaborator</div>;
    img2 = <Complete />;
    text2 = <div className={styles.activeComplete}>Access & Type</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.icon}>{img1}</div>

        {text1}

        <div className={styles.horizLine} />

        <div className={styles.icon}>{img2}</div>

        {text2}
      </div>
    </div>
  );
};

export default FlowProgressBar;
