import React, { useState } from "react";

import HelloSign from "hellosign-embedded";
import { isNil } from "lodash";

import useDeleteDocumentV2 from "@fartherfinance/frontend/api/Accounts/hooks/useDeleteDocumentV2";
import useUpdatePlanStatusV2 from "@fartherfinance/frontend/api/Accounts/hooks/useUpdatePlanStatusV2";
import useUpdateSignatureStatusV2 from "@fartherfinance/frontend/api/Accounts/hooks/useUpdateSignatureStatusV2";
import useUploadCustomPlanDocumentV2 from "@fartherfinance/frontend/api/Accounts/hooks/useUploadCustomPlanDocumentV2";
import useUploadPlanDocumentV2 from "@fartherfinance/frontend/api/Accounts/hooks/useUploadPlanDocumentV2";
import { PlanDetails } from "@fartherfinance/frontend/api/Accounts/requests/getPlanDetailsV2";
import {
  CustomDocumentType,
  customDocumentTypeOptions,
} from "@fartherfinance/frontend/api/Accounts/requests/uploadCustomPlanDocumentV2";
import {
  documentTypeOptions,
  RegularDocumentType,
} from "@fartherfinance/frontend/api/Accounts/requests/uploadPlanDocumentV2";
import {
  AgreementId,
  CustodianAccountId,
  FartherAccountId,
  PlanId,
} from "@fartherfinance/frontend/api/Types";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { captureException } from "@src/multiCustodian/services/tracking";
import Modal from "@src/sharedComponents/Modal/Modal";
import SAODropdown from "@src/sharedComponents/SAODropdown/Dropdown";
import SingleFileDropUpload from "@src/sharedComponents/SingleFileDropUpload/SingleFileDropUpload";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./SequencedAccountOpeningDocUploadModal.module.css";

const isNotNull = (
  x: CustodianAccountId | null | undefined
): x is CustodianAccountId => {
  return !isNil(x);
};

const allDocumentTypeOptions = [
  ...documentTypeOptions,
  ...customDocumentTypeOptions,
].sort();

type DocumentTypeOption = typeof allDocumentTypeOptions[number];

const isDocumentRegular = (
  docType: DocumentTypeOption | ""
): docType is RegularDocumentType => {
  return documentTypeOptions.includes(docType as RegularDocumentType);
};

const isDocumentCustom = (
  docType: DocumentTypeOption | ""
): docType is CustomDocumentType => {
  return customDocumentTypeOptions.includes(docType as CustomDocumentType);
};

export interface AccountIdsAndCustodianIds {
  accountId: FartherAccountId;
  custodianAccountNumber: CustodianAccountId | null | undefined;
}

const packetOptions = [
  "E-Signature",
  "Click to sign",
  "Offline Signature",
] as const;
type PacketOptions = typeof packetOptions[number];

interface DocSignData {
  agreementIds: AgreementId[];
  signatureId: string;
  signatureUrl: string;
}

interface Props {
  planId: PlanId;
  fartherAccounts: AccountIdsAndCustodianIds[];
  planDetails: PlanDetails | null;
  closeModal: () => void;
}

const SequencedAccountOpeningDocUploadModal = (props: Props): JSX.Element => {
  const [isMutating, setIsMutating] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [documentType, setDocumentType] = useState<DocumentTypeOption | "">("");
  const [selectedPacket, setSelectedPacket] =
    useState<PacketOptions>("E-Signature");
  const [custodianAccountNumber, setCustodianAccountNumber] =
    useState<CustodianAccountId | null>(null);

  const statusNotification = useStatusNotification();

  const auth = useAdvisorRequestAuth();

  const updatePlanDocumentSignatureStatus = useUpdateSignatureStatusV2(auth);

  const uploadPlanDocument = useUploadPlanDocumentV2(auth);

  const uploadCustomPlanDocument = useUploadCustomPlanDocumentV2(auth);

  const putUpdatePlanStatus = useUpdatePlanStatusV2(auth);

  const callDeleteDocument = useDeleteDocumentV2(auth);

  const startHelloSign = (docSignData: DocSignData) => {
    if (docSignData === null) {
      statusNotification("Document added to plan", "Success");
      setIsMutating(false);
      return;
    }

    const client = new HelloSign();
    client.open(docSignData.signatureUrl, {
      clientId: process.env.HELLOSIGN_CLIENT_ID,
      skipDomainVerification: process.env.WEBAPP_ENV !== "PROD",
    });

    client.on("send", async () => {
      try {
        const results = await Promise.allSettled(
          docSignData.agreementIds.map((agreementId) =>
            updatePlanDocumentSignatureStatus({
              planId: props.planId,
              agreementId: agreementId,
              signatureStatus: "Planning",
            })
          )
        );

        const allSucceeded =
          results.filter((res) => res.status !== "fulfilled").length === 0;

        results.forEach((result, i) => {
          if (result.status === "fulfilled") {
            setTimeout(() => {
              statusNotification("Document added to plan", "Success");
            }, i * 500);
          } else {
            // "rejected"
            const errorMessage =
              "uploadModal, updatePlanDocumentSignatureStatus in startHelloSign failed to update document status.";
            captureException(new Error(errorMessage));
            console.error(errorMessage);

            setTimeout(() => {
              statusNotification("Document not added to plan", "Error");
            }, i * 500);
          }
        });

        if (
          props.planDetails?.plan.planStatus === "Not Started" &&
          allSucceeded
        ) {
          await putUpdatePlanStatus({
            planId: props.planId,
            newStatus: "In Progress",
          });
        }
      } catch (e) {
        const errorMessage =
          "uploadModal, updatePlanDocumentSignatureStatus in startHelloSign failed to update plan status.";
        captureException(new Error(errorMessage));
        console.error(errorMessage, e);
      } finally {
        setIsMutating(false);
        props.closeModal();
      }
    });

    client.on("cancel", async () => {
      try {
        await Promise.all(
          docSignData.agreementIds.map((agreementId) =>
            callDeleteDocument({
              planId: props.planId,
              agreementId: agreementId,
            })
          )
        );

        statusNotification("Documents were not added to plan", "Error");
      } catch (e) {
        const errorMessage =
          "uploadModal, useDeleteDocument in HelloSign failed to remove document.";
        captureException(new Error(errorMessage));
        console.error(errorMessage, e);
      } finally {
        setIsMutating(false);
      }
    });

    client.on("error", async () => {
      try {
        const errorMessageHS =
          "HelloSign exited with error in SAO advisor login";
        captureException(new Error(errorMessageHS));

        await Promise.all(
          docSignData.agreementIds.map((agreementId) =>
            callDeleteDocument({
              planId: props.planId,
              agreementId: agreementId,
            })
          )
        );

        statusNotification("HelloSign error - please try again", "Error");
      } catch (e) {
        const errorMessage =
          "uploadModal, useDeleteDocument in HelloSign failed to remove document after HelloSign error.";
        captureException(new Error(errorMessage));
        console.error(errorMessage, e);
      } finally {
        setIsMutating(false);
      }
    });
  };

  const addPlanDocument = async () => {
    if (file === null) {
      console.error("addPlanDocument, this.state.file === null");
      return;
    }

    setIsMutating(true);

    if (
      props.planDetails?.plan.planHolder.clientId &&
      isDocumentCustom(documentType)
    ) {
      const response = await uploadCustomPlanDocument({
        planId: props.planId,
        clientIds: [props.planDetails?.plan.planHolder.clientId],
        documentType: documentType,
        document: file,
      });

      if (response === undefined) {
        const errorMessage =
          "uploadModal.tsx, uploadCustomPlanDocument returned undefined.";
        captureException(new Error(errorMessage));
        throw new Error(errorMessage);
      }

      startHelloSign(response);
    } else {
      if (
        !isDocumentRegular(documentType) ||
        !props.planDetails?.plan.planHolder.clientId
      ) {
        setIsMutating(false);
        return;
      }

      const selectedAccount = props.fartherAccounts.find(
        (acc) => acc.custodianAccountNumber === custodianAccountNumber
      );

      if (!selectedAccount) {
        statusNotification("This account can't be found", "Error");

        setIsMutating(false);

        return captureException(
          "An incorrect custodianAccountNumber was provided",
          {
            "state.custodianAccountNumber": custodianAccountNumber,
            "planDetails.custodianAccountNumber":
              props.planDetails?.resources.accounts.map(
                (a) => a.custodianAccountNumber
              ),
            accountsLength:
              props.planDetails?.resources.accounts.length ?? "undefined",
          }
        );
      }

      const response = await uploadPlanDocument({
        planId: props.planId,
        virtualAccountId: selectedAccount.accountId,
        clientIds: [props.planDetails.plan.planHolder.clientId],
        documentType: documentType,
        document: file,
      });

      if (response === undefined) {
        const errorMessage =
          "uploadModal.tsx, uploadPlanDocument returned undefined.";
        captureException(new Error(errorMessage));
        throw new Error(errorMessage);
      }

      startHelloSign(response);
    }
  };

  let disabled = false;
  if (
    (!isDocumentCustom(documentType) &&
      (custodianAccountNumber === "" || custodianAccountNumber === null)) ||
    !props.planDetails?.plan.planHolder.clientId ||
    !documentType ||
    !file
  ) {
    disabled = true;
  }

  if (isMutating) {
    return (
      <Modal closeModal={props.closeModal} modalStyle={{ width: "520px" }}>
        <Skeleton width={130} className={styles.title} />

        <div className={styles.dropdownDiv}>
          <Skeleton width={100} className={styles.dropdownHeader} />

          <Skeleton width={"100%"} />
        </div>

        <div className={styles.dropdownDiv}>
          <Skeleton width={100} className={styles.dropdownHeader} />

          <Skeleton width={"100%"} />
        </div>

        {!isDocumentCustom(documentType) && (
          <div className={styles.dropdownDiv}>
            <Skeleton width={100} className={styles.dropdownHeader} />

            <Skeleton width={"100%"} />
          </div>
        )}

        <Skeleton width={"100%"} height={96} />

        <div className={styles.footer}>
          <Skeleton width={100} height={35} className={styles.marginRight} />

          <Skeleton width={100} height={35} />
        </div>
      </Modal>
    );
  }

  return (
    <Modal closeModal={props.closeModal} modalStyle={{ width: "520px" }}>
      <div className={styles.title}>Add Documents</div>

      <div className={styles.dropdownDiv}>
        <div className={styles.dropdownHeader}>Document Type</div>

        <SAODropdown
          value={{
            item: documentType,
            label: documentType,
          }}
          values={allDocumentTypeOptions.map((doc) => ({
            item: doc,
            label: doc,
          }))}
          onChange={(e) => setDocumentType(e.item)}
          style={{ width: "100%" }}
          disablePortal={false}
        />
      </div>

      <div className={styles.dropdownDiv}>
        <div className={styles.dropdownHeader}>
          {"Select A Packet (Optional)"}
        </div>

        <SAODropdown
          value={{
            item: selectedPacket,
            label: selectedPacket,
          }}
          values={packetOptions.map((packet) => ({
            item: packet,
            label: packet,
          }))}
          onChange={(e) => setSelectedPacket(e.item)}
          style={{ width: "100%" }}
          disablePortal={false}
        />
      </div>

      {!isDocumentCustom(documentType) && (
        <div className={styles.dropdownDiv}>
          <div className={styles.dropdownHeader}>{"Account #"}</div>

          <SAODropdown
            value={{
              item: custodianAccountNumber ?? "",
              label: custodianAccountNumber ?? "",
            }}
            values={props.fartherAccounts
              .map((acct) => acct.custodianAccountNumber)
              .filter(isNotNull)
              .map((num) => ({
                item: num,
                label: num,
              }))}
            onChange={(e) =>
              setCustodianAccountNumber(
                e.item === "" ? null : (e.item as CustodianAccountId)
              )
            }
            style={{ width: "100%" }}
            disablePortal={false}
          />
        </div>
      )}

      <SingleFileDropUpload
        file={file}
        text={"Upload files through"}
        pressText={"HelloSign"}
        onChange={setFile}
        acceptedFileExtensions={[".pdf", ".png", ".jpg", ".jpeg", ".heic"]}
      />

      <div className={styles.footer}>
        <Button
          variant={"outlined"}
          buttonType={"secondary"}
          text={"Cancel"}
          onClick={() => props.closeModal()}
        />

        <Button
          disabled={disabled}
          variant={"contained"}
          buttonType={"primary"}
          text={"Upload"}
          onClick={() => addPlanDocument()}
          style={{ marginLeft: "20px" }}
        />
      </div>
    </Modal>
  );
};

export default SequencedAccountOpeningDocUploadModal;
