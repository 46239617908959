import React, { useEffect, useMemo } from "react";

import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import StarIcon from "@mui/icons-material/Star";
import { useFlags } from "launchdarkly-react-client-sdk";
import { orderBy } from "lodash";
import { useSelector } from "react-redux";

import { UNKNOWN_ACCOUNT } from "@fartherfinance/frontend/api/Accounts/utilities/accountUtil";
import { ExternalAccount } from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAccounts";
import useFundingAccountExtended from "@fartherfinance/frontend/api/ExternalAccount/hooks/useFundingAccountExtended";
import useGetExternalAccounts from "@fartherfinance/frontend/api/ExternalAccount/hooks/useGetExternalAccounts";
import usePlaidLinks from "@fartherfinance/frontend/api/ExternalAccount/hooks/usePlaidLinks";
import {
  ManualAccount,
  PlaidAccount,
} from "@fartherfinance/frontend/api/ExternalAccount/requests/getAllExternalAccounts";
import { PlaidLinkStatus } from "@fartherfinance/frontend/api/ExternalAccount/requests/types";
import useInvitations from "@fartherfinance/frontend/api/Sharing/hooks/useInvitations";
import { ReceivedInvitation } from "@fartherfinance/frontend/api/Sharing/Types";
import { ClientId, InstitutionId } from "@fartherfinance/frontend/api/Types";
import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import { AccountsPath } from "@src/config/routing/RouterPaths";
import FlowerBullet from "@src/multiCustodian/components/PerformanceGroups/Projections/FlowerBullet";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";
import { State } from "@src/store";

import Accordion, { Row, specialtyIconWidth } from "./Accordion";
import PlaidNotConnectedChip from "./Components/PlaidNotConnectedChip";
import PlaidReconnectButton from "./Components/PlaidReconnectButton";
import ValueDisplay from "./Components/ValueDisplay";
import {
  getExternalPlaidAccountBalance,
  isPlaidAccount,
} from "./externalAccountHelpers";

import styles from "./Accordion.module.css";

type PlaidInstitutionIdToLinkStatus = Record<InstitutionId, PlaidLinkStatus>;

type plaidType = ExternalAccount["accountType"];

const nameLookup: Record<plaidType, string> = {
  investment: "External Investment",
  credit: "External Credit Cards",
  depository: "External Banking",
  loan: "External Loans",
  other: "External Misc.",
};

const colorLookup: Record<plaidType, string> = {
  investment: "#39D435",
  credit: "#6EA6CD",
  depository: "#FE665C",
  loan: "#142EDB",
  other: "#ffffff",
};

const makeSubDescription = (acc: PlaidAccount | ManualAccount) => {
  if (isPlaidAccount(acc)) {
    const mask = acc.details.account.accountNumberMask
      ? `(...${acc.details.account.accountNumberMask})`
      : "";
    return `${acc.details.account.name} - ${acc.details.account.accountType} ${mask}`;
  }

  const mask = acc.details.account.accountNumber.slice(
    acc.details.account.accountNumber.length - 4
  );
  return `${acc.details.bank.name} - ${acc.details.account.accountType} (...${mask})`;
};

const makeSharedSubDescription = (
  acc: PlaidAccount | ManualAccount,
  invitations?: ReceivedInvitation[]
): string => {
  const invitation = invitations?.find((invitation) =>
    invitation.resources.some((r) => r.resourceId === acc.accountId)
  );

  const name = invitation
    ? `${invitation.invitor.firstName} ${invitation.invitor.lastName}`
    : "Unknown";

  return `${makeSubDescription(acc)} shared by ${name}`;
};

interface Props {
  clientId: ClientId;
  expanded: boolean;
  setExpanded: (newState: boolean) => void;
  type: plaidType;
  isLoading: boolean;
  setLoaded: () => void;
}

export default function ExternalAccountAccordion(props: Props): JSX.Element {
  const { isAdvisor } = useSelector((state: State) => ({
    isAdvisor: state.main_Reducer.user.isAdvisor,
  }));

  const auth = useRequestAuth();

  const fundingAccount = useFundingAccountExtended(auth);

  const plaidLinks = usePlaidLinks(auth);

  const externalAccounts = useGetExternalAccounts(props.clientId, auth);

  const invitations = useInvitations(auth);

  const { showStalePlaid, showAdvisorsStalePlaid } = useFlags();

  const showPlaidReconnect = isAdvisor
    ? showAdvisorsStalePlaid
    : showStalePlaid;

  const institutionIdToStatus = useMemo((): PlaidInstitutionIdToLinkStatus => {
    if (plaidLinks.data === undefined) {
      return {};
    }

    return plaidLinks.data.reduce((acc, cur) => {
      return { ...acc, [cur.institutionId]: cur.status };
    }, {});
  }, [plaidLinks.data]);

  const {
    color: { $icon, $iconSubtle },
  } = useTheme();

  const { setLoaded } = props;

  useEffect(() => {
    if (!externalAccounts.isLoading) {
      setLoaded();
    }
  }, [externalAccounts.isLoading, setLoaded]);

  const visiblePlaidAccounts = useMemo(() => {
    if (externalAccounts.isLoading || externalAccounts.hasError) {
      return externalAccounts;
    }

    const onlyPlaid = externalAccounts.data.accounts.filter(isPlaidAccount);

    const visible = onlyPlaid.filter((acc) => acc.details.account.isVisible);

    const correctType = visible.filter(
      (a) => a.details?.account.accountType === props.type
    );

    return {
      ...externalAccounts,
      data: orderBy(
        correctType,
        [
          (a): string =>
            a.details?.account.name ?? a.details?.account.officialName ?? "",
          (a) => a.accountId,
        ],
        ["asc", "asc"]
      ),
    };
  }, [externalAccounts, props.type]);

  if (visiblePlaidAccounts.hasError) {
    return (
      <Accordion
        expanded={props.expanded}
        setExpanded={props.setExpanded}
        icon={
          <FlowerBullet
            colorHex={colorLookup[props.type]}
            wH={specialtyIconWidth}
          />
        }
        title={nameLookup[props.type]}
        total={null}
        rows={[
          {
            key: "0",
            description: (
              <div className={styles.errorNoExternalAccounts}>
                There was an error retrieving your external accounts.
              </div>
            ),
            subDescription: "",
            elementRightOfLeftContent: undefined,
            elementLeftOfRightContent: undefined,
            value: null,
            valueDisplay: <></>,
            icon: undefined,
            linkTo: undefined,
          },
        ]}
        isExternalAccount={true}
      />
    );
  }

  if (visiblePlaidAccounts.isLoading || props.isLoading) {
    return <Skeleton width={"100%"} height={60} />;
  }

  const total = visiblePlaidAccounts.data.reduce((accum, curr) => {
    const balance = getExternalPlaidAccountBalance(curr) ?? 0;
    return accum + balance;
  }, 0);

  const shouldNegateValue =
    (props.type === "loan" || props.type === "credit") && total > 0;

  return (
    <Accordion
      expanded={props.expanded}
      setExpanded={props.setExpanded}
      icon={
        <FlowerBullet
          colorHex={colorLookup[props.type]}
          wH={specialtyIconWidth}
        />
      }
      title={nameLookup[props.type]}
      total={shouldNegateValue ? total * -1 : total}
      rows={visiblePlaidAccounts.data.map((a): Row => {
        const details = a.details?.account;
        const name = details?.name ?? details?.officialName ?? UNKNOWN_ACCOUNT;

        const linkStatus: PlaidLinkStatus | undefined =
          institutionIdToStatus[a.details.institutionId];

        const needsRelinking =
          linkStatus === "error" || linkStatus === "expired";

        const isShared = a.clientId !== props.clientId;

        const balance = getExternalPlaidAccountBalance(a);

        return {
          key: a.accountId,
          description: name,
          subDescription: isShared
            ? makeSharedSubDescription(a, invitations.data?.received)
            : makeSubDescription(a),
          elementRightOfLeftContent:
            needsRelinking && showPlaidReconnect ? (
              <PlaidNotConnectedChip />
            ) : undefined,
          elementLeftOfRightContent:
            needsRelinking && showPlaidReconnect ? (
              <PlaidReconnectButton />
            ) : undefined,
          value: balance ?? 0,
          valueDisplay: <ValueDisplay account={a} balance={balance} />,
          icon: isShared ? (
            <Tooltip tooltipText={"This is shared with you"}>
              <PeopleOutlineOutlinedIcon
                style={{
                  width: specialtyIconWidth,
                }}
                sx={{
                  color: $iconSubtle,
                  "&:hover": {
                    color: $icon,
                  },
                }}
              />
            </Tooltip>
          ) : fundingAccount.data?.accountId === a.accountId ? (
            <Tooltip tooltipText="This is your funding account">
              <StarIcon
                sx={{
                  color: $iconSubtle,
                  "&:hover": {
                    color: $icon,
                  },
                  width: `${specialtyIconWidth}px`,
                }}
              />
            </Tooltip>
          ) : undefined,
          linkTo: `/Client/${props.clientId}/${AccountsPath}/External/${a.accountId}/Remove`,
        };
      })}
      isExternalAccount={true}
    />
  );
}
