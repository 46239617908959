type FinScoreLabel =
  | "Conservative"
  | "Moderately Conservative"
  | "Balanced"
  | "Moderately Aggressive"
  | "Aggressive";

const finScoreToLabel = (
  finScore: number | undefined
): FinScoreLabel | "N/A" => {
  if (finScore === undefined) {
    return "N/A";
  }

  if (finScore <= 20) {
    return "Conservative";
  } else if (finScore <= 40) {
    return "Moderately Conservative";
  } else if (finScore <= 60) {
    return "Balanced";
  } else if (finScore <= 80) {
    return "Moderately Aggressive";
  } else {
    // 81-100
    return "Aggressive";
  }
};

export default finScoreToLabel;
