import React, { useCallback, useEffect, useMemo, useState } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, FormHelperText, Stack } from "@mui/material";
import { every } from "lodash";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { Partner } from "@fartherfinance/frontend/api/Dashboard/requests/getClientDashboard";
import useCreateFartherEmployee from "@fartherfinance/frontend/api/Entity/hooks/useCreateFartherEmployee";
import {
  Request as EmployeeRequest,
  serviceTeamSupportsOrg,
} from "@fartherfinance/frontend/api/Entity/requests/postFartherEmployee";
import {
  ServiceTeam,
  serviceTeamOptions,
} from "@fartherfinance/frontend/api/Entity/Types";
import { partners } from "@fartherfinance/frontend/api/Types";

import Drawer from "../../Drawer/Drawer";
import ButtonPrimary from "../../MUI/Button/Button";
import { emailValidationRegex } from "@src/multiCustodian/components/Advisor/ClientProfile/Forms/constants";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import FormFieldLabel from "@src/sharedComponents/Forms/Private/FormFieldLabel";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import FileDropUploadComponent from "@src/sharedComponents/SingleFileDropUpload/SingleFileDropUpload";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import styles from "./CreateCXMemberPage.module.css";

const FirstName = "FirstName";
const LastName = "LastName";
const FartherEmail = "FartherEmail";
const OrganizationField = "OrganizationField";
const ServiceTeamField = "ServiceTeam";

interface Form {
  [FirstName]: string | undefined;
  [LastName]: string | undefined;
  [FartherEmail]: string | undefined;
  [OrganizationField]: { label: Partner } | undefined;
  [ServiceTeamField]: { label: ServiceTeam } | undefined;
}

type ValidForm = StripUndefined<Form>;

const formIsValid = (f: Form): f is ValidForm => {
  return every(f, (v) => v !== undefined);
};

const CreateCXMemberPage = (): JSX.Element => {
  const history = useHistory();

  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [imgError, setImgError] = useState<string | null>(null);

  const [mutating, setMutating] = useState(false);

  const auth = useAdvisorRequestAuth();

  const createFartherEmployee = useCreateFartherEmployee(auth);

  const methods = useForm<Form>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      [OrganizationField]: { label: "Farther" },
    },
  });

  const statusNotification = useStatusNotification();

  const {
    control,
    getValues,
    formState: { isValid, isDirty },
    resetField,
  } = methods;

  const organization = useWatch({ control, name: OrganizationField });
  const serviceTeam = useWatch({ control, name: ServiceTeamField });

  const serviceTeams = useMemo(() => {
    if (organization === undefined) {
      return serviceTeamOptions.map((t) => ({ label: t }));
    }

    const filteredServiceTeams = serviceTeamOptions
      .filter((t) => serviceTeamSupportsOrg(t, organization.label))
      .map((t) => ({ label: t }));

    resetField(ServiceTeamField); //Must reset the field *Before* returning the new dropdown options, otherwise we may get an MUI error (If the selected value doesn't exist in the dropdown options)

    return filteredServiceTeams;
  }, [resetField, organization]);

  useEffect(() => {
    if (profileImage === null) {
      setImgError(null);
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target === null || typeof event.target.result !== "string") {
        setProfileImage(null);
        setImgError("Failed to upload this file");
        return;
      }

      const img = new Image();

      img.onload = () => {
        if (img.width !== img.height) {
          setImgError(
            `The image must be square. Yours dimensions are ${img.width}x${img.height}.`
          );
        } else {
          setImgError(null);
        }
      };

      img.onerror = () => {
        setProfileImage(null);
        setImgError("Failed to upload this file. Ensure the format is PNG.");
      };

      img.src = event.target.result;
    };

    reader.readAsDataURL(profileImage);
  }, [profileImage]);

  const teamAndOrgMatch =
    organization !== undefined &&
    serviceTeam !== undefined &&
    serviceTeamSupportsOrg(serviceTeam.label, organization.label);

  const onClose = useCallback(
    () => history.push({ ...history.location, pathname: "/Advisor/Admin" }),
    [history]
  );

  const submit = async () => {
    if (mutating) {
      statusNotification("Form is being submitted", "Error");
      return;
    }

    if (!isDirty) {
      statusNotification("Nothing to submit", "Error");
      return;
    }

    if (imgError !== null) {
      statusNotification("Invalid profile image", "Error");
      return;
    }

    if (!isValid) {
      statusNotification("Some fields are incomplete", "Error");
      return;
    }

    const values = getValues();

    if (!formIsValid(values)) {
      statusNotification("Some fields are incomplete", "Error");
      return;
    }

    const form: EmployeeRequest = {
      first: values[FirstName],
      last: values[LastName],
      email: values[FartherEmail],
      serviceTeam: values[ServiceTeamField].label,
      institution: values[OrganizationField].label,
      ...(profileImage !== null ? { headshot: profileImage } : {}),
    };

    if (serviceTeamSupportsOrg(form.serviceTeam, form.institution) === false) {
      statusNotification(
        "Invalid service team selected for the organization",
        "Error"
      );
      return;
    }

    try {
      setMutating(true);

      await createFartherEmployee(form);

      statusNotification(
        `CX member ${form.first} ${form.last} created`,
        "Success"
      );

      onClose();
    } catch {
      statusNotification("Failed to create CX account", "Error");
    } finally {
      setMutating(false);
    }
  };

  return (
    <Drawer
      isDrawerOpen
      onClose={onClose}
      footer={
        <Stack direction="row" gap="16px">
          <ButtonPrimary
            text="Cancel"
            variant="outlined"
            buttonType="primary"
            onClick={onClose}
          />

          <ButtonPrimary
            disabled={
              !isDirty ||
              !isValid ||
              mutating ||
              imgError !== null ||
              teamAndOrgMatch === false
            }
            text="Create CX Member"
            variant="contained"
            buttonType="primary"
            onClick={submit}
          />
        </Stack>
      }
    >
      {mutating && <LogoLoadingStill onTop />}
      <FormProvider {...methods}>
        <div className={styles.title}>Create CX Member</div>

        <FlexWrapper>
          <FormTextField
            name={FirstName}
            label={"First Name"}
            required="Must not be empty"
          />

          <FormTextField
            name={LastName}
            label={"Last Name"}
            required="Must not be empty"
          />

          <FormTextField
            name={FartherEmail}
            label={"Farther Email"}
            required="Must not be empty"
            rules={{
              validate: {
                isValidEmail: (email) => {
                  return !emailValidationRegex.test(email ?? "")
                    ? "Please enter a valid email"
                    : true;
                },
              },
            }}
          />

          <FormDropdownField
            name={OrganizationField}
            label={"Organization"}
            values={partners.map((p) => ({ label: p }))}
            disableSearch
            required={"Must not be empty"}
          />

          <FormDropdownField
            name={ServiceTeamField}
            label={"Service Team"}
            values={serviceTeams}
            required={"Must not be empty"}
            rules={{
              deps: [OrganizationField],
              validate: {
                teamSupportsOrg: (
                  team: { label: ServiceTeam } | undefined,
                  form
                ) => {
                  const org = form[OrganizationField];

                  if (team === undefined || org === undefined) {
                    return true;
                  }

                  return serviceTeamSupportsOrg(team.label, org.label)
                    ? true
                    : `This service team does not support the ${org.label} organization`;
                },
              },
            }}
          />
        </FlexWrapper>

        <Spacer verticalSpacing="30px" />

        <Box>
          <FormFieldLabel
            label={
              <div className={styles.label}>
                <span>Profile Image</span>

                <Tooltip
                  tooltipText={
                    <ul className={styles.list}>
                      <li>Image must be a square</li>

                      <li>Dimensions should *ideally* be 250x250</li>

                      <li>Image format should be PNG (not JPEG)</li>

                      <li>The background should be transparent</li>
                    </ul>
                  }
                  placement={"top"}
                >
                  <InfoOutlinedIcon style={{ width: "17px", height: "17px" }} />
                </Tooltip>
              </div>
            }
          />

          <FileDropUploadComponent
            file={profileImage}
            onChange={setProfileImage}
            acceptedFileExtensions={[".png"]}
            text={"Drop your file here or "}
            pressText={<div className={styles.fileUploadText}>browse</div>}
          />

          <FormHelperText error className={styles.helperText}>
            {imgError}
          </FormHelperText>
        </Box>
      </FormProvider>
    </Drawer>
  );
};

export default CreateCXMemberPage;
