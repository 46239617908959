import { useSelector } from "react-redux";

import { State } from "@src/store";

type ApplicationMode = {
  isClientMode: boolean;
  isAdvisorMode: boolean;
  isImpersonationMode: boolean;
};

export default function useApplicationMode(): ApplicationMode {
  const { isAdvisor, clientId, advisorId } = useSelector((state: State) => ({
    isAdvisor: state.main_Reducer.user.isAdvisor,
    advisorId: state.main_Reducer.cockroach_advisor_id,
    clientId: state.main_Reducer.user.id_user,
  }));

  const isClientMode =
    clientId !== null && advisorId === null && isAdvisor === false;
  const isAdvisorMode =
    clientId === null && advisorId !== null && isAdvisor === true;
  const isImpersonationMode =
    clientId !== null && advisorId !== null && isAdvisor === true;

  return {
    isClientMode,
    isAdvisorMode,
    isImpersonationMode,
  };
}
