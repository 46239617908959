import React from "react";

import ArrowEastIcon from "@mui/icons-material/East";
import ArrowWestIcon from "@mui/icons-material/West";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./TablePaginationActions.module.css";

import type { TablePaginationActionsProps as MuiTablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";

type TablePaginationActionsProps = MuiTablePaginationActionsProps & {
  isPrevButtonDisabled: boolean;
  isNextButtonDisabled: boolean;
};

export default function TablePaginationActions({
  page,
  onPageChange,
  isPrevButtonDisabled,
  isNextButtonDisabled,
}: TablePaginationActionsProps) {
  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  return (
    <Box className={styles.container}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={isPrevButtonDisabled}
        disableRipple
        disableFocusRipple
      >
        <ArrowWestIcon
          className={toClassName(styles.arrowIcon, {
            [styles.arrowIconDisabled]: isPrevButtonDisabled,
          })}
        />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={isNextButtonDisabled}
        disableRipple
        disableFocusRipple
      >
        <ArrowEastIcon
          className={toClassName(styles.arrowIcon, {
            [styles.arrowIconDisabled]: isNextButtonDisabled,
          })}
        />
      </IconButton>
    </Box>
  );
}
