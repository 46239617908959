import React, { PropsWithChildren, useEffect } from "react";

import { useParams } from "react-router-dom";

import { TaxBudget } from "@fartherfinance/frontend/api/InvestmentModel/Types";
import { ClientId, TaxBudgetId } from "@fartherfinance/frontend/api/Types";

import { actions } from "../reducer/actions";
import { useCreateEditTaxBudgetContext } from "../reducer/Context";
import { thisYear } from "../utils/taxYearOptions";

interface Props {
  budgets: TaxBudget[];
}

const FlowInit: React.FC<PropsWithChildren<Props>> = ({
  budgets,
  children,
}) => {
  const { clientId, taxBudgetId } = useParams<{
    clientId: ClientId;
    taxBudgetId: TaxBudgetId;
  }>();

  const { state, dispatch } = useCreateEditTaxBudgetContext();

  const { budgetId, year } = state.data;

  useEffect(() => {
    if (!budgetId && taxBudgetId) {
      const budget = budgets?.find((b) => b.budgetId === taxBudgetId);

      if (budget) {
        dispatch({ type: actions.SET_EXISTING_BUDGET, payload: budget });
      }
    } else if (!year) {
      dispatch({
        type: actions.SET_BLANK_BUDGET_FOR_YEAR,
        payload: {
          clientId,
          year: thisYear,
        },
      });
    }
  }, [budgetId, budgets, clientId, dispatch, taxBudgetId, year]);

  return <>{children}</>;
};

export default FlowInit;
