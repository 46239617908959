import React from "react";

import Admin from "../Admin";
import CreateBulkClientsModal from "@src/multiCustodian/components/Admin/CreateBulkClients/CreateBulkClientsModal";

const CreateBulkClients = (): JSX.Element => {
  return (
    <Admin>
      <CreateBulkClientsModal />
    </Admin>
  );
};

export default CreateBulkClients;
