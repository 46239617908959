import React from "react";

import styles from "./ScrollContainer.module.css";

interface Props {
  maxHeight: number;
}

const ScrollContainer = (
  props: React.PropsWithChildren<Props>
): JSX.Element => {
  return (
    <div
      className={styles.container}
      style={{ maxHeight: `${props.maxHeight}px` }}
    >
      {props.children}
    </div>
  );
};

export default ScrollContainer;
