import React from "react";

import Button from "../../../MUI/Button/Button";
import Modal from "@src/sharedComponents/Modal/Modal";

import styles from "./DeleteAccountGroupModal.module.css";

interface Props {
  onDelete: () => void;
  closeModal: () => void;
}

const DeleteAccountGroupModal: React.FC<Props> = ({
  onDelete,
  closeModal,
}: Props) => {
  return (
    <Modal className={styles.modal} closeModal={closeModal}>
      <div className={styles.title}>
        Are you sure you want to delete this group?
      </div>

      <div className={styles.subTitle}>
        Deleting this group will remove it from Home.
      </div>

      <div className={styles.footer}>
        <Button
          text={"Cancel"}
          variant={"outlined"}
          buttonType={"secondary"}
          onClick={closeModal}
          sx={{ width: "80px" }}
        />

        <Button
          text={"Delete Group"}
          variant={"outlined"}
          buttonType={"warning"}
          onClick={onDelete}
          sx={{ marginLeft: "20px", width: "120px" }}
        />
      </div>
    </Modal>
  );
};

export default DeleteAccountGroupModal;
