import React from "react";

import DeleteListModal from "./DeleteListModal";
import EquivalentSecuritiesDrawer from "./Drawer/EquivalentSecuritiesDrawer";

const DeleteEquivalentSecurities: React.FC = () => {
  return (
    <EquivalentSecuritiesDrawer>
      <DeleteListModal />
    </EquivalentSecuritiesDrawer>
  );
};

export default DeleteEquivalentSecurities;
