import { TaxType } from "@fartherfinance/frontend/api/Types";

import isValidEIN from "./isValidEIN";
import isValidSSN from "./isValidSSN";

export default function isValidTaxId(
  taxId: string | undefined,
  taxType: TaxType | undefined
): true | string {
  if (taxType === undefined) {
    return "Please select a Tax Id Type";
  }

  switch (taxType) {
    case "ITIN":
      return "'ITIN' is not enabled for this form yet";

    case "Applied For":
      return "'Applied For' is not enabled for this form yet";

    case "EIN":
      return isValidEIN(taxId) ? true : "EIN must be a 9-digit number";

    case "SSN":
      return isValidSSN(taxId) ? true : "SSN must be a 9-digit number";
  }
}
