import React, { useMemo, useState } from "react";

import LinkIcon from "@mui/icons-material/Link";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import useFartherEmployees from "@fartherfinance/frontend/api/Entity/hooks/useFartherEmployees";
import { EssentialPortfolioV1 } from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/getEssentialPortfoliosV1";
import { CustomPortfolioV1 } from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";

import { FARTHER_MODELS_TYPE_NAME } from "../../constants";
import { taxTypeToStatus } from "../../utils/taxType";
import useIsAdmin from "@src/multiCustodian/components/Advisor/utils/useIsAdmin";
import { toSortable } from "@src/multiCustodian/components/Client/Accounts/Holdings/Components/BreakdownTable/HoldingsTable";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import Chip from "@src/sharedComponents/Chip/Chip";
import FullDataTable from "@src/sharedComponents/Table/FullDataTable";
import { Cell, Row } from "@src/sharedComponents/Table/Types";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";
import { State } from "@src/store";

import Header, { FilterOption } from "./Header";

const isEssentialPortfolioV1 = (
  p: CustomPortfolioV1 | EssentialPortfolioV1
): p is EssentialPortfolioV1 => {
  return "set" in p && "subset" in p;
};

export const rowsPerPage = 10;

export const tableKeys = [
  "Name",
  "Type",
  "Tax Status",
  "Direct-Indexed",
  "Securities",
  "Active Clients",
  "Updated",
] as const;

export type TableKey = typeof tableKeys[number];

interface Props {
  portfolios: (EssentialPortfolioV1 | CustomPortfolioV1)[];
}

const Table = (props: Props): JSX.Element => {
  const { search: urlQueryString } = useLocation();
  const paramsObj = useMemo(
    () => new URLSearchParams(urlQueryString),
    [urlQueryString]
  );

  const updateUrlQueryString = (newSearchString: string) => {
    const location = history.location;
    const params = new URLSearchParams(location.search);

    params.set("search", newSearchString);
    if (newSearchString === "") {
      params.delete("search");
    }

    history.replace({ ...location, search: params.toString() });
  };

  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));

  const { state } = useLocation<
    { modelsActiveVsArchivedFilter: FilterOption | undefined } | undefined
  >();
  const filterFromUrl = state?.modelsActiveVsArchivedFilter;

  const [filter, setFilter] = useState<FilterOption>(
    filterFromUrl ?? "Active Models"
  );

  const [tableSearch, setTableSearch] = useState<string>(
    paramsObj.get("search") ?? ""
  );

  const history = useHistory();

  const auth = useAdvisorRequestAuth();
  const employees = useFartherEmployees("All", auth);

  const filtered: (EssentialPortfolioV1 | CustomPortfolioV1)[] = useMemo(() => {
    const activeOrInactive = props.portfolios.filter((p) => {
      switch (filter) {
        case "Active Models":
          return p.isActive === true;

        case "Archived Models":
          return p.isActive === false;

        default:
          const _x: never = filter;
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          throw new Error(`[Model marketplace]: Unknown filter: ${_x}`);
      }
    });

    return activeOrInactive.filter((m) =>
      m.displayName.toLowerCase().includes(tableSearch.toLowerCase())
    );
  }, [filter, props.portfolios, tableSearch]);

  const isAdmin = useIsAdmin(advisorId);

  const portfolios: Row<TableKey, Cell>[] = useMemo(() => {
    return filtered.map((p) => {
      const type = isEssentialPortfolioV1(p)
        ? p.set === "FAM"
          ? FARTHER_MODELS_TYPE_NAME
          : p.set
        : p.portfolioType;

      const nameFullValue = isEssentialPortfolioV1(p)
        ? `${type} ${toSortable(parseInt(p.assetAllocation.split("/")[0], 10))}`
        : p.displayName;

      const isSharedCustomModel =
        type === "Custom" &&
        isAdmin.data === false &&
        "ownerId" in p &&
        p.ownerId !== advisorId;

      let modelOwner = null;
      if (employees.data && type === "Custom" && "ownerId" in p) {
        const employee = employees.data.advisors.find(
          (e) => e.advisorId === p.ownerId
        );
        if (employee !== undefined) {
          modelOwner = `${employee.name.first} ${employee.name.last}`;
        }
      }

      return {
        key: p.portfolioModelId,
        Name: { value: p.displayName, fullValue: nameFullValue.toLowerCase() },
        Type: {
          value: isSharedCustomModel ? (
            <div>
              <Tooltip
                tooltipText={`This custom model portfolio has been shared with you${
                  modelOwner ? ` by ${modelOwner}.` : "."
                }`}
                placement={"top"}
              >
                <div>
                  <Chip
                    variant="outlined"
                    icon={
                      <LinkIcon
                        style={{
                          width: "14px",
                          height: "14px",
                          color: "var(--icon)",
                          marginLeft: "10px",
                        }}
                      />
                    }
                    label={type}
                  />
                </div>
              </Tooltip>
            </div>
          ) : (
            <Chip variant="outlined" label={type} />
          ),
          fullValue: type.toLowerCase(),
        },
        "Tax Status": { value: taxTypeToStatus[p.taxType] },
        "Direct-Indexed": {
          value: p.directIndexing ? "Yes" : "No",
        },
        Securities: {
          value: p.numSecurities,
          fullValue: toSortable(p.numSecurities),
        },
        "Active Clients": {
          value: p.activeClients,
          fullValue: toSortable(p.activeClients),
        },
        Updated: {
          value: format(p.createTime, "MM/dd/yy"),
          fullValue: p.createTime.toISOString(),
        },
        onClick: () =>
          history.push({
            ...history.location,
            pathname: `/Advisor/Investments/ModelMarketplace/${p.portfolioModelId}/ModelDetails`,
            state: {
              modelsActiveVsArchivedFilter: filter,
            },
          }),
      };
    });
  }, [filtered, history, filter, advisorId, isAdmin.data, employees.data]);

  return (
    <>
      <Header
        filter={filter}
        setFilter={setFilter}
        tableSearch={tableSearch}
        setTableSearch={(searchString: string) => {
          setTableSearch(searchString);
          updateUrlQueryString(searchString);
        }}
      />

      <FullDataTable
        columns={tableKeys}
        rows={portfolios}
        defaultRowsPerPage={rowsPerPage}
        defaultSortColumn={["Active Clients", "desc"]}
        disableColumnSorting={portfolios.length > 0 ? undefined : tableKeys}
        emptyCell={<Chip label="Placeholder" />} // This is the highest cell we will have
      />
    </>
  );
};

export default Table;
