import React from "react";

import ArchiveAccountModal from "@src/multiCustodian/components/Advisor/ClientProfile/Components/Archive/ArchiveAccountModal";
import ClientAdmin from "@src/multiCustodian/components/Advisor/ClientProfile/Components/ClientAdmin";

const AdminArchiveAccount = (): JSX.Element => {
  return (
    <ClientAdmin>
      <ArchiveAccountModal />
    </ClientAdmin>
  );
};

export default AdminArchiveAccount;
