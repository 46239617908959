import { ChecklistItemName } from "@fartherfinance/frontend/api/Entity/requests/getClientChecklist";

export const DRAWER_FOOTER_PORTAL_ID = "drawer-footer-portal-id";

export type DashboardForm =
  | Extract<
      ChecklistItemName,
      | "Investor Profile"
      | "Contact Information"
      | "Additional Information"
      | "Beneficiaries"
      | "Affiliations"
    >
  | "Trusts"
  | "Security & Access";

const dashboardDrawerForms: readonly DashboardForm[] = [
  "Investor Profile",
  "Contact Information",
  "Additional Information",
  "Beneficiaries",
  "Affiliations",
  "Trusts",
  "Security & Access",
] as const;

export const dashboardDrawerFormsMenuOptions = dashboardDrawerForms.map(
  (formName) => ({ name: formName })
);
