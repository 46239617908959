import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  info: {
    maxWidth: 220,
    fontFamily: theme.typography.fontSecondary,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.fontSize,
    lineHeight: "19px",
    letterSpacing: "0.63px",
    color: theme.colors.primary.tints.navy50,
  },
}));
