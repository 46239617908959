import { useDebugValue, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";

import { magicCheckLogin } from "@src/magic";
import { MCReduxAction, State } from "@src/store";

import useClientFartherLogin from "./useClientFartherLogin";

type LoggedInState = {
  isLoggedIn: boolean;
};

type Return =
  | { isLoading: true; hasError: false; data: undefined }
  | { isLoading: false; hasError: false; data: LoggedInState }
  | { isLoading: false; hasError: true; data: undefined };

export default function useCheckSavedClientMagicSession(): Return {
  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));
  const [isLoggedIn, setIsLoggedIn] = useState<null | boolean>(null);
  const isLoading = isLoggedIn === null;

  const history = useHistory();

  useDebugValue(
    `isLoading: ${isLoading ? "Yes" : "No"}, isLoggedIn: ${
      isLoggedIn ? "Yes" : "No"
    }, clientId: ${clientId ?? "UnknownId"}`
  );

  const dispatch = useDispatch<Dispatch<MCReduxAction>>();
  const advisorFartherLogin = useClientFartherLogin();

  useEffect(() => {
    if (clientId !== null) {
      //Already logged in, noop
      console.log(
        "useCheckSavedClientMagicSession: Already logged in, skipping"
      );
      setIsLoggedIn(true);
      return;
    }

    magicCheckLogin("Client")
      .then(async (isLoggedIn) => {
        console.log("useCheckSavedClientMagicSession isLoggedIn", isLoggedIn);

        setIsLoggedIn(isLoggedIn);
      })
      .catch((err) => {
        setIsLoggedIn(false);

        console.error("useCheckSavedAdvisorMagicSession", err);
      });
  }, [advisorFartherLogin, clientId, dispatch, history]);

  return useMemo((): Return => {
    if (isLoading) {
      return { isLoading, hasError: false, data: undefined };
    } else {
      return { isLoading: false, hasError: false, data: { isLoggedIn } };
    }
  }, [isLoading, isLoggedIn]);
}
