import React, { useCallback, useEffect, useMemo, useState } from "react";

import { format } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import { head, last, throttle } from "lodash";

import useClientAccounts from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAccounts";
import {
  DateRange,
  TimeFrame,
} from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Types";
import useManagedAccountCustodians from "@fartherfinance/frontend/api/PerformanceGroups/hooks/useManagedAccountCustodians";
import useMatchPerformanceGroup from "@fartherfinance/frontend/api/PerformanceGroups/hooks/useMatchPerformanceGroup";
import { ClientId, FartherAccountId } from "@fartherfinance/frontend/api/Types";

import { dateFormat } from "@src/constants/dateFormat";
import PerformanceGroupsSummaryBreakdownTable from "@src/multiCustodian/components/PerformanceGroups/Summary/BreakdownTable";
import { usePerformanceCustomDateRangeContext } from "@src/multiCustodian/components/PerformanceGroups/Summary/components/PerformanceCustomDateRangeContextProvider";
import Legend from "@src/multiCustodian/components/PerformanceGroups/Summary/Legend";
import SummaryGraphContainer from "@src/multiCustodian/components/PerformanceGroups/Summary/SummaryGraphContainer";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import { Disclaimer } from "@src/multiCustodian/pages/Dashboard/Performance_Groups/PG_hardcoded_exports";
import createBalanceSummary from "@src/multiCustodian/pages/Dashboard/Performance_Groups/tabs/SummaryTab/utils/createBalanceSummary";
import { trackEvent } from "@src/multiCustodian/services/tracking";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import toPercent from "@src/utils/toPercent";
import toUSD from "@src/utils/toUSD";

import SetGoalButton from "./Components/SetGoalButton";
import Slider from "./Components/SliderContainer";

import styles from "./Summary.module.css";

interface Props {
  clientId: ClientId;
  accountId: FartherAccountId;
}

export default function Summary(
  props: React.PropsWithChildren<Props>
): JSX.Element {
  const [endDate, setEndDate] = useState<string | null>(null);
  const [timeFrame, setTimeFrame] = useState<TimeFrame>("1Y");

  const { enableBdPerformancePassthrough } = useFlags();

  const auth = useRequestAuth();
  const performanceGroupId = useMatchPerformanceGroup(
    props.clientId,
    props.accountId,
    auth
  );

  const accounts = useClientAccounts(props.clientId, auth);

  const account = useMemo(() => {
    if (accounts.isLoading || accounts.hasError) {
      return accounts;
    }

    const currentAccount = accounts.data.fartherAccounts.find(
      (acc) => acc.accountId === props.accountId
    );

    return {
      ...accounts,
      data: currentAccount ?? null,
    };
  }, [accounts, props.accountId]);

  const { customDateRange } = usePerformanceCustomDateRangeContext();

  const customTimeFrame: DateRange | null = useMemo(() => {
    if (
      timeFrame !== "Custom" ||
      customDateRange.from === null ||
      customDateRange.to === null
    ) {
      return null;
    }

    return {
      startDate: format(customDateRange.from, dateFormat),
      endDate: format(customDateRange.to, dateFormat),
    };
  }, [timeFrame, customDateRange]);

  const summary = useManagedAccountCustodians(
    performanceGroupId.data ?? null,
    timeFrame,
    customTimeFrame,
    props.clientId,
    auth
  );

  const firstBalanceData = useMemo(
    () => head(summary.data?.data) ?? null,
    [summary.data]
  );

  const lastBalanceData = useMemo(
    () => last(summary.data?.data) ?? null,
    [summary.data]
  );

  const changeEndDate = useCallback((date: string): void => {
    setEndDate(date);
  }, []);

  const displayData = useMemo(() => {
    if (summary.data === undefined || endDate === null) {
      return null;
    }

    const dataPoint = summary.data?.data.find((e) => e.endDate === endDate);

    if (dataPoint === undefined) {
      return null;
    }

    const marketGains =
      dataPoint.balanceOnEndDay -
      (dataPoint.balanceOnStartDay +
        dataPoint.depositsSinceStartDay -
        dataPoint.withdrawalsSinceStartDay);

    const gains = enableBdPerformancePassthrough
      ? dataPoint.netGainsSinceStartDay
      : marketGains;

    return {
      deposits: toUSD(dataPoint.depositsSinceStartDay),
      withdrawals: toUSD(dataPoint.withdrawalsSinceStartDay),
      marketGains: gains,
      currentBalance: toUSD(dataPoint.balanceOnEndDay),
      twr: toPercent(dataPoint.returnsSinceStartDay / 100),
      netAdditions: toUSD(dataPoint.netAdditions),
    };
  }, [endDate, summary.data, enableBdPerformancePassthrough]);

  const throttledUpdate = useMemo(
    () => throttle(changeEndDate, 10),
    [changeEndDate]
  );

  useEffect(() => {
    if (summary.data === undefined) {
      return;
    }

    const lastItem = last(summary.data.data);

    if (lastItem !== undefined) {
      changeEndDate(summary.data.endDate);
    }
  }, [changeEndDate, summary]);

  const balanceSummary = useMemo(
    () =>
      createBalanceSummary(
        summary.data?.data ?? [],
        enableBdPerformancePassthrough
      ),
    [summary.data?.data, enableBdPerformancePassthrough]
  );

  useEffect(() => {
    trackEvent({
      name: "Client Open Accounts Details Summary",
    });
  }, []);

  const accountNotOpen =
    account.data?.accountDetails.operationalState === "Planning" ||
    account.data?.accountDetails.operationalState === "Pending" ||
    account.data?.accountDetails.operationalState === "Closed";

  if (
    accountNotOpen ||
    performanceGroupId.data === null ||
    performanceGroupId.hasError ||
    summary.hasError ||
    account.hasError
  ) {
    return (
      <>
        <div className={styles.heading}>
          <div className={styles.headingText}>Account Summary</div>
        </div>

        <div className={styles.errorDiv}>Summary data unavailable</div>

        <Spacer verticalSpacing="32px" />

        <Disclaimer />

        <Spacer verticalSpacing="32px" />

        {props.children}
      </>
    );
  }

  const targetGoal = account.data?.accountDetails.targetGoal ?? null;

  return (
    <>
      <div className={styles.heading}>
        <div className={styles.headingText}>Account Summary</div>

        <div id="performanceCustomTimeFrameChip" />
      </div>

      <Legend
        displayVertical={false}
        contributionsText={"Deposits & Withdrawals:"}
      />

      <SummaryGraphContainer
        isLoading={summary.isLoading}
        summaryData={summary.data ?? null}
        timeRange={timeFrame}
        setTimeRange={setTimeFrame}
        balanceSummary={balanceSummary}
        throttledUpdate={throttledUpdate}
      />

      <Spacer verticalSpacing="20px" />

      <PerformanceGroupsSummaryBreakdownTable
        accountId={props.accountId}
        noData={summary.data === undefined || summary.data.data.length === 0}
        balSumFirstItem={firstBalanceData}
        balSumLastItem={lastBalanceData}
        currentBalance={displayData?.currentBalance ?? null}
        marketGains={displayData?.marketGains ?? null}
        deposits={displayData?.deposits ?? null}
        withdrawals={displayData?.withdrawals ?? null}
        timeWeightedReturns={displayData?.twr ?? null}
        netAdditions={displayData?.netAdditions ?? null}
      />

      <Spacer verticalSpacing="40px" />

      {account.isLoading || summary.isLoading ? (
        <Skeleton height={50} />
      ) : targetGoal === null ? (
        <Slider
          max={0}
          leftText="Target Goal"
          rightText={
            <SetGoalButton
              currentTargetGoal={null}
              clientId={props.clientId}
              accountId={props.accountId}
            />
          }
          value={0}
        />
      ) : (
        <Slider
          max={targetGoal}
          leftText="Target Goal"
          rightText={
            <SetGoalButton
              accountBalance={lastBalanceData?.balanceOnEndDay ?? null}
              currentTargetGoal={targetGoal}
              clientId={props.clientId}
              accountId={props.accountId}
            />
          }
          value={lastBalanceData?.balanceOnEndDay ?? 0}
        />
      )}

      <Spacer verticalSpacing="32px" />

      <Disclaimer />

      <Spacer verticalSpacing="32px" />

      {props.children}
    </>
  );
}
