import React, { PropsWithChildren } from "react";

import styles from "./SimplePopoverItem.module.css";

interface Props {
  onClick: () => void;
}

const SimplePopoverItem: React.FC<PropsWithChildren<Props>> = ({
  children,
  onClick,
}) => (
  <button className={styles.item} onClick={onClick}>
    {children}
  </button>
);

export default SimplePopoverItem;
