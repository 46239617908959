import React, { useState } from "react";

import DownloadIcon from "@mui/icons-material/Download";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Fade, Stack, Typography } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";

import useAdvisorMarketplaceOffering from "@fartherfinance/frontend/api/AltAssetsService/hooks/useAdvisorMarketplaceOffering";
import {
  MarketplaceAssetType,
  MarketplaceOfferingDetailsAdvisor,
} from "@fartherfinance/frontend/api/AltAssetsService/Types";

import Drawer from "@src/multiCustodian/components/Drawer/Drawer";
import OfferingDetailsParagraph from "@src/multiCustodian/components/Marketplace/OfferingDetails/OfferingDetailsParagraph/OfferingDetailsParagraph";
import TabularSection from "@src/multiCustodian/components/Marketplace/OfferingDetails/TabularSection/TabularSection";
import {
  MarketplaceOfferingAdvisorPathParams,
  MarketplaceOfferingTypeCard,
} from "@src/multiCustodian/components/Marketplace/types";
import { findOfferingTypeByValue } from "@src/multiCustodian/components/Marketplace/utils";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import IconButton from "@src/sharedComponents/IconButton/IconButton";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import Menu from "@src/sharedComponents/Menu/Menu";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

// NOTE: shared css styles
import styles from "@src/multiCustodian/components/Marketplace/OfferingDetails/OfferingDetails.module.css";

interface OfferingDetailsAdvisorProps {
  assetType: MarketplaceAssetType;
  offeringTypes: MarketplaceOfferingTypeCard[];
  marketplacePath: string;
}

const OfferingDetailsAdvisor: React.FC<OfferingDetailsAdvisorProps> = ({
  assetType,
  offeringTypes,
  marketplacePath,
}) => {
  const history = useHistory();
  const { offeringType, offeringId } =
    useParams<MarketplaceOfferingAdvisorPathParams>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const auth = useAdvisorRequestAuth();
  const {
    data: offering,
    isLoading,
    hasError,
  } = useAdvisorMarketplaceOffering(offeringId, auth);

  const marketplaceOfferingType = findOfferingTypeByValue(
    offeringTypes,
    offeringType
  );

  const handleNavigateToOfferingsList = (): void => {
    history.push({
      pathname: `/Advisor/${marketplacePath}/${offeringType}`,
      search: history.location.search,
    });
  };

  const handleScheduleMeetingFlow = (
    offering: MarketplaceOfferingDetailsAdvisor["v1"]
  ): void => {
    const scheduleMeetingUrl = offering.contact.calendlyLink;

    if (scheduleMeetingUrl) {
      window.open(scheduleMeetingUrl, "_blank");
    } else {
      const offeringTypeName = findOfferingTypeByValue(
        offeringTypes,
        offering.offeringType
      )?.name;

      const mailTo = offering.contact.email;
      const mailSubject = "Intro Call with Farther Advisor";
      const mailBody = `Hey ${
        offering.contact.name
      },%0DI'm an Advisor at Farther Finance. I'm interested in learning more about your ${
        offeringTypeName ?? ""
      } offering. Can we schedule a 30-minute introductory call%3F`;

      window.open(
        `mailto:${mailTo}?subject=${mailSubject}&body=${mailBody}`,
        "_blank"
      );
    }
  };

  return (
    <Drawer
      isDrawerOpen={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
      SlideProps={{ onExited: handleNavigateToOfferingsList }}
    >
      {offering && (
        <Fade in>
          <Stack className={styles.container}>
            <Stack className={styles.details}>
              <Typography className={styles.name}>
                {offering.partner.name}
              </Typography>

              {marketplaceOfferingType && (
                <OfferingDetailsParagraph
                  title={`${marketplaceOfferingType.name} Offering`}
                  description={marketplaceOfferingType.description}
                />
              )}

              <OfferingDetailsParagraph
                title={`About ${offering.partner.name}`}
                description={offering.partner.description}
              />

              <OfferingDetailsParagraph
                title="How It Works"
                description={offering.howItWorks}
              />
            </Stack>

            <div className={styles.getStarted}>
              <OfferingDetailsParagraph
                title="Get Started"
                description={offering.howToGetStarted}
              />

              <div className={styles.actions}>
                {offering.selfServiceLink && (
                  <Button
                    variant="outlined"
                    buttonType="primary"
                    text="Sign Up"
                    startIcon={<OpenInNewIcon />}
                    onClick={() =>
                      window.open(offering.selfServiceLink!, "_blank")
                    }
                  />
                )}
                <Button
                  variant="contained"
                  buttonType="primary"
                  text={"Schedule a Meeting"}
                  startIcon={<OpenInNewIcon />}
                  onClick={() => handleScheduleMeetingFlow(offering)}
                />
              </div>
            </div>

            <TabularSection
              title="Details"
              entries={[
                {
                  key: "Other Offerings",
                  value: offering.otherOfferings.join(", "),
                },
                {
                  key:
                    assetType === MarketplaceAssetType.enum.insurance
                      ? "Top Carriers"
                      : "Top Underwriters",
                  value: offering.providers.join(", "),
                },
              ]}
            />

            <TabularSection
              title="Contact"
              entries={[
                {
                  key: "Name",
                  value: offering.contact.name,
                },
                {
                  key: "Email",
                  value: offering.contact.email,
                },
                {
                  key: "Phone",
                  value: offering.contact.phone ?? "-",
                },
              ]}
            />

            <TabularSection
              title="Resources"
              entries={[
                {
                  key: "Website",
                  value: offering.website ? (
                    <Tooltip tooltipText={offering.website} placement="top">
                      <a
                        className={styles.link}
                        href={offering.website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {offering.website}
                      </a>
                    </Tooltip>
                  ) : (
                    "-"
                  ),
                },
                ...offering.attachments.map((attachment) => ({
                  key: attachment.filename,
                  value: (
                    <Menu
                      options={[
                        {
                          label: "Download",
                          onClick: () =>
                            (window.location.href = attachment.downloadUrl),
                          IconComponent: DownloadIcon,
                        },
                        {
                          label: "View",
                          onClick: () =>
                            window.open(attachment.previewUrl, "_blank"),
                          IconComponent: OpenInNewIcon,
                        },
                      ]}
                    >
                      {({ handleOpenMenu }) => (
                        <IconButton
                          onClick={handleOpenMenu}
                          IconComponent={MoreVertIcon}
                        />
                      )}
                    </Menu>
                  ),
                })),
              ]}
            />
          </Stack>
        </Fade>
      )}

      {hasError && (
        <Stack
          className={styles.errorContainer}
          justifyContent="center"
          alignItems="center"
        >
          <Typography className={styles.errorMessage}>
            Something went wrong, please try again later.
          </Typography>
        </Stack>
      )}

      {isLoading && (
        <div className={styles.loading}>
          <LogoLoadingStill />
        </div>
      )}
    </Drawer>
  );
};

export default OfferingDetailsAdvisor;
