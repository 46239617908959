import React from "react";

import { AltAssetsMarketplacePath } from "@src/config/routing/RouterPaths";
import { altAssetOfferingTypes } from "@src/multiCustodian/components/Marketplace/constants";
import OfferingDetailsAdvisor from "@src/multiCustodian/components/Marketplace/OfferingDetails/OfferingDetailsAdvisor";

import AltAssetsOfferingsListAdvisor from "./AltAssetsOfferingsListAdvisor";

const AltAssetsOfferingDetailsAdvisor: React.FC = () => {
  return (
    <AltAssetsOfferingsListAdvisor>
      <OfferingDetailsAdvisor
        offeringTypes={altAssetOfferingTypes}
        marketplacePath={AltAssetsMarketplacePath}
      />
    </AltAssetsOfferingsListAdvisor>
  );
};

export default AltAssetsOfferingDetailsAdvisor;
