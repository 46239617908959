import React from "react";

import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { GlobalStyles } from "tss-react";

import {
  accentBaseColors,
  accentShades,
  accentTints,
  chartBaseColors,
  chartShades,
  primaryBaseColors,
  primaryShades,
  primaryTints,
  secondaryBaseColors,
  secondaryShades,
  secondaryTints,
} from "@fartherfinance/frontend/theme/Farther/colors";
import {
  ThemeProvider as FartherThemeProvider,
  useTheme,
} from "@fartherfinance/frontend/theme/ThemeProvider";
import { AppearanceColors } from "@fartherfinance/frontend/theme/Tokens";

import ABCArizonaTextLight from "@src/assets/fonts/ABCArizonaText-Light.woff2";
import ABCArizonaTextLightItalic from "@src/assets/fonts/ABCArizonaText-LightItalic.woff2";
import FaktBold from "@src/assets/fonts/Fakt-Bold.woff2";
import FaktMedium from "@src/assets/fonts/Fakt-Medium.woff2";
import FaktNormal from "@src/assets/fonts/Fakt-Normal.woff2";
import FaktSemiBold from "@src/assets/fonts/Fakt-SemiBold.woff2";

const SPACING = 12;

export const pxToSpacing = (value: number): number => {
  return Number.parseFloat((value / SPACING).toFixed(2));
};

const MuiThemeProviderEnchancer: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const theme = useTheme();
  const muiTheme = responsiveFontSizes(createMuiTheme(theme.color));

  return (
    <>
      <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: theme.color.$backgroundInverseSubtle,
          },
        }}
      />
    </>
  );
};

export const ThemeProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <FartherThemeProvider>
      <MuiThemeProviderEnchancer>{children}</MuiThemeProviderEnchancer>
    </FartherThemeProvider>
  );
};

const createMuiTheme = (appearanceColors: AppearanceColors) =>
  createTheme({
    spacing: SPACING,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1440,
      },
    },
    typography: {
      fontFamily: "ABC Arizona Text, Fakt",
      fontPrimary: "ABC Arizona Text",
      fontSecondary: "Fakt",
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'ABC Arizona Text';
          font-style: normal;
          font-weight: 300;
          src: url(${ABCArizonaTextLight}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
  
        @font-face {
          font-family: 'ABC Arizona Text';
          font-style: italic;
          font-weight: 300;
          src: url(${ABCArizonaTextLightItalic}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
      
        @font-face {
          font-family: 'Fakt';
          font-style: normal;
          font-weight: 400;
          src: url(${FaktNormal}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
          font-family: 'Fakt';
          font-style: normal;
          font-weight: 500;
          src: url(${FaktMedium}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
          font-family: 'Fakt';
          font-style: normal;
          font-weight: 600;
          src: url(${FaktSemiBold}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
          font-family: 'Fakt';
          font-style: normal;
          font-weight: 700;
          src: url(${FaktBold}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
      `,
      },
    },
    appearance: appearanceColors,
    colors: {
      accent: {
        base: accentBaseColors,
        tints: accentTints,
        shades: accentShades,
      },
      primary: {
        base: primaryBaseColors,
        tints: primaryTints,
        shades: primaryShades,
      },
      secondary: {
        base: secondaryBaseColors,
        tints: secondaryTints,
        shades: secondaryShades,
      },
      tertiary: {
        base: chartBaseColors,
        tints: null,
        shades: chartShades,
      },
    },
    palette: {
      primary: {
        // INFO: keep the primary trio colors for backwards compatibility
        light: primaryBaseColors.cream,
        main: secondaryBaseColors.gold,
        dark: primaryBaseColors.navy,
      },
      secondary: {
        // INFO: we do not want to use material-ui default secondary palette values
        light: "#fff",
        main: "#fff",
        dark: "#fff",
      },
    },
  });
