export const combineAccountNames = (accountNames: string[]): string => {
  if (accountNames.length <= 0) {
    throw new Error(
      "accountNames in combineAccountNames should not be an empty list"
    );
  }

  if (accountNames.length === 2) {
    return `${accountNames[0]} and ${accountNames[1]}`;
  }

  if (accountNames.length > 2) {
    const lastIndex = accountNames.length - 1;
    const names = accountNames.slice(0, lastIndex).join(", ");
    return `${names} and ${accountNames[lastIndex]}`;
  }

  return accountNames[0];
};
