import React, { useMemo } from "react";

import { format } from "date-fns";
import { useHistory, useParams } from "react-router-dom";

import {
  UNKNOWN_ACCOUNT,
  UNKNOWN_BALANCE,
} from "@fartherfinance/frontend/api/Accounts/utilities/accountUtil";
import { TriggerRun } from "@fartherfinance/frontend/api/CashCycle/requests/getTriggerRuns";
import { AccountDetails } from "@fartherfinance/frontend/api/Dashboard/requests/getClientDashboard";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import { CashManagementPath } from "@src/config/routing/RouterPaths";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";

import styles from "./UpcomingTransfers.module.css";

interface Props {
  triggerRun: TriggerRun;
  recurringTransferAmount: number | undefined;
  clientAccounts: AccountDetails[];
  formattedExternalAccountName: string;
}

const UpcomingTransfers = (props: Props): JSX.Element => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const history = useHistory();

  const toAccountString = useMemo(() => {
    if (props.triggerRun.transfers.length === 1) {
      const account = props.clientAccounts.find(
        (acct) => acct.accountId === props.triggerRun.transfers[0].accountId
      );

      if (account) {
        return `${account.accountDetails.displayName} (...${account.accountDetails.custodianAccountNumber})`;
      } else {
        return UNKNOWN_ACCOUNT;
      }
    }
    return "Split between multiple accounts";
  }, [props.clientAccounts, props.triggerRun.transfers]);

  const amount = props.recurringTransferAmount
    ? props.recurringTransferAmount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : UNKNOWN_BALANCE;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p className={styles.heading}>Upcoming Transfers</p>
      </div>

      <div className={styles.body}>
        <div className={styles.bodyInnerDiv}>
          <div className={styles.bodyLeft}>
            <p className={styles.transfer}>
              {`${amount} Recurring Transfer`}
              <span className={styles.status}>Pending</span>
            </p>

            <p className={styles.fromAccount}>
              <span className={styles.from}>From:</span>
              {props.formattedExternalAccountName}
            </p>

            <p className={styles.toAccount}>
              <span className={styles.to}>To:</span>
              {toAccountString}
            </p>
          </div>

          <div className={styles.bodyRight}>
            <div className={styles.timingDiv}>
              <p className={styles.date}>
                {format(props.triggerRun.executeDate, "M/d/yyyy")}
              </p>

              <p className={styles.available}>Available in 2-5 days</p>
            </div>

            <ButtonPrimary
              text={"Cancel"}
              variant="outlined"
              buttonType="secondary"
              onClick={() =>
                history.push(
                  `/Client/${clientId}/${CashManagementPath}/CancelUpcomingTransfer`
                )
              }
              sx={{ height: "30px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingTransfers;
