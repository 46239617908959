import React from "react";

import { useSelector } from "react-redux";

import { State } from "../../store";
import Lottie from "../Lottie";

import loadingDark from "./loading_dark.json";
import loadingLight from "./loading_light.json";

import "./LogoLoadingStill.css";

const { animation: animationData_dark } = loadingDark;
const { animation: animationData_light } = loadingLight;

interface Props {
  onTop?: boolean;
  noBackgroundColor?: boolean;
}

function LogoLoadingStill(props: Props) {
  const { ui_theme } = useSelector((state: State) => ({
    ui_theme: state.main_Reducer.ui_theme,
  }));

  if (props.onTop === true) {
    return (
      <div
        className="Loader_modal_container"
        style={
          props.noBackgroundColor ? { backgroundColor: "inherit" } : undefined
        }
      >
        <div
          className="new_logo_loading"
          style={{
            backgroundColor:
              ui_theme === "farther_dark"
                ? "var(--farther_navy_color_1)"
                : "var(--farther_cream_color_1)",
          }}
        >
          <Lottie
            animationData={
              ui_theme === "farther_dark"
                ? animationData_dark
                : animationData_light
            }
            height={150}
            width={150}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="new_logo_loading"
        style={{
          backgroundColor:
            ui_theme === "farther_dark"
              ? "var(--farther_navy_color_1)"
              : "var(--farther_cream_color_1)",
        }}
      >
        <Lottie
          animationData={
            ui_theme === "farther_dark"
              ? animationData_dark
              : animationData_light
          }
          height={150}
          width={150}
        />
      </div>
    );
  }
}

export default LogoLoadingStill;
