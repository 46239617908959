import React from "react";

import OfferingTypeCard from "../OfferingTypeCard/OfferingTypeCard";
import {
  AltAssetOfferingTypeCard,
  InsuranceOfferingTypeCard,
  LendingOfferingTypeCard,
} from "../types";

import styles from "./OfferingTypeList.module.css";

type InsuranceOfferingTypeListProps = {
  offeringTypes: InsuranceOfferingTypeCard[];
  onOfferingTypeClick: (
    offeringType: InsuranceOfferingTypeCard["value"]
  ) => void;
};

type LendingOfferingTypeListProps = {
  offeringTypes: LendingOfferingTypeCard[];
  onOfferingTypeClick: (offeringType: LendingOfferingTypeCard["value"]) => void;
};

type AltAssetOfferingTypeListProps = {
  offeringTypes: AltAssetOfferingTypeCard[];
  onOfferingTypeClick: (
    offeringType: AltAssetOfferingTypeCard["value"]
  ) => void;
};

type OfferingTypeListProps =
  | InsuranceOfferingTypeListProps
  | LendingOfferingTypeListProps
  | AltAssetOfferingTypeListProps;

const OfferingTypeList: React.FC<OfferingTypeListProps> = ({
  offeringTypes,
  onOfferingTypeClick,
}) => {
  return (
    <div className={styles.container}>
      {offeringTypes.map((offeringType) => (
        <OfferingTypeCard
          key={offeringType.name}
          name={offeringType.name}
          description={offeringType.description}
          // @ts-expect-error - as this is union TS infers this as never, we would have to use a type guard to determine the type
          // which doesn't make much sense in this case
          onClick={() => onOfferingTypeClick(offeringType.value)}
        />
      ))}
    </div>
  );
};

export default OfferingTypeList;
