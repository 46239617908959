import React from "react";

import { Typography } from "@mui/material";

export default function PrivacyPolicyText(): JSX.Element {
  return (
    <Typography>
      <div>
        <h1>NOTICE OF PRIVACY PRACTICES</h1>

        <p>
          Farther Finance Advisors, LLC, an investment advisory firm, is
          committed to safeguarding the confidential information of its clients.
          We do collect nonpublic personal information in order to open and
          administer your accounts with us and to provide you with accurate and
          pertinent advice. We hold all nonpublic personal information you
          provide to us in the strictest confidence. If we were to change our
          firm policy, we would be prohibited under the law from doing so
          without advising you first.
        </p>

        <p>
          You may direct us not to make disclosures (other than disclosures
          required by law) regarding nonpublic personal information to
          nonaffiliated third parties. If you wish to opt out of disclosures to
          non-affiliated third parties, please contact us at 415-827-7371.
        </p>

        <p>
          Farther Finance Advisors, LLC collects personal information about you
          from the following sources:
        </p>

        <ul>
          <li>Applications or other forms.</li>
          <li>Discussions with nonaffiliated third parties.</li>
          <li>Information about your transactions with others or us.</li>
          <li>Questionnaires.</li>
          <li>Tax Returns.</li>
          <li>Estate Planning Documents.</li>
        </ul>

        <p>
          Farther Finance Advisors, LLC uses your personal information in the
          following manner:
        </p>

        <ul>
          <li>We do not sell your personal information to anyone.</li>
          <li>
            We limit employee and agent access to information only to those who
            have a business or professional reason for knowing, and only to
            nonaffiliated parties as permitted by law. (For example, federal
            regulations permit us to share a limited amount of information about
            you with a brokerage firm in order to execute securities
            transactions on your behalf, or so that our firm can discuss your
            financial situation with your accountant or lawyer.)
          </li>
          <li>
            We will provide notice of changes in our information sharing
            practices. If, at any time in the future, it is necessary to
            disclose any of your personal information in a way that is
            inconsistent with this policy, we will give you advance notice of
            the proposed change, so you will have the opportunity to opt out of
            such disclosure.
          </li>
          <li>
            We maintain a secure office and computer environment to ensure that
            your information is not placed at unreasonable risk.
          </li>
          <li>
            For nonaffiliated third parties that require access to your personal
            information, including financial service companies, consultants, and
            auditors, we also require strict confidentiality in our agreements
            with them and expect them to keep this information private. Federal
            and state regulators also may review firm records as permitted under
            law.
          </li>
          <li>
            We do not provide your personally identifiable information to
            mailing list vendors or solicitors for any purpose.
          </li>
          <li>
            Personally identifiable information about you will be maintained
            during the time you are a client, and for the required time
            thereafter that such records are required to be maintained by
            federal and state securities laws. After this required period of
            record retention, all such information will be destroyed.
          </li>
        </ul>
      </div>
    </Typography>
  );
}
