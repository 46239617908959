import React from "react";

import Checkbox from "@src/sharedComponents/Checkbox/Checkbox";

import styles from "./FavoriteCheckbox.module.css";

interface Props {
  isFavorite: boolean;
  setIsFavorite: (checked: boolean) => void;
}

const FavoriteCheckbox: React.FC<Props> = ({
  isFavorite,
  setIsFavorite,
}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.checkbox}>
        <Checkbox
          checked={isFavorite}
          onChange={setIsFavorite}
          label={"Favorite this group"}
          disabled={false}
        />
      </div>

      <div className={styles.text}>
        Your favorite is what you see when you first log in.
      </div>
    </div>
  );
};

export default FavoriteCheckbox;
