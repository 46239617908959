import { chipClasses } from "@mui/material/Chip";
import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

const toImportant = (value: string) => `${value} !important`;

export const useStyles = makeStyles<
  void,
  "icon" | "text" | "chip" | "select"
>()((theme, _, classes) => ({
  container: {
    marginBottom: theme.spacing(pxToSpacing(24)),
    borderRadius: "5px",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.appearance.$border,
  },
  header: {
    padding: theme.spacing(pxToSpacing(16)),
    cursor: "pointer",

    transition: theme.transitions.create(["background"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.short,
    }),

    "&:hover": {
      backgroundColor: theme.appearance.$backgroundNeutralHovered,
    },

    [`&:hover .${classes.icon}`]: {
      color: theme.appearance.$icon,
    },

    [`&:hover .${classes.text}`]: {
      color: theme.appearance.$text,
    },

    [`&:hover .${classes.chip} .${chipClasses.label}`]: {
      color: theme.appearance.$text,
    },

    [`&:hover .${classes.select}`]: {
      color: toImportant(theme.appearance.$text),
    },
  },
  textHighlight: {
    [`& .${classes.icon}`]: {
      color: theme.appearance.$icon,
    },

    [`& .${classes.text}`]: {
      color: theme.appearance.$text,
    },

    [`& .${classes.chip} .${chipClasses.label}`]: {
      color: theme.appearance.$text,
    },

    [`& .${classes.select}`]: {
      color: toImportant(theme.appearance.$text),
    },
  },
  select: {
    width: 180,
    backgroundColor: toImportant("transparent"),
    color: toImportant(theme.appearance.$textSubtle),

    transition: theme.transitions.create(["color"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.short,
    }),
  },
  icon: {
    color: theme.appearance.$iconSubtle,
    width: 18,
    height: 18,
    marginRight: theme.spacing(pxToSpacing(4)),

    transition: theme.transitions.create(["background", "transform"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.short,
    }),
  },
  iconRoate: {
    transform: "rotate(90deg)",
  },
  text: {
    fontFamily: theme.typography.fontSecondary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "20px",
    letterSpacing: "0.28px",
    color: theme.appearance.$textSubtle,

    transition: theme.transitions.create(["color"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.short,
    }),
  },
  summarySeparator: {
    margin: theme.spacing(0, pxToSpacing(8)),
    color: theme.colors.primary.shades.cream20,
  },
  chip: {
    marginLeft: theme.spacing(pxToSpacing(8)),

    [`& .${chipClasses.label}`]: {
      fontFamily: theme.typography.fontSecondary,
      color: theme.appearance.$textSubtle,

      transition: theme.transitions.create(["color"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.short,
      }),
    },
  },

  tableContainer: {
    overflow: "auto",
    maxHeight: 427, // INFO: header + 10 data rows + 2 summary rows
  },
  tableHeader: {
    display: "grid",
    gridTemplateColumns: "2% repeat(6, 1fr) 50%",
    columnGap: 24,
    padding: theme.spacing(pxToSpacing(10), pxToSpacing(12)),
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.appearance.$border,
    backgroundColor: theme.appearance.$backgroundSubtlest,
  },
  tableHeaderText: {
    fontFamily: theme.typography.fontSecondary,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    color: theme.appearance.$textSoft,
  },
  tableRow: {
    display: "grid",
    gridTemplateColumns: "2% repeat(6, 1fr) 50%",
    columnGap: 24,
    padding: theme.spacing(pxToSpacing(5), pxToSpacing(12)),
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.appearance.$border,

    transition: theme.transitions.create(["background"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.short,
    }),

    "&:hover": {
      backgroundColor: theme.appearance.$backgroundNeutralHovered,
    },
  },
  tableRowText: {
    fontFamily: theme.typography.fontSecondary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: "0.2px",
    color: theme.appearance.$text,
    whiteSpace: "nowrap",
  },
  tableRowIndex: {
    color: theme.appearance.$textSubtlest,
  },
  tableRowData: {
    color: theme.appearance.$textSubtle,
  },
  message: {
    fontFamily: theme.typography.fontSecondary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: "0.2px",
    color: theme.appearance.$textSubtle,
    margin: theme.spacing(pxToSpacing(16)),
  },
  stickyContainer: {
    position: "sticky",
    bottom: 0,
    backgroundColor: theme.appearance.$surface,

    borderTopWidth: 1,
    borderTopStyle: "solid",
    borderTopColor: theme.appearance.$border,
  },
}));
