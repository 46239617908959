import { isString } from "lodash";

type ClassNameObject = {
  [key: string]: boolean | undefined;
};

const objectToClassName = (data: ClassNameObject): string =>
  Object.entries(data)
    .filter(([_, value]) => value)
    .map(([key]) => key)
    .join(" ");

export const toClassName = (
  ...args: (ClassNameObject | string | undefined)[]
): string =>
  args
    .filter(Boolean)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .map((item) => (isString(item) ? item : objectToClassName(item!)))
    .join(" ");
