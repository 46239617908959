import React from "react";

const NotStarted: React.FC = () => (
  <svg
    width="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame 10124968">
      <circle
        id="Ellipse 705"
        cx="8.6001"
        cy="8"
        r="7.5"
        stroke="#80878F"
        strokeDasharray="2 2"
      />
    </g>
  </svg>
);

export default NotStarted;
