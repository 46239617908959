import React, { PropsWithChildren } from "react";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./BorderBoxBody.module.css";

interface Props {
  className?: string;
  showTopBorder?: boolean;
  showScrollbar?: boolean;
}

const BorderBoxBody = (props: PropsWithChildren<Props>): JSX.Element => {
  return (
    <div
      className={toClassName(
        { [styles.container]: !props.showScrollbar },
        { [styles.containerShowScrollbar]: props.showScrollbar },
        { [styles.topBorder]: props.showTopBorder },
        props.className
      )}
    >
      {props.children}
    </div>
  );
};

export default BorderBoxBody;
