import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import useInvitations from "@fartherfinance/frontend/api/Sharing/hooks/useInvitations";
import usePatchInvitation from "@fartherfinance/frontend/api/Sharing/hooks/usePatchInvitation";
import usePutInvitation from "@fartherfinance/frontend/api/Sharing/hooks/usePutInvitation";
import { ServiceProfessional } from "@fartherfinance/frontend/api/Sharing/Types";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import BaseLayout from "../../../Layouts/BaseLayout/BaseLayout";
import { getAccessLevel } from "../../utils/getAccessLevel";
import { getAccessLevelForDisplay } from "../../utils/getAccessLevelForDisplay";
import { getRelationTypeFromId } from "../../utils/getRelationTypeFromId";
import { getRelationTypeIdFromType } from "../../utils/getRelationTypeIdFromType";
import { getServiceProfessionalOptionFromType } from "../../utils/getServiceProfessionalOptionFromType";
import { getServiceProfessionalTypeFromOption } from "../../utils/getServiceProfessionalTypeFromOption";
import FlowFooter from "../shared/Footer/FlowFooter";
import FlowHero from "../shared/Hero/FlowHero";
import { SharingPath } from "@src/config/routing/RouterPaths";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

import CollaboratorNameTypeAndAccessLevel from "./NameTypeAndAccessLevel/CollaboratorNameTypeAndAccessLevel";
import { actions } from "./reducer/actions";
import { useEditCollaboratorContext } from "./reducer/Context";

const SharingEditCollaboratorFlow: React.FC = () => {
  const { clientId, collaboratorId } = useParams<{
    clientId: ClientId;
    collaboratorId: ClientId;
  }>();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { state, dispatch } = useEditCollaboratorContext();
  const { isSaveButtonDisabled, data } = state;
  const {
    firstName,
    lastName,
    accessLevel,
    collaboratorType,
    serviceProfessionalType,
    notify,
    resources,
  } = data;

  const history = useHistory();
  const statusNotification = useStatusNotification();

  const auth = useRequestAuth();
  const invitations = useInvitations(auth);
  const putInvitation = usePutInvitation(auth);
  const patchInvitation = usePatchInvitation(auth);

  const invitation = useMemo(() => {
    if (invitations.isLoading || invitations.hasError) {
      return undefined;
    }

    return invitations.data.sent.find(
      (i) => i.collaborator.collaboratorId === collaboratorId
    );
  }, [invitations, collaboratorId]);

  const finish = useCallback(() => {
    dispatch({ type: actions.RESET_TO_DEFAULT_DATA, payload: undefined });

    history.push({
      ...history.location,
      pathname: `/Client/${clientId}/${SharingPath}`,
    });
  }, [clientId, dispatch, history]);

  useEffect(() => {
    if (invitations.isLoading === false && invitations.hasError === false) {
      if (invitations.data !== undefined && invitation === undefined) {
        statusNotification("Collaborator does not exist", "Error");
        finish();
      } else if (invitation !== undefined && accessLevel === undefined) {
        // accessLevel === undefined -> only want to set data if it DNE in state yet
        dispatch({
          type: actions.SET_COLLABORATOR,
          payload: {
            firstName: invitation.collaborator.firstName,
            lastName: invitation.collaborator.lastName,
            accessLevel: getAccessLevelForDisplay(invitation.accessLevel),
            collaboratorType: getRelationTypeFromId(invitation.relationTypeId),
            serviceProfessionalType: getServiceProfessionalOptionFromType(
              invitation.serviceProfessionalType
            ),
            notify: invitation.notify,
            resources: [...invitation.resources],
          },
        });

        setIsLoading(false);
      }
    }
  }, [
    invitation,
    invitations.data,
    invitations.isLoading,
    invitations.hasError,
    finish,
    statusNotification,
    dispatch,
    accessLevel,
  ]);

  const handleOnClickSave = () => {
    updateCollaborator();
  };

  const areSelectedResourcesDifferent = () => {
    if (invitation === undefined) {
      throw new Error("invitation for collaborator is undefined");
    }

    if (resources.length !== invitation.resources.length) {
      return true;
    }

    const allTheSame = resources.every((selRes) =>
      invitation.resources.some(
        (curRes) => curRes.resourceId === selRes.resourceId
      )
    );
    return !allTheSame;
  };

  const updateCollaborator = async () => {
    if (invitation === undefined) {
      throw new Error("invitation for collaborator is undefined");
    }

    if (
      collaboratorType === undefined &&
      serviceProfessionalType === undefined
    ) {
      throw new Error(
        "collaboratorType or serviceProfessionalType is undefined"
      );
    }

    if (accessLevel === undefined) {
      throw new Error("accessLevel is undefined");
    }

    setIsLoading(true);

    const makePatch =
      collaboratorType !== getRelationTypeFromId(invitation.relationTypeId) ||
      serviceProfessionalType !==
        getServiceProfessionalOptionFromType(
          invitation.serviceProfessionalType
        );

    const access = getAccessLevel(accessLevel);

    const makePut =
      access !== invitation.accessLevel ||
      (access === invitation.accessLevel &&
        access === "Limited" &&
        areSelectedResourcesDifferent());

    const updates = [];

    if (makePatch) {
      const body = collaboratorType
        ? { relationTypeId: getRelationTypeIdFromType(collaboratorType) }
        : {
            serviceProfessionalType: getServiceProfessionalTypeFromOption(
              serviceProfessionalType
            ) as ServiceProfessional,
          };

      updates.push(
        patchInvitation({
          invitationId: invitation.invitationId,
          body,
        })
      );
    }

    if (makePut) {
      updates.push(
        putInvitation({
          invitationId: invitation.invitationId,
          body: { accessLevel: access, resources, notify },
        })
      );
    }

    const results = await Promise.allSettled(updates);

    results.forEach((res, i) => {
      const notifResStatus = res.status === "fulfilled" ? "Success" : "Error";

      if (i === 0 && makePatch) {
        statusNotification(
          res.status === "fulfilled"
            ? "Collaborator type updated"
            : "Failed to update collaborator type",
          notifResStatus
        );
      } else {
        statusNotification(
          res.status === "fulfilled"
            ? "Access updated"
            : "Failed to update access",
          notifResStatus
        );
      }
    });

    setIsLoading(false);
    finish();
  };

  if (invitations.hasError) {
    return <div>Error retrieving invitations</div>;
  }

  return (
    <BaseLayout showSideNav={false}>
      <FlowHero
        title={`Edit ${firstName} ${lastName}`}
        headingLeft={"Sharing"}
        headingRight={"Manage Collaborator"}
      />

      {isLoading && <LogoLoadingStill onTop noBackgroundColor />}

      {!isLoading && <CollaboratorNameTypeAndAccessLevel />}

      <FlowFooter
        onClickBack={() => undefined}
        onClickNext={handleOnClickSave}
        nextButtonText={"Save"}
        nextButtonIsDisabled={isSaveButtonDisabled}
        showCancelButton={true}
        showBackButton={false}
        showNextButton={true}
      />
    </BaseLayout>
  );
};

export default SharingEditCollaboratorFlow;
