export const incomeRangeOptions = [
  "Under150Thousand",
  "Under250Thousand",
  "Under500Thousand",
  "Under1Million",
  "Over1Million",
] as const;

export type IncomeRange = typeof incomeRangeOptions[number];

export const incomeRangeToLabel: Record<IncomeRange, string> = {
  Under150Thousand: "0 - 150k",
  Under250Thousand: "150 - 250k",
  Under500Thousand: "250 - 500k",
  Under1Million: "500 - 1MM",
  Over1Million: "1MM+",
};
