import React from "react";

import { useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import CashManagement from "../../CashManagement";
import AddManualFundingAccountDrawer from "@src/multiCustodian/components/Client/CashManagement/ChangeFundingAccount/AddManualFundingAccountDrawer";

const AddManualFundingAccount = (): JSX.Element => {
  const { clientId } = useParams<{ clientId: ClientId }>();

  return (
    <CashManagement>
      <AddManualFundingAccountDrawer clientId={clientId} />
    </CashManagement>
  );
};

export default AddManualFundingAccount;
