import React from "react";

import InfoOutlined from "@mui/icons-material/InfoOutlined";

import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import styles from "./AccountNames.module.css";

interface Props {
  accountNames: string[];
}

const AccountNames: React.FC<Props> = ({ accountNames }) => {
  return (
    <div>
      {`Accounts (${accountNames.length})`}
      <Tooltip
        tooltipText="When multiple accounts share a tax budget, we rebalance the largest account first and then move in descending order, with priority given to accounts that have tax-loss harvesting turned on."
        placement="top"
      >
        <InfoOutlined className={styles.icon} />
      </Tooltip>
    </div>
  );
};

export default AccountNames;
