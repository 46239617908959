import { PortfolioTaxType } from "@fartherfinance/frontend/api/Types";

export type TaxStatus = "Taxable" | "Tax-Advantaged" | "Any";

export const taxTypeToStatus: Record<PortfolioTaxType, TaxStatus> = {
  any: "Any",
  tax_exempt: "Tax-Advantaged",
  taxable: "Taxable",
};

export const taxStatusToTaxType: Record<TaxStatus, PortfolioTaxType> = {
  Any: "any",
  "Tax-Advantaged": "tax_exempt",
  Taxable: "taxable",
};
