import { PortfolioTaxType } from "@fartherfinance/frontend/api/Types";

const taxExemptRegex = /tax[-\s]*exempt$/i;
const taxableRegex = /taxable$/i;

/**
 *
 * @returns A portfolio model name that has 'Taxable' or 'Tax-Exempt' appended at the end.
 *
 * @remarks
 * BlackRock models tend to come back with their respective taxability at the end, but they are
 * usually lower-case, and 'tax exempt' lacks a dash, which we would like to see.
 * So we handle those cases.
 *
 * @example
 * "Blackrock Model 12 Tax-Exempt"
 *
 * "Farther 20/80 Model Portfolio Taxable"
 */
const makeDisplayName = (
  curDisplayName: string | undefined,
  taxExempt: boolean,
  portfolioTaxType: PortfolioTaxType | undefined
): string => {
  if (
    curDisplayName === undefined ||
    curDisplayName === "" ||
    portfolioTaxType === undefined
  ) {
    return "unknown";
  }

  if (portfolioTaxType === "any") {
    return curDisplayName;
  }

  if (taxExemptRegex.test(curDisplayName)) {
    return curDisplayName.replace(taxExemptRegex, "Tax-Exempt");
  }

  if (taxableRegex.test(curDisplayName)) {
    return curDisplayName.replace(taxableRegex, "Taxable");
  }

  return `${curDisplayName} ${taxExempt ? "Tax-Exempt" : "Taxable"}`;
};

export default makeDisplayName;
