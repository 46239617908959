import ms from "ms";

export default function setThemeCookie(
  newTheme: "farther_light" | "farther_dark"
): void {
  const d = new Date();

  d.setTime(d.getTime() + ms("1y"));

  document.cookie = `farther_ui_theme=${newTheme};expires=${d.toUTCString()};path=/;samesite=strict`;
}
