import { MarketplaceOfferingType } from "@fartherfinance/frontend/api/AltAssetsService/Types";

import { OfferingsListToolbarFilters } from "./OfferingsList/useOfferingsListDataV2";
import { MarketplaceOfferingTypeCard } from "./types";

export const findOfferingTypeByValue = (
  offeringTypes: MarketplaceOfferingTypeCard[],
  value: MarketplaceOfferingType
) => {
  return offeringTypes.find((offeringType) => offeringType.value === value);
};

export const findOfferingTypeByName = (
  offeringTypes: MarketplaceOfferingTypeCard[],
  value: string
) => {
  return offeringTypes.find((offeringType) => offeringType.name === value);
};

const tagToFilterMap: Record<string, keyof OfferingsListToolbarFilters> = {
  category: "filterByOfferingType",
  objective: "filterByObjectives",
  strategy: "filterByStrategy",
  "sub-strategy": "filterBySubStrategy",
  minimum_investment: "filterByMinimumInvestment",
  investor_qualification: "filterByInvestorQualification",
  liquidity: "filterByLiquidity",
  subscriptions: "filterBySubscriptions",
  "lock-up": "filterByLockUp",
};

export const mapTagKeyToFilterName = (
  tagKey: string
): keyof OfferingsListToolbarFilters | undefined => {
  return tagToFilterMap[tagKey];
};
