import { InitialStateParams, TaxBudgetFlowState } from "./types";
import {
  advisorDisclosureIndexMap,
  disclosureIndexMap,
  hypotheticalAdvisorDisclosureIndexMap,
  hypotheticalDisclosureIndexMap,
  pageIndexMap,
  pageIndexMapForHypothetical,
} from "./utils";

const INITIAL_STATE_INDEX = 0;

export const initialState = ({
  flowType,
  isAdvisor,
}: InitialStateParams): TaxBudgetFlowState => {
  const budgetDisclosures = isAdvisor
    ? advisorDisclosureIndexMap[INITIAL_STATE_INDEX]
    : disclosureIndexMap[INITIAL_STATE_INDEX];

  const hypotheticalDisclosures = isAdvisor
    ? hypotheticalAdvisorDisclosureIndexMap[INITIAL_STATE_INDEX]
    : hypotheticalDisclosureIndexMap[INITIAL_STATE_INDEX];

  return {
    buttonText: "Continue",
    disclosures:
      flowType === "Budget" ? budgetDisclosures : hypotheticalDisclosures,
    flowType: flowType,
    isAdvisor: isAdvisor,
    isContinueDisabled: true,
    pageIndex: 0,
    pageTitle:
      flowType === "Budget"
        ? pageIndexMap[INITIAL_STATE_INDEX]
        : pageIndexMapForHypothetical[INITIAL_STATE_INDEX],
    pageSubTitle:
      flowType === "Budget" ? "Set a Tax Budget" : "Hypothetical Scenario",
    data: {
      budgetAmount: 0,
      budgetId: undefined,
      clientIds: [],
      clientAccountIds: [],
      errorMessage: "None",
      excludedVirtualAccountIds: [],
      hypotheticalTradeGroups: [],
      hypotheticalProposal: null,
      year: undefined,
    },
  };
};
