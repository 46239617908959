/* eslint-disable react/prop-types */

import React, { Component } from "react";

import Button from "../MUI/Button/Button";

import "./alertMessageModal.css";

interface IncomingProps {
  top_text(): React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  >;
  bottom_text?: string | null;
  button_blue_text: string | null;
  button_blue_onClick(arg: any): any;
  button_yellow_text: string | null;
  button_yellow_onClick(arg: any): any;
  img_src?: string | null;
  modal_style?: object | null;
}

class AlertMessageModal extends Component<IncomingProps> {
  constructor(props: IncomingProps) {
    super(props);
  }

  render() {
    const TopText = this.props.top_text();

    return (
      <div className="AlertMessageModal__overlay">
        <div className="AlertMessageModal__modal_container">
          <div
            className="AlertMessageModal__modal"
            style={this.props.modal_style ? this.props.modal_style : {}}
          >
            <div className="AlertMessageModal__top">
              <div className="AlertMessageModal__icon_top_text_container">
                <img
                  className="AlertMessageModal__icon"
                  src={
                    this.props.img_src
                      ? this.props.img_src
                      : "/src/assets/svg/caution_triangle_white.svg"
                  }
                ></img>
                <div className="AlertMessageModal__top_text">{TopText}</div>
              </div>
              {this.props.bottom_text && (
                <p className="AlertMessageModal__bottom_text">
                  {this.props.bottom_text}
                </p>
              )}
            </div>
            <div className="AlertMessageModal__bottom">
              <Button
                variant={"outlined"}
                buttonType={"secondary"}
                text={
                  this.props.button_yellow_text
                    ? this.props.button_yellow_text
                    : "Yes"
                }
                onClick={this.props.button_yellow_onClick}
              />

              <Button
                variant={"contained"}
                buttonType={"primary"}
                text={
                  this.props.button_blue_text
                    ? this.props.button_blue_text
                    : "No"
                }
                onClick={this.props.button_blue_onClick}
                style={{ marginLeft: "15px" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AlertMessageModal;
