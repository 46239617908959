import React from "react";

import SearchIcon from "@mui/icons-material/Search";
import MuiAutocomplete, {
  autocompleteClasses,
  AutocompleteProps as MuiAutocompleteProps,
} from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import { TextField, TextFieldProps } from "../TextField/TextField.component";

import { useStyles } from "./Autocomplete.styles";

export type AutocompleteOption<T> = {
  label: string;
  value: T;
};

type AutocompletePropsToBlock =
  | "componentsProps"
  | "renderInput"
  | "renderOption";

type AutocompleteProps<T> = Omit<
  MuiAutocompleteProps<AutocompleteOption<T>, false, false, false>,
  AutocompletePropsToBlock
> & {
  isLoading?: boolean;
  getOptionKey: (option: AutocompleteOption<T>) => React.Key;
  TextFieldProps?: TextFieldProps;
};

/**
 * NOTE: we are not passing autocomplete InputProps.endAdornment to our custom TextField
 * thus we do not render both popup and clear icons
 */
export const Autocomplete = <T,>({
  className,
  isLoading,
  getOptionKey,
  TextFieldProps,
  ...restProps
}: AutocompleteProps<T>) => {
  const { classes, cx } = useStyles();

  return (
    <MuiAutocomplete
      className={cx(classes.autocomplete, className)}
      size="small"
      blurOnSelect
      openOnFocus
      disablePortal
      // NOTE: disable default filterOptions behavior
      filterOptions={(option) => option}
      componentsProps={{
        paper: { className: classes.menuPaper, elevation: 0 },
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 13],
              },
            },
          ],
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: <SearchIcon className={classes.searchIcon} />,
            endAdornment: (
              <>
                {isLoading && (
                  <CircularProgress
                    className={cx(
                      classes.loadingIcon,
                      autocompleteClasses.endAdornment
                    )}
                    size={15}
                  />
                )}
              </>
            ),
          }}
          {...TextFieldProps}
        />
      )}
      renderOption={(props, option, state) => (
        <MenuItem
          {...props}
          key={getOptionKey(option)}
          className={classes.menuItem}
          selected={state.selected}
        >
          <Typography className={classes.menuItemText}>
            {option.label}
          </Typography>
        </MenuItem>
      )}
      ListboxProps={{ className: classes.menuList }}
      {...restProps}
    />
  );
};
