import React, { useMemo } from "react";

import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";
import { mapObject } from "@fartherfinance/frontend/utils/mapObject";

import Checkbox from "@src/sharedComponents/Checkbox/Checkbox";

import styles from "./ScrollableCheckboxList.module.css";

type SubCheckboxLabel = string;

interface SubCheckbox {
  checked: boolean;
}

type SubCheckboxListConfig = Record<SubCheckboxLabel, SubCheckbox>;

export interface CheckboxConfig {
  isParent: boolean;
  checked: boolean;
  hasTopBorder: boolean;
  children: SubCheckboxListConfig | null;
}

export type CheckboxListConfig<T extends string> = Record<T, CheckboxConfig>;

export const copyConfig = <T extends string>(
  currentConfig: CheckboxListConfig<T>
): CheckboxListConfig<T> => {
  const entries: [T, CheckboxConfig][] = mapObject(currentConfig, (v, k) => [
    k,
    v,
  ]);
  return entries.reduce((acc, [key, val]) => {
    return {
      ...acc,
      [key]: {
        ...val,
        children: val.children ? copySubConfig(val.children) : null,
      },
    };
  }, {} as CheckboxListConfig<T>);
};

const copySubConfig = (
  childrenConfig: SubCheckboxListConfig
): SubCheckboxListConfig => {
  return Object.entries(childrenConfig).reduce((acc, [key, val]) => {
    return {
      ...acc,
      [key]: {
        ...val,
      },
    };
  }, {});
};

export const setConfigToAllChecked = <T extends string>(
  currentConfig: CheckboxListConfig<T>
) => {
  return updateConfigAfterToggleCheckAll(currentConfig, false);
};

export const setConfigToNoneChecked = <T extends string>(
  currentConfig: CheckboxListConfig<T>
) => {
  return updateConfigAfterToggleCheckAll(currentConfig, true);
};

const updateConfigAfterToggleCheckAll = <T extends string>(
  currentConfig: CheckboxListConfig<T>,
  currentlyAreAllChecked: boolean
): CheckboxListConfig<T> => {
  const newConfig = copyConfig(currentConfig);

  if (currentlyAreAllChecked) {
    // was checked now going to uncheck every checkbox
    mapObject(newConfig, (_, key) => key).forEach((key) => {
      newConfig[key].checked = false;
      newConfig[key].children = setAllInSubConfig(
        newConfig[key].children,
        false
      );
    });
  } else {
    // was not checked now going to check every checkbox
    mapObject(newConfig, (_, key) => key).forEach((key) => {
      newConfig[key].checked = true;
      newConfig[key].children = setAllInSubConfig(
        newConfig[key].children,
        true
      );
    });
  }

  return newConfig;
};

const setAllInSubConfig = (
  childrenConfig: SubCheckboxListConfig | null,
  bool: boolean
): SubCheckboxListConfig | null => {
  if (!childrenConfig) {
    return null;
  }

  return Object.entries(childrenConfig).reduce((acc, [key, _]) => {
    return {
      ...acc,
      [key]: {
        checked: bool,
      },
    };
  }, {});
};

interface NumCheckedTally {
  numChecked: number;
  total: number;
}

export const getTotalAndNumChecked = <T extends string>(
  checkboxesConfig: CheckboxListConfig<T>
): NumCheckedTally => {
  let total = 0;
  let checked = 0;
  mapObject(checkboxesConfig, (val, _) => val).forEach((val) => {
    if (val.isParent) {
      Object.values(val.children ?? {}).forEach((childVal) => {
        total += 1;
        if (childVal.checked) {
          checked += 1;
        }
      });
    } else {
      total += 1;
      if (val.checked) {
        checked += 1;
      }
    }
  });

  return { numChecked: checked, total: total };
};

export const calcAreAllChecked = <T extends string>(
  checkboxesConfig: CheckboxListConfig<T>
): boolean => {
  const result = getTotalAndNumChecked(checkboxesConfig);
  return result.total === result.numChecked;
};

export const calcAreAllUnchecked = <T extends string>(
  checkboxesConfig: CheckboxListConfig<T>
): boolean => {
  const result = getTotalAndNumChecked(checkboxesConfig);
  return result.numChecked === 0;
};

interface Props<T extends string> {
  haveSelectAll?: boolean;
  selectAllText?: string;
  checkboxesConfig: CheckboxListConfig<T>;
  setCheckboxesConfig: (checkboxesConfig: CheckboxListConfig<T>) => void;
  multiSelectOff?: boolean;
  minWidth?: number;
  maxHeight?: number;
}

const ScrollableCheckboxList = <T extends string>(
  props: Props<T>
): JSX.Element => {
  const {
    color: { $borderBold },
  } = useTheme();

  const areAllChecked = useMemo(() => {
    return calcAreAllChecked(props.checkboxesConfig);
  }, [props.checkboxesConfig]);

  const handleSetCheckAll = () => {
    const newConfig = updateConfigAfterToggleCheckAll(
      props.checkboxesConfig,
      areAllChecked
    );
    props.setCheckboxesConfig(newConfig);
  };

  const handleToggleParent = (key: T) => {
    const newConfig = copyConfig(props.checkboxesConfig);

    if (props.checkboxesConfig[key].checked) {
      if (props.multiSelectOff) {
        return; // when multiSelectOff, have to have only 1 checkbox checked at all times so don't want to uncheck the only checkbox that is currently checked
      }

      // was checked now going to be unchecked -> uncheck every child
      newConfig[key] = {
        ...newConfig[key],
        checked: false,
        children: setAllInSubConfig(newConfig[key].children, false),
      };
    } else {
      if (props.multiSelectOff) {
        mapObject(newConfig, (_, k) => k).forEach((k) => {
          if (k === key) {
            // was not checked now going to be checked + check every child
            newConfig[key] = {
              ...newConfig[key],
              checked: true,
              children: setAllInSubConfig(newConfig[key].children, true),
            };
          } else {
            // set to unchecked, only 1 should be checked (above)
            newConfig[k].checked = false;
            newConfig[k].children = setAllInSubConfig(
              newConfig[k].children,
              false
            );
          }
        });
      } else {
        // was not checked now going to be checked + check every child
        newConfig[key] = {
          ...newConfig[key],
          checked: true,
          children: setAllInSubConfig(newConfig[key].children, true),
        };
      }
    }

    props.setCheckboxesConfig(newConfig);
  };

  const handleToggleChild = (parentKey: T, childKey: SubCheckboxLabel) => {
    const newConfig = copyConfig(props.checkboxesConfig);

    if (props.checkboxesConfig[parentKey].children?.[childKey].checked) {
      // was checked now going to be unchecked -> uncheck parent and child but not other neighboring children
      newConfig[parentKey] = {
        ...newConfig[parentKey],
        checked: false,
        children: {
          ...newConfig[parentKey].children,
          [childKey]: {
            checked: false,
          },
        },
      };
    } else {
      // was not checked now going to be checked
      const newChildren = {
        ...newConfig[parentKey].children,
        [childKey]: {
          checked: true,
        },
      };

      const everyChildChecked = Object.values(newChildren).every(
        (child) => child.checked
      );

      newConfig[parentKey] = {
        ...newConfig[parentKey],
        checked: everyChildChecked,
        children: newChildren,
      };
    }

    props.setCheckboxesConfig(newConfig);
  };

  const entries: [T, CheckboxConfig][] = useMemo(() => {
    return mapObject(props.checkboxesConfig, (val, key) => [key, val]);
  }, [props.checkboxesConfig]);

  return (
    <div
      className={styles.container}
      style={{
        ...(props.minWidth ? { minWidth: `${props.minWidth}px` } : {}),
        ...(props.maxHeight ? { maxHeight: `${props.maxHeight}px` } : {}),
      }}
    >
      <div className={styles.selectAllWrapper}>
        {props.haveSelectAll && (
          <Checkbox
            checked={areAllChecked}
            onChange={handleSetCheckAll}
            label={props.selectAllText ?? "All"}
            disabled={false}
            labelMargin={6}
          />
        )}
      </div>

      {entries.map(([parentLabel, parentConfig]) => {
        return (
          <div
            key={parentLabel}
            className={styles.parentWrapper}
            style={
              parentConfig.hasTopBorder
                ? {
                    borderTop: `1px solid ${$borderBold}`,
                    paddingTop: "10px",
                    marginTop: "10px",
                  }
                : {}
            }
          >
            <div className={styles.parentInnerWrapper}>
              <Checkbox
                checked={parentConfig.checked}
                onChange={() => handleToggleParent(parentLabel)}
                label={parentLabel}
                labelMargin={6}
              />
              {parentConfig.children &&
                Object.entries(parentConfig.children).map(
                  ([childLabel, childConfig]) => {
                    return (
                      <div key={childLabel} className={styles.childWrapper}>
                        <Checkbox
                          checked={childConfig.checked}
                          onChange={() =>
                            handleToggleChild(parentLabel, childLabel)
                          }
                          label={childLabel}
                          labelMargin={6}
                        />
                      </div>
                    );
                  }
                )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ScrollableCheckboxList;
