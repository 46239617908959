import React from "react";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles2 from "./HypotheticalHoldingsWeightsTable.module.css";
import styles from "../basicTable.module.css";

interface Props {
  ticker: string;
  current: string;
  target: string;
  withTaxBudget: string;
  withoutTaxBudget: string;
  difference: string;
}

const SecuritiesRow: React.FC<Props> = (props) => {
  return (
    <tr className={toClassName(styles.row)}>
      <td className={toClassName(styles.cell, styles2.textAlignLeft)}>
        {props.ticker}
      </td>

      <td className={toClassName(styles.cell, styles2.textAlignRight)}>
        {props.current}
      </td>

      <td className={toClassName(styles.cell, styles2.textAlignRight)}>
        {props.target}
      </td>

      <td className={toClassName(styles.cell, styles2.textAlignRight)}>
        {props.withoutTaxBudget}
      </td>

      <td className={toClassName(styles.cell, styles2.textAlignRight)}>
        {props.withTaxBudget}
      </td>

      <td className={toClassName(styles.cell, styles2.textAlignRight)}>
        {props.difference}
      </td>
    </tr>
  );
};

export default SecuritiesRow;
