import {
  ExternalAccountExtended,
  PlaidAccountExtended,
} from "@fartherfinance/frontend/api/ExternalAccount/hooks/useExternalAccountsExtended";
import {
  ExternalAccount,
  ManualAccount,
} from "@fartherfinance/frontend/api/ExternalAccount/requests/getExternalAccounts";

export const isManualAccount = (
  acc: ExternalAccountExtended | ExternalAccount
): acc is ManualAccount => {
  return acc.accountProvider === "manual";
};

export const isPlaidAccount = (
  acc: ExternalAccountExtended
): acc is PlaidAccountExtended => {
  return acc.accountProvider === "plaid" && "link" in acc;
};
