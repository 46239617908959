import React, { useState } from "react";

import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import { captureException } from "@src/multiCustodian/services/tracking";

import styles from "./FileUploadButton.module.css";

const makeFilenameUrlSafeReplaceRegex = /[^a-zA-Z0-9_.-]/g;

const copyFileAndMakeFilenameUrlSafe = (oldFile: File): File => {
  const newFilename = makeFilenameUrlSafe(oldFile.name);
  const newFile = new File([oldFile.slice()], newFilename, {
    type: oldFile.type,
  });
  return newFile;
};

const makeFilenameUrlSafe = (filename: string): string => {
  return filename.replace(makeFilenameUrlSafeReplaceRegex, "_");
};

interface Props {
  onChange: (file: File | null) => void;
  currentFileName?: React.ReactNode;
  acceptedFileExtensions?: string[];
}

const FileUploadButton = (props: Props) => {
  const [file, setFile] = useState<File | null>(null);

  const browseFiles = () => {
    const inputField: HTMLElement | null =
      document.querySelector("input[type=file]");
    if (inputField) {
      inputField.click();
    }
  };

  const checkFileReady = () => {
    const element: HTMLInputElement | null =
      document.querySelector("input[type=file]");

    const filesList = element === null ? null : element.files;

    const inputFile = filesList === null ? undefined : filesList[0];

    if (inputFile === undefined) {
      const errorMessage = "fileReady has an undefined file.";

      console.error(errorMessage, element, filesList, inputFile);

      captureException(new Error(errorMessage), {
        extra: {
          elementIsNull: element === null ? "True" : "False",
          "filesList.length":
            filesList === null
              ? "null"
              : filesList === undefined
              ? "undefined"
              : filesList.length.toString(),
          fileIsType: inputFile === null ? "null" : typeof inputFile,
        },
      });
      return;
    }

    const fileWithSafeName = copyFileAndMakeFilenameUrlSafe(inputFile);
    setFile(fileWithSafeName);
    props.onChange(fileWithSafeName);
  };

  return (
    <div className={styles.container}>
      <div className={styles.chooseFileDiv}>
        <ButtonPrimary
          text={"Choose File"}
          variant="outlined"
          buttonType="secondary"
          onClick={() => browseFiles()}
          sx={{ height: "30px", marginRight: "15px", whiteSpace: "nowrap" }}
        />

        {file ? (
          <p className={styles.chosenFile}>{file.name}</p>
        ) : props.currentFileName ? (
          <div className={styles.chosenFile}>{props.currentFileName}</div>
        ) : (
          <p className={styles.noFileChosen}>No file chosen</p>
        )}
      </div>

      <input
        type="file"
        accept={
          props.acceptedFileExtensions
            ? props.acceptedFileExtensions.join(", ")
            : undefined
        }
        onChange={checkFileReady}
        className={styles.htmlFileInput}
      />
    </div>
  );
};

/* eslint-disable import/no-unused-modules */
export default FileUploadButton;
