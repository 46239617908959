import React, { useReducer } from "react";

import SharingEditCollaboratorFlow from "@src/multiCustodian/components/Sharing/Flows/EditCollaborator/Flow";
import EditCollaboratorContext from "@src/multiCustodian/components/Sharing/Flows/EditCollaborator/reducer/Context";
import { initialState } from "@src/multiCustodian/components/Sharing/Flows/EditCollaborator/reducer/initialState";
import reducer from "@src/multiCustodian/components/Sharing/Flows/EditCollaborator/reducer/reducer";

const SharingEditCollaborator = (): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <EditCollaboratorContext.Provider value={{ state, dispatch }}>
      <SharingEditCollaboratorFlow />
    </EditCollaboratorContext.Provider>
  );
};

export default SharingEditCollaborator;
