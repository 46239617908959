import { Action } from "redux";

import { DashboardForm } from "../pages/Dashboard/Dashboard_Components/DashboardForms/Types";
import { deepFreeze } from "@src/utils/deepFreeze";

import { END_IMPERSONATION, EndImpersonation } from "./commonActions";

type DrawerPanelOnboardingFormsContext = {
  formToShow: DashboardForm;
};

interface MiscReducerState {
  is_in_a_modal: boolean;
  drawerPanel: {
    isOpen: boolean;
    context: DrawerPanelOnboardingFormsContext | null;
  };
}

const initialState = deepFreeze({
  is_in_a_modal: false,
  drawerPanel: {
    isOpen: false,
    context: null,
  },
});

interface SET_IS_IN_A_MODAL___ACTION {
  type: "SET_IS_IN_A_MODAL";
  payload: {
    is_in_a_modal: boolean;
  };
}

interface SET_DRAWER_PANEL_CONTEXT_Action extends Action {
  type: "SET_DRAWER_PANEL_CONTEXT";
  payload: DrawerPanelOnboardingFormsContext;
}

interface UNSET_DRAWER_PANEL_CONTEXT_Action extends Action {
  type: "UNSET_DRAWER_PANEL_CONTEXT";
}

export type MiscReducerActions =
  | SET_IS_IN_A_MODAL___ACTION
  | SET_DRAWER_PANEL_CONTEXT_Action
  | UNSET_DRAWER_PANEL_CONTEXT_Action
  | EndImpersonation;

const misc_Reducer = (
  state: MiscReducerState = initialState,
  action: MiscReducerActions
): MiscReducerState => {
  switch (action.type) {
    case END_IMPERSONATION:
      return { ...initialState };
    case "SET_IS_IN_A_MODAL":
      return {
        ...state,
        is_in_a_modal: action.payload.is_in_a_modal,
      };

    case "SET_DRAWER_PANEL_CONTEXT":
      return {
        ...state,
        drawerPanel: {
          isOpen: true,
          context: action.payload,
        },
      };

    case "UNSET_DRAWER_PANEL_CONTEXT":
      return {
        ...state,
        drawerPanel: {
          isOpen: false,
          context: null,
        },
      };

    default:
      return state;
  }
};

export default misc_Reducer;
