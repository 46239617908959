export enum actions {
  RESET_TO_DEFAULT_DATA = "RESET_TO_DEFAULT_DATA",
  SET_PAGE_INDEX = "SET_PAGE_INDEX",
  INCREMENT_PAGE_INDEX = "INCREMENT_PAGE_INDEX",
  DECREMENT_PAGE_INDEX = "DECREMENT_PAGE_INDEX",
  SET_YEAR = "SET_YEAR",
  SET_EXCLUDED_ACCOUNT_IDS = "SET_EXCLUDED_ACCOUNT_IDS",
  TOGGLE_EXCLUDED_ACCOUNT_ID = "TOGGLE_EXCLUDED_ACCOUNT_ID",
  SET_ACCOUNT_IDS = "SET_ACCOUNT_IDS",
  SET_BUDGET_AMOUNT = "SET_BUDGET_AMOUNT",
  SET_TAX_BUDGET_ID = "SET_TAX_BUDGET_ID",
  SET_EXISTING_BUDGET = "SET_EXISTING_BUDGET",
  SET_BLANK_BUDGET_FOR_YEAR = "SET_BLANK_BUDGET_FOR_YEAR",
  SET_IS_ADVISOR = "SET_IS_ADVISOR",
  SET_CLIENT_IDS = "SET_CLIENT_IDS",
  SET_HYPOTHETICAL_TRADEGROUPS = "SET_HYPOTHETICAL_TRADEGROUPS",
  SET_HYPOTHETICAL_PROPOSAL = "SET_HYPOTHETICAL_PROPOSAL",
}
