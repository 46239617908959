import React from "react";

import CircleIcon from "@mui/icons-material/Circle";

import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import Chip from "@src/sharedComponents/Chip/Chip";

import styles from "../Accordion.module.css";

export default function PlaidNotConnectedChip(): JSX.Element {
  const {
    color: { $iconDanger, $textSubtle },
  } = useTheme();

  return (
    <Chip
      icon={
        <CircleIcon
          style={{ color: $iconDanger }}
          className={styles.plaidNotConnectedCircleIcon}
        />
      }
      label={"Not Connected"}
      labelColor={$textSubtle}
      sx={{ marginLeft: "10px" }}
    />
  );
}
