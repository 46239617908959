import React, { useEffect, useRef, useState } from "react";

import { ArrowForward } from "@mui/icons-material";

import Button from "../MUI/Button/Button";
import TextInput from "@src/sharedComponents/TextInput/TextInput";
import Text from "@src/sharedComponents/Typography/Text";

import Layout from "./Layout";

import * as styles from "./StytchLogin.module.css";

interface Props {
  authenticateOTP: (code: string) => Promise<void>;
  emailAddress?: string;
  error: string | null;
  isLoading: boolean;
  methodType: "email" | "phone";
  onBack?: () => void;
  resendDisabled: boolean;
  resendOTPCode: () => Promise<void>;
}

const VerifyOTP: React.FC<Props> = ({
  authenticateOTP,
  emailAddress,
  error,
  isLoading,
  methodType,
  onBack,
  resendDisabled,
  resendOTPCode,
}) => {
  const lastMethodType = useRef(methodType);
  const [code, setCode] = useState("");

  useEffect(() => {
    // If we change the OTP method type, reset the code
    if (lastMethodType.current !== methodType) {
      setCode("");
      lastMethodType.current = methodType;
    }
  }, [methodType]);

  const title = methodType === "email" ? "Code Sent" : "Verify Your Account";

  const subtitle =
    methodType === "email"
      ? `We sent a one-time code to ${
          emailAddress ?? "your email"
        }. Please check your device and enter the code below.`
      : "To verify your identify, enter the code sent to your phone. ";

  const submitDisabled = code === "";

  return (
    <Layout onBack={onBack} subtitle={subtitle} title={title}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (submitDisabled) {
            return;
          }

          authenticateOTP(code);
        }}
      >
        <div className={styles.horizontal}>
          <TextInput
            autoFocus
            label="Code"
            placeholder="123456"
            type="number"
            autoComplete="one-time-code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </div>

        {error !== null ? (
          <Text className={styles.helper} variant="helperError">
            {error}
          </Text>
        ) : (
          <div className={styles.helperSpacer} />
        )}

        <div className={styles.spacer} />

        <Button
          disabled={submitDisabled}
          text="Verify code"
          onClick={(e) => {
            e.preventDefault();
            authenticateOTP(code);
          }}
          variant="contained"
          buttonType="primary"
          sx={{ width: "100%" }}
          useLoader
          isLoading={isLoading}
        />

        <div className={styles.spacer} />

        <Button
          disabled={resendDisabled}
          text={
            <>
              Resend Code <ArrowForward className={styles.arrow} />
            </>
          }
          onClick={(e) => {
            e.preventDefault();
            setCode("");
            resendOTPCode();
          }}
          variant="text"
          buttonType="primary"
          sx={{
            width: "100%",
            textDecoration: "underline",
            color: resendDisabled
              ? "var(--textDisabled) !important"
              : "inherit",
          }}
        />

        <input type="submit" style={{ display: "none" }} />
      </form>
    </Layout>
  );
};

export default VerifyOTP;
