import { useState } from "react";

export type UploadState = "uploading" | "success" | "error";
export type UploadStateMap = Record<string, UploadState>;

const useUploadStateMap = () => {
  const [uploadStateMap, setUploadStateMap] = useState<UploadStateMap | null>(
    null
  );

  const setUploadState = (filename: string, uploadState: UploadState): void => {
    setUploadStateMap((prev) => ({
      ...prev,
      [filename]: uploadState,
    }));
  };

  const resetUploadState = (): void => {
    setUploadStateMap(null);
  };

  return {
    uploadStateMap,
    setUploadState,
    setUploadStateBare: setUploadStateMap,
    resetUploadState,
  };
};

export default useUploadStateMap;
