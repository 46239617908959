import React from "react";

import PieChartIcon from "@mui/icons-material/PieChart";
import { Link } from "react-router-dom";

import {
  ClientId,
  HookResult,
  PortfolioId,
} from "@fartherfinance/frontend/api/Types";

import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import styles from "../Settings.module.css";

const noPortfolioTooltipText =
  "A model portfolio is not applied to this account. Go to the Model Portfolios page to apply one.";

type Props = {
  clientId: ClientId;
  portfolioId: HookResult<PortfolioId | null>;
};

const PortfolioModelLink = (props: Props): JSX.Element => {
  const statusNotification = useStatusNotification();

  if (props.portfolioId.isLoading) {
    return (
      <Link
        to={"#"}
        className={toClassName(styles.buttonDiv, styles.buttonBackground)}
        onClick={() => statusNotification("Loading", "Error")}
      >
        <PieChartIcon className={styles.muiImg} />

        <div className={styles.text}>Portfolio Model</div>
      </Link>
    );
  }

  if (props.portfolioId.hasError) {
    return (
      <Link
        to={"#"}
        className={toClassName(styles.buttonDiv, styles.buttonBackground)}
        onClick={() => statusNotification("Error loading", "Error")}
      >
        <PieChartIcon className={styles.muiImg} />

        <div className={styles.text}>Portfolio Model</div>
      </Link>
    );
  }

  if (props.portfolioId.data === null) {
    return (
      <Tooltip placement="top" tooltipText={noPortfolioTooltipText}>
        <Link
          to={"#"}
          className={toClassName(
            styles.buttonDiv,
            styles.buttonBackgroundDisabled
          )}
        >
          <PieChartIcon className={styles.muiImgDisabled} />

          <div className={styles.textDisabled}>Portfolio Model</div>
        </Link>
      </Tooltip>
    );
  }

  return (
    <Link
      to={`/Client/${props.clientId}/Investments/Portfolios/${props.portfolioId.data}/Accounts`}
      className={toClassName(styles.buttonDiv, styles.buttonBackground)}
    >
      <PieChartIcon className={styles.muiImg} />

      <div className={styles.text}>Portfolio Model</div>
    </Link>
  );
};

export default PortfolioModelLink;
