import React, { PropsWithChildren } from "react";

import ClearIcon from "@mui/icons-material/Clear";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import Modal from "@src/sharedComponents/Modal/Modal";

import styles from "./ModalWithClearButton.module.css";

interface Props {
  isOpen?: boolean;
  disableAutoFocus?: boolean;
  modalStyle?: React.CSSProperties;
  className?: string;
  closeModal: () => void;
}

const ModalWithClearButton: React.FC<PropsWithChildren<Props>> = ({
  isOpen,
  disableAutoFocus,
  modalStyle,
  className,
  closeModal,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <Modal
      className={toClassName(styles.modal, className)}
      isOpen={isOpen}
      closeModal={closeModal}
      modalStyle={modalStyle}
      disableAutoFocus={disableAutoFocus}
    >
      <div className={styles.clearIconDiv}>
        <ClearIcon className={styles.clearIcon} onClick={closeModal} />
      </div>

      <div className={styles.container}>{children}</div>
    </Modal>
  );
};

export default ModalWithClearButton;
