import React from "react";

import CloseIcon from "@mui/icons-material/Close";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./DeletableChip.module.css";

interface Props {
  label: string;
  onPress: () => void;
  onClear: () => void;
  className?: string;
  hideCloseIcon?: boolean;
}

const DeletableChip = (props: Props) => {
  return (
    <div className={toClassName(styles.container, props.className)}>
      <div className={styles.text} onClick={() => props.onPress()}>
        {props.label}
      </div>

      {!props.hideCloseIcon && (
        <CloseIcon className={styles.chipCloseIcon} onClick={props.onClear} />
      )}
    </div>
  );
};

export default DeletableChip;
