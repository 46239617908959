import React from "react";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./BasicTableChip.module.css";

interface Props {
  text: string;
  isSelected: boolean;
  onClick: () => void;
}

const BasicTableChip = (props: React.PropsWithChildren<Props>) => {
  return (
    <div
      className={toClassName(styles.container, {
        [styles.selected]: props.isSelected,
        [styles.notSelected]: !props.isSelected,
      })}
      onClick={props.onClick}
    >
      <div className={styles.text}>{props.text}</div>

      {props.children}
    </div>
  );
};

export default BasicTableChip;
