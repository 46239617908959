import React, { useEffect } from "react";

import { isAfter, isBefore, isValid, sub } from "date-fns";
import { FormProvider, useForm, useFormState } from "react-hook-form";

import FormDateField from "@src/sharedComponents/Forms/FormDateField";

import styles from "./CustomDateRangePicker.module.css";

export interface CustomDateRange {
  from: Date | null;
  to: Date | null;
}

interface Form {
  FromDate: Date | undefined;
  ToDate: Date | undefined;
}

interface Props {
  customDateRange: CustomDateRange;
  setCustomDateRange: (dateRangeObj: CustomDateRange) => void;
  setFormIsValid: (b: boolean) => void;
}

const CustomDateRangePicker = ({
  customDateRange,
  setCustomDateRange,
  setFormIsValid,
}: Props) => {
  const methods = useForm<Form>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      FromDate: customDateRange.from ?? undefined,
      ToDate: customDateRange.to ?? undefined,
    },
  });

  const { control, getValues, reset } = methods;

  const { isDirty: formIsDirty, isValid: formIsValid } = useFormState({
    control,
  });

  useEffect(() => {
    setFormIsValid(formIsValid);
  }, [setFormIsValid, formIsValid]);

  const values = getValues();

  useEffect(() => {
    if (formIsDirty) {
      const from = values.FromDate ?? null;
      const to = values.ToDate ?? null;

      reset({}, { keepValues: true });

      setCustomDateRange({
        from: sanitizeDate(from),
        to: sanitizeDate(to),
      });
    }
    // eslint-disable-next-line
  }, [values, formIsDirty]);

  const sanitizeDate = (date: Date | null) => {
    if (!date) {
      return null;
    }

    if (
      isValid(date) &&
      isAfter(date, sub(new Date(), { years: 5 })) &&
      isBefore(date, new Date())
    ) {
      return date;
    }

    return null;
  };

  return (
    <div className={styles.container}>
      <div className={styles.heading}>Custom Date Range</div>

      <FormProvider {...methods}>
        <div className={styles.flexDiv}>
          <div className={styles.text}>From</div>

          <div className={styles.picker}>
            <FormDateField
              required={"Add a date"}
              label={undefined}
              name={"FromDate"}
              placeholder={"MM/DD/YYYY"}
              rules={{
                deps: ["ToDate"],
                validate: {
                  isValid: (value) => {
                    if (value && !isValid(value)) {
                      return "Date is not valid";
                    }

                    if (
                      value &&
                      isBefore(value, sub(new Date(), { years: 5 }))
                    ) {
                      return "Date is too far in the past";
                    }

                    return true;
                  },
                  isNotFuture: (value) => {
                    if (value && isAfter(value, new Date())) {
                      return "Cannot select a future date";
                    }
                    return true;
                  },
                  isInRange: (value, { ToDate }) => {
                    if (value && isAfter(value, ToDate)) {
                      return "Needs to be a date before To date";
                    }
                    return true;
                  },
                },
              }}
            />
          </div>
        </div>

        <div className={styles.flexDiv}>
          <div className={styles.text}>To</div>

          <div className={styles.picker}>
            <FormDateField
              required={"Add a date"}
              label={undefined}
              name={"ToDate"}
              placeholder={"MM/DD/YYYY"}
              rules={{
                deps: ["FromDate"],
                validate: {
                  isValid: (value) => {
                    if (value && !isValid(value)) {
                      return "Date is not valid";
                    }

                    if (
                      value &&
                      isBefore(value, sub(new Date(), { years: 5 }))
                    ) {
                      return "Date is too far in the past";
                    }

                    return true;
                  },
                  isNotFuture: (value) => {
                    if (
                      value &&
                      isAfter(value, new Date()) &&
                      isBefore(value, sub(new Date(), { years: 5 }))
                    ) {
                      return "Cannot select a future date";
                    }
                    return true;
                  },
                  isInRange: (value, { FromDate }) => {
                    if (value && isBefore(value, FromDate)) {
                      return "Needs to be a date after From date";
                    }
                    return true;
                  },
                },
              }}
            />
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default CustomDateRangePicker;
