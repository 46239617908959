import React from "react";

import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import { graphSettings } from "../utils/graphSettings";

interface Props {
  x: number;
  y: number;
  showHorizontal: boolean;
  showVertical: boolean;
}

const CustomHoverLines = (props: Props): JSX.Element => {
  const {
    color: { $borderFocused },
  } = useTheme();

  return (
    <>
      {/* Horizontal */}
      {props.showHorizontal && (
        <line
          x1={graphSettings.YAxisWidth}
          y1={props.y}
          x2={props.x}
          y2={props.y}
          stroke={$borderFocused}
          strokeDasharray="4 4"
        />
      )}
      {/* Vertical */}
      {props.showVertical && (
        <line
          x1={props.x}
          y1={`calc(100% - ${graphSettings.XAxisHeight}px)`} //if this is just 100%, the line sticks out from the bottom unless XAxisHeight is 0
          x2={props.x}
          y2={props.y}
          stroke={$borderFocused}
          strokeDasharray="4 4"
        />
      )}
    </>
  );
};

export default CustomHoverLines;
