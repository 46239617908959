import React from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom-v5-compat";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import { AccountsPath } from "@src/config/routing/RouterPaths";
import BackButton from "@src/multiCustodian/components/Client/BackButton";
import ExitButton from "@src/multiCustodian/components/Client/ExitButton";
import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import { State as ReduxState } from "@src/store";

import styles from "./AddAccount.module.css";

const AddAccount = (): JSX.Element => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const { chevronRightImg, isAdvisor } = useSelector((state: ReduxState) => ({
    chevronRightImg: state.main_Reducer.brand.current.images.chevron_right,
    isAdvisor: state.main_Reducer.user.isAdvisor,
  }));

  const history = useHistory();

  const { enableOffPortalAccountAdding } = useFlags();

  return (
    <BaseLayout showSideNav={false}>
      <ExitButton />

      <div className={styles.container}>
        <div className={styles.centeringContainer1}>
          <div className={styles.backButtonContainer}>
            <BackButton
              onClick={() =>
                history.push(`/Client/${clientId}/${AccountsPath}`)
              }
            />
          </div>

          <div className={styles.innerContainer}>
            <div className={styles.centeringContainer2}>
              <p className={styles.title}>Add Account</p>

              <p className={styles.summary}>
                Please select the type of account you would like to add
              </p>

              <div
                className={isAdvisor ? styles.disableLink : styles.optionDiv}
                onClick={
                  isAdvisor
                    ? undefined
                    : () =>
                        history.push(
                          `/Client/${clientId}/${AccountsPath}/Add/External`
                        )
                }
              >
                <p className={styles.optionText}>Link Account via Plaid</p>

                <img className={styles.optionImg} src={chevronRightImg} />
              </div>

              <Link
                className={styles.optionDiv}
                to={`/Client/${clientId}/${AccountsPath}/Add/Manual`}
              >
                <p className={styles.optionText}>
                  Add Manually-Tracked Account
                </p>

                <img className={styles.optionImg} src={chevronRightImg} />
              </Link>

              {isAdvisor && enableOffPortalAccountAdding === true && (
                <Link
                  className={styles.optionDiv}
                  to={`/Client/${clientId}/${AccountsPath}/Add/OffPortal`}
                >
                  <p className={styles.optionText}>Add Off-Portal Account</p>

                  <img className={styles.optionImg} src={chevronRightImg} />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default AddAccount;
