import React from "react";

const Clients: React.FC = () => (
  <svg
    width="100%"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon">
      <path
        id="Vector"
        d="M7 7.4375C7.95083 7.4375 8.79083 7.665 9.47333 7.9625C10.1033 8.2425 10.5 8.8725 10.5 9.555V10.5H3.5V9.56083C3.5 8.8725 3.89667 8.2425 4.52667 7.96833C5.20917 7.665 6.04917 7.4375 7 7.4375ZM2.33333 7.58333C2.975 7.58333 3.5 7.05833 3.5 6.41667C3.5 5.775 2.975 5.25 2.33333 5.25C1.69167 5.25 1.16667 5.775 1.16667 6.41667C1.16667 7.05833 1.69167 7.58333 2.33333 7.58333ZM2.9925 8.225C2.77667 8.19 2.56083 8.16667 2.33333 8.16667C1.75583 8.16667 1.2075 8.28917 0.711667 8.505C0.28 8.69167 0 9.11167 0 9.58417V10.5H2.625V9.56083C2.625 9.07667 2.75917 8.62167 2.9925 8.225ZM11.6667 7.58333C12.3083 7.58333 12.8333 7.05833 12.8333 6.41667C12.8333 5.775 12.3083 5.25 11.6667 5.25C11.025 5.25 10.5 5.775 10.5 6.41667C10.5 7.05833 11.025 7.58333 11.6667 7.58333ZM14 9.58417C14 9.11167 13.72 8.69167 13.2883 8.505C12.7925 8.28917 12.2442 8.16667 11.6667 8.16667C11.4392 8.16667 11.2233 8.19 11.0075 8.225C11.2408 8.62167 11.375 9.07667 11.375 9.56083V10.5H14V9.58417ZM7 3.5C7.96833 3.5 8.75 4.28167 8.75 5.25C8.75 6.21833 7.96833 7 7 7C6.03167 7 5.25 6.21833 5.25 5.25C5.25 4.28167 6.03167 3.5 7 3.5Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default Clients;
