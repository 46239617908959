import React, { useCallback } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import { trackEvent } from "@src/multiCustodian/services/tracking";
import LinkButton from "@src/sharedComponents/LinkButton/LinkButton";

import styles from "./Buttons.module.css";

interface Props {
  disabled?: boolean;
}

const Buttons = ({ disabled }: Props): JSX.Element => {
  const { clientId } = useParams<{ clientId: ClientId }>();

  const { enableTaxBudgetHypotheticals } = useFlags();

  const onTrackOpenCreate = useCallback(() => {
    trackEvent({ name: "Client Open Create Tax-Budget" });
  }, []);

  const onTrackOpenHypothetical = useCallback(() => {
    trackEvent({ name: "Client Open Hypothetical-Tax-Budget" });
  }, []);

  return (
    <div className={styles.container}>
      <LinkButton
        buttonType={"primary"}
        variant={"contained"}
        text={"New Tax Budget"}
        sx={{ width: "210px", marginTop: "10px" }}
        onClick={onTrackOpenCreate}
        to={`/Client/${clientId}/TaxBudgets/Create`}
        disabled={disabled}
      />

      {enableTaxBudgetHypotheticals && (
        <LinkButton
          buttonType={"secondary"}
          variant={"outlined"}
          text={"New Hypothetical Scenario"}
          sx={{ width: "210px" }}
          onClick={onTrackOpenHypothetical}
          to={`/Client/${clientId}/TaxBudgets/CreateHypothetical`}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default Buttons;
