import React from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MuiSelect, {
  SelectProps as MuiSelectProps,
  SelectChangeEvent,
} from "@mui/material/Select";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import SelectItem from "./SelectItem";

import styles from "./Select.module.css";

export type SelectProps<T, V = T> = Omit<
  MuiSelectProps<V>,
  "value" | "defaultValue" | "onChange" | "renderValue"
> & {
  className?: string;
  menuClassName?: string;
  options: readonly T[];
  value: V;
  onChange: (value: V) => void;
  renderValue?: (value: V) => React.ReactNode;
  renderOption?: (value: T) => React.ReactNode;
};

// TODO: add label and helper text
export default function Select<T, V = T>({
  className,
  options,
  value,
  onChange,
  renderOption,
  ...props
}: SelectProps<T, V>) {
  const handleOnChange = (event: SelectChangeEvent<V>): void => {
    onChange(event.target.value as V);
  };

  const handleDefaultRenderOption = (option: T): React.ReactNode => {
    if (typeof option === "string") {
      return <SelectItem key={option} label={option} value={option} />;
    }

    return <SelectItem label="Type not" value="Unknown" />;
  };

  return (
    <MuiSelect
      className={toClassName(styles.select, className)}
      variant={props.variant ?? "outlined"}
      size={props.size ?? "small"}
      value={value}
      onChange={handleOnChange}
      IconComponent={(props) => (
        <KeyboardArrowDownIcon
          className={toClassName(styles.icon, props.className)}
        />
      )}
      MenuProps={{
        classes: { paper: toClassName(styles.menuPaper, props.menuClassName) },
        autoFocus: false,
        disablePortal: true,
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
        transformOrigin: { horizontal: "right", vertical: -4 },
        PaperProps: { elevation: 0 },
        MenuListProps: { className: styles.menuList },
      }}
      {...props}
    >
      {options.map(renderOption ?? handleDefaultRenderOption)}
    </MuiSelect>
  );
}
