import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

export const useStyles = makeStyles()((theme) => ({
  searchInput: {
    marginLeft: theme.spacing(pxToSpacing(16)),
  },
  searchAdornment: {
    marginRight: theme.spacing(pxToSpacing(6)),
  },
  searchIcon: {
    color: theme.appearance.$iconSubtle,
    width: 18,
    height: 18,
  },
  filtersContainer: {
    cursor: "pointer",
  },
  filtersLabel: {
    fontFamily: theme.typography.fontSecondary,
    fontSize: theme.typography.pxToRem(12),
    letterSpacing: "0.45px",
    color: theme.appearance.$text,
    marginLeft: theme.spacing(pxToSpacing(20)),
    lineHeight: "13px",
  },
  filtersIconRotate: {
    transform: "rotate(180deg)",
  },
  switchText: {
    fontFamily: theme.typography.fontSecondary,
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: "0.63px",
    color: theme.appearance.$text,
  },
  switch: {
    display: "flex",
  },
}));
