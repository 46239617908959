import React, { ComponentProps } from "react";

import { Controller, Message, useFormContext } from "react-hook-form";

import RadioButtons from "../Radio/RadioButtons";

interface Props {
  name: string;
  label: string;
  values: readonly string[];
  defaultValue?: string;
  required?: Message | false;
  rules?: ComponentProps<typeof Controller>["rules"];
  row?: boolean;
}

export default function FormRadioField({ ...props }: Props): JSX.Element {
  const { control } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      rules={{ required: props.required, ...props.rules }}
      defaultValue={props.defaultValue}
      render={({ field, fieldState }) => (
        <RadioButtons
          label={props.label}
          onChange={field.onChange}
          value={field.value ?? null}
          values={props.values}
          onBlur={field.onBlur}
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
          isValid={undefined}
          row={props.row}
        />
      )}
    />
  );
}
