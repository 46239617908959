import React, { useRef, useState } from "react";

import { CircularProgress } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";

import useDeleteInvitation from "@fartherfinance/frontend/api/Sharing/hooks/useDeleteInvitation";
import { SentInvitation } from "@fartherfinance/frontend/api/Sharing/Types";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import { getAccessLevelForDisplay } from "../../../utils/getAccessLevelForDisplay";
import DeleteModal from "../DeleteModal/DeleteModal";
import { SharingPath } from "@src/config/routing/RouterPaths";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import Chip from "@src/sharedComponents/Chip/Chip";
import Popover from "@src/sharedComponents/Popover/Popover";
import SimplePopoverItem from "@src/sharedComponents/Popover/SimplePopoverItem";
import SimplePopoverMenu from "@src/sharedComponents/Popover/SimplePopoverMenu";
import ThreeDotsButton from "@src/sharedComponents/ThreeDotsButton/ThreeDotsButton";

import styles from "./CollaboratorTableRow.module.css";

interface Props {
  invitation: SentInvitation;
}

const CollaboratorTableRow: React.FC<Props> = ({ invitation }) => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const anchorRef = useRef<HTMLDivElement | null>(null);

  const history = useHistory();

  const statusNotification = useStatusNotification();

  const navToEdit = () => {
    history.push({
      ...history.location,
      pathname: `/Client/${clientId}/${SharingPath}/EditCollaborator/${invitation.collaborator.collaboratorId}`,
    });
  };

  const handleOnClickDelete = () => {
    setShowPopover(false);
    setShowDeleteModal(true);
  };

  const auth = useRequestAuth();

  const deleteInvitation = useDeleteInvitation(auth);

  const deleteCollaborator = async () => {
    if (invitation === undefined) {
      throw new Error("invitation for collaborator is undefined");
    }

    setShowDeleteModal(false);

    try {
      setIsLoading(true);

      await deleteInvitation(invitation.invitationId);

      statusNotification("Collaborator removed", "Success");
      setIsLoading(false);
    } catch (_e) {
      statusNotification("Error removing collaborator", "Error");
      setIsLoading(false);
    }
  };

  const accessLevel = getAccessLevelForDisplay(invitation.accessLevel);

  return (
    <>
      <div className={styles.row}>
        <div
          className={styles.col1}
        >{`${invitation.collaborator.firstName} ${invitation.collaborator.lastName}`}</div>

        <div className={styles.col2}>
          <Chip
            variant="outlined"
            label={accessLevel === "Revoke" ? "Revoked" : accessLevel}
          />
        </div>

        <div className={styles.col3}>
          <Chip variant="outlined" label={invitation.status} />
        </div>

        <div className={styles.col4}>
          {!isLoading && (
            <Popover
              open={showPopover}
              onClose={() => setShowPopover(false)}
              anchor={anchorRef.current}
              popoverElement={
                <ThreeDotsButton
                  onClick={() => setShowPopover(true)}
                  anchorRef={anchorRef}
                />
              }
            >
              <SimplePopoverMenu>
                {invitation.status !== "Rejected" && (
                  <SimplePopoverItem onClick={navToEdit}>
                    Edit
                  </SimplePopoverItem>
                )}
                <SimplePopoverItem onClick={handleOnClickDelete}>
                  Delete
                </SimplePopoverItem>
              </SimplePopoverMenu>
            </Popover>
          )}
          {isLoading && (
            <div className={styles.loader}>
              <CircularProgress color={"inherit"} size={20} />
            </div>
          )}
        </div>
      </div>
      {showDeleteModal && (
        <DeleteModal
          onDelete={deleteCollaborator}
          hideModal={() => setShowDeleteModal(false)}
        />
      )}
    </>
  );
};

export default CollaboratorTableRow;
