import React, { useState } from "react";

import { Plaid } from "plaid-link";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import useClientDashboard from "@fartherfinance/frontend/api/Dashboard/hooks/useClientDashboard";
import useInvalidateClientDashboard from "@fartherfinance/frontend/api/Dashboard/hooks/useInvalidateClientAccounts";
import usePlaidCreateLink from "@fartherfinance/frontend/api/ExternalAccount/hooks/usePlaidCreateLink";
import getPlaidLinkToken from "@fartherfinance/frontend/api/ExternalAccount/requests/getPlaidLinkToken";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import { AccountsPath } from "@src/config/routing/RouterPaths";
import ExitButton from "@src/multiCustodian/components/Client/ExitButton";
import pollClientDashboard from "@src/multiCustodian/components/Client/PlaidRelink/pollClientDashboard";
import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { captureException } from "@src/multiCustodian/services/tracking";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import { State as ReduxState } from "@src/store";

import styles from "./PlaidLink.module.css";

const PlaidLink = (): JSX.Element => {
  const [plaidMetaData, setPlaidMetaData] =
    useState<Plaid.OnSuccessMetaData | null>(null);
  const [plaidToken, setPlaidToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isAdvisor } = useSelector((state: ReduxState) => ({
    isAdvisor: state.main_Reducer.user.isAdvisor ?? false,
  }));

  const history = useHistory();

  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const statusNotification = useStatusNotification();

  const auth = useRequestAuth();
  const dashboard = useClientDashboard(clientId, auth);
  const invalidateDashboard = useInvalidateClientDashboard();

  const linkPlaidAccount = usePlaidCreateLink(auth);

  if (isAdvisor) {
    statusNotification(
      "Advisors are not allowed to link Plaid accounts",
      "Error"
    );
    history.replace(`/Client/${clientId}/${AccountsPath}`);
  }

  const fetchLinkToken = async () => {
    if (isLoading || isAdvisor) {
      return false;
    }

    setPlaidMetaData(null);
    setPlaidToken(null);

    if (!clientId || !auth) {
      const err = new Error("clientId or auth is null");

      captureException(err, {
        extra: {
          clientId: auth?.clientId ?? "null",
          auth: auth ?? "null",
        },
      });

      throw err;
    }

    setIsLoading(true);

    const onSuccess: Plaid.OnSuccess = (public_token, metadata) => {
      onSubmit(public_token, metadata);
    };

    const onExit = () => {
      setIsLoading(false);
    };

    const linkEnv = window.location.href.includes("localhost")
      ? "localhost"
      : window.location.href.includes("uat-stagingapp")
      ? "uat-stagingapp"
      : null;

    try {
      const res = await getPlaidLinkToken(linkEnv, auth);

      const handler = window.Plaid.create({
        token: res.token,
        clientName: "Farther",
        countryCodes: ["US"],
        env: process.env.WEBAPP_ENV == "PROD" ? "production" : "sandbox",
        product: ["transactions"],
        language: "en",
        webhook: process.env.PLAID_FARTHER_WEBHOOK_URL,
        onSuccess: onSuccess,
        onExit,
      });

      handler.open();
    } catch (e) {
      console.error(e);
      captureException(e, {
        extra: {
          client: clientId,
          linkEnv: linkEnv,
          call: "getPlaidLinkToken or window.Plaid.create()",
          file: "PlaidLink.tsx",
        },
      });
      statusNotification("Error opening Plaid client", "Error");
      setIsLoading(false);
    }
  };

  const onSubmit = async (token: string, data: Plaid.OnSuccessMetaData) => {
    const plaid_token = token ?? plaidToken;
    const plaidData = data ?? plaidMetaData;

    if (!plaid_token || !plaidData || !plaidData.institution) {
      return;
    }

    if (!clientId || !auth) {
      const err = new Error("clientId or jwt is null");

      captureException(err, {
        extra: {
          clientId: auth?.clientId,
          jwt: auth?.jwt,
        },
      });

      throw err;
    }

    try {
      setIsLoading(true);

      await linkPlaidAccount(plaid_token);

      statusNotification("Institution linked", "Success");

      pollClientDashboard(
        clientId,
        auth,
        statusNotification,
        dashboard.data,
        invalidateDashboard,
        "Retrieving newly linked accounts...",
        "Linked accounts retrieved",
        "Error retrieving newly linked accounts"
      );

      history.push(`/Client/${clientId}/${AccountsPath}`);
    } catch (_e) {
      statusNotification("Error linking institution", "Error");
      setIsLoading(false);
    }
  };

  return (
    <BaseLayout>
      <ExitButton />

      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div className={styles.centeringContainer}>
            {!isLoading ? (
              <>
                <p className={styles.text}>
                  To link accounts, you'll be asked to select your institution
                  using Plaid.
                </p>

                <p className={styles.text2}>
                  * We use Plaid to authenticate your accounts. In the case your
                  institution isn't supported by Plaid, contact Farther for
                  further assistance.
                </p>

                <div className={styles.space} />

                <div className={styles.buttonDiv}>
                  <div
                    className={styles.backButtonContainer}
                    onClick={() =>
                      history.push(`/Client/${clientId}/${AccountsPath}/Add`)
                    }
                  >
                    <img src="/src/assets/svg/left_arrow.svg" />
                    <div className={styles.backButton}>Back</div>
                  </div>

                  <Button
                    variant={"contained"}
                    buttonType={"primary"}
                    text={"Link Accounts"}
                    onClick={() => fetchLinkToken()}
                  />
                </div>
              </>
            ) : (
              <div className={styles.loadingContainer}>
                <LogoLoadingStill />
              </div>
            )}
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default PlaidLink;
