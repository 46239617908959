import { format, parse } from "date-fns";

import { dateFormat } from "@src/constants/dateFormat";

type YYYYMMDD = string;

const formatDateForGraph = (date: Date | YYYYMMDD): string => {
  return typeof date === "string"
    ? format(parse(date, dateFormat, new Date()), "PP")
    : format(date, "PP");
};

export default formatDateForGraph;
