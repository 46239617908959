import React from "react";

import Box from "@mui/material/Box";

import { useStyles } from "./TableHeader.styles";

export interface TableHeaderProps {
  children: React.ReactNode;
  className?: string;
}

export const TableHeader: React.FC<TableHeaderProps> = ({
  children,
  className,
}) => {
  const { classes, cx } = useStyles();

  return <Box className={cx(classes.header, className)}>{children}</Box>;
};
