import React from "react";

import Typography from "@mui/material/Typography";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./ExpandableDescription.module.css";

interface ExpandableDescriptionProps {
  title: string;
  description: string;
}

// TODO: add view more

const ExpandableDescription: React.FC<ExpandableDescriptionProps> = ({
  title,
  description,
}) => {
  return (
    <div>
      <Typography className={toClassName(styles.text, styles.title)}>
        {title}
      </Typography>
      <Typography className={toClassName(styles.text, styles.description)}>
        {description}
      </Typography>
    </div>
  );
};

export default ExpandableDescription;
