import React, { useState } from "react";

import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import { truncate } from "lodash";

import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { captureException } from "@src/multiCustodian/services/tracking";

import { checkFiletype } from "./checkFileType";

import styles from "./SingleFileDropUpload.module.css";

const makeFilenameUrlSafeReplaceRegex = /[^a-zA-Z0-9_.-]/g;

const copyFileAndMakeFilenameUrlSafe = (oldFile: File): File => {
  const newFilename = makeFilenameUrlSafe(oldFile.name);
  const newFile = new File([oldFile.slice()], newFilename, {
    type: oldFile.type,
  });
  return newFile;
};

const makeFilenameUrlSafe = (filename: string): string => {
  return filename.replace(makeFilenameUrlSafeReplaceRegex, "_");
};

interface Props {
  file: File | null;
  onChange: (file: File | null) => void;
  acceptedFileExtensions?: string[];
  icon?: React.ReactNode;
  text?: string;
  pressText?: React.ReactNode;
}

const SingleFileDropUpload = (props: Props) => {
  const [fileHover, setFileHover] = useState<boolean>(false);

  const statusNotification = useStatusNotification();

  const {
    color: { $backgroundSubtle },
  } = useTheme();

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setFileHover(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setFileHover(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const browseFiles = () => {
    const inputField: HTMLElement | null =
      document.querySelector("input[type=file]");
    if (inputField) {
      inputField.click();
    }
  };

  const checkFileReady = () => {
    /** @type {HTMLInputElement | null} */
    const element: HTMLInputElement | null =
      document.querySelector("input[type=file]");

    /** @type {FileList | null} */
    const filesList = element === null ? null : element.files;

    /** @type {File | undefined} */
    const inputFile = filesList === null ? undefined : filesList[0];

    if (inputFile === undefined) {
      const errorMessage = "fileReady has an undefined file.";

      console.error(errorMessage, element, filesList, inputFile);

      captureException(new Error(errorMessage), {
        extra: {
          elementIsNull: element === null ? "True" : "False",
          "filesList.length":
            filesList === null
              ? "null"
              : filesList === undefined
              ? "undefined"
              : filesList.length.toString(),
          fileIsType: inputFile === null ? "null" : typeof inputFile,
        },
      });
      return;
    }

    const fileWithSafeName = copyFileAndMakeFilenameUrlSafe(inputFile);

    props.onChange(fileWithSafeName);
  };

  const clearFile = () => {
    const file_input: HTMLInputElement | null =
      document.querySelector("input[type=file]");
    if (file_input) {
      file_input.value = "";
    }
    setFileHover(false);
    props.onChange(null);
  };

  const fileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setFileHover(false);

    let file: File | null = null;

    if (e.dataTransfer.items) {
      // must use for loop as e.dataTransfer.items is not iterable
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        if (e.dataTransfer.items[i].kind === "file") {
          file = e.dataTransfer.items[i].getAsFile();
          break;
        }
      }
    } else {
      const aFile = e.dataTransfer.files[0] as File | undefined;
      if (aFile !== undefined) {
        file = aFile;
      }
    }

    if (file === null) {
      return;
    }

    if (props.acceptedFileExtensions !== undefined) {
      const isValid = checkFiletype(file, props.acceptedFileExtensions);
      if (!isValid) {
        statusNotification(
          `File not acceptable. Accepted file types are: ${props.acceptedFileExtensions.join(
            ", "
          )}`,
          "Error"
        );
        return;
      }
    }

    const fileWithSafeName = copyFileAndMakeFilenameUrlSafe(file);

    props.onChange(fileWithSafeName);
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.dropUploadContainer}
        style={
          fileHover
            ? {
                backgroundColor: $backgroundSubtle,
              }
            : {}
        }
        onDrop={(e: React.DragEvent<HTMLDivElement>) => fileDrop(e)}
        onDragOver={(e: React.DragEvent<HTMLDivElement>) => handleDragOver(e)}
        onDragEnter={(e: React.DragEvent<HTMLDivElement>) => handleDragEnter(e)}
        onDragLeave={(e: React.DragEvent<HTMLDivElement>) => handleDragLeave(e)}
      >
        {props.file ? (
          <div className={styles.uploadPill}>
            <p className={styles.uploadPillText}>
              {truncate(props.file.name, { length: 32 })}
            </p>
            <ClearRoundedIcon className={styles.xIcon} onClick={clearFile} />
          </div>
        ) : (
          <div className={styles.noFileYetDiv}>
            {props.icon ?? <FolderRoundedIcon className={styles.fileIcon} />}

            <p className={styles.uploadText}>
              {props.text ?? "Drop your file here or"}
            </p>

            <div className={styles.browseText} onClick={browseFiles}>
              {props.pressText ?? "browse"}
            </div>
          </div>
        )}
      </div>

      <input
        type="file"
        accept={
          props.acceptedFileExtensions
            ? props.acceptedFileExtensions.join(", ")
            : undefined
        }
        onChange={checkFileReady}
        className={styles.htmlFileInput}
      />
    </div>
  );
};

export default SingleFileDropUpload;
