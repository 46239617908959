import React, { PropsWithChildren } from "react";

import { useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import PageHero from "@src/sharedComponents/PageHero/PageHero";
import TitleBlock from "@src/sharedComponents/PageHero/TitleBlock";

const CreateUpdateFinancialPlan = (
  props: PropsWithChildren<unknown>
): JSX.Element => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  return (
    <div>
      <div>Top Bar</div>

      <PageHero
        start={<TitleBlock title="<Category>" subTitle="<Summary text...>" />}
        end={
          <Button
            text="Some Button"
            variant="contained"
            buttonType="primary"
            onClick={() => console.log(`${clientId} clicked!`)}
          />
        }
      />

      {props.children}

      <div>Bottom Bar</div>
    </div>
  );
};

export default CreateUpdateFinancialPlan;
