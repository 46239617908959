import { AccountType } from "@fartherfinance/frontend/api/Transfer/requests/Types";
import {
  ExternalAccountId,
  FartherAccountId,
  TransferId,
} from "@fartherfinance/frontend/api/Types";

export type TransferType =
  | "transfer"
  | "bank"
  | "roll over"
  | "era"
  | "Roth 401(k)"
  | "Solo 401(k)"
  | "Simple 401(k)"
  | "403(b)"
  | "Traditional 401(k)";

export const iraTypes = [
  "Traditional IRA",
  "Roth IRA",
  "SEP IRA",
  "Simple IRA",
  "Beneficiary Traditional IRA",
  "Beneficiary Roth IRA",
] as const;
export type IraType = typeof iraTypes[number];

export type AcatsInstitutionInfo =
  | { type: "code"; acatsInstitutionCode: string }
  | { type: "name"; acatsInstitutionName: string };

export type TransferScreen =
  | { screen: "transferType" }
  | { screen: "bankAccount" }
  /** Institution Selection **/
  | {
      screen: "IRA/institutionSelection";
      transferType: "roll over";
      iraType: IraType;
    }
  | {
      screen: "Investment/institutionSelection";
      transferType: "transfer";
    }
  /** Missing Institution **/
  | {
      screen: "IRA/missingInstitution";
      transferType: "roll over";
      iraType: IraType;
    }
  | {
      screen: "Investment/missingInstitution";
      transferType: "transfer";
    }
  /** Account Information **/
  | {
      screen: "IRA/accountInformation";
      transferType: "roll over";
      acatsInstitution: AcatsInstitutionInfo;
      acatsAccountType: AccountType;
      iraType: IraType;
    }
  | {
      screen: "Investment/accountInformation";
      transferType: "transfer";
      acatsInstitution: AcatsInstitutionInfo;
      acatsAccountType: AccountType;
    }
  /** Transfer Destination **/
  | {
      screen: "IRA/transferDestination";
      transferType: "roll over";
      acatsInstitution: AcatsInstitutionInfo;
      acatsAccountType: AccountType;
      iraType: IraType;
      accountHolderName: string;
      accountNumber: string;
    }
  | {
      screen: "Investment/transferDestination";
      transferType: "transfer";
      acatsInstitution: AcatsInstitutionInfo;
      acatsAccountType: AccountType;
      accountHolderName: string;
      accountNumber: string;
    }
  /** Optional Info **/
  | {
      screen: "IRA/optionalInfo";
      transferType: "roll over";
      acatsInstitution: AcatsInstitutionInfo;
      acatsAccountType: AccountType;
      iraType: IraType;
      accountHolderName: string;
      accountNumber: string;
      toAccountId: FartherAccountId;
    }
  | {
      screen: "Investment/optionalInfo";
      transferType: "transfer";
      acatsInstitution: AcatsInstitutionInfo;
      acatsAccountType: AccountType;
      accountHolderName: string;
      accountNumber: string;
      toAccountId: FartherAccountId;
    }
  /** Transfer Verification **/
  | {
      screen: "IRA/transferVerification";
      transferType: "roll over";
      iraType: IraType;
      acatsInstitution: AcatsInstitutionInfo;
      acatsAccountType: AccountType;
      accountHolderName: string;
      accountNumber: string;
      toAccountId: FartherAccountId;
    }
  | {
      screen: "Investment/transferVerification";
      transferType: "transfer";
      acatsInstitution: AcatsInstitutionInfo;
      acatsAccountType: AccountType;
      accountHolderName: string;
      accountNumber: string;
      toAccountId: FartherAccountId;
    }
  /** Transfer Initiated **/
  | {
      screen: "IRA/transferInitiated";
      transferType: "roll over";
      acatsInstitution: AcatsInstitutionInfo;
      acatsAccountType: AccountType;
      iraType: IraType;
      accountHolderName: string;
      accountNumber: string;
      toAccountId: FartherAccountId;
      uploadFilename: string | null;
      statement: File | null;
    }
  | {
      screen: "Investment/transferInitiated";
      transferType: "transfer";
      acatsInstitution: AcatsInstitutionInfo;
      acatsAccountType: AccountType;
      accountHolderName: string;
      accountNumber: string;
      toAccountId: FartherAccountId;
      uploadFilename: string | null;
      statement: File | null;
    }
  | { screen: "IRA/TypeSelection" }
  | { screen: "ERA/Selection" } // Employer Retirement Account
  | { screen: "deposit" }
  | { screen: "withdrawal" }
  | {
      screen: "withdrawalSuccess";
      withdrawFromAccountId: FartherAccountId;
      depositToExternalId: ExternalAccountId;
      transferAmount: number;
      transferId: TransferId;
    }
  | {
      screen: "Employer Retirement Account Instructions";
      transferType: TransferType;
    };

export type SetScreenAndTransferType = (screen: TransferScreen) => void;
