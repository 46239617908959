import React, { useState } from "react";

import { Stack, Typography } from "@mui/material";

import styles from "./CSVTips.module.css";

interface Props {
  uploadType: "Sleeve" | "Partner" | "Custom";
}

const CSVTips = ({ uploadType }: Props): JSX.Element => {
  const [showAll, setShowAll] = useState(false);

  return (
    <Stack className={styles.stack}>
      <Typography fontWeight={600} fontSize="13px">
        Tips on preparing the CSV
      </Typography>

      <ul className={styles.list}>
        {!showAll && (
          <>
            <li>
              If you're having trouble with uploading a CSV, we encourage you to
              create the model via the UI instead
            </li>

            <li>
              What to put in the columns
              <ul className={styles.list}>
                <li>
                  {uploadType === "Custom"
                    ? `Partner = "Custom"`
                    : uploadType === "Partner"
                    ? `Partner = Name of the organization, e.g. "Farther" or "BlackRock"`
                    : "Sleeve Name = Name you've chosen for the sleeve"}
                </li>
              </ul>
            </li>
          </>
        )}

        {!showAll ? (
          <></>
        ) : uploadType === "Custom" ? (
          <CustomCSVTips />
        ) : uploadType === "Partner" ? (
          <FAMPartnerCSVTips />
        ) : (
          <SleeveCSVTips />
        )}
      </ul>

      {showAll ? (
        <div className={styles.seeLess}>
          <div
            className={styles.expanderText}
            onClick={() => setShowAll(false)}
          >
            See Less
          </div>
        </div>
      ) : (
        <div className={styles.seeMore}>
          <div className={styles.expanderText} onClick={() => setShowAll(true)}>
            See More
          </div>
        </div>
      )}
    </Stack>
  );
};

export default CSVTips;

const HowToFormatTheData = (): JSX.Element => {
  return (
    <li>
      How to format the data
      <ul className={styles.list}>
        <li>
          Tickers
          <ul className={styles.list}>
            <li>Cash is entered as "US Dollars" in the Ticker column</li>

            <li>
              Use tickers, not company names (e.g. AAPL instead of Apple Inc.)
            </li>
          </ul>
        </li>

        <li>
          Weights
          <ul className={styles.list}>
            <li>Two decimal places are required</li>

            <li>Use numbers between 0.00%-100.00%</li>

            <li>Total weights should sum to 100.00%</li>
          </ul>
        </li>
      </ul>
    </li>
  );
};

const CustomCSVTips = (): JSX.Element => {
  return (
    <>
      <li>
        What to put in the columns
        <ul className={styles.list}>
          <li>Partner = "Custom"</li>

          <li>Model Type = "Custom"</li>

          <li>Model Name = Name you've chosen for the model</li>

          <li>Asset Allocation = "Custom"</li>

          <li>
            Direct-Indexing = "No"
            <ul className={styles.list}>
              <li>Direct-Indexing is not available for custom portfolios</li>
            </ul>
          </li>

          <li>Tax Type = "Any", "Tax-exempt", or "Taxable"</li>

          <li>
            Sleeve Name = Name you've chosen for the sleeve
            <ul className={styles.list}>
              <li>
                Leave blank if you are only allocating to individual securities.
                Otherwise, include the name of the portfolio sleeve.
              </li>

              <li>
                You can only include sleeves that have already been created.
              </li>
            </ul>
          </li>
        </ul>
      </li>

      <HowToFormatTheData />
    </>
  );
};

const FAMPartnerCSVTips = (): JSX.Element => {
  return (
    <>
      <li>
        What to put in the columns
        <ul className={styles.list}>
          <li>
            Partner = Name of the organization, e.g. "Farther" or "BlackRock"
          </li>

          <li>
            Model Type = Category of the model, e.g. "Core" or "Alternative"
          </li>

          <li>Model Name = Name of the model</li>

          <li>
            Asset Allocation = "100/0", "80/20", "60/40", etc.
            <ul className={styles.list}>
              <li>First number is the percentage allocation to equities</li>

              <li>
                Second number is the percentage allocation to fixed income
              </li>
            </ul>
          </li>

          <li>Direct-Indexing = "Yes" or "No"</li>

          <li>Tax Type = "Any", "Tax-exempt", or "Taxable"</li>
        </ul>
      </li>

      <HowToFormatTheData />
    </>
  );
};

const SleeveCSVTips = (): JSX.Element => {
  return (
    <>
      <li>
        What to put in the columns
        <ul className={styles.list}>
          <li>
            Sleeve Name = Name you've chosen for the sleeve
            <ul className={styles.list}>
              <li>
                Leave blank if you are only allocating to individual securities.
                Otherwise, include the name of the portfolio sleeve.
              </li>

              <li>
                You can only include sleeves that have already been created
              </li>
            </ul>
          </li>
        </ul>
      </li>

      <HowToFormatTheData />
    </>
  );
};
