import opacity from "hex-color-opacity";

const opacityLimit = 0.2; // 20% - result will never be more opaque than 20% of the passed in hexColor

const generateColorShadeFromBaseColor = (
  hexColor: string,
  idx: number,
  range: number,
  applyOffset: boolean = false
): string => {
  if (applyOffset) {
    // offsetting will cause the first opacity percentage to not be 1.0 thus starting with new color shade (not the exact same primary color assigned to the category - a shade of that) for first bar on left in bar chart
    // applying offset is only skipped if the category has 1 class so that color of the dot in the drill-in chip matches the only bar's color
    idx = idx + 1;
    range = range + 1;
  }

  if (range === 1) {
    return hexColor;
  }
  // reduces the incoming idx (and thus the range of numbers the ids' can be) so that the resulting opacity percentage <= opacityLimit
  const maxIdx = range - 1; // idx is 0 based so the max idx will ever be is range - 1
  const rangeReduction = range - range * opacityLimit;
  const squishFactor = rangeReduction / maxIdx;
  const reducedIdx = idx * squishFactor;

  return opacity(hexColor, (range - reducedIdx) / range);
};

export default generateColorShadeFromBaseColor;
