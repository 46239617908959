import React from "react";

import { Stack, Typography } from "@mui/material";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import Modal from "@src/sharedComponents/Modal/Modal";

import styles from "./AccreditedInvestorModal.module.css";

const accreditedInvestorRequirements = [
  "Earned income that exceeds $200,000 (or $300,000 together with a spouse or spousal equivalent) in each of the prior two years and reasonably expects the same for the current year -OR-",
  "Has a net worth of over $1 million, either alone or together with a spouse or spousal equivalent (Excluding the value of the person's primary residence) -OR-",
  "Holds in good standing a Series 7, 65, or 82 license -OR-",
  "Any trust, with total assets in excess of $5 million, not formed specifically to purchase the subject securities, whose purchase is directed by a sophisticated person -OR-",
  "Certify entity with total investment in excess of $5 million, not formed to specifically purchase the subject securities -OR-",
  "Any entity in which all the equity owners are accredited investors.",
];

interface AccreditedInvestorModalProps {
  onConfirm: () => void;
  onDecline: () => void;
  onClose: () => void;
}

const AccreditedInvestorModal: React.FC<AccreditedInvestorModalProps> = ({
  onConfirm,
  onDecline,
  onClose,
}) => {
  return (
    <Modal closeModal={onClose} modalStyle={{ width: "684px" }}>
      <Typography className={styles.title}>
        Are You An Accredited Investor?
      </Typography>
      <Stack marginTop="48px">
        <Typography className={styles.text}>
          Under the federal securities laws, only persons who are accredited
          investors may participate in alternative investment offerings. To
          verify that you are an accredited investor, please indicate you are an
          individual who:
        </Typography>

        <ul>
          {accreditedInvestorRequirements.map((item, index) => (
            <Typography
              key={index}
              component="li"
              className={toClassName(styles.text, styles.spacer)}
            >
              {item}
            </Typography>
          ))}
        </ul>
      </Stack>

      <Stack
        direction="row"
        gap="16px"
        marginTop="48px"
        justifyContent="flex-end"
      >
        <Button
          variant="outlined"
          buttonType="primary"
          text="No"
          onClick={onDecline}
        />
        <Button
          variant="contained"
          buttonType="primary"
          text="Yes"
          onClick={onConfirm}
        />
      </Stack>
    </Modal>
  );
};

export default AccreditedInvestorModal;
