import type { User, UserAccount, UsersResponse } from "../types";

export type UserDto = User & {
  accounts: UserAccount[];
};

export const toUserDto = ({
  userDetails,
  accounts,
}: UsersResponse): UserDto => ({
  ...userDetails,
  accounts,
});
