import React, { PropsWithChildren } from "react";

import { MarketplaceAssetType } from "@fartherfinance/frontend/api/AltAssetsService/Types";

import OfferingsListAdvisorV2 from "../../Marketplace/OfferingsListAdvisor/OfferingsListAdvisorV2";
import { AltAssetsMarketplacePath } from "@src/config/routing/RouterPaths";
import { altAssetOfferingTypes } from "@src/multiCustodian/components/Marketplace/constants";

const AltAssetsOfferingsListAdvisor: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <OfferingsListAdvisorV2
      assetType={MarketplaceAssetType.enum.alternative_asset}
      offeringTypes={altAssetOfferingTypes}
      marketplacePath={AltAssetsMarketplacePath}
      pageTitleFallback="Alternative Assets"
    >
      {children}
    </OfferingsListAdvisorV2>
  );
};

export default AltAssetsOfferingsListAdvisor;
