import React, { useState } from "react";

import MuiTableRow from "@mui/material/TableRow";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./TableRow.module.css";

interface TableRowProps {
  children: (props: { isHovering: boolean }) => React.ReactNode;
  className?: string;
  onClick?: () => void;
}

export default function TableRow({
  children,
  className,
  onClick,
}: TableRowProps): JSX.Element {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <MuiTableRow
      onClick={onClick}
      className={toClassName(styles.row, className, {
        [styles.rowHasOnClick]: onClick !== undefined,
      })}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {children({ isHovering })}
    </MuiTableRow>
  );
}
