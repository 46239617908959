import React, { useMemo, useState } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { orderBy, range } from "lodash";
import { useParams } from "react-router-dom";

import { FartherManagedAccount } from "@fartherfinance/frontend/api/Accounts/Types";
import { PortfolioPartner } from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";
import useCreateTradingGroup from "@fartherfinance/frontend/api/TradingGroups/hooks/useCreateTradingGroup";
import useUpdateTradingGroupV1_5 from "@fartherfinance/frontend/api/TradingGroups/hooks/useUpdateTradingGroupV1_5";
import { TradingGroup } from "@fartherfinance/frontend/api/TradingGroups/requests/getTradingGroup";
import {
  ClientId,
  FartherAccountId,
  PortfolioId,
  TradingGroupId,
} from "@fartherfinance/frontend/api/Types";

import {
  AccountWithPortfolio,
  useAvailableAccountFromTradingGroups,
} from "../utils/useAvailableAccountFromTradingGroups";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { captureException } from "@src/multiCustodian/services/tracking";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import ApplyAccountRow from "./ApplyAccountRow";
import RemoveAccountFromTradingGroupModal from "./RemoveAccountFromTradingGroupModal";

import styles from "./AccountsListSelection.module.css";

const OPTION_DIV_HEIGHT_IN_PX = 40;

export interface Props {
  portfolioId: PortfolioId;
  accounts: FartherManagedAccount[];
  isDerived: boolean;
  portfolioModelType: PortfolioPartner | "Custom";
  tradingGroups: TradingGroup[];
  maxItemsBeforeScroll?: number; // default is 5 -> 200px
}

const AccountsListSelection = (props: Props): JSX.Element => {
  const [isMutating, setIsMutating] = useState<boolean>(false);
  const [showRemoveAccountModal, setShowRemoveAccountModal] =
    useState<boolean>(false);
  const [tradingGroupIdToRemove, setTradingGroupIdToRemove] =
    useState<TradingGroupId | null>(null);
  const [accountIdToRemove, setAccountIdToRemove] =
    useState<FartherAccountId | null>(null);

  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const statusNotification = useStatusNotification();

  const auth = useRequestAuth();
  const createTradingGroup = useCreateTradingGroup(clientId, auth);
  const { updateTradingGroup, state: updateTradingGroupReqState } =
    useUpdateTradingGroupV1_5(clientId, auth);

  const rawRows = useAvailableAccountFromTradingGroups(
    clientId,
    props.portfolioId
  );

  const rows = useMemo(
    () =>
      (rawRows.data ?? []).map(
        (a): AccountWithPortfolio => ({
          ...a,
          isDisabled: isMutating ? true : a.isDisabled,
          isChecked: a.portfolioId === props.portfolioId,
        })
      ),
    [isMutating, props.portfolioId, rawRows.data]
  );

  const callCreateTradingGroup = async (accountId: FartherAccountId) => {
    const tgBody = {
      displayName: `${props.portfolioModelType} Trading Group`,
      accounts: [accountId],
      portfolioId: props.portfolioId,
      isActive: true,
      directIndexing: false,
      taxLossHarvesting: false,
      equivalentSecuritiesEnabled: false,
    };

    try {
      setIsMutating(true);
      await createTradingGroup(tgBody);
      statusNotification("Account added", "Success");
    } catch (e) {
      console.error(e);
      statusNotification("Error adding account", "Error");
    } finally {
      setIsMutating(false);
    }
  };

  const removeAccountFromTradingGroup = async () => {
    if (!tradingGroupIdToRemove) {
      return;
    }

    if (updateTradingGroupReqState !== "Ready") {
      statusNotification("Please wait a few seconds for data to load", "Error");
      return;
    }

    const body = {
      displayName: `${props.portfolioModelType} Trading Group`,
      accounts: [],
      portfolioId: props.portfolioId,
      isActive: false,
      directIndexing: false,
      taxLossHarvesting: false,
      equivalentSecuritiesEnabled: false,
    };

    try {
      setIsMutating(true);
      await updateTradingGroup({
        tradingGroupId: tradingGroupIdToRemove,
        body: body,
      });
      statusNotification("Account removed", "Success");
    } catch (e) {
      console.error(e);
      statusNotification("Error removing account", "Error");
    } finally {
      setTradingGroupIdToRemove(null);
      setAccountIdToRemove(null);
      setIsMutating(false);
      setShowRemoveAccountModal(false);
    }
  };

  const applyAccount = (
    isChecked: boolean,
    accountId: FartherAccountId,
    tradingGroupId: TradingGroupId | null
  ) => {
    if (isMutating) {
      return;
    }

    if (isChecked) {
      // account will be removed
      if (!tradingGroupId) {
        const errorMsg = `tradingGroupId is undefined when removing an account from a trading group for a ${props.portfolioModelType} model`;
        console.error(errorMsg);
        captureException(errorMsg, {
          extra: {
            clientId: clientId,
            accountId: accountId,
            portfolioId: props.portfolioId,
            tradingGroupId: tradingGroupId,
          },
        });
        return;
      }

      setTradingGroupIdToRemove(tradingGroupId);
      setAccountIdToRemove(accountId);
      setShowRemoveAccountModal(true);
    } else {
      // account will be added / trading group will be created
      callCreateTradingGroup(accountId);
    }
  };

  const appliedAccountsText = `${
    rows.filter((a) => a.assignedTo === "ThisPortfolio").length
  } Applied`;

  const availableAccounts = rows.filter(
    (a) => a.assignedTo === "None" && a.sharedBy.length === 0
  ).length;

  if (isMutating || rawRows.isLoading) {
    return (
      <div className={styles.container}>
        <div className={styles.listHeaderDiv}>
          <div className={styles.flexDiv}>
            <div className={styles.flexDiv}>
              <p className={styles.headerText1}>Apply Accounts</p>

              <Tooltip
                tooltipText="Accounts applied to this model portfolio will be traded individually."
                placement="top"
              >
                <InfoOutlinedIcon className={styles.infoIcon} />
              </Tooltip>
            </div>

            <Skeleton width={120} />
          </div>

          <Skeleton width={120} />
        </div>

        <div className={styles.optionsDiv}>
          {range(3).map((i) => {
            return (
              <div key={i} className={styles.optionDiv}>
                <Skeleton width={120} />

                <Skeleton width={120} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  const availableAccountsText = `${availableAccounts} Available`;

  if (rawRows.isLoading) {
    return <div>Loading</div>;
  }

  if (rawRows.hasError) {
    return <div>Error</div>;
  }

  return props.isDerived ? (
    <div className={styles.headerText1}>
      Derived portfolios can not be applied to accounts
    </div>
  ) : (
    <div className={styles.container}>
      {showRemoveAccountModal && (
        <RemoveAccountFromTradingGroupModal
          closeModal={() => setShowRemoveAccountModal(false)}
          onRemove={() => removeAccountFromTradingGroup()}
          tradingGroupName={
            props.tradingGroups.find(
              (tg) => tg.groupId === tradingGroupIdToRemove
            )?.displayName ?? "N/A"
          }
          accountName={
            props.accounts.find(
              (acct) => acct.virtualAccountId === accountIdToRemove
            )?.accountDetails.displayName ?? "N/A"
          }
          isMutating={isMutating}
        />
      )}

      <div className={styles.listHeaderDiv}>
        <div className={styles.flexDiv}>
          <div className={styles.flexDiv}>
            <p className={styles.headerText1}>Apply Accounts</p>

            <Tooltip
              tooltipText="Accounts applied to this model portfolio will be traded individually."
              placement="top"
            >
              <InfoOutlinedIcon className={styles.infoIcon} />
            </Tooltip>
          </div>

          <p className={styles.headerText2}>{availableAccountsText}</p>
        </div>

        <p className={styles.headerText3}>{appliedAccountsText}</p>
      </div>

      <div
        className={styles.optionsDiv}
        style={{
          maxHeight: props.maxItemsBeforeScroll
            ? `${OPTION_DIV_HEIGHT_IN_PX * props.maxItemsBeforeScroll}px`
            : "200px",
        }}
      >
        {props.accounts.length > 0 ? (
          orderBy(rows, [(r) => r.sharedBy.length > 0], ["asc"]).map((acct) => (
            <ApplyAccountRow
              key={acct.accountId}
              account={acct}
              applyAccount={applyAccount}
              portfolioId={props.portfolioId}
            />
          ))
        ) : (
          <NoSelectableAccountsJSX />
        )}
      </div>
    </div>
  );
};

export default AccountsListSelection;

const NoSelectableAccountsJSX = (): JSX.Element => (
  <div className={styles.optionDiv}>
    <p className={styles.noAccountsText}>No available accounts</p>
  </div>
);
