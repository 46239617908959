import React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./DropdownPopoverButton.module.css";

interface Props {
  text: string;
  showPopover: () => void;
  minWidth?: number;
  className?: string;
  textClassName?: string;
  iconClassName?: string;
  disabled?: boolean;
}

const DropdownPopoverButton = (props: Props): JSX.Element => {
  return (
    <div
      className={toClassName(styles.container, props.className, {
        [styles.containerHover]: !props.disabled,
        [styles.containerDisabled]: props.disabled,
      })}
      style={props.minWidth ? { minWidth: `${props.minWidth}px` } : {}}
      onClick={() => props.showPopover()}
    >
      <div className={toClassName(styles.text, props.textClassName)}>
        {props.text}
      </div>

      <ExpandMoreIcon
        className={toClassName(styles.icon, props.iconClassName)}
      />
    </div>
  );
};

export default DropdownPopoverButton;
