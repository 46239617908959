import React from "react";

import styles from "./FlowHero.module.css";

interface Props {
  title: string;
  headingLeft: string;
  headingRight: string;
}

const FlowHero: React.FC<Props> = ({ title, headingLeft, headingRight }) => {
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.flexDiv}>
          <div className={styles.headingLeft}>{`${headingLeft} /`}</div>

          <div className={styles.headingRight}>{headingRight}</div>
        </div>

        <div className={styles.title}>{title}</div>
      </div>
    </div>
  );
};

export default FlowHero;
