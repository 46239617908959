import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";

interface Props {
  className?: string;
  onClick: () => void;
}

const CancelButton: React.FC<Props> = ({ className, onClick }) => {
  return (
    <IconButton classes={{ root: className }} onClick={onClick}>
      <CancelIcon
        sx={{ width: "18px", height: "18px", color: "var(--icon)" }}
      />
    </IconButton>
  );
};

export default CancelButton;
