interface FullName {
  first: string;
  last: string;
}

export function splitEmployeeName(fullName: string | null): FullName {
  if (fullName === null) {
    return { first: "", last: "" };
  }

  const [employeeFirst, ...employeeRemainingNames] = fullName.split(" ");

  const employeeLast = employeeRemainingNames.join(" ");

  return { first: employeeFirst, last: employeeLast };
}
