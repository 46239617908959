import React, { useState } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import useClientAccounts from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAccounts";
import useExternalAccountsExtended from "@fartherfinance/frontend/api/ExternalAccount/hooks/useExternalAccountsExtended";
import useFundingAccountExtended from "@fartherfinance/frontend/api/ExternalAccount/hooks/useFundingAccountExtended";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import { State } from "../../../../../store";
import { SetScreenAndTransferType, TransferScreen } from "../Types";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";

import "./css.css";

interface Props {
  setCurScreen: SetScreenAndTransferType;
}

type Heading =
  | "Bank Account"
  | "Investment Account"
  | "IRA"
  | "Employer Retirement Account - 401(k), 403(b)";

interface HeadingOption {
  navigateTo: TransferScreen;
  heading: Heading;
}

const headingOptions: HeadingOption[] = [
  { navigateTo: { screen: "bankAccount" }, heading: "Bank Account" },
  {
    navigateTo: {
      screen: "Investment/institutionSelection",
      transferType: "transfer",
    },
    heading: "Investment Account",
  },
  { navigateTo: { screen: "IRA/TypeSelection" }, heading: "IRA" },
  {
    navigateTo: { screen: "ERA/Selection" },
    heading: "Employer Retirement Account - 401(k), 403(b)",
  },
];

const TransferTypeSelection = (props: Props): JSX.Element => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const auth = useRequestAuth();

  // Prefetch
  useExternalAccountsExtended(undefined, auth);

  useFundingAccountExtended(auth);

  // Prefetch
  useClientAccounts(clientId, auth);

  const chevron_down = useSelector(
    (state: State) => state.main_Reducer.brand.current.images.chevron_down
  );

  const [hoveringOver, setHoveringOver] = useState<number>(-1);

  const onNext = (option: HeadingOption) => {
    props.setCurScreen(option.navigateTo);
  };

  const { enableOneTimeAch, enableOneTimeAcats } = useFlags();

  return (
    <div className="Transfers__container">
      <div className="Transfers__content_container">
        {headingOptions
          .filter((opt) => {
            switch (opt.heading) {
              // ACH
              case "Bank Account":
                return enableOneTimeAch;

              // ACATS
              case "Employer Retirement Account - 401(k), 403(b)":
              case "IRA":
              case "Investment Account":
                return enableOneTimeAcats;

              default:
                const _x: never = opt.heading;
                return true;
            }
          })
          .map((opt, idx) => {
            return (
              <div
                key={opt.heading}
                className="Transfers__navlink"
                onClick={() => onNext(opt)}
              >
                <div
                  className="Transfers__choices_div"
                  onMouseEnter={() => setHoveringOver(idx)}
                  onMouseLeave={() => setHoveringOver(-1)}
                  style={{
                    backgroundColor:
                      hoveringOver === idx
                        ? "var(--item_div_hover_color_1)"
                        : "var(--background_div_color_1)",
                  }}
                >
                  <p className="Transfers__text_bold">{opt.heading}</p>
                  <img src={chevron_down} className="Transfers__arrow_img" />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TransferTypeSelection;
