import { isNil } from "lodash";

const nineDigitRegex = /^[0-9]{9}$/;
const einRegex = /^[0-9]{2}-[0-9]{7}$/;

export default function isValidEIN(taxID: string | null | undefined): boolean {
  if (isNil(taxID) || taxID === "") {
    return false;
  }

  const validTaxID = einRegex.test(taxID) || nineDigitRegex.test(taxID);

  return validTaxID;
}
