import { useCallback } from "react";

import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { FartherToken } from "@fartherfinance/frontend/api/Identity/requests/exchangeStytchToken";
import { AdvisorId, ClientId } from "@fartherfinance/frontend/api/Types";

import { MCReduxAction } from "@src/store";

export default function useLogIntoRedux() {
  const dispatch = useDispatch<Dispatch<MCReduxAction>>();

  return useCallback(
    (
      personId: string,
      personType: "Advisor" | "Client",
      fartherToken: FartherToken
    ) => {
      if (personType === "Advisor") {
        dispatch({
          type: "SET_IS_LOGGED_IN_AS_ADVISOR_STATE",
          payload: {
            isLoggedIn: true,
            farther_jwt: fartherToken,
            isAdvisor: true,
            cur_user: null,
            cockroach_advisor_id: personId as AdvisorId,
          },
        });
      } else {
        dispatch({
          type: "LOG_IN",
          payload: {
            farther_jwt: fartherToken,
            id_user: personId as ClientId,
            isAdvisor: false,
            conflict: false,
            email: "<none>",
            password: null,
          },
        });
      }
    },
    [dispatch]
  );
}
