import {
  AddCollaboratorFlowData,
  ButtonText,
  FlowState,
  PageIndex,
} from "./types";

export const isNextButtonDisabled = (
  state: FlowState<AddCollaboratorFlowData>
): boolean => {
  switch (state.pageIndex) {
    case 0:
      return !state.data.firstName || !state.data.lastName || !state.data.email;
    case 1:
      return (
        (!state.data.collaboratorType && !state.data.serviceProfessionalType) ||
        !state.data.accessLevel ||
        (state.data.accessLevel === "Limited Access" &&
          state.data.resources.length === 0)
      );
    default:
      return false;
  }
};

export const determineButtonText = (pageIndex: PageIndex): ButtonText => {
  if (pageIndex === 2) {
    return "Save";
  }

  return "Next";
};
