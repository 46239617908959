import React, { useCallback, useMemo, useState } from "react";

import { Box } from "@mui/material";
import { capitalize } from "lodash";
import { FormProvider, useForm, useFormState } from "react-hook-form";
import { useHistory } from "react-router-dom";

import useClientCustodian from "@fartherfinance/frontend/api/Dashboard/hooks/useClientCustodian";
import useClientProfile from "@fartherfinance/frontend/api/Entity/hooks/useClientProfile";
import useCreateExternalAccount from "@fartherfinance/frontend/api/ExternalAccount/hooks/useCreateManualAccount";
import useSetFundingAccount from "@fartherfinance/frontend/api/ExternalAccount/hooks/useSetFundingAccount";
import {
  FundingAccountTypeOption,
  fundingAccountTypeOptions,
} from "@fartherfinance/frontend/api/ExternalAccount/requests/types";
import {
  ClientId,
  USAState,
  USAStates,
} from "@fartherfinance/frontend/api/Types";

import custodiansRequiringVerificationDocument from "@src/constants/custodiansRequiringVerificationDocument";
import Drawer from "@src/multiCustodian/components/Drawer/Drawer";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import FileUploadButton from "@src/sharedComponents/FileUploadButton/FileUploadButton";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import FormNumberField from "@src/sharedComponents/Forms/FormNumberField";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import FormFieldLabel from "@src/sharedComponents/Forms/Private/FormFieldLabel";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import { AutocompleteOption } from "@src/sharedComponents/SAODropdown/Dropdown";

import Footer from "./Footer";

import styles from "./AddManualFundingAccountDrawer.module.css";

const pageTitle = "Add Funding Account Manually";

interface AccountTypeAutoComplete extends AutocompleteOption {
  label: string;
  value: FundingAccountTypeOption;
}

interface StateAutoComplete extends AutocompleteOption {
  label: USAState;
}

interface Form {
  BankName: string | undefined;
  AccountHolderName: string | undefined;
  AccountTitle: string | undefined;
  AccountType: AccountTypeAutoComplete | undefined;
  City: string | undefined;
  State: StateAutoComplete | undefined;
  AccountNumber: string | undefined;
  RoutingNumber: string | undefined;
}

type StripUndefined<F extends object> = { [k in keyof F]: NonNullable<F[k]> };

interface Props {
  clientId: ClientId;
}

const AddManualFundingAccountDrawer = (props: Props): JSX.Element => {
  const [isMutating, setIsMutating] = useState<boolean>(false);

  const [verificationFile, setVerificationFile] = useState<File | null>(null);

  const history = useHistory();

  const statusNotification = useStatusNotification();

  const auth = useRequestAuth();

  const profile = useClientProfile(props.clientId, auth);

  const clientCustodian = useClientCustodian(props.clientId, auth);

  const setFundingAccount = useSetFundingAccount(auth);

  const callCreateExternalAccount = useCreateExternalAccount(auth);

  const methods = useForm<Form>({
    mode: "all",
    reValidateMode: "onChange",
  });

  const { control, getValues } = methods;

  const { isValid } = useFormState({
    control,
  });

  const onClose = useMemo(() => {
    return () => {
      history.push({
        pathname: `/Client/${props.clientId}/CashManagement`,
        state: history.location.state,
      });
    };
  }, [history, props.clientId]);

  const verificationFileRequired = useMemo(() => {
    if (clientCustodian.data?.type === "SingleFullCustodian") {
      //For now, Cash Management only permits clients that have a single 'full' custodian
      return custodiansRequiringVerificationDocument.includes(
        clientCustodian.data.custodian
      );
    }

    return false;
  }, [clientCustodian]);

  const submit = useCallback(async () => {
    if (!isValid || profile.data === undefined) {
      statusNotification("Some fields are missing", "Error");
      return;
    }

    if (verificationFileRequired && verificationFile === null) {
      statusNotification(
        "Please attach an account verification document",
        "Error"
      );
      return;
    }

    const values = getValues() as StripUndefined<Form>;

    try {
      setIsMutating(true);

      const { account } = await callCreateExternalAccount({
        data: {
          account: {
            accountType: values.AccountType.value,
            accountTitle: values.AccountTitle,
            accountNumber: values.AccountNumber,
            accountHolderNames: [values.AccountHolderName],
          },
          bank: {
            routingNumber: values.RoutingNumber,
            name: values.BankName,
            city: values.City,
            state: values.State.label,
          },
          planId: null,
        },
        ...(verificationFile !== null && { attachment: verificationFile }),
        clientId: props.clientId,
      });

      statusNotification("Manual account created.", "Success");

      try {
        await setFundingAccount({
          accountId: account.accountId,
        });
      } catch {
        statusNotification("Unable to update funding account.", "Error");
      }

      onClose();
    } catch {
      statusNotification("Failed to create manual account.", "Error");
      setIsMutating(false);
    }
  }, [
    isValid,
    profile.data,
    verificationFileRequired,
    verificationFile,
    getValues,
    statusNotification,
    callCreateExternalAccount,
    props.clientId,
    onClose,
    setFundingAccount,
  ]);

  return (
    <Drawer
      header={
        <div className={styles.header}>
          Funding Settings | Funding Account
          {" | "}
          <span>{pageTitle}</span>
        </div>
      }
      footer={
        <Footer
          onSave={submit}
          saveDisabled={
            !isValid ||
            profile.data === undefined ||
            (verificationFileRequired && verificationFile === null)
          }
          closeDrawer={onClose}
          isMutating={isMutating}
        />
      }
      isDrawerOpen
      onClose={onClose}
      transitionDuration={0}
    >
      <FormProvider {...methods}>
        <FormH1>{pageTitle}</FormH1>

        <Spacer verticalSpacing="4px" />

        <FlexWrapper>
          <FormTextField
            required={"Bank Name is required"}
            label={"Bank Name"}
            name={"BankName"}
          />

          <FormTextField
            required={"Account Name is required"}
            label={"Account Holder Name"}
            name={"AccountHolderName"}
          />

          <FormTextField
            required={"Account Title is required"}
            label={"Account Title"}
            name={"AccountTitle"}
          />

          <FormDropdownField
            required={"Account Type is required"}
            label={"Account Type"}
            name={"AccountType"}
            disableSearch
            values={fundingAccountTypeOptions.map((el) => ({
              label: capitalize(el),
              value: el,
            }))}
          />

          <FormTextField
            required={"City is required"}
            label={"Bank City"}
            name={"City"}
          />

          <FormDropdownField
            required={"State is required"}
            label={"Bank State"}
            name={"State"}
            values={USAStates.map((el) => ({ label: el }))}
          />

          <FormTextField
            required={"Account # is required"}
            label={"Account #"}
            name={"AccountNumber"}
          />

          <FormNumberField
            required={"Routing # is required"}
            label={"Routing #"}
            name={"RoutingNumber"}
            rules={{
              minLength: {
                value: 9,
                message: "Must have 9 numbers",
              },
              maxLength: {
                value: 9,
                message: "Must have 9 numbers",
              },
            }}
          />
        </FlexWrapper>
      </FormProvider>

      <Box marginTop={"32px"}>
        <FormFieldLabel
          label={<div className={styles.label}>Account Verification</div>}
        />

        <FileUploadButton
          onChange={setVerificationFile}
          currentFileName={verificationFile?.name}
        />

        <div className={styles.helperText}>
          Upload a bank statement or voided check <br />
          associated with this funding account.
        </div>
      </Box>

      {isMutating && (
        <div className={styles.loading}>
          <LogoLoadingStill />
        </div>
      )}
    </Drawer>
  );
};

export default AddManualFundingAccountDrawer;
