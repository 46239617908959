import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useHistory, useLocation, useParams } from "react-router-dom";

import useExecutePlanV2 from "@fartherfinance/frontend/api/Accounts/hooks/useExecutePlanV2";
import usePlanDetailsV2 from "@fartherfinance/frontend/api/Accounts/hooks/usePlanDetailsV2";
import { Plan } from "@fartherfinance/frontend/api/Accounts/Types";
import { PlanId } from "@fartherfinance/frontend/api/Types";

import LogoLoadingStill from "../../../../sharedComponents/LogoLoadingStill/LogoLoadingStill";
import EditablePlanName from "../components/EditablePlanName";
import AddAccountsAndTable from "../components/PlanAccountsTable/Container";
import Container from "../components/PlanDocumentsTable/Container";
import AddTransferAuthsAndTable from "../components/PlanTransferAuthsTable/Container";
import Plans from "../Plans";
import Drawer, {
  largeDrawer,
} from "@src/multiCustodian/components/Drawer/Drawer";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";

import styles from "./plans.module.css";

interface Props {
  selectedAccount?: string;
}

const EditPlan = (props: PropsWithChildren<Props>): JSX.Element => {
  const { enableCashManagementV2 } = useFlags();

  const [isMutating, setIsMutating] = useState(false);
  const { planId } = useParams<{ planId: PlanId }>();
  const history = useHistory();

  const statusNotification = useStatusNotification();

  const { search } = useLocation();
  const qs = useMemo(() => new URLSearchParams(search), [search]);

  const auth = useAdvisorRequestAuth();

  const planDetails = usePlanDetailsV2(planId, auth);

  const callPutExecutePlan = useExecutePlanV2(auth);

  const executePlan = useCallback(async () => {
    try {
      setIsMutating(true);
      await callPutExecutePlan(planId);
      statusNotification("Plan Executed", "Success");
      setIsMutating(false);
      history.push(`/Advisor/Plans?${qs.toString()}`);
    } catch {
      statusNotification("Error executing plan", "Error");
      setIsMutating(false);
    }
  }, [callPutExecutePlan, history, planId, qs, statusNotification]);

  if (planDetails.isLoading) {
    return (
      <Plans>
        <Drawer
          container_style={largeDrawer}
          isDrawerOpen
          onClose={() => history.push(`/Advisor/Plans?${qs.toString()}`)}
          transitionDuration={0}
        >
          <div className={styles.loadingContainer}>
            <LogoLoadingStill />
          </div>
        </Drawer>

        {props.children}
      </Plans>
    );
  }

  if (planDetails.hasError) {
    return (
      <Plans>
        <Drawer
          container_style={largeDrawer}
          isDrawerOpen
          onClose={() => history.push(`/Advisor/Plans?${qs.toString()}`)}
          transitionDuration={0}
        >
          <div className={styles.loadingContainer}>Error</div>
        </Drawer>

        {props.children}
      </Plans>
    );
  }

  return (
    <Plans>
      <Drawer
        container_style={largeDrawer}
        isDrawerOpen
        header={<DrawerHeaderLeft plan={planDetails.data.plan} />}
        footer={
          <DrawerFooter
            submitDisabled={
              !planDetails.data.isValidPlan ||
              isMutating ||
              planDetails.data.plan.planStatus === "Complete"
            }
            queryString={qs.toString()}
            onSubmit={executePlan}
          />
        }
        onClose={() => history.push(`/Advisor/Plans?${qs.toString()}`)}
        transitionDuration={0}
      >
        <Header plan={planDetails.data.plan} />

        <div className={styles.overflow_auto_div_for_tables}>
          <AddAccountsAndTable
            queryStringParams={qs.toString()}
            selectedAccount={props.selectedAccount}
          />

          {enableCashManagementV2 && <AddTransferAuthsAndTable />}

          <Container planId={planId} planDetails={planDetails.data} />
        </div>
      </Drawer>

      {props.children}
    </Plans>
  );
};

interface HeaderProps {
  plan: Plan;
}

const Header = ({ plan }: HeaderProps): JSX.Element => {
  let planStatusColor = "var(--primary_text_color_1)";
  switch (plan?.planStatus) {
    case "Not Started":
      planStatusColor = "var(--bad_negative_color)";
      break;
    case "In Progress":
      planStatusColor = "var(--item_focus_selected_color_1)";
      break;
    case "Ready to Review":
      planStatusColor = "var(--item_focus_selected_color_4)";
      break;
    case "Complete":
      planStatusColor = "var(--positive_performance_up_color)";
      break;
  }

  return (
    <div className={styles.topDiv_planNameOnLeft_planStatusOnRight}>
      <EditablePlanName />

      <p
        className={styles.topDiv_planStatusOnRight}
        style={{ color: planStatusColor }}
      >
        {plan.planStatus === "Complete" ? "Submitted" : plan.planStatus}
      </p>
    </div>
  );
};

export default EditPlan;

interface DrawerHeaderLeftProps {
  plan: Plan | undefined;
}

const DrawerHeaderLeft = ({ plan }: DrawerHeaderLeftProps): JSX.Element => {
  if (!plan) {
    return <></>;
  }

  return (
    <div className={styles.drawerHeaderLeft_div}>
      <p className={styles.drawerHeaderLeft_clientNameId}>
        {`${plan.planHolder.name.first} ${
          plan.planHolder.name.last
        } (...${plan.planHolder.clientId.slice(-5)}) | `}
      </p>

      <p className={styles.drawerHeaderLeft_planStatus}>{plan.planTitle}</p>
    </div>
  );
};

interface DrawerFooterProps {
  queryString: string;
  submitDisabled: boolean;
  onSubmit: () => void;
}

const DrawerFooter = ({
  queryString,
  submitDisabled,
  onSubmit: onRelease,
}: DrawerFooterProps): JSX.Element => {
  const history = useHistory();

  return (
    <div className={styles.drawerFooter_buttons_div}>
      <Button
        variant={"outlined"}
        buttonType={"secondary"}
        text={"Cancel"}
        onClick={() => history.push(`/Advisor/Plans?${queryString}`)}
      />

      <Button
        variant={"contained"}
        buttonType={"primary"}
        text={"Submit"}
        onClick={onRelease}
        style={{ marginLeft: "25px" }}
        disabled={submitDisabled}
      />
    </div>
  );
};
