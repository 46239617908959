import React from "react";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import Modal from "@src/sharedComponents/Modal/Modal";

import styles from "./modal.module.css";

interface Props {
  closeModal: () => void;
  onAdd: () => void;
  tradingGroupName: string;
  isMutating: boolean;
}

const DirectIndexingModal = (props: Props): JSX.Element => {
  return (
    <Modal closeModal={props.closeModal} modalStyle={{ width: "520px" }}>
      {!props.isMutating && (
        <>
          <p className={styles.modalHeader}>
            {`Are you sure you want to turn off direct indexing for ${props.tradingGroupName}?`}
          </p>

          <p className={styles.modalText}>
            As there may be tax consequences with any investment strategy,
            Farther recommends consulting with your tax professional before
            making any changes to your investment strategy.
          </p>

          <p className={styles.modalText}>
            Farther and their advisors are not tax professionals and therefore
            do not dispense tax advise.
          </p>

          <div className={styles.modalButtonsDiv}>
            <Button
              variant={"outlined"}
              buttonType={"secondary"}
              text={"Cancel"}
              onClick={props.closeModal}
            />

            <Button
              variant={"contained"}
              buttonType={"primary"}
              text={"Turn Off Direct Indexing"}
              onClick={props.onAdd}
              style={{ marginLeft: "20px" }}
            />
          </div>
        </>
      )}

      {props.isMutating && <LogoLoadingStill onTop={true} />}
    </Modal>
  );
};

export default DirectIndexingModal;
