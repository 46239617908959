import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";
import { isAxiosError } from "axios";
import { flow } from "lodash";
import mixpanel from "mixpanel-browser";

import { AdvisorId, ClientId } from "@fartherfinance/frontend/api/Types";
import { TrackingEvents } from "@fartherfinance/frontend/eventTracking/events";
import { convertProfileDetails } from "@fartherfinance/frontend/eventTracking/mixpanel";
import { ProfileDetails } from "@fartherfinance/frontend/eventTracking/profiles";
import { setExceptionReporter } from "@fartherfinance/frontend/exceptions";
import {
  setDebugLogger,
  setErrorLogger,
} from "@fartherfinance/frontend/logger";

import normalizeURL from "@src/utils/normalizeURL";

/**
 * Identify user to tracking service
 * @param id Unique identifier for a user
 * @returns void
 */
export function identify(id: ClientId | AdvisorId) {
  console.log("Identify", id);

  Sentry.setUser({ id: id });

  datadogRum.setUser({ id: id });
  datadogLogs.setUser({ id: id });

  // Do not log to most places if not in real PROD
  if (process.env.WEBAPP_EXACT_ENV !== "PROD") {
    return;
  }

  mixpanel.identify(id);
}

/**
 * Sent user event to a tracking service
 * @param event Event to send to tracking service
 * @param extraProps An extra props not in shared event
 * @returns void
 */
export function trackEvent(
  event: TrackingEvents,
  extraProps?: Record<string, string | number>
) {
  const baseProps: Record<string, string | number | null> =
    "properties" in event ? event.properties : {};
  const extendedProps =
    extraProps !== undefined ? { ...baseProps, ...extraProps } : baseProps;

  // Always log to Sentry
  Sentry.addBreadcrumb({ message: event.name, data: extendedProps });

  // Always log to Data Dog
  datadogRum.addAction(event.name, extendedProps);

  // Do not log to most places if not in real PROD
  if (process.env.WEBAPP_EXACT_ENV !== "PROD") {
    return;
  }

  mixpanel.track(event.name, extendedProps);
}

interface ExtendedProfileDetails extends ProfileDetails {
  id?: AdvisorId | ClientId;
  advisorId?: AdvisorId;
  advisorName?: `${string} ${string}`;
  dateOfBirth?: string;
  joinedDate?: string;
  impersonatedById?: AdvisorId;
  impersonatingClientId?: ClientId;
}

/**
 * Set user profile in tracking services
 * @param details Profile Details to set for the user
 * @returns void
 */
export function setUserInfo(details: ExtendedProfileDetails) {
  // Always log to Sentry
  Sentry.setUser(details);

  const { isAdvisor, isAdmin, ...restOfDetails } = details;

  datadogRum.setUser({
    ...restOfDetails,
    isAdmin: isAdmin === undefined ? undefined : isAdmin ? "True" : "False",
    isAdvisor: isAdmin === undefined ? undefined : isAdvisor ? "True" : "False",
  });

  datadogLogs.setUser({
    ...restOfDetails,
    isAdmin: isAdmin === undefined ? undefined : isAdmin ? "True" : "False",
    isAdvisor: isAdmin === undefined ? undefined : isAdvisor ? "True" : "False",
  });

  if (details.email !== undefined) {
    Sentry.setUser({ username: details.email });
  }

  if (details.isAdmin === true) {
    Sentry.setUser({ segment: "admin" });
  } else if (details.isAdvisor === true) {
    Sentry.setUser({ segment: "advisor" });
  } else {
    Sentry.setUser({ segment: "client" });
  }

  // Required because of this message from Sentry Support
  // > Replays that get sent before a user is identified will have the
  // > default, which is the ip.  There won't be any way to retroactively
  // > edit a user for an already sent and processed replay.  Maybe you can
  // > look at Lazy Loading the replay integration?  That way you can add
  // > the integration on the user's login and only capture replays when the
  // > user is logged in?  Otherwise you could set the user as some form of
  // > placeholder before you receive the actual id and then set it again.
  if (details.email !== undefined) {
    startSentryReplay(details)
      .then((didLoad) => {
        if (didLoad) {
          console.log("Starting Sentry Replay with info", details);
        }
      })
      .catch((e) => {
        console.log("Sentry Replay Error", e);
      });
  }

  // Do not log to most places if not in real PROD
  if (process.env.WEBAPP_EXACT_ENV !== "PROD") {
    return;
  }

  const mixpanelTransformer = flow(
    filterMixpanelProfile,
    convertProfileDetails
  );

  mixpanel.people.set(mixpanelTransformer(details));
}

function filterMixpanelProfile(
  details: ExtendedProfileDetails
): ProfileDetails {
  const { id: _id, ...mixpanelDetails } = details;

  return mixpanelDetails;
}

/**
 * Reset tracking
 */
export function resetTracking() {
  // Always reset
  mixpanel.reset();

  datadogRum.clearUser();
  datadogLogs.clearUser();

  Sentry.setUser(null);
}

export function captureException(rawError: Error | unknown, extra?: object) {
  const error: Error =
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    rawError instanceof Error ? rawError : new Error(`${rawError}`);

  try {
    Sentry.captureException(error, extra);
  } catch (e) {
    console.error(e);
  }

  try {
    if (isAxiosError(error)) {
      const url = normalizeURL(error.config?.url ?? "[unknown url]");
      (error as any).dd_fingerprint = [error.name, error.message, url].join(
        " - "
      );
      error.message = `${error.message} - ${url}$`;
    } else {
      (error as any).dd_fingerprint = [error.name, error.message].join(" - ");
    }
    datadogRum.addError(error, extra);
    // datadogLogs.logger.error(`${error.name} - ${error.message}`, extra);
  } catch (e) {
    console.error(e);
  }

  console.log("Exception", rawError, extra);
}

setExceptionReporter(captureException);

setDebugLogger((service, message) => {
  console.log(service, message);

  datadogLogs.logger.debug(`${service} ${message}`);
});

setErrorLogger((service, message) => {
  console.log(service, message);

  datadogLogs.logger.error(`${service} ${message}`);
});

// This is a singleton across the app
let hasLoadedSentryReplay = false;

const startSentryReplay = async (
  details: ExtendedProfileDetails
): Promise<boolean> => {
  if (hasLoadedSentryReplay) {
    return false;
  }

  hasLoadedSentryReplay = true;

  // We _MUST_ set the user details right before the addIntegration or we do not connect them
  Sentry.setUser(details);
  Sentry.getCurrentHub()
    .getClient()
    ?.addIntegration?.(
      new Sentry.Replay({
        blockAllMedia: false,
        maskAllInputs: false,
        maskAllText: false,
        stickySession: true,
        networkCaptureBodies: true,
        networkDetailAllowUrls: ["farther.com"],
        networkRequestHeaders: [
          "Cache-Control",
          "client",
          "advisor",
          "authorization",
        ],
        networkResponseHeaders: [],
      })
    );

  return true;
};
