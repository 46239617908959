import { useCallback } from "react";

import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import login, { MetaData } from "@fartherfinance/frontend/api/login";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import metadata_payload from "../utils/metadata_payload";
import {
  identify,
  setUserInfo,
  trackEvent,
} from "@src/multiCustodian/services/tracking";
import { MCReduxAction } from "@src/store";

export interface LoginInfo {
  magicToken: string;
  clientEmail: string;
}

export default function useClientFartherLogin() {
  const dispatch = useDispatch<Dispatch<MCReduxAction>>();

  const clientFartherLogin = useCallback(
    async (auth: LoginInfo) => {
      console.log(`Logging in as client with email ${auth.clientEmail}`);

      const loginRes = await login(
        auth.magicToken,
        auth.clientEmail,
        metadata_payload() as MetaData
      );

      const clientId = loginRes.userId as ClientId;

      dispatch({
        type: "LOG_IN",
        payload: {
          farther_jwt: loginRes.jwt,
          id_user: clientId,
          isAdvisor: false,
          conflict: false,
          email: auth.clientEmail,
          password: null,
        },
      });

      identify(clientId);
      trackEvent({ name: "Client Login" });

      setUserInfo({
        id: clientId,
        isAdvisor: false,
        email: auth.clientEmail,
      });
    },
    [dispatch]
  );

  return clientFartherLogin;
}
