import React from "react";

import { useSelector } from "react-redux";

import useClientCustodian from "@fartherfinance/frontend/api/Dashboard/hooks/useClientCustodian";

import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import { State } from "@src/store";

import styles from "./WireTransferInstructions.module.css";

function WireTransferInstructions(): JSX.Element {
  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));

  const auth = useRequestAuth();

  const custodian = useClientCustodian(clientId, auth);

  if (custodian.isLoading) {
    return <LoadingInstructions />;
  }

  if (custodian.hasError) {
    return <ErrorInstructions />;
  }

  const currentCustodian =
    custodian.data.type === "SingleFullCustodian"
      ? custodian.data.custodian
      : custodian.data.type;

  switch (currentCustodian) {
    case "Apex":
      return <ApexInstructions />;

    case "Fidelity":
      return <ContactAdvisor />;

    case "Pershing":
      return <ContactAdvisor />;

    case "Schwab":
      return <ContactAdvisor />;

    case "Limited":
      return <ContactAdvisor />;

    case "MultipleFullCustodians":
      return <ContactAdvisor />;

    default:
      const _x: never = currentCustodian;
      throw new Error(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `[WireTransferInstructions]: Unexpected custodian: ${_x}`
      );
  }
}
export default WireTransferInstructions;

const Container = (props: React.PropsWithChildren<unknown>): JSX.Element => {
  return (
    <div className={styles.wire_transfer_container}>
      <div className={styles.questionContainer}>
        <p className={styles.questionText}>Prefer to send a wire transfer?</p>
      </div>
      <div className={styles.wire_transfer_div}>{props.children}</div>
    </div>
  );
};

const LoadingInstructions = () => {
  return (
    <Container>
      <div>Getting proper instructions...</div>
    </Container>
  );
};

const ErrorInstructions = () => {
  return (
    <Container>
      <div>Please contact your advisor for wire instructions</div>
    </Container>
  );
};

const ContactAdvisor = () => {
  return (
    <Container>
      <div>Please contact your advisor for wire instructions</div>
    </Container>
  );
};

const ApexInstructions = (): JSX.Element => {
  return (
    <Container>
      <>
        <div>Looking to make a wire transfer? Follow the steps below:</div>
        <div>1. Call or login to your institution to initiate a transfer</div>
        <div>
          2. FBO: Provide the appropriate information - APEX Clearing
          Corporation FFC:
          <p className={styles.wire_transfer_text}>
            [full name on your account], [your account number]
          </p>
        </div>
        <div>3. Provide the Apex account number: 3713286</div>
        <div>4. Provide the Apex routing number: 071000288</div>
        <div>
          <div>5. Use this mailing address:</div>
          <p className={styles.wire_transfer_text}>
            BMO/Harris Bank <br />
            111 West Monroe Street <br />
            9th Floor Center <br />
            Chicago, IL 60603
          </p>
        </div>
      </>
    </Container>
  );
};
