import React from "react";

import { Box, Typography } from "@mui/material";
import { format } from "date-fns";

import { OpportunityTransaction } from "@fartherfinance/frontend/api/Opportunities/Types";
import { formatCurrency } from "@fartherfinance/frontend/formatting/numbers";

import OpportunityMetadata from "./OpportunityMetadata/OpportunityMetadata";

import styles from "./Opportunity.module.css";

interface OpportunityTransferProps {
  transfer: OpportunityTransaction;
}

export default function OpportunityTransfer({
  transfer,
}: OpportunityTransferProps): JSX.Element {
  return (
    <Box>
      <Typography className={styles.title}>Transfer</Typography>
      <Box className={styles.container}>
        <OpportunityMetadata
          label="Amount"
          value={formatCurrency(transfer.amount.toString(), {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          })}
        />
        <OpportunityMetadata label="Details" value={transfer.transactionName} />
        <OpportunityMetadata label="Conf. #" value={transfer.transactionId} />
        <OpportunityMetadata
          label="Date"
          value={format(transfer.transactionDate, "MM/dd/yy")}
        />
      </Box>
    </Box>
  );
}
