import { useEffect } from "react";

import { addBreadcrumb } from "@sentry/react";
import { History } from "history";
import ms, { StringValue } from "ms";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";

import { trackEvent } from "../services/tracking";
import logUserOut from "../utils/logUserOut";
import { getMagicMetadata } from "@src/magic";
import { MCReduxAction, State } from "@src/store";

import useStatusNotification, { ShowStatusFn } from "./useStatusNotification";

export default function useMagicRefreshToken(isAdvisor: boolean | null) {
  const dispatch = useDispatch<Dispatch<MCReduxAction>>();

  const history = useHistory();

  const executeStatusNotification = useStatusNotification();

  const { did } = useSelector((state: State) => ({
    did: state.main_Reducer.farther_jwt,
  }));

  useEffect(() => {
    if (isAdvisor === null) {
      return;
    }

    if (typeof did === "string" && did.startsWith("ffp_")) {
      // using a Farther token and we should do nothing;
      return;
    }

    // On a loop check that the magic token is good
    const sentryInterval = setInterval(() => {
      if (did === null) {
        return;
      }

      checkToken(did, history, executeStatusNotification);
    }, ms("1min"));

    return () => {
      clearInterval(sentryInterval);
      // clearInterval(refreshInterval);
    };
  }, [did, dispatch, executeStatusNotification, history, isAdvisor]);
}

function checkToken(
  did: string,
  history?: History,
  showPopup?: ShowStatusFn
): boolean {
  try {
    if (did === null) {
      throw new Error("checkToken got null did");
    }

    const metadata = getMagicMetadata(did);
    const epochInSeconds = Date.now() / 1000;

    if (metadata !== null && metadata.ext <= epochInSeconds) {
      // Our token is not valid, we need to log out and grab a new one
      addBreadcrumb({
        message: "Magic token has expired",
        data: { magicMetadata: metadata, now: epochInSeconds },
      });

      trackEvent(
        { name: "Magic token expired" },
        {
          ext: metadata.ext,
          iat: metadata.iat,
          now: epochInSeconds,
        }
      );

      console.log("Magic token expired", metadata.ext, epochInSeconds);

      let logoutIn = 0;
      if (showPopup) {
        const time: StringValue = "10 seconds";
        logoutIn = ms(time);
        showPopup(
          `Your login has expired, we'll log you out in ${time}`,
          "Error"
        );
      }

      setTimeout(() => {
        logUserOut("Magic token expired", history);
      }, logoutIn);

      return false;
    }
  } catch (e) {
    console.error("useMagicRefreshToken token decode failed", e);
    return false;
  }

  return true;
}
