/* eslint-disable import/no-unused-modules */
import React, { ComponentProps } from "react";

import { Controller, Message, useFormContext } from "react-hook-form";

import DropdownMultiSelect from "../DropdownMultiSelect/DropdownMultiSelect";

export interface Props {
  name: string;
  label: string;
  values: readonly string[];
  defaultValue?: string[];
  required?: Message | false;
  rules?: ComponentProps<typeof Controller>["rules"];
}

export default function FormDropdownMultiSelectField({
  ...props
}: Props): JSX.Element {
  const { control } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      rules={{ required: props.required, ...props.rules }}
      defaultValue={props.defaultValue}
      render={({ field, fieldState }) => (
        <DropdownMultiSelect
          formLabel={props.label}
          onChange={(event) => {
            const {
              target: { value },
            } = event;

            field.onChange(
              typeof value === "string" ? value.split(",") : value
            );
          }}
          value={field.value}
          values={props.values}
          name={field.name}
          onBlur={field.onBlur}
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
        />
      )}
    />
  );
}
