import React from "react";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import Modal from "@src/sharedComponents/Modal/Modal";

import styles from "./AccountsListSelection.module.css";

interface Props {
  closeModal: () => void;
  onRemove: () => void;
  tradingGroupName: string;
  accountName: string;
  isMutating: boolean;
}

const RemoveAccountFromTradingGroupModal = (props: Props): JSX.Element => {
  return (
    <Modal closeModal={props.closeModal} modalStyle={{ width: "520px" }}>
      {props.isMutating && <LogoLoadingStill onTop={true} />}

      <p className={styles.modalHeader}>
        {`Are you sure you want to remove ${props.accountName} from ${props.tradingGroupName}?`}
      </p>

      <div className={styles.modalButtonsDiv}>
        <Button
          variant={"outlined"}
          buttonType={"secondary"}
          text={"Cancel"}
          onClick={props.closeModal}
        />

        <Button
          variant={"contained"}
          buttonType={"primary"}
          text={"Remove Account"}
          onClick={props.onRemove}
          style={{ marginLeft: "20px" }}
        />
      </div>
    </Modal>
  );
};

export default RemoveAccountFromTradingGroupModal;
