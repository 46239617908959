import React from "react";

import styles from "./BorderedList.module.css";

export interface Props {
  headers?: {
    left: React.ReactNode;
    right?: React.ReactNode;
    rowStyle?: React.CSSProperties;
  }[];
  rows: {
    left: React.ReactNode;
    right?: React.ReactNode;
    rowStyle?: React.CSSProperties;
  }[];
  maxHeight?: number;
}

const BorderedList = (props: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      {(props.headers ?? []).map((r, i) => {
        return (
          <div key={`${i}`} className={styles.row} style={r.rowStyle}>
            <div>{r.left}</div>
            <div>{r.right}</div>
          </div>
        );
      })}

      <div
        className={styles.rows}
        style={props.maxHeight ? { maxHeight: `${props.maxHeight}px` } : {}}
      >
        {props.rows.map((r, i) => {
          return (
            <div key={`${i}`} className={styles.row} style={r.rowStyle}>
              <div>{r.left}</div>
              <div>{r.right}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BorderedList;
