export const enum Environment {
  UAT = "UAT",
  PRODUCTION = "PROD",
}

export const getTargetEnvironment = (env: Environment) => {
  switch (env) {
    case Environment.UAT:
    case Environment.PRODUCTION:
      return env;
    default:
      const _x: never = env;
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Invalid environment: ${env}`);
  }
};

export const getEnvironment = () =>
  getTargetEnvironment(
    (process.env.WEBAPP_ENV as Environment) ?? Environment.UAT
  );
