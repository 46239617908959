import React, { useMemo } from "react";

import { orderBy } from "lodash";

import { AccountGroup } from "@fartherfinance/frontend/api/PerformanceGroups/requests/getAccountGroups";
import { PerformanceGroupId } from "@fartherfinance/frontend/api/Types";

import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { captureException } from "@src/multiCustodian/services/tracking";
import useThemeFragment from "@src/multiCustodian/theme/useThemeFragment";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import Select from "@src/sharedComponents/Select/Select";
import SelectItem from "@src/sharedComponents/Select/SelectItem";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

interface AccountGroupDropdownProps {
  value: AccountGroup | undefined;
  options: AccountGroup[];
  onChange: (id: PerformanceGroupId) => void;
  className?: string;
}

const AccountGroupDropdown = (props: AccountGroupDropdownProps) => {
  const t = useThemeFragment();
  const company = t("companyName") ?? "Farther";

  const statusNotification = useStatusNotification();

  const options: AccountGroup[] = useMemo(() => {
    if (props.options.length <= 0) {
      return [];
    }

    const allClientAccountOption = props.options.find(
      (ag) => ag.accountGroupType === "AllClientAccounts"
    );

    const allOptionsExceptAllClientAccountOption = props.options.filter(
      (ag) => ag.accountGroupType !== "AllClientAccounts"
    );

    const allOptionsExceptAllClientAccountOptionOrdered = orderBy(
      allOptionsExceptAllClientAccountOption,
      (ag) => ag.displayName.toLowerCase(),
      "asc"
    );

    if (allClientAccountOption === undefined) {
      statusNotification(
        "Unable to fetch performance data for all accounts",
        "Error"
      );

      const e = new Error(
        "AllClientAccounts account group not found among account groups"
      );

      captureException(e, {
        extra: {
          accountGroups: props.options,
          file: "AccountGroupDropdown.tsx",
        },
      });

      return allOptionsExceptAllClientAccountOptionOrdered;
    }

    return [
      allClientAccountOption,
      ...allOptionsExceptAllClientAccountOptionOrdered,
    ];
  }, [props.options, statusNotification]);

  if (props.value === undefined) {
    return (
      <div className={toClassName(props.className)}>
        <Skeleton width={"100%"} height={30} style={{ transform: "none" }} />
      </div>
    );
  }

  return (
    <Select<AccountGroup, PerformanceGroupId>
      className={props.className}
      value={props.value?.accountGroupId}
      options={options}
      onChange={props.onChange}
      renderValue={(v) => {
        if (v === props.value?.accountGroupId) {
          return (
            <>
              {props.value.accountGroupType === "AllClientAccounts"
                ? `${company} Managed Accounts`
                : props.value?.displayName}
            </>
          );
        } else {
          return <>Unknown</>;
        }
      }}
      renderOption={(group) => (
        <SelectItem
          key={group.accountGroupId}
          label={
            group.accountGroupType === "AllClientAccounts"
              ? `${company} Managed Accounts`
              : group.displayName
          }
          value={group.accountGroupId}
        />
      )}
      size={"medium"}
    />
  );
};

export default AccountGroupDropdown;
