import React from "react";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

export interface RowProps {
  key: string;
  description: string;
  secondaryDescription?: string;
  allocation: number;
  onClick?: () => void;
}

import styles from "./Row.module.css";

type Props = Omit<RowProps, "key">;

const SleeveRow = (props: Props): JSX.Element => {
  const {
    color: { $iconSubtle },
  } = useTheme();

  return (
    <div
      className={styles.container}
      style={!props.onClick ? { cursor: "default" } : {}}
      onClick={props.onClick}
    >
      <div className={styles.descriptionDiv}>
        <div className={styles.description1}>{props.description}</div>

        {props.secondaryDescription !== undefined && (
          <p className={styles.description2}>{props.secondaryDescription}</p>
        )}
      </div>
      <div className={styles.allocation}>
        <div>
          {(props.allocation / 100).toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 2,
          })}
        </div>
        {props.onClick && (
          <ChevronRightIcon
            style={{
              color: $iconSubtle,
              width: "18px",
              height: "18px",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SleeveRow;
