import React from "react";

import Container, { ContainerProps } from "@mui/material/Container";

import { useStyles } from "./WidthLimiter.styles";

type WidthLimiterProps<C extends React.ElementType> = Omit<
  ContainerProps<C, { component?: C }>,
  "maxWidth"
> & {
  className?: string;
};

const WidthLimiterComponent = <C extends React.ElementType>(
  { children, className, ...restProps }: WidthLimiterProps<C>,
  ref: React.ForwardedRef<C>
) => {
  const { classes, cx } = useStyles();

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <Container
      ref={ref as any}
      className={cx(classes.root, className)}
      maxWidth="xl"
      {...restProps}
    >
      {children}
    </Container>
  );
};

export const WidthLimiter = React.forwardRef(WidthLimiterComponent);
