import React from "react";

import { useHistory, useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import RemovePlaidAccountModal from "@src/multiCustodian/components/Client/Accounts/RemovePlaidAccountModal/RemovePlaidAccountModal";
import Accounts from "@src/multiCustodian/pages/Client/:clientId/Accounts";

const RemoveExternalAccount = (): JSX.Element => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const history = useHistory();

  return (
    <Accounts>
      <RemovePlaidAccountModal
        closeModal={() => history.push(`/Client/${clientId}/Accounts`)}
      />
    </Accounts>
  );
};

export default RemoveExternalAccount;
