import React, { PropsWithChildren } from "react";

import { useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import { FinancialPlansPath } from "@src/config/routing/RouterPaths";
import HeroTooltipCard from "@src/multiCustodian/components/FinancialPlans/Components/HeroTooltipCard";
import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import LinkButton from "@src/sharedComponents/LinkButton/LinkButton";
import PageHero from "@src/sharedComponents/PageHero/PageHero";
import TitleBlock from "@src/sharedComponents/PageHero/TitleBlock";

import styles from "./FinancialPlans.module.css";

const FinancialPlans = (props: PropsWithChildren<unknown>): JSX.Element => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const noCompletePlans = true;

  return (
    <BaseLayout>
      <PageHero
        className={toClassName({
          [styles.pageHero]: !noCompletePlans,
          [styles.pageHeroNoPlans]: noCompletePlans,
        })}
        start={
          noCompletePlans ? (
            <div className={styles.heroTitleGap}>
              <TitleBlock
                title="Financial Plans"
                subTitle="Identify your goals and create a plan to achieve them."
              />

              <LinkButton
                text="Create Plan"
                variant="contained"
                buttonType="primary"
                to={`/Client/${clientId}/${FinancialPlansPath}/CreateUpdate`}
              />
            </div>
          ) : (
            <TitleBlock title="Financial Plans" />
          )
        }
        end={
          noCompletePlans ? (
            <div className={styles.heroCardsGap}>
              <HeroTooltipCard
                title="Set Goals"
                text="Track your financial goals — anything from buying a home to saving for a wedding."
                className={styles.heroCard}
              />

              <HeroTooltipCard
                title="Track income and expenses into retirement"
                text="Track your income, expenses, and savings to see if you're on track."
                className={styles.heroCard}
              />
            </div>
          ) : (
            <div className={styles.heroCardsGap}>
              <LinkButton
                text="Create Plan"
                variant="contained"
                buttonType="secondary"
                to={`/Client/${clientId}/${FinancialPlansPath}/CreateUpdate`}
              />

              <div>Dropdown Menu</div>
            </div>
          )
        }
      />

      {props.children}
    </BaseLayout>
  );
};

export default FinancialPlans;
