import React from "react";

import CreateEditLayout from "../../../TaxBudgets/CreateEditLayout/CreateEditLayout";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";

import AccountsReviewRow from "./AccountsReviewRow";
import AmountReviewRow from "./AmountReviewRow";
import YearReviewRow from "./YearReviewRow";

import styles from "./ReviewBudget.module.css";

const ReviewBudget: React.FC = () => {
  return (
    <CreateEditLayout>
      <BorderBox className={styles.container}>
        <div className={styles.title}>Budget Summary</div>
        <BorderBox className={styles.body}>
          <YearReviewRow />
          <AmountReviewRow />
          <AccountsReviewRow />
        </BorderBox>
      </BorderBox>
    </CreateEditLayout>
  );
};

export default ReviewBudget;
