import React, { useMemo } from "react";

import { orderBy } from "lodash";

import { UNKNOWN_BALANCE } from "@fartherfinance/frontend/api/Accounts/utilities/accountUtil";
import { HydratedSecurity } from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Holdings/utils";
import { TaxLot } from "@fartherfinance/frontend/api/PerformanceGroups/requests/getHoldings";
import { Ticker } from "@fartherfinance/frontend/api/Types";

import { DrillInState } from "../Types";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import PerformanceIndicatorArrow from "@src/sharedComponents/PerformanceIndicatorArrow/PerformanceIndicatorArrow";
import FullDataTable from "@src/sharedComponents/Table/FullDataTable";
import type {
  Cell,
  ExpandableRow,
  Row,
  RowWithoutClick,
  TableSortOrder,
} from "@src/sharedComponents/Table/Types";
import toUSD from "@src/utils/toUSD";

import CashContainer from "./Components/CashContainer";
import ExpandableRowComponent, {
  formatNumber,
} from "./Components/ExpandableRow/ExpandableRow";
import TickerContainer from "./Components/TickerContainer";

import styles from "./HoldingsTable.module.css";

const cashTicker = "CASH" as Ticker;

const rowsPerPage = 50;

export const tableKeys = [
  "Name",
  "Shares",
  "Allocation",
  "Price",
  "Cost Basis",
  "Value",
] as const;

export type TableKey = typeof tableKeys[number];

export interface SecuritiesWithExtraData extends HydratedSecurity {
  profit: number | null;
}

interface Props {
  securities: SecuritiesWithExtraData[];
  totalSecuritiesValue: number;
  taxLots: TaxLot[];
  drillInState: DrillInState;
}

const toPercent = (amount: number): string => {
  return amount.toLocaleString("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const toSortable = (amount: number, fractionDigits = 2): string => {
  return formatNumber(amount, fractionDigits).padStart(20, "0");
};

const HoldingsTable = (props: Props): JSX.Element => {
  const secondaryDefaultSortColumn: readonly [TableKey, TableSortOrder] =
    useMemo(() => {
      return props.drillInState.class !== null
        ? ["Allocation", "desc"]
        : ["Name", "asc"];
    }, [props.drillInState.class]);

  const securities: Row<TableKey, Cell>[] = useMemo(() => {
    return props.securities.map((item) => {
      const taxLots = orderBy(
        props.taxLots.filter((t) => t.ticker === item.ticker),
        (t) => t.buyDate,
        "desc"
      );

      const costBasis = taxLots.reduce(
        (total, t) => total + t.buyPrice * t.quantity,
        0
      );

      const taxLotBuyPriceIs0WithQuantity = taxLots.some(
        (tl) => tl.buyPrice === 0 && tl.quantity > 0
      );

      if (item.ticker === cashTicker) {
        const row: RowWithoutClick<TableKey, Cell> = {
          key: item.ticker,
          Name: {
            value: <CashContainer ticker={item.ticker} />,
            fullValue: "!", // Attempting to keep CASH as the top option
          },
          Shares: {
            value: <div className={styles.mutedText}>{UNKNOWN_BALANCE}</div>,
            fullValue: UNKNOWN_BALANCE,
          },
          Allocation: {
            value: toPercent(item.marketValue / props.totalSecuritiesValue),
            fullValue: toSortable(
              item.marketValue / props.totalSecuritiesValue,
              4
            ),
          },
          Price: {
            //Current market price of the ticker
            value: (
              <div className={toClassName(styles.price)}>{UNKNOWN_BALANCE}</div>
            ),
            fullValue: toSortable(item.marketValue / item.quantity),
          },
          "Cost Basis": {
            value: <div className={styles.price}>{UNKNOWN_BALANCE}</div>,
            fullValue: toSortable(costBasis),
          },
          Value: {
            value: (
              <div>
                <div className={styles.marketValue}>
                  {toUSD(item.marketValue)}
                </div>
              </div>
            ),
            fullValue: toSortable(item.marketValue),
          },
        };

        return row;
      }

      const row: ExpandableRow<TableKey, Cell> = {
        key: item.ticker,
        Name: {
          value: (
            <TickerContainer ticker={item.ticker} tickerName={item.name} />
          ),
          fullValue:
            item.ticker === cashTicker ? "!" : item.ticker.toUpperCase(), // Attempting to keep CASH as the top option
        },
        Shares: {
          value: formatNumber(item.quantity),
          fullValue: toSortable(item.quantity),
        },
        Allocation: {
          value: toPercent(item.marketValue / props.totalSecuritiesValue),
          fullValue: toSortable(
            item.marketValue / props.totalSecuritiesValue,
            4
          ),
        },
        Price: {
          //Current market price of the ticker
          value: (
            <div className={toClassName(styles.price)}>
              {toUSD(item.marketValue / item.quantity)}
            </div>
          ),
          fullValue: toSortable(item.marketValue / item.quantity),
        },
        "Cost Basis": {
          value: <div className={styles.price}>{toUSD(costBasis)}</div>,
          fullValue: toSortable(costBasis),
        },
        Value: {
          value: (
            <div>
              <div className={styles.marketValue}>
                {toUSD(item.marketValue)}
              </div>
              {taxLotBuyPriceIs0WithQuantity ? (
                <div className={styles.percent}>{"- -"}</div>
              ) : (
                item.profit !== null && (
                  <div className={styles.percent}>
                    <PerformanceIndicatorArrow
                      style={{ marginRight: "5px" }}
                      status={item.profit >= 0 ? "positive" : "negative"}
                    />
                    <span>{toPercent(item.profit)}</span>
                  </div>
                )
              )}
            </div>
          ),
          fullValue: toSortable(item.marketValue),
        },
        expandableDetails: <ExpandableRowComponent taxLots={taxLots} />,
        onClick: "ExpandRow",
      };

      return row;
    });
  }, [props.securities, props.taxLots, props.totalSecuritiesValue]);

  return (
    <div className={styles.container}>
      <FullDataTable
        columns={tableKeys}
        rows={securities}
        defaultRowsPerPage={rowsPerPage}
        defaultSortColumn={["Name", "asc"]}
        secondaryDefaultSortColumn={secondaryDefaultSortColumn}
        disableColumnSorting={securities.length > 0 ? undefined : tableKeys}
        emptyCell={
          <TickerContainer ticker="Placeholder" tickerName="Placeholder" />
        } // This is the highest cell we will have
        hidePagination={securities.length <= rowsPerPage}
        className={styles.minWidth}
      />
    </div>
  );
};

export default HoldingsTable;
