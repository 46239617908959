import React, { useMemo, useState } from "react";

import { FormControl, FormLabel } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  DesktopDatePicker,
  DesktopDatePickerProps as MuiDesktopDatePickerProps,
} from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { parse } from "date-fns";
import { useSelector } from "react-redux";

import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import { fontSize } from "../constants";
import { State } from "@src/store";

class StrictAdapter extends AdapterDateFns {
  public parse = (value: string, formatString: string): any => {
    if (value === "") {
      return null;
    }

    // this only works where we expect zero-prefixed dates
    if (value.length !== formatString.length) {
      return null;
    }
    return parse(value, formatString, new Date());
  };
}

type DesktopDatePickerProps = MuiDesktopDatePickerProps<Date, Date>;

export interface Props
  extends Omit<DesktopDatePickerProps, "renderInput" | "TextFieldProps"> {
  style?: React.CSSProperties;
  error?: boolean;
  helperText?: React.ReactNode;
  onBlur?: () => void;
  onTextChange?: (text: string) => void;
  disableOpenPicker?: boolean;
  placeholder?: string;
}

export default function DatePicker(props: Props): JSX.Element {
  const [isTextFieldEmpty, setIsTextFieldEmpty] = useState(
    props.value === null
  );

  const {
    color: {
      $backgroundSubtle,
      $text,
      $textSubtle,
      $textDisabled,
      $textSuccess,
      $textAccentBlue,
      $textSelected,
      $backgroundInverseSubtle,
      $icon,
      $iconSubtle,
    },
  } = useTheme();

  const { fontRegular } = useSelector((state: State) => ({
    fontRegular: state.main_Reducer.brand.current.fonts.regular,
  }));

  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: fontRegular,
          fontSize: fontSize,
        },
        palette: {
          primary: {
            main: $textSubtle,
          },
          secondary: {
            main: $textSuccess,
          },
          text: {
            primary: $text /* Input Text, Calendar Text and Buttons */,
            secondary: $textSubtle /* Label */,
            disabled: $textDisabled /* Disabled Input Text */,
          },
          action: {
            active: $text /* Calendar Icon */,
            hover: $textAccentBlue,
            selected: $textSelected,
            disabled: $textDisabled /* Disabled Calendar Arrows */,
            disabledBackground: $backgroundInverseSubtle,
          },
          background: {
            default: $backgroundSubtle,
            paper: $backgroundSubtle /* Calendar Background */,
          },
        },
      }),
    [
      fontRegular,
      $backgroundSubtle,
      $text,
      $textSubtle,
      $textDisabled,
      $textSuccess,
      $textAccentBlue,
      $textSelected,
      $backgroundInverseSubtle,
    ]
  );

  const sx = useMemo(() => {
    return {
      ".Mui-focused:after": {
        borderWidth: "1px",
      },
      ".MuiInput-input": {
        padding: "4px 4px 4px 0px",
        marginBottom: "1px",
      },
      ".MuiInput-root": {
        fontSize: "1rem", //prevents strange MUI resizing
      },
      "& .MuiInputBase-root": {
        "&:hover:before": {
          borderBottomWidth: 1,
          borderBottomColor: $textDisabled,
        },
      },
      "& .MuiInput-underline": {
        "&:after": {
          borderBottomWidth: 1,
          borderBottomColor: $textDisabled,
        },
        "&:before": {
          borderBottomWidth: 1,
          borderBottomColor: $textDisabled,
          borderBottomStyle: "solid",
        },
      },
      "& .MuiButtonBase-root": {
        marginRight: "0px",
      },
      "& .MuiSvgIcon-root": {
        width: "18px",
        height: "18px",
        color: props.value === null || isTextFieldEmpty ? $icon : $iconSubtle,
      },
    };
  }, [$icon, $iconSubtle, $textDisabled, isTextFieldEmpty, props.value]);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={StrictAdapter}>
        <FormControl fullWidth={true} sx={{ ...props.style }}>
          {props.label && (
            <FormLabel style={{ fontSize: fontSize }}>{props.label}</FormLabel>
          )}

          <DesktopDatePicker
            {...props}
            label={""}
            InputProps={{ style: { fontSize: fontSize } }}
            inputFormat={props.inputFormat ?? "MM/dd/yyyy"}
            onClose={props.onBlur}
            disableOpenPicker={props.disableOpenPicker}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  ...(props.placeholder !== undefined && {
                    placeholder: props.placeholder,
                  }),
                }}
                sx={sx}
                variant="standard"
                error={props.error}
                helperText={props.helperText}
                onBlur={props.onBlur}
                onChange={(e) => {
                  params.onChange?.(e);
                  props.onTextChange?.(e.target.value);
                  setIsTextFieldEmpty(e.target.value === "");
                }}
              />
            )}
          />
        </FormControl>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
