import React, { useMemo, useState } from "react";

import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { FartherManagedAccount } from "@fartherfinance/frontend/api/Accounts/Types";
import useInvalidatePortfoliosV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useInvalidatePortfoliosV2";
import useAvailableAccounts from "@fartherfinance/frontend/api/TradingGroups/hooks/useAvailableAccounts";
import useTradingGroup from "@fartherfinance/frontend/api/TradingGroups/hooks/useTradingGroup";
import useUpdateTradingGroupV1_5 from "@fartherfinance/frontend/api/TradingGroups/hooks/useUpdateTradingGroupV1_5";
import { TradingGroupAccount } from "@fartherfinance/frontend/api/TradingGroups/requests/getAvailableAccounts";
import { TradingGroup } from "@fartherfinance/frontend/api/TradingGroups/requests/getTradingGroup";
import {
  FartherAccountId,
  HookResult,
  PortfolioId,
  TradingGroupId,
} from "@fartherfinance/frontend/api/Types";

import {
  DI_MIN_BALANCE_TOOLTIP_TEXT,
  EQUIVALENT_SECURITIES_TOOLTIP_TEXT,
  TAX_LOSS_HARVESTING_TOOLTIP_TEXT,
  TRADING_TOGGLE_DISABLED_MSG,
} from "../const";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import LinkButton from "@src/sharedComponents/LinkButton/LinkButton";
import Select from "@src/sharedComponents/Select/Select";
import Switch from "@src/sharedComponents/Switch/Switch";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";
import TooltipBasic from "@src/sharedComponents/Tooltip/TooltipBasic";
import { State } from "@src/store";
import { pluralize } from "@src/utils/pluralize";

import EditableTradingGroupName from "./EditableTradingGroupName";
import AddAccountModal from "./modals/AddAccountModal";
import DirectIndexingModal from "./modals/DirectIndexingModal";
import EnableTaxLossHarvestingModal from "./modals/EnableTaxLossHarvestingModal";
import EquivalentSecuritiesModal from "./modals/EquivalentSecuritiesModal";
import RemoveAccountModal from "./modals/RemoveAccountModal";
import ToggleTradingSwitchModal from "./modals/ToggleTradingSwitchModal";
import TradingGroupsTableLoading from "./TradingGroupsTableLoading";
import { TradingGroupAccountLocal } from "./Types";
import createAccountLabel from "./utils/createAccountLabel";

import styles from "./TradingGroupsTable.module.css";

const isAccountAllowed = (
  account: TradingGroupAccountLocal,
  tradingGroup: TradingGroup,
  accountsInThisTradingGroup: TradingGroupAccount[]
): boolean => {
  if (account.tradingGroupId === tradingGroup.groupId) {
    //This account already exists in this trading group
    return false;
  }

  if (
    //Trading group has an account that cannot be grouped with other accounts
    accountsInThisTradingGroup.some(
      (acc) => acc.multiAccountTradingGroupAllowed === false
    )
  ) {
    return false;
  }

  if (
    //Trading group has one or more accounts - and this account cannot be grouped with other accounts
    tradingGroup.accounts.length > 0 &&
    account.multiAccountTradingGroupAllowed === false
  ) {
    return false;
  }

  return true;
};

interface TradingGroupSettings {
  isActive: boolean;
  directIndexing: boolean;
  taxLossHarvesting: boolean;
  equivalentSecuritiesEnabled: boolean;
}

interface Props {
  tradingGroupId: TradingGroupId;
  portfolioId: PortfolioId;
  fartherAccounts: FartherManagedAccount[];
}

const TradingGroupsTable = (props: Props): JSX.Element => {
  const { clientId } = useSelector((state: State) => ({
    clientId: state.main_Reducer.user.id_user,
  }));

  const [showAddAccountModal, setShowAddAccountModal] =
    useState<boolean>(false);
  const [showRemoveAccountModal, setShowRemoveAccountModal] =
    useState<boolean>(false);
  const [showEquivalentSecuritiesModal, setShowEquivalentSecuritiesModal] =
    useState<boolean>(false);
  const [showDirectIndexingModal, setShowDirectIndexingModal] =
    useState<boolean>(false);
  const [accountIdToAdd, setAccountIdToAdd] = useState<FartherAccountId | null>(
    null
  );
  const [accountIdToRemove, setAccountIdToRemove] =
    useState<FartherAccountId | null>(null);
  const [isMutating, setIsMutating] = useState<boolean>(false);
  const [
    showEnableTaxLossHarvestingModal,
    setShowEnableTaxLossHarvestingModal,
  ] = useState<boolean>(false);
  const [showToggleTradingSwitchModal, setShowToggleTradingSwitchModal] =
    useState<boolean>(false);

  const history = useHistory();

  const statusNotification = useStatusNotification();

  const { enableEquivalentSecurities, enableTaxLossHarvesting } = useFlags();

  const invalidateAllPortfolios = useInvalidatePortfoliosV2();

  const auth = useRequestAuth();
  const tradingGroup = useTradingGroup(props.tradingGroupId, auth);
  const availableAccounts = useAvailableAccounts(auth);
  const { updateTradingGroup, state: updateTradingGroupReqState } =
    useUpdateTradingGroupV1_5(clientId, auth);

  const allAccountsWithAllData: HookResult<TradingGroupAccountLocal[]> =
    useMemo(() => {
      if (availableAccounts.isLoading || availableAccounts.hasError) {
        return availableAccounts;
      }

      const accounts = availableAccounts.data.accounts
        // Filter out joint accounts
        .filter((avAcc) => {
          const isNotAJointAccount = props.fartherAccounts.some(
            (acc) => acc.virtualAccountId === avAcc.accountId
          );
          return isNotAJointAccount;
        })
        .map((avAcc) => {
          const account = props.fartherAccounts.find(
            (acc) => acc.virtualAccountId === avAcc.accountId
          );

          if (account === undefined) {
            const err = new Error(
              `[${TradingGroupsTable.name}] Account with the ID ${avAcc.accountId} could not be found.`
            );

            throw err;
          }

          return {
            ...avAcc,
            ...account.accountDetails,
          };
        });

      return { data: accounts, isLoading: false, hasError: false };
    }, [availableAccounts, props.fartherAccounts]);

  const accountsInTradingGroup: HookResult<TradingGroupAccountLocal[]> =
    useMemo(() => {
      if (tradingGroup.isLoading || tradingGroup.hasError) {
        return tradingGroup;
      }

      if (allAccountsWithAllData.isLoading || allAccountsWithAllData.hasError) {
        return allAccountsWithAllData;
      }

      const accounts = allAccountsWithAllData.data.filter(
        (acct) =>
          tradingGroup.data.accounts.find(
            (a) => a.virtualAccountId === acct.accountId
          ) !== undefined
      );

      return {
        data: accounts,
        isLoading: false,
        hasError: false,
      };
    }, [allAccountsWithAllData, tradingGroup]);

  const allowedAccountsForGroup: HookResult<TradingGroupAccountLocal[]> =
    useMemo(() => {
      if (accountsInTradingGroup.isLoading || accountsInTradingGroup.hasError) {
        return accountsInTradingGroup;
      }

      if (tradingGroup.isLoading || tradingGroup.hasError) {
        return tradingGroup;
      }

      if (allAccountsWithAllData.isLoading || allAccountsWithAllData.hasError) {
        return allAccountsWithAllData;
      }

      const allowed = allAccountsWithAllData.data.filter((acc) =>
        isAccountAllowed(acc, tradingGroup.data, accountsInTradingGroup.data)
      );

      return { data: allowed, isLoading: false, hasError: false };
    }, [accountsInTradingGroup, allAccountsWithAllData, tradingGroup]);

  if (
    tradingGroup.hasError ||
    availableAccounts.hasError ||
    accountsInTradingGroup.hasError ||
    allowedAccountsForGroup.hasError
  ) {
    return <div>Error</div>;
  }

  if (
    tradingGroup.isLoading ||
    availableAccounts.isLoading ||
    accountsInTradingGroup.isLoading ||
    allowedAccountsForGroup.isLoading
  ) {
    return <TradingGroupsTableLoading />;
  }

  const dropdownMenuAccountOptions = allowedAccountsForGroup.data.map((acct) =>
    createAccountLabel(acct)
  );

  const selectableAccountsJSX = accountsInTradingGroup.data.map((acct) => {
    return (
      <div key={acct.accountId} className={styles.optionDiv}>
        <div className={styles.flexDiv}>
          <p className={styles.text}>{acct.displayName}</p>
          <p className={styles.secondaryText}>{acct.custodianAccountNumber}</p>
        </div>

        <RemoveCircleOutlineOutlinedIcon
          className={styles.removeIcon}
          onClick={() => {
            setAccountIdToRemove(acct.accountId);
            setShowRemoveAccountModal(true);
          }}
        />
      </div>
    );
  });

  const noSelectableAccountsJSX = (
    <div className={styles.optionDiv}>
      <p className={styles.noAccountsText}>No accounts</p>
    </div>
  );

  const accountsInTradingGroupText = pluralize(
    accountsInTradingGroup.data,
    "Account",
    true
  );

  const accountsInTradingGroupModalText = pluralize(
    accountsInTradingGroup.data.length + 1,
    "Account",
    true
  );

  const accountToAdd = allowedAccountsForGroup.data.find(
    (acct) => acct.accountId === accountIdToAdd
  );

  const accountToRemove = accountsInTradingGroup.data.find(
    (acct) => acct.accountId === accountIdToRemove
  );

  const directIndexingEnabled =
    tradingGroup.data.changesAllowed &&
    (tradingGroup.data.directIndexingAllowed ||
      tradingGroup.data.directIndexing);

  const taxLossHarvestingEnabled =
    tradingGroup.data.changesAllowed &&
    tradingGroup.data.taxLossHarvestingAllowed &&
    enableTaxLossHarvesting; //Global switch

  const callUpdateTradingGroup = async (
    newSettings: TradingGroupSettings,
    toggledEquivalentSecurities?: boolean
  ) => {
    if (updateTradingGroupReqState !== "Ready") {
      statusNotification("Please wait a few seconds for data to load", "Error");
      return;
    }

    const body = {
      displayName: tradingGroup.data.displayName,
      accounts: [
        ...tradingGroup.data.accounts.map((tgAcct) => tgAcct.virtualAccountId),
      ],
      portfolioId: props.portfolioId,
      isActive: newSettings.isActive,
      directIndexing: newSettings.directIndexing,
      taxLossHarvesting: newSettings.taxLossHarvesting,
      equivalentSecuritiesEnabled: newSettings.equivalentSecuritiesEnabled,
    };

    try {
      setIsMutating(true);
      await updateTradingGroup({
        tradingGroupId: props.tradingGroupId,
        body: body,
      });
      statusNotification("Trading group updated", "Success");
    } catch (e) {
      console.error(e);
      statusNotification("Error updating trading group", "Error");
    } finally {
      setIsMutating(false);
      setShowDirectIndexingModal(false);
      setShowEnableTaxLossHarvestingModal(false);
      setShowToggleTradingSwitchModal(false);
      setShowEquivalentSecuritiesModal(false);

      if (toggledEquivalentSecurities && clientId) {
        invalidateAllPortfolios(clientId, props.portfolioId);
        history.push({
          pathname: `/Client/${clientId}/Investments/Portfolios`,
          state: history.location.state,
        });
      }
    }
  };

  const addAccountToTradingGroup = async (
    tradingGroupId: TradingGroupId,
    accountId: FartherAccountId
  ) => {
    if (updateTradingGroupReqState !== "Ready") {
      statusNotification("Please wait a few seconds for data to load", "Error");
      return;
    }

    const accountIdsInTradingGroup = tradingGroup.data.accounts.map(
      (tgAcct) => tgAcct.virtualAccountId
    );
    if (accountIdsInTradingGroup === undefined) {
      console.error("Could not find accountIds for current trading group");
      return;
    }

    const body = {
      displayName: tradingGroup.data.displayName,
      accounts: [...accountIdsInTradingGroup, accountId],
      portfolioId: props.portfolioId,
      isActive: tradingGroup.data.isActive,
      directIndexing: tradingGroup.data.directIndexing,
      taxLossHarvesting: tradingGroup.data.taxLossHarvesting,
      equivalentSecuritiesEnabled:
        tradingGroup.data.equivalentSecuritiesEnabled,
    };

    try {
      setIsMutating(true);
      await updateTradingGroup({
        tradingGroupId: tradingGroupId,
        body: body,
      });
      statusNotification("Account added", "Success");
    } catch (e) {
      console.error(e);
      statusNotification("Error adding account", "Error");
    } finally {
      setIsMutating(false);
      setShowAddAccountModal(false);
    }
  };

  const removeAccountFromTradingGroup = async (
    tradingGroupId: TradingGroupId,
    accountId: FartherAccountId
  ) => {
    if (updateTradingGroupReqState !== "Ready") {
      statusNotification("Please wait a few seconds for data to load", "Error");
      return;
    }

    const accountIdsToBeInTradingGroup = tradingGroup.data.accounts
      .map((tgAcct) => tgAcct.virtualAccountId)
      .filter((acctId) => acctId !== accountId);
    if (accountIdsToBeInTradingGroup === undefined) {
      console.error("Could not find accountIds for current trading group");
      return;
    }

    const body = {
      displayName: tradingGroup.data.displayName,
      accounts: [...accountIdsToBeInTradingGroup],
      portfolioId: props.portfolioId,
      isActive: tradingGroup.data.isActive,
      directIndexing: tradingGroup.data.directIndexing,
      taxLossHarvesting: tradingGroup.data.taxLossHarvesting,
      equivalentSecuritiesEnabled:
        tradingGroup.data.equivalentSecuritiesEnabled,
    };

    try {
      setIsMutating(true);
      await updateTradingGroup({
        tradingGroupId: tradingGroupId,
        body: body,
      });
      statusNotification("Account removed", "Success");
    } catch (e) {
      console.error(e);
      statusNotification("Error removing account", "Error");
    } finally {
      setIsMutating(false);
      setShowRemoveAccountModal(false);
    }
  };

  return (
    <>
      {showAddAccountModal && accountToAdd !== undefined && (
        <AddAccountModal
          closeModal={() => setShowAddAccountModal(false)}
          onAdd={() => {
            if (accountIdToAdd === null) {
              return;
            }
            addAccountToTradingGroup(props.tradingGroupId, accountIdToAdd);
          }}
          tradingGroupName={tradingGroup.data.displayName}
          accountsInTradingGroupText={accountsInTradingGroupModalText}
          accountsInTradingGroup={accountsInTradingGroup.data}
          accountToAdd={accountToAdd}
          isMutating={isMutating}
        />
      )}

      {showDirectIndexingModal && (
        <DirectIndexingModal
          closeModal={() => setShowDirectIndexingModal(false)}
          onAdd={() => {
            callUpdateTradingGroup({
              isActive: tradingGroup.data.isActive,
              directIndexing: false,
              taxLossHarvesting: tradingGroup.data.taxLossHarvesting,
              equivalentSecuritiesEnabled:
                tradingGroup.data.equivalentSecuritiesEnabled,
            });
          }}
          tradingGroupName={tradingGroup.data.displayName}
          isMutating={isMutating}
        />
      )}

      {showRemoveAccountModal && accountToRemove !== undefined && (
        <RemoveAccountModal
          closeModal={() => setShowRemoveAccountModal(false)}
          onRemove={() => {
            if (accountIdToRemove === null) {
              return;
            }
            removeAccountFromTradingGroup(
              props.tradingGroupId,
              accountIdToRemove
            );
          }}
          tradingGroupName={tradingGroup.data.displayName}
          accountToRemove={accountToRemove}
          isMutating={isMutating}
        />
      )}

      {showEnableTaxLossHarvestingModal && (
        <EnableTaxLossHarvestingModal
          onConfirm={() =>
            callUpdateTradingGroup({
              isActive: tradingGroup.data.isActive,
              directIndexing: tradingGroup.data.directIndexing,
              taxLossHarvesting: !tradingGroup.data.taxLossHarvesting,
              equivalentSecuritiesEnabled:
                tradingGroup.data.equivalentSecuritiesEnabled,
            })
          }
          isMutating={isMutating}
          closeModal={() => setShowEnableTaxLossHarvestingModal(false)}
        />
      )}

      {showToggleTradingSwitchModal && (
        <ToggleTradingSwitchModal
          onConfirm={() =>
            callUpdateTradingGroup({
              isActive: !tradingGroup.data.isActive,
              directIndexing: tradingGroup.data.directIndexing,
              taxLossHarvesting: tradingGroup.data.taxLossHarvesting,
              equivalentSecuritiesEnabled:
                tradingGroup.data.equivalentSecuritiesEnabled,
            })
          }
          accountsInTradingGroup={accountsInTradingGroup.data}
          isMutating={isMutating}
          closeModal={() => setShowToggleTradingSwitchModal(false)}
          toggleOnToOff={tradingGroup.data.isActive}
        />
      )}

      {showEquivalentSecuritiesModal && (
        <EquivalentSecuritiesModal
          closeModal={() => setShowEquivalentSecuritiesModal(false)}
          isActivating={!tradingGroup.data.equivalentSecuritiesEnabled}
          isMutating={isMutating}
          onConfirm={() =>
            callUpdateTradingGroup(
              {
                isActive: tradingGroup.data.isActive,
                directIndexing: tradingGroup.data.directIndexing,
                taxLossHarvesting: tradingGroup.data.taxLossHarvesting,
                equivalentSecuritiesEnabled:
                  !tradingGroup.data.equivalentSecuritiesEnabled,
              },
              true // extra optional param: lets callUpdateTradingGroup know an update ocurred bc of equivalentSecuritiesEnabled switch toggle so will call an extra invalidation for all portfolios queries (the current portfolio, all essential and all custom linked to the client)
            )
          }
        />
      )}

      <div className={styles.container}>
        <div className={styles.listHeaderDiv}>
          <div className={styles.flexDiv}>
            <EditableTradingGroupName
              clientId={clientId}
              portfolioId={props.portfolioId}
              tradingGroupId={props.tradingGroupId}
            />

            <p className={styles.secondaryHeaderText}>
              {accountsInTradingGroupText}
            </p>
          </div>

          {tradingGroup.data.addAccountAllowed && (
            <Select
              value={"Add Accounts"}
              options={
                dropdownMenuAccountOptions.length > 0
                  ? dropdownMenuAccountOptions
                  : ["No Available Accounts"]
              }
              onChange={(name) => {
                if (dropdownMenuAccountOptions.length <= 0) {
                  return;
                }

                const chosen = allowedAccountsForGroup.data.find(
                  (acct) => createAccountLabel(acct) === name
                );

                if (chosen === undefined) {
                  return;
                }

                setAccountIdToAdd(chosen.accountId);
                setShowAddAccountModal(true);
              }}
              style={{ width: "200px" }}
              renderValue={(value) => value}
            />
          )}
        </div>

        <div className={styles.optionsDiv}>
          <div className={styles.half_width}>
            <p className={styles.headingText}>Accounts</p>
          </div>

          <div className={styles.divRight}>
            {accountsInTradingGroup.data.length > 0
              ? selectableAccountsJSX
              : noSelectableAccountsJSX}
          </div>
        </div>

        <div className={styles.settingsDiv}>
          <div className={styles.half_width}>
            <p className={styles.headingText}>Trading group Settings</p>
          </div>

          <div className={styles.half_width}>
            <div className={styles.flexApart}>
              <div className={styles.flexDiv}>
                <p
                  className={
                    tradingGroup.data.changesAllowed
                      ? styles.text
                      : styles.textDisabled
                  }
                >
                  Trading
                </p>

                {!tradingGroup.data.changesAllowed && (
                  <TooltipBasic
                    type={
                      tradingGroup.data.changesAllowed ? "primary" : "secondary"
                    }
                    text={TRADING_TOGGLE_DISABLED_MSG}
                  />
                )}
              </div>

              <Tooltip
                disableHoverListener={tradingGroup.data.changesAllowed}
                tooltipText={TRADING_TOGGLE_DISABLED_MSG}
                placement="left"
              >
                <span>
                  <Switch
                    disabled={!tradingGroup.data.changesAllowed}
                    on={tradingGroup.data.isActive}
                    onClick={() => setShowToggleTradingSwitchModal(true)}
                  />
                </span>
              </Tooltip>
            </div>

            <div className={styles.flexApart}>
              <div className={styles.flexDiv}>
                <p
                  className={
                    taxLossHarvestingEnabled ? styles.text : styles.textDisabled
                  }
                >
                  Tax-loss harvesting
                </p>

                <TooltipBasic
                  type={taxLossHarvestingEnabled ? "primary" : "secondary"}
                  text={TAX_LOSS_HARVESTING_TOOLTIP_TEXT}
                />
              </div>

              <Switch
                disabled={!taxLossHarvestingEnabled}
                on={tradingGroup.data.taxLossHarvesting}
                onClick={() => {
                  if (tradingGroup.data.taxLossHarvesting) {
                    callUpdateTradingGroup({
                      isActive: tradingGroup.data.isActive,
                      directIndexing: tradingGroup.data.directIndexing,
                      taxLossHarvesting: !tradingGroup.data.taxLossHarvesting,
                      equivalentSecuritiesEnabled:
                        tradingGroup.data.equivalentSecuritiesEnabled,
                    });
                  } else {
                    setShowEnableTaxLossHarvestingModal(true);
                  }
                }}
              />
            </div>

            <div className={styles.flexApart}>
              <div className={styles.flexDiv}>
                <p
                  className={
                    directIndexingEnabled ? styles.text : styles.textDisabled
                  }
                >
                  Direct Indexing
                </p>

                <TooltipBasic
                  type={directIndexingEnabled ? "primary" : "secondary"}
                  text={DI_MIN_BALANCE_TOOLTIP_TEXT}
                />
              </div>

              <Tooltip
                tooltipText={
                  tradingGroup.data.equivalentSecuritiesEnabled
                    ? "To modify your direct indexing settings, please remove the accounts from this trading group, then re-select your Model Portfolio."
                    : ""
                }
                placement={"left"}
              >
                <div>
                  <Switch
                    disabled={!directIndexingEnabled}
                    on={tradingGroup.data.directIndexing}
                    onClick={() => {
                      if (tradingGroup.data.directIndexing) {
                        setShowDirectIndexingModal(true);
                      } else {
                        callUpdateTradingGroup({
                          isActive: tradingGroup.data.isActive,
                          directIndexing: true,
                          taxLossHarvesting:
                            tradingGroup.data.taxLossHarvesting,
                          equivalentSecuritiesEnabled:
                            tradingGroup.data.equivalentSecuritiesEnabled,
                        });
                      }
                    }}
                  />
                </div>
              </Tooltip>
            </div>

            {enableEquivalentSecurities && (
              <div className={styles.flexApart}>
                <div className={styles.flexDiv}>
                  <LinkButton
                    text={"Equivalent Securities"}
                    isCustom={true}
                    to={
                      clientId
                        ? `/Client/${clientId}/Investments/Portfolios/${props.portfolioId}/Accounts/EquivalentSecurities`
                        : "/Client"
                    }
                  />

                  <TooltipBasic
                    type={"primary"}
                    text={EQUIVALENT_SECURITIES_TOOLTIP_TEXT}
                  />
                </div>

                <Switch
                  on={tradingGroup.data.equivalentSecuritiesEnabled}
                  onClick={() => setShowEquivalentSecuritiesModal(true)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TradingGroupsTable;
