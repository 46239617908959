import React, { useMemo } from "react";

import { useParams } from "react-router-dom";

import useClientAccounts from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAccounts";
import useInvitations from "@fartherfinance/frontend/api/Sharing/hooks/useInvitations";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

import EmptyCollaboratorsTable from "./CollaboratorsTable/Empty/EmptyCollaboratorsTable";
import CollaboratorsTable from "./CollaboratorsTable/Table/CollaboratorsTable";
import SharedWithYouTable from "./SharedWithYouTable/SharedWithYouTable";

import styles from "./Sharing.module.css";

const Sharing = (): JSX.Element => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const auth = useRequestAuth();
  const invitations = useInvitations(auth);
  const _ = useClientAccounts(clientId, auth); // pre-load client's accounts

  const { collaborators, sharedWithYou } = useMemo(() => {
    if (invitations.isLoading || invitations.hasError) {
      return {
        collaborators: [],
        sharedWithYou: [],
      };
    }

    return {
      collaborators: invitations.data.sent,
      sharedWithYou: invitations.data.received,
    };
  }, [invitations]);

  if (invitations.hasError) {
    return <div>Error retrieving invitations</div>;
  }

  if (invitations.isLoading) {
    return (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.loadingContainer}>
            <LogoLoadingStill />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.heading}>Your Collaborators</div>

        <div className={styles.subHeading}>
          Share access with family members and service professionals (e.g., your
          accountant) by giving them full or partial access.
        </div>

        {collaborators.length === 0 ? (
          <EmptyCollaboratorsTable />
        ) : (
          <CollaboratorsTable invitations={collaborators} />
        )}

        {sharedWithYou.length > 0 && (
          <SharedWithYouTable invitations={sharedWithYou} />
        )}
      </div>
    </div>
  );
};

export default Sharing;
