import React, { useMemo, useState } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { accessLevelsForDisplay } from "../../../constants";
import { AccessLevelForDisplay } from "../../../Types";
import AccessLevelInfoNote from "../../shared/AccessLevelInfoNote/AccessLevelInfoNote";
import InfoModal from "../../shared/InfoModal/InfoModal";
import SelectResourcesToShare from "../../shared/SelectResources/SelectResourcesToShare";
import { actions } from "../reducer/actions";
import { useAddCollaboratorContext } from "../reducer/Context";
import {
  type Relationship,
  relationshipOptions,
} from "@src/constants/relationship";
import {
  serviceProfessionalOptions,
  ServiceProfessionalTypeOption,
} from "@src/constants/serviceProffesional";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";
import BorderBoxBody from "@src/sharedComponents/BorderBox/BorderBoxBody";
import HeaderWithSubHeader from "@src/sharedComponents/HeaderWithSubHeader/HeaderWithSubHeader";
import Dropdown, {
  AutocompleteOption,
} from "@src/sharedComponents/SAODropdown/Dropdown";

import AddCollaboratorSelectResourcesFormStateAndDispatcher from "./SelectResourcesStateAndDispatcher/FormStateAndDispatcher";

import styles from "./CollaboratorTypeAndAccessLevel.module.css";

const CollaboratorTypeAndAccessLevel: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const { state, dispatch } = useAddCollaboratorContext();

  const {
    data: { collaboratorType, accessLevel, serviceProfessionalType },
  } = state;

  const handleType = (e: AutocompleteOption) => {
    if (relationshipOptions.includes(e.label as Relationship)) {
      dispatch({
        type: actions.SET_COLLABORATOR_TYPE,
        payload: e.label as Relationship,
      });
    } else {
      dispatch({
        type: actions.SET_SERVICE_PRO_TYPE,
        payload: e.label as ServiceProfessionalTypeOption,
      });
    }
  };

  const handleAccess = (e: AutocompleteOption) => {
    const accessLevel = e.label as AccessLevelForDisplay;

    dispatch({
      type: actions.SET_ACCESS_LEVEL,
      payload: accessLevel,
    });

    if (accessLevel === "All-Access") {
      dispatch({ type: actions.REMOVE_ALL_RESOURCES, payload: undefined });
    }
  };

  const dropdownOptions = [
    ...relationshipOptions,
    ...serviceProfessionalOptions,
  ];

  const dropdownValue = useMemo(() => {
    if (collaboratorType) {
      return { label: collaboratorType };
    } else if (serviceProfessionalType) {
      return { label: serviceProfessionalType };
    }
    return null;
  }, [collaboratorType, serviceProfessionalType]);

  return (
    <div className={styles.container}>
      {showModal && <InfoModal hideModal={() => setShowModal(false)} />}

      <div className={styles.inner}>
        <HeaderWithSubHeader
          className={styles.headerWithSubHeader}
          header={"Add Type and Access"}
          subHeader={
            "Choose the collaborator's type and access level. You can always change this later."
          }
        />

        <BorderBox className={styles.borderBox}>
          <BorderBoxBody className={styles.borderBoxBody} showTopBorder={false}>
            <div className={styles.dropdownsContainer}>
              <Dropdown
                disableSearch={true}
                groupBy={(option) => {
                  if (
                    relationshipOptions.includes(option.label as Relationship)
                  ) {
                    return "Family Member";
                  }
                  return "Service Pro";
                }}
                formLabel="Collaborator Type"
                value={dropdownValue}
                onChange={handleType}
                values={dropdownOptions.map((t) => ({ label: t }))}
              />

              <Dropdown
                disableSearch={true}
                formLabel={
                  <div>
                    Access
                    <InfoOutlinedIcon
                      className={styles.infoIcon}
                      onClick={() => setShowModal(true)}
                    />
                  </div>
                }
                value={
                  accessLevel
                    ? {
                        label: accessLevel,
                      }
                    : null
                }
                onChange={handleAccess}
                values={accessLevelsForDisplay
                  .filter((al) => al !== "Revoke")
                  .map((l) => ({
                    label: l,
                  }))}
              />
            </div>

            <AccessLevelInfoNote accessLevel={accessLevel} />
          </BorderBoxBody>
        </BorderBox>

        {accessLevel === "Limited Access" && (
          <SelectResourcesToShare>
            <AddCollaboratorSelectResourcesFormStateAndDispatcher />
          </SelectResourcesToShare>
        )}
      </div>
    </div>
  );
};

export default CollaboratorTypeAndAccessLevel;
