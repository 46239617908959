import React, { useState } from "react";

import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import useUpdateAltAssetName from "@fartherfinance/frontend/api/Accounts/hooks/useUpdateAltAccountName";
import { AltAssetId, ClientId } from "@fartherfinance/frontend/api/Types";

import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

import sharedStyles from "./SharedStyles.module.css";
import styles from "../ManualAssetDrawer.module.css";

const AssetName = "AssetName";

interface Form {
  [AssetName]: string;
}

interface Props {
  currentAssetName: string;
  closeModal: () => void;
}

const RenameAccountForm = (props: Props): JSX.Element => {
  const [mutating, setMutating] = useState(false);

  const { clientId, accountId } = useParams<{
    clientId: ClientId;
    accountId: AltAssetId;
  }>();

  const auth = useRequestAuth();

  const statusNotification = useStatusNotification();

  const updateAltAssetName = useUpdateAltAssetName(clientId, auth);

  const methods = useForm<Form>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      [AssetName]: props.currentAssetName,
    },
  });

  const {
    getValues,
    formState: { isValid, isDirty },
  } = methods;

  const isDisabled = !isValid || !isDirty || mutating;

  const submit = async () => {
    if (!isValid || !isDirty) {
      statusNotification("The form is incomplete", "Error");

      return;
    }

    if (mutating) {
      statusNotification("Form is already being submitted", "Error");

      return;
    }

    const values = getValues();

    try {
      setMutating(true);

      await updateAltAssetName({
        assetId: accountId,
        assetName: values[AssetName],
      });

      statusNotification("Account name updated", "Success");

      props.closeModal();
    } catch (e) {
      console.error(e);

      statusNotification("Failed to update account name", "Error");
    } finally {
      setMutating(false);
    }
  };

  return (
    <>
      {mutating && (
        <div className={sharedStyles.loading}>
          <LogoLoadingStill />
        </div>
      )}

      <FormProvider {...methods}>
        <FormH1>Rename Account</FormH1>

        <Spacer verticalSpacing="30px" />

        <FormTextField
          name="AssetName"
          label="Account Name"
          required="Must not be empty"
          rules={{
            maxLength: {
              value: 100,
              message: "Must not exceed 100 characters",
            },
          }}
        />
      </FormProvider>

      <Spacer verticalSpacing="20px" />

      <div className={styles.modal__yes_no_div}>
        <ButtonPrimary
          variant={"outlined"}
          buttonType={"secondary"}
          text={"Cancel"}
          onClick={props.closeModal}
        />

        <ButtonPrimary
          disabled={isDisabled}
          variant={"contained"}
          buttonType={"primary"}
          text={"Confirm"}
          onClick={submit}
          style={{ marginLeft: "25px" }}
        />
      </div>
    </>
  );
};

export default RenameAccountForm;
