import React, { useEffect, useMemo, useState } from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { Divider, InputAdornment, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import { orderBy } from "lodash";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import useAllAccounts from "@fartherfinance/frontend/api/Accounts/hooks/useAllAccounts";
import useDeleteFolderDocument from "@fartherfinance/frontend/api/CustodianDoc/hooks/useDeleteFolderDocument";
import useGetDocuments from "@fartherfinance/frontend/api/CustodianDoc/hooks/useGetDocuments";
import useUploadClientDocument from "@fartherfinance/frontend/api/CustodianDoc/hooks/useUploadClientDocument";
import { DocFile } from "@fartherfinance/frontend/api/CustodianDoc/requests/getClientDocuments";
import getDocumentUrl from "@fartherfinance/frontend/api/CustodianDoc/requests/getDocumentUrl";
import useClientDashboard, {
  ClientId,
} from "@fartherfinance/frontend/api/Dashboard/hooks/useClientDashboard";
import useAdvisorClients from "@fartherfinance/frontend/api/Entity/hooks/useAdvisorClients";
import useClientProfile from "@fartherfinance/frontend/api/Entity/hooks/useClientProfile";
import useUpdateClientDefaultCustodian from "@fartherfinance/frontend/api/Entity/hooks/useUpdateClientDefaultCustodian";
import useAllAccountBalancesV4 from "@fartherfinance/frontend/api/PerformanceGroups/hooks/useAllAccountBalancesV4";
import {
  Custodian,
  custodianOptions,
  HookResult,
} from "@fartherfinance/frontend/api/Types";

import formatPhoneNumber from "../Forms/utils/formatPhoneNumber";
import {
  getExternalPlaidAccountBalance,
  isPlaidAccount,
} from "@src/multiCustodian/components/Client/Accounts/AccountsAccordions/externalAccountHelpers";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import copyToClipboard from "@src/multiCustodian/utils/copyToClipboard";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormH2 from "@src/sharedComponents/Forms/FormH2";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import IconButton from "@src/sharedComponents/IconButton/IconButton";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import RadioButtons from "@src/sharedComponents/Radio/RadioButtons";
import Dropdown from "@src/sharedComponents/SAODropdown/Dropdown";
import FileDropUpload from "@src/sharedComponents/SingleFileDropUpload/SingleFileDropUpload";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import TextInput from "@src/sharedComponents/TextInput/TextInput";
import { State } from "@src/store";

import ClientDrawer from "./ClientDrawer";

import styles from "./ClientProfile.module.css";

const dateFormat = "MM/dd/yyyy";
const yesNo = ["Yes", "No"] as const;
const clientIdPathPrefix = /client_id\/\d+\//;

export default function ProfileV4() {
  const { clientId } = useParams<{ clientId: ClientId }>();

  const [isMutating, setIsMutating] = useState(false);

  const [currentAgreementsFile, setCurrentAgreementsFile] =
    useState<DocFile | null>(null);

  const [newAgreementsFile, setNewAgreementsFile] = useState<File | null>(null);

  const [hasSignedAgreements, setHasSignedAgreements] = useState(false);

  const [defaultCustodian, setDefaultCustodian] = useState<Custodian | null>(
    null
  );

  const history = useHistory();

  const auth = useAdvisorRequestAuth();

  const clientAuth = auth !== null ? { ...auth, clientId: clientId } : null;

  const clientDashboard = useClientDashboard(clientId, clientAuth);

  const allAccounts = useAllAccounts(clientId, clientAuth);

  const clientProfile = useClientProfile(clientId, clientAuth);

  const clientDocuments = useGetDocuments(clientId, clientAuth);

  const callUploadClientDocument = useUploadClientDocument(auth);

  const callDeleteClientDocument = useDeleteFolderDocument(clientAuth);

  const statusNotification = useStatusNotification();

  const advisorClients = useAdvisorClients(undefined, auth);

  const accountBalancesNoShared = useAllAccountBalancesV4(
    clientId,
    true,
    clientAuth
  );

  const updateClientCustodian = useUpdateClientDefaultCustodian(auth);

  useEffect(() => {
    if (
      defaultCustodian !== null ||
      clientDashboard.isLoading ||
      clientDashboard.hasError
    ) {
      return;
    }

    setDefaultCustodian(clientDashboard.data.defaultCustodian);
  }, [
    clientDashboard.data?.defaultCustodian,
    clientDashboard.hasError,
    clientDashboard.isLoading,
    defaultCustodian,
  ]);

  const fartherAccountsTotalBalance: HookResult<number> = useMemo(() => {
    if (accountBalancesNoShared.isLoading || accountBalancesNoShared.hasError) {
      return accountBalancesNoShared;
    }

    const totalBalance = accountBalancesNoShared.data
      .map((b) => b.balance)
      .reduce<number>((accum, curr) => accum + (curr ?? 0), 0);

    return {
      data: totalBalance,
      isLoading: false,
      hasError: false,
    };
  }, [accountBalancesNoShared]);

  const netWorth: HookResult<number> = useMemo(() => {
    if (allAccounts.isLoading || allAccounts.hasError) {
      return allAccounts;
    }

    if (
      fartherAccountsTotalBalance.isLoading ||
      fartherAccountsTotalBalance.hasError
    ) {
      return fartherAccountsTotalBalance;
    }

    return {
      isLoading: false,
      hasError: false,
      data: [
        fartherAccountsTotalBalance.data,
        ...(allAccounts.data.manuallyTrackedAccounts ?? []).map(
          (a) => a.assetValue ?? 0
        ),
        ...(allAccounts.data.externalAccounts ?? []).map((a) =>
          isPlaidAccount(a) ? getExternalPlaidAccountBalance(a) ?? 0 : 0
        ),
      ].reduce((prev, curr) => prev + curr, 0),
    };
  }, [allAccounts, fartherAccountsTotalBalance]);

  useEffect(() => {
    const fartherRecords = clientDocuments.data?.FartherRecords ?? [];
    const customAgreement = fartherRecords.find(
      (document) => document.parentDirectory === "Custom%20Agreements"
    );

    if (customAgreement) {
      setHasSignedAgreements(true);
      setCurrentAgreementsFile(customAgreement);
    }

    if (!customAgreement && currentAgreementsFile) {
      setHasSignedAgreements(false);
      setCurrentAgreementsFile(null);
    }
  }, [clientDocuments, currentAgreementsFile]);

  const handleUploadClientDocument = async (): Promise<void> => {
    if (!newAgreementsFile) {
      return;
    }

    try {
      setIsMutating(true);
      await callUploadClientDocument({
        clientId,
        document: newAgreementsFile,
        documentData: {
          path: "Farther%20Records/Signed%20Documents/Custom%20Agreements",
          agreementTemplate: "Custom Client Agreements (IAA, CRS, ADV)",
          signatureType: "eSignature",
        },
      });
      statusNotification("Document uploaded successfully", "Success");
      setNewAgreementsFile(null);
    } catch {
      statusNotification("Failed to upload document", "Error");
    } finally {
      setIsMutating(false);
    }
  };

  const handleRemoveClientDocument = async (): Promise<void> => {
    if (!currentAgreementsFile) {
      return;
    }

    const documentPath = currentAgreementsFile.path.replace(
      clientIdPathPrefix,
      ""
    );

    try {
      setIsMutating(true);
      await callDeleteClientDocument(documentPath);
      statusNotification("Document removed successfully", "Success");
    } catch {
      statusNotification("Failed to remove document", "Error");
    } finally {
      setIsMutating(false);
    }
  };

  const handleDownloadClientDocument = async (): Promise<void> => {
    if (!currentAgreementsFile || !clientAuth) {
      return;
    }

    const documentPath = currentAgreementsFile.path.replace(
      clientIdPathPrefix,
      ""
    );

    try {
      setIsMutating(true);
      const { URL } = await getDocumentUrl(documentPath, clientAuth);
      const a = document.createElement("a");

      a.href = URL;
      a.click();
    } catch {
      statusNotification("Failed to download document", "Error");
    } finally {
      setIsMutating(false);
    }
  };

  const callUpdateClientCustodian = async () => {
    if (
      defaultCustodian === null ||
      defaultCustodian === clientDashboard.data?.defaultCustodian
    ) {
      return;
    }

    try {
      setIsMutating(true);

      await updateClientCustodian({ clientId, custodian: defaultCustodian });

      statusNotification("Custodian updated", "Success");
    } catch {
      statusNotification("Failed to update custodian", "Success");
    } finally {
      setIsMutating(false);
    }
  };

  const handleSaveChanges = async (): Promise<void> => {
    if (currentAgreementsFile === null) {
      handleUploadClientDocument();
    } else {
      handleRemoveClientDocument();
    }

    callUpdateClientCustodian();
  };

  const isLoading =
    advisorClients.isLoading ||
    allAccounts.isLoading ||
    clientDocuments.isLoading;

  const isError =
    advisorClients.hasError || allAccounts.hasError || clientDocuments.hasError;

  if (isLoading) {
    return (
      <ClientDrawer curTab={"Profile"}>
        <div className={styles.loading}>
          <LogoLoadingStill />
        </div>
      </ClientDrawer>
    );
  }

  if (isError) {
    return (
      <ClientDrawer curTab={"Profile"}>
        <div className={styles.error}>Error</div>
      </ClientDrawer>
    );
  }

  const currentClient =
    advisorClients.data.clients.find(
      (client) => client.clientId === clientId
    ) ?? null;

  if (currentClient === null) {
    return (
      <ClientDrawer curTab={"Profile"}>
        <div className={styles.error}>Client not found</div>
      </ClientDrawer>
    );
  }

  const created = currentClient.registeredTime
    ? format(currentClient.registeredTime, dateFormat)
    : null;

  const recentLogin =
    currentClient.lastLoginTime !== null
      ? format(currentClient.lastLoginTime, "MMMM d, yyyy h:mm a")
      : "None";

  const email = currentClient.emailAddress;

  const phone = currentClient.phoneNumber
    ? formatPhoneNumber(currentClient.phoneNumber)
    : "None";

  const netWorthNumber = netWorth.data;

  const enabledStates =
    (hasSignedAgreements && newAgreementsFile !== null) ||
    (!hasSignedAgreements && currentAgreementsFile !== null);

  const canUpdateCustodian =
    defaultCustodian !== clientDashboard.data?.defaultCustodian &&
    defaultCustodian !== null;

  const submitDisabled =
    enabledStates === false && canUpdateCustodian === false;

  return (
    <ClientDrawer
      curTab={"Profile"}
      footer={
        <Footer
          onClose={() =>
            history.push({
              pathname: "/Advisor/Clients",
              search: location.search,
            })
          }
          onSave={handleSaveChanges}
          disabled={submitDisabled}
        />
      }
    >
      <Spacer />

      <FormH2>Contact</FormH2>

      <FlexWrapper>
        <TextInput
          value={email}
          label="Email"
          disabled={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Copy textToCopy={email} textType="email" />
              </InputAdornment>
            ),
          }}
        />

        <TextInput
          value={phone}
          label="Mobile"
          disabled={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Copy textToCopy={phone} textType="mobile" />
              </InputAdornment>
            ),
          }}
        />

        {clientProfile.isLoading ? (
          <Skeleton width={"50%"} height={70} />
        ) : (
          <TextInput
            value={clientProfile.data?.investorProfile.address?.state ?? "None"}
            label="State of Residence"
            disabled={true}
          />
        )}
      </FlexWrapper>

      <Spacer />

      <FormH2>Account</FormH2>

      <FlexWrapper>
        <TextInput
          value={clientId}
          label="ID"
          disabled={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Copy textToCopy={clientId} textType="client ID" />
              </InputAdornment>
            ),
          }}
        />

        {clientDashboard.isLoading ? (
          <Skeleton width={"50%"} height={100} />
        ) : (
          <TextInput
            value={
              clientDashboard.data !== undefined
                ? `${clientDashboard.data.advisor.name.first} ${clientDashboard.data.advisor.name.last}`
                : "N/A"
            }
            label="Advisor"
            disabled={true}
          />
        )}

        <TextInput value={created} label="Created" disabled={true} />

        <TextInput
          value={recentLogin}
          label="Most Recent Log In"
          disabled={true}
        />

        {netWorth.isLoading ? (
          <Skeleton width={"50%"} height={70} />
        ) : (
          <TextInput
            value={
              netWorthNumber !== undefined
                ? netWorthNumber.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : "N/A"
            }
            label="Net Worth"
            disabled={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        )}

        {clientDashboard.isLoading ? (
          <Skeleton width={"50%"} height={70} />
        ) : (
          <Dropdown
            formLabel={"Custodian"}
            value={defaultCustodian ? { label: defaultCustodian } : null}
            onChange={(c) => setDefaultCustodian(c.label)}
            disableSearch
            values={orderBy(
              custodianOptions,
              (c) => c.toLowerCase(),
              "asc"
            ).map((c) => ({ label: c }))}
          />
        )}
      </FlexWrapper>

      <Stack gap="8px" marginTop="38px">
        <FormH2>Client Agreements</FormH2>
        <RadioButtons
          label="Has the client already signed an IAA, ADV and CRS off of the Farther portal?"
          values={yesNo}
          value={hasSignedAgreements ? "Yes" : "No"}
          onChange={(value) => setHasSignedAgreements(value === "Yes")}
          isValid={undefined}
        />
      </Stack>

      {hasSignedAgreements && (
        <div className={styles.agreementsContainer}>
          {!currentAgreementsFile && (
            <Stack className={styles.uploadArea} gap="8px" marginTop="16px">
              <Typography className={styles.uploadText}>
                Upload a copy of the agreements combined into one file
              </Typography>
              <FileDropUpload
                file={newAgreementsFile}
                text="Drop your file here or"
                pressText="browse"
                onChange={setNewAgreementsFile}
                acceptedFileExtensions={[
                  ".pdf",
                  ".png",
                  ".jpg",
                  ".jpeg",
                  ".heic",
                ]}
              />
            </Stack>
          )}

          {currentAgreementsFile && (
            <>
              <Divider className={styles.divider} />

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack direction="row" alignItems="center" gap="8px">
                  <InsertDriveFileOutlinedIcon className={styles.icon} />
                  <Typography className={styles.fileName}>
                    {currentAgreementsFile.fileName}
                  </Typography>
                  <Typography className={styles.fileType}>
                    (IAA, ADV, CRS)
                  </Typography>
                </Stack>

                <Stack direction="row" alignItems="center" gap="50px">
                  <Typography
                    className={styles.fileAction}
                    onClick={() => setHasSignedAgreements(false)}
                  >
                    Remove
                    <DeleteOutlinedIcon
                      className={toClassName(styles.icon, styles.spacer)}
                    />
                  </Typography>

                  <Typography
                    className={styles.fileAction}
                    onClick={handleDownloadClientDocument}
                  >
                    Download
                    <FileDownloadOutlinedIcon
                      className={toClassName(styles.icon, styles.spacer)}
                    />
                  </Typography>
                </Stack>
              </Stack>
            </>
          )}
        </div>
      )}

      {isMutating && (
        <div className={styles.loading}>
          <LogoLoadingStill />
        </div>
      )}
    </ClientDrawer>
  );
}

interface CopyProps {
  textToCopy: string;
  textType: string;
}

const Copy = (props: CopyProps): JSX.Element => {
  const { smallXIcon } = useSelector((state: State) => ({
    smallXIcon: state.main_Reducer.brand.current.images.small_x_icon,
  }));

  return (
    <div className={styles.copyContainer}>
      <div className={styles.copyInfo}>Copy</div>
      <IconButton
        size="small"
        iconClassName={styles.copyIcon}
        onClick={() =>
          copyToClipboard(props.textToCopy, smallXIcon, props.textType)
        }
        IconComponent={ContentCopyIcon}
      />
    </div>
  );
};

interface FooterProps {
  onClose: () => void;
  onSave: () => void;
  disabled: boolean;
}

const Footer = (props: FooterProps) => {
  return (
    <Stack direction="row" gap="20px">
      <ButtonPrimary
        text="Cancel"
        onClick={props.onClose}
        variant="outlined"
        buttonType="primary"
      />

      <ButtonPrimary
        text="Save"
        onClick={props.onSave}
        variant="contained"
        buttonType="primary"
        disabled={props.disabled}
      />
    </Stack>
  );
};
