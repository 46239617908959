import React from "react";

import { useParams } from "react-router-dom";

import { ClientId, PortfolioId } from "@fartherfinance/frontend/api/Types";
import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import Portfolios from "../../Portfolios";
import HistoricPerformanceTable from "@src/multiCustodian/components/Client/Portfolio/ModelAnalysis/HistoricPerformanceTable";
import ModelDetailsTable from "@src/multiCustodian/components/Client/Portfolio/ModelAnalysis/ModelDetailsTable";
import PortfolioDrawer from "@src/multiCustodian/components/Client/Portfolio/PortfolioDrawer/PortfolioDrawer";
import PortfolioAnalysisAssetClassCategoryTable from "@src/multiCustodian/components/PortfolioAnalysis/PortfolioAnalysisAssetClassCategoryTable";

export default function PortfolioModelDetails(): JSX.Element {
  const { clientId, portfolioId } = useParams<{
    clientId: ClientId;
    portfolioId: PortfolioId;
  }>();

  const {
    color: { $backgroundSubtle },
  } = useTheme();

  return (
    <Portfolios>
      <PortfolioDrawer
        curTab="Model Details"
        clientId={clientId}
        portfolioId={portfolioId}
      >
        <ModelDetailsTable portfolioId={portfolioId} />

        <HistoricPerformanceTable portfolioId={portfolioId} />

        <PortfolioAnalysisAssetClassCategoryTable
          showDirectIndexedFilter={true}
          portfolioId={portfolioId}
          headerStyle={{ backgroundColor: $backgroundSubtle }}
        />
      </PortfolioDrawer>
    </Portfolios>
  );
}
