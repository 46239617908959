import { endOfDay, parseISO, startOfDay } from "date-fns";

import type { OpportunitiesFilterQueryParams } from "@fartherfinance/frontend/api/Opportunities/requests/getOpportunities";
import type { ClientId } from "@fartherfinance/frontend/api/Types";

import type { EnhancedAutocompleteOption } from "@src/multiCustodian/pages/Advisor/common";
import {
  mapLabelToValue,
  mapValueToLabel,
  opportunityPriorityMap,
  opportunityStatusFilterMap,
  opportunityTypeMap,
} from "@src/multiCustodian/pages/Advisor/Opportunities/utils";

import type { FormData } from "./OpportunitiesFilterForm";

const toDate = (value?: string) => (value ? parseISO(value) : undefined);

export const transformFilterQueryParamsToFormValues = ({
  filters,
  clientsOptions,
}: {
  filters: OpportunitiesFilterQueryParams;
  clientsOptions: EnhancedAutocompleteOption<ClientId | undefined>[];
}): FormData => {
  const {
    filterByClient,
    filterByCreatedAfter,
    filterByCreatedBefore,
    filterByUpdatedAfter,
    filterByUpdatedBefore,
    filterByStatus,
    filterByType,
    filterByPriority,
  } = filters;

  return {
    filterByClient: clientsOptions.find(
      (client) => client.value === filterByClient?.at(0)
    ),
    filterByCreatedAfter: toDate(filterByCreatedAfter),
    filterByCreatedBefore: toDate(filterByCreatedBefore),
    filterByUpdatedAfter: toDate(filterByUpdatedAfter),
    filterByUpdatedBefore: toDate(filterByUpdatedBefore),
    filterByStatus: filterByStatus?.map((opportunityStatus) =>
      mapValueToLabel(opportunityStatusFilterMap, opportunityStatus)
    ),
    filterByType: filterByType?.map((opportunityType) =>
      mapValueToLabel(opportunityTypeMap, opportunityType)
    ),
    filterByPriority: filterByPriority?.map((opportunityPriority) =>
      mapValueToLabel(opportunityPriorityMap, opportunityPriority)
    ),
  };
};

export const transformFormValuesToFilterQueryParams = (
  formData: FormData
): OpportunitiesFilterQueryParams => {
  const {
    filterByClient,
    filterByCreatedAfter,
    filterByCreatedBefore,
    filterByUpdatedAfter,
    filterByUpdatedBefore,
    filterByStatus,
    filterByType,
    filterByPriority,
  } = formData;

  return {
    filterByClient:
      filterByClient && filterByClient.value
        ? [filterByClient.value]
        : undefined,
    filterByCreatedAfter: filterByCreatedAfter
      ? startOfDay(filterByCreatedAfter).toISOString()
      : undefined,
    filterByCreatedBefore: filterByCreatedBefore
      ? endOfDay(filterByCreatedBefore).toISOString()
      : undefined,
    filterByUpdatedAfter: filterByUpdatedAfter
      ? startOfDay(filterByUpdatedAfter).toISOString()
      : undefined,
    filterByUpdatedBefore: filterByUpdatedBefore
      ? endOfDay(filterByUpdatedBefore).toISOString()
      : undefined,
    filterByStatus:
      filterByStatus && filterByStatus.length
        ? filterByStatus.map((statusLabel) =>
            mapLabelToValue(opportunityStatusFilterMap, statusLabel)
          )
        : undefined,
    filterByType:
      filterByType && filterByType.length
        ? filterByType.map((typeLabel) =>
            mapLabelToValue(opportunityTypeMap, typeLabel)
          )
        : undefined,
    filterByPriority:
      filterByPriority && filterByPriority.length
        ? filterByPriority.map((priorityLabel) =>
            mapLabelToValue(opportunityPriorityMap, priorityLabel)
          )
        : undefined,
  };
};
