import React from "react";

import useAdvisorDetails from "@src/multiCustodian/hooks/Advisor/useAdvisorDetails";
import useClientName from "@src/multiCustodian/hooks/useClientName";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./Avatar.module.css";

interface Props {
  className?: string;
  initials?: string;
  imageSource?: string;
}

export default function Avatar(props: Props): JSX.Element {
  const clientName = useClientName();
  const advisorSelf = useAdvisorDetails();

  if (props.imageSource) {
    return (
      <img
        className={toClassName(styles.image, props.className)}
        src={props.imageSource}
      />
    );
  }

  if (props.initials) {
    return (
      <div className={toClassName(styles.avatar, props.className)}>
        {props.initials}
      </div>
    );
  }

  if (clientName !== null) {
    return (
      <div className={toClassName(styles.avatar, props.className)}>
        {clientName.initials}
      </div>
    );
  } else {
    const headshot = advisorSelf?.details.headshotPath;

    if (headshot) {
      return (
        <img
          className={toClassName(styles.image, props.className)}
          src={headshot}
        />
      );
    } else {
      const firstInitial: string = (advisorSelf?.name.first ?? "").slice(0, 1);
      const lastInitial: string = (advisorSelf?.name.last ?? "").slice(0, 1);

      const advisorInitials = `${firstInitial}${lastInitial}`;
      return (
        <div className={toClassName(styles.avatar, props.className)}>
          {advisorInitials}
        </div>
      );
    }
  }
}
