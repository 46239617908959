import React from "react";

import { add, isAfter, isBefore, isValid, sub } from "date-fns";

import { Opportunity } from "@fartherfinance/frontend/api/Opportunities/Types";

import { isMarketplaceOfferingOpportunity } from "../../OpportunityDetails.utils";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormDateField from "@src/sharedComponents/Forms/FormDateField";

import OpportunityOfferingCommonDetails from "./OpportunityOfferingCommonDetails";
import OpportunityStandardCommonDetails from "./OpportunityStandardCommonDetails";

const isDateValid = (date: Date | null): true | string => {
  return date && !isValid(date) ? "Invalid date" : true;
};

const isTooFarInFuture = (date: Date | null, maxDate: Date): true | string => {
  return date && isAfter(date, maxDate)
    ? "Please choose a date within the next 2 years from today"
    : true;
};

const isTooFarInPast = (date: Date | null, minDate: Date): true | string => {
  return date && isBefore(date, minDate)
    ? "Please select a date within the past 2 years from today"
    : true;
};

interface OpportunityCommonDetailsProps {
  opportunity: Opportunity;
}

export default function OpportunityCommonDetails({
  opportunity,
}: OpportunityCommonDetailsProps): JSX.Element {
  const minDate = sub(new Date(), { years: 2 });
  const maxDate = add(new Date(), { years: 2 });

  return (
    <>
      {isMarketplaceOfferingOpportunity(opportunity) ? (
        <OpportunityOfferingCommonDetails opportunity={opportunity} />
      ) : (
        <OpportunityStandardCommonDetails opportunity={opportunity} />
      )}

      <FlexWrapper>
        <FormDateField
          name="dueDate"
          label="Due Date"
          minDate={minDate}
          maxDate={maxDate}
          rules={{
            validate: {
              isDateValid,
              isTooFarInPast: (value) => isTooFarInPast(value, minDate),
              isTooFarInFuture: (value) => isTooFarInFuture(value, maxDate),
            },
          }}
        />
      </FlexWrapper>
    </>
  );
}
