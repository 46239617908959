import React from "react";

import FolderIcon from "@mui/icons-material/Folder";
import { Stack, Typography } from "@mui/material";

import { DocOrFilePathPart } from "@fartherfinance/frontend/api/CustodianDoc/Types";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import Modal from "@src/sharedComponents/Modal/Modal";

import styles from "./RemoveFolderModal.module.css";

interface RemoveDirectoryModalProps {
  directoryToRemove: DocOrFilePathPart;
  currentDirectoryScope: DocOrFilePathPart[];
  onConfirm: (directory: DocOrFilePathPart) => void;
  onClose: () => void;
}

const RemoveDirectoryModal: React.FC<RemoveDirectoryModalProps> = ({
  directoryToRemove,
  currentDirectoryScope,
  onConfirm,
  onClose,
}) => {
  const currentDirectoryRecordsCount = currentDirectoryScope.filter(
    (directory) =>
      directory.parentDirectory === directoryToRemove.currentDirectory
  ).length;

  const recordsToRemove =
    currentDirectoryRecordsCount === 1
      ? `${currentDirectoryRecordsCount} item`
      : `${currentDirectoryRecordsCount} items`;

  return (
    <Modal closeModal={onClose} modalStyle={{ width: "490px" }}>
      <Stack gap="4px">
        <FormH1>Are you sure you want to delete this folder?</FormH1>
        <Typography className={styles.secondaryText}>
          This will remove the folder and all items contained inside it.
        </Typography>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        marginTop="32px"
      >
        <Stack direction="row" alignItems="center" gap="8px">
          <FolderIcon className={styles.icon} />
          <Typography className={styles.directoryName}>
            {directoryToRemove.currentDirectory
              ? decodeURIComponent(directoryToRemove.currentDirectory)
              : "Unknown folder"}
          </Typography>
        </Stack>
        <Typography className={styles.secondaryText}>
          {recordsToRemove}
        </Typography>
      </Stack>

      <Stack
        direction="row"
        gap="16px"
        marginTop="40px"
        justifyContent="flex-end"
      >
        <Button
          variant="outlined"
          buttonType="primary"
          text="Cancel"
          onClick={onClose}
        />
        <Button
          variant="outlined"
          buttonType="error"
          text="Delete"
          onClick={() => onConfirm(directoryToRemove)}
        />
      </Stack>
    </Modal>
  );
};

export default RemoveDirectoryModal;
