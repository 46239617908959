import React from "react";

import { AccountDetails } from "@fartherfinance/frontend/api/Dashboard/requests/getClientDashboard";
import { TradingGroup } from "@fartherfinance/frontend/api/TradingGroups/requests/getTradingGroup";

import TradingGroupSummary from "../../Client/TaxBudgets/SelectAccounts/TradingGroupSummary";
import Checkbox from "@src/sharedComponents/Checkbox/Checkbox";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import Label from "./Label";

import styles from "./AccountRow.module.css";

interface Props {
  account: AccountDetails;
  checked: boolean;
  onChecked: (account: AccountDetails) => void;
  tradingGroup?: TradingGroup;
  tradingGroupAccounts: AccountDetails[];
}

const AccountRow: React.FC<Props> = ({
  account,
  checked,
  onChecked,
  tradingGroup,
  tradingGroupAccounts,
}) => {
  const handleOnChange = () => {
    onChecked(account);
  };

  return (
    <div className={styles.container}>
      <Checkbox
        onChange={handleOnChange}
        checked={checked}
        label={<Label account={account} />}
        labelSx={{
          color: "var(--text)",
          fontFamily: "var(--fakt)",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "20px",
          letterSpacing: "0.28px",
        }}
      />
      {tradingGroup && (
        <Tooltip
          placement="top"
          tooltipText={
            <TradingGroupSummary
              tradingGroup={tradingGroup}
              accounts={tradingGroupAccounts}
            />
          }
        >
          <div className={styles.tradingGroup}>Trading Group</div>
        </Tooltip>
      )}
    </div>
  );
};

export default AccountRow;
