import React from "react";

import Fade from "@mui/material/Fade";
import { range } from "lodash";

import {
  MarketplaceOffering,
  MarketplaceOfferingTag,
} from "@fartherfinance/frontend/api/AltAssetsService/Types";
import { MarketplaceOfferingId } from "@fartherfinance/frontend/api/Types";
import { PaginationHeaders } from "@fartherfinance/frontend/api/utils/parsePaginationHeaders";

import OfferingsListCardV2 from "../OfferingsListCard/OfferingsListCardV2";
import { useBaseLayoutRef } from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import { FartherTablePagination as Pagination } from "@src/sharedComponents/Table/Components";

import styles from "./OfferingsListV2.module.css";

interface OfferingsListProps {
  offerings: MarketplaceOffering["v2"][];
  pagination: PaginationHeaders | undefined;
  isLoading: boolean;
  isErrored: boolean;
  onCardClick: (offeringId: MarketplaceOfferingId) => void;
  onTagClick: (tag: MarketplaceOfferingTag) => void;
  onPageChange: (page: number) => void;
}

const OfferingsListV2: React.FC<OfferingsListProps> = ({
  offerings,
  pagination,
  isLoading,
  isErrored,
  onCardClick,
  onPageChange,
  onTagClick,
}) => {
  const baseLayoutRef = useBaseLayoutRef();

  const handlePageChange = (page: number) => {
    onPageChange(page + 1);

    setTimeout(() => {
      baseLayoutRef?.scrollTo({ top: 0, behavior: "smooth" });
    }, 500);
  };

  return (
    <>
      {isErrored && (
        <div className={styles.message}>
          Something went wrong, please try again later
        </div>
      )}

      {!isLoading && !isErrored && offerings.length === 0 && (
        <div className={styles.message}>No Investments Found</div>
      )}

      <div className={styles.listContainer}>
        {isLoading && (
          <>
            {range(0, 10).map((_, index) => (
              <Fade key={index} in>
                <div>
                  <OfferingsListCardV2 isLoading={true} />
                </div>
              </Fade>
            ))}
          </>
        )}

        {!isLoading && offerings.length > 0 && (
          <>
            {offerings.map((offering) => (
              <OfferingsListCardV2
                key={offering.offeringId}
                isLoading={false}
                partnerName={offering.partnerName}
                partnerDescription={offering.about}
                tags={offering.tags}
                isFeatured={offering.isFeatured}
                onClick={() => onCardClick(offering.offeringId)}
                onTagClick={onTagClick}
              />
            ))}
          </>
        )}
      </div>

      {pagination && (!isErrored || offerings.length > 0) && (
        <Pagination
          className={styles.pagination}
          pageSize={pagination.pageSize}
          currentPage={pagination.currentPage - 1}
          totalCount={pagination.totalCount}
          onPageChange={handlePageChange}
          isPrevButtonDisabled={pagination.prevPage === undefined}
          isNextButtonDisabled={pagination.nextPage === undefined}
        />
      )}
    </>
  );
};

export default OfferingsListV2;
