import React, { useMemo, useState } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";
import { orderBy } from "lodash";

import { Sleeve } from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";

import BasicTableChip from "@src/sharedComponents/BasicTableChip/BasicTableChip";
import IconButton from "@src/sharedComponents/IconButton/IconButton";

import Row, { RowProps } from "./SleeveRow";

import styles from "./SleeveTable.module.css";

interface Props {
  onBack?: () => void;
  title: { description: string; secondaryDescription: string };
  rows: RowProps[];
  sleeve: Sleeve;
}

const SleeveTable = (props: Props): JSX.Element => {
  const [sortByWeight, setSortByWeight] = useState<boolean>(false);
  const [isShowingAllSecurities, setIsShowingAllSecurities] =
    useState<boolean>(false);

  const anyRowsToClickInto = useMemo(
    () => props.rows.some((r) => r.onClick),
    [props.rows]
  );

  const rowsContent = useMemo(() => {
    if (isShowingAllSecurities) {
      return orderBy(
        props.sleeve.positions,
        sortByWeight
          ? [(el) => el.value / 100, (el) => el.ticker]
          : [(el) => el.ticker],
        sortByWeight ? ["desc", "asc"] : ["asc"]
      ).map((sec) => {
        return (
          <Row
            key={sec.ticker}
            description={sec.ticker === "US Dollars" ? "CASH" : sec.ticker}
            secondaryDescription={
              sec.ticker === "CASH$" ? "US Dollars" : sec.ticker
            }
            allocation={sec.value / 100}
          />
        );
      });
    }

    return orderBy(
      props.rows,
      sortByWeight
        ? [(el) => el.allocation, (el) => el.description]
        : [(el) => el.description],
      sortByWeight ? ["desc", "asc"] : ["asc"]
    ).map((r) => (
      <Row
        key={JSON.stringify(r)}
        description={r.description}
        secondaryDescription={r.secondaryDescription}
        allocation={r.allocation}
        onClick={r.onClick}
      />
    ));
  }, [
    props.rows,
    sortByWeight,
    isShowingAllSecurities,
    props.sleeve.positions,
  ]);

  const showHeadingWithChips = props.onBack === undefined;

  const clickShowAllSecuritiesChip = () => {
    setIsShowingAllSecurities(!isShowingAllSecurities);
  };

  const clearFilters = () => {
    if (isShowingAllSecurities) {
      setIsShowingAllSecurities(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          {props.onBack !== undefined && (
            <IconButton
              size="small"
              iconClassName={styles.headerBackIcon}
              onClick={props.onBack}
              IconComponent={ArrowBackIcon}
            />
          )}
          <div className={styles.description}>{props.title.description}</div>
        </div>

        <div className={styles.headerRight}>
          {props.title.secondaryDescription}
        </div>
      </div>

      {showHeadingWithChips && (
        <div className={styles.filterContainer}>
          <div className={styles.filterContainerLeft}>
            <BasicTableChip
              text={"Show All Securities"}
              isSelected={isShowingAllSecurities}
              onClick={clickShowAllSecuritiesChip}
            />
          </div>

          <div className={styles.filterContainerRight}>
            {isShowingAllSecurities && (
              <div className={styles.clearPill} onClick={clearFilters}>
                <div className={styles.clearPillText}>Clear</div>

                <ClearIcon className={styles.clearPillIcon} />
              </div>
            )}
          </div>
        </div>
      )}

      <div className={styles.body}>
        <div
          className={styles.tableHeader}
          style={
            anyRowsToClickInto && !isShowingAllSecurities
              ? { padding: "0px 30px 12px 0px" }
              : {}
          }
        >
          <div
            className={styles.headerColumn}
            onClick={() => setSortByWeight(false)}
          >
            Category
          </div>
          <div
            className={styles.headerColumn}
            onClick={() => setSortByWeight(true)}
          >
            Weight
          </div>
        </div>
        <div>{rowsContent}</div>
      </div>

      <div
        className={styles.footer}
        style={
          anyRowsToClickInto && !isShowingAllSecurities
            ? { padding: "0px 46px 16px 16px" }
            : {}
        }
      >
        <div>Total</div>
        <div>
          {(
            props.rows.reduce((prev, cur) => {
              return prev + cur.allocation;
            }, 0) / 100
          ).toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 2,
          })}
        </div>
      </div>
    </div>
  );
};

export default SleeveTable;
