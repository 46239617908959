import React, { useMemo, useState } from "react";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Box, IconButton } from "@mui/material";
import { orderBy } from "lodash";

import useSearchSecurities from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useSearchSecurities";
import type searchSecurities from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/searchSecurities";
import { Ticker } from "@fartherfinance/frontend/api/Types";

import DisplayName from "../../SharedComponents/SearchSecurities/DisplayName";
import {
  SelectedSecurity,
  USDTicker,
} from "../../SharedComponents/SearchSecurities/Types";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import FormNumberField from "@src/sharedComponents/Forms/FormNumberField";
import SearchSecurity from "@src/sharedComponents/SearchSecurity/SearchSecurity";
import { pluralize } from "@src/utils/pluralize";

import styles from "../../SharedComponents/SearchSecurities/SecuritySearch.module.css";

type RemoteSearch = Awaited<ReturnType<typeof searchSecurities>>[number];

interface Search extends RemoteSearch {
  label: string;
}

interface Props {
  selectedSecurities: SelectedSecurity[];
  addSecurity: (sec: SelectedSecurity) => void;
  removeSecurity: (ticker: Ticker) => void;
  total: number;
}

const SecuritySearch = (props: Props): JSX.Element => {
  const [input, setInput] = useState<string | null>(null);

  const addSecurity = (sec: SelectedSecurity) => {
    props.addSecurity(sec);

    setInput(null);
  };

  const auth = useAdvisorRequestAuth();

  const validSearchInput = input === null || input.length <= 1 ? null : input;

  const searchResults = useSearchSecurities(validSearchInput, auth);

  const selectedSecuritiesOrderedAlphaAsc = useMemo(
    () =>
      orderBy(
        props.selectedSecurities,
        [(s) => s.ticker.toLowerCase()],
        ["asc"]
      ),
    [props.selectedSecurities]
  );

  const options = useMemo(() => {
    return (searchResults.data ?? []).map((r) => ({
      ...r,
      label: `${r.ticker} - ${r.displayName}`,
    }));
  }, [searchResults]);

  return (
    <div className={styles.container}>
      <Box padding="12px">
        <SearchSecurity<Search>
          searchInput={input}
          onChange={addSecurity}
          handleSearch={(term) => setInput(term.trim())}
          options={options}
          isLoading={searchResults.isLoading}
        />
      </Box>

      <div className={toClassName(styles.row, styles.header, styles.bold)}>
        <div className={styles.subtleText}>Ticker</div>
        <div className={styles.subtleText}>Weight</div>
      </div>

      <div className={styles.rowContainer}>
        <div className={styles.row}>
          <div className={styles.tickerContainer}>
            <div className={toClassName(styles.text, styles.ticker)}>
              Cash
              <span className={styles.subtleText}>US Dollars</span>
            </div>
          </div>

          <Box width={80}>
            <FormNumberField
              name={"Cash"}
              endAdornment={"%"}
              hideErrorMsg={true}
              customRegex={{
                regex: /^[\d\.]+$/g,
                message: `${USDTicker} must be only numerical digits`,
              }}
              rules={{
                required: false,
                min: {
                  value: 0.0,
                  message: `${USDTicker} can't be negative`,
                },
                max: {
                  value: 100,
                  message: `${USDTicker} is over 100%`,
                },
                validate: {
                  moreThan2Decimals: (value): string | true => {
                    if (typeof value !== "string") {
                      return `Invalid value for ${USDTicker}`;
                    }

                    const decimalPortion = value.split(".")[1] as
                      | string
                      | undefined;
                    if (decimalPortion === undefined) {
                      return true;
                    }

                    // This will catch even 1.110
                    return decimalPortion.length <= 2
                      ? true
                      : `Only 2 decimals allowed for ${USDTicker}`;
                  },
                },
              }}
            />
          </Box>
        </div>

        {selectedSecuritiesOrderedAlphaAsc.map((s) => {
          const asteriscCharInTicker = s.ticker.includes("*");
          // if there is a "." in the ticker, setValue from useForm will evaluate that . as a property and break apart the key/string into an obj so replaced "." with "*", now want to switch back to "." for displaying and BE look-up
          const originalTicker = asteriscCharInTicker
            ? (s.ticker.replace(/\*/g, ".") as Ticker)
            : s.ticker;

          const nameToShow = s.displayName ?? originalTicker ?? "Unknown";

          return (
            <div key={s.ticker} className={styles.row}>
              <div className={styles.tickerContainer}>
                <IconButton
                  className={styles.deleteIconContainer}
                  onClick={() => props.removeSecurity(s.ticker)}
                >
                  <RemoveCircleOutlineIcon className={styles.deleteIcon} />
                </IconButton>
                <div className={toClassName(styles.text, styles.ticker)}>
                  {originalTicker}
                  <span className={styles.subtleText}>
                    {s.displayName ?? <DisplayName ticker={originalTicker} />}
                  </span>
                </div>
              </div>

              <Box width={80}>
                <FormNumberField
                  name={`Security-${s.ticker}`}
                  endAdornment={"%"}
                  hideErrorMsg={true}
                  customRegex={{
                    regex: /^[\d\.]+$/g,
                    message: `${nameToShow} must be only numerical digits`,
                  }}
                  rules={{
                    required: `${nameToShow} must have an allocation`,
                    min: {
                      value: 0.01,
                      message: `${nameToShow} must be larger than 0.01%`,
                    },
                    max: {
                      value: 100,
                      message: `${nameToShow} is over 100%`,
                    },
                    validate: {
                      moreThan2Decimals: (value): string | true => {
                        if (typeof value !== "string") {
                          return `Invalid value for ${nameToShow}`;
                        }

                        const decimalPortion = value.split(".")[1] as
                          | string
                          | undefined;
                        if (decimalPortion === undefined) {
                          return true;
                        }

                        // This will catch even 1.110
                        return decimalPortion.length <= 2
                          ? true
                          : `Only 2 decimals allowed for ${nameToShow}`;
                      },
                    },
                  }}
                />
              </Box>
            </div>
          );
        })}
      </div>

      <div className={styles.footer}>
        <div className={styles.subtleText}>
          {pluralize(props.selectedSecurities, "Security", true)}
        </div>

        <div className={toClassName(styles.text, styles.total)}>
          <span className={styles.bold}>Total</span>
          {(props.total / 100).toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      </div>
    </div>
  );
};

export default SecuritySearch;
