import { z } from "zod";

import { FartherManagedAccountDetails } from "@fartherfinance/frontend/api/Accounts/Types";

type FartherManagedAccountDetails = z.infer<
  typeof FartherManagedAccountDetails
>;

const createAccountLabel = (
  accountDetails: FartherManagedAccountDetails
): string => {
  return `${accountDetails.displayName} (${
    accountDetails.custodianAccountNumber ?? ""
  })`;
};

export default createAccountLabel;
