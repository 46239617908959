import { groupBy } from "lodash";

import {
  ClassOfSecurities,
  HydratedSecurity,
} from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Holdings/utils";
import { AssetClassCategory } from "@fartherfinance/frontend/api/PerformanceGroups/requests/getHoldings";

const combineAssetCategoryClassLists = (
  existingCategoryClasses: ClassOfSecurities[],
  currentCategoryClasses: ClassOfSecurities[]
): ClassOfSecurities[] => {
  // when combining categories they may have the same classes so combine those - will not have to combine securities bc don't expect there to ever be duplicate securities
  const groupedClasses = groupBy(
    [...existingCategoryClasses, ...currentCategoryClasses],
    (c) => c.class
  );

  return Object.values(groupedClasses).map((group: ClassOfSecurities[]) => {
    if (group.length === 1) {
      return group[0]; // no combination/reduction needed
    }

    const newTotalMarketValueOfClass = group.reduce(
      (acc, cur) => acc + cur.marketValue,
      0
    );

    return group.reduce((acc, cur) => {
      const updatedSecurities = cur.securities.map((sec: HydratedSecurity) => {
        return {
          ...sec,
          category: "Other" as AssetClassCategory,
          percentOfClass: sec.marketValue / newTotalMarketValueOfClass,
        };
      });

      return {
        ...acc,
        class: cur.class,
        category: "Other" as AssetClassCategory,
        marketValue: newTotalMarketValueOfClass,
        percentOfCategory: (acc.percentOfCategory ?? 0) + cur.percentOfCategory,
        percentOfTotal: (acc.percentOfTotal ?? 0) + cur.percentOfTotal,
        securities: [...(acc.securities ?? []), ...(updatedSecurities ?? [])],
      };
    }, {} as ClassOfSecurities);
  });
};

export default combineAssetCategoryClassLists;
