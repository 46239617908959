import React, { useState } from "react";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";

import usePatchAccountGroupsV4 from "@fartherfinance/frontend/api/PerformanceGroups/hooks/usePatchAccountGroupsV4";
import {
  ClientId,
  PerformanceGroupId,
} from "@fartherfinance/frontend/api/Types";

import FavoriteStarLabel from "../Shared/FavoriteStarLabel/FavoriteStarLabel";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import styles from "./AccountGroupRow.module.css";

interface Props {
  accountGroupId: PerformanceGroupId;
  name: string;
  onSelectGroup: () => void;
  isFavorite: boolean;
}

const AccountGroupRow: React.FC<Props> = ({
  accountGroupId,
  name,
  onSelectGroup,
  isFavorite,
}: Props) => {
  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const notify = useStatusNotification();

  const auth = useRequestAuth();
  const updateAccountGroup = usePatchAccountGroupsV4(auth);

  const onSetFavorite = async (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    try {
      e.stopPropagation(); // the whole row is clickable and so is this star icon within so stop the click propagation to the parent row

      setIsLoading(true);

      await updateAccountGroup({
        clientId,
        accountGroupId: accountGroupId,
        body: {
          isFavorite: true,
        },
      });

      notify("Account group favorited", "Success");
    } catch (_e) {
      notify("Account group could not be favorited. Try again.", "Error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container} onClick={onSelectGroup}>
      <div className={styles.text}>{name}</div>

      <div className={styles.flexRow}>
        {isLoading ? (
          <div className={styles.loader}>
            <CircularProgress color={"inherit"} size={20} />
          </div>
        ) : isFavorite ? (
          <FavoriteStarLabel />
        ) : (
          <Tooltip tooltipText="Favorite" placement="top">
            <StarBorderIcon
              className={styles.starIcon}
              onClick={onSetFavorite}
            />
          </Tooltip>
        )}

        <KeyboardArrowRightIcon className={styles.icon} />
      </div>
    </div>
  );
};

export default AccountGroupRow;
