import React, { useState } from "react";

import { uniq } from "lodash";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import useGetPlanAccountTypesV2 from "@fartherfinance/frontend/api/Accounts/hooks/useGetPlanAccountTypesV2";
import usePlanDetailsV2 from "@fartherfinance/frontend/api/Accounts/hooks/usePlanDetailsV2";
import { Plan } from "@fartherfinance/frontend/api/Accounts/Types";
import { PlanId } from "@fartherfinance/frontend/api/Types";

import { State as ReduxState } from "../../../../../store";
import AddAccountModal from "../AddAccountModal/AddAccountState";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import { captureException } from "@src/multiCustodian/services/tracking";

import PlanAccountsTable from "./PlanAccountsTable";

import styles from "../../Plans/plans.module.css";

interface Props {
  queryStringParams: string;
  selectedAccount?: string;
}

export default function Container(props: Props): JSX.Element {
  const [showAddAccountModal, setShowAddAccountModal] =
    useState<boolean>(false);

  const auth = useAdvisorRequestAuth();

  const { planId } = useParams<{ planId: PlanId }>();

  const { data: planAccountTypes = [] } = useGetPlanAccountTypesV2(auth);
  const planDetails = usePlanDetailsV2(planId, auth);
  const plan: Plan | undefined = planDetails.data?.plan;

  const accounts = planDetails.data?.resources.accounts;

  const allPlanCustodians = uniq((accounts ?? []).map((a) => a.custodian));

  if (allPlanCustodians.length > 1) {
    console.error(
      `Plan has multiple custodians: ${allPlanCustodians.join(", ")}`
    );

    captureException(
      `Plan has multiple custodians: ${allPlanCustodians.join(", ")}`,
      { extra: { plan: JSON.stringify(planDetails ?? {}) } }
    );
  }

  const planCustodian =
    allPlanCustodians.length === 1 ? allPlanCustodians[0] : null;

  return (
    <>
      <Header
        add={() => {
          if (plan === undefined) {
            alert("Plan has not loaded, try again in a minute");
          }

          setShowAddAccountModal(true);
        }}
        hideAddAccountButton={
          planDetails.data?.plan.planStatus === undefined
            ? true
            : planDetails.data.plan.planStatus === "Complete"
        }
      />

      {showAddAccountModal && plan !== undefined && (
        <AddAccountModal
          planId={plan?.planId}
          planHolderClientId={plan?.planHolder?.clientId}
          currentPlanAccountsCustodian={planCustodian}
          planAccountTypes={planAccountTypes}
          closeModal={() => setShowAddAccountModal(false)}
        />
      )}

      <PlanAccountsTable
        queryStringParams={props.queryStringParams}
        planId={planId}
        planDetails={planDetails.data ?? null}
        selectedAccount={props.selectedAccount}
      />
    </>
  );
}

interface HeaderProps {
  add: () => void;
  hideAddAccountButton: boolean;
}

const Header = (props: HeaderProps): JSX.Element => {
  const { add_circle } = useSelector((state: ReduxState) => ({
    add_circle: state.main_Reducer.brand.current.images.add_circle,
  }));

  return (
    <div className={styles.flex_between_div}>
      <p className={styles.plan_table_type_header}>Accounts</p>

      <div
        className={styles.flex_div}
        onClick={props.hideAddAccountButton ? undefined : props.add}
      >
        <p className={styles.table_header_add_text}>Add</p>
        <img
          className={
            props.hideAddAccountButton
              ? styles.table_header_add_icon_disabled
              : styles.table_header_add_icon
          }
          src={add_circle}
        />
      </div>
    </div>
  );
};
