import React, { useState } from "react";

import CircleIcon from "@mui/icons-material/Circle";
import { Box, Fade, Stack, Typography } from "@mui/material";
import { formatDistanceToNow, formatRelative } from "date-fns";
import isFunction from "lodash/isFunction";

import { FeedStatus } from "@fartherfinance/frontend/api/Notifications/Types";
import { FeedId } from "@fartherfinance/frontend/api/Types";

import Skeleton from "../../Skeleton/Skeleton";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import NotificationActions from "./components/NotificationActions";

import styles from "./Notification.module.css";

type NotificationOwnProps = {
  notificationId: FeedId;
  message: string;
  topic: string | null;
  isUnseen: boolean;
  createdAt: Date;
  onClick?: () => void;
  onArchive: (notificationId: FeedId) => void;
  onStatusToggle: (notificationId: FeedId, newStatus: FeedStatus) => void;
};

type NotificationLoadingFalseProps = NotificationOwnProps & {
  isLoading: false;
};

type NotificationLoadingTrueProps = Never<NotificationOwnProps> & {
  isLoading: true;
};

type NotificationProps =
  | NotificationLoadingFalseProps
  | NotificationLoadingTrueProps;

// NOTE: if this component is rendered outside of the parent that has a mount/unmount flow,
// then the createdAt timestamp in distance mode will not update by itself.
export default function Notification({
  notificationId,
  message,
  topic,
  isUnseen,
  isLoading,
  createdAt,
  onClick,
  onArchive,
  onStatusToggle,
}: NotificationProps): JSX.Element {
  const [isDistanceMode, setIsDistanceMode] = useState(true);
  const [isHovering, setIsHovering] = useState(false);
  const isUnseenMarkVisible = isUnseen && !isLoading;
  const newStatus = isUnseen
    ? FeedStatus.Values.READ
    : FeedStatus.Values.UNREAD;

  const handleMouseEnter = (): void => {
    if (!isLoading) {
      setIsHovering(true);
    }
  };

  const handleMouseLeave = (): void => {
    if (!isLoading) {
      setIsHovering(false);
    }
  };

  const handleOnContainerClick = (): void => {
    if (isFunction(onClick) && !isLoading) {
      onClick();
    }
  };

  const handleOnTimestampClick = (
    event: React.MouseEvent<HTMLSpanElement>
  ): void => {
    event.stopPropagation();
    setIsDistanceMode((isDistanceMode) => !isDistanceMode);
  };

  return (
    <Box
      className={toClassName(styles.container, {
        [styles.containerUnseen]: isUnseen,
        [styles.containerSeen]: !isUnseen,
        [styles.noHover]: isLoading,
      })}
      onClick={handleOnContainerClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Fade in={isHovering}>
        <div>
          <NotificationActions
            isUnseen={!isLoading ? isUnseen : false}
            onArchive={() => onArchive?.(notificationId)}
            onStatusToggle={() => onStatusToggle?.(notificationId, newStatus)}
          />
        </div>
      </Fade>

      <Stack direction="row" justifyContent="space-between">
        <Typography
          className={toClassName(styles.message, {
            [styles.messageUnseen]: isUnseen,
            [styles.messageSeen]: !isUnseen,
          })}
        >
          {isLoading ? <Skeleton /> : message}
        </Typography>
        {isUnseenMarkVisible && (
          <CircleIcon
            className={toClassName(styles.unseenMark, styles.spacerLeft)}
          />
        )}
      </Stack>

      <Stack direction="row" justifyContent="space-between">
        <Typography className={toClassName(styles.details, styles.topic)}>
          {isLoading ? <Skeleton /> : topic}
        </Typography>
        <Typography
          className={toClassName(
            styles.details,
            styles.timestamp,
            styles.spacerLeft
          )}
        >
          {isLoading ? (
            <Skeleton />
          ) : (
            <span onClick={handleOnTimestampClick}>
              {isDistanceMode
                ? formatDistanceToNow(createdAt, { addSuffix: true })
                : formatRelative(createdAt, new Date())}
            </span>
          )}
        </Typography>
      </Stack>
    </Box>
  );
}
