import React from "react";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./PopoverMenu.module.css";

export interface NavLinkMenuOption {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  label: string;
  isEnabled: boolean;
}

interface Props {
  item: NavLinkMenuOption;
  className: string;
}

const PopoverMenuRow: React.FC<Props> = ({ item, className }: Props) => {
  return (
    <div
      className={toClassName(
        styles.row,
        {
          [styles.row]: item.isEnabled,
          [styles.disabled]: !item.isEnabled,
        },
        className
      )}
      onClick={item.onClick}
    >
      {item.label}
    </div>
  );
};

export default PopoverMenuRow;
