import React, { useMemo, useState } from "react";

import { orderBy } from "lodash";

import useCreatePlanV2 from "@fartherfinance/frontend/api/Accounts/hooks/useCreatePlanV2";
import useAdvisorClients from "@fartherfinance/frontend/api/Entity/hooks/useAdvisorClients";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import Input_Component from "../../../components/Input/Input_Component_2";
import Button from "../../../components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import Dropdown, {
  AutocompleteOption,
} from "@src/sharedComponents/SAODropdown/Dropdown";
import { extractAxiosErrorMessage, isAxiosErrorCode } from "@src/utils/axios";

// NOTE! removing these 2 imports will break all of Plans css (throughout all the Plans components since they don't use css modules)
import "@src/multiCustodian/pages/Login/advisorLogin.css";
import "@src/multiCustodian/pages/Login.css";

const plansFilter_neutral_underline_style: React.CSSProperties = {
  borderBottom: "1px solid var(--neutral_input_underline_color_1)",
  width: "100%",
  marginBottom: "10px",
};

interface IncomingProps {
  plansFilterStr: string;
  setPlansFilterStr: (plansFilterStr: string) => void;
  setPlanBeingCreated: (bool: boolean) => void;
}

const PlansHeader = (props: IncomingProps): JSX.Element => {
  return (
    <div className="AdvisorLogin_page__openAccounts_headerDiv">
      <div className="AdvisorLogin_page__openAccounts_innerHeaderDiv">
        <ClientFilter
          plansFilterStr={props.plansFilterStr}
          setPlansFilterStr={props.setPlansFilterStr}
        />

        <div className="AdvisorLogin_page__openAccounts_innerHeaderDiv_divide" />

        <CreatePlan setPlanBeingCreated={props.setPlanBeingCreated} />
      </div>
    </div>
  );
};

export default PlansHeader;

interface ClientFilterProps {
  plansFilterStr: string;
  setPlansFilterStr: (plansFilterStr: string) => void;
}

const ClientFilter = (props: ClientFilterProps): JSX.Element => {
  return (
    <div className="AdvisorLogin_page__plansFilter_input_div">
      <Input_Component
        title={"Plans Filter"}
        title_img={""}
        name={"plans_filter"}
        type={"text"}
        placeholder={"Client or plan name..."}
        value={props.plansFilterStr}
        validation_img={""}
        show_checkmark={false}
        underline_style={plansFilter_neutral_underline_style}
        error_description={""}
        handleInputChange={(e) => props.setPlansFilterStr(e.target.value)}
      />
    </div>
  );
};

interface AccountAutocompleteOption extends AutocompleteOption {
  clientId: ClientId;
}

interface MiniClient {
  name: {
    first: string;
    last: string;
  };
  clientId: ClientId;
}

const labelmaker = (client: MiniClient): string =>
  `${client.name.last}, ${client.name.first} (...${client.clientId.substring(
    client.clientId.length - 4
  )})`;

interface CreatePlanProps {
  setPlanBeingCreated: (bool: boolean) => void;
}

const CreatePlan = (props: CreatePlanProps): JSX.Element => {
  const [selectedAccountHolderId, setSelectedAccountHolderId] = useState<
    ClientId | undefined
  >(undefined);

  const statusNotification = useStatusNotification();

  const auth = useAdvisorRequestAuth();
  const advisorClients = useAdvisorClients(undefined, auth);

  const callCreatePlan = useCreatePlanV2(auth);

  const autocompleteHolders = useMemo(
    () =>
      orderBy(
        advisorClients.data?.clients ?? [],
        [(el) => `${el.name.last} ${el.name.first}`],
        ["asc"]
      ).map(
        (a): AccountAutocompleteOption => ({
          label: labelmaker(a),
          clientId: a.clientId,
        })
      ),
    [advisorClients.data]
  );

  const handleCreatePlan = async (): Promise<void> => {
    if (selectedAccountHolderId) {
      props.setPlanBeingCreated(true);

      try {
        await callCreatePlan(selectedAccountHolderId);
        statusNotification("Plan created", "Success");
      } catch (error) {
        const errorMessage = isAxiosErrorCode(400, error)
          ? extractAxiosErrorMessage(error)
          : "Error creating plan";

        statusNotification(errorMessage, "Error");
      } finally {
        props.setPlanBeingCreated(false);
      }
    }
  };

  return (
    <div className="AdvisorLogin_page__openAccounts_createPlanDiv">
      <div className="AdvisorLogin_page__openAccounts_selectPrimaryAccountHolderDiv">
        <Dropdown<AccountAutocompleteOption>
          formLabel="Primary Account Holder"
          values={autocompleteHolders}
          onChange={(newClient) =>
            setSelectedAccountHolderId(newClient.clientId)
          }
          value={
            autocompleteHolders.find(
              (e) => e.clientId === selectedAccountHolderId
            ) ?? null
          }
        />
      </div>

      <Button
        disabled={!selectedAccountHolderId}
        variant={"contained"}
        buttonType={"primary"}
        text={"Create Plan"}
        onClick={handleCreatePlan}
      />
    </div>
  );
};
