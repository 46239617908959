import React, { ComponentProps } from "react";

import { Controller, Message, useFormContext } from "react-hook-form";

import Select, { SelectProps } from "../Select/Select";

interface Props<T extends string>
  extends Omit<SelectProps<T>, "value" | "onChange" | "required"> {
  name: string;
  placeholder?: string;
  defaultValue?: T;
  disabled?: boolean;
  required?: Message | false;
  rules?: ComponentProps<typeof Controller>["rules"];
}

export default function FormSelectField<T extends string>({
  name,
  defaultValue,
  required,
  rules,
  ...restProps
}: Props<T>): JSX.Element {
  const { control } = useFormContext();

  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      control={control}
      rules={{ required, ...rules }}
      render={({ field }) => (
        <Select
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          {...restProps}
        />
      )}
    />
  );
}
