import React from "react";

import { orderBy } from "lodash";

import EquivalentSecuritiesDrawerTableRow from "../../Admin/EquivalentSecurities/Drawer/EquivalentSecuritiesDrawerTableRow";
import { EquivalentSecurityListWithInfo } from "../../Admin/EquivalentSecurities/useEquivalentSecurityListMembersWithInfo";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";
import { pluralize } from "@src/utils/pluralize";

import styles from "./EquivalentSecuritiesDrawer.module.css";

interface Props {
  list: EquivalentSecurityListWithInfo;
}

const EquivalentSecuritiesList: React.FC<Props> = ({ list }) => {
  return (
    <div className={styles.listContainer}>
      <div className={styles.heading}>
        <div className={styles.header}>{list.name}</div>

        <div className={styles.numItems}>
          {pluralize(list.members, "Security", true)}
        </div>
      </div>

      <div className={styles.subHeader}>{list.description}</div>

      <BorderBox className={styles.borderBox}>
        {orderBy(list.members, (s) => s.ticker, ["asc"]).map((sec) => (
          <EquivalentSecuritiesDrawerTableRow
            key={sec.securityId}
            ticker={sec.ticker}
            description={sec.description}
          />
        ))}
      </BorderBox>
    </div>
  );
};

export default EquivalentSecuritiesList;
