import React, { useMemo } from "react";

import { chunk } from "lodash";

import {
  CreateEssentialPortfolio,
  UpdateEssentialPortfolio,
} from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";

import calculateSubIndex from "@src/utils/calculateSubIndex";
import frontEvenChunk from "@src/utils/frontEvenChunk";
import setToXElements from "@src/utils/setToXElements";

import styles from "./SecurityColumnsTable.module.css";

interface Props {
  securityWeights: SecurityWeight[];
}

type Portfolio = CreateEssentialPortfolio | UpdateEssentialPortfolio;
type SecurityWeight = Portfolio["positions"][number];

const SecurityColumnsTable = ({ securityWeights }: Props): JSX.Element => {
  const buckets = useMemo((): SecurityWeight[][] => {
    if (securityWeights.length <= 20) {
      return setToXElements(4, chunk(securityWeights, 5));
    } else {
      return setToXElements(
        4,
        frontEvenChunk(securityWeights, Math.floor(securityWeights.length / 4))
      );
    }
  }, [securityWeights]);

  return (
    <div className={styles.container}>
      {buckets.map((bucket, bucket_idx) => {
        return bucket.length > 0 ? (
          <div className={styles.column}>
            <ColumnHeader />

            <div className={styles.elementsDiv}>
              {bucket.map((el, idx) => {
                const subIndex = calculateSubIndex(buckets, bucket_idx, idx);
                return (
                  <Row
                    key={subIndex}
                    idx={subIndex + 1}
                    ticker={el.securityIdentifier}
                    weight={el.weight}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <></>
        );
      })}
    </div>
  );
};

export default SecurityColumnsTable;

const ColumnHeader = () => {
  return (
    <div className={styles.columnHeader}>
      <div className={styles.columnHeaderNumberDiv}>
        <div className={styles.columnHeaderNumber}>#</div>
      </div>

      <div className={styles.columnHeaderTicker}>Ticker</div>

      <div className={styles.columnHeaderWeightDiv}>
        <div className={styles.columnHeaderWeight}>Weight</div>
      </div>
    </div>
  );
};

interface RowProps {
  idx: number;
  ticker: string;
  weight: number;
}

const Row = ({ idx, ticker, weight }: RowProps) => {
  return (
    <div className={styles.rowDiv}>
      <div className={styles.rowNumberDiv}>
        <div className={styles.rowNumber}>{idx}</div>
      </div>

      <div className={styles.rowTicker}>{ticker}</div>

      <div className={styles.rowWeightDiv}>
        <div className={styles.rowWeight}>
          {(weight / 10000).toLocaleString("en-US", {
            style: "percent",
            maximumFractionDigits: 2,
          })}
        </div>
      </div>
    </div>
  );
};
