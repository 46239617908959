import React from "react";

import Button from "@src/multiCustodian/components/MUI/Button/Button";

const EmptyButton: React.FC = () => (
  <Button
    variant="contained"
    buttonType="primary"
    style={{ display: "block" }}
    text={"Fake"}
    onClick={() => undefined}
  />
);

export default EmptyButton;
