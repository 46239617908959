import React from "react";

import { format } from "date-fns";
import { useParams } from "react-router-dom";

import useClientAccounts from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAccounts";
import { TaxBudget } from "@fartherfinance/frontend/api/InvestmentModel/Types";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import Row from "../../../TaxBudgets/DetailTabs/Row";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";
import toUSD from "@src/utils/toUSD";

import AccountNames from "./AccountNames";

interface Props {
  budget?: TaxBudget;
}

const DetailsTable: React.FC<Props> = ({ budget }) => {
  const { clientId } = useParams<{ clientId: ClientId }>();

  const auth = useRequestAuth();

  const { data: accountData } = useClientAccounts(clientId, auth);

  const accountNames =
    accountData?.fartherAccounts
      .filter((a) =>
        (budget?.excludedVirtualAccountIds ?? []).every(
          (eaid) => eaid !== a.accountId
        )
      )
      .map((account) => account.accountDetails.displayName) || [];

  return (
    <div>
      <BorderBox>
        <Row start="Amount" end={`${toUSD(budget?.budgetAmount || 0)}`} />

        <Row
          start={<AccountNames accountNames={accountNames} />}
          end={accountNames.join(", ")}
        />

        <Row
          start="Created"
          end={`${format(budget?.createTime || new Date(), "MM/dd/yyyy")}`}
        />
      </BorderBox>
    </div>
  );
};

export default DetailsTable;
