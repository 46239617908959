import React from "react";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import styles from "./FlexWrapper.module.css";

interface Props {
  className?: string;
  style?: React.CSSProperties;
}

export default function FlexWrapper(
  props: React.PropsWithChildren<Props>
): JSX.Element {
  return (
    <div
      className={toClassName(styles.wrapper, props.className)}
      style={props.style}
    >
      {props.children}
    </div>
  );
}
