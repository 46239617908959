import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  legendContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.appearance.$backgroundSubtlest,
  },
  legendText: {
    fontFamily: theme.typography.fontSecondary,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "19px",
    letterSpacing: "0.63px",
    color: theme.colors.primary.tints.navy50,

    transform: "rotate(-90deg)",
  },
}));
