import React, { useEffect } from "react";

import ClientsAdvisor from "@src/multiCustodian/components/Client/Advisors/ClientsAdvisor";
import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import { trackEvent } from "@src/multiCustodian/services/tracking";
import PageHero from "@src/sharedComponents/PageHero/PageHero";
import TitleBlock from "@src/sharedComponents/PageHero/TitleBlock";

export default function Advisor(): JSX.Element {
  useEffect(() => {
    trackEvent({ name: "Client Open Advisor" });
  }, []);

  return (
    <BaseLayout>
      <PageHero start={<TitleBlock title="Advisor" />} />

      <ClientsAdvisor />
    </BaseLayout>
  );
}
