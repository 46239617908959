import React, { useMemo } from "react";

import { head, isNil, orderBy } from "lodash";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";

import useCreateTrigger from "@fartherfinance/frontend/api/CashCycle/hooks/useCreateTrigger";
import {
  FixedAmountAccount,
  UserAmountType,
} from "@fartherfinance/frontend/api/CashCycle/requests/getCurrentTrigger";
import { IncomingTriggerBody } from "@fartherfinance/frontend/api/CashCycle/requests/postTrigger";
import { AccountDetails } from "@fartherfinance/frontend/api/Dashboard/hooks/useClientAccounts";
import { ClientId, FartherAccountId } from "@fartherfinance/frontend/api/Types";
import { mapObject } from "@fartherfinance/frontend/utils/mapObject";

import {
  cronDayStringToDayNumberString,
  CUSTOM_DAY_OF_THE_MONTH,
  dropdownOptions,
  FIXED_AMOUNT_TYPE,
  FIXED_METHOD_TYPE,
  makeScheduleBody,
  ScheduleDateString,
  scheduleTypeToOptionString,
  TransferDay,
} from "../shared";
import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import formatAmount from "@src/multiCustodian/pages/Dashboard/Dashboard_Components/DashboardForms/formatters/formatAmount";
import isValidLocaleNumber from "@src/multiCustodian/pages/Dashboard/Dashboard_Components/DashboardForms/validators/isValidLocaleNumber";
import numberGTEMin from "@src/multiCustodian/pages/Dashboard/Dashboard_Components/DashboardForms/validators/numberGTEMin";
import numberLTEMax from "@src/multiCustodian/pages/Dashboard/Dashboard_Components/DashboardForms/validators/numberLTEMax";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import Spacer from "@src/sharedComponents/Forms/Spacer";

import FormAmountRow from "./Components/FormAmountRow/FormAmountRow";
import { fundingDaysInMonth, isValidAccountTypeForCashMgmt } from "./shared";
import validAmount, {
  Amount,
  MAX_AMOUNT,
  MIN_AMOUNT,
} from "./validators/validAmount";

import styles from "./SetupRecurringTransfersModal.module.css";

const FrequencyField = "Frequency";

const TransferDayField = "TransferDay";

type AmountField = Record<FartherAccountId, Amount | undefined>;

type AmountFieldEntry = [Amount | undefined, FartherAccountId];

const isAmountField = (
  field: [Form[keyof Form], keyof Form]
): field is AmountFieldEntry => {
  return field[1] !== FrequencyField && field[1] !== TransferDayField;
};

type ValidAmountField = [Amount, FartherAccountId];

const isValidAmountField = (
  amountField: AmountFieldEntry
): amountField is ValidAmountField => {
  return validAmount(amountField[0]);
};

type Form = {
  [FrequencyField]: { label: ScheduleDateString } | undefined;
  [TransferDayField]: { label: TransferDay } | null | undefined;
} & AmountField;

interface Props {
  closeModal: () => void;
  setIsMutating: (isMutating: boolean) => void;
  isMutating: boolean;
  currentFrequency:
    | "month_first"
    | "month_last"
    | "month_split"
    | "custom"
    | null;
  currentCustomDay: string | null;
  currentFixedAmountAccounts: FixedAmountAccount[] | null;
  accounts: AccountDetails[];
}

const FixedFundingMethodSetup = (props: Props): JSX.Element => {
  const validAccounts = useMemo(() => {
    return orderBy(
      props.accounts.filter(
        (acc) =>
          acc.accountDetails.operationalState === "Open" &&
          isValidAccountTypeForCashMgmt(acc)
      ),
      [
        (a) => a.accountDetails.displayName,
        (a) => a.accountDetails.custodianAccountNumber,
        (a) => a.accountId,
      ],
      ["asc", "asc", "asc"]
    );
  }, [props.accounts]);

  const defaultValuesPartial: Form = useMemo(() => {
    return {
      [FrequencyField]:
        props.currentFrequency === null
          ? undefined
          : { label: scheduleTypeToOptionString(props.currentFrequency) },
      [TransferDayField]:
        props.currentCustomDay === null
          ? undefined
          : cronDayStringToDayNumberString(props.currentCustomDay) ?? undefined,
    };
  }, [props.currentCustomDay, props.currentFrequency]);

  const defaultValues: Form = useMemo(() => {
    return (props.currentFixedAmountAccounts ?? []).reduce(
      (defaultFormValues, acc) => {
        return {
          ...defaultFormValues,
          [acc.accountId]: acc.amount.toLocaleString("en-US"),
        };
      },
      defaultValuesPartial
    );
  }, [defaultValuesPartial, props.currentFixedAmountAccounts]);

  const methods = useForm<Form>({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues,
  });

  const {
    control,
    resetField,
    getValues,
    clearErrors,
    setError,
    formState: { isValid, isDirty, errors },
  } = methods;

  const frequency = useWatch({ control, name: FrequencyField });

  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const statusNotification = useStatusNotification();

  const auth = useRequestAuth();

  const createTrigger = useCreateTrigger(clientId, auth);

  const onUpdate = async () => {
    if (isDisabled) {
      return;
    }

    const values = getValues();

    if (values.Frequency === undefined) {
      return;
    }

    if (
      values.Frequency.label === CUSTOM_DAY_OF_THE_MONTH &&
      isNil(values.TransferDay)
    ) {
      return;
    }

    try {
      props.setIsMutating(true);

      const amountFields = mapObject<
        keyof Form,
        Form[keyof Form],
        [Form[keyof Form], keyof Form]
      >(values, (val, key) => [val, key]).filter(isAmountField);

      const validAmountFields = amountFields
        .filter(isValidAmountField)
        .map(([val, key]) => ({ accountId: key, amount: val }));

      const sum = validAmountFields.reduce(
        //Note, amount may have ','
        (total, { amount }) => total + parseFloat(amount.replace(/,/g, "")),
        0
      );

      const amount: UserAmountType = {
        type: FIXED_AMOUNT_TYPE,
        amount: sum,
      };

      const method: IncomingTriggerBody["method"] = {
        type: FIXED_METHOD_TYPE,
        accounts: validAmountFields.map(({ accountId, amount }) => ({
          //Note, amount may have ','
          accountId,
          amount: parseFloat(amount.replace(/,/g, "")),
        })),
      };

      const body: IncomingTriggerBody = {
        schedule: makeScheduleBody(
          values.Frequency.label,
          values.Frequency.label === CUSTOM_DAY_OF_THE_MONTH
            ? values.TransferDay?.label
            : undefined
        ),
        amount,
        method,
      };

      await createTrigger({
        clientId,
        body,
      });

      statusNotification("Funding settings updated", "Success");
    } catch (_e) {
      statusNotification("Error updating settings", "Error");
    } finally {
      props.setIsMutating(false);
      props.closeModal();
    }
  };

  const isDisabled = !isValid || !isDirty;

  if (props.isMutating) {
    return <></>;
  }

  return (
    <div className={styles.methodContainer}>
      <FormProvider {...methods}>
        <FormDropdownField
          name={FrequencyField}
          placeholder={"Select a frequency..."}
          label={"Frequency"}
          values={dropdownOptions.map((option) => ({
            label: option,
          }))}
          disableSearch={true}
          required={"Must not be empty"}
          rules={{
            onChange: (e) => {
              if (e.target?.value?.label !== CUSTOM_DAY_OF_THE_MONTH) {
                resetField(TransferDayField, { defaultValue: null }); //Only null is accepted by react-hook-form for reset
              }
            },
          }}
        />

        {(frequency === undefined ||
          frequency?.label === CUSTOM_DAY_OF_THE_MONTH) && (
          <>
            <Spacer />
            <FormDropdownField
              name={TransferDayField}
              placeholder={
                frequency?.label === CUSTOM_DAY_OF_THE_MONTH
                  ? "1 - 28"
                  : "Not required"
              }
              label={"Transfer Day"}
              values={fundingDaysInMonth.map((day) => ({
                label: day,
              }))}
              disableSearch={true}
              disabled={
                frequency !== undefined &&
                frequency.label !== CUSTOM_DAY_OF_THE_MONTH
              }
              required={
                frequency?.label === CUSTOM_DAY_OF_THE_MONTH
                  ? "Must not be empty"
                  : false
              }
            />
          </>
        )}

        <Spacer />

        <p className={styles.accountsLabel}>
          Accounts
          <span className={styles.accountsLabelSpan}>
            {validAccounts.length > 4 ? "(scroll to view all)" : ""}
          </span>
        </p>

        <div className={styles.scrollDiv}>
          {validAccounts.map((a) => {
            return (
              <FormAmountRow
                name={a.accountId}
                key={a.accountId}
                label={a.accountDetails.displayName}
                startAdornment="$"
                placeholder="0"
                valueFormatterOnChange={(e) => {
                  //decimals are not allowed
                  return formatAmount(head(e.split(".")));
                }}
                valueFormatterOnBlur={(e) => {
                  const values = getValues();

                  const atLeastOneAccountIsFunded = validAccounts.some((acc) =>
                    validAmount(values[acc.accountId])
                  );

                  if (!atLeastOneAccountIsFunded && (isNil(e) || e === "")) {
                    if (errors.root === undefined) {
                      setError("root", {
                        type: "custom",
                        message: "No amount funded correctly",
                      });
                    }
                  } else if (errors.root !== undefined) {
                    clearErrors("root");
                  }

                  //decimals are not allowed
                  return formatAmount(head(e.split(".")));
                }}
                rules={{
                  validate: {
                    isValidNumber: (e) => {
                      return isValidLocaleNumber(e, true);
                    },
                    atLeastMin: (e) => {
                      return numberGTEMin(e, MIN_AMOUNT)
                        ? true
                        : `Amount must be at least ${MIN_AMOUNT.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 0,
                            }
                          )}`;
                    },
                    atMostMax: (e) => {
                      return numberLTEMax(e, MAX_AMOUNT)
                        ? true
                        : `Amount must not exceed ${MAX_AMOUNT.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 0,
                            }
                          )}`;
                    },
                  },
                }}
              />
            );
          })}
        </div>

        {errors.root !== undefined && (
          <div className={styles.error}>{errors.root.message}</div>
        )}

        <div className={styles.textOfNote}>
          *Tax advantaged accounts are not supported at this time
        </div>
      </FormProvider>

      <div className={styles.footer}>
        <div className={styles.modalButtonsDiv}>
          <Button
            variant={"outlined"}
            buttonType={"secondary"}
            text={"Cancel"}
            onClick={props.closeModal}
          />

          <Button
            disabled={isDisabled}
            variant={"contained"}
            buttonType={"primary"}
            text={"Setup"}
            onClick={() => onUpdate()}
            style={{ marginLeft: "15px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default FixedFundingMethodSetup;
