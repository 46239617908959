import React, { useCallback, useMemo, useState } from "react";

import { format, parse } from "date-fns";
import {
  DefaultValues,
  FieldPath,
  FormProvider,
  useForm,
  useFormState,
  useWatch,
} from "react-hook-form";

import useSetPlanAccountInfoV2 from "@fartherfinance/frontend/api/Accounts/hooks/useSetPlanAccountInfoV2";
import useSetPlanAccountPeopleV2 from "@fartherfinance/frontend/api/Accounts/hooks/useSetPlanAccountPeopleV2";
import { JointHoldersBody } from "@fartherfinance/frontend/api/Accounts/requests/setPlanAccountPeopleV2";
import {
  Account,
  AccountInformationFormToSend,
} from "@fartherfinance/frontend/api/Accounts/Types";
import {
  FartherAccountId,
  PlanId,
  USAState,
  USAStates,
} from "@fartherfinance/frontend/api/Types";

import DrawerHeader from "../Components/DrawerHeader";
import useTriggerForm from "../hooks/useTriggerForm";
import { dateFormat } from "@src/constants/dateFormat";
import Drawer from "@src/multiCustodian/components/Drawer/Drawer";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import FlexWrapper from "@src/sharedComponents/Forms/FlexWrapper";
import FormAccountHolderDropdownField, {
  AutocompleteClient,
} from "@src/sharedComponents/Forms/FormAccountHolderDropdownField";
import FormDateField from "@src/sharedComponents/Forms/FormDateField";
import FormDropdownField from "@src/sharedComponents/Forms/FormDropdownField";
import FormH1 from "@src/sharedComponents/Forms/FormH1";
import FormH2 from "@src/sharedComponents/Forms/FormH2";
import FormNumberField from "@src/sharedComponents/Forms/FormNumberField";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import Spacer from "@src/sharedComponents/Forms/Spacer";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import { extractAxiosErrorMessage, isAxiosErrorCode } from "@src/utils/axios";

import styles from "./Form.module.css";

const married = ["Yes", "No"] as const;

interface Form {
  AccountName: string | undefined;
  AgreementDate: Date | undefined;
  TenancyState: { label: USAState } | undefined;
  Married: { label: typeof married[number] } | undefined;
  TargetGoal: string | undefined;
  TargetDate: Date | undefined;
  PrimaryAccountHolder: AutocompleteClient | undefined;
  SecondaryAccountHolder: AutocompleteClient | undefined;
}

type StripUndefined<F extends object> = { [k in keyof F]: NonNullable<F[k]> };

interface Props {
  planId: PlanId;
  accountId: FartherAccountId;
  onClose: () => void;
  account: Account;
}

const calcDefaultValues = (account: Account): DefaultValues<Form> => {
  const defaultAccountName =
    account.accountInformation.accountTitle ?? "Joint TE";

  const agreementDate =
    account.accountInformation?.jointData?.agreementDate ?? null;
  const defaultAgreementDate = agreementDate
    ? parse(agreementDate, dateFormat, new Date())
    : undefined;

  const defaultTenancyState =
    account.accountInformation?.jointData?.jointTenancyState ?? null;

  const defaultMarried =
    account.accountInformation?.jointData?.jointHoldersMarried ?? null;

  const accountTarget = account.accountInformation.accountTarget ?? null;

  const defaultTargetGoal = accountTarget ? `${accountTarget.goal}` : "";

  const defaultTargetDate = accountTarget
    ? parse(accountTarget.date, dateFormat, new Date())
    : undefined;

  const primaryHolder = account.accountPeople?.primaryAccountHolder;

  const defaultPrimaryHolder = primaryHolder
    ? {
        ...primaryHolder,
        label: `${primaryHolder.name.first} ${
          primaryHolder.name.last
        } (...${primaryHolder.clientId.slice(-5)})`,
      }
    : undefined;

  const secondaryHolder = account.accountPeople?.secondaryAccountHolders
    ? account.accountPeople.secondaryAccountHolders[0]
    : undefined;

  const defaultSecondaryHolder = secondaryHolder
    ? {
        ...secondaryHolder,
        label: `${secondaryHolder.name.first} ${
          secondaryHolder.name.last
        } (...${secondaryHolder.clientId.slice(-5)})`,
      }
    : undefined;

  return {
    AccountName: defaultAccountName,
    AgreementDate: defaultAgreementDate,
    TenancyState: defaultTenancyState
      ? { label: defaultTenancyState }
      : undefined,
    Married: { label: defaultMarried ? "Yes" : "No" },
    TargetGoal: defaultTargetGoal,
    TargetDate: defaultTargetDate,
    PrimaryAccountHolder: defaultPrimaryHolder,
    SecondaryAccountHolder: defaultSecondaryHolder,
  };
};

export default function JointTE(props: Props): JSX.Element {
  const [isMutating, setIsMutating] = useState<boolean>(false);

  const statusNotification = useStatusNotification();

  const defaultValues = useMemo(
    () => calcDefaultValues(props.account),
    [props.account]
  );

  const methods = useForm<Form>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
  });

  const { control, getValues, trigger } = methods;

  const { isValid } = useFormState({
    control,
  });

  const targetGoalValue = useWatch<Form>({ control, name: "TargetGoal" });
  const targetGoalNonEmpty =
    typeof targetGoalValue !== "string"
      ? false
      : nonEmptyValue(targetGoalValue);

  const targetDateValue = useWatch<Form>({ control, name: "TargetDate" }) as
    | Date
    | undefined;
  const targetDateNotEmpty = targetDateValue !== undefined;

  const auth = useAdvisorRequestAuth();
  const callPutPlanAccountInfo = useSetPlanAccountInfoV2(auth);
  const callPutPlanAccountPeople = useSetPlanAccountPeopleV2(auth);

  const needsManualTriggering: FieldPath<Form>[] = useMemo(
    () => ["TargetDate", "TargetGoal"],
    []
  );
  useTriggerForm<Form>(control, trigger, needsManualTriggering);

  const submit = useCallback(async () => {
    const values = getValues() as StripUndefined<Form>;

    const tenancyState = values.TenancyState?.label ?? values.TenancyState;

    if (!isValid) {
      return;
    }

    const form: AccountInformationFormToSend = {
      accountType: "Joint TE",
      accountTitle: values.AccountName,
      accountTarget: targetGoalNonEmpty
        ? {
            goal: parseFloat(values.TargetGoal),
            date: format(values.TargetDate, dateFormat),
          }
        : null,
      jointData: {
        jointTenancyState: tenancyState,
        jointHoldersMarried:
          (values.Married.label ?? values.Married) === "Yes" ? true : false,
        agreementDate: format(values.AgreementDate, dateFormat),
      },
    };

    const people: JointHoldersBody = {
      primaryAccountHolderId: values.PrimaryAccountHolder.clientId,
      secondaryAccountHolderIds: [values.SecondaryAccountHolder.clientId],
    };

    try {
      setIsMutating(true);

      await callPutPlanAccountInfo({
        planId: props.planId,
        virtualAccountId: props.accountId,
        accountData: form,
      });

      await callPutPlanAccountPeople({
        planId: props.planId,
        virtualAccountId: props.accountId,
        people: people,
      });

      statusNotification("Account updated", "Success");
      props.onClose();
    } catch (error) {
      const errorMessage = isAxiosErrorCode(400, error)
        ? extractAxiosErrorMessage(error)
        : "Error updating account";

      statusNotification(errorMessage, "Error");
      setIsMutating(false);
    }
  }, [
    getValues,
    isValid,
    targetGoalNonEmpty,
    callPutPlanAccountInfo,
    callPutPlanAccountPeople,
    props,
    statusNotification,
  ]);

  return (
    <Drawer
      transitionDuration={0}
      header={
        <DrawerHeader planId={props.planId} accountId={props.accountId} />
      }
      footer={
        <Footer
          onSave={submit}
          saveDisabled={!isValid}
          closeDrawer={props.onClose}
          isMutating={isMutating}
        />
      }
      isDrawerOpen
      onClose={props.onClose}
    >
      <FormProvider {...methods}>
        <FormH1>Joint TE</FormH1>

        <FormH2>Account Information</FormH2>

        <FlexWrapper>
          <FormTextField
            name="AccountName"
            label="Account Name"
            required="Must not be empty"
          />

          <FormDateField
            name="AgreementDate"
            label="Agreement Date"
            required="Must not be empty"
          />

          <FormDropdownField
            name="TenancyState"
            label="Joint Tenancy State"
            required="Must not be empty"
            values={USAStates.map((el) => ({ label: el }))}
          />

          <FormDropdownField
            name="Married"
            label="Joint Holders Married"
            required="Must not be empty"
            values={married.map((el) => ({ label: el }))}
          />

          <FormNumberField
            name={"TargetGoal"}
            startAdornment={"$"}
            required={
              targetDateNotEmpty
                ? "This is required when Target Date is set"
                : undefined
            }
            label="Target Goal (Required when Date set)"
          />

          <FormDateField
            required={
              targetGoalNonEmpty
                ? "This is required when Target Goal is set"
                : undefined
            }
            rules={{ deps: ["TargetGoal"] }}
            dateCheck={"AfterToday"}
            label="Target Date (Required when Goal set)"
            name={"TargetDate"}
          />
        </FlexWrapper>

        <Spacer />

        <FormH2>PEOPLE</FormH2>

        <FlexWrapper>
          <FormAccountHolderDropdownField
            name="PrimaryAccountHolder"
            label="Primary Account Holder"
            required="Must not be empty"
            rules={{
              deps: ["SecondaryAccountHolder"],
              validate: {
                uniqueHolders: () => {
                  const values = getValues();

                  return values["PrimaryAccountHolder"]?.clientId ===
                    values["SecondaryAccountHolder"]?.clientId
                    ? "Holders can't be the same"
                    : true;
                },
              },
            }}
          />

          <FormAccountHolderDropdownField
            name="SecondaryAccountHolder"
            label="Secondary Account Holder"
            required="Must not be empty"
            rules={{
              deps: ["PrimaryAccountHolder"],
              validate: {
                uniqueHolders: () => {
                  const values = getValues();

                  return values["PrimaryAccountHolder"]?.clientId ===
                    values["SecondaryAccountHolder"]?.clientId
                    ? "Holders can't be the same"
                    : true;
                },
              },
            }}
          />
        </FlexWrapper>
      </FormProvider>

      {isMutating && (
        <div className={styles.loading}>
          <LogoLoadingStill />
        </div>
      )}
    </Drawer>
  );
}

const nonEmptyValue = (value: string | undefined | null) => {
  return value !== undefined && value !== "" && value !== null;
};

interface FooterProps {
  closeDrawer: () => void;
  saveDisabled: boolean;
  onSave: () => void;
  isMutating: boolean;
}

const Footer = (props: FooterProps): JSX.Element => {
  return (
    <div className={styles.footer}>
      <ButtonPrimary
        text={"Cancel"}
        onClick={props.closeDrawer}
        variant={"outlined"}
        buttonType={"primary"}
      />

      <ButtonPrimary
        text={"Save"}
        onClick={props.onSave}
        variant={"contained"}
        buttonType={"primary"}
        disabled={props.saveDisabled || props.isMutating}
      />
    </div>
  );
};
