/* eslint-disable react/prop-types */

import React, { Component, PropsWithChildren } from "react";

import styles from "./input3.module.css";

interface IncomingProps {
  handleInputChange: (e: any) => void;
  title: string;
  show_checkmark: boolean;
  underline_style: React.CSSProperties;
  placeholder: string;
  type: React.HTMLInputTypeAttribute;
  value: any;

  //Optional
  style?: React.CSSProperties;
  pre_text?: string;
  error_description?: string;
  title_img?: string | null;
  show_cred_container?: boolean;
  header_margin_bottom?: string;
  validation_img?: string | null;
  onFocus?: () => void;
  input_shell_margin?: object;
  disabled?: boolean;
  name?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  right_img?: {
    src: string;
    onClick?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  };
}

type InitialState = Record<string, never>;

class Input_Component extends Component<
  PropsWithChildren<IncomingProps>,
  InitialState
> {
  render() {
    return (
      <div style={this.props.style}>
        {this.props.show_cred_container && (
          <div
            className={styles.cred_container}
            style={
              this.props.header_margin_bottom
                ? { marginBottom: this.props.header_margin_bottom }
                : {}
            }
          >
            <div className={styles.cred_container_leftSide}>
              {this.props.title_img && (
                <img src={this.props.title_img} className={styles.cred_img} />
              )}
              <div className={styles.input_title}>{this.props.title}</div>
              {this.props.children}
            </div>
            {((this.props.error_description &&
              this.props.error_description.length > 0) ||
              this.props.show_checkmark) && (
              <img
                src={this.props.validation_img ? this.props.validation_img : ""}
                className={styles.validation_img}
              ></img>
            )}
          </div>
        )}

        <div
          className={styles.input_shell}
          style={{
            ...this.props.underline_style,
            ...(this.props.input_shell_margin && {
              ...this.props.input_shell_margin,
            }),
          }}
        >
          {this.props.pre_text && (
            <p className={styles.pre_text}>{this.props.pre_text}</p>
          )}
          <input
            className={`${styles.input} input_3_selectable_classname`}
            placeholder={this.props.placeholder}
            autoComplete="off"
            name={this.props.name}
            type={this.props.type}
            onChange={this.props.handleInputChange}
            value={this.props.value}
            onFocus={() =>
              this.props.onFocus ? this.props.onFocus() : undefined
            }
            onKeyDown={this.props.onKeyDown}
            disabled={this.props.disabled ? true : false}
          />
          {this.props.right_img && (
            <img
              src={this.props.right_img.src}
              onClick={
                this.props.right_img.onClick
                  ? this.props.right_img.onClick
                  : () => undefined
              }
            />
          )}
        </div>

        {this.props.error_description &&
          this.props.error_description.length > 0 && (
            <div className={styles.error_text}>
              {this.props.error_description}
            </div>
          )}
      </div>
    );
  }
}

export default Input_Component;
