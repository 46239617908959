import React from "react";

import { useHistory, useParams } from "react-router-dom";

import { ClientId, FartherAccountId } from "@fartherfinance/frontend/api/Types";

import AccountSettings from "../../../Settings";
import { AccountsPath } from "@src/config/routing/RouterPaths";
import RenameAccountModal from "@src/multiCustodian/components/Client/Accounts/RenameAccountModal/RenameAccountModal";

const RenameAccount = (): JSX.Element => {
  const { clientId, accountId } = useParams<{
    clientId: ClientId;
    accountId: FartherAccountId;
  }>();

  const history = useHistory();

  return (
    <AccountSettings>
      <RenameAccountModal
        closeModal={() =>
          history.push(
            `/Client/${clientId}/${AccountsPath}/Managed/${accountId}/Settings`
          )
        }
      />
    </AccountSettings>
  );
};

export default RenameAccount;
