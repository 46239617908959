import React, { useCallback, useEffect, useMemo, useState } from "react";

import { isAxiosError } from "axios";
import { FormProvider, useForm, useFormState } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import usePatchEquivalentSecurityList from "@fartherfinance/frontend/api/EquivalentSecurities/hooks/usePatchEquivalentSecurityList";
import usePostEquivalentSecurityList from "@fartherfinance/frontend/api/EquivalentSecurities/hooks/usePostEquivalentSecurityList";
import usePutEquivalentSecurityList from "@fartherfinance/frontend/api/EquivalentSecurities/hooks/usePutEquivalentSecurityList";
import { EquivalentSecurityListBody } from "@fartherfinance/frontend/api/EquivalentSecurities/requests/postEquivalentSecurityList";
import { EquivalentSecurityListMembers } from "@fartherfinance/frontend/api/EquivalentSecurities/requests/putEquivalentSecurityList";
import {
  EquivalentSecurityListId,
  SecurityId,
  Ticker,
} from "@fartherfinance/frontend/api/Types";

import PageTitle from "@src/multiCustodian/components/Advisor/Investments/SharedComponents/PageTitle";
import BackButton from "@src/multiCustodian/components/Client/BackButton";
import ButtonPrimary from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import FormTextField from "@src/sharedComponents/Forms/FormTextField";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";
import { pluralize } from "@src/utils/pluralize";

import SecuritySearchTable from "./SecuritySearchTable";

import styles from "./EquivalentSecuritiesForm.module.css";

interface SelectedEquivalentSecurity {
  ticker: Ticker;
  description: string;
  securityId: SecurityId;
}

const ListNameField = "ListName";
const ListDescriptionField = "ListDescription";

interface Form {
  [ListNameField]: string | undefined;
  [ListDescriptionField]: string | undefined;
}

interface Props {
  isUpdate: boolean;
  members: SelectedEquivalentSecurity[] | null;
  name: string | undefined;
  description: string | undefined;
}

const EquivalentSecuritiesForm: React.FC<Props> = ({
  isUpdate,
  members,
  name,
  description,
}) => {
  const { listId } = useParams<{
    listId: EquivalentSecurityListId;
  }>();

  const history = useHistory();

  const [mutating, setMutating] = useState(false);

  const [securitiesAreTheSame, setSecuritiesAreTheSame] = useState(true);

  const [selectedSecurities, setSelectedSecurities] = useState<
    SelectedEquivalentSecurity[]
  >(
    !members
      ? []
      : members.map((s) => ({
          ticker: s.ticker as Ticker,
          description: s.description,
          securityId: s.securityId,
        }))
  );

  const statusNotification = useStatusNotification();

  const auth = useAdvisorRequestAuth();
  const postEquivalentSecurityList = usePostEquivalentSecurityList(auth);
  const putEquivalentSecurityList = usePutEquivalentSecurityList(listId, auth);
  const patchEquivalentSecurityList = usePatchEquivalentSecurityList(
    listId,
    auth
  );

  const defaultValues: Partial<Form> = useMemo(() => {
    return {
      [ListNameField]: name ?? "",
      [ListDescriptionField]: description ?? "",
    };
  }, [name, description]);

  const methods = useForm<Form>({
    mode: "all",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    defaultValues,
  });

  const { control, getValues, setValue } = methods;

  const { isValid, isDirty } = useFormState({
    control,
  });

  useEffect(() => {
    setValue(ListNameField, name);
    setValue(ListDescriptionField, description);
  }, [name, description, setValue]); // eslint-disable-next-line

  useEffect(() => {
    setSelectedSecurities(
      !members
        ? []
        : members.map((s) => ({
            ticker: s.ticker as Ticker,
            description: s.description,
            securityId: s.securityId,
          }))
    );
  }, [members]);

  const getSecuritiesAreTheSame = useCallback(
    (newSelectedSecurities: SelectedEquivalentSecurity[]) => {
      const sameLength =
        (members ?? []).length === newSelectedSecurities.length;

      return (
        sameLength &&
        (members ?? []).every((mem) =>
          newSelectedSecurities.some((sec) => sec.securityId === mem.securityId)
        )
      );
    },
    [members]
  );

  const addSecurity = (sec: SelectedEquivalentSecurity) => {
    if (selectedSecurities.some((s) => s.securityId === sec.securityId)) {
      statusNotification("Security already added", "Success");
      return;
    }

    const newSelectedSecurities = [
      ...selectedSecurities,
      {
        ticker: sec.ticker,
        description: sec.description,
        securityId: sec.securityId,
      },
    ];

    setSecuritiesAreTheSame(getSecuritiesAreTheSame(newSelectedSecurities));
    setSelectedSecurities(newSelectedSecurities);
  };

  const removeSecurity = (securityId: SecurityId) => {
    const newSelectedSecurities = selectedSecurities.filter(
      (s) => s.securityId !== securityId
    );

    setSecuritiesAreTheSame(getSecuritiesAreTheSame(newSelectedSecurities));
    setSelectedSecurities(newSelectedSecurities);
  };

  const backToAdminPage = useCallback(
    () =>
      history.push({
        pathname: "/Advisor/Admin",
        state: history.location.state,
      }),
    [history]
  );

  const backToDrawer = useCallback(
    () =>
      history.push({
        pathname: `/Advisor/Admin/EquivalentSecurities/${listId}`,
        state: history.location.state,
      }),
    [history, listId]
  );

  const onBack = useCallback(
    () => (isUpdate ? backToDrawer() : backToAdminPage()),
    [isUpdate, backToDrawer, backToAdminPage]
  );

  const onCreate = useCallback(async () => {
    const values = getValues();

    if (!isValid || !values[ListNameField]) {
      statusNotification("Form is incomplete", "Error");
      return;
    }

    if (mutating) {
      statusNotification("Form is being submitted", "Error");
      return;
    }

    const members = selectedSecurities.map((sec) => {
      return {
        securityId: sec.securityId,
      };
    });

    const body: EquivalentSecurityListBody = {
      name: values[ListNameField],
      description: values[ListDescriptionField] ?? "",
      members: members,
    };

    try {
      setMutating(true);

      await postEquivalentSecurityList(body);

      statusNotification("List created", "Success");
      setMutating(false);
      backToAdminPage();
    } catch (e) {
      const serverMsg = isAxiosError(e) ? e.response?.data?.message : null;
      const errorMsg = serverMsg ?? "Failed to create list";
      statusNotification(`${errorMsg}`, "Error");
      setMutating(false);
    }
  }, [
    backToAdminPage,
    isValid,
    mutating,
    getValues,
    postEquivalentSecurityList,
    selectedSecurities,
    statusNotification,
  ]);

  const onUpdate = useCallback(async () => {
    const values = getValues();

    if (!isValid || !values[ListNameField]) {
      statusNotification("Form is incomplete", "Error");
      return;
    }

    if (mutating) {
      statusNotification("Form is being submitted", "Error");
      return;
    }

    try {
      setMutating(true);

      if (
        name !== values[ListNameField] ||
        description !== values[ListDescriptionField]
      ) {
        await patchEquivalentSecurityList({
          name: values[ListNameField],
          description: values[ListDescriptionField] ?? "",
        });
      }

      if (!securitiesAreTheSame) {
        const members = selectedSecurities.map((sec) => {
          return {
            securityId: sec.securityId,
          };
        });

        const body: EquivalentSecurityListMembers = {
          members: members,
        };

        await putEquivalentSecurityList(body);
      }

      statusNotification("List updated", "Success");
      setMutating(false);
      backToDrawer();
    } catch (e) {
      const serverMsg = isAxiosError(e) ? e.response?.data?.message : null;
      const errorMsg = serverMsg ?? "Failed to update list";
      statusNotification(`${errorMsg}`, "Error");
      setMutating(false);
    }
  }, [
    backToDrawer,
    isValid,
    mutating,
    getValues,
    putEquivalentSecurityList,
    patchEquivalentSecurityList,
    selectedSecurities,
    statusNotification,
    description,
    name,
    securitiesAreTheSame,
  ]);

  return (
    <div className={styles.container}>
      <div className={styles.centeringContainer}>
        <BackButton onClick={onBack} />

        <div className={styles.bodyContainer}>
          {mutating && (
            <div className={styles.loading}>
              <LogoLoadingStill onTop />
            </div>
          )}

          <PageTitle>{`${
            isUpdate ? "Update" : "Create"
          } a List of Equivalent Securities`}</PageTitle>

          <div className={toClassName(styles.headerMargin, styles.textBold)}>
            List Details
          </div>

          <FormProvider {...methods}>
            <div className={styles.textFields}>
              <FormTextField
                name={ListNameField}
                label="Name"
                required="Must not be empty"
              />

              <FormTextField
                name={ListDescriptionField}
                label="Description (Optional)"
              />
            </div>

            <div
              className={toClassName(styles.headerMargin, styles.tableHeader)}
            >
              <div className={styles.textBold}>
                List of Equivalent Securities
              </div>

              <div className={styles.textSubtle}>
                {pluralize(selectedSecurities, "Security", true)}
              </div>
            </div>

            <SecuritySearchTable
              selectedSecurities={selectedSecurities}
              addSecurity={addSecurity}
              removeSecurity={removeSecurity}
            />
          </FormProvider>

          <div className={styles.footer}>
            {isUpdate ? (
              <ButtonPrimary
                text="Update List"
                variant="contained"
                onClick={onUpdate}
                buttonType="primary"
                disabled={
                  selectedSecurities.length <= 1 ||
                  !isValid ||
                  (!isDirty && securitiesAreTheSame)
                }
              />
            ) : (
              <ButtonPrimary
                text="Create List"
                variant="contained"
                onClick={onCreate}
                buttonType="primary"
                disabled={selectedSecurities.length <= 1 || !isValid}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EquivalentSecuritiesForm;
