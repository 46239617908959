import {
  ManualAccount,
  PlaidAccount,
} from "@fartherfinance/frontend/api/ExternalAccount/requests/getAllExternalAccounts";

export const getExternalPlaidAccountBalance = (
  acc: PlaidAccount
): number | null => {
  const multiplier =
    acc.details.account.accountType === "credit" ||
    acc.details.account.accountType === "loan"
      ? -1
      : 1;

  const currentBalance =
    acc.details.account.currentBalance !== null
      ? acc.details.account.currentBalance * multiplier
      : null;

  const availableBalance =
    acc.details.account.availableBalance !== null
      ? acc.details.account.availableBalance * multiplier
      : null;

  return acc.details.account.connectionHealthType === "healthy"
    ? currentBalance ?? availableBalance
    : null;
};

export const isPlaidAccount = (
  acc: PlaidAccount | ManualAccount
): acc is PlaidAccount => {
  return acc.accountProvider === "plaid";
};
