export const prefixOptions = [
  "Dr",
  "Mdm",
  "Mr",
  "Ms",
  "Mrs",
  "Miss",
  "Sir",
  "Prof",
] as const;

export type Prefix = typeof prefixOptions[number];
