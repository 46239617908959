import React, { useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import usePortfolioV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/usePortfolioV2";
import useToggleCustomPortfolioV2 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useToggleCustomPortfolioV2";
import useToggleEssentialPortfolio from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useToggleEssentialPortfolio";
import { PortfolioId } from "@fartherfinance/frontend/api/Types";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import Modal from "@src/sharedComponents/Modal/Modal";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./ArchiveModelModal.module.css";

const ArchiveModelModal = (): JSX.Element => {
  const [isMutating, setIsMutating] = useState<boolean>(false);

  const { portfolioId, sharingOrModelDetails } = useParams<{
    portfolioId: PortfolioId;
    sharingOrModelDetails: "Sharing" | "ModelDetails";
  }>();

  const history = useHistory();

  useEffect(() => {
    if (
      sharingOrModelDetails === "Sharing" ||
      sharingOrModelDetails === "ModelDetails"
    ) {
      return;
    } else {
      history.push({
        ...history.location,
        pathname: `/Advisor/Investments/ModelMarketplace/${portfolioId}/ModelDetails`,
      });
    }
  }, [history, portfolioId, sharingOrModelDetails]);

  const statusNotification = useStatusNotification();

  const auth = useAdvisorRequestAuth();
  const portfolio = usePortfolioV2(portfolioId, auth);
  const toggleEssentialPortfolio = useToggleEssentialPortfolio(auth);
  const toggleCustomPortfolio = useToggleCustomPortfolioV2(auth);

  const archiveModel = async () => {
    if (portfolio.data === undefined) {
      return;
    }

    if (portfolio.data.model.activeClients >= 1) {
      history.push({
        ...history.location,
        pathname: `/Advisor/Investments/ModelMarketplace/${portfolioId}/${sharingOrModelDetails}/Replace`,
      });
    } else {
      try {
        setIsMutating(true);

        if (portfolio.data.model.portfolioType === "Custom") {
          await toggleCustomPortfolio(portfolioId);
        } else {
          await toggleEssentialPortfolio(portfolioId);
        }

        statusNotification("Model archived", "Success");
        setIsMutating(false);
        history.push({
          ...history.location,
          pathname: `/Advisor/Investments/ModelMarketplace/${portfolioId}/${sharingOrModelDetails}`,
        });
      } catch (_e) {
        statusNotification("Error archiving model", "Error");
        setIsMutating(false);
      }
    }
  };

  if (portfolio.hasError) {
    return <div>Error retrieving models</div>;
  }

  if (isMutating || portfolio.isLoading) {
    return (
      <Modal
        closeModal={() =>
          history.push({
            ...history.location,
            pathname: `/Advisor/Investments/ModelMarketplace/${portfolioId}/${sharingOrModelDetails}`,
          })
        }
        modalStyle={{ width: "520px" }}
      >
        <div className={styles.modalTitle}>
          Once archived, this model cannot be used for rebalancing.
        </div>

        <div className={styles.modalText}>
          You can still view the model from the dropdown on the main Model
          Portfolios page. To use this model in the future, you will need to
          make it active.
        </div>

        <div className={styles.modalFooter}>
          <Skeleton
            width={70}
            height={35}
            style={{ transform: "none", marginRight: "10px" }}
          />

          <Skeleton width={100} height={35} style={{ transform: "none" }} />
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      closeModal={() =>
        history.push({
          ...history.location,
          pathname: `/Advisor/Investments/ModelMarketplace/${portfolioId}/${sharingOrModelDetails}`,
        })
      }
      modalStyle={{ width: "520px" }}
    >
      <div className={styles.modalTitle}>
        Once archived, this model cannot be used for rebalancing.
      </div>

      <div className={styles.modalText}>
        You can still view the model from the dropdown on the main Model
        Portfolios page. To use this model in the future, you will need to make
        it active.
      </div>

      <div className={styles.modalFooter}>
        <Button
          variant={"outlined"}
          buttonType={"secondary"}
          text={"Cancel"}
          onClick={() =>
            history.push({
              ...history.location,
              pathname: `/Advisor/Investments/ModelMarketplace/${portfolioId}/${sharingOrModelDetails}`,
            })
          }
        />

        <Button
          variant={
            portfolio.data.model.activeClients === 0 ? "outlined" : "contained"
          }
          buttonType={
            portfolio.data.model.activeClients === 0 ? "warning" : "primary"
          }
          text={
            portfolio.data.model.activeClients === 0
              ? "Archive Model Portfolio"
              : "Continue"
          }
          onClick={archiveModel}
          style={{ marginLeft: "15px" }}
        />
      </div>
    </Modal>
  );
};

export default ArchiveModelModal;
