import React from "react";

import { TaxBudget } from "@fartherfinance/frontend/api/InvestmentModel/Types";

import CurrentBudget from "../CurrentBudget/CurrentBudget";
import FutureBudgets from "../FutureBudgets/FutureBudgets";

interface Props {
  currentBudget?: TaxBudget;
  futureBudgets: TaxBudget[];
}

const TaxBudgetList: React.FC<Props> = ({ currentBudget, futureBudgets }) => {
  return (
    <div>
      <CurrentBudget budget={currentBudget} />
      <FutureBudgets budgets={futureBudgets} />
    </div>
  );
};

export default TaxBudgetList;
