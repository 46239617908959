import roundToIntIfNegligibleDecimal from "./roundToIntIfNegligibleDecimal";

export const weightAdder =
  <T>(access: (x: T) => string) =>
  (obj: T[]): number => {
    const sumAsInt: number = obj.reduce((accum, curr) => {
      const parsed = parseFloat(access(curr) ?? "NaN");

      if (isNaN(parsed)) {
        console.error(`Detected parsed NaN for ${JSON.stringify(curr)}`);
        return accum;
      }

      // due to floating point, do the addition as an integer, convert back to floating point
      // we use `100` because we limit to 2 decimal places
      return roundToIntIfNegligibleDecimal(accum + parsed * 100);
    }, 0);

    return roundToIntIfNegligibleDecimal(sumAsInt / 100);
  };
