import { useCallback } from "react";

import * as Sentry from "@sentry/react";
import ms from "ms";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import advisorLogin, {
  defaultMetaData,
} from "@fartherfinance/frontend/api/advisorLogin";
import getAdvisorInfo from "@fartherfinance/frontend/api/Dashboard/requests/getAdvisorInfo";

import {
  IsAdminSessionKey,
  LoggedInStateSessionKey,
} from "@src/constants/localStorageKeys";
import {
  identify,
  setUserInfo,
  trackEvent,
} from "@src/multiCustodian/services/tracking";
import isLocalStorageAvailable from "@src/multiCustodian/utils/isLocalStorageAvailable";
import { MCReduxAction } from "@src/store";

export interface LoginInfo {
  magicToken: string;
  advisorEmail: string;
}

const enableLocalStorageCache =
  process.env.WEBAPP_ENV === "UAT" && isLocalStorageAvailable();
const enableSessionStorage = true;

export default function useAdvisorFartherLogin() {
  const dispatch = useDispatch<Dispatch<MCReduxAction>>();

  const advisorFartherLogin = useCallback(
    async (auth: LoginInfo) => {
      console.log(`Logging in with email ${auth.advisorEmail}`);

      try {
        if (enableLocalStorageCache) {
          const storedLoginRes = enableLocalStorageCache
            ? localStorage.getItem(`advisor.info.mini.${auth.advisorEmail}`)
            : null;

          if (storedLoginRes !== null) {
            const loginRes = JSON.parse(storedLoginRes);
            dispatch({
              type: "SET_IS_LOGGED_IN_AS_ADVISOR_STATE",
              payload: {
                isLoggedIn: true,
                farther_jwt: auth.magicToken,
                isAdvisor: true,
                cur_user: null,
                cockroach_advisor_id: loginRes.advisorId,
              },
            });

            const storedAdvisorInfo = enableLocalStorageCache
              ? localStorage.getItem(`advisor.info.${loginRes.advisorId}`)
              : null;

            if (storedAdvisorInfo !== null) {
              const advisorInfo = JSON.parse(storedAdvisorInfo);

              if (advisorInfo.isAdmin) {
                dispatch({
                  type: "SET_ISADMIN",
                  payload: {
                    isAdmin: advisorInfo.isAdmin,
                  },
                });
              }
            }
          }
        }
      } catch (e) {
        console.error("LocalStorage error", e);
      }

      const loginRes = await advisorLogin(
        auth.magicToken,
        auth.advisorEmail,
        defaultMetaData
      );

      const loggedInPayload = {
        isLoggedIn: true,
        farther_jwt: auth.magicToken,
        isAdvisor: true,
        cur_user: null,
        cockroach_advisor_id: loginRes.advisorId,
      };

      dispatch({
        type: "SET_IS_LOGGED_IN_AS_ADVISOR_STATE",
        payload: loggedInPayload,
      });

      try {
        if (enableSessionStorage) {
          sessionStorage.setItem(
            LoggedInStateSessionKey,
            JSON.stringify(loggedInPayload)
          );
        }
      } catch (e) {
        console.error(e);
      }

      try {
        if (enableLocalStorageCache) {
          localStorage.setItem(
            `advisor.info.mini.${auth.advisorEmail}`,
            JSON.stringify(loginRes)
          );
        }
      } catch (e) {
        console.error("LocalStorage error", e);
      }

      if (process.env.WEBAPP_ENV !== "PROD") {
        const d = new Date();

        d.setTime(d.getTime() + ms("1year"));

        const expires = "expires=" + d.toUTCString();

        document.cookie =
          "farther_email=" +
          auth.advisorEmail +
          ";" +
          expires +
          ";path=/;samesite=strict";
      }

      identify(loginRes.advisorId);
      trackEvent({ name: "Advisor Login" });

      // Check if we are an admin

      const advisorInfo = await getAdvisorInfo(loginRes.advisorId, {
        advisorId: loginRes.advisorId,
        jwt: auth.magicToken,
      });

      try {
        if (enableLocalStorageCache) {
          localStorage.setItem(
            `advisor.info.${loginRes.advisorId}`,
            JSON.stringify(advisorInfo)
          );
        }
      } catch (e) {
        console.error("LocalStorage error", e);
      }

      setUserInfo({
        id: loginRes.advisorId,
        isAdvisor: true,
        isAdmin: advisorInfo.isAdmin,
        email: auth.advisorEmail,
      });

      if (advisorInfo.isAdmin) {
        const isAdminPayload = {
          isAdmin: advisorInfo.isAdmin,
        };

        dispatch({
          type: "SET_ISADMIN",
          payload: isAdminPayload,
        });

        try {
          if (enableSessionStorage) {
            sessionStorage.setItem(
              IsAdminSessionKey,
              JSON.stringify(isAdminPayload)
            );
          }
        } catch (e) {
          console.error("SessionStorage error", e);
        }

        Sentry.setUser({
          isAdmin: advisorInfo.isAdmin,
        });
      }
    },
    [dispatch]
  );

  return advisorFartherLogin;
}
