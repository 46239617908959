import React, { useCallback } from "react";

import { FormProvider, useForm, useFormState } from "react-hook-form";
import { useParams } from "react-router-dom";

import { ClientId } from "@fartherfinance/frontend/api/Types";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useClientNameForId from "@src/multiCustodian/hooks/Advisor/useClientNameForId";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import FormAccountHolderDropdownField, {
  AutocompleteClient,
} from "@src/sharedComponents/Forms/FormAccountHolderDropdownField";
import Modal from "@src/sharedComponents/Modal/Modal";

import styles from "./SelectClientModal.module.css";

interface Form {
  spouse: AutocompleteClient | undefined;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (clientId: ClientId) => void;
}

const SelectClientModal: React.FC<Props> = ({ isOpen, onClose, onSelect }) => {
  const { clientId } = useParams<{ clientId: ClientId }>();

  const auth = useAdvisorRequestAuth();

  const clientName = useClientNameForId(clientId, auth);

  const methods = useForm<Form>({
    mode: "all",
    reValidateMode: "onChange",
  });

  const { control, getValues } = methods;

  const { isValid } = useFormState({
    control,
  });

  const handleOnClick = useCallback(() => {
    const spouseClientId = getValues("spouse")?.clientId;

    if (spouseClientId) {
      onSelect(spouseClientId);
    }
  }, [getValues, onSelect]);

  return (
    <FormProvider {...methods}>
      <Modal
        closeModal={onClose}
        isOpen={isOpen}
        modalStyle={{ overflow: "visible" }}
      >
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.title}>{`Identify ${
              clientName?.name.first || "Client"
            }'s Spouse`}</div>
            <div className={styles.subtitle}>
              {`Type the name of ${
                clientName?.name.first || "Client"
              }'s spouse in the field below.`}
            </div>
          </div>
          <div className={styles.inputContainer}>
            <FormAccountHolderDropdownField
              name="spouse"
              label="Spouse Name"
              required="Must not be empty"
              rules={{
                validate: {
                  uniqueHolders: () => {
                    const values = getValues();

                    return values["spouse"]?.clientId === clientId
                      ? "Spouse can't be the same as client"
                      : true;
                  },
                },
              }}
            />
          </div>
          <div className={styles.footer}>
            <Button
              text="Cancel"
              variant="outlined"
              buttonType="primary"
              onClick={onClose}
              sx={{ width: "130px" }}
            />
            <Button
              disabled={!isValid}
              text="Add Spouse"
              variant="contained"
              buttonType="primary"
              onClick={handleOnClick}
              sx={{ width: "130px" }}
            />
          </div>
        </div>
      </Modal>
    </FormProvider>
  );
};

export default SelectClientModal;
