import React from "react";

import RefreshIcon from "@mui/icons-material/Refresh";

import DocumentsExplorerMessage from "./DocumentsExplorerMessage";

interface DocumentsExplorerErrorStateProps {
  onCallToActionClick: () => void;
}

const DocumentsExplorerErrorState: React.FC<
  DocumentsExplorerErrorStateProps
> = ({ onCallToActionClick }) => {
  return (
    <DocumentsExplorerMessage
      title="No files"
      subtitle="Files failed to load, try reloading"
      action={{
        label: "Reload",
        icon: <RefreshIcon />,
        onClick: onCallToActionClick,
      }}
    />
  );
};

export default DocumentsExplorerErrorState;
