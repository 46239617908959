import { TASK_TITLE_MAX_LENGTH } from "@fartherfinance/frontend/api/Tasks/constants/common";
import type {
  GetTasksQueryParams,
  TasksFilterQueryParams,
} from "@fartherfinance/frontend/api/Tasks/requests/getTasks";
import type { AssigneePayload } from "@fartherfinance/frontend/api/Tasks/requests/types";
import {
  TaskPriority,
  TaskStatus,
} from "@fartherfinance/frontend/api/Tasks/Types";
import { Custodian, SortOrder } from "@fartherfinance/frontend/api/Types";

import type { EnhancedAutocompleteOption } from "@src/multiCustodian/pages/Advisor/common";

export const TASKS_DEFAULT_PAGE = 1;
const TASKS_DEFAULT_PAGE_SIZE = 20;

export const TASKS_DEFAULT_FILTERING_QUERY_PARAMS: TasksFilterQueryParams = {
  filterByTaskStatus: [
    TaskStatus.enum.NOT_STARTED,
    TaskStatus.enum.IN_PROGRESS,
    TaskStatus.enum.WAITING_ON_CLIENT,
    TaskStatus.enum.WAITING_ON_CLIENT_SERVICES,
    TaskStatus.enum.WAITING_ON_ADVISOR,
    TaskStatus.enum.WAITING_ON_CUSTODIAN,
    TaskStatus.enum.AWAITING_RESPONSE,
  ],
  filterByTaskPriority: [
    TaskPriority.enum.LOW,
    TaskPriority.enum.MEDIUM,
    TaskPriority.enum.HIGH,
  ],
};

export const TASKS_DEFAULT_QUERY_PARAMS: GetTasksQueryParams = {
  page: TASKS_DEFAULT_PAGE,
  pageSize: TASKS_DEFAULT_PAGE_SIZE,
  sort: `${SortOrder.Ascending}dueDate`,
  ...TASKS_DEFAULT_FILTERING_QUERY_PARAMS,
};

export const ATTACHMENTS_LIMIT_COUNT = 5;
export const TASK_TITLE_MAX_LENGTH_ERROR_MSG = `Task title cannot exceed ${TASK_TITLE_MAX_LENGTH} characters`;

export const custodianOptions = Custodian.options.map((custodian) => ({
  label: custodian,
  value: custodian,
}));

export type TaskAssigneeAutocompleteOption<T extends string = string> =
  EnhancedAutocompleteOption<T> & {
    meta: Pick<AssigneePayload, "type">;
  };
