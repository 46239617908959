import React, { useMemo } from "react";

import {
  CreateEssentialPortfolio,
  UpdateEssentialPortfolio,
} from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";

import { taxTypeToStatus } from "../../utils/taxType";
import Chip from "@src/sharedComponents/Chip/Chip";
import { FartherTableBodyCell } from "@src/sharedComponents/Table/Components";
import FullDataTable from "@src/sharedComponents/Table/FullDataTable";
import { Cell, Row } from "@src/sharedComponents/Table/Types";
import { pluralize } from "@src/utils/pluralize";

import ExpandableRow from "./SecurityColumnsTable/SecurityColumnsTable";

import styles from "./ModelSecuritiesTable.module.css";

export const tableKeys = [
  "Model Name",
  "Direct-Indexed",
  "Tax Status",
] as const;

export type TableKey = typeof tableKeys[number];

export const rowsPerPage = 10;

type Portfolio = CreateEssentialPortfolio | UpdateEssentialPortfolio;

interface Props {
  partnerModels: Portfolio[];
}

const ModelSecuritiesTable = (props: Props): JSX.Element => {
  const rows: Row<TableKey, Cell>[] = useMemo(() => {
    return props.partnerModels.map((p) => {
      const modelNameFullValue = `${p.set} ${
        p.assetAllocation.split("/")[0]
      })}`;

      return {
        key: p.portfolioId ?? p.displayName,
        "Model Name": {
          value: (
            <div className={styles.modelName}>
              <span className={styles.nameText}>{p.displayName}</span>
              <div>
                {/* The chip is inside a div because if the span takes up two + lines, the chip expands vertically to fill the space */}
                <Chip
                  variant="outlined"
                  label={pluralize(p.positions, "Security", true)}
                />
              </div>
            </div>
          ),
          fullValue: modelNameFullValue,
        },
        "Direct-Indexed": { value: p.directIndexing ? "Direct" : "Indirect" },
        "Tax Status": { value: taxTypeToStatus[p.taxType] },
        //colSpan number must account for total number of columns (including the expand icon column)
        expandableDetails: (
          <FartherTableBodyCell
            className={styles.tableCellNoPadding}
            colSpan={tableKeys.length + 1}
          >
            <ExpandableRow securityWeights={p.positions} />
          </FartherTableBodyCell>
        ),
        onClick: "ExpandRow",
      };
    });
  }, [props.partnerModels]);

  return (
    <FullDataTable
      columns={tableKeys}
      rows={rows}
      defaultRowsPerPage={rowsPerPage}
      defaultSortColumn={["Model Name", "desc"]}
      disableColumnSorting={rows.length > 0 ? undefined : tableKeys}
      emptyCell={<Chip label="Placeholder" />} // This is the highest cell we will have
    />
  );
};

export default ModelSecuritiesTable;
