import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { format } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import { last, orderBy, throttle } from "lodash";
import { useHistory, useParams } from "react-router-dom";

import useFartherEmployees from "@fartherfinance/frontend/api/Entity/hooks/useFartherEmployees";
import type { Advisor } from "@fartherfinance/frontend/api/Entity/requests/getFartherEmployees";
import useGetBalances from "@fartherfinance/frontend/api/PerformanceGroups/hooks/SummaryScreen/useGetBalances";
import {
  DateRange,
  TimeFrame,
} from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Types";
import useAccountGroups from "@fartherfinance/frontend/api/PerformanceGroups/hooks/useAccountGroups";
import { RequestBody } from "@fartherfinance/frontend/api/PerformanceGroups/requests/getAccountGroups";
import { AdvisorId } from "@fartherfinance/frontend/api/Types";
import pipe from "@fartherfinance/frontend/utils/pipe";

import { DEFAULT_TIME_FRAME } from "../../Dashboard/Performance_Groups/shared";
import { dateFormat } from "@src/constants/dateFormat";
import AdvisorDropdown from "@src/multiCustodian/components/Advisor/BookAnalytics/AdvisorDropdown";
import AdvisorBookAnalyticsTable from "@src/multiCustodian/components/Advisor/BookAnalytics/Table";
import DateDisplay from "@src/multiCustodian/components/GraphComponents/Summary/DateDisplay/DateDisplay";
import TimeRangeSelector from "@src/multiCustodian/components/PerformanceGroups/Shared/TimeRangeSelector";
import EndDate from "@src/multiCustodian/components/PerformanceGroups/Summary/components/EndDate/EndDate";
import { usePerformanceCustomDateRangeContext } from "@src/multiCustodian/components/PerformanceGroups/Summary/components/PerformanceCustomDateRangeContextProvider";
import Legend from "@src/multiCustodian/components/PerformanceGroups/Summary/Legend";
import SummaryGraph from "@src/multiCustodian/components/PerformanceGroups/Summary/SummaryGraph";
import { timeFrameOptions } from "@src/multiCustodian/components/PerformanceGroups/Summary/SummaryGraphContainer";
import SummaryHeader from "@src/multiCustodian/components/PerformanceGroups/Summary/SummaryHeader";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import { AdvisorWithFullName } from "@src/multiCustodian/pages/Dashboard/Performance_Groups/tabs/SummaryTab/SummaryTabV4";
import PortaledChip from "@src/sharedComponents/PortaledChip/PortaledChip";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "@src/multiCustodian/components/Advisor/BookAnalytics/BookAnalytics.module.css";

const DEFAULT_ADVISOR = { first: "bryan", last: "d'alessandro" }; // default/fallback to an advisor that has good data. Use name as advisorId is not the same across environment

interface XAxisData {
  index: number;
  xPos: number;
  graphWidth: number;
}

const useGetAdvisorDropdownList = (
  companyNameOfEmployeeWithAdminRole: string | null
): AdvisorWithFullName[] => {
  const auth = useAdvisorRequestAuth();

  const employees = useFartherEmployees("All", auth);

  const advisors = useMemo((): Advisor[] => {
    if (employees.data && companyNameOfEmployeeWithAdminRole !== null) {
      return employees.data.advisors.filter((adv) => {
        // can view every advisor across all companies
        if (companyNameOfEmployeeWithAdminRole === "Farther") {
          return adv.roles.some((role) => role.endsWith("Advisor"));
        }

        // can ONLY view advisors at their company
        return adv.roles.some(
          (role) =>
            role.startsWith(companyNameOfEmployeeWithAdminRole) &&
            role.endsWith("Advisor")
        );
      });
    } else {
      return [];
    }
  }, [employees.data, companyNameOfEmployeeWithAdminRole]);

  const sortedAdvisors = useMemo(
    () =>
      orderBy(
        advisors.map((adv) => ({
          ...adv,
          fullName: `${adv.name.first} ${adv.name.last}`,
        })),
        (a) => a.fullName,
        "asc"
      ),
    [advisors]
  );

  return sortedAdvisors;
};

export default function BookAnalytics(
  props: PropsWithChildren<unknown>
): JSX.Element {
  const {
    enableBookAnalyticsCustomTimeFrame,
    enablePerformanceGroups,
    enableLowDataPerformanceGroups,
  } = useFlags();

  const [isHoveringOverGraph, setIsHoveringOverGraph] =
    useState<boolean>(false);

  const [xAxisData, setXAxisData] = useState<XAxisData | null>(null);

  const { advisorId } = useParams<{ advisorId: AdvisorId }>();

  const [currentDate, setCurrentDate] = useState<string | null>(null);

  const throttledSetCurrentDate = useMemo(
    () => throttle(setCurrentDate, 10),
    []
  );

  const [timeFrame, setTimeFrame] = useState<TimeFrame>("1Y");

  const history = useHistory();

  const auth = useAdvisorRequestAuth();

  const employees = useFartherEmployees("All", auth);

  const curLoggedInEmployee: Advisor | null = useMemo(() => {
    if (employees.data && auth) {
      return (
        employees.data?.advisors.find((a) => a.advisorId === auth.advisorId) ??
        null
      );
    } else {
      return null;
    }
  }, [auth, employees.data]);

  const hasEngineeringRole = useMemo(() => {
    return (
      curLoggedInEmployee?.roles.some((role) => role === "Engineering") ?? false
    );
  }, [curLoggedInEmployee]);

  const adminRole = useMemo(() => {
    return (
      curLoggedInEmployee?.roles.find((role) => role.endsWith("Admin")) ?? null
    );
  }, [curLoggedInEmployee]);

  const companyNameOfEmployeeWithAdminRole = hasEngineeringRole
    ? "Farther"
    : adminRole
    ? adminRole.split(" ")[0]
    : null;

  const canViewAdvisorsDropdownMenu =
    companyNameOfEmployeeWithAdminRole ?? false;

  const sortedAdvisors = useGetAdvisorDropdownList(
    companyNameOfEmployeeWithAdminRole
  );

  const hasAdvisorRole = useMemo(() => {
    return (
      curLoggedInEmployee?.roles.find((role) => role.endsWith("Advisor")) ??
      false
    );
  }, [curLoggedInEmployee]);

  useEffect(() => {
    if (
      auth &&
      employees.data &&
      !hasAdvisorRole &&
      advisorId === auth.advisorId &&
      sortedAdvisors.length > 0
    ) {
      const defaultAdvisor =
        employees.data?.advisors.find(
          (a) =>
            a.name.first.toLowerCase() === DEFAULT_ADVISOR.first &&
            a.name.last.toLowerCase().startsWith(DEFAULT_ADVISOR.last) // last names can end with extra info like: CFP® or EA
        ) ?? null;

      const defaultAdvisorId = defaultAdvisor
        ? defaultAdvisor.advisorId
        : sortedAdvisors[0].advisorId;

      // redirect to view Book for first advisor in list
      history.push(`/Advisor/BookAnalytics/Advisor/${defaultAdvisorId}`);
    }
  }, [auth, employees, hasAdvisorRole, history, sortedAdvisors, advisorId]);

  const hasPermissionToViewBAForAdvisorId = useMemo(() => {
    if (!auth) {
      return false;
    }

    if (auth.advisorId === advisorId) {
      return true;
    }

    if (companyNameOfEmployeeWithAdminRole === null) {
      return false;
    }

    if (companyNameOfEmployeeWithAdminRole === "Farther") {
      return true;
    }

    if (companyNameOfEmployeeWithAdminRole !== null) {
      // different company
      const targetAdvisor =
        employees.data?.advisors.find((a) => a.advisorId === advisorId) ?? null;
      if (targetAdvisor) {
        const targetAdvisorsAdvisorRole = targetAdvisor.roles.find((role) =>
          role.endsWith("Advisor")
        );
        if (targetAdvisorsAdvisorRole) {
          const company = targetAdvisorsAdvisorRole.split(" ")[0];
          return company === companyNameOfEmployeeWithAdminRole;
        }
      }

      return false;
    }

    return false;
  }, [auth, advisorId, companyNameOfEmployeeWithAdminRole, employees]);

  useEffect(() => {
    if (!auth || !employees.data || hasPermissionToViewBAForAdvisorId) {
      return;
    }

    // redirect to users advisorId or first in list as they do not have permission to view target advisorId (from url)
    if (sortedAdvisors.length > 0) {
      history.push(
        `/Advisor/BookAnalytics/Advisor/${sortedAdvisors[0].advisorId}`
      );
    }
    history.push(`/Advisor/BookAnalytics/Advisor/${auth.advisorId}`);
  }, [
    auth,
    employees,
    hasPermissionToViewBAForAdvisorId,
    history,
    sortedAdvisors,
  ]);

  const body: RequestBody | null = useMemo(
    () =>
      hasPermissionToViewBAForAdvisorId
        ? {
            personId: advisorId,
            dateAndReturnSettings: {
              dateRanges: null,
              feeMethod: "grossOfFees",
              returnMethod: "cumulative",
            },
          }
        : null,
    [advisorId, hasPermissionToViewBAForAdvisorId]
  );

  const accountGroups = useAccountGroups(
    body,
    enablePerformanceGroups ? auth : null
  );

  const currentAdvisor: AdvisorWithFullName | null = useMemo(
    () =>
      pipe(
        employees.data?.advisors.find((a) => a.advisorId === advisorId) ?? null,

        (a: Advisor): AdvisorWithFullName => ({
          ...a,
          fullName: `${a.name.first} ${a.name.last}`,
        })
      ),
    [advisorId, employees.data]
  );

  const accountGroup = useMemo(() => {
    return (
      accountGroups.data?.filter(
        (a) => a.accountGroupType === "AdvisorBook"
      )?.[0] ?? null
    );
  }, [accountGroups]);

  const accountGroupId = useMemo(() => {
    return accountGroup?.accountGroupId ?? null;
  }, [accountGroup?.accountGroupId]);

  const {
    customDateRange,
    showCustomDateRangeForm,
    setShowCustomDateRangeForm,
  } = usePerformanceCustomDateRangeContext();

  const customTimeFrame: DateRange | null = useMemo(() => {
    if (
      timeFrame !== "Custom" ||
      customDateRange.from === null ||
      customDateRange.to === null
    ) {
      return null;
    }

    return {
      startDate: format(customDateRange.from, dateFormat),
      endDate: format(customDateRange.to, dateFormat),
    };
  }, [timeFrame, customDateRange]);

  const balances = useGetBalances(
    accountGroupId,
    timeFrame,
    customTimeFrame,
    advisorId,
    enablePerformanceGroups ? auth : null
  );

  const graphData = useMemo(() => {
    return orderBy(
      (balances.data ?? []).map((b) => ({
        startDate: b.startDate,
        endDate: b.endDate,
        balanceOnEndDay: b.balanceEnd,
        relativeContributions: b.balanceStart + b.netAdditions,
        balanceStart: b.balanceStart,
        balanceEnd: b.balanceEnd,
        netGains: b.netGains,
        netAdditions: b.netAdditions,
        twr: b.returns,
        fees: b.fees,
      })),
      [(b) => b.endDate],
      ["asc"]
    );
  }, [balances.data]);

  const currentDataPoint = useMemo(() => {
    if (currentDate === null) {
      return last(graphData) ?? null;
    }

    const currentDateInData =
      graphData.find((d) => d.endDate === currentDate) ?? null;
    if (currentDateInData === null) {
      return last(graphData) ?? null;
    }

    return currentDateInData;
  }, [currentDate, graphData]);

  const changeXAxisData = useCallback((axisData: XAxisData): void => {
    setXAxisData(axisData);
  }, []);

  const throttledUpdate = useMemo(
    () => throttle(changeXAxisData, 100),
    [changeXAxisData]
  );

  const portaledChipLabel = useMemo(() => {
    if (customDateRange.from === null || customDateRange.to === null) {
      return "From - To -";
    }

    return `From: ${format(customDateRange.from, "M/d/yyyy")} - To: ${format(
      customDateRange.to,
      "M/d/yyyy"
    )}`;
  }, [customDateRange]);

  const filteredTimeFrameOptions = useMemo(() => {
    return timeFrameOptions(enableLowDataPerformanceGroups).filter(
      (tf) =>
        enableBookAnalyticsCustomTimeFrame ||
        (!enableBookAnalyticsCustomTimeFrame && tf !== "Custom") // hides custom date range
    );
  }, [enableBookAnalyticsCustomTimeFrame, enableLowDataPerformanceGroups]);

  if (accountGroups.hasError || balances.hasError) {
    return (
      <>
        <div className={styles.loading}>Error</div>

        {props.children}
      </>
    );
  }

  const noAccountGroups =
    accountGroups.data !== undefined && accountGroups.data.length === 0;

  if (noAccountGroups) {
    return (
      <div className={styles.noDataContainer}>
        <span>No performance data to show</span>

        {employees.isLoading || currentAdvisor === null ? (
          <Skeleton
            className={styles.dropdownContainer}
            width={120}
            height={40}
          />
        ) : canViewAdvisorsDropdownMenu ? (
          <div className={styles.dropdownContainer}>
            <AdvisorDropdown
              value={currentAdvisor}
              options={sortedAdvisors}
              onChange={(newAdvisorId) =>
                history.push({
                  ...history.location,
                  pathname: `/Advisor/BookAnalytics/Advisor/${newAdvisorId}`,
                })
              }
            />
          </div>
        ) : null}
      </div>
    );
  }

  const isLoading = accountGroups.isLoading || balances.isLoading;

  if (!enablePerformanceGroups) {
    return (
      <div className={styles.pageContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.centeringContainer}>Under maintenance</div>
        </div>
      </div>
    );
  }

  return (
    <>
      {timeFrame === "Custom" && (
        <PortaledChip
          portalMountElementId={"performanceCustomTimeFrameChip"}
          label={portaledChipLabel}
          onPress={() => setShowCustomDateRangeForm(!showCustomDateRangeForm)}
          onClear={() => {
            setTimeFrame(DEFAULT_TIME_FRAME);
          }}
        />
      )}

      <div className={styles.pageContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.centeringContainer}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flexGrow: 1 }}>
                <SummaryHeader
                  isLoading={isLoading}
                  showAdvisorVersion
                  balance={currentDataPoint?.balanceEnd ?? null}
                  twr={currentDataPoint?.twr ?? null}
                  netGains={currentDataPoint?.netGains ?? null}
                  timeRange={timeFrame}
                >
                  <Legend
                    displayVertical={true}
                    contributionsText={"Net Additions:"}
                  />
                </SummaryHeader>

                <div
                  onMouseEnter={() => setIsHoveringOverGraph(true)}
                  onMouseLeave={() => setIsHoveringOverGraph(false)}
                >
                  <SummaryGraph
                    isLoading={isLoading}
                    balanceSummary={graphData}
                    toDate={last(graphData)?.endDate ?? null}
                    updateDisplayData={throttledSetCurrentDate}
                    setXIndexAndPosition={(
                      xIndex: number,
                      xPos: number,
                      graphWidth: number
                    ) =>
                      throttledUpdate({
                        index: xIndex,
                        xPos: xPos,
                        graphWidth: graphWidth,
                      })
                    }
                    timeRange={timeFrame}
                  />
                </div>

                <DateDisplay
                  isHoveringOverGraph={isHoveringOverGraph}
                  startDate={currentDataPoint?.startDate ?? null}
                  xAxisData={xAxisData}
                  dateKey="endDate"
                  data={graphData}
                >
                  {!isHoveringOverGraph && (
                    <div className={styles.xAxisFloat}>
                      <TimeRangeSelector
                        isDisabled={isLoading}
                        timeRange={timeFrame}
                        timeFrameOptions={filteredTimeFrameOptions}
                        setTimeRange={setTimeFrame}
                      />
                    </div>
                  )}

                  {!isHoveringOverGraph && (
                    <EndDate endDate={last(graphData)?.endDate ?? null} />
                  )}
                </DateDisplay>
              </div>

              <div className={styles.tableDiv}>
                {enableLowDataPerformanceGroups ? null : employees.isLoading ||
                  currentAdvisor === null ? (
                  <div className={styles.dropdownContainer}>
                    <Skeleton
                      width={"100%"}
                      height={30}
                      style={{ transform: "none" }}
                    />
                  </div>
                ) : canViewAdvisorsDropdownMenu ? (
                  <div className={styles.dropdownContainer}>
                    <AdvisorDropdown
                      className={styles.width100}
                      value={currentAdvisor}
                      options={sortedAdvisors}
                      onChange={(newAdvisorId) =>
                        history.push(
                          `/Advisor/BookAnalytics/Advisor/${newAdvisorId}`
                        )
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}

                <AdvisorBookAnalyticsTable
                  isLoading={isLoading}
                  title={
                    currentAdvisor === null ? (
                      <Skeleton width={60} />
                    ) : (
                      `${currentAdvisor.name.first}'s Book`
                    )
                  }
                  balanceStart={currentDataPoint?.balanceStart ?? null}
                  balanceEnd={currentDataPoint?.balanceEnd ?? null}
                  netAdditions={currentDataPoint?.netAdditions ?? null}
                  marketGains={currentDataPoint?.netGains ?? null}
                  fees={currentDataPoint?.fees ?? null}
                />
              </div>
            </div>

            {props.children}
          </div>
        </div>
      </div>
    </>
  );
}
