import { isNil } from "lodash";
export default function formatAmount(
  input: string | number | null | undefined
): string {
  if (isNil(input)) {
    return "";
  }

  if (input === "") {
    return input;
  }

  if (typeof input === "string" && input.endsWith(".")) {
    return input;
  }

  if (typeof input === "string") {
    const normalized = input.replace(/,/g, "");

    const parsed = Number(normalized);

    if (isNaN(parsed)) {
      return input;
    }

    const decimalPlaces = normalized.split(".")[1]?.length || null;

    return formatter(parsed, decimalPlaces);
  } else {
    let decimalPlaces = null;
    // See if we have a decimal
    if (input !== 0 && input % Math.round(input) !== 0) {
      decimalPlaces = 2;
    }

    return formatter(input, decimalPlaces);
  }
}

function formatter(num: number, decimalPlaces: number | null): string {
  const decimalPlacesToUse = decimalPlaces
    ? Math.min(2, decimalPlaces)
    : undefined;

  return num.toLocaleString("en-US", {
    minimumFractionDigits: decimalPlacesToUse,
    maximumFractionDigits: decimalPlacesToUse,
  });
}
