import React from "react";

import { useParams } from "react-router-dom";

import { PortfolioId } from "@fartherfinance/frontend/api/Types";
import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import ModelMarketplace from "../ModelMarketplace";
import ModelMarketplaceModelDetailsTable from "@src/multiCustodian/components/Advisor/Investments/ModelMarketplace/ModelDetailsTable";
import ModelDrawer from "@src/multiCustodian/components/Advisor/Investments/ModelMarketplace/ModelDrawer/ModelDrawer";
import HistoricPerformanceTable from "@src/multiCustodian/components/Client/Portfolio/ModelAnalysis/HistoricPerformanceTable";
import PortfolioAnalysisAssetClassCategoryTable from "@src/multiCustodian/components/PortfolioAnalysis/PortfolioAnalysisAssetClassCategoryTable";

export default function ModelMarketplaceModelDetails(): JSX.Element {
  const { portfolioId } = useParams<{
    portfolioId: PortfolioId;
  }>();

  const {
    color: { $backgroundSubtle },
  } = useTheme();

  return (
    <ModelMarketplace>
      <ModelDrawer curTab="Model Details" portfolioId={portfolioId}>
        <ModelMarketplaceModelDetailsTable portfolioId={portfolioId} />

        <HistoricPerformanceTable portfolioId={portfolioId} />

        <PortfolioAnalysisAssetClassCategoryTable
          showDirectIndexedFilter={false}
          portfolioId={portfolioId}
          headerStyle={{ backgroundColor: $backgroundSubtle }}
        />
      </ModelDrawer>
    </ModelMarketplace>
  );
}
