import React, { PropsWithChildren } from "react";

import styles from "./HeaderWithSubtext.module.css";

interface Props {
  title: string;
  text: string;
}

const HeaderWithSubtext: React.FC<PropsWithChildren<Props>> = ({
  title,
  text,
  children,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.flexRow}>
        <div className={styles.title}>{title}</div>

        {children}
      </div>

      <div className={styles.text}>{text}</div>
    </div>
  );
};

export default HeaderWithSubtext;
