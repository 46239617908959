import React from "react";

import { MarketplaceAssetType } from "@fartherfinance/frontend/api/AltAssetsService/Types";

import InsuranceOfferingsListClient from "../OfferingsListClient/InsuranceOfferingsListClient";
import { InsuranceMarketplacePath } from "@src/config/routing/RouterPaths";
import { insuranceOfferingTypes } from "@src/multiCustodian/components/Marketplace/constants";

import OfferingDetailsClient from "./OfferingDetailsClient";

const InsuranceOfferingDetailsClient: React.FC = () => {
  return (
    <InsuranceOfferingsListClient>
      <OfferingDetailsClient
        assetType={MarketplaceAssetType.enum.insurance}
        offeringTypes={insuranceOfferingTypes}
        marketplacePath={InsuranceMarketplacePath}
      />
    </InsuranceOfferingsListClient>
  );
};

export default InsuranceOfferingDetailsClient;
