import React, { useMemo, useState } from "react";

import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { orderBy } from "lodash";
import { useHistory, useLocation, useParams } from "react-router-dom";

import useCustomPortfoliosV1 from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useCustomPortfoliosV1";
import usePortfolioPartners from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/usePortfolioPartners";
import { CustomPortfolioV1 } from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";
import { ClientId, HookResult } from "@fartherfinance/frontend/api/Types";

import { portfolioLocationState } from "../Portfolios";
import BaseLayout from "@src/multiCustodian/components/Layouts/BaseLayout/BaseLayout";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import OptionWithExpandCollapseContent from "@src/sharedComponents/OptionWithExpandCollapseContent/OptionWithExpandCollapseContent";

import BackAndExitButtonWrapper from "./Create/Custom/BackAndExitButtonWrapper";

import styles from "./Create.module.css";

export default function ChoosePortfolioType() {
  const [showPartnerModelOptions, setShowPartnerModelOptions] =
    useState<boolean>(false);
  const [showCustomModelOptions, setShowCustomModelOptions] =
    useState<boolean>(false);

  const history = useHistory();

  const { state } = useLocation();

  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const auth = useRequestAuth();
  const partners = usePortfolioPartners(auth);

  const customModels = useCustomPortfoliosV1(clientId, auth);

  const activeSharedModels: HookResult<CustomPortfolioV1[]> = useMemo(() => {
    if (customModels.isLoading || customModels.hasError) {
      return customModels;
    }

    const active = customModels.data.portfolios.filter(
      (p) => p.isActive === true
    );

    const notClientOwned = active.filter((p) => p.ownerId !== clientId);

    return {
      ...customModels,
      data: notClientOwned,
    };
  }, [clientId, customModels]);

  const choosePortfolioType = (type: string) => {
    history.push(`/Client/${clientId}/Investments/Portfolios/Create/${type}`);
  };

  const portfolioPartners = useMemo(() => {
    if (partners.isLoading || partners.hasError) {
      return partners;
    }

    return {
      ...partners,
      data: partners.data.portfolioPartners.filter(
        (p) => p.portfolioPartnerName !== "FAM" && p.isActive
      ),
    };
  }, [partners]);

  if (portfolioPartners.hasError || activeSharedModels.hasError) {
    const errored = [
      portfolioPartners.hasError ? "portfolio partners" : "",
      activeSharedModels.hasError ? "custom models" : "",
    ]
      .filter((x) => x !== "")
      .join(", ");

    return <div className={styles.text}>{errored} unavailable.</div>;
  }

  const showSharedModelTable =
    activeSharedModels.isLoading ||
    (activeSharedModels.data !== undefined &&
      activeSharedModels.data.length > 0);

  const backButtonOnClick = () =>
    history.push({
      pathname: `/Client/${clientId}/Investments/Portfolios`,
      state: { ...(state ?? {}), ...portfolioLocationState },
    });

  const exitButtonOnClick = () =>
    history.push({
      pathname: `/Client/${clientId}/Investments/Portfolios`,
      state: { ...(state ?? {}), ...portfolioLocationState },
    });

  return (
    <BaseLayout showSideNav={false}>
      <BackAndExitButtonWrapper
        backButtonOnClick={backButtonOnClick}
        exitButtonOnClick={exitButtonOnClick}
      >
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <div className={styles.heading}>
              Choose your type of Model Portfolio
            </div>

            <div
              className={styles.optionDiv}
              onClick={() => choosePortfolioType("FAM")}
            >
              <div>
                <div className={styles.optionHeading}>Farther</div>
                <div className={styles.optionText}>
                  Use one of Farther's model portfolios
                </div>
              </div>
              <ChevronRightRoundedIcon className={styles.chevron} />
            </div>

            <div className={styles.optionTable2}>
              <OptionWithExpandCollapseContent
                expand={showPartnerModelOptions}
                setExpand={setShowPartnerModelOptions}
                isLoading={portfolioPartners.isLoading}
                headerLeftContent={
                  <div>
                    <div className={styles.optionHeading}>Partner</div>
                    <div className={styles.optionText}>
                      Use one of our partner's model portfolios
                    </div>
                  </div>
                }
              >
                {orderBy(
                  portfolioPartners.data,
                  (p) => p.portfolioPartnerName.toLowerCase(),
                  "asc"
                ).map((partner) => {
                  return (
                    <div
                      key={partner.portfolioPartnerId}
                      className={styles.tableOption}
                      onClick={() =>
                        choosePortfolioType(partner.portfolioPartnerName)
                      }
                    >
                      <div className={styles.optionHeading}>
                        {partner.portfolioPartnerName}
                      </div>

                      <ChevronRightRoundedIcon className={styles.chevron} />
                    </div>
                  );
                })}
              </OptionWithExpandCollapseContent>
            </div>

            {showSharedModelTable && (
              <div className={styles.optionTable2}>
                <OptionWithExpandCollapseContent
                  expand={showCustomModelOptions}
                  setExpand={setShowCustomModelOptions}
                  isLoading={activeSharedModels.isLoading}
                  headerLeftContent={
                    <div>
                      <div className={styles.optionHeading}>
                        Shared by Your Advisor
                      </div>
                      <div className={styles.optionText}>
                        Use a custom model created by your advisor
                      </div>
                    </div>
                  }
                >
                  {orderBy(
                    activeSharedModels.data,
                    (m) => m.displayName.toLowerCase(),
                    "asc"
                  ).map((p) => {
                    return (
                      <div
                        key={p.portfolioModelId}
                        className={styles.tableOption}
                        onClick={() =>
                          history.push({
                            pathname: `/Client/${clientId}/Investments/Portfolios/${p.portfolioModelId}/Accounts`,
                            state: {
                              ...(state ?? {}),
                              ...portfolioLocationState,
                            },
                          })
                        }
                      >
                        <div className={styles.optionHeading}>
                          {p.displayName}
                        </div>

                        <ChevronRightRoundedIcon className={styles.chevron} />
                      </div>
                    );
                  })}
                </OptionWithExpandCollapseContent>
              </div>
            )}

            <div
              className={styles.optionDiv}
              onClick={() => choosePortfolioType("Custom")}
            >
              <div>
                <div className={styles.optionHeading}>Custom</div>
                <div className={styles.optionText}>
                  Create a custom model portfolio
                </div>
              </div>
              <ChevronRightRoundedIcon className={styles.chevron} />
            </div>
          </div>
        </div>
      </BackAndExitButtonWrapper>
    </BaseLayout>
  );
}
