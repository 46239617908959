import React from "react";

import Accounts from "./icons/Accounts";
import Admin from "./icons/Admin";
import Advisor from "./icons/Advisor";
import AltAssets from "./icons/AltAssets";
import BookAnalytics from "./icons/BookAnalytics";
import CashManagement from "./icons/CashManagement";
import ClientOps from "./icons/ClientOps";
import Clients from "./icons/Clients";
import Documents from "./icons/Documents";
import FinancialPlans from "./icons/FinancialPlans";
import Home from "./icons/Home";
import Insurance from "./icons/Insurance";
import Investments from "./icons/Investments";
import Lending from "./icons/Lending";
import Opportunities from "./icons/Opportunities";
import Plans from "./icons/Plans";
import RaiseCash from "./icons/RaiseCash";
import Sharing from "./icons/Sharing";
import Tasks from "./icons/Tasks";
import TaxBudgets from "./icons/TaxBudgets";
import Transfers from "./icons/Transfers";

export type NavIconName =
  | "Accounts"
  | "Admin"
  | "Advisor"
  | "AltAssets"
  | "BookAnalytics"
  | "CashManagement"
  | "ClientOps"
  | "Clients"
  | "Documents"
  | "FinancialPlans"
  | "Home"
  | "Insurance"
  | "Investments"
  | "Lending"
  | "Opportunities"
  | "Plans"
  | "RaiseCash"
  | "Sharing"
  | "Tasks"
  | "TaxBudgets"
  | "Transfers";

interface Props {
  iconName: NavIconName;
}

const NavIcon: React.FC<Props> = ({ iconName }) => {
  switch (iconName) {
    case "Accounts":
      return <Accounts />;
    case "Admin":
      return <Admin />;
    case "Advisor":
      return <Advisor />;
    case "AltAssets":
      return <AltAssets />;
    case "BookAnalytics":
      return <BookAnalytics />;
    case "CashManagement":
      return <CashManagement />;
    case "ClientOps":
      return <ClientOps />;
    case "Clients":
      return <Clients />;
    case "Documents":
      return <Documents />;
    case "Home":
      return <Home />;
    case "FinancialPlans":
      return <FinancialPlans />;
    case "Insurance":
      return <Insurance />;
    case "Investments":
      return <Investments />;
    case "Lending":
      return <Lending />;
    case "Opportunities":
      return <Opportunities />;
    case "Plans":
      return <Plans />;
    case "RaiseCash":
      return <RaiseCash />;
    case "Sharing":
      return <Sharing />;
    case "Tasks":
      return <Tasks />;
    case "TaxBudgets":
      return <TaxBudgets />;
    case "Transfers":
      return <Transfers />;
    default:
      return <></>;
  }
};

export default NavIcon;
