import React, { useCallback, useEffect, useMemo, useState } from "react";

import useAuthenticateOtps from "@fartherfinance/frontend/api/Identity/hooks/useAuthenticateOtps";
import useAuthenticatePassword from "@fartherfinance/frontend/api/Identity/hooks/useAuthenticatePassword";
import useCheckForExistingMFA from "@fartherfinance/frontend/api/Identity/hooks/useCheckForExistingMFA";
import useCheckForExistingPassword from "@fartherfinance/frontend/api/Identity/hooks/useCheckForExistingPassword";
import useLoginOrCreate from "@fartherfinance/frontend/api/Identity/hooks/useLoginOrCreate";
import { type UpdateMFAConfirm } from "@fartherfinance/frontend/api/Identity/hooks/useMFAConfirm";
import useResetByEmail from "@fartherfinance/frontend/api/Identity/hooks/useResetByEmail";
import useResetByEmailStart from "@fartherfinance/frontend/api/Identity/hooks/useResetByEmailStart";
import useSendMFASms from "@fartherfinance/frontend/api/Identity/hooks/useSendMFASms";
import {
  EmailAddress,
  IdentifierType,
  StytchToken,
} from "@fartherfinance/frontend/api/Identity/requests/exchangeStytchToken";

import useCheckSavedSession from "@src/multiCustodian/hooks/useCheckSavedSession";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";

import AccountLock from "./AccountLock";
import { LocalStorageKeys } from "./constants";
import errorHandler from "./errorHandler";
import getDefaultEmail from "./getDefaultEmail";
import PasswordEmailSent from "./PasswordEmailSent";
import PasswordEntry from "./PasswordEntry";
import PasswordReset from "./PasswordReset";
import SetMFA from "./SetMFA";
import StytchLoginStartComponent from "./Start";
import { LoginFunction } from "./Types";
import useLogin from "./useLogin";
import VerifyOTP from "./VerifyOTP";

type State =
  | { type: "start" }
  | {
      type: "emailOTP";
      methodId: string;
      emailAddress: EmailAddress;
    }
  | { type: "emailPassword" }
  | { type: "PasswordReset"; token: string }
  | {
      type: "PasswordEmailSent";
      action: "Create" | "Reset";
      emailAddress: string;
    }
  | {
      type: "smsOtpMfa";
      methodId: string;
      emailAddress: EmailAddress;
      confirm?: UpdateMFAConfirm;
    }
  | {
      type: "accountLock";
      emailAddress: string;
    }
  | { type: "setMFA"; token: StytchToken; emailAddress: EmailAddress };

const StytchLoginComponent: React.FC = () => {
  const statusNotification = useStatusNotification();

  const [state, setState] = useState<State>({ type: "start" });

  const [resendDisabled, setResendDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const exchangeToken = useLogin();
  const checkMFA = useCheckForExistingMFA();
  const sendMFASms = useSendMFASms();
  const loginOrCreate = useLoginOrCreate();
  const authenticateOtps = useAuthenticateOtps();
  const authenticatePassword = useAuthenticatePassword();
  const resetByEmail = useResetByEmail();
  const resetByEmailStart = useResetByEmailStart();

  useCheckSavedSession();

  const mfaLogin: LoginFunction = useCallback(
    async (token: StytchToken, emailAddress: EmailAddress): Promise<void> => {
      await exchangeToken(token, emailAddress);
      return;
    },
    [exchangeToken]
  );

  const login: LoginFunction = useCallback(
    async (
      token: StytchToken,
      emailAddress: EmailAddress,
      devEmail?: EmailAddress
    ): Promise<void> => {
      // Don't check 2FA if UAT
      if (process.env.WEBAPP_EXACT_ENV === "UAT") {
        exchangeToken(token, emailAddress);
        return;
      }

      // Check if we need to do 2FA
      const hasMFA = await checkMFA(emailAddress);

      switch (hasMFA) {
        case "AskMFA":
          await exchangeToken(token, emailAddress, devEmail, true);

          setState({
            type: "setMFA",
            token: token,
            emailAddress: emailAddress,
          });
          return;
        case "HasMFA":
          const res = await sendMFASms(emailAddress, devEmail);
          setState({
            type: "smsOtpMfa",
            methodId: res.methodId,
            emailAddress: emailAddress,
          });
          return;
        case "NoMFA":
          await exchangeToken(token, emailAddress);
          return;
      }
    },
    [checkMFA, exchangeToken, sendMFASms]
  );

  const checkEmailForPassword = useCheckForExistingPassword();

  const submitOTP = useCallback(
    async (email) => {
      try {
        const [clientEmail, devEmail] = email.split("__");
        const res = await loginOrCreate({ email: devEmail ?? clientEmail });

        setState({
          type: "emailOTP",
          methodId: res.method_id,
          // Do not use dev email for this
          emailAddress: email,
        });

        try {
          localStorage.setItem(LocalStorageKeys.email, email);
          localStorage.setItem(LocalStorageKeys.lastLoginType, "otp");
        } catch (e) {
          console.error("Failed to store email in localStorage", e);
        }

        setError(null);

        return res;
      } catch (err) {
        const msg = errorHandler(err);

        statusNotification(msg, "Error");
      }
    },
    [loginOrCreate, statusNotification]
  );

  const [email, setEmail] = useState<string>(getDefaultEmail() ?? "");
  const [error, setError] = useState<string | null>(null);

  const qs = useMemo(() => new URLSearchParams(window.location.search), []);

  useEffect(() => {
    try {
      const savedEmail = localStorage.getItem(LocalStorageKeys.email);
      const savedLastLoginType = localStorage.getItem(
        LocalStorageKeys.lastLoginType
      );
      if (savedEmail !== null && savedLastLoginType !== null) {
        switch (savedLastLoginType) {
          case "otp":
            //do nothing
            return;

          case "password":
            setState({ type: "emailPassword" });
            return;

          default:
            return;
        }
      }
    } catch (e) {
      console.error("Failed to get email from localStorage", e);
    }

    // Only run on load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const token = qs.get("token");
    const tokenType = qs.get("stytch_token_type");

    if (token !== null && tokenType !== null) {
      switch (tokenType) {
        case "reset_password":
          setState({ type: "PasswordReset", token: token });
          break;
        case "magic_links":
        case "oauth":
          // panic
          break;
        default:
          break;
      }
    }
    // only run this at load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Disable the resend code button for 30s after an attempt
  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;

    if (resendDisabled === true) {
      timeoutId = setTimeout(() => {
        setResendDisabled(false);
      }, 30000); // 30s
    }

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, [resendDisabled]);

  const authenticateOTP = useCallback(
    async (
      code: string,
      methodId: string,
      emailAddress: EmailAddress
    ): Promise<void> => {
      setIsLoading(true);

      try {
        // Need to pass in a session token if it exists to link password auth to the same session
        const sessionToken = localStorage.getItem(
          LocalStorageKeys.sessionToken
        );

        const res = await authenticateOtps(
          methodId,
          code,
          sessionToken ?? undefined
        );

        // The session token changes after 2FA, so store it again
        localStorage.setItem(LocalStorageKeys.sessionToken, res.session_token);

        // Clear any previous errors
        setError(null);

        if (state.type === "smsOtpMfa") {
          if (state.confirm !== undefined) {
            await state.confirm();
          }

          await mfaLogin(res.session_token as StytchToken, emailAddress);
        } else {
          const [clientEmail, devEmail] = emailAddress.split("__");
          await login(
            res.session_token as StytchToken,
            clientEmail as EmailAddress,
            devEmail as EmailAddress
          );
        }
      } catch (err) {
        const msg = errorHandler(err);

        setError(msg);
      } finally {
        setIsLoading(false);
      }
    },
    [authenticateOtps, login, mfaLogin, state]
  );

  const resendOTPCode = async (
    identifierType: IdentifierType,
    emailAddress: EmailAddress
  ): Promise<string | undefined> => {
    const [clientEmail, devEmail] = emailAddress.split("__");
    const emailToSend = devEmail ?? clientEmail;

    switch (identifierType) {
      case "email":
        {
          try {
            const res = await loginOrCreate({ email: emailToSend });

            statusNotification("Code re-sent", "Success");

            setResendDisabled(true);

            setError(null);

            return res.method_id;
          } catch (err) {
            const msg = errorHandler(err);

            statusNotification(msg, "Error");
          }
        }
        break;
      case "phoneNumber": {
        try {
          const res = await sendMFASms(emailToSend);

          statusNotification("Code re-sent", "Success");

          setResendDisabled(true);

          setError(null);

          return res.methodId;
        } catch (err) {
          const msg = errorHandler(err);

          statusNotification(msg, "Error");
        }
      }
    }
  };

  const resetPasswordStart = useCallback(
    async (email: string) => {
      try {
        const [clientEmail, devEmail] = email.split("__");

        await resetByEmailStart({
          email: devEmail ?? clientEmail,
          login_redirect_url: `${window.location.origin}/Login?email=${email}`,
          reset_password_redirect_url: `${window.location.origin}/Login?email=${email}`,
        });

        setState({
          type: "PasswordEmailSent",
          action: "Reset",
          emailAddress: email,
        });
      } catch (err) {
        const msg = errorHandler(err);

        statusNotification(msg, "Error");
      }
    },
    [resetByEmailStart, statusNotification]
  );

  const continueWithPassword = useCallback(
    async (email: string) => {
      try {
        const [clientEmail, devEmail] = email.split("__");

        const res = await checkEmailForPassword(devEmail ?? clientEmail);

        if (res === "HasPassword") {
          setState({ type: "emailPassword" });
        } else {
          await resetPasswordStart(email);

          setState({
            type: "PasswordEmailSent",
            action: "Create",
            emailAddress: email,
          });
        }
      } catch (err) {
        const msg = errorHandler(err);

        statusNotification(msg, "Error");
      }
    },
    [checkEmailForPassword, resetPasswordStart, statusNotification]
  );

  const submitPasswordLogin = useCallback(
    async (email: string, password: string) => {
      const [clientEmail, devEmail] = email.split("__");

      setIsLoading(true);

      try {
        const res = await authenticatePassword(
          devEmail ?? clientEmail,
          password
        );

        try {
          localStorage.setItem(LocalStorageKeys.email, email);
          localStorage.setItem(LocalStorageKeys.lastLoginType, "password");
          localStorage.setItem(
            LocalStorageKeys.sessionToken,
            res.session_token
          );
        } catch (e) {
          console.error("Failed to store email in localStorage", e);
        }

        // Clear any previous errors
        setError(null);

        await login(
          res.session_token as StytchToken,
          clientEmail as EmailAddress,
          devEmail as EmailAddress
        );
      } catch (err) {
        const msg = errorHandler(err);

        setError(msg);
      } finally {
        setIsLoading(false);
      }
    },
    [authenticatePassword, login]
  );

  const submitResetPassword = useCallback(
    async (email: string, password: string) => {
      if (state.type === "PasswordReset") {
        setIsLoading(true);

        try {
          const res = await resetByEmail(password, state.token);

          const [impersonateEmail, devEmail] = email.split("__");

          await login(
            res.session_token as StytchToken,
            impersonateEmail as EmailAddress,
            devEmail as EmailAddress
          );

          try {
            localStorage.setItem(LocalStorageKeys.email, email);
          } catch (e) {
            console.error("Failed to store email in localStorage", e);
          }
        } catch (err) {
          const msg = errorHandler(err);

          statusNotification(msg, "Error");
        } finally {
          setIsLoading(false);
        }
      }
    },
    [login, resetByEmail, state, statusNotification]
  );

  switch (state.type) {
    case "start":
      return (
        <StytchLoginStartComponent
          onSendOTPClick={async (email) => {
            setEmail(email);
            await submitOTP(email);
          }}
          onPasswordContinueClick={async (email) => {
            setEmail(email);
            await continueWithPassword(email);
          }}
          onResetPasswordClick={async (email) => {
            setEmail(email);
            await resetPasswordStart(email);
          }}
        />
      );

    case "emailOTP":
      return (
        <VerifyOTP
          authenticateOTP={async (code) => {
            await authenticateOTP(code, state.methodId, state.emailAddress);
          }}
          emailAddress={email}
          error={error}
          isLoading={isLoading}
          methodType="email"
          onBack={() => setState({ type: "start" })}
          resendDisabled={resendDisabled}
          resendOTPCode={async () => {
            await resendOTPCode("email", state.emailAddress);
          }}
        />
      );

    case "PasswordReset":
      return (
        <PasswordReset
          isLoading={isLoading}
          onPasswordResetClick={async (email, password) => {
            setEmail(email);
            await submitResetPassword(email, password);
          }}
        />
      );

    case "PasswordEmailSent":
      return (
        <PasswordEmailSent
          action={state.action}
          emailAddress={state.emailAddress}
          onBack={() => setState({ type: "start" })}
          onResendPasswordEmailClick={async (email) => {
            await continueWithPassword(email);
            statusNotification("Email re-sent", "Success");
          }}
        />
      );

    case "emailPassword":
      return (
        <PasswordEntry
          onBack={() => {
            localStorage.removeItem(LocalStorageKeys.lastLoginType);
            setState({ type: "start" });
          }}
          emailAddress={email}
          isLoading={isLoading}
          onPasswordLoginClick={async (email, password) => {
            setEmail(email);
            await submitPasswordLogin(email, password);
          }}
          onResetPasswordClick={async (email) => {
            setEmail(email);
            await resetPasswordStart(email);
          }}
          error={error}
        />
      );

    case "smsOtpMfa":
      return (
        <VerifyOTP
          authenticateOTP={async (code) => {
            await authenticateOTP(code, state.methodId, state.emailAddress);
          }}
          error={error}
          isLoading={isLoading}
          resendDisabled={resendDisabled}
          resendOTPCode={async () => {
            await resendOTPCode("phoneNumber", state.emailAddress);
          }}
          methodType="phone"
          onBack={() => setState({ type: "start" })}
        />
      );

    case "accountLock":
      return (
        <AccountLock
          emailAddress={state.emailAddress}
          onBack={() => setState({ type: "start" })}
          onResetPasswordClick={async (email) => {
            setEmail(email);
            await resetPasswordStart(email);
          }}
        />
      );

    case "setMFA":
      return (
        <SetMFA
          onSetMFA={async (methodId, confirm) => {
            if (methodId === null) {
              const res = await sendMFASms(state.emailAddress);

              setState({
                type: "smsOtpMfa",
                emailAddress: state.emailAddress,
                methodId: res.methodId,
                confirm: confirm,
              });
            } else {
              setState({
                type: "smsOtpMfa",
                emailAddress: state.emailAddress,
                methodId: methodId,
                confirm: confirm,
              });
            }
          }}
          onSkip={async () => {
            await mfaLogin(state.token, state.emailAddress);
          }}
        />
      );
  }
};

export default StytchLoginComponent;
