import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { advisorLogoutBroadcastChannel } from "@src/multiCustodian/utils/clearUserData";
import logUserOut from "@src/multiCustodian/utils/logUserOut";
import { State } from "@src/store";

export default function useSyncAdvisorLogout() {
  const history = useHistory();
  const { advisorId } = useSelector((state: State) => ({
    advisorId: state.main_Reducer.cockroach_advisor_id,
  }));

  useEffect(() => {
    const handler = () => {
      if (advisorId === null) {
        return;
      }

      console.log("Another Advisor tab logged out");
      logUserOut("AdvisorLogout Broadcast Channel", history);
    };
    advisorLogoutBroadcastChannel.addEventListener("message", handler);

    return () => {
      advisorLogoutBroadcastChannel.removeEventListener("message", handler);
    };
  }, [advisorId, history]);
}
