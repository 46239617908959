import { avatarClasses } from "@mui/material/Avatar";
import { makeStyles } from "tss-react/mui";

import { pxToSpacing } from "@src/yellowstone/theme";

export const useStyles = makeStyles()((theme) => ({
  avatar: {
    width: 30,
    height: 30,

    [`&.${avatarClasses.root}`]: {
      backgroundColor: theme.appearance.$backgroundSelected,

      [`& .${avatarClasses.fallback}`]: {
        display: "none",
      },
    },
  },
  avatarText: {
    fontFamily: theme.typography.fontSecondary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "19px",
    letterSpacing: "0.63px",
    color: theme.palette.common.white,

    marginLeft: theme.spacing(pxToSpacing(10)),
  },
}));
