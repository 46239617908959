import React from "react";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import Tooltip from "@src/sharedComponents/Tooltip/Tooltip";

import styles from "./NavItem.module.css";

interface Props {
  isHidden: boolean;
}

const IsHiddenIcon: React.FC<Props> = ({ isHidden }) => {
  return (
    <>
      {isHidden && (
        <Tooltip
          tooltipText={"This page is not visible to the client."}
          placement="top"
        >
          <VisibilityOffIcon className={styles.visibilityOffIcon} />
        </Tooltip>
      )}
    </>
  );
};

export default IsHiddenIcon;
