import React from "react";

import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import Typography from "@src/sharedComponents/Typography/Text";

import styles from "./HeroTooltipCard.module.css";

interface Props {
  title: string;
  text: string;
  className?: string;
}

const HeroTooltipCard = ({ title, text, className }: Props): JSX.Element => {
  return (
    <div className={toClassName(styles.container, className)}>
      <Typography className={styles.title} variant="title3">
        {title}
      </Typography>

      <Typography variant="body">{text}</Typography>
    </div>
  );
};

export default HeroTooltipCard;
