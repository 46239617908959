import React, { useMemo, useState } from "react";

import { useParams } from "react-router-dom";

import useClientDashboard from "@fartherfinance/frontend/api/Dashboard/hooks/useClientDashboard";
import { ClientId } from "@fartherfinance/frontend/api/Types";

import ProfilePhotoAdvisor from "../../Navbar/ProfilePhotoAdvisor";
import useRequestAuth from "@src/multiCustodian/hooks/useRequestAuth";
import { trackEvent } from "@src/multiCustodian/services/tracking";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";

import ClientLoginAdvisorPopoverMenu from "./ClientLoginAdvisorPopoverMenu";
import ProfileBox from "./ProfileBox";

import styles from "./ProfileBox.module.css";

const AdvisorProfileBox: React.FC = () => {
  const [showPopover, setShowPopover] = useState<boolean>(false);

  const { clientId } = useParams<{
    clientId: ClientId;
  }>();

  const clientAuth = useRequestAuth();
  const dashboard = useClientDashboard(clientId, clientAuth);

  const clientsAdvisorName = useMemo(() => {
    if (dashboard.data === undefined) {
      return "...";
    }

    return `${dashboard.data.advisor.name.first} ${dashboard.data.advisor.name.last}`;
  }, [dashboard.data]);

  const togglePopover = () => {
    if (!showPopover) {
      trackEvent({ name: "Client Open Advisor-Menu" });
    }
    setShowPopover(!showPopover);
  };

  return (
    <div
      className={toClassName(styles.container, {
        [styles.selected]: showPopover,
      })}
      onClick={togglePopover}
    >
      <ProfileBox
        isAdvisor={true}
        name={clientsAdvisorName}
        avatar={
          <ProfilePhotoAdvisor
            containerClassName={styles.smallAvatar}
            avatarClassName={styles.smallAvatar}
          />
        }
        popover={
          <ClientLoginAdvisorPopoverMenu
            showPopover={showPopover}
            setShowPopover={setShowPopover}
          />
        }
      />
    </div>
  );
};

export default AdvisorProfileBox;
