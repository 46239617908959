import React from "react";

import "./status.css";

type Props = {
  clickBanner?: () => void;
  msg: string;
} & (
  | { img_src?: string; icon?: undefined }
  | { img_src?: undefined; icon?: JSX.Element }
);

const Status = (props: React.PropsWithChildren<Props>): JSX.Element => {
  return (
    <div
      className="Dashboard_Caution_Update_component__navlink"
      onClick={props.clickBanner}
    >
      <div className="Dashboard_Caution_Update_component__container">
        <div className="Dashboard_Caution_Update_component__inner_div">
          <div className="Dashboard_Caution_Update_component__inner_div_left">
            {props.img_src !== undefined ? (
              <img src={props.img_src} />
            ) : props.icon !== undefined ? (
              props.icon
            ) : (
              <></>
            )}

            <div className="Dashboard_Caution_Update_component__text">
              {props.msg}
            </div>
          </div>

          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Status;
