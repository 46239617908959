import React, { PropsWithChildren } from "react";

import BudgetDisclosure from "../Disclosure/Disclosure";
import { useCreateEditTaxBudgetContext } from "../reducer/Context";

import styles from "./CreateEditLayout.module.css";

import type { Disclosure } from "../reducer/types";

const CreateEditLayout: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { state } = useCreateEditTaxBudgetContext();

  const { disclosures } = state;

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.leftColumn}>{children}</div>

        {disclosures !== null && (
          <div className={styles.rightColumn}>
            {disclosures.map(({ body, title }: Disclosure, index: number) => (
              <BudgetDisclosure key={index} title={title || ""}>
                {body}
              </BudgetDisclosure>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateEditLayout;
