import React from "react";

import TextInput from "@src/sharedComponents/TextInput/TextInput";

import styles from "./GroupName.module.css";

interface Props {
  groupName: string;
  setGroupName: (s: string) => void;
  label: React.ReactNode;
  isDisabled: boolean;
}

const GroupName: React.FC<Props> = ({
  groupName,
  setGroupName,
  label,
  isDisabled,
}: Props) => {
  return (
    <div className={styles.container}>
      <TextInput
        label={label}
        value={groupName}
        onChange={(e) => setGroupName(e.target.value)}
        disabled={isDisabled}
      />
    </div>
  );
};

export default GroupName;
