import React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import BorderBox from "@src/sharedComponents/BorderBox/BorderBox";
import BorderBoxHeader from "@src/sharedComponents/BorderBox/BorderBoxHeader";

import styles from "./ScenarioSettings.module.css";

interface Props {
  setIsOpen: () => void;
}

const ScenarioSettingsClosed: React.FC<Props> = ({ setIsOpen }) => {
  return (
    <BorderBox className={styles.borderBox}>
      <BorderBoxHeader
        className={styles.borderBoxHeader}
        start={"Scenario Settings"}
        end={
          <ExpandMoreIcon className={styles.chevronDown} onClick={setIsOpen} />
        }
      />
    </BorderBox>
  );
};

export default ScenarioSettingsClosed;
