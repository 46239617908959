import React, { useState } from "react";

import { isNil } from "lodash";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import useDeletePlanAccountV2 from "@fartherfinance/frontend/api/Accounts/hooks/useDeletePlanAccountV2";
import { PlanDetails } from "@fartherfinance/frontend/api/Accounts/requests/getPlanDetailsV2";
import {
  Account,
  AccountPeople,
} from "@fartherfinance/frontend/api/Accounts/Types";
import { FartherAccountId, PlanId } from "@fartherfinance/frontend/api/Types";

import { State as ReduxState } from "../../../../../store";
import AlertMessageModal from "../../../../components/Modals/AlertMessageModal";
import PlanTableComponent from "../table/PlanTable";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";

import styles from "../table/table.module.css";

const calculateNumOfPeople = (accountPeople: AccountPeople): number => {
  let numPeople = 0;

  if (!isNil(accountPeople.accountHolder)) {
    numPeople += 1;
  }

  if (!isNil(accountPeople.primaryAccountHolder)) {
    numPeople += 1;
  }

  numPeople += (accountPeople.secondaryAccountHolders ?? []).length;

  numPeople += (accountPeople.beneficiaries ?? []).length;

  numPeople += (accountPeople.trustees ?? []).length;

  return numPeople;
};

const headers = ["Account", "Account #", "Custodian", "Custodian #", "People"];
const cellWidthPercentages = [20, 20, 20, 20, 20];

interface Props {
  planDetails: PlanDetails | null;
  planId: PlanId;
  queryStringParams: string;
  selectedAccount?: string;
}

const PlanAccountsTable = (props: Props): JSX.Element => {
  const [modalData, setModalData] = useState<ModalData | null>(null);

  const statusNotification = useStatusNotification();

  const auth = useAdvisorRequestAuth();

  const callDeletePlanAccount = useDeletePlanAccountV2(auth);

  return (
    <>
      <PlanTableComponent
        headers={headers}
        cellWidthPercentages={cellWidthPercentages}
        tableContent={(props.planDetails?.resources.accounts ?? []).map(
          (acct: Account) => (
            <TableRow
              key={acct.virtualAccountId}
              account={acct}
              queryStringParams={props.queryStringParams}
              planId={props.planId}
              isSelected={props.selectedAccount === acct.virtualAccountId}
              setModalData={setModalData}
              planStatus={props.planDetails?.plan.planStatus}
            />
          )
        )}
        placeholderText={
          props.planDetails === null
            ? "Loading..."
            : "Add custodian accounts to this plan."
        }
      />

      {modalData !== null && (
        <AlertMessageModal
          top_text={() => (
            <p>
              Are you sure you want to delete{" "}
              <span>{modalData.accountType}</span> account?
            </p>
          )}
          bottom_text={""}
          button_blue_text={"No"}
          button_blue_onClick={() => setModalData(null)}
          button_yellow_text={"Yes"}
          button_yellow_onClick={async () => {
            try {
              await callDeletePlanAccount({
                planId: props.planId,
                virtualAccountId: modalData.virtualAccountId,
              });
              statusNotification("Account removed", "Success");
              setModalData(null);
            } catch (_e) {
              statusNotification("Error removing account", "Error");
              setModalData(null);
            }
          }}
          img_src={null}
          modal_style={null}
        />
      )}
    </>
  );
};

export default PlanAccountsTable;

interface ModalData {
  accountType: string;
  virtualAccountId: FartherAccountId;
}

interface TableRowProps {
  account: Account;
  planId: PlanId;
  queryStringParams: string;
  isSelected: boolean;
  setModalData: (date: ModalData) => void;
  planStatus: string | undefined;
}

const TableRow = ({ account, ...props }: TableRowProps): JSX.Element => {
  const [isHovering, setHovering] = useState(false);
  const { small_x_icon } = useSelector((state: ReduxState) => ({
    small_x_icon: state.main_Reducer.brand.current.images.small_x_icon,
  }));

  const history = useHistory();

  const numPeople = calculateNumOfPeople(account.accountPeople);

  return (
    <tr
      className={styles.tableRow}
      style={{
        backgroundColor: props.isSelected ? "rgba(255,255,255,0.3)" : undefined,
      }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <td
        className={styles.tableCol}
        style={{ width: cellWidthPercentages[0] + "%" }}
        onClick={() => {
          history.push(
            `/Advisor/Plans/${props.planId}/Accounts/${account.virtualAccountId}?${props.queryStringParams}`
          );
        }}
      >
        <div className={styles.tableCol_cell}>
          {account.accountInformation.accountTitle}
        </div>
      </td>

      <td
        className={styles.tableCol}
        style={{ width: cellWidthPercentages[1] + "%" }}
        onClick={() => {
          history.push(
            `/Advisor/Plans/${props.planId}/Accounts/${account.virtualAccountId}?${props.queryStringParams}`
          );
        }}
      >
        <div className={styles.tableCol_cell}>
          {"..." + account.virtualAccountId.slice(-6)}
        </div>
      </td>

      <td
        className={styles.tableCol}
        style={{ width: cellWidthPercentages[2] + "%" }}
        onClick={() => {
          history.push(
            `/Advisor/Plans/${props.planId}/Accounts/${account.virtualAccountId}?${props.queryStringParams}`
          );
        }}
      >
        <div className={styles.tableCol_cell}>{account.custodian}</div>
      </td>

      <td
        className={styles.tableCol}
        style={{ width: cellWidthPercentages[3] + "%" }}
        onClick={() => {
          history.push(
            `/Advisor/Plans/${props.planId}/Accounts/${account.virtualAccountId}?${props.queryStringParams}`
          );
        }}
      >
        <div className={styles.tableCol_cell}>
          {account.custodianAccountNumber}
        </div>
      </td>

      <td
        className={styles.tableCol}
        style={{ width: cellWidthPercentages[4] + "%" }}
      >
        <div className={styles.tableCol_cell}>
          <div className={styles.flex_apart}>
            <div
              className={styles.col_cell_div_left}
              onClick={() => {
                history.push(
                  `/Advisor/Plans/${props.planId}/Accounts/${account.virtualAccountId}?${props.queryStringParams}`
                );
              }}
            >
              <img
                className={styles.tableCol_numPeople_icon}
                src={"/src/assets/svg/user.svg"}
              />

              {numPeople}
            </div>

            {isHovering && props.planStatus !== "Complete" && (
              <img
                className={styles.table_row_x_img}
                src={small_x_icon}
                onClick={() => {
                  props.setModalData({
                    accountType: account.accountInformation.accountType,
                    virtualAccountId: account.virtualAccountId,
                  });
                }}
              />
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};
