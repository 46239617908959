import React from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import Popover from "@src/sharedComponents/Popover/Popover";

import styles from "./SpouseAccountPopOver.module.css";

interface Props {
  anchorRef: React.MutableRefObject<HTMLDivElement | null>;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onDelete: () => void;
  onEdit: () => void;
}

const SpouseAccountPopOver: React.FC<Props> = ({
  anchorRef,
  isOpen,
  onClose,
  onDelete,
  onEdit,
  onOpen,
}) => {
  return (
    <Popover
      open={isOpen}
      onClose={onClose}
      anchorOriginVertical={"bottom"}
      anchorOriginHorizontal={"right"}
      transformOriginHorizontal={"right"}
      anchor={anchorRef.current}
      popoverElement={
        <div className={styles.dotMenu} onClick={onOpen} ref={anchorRef}>
          <MoreHorizIcon
            classes={{
              root: styles.dotsIcon,
            }}
          />
        </div>
      }
    >
      <div className={styles.popoverMenu}>
        <Button
          text="Edit Spouse"
          onClick={onEdit}
          variant="text"
          buttonType="primary"
        />
        <Button
          text="Remove Spouse"
          onClick={onDelete}
          variant="text"
          buttonType="error"
        />
      </div>
    </Popover>
  );
};

export default SpouseAccountPopOver;
