import React, { useEffect, useMemo } from "react";

import { useHistory, useParams } from "react-router-dom";

import useSleeve from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useSleeve";
import { SleeveId } from "@fartherfinance/frontend/api/Types";

import Button from "@src/multiCustodian/components/MUI/Button/Button";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import Modal from "@src/sharedComponents/Modal/Modal";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";

import styles from "./ArchiveSleeveModal.module.css";

const ArchiveSleeveModal = (): JSX.Element => {
  const { sleeveId, sleeveDrawerTab } = useParams<{
    sleeveId: SleeveId;
    sleeveDrawerTab: "Sharing" | "SleeveUsage" | "SleeveDetails";
  }>();

  const history = useHistory();

  useEffect(() => {
    if (
      sleeveDrawerTab === "Sharing" ||
      sleeveDrawerTab === "SleeveUsage" ||
      sleeveDrawerTab === "SleeveDetails"
    ) {
      return;
    } else {
      history.push({
        ...history.location,
        pathname: `/Advisor/Investments/Sleeves/${sleeveId}`,
      });
    }
  }, [history, sleeveId, sleeveDrawerTab]);

  const statusNotification = useStatusNotification();

  const auth = useAdvisorRequestAuth();
  const sleeve = useSleeve(sleeveId, auth);

  const content = useMemo(() => {
    const archiveSleeve = () => {
      if (sleeve.data === undefined) {
        statusNotification("Error retrieving sleeve", "Error");
        return;
      }

      history.push({
        ...history.location,
        pathname: `/Advisor/Investments/Sleeves/${sleeveId}/${sleeveDrawerTab}/Replace`,
      });
    };

    return sleeve.isLoading ? (
      <>
        <Skeleton
          width={70}
          height={35}
          style={{ transform: "none", marginRight: "10px" }}
        />

        <Skeleton width={100} height={35} style={{ transform: "none" }} />
      </>
    ) : (
      <>
        <Button
          variant={"outlined"}
          buttonType={"secondary"}
          text={"Cancel"}
          onClick={() =>
            history.push({
              ...history.location,
              pathname: `/Advisor/Investments/Sleeves/${sleeveId}/${sleeveDrawerTab}`,
            })
          }
        />

        <Button
          variant={"outlined"}
          buttonType={"warning"}
          text={"Archive Sleeve"}
          onClick={archiveSleeve}
          style={{ marginLeft: "15px" }}
        />
      </>
    );
  }, [history, sleeve, sleeveDrawerTab, sleeveId, statusNotification]);

  if (sleeve.hasError) {
    return <div>Error retrieving models</div>;
  }

  return (
    <Modal
      closeModal={() =>
        history.push({
          ...history.location,
          pathname: `/Advisor/Investments/Sleeves/${sleeveId}/${sleeveDrawerTab}`,
        })
      }
      modalStyle={{ width: "520px" }}
    >
      <div className={styles.modalTitle}>
        Once archived, this sleeve cannot be used for rebalancing.
      </div>

      <div className={styles.modalText}>
        You can still view the sleeve from the archived sleeves dropdown option.
        To use this sleeve in the future, you will need to make it active.
      </div>

      <div className={styles.modalFooter}>{content}</div>
    </Modal>
  );
};

export default ArchiveSleeveModal;
