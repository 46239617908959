import React, { useEffect, useMemo, useState } from "react";

import { useParams } from "react-router-dom";

import useSleeve from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useSleeve";
import useValidateSleeve from "@fartherfinance/frontend/api/PortfolioManagement/hooks/PQS/useValidateSleeve";
import { SecurityWeight } from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";
import { SleeveId } from "@fartherfinance/frontend/api/Types";

import CSVFormModal from "../../SharedComponents/CSVFormModal/CSVFormModal";
import CreateUpdateSleeveForm from "../CreateSleeve/CreateUpdateSleeveForm";
import useAdvisorRequestAuth from "@src/multiCustodian/hooks/useAdvisorRequestAuth";
import useStatusNotification from "@src/multiCustodian/hooks/useStatusNotification";
import LogoLoadingStill from "@src/sharedComponents/LogoLoadingStill/LogoLoadingStill";

const UpdateSleeve = (): JSX.Element => {
  const { sleeveId } = useParams<{
    sleeveId: SleeveId;
  }>();

  const [mutating, setMutating] = useState(false);

  const [csvSecurities, setCsvSecurities] = useState<SecurityWeight[] | null>(
    null
  );

  const [cameFromUpload, setCameFromUpload] = useState<boolean>(false);

  const [pageState, setPageState] = useState<"CSV_UPLOAD" | "SLEEVE_EDIT">(
    "SLEEVE_EDIT"
  );

  const [csvFile, setCSVFile] = useState<File | null>(null);

  const [csvErrors, setCSVErrors] = useState<string[] | null>(null);

  const statusNotification = useStatusNotification();

  const auth = useAdvisorRequestAuth();
  const sleeve = useSleeve(sleeveId, auth);

  const validateSleeveCSV = useValidateSleeve(auth);

  const reset = () => {
    setCSVFile(null);
    setCSVErrors(null);
    setCsvSecurities(null);
    setCameFromUpload(false);
  };

  useEffect(() => {
    if (
      pageState === "CSV_UPLOAD" &&
      csvSecurities !== null &&
      cameFromUpload === true
    ) {
      setPageState("SLEEVE_EDIT"); //Ensure csv data is saved in state before opening the model edit form, otherwise the default values will not be mapped correctly onto the form
    }
  }, [csvSecurities, pageState, cameFromUpload]);

  const currentPositions = useMemo(() => {
    if (sleeve.data === undefined) {
      return [];
    }

    return sleeve.data.positions.map((p) => ({
      securityIdentifier: p.ticker,
      weight: p.value,
    }));
  }, [sleeve.data]);

  if (sleeve.hasError) {
    return <div>Error retrieving sleeve</div>;
  }

  if (sleeve.isLoading) {
    return <LogoLoadingStill onTop={true} />;
  }

  const addNewFile = (newFile: File | null) => {
    setCSVFile(newFile);

    if (csvErrors !== null && newFile !== null) {
      //Only remove an existing error if a new file has been provided
      setCSVErrors(null);
    }
  };

  const uploadCSVFile = async () => {
    if (csvFile === null) {
      statusNotification("Please attach a CSV document", "Error");
      return;
    }

    if (mutating) {
      statusNotification("Uploading your document, please wait", "Error");
      return;
    }

    try {
      setMutating(true);

      const res = await validateSleeveCSV(csvFile);

      if (res.status === "valid") {
        if (res.data.sleeves.length <= 0) {
          setCSVErrors(["No sleeves were found in the CSV"]);
        } else if (
          res.data.sleeves[0].displayName !== sleeve.data.displayName
        ) {
          statusNotification("Sleeves don't match", "Error");

          setCSVErrors([
            `The sleeve in the CSV file: ${res.data.sleeves[0].displayName}, does not match the sleeve you are trying to update: ${sleeve.data.displayName}`,
          ]);
        } else {
          if (res.data.sleeves.length <= 0) {
            setCSVErrors(["No sleeves were found in the CSV"]);
          } else if (res.data.sleeves.length >= 2) {
            statusNotification(
              "Can only upload 1 sleeve at a time - only the first sleeve was uploaded",
              "Error"
            );
          }

          setCsvSecurities(res.data.sleeves[0].positions.securities);
          setCameFromUpload(true);

          statusNotification("CSV validation successful", "Success");
        }
      } else {
        setCSVErrors(res.data.errors ?? null);
      }
    } catch (e) {
      if (e instanceof Error) {
        statusNotification(e.message, "Error");
      }
    } finally {
      setMutating(false);
    }
  };

  if (pageState === "CSV_UPLOAD") {
    return (
      <CSVFormModal
        closeModal={() => setPageState("SLEEVE_EDIT")}
        formObjective={"Update Sleeve"}
        uploadType={"Sleeve"}
        csvFile={csvFile}
        addNewFile={addNewFile}
        uploadCSVFile={uploadCSVFile}
        mutating={mutating}
        csvError={csvErrors}
      />
    );
  }

  return (
    <CreateUpdateSleeveForm
      isUpdate={true}
      goToCSVUpload={() => {
        reset();
        setPageState("CSV_UPLOAD");
      }}
      currentSecurities={csvSecurities ? csvSecurities : currentPositions}
      sleeveId={sleeveId}
      sleeveName={sleeve.data.displayName}
      sleeveOwnerId={sleeve.data.ownerId}
      cameFromUpload={cameFromUpload}
    />
  );
};

export default UpdateSleeve;
