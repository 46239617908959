import React, { useMemo } from "react";

import { format } from "date-fns";
import { orderBy } from "lodash";
import { useHistory, useLocation } from "react-router-dom";

import { PortfolioPartner } from "@fartherfinance/frontend/api/PortfolioManagement/requests/PQS/Types";
import {
  ClientId,
  PortfolioId,
  PortfolioTaxType,
} from "@fartherfinance/frontend/api/Types";

import { FARTHER_MODELS_TYPE_NAME } from "@src/multiCustodian/components/Advisor/Investments/constants";
import Chip from "@src/sharedComponents/Chip/Chip";
import FullDataTable from "@src/sharedComponents/Table/FullDataTable";
import TableCell from "@src/sharedComponents/Table/TableCell/TableCell";
import { Cell, Row } from "@src/sharedComponents/Table/Types";

import styles from "./PortfoliosTable.module.css";

export interface GeneralPortfolioData {
  portfolioModelId: PortfolioId;
  displayName: string;
  createTime: Date;
  taxType: PortfolioTaxType;
  assetAllocation: string | null;
  directIndexing: boolean | null;
  accountsApplied: number;
  portfolioPartner: PortfolioPartner | "Custom";
}

export const tableKeys = [
  "Name",
  "Model Type",
  "Tax Type",
  "Accounts Applied",
  "Created",
] as const;

type TableKey = typeof tableKeys[number];

interface Props {
  data: GeneralPortfolioData[];
  clientId: ClientId;
}

const PortfoliosTable = ({ data, clientId }: Props): JSX.Element => {
  const history = useHistory();
  const location = useLocation();

  const portfolios: Row<TableKey, Cell>[] = useMemo(() => {
    return orderBy(
      data,
      [(p) => p.accountsApplied, (p) => p.displayName.toLowerCase()],
      ["desc", "asc"]
    ).map((d) => {
      const Name = d.displayName;

      return {
        key: d.portfolioModelId,
        Name: {
          value: Name,
          fullValue: Name.toLowerCase(),
        },
        "Model Type": {
          value: (
            <Chip
              label={
                d.portfolioPartner === "FAM"
                  ? FARTHER_MODELS_TYPE_NAME
                  : d.portfolioPartner
              }
            />
          ),
          fullValue:
            d.portfolioPartner === "Custom"
              ? `!${d.portfolioPartner}`
              : d.portfolioPartner,
        },
        "Tax Type": {
          value: (
            <Chip
              label={
                d.taxType === "any"
                  ? "Any"
                  : d.taxType === "taxable"
                  ? "Taxable"
                  : "Tax-Advantaged"
              }
            />
          ),
          fullValue: d.taxType,
        },
        "Accounts Applied": {
          value: d.accountsApplied,
          fullValue: d.accountsApplied.toString().padStart(4, "0"),
        },
        Created: {
          value: format(d.createTime, "M/d/yyyy"),
          fullValue: d.createTime.toISOString(),
        },
        onClick: () =>
          history.push({
            ...location,
            pathname: `/Client/${clientId}/Investments/Portfolios/${d.portfolioModelId}`,
          }),
      };
    });
  }, [data, history, location, clientId]);

  return (
    <FullDataTable
      columns={tableKeys}
      rows={portfolios}
      defaultRowsPerPage={10}
      defaultSortColumn={["Accounts Applied", "desc"]}
      disableColumnSorting={portfolios.length > 0 ? undefined : tableKeys}
      emptyCell={<Chip label="filler" />} // This is the highest cell we will have
    >
      {portfolios.length === 0 && <EmptyTable />}
    </FullDataTable>
  );
};

export default PortfoliosTable;

const EmptyTable = () => {
  return (
    <TableCell colSpan={tableKeys.length}>
      <div className={styles.emptyTable}>
        <div className={styles.emptyTableText}>
          You have not added any model portfolios.
        </div>
      </div>
    </TableCell>
  );
};
