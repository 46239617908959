import React from "react";

import { Box, Collapse, Stack } from "@mui/material";
import { useWatch } from "react-hook-form";

import { OpportunityCancelationReason } from "@fartherfinance/frontend/api/Opportunities/Types";

import OpportunityAdditionalDetails from "../OpportunityAdditionalDetails";
import {
  mapLabelToValue,
  opportunityCancelationReasonMap,
} from "@src/multiCustodian/pages/Advisor/Opportunities/utils";
import FormRadioField from "@src/sharedComponents/Forms/FormRadioField";
import FormTextareaField from "@src/sharedComponents/Forms/FormTextareaField";

import styles from "./OpportunityCanceledForm.module.css";

const cancelationReasons = Array.from(opportunityCancelationReasonMap.values());

export default function OpportunityCanceledForm(): JSX.Element {
  const cancelationReason = useWatch({
    name: "cancelReason",
  });
  const isOtherReasonSelected =
    cancelationReason &&
    mapLabelToValue(opportunityCancelationReasonMap, cancelationReason) ===
      OpportunityCancelationReason.enum.OtherCancelReason;

  return (
    <Stack className={styles.container}>
      <Box>
        <FormRadioField
          name="cancelReason"
          label="What is the reason this opportunity is canceled?"
          required="Please select a reason for canceling this opportunity."
          values={cancelationReasons}
          row={false}
        />
        <Collapse in={isOtherReasonSelected}>
          <Box>
            <FormTextareaField
              containerClassName={styles.otherReasonTextarea}
              name="cancelDetails"
              label=""
              required={
                isOtherReasonSelected
                  ? "Please provide a reason for canceling the opportunity."
                  : false
              }
              placeholder="Provide details..."
              minRows={1}
            />
          </Box>
        </Collapse>
      </Box>
      <OpportunityAdditionalDetails fieldName="cancelExtraDetails" />
    </Stack>
  );
}
