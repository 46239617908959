import React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Stack } from "@mui/system";
import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { OperationalState } from "@fartherfinance/frontend/api/Types";
import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import { Accordions } from "../Types";
import { toClassName } from "@src/multiCustodian/utils/to-class-name";
import IconButton from "@src/sharedComponents/IconButton/IconButton";
import Skeleton from "@src/sharedComponents/Skeleton/Skeleton";
import { State } from "@src/store";

import * as styles from "./Accordion.module.css";

const titleGap = 12;
export const specialtyIconWidth = 18;

export interface Row {
  key: string;
  description: React.ReactNode;
  subDescription?: string;
  status?: OperationalState | "(Sold)";
  value: number | null;
  valueDisplay: React.ReactNode;
  linkTo?: string; //URL
  icon?: React.ReactNode;
  isShared?: boolean;
  elementRightOfLeftContent?: JSX.Element;
  elementLeftOfRightContent?: JSX.Element;
}

interface Props {
  icon: React.ReactNode;
  title: string;
  total: number | undefined | null;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  rows: Row[];
  linkState?: { openAccordions: Accordions[] };
  isExternalAccount?: boolean;
}

export default function Accordion(props: Props): JSX.Element {
  const { isAdvisor } = useSelector((state: State) => ({
    isAdvisor: state.main_Reducer.user.isAdvisor,
  }));

  const {
    color: { $surfaceMedium, $surfaceLow },
  } = useTheme();

  return (
    <div className={styles.border}>
      <motion.header
        initial={false}
        className={toClassName(styles.header)}
        animate={{
          backgroundColor: props.expanded ? $surfaceMedium : $surfaceLow,
        }}
        onClick={() => props.setExpanded(!props.expanded)}
        transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
      >
        <div className={styles.leftRight}>
          <Stack direction={"row"} alignItems={"center"} gap={`${titleGap}px`}>
            <div className={styles.titleIcon}>{props.icon}</div>
            <div className={styles.boldFont}>{props.title}</div>
          </Stack>

          <div className={styles.row}>
            <>
              {props.total === null ? (
                <div className={styles.noBalance}>
                  Unable to retrieve balance
                </div>
              ) : props.total === undefined ? (
                <Skeleton width={120} height={30} />
              ) : (
                <div className={styles.boldFont}>
                  {props.total.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </div>
              )}
            </>

            <IconButton
              className={styles.expandIconContainer}
              iconClassName={toClassName(styles.expandIcon, {
                [styles.expandIconRotate]: props.expanded,
              })}
              IconComponent={ExpandMoreIcon}
            />
          </div>
        </div>

        <div className={styles.headerLine} />
      </motion.header>

      <AnimatePresence initial={false}>
        {props.expanded && (
          <motion.section
            className={toClassName(styles.content, styles.rowGap)}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: {
                backgroundColor: $surfaceMedium,
                height: "auto",
              },
              collapsed: {
                backgroundColor: $surfaceLow,
                height: 0,
              },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {/* contentSpacer is required to make gap work from the top */}
            <div className={styles.contentSpacer} />

            {props.rows.map((r) =>
              !props.isExternalAccount ? (
                <NonExternalAccountRow
                  key={r.key}
                  linkTo={r.linkTo}
                  linkState={props.linkState}
                  icon={r.icon}
                  description={r.description}
                  subDescription={r.subDescription}
                  status={r.status}
                  valueDisplay={r.valueDisplay}
                  isShared={r.isShared}
                  elementRightOfLeftContent={r.elementRightOfLeftContent}
                  elementLeftOfRightContent={r.elementLeftOfRightContent}
                />
              ) : (
                <ExternalAccountRow
                  key={r.key}
                  isAdvisor={isAdvisor}
                  linkTo={r.linkTo}
                  linkState={props.linkState}
                  icon={r.icon}
                  description={r.description}
                  subDescription={r.subDescription}
                  status={r.status}
                  valueDisplay={r.valueDisplay}
                  elementRightOfLeftContent={r.elementRightOfLeftContent}
                  elementLeftOfRightContent={r.elementLeftOfRightContent}
                />
              )
            )}

            {/* contentSpacer is required to make gap work from the bottom */}
            <div className={styles.contentSpacer} />
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
}

interface RowProps {
  linkTo?: string;
  linkState?: { openAccordions: Accordions[] };
  icon?: React.ReactNode;
  description: React.ReactNode;
  subDescription?: string;
  status?: "Pending" | "Planning" | "Open" | "Error" | "Closed" | "(Sold)";
  valueDisplay: React.ReactNode;
  isShared?: boolean;
  elementRightOfLeftContent?: JSX.Element; // right now only used for Plaid "Not Connected" chip
  elementLeftOfRightContent?: JSX.Element; // right now only used for Plaid "Reconnect" button -> Plaid Institution Relink
}

const NonExternalAccountRow = (props: RowProps) => {
  return props.isShared ? (
    <div
      className={styles.leftRight}
      aria-disabled={props.linkTo ? false : true}
    >
      <Stack direction={"row"} gap={`${titleGap}px`} alignItems={"center"}>
        {props.icon ?? ( //Needed in order to align description text with category title
          <div style={{ width: `${specialtyIconWidth}px` }} />
        )}

        <div>
          <span className={styles.descriptionText}>{props.description}</span>

          <div className={styles.subDescriptionText}>
            {props.subDescription ?? " "}
          </div>
        </div>
      </Stack>

      <div className={toClassName(styles.row, styles.rightRowSide)}>
        {props.status === "Pending" ? (
          <div className={styles.statusText}>Pending</div>
        ) : (
          <div className={styles.amountText}>{props.valueDisplay}</div>
        )}

        <IconButton
          sx={{ visibility: props.linkTo ? "visible" : "hidden" }}
          iconClassName={styles.asdIcon}
          IconComponent={ExpandMoreIcon}
        />
      </div>
    </div>
  ) : (
    <Link
      className={styles.leftRight}
      to={(_loc) => ({
        pathname: props.linkTo ?? "#",
        state: {
          ...(props.linkState ?? {}),
          scrollY: window.scrollY,
        },
      })}
      style={props.linkTo ? {} : { pointerEvents: "none" }}
      aria-disabled={props.linkTo ? false : true}
    >
      <Stack direction={"row"} gap={`${titleGap}px`} alignItems={"center"}>
        {props.icon ?? ( //Needed in order to align description text with category title
          <div style={{ width: `${specialtyIconWidth}px` }} />
        )}

        <div>
          <span className={styles.descriptionText}>{props.description}</span>

          <div className={styles.subDescriptionText}>
            {props.subDescription ?? " "}
          </div>
        </div>

        {props.elementRightOfLeftContent && props.elementRightOfLeftContent}
      </Stack>

      <div className={toClassName(styles.row, styles.rightRowSide)}>
        {props.elementLeftOfRightContent && props.elementLeftOfRightContent}

        {props.status === "Pending" ? (
          <div className={styles.statusText}>Pending</div>
        ) : (
          <div className={styles.amountText}>{props.valueDisplay}</div>
        )}

        <IconButton
          sx={{ visibility: props.linkTo ? "visible" : "hidden" }}
          iconClassName={styles.asdIcon}
          IconComponent={ExpandMoreIcon}
        />
      </div>
    </Link>
  );
};

interface ExternalAccountRowProps extends Omit<RowProps, "isShared"> {
  isAdvisor: boolean | null;
}

const ExternalAccountRow = (props: ExternalAccountRowProps) => {
  return (
    <div className={styles.leftRightNoLink}>
      <Stack direction={"row"} gap={`${titleGap}px`} alignItems={"center"}>
        {props.icon ?? ( //Needed in order to align description text with category title
          <div style={{ width: `${specialtyIconWidth}px` }} />
        )}

        <div>
          <span className={styles.descriptionText}>{props.description}</span>

          <div className={styles.subDescriptionText}>
            {props.subDescription ?? " "}
          </div>
        </div>

        {props.elementRightOfLeftContent && props.elementRightOfLeftContent}
      </Stack>

      <div className={toClassName(styles.row, styles.rightRowSide)}>
        {props.elementLeftOfRightContent && props.elementLeftOfRightContent}

        {props.status === "Pending" ? (
          <div className={styles.statusText}>Pending</div>
        ) : (
          <div className={styles.amountText}>{props.valueDisplay}</div>
        )}

        {!props.isAdvisor && (
          <Link
            className={styles.deleteIcon}
            to={(_loc) => ({
              pathname: props.linkTo,
              state: {
                ...(props.linkState ?? {}),
                scrollY: window.scrollY,
              },
            })}
          >
            <DeleteIcon className={styles.removeIcon} />
          </Link>
        )}
      </div>
    </div>
  );
};
