import React, { PropsWithChildren } from "react";

import { MarketplaceAssetType } from "@fartherfinance/frontend/api/AltAssetsService/Types";

import { InsuranceMarketplacePath } from "@src/config/routing/RouterPaths";
import { insuranceOfferingTypes } from "@src/multiCustodian/components/Marketplace/constants";

import OfferingsListAdvisor from "./OfferingsListAdvisor";

const InsuranceOfferingsListAdvisor: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <OfferingsListAdvisor
      assetType={MarketplaceAssetType.enum.insurance}
      offeringTypes={insuranceOfferingTypes}
      marketplacePath={InsuranceMarketplacePath}
      pageTitleFallback="Insurance"
    >
      {children}
    </OfferingsListAdvisor>
  );
};

export default InsuranceOfferingsListAdvisor;
