import React from "react";

import AdvisorContainer from "../../AdvisorContainer";
import CreateUploadSleeve from "@src/multiCustodian/components/Advisor/Investments/Sleeves/CreateSleeve/CreateSleeve";

const CreateSleeve = (): JSX.Element => {
  return (
    <AdvisorContainer hideNavbar={true}>
      <CreateUploadSleeve />
    </AdvisorContainer>
  );
};

export default CreateSleeve;
