import React, { ComponentProps } from "react";

import { InputAdornment } from "@mui/material";
import { Controller, Message, useFormContext } from "react-hook-form";

import TextInput from "../TextInput/TextInput";

import FormFieldLabel from "./Private/FormFieldLabel";

export interface Props {
  name: string;
  label?: React.ReactNode;
  labelTooltip?: string;
  placeholder?: string;
  defaultValue?: string;
  disabled?: boolean;
  required?: Message | false;
  rules?: ComponentProps<typeof Controller>["rules"];
  startAdornment?: string;
  endAdornment?: string;
  textAlign?: React.CSSProperties["textAlign"];
  hideErrorMsg?: boolean;
  customRegex?: { regex: RegExp; message: string };
  valueFormatterOnChange?: <T extends string>(input: T) => string;
  valueFormatterOnBlur?: <T extends string>(input: T) => string;
}

export default function FormNumberField({ ...props }: Props): JSX.Element {
  const { control } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      rules={{
        pattern: {
          value: props.customRegex?.regex ?? /^[\d\.]+$/g,
          message:
            props.customRegex?.message ?? "Must be only numerical digits",
        },
        required: props.required,
        ...props.rules,
      }}
      defaultValue={props.defaultValue}
      render={({ field, fieldState }) => (
        <TextInput
          label={
            <FormFieldLabel
              label={props.label}
              labelTooltip={props.labelTooltip}
            />
          }
          placeholder={props.placeholder}
          onChange={(e) =>
            field.onChange(
              props.valueFormatterOnChange
                ? props.valueFormatterOnChange(e.target.value)
                : e
            )
          }
          value={field.value}
          name={field.name}
          inputRef={field.ref}
          onBlur={(e) => {
            if (props.valueFormatterOnBlur) {
              field.onChange(props.valueFormatterOnBlur(e.target.value));
            }

            field.onBlur();
          }}
          disabled={props.disabled}
          error={fieldState.invalid}
          helperText={
            props.hideErrorMsg === true ? undefined : fieldState.error?.message
          }
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
            style: { textAlign: props.textAlign },
          }}
          InputProps={{
            ...(props.startAdornment && {
              startAdornment: (
                <InputAdornment position="start">
                  {props.startAdornment}
                </InputAdornment>
              ),
            }),
            ...(props.endAdornment && {
              endAdornment: (
                <InputAdornment position="end">
                  {props.endAdornment}
                </InputAdornment>
              ),
            }),
          }}
        />
      )}
    />
  );
}
