import React from "react";

const AltAssets: React.FC = () => (
  <svg
    width="100%"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.2597 31.6072H7V38.5543C7 41.6911 9.54293 44.2341 12.6798 44.2341V44.8652C9.54293 44.8652 7 47.4081 7 50.5449V56.9994H21.2597V31.6072Z"
      fill="currentColor"
    />
    <path
      d="M38.9305 18.9911H24.6708V31.9284C24.6708 35.0653 27.2137 37.6082 30.3506 37.6082V38.2393C27.2137 38.2393 24.6708 40.7822 24.6708 43.9191V57H38.9305V18.9911Z"
      fill="currentColor"
    />
    <path
      d="M57 7H42.9381V26.399C42.9381 29.4923 45.4457 32 48.5391 32V32.6329C45.4457 32.6329 42.938 35.1406 42.9379 38.2339L42.9375 57H56.9994L57 7Z"
      fill="currentColor"
    />
  </svg>
);

export default AltAssets;
