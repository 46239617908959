import React from "react";

import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { format, parseISO } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";

import { PlaidAccount } from "@fartherfinance/frontend/api/ExternalAccount/requests/getAllExternalAccounts";
import { useTheme } from "@fartherfinance/frontend/theme/ThemeProvider";

import { State } from "@src/store";
import toUSD from "@src/utils/toUSD";

import UnknownBalance from "./UnknownBalance";

interface Props {
  account: PlaidAccount;
  balance: number | null;
}

const ValueDisplay = ({ account, balance }: Props): JSX.Element => {
  const { isAdvisor } = useSelector((state: State) => ({
    isAdvisor: state.main_Reducer.user.isAdvisor,
  }));

  const { showStalePlaid, showAdvisorsStalePlaid } = useFlags();

  const showPlaidLastUpdated = isAdvisor
    ? showAdvisorsStalePlaid
    : showStalePlaid;

  const {
    color: { $textSubtle },
  } = useTheme();

  const lastUpdated = null;

  if (balance === null) {
    return <UnknownBalance />;
  } else if (
    account.details.account.connectionHealthType === "healthy" ||
    !showPlaidLastUpdated
  ) {
    return <>{toUSD(balance)}</>;
  } else {
    return (
      <Stack alignItems={"end"} sx={{ color: $textSubtle }}>
        <Typography fontSize={14}>{toUSD(balance)}</Typography>
        <Typography fontSize={12}>
          Last updated{" "}
          {lastUpdated
            ? format(parseISO(lastUpdated), "MM/dd/yyyy")
            : "Unknown"}
        </Typography>
      </Stack>
    );
  }
};

export default ValueDisplay;
