import React from "react";

import { useHistory, useParams } from "react-router-dom";

import { SMAAssetId } from "@fartherfinance/frontend/api/AltAssetsService/requests/Types";

import SMA from "../SMA";
import SMADetails from "@src/multiCustodian/components/Advisor/Investments/SMA/SMADetails/SMADetails";

const SMAId = ({ children }: React.PropsWithChildren<unknown>): JSX.Element => {
  const { SMAId } = useParams<{
    SMAId: SMAAssetId;
  }>();

  const history = useHistory();

  const onClose = () => {
    //Preserve original sorting of SMA Table
    history.push({
      ...location,
      pathname: "/Advisor/Investments/SMA",
    });
  };

  const selectDifferentSMA = (smaId: string) => {
    history.push({
      ...location,
      pathname: `/Advisor/Investments/SMA/${smaId}`,
    });
  };

  const applyToAccount = () => {
    history.push({
      ...location,
      pathname: `/Advisor/Investments/SMA/${SMAId}/Apply`,
    });
  };

  return (
    <SMA>
      <SMADetails
        SMAId={SMAId}
        onClose={onClose}
        selectDifferentSMA={selectDifferentSMA}
        applyToAccount={applyToAccount}
      />

      {children}
    </SMA>
  );
};

export default SMAId;
