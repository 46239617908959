import React from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import AggressionSlider, {
  Step,
} from "@src/multiCustodian/components/Client/Portfolio/AgressionSlider/AggressionSlider";
import Checkbox from "@src/sharedComponents/Checkbox/Checkbox";

import styles from "./RiskLevel.module.css";

interface Props {
  goBack: () => void;
  taxExempt: boolean;
  steps: Step[];
  setTaxExempt: () => void;
  currentStep: number;
  setCurrentStep: (step: number) => void;
  shiftLabelInPixelsIfAtSliderStartOrEnd?: number;
  isCheckboxDisabled: boolean;
}

const RiskLevel = (props: Props): JSX.Element => {
  return (
    <div className={styles.riskLevel}>
      <div className={styles.clientLayoutWidth}>
        <div className={styles.header}>
          <div className={styles.back} onClick={props.goBack}>
            <ArrowBackIcon style={{ width: "10px" }} /> Back
          </div>

          <div className={styles.title}>Select your level of risk</div>

          <IsTaxExempt
            taxExempt={props.taxExempt}
            setTaxExempt={props.setTaxExempt}
            isDisabled={props.isCheckboxDisabled}
          />
        </div>

        <AggressionSlider
          steps={props.steps}
          leftText="CONSERVATIVE"
          rightText="AGGRESSIVE"
          onChange={props.setCurrentStep}
          currentStep={props.currentStep}
          shiftLabelInPixelsIfAtSliderStartOrEnd={
            props.shiftLabelInPixelsIfAtSliderStartOrEnd
          }
        />
      </div>
    </div>
  );
};

export default RiskLevel;

const IsTaxExempt = (props: {
  taxExempt: boolean;
  setTaxExempt: () => void;
  isDisabled: boolean;
}): JSX.Element => {
  return (
    <div className={styles.isTaxExempt}>
      <Checkbox
        label={"Tax-exempt"}
        checked={props.taxExempt}
        onChange={props.setTaxExempt}
        disabled={props.isDisabled}
      />
    </div>
  );
};
