import React, { useState } from "react";

import TextInput from "../../../sharedComponents/TextInput/TextInput";
import Button from "../MUI/Button/Button";
import normalizeEmail from "@src/utils/normalizeEmail";

import Layout from "./Layout";

import * as styles from "./StytchLogin.module.css";

interface Props {
  emailAddress: string;
  onBack?: () => void;
  onResetPasswordClick: (email: string) => Promise<void>;
}

const AccountLock: React.FC<Props> = ({
  emailAddress,
  onBack,
  onResetPasswordClick,
}) => {
  const [email, setEmail] = useState<string>(emailAddress);

  const disabled = email === "";

  return (
    <Layout
      onBack={onBack}
      subtitle="You have made too many incorrect attempts. Please reset your password, or try again after one hour."
      title="Account Locked"
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (disabled) {
            return;
          }

          onResetPasswordClick(email);
        }}
      >
        <TextInput
          label="Email address"
          type={"email"}
          autoComplete="email"
          placeholder="email@example.com"
          value={email}
          onChange={(e) => {
            e.preventDefault();
            setEmail(normalizeEmail(e.target.value));
          }}
        />
        <div className={styles.spacer} />
        <Button
          disabled={disabled}
          text="Reset Password"
          onClick={() => onResetPasswordClick(email)}
          variant="contained"
          buttonType="primary"
          sx={{
            width: "100%",
          }}
        />

        <input type="submit" style={{ display: "none" }} />
      </form>
    </Layout>
  );
};

export default AccountLock;
