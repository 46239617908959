export const graphSettings = {
  YAxisWidth: 90,
  XAxisHeight: 0,
  strokeWidth: 3,
  /* tickCount determines how many labels to place */
  yTickCount: 5,
  /* dx: moves YTick labels left
    dy: moves XTick labels down */
  dx: -8,
  dy: 10,
  fontSize: "14px",
  dashedStrokeWidth: 2,
  strokeDasharray: "6 6",
};
