import { CategoryOfClasses } from "@fartherfinance/frontend/api/PerformanceGroups/hooks/Holdings/utils";
import { AssetClassCategory } from "@fartherfinance/frontend/api/PerformanceGroups/requests/getHoldings";

import combineOtherAndUnexpectedCategory from "@src/multiCustodian/components/Client/Accounts/Holdings/utils/combineOtherAndUnexpectedCategory";

import unknownCategory from "./unknownCategory";

export type AssetClassCategoryObj = Record<
  AssetClassCategory,
  CategoryOfClasses
>;

const reduceHoldingsBreakdown = (
  holdings: CategoryOfClasses[]
): AssetClassCategoryObj => {
  // desired output is to transform incoming holdings which is a list of Category objs into an object with keys which are the category names
  // during the process need to do 2 things:
  // 1.) merge any unknown/unidentifiable categories into category "Other"
  // 2.) merge any classes (within the merged "Other" category) that are the same - when categories get merged they can have multiple of the same class.
  // During merging of categories or classes (since merging affects the percentages and totals) need to re calc/update fields like the: marketValue,
  // percentOfTotal, percentOfCategory, percentOfClass etc all the way down to the security level
  // Don't need to worry about merging duplicate securities but will need to update each security's percentOfCategory field if a category merge occurs
  // and update each security's percentOfClass if classes get merged
  return holdings.reduce(
    (acc: AssetClassCategoryObj, cur: CategoryOfClasses) => {
      const categoryName =
        unknownCategory(cur.category) || cur.category === "Unidentified"
          ? "Other"
          : cur.category;

      const OtherCategoryName = "Other" as AssetClassCategory;

      return {
        ...acc,
        [categoryName]: {
          ...(categoryName === OtherCategoryName
            ? combineOtherAndUnexpectedCategory(acc[OtherCategoryName], cur)
            : cur),
        },
      };
    },
    {}
  );
};

export default reduceHoldingsBreakdown;
