import isString from "lodash/isString";

import { DocOrFilePathPart } from "@fartherfinance/frontend/api/CustodianDoc/Types";

import { lockedDirectories } from "./const";

export const isFile = (directory: DocOrFilePathPart): boolean => {
  return (
    directory.currentDirectory === null &&
    directory.fileName !== null &&
    directory.fileType !== null
  );
};

const lockedEncodedDirectories = lockedDirectories.map(
  (directory) => directory.value
);

export function isDirectoryLocked(directories: string[]): boolean;
export function isDirectoryLocked(directories: string): boolean;
export function isDirectoryLocked(directories: string[] | string): boolean {
  const isSingleDirectory = isString(directories);

  if (isSingleDirectory) {
    // NOTE: we assume that the single directory variant is already encoded
    const directory = directories; // NOTE: alias the name

    return lockedEncodedDirectories.includes(directory);
  }

  const encodedDirectories = directories.map((directory) =>
    encodeURIComponent(directory)
  );

  return (
    lockedEncodedDirectories.filter((lockedDirectory) =>
      encodedDirectories.includes(lockedDirectory)
    ).length > 0
  );
}

export const isFolderNameSafe = (folderName: string): boolean => {
  return /^[a-zA-Z0-9_.-\s]+$/.test(folderName);
};
