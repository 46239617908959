import React from "react";

import {
  MakretplaceOfferingDataType,
  MarketplaceOfferingData,
} from "@fartherfinance/frontend/api/AltAssetsService/Types";

import TabularSection from "../TabularSection/TabularSection";

interface OfferingDataProps {
  offeringData: MarketplaceOfferingData[];
}

const OfferingData: React.FC<OfferingDataProps> = ({ offeringData }) => {
  const handleFormatValue = (offeringData: MarketplaceOfferingData) => {
    const formattedValues = offeringData.value.map((value) => {
      if (offeringData.type === MakretplaceOfferingDataType.enum.currency_usd) {
        return parseInt(value as string).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 0,
        });
      }

      return value;
    });

    if (formattedValues.length === 1) {
      return formattedValues.at(0);
    }

    if (formattedValues.length > 1) {
      return formattedValues.join(", ");
    }

    // NOTE: This should never happen, but leaving just in case
    return "-";
  };

  return (
    <>
      {offeringData
        .filter((offeringData) => offeringData.type === "section")
        .map((offeringData) => (
          <TabularSection
            key={offeringData.key}
            title={offeringData.keyDisplayName}
            entries={(offeringData.value as MarketplaceOfferingData[])
              .filter((data) => data.value.length > 0)
              .map((data) => ({
                key: data.keyDisplayName,
                value: handleFormatValue(data),
              }))}
          />
        ))}
    </>
  );
};

export default OfferingData;
